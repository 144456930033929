import React from 'react';
import ArcPropTypes from '../../helpers/arc/propTypes';

const ArcTokenOutlined = ({ color, backgroundColor, ...props }) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fillRule="nonzero" stroke={color} strokeWidth="1" fill={backgroundColor}><g transform="translate(1.044 1.044)"><path d="M11.956 23.912a11.95 11.95 0 0 0 11.956-11.956C23.912 5.353 18.559 0 11.956 0S0 5.353 0 11.956s5.353 11.956 11.956 11.956z" /><circle cx="11.956" cy="11.956" r="9.299" /></g><path d="M8.19 15.646v-5.308l4.756-2.774 4.756 2.775v7.978l-2.378-1.302-2.378 1.37-4.756-2.74v.001zm4.818-5.305l-2.278 1.326v2.651l2.278 1.326 2.279-1.326v-2.651l-2.279-1.327v.001z" />
    </g>
  </svg>
);

ArcTokenOutlined.propTypes = {
  color: ArcPropTypes.string,
  backgroundColor: ArcPropTypes.string,
};

ArcTokenOutlined.defaultProps = {
  color: '#FFF',
  backgroundColor: 'none',
};

export default ArcTokenOutlined;
