import { withStyles } from '@material-ui/styles';

const styles = ({ palette }) => ({
  '@global': {
    '.video-js .vjs-big-play-button': {
      top: '50%',
      left: '50%',
      transform: 'translate3d(-50%, -50%, 0) !important',

      borderRadius: 2,
      border: 0,

      '&:hover': {
        transform: 'translate3d(-50%, -50%, 0) !important',
      },

      backgroundColor: `${palette.blue.main} !important`,
      boxShadow: `0px 4px 0px ${palette.blue.dark}`,

      '&.arc-button--contained:not([disabled]):not(.arc-button--disabled):focus': {
        backgroundColor: `${palette.blue.light} !important`,
      },

      [`
        &:hover:active,
        &:active
     `]: {
        boxShadow: `0px 3px 0px ${palette.blue.dark}`,
        transform: 'translate3d(-50%, calc(-50% + 1px), 0) !important',
      },

      '@media (pointer: fine)': {
        '&:hover': {
          boxShadow: `0px 5px 0px ${palette.blue.dark}`,
          transform: 'translate3d(-50%, calc(-50% - 1px), 0) !important',
        },
      },
    },

    [`
      .video-js .vjs-play-control,
      .video-js .vjs-volume-panel,
      .video-js .vjs-time-control,
      .video-js .vjs-fullscreen-control
    `]: {
      backgroundColor: '#0290C3',
    },

    '.video-js .vjs-control-bar': {
      backgroundColor: 'rgba(82, 213, 255, 0.7)',
    },

    '.video-js .vjs-progress-control': {
      backgroundColor: 'rgba(0,0,0,0.11)',
    },

    '.video-js .vjs-progress-control .vjs-progress-holder': {
      backgroundColor: 'transparent',
      margin: 0,
      height: '100%',
    },

    '.video-js .vjs-progress-holder .vjs-load-progress': {
      backgroundColor: 'rgba(82, 213, 255, 0.47)',
    },

    '.video-js .vjs-progress-holder .vjs-play-progress': {
      backgroundColor: '#52D5FF',
    },

    '.video-js.vjs-scrubbing .vjs-mouse-display': {
      display: 'none !important',
    },
  },
});

const VideoJsJss = () => null;
export default withStyles(styles, { name: 'VideoJsJss' })(VideoJsJss);
