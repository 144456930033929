import React from 'react';
import { Path } from 'react-primitives-svg';
import Icon from '../Icon';

const Diamond = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.707 20.475a.365.365 0 00.592 0l8.222-11.513a.576.576 0 00-.006-.675L16.99 3.526a.375.375 0 00-.3-.15H7.316a.375.375 0 00-.3.15l-3.526 4.76a.576.576 0 00-.006.675l8.222 11.515z"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.627 8.625H3.378M8.611 8.626l3.392-5.251 3.392 5.25M16.865 3.42l-4.862 17.205M7.14 3.42l4.863 17.205"
    />
  </Icon>
);

Diamond.displayName = 'Diamond';

export default Diamond;
