import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArcTooltip from '../ArcTooltip';
import ArcView from '../../primitives/ArcView/ArcView';


class ArcSelect extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    fullWidth: PropTypes.bool,
    hasLabel: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    inputRef: PropTypes.func,
    label: PropTypes.node,
    name: PropTypes.string,
    onItemClick: PropTypes.func,
    placeholder: PropTypes.node,
    SelectDisplayProps: PropTypes.objectOf(PropTypes.any),
    tooltip: PropTypes.string,
    id: PropTypes.string,
    multiple: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
    variant: PropTypes.oneOf([
      'outlined',
    ]),
  };

  static defaultProps = {
    children: undefined,
    fullWidth: false,
    hasLabel: true,
    items: [],
    inputRef: undefined,
    label: undefined,
    name: undefined,
    onItemClick: global.noop,
    placeholder: undefined,
    SelectDisplayProps: undefined,
    tooltip: undefined,
    id: undefined,
    value: '',
    variant: undefined,
    multiple: false,
  };

  get validDomId() {
    if (this.props.id) {
      return this.props.id;
    }

    return this.props.label ? this.props.label.replace(/^[A-Za-z]+[\w-:.]*$/, '') : '';
  }

  renderItem = ({ label, ...props }) => (
    <MenuItem onClick={() => this.props.onItemClick(props.value)} key={props.value} {...props}>
      {label}
    </MenuItem>
  );

  renderItems() {
    return this.props.items.map(this.renderItem);
  }

  render() {
    const {
      fullWidth,
      hasLabel,
      label,
      onItemClick,
      items,
      placeholder,
      SelectDisplayProps,
      inputRef,
      tooltip,
      children,
      variant,
      ...rest
    } = this.props;

    const selectDisplayProps = SelectDisplayProps || {
      style: {
        height: 'auto',
      },
    };

    return (
      <FormControl fullWidth={fullWidth} variant={variant}>
        <ArcView row>
          <ArcView flexGrow="1">
            {hasLabel && label && (<InputLabel htmlFor={this.validDomId}>{label}</InputLabel>)}
            <Select
              {...rest}
              inputRef={inputRef}
              name={this.validDomId}
              displayEmpty
              SelectDisplayProps={selectDisplayProps}
              input={variant === 'outlined' ? (
                <OutlinedInput
                  labelWidth={hasLabel && label ? label.length * 8 : 0}
                  name={this.props.name}
                  id={this.props.id}
                  ref={inputRef}
                />
              ) : undefined}
            >
              {children || this.renderItems()}
            </Select>
          </ArcView>
          {tooltip && <ArcTooltip title={tooltip} />}
        </ArcView>
        {placeholder && (<FormHelperText>{placeholder}</FormHelperText>)}
      </FormControl>
    );
  }
}

export default ArcSelect;
