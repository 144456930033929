import React from 'react';
import Emitter from 'emmett';
import Close from '@material-ui/icons/Close';
import Attachment from '@material-ui/icons/Attachment';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import {
  ArcView,
  ArcButton,
  ArcIconButton,
  createWithStyles,
  ArcPropTypes,
} from 'arcade-frontend-ui';
import ArcUserInputContainer from 'arcade-frontend-core/src/containers/ArcUserInputContainer';

import FileDropper from './FileDropper';
import Previews from './Previews';

const styles = {
  Controls: theme => ({
    root: {
      borderTopWidth: 3,
      borderColor: theme.palette.grey[200],
      borderStyle: 'solid',
      backgroundColor: theme.palette.grey[100],
      flexDirection: 'row',
    },
  }),
  LeftButtons: () => ({
    root: {
      flexGrow: 1,
    },
  }),
  RightButtons: () => ({
    root: {
      flexDirection: 'row',
      flexBasis: 'content',
    },
  }),
  IconButton: () => ({
    root: {
      width: 56,
    },
  }),
  ShareButton: () => ({
    root: {
      borderRadius: 0,
    },
  }),
  SavingWrapper: () => ({
    root: {
      alignItems: 'center',
      position: 'relative',
    },
    horizontal: {
      flexDirection: 'row',
    },
  }),
  InputWrapper: () => ({
    root: {
      flexGrow: 1,
      flexShrink: 1,
    },
  }),
};

const Controls = createWithStyles(styles.Controls)(ArcView);
const LeftButtons = createWithStyles(styles.LeftButtons)(ArcView);
const RightButtons = createWithStyles(styles.RightButtons)(ArcView);
const IconButton = createWithStyles(styles.IconButton)(ArcIconButton);
const ShareButton = createWithStyles(styles.ShareButton)(ArcButton);
const SavingWrapper = createWithStyles(styles.SavingWrapper)(ArcView);
const InputWrapper = createWithStyles(styles.InputWrapper)(ArcView);

const initialState = {
  files: [],
  hasChanged: false,
  modelValue: '',
  displayValue: '',
  mentionIds: [],
  hashTags: [],
  initialValue: '',
  isFocused: false,
};

class Editor extends React.Component {
  static propTypes = {
    value: ArcPropTypes.string,
    onSubmit: ArcPropTypes.func,
    onCancel: ArcPropTypes.func,
    multipleFiles: ArcPropTypes.bool,
    attachmentSupport: ArcPropTypes.bool,
    isSaving: ArcPropTypes.bool,
    controls: ArcPropTypes.func,
    placeholder: ArcPropTypes.string,
    horizontal: ArcPropTypes.bool,
    submitOnEnter: ArcPropTypes.bool,
    taggingRules: ArcPropTypes.shape({}),
    hasSaveButton: ArcPropTypes.bool,
  };

  static defaultProps = {
    value: '',
    placeholder: "What's on your mind?",
    onSubmit: ArcPropTypes.noop,
    onCancel: ArcPropTypes.noop,
    multipleFiles: false,
    attachmentSupport: true,
    isSaving: false,
    controls: null,
    horizontal: false,
    submitOnEnter: true,
    taggingRules: {},
    hasSaveButton: false,
  };

  constructor(props) {
    super(props);
    this.emitter = new Emitter();
  }

  state = {
    ...initialState,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.isSaving === true && nextProps.isSaving === false) {
      this.clear();
    }

    if (this.props.attachmentSupport !== nextProps.attachmentSupport) {
      this.clear();
    }

    if (this.props.value !== nextProps.value) {
      this.clear({ initialValue: nextProps.value });
    }
  }

  componentWillUnmount() {
    this.cleanUpPreviews();
  }

  setIsFocused = isFocused => this.setState({ isFocused });

  cleanUpPreviews(files = this.state.files) {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }

  handleBlur = () => this.setIsFocused(false);

  handleChange = (event, modelValue, displayValue, mentionIds, hashTags) => {
    this.setState({
      hasChanged: this.state.initialValue !== modelValue,
      modelValue,
      displayValue,
      mentionIds,
      hashTags,
    });
  };

  handleInputSubmit = (
    event,
    modelValue,
    displayValue,
    mentionIds,
    hashTags,
  ) => {
    this.setState(
      { modelValue, displayValue, mentionIds, hashTags },
      this.handleSubmit,
    );
  };

  handleSelect = () => this.setIsFocused(true);

  handleSubmit = () => {
    this.props.onSubmit(this.state);
  };

  removeFile = name => {
    this.cleanUpPreviews(this.state.files.filter(file => file.name === name));
    this.setState({
      files: this.state.files.filter(file => file.name !== name),
    });
  };

  clear = newValues => {
    this.cleanUpPreviews();
    this.setState({ ...initialState, ...newValues });
    this.emitter.emit('clear');
  };

  handleCancel = () => {
    this.clear();
    this.props.onCancel();
  };

  filesSelected = ({ acceptedFiles }) => {
    const newFiles = acceptedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    if (this.props.multipleFiles) {
      const currentFiles = [...this.state.files, ...newFiles];
      this.setState({ hasChanged: true, files: currentFiles });
    } else {
      this.cleanUpPreviews();
      this.setState({ hasChanged: true, files: [newFiles[0]] });
    }
  };

  defaultControls = ({
    open,
    onCancel,
    onSubmit,
    hasChanged,
    attachmentSupport,
  }) => (
    <Controls>
      <LeftButtons>
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </LeftButtons>
      <RightButtons>
        {attachmentSupport && (
          <IconButton onClick={open}>
            <PhotoCamera />
          </IconButton>
        )}
        {attachmentSupport && (
          <IconButton onClick={open}>
            <Attachment />
          </IconButton>
        )}
        <ShareButton
          color={'primary'}
          variant={'flat'}
          onClick={onSubmit}
          disabled={!hasChanged}
        >
          Share
        </ShareButton>
      </RightButtons>
    </Controls>
  );

  renderBody = open => {
    const {
      attachmentSupport,
      value,
      isSaving,
      controls,
      placeholder,
      horizontal,
      submitOnEnter,
      taggingRules,
      hasSaveButton,
    } = this.props;
    const controlsParams = {
      open,
      attachmentSupport,
      onCancel: this.handleCancel,
      onSubmit: this.handleSubmit,
      hasChanged: this.state.hasChanged,
      isFocused: this.state.isFocused,
      isSaving,
    };

    return (
      <SavingWrapper horizontal={horizontal}>
        <InputWrapper>
          <ArcUserInputContainer
            placeholder={placeholder}
            onBlur={this.handleBlur}
            onSelect={this.handleSelect}
            onSubmit={this.handleSubmit}
            onChange={this.handleChange}
            events={this.emitter}
            value={value}
            submitOnEnter={submitOnEnter}
            taggingRules={taggingRules}
            hasSaveButton={hasSaveButton}
          />
        </InputWrapper>

        {(controls || this.defaultControls)(controlsParams)}

        {attachmentSupport && (
          <Previews files={this.state.files} remove={this.removeFile} />
        )}
      </SavingWrapper>
    );
  };

  render() {
    if (this.props.attachmentSupport) {
      return (
        <FileDropper
          filesSelected={this.filesSelected}
          withOpen={open => this.renderBody(open)}
        />
      );
    }
    return this.renderBody();
  }
}

export default Editor;
