import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from 'arcade-frontend-ui';

import ManageMetricEntityCard from './ManageMetricEntityCard';

class ManageMetricsEntitiesList extends React.PureComponent {
  static propTypes = {
    entities: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
  };

  static defaultProps = {
    entities: null,
  };

  renderEntity = entity => (
    <Grid item key={entity.id}>
      <ManageMetricEntityCard {...entity} />
    </Grid>
  );

  render() {
    const { entities } = this.props;

    return (
      <Grid container spacing={2} style={{ margin: 16 }}>
        {entities.map(this.renderEntity)}
      </Grid>
    );
  }
}

export default ManageMetricsEntitiesList;
