import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';

import VpnKey from '@material-ui/icons/VpnKey';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';
import ArcIconButton from 'arcade-frontend-ui/src/elements/ArcIconButton';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';

import * as PASSWORD_FIELDS from '../../types/password-fields';

import ArcForm from '../ArcForm/ArcForm';
import ArcFormField from '../ArcFormField';

const INITIAL_VALUES = {
  currentPassword: '',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  currentPassword: Yup.string()
    .label('Password')
    .required()
    .min(PASSWORD_FIELDS.PASSWORD.min)
    .max(PASSWORD_FIELDS.PASSWORD.max),
});

const PAPER_PROPS = {
  style: {
    margin: 16,
    width: '100%',
    maxWidth: 288,
  },
};

const PREVENT_DEFAULT = e => e && e.preventDefault && e.preventDefault();

function ArcPasswordForm({ open, ...props }) {
  const [showPassword, setShowPassword] = React.useState(false);

  function handlePasswordVisibilityClick() {
    setShowPassword(!showPassword);
  }

  function renderForm(formikProps) {
    return (
      <>
        <DialogTitle>
          <ArcBox display="flex" flexDirection="row">
            <ArcBox display="flex" mr={1}>
              {props.hasSucceeded ? <LockOpen /> : <Lock />}
            </ArcBox>
            <ArcBox>{props.title}</ArcBox>
          </ArcBox>
        </DialogTitle>
        <DialogContent>
          {props.children}
          <ArcBox>
            <ArcFormField
              autoComplete="current-password"
              autoFocus
              type={showPassword ? 'text' : 'password'}
              name="currentPassword"
              label="Password"
              mb={0}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey color="action" fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <ArcIconButton
                      aria-label="toggle password visibility"
                      onClick={handlePasswordVisibilityClick}
                      onMouseDown={PREVENT_DEFAULT}
                    >
                      <ArcText isSmaller>
                        {showPassword ? 'HIDE' : 'SHOW'}
                      </ArcText>
                    </ArcIconButton>
                  </InputAdornment>
                ),
              }}
            />
          </ArcBox>
        </DialogContent>
        <DialogActions>
          <ArcButton label={props.cancelLabel} onClick={props.onCancel} />
          <ArcResourceButton
            form="ArcPasswordForm"
            color="secondary"
            variant="contained"
            type="submit"
            label={props.submitLabel}
            onClick={formikProps.handleSubmit}
          />
        </DialogActions>
      </>
    );
  }

  return (
    <Dialog open={open} onClose={props.onCancel} PaperProps={PAPER_PROPS}>
      <ArcForm
        id="ArcPasswordForm"
        onSubmit={props.onSubmit}
        initialValues={{ ...INITIAL_VALUES, ...props.initialValues }}
        validationSchema={VALIDATION_SCHEMA}
      >
        {renderForm}
      </ArcForm>
    </Dialog>
  );
}

ArcPasswordForm.propTypes = {
  cancelLabel: PropTypes.string,
  children: PropTypes.node,
  hasSucceeded: PropTypes.bool,
  initialValues: PropTypes.shape({
    currentPassword: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  submitLabel: PropTypes.string,
  title: PropTypes.node,
};

ArcPasswordForm.defaultProps = {
  cancelLabel: 'Cancel',
  children: (
    <ArcBox
      mb={4}
      color="caption.color"
      fontSize="caption.fontSize"
      fontWeight="caption.fontWeight"
      lineHeight="caption.lineHeight"
    >
      <ArcText>
        {
          'For security reasons, please enter your current password to continue.'
        }
      </ArcText>
    </ArcBox>
  ),
  hasSucceeded: false,
  initialValues: {},
  onCancel: global.noop,
  onSubmit: global.noop,
  open: false,
  submitLabel: 'Ok',
  title: 'Enter Password',
};

export default ArcPasswordForm;
