import * as ROUTE_TYPES from '../types/routes';

const routeValues = Object.values(ROUTE_TYPES);

export default function actionIsValidRoute(action) {
  if (!action || !action.type || action.type === ROUTE_TYPES.NOT_FOUND) {
    return false;
  }

  return routeValues.indexOf(action.type) > -1;
}
