import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import { ArcText } from 'arcade-frontend-ui';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import * as GAME_PLAYER_STATUSES from 'arcade-frontend-core/src/types/game-player-statuses';

const useStyles = makeStyles(() => ({
  textGrey: {
    color: '#616264',
    lineHeight: 1.5,
  },
}));

const GamesRewardAndPlacementText = ({ winners, playerStatus, finalScore }) => {
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);

  const userDidWin =
    winners &&
    winners.find(
      winner =>
        currentUser.id === winner.userId ||
        winner.includedUsers.indexOf(currentUser.id) > -1,
    );

  const hasWinners = winners.length > 0;

  if (GAME_PLAYER_STATUSES.MANAGER_PLAYER_TYPES.indexOf(playerStatus) > -1) {
    return (
      <>
        {hasWinners ? (
          <ArcText className={classes.textGrey}>
            {`${winners.length} ${pluralize(
              'Winner',
              winners.length,
            )}! Wow! Verify now to award winners`}
          </ArcText>
        ) : (
          <ArcText className={classes.textGrey}>
            {'No winners today! Verify to complete the game and alert players!'}
          </ArcText>
        )}
      </>
    );
  }

  if (playerStatus === GAME_PLAYER_STATUSES.PLAYER) {
    return (
      <>
        {userDidWin ? (
          <ArcText className={classes.textGrey}>
            {`You scored ${finalScore} and won prizes! Nice Work!`}
          </ArcText>
        ) : (
          <ArcText className={classes.textGrey}>
            {"You didn't win today! Keep at it and better luck next time!"}
          </ArcText>
        )}
      </>
    );
  }

  return (
    <ArcText className={classes.textGrey}>
      {"You aren't playing this game but you can spectate!"}
    </ArcText>
  );
};

GamesRewardAndPlacementText.displayName = 'GamesRewardAndPlacementText';

GamesRewardAndPlacementText.propTypes = {
  winners: PropTypes.arrayOf(PropTypes.any),
  playerStatus: PropTypes.oneOf(GAME_PLAYER_STATUSES.ALL_PARTICIPANT_TYPES),
  finalScore: PropTypes.number,
};

GamesRewardAndPlacementText.defaultProps = {
  winners: [],
  playerStatus: null,
  finalScore: 0,
};

export default GamesRewardAndPlacementText;
