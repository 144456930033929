import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const Shops = props => (
  <Icon {...props}>
    <Path d="M3.95695 22.5C3.12995 22.5 2.45695 21.827 2.45695 21V10.227C1.63295 9.87 0.993952 9.193 0.689952 8.346C0.488952 7.785 0.494952 7.17 0.706952 6.614L2.28795 2.466C2.50795 1.889 3.07095 1.5 3.68895 1.5H20.311C20.929 1.5 21.492 1.889 21.712 2.466L23.292 6.614C23.505 7.17 23.511 7.785 23.311 8.345C22.994 9.226 22.324 9.917 21.457 10.263V21C21.457 21.827 20.784 22.5 19.957 22.5H3.95695ZM19.957 21.5C20.233 21.5 20.457 21.276 20.457 21V10.491C20.389 10.495 20.322 10.498 20.255 10.498C19.384 10.498 18.569 10.161 17.955 9.548C17.781 9.374 17.629 9.185 17.5 8.981C16.91 9.917 15.876 10.5 14.75 10.5C13.624 10.5 12.59 9.917 12 8.981C11.41 9.917 10.377 10.5 9.24995 10.5C8.12195 10.5 7.08795 9.916 6.49795 8.978C6.11195 9.588 5.52995 10.061 4.84795 10.306C4.49795 10.432 4.13195 10.496 3.75995 10.496C3.65895 10.496 3.55795 10.491 3.45695 10.482V21C3.45695 21.276 3.68095 21.5 3.95695 21.5H13.457V13.5C13.457 12.948 13.905 12.5 14.457 12.5H18.457C19.009 12.5 19.457 12.948 19.457 13.5V21.5H19.957ZM18.457 21.5V13.5H14.457V21.5H18.457ZM12 6.75C12.276 6.75 12.5 6.974 12.5 7.25C12.5 8.491 13.509 9.5 14.75 9.5C15.991 9.5 17 8.491 17 7.25C17 6.974 17.224 6.75 17.5 6.75C17.776 6.75 18 6.974 18 7.249C18.001 8.489 19.011 9.498 20.251 9.498C21.199 9.497 22.05 8.898 22.369 8.008C22.489 7.672 22.485 7.304 22.358 6.971L20.778 2.822C20.704 2.63 20.517 2.5 20.311 2.5H3.68895C3.48295 2.5 3.29495 2.63 3.22195 2.822L1.64095 6.97C1.51395 7.304 1.51095 7.672 1.63095 8.009C1.83395 8.574 2.24595 9.027 2.78895 9.284C3.09295 9.427 3.41595 9.5 3.74695 9.5C4.00595 9.5 4.26295 9.455 4.50995 9.366C5.39995 9.046 5.99895 8.195 5.99995 7.25C5.99995 6.975 6.22395 6.75 6.49995 6.75C6.77595 6.75 6.99995 6.974 6.99995 7.25C6.99995 8.491 8.00895 9.5 9.24995 9.5C10.491 9.5 11.5 8.491 11.5 7.25C11.5 6.974 11.724 6.75 12 6.75Z" fill="#767676" />
    <Path d="M5.45703 19.5C4.90603 19.5 4.45703 19.052 4.45703 18.5V13.5C4.45703 12.948 4.90603 12.5 5.45703 12.5H11.457C12.008 12.5 12.457 12.948 12.457 13.5V18.5C12.457 19.052 12.008 19.5 11.457 19.5H5.45703ZM5.45703 18.5H11.457V13.5H5.45703V18.5Z" fill="#767676" />
  </Icon>
);

export default Shops;
