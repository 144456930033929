import { createReducer } from 'arcade-frontend-ui';

import * as routeTypes from 'arcade-frontend-core/src/types/routes';
import { types } from '../../../actions/manage/metrics';

const initialState = false;

const isTrue = () => true;
const isFalse = () => false;

const handlers = {
  [types.MANAGE_METRICS_CREATE.SUCCESS]: isTrue,
  [routeTypes.ROUTE_MANAGE_METRICS_CREATE]: isTrue,
  [routeTypes.ROUTE_MANAGE_METRICS]: isFalse,
};

const isCreating = createReducer(initialState, handlers);

export default isCreating;

export const getIsCreating = state => state.manage.metrics.isCreating;
