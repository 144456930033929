import React from 'react';
import { withStyles } from '@material-ui/styles';
import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import ArcPropTypes from '../../helpers/arc/propTypes';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    flexGrow: 0,
    minHeight: 48,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,

    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
  },

  stepIconRoot: {
    '&$stepIconCompleted': {
      color: theme.palette.success.main,
    },

    '&$stepIconError': {
      color: theme.palette.danger.main,
    },
  },

  stepIconError: {},
  stepIconCompleted: {},

  stepIconText: {
    fill: theme.palette.common.white,
  },

  stepLabelRoot: {
    opacity: 0.5,
  },

  iconContainerConfirm: {
    display: 'none',
  },

  stepConnectorLine: {
    borderColor: theme.palette.divider,
  },

  stepRoot: {
    cursor: 'pointer',
    padding: theme.spacing(3),

    '&:first-child, &:last-child': {
      padding: theme.spacing(3),
    },

    '& span': {
      cursor: 'pointer',
      userSelect: 'none',
    },
  },
});

class ArcStepper extends React.PureComponent {
  static propTypes = {
    activeStep: ArcPropTypes.number,
    classes: ArcPropTypes.objectOf(ArcPropTypes.string).isRequired,
    confirmLabel: ArcPropTypes.string,
    hasConfirm: ArcPropTypes.bool,
    onConfirm: ArcPropTypes.func,
    onStep: ArcPropTypes.func,
    steps: ArcPropTypes.arrayOf(ArcPropTypes.shape({
      isValid: ArcPropTypes.bool.isRequired,
      label: ArcPropTypes.string.isRequired,
    })),
  };

  static defaultProps = {
    activeStep: 0,
    confirmLabel: 'Confirm',
    hasConfirm: false,
    onConfirm: ArcPropTypes.noop,
    onStep: ArcPropTypes.noop,
    steps: [],
  };

  renderStep = (step, index) => {
    const { activeStep, classes } = this.props;
    let error = false;

    if (activeStep > index) {
      error = !step.isValid;
    }

    return (
      <Step
        key={step.label}
        classes={{ root: classes.stepRoot }}
        onClick={() => this.props.onStep(index)}
      >
        <StepLabel
          StepIconProps={{
            classes: {
              root: classes.stepIconRoot,
              completed: classes.stepIconCompleted,
              text: classes.stepIconText,
              error: error && classes.stepIconError,
            },
          }}
          error={error}
          classes={{
            label: activeStep !== index && classes.stepLabelRoot,
          }}
        >
          {step.label}
        </StepLabel>
      </Step>
    );
  };

  render() {
    const { activeStep, classes, steps } = this.props;
    return (
      <Stepper
        activeStep={activeStep}
        className={classes.root}
        connector={(
          <StepConnector classes={{ line: classes.stepConnectorLine }} />
        )}
      >
        {steps.map(this.renderStep)}

        {this.props.hasConfirm && (
          <Step
            classes={{ root: classes.stepRoot }}
            onClick={() => this.props.onConfirm(steps.length)}
          >
            <StepLabel
              classes={{
                iconContainer: classes.iconContainerConfirm,
                label: activeStep !== steps.length && classes.stepLabelRoot,
              }}
            >
              {this.props.confirmLabel}
            </StepLabel>
          </Step>
        )}
      </Stepper>
    );
  }
}


export default withStyles(styles)(ArcStepper);
