import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../actions';

const handleEntitiesIndexSuccess = (state, action) => {
  const { data } = action.payload;
  const newState = {};

  data.forEach((entity) => {
    newState[entity.id] = entity;
  });

  return newState;
};

const initialState = {};

const handlers = {
  [types.APP_TAGGABLE_ENTITIES_INDEX.SUCCESS]: handleEntitiesIndexSuccess,
};

const byId = createReducer(initialState, handlers);

export default byId;

export const getById = state => state.app.entities.byId;
