import React from 'react';
import { ActionType, ArcUser, ArcUserState } from '../arc-user-reducer';

import {
  ArcUserStateContext,
  ArcUserDispatchContext,
} from '../ArcUserProvider';

function useArcUserState() {
  const context = React.useContext(ArcUserStateContext);

  if (context === undefined) {
    throw new Error('useArcUserState must be used within an ArcUserProvider');
  }

  return context;
}

function useArcUserDispatch() {
  const context = React.useContext(ArcUserDispatchContext);

  if (context === undefined) {
    throw new Error(
      'useArcUserDispatch must be used within an ArcUserProvider',
    );
  }

  return context;
}

interface UseArcUserValue {
  arcUser: ArcUserState;
  login: (payload: ArcUser) => void;
  logout: () => void;
}

export default function useArcUser(): UseArcUserValue {
  const arcUser = useArcUserState();
  const dispatch = useArcUserDispatch();

  const login = React.useCallback(
    (payload: ArcUser) =>
      dispatch({
        type: ActionType.LOGIN,
        payload,
      }),
    [],
  );

  const logout = React.useCallback(
    () => dispatch({ type: ActionType.LOGOUT }),
    [],
  );

  return { arcUser, login, logout };
}
