import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const Chat = props => (
  <Icon {...props}>
    <Path
      fill="currentColor"
      d="M23.831 14.357a5.807 5.807 0 00-3.82-5.144c.104-.453.155-.915.152-1.38C20.19 3.544 15.639 0 10.08 0 4.524 0 0 3.544 0 7.889a7.02 7.02 0 002.827 5.516v4.33a.51.51 0 00.262.456c.078.05.17.074.262.069a.469.469 0 00.29-.124l4.386-2.538.993.124c.8 2.648 3.778 4.551 7.309 4.551.499-.006.996-.048 1.49-.124l3.185 1.834a.526.526 0 00.262 0 .622.622 0 00.262 0 .51.51 0 00.262-.455l.185-2.997c.621-.48.943-1.213 1.296-1.915a5.255 5.255 0 00.56-2.26zM3.875 13.074v-.11a.883.883 0 010-.097v-.151a6.068 6.068 0 01-2.813-4.827c0-3.765 4.04-6.896 9.02-6.896 4.978 0 9.033 3.062 9.033 6.896s-4.055 6.826-9.033 6.826a11.832 11.832 0 01-2.097-.179h-.317L3.875 16.77v-3.696zm16.84 7.613l-2.552-1.476h-.165a.235.235 0 00-.138 0 7.722 7.722 0 01-1.503.138 6.55 6.55 0 01-6.179-3.448c4.496 0 8.275-2.33 9.654-5.516a4.73 4.73 0 013.062 4.137 4.317 4.317 0 01-1.848 3.393l-.07.083a.286.286 0 000 .082.193.193 0 000 .097.26.26 0 000 .096v2.414h-.261z"
    />
  </Icon>
);

export default Chat;
