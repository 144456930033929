import ArcView from '../../primitives/ArcView/ArcView';
import { createWithStyles } from '../../styles';

const styles = {
  ArcCardNav: () => ({
    root: {
      flexShrink: 0,
      flexGrow: 0,
      flexDirection: 'row',
      alignItems: 'center',
    },
  }),
};

const ArcCardNav = createWithStyles(styles.ArcCardNav)(ArcView);

export default ArcCardNav;
