import arcadeReactAnimatedSplash from './arcadeReactAnimatedSplash';
import arcadeReactAwardTokenFormContainer from './arcadeReactAwardTokenFormContainer';
import arcadeReactChatCreateFormDialog from './arcadeReactChatCreateFormDialog';
import arcadeReactChatEditFormDialog from './arcadeReactChatEditFormDialog';
import arcadeReactChecklist from './arcadeReactChecklist';
import arcadeReactDatepicker from './arcadeReactDatepicker';
import arcadeReactIcon from './arcadeReactIcon';
import arcadeReactInterlaced from './arcadeReactInterlaced';
import arcadeReactLeftMenu from './arcadeReactLeftMenu';
import arcadeReactLoader from './arcadeReactLoader';
import arcadeReactLogin from './arcadeReactLogin';
import arcadeReactManageBilling from './arcadeReactManageBilling';
import arcadeReactManageChecklists from './arcadeReactManageChecklists';
import arcadeReactManageEngagement from './arcadeReactManageEngagement';
import arcadeReactManageGames from './arcadeReactManageGames';
import arcadeReactManageMetrics from './arcadeReactManageMetrics';
import arcadeReactManagePeople from './arcadeReactManagePeople';
import arcadeReactManagePosts from './arcadeReactManagePosts';
import arcadeReactManageRewards from './arcadeReactManageRewards';
import arcadeReactManageSales from './arcadeReactManageSales';
import arcadeReactManageTransactions from './arcadeReactManageTransactions';
import arcadeReactManageTokens from './arcadeReactManageTokens';
import arcadeReactManageTokenUserBudgets from './arcadeReactManageTokenUserBudgets';
import arcadeReactManageIntegrationHistory from './arcadeReactManageIntegrationHistory';
import arcadeReactNewsfeedFab from './arcadeReactNewsfeedFab';
import arcadeReactNewsfeedView from './arcadeReactNewsfeedView';
import arcadeReactNotificationsPanel from './arcadeReactNotificationsPanel';
import arcadeReactNotificationsPortal from './arcadeReactNotificationsPortal';
import arcadeReactPeople from './arcadeReactPeople';
import arcadeReactProfileView from './arcadeReactProfileView';
import arcadeReactQuicklinks from './arcadeReactQuicklinks';
import arcadeReactRewardsAward from './arcadeReactRewardsAward';
import arcadeReactRewardsHistory from './arcadeReactRewardsHistory';
import arcadeReactRewardsShop from './arcadeReactRewardsShop';
import arcadeReactRewardsTransactionsIndex from './arcadeReactRewardsTransactionsIndex';
import arcadeReactTourStepper from './arcadeReactTourStepper';
import arcadeReactUserInput from './arcadeReactUserInput';
import arcadeReactNotifications from './arcadeReactNotifications';
import arcadeReactSizzles from './arcadeReactSizzles';
import arcadeReactVideo from './arcadeReactVideo';
import arcadeReactToken from './arcadeReactToken';
import arcadeReactUploadManager from './arcadeReactUploadManager';
import arcadeReactUploadAnchor from './arcadeReactUploadAnchor';
import arcadeReactActivityEditor from './arcadeReactActivityEditor';
import arcadeReactPreviewList from './arcadeReactPreviewList';
import arcadeReactChatSearch from './arcadeReactChatSearch';
import arcadeReactTaggedActivitiesDialog from './arcadeReactTaggedActivitiesDialog';

if (window.angular) {
  const angularApp = window.angular.module('desktop');

  angularApp.run([
    '$state',
    function ($state) {
      window.ngState = $state;
    },
  ]);
  angularApp.run([
    '$injector',
    function (_$injector) {
      window.$injector = _$injector;
    },
  ]);

  const module = window.angular.module('arcade.material');

  module.component('arcadeReactAnimatedSplash', arcadeReactAnimatedSplash);
  module.component(
    'arcadeReactChatCreateFormDialog',
    arcadeReactChatCreateFormDialog,
  );
  module.component(
    'arcadeReactChatEditFormDialog',
    arcadeReactChatEditFormDialog,
  );
  module.component('arcadeReactChecklist', arcadeReactChecklist);
  module.component(
    'arcadeReactAwardTokenFormContainer',
    arcadeReactAwardTokenFormContainer,
  );
  module.component('arcadeReactDatepicker', arcadeReactDatepicker);
  module.component('arcadeReactIcon', arcadeReactIcon);
  module.component('arcadeReactInterlaced', arcadeReactInterlaced);
  module.component('arcadeReactLeftMenu', arcadeReactLeftMenu);
  module.component('arcadeReactLoader', arcadeReactLoader);
  module.component('arcadeReactLogin', arcadeReactLogin);
  module.component('arcadeReactManageBilling', arcadeReactManageBilling);
  module.component('arcadeReactManageChecklists', arcadeReactManageChecklists);
  module.component('arcadeReactManageEngagement', arcadeReactManageEngagement);
  module.component('arcadeReactManageGames', arcadeReactManageGames);
  module.component('arcadeReactManageMetrics', arcadeReactManageMetrics);
  module.component('arcadeReactManagePeople', arcadeReactManagePeople);
  module.component('arcadeReactManagePosts', arcadeReactManagePosts);
  module.component('arcadeReactManageRewards', arcadeReactManageRewards);
  module.component('arcadeReactManageSales', arcadeReactManageSales);
  module.component(
    'arcadeReactManageTransactions',
    arcadeReactManageTransactions,
  );
  module.component('arcadeReactManageTokens', arcadeReactManageTokens);
  module.component(
    'arcadeReactManageTokenUserBudgets',
    arcadeReactManageTokenUserBudgets,
  );
  module.component(
    'arcadeReactManageIntegrationHistory',
    arcadeReactManageIntegrationHistory,
  );
  module.component('arcadeReactNewsfeedFab', arcadeReactNewsfeedFab);
  module.component('arcadeReactNewsfeedView', arcadeReactNewsfeedView);
  module.component(
    'arcadeReactNotificationsPanel',
    arcadeReactNotificationsPanel,
  );
  module.component(
    'arcadeReactNotificationsPortal',
    arcadeReactNotificationsPortal,
  );
  module.component('arcadeReactPeople', arcadeReactPeople);
  module.component('arcadeReactProfileView', arcadeReactProfileView);
  module.component('arcadeReactQuicklinks', arcadeReactQuicklinks);
  module.component('arcadeReactRewardsAward', arcadeReactRewardsAward);
  module.component('arcadeReactRewardsHistory', arcadeReactRewardsHistory);
  module.component('arcadeReactRewardsShop', arcadeReactRewardsShop);
  module.component(
    'arcadeReactRewardsTransactionsIndex',
    arcadeReactRewardsTransactionsIndex,
  );
  module.component('arcadeReactTourStepper', arcadeReactTourStepper);
  module.component('arcadeReactUserInput', arcadeReactUserInput);
  module.component('arcadeReactNotifications', arcadeReactNotifications);
  module.component('arcadeReactSizzles', arcadeReactSizzles);
  module.component('arcadeReactVideo', arcadeReactVideo);
  module.component('arcadeReactToken', arcadeReactToken);
  module.component('arcadeReactUploadManager', arcadeReactUploadManager);
  module.component('arcadeReactUploadAnchor', arcadeReactUploadAnchor);
  module.component('arcadeReactActivityEditor', arcadeReactActivityEditor);
  module.component('arcadeReactPreviewList', arcadeReactPreviewList);
  module.component('arcadeReactChatSearch', arcadeReactChatSearch);
  module.component(
    'arcadeReactTaggedActivitiesDialog',
    arcadeReactTaggedActivitiesDialog,
  );
}
