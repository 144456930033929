import { forms } from 'arcade-frontend-ui';

const model = 'dateRange';

const initialState = {
  dateType: 'today',
  dateRange: {
    from: null,
    to: null,
  },
  selectedDay: '',
};

export const reducer = forms.createFormReducer(model, initialState);

export const getForm = state => state.newForms.metrics[model];
