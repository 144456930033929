import * as types from 'arcade-frontend-core/src/types/routes';

export const routeManageActivities = (props = {}) => {
  const { integrationRunName, integrationRunId } = props;

  return {
    type: types.ROUTE_MANAGE_ACTIVITIES,
    payload: { integrationRunName },
    query: { integrationRunId },
  };
};
