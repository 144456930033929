import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    left: 8,
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: 16,
  },
}));

function ArcMultiSelectPlaceholder(props) {
  const classes = useStyles();

  const { innerProps, children } = props;
  return (
    <Typography color="textSecondary" className={classes.root} {...innerProps}>
      {children}
    </Typography>
  );
}

ArcMultiSelectPlaceholder.displayName = 'ArcMultiSelectPlaceholder';

ArcMultiSelectPlaceholder.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.shape({}),
};

ArcMultiSelectPlaceholder.defaultProps = {
  children: null,
  innerProps: {},
};

export default ArcMultiSelectPlaceholder;
