import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcResourceEmptyPending from 'arcade-frontend-ui/src/components/ArcResourceEmptyPending';

import GamesActiveEmpty from '../GamesActiveEmpty';
import GamesGameCard from '../GamesGameCard';
import GamesNoResultsBox from '../GamesNoResultsBox';

function GamesGameCardListPlaceholder(props) {
  return (
    <Grid container spacing={2} wrap="nowrap">
      {Array.from(new Array(2)).map((_, idx) => (
        <Grid key={idx.toString()} item xs={12} sm="auto">
          <GamesGameCard.Placeholder {...props} />
        </Grid>
      ))}
    </Grid>
  );
}

function GamesGameCardList({
  emptyNode,
  games,
  isPending,
  onCardClick,
  onTabReset,
  status,
  title,
}) {
  return (
    <ArcBox pt={5} display="flex" flexDirection="column">
      <ArcBox display="flex" flexDirection="row" alignItems="center" mb={2}>
        <ArcBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexGrow={1}
          mr={1}
        >
          <ArcText sectionTitle>{title}</ArcText>
          {isPending && (
            <ArcBox ml={1}>
              <CircularProgress size={16} />
            </ArcBox>
          )}
        </ArcBox>
      </ArcBox>

      <ArcResourceEmptyPending
        isPending={isPending}
        hasData={!!games.length}
        emptyNode={emptyNode}
        failedNode={'failed'}
        pendingNode={<GamesGameCardListPlaceholder status={status} />}
      >
        <Grid container spacing={2} wrap={'wrap'}>
          {games.map(game => (
            <Grid item xs={12} sm="auto" key={game.id}>
              <GamesGameCard
                id={game.id}
                allowManualAdd={game.rules.allowManualAdd}
                campaignImage={game.campaignImage}
                expiresAt={game.expiresAt}
                startsAt={game.startsAt}
                status={game.status}
                score={game.currentUserScore}
                finalScore={game.finalScore}
                isManager={game.isManager}
                name={game.name}
                onTabReset={onTabReset}
                onClick={() => onCardClick(game.id)}
                participantCount={game.participantCount}
                pendingCount={game.pendingSalesCount}
                playerStatus={game.playerStatus}
                type={game.type}
                rankings={game.rankings}
                rewards={game.rewards}
                rules={game.rules}
                winners={game.winners}
                leaderboardActivityId={game.leaderboardActivityId}
                height="100%"
                mx="auto"
              />
            </Grid>
          ))}
        </Grid>
      </ArcResourceEmptyPending>
    </ArcBox>
  );
}

GamesGameCardList.displayName = 'GamesGameCardList';

GamesGameCardList.Placeholder = GamesGameCardListPlaceholder;

GamesGameCardList.propTypes = {
  emptyNode: PropTypes.node,
  games: PropTypes.arrayOf(PropTypes.shape({})),
  isPending: PropTypes.bool,
  onCardClick: PropTypes.func,
  onTabReset: PropTypes.func,
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
};

GamesGameCardList.defaultProps = {
  emptyNode: (
    <GamesNoResultsBox>
      <GamesActiveEmpty />
    </GamesNoResultsBox>
  ),
  games: [],
  isPending: false,
  onCardClick: global.noop,
  onTabReset: global.noop,
  status: '',
};

export default GamesGameCardList;
