import { createRequestingStatusReducer } from 'arcade-frontend-ui';

import { types as typesCore } from 'arcade-frontend-core/src/actions';

import { types } from '../../../actions/manage/people';

const initialState = {
  LOCK_ACCOUNT: 'DEFAULT',
  UNLOCK_ACCOUNT: 'DEFAULT',
  MANAGE_PEOPLE_PROFILE_UPDATE: 'DEFAULT',
  RESET_PASSWORD: 'DEFAULT',
  MANAGE_PEOPLE_PERMISSION_UPDATE: 'DEFAULT',
  APP_INVITE_PERSON: 'DEFAULT',
};

const actions = [
  types.MANAGE_PEOPLE_PROFILE_UPDATE,
  types.MANAGE_PEOPLE_PERMISSIONS_UPDATE,
  types.LOCK_ACCOUNT,
  types.RESET_PASSWORD,
  types.UNLOCK_ACCOUNT,

  typesCore.APP_INVITE_PERSON,
];

const requestStatus = createRequestingStatusReducer(initialState, actions);

export default requestStatus;

export const getRequestStatus = state => state.manage.people.requestStatus;
