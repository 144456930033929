import { makeApiActionTypes } from 'arcade-frontend-ui';
import {
  makeDateFilterParams,
  makeParams,
} from 'arcade-frontend-core/src/actions/helpers';

import { actions as commentActions } from './comments';

// Types
export const NEWSFEED_INDEX = makeApiActionTypes('NEWSFEED_INDEX');
export const NEWSFEED_ACK_PIN = makeApiActionTypes('NEWSFEED_ACK_PIN');
export const NEWSFEED_ACTIVITY = makeApiActionTypes('NEWSFEED_ACTIVITY');
export const NEWSFEED_ACTIVITY_RELOAD_COMMENTS = makeApiActionTypes(
  'NEWSFEED_ACTIVITY_RELOAD_COMMENTS',
);
export const NEWSFEED_CREATE_ACTIVITY = makeApiActionTypes(
  'NEWSFEED_CREATE_ACTIVITY',
);
export const NEWSFEED_UPDATE_ACTIVITY = makeApiActionTypes(
  'NEWSFEED_UPDATE_ACTIVITY',
);
export const NEWSFEED_CREATE_COMMENT = makeApiActionTypes(
  'NEWSFEED_CREATE_COMMENT',
);
export const NEWSFEED_DELETE_COMMENT = makeApiActionTypes(
  'NEWSFEED_DELETE_COMMENT',
);
export const NEWSFEED_TOGGLE_ACTIVITY_RESPECT = makeApiActionTypes(
  'NEWSFEED_TOGGLE_ACTIVITY_RESPECT',
);
export const NEWSFEED_DELETE_ACTIVITY = makeApiActionTypes(
  'NEWSFEED_DELETE_ACTIVITY',
);
export const NEWSFEED_PIN_ACTIVITY = makeApiActionTypes(
  'NEWSFEED_PIN_ACTIVITY',
);
export const NEWSFEED_UNPIN_ACTIVITY = makeApiActionTypes(
  'NEWSFEED_UNPIN_ACTIVITY',
);
export const NEWSFEED_GET_ACTIVITY_ACKS = makeApiActionTypes(
  'NEWSFEED_GET_ACTIVITY_ACKS',
);
export const NEWSFEED_GET_ACTIVITY_COMMENTS = makeApiActionTypes(
  'NEWSFEED_GET_ACTIVITY_COMMENTS',
);
export const NEWSFEED_GET_ACTIVITY_RESPECTS = makeApiActionTypes(
  'NEWSFEED_GET_ACTIVITY_RESPECTS',
);
export const NEWSFEED_GET_ACTIVITY_VIEWS = makeApiActionTypes(
  'NEWSFEED_GET_ACTIVITY_VIEWS',
);
export const NEWSFEED_GET_SUMMARY_ACTIVITIES = makeApiActionTypes(
  'NEWSFEED_GET_SUMMARY_ACTIVITIES',
);
export const NEWSFEED_REPORT_ACTIVITY = makeApiActionTypes(
  'NEWSFEED_REPORT_ACTIVITY',
);
export const NEWSFEED_CLEAR_ACTIVITIES = 'NEWSFEED_CLEAR_ACTIVITIES';
export const NEWSFEED_CLEAR_AND_RELOAD_ACTIVITIES =
  'NEWSFEED_CLEAR_AND_RELOAD_ACTIVITIES';
export const NEWSFEED_EDIT_ACTIVITY = 'NEWSFEED_EDIT_ACTIVITY';
export const NEWSFEED_EDIT_ACTIVITY_CANCEL = 'NEWSFEED_EDIT_ACTIVITY_CANCEL';
export const NEWSFEED_EDIT_ACTIVITY_DONE = 'NEWSFEED_EDIT_ACTIVITY_DONE';
export const NEWSFEED_SAVE_COMMENT_AND_UPDATE_ACTIVITY =
  'NEWSFEED_SAVE_COMMENT_AND_UPDATE_ACTIVITY';
export const NEWSFEED_DELETE_COMMENT_AND_UPDATE_ACTIVITY =
  'NEWSFEED_DELETE_COMMENT_AND_UPDATE_ACTIVITY';
export const NEWSFEED_ACKNOWLEDGE_PIN_AND_UPDATE_ACTIVITY =
  'NEWSFEED_ACKNOWLEDGE_PIN_AND_UPDATE_ACTIVITY';
export const NEWSFEED_TOGGLE_RESPECT_AND_UPDATE_ACTIVITY =
  'NEWSFEED_TOGGLE_RESPECT_AND_UPDATE_ACTIVITY';
export const NEWSFEED_TOGGLE_ACTIVITY_PIN_AND_UPDATE_ACTIVITY =
  'NEWSFEED_TOGGLE_ACTIVITY_PIN_AND_UPDATE_ACTIVITY';
export const NEWSFEED_CREATE_AND_RELOAD_ACTIVITY =
  'NEWSFEED_CREATE_AND_RELOAD_ACTIVITY';
export const NEWSFEED_UPDATE_AND_RELOAD_ACTIVITY =
  'NEWSFEED_UPDATE_AND_RELOAD_ACTIVITY';
export const NEWSFEED_DELETE_AND_RELOAD_ACTIVITIES =
  'NEWSFEED_DELETE_AND_RELOAD_ACTIVITIES';
export const NEWSFEED_CLEAR_ATTACHED_VIDEOS = 'NEWSFEED_CLEAR_ATTACHED_VIDEOS';
export const NEWSFEED_SELECT_AUDIENCE = 'NEWSFEED_SELECT_AUDIENCE';
export const NEWSFEED_EDIT_ACTIVITY_FROM_ANGULAR =
  'NEWSFEED_EDIT_ACTIVITY_FROM_ANGULAR';
export const NEWSFEED_PROMOTE_AND_RELOAD_ACTIVITY =
  'NEWSFEED_PROMOTE_AND_RELOAD_ACTIVITY';
export const NEWSFEED_PROMOTE_ACTIVITY = makeApiActionTypes(
  'NEWSFEED_PROMOTE_ACTIVITY',
);

export const NEWSFEED_REACT_TO_ACTIVITY = makeApiActionTypes(
  'NEWSFEED_REACT_TO_ACTIVITY',
);
export const NEWSFEED_DELETE_REACT_TO_ACTIVITY = makeApiActionTypes(
  'NEWSFEED_DELETE_REACT_TO_ACTIVITY',
);
export const NEWSFEED_REACT_TO_COMMENT = makeApiActionTypes(
  'NEWSFEED_REACT_TO_COMMENT',
);
export const NEWSFEED_DELETE_REACT_TO_COMMENT = makeApiActionTypes(
  'NEWSFEED_DELETE_REACT_TO_COMMENT',
);
export const NEWSFEED_GET_COMMENT_REACTIONS = makeApiActionTypes(
  'NEWSFEED_GET_COMMENT_REACTIONS',
);
export const NEWSFEED_GET_ACTIVITY_REACTIONS = makeApiActionTypes(
  'NEWSFEED_GET_ACTIVITY_REACTIONS',
);
export const ACTIVITY_HISTORY_INDEX = makeApiActionTypes(
  'ACTIVITY_HISTORY_INDEX',
);
export const ACTIVITY_HISTORY_DETAILS = makeApiActionTypes(
  'ACTIVITY_HISTORY_DETAILS',
);
export const ACTIVITY_HISTORY_DELETE = makeApiActionTypes(
  'ACTIVITY_HISTORY_DELETE',
);
export const NEWSFEED_SAVE_COMMENT_REACTION = 'NEWSFEED_SAVE_COMMENT_REACTION';
export const NEWSFEED_SAVE_ACTIVITY_REACTION =
  'NEWSFEED_SAVE_ACTIVITY_REACTION';
export const NEWSFEED_TAGGED_ACTIVITIES = makeApiActionTypes(
  'NEWSFEED_TAGGED_ACTIVITIES',
);
export const NEWSFEED_OPEN_TAGGED_ACTIVITIES =
  'NEWSFEED_OPEN_TAGGED_ACTIVITIES';
export const NEWSFEED_CLOSE_TAGGED_ACTIVITIES =
  'NEWSFEED_CLOSE_TAGGED_ACTIVITIES';

// Actions
const activityIndexRequest = payload => ({
  type: NEWSFEED_INDEX.REQUEST,
  payload,
});

const activityAcknowledgePin = ({ id }) => ({
  type: NEWSFEED_ACK_PIN.REQUEST,
  payload: { id },
});

const createActivity = data => ({
  type: NEWSFEED_CREATE_ACTIVITY.REQUEST,
  payload: data,
});

const updateActivity = ({ data, id }) => ({
  type: NEWSFEED_UPDATE_ACTIVITY.REQUEST,
  payload: { data, id },
});

const editActivity = data => ({
  type: NEWSFEED_EDIT_ACTIVITY,
  payload: data,
});

const editActivityDone = () => ({
  type: NEWSFEED_EDIT_ACTIVITY_DONE,
  payload: {},
});

const editActivityCancel = () => ({
  type: NEWSFEED_EDIT_ACTIVITY_CANCEL,
  payload: {},
});

const createComment = data => ({
  type: NEWSFEED_CREATE_COMMENT.REQUEST,
  payload: data,
});

const reloadComments = ({ id }) => ({
  type: NEWSFEED_ACTIVITY_RELOAD_COMMENTS.REQUEST,
  payload: { id },
});

const loadActivity = ({ id }) => ({
  type: NEWSFEED_ACTIVITY.REQUEST,
  payload: { id },
});

const deleteActivity = ({ id }) => ({
  type: NEWSFEED_DELETE_ACTIVITY.REQUEST,
  payload: { id },
});

const toggleActivityRespect = ({ id }) => ({
  type: NEWSFEED_TOGGLE_ACTIVITY_RESPECT.REQUEST,
  payload: { id },
});

const saveCommentAndUpdateActivity = data => ({
  type: NEWSFEED_SAVE_COMMENT_AND_UPDATE_ACTIVITY,
  payload: data,
});

const deleteCommentAndUpdateActivity = data => ({
  type: NEWSFEED_DELETE_COMMENT_AND_UPDATE_ACTIVITY,
  payload: data,
});

const acknowledgePinAndUpdateActivity = data => ({
  type: NEWSFEED_ACKNOWLEDGE_PIN_AND_UPDATE_ACTIVITY,
  payload: data,
});

const toggleRespectAndUpdateActivity = data => ({
  type: NEWSFEED_TOGGLE_RESPECT_AND_UPDATE_ACTIVITY,
  payload: data,
});

const getActivityAcks = ({ id }) => ({
  type: NEWSFEED_GET_ACTIVITY_ACKS.REQUEST,
  payload: { id },
});

const getActivityComments = id => ({
  type: NEWSFEED_GET_ACTIVITY_COMMENTS.REQUEST,
  payload: id,
});

const getActivityRespects = ({ id }) => ({
  type: NEWSFEED_GET_ACTIVITY_RESPECTS.REQUEST,
  payload: { id },
});

const getActivityViews = ({ id }) => ({
  type: NEWSFEED_GET_ACTIVITY_VIEWS.REQUEST,
  payload: { id },
});

const pinActivity = ({ id, expires }) => ({
  type: NEWSFEED_PIN_ACTIVITY.REQUEST,
  payload: { id, expires },
});

const unpinActivity = ({ id }) => ({
  type: NEWSFEED_UNPIN_ACTIVITY.REQUEST,
  payload: { id },
});

const toggleActivityPinAndUpdateActivity = ({ id, expires, pin }) => ({
  type: NEWSFEED_TOGGLE_ACTIVITY_PIN_AND_UPDATE_ACTIVITY,
  payload: { id, expires, pin },
});

const getSummaryActivities = ({ type, newest, oldest }) => ({
  type: NEWSFEED_GET_SUMMARY_ACTIVITIES.REQUEST,
  payload: { type, newest, oldest },
});

const clearAndReloadActivities = () => ({
  type: NEWSFEED_CLEAR_AND_RELOAD_ACTIVITIES,
  payload: {},
});

const clearActivities = () => ({
  type: NEWSFEED_CLEAR_ACTIVITIES,
  payload: {},
});

const reportActivity = ({ id }) => ({
  type: NEWSFEED_REPORT_ACTIVITY.REQUEST,
  payload: { id },
});

const createAndReloadActivity = data => ({
  type: NEWSFEED_CREATE_AND_RELOAD_ACTIVITY,
  payload: data,
});

const updateAndReloadActivity = data => ({
  type: NEWSFEED_UPDATE_AND_RELOAD_ACTIVITY,
  payload: data,
});

const deleteAndReloadActivities = data => ({
  type: NEWSFEED_DELETE_AND_RELOAD_ACTIVITIES,
  payload: data,
});

const promoteAndUpdateActivity = data => ({
  type: NEWSFEED_PROMOTE_AND_RELOAD_ACTIVITY,
  payload: data,
});

const clearAttachedVideos = name => ({
  type: NEWSFEED_CLEAR_ATTACHED_VIDEOS,
  payload: {
    name,
  },
});

const selectAudience = audience => ({
  type: NEWSFEED_SELECT_AUDIENCE,
  payload: audience,
});

const apiActivityHistoryIndexRequest = params => ({
  type: ACTIVITY_HISTORY_INDEX.REQUEST,
  payload: {
    ...makeParams(params, ['page', 'rowsPerPage']),
    ...makeDateFilterParams(params),
  },
});

const reactToActivity = data => ({
  type: NEWSFEED_REACT_TO_ACTIVITY.REQUEST,
  payload: data,
});

const reactToComment = data => ({
  type: NEWSFEED_REACT_TO_COMMENT.REQUEST,
  payload: data,
});

const deleteReactToActivity = data => ({
  type: NEWSFEED_DELETE_REACT_TO_ACTIVITY.REQUEST,
  payload: data,
});

const deleteReactToComment = data => ({
  type: NEWSFEED_DELETE_REACT_TO_COMMENT.REQUEST,
  payload: data,
});

const getActivityReactions = id => ({
  type: NEWSFEED_GET_ACTIVITY_REACTIONS.REQUEST,
  payload: { id },
});

const getCommentReactions = (id, activityId) => ({
  type: NEWSFEED_GET_COMMENT_REACTIONS.REQUEST,
  payload: { id, activityId },
});

const saveCommentReaction = (id, reaction, activityId) => ({
  type: NEWSFEED_SAVE_COMMENT_REACTION,
  payload: { id, reaction, activityId },
});

const saveActivityReaction = (id, reaction) => ({
  type: NEWSFEED_SAVE_ACTIVITY_REACTION,
  payload: { id, reaction },
});

const apiActivityHistoryDetailsRequest = id => ({
  type: ACTIVITY_HISTORY_DETAILS.REQUEST,
  payload: id,
});

const apiActivityHistoryDeleteRequest = id => ({
  type: ACTIVITY_HISTORY_DELETE.REQUEST,
  payload: id,
});

const editActivityFromAngular = activity => ({
  type: NEWSFEED_EDIT_ACTIVITY_FROM_ANGULAR,
  payload: activity,
});

const promoteActivity = id => ({
  type: NEWSFEED_PROMOTE_ACTIVITY.REQUEST,
  payload: id,
});

const taggedActivities = tag => ({
  type: NEWSFEED_TAGGED_ACTIVITIES.REQUEST,
  payload: tag,
});

const openTaggedActivities = tag => ({
  type: NEWSFEED_OPEN_TAGGED_ACTIVITIES,
  payload: tag,
});

const closeTaggedActivities = () => ({
  type: NEWSFEED_CLOSE_TAGGED_ACTIVITIES,
  payload: {},
});

export const actions = {
  apiActivityHistoryIndexRequest,
  apiActivityHistoryDetailsRequest,
  apiActivityHistoryDeleteRequest,
  getActivityRespects,
  activityIndexRequest,
  activityAcknowledgePin,
  createActivity,
  updateActivity,
  editActivity,
  editActivityDone,
  editActivityCancel,
  createComment,
  reloadComments,
  loadActivity,
  deleteActivity,
  reportActivity,
  toggleActivityRespect,
  saveCommentAndUpdateActivity,
  deleteCommentAndUpdateActivity,
  acknowledgePinAndUpdateActivity,
  toggleRespectAndUpdateActivity,
  toggleActivityPinAndUpdateActivity,
  clearAndReloadActivities,
  clearActivities,
  pinActivity,
  unpinActivity,
  getActivityAcks,
  getActivityComments,
  getActivityViews,
  getSummaryActivities,
  createAndReloadActivity,
  updateAndReloadActivity,
  deleteAndReloadActivities,
  promoteAndUpdateActivity,
  clearAttachedVideos,
  selectAudience,
  editActivityFromAngular,
  deleteReactToComment,
  deleteReactToActivity,
  reactToComment,
  reactToActivity,
  getCommentReactions,
  getActivityReactions,
  saveCommentReaction,
  saveActivityReaction,
  promoteActivity,
  taggedActivities,
  openTaggedActivities,
  closeTaggedActivities,
  ...commentActions,
};

export const types = {
  NEWSFEED_INDEX,
  NEWSFEED_ACK_PIN,
  NEWSFEED_ACTIVITY,
  NEWSFEED_ACTIVITY_RELOAD_COMMENTS,
  NEWSFEED_DELETE_ACTIVITY,
  NEWSFEED_CREATE_ACTIVITY,
  NEWSFEED_UPDATE_ACTIVITY,
  NEWSFEED_EDIT_ACTIVITY,
  NEWSFEED_EDIT_ACTIVITY_DONE,
  NEWSFEED_EDIT_ACTIVITY_CANCEL,
  NEWSFEED_REPORT_ACTIVITY,
  NEWSFEED_GET_ACTIVITY_RESPECTS,
  NEWSFEED_TOGGLE_ACTIVITY_RESPECT,
  NEWSFEED_CREATE_COMMENT,
  NEWSFEED_DELETE_COMMENT,
  NEWSFEED_PIN_ACTIVITY,
  NEWSFEED_UNPIN_ACTIVITY,
  NEWSFEED_GET_ACTIVITY_ACKS,
  NEWSFEED_GET_ACTIVITY_COMMENTS,
  NEWSFEED_GET_ACTIVITY_VIEWS,
  NEWSFEED_CLEAR_ACTIVITIES,
  NEWSFEED_GET_SUMMARY_ACTIVITIES,
  NEWSFEED_CLEAR_AND_RELOAD_ACTIVITIES,
  NEWSFEED_SAVE_COMMENT_AND_UPDATE_ACTIVITY,
  NEWSFEED_DELETE_COMMENT_AND_UPDATE_ACTIVITY,
  NEWSFEED_ACKNOWLEDGE_PIN_AND_UPDATE_ACTIVITY,
  NEWSFEED_TOGGLE_RESPECT_AND_UPDATE_ACTIVITY,
  NEWSFEED_TOGGLE_ACTIVITY_PIN_AND_UPDATE_ACTIVITY,
  NEWSFEED_CREATE_AND_RELOAD_ACTIVITY,
  NEWSFEED_UPDATE_AND_RELOAD_ACTIVITY,
  NEWSFEED_DELETE_AND_RELOAD_ACTIVITIES,
  NEWSFEED_CLEAR_ATTACHED_VIDEOS,
  NEWSFEED_SELECT_AUDIENCE,
  ACTIVITY_HISTORY_INDEX,
  ACTIVITY_HISTORY_DETAILS,
  ACTIVITY_HISTORY_DELETE,
  NEWSFEED_EDIT_ACTIVITY_FROM_ANGULAR,
  NEWSFEED_DELETE_REACT_TO_COMMENT,
  NEWSFEED_REACT_TO_COMMENT,
  NEWSFEED_DELETE_REACT_TO_ACTIVITY,
  NEWSFEED_REACT_TO_ACTIVITY,
  NEWSFEED_GET_ACTIVITY_REACTIONS,
  NEWSFEED_GET_COMMENT_REACTIONS,
  NEWSFEED_SAVE_COMMENT_REACTION,
  NEWSFEED_SAVE_ACTIVITY_REACTION,
  NEWSFEED_PROMOTE_AND_RELOAD_ACTIVITY,
  NEWSFEED_PROMOTE_ACTIVITY,
  NEWSFEED_TAGGED_ACTIVITIES,
  NEWSFEED_OPEN_TAGGED_ACTIVITIES,
  NEWSFEED_CLOSE_TAGGED_ACTIVITIES,
};
