import React from 'react';
import { throttle } from 'throttle-debounce';
import Typography from '@material-ui/core/Typography';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import ArcToggle from '../../elements/ArcToggle';
import ArcTextField from '../../elements/ArcTextField';
import { createWithStyles } from '../../styles';

import ArcPeopleList from '../ArcPeopleList';

const styles = {
  HeadingText: theme => ({
    root: {
      fontWeight: '300',
      marginRight: theme.spacing(1),
    },
  }),

  ListContainer: () => ({
    root: {
      marginBottom: 48,
    },
  }),

  SearchField: theme => ({
    root: {
      margin: theme.spacing(1),
    },
  }),
};

const HeadingText = createWithStyles(styles.HeadingText)(Typography);
const ListContainer = createWithStyles(styles.ListContainer)(ArcView);
const SearchField = createWithStyles(styles.SearchField)(ArcTextField);

const TYPES = {
  BOTH: 'both',
  PEOPLE: 'people',
  TEAMS: 'teams',
  PEOPLE_BY_TEAM: 'peopleByTeam',
};

class ArcPeopleSelector extends React.PureComponent {
  static propTypes = {
    onChange: ArcPropTypes.func,
    peopleById: ArcPropTypes.objectOf(ArcPropTypes.any),
    availablePeople: ArcPropTypes.arrayOf(ArcPropTypes.object),
    availableTeams: ArcPropTypes.arrayOf(ArcPropTypes.object),
    availableTeamPeople: ArcPropTypes.objectOf(
      ArcPropTypes.arrayOf(ArcPropTypes.object),
    ),
    gameTypeEmitter: ArcPropTypes.emitter,
    selectedPeople: ArcPropTypes.arrayOf(ArcPropTypes.string),
    selectedTeams: ArcPropTypes.arrayOf(ArcPropTypes.string),
    teamsById: ArcPropTypes.objectOf(ArcPropTypes.any),
    type: ArcPropTypes.oneOf(Object.values(TYPES)),
    isFetching: ArcPropTypes.bool,
  };

  static defaultProps = {
    onChange: ArcPropTypes.noop,
    peopleById: {},
    availablePeople: [],
    availableTeams: [],
    availableTeamPeople: {},
    gameTypeEmitter: ArcPropTypes.nullEmitter,
    selectedPeople: [],
    selectedTeams: [],
    teamsById: {},
    type: TYPES.BOTH,
    isFetching: false,
  };

  static TYPES = TYPES;

  constructor(props) {
    super(props);

    this.peopleIdsByTeamId = {};
    this.teamIdByPersonId = {};

    const { availableTeams } = props;

    availableTeams.forEach(team => {
      if (typeof team !== 'undefined') {
        const peopleIds = team.peopleIds || [];
        this.peopleIdsByTeamId[team.id] = peopleIds;

        peopleIds.forEach(id => {
          this.teamIdByPersonId[id] = team.id;
        });
      }
    });

    this.props.gameTypeEmitter.on('resetSearchTerm', () =>
      this.setSearchTerm('', this.updateListThrottled),
    );

    this.state = {
      selectedPeople: this.props.selectedPeople || [],
      selectedTeams: this.selectedTeamList,
      individualSelectType: 'by name',
      searchTerm: '',
      people: this.props.availablePeople,
      teams: this.props.availableTeams,
    };
  }

  componentWillReceiveProps(nextProps) {
    const availablePeopleChanged =
      this.props.availablePeople !== nextProps.availablePeople;
    const availableTeamsChanged =
      this.props.availableTeams !== nextProps.availableTeams;

    if (availablePeopleChanged) {
      this.setState({
        people: nextProps.availablePeople,
      });
    }

    if (availableTeamsChanged) {
      this.setState({
        teams: nextProps.availableTeams,
      });
    }
  }

  componentWillUnmount() {
    this.props.gameTypeEmitter.off('resetSearchTerm');
  }

  get selectedTeamList() {
    if (this.props.selectedPeople.length >= 1) {
      return this.props.selectedTeams && this.props.selectedTeams.length === 0
        ? this.getSelectedTeamsFromPeopleIds(this.props.selectedPeople)
        : this.props.selectedTeams;
    }
    return [];
  }

  getSelectedTeamsFromPeopleIds(peopleIds) {
    const selectedPeopleByTeamIds = {};

    peopleIds.forEach(id => {
      const teamId = this.teamIdByPersonId[id];
      selectedPeopleByTeamIds[teamId] = selectedPeopleByTeamIds[teamId] || [];
      selectedPeopleByTeamIds[teamId].push(id);
    });

    const selectedTeams = [];

    Object.keys(selectedPeopleByTeamIds).forEach(key => {
      const selectedPeople = selectedPeopleByTeamIds[key];
      const teamPeopleIds = this.peopleIdsByTeamId[key];

      if (
        selectedPeople &&
        teamPeopleIds &&
        selectedPeople.length === teamPeopleIds.length
      ) {
        selectedTeams.push(key);
      }
    });

    return selectedTeams;
  }

  setPeople = people => this.setState({ people });

  setTeams = teams => this.setState({ teams });

  setSelectedPeople = selectedPeople => {
    const selectedTeams = this.getSelectedTeamsFromPeopleIds(selectedPeople);

    this.setState(
      {
        selectedPeople,
        selectedTeams: selectedTeams.unique(),
      },
      this.handleChange,
    );
  };

  setSelectedTeams = (selectedTeams, teamId, checked) => {
    const selectAllPeople = selectedTeams
      .map(team => this.peopleIdsByTeamId[team])
      .flat(1);

    let selectedPeople =
      teamId === 'all' ? selectAllPeople : this.state.selectedPeople;

    if (checked) {
      const nextSelectedPeople =
        teamId === 'all'
          ? selectedPeople
          : this.peopleIdsByTeamId[teamId] || [];

      selectedPeople = [...selectedPeople, ...nextSelectedPeople];
    } else {
      selectedPeople = selectedPeople.filter(personId => {
        if (!this.peopleIdsByTeamId[teamId]) {
          return false;
        }

        return !this.peopleIdsByTeamId[teamId].includes(personId);
      });
    }

    this.setState(
      {
        selectedPeople: selectedPeople.unique(),
        selectedTeams,
      },
      this.handleChange,
    );
  };

  setIndividualSelectType = label => {
    this.setState({
      individualSelectType: label,
    });
  };

  setSearchTerm = (searchTerm, cb) => {
    this.setState({ searchTerm }, cb);
  };

  handleChange = () =>
    this.props.onChange({
      peopleIds: this.state.selectedPeople,
      teamIds: this.state.selectedTeams,
    });

  handleChangePeople = (event, ids, id, checked) => {
    this.setSelectedPeople(ids, id, checked);
  };

  handleChangeTeams = (event, ids, id, checked) => {
    this.setSelectedTeams(ids, id, checked);
  };

  handleChangePeopleByTeam = (event, label) => {
    this.setIndividualSelectType(label);
    this.setSearchTerm('', this.updateListThrottled);
  };

  handleSearchTermChange = event => {
    this.setSearchTerm(event.target.value, this.updateListThrottled);
  };

  filterPeopleBySearchTerm = searchTerm => {
    const availablePeopleList = this.props.availablePeople.filter(Boolean);

    return availablePeopleList.filter(person =>
      this.containsSearchTerm(person, searchTerm),
    );
  };

  filterTeamsBySearchTerm = searchTerm => {
    const availableTeamsList = this.props.availableTeams.filter(Boolean);

    return availableTeamsList.filter(team =>
      this.containsSearchTerm(team, searchTerm),
    );
  };

  containsSearchTerm = (personOrTeam, searchTerm) =>
    personOrTeam.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;

  updateListThrottled = throttle(300, () => {
    const updatedPeopleList = this.filterPeopleBySearchTerm(
      this.state.searchTerm,
    );
    let updatedTeamList = [];

    if (
      this.state.individualSelectType === 'by team' ||
      this.props.type === 'teams'
    ) {
      updatedTeamList = this.filterTeamsBySearchTerm(this.state.searchTerm);
    }

    this.setPeople(updatedPeopleList);
    this.setTeams(updatedTeamList);
  });

  renderPeople() {
    const { people, teams } = this.state;

    const peopleByTeam = {};
    teams.forEach(team => {
      peopleByTeam[team.id] = this.props.availableTeamPeople[team.id];
    });

    const searchFieldPlaceholder =
      this.state.individualSelectType === 'by name'
        ? 'Search People'
        : 'Search Teams';

    return (
      <ListContainer>
        <ArcView row align="center" justify="space-between">
          <HeadingText variant="h5">{'Individuals'}</HeadingText>
          <ArcToggle
            label={this.state.individualSelectType}
            onChange={this.handleChangePeopleByTeam}
            marginLeft
          />
          <SearchField
            placeholder={searchFieldPlaceholder}
            value={this.state.searchTerm}
            onChange={this.handleSearchTermChange}
          />
        </ArcView>
        {this.state.individualSelectType === 'by name' ? (
          <ArcPeopleList
            isSelectable
            onChangePeople={this.handleChangePeople}
            people={people}
            teams={teams}
            type={ArcPeopleList.TYPES.PEOPLE}
            selectedPeople={this.state.selectedPeople}
            selectedTeams={this.state.selectedTeams}
            isFetching={this.props.isFetching}
          />
        ) : (
          <ArcPeopleList
            isSelectable
            onChangePeople={this.handleChangePeople}
            onChangeTeams={this.handleChangeTeams}
            people={people}
            teams={teams}
            peopleByTeam={peopleByTeam}
            peopleById={this.props.peopleById}
            teamsById={this.props.teamsById}
            type={ArcPeopleList.TYPES.PEOPLE_BY_TEAM}
            selectedPeople={this.state.selectedPeople}
            selectedTeams={this.state.selectedTeams}
            showSelectAll={false}
            isFetching={this.props.isFetching}
          />
        )}
      </ListContainer>
    );
  }

  renderTeams() {
    const { people, teams } = this.state;

    return (
      <ListContainer>
        <ArcView row align="center" justify="space-between">
          <HeadingText variant="h5">{'Teams'}</HeadingText>
          <SearchField
            placeholder="Search Teams"
            value={this.state.searchTerm}
            onChange={this.handleSearchTermChange}
          />
        </ArcView>

        <ArcPeopleList
          isSelectable
          onChangeTeams={this.handleChangeTeams}
          people={people}
          selectedPeople={this.state.selectedPeople}
          selectedTeams={this.state.selectedTeams}
          teams={teams}
          type={ArcPeopleList.TYPES.TEAMS}
          isFetching={this.props.isFetching}
        />
      </ListContainer>
    );
  }

  renderBoth() {
    return (
      <React.Fragment>
        {this.renderPeople()}
        {this.renderTeams()}
      </React.Fragment>
    );
  }

  renderByType() {
    switch (this.props.type) {
      case TYPES.TEAMS:
        return this.renderTeams();
      case TYPES.PEOPLE:
        return this.renderPeople();
      case TYPES.BOTH:
      default:
        return this.renderBoth();
    }
  }

  render() {
    return <ArcView>{this.renderByType()}</ArcView>;
  }
}

export default ArcPeopleSelector;
