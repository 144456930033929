import {
  makeParams,
  makeDateFilterParams,
  makePaginationParams,
} from 'arcade-frontend-core/src/actions/helpers';

import * as types from './types';

export const apiManageSalesIndexRequest = params => {
  return {
    type: types.MANAGE_SALES_INDEX.REQUEST,
    payload: {
      ...makeParams(params, [
        'integrationRunId',
        'metricId',
        'metric_id',
        'user',
      ]),
      ...makePaginationParams(params),
      ...makeDateFilterParams(params),
    },
  };
};

export const apiManageSalesCreateRequest = sale => ({
  type: types.MANAGE_SALES_CREATE.REQUEST,
  payload: {
    sale,
  },
});

export const apiManageSalesDeleteRequest = sale => ({
  type: types.MANAGE_SALES_DELETE.REQUEST,
  payload: {
    sale,
  },
});

export const apiManageSalesUpdateRequest = sale => ({
  type: types.MANAGE_SALES_UPDATE.REQUEST,
  payload: {
    sale,
  },
});

export const apiManageSaleMetricsIndexRequest = () => ({
  type: types.MANAGE_SALE_METRICS_INDEX.REQUEST,
  payload: {},
});

export const apiManageSaleTeamsIndexRequest = () => ({
  type: types.MANAGE_SALE_TEAMS_INDEX.REQUEST,
  payload: {},
});

export const apiManageSaleUsersIndexRequest = () => ({
  type: types.MANAGE_SALE_USERS_INDEX.REQUEST,
  payload: {},
});
