import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ChevronRight from '@material-ui/icons/ChevronRight';

import { useTheme } from '@material-ui/styles';
import ArcButton from '../../elements/ArcButton';
import ArcLink from '../../elements/ArcLink';

import AlertDiamond from '../../icons/AlertDiamond';

const ERROR_TYPES = {
  FEATURE_NOT_AVAILABLE: 'feature_not_available',
  GEOFENCED: 'geofenced',
  INSUFFICIENT_FUNDS: 'insufficient_funds',
  INVALID_AUTH: 'invalid_auth',
  NO_PERMISSION: 'no_permission',
};

export function getMessageByErrorType(errorCode) {
  switch (errorCode) {
    case ERROR_TYPES.FEATURE_NOT_AVAILABLE:
      return "We're sorry, this feature is not available for you yet.";
    case ERROR_TYPES.INSUFFICIENT_FUNDS:
      return "We're sorry, you dont have enough in your budget.";
    case ERROR_TYPES.INVALID_AUTH:
      return "We're sorry, that is incorrect.";
    case ERROR_TYPES.NO_PERMISSION:
      return "We're sorry, you're not allowed to do that.";
    default:
      return 'Sorry! Something went wrong.';
  }
}

const STRINGS = {
  'UI/RESOURCE_ERROR_DIALOG_TITLE': 'Error',
  'UI/RESOURCE_ERROR_DIALOG_DETAILS': 'Error Details',
  'UI/RESOURCE_ERROR_DIALOG_NEED_HELP': 'Need help?',
  'UI/RESOURCE_ERROR_DIALOG_CONTACT_US': 'Contact Us',
  'UI/RESOURCE_ERROR_DIALOG_OK': 'Ok',
};

const paperProps = {
  style: {
    minHeight: 338,
    maxWidth: 338,
  },
};

const errorDetailsLinkStyle = {
  cursor: 'pointer',
};

const BASE_SUPPORT_URL = 'https://workplacearcade.com/support/request';

function ArcResourceErrorDialog({
  actionChildren,
  children,
  entityId,
  error,
  onClose,
  open,
  userId,
}) {
  const theme = useTheme();

  const [errorIn, setErrorIn] = React.useState(false);

  function handleClickError() {
    setErrorIn(!errorIn);
  }

  if (!error || !error.data || !error.data.response) {
    return null;
  }

  const {
    status,
    statusText,
    data,
  } = error.data.response;

  const errorType = data && data.error ? data.error : null;

  const message = getMessageByErrorType(errorType);

  const supportHref = entityId && userId
    ? `${BASE_SUPPORT_URL}?eid=${entityId}&uid=${userId}`
    : BASE_SUPPORT_URL;

  return (

    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="arc-resource-error-dialog-title"
      aria-describedby="arc-resource-error-dialog-description"
      PaperProps={paperProps}
    >
      <DialogTitle id="arc-resource-error-dialog-title">
        <Box
          display="flex"
          alignItems="center"
        >
          <Box
            alignSelf="center"
            display="flex"
            mr={1}
          >
            <AlertDiamond />
          </Box>
          {STRINGS['UI/RESOURCE_ERROR_DIALOG_TITLE']}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          clone
          pt={6}
          fontSize={18}
        >
          <DialogContentText
            id="arc-resource-error-dialog-description"
            tabIndex={-1}
            color="textPrimary"
          >
            {message}
          </DialogContentText>
        </Box>

        {children}

        <Box
          color="text.secondary"
          pb={3}
          fontSize={14}
        >
          {STRINGS['UI/RESOURCE_ERROR_DIALOG_NEED_HELP']}
          {' '}
          <ArcLink
            target="_blank"
            size="inherit"
            href={supportHref}
          >
            {STRINGS['UI/RESOURCE_ERROR_DIALOG_CONTACT_US']}
          </ArcLink>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          color="text.secondary"
        >
          <Box
            onClick={handleClickError}
            display="flex"
            alignItems="center"
            style={errorDetailsLinkStyle}
            py={0.25}
            fontSize={12}
          >
            <ChevronRight
              color="inherit"
              style={{
                transition: theme.transitions.create('transform'),
                transform: errorIn ? 'rotate(90deg)' : 'rotate(0)',
              }}
            />
            <Box>
              {STRINGS['UI/RESOURCE_ERROR_DIALOG_DETAILS']}
            </Box>
          </Box>
          <Collapse in={errorIn}>
            <Box
              pl={3}
              fontSize={10}
            >
              {!!errorType && (
                <Box mb={0.5}>
                  {errorType}
                </Box>
              )}
              <Box>{`${status} ${statusText}`}</Box>
            </Box>
          </Collapse>
        </Box>
      </DialogContent>
      <DialogActions>
        {actionChildren}
        <ArcButton
          onClick={onClose}
          variant="outlined"
        >
          {STRINGS['UI/RESOURCE_ERROR_DIALOG_OK']}
        </ArcButton>
      </DialogActions>
    </Dialog>
  );
}

ArcResourceErrorDialog.displayName = 'ArcResourceErrorDialog';

ArcResourceErrorDialog.ERROR_TYPES = ERROR_TYPES;

ArcResourceErrorDialog.propTypes = {
  actionChildren: PropTypes.node,
  children: PropTypes.node,
  entityId: PropTypes.string,
  error: PropTypes.shape({
    data: PropTypes.shape({
      response: PropTypes.shape({
        data: PropTypes.shape({
          error: PropTypes.string,
        }),
        status: PropTypes.number,
        statusText: PropTypes.string,
      }),
    }),
  }),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  userId: PropTypes.string,
};

ArcResourceErrorDialog.defaultProps = {
  actionChildren: null,
  children: null,
  entityId: '',
  error: undefined,
  onClose: global.noop,
  open: false,
  userId: '',
};

export default ArcResourceErrorDialog;
