import { PLATFORMS, getArcPlatform } from './platform';

export const breakpointWidths = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1440,
  xxl: 1920,
};

const breakpointTitles = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

export const getBreakpointTitleByWidth = (width) => {
  let breakpointTitle;
  breakpointTitles.forEach((title) => {
    if (!breakpointTitle && (width < breakpointWidths[title] && width < breakpointWidths.xl)) {
      breakpointTitle = title;
    }
  });

  if (!breakpointTitle) {
    if (width > breakpointWidths.xl && width < breakpointWidths.xxl) {
      breakpointTitle = 'xl';
    } else if (width >= breakpointWidths.xxl) {
      breakpointTitle = 'xxl';
    }
  }

  return breakpointTitle;
};

export const breakpoints = (size) => {
  switch (size) {
    // Phone
    case 'xs': return 480;

    // Tablet (sm)
    case 'sm': return 768;

    // Tablet (lg)
    case 'md': return 992;

    // Desktop
    case 'lg': return getArcPlatform() === PLATFORMS.LAUNCH ? 1024 : 1200;

    // Desktop (lg)
    case 'xl': return 1440;

    // Desktop (full HD)
    case 'xxl': return 1920;

    default: return size;
  }
};

export const media = (size, args = {}) => {
  const {
    isMax,
    isCamelCase,
  } = args;

  let bp = breakpoints(size);
  const type = isMax ? 'max' : 'min';

  if (isMax) bp -= 1;

  return isCamelCase
    ? `@media(${type}Width: ${bp}px)`
    : `@media(${type}-width: ${bp}px)`;
};
