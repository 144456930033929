import React from 'react';

import {
  ArcIconButton,
  ArcView,
  ArcImage,
  ArcText,
  createWithStyles,
  ArcPropTypes,

  ActivityIndicator,

  Tooltip,
} from 'arcade-frontend-ui';

import Typography from '@material-ui/core/Typography';
import Archive from '@material-ui/icons/Archive';
import Style from '@material-ui/icons/Style';
import Unarchive from '@material-ui/icons/Unarchive';
import Photo from '@material-ui/icons/Photo';

const styles = {
  RewardItemRow: () => ({
    root: {
      borderBottom: '1px solid #F5F5F5',
      userSelect: 'none',
    },
  }),

  RewardItem: theme => ({
    root: {
      flexGrow: 100,
      fontWeight: '100',
      height: 48,
      paddingLeft: theme.spacing(2),
      width: 'calc(100% - 48px)',
    },

    onClick: {
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme.palette.default[100],
      },
    },
  }),

  RewardItemData: theme => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      flexGrow: 1,
      flexShrink: 1,
      paddingRight: theme.spacing(2),
    },

    padding0: {
      paddingRight: 0,
    },

    grow0: {
      flexGrow: 0,
    },

    grow10: {
      flexGrow: 10,
    },

    grow100: {
      flexGrow: 100,
    },

    overflowHidden: {
      overflow: 'hidden',
    },
  }),

  RewardItemImage: () => ({
    root: {
      width: 24,
      height: 24,
    },
  }),
};

const styleActivityIndicator = {
  width: 24,
  height: 24,
};

const stylePlaceholderImage = {
  opacity: 0.1,
};

const styleFullWidth = {
  width: '100%',
};

const RewardItemRow = createWithStyles(styles.RewardItemRow)(ArcView);
const RewardItem = createWithStyles(styles.RewardItem)(ArcView);
const RewardItemData = createWithStyles(styles.RewardItemData)(ArcView);
const RewardItemImage = createWithStyles(styles.RewardItemImage)(ArcImage);

class ManageRewardsListItem extends React.PureComponent {
  static propTypes = {
    onArchive: ArcPropTypes.func,
    onRestore: ArcPropTypes.func,
    onClick: ArcPropTypes.func,

    variants: ArcPropTypes.arrayOf(ArcPropTypes.shape({
      label: ArcPropTypes.oneOfType([
        ArcPropTypes.number,
        ArcPropTypes.string,
      ]),
      value: ArcPropTypes.number,
    })),

    archived: ArcPropTypes.bool,
    imageUrl: ArcPropTypes.string,
    description: ArcPropTypes.string,
    name: ArcPropTypes.string,
    isRequesting: ArcPropTypes.bool,
  };

  static defaultProps = {
    onArchive: ArcPropTypes.noop,
    onRestore: ArcPropTypes.noop,
    onClick: ArcPropTypes.noop,

    archived: false,
    variants: [],
    imageUrl: null,
    description: null,
    name: null,
    isRequesting: false,
  };

  handleArchive = e => this.props.onArchive(e, this.props);

  handleRestore = e => this.props.onRestore(e, this.props);

  handleClick = e => this.props.onClick(e, this.props);

  renderButton() {
    let icon = this.props.archived ? (<Unarchive />) : (<Archive />);

    if (this.props.isRequesting) {
      icon = (<ActivityIndicator size="small" style={styleActivityIndicator} />);
    }

    return (
      <ArcIconButton
        onClick={this.props.archived ? this.handleRestore : this.handleArchive}
        disabled={this.props.isRequesting}
      >
        {icon}
      </ArcIconButton>
    );
  }

  renderVariantLabel() {
    const { length } = this.props.variants;

    return (
      <Tooltip title={`${length} ${length === 1 ? 'variant' : 'variants'}`} placement="right">
        <Style fontSize="inherit" color="action" />
      </Tooltip>
    );
  }

  render() {
    return (
      <RewardItemRow row>
        <RewardItem row onClick={this.handleClick}>
          <RewardItemData grow="0">
            {this.props.imageUrl ? (
              <RewardItemImage src={this.props.imageUrl} />
            ) : (
              <Photo style={stylePlaceholderImage} />
            )}
          </RewardItemData>

          <RewardItemData overflow="hidden">
            <ArcView style={styleFullWidth}>
              <ArcView row align="center">
                <ArcText isSpaced textOverflow="ellipsis">{this.props.name}</ArcText>
                {this.props.variants && !!this.props.variants.length && this.renderVariantLabel()}
              </ArcView>
              {this.props.description && (
                <Typography variant="caption">
                  {this.props.description}
                </Typography>
              )}
            </ArcView>
          </RewardItemData>
        </RewardItem>

        <Tooltip title={this.props.archived ? 'Restore' : 'Archive'} placement="left">
          <RewardItemData grow="0" padding="0">
            {this.renderButton()}
          </RewardItemData>
        </Tooltip>
      </RewardItemRow>
    );
  }
}

export default ManageRewardsListItem;
