import React from 'react';
import { Platform } from 'react-primitives';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView/ArcView';
import { createWithStyles } from '../../styles';
import percentToArcColor from '../../styles/colors/percentToArcColor';


const styles = {
  Container: () => ({
    root: {
      overflow: 'hidden',
      position: 'relative',
      backgroundColor: 'rgba(0,0,0,0.11)',
    },
  }),

  InnerBar: theme => ({
    root: {
      position: 'absolute',
      left: 0,
      height: '100%',
      backgroundColor: theme.palette.red.main,

      ...Platform.select({
        web: {
          backgroundImage: 'linear-gradient(-45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)',
          backgroundSize: '32px 32px',
          transitionDelay: 0,
          transitionProperty: 'width',
          transitionTimingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',
        },
      }),
    },

    animate: {
      ...Platform.select({
        web: {
          animationDelay: '0s',
          animationDirection: 'normal',
          animationDuration: '2s',
          animationFillMode: 'none',
          animationIterationCount: 'infinite',
          animationName: 'move',
          animationPlayState: 'running',
          animationTimingFunction: 'linear',
        },
      }),
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);
const InnerBar = createWithStyles(styles.InnerBar)(ArcView);

class ArcProgress extends React.PureComponent {
  static displayName = 'ArcProgress';

  static propTypes = {
    delay: ArcPropTypes.number,
    duration: ArcPropTypes.number,
    height: ArcPropTypes.number,
    percent: ArcPropTypes.number,
    style: ArcPropTypes.style,
  };

  static defaultProps = {
    delay: 0,
    duration: 1000,
    height: undefined,
    percent: 0,
    style: null,
  };

  render() {
    const {
      delay,
      duration,
      height,
      percent,
      style,
      ...rest
    } = this.props;

    const animate = percent >= 100;

    const styleContainer = {
      height,
      ...style,
    };

    const styleInnerBar = {
      backgroundColor: percentToArcColor(percent),
      height,
      width: `${percent}%`,
      ...Platform.select({
        web: {
          transitionDuration: `${duration}ms`,
        },
      }),
    };

    return (
      <Container
        {...rest}
        className="ArcProgress"
        style={styleContainer}
      >
        <InnerBar
          animate={animate}
          className={animate ? 'animate' : null}
          style={styleInnerBar}
        />
      </Container>
    );
  }
}

export default ArcProgress;
