import React from 'react';
import PropTypes from 'prop-types';

import ArcResponsiveDialog from 'arcade-frontend-ui/src/components/ArcResponsiveDialog';
import ManageGamesVerifyContainer from 'arcade-frontend-games/src/containers/ManageGamesVerifyContainer';

function GamesVerificationDialog({ open, onClose, game }) {
  return (
    <ArcResponsiveDialog open={open} onClose={onClose}>
      {game.id && (
        <ManageGamesVerifyContainer inDialog game={game} onClose={onClose} />
      )}
    </ArcResponsiveDialog>
  );
}

GamesVerificationDialog.displayName = 'GamesVerificationDialog';

GamesVerificationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  game: PropTypes.shape({
    id: PropTypes.string,
  }),
};

GamesVerificationDialog.defaultProps = {
  open: false,
  onClose: global.noop,
  game: {},
};

export default GamesVerificationDialog;
