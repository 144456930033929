import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { makeStyles, createStyles } from '@material-ui/styles';

import images from '../../assets/Emojis/images';

const VARIANTS = {
  DEFAULT: 'default',
  BUBBLE: 'bubble',
};

const useStyles = makeStyles(theme => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    textAlign: 'center',
    userSelect: 'none',
  },

  'root-bubble': {
    backgroundColor: '#fff',
    borderRadius: '50%',
    border: '1px solid #e8e8e8',
  },

  isSelected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: '#ebf5f9',
  },

  image: {
    display: 'block',
    width: '1em',
    height: '1em',
  },
}));

function ArcEmoji({
  className,
  emoji,
  isSelected,
  label,
  variant,
  ...props
}) {
  const classes = useStyles();

  const image = images.find(i => i.emoji === emoji);

  return (
    <span
      role="img"
      aria-label={label}
      {...props}
      className={cx([
        classes.root,
        classes[`root-${variant}`],
        isSelected && classes.isSelected,
        className,
      ])}
    >
      {image ? (
        <img
          alt={label}
          className={cx([
            classes.image,
          ])}
          src={image.image}
        />
      ) : (
        emoji
      )}
    </span>
  );
}

ArcEmoji.propTypes = {
  className: PropTypes.string,
  emoji: PropTypes.string,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

ArcEmoji.defaultProps = {
  className: '',
  emoji: '',
  isSelected: false,
  label: '',
  style: {},
  variant: VARIANTS.DEFAULT,
};

ArcEmoji.displayName = 'ArcEmoji';

ArcEmoji.VARIANTS = VARIANTS;

export default ArcEmoji;
