import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import ArcDeleteDialog from 'arcade-frontend-ui/src/components/ArcDeleteDialog';
import ArcConfirmDialog from 'arcade-frontend-ui/src/components/ArcConfirmDialog';
import {
  getLocationPayload,
  getLocationQuery,
  getLocationType,
} from 'arcade-frontend-features/src/reducers/location';
import {
  getCurrentUser,
  getCurrentUserPermission,
} from 'arcade-frontend-core/src/reducers/user';
import withResourceAction from 'arcade-frontend-core/src/helpers/withResourceAction';
import {
  ROUTE_NEWSFEED_CATEGORY,
  ROUTE_NEWSFEED_SHOW,
} from 'arcade-frontend-core/src/types/routes';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import { actions as quicklinkActions } from 'arcade-frontend-people/src/actions';

import { actions } from '../../actions';
import { selectors } from '../../reducers/activities';
import { selectors as respectsSelectors } from '../../reducers/activity_respects';
import { selectors as viewsSelectors } from '../../reducers/activity_views';
import { selectors as acksSelectors } from '../../reducers/activity_acks';
import { selectors as acknowledgedSelectors } from '../../reducers/acknowledgement';
import { getActivitySets } from '../../reducers/activity_sets';
import { getActivitySetsLoading } from '../../reducers/activity_sets_loading';
import { getRequestStatus } from '../../reducers/requestStatus';
import {
  getReactionsByActivityId,
  getReactionsIsRequestingByActivityId,
} from '../../reducers/activity_reactions';
import {
  getCommentsById,
  getCommentCreateActivityId,
} from '../../reducers/rrComments';
import { resources } from '../../resources/comments';
import {
  UserDialog,
  PinPostDialog,
  ReportPostDialog,
  PromotePostDialog,
} from '../../components/ActivityDialogs';
import ActivityReactionDialog from '../../components/ActivityReactionDialog';
import NewsfeedActivityDialog from '../../components/NewsfeedActivityDialog';
import NewsfeedSummaryDialog from '../../components/NewsfeedSummaryDialog';
import CommentReactionDialog from '../../components/CommentReactionDialog';
import RecipientDialog from '../../components/RecipientDialog';

const ACTIVITY_LABELS_BY_TYPE = {
  SaleActivity: 'Sales',
  BadgeActivity: 'Badge',
  PerformanceActivity: 'Achievement',
  LeaderboardActivity: 'Progress',
  QuestActivity: 'Quest',
};

class NewsfeedActivitiesDialogs extends React.PureComponent {
  static propTypes = {
    acknowledgedById: PropTypes.objectOf(PropTypes.string),
    acknowledgePin: PropTypes.func.isRequired,
    activityAcknowledged: PropTypes.objectOf(PropTypes.array).isRequired,
    activityRespects: PropTypes.objectOf(PropTypes.array).isRequired,
    activitySets: PropTypes.objectOf(PropTypes.array).isRequired,
    activityViews: PropTypes.objectOf(PropTypes.array).isRequired,
    activitySetsLoading: PropTypes.objectOf(PropTypes.string),
    activitiesById: PropTypes.objectOf(PropTypes.object),
    canPin: PropTypes.bool,
    canPromote: PropTypes.bool,
    currentDialog: PropTypes.string,
    commentsById: PropTypes.objectOf(PropTypes.object),
    locationType: PropTypes.string,
    locationPayload: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      newest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      oldest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      type: PropTypes.string,
    }),
    locationQuery: PropTypes.shape({
      activityId: PropTypes.string,
      commentId: PropTypes.string,
      dialog: PropTypes.string,
    }),
    onActivityAcksRequest: PropTypes.func.isRequired,
    onActivityRequest: PropTypes.func.isRequired,
    onActivityRespectsRequest: PropTypes.func.isRequired,
    onActivityViewsRequest: PropTypes.func.isRequired,
    onCommentReactionDialogOpen: PropTypes.func.isRequired,
    onCommentReactionSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onDelete: PropTypes.func.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
    onReport: PropTypes.func.isRequired,
    onPromote: PropTypes.func.isRequired,
    onSummaryRequest: PropTypes.func.isRequired,
    onTogglePin: PropTypes.func.isRequired,
    requestData: PropTypes.func,
    requestStatus: PropTypes.shape({
      NEWSFEED_GET_ACTIVITY_VIEWS: PropTypes.string.isRequired,
      NEWSFEED_GET_ACTIVITY_RESPECTS: PropTypes.string.isRequired,
      NEWSFEED_GET_ACTIVITY_ACKS: PropTypes.string.isRequired,
      NEWSFEED_ACTIVITY: PropTypes.string.isRequired,
    }).isRequired,
    onRespect: PropTypes.func,
    onCommentDelete: PropTypes.func,
    onCommentSave: PropTypes.func,
    onQuicklinkUser: PropTypes.func,
    savingCommentId: PropTypes.string,
    commentParticipants: PropTypes.arrayOf(PropTypes.string),
    user: PropTypes.shape({}),
    reactionsByActivityId: PropTypes.shape({}),
    reactionsIsRequestingByActivityId: PropTypes.shape({}),
    onReactionSelect: PropTypes.func,
  };

  static defaultProps = {
    acknowledgedById: {},
    activitiesById: {},
    canPin: false,
    canPromote: false,
    currentDialog: null,
    commentsById: {},
    locationPayload: {},
    locationQuery: {},
    locationType: '',
    onClose: undefined,
    onRespect: global.noop,
    onCommentDelete: global.noop,
    onCommentSave: global.noop,
    savingCommentId: null,
    commentParticipants: [],
    user: {},
    requestData: global.noop,
    reactionsByActivityId: {},
    reactionsIsRequestingByActivityId: {},
    onReactionSelect: global.noop,
    onQuicklinkUser: global.noop,
    activitySetsLoading: {},
  };

  componentDidMount() {
    this.getData();
    this.hasRequestingActivitySucceeded = false;
    this.hasNoResults = false;
  }

  componentDidUpdate(prevProps) {
    const dialogHasChanged =
      prevProps.currentDialog !== this.props.currentDialog;
    const locationHasChanged =
      prevProps.locationType !== this.props.locationType;
    const locationPayloadIdHasChanged =
      (prevProps.locationPayload && prevProps.locationPayload.id) !==
      (this.props.locationPayload && this.props.locationPayload.id);
    const locationQueryIdHasChanged =
      (prevProps.locationQuery && prevProps.locationQuery.activityId) !==
      (this.props.locationQuery && this.props.locationQuery.activityId);
    const activityHasChanged =
      this.currentActivityId &&
      (locationPayloadIdHasChanged || locationQueryIdHasChanged);
    const correctLocation =
      [ROUTE_NEWSFEED_SHOW, ROUTE_NEWSFEED_CATEGORY].indexOf(
        this.props.locationType,
      ) > -1;

    if (
      (dialogHasChanged || locationHasChanged || activityHasChanged) &&
      correctLocation
    ) {
      this.getData();
    }

    if (prevProps.locationPayload.id !== this.props.locationPayload.id) {
      this.prevActivity = this.props.activitiesById[
        prevProps.locationPayload.id
      ];
    }

    if (
      prevProps.locationQuery.activityId !== this.props.locationQuery.activityId
    ) {
      this.prevActivity = this.props.activitiesById[
        prevProps.locationQuery.activityId
      ];
    }
  }

  get currentActivity() {
    return (
      this.props.activitiesById[this.currentActivityId] ||
      this.prevActivity ||
      {}
    );
  }

  get currentActivityId() {
    const { locationPayload, locationQuery } = this.props;

    if (locationPayload && locationPayload.id) {
      return locationPayload.id.toString();
    }

    if (locationQuery && locationQuery.activityId) {
      return locationQuery.activityId.toString();
    }

    return null;
  }

  get currentCommentId() {
    const { locationQuery } = this.props;

    if (locationQuery && locationQuery.commentId) {
      return locationQuery.commentId.toString();
    }

    return null;
  }

  get isRequestingViews() {
    return this.props.requestStatus.NEWSFEED_GET_ACTIVITY_VIEWS === 'REQUEST';
  }

  get isRequestingRespects() {
    return (
      this.props.requestStatus.NEWSFEED_GET_ACTIVITY_RESPECTS === 'REQUEST'
    );
  }

  get isRequestingAcks() {
    return (
      this.props.requestStatus.NEWSFEED_GET_ACTIVITY_RESPECTS === 'REQUEST'
    );
  }

  get locationPayload() {
    return this.props.locationPayload || {};
  }

  get summaries() {
    const { type, oldest, newest } = this.summaryPayload;

    if (!type || !oldest || !newest) {
      return [];
    }

    const encodedKey = `${type}-${oldest.valueOf()}-${newest.valueOf()}`;

    return this.props.activitySets[encodedKey] &&
      this.props.activitySets[encodedKey].length
      ? this.props.activitySets[encodedKey].map(
          id => this.props.activitiesById[id],
        )
      : [];
  }

  get summariesLoading() {
    const { type, oldest, newest } = this.summaryPayload;

    if (!type || !oldest || !newest) {
      return false;
    }

    const encodedKey = `${type}-${oldest.valueOf()}-${newest.valueOf()}`;

    return this.props.activitySetsLoading[encodedKey];
  }

  get summaryTitle() {
    const { type } = this.summaryPayload;
    if (!type) return null;
    return `${ACTIVITY_LABELS_BY_TYPE[type]} Activities`;
  }

  get recipients() {
    return (
      (this.currentActivity.additionalInformation &&
        this.currentActivity.additionalInformation.rewardedUsers) ||
      []
    );
  }

  get summaryPayload() {
    const { type, oldest, newest } = this.locationPayload;

    return {
      type,
      oldest: oldest ? moment(parseInt(oldest, 10)) : null,
      newest: newest ? moment(parseInt(newest, 10)) : null,
    };
  }

  getData() {
    const { currentActivityId } = this;

    if (
      currentActivityId &&
      !this.props.activitiesById[currentActivityId] &&
      this.props.locationType === ROUTE_NEWSFEED_SHOW
    ) {
      this.props.onActivityRequest({ id: currentActivityId });
    }

    const shouldFetchSummary =
      this.props.locationType === ROUTE_NEWSFEED_CATEGORY &&
      !this.summaries.filter(f => f).length;

    if (shouldFetchSummary) {
      this.props.onSummaryRequest(this.summaryPayload);
    }
  }

  handleCommentSave = (activityId, comment) => {
    this.props.requestData({
      resources: [comment],
      list: activityId,
      requestProperties: { activityId },
    });
  };

  handleDelete = () => {
    this.props.onClose('delete');
    this.props.onDelete({
      id: this.currentActivityId,
    });
  };

  handlePromote = () => {
    this.props.onClose('promote');
    this.props.onPromote({
      id: this.currentActivityId,
    });
  };

  handleReport = () =>
    this.props.onReport({
      id: this.currentActivityId,
    });

  handlePin = expires =>
    this.props.onTogglePin({
      id: this.currentActivityId,
      expires,
      pin: true,
    });

  handleUnpin = () => {
    this.props.onClose('unpin');
    this.props.onTogglePin({
      id: this.currentActivityId,
      pin: false,
    });
  };

  getComment = id => {
    console.log('getting comment', id);
    // this.props.requestData({ resources: [{ id }] });
  };

  render() {
    const { locationQuery, onClose, commentsById } = this.props;

    const { dialog } = locationQuery;

    const currentComment = commentsById[this.currentCommentId];
    const commentReactions = currentComment ? currentComment.reactions : [];

    return (
      <React.Fragment>
        <NewsfeedActivityDialog
          open={
            !!this.currentActivityId &&
            this.props.locationType === ROUTE_NEWSFEED_SHOW
          }
          // open={true || this.props.locationType === ROUTE_NEWSFEED_SHOW}
          acknowledgedById={this.props.acknowledgedById}
          acknowledgePin={this.props.acknowledgePin}
          activity={this.currentActivity}
          canPin={this.props.canPin}
          canPromote={this.props.canPromote}
          onActivityAcksRequest={this.props.onActivityAcksRequest}
          onActivityRespectsRequest={this.props.onActivityRespectsRequest}
          onActivityViewsRequest={this.props.onActivityViewsRequest}
          onCommentSave={this.handleCommentSave}
          onCommentDelete={this.props.onCommentDelete}
          onCommentReactionDialogOpen={this.props.onCommentReactionDialogOpen}
          onCommentReactionSelect={this.props.onCommentReactionSelect}
          onDialogOpen={this.props.onDialogOpen}
          onRespect={this.props.onRespect}
          commentParticipants={this.props.commentParticipants}
          savingCommentId={this.props.savingCommentId}
          onReactionSelect={this.props.onReactionSelect}
          reactionsByActivityId={this.props.reactionsByActivityId}
          reactionsIsRequestingByActivityId={
            this.props.reactionsIsRequestingByActivityId
          }
          requestStatus={this.props.requestStatus}
          onClose={() => onClose('activity')}
          currentUser={this.props.user}
        />

        <NewsfeedSummaryDialog
          title={this.summaryTitle}
          open={this.props.locationType === ROUTE_NEWSFEED_CATEGORY}
          summaries={this.summaries}
          isLoading={this.summariesLoading}
          onRespect={this.props.onRespect}
          onReactionSelect={this.props.onReactionSelect}
          onCommentSave={this.props.onCommentSave}
          onCommentDelete={this.props.onCommentDelete}
          commentParticipants={this.props.commentParticipants}
          savingCommentId={this.props.savingCommentId}
          reactionsByActivityId={this.props.reactionsByActivityId}
          reactionsIsRequestingByActivityId={
            this.props.reactionsIsRequestingByActivityId
          }
          currentUser={this.props.user}
          onQuicklinkUser={this.props.onQuicklinkUser}
          onClose={() => onClose('summary')}
        />

        <CommentReactionDialog
          commentId={this.currentCommentId}
          open={dialog === 'comment-reactions'}
          onClose={() => onClose('comment-reactions')}
          commentReactions={commentReactions}
          getCommentReactions={this.getComment}
        />

        <PromotePostDialog
          open={dialog === 'promote-post'}
          onClose={() => onClose('promote-post')}
          title="Share Post"
          onConfirm={this.handlePromote}
        />

        <RecipientDialog
          open={dialog === 'recipients'}
          onClose={() => onClose('recipients')}
          onQuicklinkUser={this.props.onQuicklinkUser}
          recipients={this.recipients}
        />

        <UserDialog
          open={dialog === 'respecters'}
          onClose={() => onClose('respecters')}
          users={this.props.activityRespects[this.currentActivityId]}
          verb="respected"
          title="Respects"
          isRequesting={this.isRequestingRespects}
          onQuicklinkUser={this.props.onQuicklinkUser}
        />

        <UserDialog
          open={dialog === 'who-viewed'}
          onClose={() => onClose('who-viewed')}
          users={this.props.activityViews[this.currentActivityId]}
          verb="viewed"
          title="Views"
          isRequesting={this.isRequestingViews}
          onQuicklinkUser={this.props.onQuicklinkUser}
        />

        <UserDialog
          open={dialog === 'who-acked'}
          onClose={() => onClose('who-acked')}
          users={this.props.activityAcknowledged[this.currentActivityId]}
          verb="acknowledged"
          title="Acknowledgements"
          isRequesting={this.isRequestingAcks}
          onQuicklinkUser={this.props.onQuicklinkUser}
        />

        <PinPostDialog
          open={dialog === 'pin-post'}
          onClose={() => onClose('pin-post')}
          onPin={this.handlePin}
          audienceName={this.currentActivity.audienceName}
        />

        <ReportPostDialog
          open={dialog === 'report-post'}
          onClose={() => onClose('report-post')}
          onConfirm={this.handleReport}
        />

        <ArcDeleteDialog
          open={dialog === 'delete-post'}
          onClose={() => onClose('delete-post')}
          onDelete={this.handleDelete}
        />

        <ArcConfirmDialog
          open={dialog === 'unpin-post'}
          onClose={() => onClose('unpin-post')}
          confirmLabel="Unpin"
          content="Unpinning this post will remove the pin for all users"
          onConfirm={this.handleUnpin}
        />

        <ActivityReactionDialog
          open={dialog === 'reactions'}
          onClose={() => onClose('reactions')}
          reactions={this.currentActivity.reactions}
          onQuicklinkUser={this.props.onQuicklinkUser}
        />
      </React.Fragment>
    );
  }
}

const getState = (state, props) => ({
  acknowledgedById: acknowledgedSelectors.acknowledgedState(state),
  activitiesById: selectors.activitiesById(state),
  activityAcknowledged: acksSelectors.acks(state),
  activityRespects: respectsSelectors.respects(state),
  activitySets: getActivitySets(state),
  activitySetsLoading: getActivitySetsLoading(state),
  activityViews: viewsSelectors.views(state),
  canPin: getCurrentUserPermission(state, PERMISSIONS.PIN_NEWSFEED_POSTS),
  canPromote: getCurrentUserPermission(
    state,
    PERMISSIONS.PROMOTE_NEWSFEED_POSTS,
  ),
  commentsById: getCommentsById(state),
  locationPayload: getLocationPayload(state),
  locationQuery: getLocationQuery(state),
  locationType: getLocationType(state),
  requestStatus: getRequestStatus(state),
  savingCommentId: getCommentCreateActivityId(state),
  reactionsByActivityId: getReactionsByActivityId(state),
  reactionsIsRequestingByActivityId: getReactionsIsRequestingByActivityId(
    state,
  ),
  user: getCurrentUser(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      acknowledgePin: actions.acknowledgePinAndUpdateActivity,
      getComment: actions.getComment,
      onActivityAcksRequest: actions.getActivityAcks,
      onActivityRequest: actions.loadActivity,
      onActivityRespectsRequest: actions.getActivityRespects,
      onActivityViewsRequest: actions.getActivityViews,
      onCommentReactionSelect: actions.saveCommentReaction,
      onDelete: actions.deleteAndReloadActivities,
      onPromote: actions.promoteAndUpdateActivity,
      onReport: actions.reportActivity,
      onSummaryRequest: actions.getSummaryActivities,
      onTogglePin: actions.toggleActivityPinAndUpdateActivity,
      onReactionSelect: actions.saveActivityReaction,
      onRespect: actions.toggleRespectAndUpdateActivity,
      onCommentDelete: actions.deleteCommentAndUpdateActivity,
      onCommentSave: actions.saveCommentAndUpdateActivity,
      onQuicklinkUser: quicklinkActions.showQuicklinksUser,
    },
    dispatch,
  );

export default compose(
  connect(getState, getActions),
  withResourceAction(resources.apiCreateComment),
)(NewsfeedActivitiesDialogs);
