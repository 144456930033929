import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Add from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

import {
  ArcPropTypes,
  ArcView,
  ArcMainView,
  ArcViewTitleBar,
  ArcScroll,
} from 'arcade-frontend-ui';

import { actions, routes } from '../../../actions/manage';
import { routeManageMetricsCreate } from '../../../actions/manage/metrics/routes';

import ManageMetricsDetailsContainer from './ManageMetricsDetailsContainer';
import ManageMetricsViewIndexContainer from './ManageMetricsViewIndexContainer';

const styleFabAddButton = {
  top: 'auto',
  right: 30,
  bottom: 70,
  left: 'auto',
  position: 'fixed',
};

const stylePositionRel = {
  position: 'relative',
  // magic 50px for height of <ArcViewTitleBar>
  // height: 'calc(100% - 50px)',
  height: '100%',
};

const styleWithTab = {
  position: 'relative',
  height: 'calc(100% - 48px)',
};

class ManageMetricsViewContainer extends React.PureComponent {
  static propTypes = {
    currentMetricId: ArcPropTypes.string,
    routeManage: ArcPropTypes.func.isRequired,
    showNew: ArcPropTypes.bool,
    routeManageMetricsCreate: PropTypes.func,
    metricType: PropTypes.string,
    setMetricType: PropTypes.func,
    onDateRangeChange: PropTypes.func,
    onPeopleChange: PropTypes.func,
    dateRange: PropTypes.shape({
      type: PropTypes.oneOf(['time period', 'date range']),
      timePeriod: PropTypes.string,
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
    }),
    people: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    currentMetricId: null,
    showNew: false,
    routeManageMetricsCreate: global.noop,
    metricType: 'active',
    setMetricType: global.noop,
    onDateRangeChange: global.noop,
    onPeopleChange: global.noop,
    dateRange: undefined,
    people: [],
  };

  handleClickCreate = () => this.props.routeManageMetricsCreate();

  renderFab() {
    return (
      <ArcView style={styleFabAddButton}>
        <Fab color="primary" aria-label="add" onClick={this.handleClickCreate}>
          <Add />
        </Fab>
      </ArcView>
    );
  }

  render() {
    const metricsListStyle = this.props.showNew
      ? styleWithTab
      : stylePositionRel;

    return (
      <ArcView flex={1} fullHeight>
        {this.props.currentMetricId !== null && (
          <ArcView zIndex="1000" position="absolute" fillToParent>
            <ManageMetricsDetailsContainer
              dateRange={this.props.dateRange}
              people={this.props.people}
            />
          </ArcView>
        )}

        <ArcMainView
          isViewing
          hasScroll={false}
          style={stylePositionRel}
          hasNoPaddingLeftLaunch
        >
          <ArcView style={metricsListStyle}>
            <ArcViewTitleBar
              onClickBack={this.props.routeManage}
              title="Manage Metrics"
            />
            <ArcScroll>
              <ManageMetricsViewIndexContainer
                metricType={this.props.metricType}
                setMetricType={this.props.setMetricType}
                dateRange={this.props.dateRange}
                onDateRangeChange={this.props.onDateRangeChange}
                onPeopleChange={this.props.onPeopleChange}
              />
            </ArcScroll>
          </ArcView>
          {this.renderFab()}
        </ArcMainView>
      </ArcView>
    );
  }
}

const mapStateToProps = state => ({
  currentMetricId: state.manage.metrics.currentMetricId,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
      ...routes,
      routeManageMetricsCreate,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageMetricsViewContainer);
