import { types } from 'arcade-frontend-core/src/actions';
import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';
import * as auth from 'arcade-frontend-core/src/helpers/auth';
import actionIsValidRoute from 'arcade-frontend-core/src/helpers/actionIsValidRoute';
import { getAppReady } from 'arcade-frontend-core/src/reducers/appReady';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import RollbarClient from '../../../../ui/src/providers/RollbarProvider/client';

export default store => next => (action) => {
  const state = store.getState();

  function handleLogout() {
    console.log('[middleware/authentication] handleLogout', {
      action,
      store,
    });
    RollbarClient.info('[middleware/authentication] handleLogout', {
      action,
      store,
    });

    auth.signOut().then(() => {
      store.dispatch({
        ...state.location,
        type: ROUTE_TYPES.ROUTE_LOGIN,
      });
    });

    return next(action);
  }

  if (action.type === types.LOGOUT) {
    return handleLogout();
  }

  const appReady = getAppReady(state);

  if (appReady && actionIsValidRoute(action)) {
    const currentUser = getCurrentUser(state);

    const isEntryRoute = action.type === ROUTE_TYPES.ROUTE_HOME
      || action.type === ROUTE_TYPES.ROUTE_LOGIN
      || action.type === ROUTE_TYPES.ROUTE_LOGIN_VIA_LINK;

    const shouldLogout = !currentUser.id && !isEntryRoute;

    if (shouldLogout) {
      return handleLogout();
    }
  }

  if (action.type.indexOf('_FAILURE') !== -1) {
    if (action.payload && action.payload.status) {
      switch (action.payload.status) {
        case 401:
          return handleLogout();
        default:
          return next(action);
      }
    }
  }

  return next(action);
};
