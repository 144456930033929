import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { getLocation } from 'arcade-frontend-features/src/reducers/location';
import * as types from 'arcade-frontend-core/src/types/routes';

import { resources } from '../../resources/games';
import { getGame } from '../../reducers/games';
import GamesCompletionDialog from '../../components/GamesCompletionDialog';

const ANIMATION_DELAY = 500;

function GamesCompletionContainer(props) {
  const dispatch = useDispatch();

  let location = useSelector(getLocation);

  if (props.location) {
    ({ location } = props);
  }

  const apiPutGamesEventsComplete = useResourceAction(
    resources.apiPutGamesEventsComplete,
  );
  const apiPostGamesEventsVerifyAll = useResourceAction(
    resources.apiPostGamesEventsVerifyAll,
  );
  const gameId = location.query ? location.query.formGameId : '';

  const handleFailure = () => {
    apiPostGamesEventsVerifyAll.onStatusReset();
    apiPutGamesEventsComplete.onStatusReset();
  };

  const handleSuccess = () => {
    apiPutGamesEventsComplete.onErrorReset();
    apiPostGamesEventsVerifyAll.onErrorReset();

    const routeAction = {
      type: types.ROUTE_GAMES,
      payload: {},
      query: {
        ...location.query,
        form: undefined,
        formGameId: undefined,
      },
    };

    dispatch(routeAction);
  };

  const handleClose = () => {
    apiPutGamesEventsComplete.onErrorReset();
    apiPostGamesEventsVerifyAll.onErrorReset();

    const routeAction = {
      ...location,
      query: {
        ...location.query,
        form: undefined,
        formGameId: undefined,
      },
    };

    dispatch(routeAction);
  };

  const handleComplete = () => {
    apiPutGamesEventsComplete.onErrorReset();
    apiPostGamesEventsVerifyAll.onErrorReset();

    apiPutGamesEventsComplete
      .requestData({
        resources: [{ id: gameId }],
      })
      .then(() => {
        setTimeout(() => {
          handleSuccess();
        }, ANIMATION_DELAY);
      })
      .catch(() => {
        setTimeout(() => {
          apiPutGamesEventsComplete.onStatusReset();
        }, ANIMATION_DELAY);
      });
  };

  const handleVerifyAll = () => {
    apiPostGamesEventsVerifyAll.onErrorReset();
    apiPutGamesEventsComplete.onErrorReset();

    handleFailure();

    apiPostGamesEventsVerifyAll
      .requestData({
        resources: [{ id: gameId }],
      })
      .then(() => {
        apiPutGamesEventsComplete
          .requestData({
            resources: [{ id: gameId }],
          })
          .then(() => {
            handleSuccess();
          })
          .catch(handleFailure);
      })
      .catch(handleFailure);
  };

  const shouldOpen =
    location.query && location.query.form === 'complete-game' && gameId;
  const game = useSelector(getGame(gameId));

  return (
    <GamesCompletionDialog
      open={shouldOpen}
      pendingSalesCount={game ? game.pendingSalesCount : 0}
      onClose={handleClose}
      onComplete={handleComplete}
      onVerifyAll={handleVerifyAll}
      completeStatus={apiPutGamesEventsComplete.status}
      verifyAllStatus={apiPostGamesEventsVerifyAll.status}
      hasError={
        apiPutGamesEventsComplete.hasError ||
        apiPostGamesEventsVerifyAll.hasError
      }
    />
  );
}

GamesCompletionContainer.displayName = 'GamesCompletionContainer';

GamesCompletionContainer.propTypes = {
  location: PropTypes.shape({
    payload: PropTypes.shape({
      id: PropTypes.string,
    }),
    query: PropTypes.shape({
      tab: PropTypes.string,
      form: PropTypes.string,
      formGameId: PropTypes.string,
    }),
  }),
};

GamesCompletionContainer.defaultProps = {
  location: undefined,
};

export default GamesCompletionContainer;
