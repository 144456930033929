import React from 'react';
import moment from 'moment';
import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcStyles from '../../styles';
import ArcText from '../../primitives/ArcText/ArcText';

const style = {
  color: ArcStyles.theme.grey2,
};

class ArcDateText extends React.Component {
  static propTypes = {
    date: ArcPropTypes.string,
  };

  static defaultProps = {
    date: null,
  };

  constructor(props) {
    super(props);
    if (props.date) {
      this.displayDate = this.formatDate(props.date);
    } else {
      this.displayDate = '';
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.date) {
      this.displayDate = this.formatDate(nextProps.date);
    }
  }

  shouldComponentUpdate(nextProps) {
    return this.props.date !== nextProps.date;
  }

  formatDate = date => moment(date, 'ddd MMM DD, YYYY HH:mm:ss').format('ddd MMM D, h:mma').toString();

  render() {
    return (
      <ArcText
        style={style}
      >
        {this.displayDate}
      </ArcText>
    );
  }
}

export default ArcDateText;
