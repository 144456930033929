import React from 'react';
// import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView/ArcView';
import { createWithStyles } from '../../styles';
import ArcPropTypes from '../../helpers/arc/propTypes';

const styles = {
  ItemText: theme => ({
    root: {
      fontSize: 16,
      color: theme.palette.icon.primary,
      fontWeight: '300',
      textTransform: 'capitalize',

      [theme.breakpoints.down('sm')]: {
        '&:active': {
          color: theme.palette.primary.main,
        },
      },

      [theme.breakpoints.up('sm')]: {
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },

    isActive: {
      color: theme.palette.primary.main,
      fontWeight: '500',
    },
  }),

  ItemView: theme => ({
    root: {
      cursor: 'pointer',
      padding: '16px 8px',
      marginRight: 8,

      [theme.breakpoints.down('sm')]: {
        marginRight: 12,
      },

      [theme.breakpoints.down(322)]: {
        marginRight: 9,
      },
    },
  }),
};

const ItemView = createWithStyles(styles.ItemView)(ArcView);
const ItemText = createWithStyles(styles.ItemText)(ArcView);

const ArcCardNavItem = ({ children, isActive, ...props }) => (
  <ItemView {...props}>
    {typeof children === 'string' ? (
      <ItemText isActive={isActive}>
        {children}
      </ItemText>
    ) : children}
  </ItemView>
);

ArcCardNavItem.propTypes = {
  children: ArcPropTypes.children,
  isActive: ArcPropTypes.bool,
};

ArcCardNavItem.defaultProps = {
  children: null,
  isActive: true,
};

export default ArcCardNavItem;
