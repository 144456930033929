import React from 'react';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView/ArcView';

const style = {
  // padding: '0 16px',
  padding: 0,
};

const styleWrap = {
  ...style,
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const ArcViewContent = props => (
  <ArcView
    style={props.wrap
      ? { ...styleWrap, ...props.style }
      : { ...style, ...props.style }
    }
  >
    {props.children}
  </ArcView>
);

ArcViewContent.propTypes = {
  children: ArcPropTypes.children,
  style: ArcPropTypes.style,
  wrap: ArcPropTypes.bool,
};

ArcViewContent.defaultProps = {
  children: undefined,
  style: undefined,
  wrap: false,
};

export default ArcViewContent;
