import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';

import ArcView from '../../primitives/ArcView';
import ArcEntity from '../ArcEntity';


const rootStyle = {
  marginRight: -8,
};

function ArcStackedEntities({
  entities,
  ...props
}) {
  return (
    <ArcView
      row
      wrap="wrap"
      justify="center"
      paddingRight="8"
    >
      {entities.map(({ name, ...entity }) => (
        <Tooltip
          key={entity.id}
          title={name}
        >
          <ArcView
            style={rootStyle}
          >
            <ArcEntity
              padding="0"
              {...props}
              {...entity}
              teamName=""
            />
          </ArcView>
        </Tooltip>
      ))}
    </ArcView>
  );
}

ArcStackedEntities.displayName = 'ArcStackedEntities';

ArcStackedEntities.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
  })),
};

ArcStackedEntities.defaultProps = {
  entities: [],
};

export default ArcStackedEntities;
