import React from 'react';
// import ArcPropTypes from '../../helpers/arc/propTypes';

const RepeatingVine = ({ ...props }) => (
  <svg
    width="51"
    height="24"
    viewBox="0 0 51 24"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <g fillRule="nonzero" fill="none"><path d="M12 9h6v3H6V9H3V6h9v3zM0 3h3v3H0V3z" fill="#4CA600" /><path d="M15 3h3v3h3v3h-9V6H3V0h12v3z" fill="#62D500" /><path d="M39 20h-6v3h12v-3h3v-3h-9v3zm12-6h-3v3h3v-3z" fill="#4CA600" /><path d="M36 14h-3v3h-3v3h9v-3h9v-6H36v3z" fill="#62D500" /><g><path fill="#67B028" d="M21 0h3v24h-3z" /><path fill="#74C82B" d="M24 0h6v24h-6z" /></g>
    </g>
  </svg>
);

RepeatingVine.propTypes = {
};

RepeatingVine.defaultProps = {
};

export default RepeatingVine;
