import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import UndoIcon from '@material-ui/icons/Undo';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import {
  PENDING,
  CANCELLABLE_APPROVAL,
  CANCELLABLE_REJECTION,
  GAME_EVENT_STATUSES,
} from 'arcade-frontend-core/src/types/game-events';

const styles = makeStyles(theme => ({
  iconWrapper: {
    marginRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: theme.palette.grey.tint,
    borderStyle: 'solid',
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
}));

const GamesEventsMenu = ({ open, onClose, onAction, status }) => {
  const classes = styles();

  return (
    <Dialog open={open} onClose={onClose}>
      {status === PENDING && (
        <ArcBox className={classes.menuItem} onClick={() => onAction('reject')}>
          <ArcBox className={classes.iconWrapper} color="warning.main">
            <BlockIcon />
          </ArcBox>
          Reject
        </ArcBox>
      )}
      {status === CANCELLABLE_APPROVAL && (
        <ArcBox className={classes.menuItem} onClick={() => onAction('revert')}>
          <ArcBox className={classes.iconWrapper}>
            <UndoIcon />
          </ArcBox>
          Undo Verification
        </ArcBox>
      )}
      {status === CANCELLABLE_REJECTION && (
        <ArcBox className={classes.menuItem} onClick={() => onAction('revert')}>
          <ArcBox className={classes.iconWrapper}>
            <UndoIcon />
          </ArcBox>
          Undo Reject
        </ArcBox>
      )}
      <ArcBox className={classes.menuItem} onClick={() => onAction('delete')}>
        <ArcBox className={classes.iconWrapper} color="error.main">
          <DeleteIcon />
        </ArcBox>
        Delete Score
      </ArcBox>
    </Dialog>
  );
};

GamesEventsMenu.displayName = 'GamesEventsMenu';

GamesEventsMenu.propTypes = {
  open: PropTypes.bool,
  onAction: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.oneOf(GAME_EVENT_STATUSES),
};

GamesEventsMenu.defaultProps = {
  open: false,
  onAction: global.noop,
  onClose: global.noop,
  status: PENDING,
};

export default GamesEventsMenu;
