import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import { ArcText, date } from 'arcade-frontend-ui';

moment.updateLocale('en', {
  relativeTime: {
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

const useStyles = makeStyles(theme => ({
  tagText: {
    color: '#616264',
    fontSize: theme.font.getFontSize(0.875),
    lineHeight: '150%',
  },

  textGreen: {
    color: '#00a77c',
  },
}));

function GamesCountdownBadge({ endDateTime, labelLeft, labelRight, ...props }) {
  const classes = useStyles();

  const hasTimePassed = date.isAfter(moment.now(), endDateTime);
  const timeFromNow = moment(endDateTime).fromNow(true);

  let displayLabelRight = labelRight;

  if (labelRight === null) {
    displayLabelRight = hasTimePassed ? 'ago' : 'left to go';
  }

  return (
    <ArcBox bgcolor="#F9F9F9" px={2} py={0.5} borderRadius={8} {...props}>
      <ArcBox className={classes.tagText}>
        {timeFromNow !== 'Invalid date' ? (
          <>
            {hasTimePassed && <ArcText marginRight="4">{'Finished'}</ArcText>}
            {!!labelLeft && <ArcText marginRight="4">{labelLeft}</ArcText>}
            <ArcText isStrong className={classes.textGreen}>
              {timeFromNow}
            </ArcText>
            {!!displayLabelRight && (
              <ArcText marginLeft="4">{displayLabelRight}</ArcText>
            )}
          </>
        ) : (
          <ArcText isStrong color="default">
            {'unknown time'}
          </ArcText>
        )}
      </ArcBox>
    </ArcBox>
  );
}

GamesCountdownBadge.displayName = 'GamesCountdownBadge';

GamesCountdownBadge.propTypes = {
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  endDateTime: PropTypes.string,
};

GamesCountdownBadge.defaultProps = {
  labelLeft: null,
  labelRight: null,
  endDateTime: '',
};

export default GamesCountdownBadge;
