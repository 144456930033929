import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { withTheme } from '@material-ui/styles';

import DialogActions from '@material-ui/core/DialogActions';
import IconErrorOutlined from '@material-ui/icons/ErrorOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import IconAdd from '@material-ui/icons/Add';
import IconClose from '@material-ui/icons/Close';
import IconRemove from '@material-ui/icons/Remove';
import IconAttachMoney from '@material-ui/icons/AttachMoney';
import IconCheck from '@material-ui/icons/Check';
import IconEdit from '@material-ui/icons/Edit';

import {
  ArcButton,
  ArcDateTime,
  ArcIconButton,
  ArcDialog,
  ArcLoaderButton,
  ArcFormField,
  ArcForm,
  ArcHeading,
  ArcInternalLink,
  ArcView,
  ArcTextField,
  date,
} from 'arcade-frontend-ui';

import ArcPerson from 'arcade-frontend-ui/src/components/ArcPeopleList/ArcPerson';
import ArcTeam from 'arcade-frontend-ui/src/components/ArcPeopleList/ArcTeam';

import ArcadeHashtag from '../../../assets/ArcadeHashtag';
import ArcadePercentage from '../../../assets/ArcadePercentage';


const paperProps = {
  style: {
    margin: 16,
    width: '100%',
    maxWidth: 360,
  },
};

const styleMenuItem = {
  height: 'auto',
};

const styleIconError = {
  marginRight: 8,
};

const FIRST_METRIC = {
  id: '-1',
  name: 'Select a Metric',
};

const FIRST_PERSON = {
  id: '-1',
  name: 'Select a Person',
};

const FIRST_TEAM = {
  color: '#e8e8e8',
  id: '-1',
  letter: 'NA',
  name: 'Select a Team',
};

const PLACEHOLDER_ITEM = (
  <ArcView row align="center">
    <ArcView
      padding="16"
      className="shimmer"
      marginRight="8"
    />
    <ArcView
      className="shimmer"
      padding="16"
      flexGrow="100"
    />
  </ArcView>
);

const INITIAL_VALUES = {
  createdAt: date.toNextMinuteInterval(new Date(), 1).toISOString(),
  metricId: '-1',
  quantity: '1',
  teamId: '-1',
  userId: '-1',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  createdAt: Yup.date()
    .required('Required')
    .notOneOf(['']),
  metricId: Yup.string()
    .required('Required')
    .notOneOf(['-1']),
  quantity: Yup.number()
    .required('Required')
    .notOneOf([0]),
  teamId: Yup.string()
    .required('Required')
    .notOneOf(['-1']),
  userId: Yup.string()
    .required('Required')
    .notOneOf(['-1']),
});

const getFormattedDate = (value) => {
  if (!value) {
    return '';
  }

  const formattedDate = new Date(value);

  return formattedDate.toLocaleString();
};

class SaleCreateForm extends React.PureComponent {
  static propTypes = {
    initialValues: PropTypes.objectOf(PropTypes.string),
    metrics: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onSubmit: PropTypes.func,
    people: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    })),
    requestErrorMessage: PropTypes.string,
    requestStatus: PropTypes.oneOf([
      'DEFAULT',
      'REQUEST',
      'SUCCESS',
      'FAILURE',
    ]),
    sale: PropTypes.shape({
      id: PropTypes.string,
    }),
    teams: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string,
      id: PropTypes.string.isRequired,
      letter: PropTypes.string,
      name: PropTypes.string.isRequired,
    })),
    theme: PropTypes.shape({
      palette: PropTypes.any,
    }).isRequired,
  };

  static defaultProps = {
    initialValues: INITIAL_VALUES,
    metrics: [],
    open: false,
    onClose: global.noop,
    onDelete: global.noop,
    onSubmit: global.noop,
    people: [],
    requestErrorMessage: '',
    requestStatus: {},
    sale: {},
    teams: [],
  };

  state = {
    createdAtIsEditing: false,
    person: this.props.people.find(p => p.id === this.props.initialValues.userId),
    quantityIsFocused: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.requestStatus !== this.props.requestStatus) {
      if (this.props.requestStatus === 'DEFAULT' && prevProps.requestStatus === 'SUCCESS') {
        this.props.onClose();
      }
    }

    if (prevProps.initialValues !== this.props.initialValues) {
      if (prevProps.initialValues.userId !== this.props.initialValues.userId) {
        const person = this.props.people.find(p => p.id === this.props.initialValues.userId);
        this.setPerson(person);
      }
    }
  }

  get hasMetrics() {
    return !!this.displayableMetrics.length;
  }

  get hasPeople() {
    return !!this.props.people.length;
  }

  get hasTeams() {
    return !!this.displayableTeams.length;
  }

  get isEditing() {
    return !!this.props.sale && !!this.props.sale.id;
  }

  get isRequesting() {
    return this.loadingState === 'REQUEST';
  }

  get isSuccess() {
    return this.loadingState === 'SUCCESS';
  }

  get loadingState() {
    return this.props.requestStatus;
  }

  get metrics() {
    return [FIRST_METRIC, ...this.props.metrics];
  }

  get people() {
    return [FIRST_PERSON, ...this.props.people];
  }

  get teams() {
    return [FIRST_TEAM, ...this.props.teams];
  }

  get displayableTeams() {
    if (this.state.person) {
      const teams = this.teams.filter(t => t.id === this.state.person.teamId);

      return teams.length ? teams : [];
    }

    return this.teams;
  }

  get displayableMetrics() {
    if (this.state.person && this.state.person.metrics) {
      const metrics = this.metrics.filter(metric => this.state.person.metrics.indexOf(metric.id) > -1);

      return metrics.length ? metrics : [];
    }

    return this.metrics;
  }

  setCreatedAtIsEditing = createdAtIsEditing => this.setState({ createdAtIsEditing });

  setMetric = metric => this.setState({ metric });

  setPerson = person => this.setState({ person });

  setQuantityIsFocused = quantityIsFocused => this.setState({ quantityIsFocused });

  handleCreatedAtEdit = () => {
    this.setCreatedAtIsEditing(!this.state.createdAtIsEditing);
  };

  handleCreatedAtFocus = () => {
    this.setCreatedAtIsEditing(true);
  };

  handleLinkClick = () => {
    this.props.onClose();
  };

  handleMetricChange = (event) => {
    const metric = this.props.metrics.find(m => m.id === event.target.value);

    this.setMetric(metric || null);
  };

  handleQuantityBlur = () => this.setQuantityIsFocused(false);

  handleQuantityFocus = () => this.setQuantityIsFocused(true);

  renderMetric = metric => (
    <ArcView>{metric.name}</ArcView>
  );

  renderMetricItem = metric => (
    <MenuItem
      key={metric.id}
      style={styleMenuItem}
      value={metric.id}
    >
      {this.renderMetric(metric)}
    </MenuItem>
  );

  renderMetricItems = () => this.displayableMetrics.map(this.renderMetricItem);

  renderMetricValue = (value) => {
    const metric = this.metrics.find(t => t.id === value);

    if (this.hasMetrics && metric) {
      return this.renderMetric(metric);
    }

    if (this.isRequesting) {
      return PLACEHOLDER_ITEM;
    }

    return (
      <ArcView>
        {'No Metrics available'}
      </ArcView>
    );
  };

  renderPerson = person => (
    <ArcPerson
      name={person.name}
      imageUrl={person.imageUrl}
      style={{
        color: 'inherit',
      }}
    />
  );

  renderPersonItem = person => (
    <MenuItem
      key={person.id}
      style={styleMenuItem}
      value={person.id}
    >
      {this.renderPerson(person)}
    </MenuItem>
  );

  renderPersonItems = () => this.people.map(this.renderPersonItem);

  renderPersonValue = (value) => {
    const person = this.people.find(t => t.id === value);

    if (this.hasPeople && person) {
      return this.renderPerson(person);
    }

    if (this.isRequesting) {
      return PLACEHOLDER_ITEM;
    }

    return (
      <ArcView>
        {'No People available'}
      </ArcView>
    );
  };

  renderTeam = team => (
    <ArcTeam
      id={team.id}
      color={team.color}
      letter={team.letter}
      name={team.name}
      style={{
        color: 'inherit',
      }}
    />
  );

  renderTeamItem = team => (
    <MenuItem
      key={team.id}
      style={styleMenuItem}
      value={team.id}
    >
      {this.renderTeam(team)}
    </MenuItem>
  );

  renderTeamItems = () => this.displayableTeams.map(this.renderTeamItem);

  renderTeamValue = (value) => {
    const team = this.teams.find(t => t.id === value);

    if (this.hasTeams && team) {
      return this.renderTeam(team);
    }

    if (this.isRequesting) {
      return PLACEHOLDER_ITEM;
    }

    return (
      <ArcView>
        {'No Teams available'}
      </ArcView>
    );
  };

  get startAdornment() {
    const { metric, quantityIsFocused } = this.state;

    if (!metric) return null;

    const { palette } = this.props.theme;
    const color = quantityIsFocused ? palette.primary.main : palette.text.disabled;

    switch (metric.unit) {
      case '$':
        return <IconAttachMoney color={quantityIsFocused ? 'primary' : 'disabled'} fontSize="small" />;
      case '#':
        return <ArcadeHashtag style={{ color }} />;
      case '%':
        return <ArcadePercentage style={{ color }} />;

      default:
        return null;
    }
  }

  renderForm = (formikProps) => {
    const { person } = this.state;

    const hasUser = formikProps.values.userId && formikProps.values.userId !== '-1' && !!person;

    const teamIsDisabled = this.isRequesting || !this.hasTeams || !hasUser;

    const teamSelect = (
      <ArcFormField
        name="teamId"
        type="select"
        label="Team"
        disabled={teamIsDisabled}
        fullWidth
        validations={{
          isRequired: true,
        }}
        renderValue={this.renderTeamValue}
      >
        {this.renderTeamItems()}
      </ArcFormField>
    );

    const metricIsDisabled = this.isRequesting || !this.hasMetrics || !hasUser;

    const metricSelect = (
      <ArcFormField
        name="metricId"
        type="select"
        label="Metric"
        disabled={metricIsDisabled}
        fullWidth
        onChange={this.handleMetricChange}
        validations={{
          isRequired: true,
        }}
        renderValue={this.renderMetricValue}
      >
        {this.renderMetricItems()}
      </ArcFormField>
    );

    return (
      <ArcView>
        <ArcView
          row
          justify="space-between"
          padding="16"
          marginBottom="8"
        >
          <ArcHeading>
            {this.isEditing ? 'Update Activity' : 'Create Activity'}
          </ArcHeading>
          <ArcIconButton onClick={this.props.onClose}>
            <IconClose />
          </ArcIconButton>
        </ArcView>

        <ArcView
          paddingTop="8"
          paddingBottom="8"
          paddingLeft="24"
          paddingRight="24"
        >
          <ArcFormField
            name="userId"
            type="select"
            label="Person"
            disabled={this.isRequesting || !this.hasPeople}
            fullWidth
            validations={{
              isRequired: true,
            }}
            validator={(errors) => {
              if (!hasUser) {
                errors.push('Person is required');
              }
            }}
            renderValue={this.renderPersonValue}
            onChange={(event) => {
              const personItem = this.props.people.find(p => p.id === event.target.value);

              if (personItem) {
                let metricId = '-1';
                let metricItem = null;

                if (personItem.metrics) {
                  metricId = personItem.metrics[0];
                  metricItem = this.props.metrics.find(m => m.id === metricId);
                }

                formikProps.setFieldValue('teamId', personItem.teamId || '-1');
                formikProps.setFieldValue('metricId', metricId);

                this.setPerson(personItem);
                this.setMetric(metricItem);
              } else {
                formikProps.setFieldValue('teamId', '-1');
                formikProps.setFieldValue('metricId', '-1');
                this.setPerson(null);
                this.setMetric(null);
              }
            }}
          >
            {this.renderPersonItems()}
          </ArcFormField>

          {!teamIsDisabled ? teamSelect : (
            <Tooltip title={hasUser ? `${person.name} needs at least one team` : 'You need to select a person first'}>
              <>{teamSelect}</>
            </Tooltip>
          )}

          {!metricIsDisabled ? metricSelect : (
            <Tooltip title={hasUser ? `${person.name} needs at least one metric` : 'You need to select a person first'}>
              <>{metricSelect}</>
            </Tooltip>
          )}

          <ArcFormField
            name="quantity"
            type={this.state.metric && this.state.metric.unit === '$' ? 'currency' : 'number'}
            label="Sale Quantity"
            autoComplete="off"
            disabled={this.isRequesting || !this.hasTeams || !this.hasMetrics || !hasUser}
            fullWidth
            onBlur={this.handleQuantityBlur}
            onFocus={this.handleQuantityFocus}
            validations={{
              isRequired: true,
              isNumber: false, // this looks wrong but im overriding the number validator below because
              // default validations only allow positive numbers
            }}
            validator={(errors) => {
              const quantity = parseFloat(formikProps.values.quantity);

              if (quantity === 0) {
                errors.push('Quantity cant be zero');
              }
            }}
            InputProps={{
              startAdornment: this.startAdornment,
              endAdornment: (
                <InputAdornment position="end">
                  <ArcIconButton
                    aria-label="Remove 1 from quantity"
                    disabled={this.isRequesting || !this.hasTeams || !this.hasMetrics || !hasUser}
                    onClick={
                      () => {
                        const value = parseFloat(formikProps.values.quantity);
                        let nextValue = value - 1;

                        if (nextValue === 0) {
                          nextValue = -1;
                        }

                        formikProps.setFieldValue('quantity', nextValue.toString());
                      }
                    }
                  >
                    <IconRemove />
                  </ArcIconButton>
                  <ArcIconButton
                    aria-label="Add 1 to quantity"
                    disabled={this.isRequesting || !this.hasTeams || !this.hasMetrics || !hasUser}
                    onClick={
                      () => {
                        const value = parseFloat(formikProps.values.quantity);
                        let nextValue = value + 1;

                        if (nextValue === 0) {
                          nextValue = 1;
                        }

                        formikProps.setFieldValue('quantity', nextValue.toString());
                      }
                    }
                  >
                    <IconAdd />
                  </ArcIconButton>
                </InputAdornment>
              ),
            }}
          />

          <ArcView marginBottom="16">
            <ArcTextField
              label="Created"
              onFocus={this.handleCreatedAtFocus}
              disabled={this.isRequesting || !this.hasTeams || !this.hasMetrics || !hasUser}
              value={getFormattedDate(formikProps.values.createdAt)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment variant="filled" position="end">
                    <ArcIconButton
                      onClick={this.handleCreatedAtEdit}
                    >
                      {this.state.createdAtIsEditing ? (
                        <IconCheck />
                      ) : (
                        <IconEdit />
                      )}
                    </ArcIconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </ArcView>

          {this.state.createdAtIsEditing && (
            <ArcDateTime
              mode="permanent"
              initialDate={this.props.initialValues.createdAt || new Date()}
              onChange={val => formikProps.setFieldValue('createdAt', val)}
            />
          )}

          {this.props.requestErrorMessage && (
            <ArcView row align="center">
              <IconErrorOutlined
                color="error"
                fontSize="small"
                style={styleIconError}
              />
              {this.props.requestErrorMessage}
            </ArcView>
          )}

          {person && !person.teamId && (
            <ArcView row align="center" marginBottom="16">
              <IconErrorOutlined
                color="error"
                fontSize="small"
                style={styleIconError}
              />
              <ArcView align="flex-start">
                <ArcView>
                  <Typography variant="caption">{`${person.name} does not belong to a team yet.`}</Typography>
                </ArcView>
                <ArcInternalLink
                  href="/arcade/manage/teams"
                  onClick={this.handleLinkClick}
                >
                  <Typography variant="caption" color="inherit">
                    {'Go to manage teams'}
                  </Typography>
                </ArcInternalLink>
              </ArcView>
            </ArcView>
          )}

          {person && (!person.metrics || !person.metrics.length) && (
            <ArcView row align="center">
              <IconErrorOutlined
                color="error"
                fontSize="small"
                style={styleIconError}
              />
              <ArcView align="flex-start">
                <ArcView>
                  <Typography variant="caption">{`${person.name} has no metrics assigned.`}</Typography>
                </ArcView>
                <ArcInternalLink
                  href="/arcade/manage/metrics"
                  onClick={this.handleLinkClick}
                >
                  <Typography variant="caption" color="inherit">
                    {'Go to manage metrics'}
                  </Typography>
                </ArcInternalLink>
              </ArcView>
            </ArcView>
          )}
        </ArcView>

        <DialogActions>
          {this.isEditing && (
            <ArcButton
              onClick={this.props.onDelete}
              color="danger"
              disabled={this.isRequesting || this.isSuccess}
            >
              {'Delete'}
            </ArcButton>
          )}
          <ArcView spacer />
          <ArcButton
            onClick={this.props.onClose}
            isSpaced
          >
            {'Cancel'}
          </ArcButton>
          <ArcLoaderButton
            type="submit"
            color="secondary"
            disabled={this.isRequesting || this.isSuccess || (formikProps.submitCount > 0 && !formikProps.isValid)}
            variant="contained"
            label={this.isEditing ? 'Save' : 'Create'}
            onClick={formikProps.isValid ? formikProps.handleSubmit : undefined}
            loadingState={this.loadingState}
          />
        </DialogActions>
      </ArcView>
    );
  };

  render() {
    return (
      <ArcDialog
        open={this.props.open}
        PaperProps={paperProps}
        onClose={this.props.onClose}
      >
        <ArcForm
          onSubmit={this.props.onSubmit}
          initialValues={{ ...INITIAL_VALUES, ...this.props.initialValues }}
          validationSchema={VALIDATION_SCHEMA}
        >
          {this.renderForm}
        </ArcForm>
      </ArcDialog>
    );
  }
}

export default withTheme(SaleCreateForm);
