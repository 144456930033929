import React from 'react';
import PropTypes from 'prop-types';

const UsersOutlined = ({ height, width, stroke, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="users">
      <g id="Group">
        <path id="Vector" d="M16.5 20.5H23.5C23.5 18.5804 22.4991 16.7997 20.8593 15.8018C19.2195 14.8038 17.178 14.733 15.473 15.615" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_2" fillRule="evenodd" clipRule="evenodd" d="M0.5 20.5C0.5 16.634 3.63401 13.5 7.5 13.5C11.366 13.5 14.5 16.634 14.5 20.5H0.5Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_3" fillRule="evenodd" clipRule="evenodd" d="M17.944 13.5C19.7389 13.5 21.194 12.0449 21.194 10.25C21.194 8.45507 19.7389 7 17.944 7C16.1491 7 14.694 8.45507 14.694 10.25C14.694 12.0449 16.1491 13.5 17.944 13.5Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_4" fillRule="evenodd" clipRule="evenodd" d="M7.5 12C9.84721 12 11.75 10.0972 11.75 7.75C11.75 5.40279 9.84721 3.5 7.5 3.5C5.15279 3.5 3.25 5.40279 3.25 7.75C3.25 10.0972 5.15279 12 7.5 12Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </g>
  </svg>
);

UsersOutlined.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  stroke: PropTypes.string,
};

UsersOutlined.defaultProps = {
  height: 24,
  width: 24,
  stroke: 'black',
};

export default UsersOutlined;
