import { react2angular } from 'react2angular';

import ChatSearchAnchor from 'arcade-frontend-chat/src/components/ChatSearchAnchor';

import { createProvidedContainer } from './helpers';

const ProvidedChatSearchAnchor = createProvidedContainer(ChatSearchAnchor);

export default react2angular(ProvidedChatSearchAnchor, [
  'label',
  'closeOnResultSelected',
  'onResultSelected',
]);
