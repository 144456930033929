import * as types from './types';

const getInitialState = state => ({
  errors: {},
  values: state,
  touched: {},
});

const getObjectByKeys = (keys = [], object = {}) => {
  const nextObject = {};

  keys.forEach((key) => {
    nextObject[key] = object[key];

    if (typeof nextObject[key] === 'undefined') {
      delete nextObject[key];
    } else if (nextObject[key] === null) {
      nextObject[key] = '';
    }
  });

  return nextObject;
};

const getInvalidKeys = (validKeys, nextKeys) => nextKeys.filter(x => !validKeys.includes(x));

const createFormReducer = (model, initialState) => {
  const validKeys = Object.keys(initialState);

  return (state = getInitialState(initialState), action) => {
    if (action.type === types.FORM_CHANGE && action.payload.model === model) {
      const { errors, values, touched } = action.payload.form;

      if (process.env.NODE_ENV !== 'production') {
        const invalidKeys = getInvalidKeys(validKeys, Object.keys(values));

        if (invalidKeys.length) {
          const msg = invalidKeys.map(key => `\`${key}\``).join(', ');
          console.warn( // eslint-disable-line no-console
            `\`${model}\` form reducer skipped updating the following values: ${msg}`,
          );
        }
      }

      return {
        errors: {
          ...state.errors,
          ...getObjectByKeys(validKeys, errors),
        },
        values: {
          ...state.values,
          ...getObjectByKeys(validKeys, values),
        },
        touched: {
          ...state.touched,
          ...getObjectByKeys(validKeys, touched),
        },
      };
    }

    return state;
  };
};

export default createFormReducer;
