import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import moment from 'moment';

import { selectors as setSelectors, filterBySet } from './activity_sets';
import { selectors as activitySelectors } from './activities';
import { selectors as summarySelectors } from './summaries';

const sortedNewsfeedList = createCachedSelector(
  setSelectors.activitiesForSet,
  summarySelectors.summaries,
  (activityData, summaryData) => {
    const unpinnedActivities = Object.values(activityData).filter(
      ({ pinned }) => !pinned,
    );

    const activityRecords = unpinnedActivities.reverse().map(r => ({
      date: r.interestingAt,
      type: 'activity',
      content: r,
    }));
    const summaryRecords = Object.values(summaryData).map(r => ({
      date: r.newest,
      type: 'summary',
      content: r,
    }));

    const sortedActivities = [...activityRecords, ...summaryRecords].sort(
      (a, b) => b.date - a.date,
    );

    const batchedActivities = sortedActivities.reduce(
      ({ blocks, currentDate }, activity) => {
        const { date } = activity;

        let nextDate = currentDate;
        let currentBlock = blocks[blocks.length - 1];

        if (
          currentDate &&
          currentDate.isSame &&
          !currentDate.isSame(date, 'day')
        ) {
          nextDate = date;

          currentBlock = {
            date: nextDate,
            sections: [],
          };

          blocks.push(currentBlock);
        }

        currentBlock.sections.push(activity);

        return {
          blocks,
          currentDate: nextDate,
        };
      },
      {
        blocks: [],
        currentDate: moment(0),
      },
    );

    return batchedActivities.blocks;
  },
)(filterBySet);

const pinnedActivityList = createSelector(
  activitySelectors.activitiesById,
  activityData =>
    Object.values(activityData)
      .filter(({ pinned }) => pinned)
      .sort((a, b) => b.interestingAt - a.interestingAt),
);

const orderedActivitiesForSet = createCachedSelector(
  setSelectors.activitiesForSet,
  activities =>
    Object.values(activities).sort((a, b) => b.interestingAt - a.interestingAt),
)(filterBySet);

export const selectors = {
  sortedNewsfeedList,
  pinnedActivityList,
  orderedActivitiesForSet,
};
