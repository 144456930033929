import { createReducer } from 'arcade-frontend-ui';
import { createSelector } from 'reselect';

import { types } from '../../../actions/manage/rewards';

const initialState = {};

const handleManageRewardsIndexSuccess = (state, action) => {
  const { data } = action.payload;

  const newState = { ...state };

  data.forEach((reward) => {
    newState[reward.id] = reward;
  });

  return newState;
};

const handleManageRewardsCreateSuccess = (state, action) => {
  const { data } = action.payload;

  return {
    ...state,
    [data.id]: {
      ...state[data.id],
      ...data,
    },
  };
};

const getRequestActionPayloadId = meta => meta.requestAction.payload.id;

const updateArchived = archived => (state, action) => {
  const id = getRequestActionPayloadId(action.meta);

  return {
    ...state,
    [id]: {
      ...state[id],
      archived,
    },
  };
};

const handleManageRewardsArchiveSuccess = updateArchived(true);
const handleManageRewardsRestoreSuccess = updateArchived(false);

const handlers = {
  [types.MANAGE_REWARDS_INDEX.SUCCESS]: handleManageRewardsIndexSuccess,
  [types.MANAGE_REWARDS_CUSTOM_INDEX.SUCCESS]: handleManageRewardsIndexSuccess,
  [types.MANAGE_REWARDS_CREATE.SUCCESS]: handleManageRewardsCreateSuccess,
  [types.MANAGE_REWARDS_ARCHIVE.SUCCESS]: handleManageRewardsArchiveSuccess,
  [types.MANAGE_REWARDS_RESTORE.SUCCESS]: handleManageRewardsRestoreSuccess,
  [types.MANAGE_REWARDS_CUSTOM_ARCHIVE.SUCCESS]: handleManageRewardsArchiveSuccess,
  [types.MANAGE_REWARDS_CUSTOM_RESTORE.SUCCESS]: handleManageRewardsRestoreSuccess,
};

const byId = createReducer(initialState, handlers);

const filterCollectionByPropValue = (collection, prop, value) => (
  Object.keys(collection).filter(id => collection[id][prop] === value)
);

export const getManageRewardsById = state => state.manage.rewards.byId;

const getManageRewardsFilterProp = prop => () => prop;
const getManageRewardsFilterValue = value => () => value;

const createFilterSelector = (prop, value) => (
  createSelector(
    [
      getManageRewardsById,
      getManageRewardsFilterProp(prop),
      getManageRewardsFilterValue(value),
    ],
    filterCollectionByPropValue,
  )
);

export default byId;

export const getManageRewardsFilterActiveState = createFilterSelector('archived', false);
export const getManageRewardsFilterArchivedState = createFilterSelector('archived', true);
