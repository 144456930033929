import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcStyles from '../../styles';

const styleUnchecked = {
  color: ArcStyles.theme.arcTextColorMuted,
};

const styleChecked = {
  color: ArcStyles.theme.brandPrimary,
};

const stylePartial = {
  color: ArcStyles.theme.grey3,
};

class ArcCheckbox extends React.PureComponent {
  static displayName = 'ArcCheckbox';

  static propTypes = {
    id: ArcPropTypes.string,
    name: ArcPropTypes.string,
    checked: ArcPropTypes.bool,
    checkedIcon: ArcPropTypes.children,
    icon: ArcPropTypes.children,
    label: ArcPropTypes.node,
    labelPlacement: ArcPropTypes.oneOf(['start', 'end']),
    onChange: ArcPropTypes.func,
    partiallyChecked: ArcPropTypes.bool,
    value: ArcPropTypes.string,
  };

  static defaultProps = {
    id: undefined,
    name: undefined,
    checked: false,
    checkedIcon: undefined,
    icon: undefined,
    label: '',
    labelPlacement: undefined,
    onChange: ArcPropTypes.noop,
    partiallyChecked: false,
    value: '',
  };

  stylePartialChecked() {
    return this.props.partiallyChecked ? stylePartial : styleUnchecked;
  }

  render() {
    const {
      checked,
      checkedIcon,
      icon,
      id,
      label,
      labelPlacement,
      onChange,
      partiallyChecked,
      value,
      ...rest
    } = this.props;

    const isChecked = checked || partiallyChecked;

    const control = (
      <Checkbox
        aria-checked={isChecked ? 'true' : 'false'}
        checked={isChecked}
        checkedIcon={checkedIcon}
        disableRipple
        icon={icon}
        id={id}
        onChange={onChange}
        style={checked ? styleChecked : this.stylePartialChecked()}
        value={value}
        {...(label ? {} : rest)}
      />
    );

    if (label) {
      return (
        <FormControlLabel
          control={control}
          label={label}
          labelPlacement={labelPlacement}
          htmlFor={id}
          {...rest}
        />
      );
    }

    return control;
  }
}

export default ArcCheckbox;
