import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../../actions/manage/games/types';

const initialState = 0;

const handleSuccess = (_, action) => action.payload.data.balance;

const handlers = {
  [types.MANAGE_GAMES_GET_ESCROWABLE_FUNDS.SUCCESS]: handleSuccess,
};

export default createReducer(initialState, handlers);

export const getManageGamesEscrowAvailable = state => state.manage.games.escrowAvailable;
