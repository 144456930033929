import React from 'react';
import PropTypes from 'prop-types';

const CalendarOutlined = ({ height, width, stroke, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path d="M22.5 2.50101H1.5C0.947715 2.50101 0.5 2.94872 0.5 3.50101V22.501C0.5 23.0533 0.947715 23.501 1.5 23.501H22.5C23.0523 23.501 23.5 23.0533 23.5 22.501V3.50101C23.5 2.94872 23.0523 2.50101 22.5 2.50101Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.5 7.50101H23.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.5 0.501007V5.50101" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.5 0.501007V5.50101" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0H24V24H0V0Z" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CalendarOutlined;

CalendarOutlined.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  stroke: PropTypes.string,
};

CalendarOutlined.defaultProps = {
  height: 24,
  width: 24,
  stroke: 'black',
};
