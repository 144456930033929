import React from 'react';
import FormControl from '@material-ui/core/FormControl';

import IconAdd from '@material-ui/icons/Add';
import IconClear from '@material-ui/icons/Clear';

import ArcPropTypes from '../../helpers/arc/propTypes';
import { makeValidators } from '../../helpers/utils/validators';
import ArcButton from '../ArcButton';
import ArcIconButton from '../ArcIconButton';
import ArcNumberField from '../ArcNumberField';
import ArcTextField from '../ArcTextField';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';

const styleSpacer = {
  marginRight: 8,
  width: '50%',
};

const styleButton = {
  alignSelf: 'flex-start',
  marginTop: 8,
};

const styleIndex = {
  width: 56,
};

function findWithProp(array, attr, value) {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

const labelsByType = {
  label: 'Label',
  value: 'Cost',
};

const getErrors = (label, value) => {
  const errors = [];

  const { messages, validators } = makeValidators(label, {
    isRequired: true,
    isNumber: label === labelsByType.value,
  });

  Object.keys(validators).forEach((key) => {
    const isValid = validators[key](value);

    if (!isValid) {
      errors.push(messages[key]);
    }
  });

  return errors;
};


class ArcMultiAdd extends React.PureComponent {
  static propTypes = {
    fullWidth: ArcPropTypes.bool,
    maxRows: ArcPropTypes.number,
    onChange: ArcPropTypes.func,
    onError: ArcPropTypes.func,
    value: ArcPropTypes.arrayOf(ArcPropTypes.shape({
      label: ArcPropTypes.string.isRequired,
      value: ArcPropTypes.oneOfType([
        ArcPropTypes.number,
        ArcPropTypes.string,
      ]).isRequired,
    })),
  };

  static defaultProps = {
    fullWidth: false,
    maxRows: undefined,
    onChange: ArcPropTypes.noop,
    onError: ArcPropTypes.noop,
    value: [],
  };

  state = this.initialState;

  componentDidMount() {
    this.state.rows.forEach((row) => {
      ['label', 'value'].forEach((type) => {
        const errors = getErrors(labelsByType[type], row[type]);
        this.setErrors(type, row.id, errors);
      });
    });
  }

  get initialIdCount() {
    return this.state.rows.length || -1;
  }

  get initialState() {
    let rows = [];

    if (this.props.value) {
      rows = this.props.value.map((row, index) => ({
        ...row,
        id: index,
      }));
    }

    return {
      labelErrorsById: {},
      valueErrorsById: {},
      rows,
    };
  }

  get canAddRow() {
    const { maxRows } = this.props;

    if (typeof maxRows === 'undefined') {
      return true;
    }

    return this.state.rows.length < maxRows;
  }

  setErrors = (type, id, errors) => {
    const typeKey = `${type}ErrorsById`;
    this.setState(prevState => ({
      [typeKey]: {
        ...prevState[typeKey],
        [id]: errors,
      },
    }), this.handleError);
  };

  setRows = rows => this.setState({ rows });

  getErrorText = (type, id) => {
    const typeKey = `${type}ErrorsById`;

    if (this.state[typeKey] && this.state[typeKey][id] && this.state[typeKey][id][0]) {
      return this.state[typeKey][id][0];
    }

    return labelsByType[type];
  };

  updateRow = (type, row, value) => {
    const index = findWithProp(this.state.rows, 'id', row.id);
    const nextRow = {
      ...row,
      [type]: value,
    };

    const nextRows = [...this.state.rows];
    nextRows[index] = nextRow;
    this.setRows(nextRows);

    this.setErrors(type, row.id, getErrors(labelsByType[type], value));
    this.props.onChange(nextRows);
  };

  idCount = this.initialIdCount;

  handleBlur = (e, type, row) => {
    const normalizedRows = this.state.rows.map(item => ({
      label: item.label,
      value: item.value,
    }));

    this.props.onChange(normalizedRows);
    this.setErrors(type, row.id, getErrors(labelsByType[type], row[type]));
  };

  handleChangeLabel = (e, row) => this.updateRow('label', row, e.target.value);

  handleChangeCost = (e, row) => this.updateRow('value', row, e.target.value);

  handleClickAdd = () => {
    if (!this.canAddRow) {
      return;
    }

    this.idCount += 1;
    const nextRows = [...this.state.rows, {
      id: this.idCount,
      label: '',
      value: '',
    }];

    this.setRows(nextRows);
    this.props.onChange(nextRows);
  };

  handleError = () => {
    let errors = [];

    ['label', 'value'].forEach((type) => {
      const typeKey = `${type}ErrorsById`;

      Object.keys(this.state[typeKey]).forEach((id) => {
        if (this.state[typeKey] && this.state[typeKey][id]) {
          errors = errors.concat(this.state[typeKey][id]);
        }
      });
    });

    this.props.onError(errors);
  };

  handleClickRemove = (row) => {
    const nextRows = this.state.rows.filter(r => r.id !== row.id);
    this.setRows(nextRows);
    this.props.onChange(nextRows);
  };

  renderRow = (row, index) => (
    <ArcView
      key={row.id}
      row
      marginBottom="16"
    >
      <ArcView
        align="center"
        justify="center"
        style={styleIndex}
      >
        <ArcText color="disabled">
          {index + 1}
        </ArcText>
      </ArcView>
      <ArcTextField
        error={!!this.state.labelErrorsById[row.id] && !!this.state.labelErrorsById[row.id].length}
        label={this.getErrorText('label', row.id)}
        onBlur={e => this.handleBlur(e, 'label', row)}
        onChange={e => this.handleChangeLabel(e, row)}
        value={row.label}
        variant="outlined"
        style={styleSpacer}
      />

      <ArcNumberField
        error={!!this.state.valueErrorsById[row.id] && !!this.state.valueErrorsById[row.id].length}
        label={this.getErrorText('value', row.id)}
        onBlur={e => this.handleBlur(e, 'value', row)}
        onChange={e => this.handleChangeCost(e, row)}
        value={row.value}
        variant="outlined"
      />

      <ArcIconButton
        onClick={() => this.handleClickRemove(row)}
        disableRipple
      >
        <IconClear
          color="disabled"
          fontSize="inherit"
        />
      </ArcIconButton>
    </ArcView>
  );

  render() {
    return (
      <FormControl fullWidth={this.props.fullWidth}>
        {this.state.rows.map(this.renderRow)}
        <ArcButton
          disabled={!this.canAddRow}
          onClick={this.handleClickAdd}
          style={styleButton}
          variant="outlined"
          color="primary"
        >
          <IconAdd />
          <ArcView marginLeft="8">{'Add Variant'}</ArcView>
        </ArcButton>
      </FormControl>
    );
  }
}

export default ArcMultiAdd;
