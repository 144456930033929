import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { rankingsByGameId } from 'arcade-frontend-features/src/reducers/manage/games/rankingsByGameId';
import { propTypes as gamePropTypes } from 'arcade-frontend-features/src/resources/games';
import { apiManageGamesRankingsIndexRequest } from 'arcade-frontend-features/src/actions/manage/games/actions';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import GameLeaderboard from '../../components/GameLeaderboard';


const ManageGamesLeaderboardContainer = ({ game, user, allRankings, getGamesRankings }) => {
  useEffect(() => { getGamesRankings(game.id); }, [game.id]);

  const rankings = allRankings[game.id];

  if (!rankings) return null;

  return (
    <GameLeaderboard
      {...game}
      currentGameId={game.id}
      currentUserId={user.id}
      rankings={rankings}
    />
  );
};

ManageGamesLeaderboardContainer.propTypes = {
  game: gamePropTypes.game.isRequired,
  user: PropTypes.shape({ id: PropTypes.string }).isRequired,
  allRankings: PropTypes.objectOf(gamePropTypes.gameRankings).isRequired,
  getGamesRankings: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  allRankings: rankingsByGameId(state),
  user: getCurrentUser(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getGamesRankings: apiManageGamesRankingsIndexRequest,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ManageGamesLeaderboardContainer);
