import React from 'react';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import ArcButton from '../../elements/ArcButton';
import ArcView from '../../primitives/ArcView';
import ArcText from '../../primitives/ArcText';

const ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'center',
};

const TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'center',
};

function ArcBreadcrumbsMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const itemsLength = props.items.length;

  return (
    <React.Fragment>
      <ArcButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        size="small"
        onClick={handleClick}
      >
        <MoreHorizIcon fontSize="small" />
      </ArcButton>
      <Menu
        id="customized-menu"
        elevation={1}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={ANCHOR_ORIGIN}
        transformOrigin={TRANSFORM_ORIGIN}
      >
        {props.items.map((item, index) => (
          [
            <MenuItem
              key={item.key}
              onClick={() => props.onClick(item.key) && handleClose()}
            >
              <ListItemText
                primary={item.icon ? (
                  <ArcView row align="center">
                    <ArcView marginRight="8">
                      {item.icon}
                    </ArcView>
                    <ArcText isSmall>{item.label}</ArcText>
                  </ArcView>
                ) : item.label}
              />
            </MenuItem>,
            index === itemsLength - 2 && (<Divider key="divider" />),
          ]
        ))}
      </Menu>
    </React.Fragment>
  );
}

ArcBreadcrumbsMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    icon: PropTypes.node,
    label: PropTypes.string.isRequired,
  })),
  onClick: PropTypes.func,
};

ArcBreadcrumbsMenu.defaultProps = {
  items: [],
  onClick: global.noop,
};

export default ArcBreadcrumbsMenu;
