import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import VerticalRecapBar from 'arcade-frontend-ui/src/assets/VerticalRecapBar';
import { ArcView } from 'arcade-frontend-ui';

import NewsfeedSummaryItem from '../NewsfeedSummaryItem';

const PLACEHOLDER = (
  <ArcView align="flex-start">
    <ArcView
      row
      justify="space-around"
      elevation="1"
      rounded
      padding="8"
      color="paper"
      marginLeft="auto"
      marginRight="auto"
    >
      {NewsfeedSummaryItem.PLACEHOLDER}
      {NewsfeedSummaryItem.PLACEHOLDER}
    </ArcView>
  </ArcView>
);

class NewsfeedSummaryCard extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    onSummaryRequest: PropTypes.func,
    summaries: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        count: PropTypes.number.isRequired,
        userImages: PropTypes.arrayOf(PropTypes.string).isRequired,
        newest: PropTypes.instanceOf(moment),
        oldest: PropTypes.instanceOf(moment),
      }),
    ),
  };

  static defaultProps = {
    isLoading: false,
    onSummaryRequest: global.noop,
    summaries: [],
  };

  static PLACEHOLDER = PLACEHOLDER;

  renderSummary = summary => (
    <NewsfeedSummaryItem
      key={summary.type}
      {...summary}
      onClick={this.props.onSummaryRequest}
    />
  );

  render() {
    if (this.props.isLoading) {
      return PLACEHOLDER;
    }

    return (
      <ArcView
        overflow="hidden"
        position="relative"
        row
        elevation="1"
        rounded
        marginBottom="32"
        color="paper"
      >
        <ArcView position="absolute" align="flex-start" fullHeight>
          <ArcView spacer fullWidth color="blue" />
          <VerticalRecapBar width="24px" height="84px" colorString="#0290C3" />
          <ArcView spacer fullWidth color="blue" />
        </ArcView>
        <ArcView
          row
          fullWidth
          justify="space-around"
          paddingLeft="24"
          wrap="wrap"
        >
          {this.props.summaries.map(this.renderSummary)}
        </ArcView>
      </ArcView>
    );
  }
}

export default NewsfeedSummaryCard;
