import React from 'react';
import { Path } from 'react-primitives-svg';
import Icon from '../Icon';

const HeartCircle = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      // stroke-width=".96"
      d="M12.04 23.08c6.097 0 11.04-4.943 11.04-11.04C23.08 5.943 18.137 1 12.04 1 5.943 1 1 5.943 1 12.04c0 6.097 4.943 11.04 11.04 11.04z"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.26 17.58l-4.91-5.122a2.904 2.904 0 01-.543-3.353v0a2.905 2.905 0 014.652-.754l.801.801.802-.801a2.904 2.904 0 014.652.754v0a2.904 2.904 0 01-.544 3.353l-4.91 5.121z"
    />
  </Icon>
);

HeartCircle.displayName = 'HeartCircle';

export default HeartCircle;
