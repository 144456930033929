import axios from 'axios';

import getEndpointUrlFromAxiosConfig from '../getEndpointUrlFromAxiosConfig';

let mockingEnabled = true;

const mocks = {};

function add(url, data) {
  mocks[url] = data;
}

function remove(url) {
  delete mocks[url];
}

function mock() {
  mockingEnabled = true;
}

function unmock() {
  mockingEnabled = false;
}

const isUrlMocked = url => url in mocks;

const getMockError = (config) => {
  const mockError = new Error();
  const endpointUrl = getEndpointUrlFromAxiosConfig(config);
  mockError.mockData = mocks[endpointUrl];
  mockError.config = config;
  return Promise.reject(mockError);
};

const isMockError = error => Boolean(error.mockData);

const getMockResponse = (mockError) => {
  const { mockData, config } = mockError;
  // Handle mocked error (any non-2xx status code)
  if (mockData.status && String(mockData.status)[0] !== '2') {
    const err = new Error(mockData.message || 'mock error');
    err.code = mockData.status;
    return Promise.reject(err);
  }

  // Handle mocked success
  return Promise.resolve({
    data: {},
    status: 200,
    statusText: 'OK',
    headers: {},
    config,
    isMock: true,
    ...mockData,
  });
};

if (!global.isProduction) {
  // Add a request interceptor
  axios.interceptors.request.use((config) => {
    const endpointUrl = getEndpointUrlFromAxiosConfig(config);

    if (mockingEnabled && isUrlMocked(endpointUrl)) {
      return getMockError(config);
    }

    return config;
  }, error => Promise.reject(error));

  // Add a response interceptor
  axios.interceptors.response.use(response => response, (error) => {
    if (isMockError(error)) {
      return getMockResponse(error);
    }

    return Promise.reject(error);
  });
}

export default {
  add,
  remove,
  mock,
  unmock,
};
