import { react2angular } from 'react2angular';

import {
  ArcInterlaced,
} from 'arcade-frontend-ui';

export default react2angular(ArcInterlaced, [
  'phrase',
  'fadeInUp',
  'flash',
  'color',
  'toColor',
  'fromColor',
]);
