import React from 'react';

import PropTypes from 'prop-types';

import {
  ArcView,
  ArcText,
  ArcPaper,
  ArcScroll,
  createWithStyles,
} from 'arcade-frontend-ui';
import toTitleCase from 'arcade-frontend-core/src/helpers/toTitleCase';

const styles = {
  Sections: theme => ({
    root: {
      margin: theme.spacing(2),
      flexWrap: 'wrap',
    },
  }),
  Section: theme => ({
    root: {
      width: theme.spacing(30),
      minHeight: theme.spacing(30),
      backgroundColor: theme.palette.common.white,
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }),
  SectionHeader: theme => ({
    root: {
      padding: `${theme.spacing(1)}px 0`,
    },
  }),
  Row: theme => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      flexGrow: 1,
      cursor: 'pointer',
    },
    stripe0: {
      backgroundColor: theme.palette.grey.tint,
    },
  }),
};

const Sections = createWithStyles(styles.Sections)(ArcView);
const Section = createWithStyles(styles.Section)(ArcPaper);
const SectionHeader = createWithStyles(styles.SectionHeader)(ArcView);
const Row = createWithStyles(styles.Row)(ArcView);

const ManageGamesOverview = ({ stats, isLoading, onItemClick }) => {
  const sumTotal = stats.reduce((sum, stat) => sum + stat.total, 0);

  return (
    <ArcScroll>
      <Sections row>
        <Section>
          <Row>
            <SectionHeader flexGrow={1}>
              <ArcText isLarger>Snapshot</ArcText>
            </SectionHeader>
            <ArcText isSmall>{sumTotal} games</ArcText>
          </Row>
          <ArcScroll>
            {stats.map(({ type, total }, i) => (
              <Row
                key={type}
                stripe={i % 2}
                className={isLoading ? 'shimmer' : undefined}
                onClick={() => onItemClick(type)}
              >
                <ArcView row flexGrow={1}>
                  <ArcText>{toTitleCase(type)}</ArcText>
                </ArcView>
                <ArcText>{total}</ArcText>
              </Row>
            ))}
          </ArcScroll>
        </Section>
      </Sections>
    </ArcScroll>
  );
};

ManageGamesOverview.displayName = 'ManageGamesOverview';

ManageGamesOverview.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      total: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool,
  onItemClick: PropTypes.func,
};

ManageGamesOverview.defaultProps = {
  categories: {},
  stats: [],
  isLoading: false,
  onItemClick: global.noop,
};

export default ManageGamesOverview;
