export const AddCircleAlt = () => require('./AddCircleAlt').default;
export const AlertCircle = () => require('./AlertCircle').default;
export const AlertDiamond = () => require('./AlertDiamond').default;
export const ArcTokenOutline = () => require('./ArcTokenOutline').default;
export const ArrowRight = () => require('./ArrowRight').default;
export const Attachment = () => require('./Attachment').default;
export const BlankProfileIcon = () => require('./BlankProfileIcon').default;
export const Calendar = () => require('./Calendar').default;
export const CallAt = () => require('./CallAt').default;
export const Camera = () => require('./Camera').default;
export const Cap = () => require('./Cap').default;
export const Caret = () => require('./Caret').default;
export const CautionCircle = () => require('./CautionCircle').default;
export const Chat = () => require('./Chat').default;
export const Check = () => require('./Check').default;
export const CheckCircle = () => require('./CheckCircle').default;
export const ChestAlt = () => require('./ChestAlt').default;
export const ChestOutline = () => require('./ChestOutline').default;
export const ChevronDown = () => require('./ChevronDown').default;
export const ClockOutline = () => require('./ClockOutline').default;
export const Close = () => require('./Close').default;
export const CreditCard = () => require('./CreditCard').default;
export const Diamond = () => require('./Diamond').default;
export const Expand = () => require('./Expand').default;
export const Eye = () => require('./Eye').default;
export const FlagAlt = () => require('./FlagAlt').default;
export const GameController = () => require('./GameController').default;
export const GameControllerTeam = () => require('./GameControllerTeam').default;
export const GamePad = () => require('./GamePad').default;
export const GamesOutline = () => require('./GamesOutline').default;
export const GifOutline = () => require('./GifOutline').default;
export const Group = () => require('./Group').default;
export const HashTag = () => require('./HashTag').default;
export const HeartCircle = () => require('./HeartCircle').default;
export const HelpCircle = () => require('./HelpCircle').default;
export const History = () => require('./History').default;
export const Home = () => require('./Home').default;
export const LeftArrow = () => require('./LeftArrow').default;
export const Location = () => require('./Location').default;
export const Lock = () => require('./Lock').default;
export const ManageBudgets = () => require('./ManageBudgets').default;
export const ManageIntegrations = () => require('./ManageIntegrations').default;
export const Message = () => require('./Message').default;
export const NoInternet = () => require('./NoInternet').default;
export const Notifications = () => require('./Notifications').default;
export const PeopleOutline = () => require('./PeopleOutline').default;
export const PhotoOutline = () => require('./PhotoOutline').default;
export const Play = () => require('./Play').default;
export const PresentOutline = () => require('./PresentOutline').default;
export const QuestionCircle = () => require('./QuestionCircle').default;
export const RefreshOutline = () => require('./RefreshOutline').default;
export const Remove = () => require('./Remove').default;
export const RemoveAll = () => require('./RemoveAll').default;
export const RemoveAllPeople = () => require('./RemoveAllPeople').default;
export const RibbonAlt1 = () => require('./RibbonAlt1').default;
export const RunningMan = () => require('./RunningMan').default;
export const SearchOutline = () => require('./SearchOutline').default;
export const Send = () => require('./Send').default;
export const Settings = () => require('./Settings').default;
export const Shops = () => require('./Shops').default;
export const Star = () => require('./Star').default;
export const StarFill = () => require('./StarFill').default;
export const StatsLine = () => require('./StatsLine').default;
export const StatsReport = () => require('./StatsReport').default;
export const Tag = () => require('./Tag').default;
export const TakeMyMoney = () => require('./TakeMyMoney').default;
export const Ticket = () => require('./Ticket').default;
export const Tokens3 = () => require('./Tokens3').default;
export const Torch3 = () => require('./Torch3').default;
export const User = () => require('./User').default;
export const Users = () => require('./Users').default;
export const UsersAddOutline = () => require('./UsersAddOutline').default;
export const VideoCamera = () => require('./VideoCamera').default;
export const XCircleOutline = () => require('./XCircleOutline').default;
