import axios from 'axios';

import getAxiosConfig from '../getAxiosConfig';
import ResourceError from '../ResourceError';

function apiCall(apiAction, payload, baseConfig) {
  if (process.env.NODE_ENV === 'development') {
    if (!apiAction) {
      console.warn('apiCall: `apiAction` is required', {
        apiAction,
        payload,
      });
    }

    if (!apiAction.api) {
      console.warn('apiCall: `apiAction` needs to have an `api` function that returns an endpoint definition', {
        apiAction,
        payload,
      });
    }

    if (!apiAction.request || !apiAction.success || !apiAction.failure) {
      console.warn('apiCall: `apiAction` needs to have `request`, `success` and `failure` action creators', {
        apiAction,
        payload,
      });
    }
  }

  const axiosConfig = getAxiosConfig(apiAction, payload, baseConfig);

  if (!axiosConfig.method) {
    axiosConfig.method = 'GET';
  }

  if (axiosConfig.method === 'GET') {
    delete axiosConfig.data;
  }

  const meta = {
    axiosConfig,
    requestedAt: (new Date()).toISOString(),
    requestAction: apiAction.request(payload),
  };

  const handleSuccess = (resp = {}) => ({
    meta: {
      ...meta,
      succeededAt: (new Date()).toISOString(),
    },
    data: resp.data,
  });

  const handleFailure = (error) => {
    const failurePayload = {
      meta: {
        ...meta,
        failedAt: (new Date()).toISOString(),
      },
      data: error,
    };

    // if (process.env.NODE_ENV === 'development') {
    //   console.error(failurePayload);
    // }
    throw new ResourceError(failurePayload);
    // return failurePayload;
  };

  return axios(axiosConfig)
    .then(handleSuccess)
    .catch(handleFailure);
}

export default apiCall;
