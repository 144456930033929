import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const Users = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.5 20.5h7a5.5 5.5 0 00-8.027-4.885"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M.5 20.5a7 7 0 1114 0H.5zm17.444-7a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5zM7.5 12a4.25 4.25 0 100-8.5 4.25 4.25 0 000 8.5z"
      clipRule="evenodd"
    />
  </Icon>
);

export default Users;
