import React from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';

import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';

import ArcToken from '../../assets/ArcToken';

import ArcView from '../../primitives/ArcView';
import ArcIconButton from '../../elements/ArcIconButton';
import ArcNumberField from '../../elements/ArcNumberField';

function ArcTokenField({
  disabled,
  max,
  min,
  onChange,
  value,
  ...props
}) {
  let numValue = parseInt(value, 10);

  if (Number.isNaN(numValue)) {
    numValue = 0;
  }

  const addButtonDisabled = max ? numValue >= max : false;

  const getIsValid = (val) => {
    if (Number.isNaN(val)) {
      return false;
    }

    if (val === '') {
      return true;
    }

    return (min ? val >= min : true)
      && (max ? val <= max : true);
  };

  const addButton = (
    <ArcIconButton
      size="small"
      disabled={disabled || addButtonDisabled}
      onClick={() => getIsValid(numValue + 1) && onChange(numValue + 1)}
      title="Add"
    >
      <Add color="inherit" size="small" />
    </ArcIconButton>
  );

  const removeButtonDisabled = min ? numValue <= min : false;

  const removeButton = (
    <ArcIconButton
      size="small"
      disabled={disabled || removeButtonDisabled}
      onClick={() => getIsValid(numValue - 1) && onChange(numValue - 1)}
      title="Remove"
    >
      <Remove color="inherit" size="small" />
    </ArcIconButton>
  );

  const handleChange = (evt) => {
    const parsedValue = evt.target.value
      ? parseInt(evt.target.value, 10)
      : 0;

    if (getIsValid(parsedValue)) {
      onChange(parsedValue);
    }
  };

  const removeButtonAdornment = removeButtonDisabled && min > 0 ? (
    <Tooltip title={`Min amount is ${min}`}>
      <ArcView>
        {removeButton}
      </ArcView>
    </Tooltip>
  ) : removeButton;

  const addButtonAdornment = addButtonDisabled ? (
    <Tooltip title={`Max amount is ${max}`}>
      <ArcView>
        {addButton}
      </ArcView>
    </Tooltip>
  ) : addButton;

  const startAdornment = (
    <InputAdornment position="start">
      <ArcToken width="16px" height="16px" />
    </InputAdornment>
  );

  const endAdornment = (
    <React.Fragment>
      {removeButtonAdornment}
      {addButtonAdornment}
    </React.Fragment>
  );

  return (
    <ArcNumberField
      {...props}
      disabled={disabled}
      onChange={handleChange}
      placeholder=""
      variant="outlined"
      value={numValue}
      InputProps={{
        margin: 'dense',
        style: {
          padding: '0 8px',
        },
        startAdornment,
        endAdornment,
      }}
    />
  );
}

ArcTokenField.displayName = 'ArcTokenField';

ArcTokenField.propTypes = {
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
};

ArcTokenField.defaultProps = {
  disabled: false,
  max: null,
  min: 0,
  onChange: global.noop,
  value: 0,
};

export default ArcTokenField;
