import React from 'react';

import useResourceAction from '../../hooks/useResourceAction';

function withResourceAction(action, opts) {
  return function (Component) {
    return function (props) {
      const resourceAction = useResourceAction(action, opts);

      return (
        <Component
          {...resourceAction}
          {...props}
        />
      );
    };
  };
}

export default withResourceAction;
