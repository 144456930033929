import { getStatus, resourceReducer } from 'redux-resource';

const teams = resourceReducer('teams');

export default teams;

export const getTeamsStatus = state =>
  getStatus(state, 'people.teams.requests.apiGetTeams.status');
export const getUpdateTeamsStatus = state =>
  getStatus(state, 'people.teams.requests.apiPutUpdateTeam.status');
export const getCreateTeamsStatus = state =>
  getStatus(state, 'people.teams.requests.apiPostCreateTeam.status');
export const getDeleteTeamStatus = state =>
  getStatus(state, 'people.teams.requests.apiDeleteTeam.status');
