import ArcPropTypes from '../../helpers/arc/propTypes';

const taggable = {
  id: ArcPropTypes.string.isRequired,
  name: ArcPropTypes.string.isRequired,
  display: ArcPropTypes.string.isRequired,
  type: ArcPropTypes.string,
};

const taggableTeam = ArcPropTypes.shape({
  letter: ArcPropTypes.string.isRequired,
  color: ArcPropTypes.string.isRequired,
  peopleAmount: ArcPropTypes.number,
  ...taggable,
});

const taggablePerson = ArcPropTypes.shape({
  teamName: ArcPropTypes.string,
  imageUrl: ArcPropTypes.string.isRequired,
  ...taggable,
});

const taggableEntity = ArcPropTypes.shape({
  id: ArcPropTypes.string.isRequired,
  name: ArcPropTypes.string.isRequired,
  peopleAmount: ArcPropTypes.number,
});

const taggableItem = ArcPropTypes.oneOfType([taggablePerson, taggableTeam, taggableEntity]);

export { taggableEntity, taggablePerson, taggableTeam, taggableItem };
