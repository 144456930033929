import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { ArcView, ArcScroll } from 'arcade-frontend-ui';

import ActivityHistoryListItem from './ActivityHistoryListItem';

const headings = [
  'Post',
  'Audience',
  'Views',
  'Comments',
  'Reactions',
  'Acknowledgements',
];

const PLACEHOLDERS = [0, 1, 2, 3, 4];

const noResultPlaceholder = (
  <TableRow>
    <TableCell colSpan={2}>{'No results found'}</TableCell>
  </TableRow>
);

const placeholder = PLACEHOLDERS.map(id => (
  <TableRow key={id}>
    <TableCell>
      <ArcView className="shimmer" padding="16" />
    </TableCell>
    <TableCell>
      <ArcView className="shimmer" padding="16" />
    </TableCell>
    <TableCell>
      <ArcView className="shimmer" padding="16" />
    </TableCell>
    <TableCell>
      <ArcView className="shimmer" padding="16" />
    </TableCell>
    <TableCell>
      <ArcView className="shimmer" padding="16" />
    </TableCell>
  </TableRow>
));

class ActivityHistoryList extends React.PureComponent {
  static propTypes = {
    activities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
    isRequesting: PropTypes.bool,
    onChangePage: PropTypes.func,
    onChangeRowsPerPage: PropTypes.func,
    onClickActivity: PropTypes.func,
    onClickDeleteActivity: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    totalActivities: PropTypes.number,
  };

  static defaultProps = {
    activities: null,
    isRequesting: false,
    onChangePage: global.noop,
    onChangeRowsPerPage: global.noop,
    onClickActivity: global.noop,
    onClickDeleteActivity: global.noop,
    page: 0,
    rowsPerPage: 50,
    totalActivities: 0,
  };

  handleChangePage = (e, page) => {
    this.props.onChangePage(page);
  };

  handleChangeRowsPerPage = e => {
    const rowsPerPage = parseInt(e.target.value, 10);

    this.props.onChangeRowsPerPage(rowsPerPage);
  };

  renderActivityListItem = (activity, index) => (
    <ActivityHistoryListItem
      key={activity.id}
      shade={index % 2 === 0}
      onClickActivity={this.props.onClickActivity}
      onClickDeleteActivity={this.props.onClickDeleteActivity}
      {...activity}
    />
  );

  renderColumnHeadings = heading => (
    <TableCell key={heading}>{heading}</TableCell>
  );

  renderResults() {
    const { activities } = this.props;

    const hasResults = activities.length >= 1;

    return hasResults
      ? activities.map(this.renderActivityListItem)
      : noResultPlaceholder;
  }

  render() {
    const { page, rowsPerPage, totalActivities } = this.props;

    return (
      <ArcView style={{ width: '100%', padding: 8 }}>
        <ArcScroll isHorizontal>
          <Table>
            <TableHead>
              <TableRow>{headings.map(this.renderColumnHeadings)}</TableRow>
            </TableHead>
            <TableBody>
              {this.props.isRequesting ? placeholder : this.renderResults()}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalActivities}
            rowsPerPage={rowsPerPage}
            page={page}
            rowsPerPageOptions={[25, 50, 100]}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} of ${count}`
            }
          />
        </ArcScroll>
      </ArcView>
    );
  }
}

export default ActivityHistoryList;
