import React from 'react';
import PropTypes from 'prop-types';

import navigateTo from 'arcade-frontend-core/src/helpers/navigateTo';

import ArcLink from '../../elements/ArcLink';

class ArcInternalLink extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    href: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    title: PropTypes.string,
  };

  static defaultProps = {
    onClick: undefined,
    title: '',
  };

  handleClick = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (this.props.onClick) {
      this.props.onClick(e);
    }

    if (this.props.href) {
      navigateTo(this.props.href);
    }
  };

  render() {
    const {
      children,
      title,
      ...rest
    } = this.props;

    return (
      <ArcLink
        {...rest}
        href={null}
        onClick={this.handleClick}
        title={title}
      >
        {children || title}
      </ArcLink>
    );
  }
}

export default ArcInternalLink;
