import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Contacts from '@material-ui/icons/Contacts';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';

const styleNavItemIcon = {
  padding: '16px 8px',
};

const styleNavButton = {
  width: 24,
  height: 24,
  padding: 0,
  margin: '0 4px',
};

const getColor = isActive => (isActive ? 'primary' : 'disabled');

const ArcCardNavItemIcon = ({ icon: Icon, onClick, isActive, color }) => (
  <ArcView style={styleNavItemIcon}>
    <IconButton
      style={styleNavButton}
      onClick={onClick}
      disableRipple
    >
      {Icon && (
        <Icon color={typeof color !== 'undefined' ? color : getColor(isActive)} />
      )}
    </IconButton>
  </ArcView>
);

ArcCardNavItemIcon.propTypes = {
  icon: ArcPropTypes.children,
  onClick: ArcPropTypes.func,
  isActive: ArcPropTypes.bool,
  color: ArcPropTypes.string,
};

ArcCardNavItemIcon.defaultProps = {
  icon: Contacts,
  onClick: ArcPropTypes.noop,
  isActive: false,
  color: undefined,
};

export default ArcCardNavItemIcon;
