import sagas from 'arcade-frontend-core/src/helpers/sagas';
import { actions, types } from 'arcade-frontend-games/src/actions';
import { api } from '../resources/games';

const takeLatest = [
  {
    type: types.GAMES_INDEX,
    call: api.index,
  },
  {
    type: types.GAMES_EVENTS_INDEX,
    call: api.events,
  },
  {
    type: types.GAMES_ACTIVITIES_CREATE,
    call: api.createActivity,
    onSuccess: actions.apiGamesIndexRequest,
  },
];

const takeEvery = [
  {
    type: types.GAMES_RANKINGS_INDEX,
    call: api.rankings,
  },
  {
    type: types.GAMES_GET,
    call: api.game,
  },
];

const games = [
  ...takeLatest.map(sagas.makeTakeLatest),
  ...takeEvery.map(sagas.makeTakeEvery),
];

export default games;
