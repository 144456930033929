import React from 'react';

import { G, Path } from 'react-primitives-svg';
import Icon from '../Icon';

const RefreshOutline = props => (
  <Icon {...props}>
    <Path
      clipRule="evenodd"
      fill="currentColor"
      stroke="none"
      d="M23.343 10.578a.894.894 0 00-1.766.282l.003.016c.91 5.359-2.696 10.441-8.055 11.352-5.36.91-10.442-2.696-11.352-8.055C1.263 8.813 4.87 3.73 10.228 2.82a9.843 9.843 0 018.908 3.057l-3.96 1.32a.894.894 0 10.565 1.697l5.364-1.789a.894.894 0 00.611-.848V.894a.894.894 0 00-1.788 0V4.16C15.324-.22 8.042-.037 3.664 4.567c-4.38 4.604-4.197 11.886.407 16.265 4.604 4.378 11.886 4.196 16.265-.408a11.504 11.504 0 003.007-9.846z"
    />
  </Icon>
);

export default RefreshOutline;
