import React from 'react';
import { Platform } from 'react-native';
import { Provider } from 'react-redux';

import {
  ArcPropTypes,
  ArcThemeProvider,
  ArcErrorBoundary,
} from 'arcade-frontend-ui';

import { ArcUserProvider } from 'arcade-frontend-auth';
import RollbarProvider from 'arcade-frontend-ui/src/providers/RollbarProvider';

import store from './store';

// ArcProviders is used within native so this check ensures that
// it will only block within web (and not within tests either)
let angularHasBootstrapped =
  Platform.OS !== 'web' || process.env.NODE_ENV === 'test';

class ArcProviders extends React.PureComponent {
  static propTypes = {
    children: ArcPropTypes.children.isRequired,
    store: ArcPropTypes.object, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    store: undefined,
  };

  state = {
    angularIsReady: angularHasBootstrapped,
  };

  componentDidMount() {
    if (window.angular && !angularHasBootstrapped) {
      angularHasBootstrapped = true;

      window.angular.bootstrap(document.getElementById('angular'), ['desktop']);
      this.setAngularIsReady(true);
    }
  }

  setAngularIsReady = angularIsReady => this.setState({ angularIsReady });

  render() {
    return (
      <Provider store={this.props.store || store}>
        <ArcUserProvider>
          <ArcThemeProvider>
            <React.Fragment>
              <RollbarProvider />
              <ArcErrorBoundary>
                {this.state.angularIsReady && this.props.children}
              </ArcErrorBoundary>
            </React.Fragment>
          </ArcThemeProvider>
        </ArcUserProvider>
      </Provider>
    );
  }
}

export default ArcProviders;
