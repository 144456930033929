function copyToClipboard(val) {
  const dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.setAttribute('style', 'position: absolute; z-index: -1');
  dummy.setAttribute('id', 'clipboardInput');
  document.getElementById('clipboardInput').value = val;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
}

export default copyToClipboard;
