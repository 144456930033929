import React, { FC } from 'react';
import useSWR from 'swr';

import { arcadeApiClient } from 'arcade-frontend-core/src/helpers/arcadeApiClient';
import {
  ArcFormDateRangePicker,
  ArcFormDateRangePickerProps,
} from 'arcade-frontend-ui/src/forms/ArcFormDateRangePicker';

const fetcher = url =>
  arcadeApiClient.get(url).then(({ data: { options } }) => options);

export const ArcFormDateRangePickerContainer: FC<ArcFormDateRangePickerProps> = props => {
  const { data, error } = useSWR('/filters/time_periods', fetcher)

  return (
    <ArcFormDateRangePicker
      data-testid="arc-form-date-range-picker"
      filterOptions={data}
      disabled={!!error || !data}
      style={{
        minWidth: 90,
      }}
      {...props}
    />
  );
};
