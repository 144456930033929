import React from 'react';
import Clear from '@material-ui/icons/Clear';
import IconInsertChart from '@material-ui/icons/InsertChart';
import {
  ArcView,
  ArcText,
  ArcPropTypes,
  ArcIconButton,
} from 'arcade-frontend-ui';
// import ChatSession from '../../store/chat_session';


const containerStyle = {
  position: 'absolute',
  top: '10px',
  zIndex: 9999,
  width: '90%',
  left: '5%',
};
const notificationStyle = {
  marginTop: '20px',
  backgroundColor: 'white',
  boxShadow: '0 3px 30px rgba(10,10,10,.8)',
  padding: '20px',
  borderRadius: '10px',
  color: 'rgb(102, 102, 102)',
  fontWeight: 500,
  fontSize: '20px',
  position: 'relative',
  height: '64px',
  lineHeight: '24px',
};
const closeButtonStyle = {
  borderRadius: '0px',
  position: 'absolute',
  top: '0px',
  right: '0px',
  width: '64px',
  height: '64px',
  fontSize: '36px',
  color: 'red',
};
const styleIcon = {
  height: 24,
  width: 24,
  marginRight: 8,
  color: 'green',
};

const Notification = ({ notification, onClose }) => (
  <ArcView row align={'center'} style={notificationStyle}>
    <IconInsertChart style={styleIcon} />
    <ArcText>{ notification.label }</ArcText>
    <ArcIconButton
      onClick={() => onClose(notification.id)}
      style={closeButtonStyle}
      disableRipple
    >
      <Clear
        color="disabled"
        fontSize="inherit"
      />
    </ArcIconButton>
  </ArcView>
);

Notification.propTypes = {
  notification: ArcPropTypes.shape({
    id: ArcPropTypes.string.isRequired,
    label: ArcPropTypes.string.isRequired,
  }).isRequired,
  onClose: ArcPropTypes.func.isRequired,
};

export default class NotificationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [
        // Examples
        // { id: '1', label: 'YOU WON A GAME!' },
        // { id: '2', label: 'Another notification!' },
      ],
    };

    // this.chatSession = new ChatSession();
    // this.chatSession.on('notification', (event) => {
    //   console.log('notification', event);
    // });
  }

  onClose = (id) => {
    this.setState({ notifications: this.state.notifications.filter(n => n.id !== id) });
  }

  render() {
    return (
      <ArcView style={containerStyle}>
        {this.state.notifications.map(notification => (
          <Notification key={notification.id} notification={notification} onClose={this.onClose} />
        ))}
      </ArcView>
    );
  }
}
