import { types } from '../actions';

const handleAction = (state, action) => action.payload;

const initialState = false;

const appReady = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_APP_READY:
    case types.WEBVIEW_SET_APP_READY:
      return handleAction(state, action);
    default:
      return state;
  }
};

export const getAppReady = state => state.app.appReady;

export default appReady;
