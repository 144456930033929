import { combineReducers } from 'redux';

import app from 'arcade-frontend-core/src/reducers';
import { uppy } from 'arcade-frontend-widgets/src/reducers/uppy';
import audiences from 'arcade-frontend-widgets/src/reducers/audiences';
import customAudience from 'arcade-frontend-widgets/src/reducers/customAudience';
import uploadManager from 'arcade-frontend-widgets/src/reducers/uploadManager';
import files from 'arcade-frontend-widgets/src/reducers/files';
import videos from 'arcade-frontend-widgets/src/reducers/videos';
import search from 'arcade-frontend-widgets/src/reducers/search';
import rewards from 'arcade-frontend-rewards/src/reducers';
import people from 'arcade-frontend-people/src/reducers';
import games from 'arcade-frontend-games/src/reducers';
import gamesV2 from 'arcade-frontend-games-v2/src/reducers';
import metricsPackage from 'arcade-frontend-metrics/src/reducers';

import { reducers as newsfeed } from 'arcade-frontend-newsfeed';
// eslint-disable-next-line import/no-unresolved
import { rootReducers as sharedReducers } from '@workplacearcade/web/redux/rootReducer';

import newForms from '../forms';

import location from './location';
import metrics from './metrics';
import manage from './manage';
import platform from './platform';
import webview from './webview';
import mobilechat from '../../../mobile/src/store/chat/reducer';

const appReducer = combineReducers({
  customAudience,
  uppy,
  uploadManager,
  files,
  videos,
  search,
  audiences,
  newsfeed,
  location,
  app,
  mobilechat,
  games,
  gamesV2,
  metrics,
  manage,
  people,
  platform,
  rewards,
  // forms,
  webview,
  newForms,
  metricsPackage,
  ...sharedReducers,
});

const rootReducer = (state, action) => {
  const nextState =
    action.type === 'LOGOUT'
      ? { app: state.app, location: state.location, uppy: state.uppy }
      : state;

  return appReducer(nextState, action);
};

export default rootReducer;
