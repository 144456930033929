import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ArcPropTypes } from 'arcade-frontend-ui';
import { ArcTransactionLog } from 'arcade-frontend-ui/src/components/ArcTransactionLog';
import track from 'arcade-frontend-core/src/helpers/track';

import { actions } from '../actions';
import { getIsFetching } from '../reducers/isFetching';

class RewardsTransactionsIndexContainer extends React.PureComponent {
  static propTypes = {
    apiRewardsTransactionsIndexRequest: ArcPropTypes.func.isRequired,
    apiRewardsTransactionsIndexByUserIdRequest: ArcPropTypes.func.isRequired,
    transactions: ArcPropTypes.transactions,
    isFetching: ArcPropTypes.bool,
    summary: ArcPropTypes.shape({
      total: ArcPropTypes.number,
      incoming: ArcPropTypes.number,
      outgoing: ArcPropTypes.number,
      change: ArcPropTypes.number,
    }),
    accountName: ArcPropTypes.string,
    accounts: ArcPropTypes.arrayOf(
      ArcPropTypes.shape({
        key: ArcPropTypes.string.isRequired,
        name: ArcPropTypes.string.isRequired,
        balance: ArcPropTypes.number.isRequired,
        transferTypes: ArcPropTypes.arrayOf(
          ArcPropTypes.shape({ code: ArcPropTypes.string.isRequired }),
        ).isRequired,
      }),
    ),
    user: ArcPropTypes.shape({
      id: ArcPropTypes.number,
      name: ArcPropTypes.string,
      profileImage: ArcPropTypes.string,
      monthlyLimit: ArcPropTypes.number,
      remainingBalance: ArcPropTypes.number,
    }),
  };

  static defaultProps = {
    accountName: undefined,
    transactions: [],
    isFetching: true,
    summary: {},
    accounts: [],
    user: {},
  };

  state = {
    accountName: 'holding',
    filter: [],
    user: {},
    dateRange: {
      type: 'time period',
      timePeriod: 'last_30_days',
      fromDate: undefined,
      toDate: undefined,
    },
    people: [],
    page: 0,
    rowsPerPage: 50,
  };

  componentDidMount() {
    const { accountName, filter, dateRange, people, page, rowsPerPage } =
      this.state;

    this.props.apiRewardsTransactionsIndexRequest({
      accountName,
      filter,
      people,
      page,
      rowsPerPage,
      ...dateRange,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.accountName !== this.props.accountName) {
      const filter = this.defaultFilterForAccount(
        nextProps.accounts,
        nextProps.accountName,
      );
      this.updateRequest({
        accountName: nextProps.accountName,
        filter,
        user: nextProps.user,
      });
    }
  }

  setPage = page => {
    this.updateRequest({ page });
  };

  setRowsPerPage = rowsPerPage => {
    this.updateRequest({ rowsPerPage, page: 0 });
  };

  defaultFilterForAccount(accounts, accountName) {
    return this.transferTypes(accounts, accountName).map(({ code }) => code);
  }

  transferTypes = (accounts, accountName) => {
    const account = accounts.find(acct => acct.key === accountName);
    return account ? account.transferTypes : [];
  };

  updateRequest = attributes => {
    this.setState(attributes, () => {
      const {
        accountName,
        filter,
        dateRange,
        user,
        people,
        page,
        rowsPerPage,
      } = this.state;

      if (user && user.id) {
        this.props.apiRewardsTransactionsIndexByUserIdRequest({
          userId: user.id,
          ...dateRange,
        });
      } else {
        this.props.apiRewardsTransactionsIndexRequest({
          accountName,
          filter,
          people,
          page,
          rowsPerPage,
          ...dateRange,
        });
      }
    });
  };

  handleChangeAccountName = accountName => {
    const filter = this.defaultFilterForAccount(
      this.props.accounts,
      accountName,
    );
    this.updateRequest({ accountName, filter, page: 0 });
  };

  handleDateRangeChange = dateRange => {
    track('changed token transaction log date', dateRange);

    this.updateRequest({ dateRange, page: 0 });
  };

  handlePeopleChange = people => {
    const peopleIds = people.map(person => person.id);

    this.updateRequest({ people: peopleIds, page: 0 });
  };

  handleChangeFilter = newFilter => {
    const defaultFilter = this.defaultFilterForAccount(
      this.props.accounts,
      this.state.accountName,
    );
    const filter = newFilter.length === 0 ? defaultFilter : newFilter;

    track('changed transaction log filter', {
      account: this.state.accountName,
      filter,
    });
    this.updateRequest({ filter, page: 0 });
  };

  handleDeleteUserFilter = () => {
    this.updateRequest({ user: null, page: 0 });
  };

  render() {
    const { accounts, summary, transactions, isFetching } = this.props;
    const { filter, accountName, user, dateRange, page, rowsPerPage } =
      this.state;

    return (
      <ArcTransactionLog
        accounts={accounts}
        transactions={transactions}
        accountName={accountName}
        user={user}
        onChangeAccountName={this.handleChangeAccountName}
        dateRange={dateRange}
        onDateRangeChange={this.handleDateRangeChange}
        onPeopleChange={this.handlePeopleChange}
        onChangeFilter={this.handleChangeFilter}
        onDeleteUserFilter={this.handleDeleteUserFilter}
        isFetching={isFetching}
        transferTypes={this.transferTypes(accounts, accountName)}
        filter={filter}
        summary={summary}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={this.setPage}
        setRowsPerPage={this.setRowsPerPage}
      />
    );
  }
}

const getState = (state, props) => ({
  ...state.rewards,
  transactions: state.rewards.transactions.map(
    id => state.rewards.transactionsById[id],
  ),
  isFetching: getIsFetching(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      apiRewardsTransactionsIndexRequest:
        actions.apiRewardsTransactionsIndexRequest,
      apiRewardsTransactionsIndexByUserIdRequest:
        actions.apiRewardsTransactionsIndexByUserIdRequest,
      apiRewardsAccountsIndexRequest: actions.apiRewardsAccountsIndexRequest,
    },
    dispatch,
  );

export default connect(getState, getActions)(RewardsTransactionsIndexContainer);
