import React from 'react';
import PropTypes from 'prop-types';
import StarIcon from '@material-ui/icons/StarRounded';

import {
  ArcView,
  ArcImage,
  ArcMarkdownContent,
  ArcText,
  ArcEntity,
  createWithStyles,
} from 'arcade-frontend-ui';

import { activityRecord } from '../../propTypes';
import Portrait from '../Portrait';

const OFFSET_STAR = '-248px, -42px';

const styles = {
  Title: theme => ({
    root: {
      fontSize: 24,
      textAlign: 'center',
      marginBottom: theme.spacing(1),
    },
  }),
  TokenImage: theme => ({
    root: {
      width: 40,
      height: 40,
      marginRight: theme.spacing(1),
    },
  }),
  Recipients: theme => ({
    root: {
      marginBottom: theme.spacing(2),
      color: theme.palette.grey.main,
      cursor: 'pointer',
    },
  }),
  Star: theme => ({
    root: {
      position: 'absolute',
      transform: 'translateX(-50%)',
      width: 300,
      height: 300,
      color: theme.palette.orange.main,
      opacity: 0.3,
    },

    background: {
      opacity: 0.1,
      width: 250,
      height: 250,
      bottom: 55,
    },

    right: {
      transform: 'rotate(10deg)',
    },

    left: {
      transform: `rotate(-10deg) translate(${OFFSET_STAR})`,
    },
  }),
  PortraitWrapper: () => ({
    root: {
      position: 'absolute',
      transform: 'translate(-50%, 100px)',
    },
  }),
  Centered: theme => ({
    root: {
      position: 'relative',
      marginLeft: '50%',
      height: 300,
      marginTop: theme.spacing(-5),
      marginBottom: theme.spacing(-5),
    },
  }),
  Body: () => ({
    root: {
      textAlign: 'center',
    },
  }),
};
const Title = createWithStyles(styles.Title)(ArcView);
const TokenImage = createWithStyles(styles.TokenImage)(ArcImage);
const Recipients = createWithStyles(styles.Recipients)(ArcView);
const Star = createWithStyles(styles.Star)(StarIcon);
const Centered = createWithStyles(styles.Centered)(ArcView);
const PortraitWrapper = createWithStyles(styles.PortraitWrapper)(ArcView);
const Body = createWithStyles(styles.Body)(ArcView);

const RecognitionActivity = ({ activity, onQuicklinkUser }) => {
  const { additionalInformation, content } = activity;
  const { receivingUser, tokenType, tokenImage } = additionalInformation;

  const handleRecipientClick = () => {
    onQuicklinkUser(receivingUser.id, 'person');
  };

  return (
    <ArcView>
      <Centered>
        <Star background right />
        <Star />
        <Star background left />
        <PortraitWrapper>
          <Portrait
            onClick={handleRecipientClick}
            url={receivingUser.image}
            size={120}
            badge={receivingUser.badge}
            badgeSize={40}
            offset={-16}
          />
        </PortraitWrapper>
      </Centered>
      <Body align="center">
        <Title row align="center">
          <TokenImage src={tokenImage} /> {tokenType} Star
        </Title>
        <Recipients row align="center" onClick={handleRecipientClick}>
          <ArcText isSmall textTransform="uppercase">
            Awarded to
          </ArcText>
          <ArcEntity
            type="person"
            imageUrl={receivingUser.image}
            name={receivingUser.name}
          />
        </Recipients>
        <ArcMarkdownContent content={content} />
      </Body>
    </ArcView>
  );
};

RecognitionActivity.displayName = 'RecognitionActivity';

RecognitionActivity.propTypes = {
  activity: activityRecord.isRequired,
  onQuicklinkUser: PropTypes.func,
};

RecognitionActivity.defaultProps = {
  onQuicklinkUser: global.noop,
};

export default RecognitionActivity;
