import React from 'react';
import PropTypes from 'prop-types';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import * as GAME_FORMATS from 'arcade-frontend-core/src/types/game-formats';
import { date } from 'arcade-frontend-ui';

function GamesGamePositionHelperText({
  currentPlayerPosition,
  gameType,
  numParticipants,
  ...props
}) {
  return (
    <ArcBox display="flex" flexDirection="column" {...props}>
      <ArcBox
        component={ArcText}
        lineHeight="150%"
        color="#00BA8C"
        fontSize={24}
        fontWeight={600}
      >
        {gameType === GAME_FORMATS.BOUNTY
          ? currentPlayerPosition
          : date.getOrdinal(currentPlayerPosition)}
      </ArcBox>
      <ArcBox
        component={ArcText}
        lineHeight="150%"
        color="#616264"
        fontSize={14}
      >
        {`of ${numParticipants} Players`}
      </ArcBox>
      {gameType === GAME_FORMATS.BOUNTY && (
        <ArcBox
          component={ArcText}
          lineHeight="150%"
          color="#616264"
          fontSize={14}
        >
          {'at goal'}
        </ArcBox>
      )}
    </ArcBox>
  );
}

GamesGamePositionHelperText.displayName = 'GamesGamePositionHelperText';

GamesGamePositionHelperText.propTypes = {
  currentPlayerPosition: PropTypes.number,
  gameType: PropTypes.oneOf(GAME_FORMATS.GAME_TYPES),
  numParticipants: PropTypes.number,
};

GamesGamePositionHelperText.defaultProps = {
  gameType: GAME_FORMATS.BOUNTY,
  currentPlayerPosition: 0,
  numParticipants: 0,
};

export default GamesGamePositionHelperText;
