import React from 'react';
import RewardsTransactionsIndexContainer from 'arcade-frontend-rewards/src/containers/RewardsTransactionsIndexContainer';

import ArcMainView from 'arcade-frontend-ui/src/layout/ArcMainView';

export default function ManageTransactionsView() {
  return (
    <ArcMainView isViewing>
      <RewardsTransactionsIndexContainer />
    </ArcMainView>
  );
}
