import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const handleRewardsTransactionsIndexSuccess = (_state, action) => {
  const { data } = action.payload;
  const { transactions } = data;
  const newState = [];

  transactions.forEach(transaction => {
    newState.push(transaction.id);
  });

  return newState;
};

const initialState = [];

const handlers = {
  [types.REWARDS_TRANSACTIONS_INDEX
    .SUCCESS]: handleRewardsTransactionsIndexSuccess,
  [types.REWARDS_TRANSACTIONS_INDEX_BY_USER_ID
    .SUCCESS]: handleRewardsTransactionsIndexSuccess,
};

const transactions = createReducer(initialState, handlers);

export default transactions;

export const getTransactions = state => ({
  transactions: state.rewards.transactions.map(
    id => state.transactionsById[id],
  ),
});
