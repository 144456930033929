let blue = {
  100: '#F0FDFF',
  200: '#CFF2FF',
  300: '#A8E9FF',
  400: '#52D5FF',
  // 500: '#26B8EA',
  500: '#0290C3',
  600: '#0184B5',
  700: '#065B8E',
  800: '#004467',
};
blue = {
  ...blue,
  tint: blue[100],
  light: blue[400],
  main: blue[500],
  dark: blue[800],
  contrast: '#ffffff',
};

let green = {
  100: '#F0FFF8',
  200: '#D2FFF6',
  300: '#A8FFEE',
  400: '#52FFD8',
  500: '#11E0B7',
  600: '#008E72',
  700: '#007B71',
  800: '#00524A',
};
green = {
  ...green,
  tint: green[100],
  light: green[400],
  main: green[600],
  dark: green[800],
  contrast: '#ffffff',
};

let red = {
  100: '#FDF2F1',
  200: '#FFD5CD',
  300: '#FFAC9E',
  400: '#FF6950',
  500: '#FD3A27',
  600: '#C11300',
  700: '#801200',
  800: '#520B00',
};
red = {
  ...red,
  tint: red[100],
  light: red[400],
  main: red[500],
  dark: red[800],
  contrast: '#ffffff',
};

let yellow = {
  100: '#FFF8EE',
  200: '#FFF8CD',
  300: '#FFF2A1',
  400: '#FFEA54',
  500: '#FFE215',
  600: '#FFCA13',
  700: '#E69E10',
  800: '#B55803',
};
yellow = {
  ...yellow,
  tint: yellow[100],
  light: yellow[400],
  main: yellow[600],
  dark: yellow[800],
  contrast: '#ffffff',
};

let purple = {
  100: '#FEF8FF',
  200: '#F9E6FF',
  300: '#E8C4F1',
  400: '#f1b0ff',
  500: '#DA7DEA',
  600: '#BE4BD2',
  700: '#942ca4',
  800: '#6b2577',
};
purple = {
  ...purple,
  tint: purple[100],
  light: purple[400],
  main: purple[600],
  dark: purple[800],
  contrast: '#ffffff',
};

let grey = {
  100: '#F8F8F8',
  200: '#F3F3F3',
  300: '#D8D8D8',
  400: '#C4C4C4',
  500: '#B4B4B4',
  600: '#8C8C8C',
  700: '#666666',
  800: '#353535',
};
grey = {
  ...grey,
  tint: grey[100],
  light: grey[400],
  medium: grey[500],
  main: grey[700],
  dark: grey[800],
  contrast: '#ffffff',
};

export default {
  default: grey,
  primary: blue,
  secondary: green,
  info: purple,
  success: green,
  warning: yellow,
  danger: red,

  blue,
  green,
  red,
  yellow,
  purple,
  grey,

  aqua: blue,
  maroon: purple,
  orange: yellow,
  olive: green,

  text: {
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.12)',
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
  },

  common: {
    black: '#000000',
    white: '#ffffff',
  },

  divider: '#f3f3f3',
};
