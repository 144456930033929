import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const handleQuicklinkLoadSuccess = (state, action) => {
  const { data } = action.payload;

  const {
    email,
    name,
    phone,
    team,
    id,
    is_self, // eslint-disable-line camelcase
    level,
  } = data;

  const user = {
    id: id.toString(),
    email,
    name,
    phone,
    team,
    is_self, // eslint-disable-line camelcase
    level,
    image: data.profile_image,
    userBalance: data.user_balance,
  };

  return user;
};

const initialState = null;

// remove cache from quicklinksUser
const handleQuicklinkReset = () => initialState;

const handlers = {
  [types.PEOPLE_QUICKLINKS.SUCCESS]: handleQuicklinkLoadSuccess,
  [types.RESET_QUICKLINKS_USER]: handleQuicklinkReset,
};

const quicklinkUser = createReducer(initialState, handlers);

export default quicklinkUser;

export const getQuicklinkUser = state => state.people.quicklinkUser;
