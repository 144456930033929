import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { resources } from 'arcade-frontend-core/src/resources/users';

import ManagePeoplePermissionsModal from '../../../../features/src/components/manage/people/ManagePeoplePermissionsModal';
import { getPersonById } from '../../reducers/people';

const ANIMATION_DELAY = 500;

function PeopleManagePermissionsModalContainer({ id, open, onClose }) {
  const apiGetUserPermissions = useResourceAction(
    resources.apiGetUserPermissions,
  );
  const apiPutUserPermissions = useResourceAction(
    resources.apiPutUserPermissions,
  );
  const person = useSelector(getPersonById(id));

  React.useEffect(() => {
    if (!!id && open) {
      apiGetUserPermissions
        .requestData({
          resources: [{ id }],
        })
        .catch(() => {
          apiGetUserPermissions.onStatusReset();
        });
    }
  }, [id, open]);

  const handleSubmit = (userId, permissions) => {
    apiPutUserPermissions
      .requestData({
        resources: [{ id: userId, permissions }],
      })
      .then(() => {
        setTimeout(() => {
          onClose();
          apiPutUserPermissions.onStatusReset();
        }, ANIMATION_DELAY);
      })
      .catch(global.noop);
  };

  const handleRetry = () => {
    apiGetUserPermissions.onErrorReset();
    apiGetUserPermissions.onStatusReset();

    apiGetUserPermissions
      .requestData({
        resources: [{ id }],
      })
      .catch(() => {
        apiGetUserPermissions.onStatusReset();
      });
  };

  return (
    <ManagePeoplePermissionsModal
      open={open}
      permissions={
        apiGetUserPermissions.hasData
          ? apiGetUserPermissions.data.resources[0]
          : null
      }
      getPermissionsRequestStatus={apiGetUserPermissions.status}
      updatePermissionRequestStatus={apiPutUserPermissions.status}
      getPermissionRequestHasError={apiGetUserPermissions.hasError}
      person={person}
      onClose={onClose}
      onSubmit={handleSubmit}
      onRetry={handleRetry}
    />
  );
}

PeopleManagePermissionsModalContainer.displayName =
  'PeopleManagePermissionsModalContainer';

PeopleManagePermissionsModalContainer.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

PeopleManagePermissionsModalContainer.defaultProps = {
  id: '',
  open: false,
  onClose: global.noop,
};

export default PeopleManagePermissionsModalContainer;
