import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../../actions/manage/metrics/types';

const handleTeamsLoadSuccess = (state, action) => {
  if (!action.payload) return state;

  const { data } = action.payload;

  const teamIdList = data.map(team => team.id);

  return teamIdList;
};

const initialState = [];

const handlers = {
  [types.MANAGE_METRICS_TEAMS_INDEX.SUCCESS]: handleTeamsLoadSuccess,
};

const teamIds = createReducer(initialState, handlers);

export default teamIds;

export const getManageMetricsTeamIds = state => state.manage.metrics.teamIds;
