const removeAllChildren = (element) => {
  while (element.hasChildNodes()) {
    element.removeChild(element.lastChild);
  }
};

class ArcAppBar {
  constructor() {
    if (document) {
      this.ngHeader = document.querySelector('.mdl-layout__header');

      this.container = document.createElement('div');
      this.leftBtn = document.createElement('div');
      this.title = document.createElement('div');
      this.rightBtn = document.createElement('div');
    }

    this.container.classList.add('ReactAppBar');
    this.leftBtn.classList.add('ReactAppBar__LeftBtn');
    this.title.classList.add('ReactAppBar__Title');
    this.rightBtn.classList.add('ReactAppBar__RightBtn');

    this.container.appendChild(this.leftBtn);
    this.container.appendChild(this.title);
    this.container.appendChild(this.rightBtn);

    this.hasMounted = true;
    this.backBtn = null;
    this.ngHeader.appendChild(this.container);
  }

  setLeftBtn(element) {
    removeAllChildren(this.leftBtn);
    this.leftBtn.appendChild(element);
  }

  setTitle(element) {
    removeAllChildren(this.title);
    this.title.appendChild(element);
  }

  setTitleText(text) {
    this.title.innerHTML = text;
  }

  setRightBtn(element) {
    removeAllChildren(this.rightBtn);
    this.rightBtn.appendChild(element);
  }

  show() {
    this.ngHeader.classList.add('is-viewing');
  }

  hide() {
    this.ngHeader.classList.remove('is-viewing');
  }

  createBackButton(link) {
    if (!document) return;

    if (!this.backBtn || this.backBtn.attributes.href.value === link) {
      const backBtn = document.createElement('a');
      backBtn.innerHTML = '<span class="btn-icon hide-mobile">chevron_left</span>';
      backBtn.innerHTML += '<span class="btn-icon show-mobile">close</span>';
      backBtn.setAttribute('href', link);
      backBtn.setAttribute('class', 'ChatBack material-icons');

      this.backBtn = backBtn;
      this.setLeftBtn(backBtn);
    }
  }
}

export default ArcAppBar;
