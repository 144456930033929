import React from 'react';
import Typography from '@material-ui/core/Typography';
import DayPicker, { DateUtils } from 'react-day-picker';

import * as date from '../../helpers/utils/date';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcButton from '../ArcButton';
import ArcView from '../../primitives/ArcView';

const styleButton = {
  flex: 1,
  padding: 16,
};

const styleCaption = {
  borderBottom: '1px solid #f3f3f3',
  paddingBottom: 16,
};

class ArcDateRange extends React.PureComponent {
  static propTypes = {
    maxDate: ArcPropTypes.date,
    minDate: ArcPropTypes.date,
    onChange: ArcPropTypes.func,
    onCancel: ArcPropTypes.func,
    onOk: ArcPropTypes.func,
    value: ArcPropTypes.shape({
      from: ArcPropTypes.date,
      to: ArcPropTypes.date,
    }),
  };

  static defaultProps = {
    maxDate: undefined,
    minDate: undefined,
    onChange: ArcPropTypes.noop,
    onCancel: ArcPropTypes.noop,
    onOk: ArcPropTypes.noop,
    value: {
      from: null,
      to: null,
    },
  };

  state = {
    range: this.props.value,
  };

  getDisabledDays = (day) => {
    const { maxDate, minDate } = this.props;

    if (maxDate && minDate) {
      return (
        DateUtils.isDayAfter(day, maxDate)
        && DateUtils.isDayBefore(day, minDate)
      );
    }

    if (maxDate) {
      return DateUtils.isDayAfter(day, maxDate);
    }

    if (!minDate) {
      return null;
    }

    return DateUtils.isDayBefore(day, minDate);
  };

  setRange = range => this.setState({ range });

  handleDayClick = (day) => {
    if (this.getDisabledDays(day)) {
      return;
    }

    const startOfDay = date.startOfDay(day);
    const range = DateUtils.addDayToRange(startOfDay, this.state.range);
    this.setRange(range);
  };

  handleClickCancel = () => {
    this.props.onCancel();
  };

  handleClickReset = () => {
    const range = { from: null, to: null };

    this.setRange(range);
    this.props.onChange(range);
  };

  handleClickOk = () => {
    this.props.onChange(this.state.range);
    this.props.onOk();
  };

  renderDefaultMessage() {
    return (
      <ArcView row justify="center" style={styleCaption}>
        <Typography variant="caption">
          Select {this.state.range.from ? 'another date' : 'two dates'}
        </Typography>
      </ArcView>
    );
  }

  render() {
    const { from, to } = this.state.range;

    const modifiers = {
      start: from,
      end: to,
    };

    const isValid = !!from && !!to;

    return (
      <ArcView className="ArcDateRange">
        <DayPicker
          className="Selectable"
          modifiers={modifiers}
          numberOfMonths={1}
          onDayClick={this.handleDayClick}
          disabledDays={this.getDisabledDays}
          selectedDays={[from, { from, to }]}
          showOutsideDays
          fixedWeeks
        />

        {!from || !to ? (
          this.renderDefaultMessage()
        ) : (
          <ArcView row justify="center" style={styleCaption}>
            <Typography variant="caption">
              {from && from.toLocaleDateString()}
            </Typography>

            <Typography variant="caption">&nbsp;&mdash;&nbsp;</Typography>

            <Typography variant="caption">
              {to && to.toLocaleDateString()}
            </Typography>
          </ArcView>
        )}

        <ArcView row>
          <ArcButton
            onClick={isValid ? this.handleClickReset : this.handleClickCancel}
            style={styleButton}
          >
            {isValid ? 'Reset' : 'Cancel'}
          </ArcButton>

          <ArcButton
            color="secondary"
            onClick={this.handleClickOk}
            style={styleButton}
            disabled={!isValid}
          >
            Ok
          </ArcButton>
        </ArcView>
      </ArcView>
    );
  }
}

export default ArcDateRange;
