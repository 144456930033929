import React from 'react';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import pluralize from 'pluralize';

import {
  ArcView,
  ArcPropTypes,
  ArcPlaceholder,
  ArcResponsiveDialog,
  ArcParagraph,
  ArcButton,
  ArcDateTime,
} from 'arcade-frontend-ui';
import ArcConfirmDialog from 'arcade-frontend-ui/src/components/ArcConfirmDialog';
import { uniqByAttribute } from 'arcade-frontend-ui/src/helpers/utils/array';

import { userRecord } from '../propTypes';
import Portrait from './Portrait';
import {
  PAPER_PROPS,
  Title,
  TitleLabel,
  Content,
  Warning,
  WarningMessage,
  WarningGraphic,
  DialogActions,
  Count,
  UserRow,
  PortraitLoading,
  Details,
  Username,
  CreatedAt,
  LoadingCount,
  PinPostContent,
} from './ActivityDialogs.styles';

const BasicDialog = props => {
  const { title, children, onClose, buttons, ...rest } = props;
  return (
    <ArcResponsiveDialog onClose={onClose} PaperProps={PAPER_PROPS} {...rest}>
      {!!title && (
        <Title>
          <TitleLabel>{title}</TitleLabel>
          <ArcButton size="small" onClick={onClose}>
            <CloseIcon color="action" fontSize="small" />
          </ArcButton>
        </Title>
      )}
      <Content>{children}</Content>
      {!!buttons && <DialogActions>{buttons}</DialogActions>}
    </ArcResponsiveDialog>
  );
};
BasicDialog.propTypes = {
  title: ArcPropTypes.string,
  buttons: ArcPropTypes.node,
  children: ArcPropTypes.node,
  onClose: ArcPropTypes.func.isRequired,
};
BasicDialog.defaultProps = {
  buttons: null,
  children: null,
  title: null,
};

const LoadingUser = () => (
  <UserRow>
    <PortraitLoading>
      <ArcPlaceholder padding="24" marginBottom="8" />
    </PortraitLoading>
    <Details>
      <Username>
        <ArcPlaceholder
          padding="24"
          marginLeft="16"
          marginRight="24"
          marginBottom="8"
        />
      </Username>
    </Details>
  </UserRow>
);

const LoadingUsers = () => (
  <React.Fragment>
    <LoadingCount>
      <ArcPlaceholder padding="24" marginBottom="8" />
    </LoadingCount>
    <LoadingUser />
    <LoadingUser />
    <LoadingUser />
  </React.Fragment>
);

const User = ({ user, verb, onUserClicked }) => {
  const { username, userImage, createdAt } = user;

  return (
    <UserRow onClick={onUserClicked}>
      <Portrait url={userImage} size={34} />
      <Details>
        <Username>{username}</Username>
        <CreatedAt>
          {verb} {moment(createdAt).fromNow()}
        </CreatedAt>
      </Details>
    </UserRow>
  );
};
User.propTypes = {
  user: userRecord.isRequired,
  verb: ArcPropTypes.string.isRequired,
  onUserClicked: ArcPropTypes.func.isRequired,
};

const UserDialog = ({
  users,
  title,
  verb,
  isRequesting,
  onQuicklinkUser,
  ...rest
}) => {
  const usersCount = users ? users.length : 0;
  const usersCountText = `${usersCount} ${pluralize(
    'people',
    usersCount,
  )} ${pluralize('have', usersCount)} ${verb} this activity`;
  const acknowledgementsText = `This post has been acknowledged ${usersCount} ${pluralize(
    'times',
    usersCount,
  )}`;
  const countDisplayText =
    title === 'Acknowledgements' ? acknowledgementsText : usersCountText;

  const handleUserClicked = id => onQuicklinkUser(id, 'person');

  const filteredUsers = users ? uniqByAttribute(users, 'userId') : [];

  return (
    <BasicDialog {...rest} title={title}>
      {isRequesting ? (
        <LoadingUsers />
      ) : (
        <ArcView>
          <Count>{countDisplayText}</Count>
          {filteredUsers.map(user => (
            <User
              verb={verb}
              key={user.userId}
              user={user}
              onUserClicked={() => handleUserClicked(user.userId)}
            />
          ))}
        </ArcView>
      )}
    </BasicDialog>
  );
};
UserDialog.propTypes = {
  users: ArcPropTypes.arrayOf(userRecord),
  verb: ArcPropTypes.string.isRequired,
  title: ArcPropTypes.string.isRequired,
  isRequesting: ArcPropTypes.bool,
  onQuicklinkUser: ArcPropTypes.func,
};
UserDialog.defaultProps = {
  users: null,
  isRequesting: false,
  onQuicklinkUser: global.noop,
};

const minDate = moment(new Date()).startOf('day').add(1, 'd').toDate();

class PinPostDialog extends React.Component {
  static propTypes = {
    onClose: ArcPropTypes.func.isRequired,
    onPin: ArcPropTypes.func.isRequired,
    open: ArcPropTypes.bool,
    audienceName: ArcPropTypes.string,
  };

  static defaultProps = {
    open: false,
    audienceName: null,
  };

  state = { value: minDate, initialValue: minDate };

  handleChange = value => {
    this.setState({ value });
  };

  handlePin = () => {
    this.props.onPin(this.state.value);
    this.props.onClose();
  };

  renderContent() {
    const { audienceName } = this.props;
    return (
      <PinPostContent>
        <ArcParagraph>
          Select when you want to this post to be pinned until:
        </ArcParagraph>
        <ArcDateTime
          initialDate={this.state.initialValue}
          minDate={this.state.initialValue}
          mode="permanent"
          onChange={this.handleChange}
        />
        <ArcView marginTop24>
          <ArcParagraph>
            {`This post will show to everyone in the selected audience (${audienceName}) at the top of their newsfeed and require them to acknowledge it.`}
          </ArcParagraph>
          <ArcParagraph>
            You will be able to view who has acknowledged the post by viewing
            the menu on the pinned post. You can also use this menu to un-pin
            the post.
          </ArcParagraph>
        </ArcView>
      </PinPostContent>
    );
  }

  render() {
    const { onClose, open } = this.props;

    return (
      <ArcConfirmDialog
        open={open}
        onClose={onClose}
        title="Pin Post"
        content={this.renderContent()}
        confirmLabel="Pin"
        onConfirm={this.handlePin}
        textContent={false}
      />
    );
  }
}

const UnpinPostDialog = ({ onClose, open, onUnpin }) => (
  <BasicDialog
    onClose={onClose}
    open={open}
    buttons={
      <React.Fragment>
        <ArcButton variant="flat" onClick={onClose}>
          Cancel
        </ArcButton>
        <ArcButton
          variant="flat"
          color="warning"
          onClick={() => {
            onUnpin();
            onClose();
          }}
        >
          Unpin
        </ArcButton>
      </React.Fragment>
    }
  >
    <Warning>
      <WarningGraphic />
      <WarningMessage>Are you sure?</WarningMessage>
      <ArcParagraph>
        Unpinning this post will remove the pin for all users.
      </ArcParagraph>
    </Warning>
  </BasicDialog>
);

UnpinPostDialog.propTypes = {
  onClose: ArcPropTypes.func.isRequired,
  onUnpin: ArcPropTypes.func.isRequired,
  open: ArcPropTypes.bool,
};

UnpinPostDialog.defaultProps = {
  open: false,
};

const ReportPostDialog = ({ onClose, open, onConfirm }) => (
  <BasicDialog
    onClose={onClose}
    open={open}
    buttons={
      <React.Fragment>
        <ArcButton variant="flat" onClick={onClose}>
          Cancel
        </ArcButton>
        <ArcButton
          variant="flat"
          color="danger"
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          Report Post
        </ArcButton>
      </React.Fragment>
    }
  >
    <Warning>
      <WarningGraphic />
      <WarningMessage>Are you sure you want to report this?</WarningMessage>
      <ArcParagraph>
        Reporting this will alert administrators of an inappropriate post.
      </ArcParagraph>
    </Warning>
  </BasicDialog>
);

ReportPostDialog.propTypes = {
  onClose: ArcPropTypes.func.isRequired,
  onConfirm: ArcPropTypes.func.isRequired,
  open: ArcPropTypes.bool,
};

ReportPostDialog.defaultProps = {
  open: false,
};

const DeletePostDialog = ({ onClose, open, onConfirm }) => (
  <BasicDialog
    onClose={onClose}
    open={open}
    buttons={
      <React.Fragment>
        <ArcButton variant="flat" onClick={onClose}>
          Cancel
        </ArcButton>
        <ArcButton
          variant="flat"
          color="danger"
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          Delete Post
        </ArcButton>
      </React.Fragment>
    }
  >
    <Warning>
      <WarningGraphic />
      <WarningMessage>Are you sure?</WarningMessage>
      <ArcParagraph>Deleting this post will remove it entirely.</ArcParagraph>
    </Warning>
  </BasicDialog>
);

DeletePostDialog.propTypes = {
  onClose: ArcPropTypes.func.isRequired,
  onConfirm: ArcPropTypes.func.isRequired,
  open: ArcPropTypes.bool,
};

DeletePostDialog.defaultProps = {
  open: false,
};

const PromotePostDialog = ({ onClose, open, onConfirm }) => (
  <ArcConfirmDialog
    open={open}
    onClose={onClose}
    title="Share Post"
    content="Are you sure you want to share this post? This will move the post to the protected feed for your organization and allow everyone to view it."
    confirmLabel="Share"
    onConfirm={onConfirm}
  />
);

PromotePostDialog.propTypes = {
  onClose: ArcPropTypes.func.isRequired,
  onConfirm: ArcPropTypes.func.isRequired,
  open: ArcPropTypes.bool,
};

PromotePostDialog.defaultProps = {
  open: false,
};

export {
  BasicDialog,
  UserDialog,
  PinPostDialog,
  UnpinPostDialog,
  ReportPostDialog,
  DeletePostDialog,
  PromotePostDialog,
};
