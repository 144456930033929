import React from 'react';
import { useSelector } from 'react-redux';

import { getLocation } from 'arcade-frontend-features/src/reducers/location';
import { ACTIVE } from 'arcade-frontend-core/src/types/game-statuses';
import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';

import { resources } from '../../resources/games';
import GamesGameCardList from '../../components/GamesGameCardList';
import {
  getActiveGamesByInterest,
  getActiveGamesStatus,
} from '../../reducers/games';

function GamesActiveListContainer(props) {
  const apiGetActiveGamesIndex = useResourceAction(
    resources.apiGetActiveGamesIndex,
  );

  const location = useSelector(getLocation);
  const activeGames = useSelector(getActiveGamesByInterest);
  const activeIndexRequestStatus = useSelector(getActiveGamesStatus);

  React.useEffect(() => {
    if (!location.payload.id) {
      apiGetActiveGamesIndex
        .requestData()
        .then(apiGetActiveGamesIndex.onStatusReset)
        .catch(global.noop);
    }
  }, [location.query]);

  const handleTabReset = () => {
    apiGetActiveGamesIndex
      .requestData()
      .then(apiGetActiveGamesIndex.onStatusReset)
      .catch(global.noop);
  };

  return (
    <GamesGameCardList
      status={ACTIVE}
      title="Active"
      games={activeGames}
      hasData={apiGetActiveGamesIndex.hasData}
      isPending={activeIndexRequestStatus.pending}
      onTabReset={handleTabReset}
      {...props}
    />
  );
}

GamesActiveListContainer.displayName = 'GamesActiveListContainer';

export default GamesActiveListContainer;
