import { createReducer } from 'arcade-frontend-ui';

import * as routeTypes from 'arcade-frontend-core/src/types/routes';
import { types } from '../../../actions/manage/games';

const initialState = false;

const isTrue = () => true;
const isFalse = () => false;

const handlers = {
  [types.MANAGE_GAMES_CREATE.SUCCESS]: isTrue,
  [types.MANAGE_GAMES_TEMPLATE_CREATE.SUCCESS]: isTrue,
  [routeTypes.ROUTE_MANAGE_GAMES_CREATE]: isTrue,
  [routeTypes.ROUTE_MANAGE_GAMES]: isFalse,
};

const isCreating = createReducer(initialState, handlers);

export default isCreating;

export const getManageGamesIsCreating = state => state.manage.games.isCreating;
