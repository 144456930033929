import React from 'react';

import { G, Path } from 'react-primitives-svg';

import Icon from '../Icon';

const User = props => (
  <Icon {...props}>
    <G
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#UserClip0)"
      clipRule="evenodd"
    >
      <Path d="M3 23.75a9 9 0 1118 0H3zm9-10.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" />
    </G>
    <defs>
      <clipPath id="UserClip0">
        <Path fill="#fff" d="M0 0h24v24H0V0z" />
      </clipPath>
    </defs>
  </Icon>
);

export default User;
