import React from 'react';
import { throttle } from 'throttle-debounce';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView/ArcView';
import ArcStyles, {
  createWithStyles,
} from '../../styles';

const styles = {
  Container: () => ({
    root: {
      display: 'block',
      ...ArcStyles.rules.scroll,
      height: '100%',
      width: '100%',
      flex: '1 1 auto',

      overflow: 'auto',
      overflowX: 'hidden',
      overflowY: 'auto',

      overflowStyle: 'auto',
      transform: 'translateZ(0)',

    },

    scrollbarFixed: {
      '&::-webkit-scrollbar': {
        appearance: 'none',
        width: 12,
        height: 12,
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.35)',
        backgroundClip: 'padding-box',
        border: '3px solid transparent',
        borderRadius: 6,
      },

      '&::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(0,0,0,.11)',
        border: '3px solid transparent',
        backgroundClip: 'padding-box',
        borderRadius: 6,
      },
    },

    displayFlex: {
      display: 'flex',
    },

    isHorizontal: {
      overflowX: 'auto',
    },

    isHorizontalOnly: {
      overflowX: 'auto',
      overflowY: 'hidden',
      overflowStyle: 'scroll',
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);


class ArcScroll extends React.PureComponent {
  static propTypes = {
    children: ArcPropTypes.children.isRequired,
    onScrollThrottled: ArcPropTypes.func,
    scrollRef: ArcPropTypes.func,
    scrollbar: ArcPropTypes.oneOf([
      'fixed',
      'floating',
    ]),
    isHorizontal: ArcPropTypes.bool,
    isHorizontalOnly: ArcPropTypes.bool,
  };

  static defaultProps = {
    onScrollThrottled: undefined,
    scrollbar: 'floating',
    scrollRef: ArcPropTypes.noop,
    isHorizontal: false,
    isHorizontalOnly: false,
  };

  componentWillUnmount() {
    if (this.ref) {
      if (this.props.onScrollThrottled) {
        this.ref.removeEventListener('scroll', this.props.onScrollThrottled);
      }
    }
  }

  internalRef = (ref) => {
    this.props.scrollRef(ref);
    this.ref = this.ref || ref;

    if (this.ref) {
      if (this.props.onScrollThrottled) {
        this.ref.addEventListener('scroll', this.props.onScrollThrottled);
      }
    }
  };

  handleScrollThrottled = throttle((e) => {
    this.props.onScrollThrottled(e);
  }, 300);

  render() {
    const {
      onScrollThrottled,
      scrollRef,
      ...rest
    } = this.props;

    return (
      <Container
        {...rest}
        internalRef={this.internalRef}
      >
        {this.props.children}
      </Container>
    );
  }
}

export default ArcScroll;
