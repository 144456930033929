import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { routeManage } from 'arcade-frontend-features/src/actions/manage/routes';
import { ArcMainView, ArcViewTitleBar, ArcScroll } from 'arcade-frontend-ui';
import { getAppReady } from 'arcade-frontend-core/src/reducers/appReady';

import BillingContainer from '../../containers/BillingContainer';

const BillingView: FC = () => {
  const dispatch = useDispatch();
  const appReady = useSelector(getAppReady);
  const goBack = () => dispatch(routeManage());

  return (
    <ArcMainView isViewing fullHeight hasScroll={false}>
      <ArcViewTitleBar onClickBack={goBack} title="Manage Billing" />
      <ArcScroll>{appReady && <BillingContainer />}</ArcScroll>
    </ArcMainView>
  );
};

export default BillingView;
