import React from 'react';
import PropTypes from 'prop-types';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { ArcButton, ArcView } from 'arcade-frontend-ui';

import ArcAudienceList from '../ArcAudienceList';

const audienceListStyle = {
  paddingBottom: 8,
};

const titleStyle = {
  marginLeft: 10,
};

class ArcAudiencePreSelection extends React.PureComponent {
  static displayName = 'ArcAudiencePreSelection';

  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    entitiesByUuid: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        uuid: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        teamName: PropTypes.string,
        children: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
    excludedEntitiesByParentUuid: PropTypes.objectOf(
      PropTypes.objectOf(PropTypes.bool),
    ),
    partiallySelectedEntitiesByUuid: PropTypes.objectOf(PropTypes.bool),
    preSelectingEntitiesByUuid: PropTypes.objectOf(PropTypes.bool),
    selectedEntitiesByUuid: PropTypes.objectOf(PropTypes.bool),
    onBack: PropTypes.func,
    onSelection: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    data: [],
    entitiesByUuid: {},
    excludedEntitiesByParentUuid: {},
    partiallySelectedEntitiesByUuid: {},
    preSelectingEntitiesByUuid: {},
    selectedEntitiesByUuid: {},
    onBack: global.noop,
    onSelection: global.noop,
  };

  state = {
    data: [],
    partiallySelectedEntitiesByUuid: this.props.partiallySelectedEntitiesByUuid,
    selectedEntitiesByUuid: this.props.selectedEntitiesByUuid,
  };

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    const isChangingPreSelections =
      prevProps.preSelectingEntitiesByUuid !==
      this.props.preSelectingEntitiesByUuid;

    if (prevProps.data !== this.props.data || isChangingPreSelections) {
      this.updateData();
    }
  }

  get placeholderText() {
    const { preSelectingNames } = this;
    let groupText = preSelectingNames[0];
    const { length } = preSelectingNames;

    if (length === 2) {
      groupText = `${preSelectingNames[0]} and ${preSelectingNames[1]}`;
    } else if (length === 3) {
      groupText = `${preSelectingNames[0]}, ${preSelectingNames[1]}, and ${preSelectingNames[2]}`;
    } else if (length > 3) {
      groupText = `${preSelectingNames[0]}, ${preSelectingNames[1]}, and ${length} more`;
    }

    return (
      <ArcView className="animated fadeIn">
        <ArcView>
          {`Any future members of ${groupText} will automatically join.`}
        </ArcView>
        {this.state.data.length ? (
          <ArcView marginTop="8">
            {
              'You can explicitly prevent individuals from joining by deselecting them below.'
            }
          </ArcView>
        ) : (
          <ArcView marginTop="8">
            {`No people available, however you can still add ${groupText} to your selection.`}
          </ArcView>
        )}
      </ArcView>
    );
  }

  get preSelectingNames() {
    const preSelectingNames = [];

    Object.entries(this.props.preSelectingEntitiesByUuid).forEach(
      ([uuid, selected]) => {
        if (selected) {
          preSelectingNames.push(this.props.entitiesByUuid[uuid].name);
        }
      },
    );

    return preSelectingNames;
  }

  get title() {
    const { length } = this.preSelectingNames;

    if (length === 1) {
      return `Add from ${this.preSelectingNames[0]}`;
    }

    return `Add from ${length} groups`;
  }

  setSelectedEntity = entity =>
    this.setSelectedEntityByType('selectedEntitiesByUuid', entity);

  setPartiallySelectedEntity = entity =>
    this.setSelectedEntityByType('partiallySelectedEntitiesByUuid', entity);

  setSelectedEntityByType = (type, entity) => {
    const uuid = `${entity.type}-${entity.id}`;

    this.setState({
      [type]: {
        ...this.props[type],
        ...this.state[type],
        [uuid]: !this.state[type][uuid],
      },
    });
  };

  toggleSelectAll = (toggle = true) => {
    const selectedEntitiesByUuid = {};

    this.state.data.forEach(item => {
      selectedEntitiesByUuid[item.uuid] = toggle;
    });

    this.setState({ selectedEntitiesByUuid });
  };

  updateData = () => {
    this.entitiesByPreSelectedUuid = {};

    const entities = Object.values(this.props.entitiesByUuid);
    const preSelectedEntities = entities.filter(
      item => this.props.preSelectingEntitiesByUuid[item.uuid],
    );

    const data = [];

    const getNormalizedChildren = (entity, preSelectedFromUuid) => {
      this.entitiesByPreSelectedUuid[preSelectedFromUuid] =
        this.entitiesByPreSelectedUuid[preSelectedFromUuid] || [];

      entity.children.forEach(uuid => {
        const child = this.props.entitiesByUuid[uuid];

        if (
          child.type === 'person' &&
          data.filter(item => item.id === child.id).length === 0
        ) {
          let { teamName } = child;

          if (
            this.preSelectingNames.length === 1 &&
            this.preSelectingNames[0] === teamName
          ) {
            teamName = '';
          }

          const newChild = {
            ...child,
            teamName,
          };

          this.entitiesByPreSelectedUuid[preSelectedFromUuid].push(newChild);
          data.push(newChild);
        }

        if (child.children) {
          getNormalizedChildren(child, preSelectedFromUuid);
        }
      });
    };

    preSelectedEntities.forEach(entity => {
      getNormalizedChildren(entity, entity.uuid);
    });

    const selectedEntitiesByUuid = {};

    data.forEach(item => {
      const existingSelection = this.state.selectedEntitiesByUuid[item.uuid];
      const isExcluded = this.props.excludedEntitiesByParentUuid[
        item.parentUuid
      ]
        ? this.props.excludedEntitiesByParentUuid[item.parentUuid][item.uuid]
        : false;

      if (typeof existingSelection === 'undefined') {
        selectedEntitiesByUuid[item.uuid] = !isExcluded;
      } else {
        selectedEntitiesByUuid[item.uuid] = existingSelection;
      }
    });

    this.setState({
      data,
      selectedEntitiesByUuid,
    });
  };

  handleChange = item => this.setSelectedEntity(item);

  handleSelectAll = (event, checked) => {
    const nextChecked = !checked;

    this.toggleSelectAll(nextChecked);
  };

  handleSelection = () => {
    const selectedEntitiesByUuid = { ...this.state.selectedEntitiesByUuid };
    const partiallySelectedEntitiesByUuid = {};

    Object.entries(this.entitiesByPreSelectedUuid).forEach(
      ([preSelectedUuid, children]) => {
        const allEntityChildrenUuids = children.map(item => item.uuid);

        let hasSelectedAll = true;
        let hasPartiallySelected = false;

        allEntityChildrenUuids.forEach(uuid => {
          if (selectedEntitiesByUuid[uuid]) {
            hasPartiallySelected = true;
          } else {
            hasSelectedAll = false;
          }
        });

        if (hasSelectedAll) {
          selectedEntitiesByUuid[preSelectedUuid] = true;
        } else if (hasPartiallySelected) {
          partiallySelectedEntitiesByUuid[preSelectedUuid] = true;
          selectedEntitiesByUuid[preSelectedUuid] = false;
        } else {
          partiallySelectedEntitiesByUuid[preSelectedUuid] = true;
          if (allEntityChildrenUuids.length) {
            selectedEntitiesByUuid[preSelectedUuid] = false;
          } else {
            selectedEntitiesByUuid[preSelectedUuid] = true;
          }
        }
      },
    );

    this.props.onSelection(
      selectedEntitiesByUuid,
      partiallySelectedEntitiesByUuid,
      this.entitiesByPreSelectedUuid,
    );
  };

  render() {
    return (
      <ArcView
        data-testid="ArcAudiencePreSelection"
        position="relative"
        flexGrow="1"
        flexShrink="1"
        fullHeight
        className={this.props.className}
      >
        <ArcView
          row
          align="center"
          borderBottom="default"
          borderBottomWidth="1"
          paddingBottom="8"
          paddingLeft="4"
        >
          <ArcButton size="small" onClick={this.props.onBack}>
            <ArrowBack color="action" fontSize="small" />
          </ArcButton>

          <ArcView style={titleStyle}>{this.title}</ArcView>
        </ArcView>

        <ArcAudienceList
          data={this.state.data}
          hasChange
          onChange={this.handleChange}
          onSelectAll={this.handleSelectAll}
          selectedEntitiesByUuid={this.state.selectedEntitiesByUuid}
          style={audienceListStyle}
          placeholderText={this.placeholderText}
          noDataPlaceholder=""
        />

        <ArcView borderTop="default" borderTopWidth="1" paddingTop="8">
          <ArcButton
            data-testid="ArcAudiencePreSelection-SelectButton"
            variant="outlined"
            color="blue"
            label="Add to Your Picks"
            size="small"
            fullWidth
            onClick={this.handleSelection}
          />
        </ArcView>
      </ArcView>
    );
  }
}

export default ArcAudiencePreSelection;
