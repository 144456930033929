import React from 'react';

import { ArcPropTypes } from 'arcade-frontend-ui';

import getComponentFromDirective from 'arcade-frontend-core/src/helpers/getComponentFromDirective';
import { views as NewsfeedView } from 'arcade-frontend-newsfeed';

import * as types from 'arcade-frontend-core/src/types/routes';

const ArcadeAngularNewsfeedView = getComponentFromDirective('arcadeNewsfeed');

const { PLATFORMS } = ArcPropTypes;

const base = {
  [types.ROUTE_NEWSFEED]: ArcadeAngularNewsfeedView,
  [types.ROUTE_NEWSFEED_SHOW]: ArcadeAngularNewsfeedView,
  [types.ROUTE_NEWSFEED_CATEGORY]: ArcadeAngularNewsfeedView,
};

const benjo = base;
const development = base;
const launch = base;
// const launch = {
//   [types.ROUTE_NEWSFEED]: () => <div />,
//   [types.ROUTE_NEWSFEED_SHOW]: () => <div />,
//   [types.ROUTE_NEWSFEED_CATEGORY]: () => <div />,
// };

export default {
  [PLATFORMS.BENJO]: benjo,
  [PLATFORMS.DEVELOPMENT]: development,
  [PLATFORMS.LAUNCH]: launch,
};
