import React from 'react';
import Settings from '@material-ui/icons/Settings';
import Archive from '@material-ui/icons/Archive';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import {
  ArcPropTypes,
  ArcCardNav,
  ArcCardNavItem,
  ArcProgress,
  percentToArcColor,
  ArcView,
} from 'arcade-frontend-ui';

import MetricCardNavItemIcon from './MetricCardNavItemIcon';

const items = [
  {
    type: 'activity',
    label: 'Activity',
  },
  {
    type: 'ranking',
    label: 'Ranking',
  },
];

const calculatedMetricItems = [
  {
    type: 'ranking',
    label: 'Ranking',
  },
];

const styleDetailsCardNav = {
  position: 'relative',
  padding: 0,
};

const styleArcProgress = {
  overflow: 'hidden',
  position: 'absolute',
  height: 4,
  top: -4,
  left: 8,
  right: 8,
  width: 'calc(100% - 16px)',
};

const styleArcProgressFocus = {
  ...styleArcProgress,
};

const styleMetricCardBenchmarkNav = {
  flexGrow: 1,
  textAlign: 'right',
  marginRight: 8,
  fontWeight: 'bold',
};

const styleGrow = {
  flexGrow: 1,
};

class MetricCardNav extends React.PureComponent {
  static propTypes = {
    onArchive: ArcPropTypes.func,
    onClick: ArcPropTypes.func,
    onEdit: ArcPropTypes.func,
    onRestore: ArcPropTypes.func,

    hasAdd: ArcPropTypes.bool,
    hasArchive: ArcPropTypes.bool,
    hasEdit: ArcPropTypes.bool,
    hasDetails: ArcPropTypes.bool,
    hasRestore: ArcPropTypes.bool,
    isCalculated: ArcPropTypes.bool,

    benchmarkValue: ArcPropTypes.number,
    percentOfBenchmark: ArcPropTypes.number,
    currentMetricDetailsType: ArcPropTypes.string,
    currentMetricId: ArcPropTypes.string,
    id: ArcPropTypes.string,
  };

  static defaultProps = {
    onClick: ArcPropTypes.noop,
    onArchive: ArcPropTypes.noop,
    onEdit: ArcPropTypes.noop,
    onRestore: ArcPropTypes.noop,

    hasAdd: false,
    hasArchive: false,
    hasEdit: false,
    hasDetails: false,
    hasRestore: false,
    isCalculated: false,

    benchmarkValue: null,
    percentOfBenchmark: null,
    currentMetricDetailsType: null,
    currentMetricId: null,
    id: null,
  };

  state = {
    styleArcProgress:
      this.props.currentMetricDetailsType === 'benchmark'
        ? styleArcProgressFocus
        : styleArcProgress,
  };

  componentWillReceiveProps(nextProps) {
    const { currentMetricDetailsType } = this.props;
    const nextType = nextProps.currentMetricDetailsType;
    const isChangingType = currentMetricDetailsType !== nextType;

    if (isChangingType && nextProps.currentMetricDetailsType === 'benchmark') {
      this.focusProgress();
    } else {
      this.blurProgress();
    }
  }

  benchmarkPercent = () => {
    const style = { ...styleMetricCardBenchmarkNav };

    if (this.props.currentMetricDetailsType !== 'benchmark') {
      return {
        ...style,
        color: percentToArcColor(this.props.percentOfBenchmark),
      };
    }

    return style;
  };

  handleClick = {
    activity: e => this.props.onClick(e, this.props.id, 'activity'),
    ranking: e => this.props.onClick(e, this.props.id, 'ranking'),
    create: e => this.props.onClick(e, this.props.id, 'create'),
    benchmark: e => this.props.onClick(e, this.props.id, 'benchmark'),
  };

  handleClickEdit = e => this.props.onEdit(e, this.props.id);

  handleClickArchive = e => this.props.onArchive(e, this.props.id);

  handleClickRestore = e => this.props.onRestore(e, this.props.id);

  handleMouseEnter = () => this.focusProgress();

  handleMouseLeave = () => this.blurProgress();

  blurProgress = () => this.setState({ styleArcProgress });

  focusProgress = () =>
    this.setState({ styleArcProgress: styleArcProgressFocus });

  renderNavEdit() {
    return (
      <MetricCardNavItemIcon
        icon={Settings}
        onClick={this.handleClickEdit}
        isActive={
          this.props.currentMetricDetailsType === 'edit' &&
          this.props.currentMetricId === this.props.id
        }
      />
    );
  }

  renderNavArchive() {
    return (
      <MetricCardNavItemIcon
        icon={Archive}
        onClick={this.handleClickArchive}
        color="error"
      />
    );
  }

  renderNavRestore() {
    return (
      <MetricCardNavItemIcon
        icon={ArrowUpward}
        onClick={this.handleClickRestore}
      />
    );
  }

  render() {
    const {
      currentMetricDetailsType,
      currentMetricId,
      id,
      benchmarkValue,
      percentOfBenchmark,
      hasAdd,
      hasEdit,
      hasArchive,
      hasDetails,
      hasRestore,
      isCalculated,
    } = this.props;

    const isActiveBenchmark =
      currentMetricDetailsType === 'benchmark' && currentMetricId === id;

    const itemsToDisplay = isCalculated ? calculatedMetricItems : items;

    return (
      <ArcCardNav style={styleDetailsCardNav}>
        {benchmarkValue && (
          <ArcView style={this.state.styleArcProgress}>
            <ArcProgress percent={percentOfBenchmark} height={4} />
          </ArcView>
        )}
        <ArcView row align="flex-start" style={styleGrow}>
          {itemsToDisplay.map(item => (
            <ArcCardNavItem
              key={item.type}
              onClick={this.handleClick[item.type]}
              isActive={
                currentMetricDetailsType === item.type && currentMetricId === id
              }
            >
              {item.label}
            </ArcCardNavItem>
          ))}
          {hasAdd && hasDetails && (
            <ArcCardNavItem
              key={'create'}
              onClick={this.handleClick.create}
              isActive={
                currentMetricDetailsType === 'create' && currentMetricId === id
              }
            >
              Add
            </ArcCardNavItem>
          )}
          {benchmarkValue && (
            <ArcCardNavItem
              key={'benchmark'}
              style={this.benchmarkPercent()}
              onClick={this.handleClick.benchmark}
              onMouseEnter={
                !isActiveBenchmark ? this.handleMouseEnter : undefined
              }
              onMouseLeave={
                !isActiveBenchmark ? this.handleMouseLeave : undefined
              }
              isActive={isActiveBenchmark}
            >
              {this.props.percentOfBenchmark}%
            </ArcCardNavItem>
          )}
        </ArcView>
        {hasEdit && this.renderNavEdit()}
        {hasArchive && this.renderNavArchive()}
        {hasRestore && this.renderNavRestore()}
      </ArcCardNav>
    );
  }
}

export default MetricCardNav;
