import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const initialState = {};

const handleGetRespectsSuccess = (state, action) => {
  const { data } = action.payload;
  const { id } = action.meta.requestAction.payload;
  return { ...state, [id]: data };
};

const handlers = {
  [types.NEWSFEED_GET_ACTIVITY_RESPECTS.SUCCESS]: handleGetRespectsSuccess,
};

const respects = state => state.newsfeed.respects;

export default createReducer(initialState, handlers);

export const selectors = { respects };
