import React from 'react';

const IconMultipleTargets = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor" fillRule="nonzero">
      <path d="M5.613 20a3.604 3.604 0 0 0 3.611-3.596c0-.611-.152-1.186-.422-1.69a.639.639 0 0 1-.142.004l-.459-.035-.33.329c.252.405.397.881.397 1.392a2.65 2.65 0 0 1-2.654 2.643 2.65 2.65 0 0 1-2.656-2.643 2.65 2.65 0 0 1 2.655-2.644c.513 0 .992.146 1.398.397l.299-.298-.04-.525a.688.688 0 0 1 .001-.125 3.61 3.61 0 0 0-1.659-.401 3.604 3.604 0 0 0-3.61 3.596A3.604 3.604 0 0 0 5.613 20z" />
      <path d="M5.613 15.475c.024 0 .047 0 .07.002l.668-.664.014-.014a1.773 1.773 0 0 0-.752-.166c-.982 0-1.778.792-1.778 1.77s.796 1.771 1.778 1.771 1.778-.792 1.778-1.77c0-.268-.06-.522-.167-.749l-.014.014-.667.664a.931.931 0 0 1-.93 1 .93.93 0 0 1-.933-.93c0-.512.418-.928.933-.928z" />
      <path d="M9.335 13.197l.214-.213a.248.248 0 0 0 0-.352l-.148-.148a.25.25 0 0 0-.353 0l-.245.244-.05-.65A.083.083 0 0 0 8.667 12a.082.082 0 0 0-.059.024l-.965.962a.42.42 0 0 0-.122.329l.002.037.046.602-.349.348-.628.625-.014.014-.608.606-.27.268a.207.207 0 0 0-.06.132l-.017.21a.21.21 0 0 0 .21.227h.01l.224-.01a.208.208 0 0 0 .138-.062l.268-.266.607-.605.015-.014.628-.626.38-.377.55.041.025.002a.425.425 0 0 0 .33-.122l.965-.96a.084.084 0 0 0-.053-.143l-.586-.045z" />
      <g>
        <path d="M11.613 11a3.604 3.604 0 0 0 3.611-3.596c0-.611-.152-1.186-.422-1.69a.639.639 0 0 1-.142.004l-.459-.035-.33.329c.252.405.397.881.397 1.392a2.65 2.65 0 0 1-2.654 2.643 2.65 2.65 0 0 1-2.656-2.643 2.65 2.65 0 0 1 2.655-2.644c.513 0 .992.146 1.398.397l.299-.298-.04-.525a.688.688 0 0 1 .001-.125 3.61 3.61 0 0 0-1.659-.401 3.604 3.604 0 0 0-3.61 3.596A3.604 3.604 0 0 0 11.613 11z" />
        <path d="M11.613 6.475c.024 0 .047 0 .07.002l.668-.664.014-.014a1.773 1.773 0 0 0-.752-.166c-.982 0-1.778.792-1.778 1.77s.796 1.771 1.778 1.771 1.778-.792 1.778-1.77c0-.268-.06-.522-.167-.749l-.014.014-.667.664a.931.931 0 0 1-.93 1 .93.93 0 0 1-.933-.93c0-.512.418-.928.933-.928z" />
        <path d="M15.335 4.197l.214-.213a.248.248 0 0 0 0-.352l-.148-.148a.25.25 0 0 0-.353 0l-.245.244-.05-.65A.083.083 0 0 0 14.667 3a.082.082 0 0 0-.059.024l-.965.962a.42.42 0 0 0-.122.329l.002.037.046.602-.349.348-.628.625-.014.014-.608.606-.27.268a.207.207 0 0 0-.06.132l-.017.21a.21.21 0 0 0 .21.227h.01l.224-.01a.208.208 0 0 0 .138-.062l.268-.266.607-.605.015-.014.628-.626.38-.377.55.041.025.002a.425.425 0 0 0 .33-.122l.965-.96a.084.084 0 0 0-.053-.143l-.586-.045z" />
      </g>
      <g>
        <path d="M17.613 20a3.604 3.604 0 0 0 3.611-3.596c0-.611-.152-1.186-.422-1.69a.639.639 0 0 1-.142.004l-.459-.035-.33.329c.252.405.397.881.397 1.392a2.65 2.65 0 0 1-2.654 2.643 2.65 2.65 0 0 1-2.656-2.643 2.65 2.65 0 0 1 2.655-2.644c.513 0 .992.146 1.398.397l.299-.298-.04-.525a.688.688 0 0 1 .001-.125 3.61 3.61 0 0 0-1.659-.401 3.604 3.604 0 0 0-3.61 3.596A3.604 3.604 0 0 0 17.613 20z" />
        <path d="M17.613 15.475c.024 0 .047 0 .07.002l.668-.664.014-.014a1.773 1.773 0 0 0-.752-.166c-.982 0-1.778.792-1.778 1.77s.796 1.771 1.778 1.771 1.778-.792 1.778-1.77c0-.268-.06-.522-.167-.749l-.014.014-.667.664a.931.931 0 0 1-.93 1 .93.93 0 0 1-.933-.93c0-.512.418-.928.933-.928z" />
        <path d="M21.335 13.197l.214-.213a.248.248 0 0 0 0-.352l-.148-.148a.25.25 0 0 0-.353 0l-.245.244-.05-.65a.083.083 0 0 0-.085-.078.082.082 0 0 0-.059.024l-.965.962a.42.42 0 0 0-.122.329l.002.037.046.602-.349.348-.628.625-.014.014-.608.606-.27.268a.207.207 0 0 0-.06.132l-.017.21a.21.21 0 0 0 .21.227h.01l.224-.01a.208.208 0 0 0 .138-.062l.268-.266.607-.605.015-.014.628-.626.38-.377.55.041.025.002a.425.425 0 0 0 .33-.122l.965-.96a.084.084 0 0 0-.053-.143l-.586-.045z" />
      </g>
    </g>
  </svg>
);

export default IconMultipleTargets;
