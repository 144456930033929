import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const initialState = {};

const handleSummaryActivitySuccess = (state, action) => {
  const { data } = action.payload;
  const newActivities = data.reduce((hash, act) => {
    hash[act.id] = act; // eslint-disable-line no-param-reassign
    return hash;
  }, {});

  return { ...state, ...newActivities };
};

const handleNewsfeedLoadIndexSuccess = (state, action) => {
  const { data } = action.payload;
  const { activities, pinned = [] } = data;
  const newActivities = [...pinned, ...activities].reduce((hash, act) => {
    hash[act.id] = act; // eslint-disable-line no-param-reassign
    return hash;
  }, {});

  return { ...state, ...newActivities };
};

const handleNewsfeedActivitySuccess = (state, action) => {
  const activity = action.payload.data;
  const existingActivity = state[activity.id];

  if (existingActivity && !existingActivity.isLoading) {
    return {
      ...state,
      [activity.id]: {
        isLoading: false,
        ...activity,
      },
    };
  }

  return {
    ...state,
    [activity.id]: {
      skipInList: true,
      isLoading: false,
      ...activity,
    },
  };
};

const handleNewsfeedActivityRequest = (state, action) => {
  const existingActivity = state[action.payload.id];

  if (existingActivity) {
    return state;
  }

  return {
    ...state,
    [action.payload.id]: {
      ...existingActivity,
      isLoading: true,
    },
  };
};

const handleNewsfeedActivityFailure = (state, action) => ({
  ...state,
  [action.payload.id]: {
    isLoading: false,
  },
});

const handleNewsfeedDeleteActivitySuccess = (state, action) => {
  const newState = { ...state };
  delete newState[action.meta.requestAction.payload.id];
  return newState;
};

const handleReloadCommentSuccess = (state, action) => {
  const { data } = action.payload;
  const { comments } = data;
  const existingActivity = state[data.id];
  const newActivity = { ...existingActivity, comments };
  return { ...state, [data.id]: newActivity };
};

const handleClearActivities = () => initialState;

const handleGetRespectsSuccess = (state, action) => {
  const { data } = action.payload;
  const { id } = action.meta.requestAction.payload;

  return {
    ...state,
    [id]: {
      ...state[id],
      respects: data,
    },
  };
};

const handleGetReactionsSuccess = (state, action) => {
  const { data } = action.payload;
  const { id } = action.meta.requestAction.payload;

  return {
    ...state,
    [id]: {
      ...state[id],
      reactions: data,
    },
  };
};

const handleToggleActivityRespectRequest = (state, action) => ({
  ...state,
  [action.payload.id]: {
    ...state[action.payload.id],
    userRespected: !state[action.payload.id].userRespected,
  },
});

const handleGetActivityCommentsRequest = (state, action) => {
  const { data } = action.payload;
  const id = action.meta.requestAction.payload;

  return {
    ...state,
    [id]: {
      ...state[id],
      comments: data,
    },
  };
};

const handleNewsfeedReactToCommentRequest = (state, action) => {
  const { id, activityId, reaction, currentUserId } = action.payload;

  return {
    ...state,
    [activityId]: {
      ...state[activityId],
      comments: state[activityId].comments.map(comment => ({
        ...comment,
        reactions:
          comment.id === id
            ? [
                ...comment.reactions,
                {
                  id: '-1',
                  userId: currentUserId,
                  reaction,
                },
              ]
            : comment.reactions,
      })),
    },
  };
};

const handleNewsfeedDeleteReactToCommentRequest = (state, action) => {
  const { id, activityId, commentId } = action.payload;
  return {
    ...state,
    [activityId]: {
      ...state[activityId],
      comments: state[activityId].comments.map(comment => ({
        ...comment,
        reactions:
          comment.id === commentId
            ? comment.reactions.filter(reaction => reaction.id !== id)
            : comment.reactions,
      })),
    },
  };
};

const handlers = {
  [types.NEWSFEED_INDEX.SUCCESS]: handleNewsfeedLoadIndexSuccess,
  [types.NEWSFEED_DELETE_ACTIVITY.SUCCESS]: handleNewsfeedDeleteActivitySuccess,
  [types.NEWSFEED_ACTIVITY.REQUEST]: handleNewsfeedActivityRequest,
  [types.NEWSFEED_ACTIVITY.FAILURE]: handleNewsfeedActivityFailure,
  [types.NEWSFEED_ACTIVITY.SUCCESS]: handleNewsfeedActivitySuccess,
  [types.NEWSFEED_CLEAR_ACTIVITIES]: handleClearActivities,
  [types.NEWSFEED_GET_SUMMARY_ACTIVITIES.SUCCESS]: handleSummaryActivitySuccess,
  [types.NEWSFEED_ACTIVITY_RELOAD_COMMENTS.SUCCESS]: handleReloadCommentSuccess,
  [types.NEWSFEED_GET_ACTIVITY_RESPECTS.SUCCESS]: handleGetRespectsSuccess,
  [types.NEWSFEED_GET_ACTIVITY_REACTIONS.SUCCESS]: handleGetReactionsSuccess,
  [types.NEWSFEED_TOGGLE_ACTIVITY_RESPECT
    .REQUEST]: handleToggleActivityRespectRequest,
  [types.NEWSFEED_REACT_TO_COMMENT
    .REQUEST]: handleNewsfeedReactToCommentRequest,
  [types.NEWSFEED_DELETE_REACT_TO_COMMENT
    .REQUEST]: handleNewsfeedDeleteReactToCommentRequest,
  [types.NEWSFEED_GET_ACTIVITY_COMMENTS
    .SUCCESS]: handleGetActivityCommentsRequest,
};

const activitiesById = state => state.newsfeed.activities;

export default createReducer(initialState, handlers);
export const selectors = { activitiesById };
