import { makeApiActions } from 'arcade-frontend-ui';
import { makeDateFilterParams } from 'arcade-frontend-core/src/actions/helpers';

import normalizers from './normalizers';

const manageMetricsEntityIndex = params => ({
  method: 'GET',
  url: '/manager/v4/kpi_groups',
  params: { ...makeDateFilterParams(params) },
  rootKey: 'kpi_groups',
  normalizer: normalizers.fromManageMetricsEntitiesIndex,
});

const endpoints = {
  manageMetricsEntityIndex,
};

const api = makeApiActions(endpoints);

export default api;
