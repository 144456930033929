import React from 'react';
import PropTypes from 'prop-types';

import ArcConfirmDialog from '../ArcConfirmDialog';

class ArcErrorDialog extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
  };

  static defaultProps = {
    title: 'Oops!',
    content: 'Something went wrong',
  };

  render() {
    const {
      ...rest
    } = this.props;

    return (
      <ArcConfirmDialog
        confirmButtonColor="default"
        confirmLabel="Try Again"
        hideCancel
        {...rest}
      />
    );
  }
}

export default ArcErrorDialog;
