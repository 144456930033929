import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import { ArcView, ArcEmojiButton } from 'arcade-frontend-ui';

const buttonStyle = {
  padding: 0,
  minWidth: 'auto',
};

function NewsfeedCommentReactions({ currentUserId, onClick, reactions }) {
  const uniqueReactions = reactions.map(reaction => reaction.reaction).unique();

  const reactionsWithTotals = uniqueReactions.map(uniqueReaction => ({
    reaction: uniqueReaction,
    isSelected:
      reactions.filter(
        ({ reaction, userId }) =>
          reaction === uniqueReaction && userId === currentUserId,
      ).length > 0,
    total: reactions.filter(({ reaction }) => reaction === uniqueReaction)
      .length,
  }));

  return (
    <Tooltip title="See who reacted" placement="right">
      <ArcView row cursor="pointer" onClick={onClick} alignSelf="flex-start">
        {reactionsWithTotals.map(({ reaction, total, isSelected }) => (
          <ArcView key={reaction} position="relative" marginRight="4">
            <ArcEmojiButton
              isStrong={isSelected}
              label={reaction}
              emoji={reaction}
              total={total}
              variant="default"
              style={buttonStyle}
            />
          </ArcView>
        ))}
      </ArcView>
    </Tooltip>
  );
}

NewsfeedCommentReactions.propTypes = {
  currentUserId: PropTypes.string,
  onClick: PropTypes.func,
  reactions: PropTypes.arrayOf(
    PropTypes.shape({
      reaction: PropTypes.string,
    }),
  ),
};

NewsfeedCommentReactions.defaultProps = {
  currentUserId: null,
  onClick: global.noop,
  reactions: [],
};

NewsfeedCommentReactions.displayName = 'NewsfeedCommentReactions';

export default NewsfeedCommentReactions;
