import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/sales';

const handleManageSalesLoadSuccess = (state, action) => {
  const { totalSales } = action.payload.data;

  return totalSales;
};

const handleManageSalesCreateSuccess = state => state + 1;

const handleManageSalesDeleteSuccess = state => state - 1;

const initialState = 0;

const handlers = {
  [types.MANAGE_SALES_INDEX.SUCCESS]: handleManageSalesLoadSuccess,
  [types.MANAGE_SALES_CREATE.SUCCESS]: handleManageSalesCreateSuccess,
  [types.MANAGE_SALES_DELETE.SUCCESS]: handleManageSalesDeleteSuccess,
};

const totalSales = createReducer(initialState, handlers);

export default totalSales;

export const getManageSalesTotal = state => state.manage.sales.totalSales;
