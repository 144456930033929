import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';

import ArcUploadManager from '../../components/ArcUploadManager';
import {
  uploadedVideosUpdating,
  createUppyUploadProgressSelector,
  searchResults,
} from '../../reducers';

import {
  getFilesIsLoading,
  getFilesHasMore,
  getSharedFilesHasMore,
  getFilesMine,
  getFilesShared,
  getFilesById,
} from '../../reducers/files';
import {
  getVideosIsLoading,
  getVideosHasMore,
  getSharedVideosHasMore,
  getVideosById,
  getVideosMine,
  getVideosShared,
} from '../../reducers/videos';
import { getOpen } from '../../reducers/uploadManager/open';
import { getSource } from '../../reducers/uploadManager/source';
import { getUploadStatus } from '../../reducers/uploadManager/uploadStatus';
import { getRequestStatus } from '../../reducers/uploadManager/requestStatus';
import { actions } from '../../actions/uploadManager';
import { getSearchResults, getSearchResultsById } from '../../reducers/search';

const ArcUploadManagerContainer = React.forwardRef((props, ref) => {
  if (props.user && props.user.id) {
    return <ArcUploadManager {...props} ref={ref} />;
  }
  return null;
});

ArcUploadManagerContainer.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

const mappedState = state => ({
  open: getOpen(state),
  uploadStatus: getUploadStatus(state),
  requestStatus: getRequestStatus(state),
  source: getSource(state),
  filesById: getFilesById(state),
  filesMine: getFilesMine(state),
  filesShared: getFilesShared(state),
  uploads: createUppyUploadProgressSelector(state, 'ArcUploadManager'),
  user: getCurrentUser(state),
  updating: uploadedVideosUpdating(state),
  searchResults: getSearchResults(state),
  searchResultsById: getSearchResultsById(state),
  videosById: getVideosById(state),
  videosMine: getVideosMine(state),
  videosShared: getVideosShared(state),
  videosHasMore: getVideosHasMore(state),
  sharedVideosHasMore: getSharedVideosHasMore(state),
  filesHasMore: getFilesHasMore(state),
  sharedFilesHasMore: getSharedFilesHasMore(state),

  videosIsLoading: getVideosIsLoading(state),
  filesIsLoading: getFilesIsLoading(state),
});
const dispatch = dispatcher =>
  bindActionCreators(
    {
      onCancel: actions.uploadManagerCancelled,
      onSelect: actions.uploadManagerFileSelected,
      onDelete: actions.uploadManagerDeleteUpload,
      onUpload: actions.uploadManagerStartUpload,
      getMyUploads: actions.uploadManagerGetMyUploads,
      getSharedUploads: actions.uploadManagerGetSharedUploads,
      getMyFiles: actions.uploadManagerGetMyFiles,
      getSharedFiles: actions.uploadManagerGetSharedFiles,
      searchUploads: actions.uploadManagerSearchUploads,
      resetSearch: actions.uploadManagerResetSearch,
      createUpload: actions.uploadManagerCreateUpload,
      deleteUpload: actions.uploadManagerDeleteUpload,
      updateUpload: actions.uploadManagerUpdateUpload,
      updateUploadFile: actions.uploadManagerUpdateUploadFile,
      reportError: actions.reportError,
    },
    dispatcher,
  );

export default connect(mappedState, dispatch, null, { forwardRef: true })(
  ArcUploadManagerContainer,
);
