import { createReducer } from 'arcade-frontend-ui';

import * as typesRoutes from 'arcade-frontend-core/src/types/routes';

const setGameId = (state, action) => {
  const { id } = action.payload;

  return id.toString();
};

const handleRouteGames = () => null;
const handleRouteGamesShow = setGameId;

const initialState = null;

const handlers = {
  [typesRoutes.ROUTE_GAMES]: handleRouteGames,
  [typesRoutes.ROUTE_GAMES_SHOW]: handleRouteGamesShow,
};

const currentGameId = createReducer(initialState, handlers);

export default currentGameId;

export const getCurrentGameId = state => state.games.currentGameId;
