import React from 'react';
import PropTypes from 'prop-types';

function ArcResourceEmptyPending({
  children,
  emptyNode,
  failedNode,
  hasData,
  hasFailed,
  isPending,
  pendingNode,
}) {
  if (hasFailed) return failedNode;

  if (isPending && !hasData) return pendingNode;

  if (!isPending && !hasData) return emptyNode;

  return typeof children === 'function' ? children() : (<>{children}</>);
}

ArcResourceEmptyPending.displayName = 'ArcResourceEmptyPending';

ArcResourceEmptyPending.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  hasData: PropTypes.bool,
  emptyNode: PropTypes.node,
  failedNode: PropTypes.node,
  hasFailed: PropTypes.bool,
  isPending: PropTypes.bool,
  pendingNode: PropTypes.node,
};

ArcResourceEmptyPending.defaultProps = {
  children: null,
  hasData: false,
  emptyNode: null,
  failedNode: null,
  hasFailed: false,
  isPending: false,
  pendingNode: null,
};

export default ArcResourceEmptyPending;
