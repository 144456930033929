import React from 'react';
import cx from 'classnames';
import hoistNonReactStatics from 'hoist-non-react-statics';
import TinyDatePicker from 'tiny-date-picker';
import Select from '@material-ui/core/Select';
import withTheme from '@material-ui/styles/withTheme';
import FormControl from '@material-ui/core/FormControl';

import ArcPropTypes from '../../helpers/arc/propTypes';
import {
  isSameDay,
  toNextMinuteInterval,
  toInputDate,
  toShortDate,
  startOfDay,
} from '../../helpers/utils/date';
import { createWithStyles } from '../../styles';

import { styles, styleInvisible } from './styles';

import ArcButton from '../ArcButton';
import ArcView from '../../primitives/ArcView';

const AM = 'AM';
const PM = 'PM';

const TODAY = 'today';
const TOMORROW = 'tomorrow';
const OTHER = 'other';

const TYPES = [TODAY, TOMORROW, OTHER];
const HOURS = ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
const MINUTES = ['00', '15', '30', '45'];

const ButtonGroup = createWithStyles(styles.ButtonGroup)(ArcView);
const Container = createWithStyles(styles.Container)(ArcView);
const SelectGroup = createWithStyles(styles.SelectGroup)(ArcView);
const TimeSeperator = createWithStyles(styles.TimeSeperator)(ArcView);
const PermanentModeWrapper = createWithStyles(styles.PermanentModeWrapper)(ArcView);

const styleMinute = {
  marginLeft: -1,
};

const get24Hours = (hour, amPm) => {
  if (amPm === AM && hour === 12) {
    return 0;
  }

  if (amPm === PM && hour !== 12) {
    return hour + 12;
  }

  return hour;
};

class ArcDateTime extends React.PureComponent {
  static propTypes = {
    id: ArcPropTypes.string,
    initialDate: ArcPropTypes.oneOfType([
      ArcPropTypes.instanceOf(Date),
      ArcPropTypes.string,
      ArcPropTypes.number,
    ]),
    hasError: ArcPropTypes.bool,
    hideOnSelect: ArcPropTypes.bool,
    hideTime: ArcPropTypes.bool,
    maxDate: ArcPropTypes.instanceOf(Date),
    minDate: ArcPropTypes.instanceOf(Date),
    onChange: ArcPropTypes.func,
    onSelect: ArcPropTypes.func,
    theme: ArcPropTypes.shape({
      breakpoints: ArcPropTypes.shape({
        values: ArcPropTypes.objectOf(ArcPropTypes.string),
      }),
    }),
    mode: ArcPropTypes.oneOf(['modal', 'permanent']),
  };

  static defaultProps = {
    id: undefined,
    initialDate: toNextMinuteInterval(new Date()),
    hideOnSelect: true,
    hideTime: false,
    maxDate: undefined,
    minDate: undefined,
    hasError: false,
    onChange: ArcPropTypes.noop,
    onSelect: ArcPropTypes.noop,
    theme: {},
    mode: 'modal',
  };

  static displayName = 'ArcDateTimeWeb';

  constructor(props) {
    super(props);

    this.dateRef = React.createRef();

    this.today = startOfDay(new Date());
    this.tomorrow = startOfDay(new Date(this.today));
    this.tomorrow.setDate(this.today.getDate() + 1);

    this.minDate = startOfDay(this.today);

    this.maxDate = new Date(this.today);
    this.maxDate.setDate(this.today.getDate() + 14);

    const initialDate = new Date(props.initialDate || new Date());
    let initialHours = initialDate.getHours() % 12;

    if (initialHours === 0) {
      initialHours = 12;
    }

    let active = OTHER;
    let other = initialDate;

    if (isSameDay(initialDate, this.today)) {
      active = TODAY;
      other = null;
    } else if (isSameDay(initialDate, this.tomorrow)) {
      active = TOMORROW;
      other = null;
    }

    this.state = {
      active,
      hour: initialHours,
      min: initialDate.getMinutes(),
      amPm: initialDate.getHours() >= 12 ? PM : AM,
      other,
      value: '',
    };

    this.state.value = this.getNextValue(this.state);
  }

  componentDidMount() {
    if (global.canUseDOM && this.dateRef.current) {
      const { mode } = this.props;

      this.buildTinyDatePicker();

      if (mode === 'modal') {
        this.tinyDatePicker.on('close', this.handleTinyDatePickerClose);
        this.tinyDatePicker.on('open', this.handleTinyDatePickerOpen);
      }
      this.tinyDatePicker.on('select', this.handleTinyDatePickerSelect);

      this.updateValue();
    }
  }

  componentWillUnmount() {
    if (global.canUseDOM && this.tinyDatePicker) {
      this.tinyDatePicker.destroy();
    }
  }

  get isResponsive() {
    return window.innerWidth < this.props.theme.breakpoints.values.md;
  }

  getInRange = (date) => {
    const { minDate, maxDate } = this.props;
    const compareDate = new Date(date);
    compareDate.setHours(get24Hours(this.state.hour, this.state.amPm));
    compareDate.setMinutes(this.state.min);
    const time = compareDate.getTime();

    if (minDate && maxDate) {
      return time >= minDate.getTime() && time <= maxDate.getTime();
    }

    if (minDate) {
      return time >= minDate.getTime();
    }

    if (maxDate) {
      return time <= maxDate.getTime();
    }

    return true;
  };

  getNextValue(props) {
    const { active, hour, min, amPm, other } = props;

    let nextValue = other || (active === TODAY ? this.today : this.tomorrow);
    nextValue = new Date(nextValue);

    nextValue.setHours(get24Hours(hour, amPm));
    nextValue.setMinutes(min);

    return nextValue.toString();
  }

  setActive = active => this.setState({ active }, this.updateValue);

  setHour = hour => this.setState({ hour }, this.updateValue);

  setMin = min => this.setState({ min }, this.updateValue);

  setAmPm = amPm => this.setState({ amPm }, this.updateValue);

  setOther = other => this.setState({ other }, this.updateValue);

  setValue = value => this.setState({ value }, this.handleUpdateValue);

  buildTinyDatePicker = () => {
    if (!this.dateRef.current) {
      return;
    }

    const { mode } = this.props;

    this.tinyDatePicker = TinyDatePicker(this.dateRef.current, {
      format: date => (date ? toInputDate(date) : ''),
      hilightedDate: this.state.value,
      inRange: this.getInRange,
      mode: `dp-${mode}`,
    });
  };

  createHandleClickType = label => () => {
    this.setActive(label);

    switch (label) {
      case TODAY:
        return this.handleClickToday();
      case TOMORROW:
        return this.handleClickTomorrow();
      case OTHER:
        return this.handleClickOther();
      default:
        return null;
    }
  };

  handleClickToday = () => {
    this.tinyDatePicker.close();
    this.prevActive = TODAY;
  };

  handleClickTomorrow = () => {
    this.tinyDatePicker.close();
    this.prevActive = TOMORROW;
  };

  handleClickOther = () => {
    if (this.tinyDatePicker) {
      this.tinyDatePicker.open();
    } else {
      this.setOther(null);
    }
  };

  handleChangeHour = (e) => {
    this.setHour(parseInt(e.target.value, 10));
  };

  handleChangeMin = (e) => {
    this.setMin(parseInt(e.target.value, 10));
  };

  handleChangeAmPm = (e) => {
    this.setAmPm(e.target.value);
  };

  handleClickAm = () => this.setAmPm(AM);

  handleClickPm = () => this.setAmPm(PM);

  handleTinyDatePickerSelect = (eventName, picker) => {
    const { selectedDate } = picker.state;
    const { hour, min, amPm, other } = this.state;

    if (!selectedDate) {
      this.hasSelectedOther = false;
      this.setActive(this.prevActive || TODAY);
      this.setOther(null);
    } else if (!other || selectedDate.getTime() !== other.getTime()) {
      this.hasSelectedOther = true;
      if (selectedDate.getTime() === this.today.getTime()) {
        this.setActive(TODAY);
        this.setOther(null);
      } else if (selectedDate.getTime() === this.tomorrow.getTime()) {
        this.setActive(TOMORROW);
        this.setOther(null);
      } else {
        const otherDate = new Date(selectedDate);
        otherDate.setHours(get24Hours(hour, amPm));
        otherDate.setMinutes(min);

        this.hasSetDateFromTinyDatePicker = true;
        this.setOther(otherDate);
      }
    }


    if (this.props.hideOnSelect) {
      this.props.onSelect();
    }
  };

  handleTinyDatePickerOpen = () => {
    document.querySelector('.dp-modal').classList.toggle('dp-has-error', this.props.hasError);
  };

  handleTinyDatePickerClose = () => {
    if (!this.hasSelectedOther) {
      // Closed the datepicker without selecting anything
      this.setActive(this.prevActive || TODAY);
      this.setOther(null);
    }

    this.dateRef.current.blur();
  };

  handleUpdateValue = () => {
    this.props.onChange(this.state.value);
  };

  updateValue = () => {
    const nextValue = this.getNextValue(this.state);

    this.setValue(nextValue);

    return nextValue;
  };

  renderHrs() {
    return (
      <FormControl>
        <Select
          className="ArcSelect"
          id={this.props.id ? `${this.props.id}Hours` : undefined}
          native
          onChange={this.handleChangeHour}
          value={this.state.hour}
        >
          {HOURS.map(hour => (
            <option
              key={hour}
              value={hour}
            >
              {hour}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  }

  renderDayShortcuts = () => {
    const { other } = this.state;

    return (
      <ButtonGroup margin="bottom" hasError={this.props.hasError}>
        {
          TYPES.map(label => (
            <ArcButton
              key={label}
              className={cx([
                'ArcButton',
                this.state.active === label && 'isActive',
              ])}
              fullWidth
              label={label === OTHER && other ? toShortDate(new Date(other)) : label}
              variant="outlined"
              onClick={this.createHandleClickType(label)}
            />
          ))
        }
      </ButtonGroup>
    );
  }

  render() {
    const { mode } = this.props;

    return (
      <Container>
        { mode !== 'permanent'
          ? this.renderDayShortcuts()
          : <PermanentModeWrapper internalRef={this.dateRef} /> }

        {!this.props.hideTime && (
          <SelectGroup hasError={this.props.hasError}>
            {this.renderHrs()}

            <TimeSeperator>
              {':'}
            </TimeSeperator>

            <FormControl style={styleMinute}>
              <Select
                className="ArcSelect"
                id={this.props.id ? `${this.props.id}Minutes` : undefined}
                native
                onChange={this.handleChangeMin}
                value={this.state.min}
              >
                {MINUTES.map(minute => (
                  <option
                    key={minute}
                    value={minute}
                  >
                    {minute}
                  </option>
                ))}
              </Select>
            </FormControl>

            <ButtonGroup
              aria-label="AM/PM"
              id={this.props.id ? `${this.props.id}AmPm` : undefined}
              role="radiogroup"
              margin="left"
              hasError={this.props.hasError}
            >
              <ArcButton
                aria-checked={this.state.amPm === AM ? 'true' : 'false'}
                className={cx([
                  'ArcButton',
                  this.state.amPm === AM && 'isActive',
                ])}
                label="AM"
                onClick={this.handleClickAm}
              />
              <ArcButton
                aria-checked={this.state.amPm === PM ? 'true' : 'false'}
                className={cx([
                  'ArcButton',
                  this.state.amPm === PM && 'isActive',
                ])}
                label="PM"
                onClick={this.handleClickPm}
              />
            </ButtonGroup>
          </SelectGroup>
        )}

        {mode === 'modal' && (
          <input
            tabIndex="-1"
            ref={this.dateRef}
            style={styleInvisible}
          />
        )}
      </Container>
    );
  }
}

export default hoistNonReactStatics(ArcDateTime, withTheme(ArcDateTime));
