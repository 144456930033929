/* -eslint-disable */

import React from 'react';
import Clear from '@material-ui/icons/Clear';

import {
  ArcView,
  ArcImage,
  createWithStyles,
  ArcPropTypes,
  ArcIconButton,
  ArcAttachedFile,
} from 'arcade-frontend-ui';
import { isImage } from 'arcade-frontend-core/src/helpers/files';

const styles = {
  PreviewWrapper: theme => ({
    root: {
      position: 'relative',
      padding: theme.spacing(1),
    },
  }),
  PreviewImage: () => ({
    root: {
      alignSelf: 'center',
    },
  }),
  PreviewRemove: theme => ({
    root: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      '& :hover': {
        color: theme.palette.red.main,
      },
    },
  }),
};

const PreviewImage = createWithStyles(styles.PreviewImage)(ArcImage);
const PreviewWrapper = createWithStyles(styles.PreviewWrapper)(ArcView);
const PreviewRemove = createWithStyles(styles.PreviewRemove)(ArcIconButton);

const Preview = ({ remove, file }) => {
  const { name, preview, type, size } = file;

  return (
    <PreviewWrapper>
      {isImage(type) ? (
        <React.Fragment>
          <PreviewImage alt={name} src={preview} />
          <PreviewRemove onClick={() => remove(name)}>
            <Clear />
          </PreviewRemove>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ArcAttachedFile name={name} type={type} size={size} />
          <PreviewRemove onClick={() => remove(name)}>
            <Clear />
          </PreviewRemove>
        </React.Fragment>
      )}
    </PreviewWrapper>
  );
};

Preview.propTypes = {
  remove: ArcPropTypes.func.isRequired,
  file: ArcPropTypes.shape({
    name: ArcPropTypes.string,
    preview: ArcPropTypes.string,
    size: ArcPropTypes.number,
    type: ArcPropTypes.string,
  }).isRequired,
};

const fileShape = ArcPropTypes.shape({});

const Previews = ({ files, remove }) => (
  <ArcView>
    {files.map((file, i) => (
      <Preview remove={remove} key={i.toString()} file={file} />
    ))}
  </ArcView>
);

Previews.propTypes = {
  files: ArcPropTypes.arrayOf(fileShape).isRequired,
  remove: ArcPropTypes.func.isRequired,
};

export default Previews;
