import { makeDateFilterParams } from 'arcade-frontend-core/src/actions/helpers';

import * as types from './types';

export const apiMetricsIndexRequest = params => ({
  type: types.METRICS_INDEX.REQUEST,
  payload: { ...makeDateFilterParams(params) },
  meta: {
    analytics: {
      payload: {
        event: 'Viewed assigned metrics',
      },
    },
  },
});

export const apiMetricsActivitiesIndexRequest = ({ metricId, ...rest }) => ({
  type: types.METRICS_ACTIVITIES_INDEX.REQUEST,
  payload: {
    metricId,
    ...makeDateFilterParams(rest),
  },
  meta: {
    analytics: {
      payload: {
        event: 'Viewed metric events',
      },
    },
  },
});

export const apiMetricsRankingsIndexRequest = ({ metricId, ...rest }) => ({
  type: types.METRICS_RANKINGS_INDEX.REQUEST,
  payload: {
    metricId,
    ...makeDateFilterParams(rest),
  },
  meta: {
    analytics: {
      payload: {
        event: 'Viewed metric rankings',
      },
    },
  },
});

export const apiMetricsActivitiesCreateRequest = (kpiId, amount, date) => ({
  type: types.METRICS_ACTIVITIES_CREATE.REQUEST,
  payload: {
    kpiId,
    amount,
    date,
  },
  meta: {
    analytics: {
      payload: {
        event: 'Logged a sale',
      },
    },
  },
});
