import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

import {
  ArcButton,
  ArcScoreboard,
  ArcText,
  ArcTextField,
  ArcView,
  createWithStyles,
  date,
  ArcEntity,
} from 'arcade-frontend-ui';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';

const styles = {
  FormButton: theme => ({
    root: {
      margin: theme.spacing(0.5),
      height: '100%',

      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(1),
        marginBottom: 'initial',
      },
    },
    hidden: {
      visibility: 'hidden',
    },
  }),

  ResultText: theme => ({
    root: {
      minWidth: theme.spacing(3),
      margin: theme.spacing(1),
      marginLeft: 0,
      fontSize: theme.font.getFontSize(0.85),
      alignSelf: 'center',

      [theme.breakpoints.up('lg')]: {
        marginBottom: 0,
      },
    },
  }),

  ButtonRow: theme => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(1),
      paddingRight: theme.spacing(0),
    },
    hidden: {
      visibility: 'hidden',
    },
  }),

  ScoreField: theme => ({
    root: {
      width: theme.spacing(10),
      marginTop: 1,
      flexGrow: 1,

      [theme.breakpoints.up('lg')]: {
        margin: 0,
      },
    },
  }),
};

const FormButton = createWithStyles(styles.FormButton)(ArcButton);
const ResultText = createWithStyles(styles.ResultText)(ArcText);
const ButtonRow = createWithStyles(styles.ButtonRow)(ArcView);
const ScoreField = createWithStyles(styles.ScoreField)(ArcView);

class GameVerificationListItem extends React.PureComponent {
  static displayName = 'GameVerificationListItem';

  static propTypes = {
    error: PropTypes.bool,
    formikProps: PropTypes.shape({}),
    hasUpdated: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    isEditing: PropTypes.bool,
    onUpdate: PropTypes.func,
    participantType: PropTypes.string,
    person: PropTypes.shape({
      id: PropTypes.string,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      rank: PropTypes.number,
      score: PropTypes.number,
      userId: PropTypes.string,
      teamName: PropTypes.string,
    }),
    rankingDisplay: PropTypes.number,
    reward: PropTypes.shape({
      displayName: PropTypes.string,
    }),
    setFieldValue: PropTypes.func.isRequired,
    touched: PropTypes.bool,
    value: PropTypes.string,
  };

  static defaultProps = {
    error: null,
    formikProps: {},
    hasUpdated: false,
    onChange: global.noop,
    onBlur: global.noop,
    isEditing: false,
    onUpdate: global.noop,
    participantType: null,
    person: null,
    rankingDisplay: 0,
    reward: null,
    touched: null,
    value: null,
  };

  handleClickReset = (field, value) => {
    this.props.setFieldValue(field, value);

    this.props.onUpdate(field, value, 'reset');
  };

  renderFormButtons = (formikProps, person) => {
    const scoreIsClean = formikProps.initialValues[person.id] === formikProps.values[person.id];
    const hasFieldChangedValue = !this.props.isEditing || (scoreIsClean && !this.props.hasUpdated);

    return (
      <ButtonRow>
        <Tooltip title={this.props.error || ''}>
          <FormButton
            variant="outlined"
            label="Update"
            onClick={() => this.props.onUpdate(person.id, formikProps.values[person.id])}
            isDisabled={!!formikProps.errors[person.id] || hasFieldChangedValue}
            color="secondary"
          />
        </Tooltip>
        <FormButton
          variant="outlined"
          color="danger"
          label="Reset"
          onClick={() => this.handleClickReset(person.id, formikProps.initialValues[person.id])}
          isDisabled={hasFieldChangedValue}
        />
      </ButtonRow>
    );
  }

  render() {
    const {
      isEditing,
      person,
      participantType,
      rankingDisplay,
      reward,
      formikProps,
    } = this.props;

    return (
      <ArcBox
        display="flex"
        flexDirection="column"
        py={1}
        borderBottom="1px solid #EBEBEB"
        alignItems="center"
        data-testid="GameVerificationListItem"
      >
        <ArcBox display="flex" flexDirection="row" width="100%">
          <ResultText>
            {`${rankingDisplay}${date.getOrdinalIndicator(rankingDisplay)}`}
          </ResultText>

          <ArcEntity
            type={participantType === 'team' ? 'team' : 'person'}
            name={person.name}
            imageUrl={person.imageUrl}
            teamName={person.teamName}
          />
          <ArcBox ml="auto" alignSelf="center">
            <ArcScoreboard
              score={person.score}
              numberWidth={14}
              decimalWidth={6}
            />
          </ArcBox>
        </ArcBox>

        {reward
              && (
                <ArcBox
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  alignItems="center"
                >
                  <ResultText>
                    {'Prize - '}
                  </ResultText>
                  <ResultText isStrong>
                    {reward.displayName}
                  </ResultText>
                </ArcBox>
              )
            }

        {isEditing
            && (
            <ArcBox
              display="flex"
              flexDirection="row"
              justify="flex-end"
              width="100%"
            >
              <ScoreField>
                <ArcTextField
                  name={person.id}
                  value={this.props.value}
                  variant="outlined"
                  margin="dense"
                  onChange={this.props.onChange}
                  onBlur={this.props.onBlur}
                  touched={this.props.touched}
                  error={this.props.error}
                  autoComplete="off"
                />
              </ScoreField>
              {this.renderFormButtons(formikProps, person)}
            </ArcBox>
            )
          }
      </ArcBox>
    );
  }
}

export default GameVerificationListItem;
