import React from 'react';

import {
  ArcView,
  ArcImage,
  ArcPropTypes,
  ArcStyles,
  theme,
  createWithStyles,
} from 'arcade-frontend-ui';

const blingStyle = {
  position: 'absolute',
  opacity: 0,
  padding: '5px',
  background: 'rgba(255, 255, 255, 0.8)',
  fontWeight: 'bold',
  borderRadius: '10px',
  whiteSpace: 'nowrap',
  animation: 'blingFadeSlide 1.5s ease-out 1',
  top: '10px',
  left: '0px',
  fontSize: '50px',
  height: '60px',
  flexDirection: 'row',
  alignItems: 'center',
};

const tokenImageStyle = {
  width: '70px',
  height: '70px',
  paddingLeft: '10px',
};


const ExperienceSparkle = ({ adjustment }) => (
  <ArcView style={blingStyle}>
    {`+${adjustment} XP!`}
  </ArcView>
);

ExperienceSparkle.propTypes = {
  adjustment: ArcPropTypes.string.isRequired,
};

const TokenSparkle = ({ adjustment }) => (
  <ArcView style={blingStyle}>
    <ArcView>{adjustment}</ArcView>
    <ArcView>
      <ArcImage src={theme.images.coin} alt="coin" style={tokenImageStyle} />
    </ArcView>
  </ArcView>
);

TokenSparkle.propTypes = {
  adjustment: ArcPropTypes.string.isRequired,
};


const upperCase = s => s.charAt(0).toUpperCase() + s.slice(1);

const centeredImageSparkleStyle = {
  opacity: 0,
  width: '100vmin',
  height: '100vmin',
  margin: 'auto',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  justifyContent: 'center',
  alignContents: 'center',
  fontSize: '5vmin',
  textAlign: 'center',
  color: '#555',
  animation: 'centeredImageFadeSlide 2s linear 1',
};

const centeredImageStyle = {
  width: '40%',
  height: '40%',
  margin: '0 auto 7vmin auto',
};

const ChestSparkle = ({ type }) => {
  const backgroundPath = theme.images[`rays${upperCase(type)}`];
  const backgroundImage = `url(${backgroundPath})`;
  const chestImage = theme.images[`chest${upperCase(type)}Closed`];

  return (
    <ArcView style={{ backgroundImage, ...centeredImageSparkleStyle }}>
      <ArcView>You unlocked a new</ArcView>
      <ArcImage
        src={chestImage}
        style={centeredImageStyle}
      />
      <ArcView>
        {type}
        {' '}
chest!
      </ArcView>
    </ArcView>
  );
};

ChestSparkle.propTypes = {
  type: ArcPropTypes.string.isRequired,
};

const notificationSparkleStyle = {
  root: {
    maxWidth: '60%',
    borderWidth: '0 3px 3px 3px',
    borderStyle: 'solid',
    borderColor: theme.green1,
    backgroundColor: theme.green2,
    padding: '16px 40px',
    color: 'white',
    textAlign: 'center',
    animation: 'notificationSlide 3s linear infinite',
    position: 'absolute',
    left: '50%',
    ...ArcStyles.typography.h4,
  },
};

const NotificationWrapper = createWithStyles(notificationSparkleStyle)(ArcView);
const NotificationSparkle = ({ message }) => (
  <NotificationWrapper>{message}</NotificationWrapper>);

NotificationSparkle.propTypes = {
  message: ArcPropTypes.string.isRequired,
};

export const sizzleTypes = {
  experience_changed: { // expects 'adjustment'
    duration: 1500,
    renderer: ExperienceSparkle,
  },
  tokens_changed: { // expects 'adjustment'
    duration: 1500,
    renderer: TokenSparkle,
  },
  chest_awarded: { // expects 'type'
    duration: 2000,
    renderer: ChestSparkle,
  },
  short_notification: { // expects 'message'
    duration: 3000,
    renderer: NotificationSparkle,
  },
};
