import createResourceAction from 'arcade-frontend-core/src/helpers/createResourceAction';

export const apiGetGameLeaderboard = createResourceAction('read', {
  resourceType: 'gameLeaderboard',
  requestKey: 'apiGetGameLeaderboard',

  api: props => {
    const { gameId } = props.meta;

    return {
      method: 'GET',
      url: `/v3/games/${gameId}/leaderboard`,
      transformResponse: data => ({
        resources: data.games.map(ranking => ({
          ...ranking,
          id: ranking.userId.toString(),
          userId: ranking.userId.toString(),
        })),
      }),
    };
  },
});
