import { combineReducers } from 'redux';

import accounts from './accounts';
import hasToppedUp from './hasToppedUp';
import isFetching from './isFetching';
import requestStatus from './requestStatus';
import topUpError from './topUpError';
import topUpResponse from './topUpResponse';
import topUpUrl from './topUpUrl';

const tokens = combineReducers({
  accounts,
  hasToppedUp,
  isFetching,
  requestStatus,
  topUpError,
  topUpResponse,
  topUpUrl,
});

export default tokens;
