import * as types from 'arcade-frontend-core/src/types/routes';

const scenes = {
  [types.ROUTE_MANAGE_ACTIVITIES]: types.ROUTE_MANAGE,

  [types.ROUTE_MANAGE_INTEGRATIONS]: types.ROUTE_MANAGE,

  [types.ROUTE_MANAGE_TRANSACTIONS]: types.ROUTE_MANAGE,

  [types.ROUTE_MANAGE_ENGAGEMENT]: types.ROUTE_MANAGE,

  [types.ROUTE_MANAGE_POSTS]: types.ROUTE_MANAGE,

  [types.ROUTE_MANAGE_METRICS]: types.ROUTE_MANAGE,
  [types.ROUTE_MANAGE_METRICS_SHOW]: types.ROUTE_MANAGE_METRICS,
  [types.ROUTE_MANAGE_METRICS_CREATE]: types.ROUTE_MANAGE_METRICS,
  [types.ROUTE_MANAGE_METRICS_EDIT]: types.ROUTE_MANAGE_METRICS,

  [types.ROUTE_MANAGE_GAMES]: types.ROUTE_MANAGE,
  [types.ROUTE_MANAGE_GAMES_SHOW]: types.ROUTE_MANAGE_GAMES,
  [types.ROUTE_MANAGE_GAMES_CREATE]: types.ROUTE_MANAGE_GAMES,
  [types.ROUTE_MANAGE_GAMES_EDIT]: types.ROUTE_MANAGE_GAMES,
  [types.ROUTE_MANAGE_GAMES_CLONE]: types.ROUTE_MANAGE_GAMES,
};

export default scenes;
