import React from 'react';
import PropTypes from 'prop-types';

import ArcGallery from '../ArcGallery';
import ArcAttachedFile from '../ArcAttachedFile';

import ArcView from '../../primitives/ArcView';

class ArcFileGallery extends React.PureComponent {
  static propTypes = {
    files: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      size: PropTypes.number,
    })),
    onRemove: PropTypes.func,
    renderAttachmentMessage: PropTypes.func,
  };

  static defaultProps = {
    files: [],
    onRemove: global.noop,
    renderAttachmentMessage: global.noop,
  };

  renderItem = item => item && (
    <ArcAttachedFile name={item.name} type={item.type} size={item.size} />
  );

  renderThumbnail = item => (
    <ArcView fullHeight>
      <ArcAttachedFile
        name={item.name}
        type={item.type}
        size={item.size}
        iconOnly
        isResponsive
      />
    </ArcView>
  );

  render() {
    return (
      <ArcGallery
        items={this.props.files}
        onRemove={this.props.onRemove}
        renderItem={this.renderItem}
        renderThumbnail={this.renderThumbnail}
        renderAttachmentMessage={this.props.renderAttachmentMessage}
      />
    );
  }
}

export default ArcFileGallery;
