import { getResources, getStatus, resourceReducer } from 'redux-resource';

// Sort games first by participation, then by expiry
export function sortByInterest(games) {
  return [...games].sort((gameA, gameB) => {
    const participantA = gameA.rankings.some(ranking => ranking.isSelf);
    const participantB = gameB.rankings.some(ranking => ranking.isSelf);
    if (participantA === participantB) {
      return gameA.expiresAt?.localeCompare(gameB.expiresAt);
    }
    return participantA ? -1 : 1;
  });
}

const games = resourceReducer('games');

export default games;

export const getGameStatus = id => state =>
  getStatus(state, `gamesV2.games.meta[${id}].readStatus`);

export const getGames = id => state => getResources(state.gamesV2.games, [id]);

export const getGame = id => state => getGames(id)(state)[0];

export const getActiveGames = state =>
  getResources(state.gamesV2.games, 'active');
export const getActiveGamesByInterest = state =>
  sortByInterest(getActiveGames(state));
export const getActiveGamesStatus = state =>
  getStatus(state, 'gamesV2.games.requests.apiGetActiveGamesIndex.status');

export const getPendingGames = state =>
  getResources(state.gamesV2.games, 'pending');
export const getPendingGamesByInterest = state =>
  sortByInterest(getPendingGames(state));
export const getPendingGamesStatus = state =>
  getStatus(state, 'gamesV2.games.requests.apiGetPendingGamesIndex.status');

export const getUpcomingGames = state =>
  getResources(state.gamesV2.games, 'upcoming');
export const getUpcomingGamesByInterest = state =>
  sortByInterest(getUpcomingGames(state));
export const getUpcomingGamesStatus = state =>
  getStatus(state, 'gamesV2.games.requests.apiGetUpcomingGamesIndex.status');
