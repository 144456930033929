import ArcConsole from '../../helpers/arc/console';

import * as light from './light';

export const LIGHT = 'light';
// export const DARK = 'dark';
// export const KIOSK = 'kiosk';

export const availableThemes = {
  [LIGHT]: light,
};

let defaultTheme = LIGHT;
let currentTheme = defaultTheme;

const validateTheme = (theme) => {
  if (!availableThemes[theme]) {
    const invalid = [
      `'${theme}' is not a valid theme.`,
      `Valid themes are [${Object.keys(availableThemes).join(', ')}].`,
      `Defaulting to ${defaultTheme}.`,
    ].join(' ');

    ArcConsole.warn(invalid);

    return defaultTheme;
  }

  return theme;
};
export const getTheme = () => availableThemes[currentTheme];

export const setTheme = (theme) => {
  const newTheme = validateTheme(theme);
  currentTheme = newTheme;
};

export const setDefaultTheme = (theme) => {
  const newTheme = validateTheme(theme);
  defaultTheme = newTheme;
};
