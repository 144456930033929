import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { makeStyles } from '@material-ui/styles';

import * as GAME_SCENE_SIZES from 'arcade-frontend-core/src/types/game-scene-sizes';
import { RPA_ONE_TIME } from 'arcade-frontend-core/src/types/game-formats';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcOneTime from 'arcade-frontend-ui/src/assets/ArcOneTime';
import getRewardsAsText from 'arcade-frontend-core/src/helpers/getRewardsAsText';

import GamesGameProgressIndicator from '../GamesGameProgressIndicator';

const styles = makeStyles({
  outlinePanel: {
    width: '70%',
    border: '3px dotted white',
    padding: 32,
    borderRadius: 16,
    backgroundColor: '#342467',
    color: '#FBBB38',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  scroller: {
    whiteSpace: 'nowrap',
    animation: 'gameTitleScroller linear 4s infinite both',
  },
});

function GamesSceneOneTime({
  currentUserRanking,
  metric,
  pendingCount,
  rewards,
  size,
}) {
  const classes = styles();

  let locked = true;
  if (currentUserRanking) {
    const { score, goal } = currentUserRanking;
    locked = score < goal;
  }

  let marginTop = GAME_SCENE_SIZES.LARGE ? 8 : 0;

  if (!locked) {
    marginTop = 2;
  }

  return (
    <ArcBox
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      bgcolor="common.white"
      color="common.black"
    >
      <ArcBox
        zIndex={1}
        width="100%"
        height="100%"
        position="absolute"
        top={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ArcBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          marginTop={marginTop}
        >
          <ArcBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={locked ? '30%' : '15%'}
          >
            <ArcOneTime />
          </ArcBox>
          {locked ? (
            <>
              <ArcBox mt={1} fontSize={18}>
                Prize Locked
              </ArcBox>
              <ArcBox mt={1} fontSize={14}>
                Complete To Unlock
              </ArcBox>
            </>
          ) : (
            <>
              <ArcBox
                fontSize={48}
                className={classes.outlinePanel}
                marginTop={2}
              >
                <ArcBox className={classes.scroller}>
                  You Won {getRewardsAsText(rewards)}
                </ArcBox>
              </ArcBox>
              <ArcBox px={2} mt={2} fontSize={18} textAlign="center">
                {getRewardsAsText(rewards)} Won!
              </ArcBox>
            </>
          )}
        </ArcBox>

        {currentUserRanking && locked && (
          <ArcBox mt={2} mb={9}>
            {currentUserRanking.goal > 1 && (
              <GamesGameProgressIndicator
                score={currentUserRanking.score}
                goal={currentUserRanking.goal}
                metric={metric}
                unit={currentUserRanking.unit}
                type={RPA_ONE_TIME}
                color="common.black"
              />
            )}
          </ArcBox>
        )}

        {pendingCount > 0 && (
          <ArcBox
            position="absolute"
            bottom={16}
            color="rgba(255, 255, 255, 0.5)"
            fontSize={14}
          >
            {pendingCount} pending {pluralize('verification', pendingCount)}
          </ArcBox>
        )}
      </ArcBox>
    </ArcBox>
  );
}

GamesSceneOneTime.displayName = 'GamesSceneOneTime';

GamesSceneOneTime.propTypes = {
  currentUserRanking: PropTypes.shape({
    goal: PropTypes.number,
    score: PropTypes.number,
    unit: PropTypes.string,
  }),
  metric: PropTypes.string,
  pendingCount: PropTypes.number,
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  size: PropTypes.oneOf(Object.values(GAME_SCENE_SIZES)),
};

GamesSceneOneTime.defaultProps = {
  metric: '',
  currentUserRanking: null,
  pendingCount: 0,
  rewards: [],
  isLarge: false,
  size: GAME_SCENE_SIZES.SMALL,
};

export default GamesSceneOneTime;
