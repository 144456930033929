import React from 'react';
import { createWithStyles } from '../../styles';
import ArcView from '../../primitives/ArcView/ArcView';
import ArcPropTypes from '../../helpers/arc/propTypes';

const styles = {
  Snowfield: () => ({
    root: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      animationName: 'snowFalling',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
    },
  }),

  Snow: () => ({
    root: {
      position: 'absolute',
      height: '100%',
    },
  }),
};

const Snowfield = createWithStyles(styles.Snowfield)(ArcView);
const Snow = createWithStyles(styles.Snow)(ArcView);

const Snowflake = ({ maxSize, position }) => {
  const { x, y, size } = position;
  return (
    <Snow style={{ left: `${x}%`, top: `${y}%` }}>
      <svg width={maxSize * 2} height={maxSize * 2} style={{ top: maxSize * 2, position: 'absolute' }}>
        <circle r={size} cx={maxSize} cy={maxSize} fill="white" />
      </svg>
      <svg width={maxSize * 2} height={maxSize * 2} style={{ bottom: maxSize * -4, position: 'absolute' }}>
        <circle r={size} cx={maxSize} cy={maxSize} fill="white" />
      </svg>
    </Snow>
  );
};

Snowflake.propTypes = {
  position: ArcPropTypes.shape({
    x: ArcPropTypes.number.isRequired,
    y: ArcPropTypes.number.isRequired,
    size: ArcPropTypes.number.isRequired,
  }).isRequired,
  maxSize: ArcPropTypes.number.isRequired,
};

export default class extends React.Component {
  static propTypes = {
    total: ArcPropTypes.number,
    maxSize: ArcPropTypes.number,
    minSize: ArcPropTypes.number,
    duration: ArcPropTypes.number,
  };

  static defaultProps = {
    total: 100,
    maxSize: 5,
    minSize: 1,
    duration: 5,
  };

  constructor(props) {
    super(props);

    const { total, maxSize, minSize } = props;
    this.flakes = [];
    for (let i = 0; i < total; i += 1) {
      this.flakes.push({
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: (Math.random() * (maxSize - minSize)) + minSize,
      });
    }
  }

  render() {
    return (
      <Snowfield style={{ animationDuration: `${this.props.duration}s` }}>
        { this.flakes.map((position, i) => (
          <Snowflake key={i.toString()} position={position} maxSize={this.props.maxSize} />
        ))}
      </Snowfield>
    );
  }
}
