import * as types from 'arcade-frontend-core/src/types/routes';

export const routeMetrics = () => ({
  type: types.ROUTE_METRICS,
  payload: {
    id: null,
    metricDetailsType: null,
  },
});

export const routeMetricsShow = (id, metricDetailsType) => ({
  type: types.ROUTE_METRICS_SHOW,
  payload: {
    id,
    metricDetailsType,
  },
});
