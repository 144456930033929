import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';

import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import * as GAME_PLAYER_STATUSES from 'arcade-frontend-core/src/types/game-player-statuses';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import { ArcText } from 'arcade-frontend-ui';

const Badge = ({ backgroundColor, children }) => (
  <ArcBox
    component={ArcText}
    bgcolor={backgroundColor}
    color="common.white"
    fontSize={14}
    px={1.5}
    py={1}
    borderRadius={7}
  >
    {children}
  </ArcBox>
);

function GamesWinnerBadge({ playerStatus, winners }) {
  const currentUser = useSelector(getCurrentUser);

  const didCurrentUserWin =
    winners &&
    winners.find(
      winner =>
        currentUser.id === winner.userId ||
        winner.includedUsers.indexOf(currentUser.id) > -1,
    );

  const hasWinners = winners.length > 0;

  if (playerStatus === GAME_PLAYER_STATUSES.PLAYER) {
    return didCurrentUserWin ? (
      <Badge backgroundColor="#00A77C">{'Winner!'}</Badge>
    ) : (
      <Badge backgroundColor="#676f80">{'No Win'}</Badge>
    );
  }

  return hasWinners ? (
    <Badge backgroundColor="#00A77C">
      {`${winners.length} ${pluralize('Winner', winners.length)}`}
    </Badge>
  ) : (
    <Badge backgroundColor="#676f80">{'No Winners'}</Badge>
  );
}

GamesWinnerBadge.displayName = 'GamesWinnerBadge';

GamesWinnerBadge.propTypes = {
  playerStatus: PropTypes.oneOf(GAME_PLAYER_STATUSES.ALL_PARTICIPANT_TYPES),
  winners: PropTypes.arrayOf(PropTypes.any),
};

GamesWinnerBadge.defaultProps = {
  playerStatus: null,
  winners: [],
};

Badge.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
};

Badge.defaultProps = {
  backgroundColor: '#676f80',
  children: null,
};

export default GamesWinnerBadge;
