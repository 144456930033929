import React from 'react';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import styledTheme from 'arcade-frontend-core/src/styles/styled-theme';
import ArcPropTypes from '../helpers/arc/propTypes';
import TinyDatePickerJss from '../jss/TinyDatePickerJss';
import VideoJsJss from '../jss/VideoJsJss';
import arcTheme from '../styles/theme';

export const theme = arcTheme;

const ArcThemeProvider = props => (
  <StyledThemeProvider theme={styledTheme}>
    <ThemeProvider theme={theme}>
      <TinyDatePickerJss />
      <VideoJsJss />
      {props.children}
    </ThemeProvider>
  </StyledThemeProvider>
);

ArcThemeProvider.theme = theme;

ArcThemeProvider.propTypes = {
  children: ArcPropTypes.children.isRequired,
};

export default ArcThemeProvider;
