import * as GAME_SCENE_SIZES from 'arcade-frontend-core/src/types/game-scene-sizes';

export const getStyleByGameSceneSize = (size, values) => {
  switch (size) {
    case GAME_SCENE_SIZES.SMALL:
      return values[0];
    case GAME_SCENE_SIZES.LARGE:
      return values[1];
    default:
      return null;
  }
};
