import React from 'react';
import PropTypes from 'prop-types';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import usePermission from 'arcade-frontend-core/src/hooks/usePermission';
import makeStyles from '@material-ui/styles/makeStyles';

import { ArcView, Grid } from 'arcade-frontend-ui';

import PeopleGridItem from './PeopleGridItem/PeopleGridItem';

const useStyles = makeStyles(theme => ({
  gridWrapper: {
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
  },

  peopleGrid: {
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'initial',
    },
  },
}));

function PeopleGrid({
  people,
  isFetching,
  canAwardChests,
  canAwardTokens,
  currentUser,
  onUpdateProfileSuccess,
  onUpdateTeamSuccess,
}) {
  const classes = useStyles();
  const hasTokenBalance = useFeatureFlag(FEATURE_FLAGS.TOKEN_BALANCE);
  const isManager = usePermission(PERMISSIONS.MANAGE_GAMES);
  const canManageRewards = usePermission(PERMISSIONS.MANAGE_REWARDS);

  return (
    <ArcView className={classes.gridWrapper}>
      <Grid container spacing={2} className={classes.peopleGrid}>
        {people.map(person => (
          <Grid item key={person.id}>
            <PeopleGridItem
              isFetching={isFetching && !people}
              canAwardChests={canAwardChests}
              canAwardTokens={canAwardTokens}
              canManageRewards={canManageRewards}
              isCurrentUser={person.id === currentUser.id}
              isManager={isManager}
              hasTokenBalance={hasTokenBalance}
              onUpdateProfileSuccess={onUpdateProfileSuccess}
              onUpdateTeamSuccess={onUpdateTeamSuccess}
              {...person}
            />
          </Grid>
        ))}
      </Grid>
    </ArcView>
  );
}

PeopleGrid.displayName = 'PeopleGrid';

PeopleGrid.propTypes = {
  canAwardChests: PropTypes.bool,
  canAwardTokens: PropTypes.bool,
  currentUser: PropTypes.shape({ id: PropTypes.string }),
  isFetching: PropTypes.bool,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      image: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      team: PropTypes.string,
    }),
  ),
  onUpdateProfileSuccess: PropTypes.func,
  onUpdateTeamSuccess: PropTypes.func,
};

PeopleGrid.defaultProps = {
  canAwardChests: false,
  canAwardTokens: false,
  currentUser: {},
  isFetching: false,
  people: [],
  onUpdateProfileSuccess: global.noop,
  onUpdateTeamSuccess: global.noop,
};

export default PeopleGrid;
