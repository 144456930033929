import React from 'react';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView/ArcView';

const styleArcPerson = {
  color: '#8C8C8C',
  lineHeight: '24px',
};

const styleArcPersonProfileImage = imageUrl => ({
  overflow: 'hidden',
  width: 32,
  height: 32,
  marginRight: 8,
  borderRadius: 2,
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  backgroundImage: `url(${imageUrl})`,
});

const styleName = {
  fontSize: 16,
  lineHeight: '22px',
};

const styleTeam = {
  fontSize: 10,
  lineHeight: '10px',
};

class ArcPerson extends React.PureComponent {
  static displayName = 'ArcPerson';

  static propTypes = {
    name: ArcPropTypes.string.isRequired,
    imageUrl: ArcPropTypes.string,
    style: ArcPropTypes.style,
    teamName: ArcPropTypes.string,
  };

  static defaultProps = {
    imageUrl: null,
    style: null,
    teamName: null,
  };

  render() {
    const {
      imageUrl,
      name,
      teamName,
      style,
      ...rest
    } = this.props;

    return (
      <ArcView
        row
        align="center"
        {...rest}
        style={{
          ...styleArcPerson,
          ...style,
        }}
      >
        {imageUrl && <ArcView style={styleArcPersonProfileImage(imageUrl)} />}

        <ArcView>
          <ArcView style={styleName} className="ArcPerson__name">{name}</ArcView>
          {teamName && <ArcView style={styleTeam}>{teamName}</ArcView>}
        </ArcView>
      </ArcView>
    );
  }
}

export default ArcPerson;
