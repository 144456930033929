import React from 'react';

function ArcEveryTime(props) {
  return (
    <svg viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M21.9853 154.966C20.7146 154.964 19.5564 154.237 19.0037 153.093C18.4511 151.949 18.6012 150.589 19.3903 149.593C21.8706 146.519 23.9704 143.156 25.6441 139.578C22.7193 139.271 19.974 138.02 17.8227 136.015C15.6896 133.819 14.6819 130.765 15.0886 127.73C15.5507 125.065 17.0528 122.692 19.2644 121.135C23.3203 118.224 28.8574 118.536 32.5607 121.884C35.0569 124.566 36.1458 128.266 35.5003 131.873C35.4241 132.4 35.3246 132.913 35.2053 133.417C37.3496 133.344 39.57 133.195 41.8634 133.046C44.9919 132.84 48.2199 132.625 51.4247 132.605H51.4445C53.2749 132.599 54.7639 134.077 54.7703 135.908C54.7767 137.738 53.2981 139.227 51.4678 139.233C48.4618 139.233 45.3267 139.459 42.2976 139.658C39.1061 139.87 35.8284 140.085 32.6535 140.085L32.3452 140.798C30.3697 145.437 27.7634 149.782 24.6001 153.709C23.9678 154.508 23.0036 154.971 21.9853 154.966ZM25.5678 125.775C24.7008 125.768 23.8528 126.029 23.1386 126.52C22.3733 127.028 21.8378 127.816 21.6472 128.714C21.5449 129.724 21.9028 130.726 22.6216 131.442C24.277 132.66 26.2868 133.3 28.3418 133.265C28.6138 132.512 28.8135 131.735 28.9383 130.945C29.2635 129.427 28.8846 127.843 27.9076 126.636C27.2696 126.056 26.4302 125.746 25.5678 125.775Z" fill="#D4402D" />
      <path d="M204.623 115.17L192.825 113.877C189.195 106.112 183.953 99.2095 177.448 93.6279L177.023 93.439C178.066 92.5691 178.902 91.4776 179.469 90.2441C180.444 88.031 181.087 85.6857 181.375 83.2845C181.848 78.6749 181.768 74.0253 181.136 69.4347C181.234 65.7289 179.965 62.1166 177.57 59.2869C173.073 55.3398 165.673 60.7617 162.113 63.7212C157.907 67.1567 154.627 71.5912 152.575 76.6198C152.047 77.9802 151.643 79.3856 151.369 80.8188C151.307 81.2081 151.272 81.6012 151.263 81.9953L151.2 81.9621C147.21 80.526 143.137 79.3308 139.004 78.3829L81.2054 82.5587C64.1045 90.4132 49.7212 105.492 45.8436 124.184C41.005 147.516 54.4272 169.024 73.6823 181.287C74.312 181.684 74.9417 182.082 75.6045 182.48L77.7255 212.174C77.8646 213.905 79.3032 215.243 81.0397 215.256H101.388C103.125 215.243 104.563 213.905 104.703 212.174L105.995 193.714C115.904 195.924 126.178 195.924 136.087 193.714L137.38 212.174C137.519 213.905 138.957 215.243 140.694 215.256H161.043C162.779 215.243 164.218 213.905 164.357 212.174L166.478 182.48C172.662 178.822 178.26 174.252 183.082 168.925H183.115C184.662 167.228 186.101 165.434 187.423 163.556C189.544 160.649 191.355 157.528 192.825 154.243L204.623 152.951C207.984 152.579 210.526 149.737 210.523 146.356V121.765C210.526 118.384 207.984 115.542 204.623 115.17Z" fill="#F3705A" />
      <path d="M164.125 129.089C156.804 129.089 150.869 123.154 150.869 115.832C150.869 114.002 152.352 112.518 154.183 112.518C156.013 112.518 157.497 114.002 157.497 115.832C157.497 119.493 160.464 122.46 164.125 122.46C167.786 122.46 170.753 119.493 170.753 115.832C170.753 114.002 172.237 112.518 174.068 112.518C175.898 112.518 177.382 114.002 177.382 115.832C177.382 123.154 171.447 129.089 164.125 129.089Z" fill="#252529" />
      <path d="M95.4894 93.8267C80.9929 88.6331 71.323 74.8911 71.3294 59.4923C71.3165 53.7309 72.68 48.0497 75.3063 42.9217C82.7406 28.3277 98.9909 20.4785 115.044 23.7277C131.097 26.9769 143.015 40.5278 144.189 56.8641C145.363 73.2004 135.504 88.316 120.08 93.8267H95.4894Z" fill="#FFD64F" />
      <path d="M177.381 152.288V152.255C177.375 151.071 176.738 149.98 175.71 149.393C174.682 148.806 173.418 148.812 172.396 149.41C171.373 150.007 170.747 151.104 170.753 152.288C171.384 159.719 176.156 166.158 183.082 168.925H183.115C184.662 167.228 186.101 165.435 187.423 163.556C177.878 160.01 177.381 152.885 177.381 152.288Z" fill="#D4402D" />
      <path d="M134.298 102.576C133.617 102.575 132.953 102.365 132.395 101.973C124.937 97.6512 116.396 95.5601 107.785 95.9478C99.1748 95.5608 90.6345 97.6518 83.1773 101.973C81.6804 102.99 79.6442 102.618 78.6034 101.138C77.5626 99.657 77.9021 97.6151 79.3661 96.551C85.8021 92.0206 96.4272 89.3196 107.785 89.3196C119.142 89.3196 129.767 92.0206 136.203 96.551C137.385 97.3822 137.893 98.8836 137.457 100.261C137.021 101.639 135.743 102.576 134.298 102.576Z" fill="#D4402D" />
      <path d="M111.099 56.4799V40.118C114.787 40.9547 117.484 44.119 117.727 47.893C117.727 49.7233 119.211 51.2071 121.041 51.2071C122.872 51.2071 124.355 49.7233 124.355 47.893C124.035 40.464 118.462 34.3204 111.099 33.281V32.9794C111.099 31.1491 109.615 29.6653 107.785 29.6653C105.954 29.6653 104.471 31.1491 104.471 32.9794V33.281C97.1077 34.3204 91.534 40.464 91.2141 47.893C91.534 55.3219 97.1077 61.4656 104.471 62.505V78.8668C100.782 78.0301 98.0849 74.8659 97.8423 71.0919C97.8423 69.2615 96.3585 67.7777 94.5282 67.7777C92.6978 67.7777 91.2141 69.2615 91.2141 71.0919C91.534 78.5208 97.1077 84.6645 104.471 85.7039V86.0054C104.471 87.8358 105.954 89.3196 107.785 89.3196C109.615 89.3196 111.099 87.8358 111.099 86.0054V85.7039C118.462 84.6645 124.035 78.5208 124.355 71.0919C124.035 63.6629 118.462 57.5193 111.099 56.4799ZM97.8423 47.893C98.0849 44.119 100.782 40.9547 104.471 40.118V55.6679C100.782 54.8312 98.0849 51.667 97.8423 47.893ZM111.099 78.8668V63.3169C114.787 64.1536 117.484 67.3178 117.727 71.0919C117.484 74.8659 114.787 78.0301 111.099 78.8668Z" fill="#E99726" />
      <path d="M155.676 51.2965C149.27 49.0016 144.997 42.9293 145 36.125C144.994 33.5792 145.597 31.0688 146.757 28.8029C150.042 22.3542 157.223 18.8858 164.316 20.3216C171.41 21.7573 176.676 27.7451 177.195 34.9637C177.714 42.1823 173.357 48.8615 166.542 51.2965C166.542 51.2965 164.069 52.1473 161.116 52.1473C157.656 52.1473 155.676 51.2965 155.676 51.2965Z" fill="#FFD64F" />
      <path d="M162.573 34.794V27.5641C164.202 27.9338 165.394 29.332 165.501 30.9997C165.501 31.8084 166.157 32.4641 166.966 32.4641C167.775 32.4641 168.43 31.8084 168.43 30.9997C168.289 27.717 165.826 25.0023 162.573 24.543V24.4097C162.573 23.601 161.917 22.9453 161.108 22.9453C160.299 22.9453 159.644 23.601 159.644 24.4097V24.543C156.39 25.0023 153.927 27.717 153.786 30.9997C153.927 34.2823 156.39 36.997 159.644 37.4563V44.6862C158.014 44.3165 156.822 42.9183 156.715 41.2507C156.715 40.4419 156.059 39.7862 155.25 39.7862C154.442 39.7862 153.786 40.4419 153.786 41.2507C153.927 44.5333 156.39 47.248 159.644 47.7073V47.8406C159.644 48.6494 160.299 49.305 161.108 49.305C161.917 49.305 162.573 48.6494 162.573 47.8406V47.7073C165.826 47.248 168.289 44.5333 168.43 41.2507C168.289 37.968 165.826 35.2533 162.573 34.794ZM156.715 30.9997C156.822 29.332 158.014 27.9338 159.644 27.5641V34.4352C158.014 34.0655 156.822 32.6673 156.715 30.9997ZM162.573 44.6862V37.8151C164.202 38.1848 165.394 39.583 165.501 41.2507C165.394 42.9183 164.202 44.3165 162.573 44.6862Z" fill="#E99726" />
      <path d="M98.3684 21.6008C93.9472 20.0169 90.9981 15.8258 91 11.1295C90.9961 9.37234 91.4119 7.63969 92.2129 6.07574C94.4802 1.62486 99.4363 -0.769011 104.332 0.221941C109.228 1.21289 112.863 5.34566 113.221 10.3279C113.579 15.3102 110.572 19.9201 105.868 21.6008C105.868 21.6008 104.161 22.188 102.123 22.188C99.7355 22.188 98.3684 21.6008 98.3684 21.6008Z" fill="#FFD64F" />
      <path d="M103.129 10.2114V5.22134C104.254 5.47652 105.076 6.44154 105.15 7.59255C105.15 8.15077 105.603 8.6033 106.161 8.6033C106.719 8.6033 107.172 8.15077 107.172 7.59255C107.074 5.32686 105.374 3.45317 103.129 3.13617V3.04419C103.129 2.48597 102.676 2.03345 102.118 2.03345C101.56 2.03345 101.107 2.48597 101.107 3.04419V3.13617C98.8617 3.45317 97.1618 5.32686 97.0643 7.59255C97.1618 9.85824 98.8617 11.7319 101.107 12.0489V17.039C99.9825 16.7838 99.1598 15.8188 99.0858 14.6678C99.0858 14.1096 98.6332 13.657 98.075 13.657C97.5168 13.657 97.0643 14.1096 97.0643 14.6678C97.1618 16.9335 98.8617 18.8072 101.107 19.1242V19.2161C101.107 19.7743 101.56 20.2269 102.118 20.2269C102.676 20.2269 103.129 19.7743 103.129 19.2161V19.1242C105.374 18.8072 107.074 16.9335 107.172 14.6678C107.074 12.4021 105.374 10.5284 103.129 10.2114ZM99.0858 7.59255C99.1598 6.44154 99.9825 5.47652 101.107 5.22134V9.96376C99.9825 9.70858 99.1598 8.74356 99.0858 7.59255ZM103.129 17.039V12.2966C104.254 12.5517 105.076 13.5168 105.15 14.6678C105.076 15.8188 104.254 16.7838 103.129 17.039Z" fill="#E99726" />
    </svg>

  );
}

ArcEveryTime.displayName = 'ArcEveryTime';

export default ArcEveryTime;
