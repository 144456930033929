import React from 'react';

import {
  G,
  Path,
} from 'react-primitives-svg';

import Icon from '../Icon';

const BlankProfileIcon = props => (
  <Icon {...props}>
    <G
      fill="currentColor"
      fillRule="nonzero"
    >
      <Path d="M11.957 13.65c2.953 0 5.348-2.385 5.348-5.326 0-2.94-2.395-5.31-5.348-5.31S6.61 5.4 6.61 8.325c0 2.924 2.395 5.326 5.348 5.326zm0-9.247c2.165 0 3.937 1.765 3.937 3.921 0 2.157-1.772 3.92-3.937 3.92S8.02 10.482 8.02 8.325c0-2.156 1.772-3.92 3.937-3.92zM2.705 21.965h18.57c.393 0 .705-.31.705-.702 0-3.676-3.002-6.682-6.71-6.682H8.71c-3.692 0-6.71 2.99-6.71 6.682 0 .392.312.702.705.702zm6.004-5.979h6.562a5.288 5.288 0 0 1 5.249 4.574H3.46a5.302 5.302 0 0 1 5.25-4.574z" />
    </G>
  </Icon>
);


export default BlankProfileIcon;
