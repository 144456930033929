import React from 'react';
import {
  ArcPropTypes,
  ArcScoreboardList,
} from 'arcade-frontend-ui';

class GameLeaderboard extends React.PureComponent {
  static displayName = 'GameLeaderboard';

  static propTypes = {
    rankings: ArcPropTypes.gameRankings.isRequired,
    currentUserId: ArcPropTypes.string.isRequired,
    currentGameId: ArcPropTypes.string.isRequired,
    symbol: ArcPropTypes.string,
    threshold: ArcPropTypes.number,
    hideHeader: ArcPropTypes.bool,
  };

  static defaultProps = {
    threshold: undefined,
    hideHeader: false,
    symbol: '#',
  };

  renderScoreboard() {
    return (
      <ArcScoreboardList
        threshold={this.props.threshold}
        id={this.props.currentGameId}
        currentUserId={this.props.currentUserId}
        rankings={this.props.rankings}
        symbol={this.props.symbol}
        hideHeader={this.props.hideHeader}
      />
    );
  }

  render() {
    return this.renderScoreboard();
  }
}

export default GameLeaderboard;
