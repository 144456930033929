import React from 'react';
import { useSelector } from 'react-redux';

import {
  getLocationType,
  getLocationPayload,
  getLocationQuery,
} from 'arcade-frontend-features/src/reducers/location';
import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';
import navigateTo from 'arcade-frontend-core/src/helpers/navigateTo';

import ProfileEditFormContainer from '../../containers/ProfileEditFormContainer';

const INITIAL_LOAD_DELAY = 1000;

function ProfileView() {
  const locationPayload = useSelector(getLocationPayload);
  const locationQuery = useSelector(getLocationQuery);
  const locationType = useSelector(getLocationType);

  function handleClose() {
    navigateTo(`/arcade/profile/${locationPayload.id}`);
  }

  function handleTabChange(tab) {
    navigateTo(`/arcade/profile/${locationPayload.id}/edit`, {
      tab,
    });
  }

  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setIsReady(true);
    }, INITIAL_LOAD_DELAY);

    return () => window.clearTimeout(timeout);
  });

  if (!isReady) {
    return null;
  }

  return (
    <ProfileEditFormContainer
      open={locationType === ROUTE_TYPES.ROUTE_PROFILE_EDIT}
      userId={locationPayload.id}
      currentTab={locationQuery.tab}
      currentLocation={locationType}
      onClose={handleClose}
      onTabChange={handleTabChange}
    />
  );
}

ProfileView.displayName = 'ProfileView';

export default ProfileView;
