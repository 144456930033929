import React from 'react';
import PropTypes from 'prop-types';

import ArcView from '../../primitives/ArcView/ArcView';
import { distribute } from '../../helpers/utils/distribution';


const positionOffset = (p, offsetLeft, offsetTop) => ({
  position: 'absolute',
  left: p.x - offsetLeft,
  top: p.y - offsetTop,
});

const ExampleProgress = ({
  index,
  slots,
  color,
  offsetLeft,
  offsetTop,
}) => {
  const slot = slots[index % (slots.length)];
  return (
    <ArcView
      style={{
        ...positionOffset(slot, offsetLeft, offsetTop),
        backgroundColor: color,
        padding: 20,
        borderRadius: 50,
      }}
    />
  );
};

ExampleProgress.propTypes = {
  index: PropTypes.number,
  slots: PropTypes.arrayOf(PropTypes.shape({})),
  color: PropTypes.string,
  offsetLeft: PropTypes.number,
  offsetTop: PropTypes.number,
};

ExampleProgress.defaultProps = {
  index: 0,
  slots: [],
  color: '#00a77c',
  offsetLeft: 0,
  offsetTop: 0,
};

const PositionedPlayersDemo = ({ slots, numPlayers, goal, offsetLeft, offsetTop }) => {
  const scores = [];
  const colors = ['#00A77C', '#FFD43D', '#F3604F', '#6F59BE'];

  for (let index = 0; index < numPlayers && index < colors.length; index += 1) {
    scores.push({ score: index + 1, color: colors[index] });
  }
  const reversed = [...scores].reverse();
  const target = goal;
  const distribution = distribute(slots.length, reversed, target);

  return (
    <React.Fragment>
      {distribution.map((score, index) => score && (
      <ExampleProgress
        key={index.toString()}
        slots={slots}
        index={index}
        color={colors[score.score - 1]}
        offsetLeft={offsetLeft}
        offsetTop={offsetTop}
      />
      ))}
    </React.Fragment>
  );
};

PositionedPlayersDemo.propTypes = {
  slots: PropTypes.arrayOf(PropTypes.shape({})),
  numPlayers: PropTypes.number,
  goal: PropTypes.number,
  offsetLeft: PropTypes.number,
  offsetTop: PropTypes.number,
};

PositionedPlayersDemo.defaultProps = {
  slots: [],
  numPlayers: 3,
  goal: 5,
  offsetLeft: 0,
  offsetTop: 0,
};


export default PositionedPlayersDemo;
