import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ArcPropTypes, currency } from 'arcade-frontend-ui';

import { actions, routes } from '../../actions/metrics';

import MetricCardDetails from '../../components/metrics/MetricCardDetails';

import { getMetricsById } from '../../reducers/metrics/metricsById';
import { getCurrentMetricId } from '../../reducers/metrics/currentMetricId';
import { getCurrentMetricDetailsType } from '../../reducers/metrics/currentMetricDetailsType';
import { getActivitiesById } from '../../reducers/metrics/activitiesById';

class MetricDetailsContainer extends React.PureComponent {
  static propTypes = {
    apiMetricsActivitiesIndexRequest: PropTypes.func.isRequired,
    apiMetricsActivitiesCreateRequest: PropTypes.func.isRequired,
    apiMetricsRankingsIndexRequest: PropTypes.func.isRequired,
    routeMetrics: PropTypes.func.isRequired,
    routeMetricsShow: PropTypes.func.isRequired,

    activitiesById: ArcPropTypes.activitiesById.isRequired,
    rankingsById: ArcPropTypes.rankingsById.isRequired,
    metricsById: ArcPropTypes.metricsById.isRequired,

    onDateRangeChange: PropTypes.func,
    dateRange: PropTypes.shape({
      type: PropTypes.oneOf(['time period', 'date range']),
      timePeriod: PropTypes.string,
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
    }),

    currentMetricDetailsType: PropTypes.string,
    currentMetricId: PropTypes.string,
  };

  static defaultProps = {
    onDateRangeChange: global.noop,
    dateRange: undefined,
    currentMetricDetailsType: null,
    currentMetricId: null,
  };

  constructor(props) {
    super(props);

    const { currentMetricId, currentMetricDetailsType } = props;

    if (currentMetricId) {
      this.handleApiRequests(currentMetricId, currentMetricDetailsType);
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillReceiveProps(nextProps) {
    const { currentMetricId, currentMetricDetailsType } = nextProps;

    const isChangingId =
      currentMetricId && currentMetricId !== this.props.currentMetricId;

    const isChangingType =
      currentMetricDetailsType !== this.props.currentMetricDetailsType;

    if (isChangingId || (!isChangingId && isChangingType)) {
      this.handleApiRequests(currentMetricId, currentMetricDetailsType);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = e => {
    if (e.which === 27) {
      this.props.routeMetrics();
    }
  };

  handleApiRequests(currentMetricId, currentMetricDetailsType) {
    const { dateRange } = this.props;

    switch (currentMetricDetailsType) {
      case 'activity':
        return this.props.apiMetricsActivitiesIndexRequest({
          metricId: currentMetricId,
          ...dateRange,
        });
      case 'ranking':
        return this.props.apiMetricsRankingsIndexRequest({
          metricId: currentMetricId,
          ...dateRange,
        });
      default:
        return null;
    }
  }

  handleClickNav = (e, id, type) => this.props.routeMetricsShow(id, type);

  handleClose = () => {
    this.props.routeMetrics();
  };

  render() {
    const {
      currentMetricId,
      currentMetricDetailsType,
      apiMetricsActivitiesCreateRequest,
      routeMetrics,
      metricsById,
    } = this.props;
    const currentMetric = this.props.metricsById[this.props.currentMetricId];

    const activities = this.props.activitiesById[this.props.currentMetricId];

    let formattedActivities = activities;

    if (
      metricsById[currentMetricId] &&
      formattedActivities &&
      metricsById[currentMetricId].symbol === '$' &&
      activities
    ) {
      formattedActivities = activities.map(activity => ({
        ...activity,
        quantity: currency.toCurrencyFormat(activity.quantity),
      }));
    }
    const rankings = this.props.rankingsById[this.props.currentMetricId];

    return (
      <MetricCardDetails
        id={currentMetricId}
        onClose={this.handleClose}
        onClickNav={this.handleClickNav}
        metricId={currentMetricId}
        currentMetricId={currentMetricId}
        currentMetricDetailsType={currentMetricDetailsType}
        activities={formattedActivities}
        rankings={rankings}
        apiMetricsActivitiesCreateRequest={apiMetricsActivitiesCreateRequest}
        onConfirmationClose={routeMetrics}
        {...currentMetric}
      />
    );
  }
}

export const getState = (state, props) => ({
  metricsById: getMetricsById(state),
  currentMetricId: getCurrentMetricId(state),
  currentMetricDetailsType: getCurrentMetricDetailsType(state),

  activitiesById: getActivitiesById(state),

  rankingsByMetricId: state.metrics.rankingsByMetricId,
  rankingsById: state.metrics.rankingsById,
  ...props,
});

export const getActions = dispatch =>
  bindActionCreators(
    {
      apiMetricsActivitiesIndexRequest:
        actions.apiMetricsActivitiesIndexRequest,
      apiMetricsActivitiesCreateRequest:
        actions.apiMetricsActivitiesCreateRequest,
      apiMetricsRankingsIndexRequest: actions.apiMetricsRankingsIndexRequest,
      routeMetricsShow: routes.routeMetricsShow,
      routeMetrics: routes.routeMetrics,
    },
    dispatch,
  );

export default connect(getState, getActions)(MetricDetailsContainer);
