import React from 'react';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';

import * as ESCROWABLE_FUNDS_TYPES from 'arcade-frontend-core/src/types/escrowable-funds';
import * as REWARD_TYPES from 'arcade-frontend-core/src/types/rewards';
import ArcView from 'arcade-frontend-ui/src/primitives/ArcView';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcRewardButtonGroup from 'arcade-frontend-ui/src/components/ArcRewardButtonGroup';

import ArcAwardChestForm from '../ArcAwardChestForm';
import ArcAwardTokenForm from '../ArcAwardTokenForm';

const REWARD_MESSAGE_BY_ESCROWABLE_FUNDS_TYPE = {
  [ESCROWABLE_FUNDS_TYPES.COMPANY_BUDGET]:
    'Token rewards will come from the company balance and may incur additional charges.',
  [ESCROWABLE_FUNDS_TYPES.USER_BUDGET]:
    'Token rewards will come from your balance.',
};

const REWARD_MESSAGE_BY_TYPE = {
  [REWARD_TYPES.CHESTS]:
    'Chest rewards may award tokens which will be deducted from the company rewards balance.',
  [REWARD_TYPES.TOKENS]:
    'Token rewards will come from the company balance and may incur additional charges.',
  [REWARD_TYPES.CUSTOM]:
    'Custom rewards are rewards you will distribute yourself.',
};

const FORM_BY_TYPE = {
  [REWARD_TYPES.CHESTS]: ArcAwardChestForm,
  [REWARD_TYPES.TOKENS]: ArcAwardTokenForm,
};

function getRewardValueByType(type, values) {
  switch (type) {
    case REWARD_TYPES.TOKENS:
      return values.tokens;
    case REWARD_TYPES.CHESTS:
      return values.chests;
    default:
      return null;
  }
}

function ArcAwardForm({
  escrowableFundsType,
  escrowableFundsBalance,
  initialValues,
  onCancel,
  onSubmit,
  tokensMax,
  isIdle,
  isPending,
  hasError,
  hasFailed,
}) {
  const initialRewardType =
    initialValues.type ||
    (tokensMax ? REWARD_TYPES.TOKENS : REWARD_TYPES.CHESTS);

  const [rewardType, setRewardType] = React.useState(initialRewardType);

  function handleSubmit(values) {
    const value = getRewardValueByType(rewardType, values);
    onSubmit({
      type: rewardType,
      value,
    });
  }

  return (
    <ArcView fullWidth padding="16">
      <ArcRewardButtonGroup value={rewardType} onChange={setRewardType} />

      <ArcView position="relative" overflow="hidden">
        {ArcRewardButtonGroup.REWARDS.map(type => {
          const Form = FORM_BY_TYPE[type];
          const isSelected = type === rewardType;
          const max = type === REWARD_TYPES.TOKENS ? tokensMax : undefined;

          return (
            <Fade key={type} in={isSelected}>
              <ArcView
                data-testid={`ArcAwardForm-${type}`}
                position={isSelected ? 'relative' : 'absolute'}
                fillToParent={!isSelected}
              >
                <ArcView marginBottom="16">
                  <ArcText>
                    {rewardType === REWARD_TYPES.TOKENS
                      ? REWARD_MESSAGE_BY_ESCROWABLE_FUNDS_TYPE[
                          escrowableFundsType
                        ]
                      : REWARD_MESSAGE_BY_TYPE[rewardType]}
                  </ArcText>
                </ArcView>
                <Form
                  initialValues={initialValues}
                  max={max}
                  onCancel={onCancel}
                  onSubmit={handleSubmit}
                  escrowableFundsBalance={escrowableFundsBalance}
                  submitLabel="Ok"
                  isIdle={isIdle}
                  isPending={isPending}
                  hasError={hasError}
                  hasFailed={hasFailed}
                />
              </ArcView>
            </Fade>
          );
        })}
      </ArcView>
    </ArcView>
  );
}

ArcAwardForm.displayName = 'ArcAwardForm';

ArcAwardForm.propTypes = {
  escrowableFundsType: PropTypes.oneOf(Object.values(ESCROWABLE_FUNDS_TYPES)),
  escrowableFundsBalance: PropTypes.number,
  initialValues: PropTypes.shape({
    type: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  tokensMax: PropTypes.number,
  isIdle: PropTypes.bool,
  isPending: PropTypes.bool,
  hasError: PropTypes.bool,
  hasFailed: PropTypes.bool,
};

ArcAwardForm.defaultProps = {
  escrowableFundsType: null,
  escrowableFundsBalance: 0,
  initialValues: {},
  onCancel: global.noop,
  onSubmit: global.noop,
  tokensMax: 0,

  isIdle: true,
  isPending: false,
  hasError: false,
  hasFailed: false,
};

export default ArcAwardForm;
