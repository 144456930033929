import create from './create';

const metrics = {
  create,
  dateRange: {
    type: 'today',
    fromDate: undefined,
    toDate: undefined,
  },
};

export default metrics;

export const getMetricsDateRange = state => state.newForms.metrics.dateRange;
