const fromUserIndex = user => ({
  id: user.id.toString(),
  badge: user.badge,
  email: user.email,
  name: user.name || `${user.firstname} ${user.lastname}`,
  image: user.profile_image,
  team: user.team || user.team_name,
  tokens: user.tokens,
  phone: user.contact_number,
  level: user.level,
  locked: user.locked,
  primaryTeamId: user.team_id ? user.team_id.toString() : null,
  secondaryTeams: user.additional_teams
    ? user.additional_teams.map(teamId => teamId.toString())
    : [],
});

const normalizeTeams = teams =>
  teams.map(team => ({
    ...team,
    id: team.id ? team.id.toString() : '',
  }));

const normalizers = {
  fromUserIndex,
  normalizeTeams,
};

export default normalizers;
