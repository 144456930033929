import * as types from 'arcade-frontend-core/src/types/routes';

const titles = {
  [types.NOT_FOUND]: '404',

  [types.ROUTE_BASE]: 'Arcade',

  [types.ROUTE_ACADEMY]: 'Academy',
  [types.ROUTE_ACADEMY_NEW]: 'Academy',
  [types.ROUTE_ACADEMY_SHOW]: 'Academy',
  [types.ROUTE_ACADEMY_EDIT]: 'Academy',
  [types.ROUTE_ACADEMY_START]: 'Academy',

  [types.ROUTE_CHAT]: 'Chat',
  [types.ROUTE_CHAT_SHOW]: 'Chat',
  [types.ROUTE_CHAT_CREATE]: 'Chat',
  [types.ROUTE_CHAT_JOIN]: 'Chat',

  [types.ROUTE_GAMES]: 'Games',
  [types.ROUTE_GAMES_CREATE]: 'Games',
  [types.ROUTE_GAMES_EDIT]: 'Games',
  [types.ROUTE_GAMES_SHOW]: 'Games',

  [types.ROUTE_HUB]: 'Hub',

  [types.ROUTE_HUDDLE]: 'Huddle',

  [types.ROUTE_HOME]: 'Home',

  [types.ROUTE_INSIGHTS]: 'Insights',

  [types.ROUTE_LEAGUE]: 'League',

  [types.ROUTE_MANAGE]: 'Manage',

  [types.ROUTE_MANAGE_ACCOUNT]: 'Manage',

  [types.ROUTE_MANAGE_ACTIVITIES]: 'Manage',

  [types.ROUTE_MANAGE_BILLING]: 'Manage',
  [types.ROUTE_MANAGE_BILLING2]: 'Manage',

  [types.ROUTE_MANAGE_CHECKLISTS]: 'Manage',
  [types.ROUTE_MANAGE_CHECKLISTS_CREATE]: 'Manage',
  [types.ROUTE_MANAGE_CHECKLISTS_EDIT]: 'Manage',
  [types.ROUTE_MANAGE_CHECKLISTS_SHOW]: 'Manage',

  [types.ROUTE_MANAGE_ENGAGEMENT]: 'Manage',

  [types.ROUTE_MANAGE_INTEGRATIONS]: 'Manage',

  [types.ROUTE_MANAGE_GAMES]: 'Manage',
  [types.ROUTE_MANAGE_GAMES_SHOW]: 'Manage',
  [types.ROUTE_MANAGE_GAMES_CREATE]: 'Manage',
  [types.ROUTE_MANAGE_GAMES_EDIT]: 'Manage',
  [types.ROUTE_MANAGE_GAMES_CLONE]: 'Manage',

  [types.ROUTE_MANAGE_INTEGRATION_HISTORY]: 'Manage',

  [types.ROUTE_MANAGE_METRICS]: 'Manage',
  [types.ROUTE_MANAGE_METRICS_CREATE]: 'Manage',
  [types.ROUTE_MANAGE_METRICS_EDIT]: 'Manage',
  [types.ROUTE_MANAGE_METRICS_SHOW]: 'Manage',

  [types.ROUTE_MANAGE_PEOPLE]: 'Manage',

  [types.ROUTE_MANAGE_POSTS]: 'Manage',

  [types.ROUTE_MANAGE_REWARDS]: 'Manage',
  [types.ROUTE_MANAGE_REWARDS_CREATE]: 'Manage',
  [types.ROUTE_MANAGE_REWARDS_EDIT]: 'Manage',
  [types.ROUTE_MANAGE_REWARDS_SHOW]: 'Manage',

  [types.ROUTE_MANAGE_TEAMS]: 'Manage',

  [types.ROUTE_MANAGE_TOKENS]: 'Manage',

  [types.ROUTE_MANAGE_TOKEN_BUDGETS]: 'Manage',

  [types.ROUTE_MANAGE_TRANSACTIONS]: 'Manage',

  [types.ROUTE_METRICS]: 'Metrics',
  [types.ROUTE_METRICS_SHOW]: 'Metrics',

  [types.ROUTE_NEWSFEED]: 'Newsfeed',
  [types.ROUTE_NEWSFEED_CATEGORY]: 'Newsfeed',
  [types.ROUTE_NEWSFEED_SHOW]: 'Newsfeed',

  [types.ROUTE_PEOPLE]: 'People',

  [types.ROUTE_PROFILE]: 'Profile',
  [types.ROUTE_PROFILE_EDIT]: 'Profile',

  [types.ROUTE_REWARDS]: 'Rewards',
  [types.ROUTE_REWARDS_AWARD]: 'Rewards',
  [types.ROUTE_REWARDS_CHESTS]: 'Rewards',
  [types.ROUTE_REWARDS_HISTORY]: 'Rewards',
  [types.ROUTE_REWARDS_SHOP]: 'Rewards',
  [types.ROUTE_REWARDS_TRANSACTIONS]: 'Rewards',
};

export default titles;
