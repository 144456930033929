import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const LeftArrow = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M.505 8.667h14.99M8.495 1.333L.733 8.166a.667.667 0 000 1L8.495 16"
    />
  </Icon>
);

export default LeftArrow;
