import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const handleTeamPeopleLoadSuccess = (state, action) => {
  const { data } = action.payload;
  const newState = {};

  data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });

  data.forEach(user => {
    const team = user.team || 'no team';

    if (newState[team]) {
      newState[team] = [...newState[team], user.id];
    } else {
      newState[team] = [user.id];
    }
  });

  const sortedState = {};

  Object.keys(newState)
    .sort()
    .forEach(key => {
      sortedState[key] = newState[key];
    });

  return sortedState;
};

const initialState = {};

const handlers = {
  [types.PEOPLE_INDEX.SUCCESS]: handleTeamPeopleLoadSuccess,
};

const eligibleTeamPeople = createReducer(initialState, handlers);

export default eligibleTeamPeople;

export const getTeamPeople = state => state.people.teamPeople;
