import React from 'react';
import { connect } from 'react-redux';
import { actions, Fieldset } from 'react-redux-form';

import ArcPropTypes from '../helpers/arc/propTypes';

import {
  formatDate,
  addOneDay,
  removeOneDay,
  startOfDay,
  endOfDay,
  beginningOfWeek,
  beginningOfMonth,
  oneWeekAgo,
} from '../helpers/utils/date';

import { createWithStyles } from '../styles';
import ArcView from '../primitives/ArcView';
import ArcFormDatePicker from './ArcFormDatePicker';
import ArcFormSelect from './ArcFormSelect';

const dateSelector = {
  model: '.type',
  label: 'Date Type',
  defaultSelected: 0,
  floatingLabelFixed: false,
  items: [
    {
      value: 'today',
      label: 'Today',
    },
    {
      value: 'yesterday',
      label: 'Yesterday',
    },
    {
      value: 'selectedDay',
      label: 'Selected Day',
    },
    {
      value: 'pastWeek',
      label: 'Past 7 Days',
    },
    {
      value: 'currentWeek',
      label: 'Current Week',
    },
    {
      value: 'currentMonth',
      label: 'Current Month',
    },
    {
      value: 'dateRange',
      label: 'Date Range',
    },
  ],
  style: {
    width: 160,
    flexShrink: 0,
    flexGrow: 0,
  },
};

const trimStringByDelimiter = (string, delimiter = '.') => {
  const arr = string.split(delimiter);
  if (arr.length && arr.length > 1) arr.pop();

  return arr.join('.');
};

const styleDatePicker = {
  width: 100,
};

const styleText = {
  textTransform: 'uppercase',
  color: '#e1e0e1',
  margin: 16,
  fontSize: 12,
};


const styles = {
  DateRangeView: theme => ({
    root: {
      flexDirection: 'row',

      [theme.breakpoints.up('sm')]: {
        marginRight: 8,
      },
    },
  }),

  DateRangePickerView: () => ({
    root: {
      width: 100,
    },
  }),

  DateSelectedView: theme => ({
    root: {
      width: 100,
      marginRight: 0,

      [theme.breakpoints.up('sm')]: {
        marginRight: 8,
      },
    },
  }),

  FieldsetContainer: theme => ({
    root: {
      minWidth: 200,

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
  }),
};

const DateRangeView = createWithStyles(styles.DateRangeView)(ArcView);
const DateRangePickerView = createWithStyles(styles.DateRangePickerView)(ArcView);
const DateSelectedView = createWithStyles(styles.DateSelectedView)(ArcView);
const FieldsetContainer = createWithStyles(styles.FieldsetContainer)(Fieldset);

class ArcFormDateSelector extends React.Component {
  static propTypes = {
    dispatch: ArcPropTypes.func.isRequired,
    model: ArcPropTypes.string.isRequired,

    onChange: ArcPropTypes.func,
    type: ArcPropTypes.string,
  };

  static defaultProps = {
    onChange: ArcPropTypes.noop,
    type: 'today',
  };

  constructor(props) {
    super(props);

    this.todayDate = new Date();
    this.yesterdayDate = removeOneDay(this.todayDate);
    this.today = formatDate(this.todayDate);
    this.yesterday = formatDate(this.yesterdayDate);

    this.dateRange = {
      currentMonth: beginningOfMonth,
      currentWeek: beginningOfWeek,
      pastWeek: oneWeekAgo,
      yesterday: removeOneDay,
      today: startOfDay,
      selectedDay: startOfDay,
    };

    this.getDateRange = (range, fromDate) => this.dateRange[range](fromDate);

    this.state = {
      type: props.type,
      minDate: addOneDay(this.todayDate),
    };
  }

  componentDidMount() {
    this.actionChange(this.props.model, this.state.type);
  }

  actionChange = (model, value) => {
    this.fromDate = startOfDay(this.todayDate);
    this.toDate = endOfDay(this.todayDate);

    switch (value) {
      case 'dateRange':
        this.fromDate = startOfDay(this.yesterdayDate);
        this.toDate = endOfDay(this.todayDate);
        break;
      case 'yesterday':
        this.fromDate = startOfDay(this.yesterdayDate);
        this.toDate = endOfDay(this.yesterdayDate);
        break;
      case 'selectedDay':
        this.fromDate = this.getDateRange(value, this.todayDate);
        this.toDate = endOfDay(this.fromDate);
        break;
      default:
        this.fromDate = this.getDateRange(value, this.todayDate);
        this.fromDate = startOfDay(this.fromDate);
        break;
    }

    const actionChange = actions.change(model, {
      type: value,
      fromDate: this.fromDate,
      toDate: this.toDate,
    });

    this.props.dispatch(actionChange);

    this.props.onChange(value, this.fromDate, this.toDate);
  };

  handleChangeSelect = (event, value, model) => {
    const parentModel = trimStringByDelimiter(model);

    this.actionChange(parentModel, value);

    this.setState({
      type: value,
    });
  };

  handleChangeDatePicker = ({ modelValue }) => {
    this.fromDate = modelValue;
    this.setState({
      minDate: addOneDay(modelValue),
    });
    this.props.onChange('dateRange', this.fromDate, this.toDate);
  };

  handleChangeDatePickerEnd = ({ model, modelValue }) => {
    this.toDate = endOfDay(modelValue);
    this.props.dispatch(actions.change(model, this.toDate));
    this.props.onChange('dateRange', this.fromDate, this.toDate);
  };

  handleChangeDatePickerSelect = ({ model, modelValue }) => {
    const parentModel = trimStringByDelimiter(model);

    this.fromDate = startOfDay(modelValue);
    this.toDate = endOfDay(modelValue);
    this.props.dispatch(actions.change(parentModel, {
      type: 'selectedDay',
      fromDate: this.fromDate,
      toDate: this.toDate,
      selectedDay: modelValue,
    }));

    this.props.onChange('selectedDay', this.fromDate, this.toDate);
  };

  renderDateSelector = (type) => {
    switch (type) {
      case 'dateRange':
        return (
          <DateRangeView align="center">
            <DateRangePickerView>
              <ArcFormDatePicker
                model=".fromDate"
                label="Start date"
                defaultDate={removeOneDay(this.todayDate)}
                maxDate={removeOneDay(this.todayDate)}
                onChange={this.handleChangeDatePicker}
                hideCalendarDate
                style={styleDatePicker}
                isHintText
              />
            </DateRangePickerView>
            <ArcView style={styleText}>To</ArcView>
            <DateRangePickerView>
              <ArcFormDatePicker
                model=".toDate"
                label="End date"
                defaultDate={this.todayDate}
                minDate={this.state.minDate}
                maxDate={this.todayDate}
                onChange={this.handleChangeDatePickerEnd}
                hideCalendarDate
                style={styleDatePicker}
                isHintText
              />
            </DateRangePickerView>
          </DateRangeView>
        );
      case 'today':
        return (
          <ArcView style={styleText}>{this.today}</ArcView>
        );
      case 'yesterday':
        return (
          <ArcView style={styleText}>{this.yesterday}</ArcView>
        );
      case 'selectedDay':
        return (
          <DateSelectedView>
            <ArcFormDatePicker
              model=".selectedDay"
              label="Start Date"
              defaultDate={this.todayDate}
              maxDate={this.todayDate}
              onChange={this.handleChangeDatePickerSelect}
              hideCalendarDate
              isHintText
            />
          </DateSelectedView>
        );
      case 'currentMonth':
        return (
          <ArcView style={styleText}>
            {`${formatDate(beginningOfMonth(this.todayDate))} to ${this.today}`}
          </ArcView>
        );
      case 'pastWeek':
        return (
          <ArcView style={styleText}>
            {`${formatDate(oneWeekAgo(this.todayDate))} to ${this.today}`}
          </ArcView>
        );
      case 'currentWeek':
        return (
          <ArcView style={styleText}>
            {`${formatDate(beginningOfWeek(this.todayDate))} to ${this.today}`}
          </ArcView>
        );
      default:
        return 'Please select a date type';
    }
  };

  render() {
    return (
      <FieldsetContainer
        model={this.props.model}
        component={ArcView}
        align="center"
        row
      >
        {this.renderDateSelector(this.state.type)}
        <ArcFormSelect
          onChange={this.handleChangeSelect}
          hideLabel
          fullWidth={false}
          {...dateSelector}
        />
      </FieldsetContainer>
    );
  }
}

export default connect()(ArcFormDateSelector);
