import React from 'react';
import PropTypes from 'prop-types';
import { Platform } from 'react-primitives';
import { Field } from 'formik';
import Typography from '@material-ui/core/Typography';

import {
  createWithStyles,
  ArcFormField,
  ArcGrid,
  ArcImage,
  ArcView,
  ArcText,
} from 'arcade-frontend-ui';

const PHOTO_BUTTON_SIZE = 192;

const subtitleStyle = {
  fontSize: '1rem',
  fontWeight: '400',
};

const styles = {
  AddPhotoButton: theme => ({
    root: {
      position: 'relative',
      backgroundColor: theme.palette.grey.tint,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      border: `2px dashed ${theme.palette.grey[500]}`,
      width: PHOTO_BUTTON_SIZE,
      height: PHOTO_BUTTON_SIZE,
      borderRadius: '50%',
      margin: '32px auto',
      color: theme.palette.grey[500],

      cursor: Platform.OS === 'web' ? 'pointer' : undefined,
    },

    hasPicture: {
      border: '2px solid transparent',
    },

    hasError: {
      borderColor: theme.palette.danger.main,
    },
  }),
  PhotoTooltip: theme => ({
    root: {
      position: 'absolute',
      top: '100%',
      width: '100%',
      textAlign: 'center',
      marginTop: 16,
    },

    colorDanger: {
      color: theme.palette.danger.main,
    },
  }),
  PredefinedAvatar: theme => ({
    root: {
      padding: theme.spacing(1),
      border: `2px solid ${theme.palette.grey[300]}`,
      cursor: Platform.OS === 'web' ? 'pointer' : undefined,
    },

    isSelected: {
      borderColor: theme.palette.secondary.main,
    },
  }),
};

const AddPhotoButton = createWithStyles(styles.AddPhotoButton)(ArcView);
const PhotoTooltip = createWithStyles(styles.PhotoTooltip)(ArcView);
const PredefinedAvatar = createWithStyles(styles.PredefinedAvatar)(ArcView);

const SRC_BY_AVATARS = {
  angel: 'https://arcade-assets-public.s3.amazonaws.com/avatars/angel.svg',
  crown: 'https://arcade-assets-public.s3.amazonaws.com/avatars/crown.svg',
  fairy: 'https://arcade-assets-public.s3.amazonaws.com/avatars/fairy.svg',
  joker: 'https://arcade-assets-public.s3.amazonaws.com/avatars/joker.svg',
  knight: 'https://arcade-assets-public.s3.amazonaws.com/avatars/knight.svg',
  pharaoh: 'https://arcade-assets-public.s3.amazonaws.com/avatars/pharaoh.svg',
  pirate: 'https://arcade-assets-public.s3.amazonaws.com/avatars/pirate.svg',
  viking: 'https://arcade-assets-public.s3.amazonaws.com/avatars/viking.svg',
};

const AVATARS = Object.entries(SRC_BY_AVATARS);

class ArcProfileFormStepPicture extends React.PureComponent {
  static propTypes = {
    setFieldValue: PropTypes.func.isRequired,
  };

  state = {
    selectedPredefinedAvatar: null,
  };

  setSelectedPredefinedAvatar = selectedPredefinedAvatar =>
    this.setState({ selectedPredefinedAvatar });

  base64Urls = {};

  imageRefs = {};

  predefinedAvatarHandlers = {};

  renderPredefinedAvatar = ([title, avatarSrc]) => {
    if (!this.imageRefs[avatarSrc]) {
      this.imageRefs[avatarSrc] = React.createRef();
    }

    if (!this.predefinedAvatarHandlers[avatarSrc]) {
      this.predefinedAvatarHandlers[avatarSrc] = () => {
        this.props.setFieldValue('avatar', avatarSrc);
        this.props.setFieldValue('remote_image_url', avatarSrc);
        this.setSelectedPredefinedAvatar(avatarSrc);
      };
    }

    const isSelected = this.state.selectedPredefinedAvatar === avatarSrc;

    return (
      <ArcGrid item xs={3} key={avatarSrc}>
        <Field name="remote_image_url">
          {() => (
            <PredefinedAvatar
              role="radio"
              aria-label={title}
              aria-checked={isSelected ? 'true' : 'false'}
              onClick={this.predefinedAvatarHandlers[avatarSrc]}
              isSelected={isSelected}
            >
              <ArcImage
                alt={title}
                src={avatarSrc}
                width="100%"
                height="100%"
                ref={this.imageRefs[avatarSrc]}
              />
            </PredefinedAvatar>
          )}
        </Field>
      </ArcGrid>
    );
  };

  renderPredefinedAvatars() {
    return (
      <ArcGrid role="radiogroup" container spacing={2}>
        {AVATARS.map(this.renderPredefinedAvatar)}
      </ArcGrid>
    );
  }

  renderProfileImageButton = (props, state) => {
    const { src } = state;

    const style = src
      ? {
          backgroundImage: `url(${src})`,
        }
      : undefined;

    return (
      <AddPhotoButton style={style} hasError={props.error}>
        <ArcView
          align="center"
          justify="center"
          display={src ? 'none' : undefined}
          fillToParent
        >
          <ArcView>
            <ArcText isLarge>{'Add Photo'}</ArcText>
          </ArcView>
          <ArcView display="none" hidden="only-touch">
            <ArcText isSmall>{'Drag & Drop'}</ArcText>
          </ArcView>
        </ArcView>
        {!!src && (
          <PhotoTooltip>
            <ArcText isSmall hidden="only-mouse">
              {'Tap to change'}
            </ArcText>
            <ArcText isSmall hidden="only-touch">
              {'Click to change'}
            </ArcText>
          </PhotoTooltip>
        )}
        {props.error && (
          <PhotoTooltip color="danger">{props.label}</PhotoTooltip>
        )}
      </AddPhotoButton>
    );
  };

  render() {
    return (
      <ArcView>
        <ArcView color="blue" padding="24">
          <ArcView marginTop="16">
            <Typography variant="h4">
              <ArcText color="white">{'Almost Done!'}</ArcText>
            </Typography>
          </ArcView>
        </ArcView>
        <ArcView padding="24">
          <ArcView>
            <ArcView marginBottom="16">
              <Typography variant="h5">
                {'Make a great first impression.'}
              </Typography>
              <Typography variant="caption" style={subtitleStyle}>
                {'Upload a own photo or select a character below.'}
              </Typography>
            </ArcView>

            <ArcFormField
              name="avatar"
              id="profileImage"
              type="image"
              label="Profile Image"
              render={this.renderProfileImageButton}
              validations={{
                isRequired: true,
              }}
              hasMarginBottom={false}
            />
          </ArcView>

          {this.renderPredefinedAvatars()}
        </ArcView>
      </ArcView>
    );
  }
}

export default ArcProfileFormStepPicture;
