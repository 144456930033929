import { combineReducers } from 'redux';

import rewards from 'arcade-frontend-rewards/src/forms/';

import activities from './activities';
import checklists from './checklists';
import metrics from './metrics';
import { reducer as peopleSelector } from './peopleSelector';
import { test } from './sample';

const forms = combineReducers({
  activities,
  checklists,
  metrics,
  peopleSelector,
  rewards,
  test,
});

export default forms;
