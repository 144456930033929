import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import * as GAME_STATUSES from 'arcade-frontend-core/src/types/game-statuses';
import * as GAME_PLAYER_STATUSES from 'arcade-frontend-core/src/types/game-player-statuses';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';

import GamesCountdownBadge from '../GamesCountdownBadge';
import GamesSpectatorViewBadge from '../GamesSpectatorViewBadge';

const styles = makeStyles(theme => ({
  commentButton: {
    color: '#616264',
    borderColor: theme.palette.grey[300],
    borderWidth: 1,
    borderStyle: 'solid',
    marginBottom: 1,
  },
}));

function GamesGameCardFooter({
  expiresAt,
  onVerify,
  showLeaderboard,
  status,
  playerStatus,
  onNavigateToActivity,
  hideComments,
  ...props
}) {
  const classes = styles();

  return (
    <ArcBox
      display="flex"
      flexDirection="row"
      alignItems="center"
      pt={1}
      pb={2}
      px={3}
      {...props}
    >
      <ArcBox display="flex" flexDirection="column" width="100%">
        <ArcBox flex={100} />

        <ArcBox
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          {GAME_PLAYER_STATUSES.NON_PARTICIPANT_TYPES.indexOf(playerStatus) >
            -1 && (
            <GamesSpectatorViewBadge
              label={GAME_PLAYER_STATUSES.LABEL_BY_STATUS[playerStatus]}
              tooltipContent={
                GAME_PLAYER_STATUSES.TOOLTIP_BY_STATUS[playerStatus]
              }
            />
          )}
          {status === GAME_STATUSES.ACTIVE && (
            <GamesCountdownBadge marginLeft="auto" endDateTime={expiresAt} />
          )}
        </ArcBox>

        {!hideComments && (
          <ArcBox display="flex" flexDirection="row" mt={1}>
            <ArcButton
              color="light"
              variant="outlined"
              fullWidth
              textTransform="initial"
              className={classes.commentButton}
              onClick={onNavigateToActivity}
            >
              View Comments on Post
            </ArcButton>
          </ArcBox>
        )}
      </ArcBox>
    </ArcBox>
  );
}

GamesGameCardFooter.displayName = 'GamesGameCardFooter';

GamesGameCardFooter.propTypes = {
  expiresAt: PropTypes.string,
  onVerify: PropTypes.func,
  onNavigateToActivity: PropTypes.func,
  showLeaderboard: PropTypes.bool,
  status: PropTypes.string,
  playerStatus: PropTypes.oneOf(Object.values(GAME_PLAYER_STATUSES)),
  hideComments: PropTypes.bool,
};

GamesGameCardFooter.defaultProps = {
  expiresAt: '',
  onVerify: global.noop,
  onNavigateToActivity: global.noop,
  showLeaderboard: true,
  status: '',
  playerStatus: GAME_PLAYER_STATUSES.PLAYER,
  hideComments: false,
};

export default GamesGameCardFooter;
