import { ArcPropTypes } from 'arcade-frontend-ui';
import { NOT_FOUND } from 'redux-first-router';

import * as types from 'arcade-frontend-core/src/types/routes';
import { createRoutes } from './helpers';
import CbLoginContainer from '../containers/app/LoginContainer/CbLogin/CbLoginContainer';

const { PLATFORMS } = ArcPropTypes;

const base = {
  [NOT_FOUND]: CbLoginContainer,
};

Object.values(types).forEach(routeType => {
  base[routeType] = CbLoginContainer;
});

export const routes = {
  [PLATFORMS.BENJO]: base,
  [PLATFORMS.DEVELOPMENT]: base,
  [PLATFORMS.LAUNCH]: base,
};

const RoutesOpen = createRoutes(routes);

export default RoutesOpen;
