import sagas from 'arcade-frontend-core/src/helpers/sagas';

import { types } from '../actions';
import { api } from '../resources';

const appSetAlert = payload => ({
  type: 'SET_ALERT',
  payload,
});

const onSuccessPurchaseRewards = () =>
  appSetAlert({
    type: 'success',
    title: 'Reward Purchased',
    content: 'Your purchase was successful!',
  });

const onFailurePurchaseRewards = payload =>
  appSetAlert({
    type: 'error',
    title: 'Oops',
    content: payload,
  });

const takeLatestSaga = [
  {
    type: types.REWARDS_SHOP_INDEX,
    call: api.shopIndex,
  },
  {
    type: types.REWARDS_ACCOUNTS_INDEX,
    call: api.accountsIndex,
  },
  {
    type: types.REWARDS_SHOP_PURCHASE,
    call: api.shopPurchase,
    onSuccess: [onSuccessPurchaseRewards],
    onFailure: [onFailurePurchaseRewards],
  },
  {
    type: types.REWARDS_TRANSACTIONS_INDEX,
    call: api.transactionsIndex,
  },
  {
    type: types.REWARDS_TRANSACTIONS_INDEX_BY_USER_ID,
    call: api.transactionsIndexByUserId,
  },
];

const rewards = takeLatestSaga.map(sagas.makeTakeLatest);

export default rewards;
