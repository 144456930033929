import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  ArcView,
  ArcLoader,
  ArcMainView,
  ArcScroll,
  ArcViewTitleBar,

  components,
  forms,
} from 'arcade-frontend-ui';

import FormRewardCreate from 'arcade-frontend-rewards/src/forms/create';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import * as routeTypes from 'arcade-frontend-core/src/types/routes';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import { routes } from '../../../actions/manage';
import { actions, routes as routesRewards } from '../../../actions/manage/rewards';
import { getLocationType, getLocationPayload } from '../../../reducers/location';
import { getManageRewardsArcadeActive, getManageRewardsArcadeArchived } from '../../../reducers/manage/rewards/arcade';
import { getManageRewardsCustomActive, getManageRewardsCustomArchived } from '../../../reducers/manage/rewards/custom';
import * as fromById from '../../../reducers/manage/rewards/byId';
import { getIsFetching } from '../../../reducers/manage/rewards/isFetching';
import { getIsRequesting } from '../../../reducers/manage/rewards/isRequesting';
import ManageRewardsList from '../../../components/manage/rewards/ManageRewardsList';


const HiddenManageRewardsList = components.withHiddenUnmount(ManageRewardsList);

const stylePositionRel = {
  position: 'relative',
  backgroundColor: '#fff',
};

const REWARD_PROPS = {
  id: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  variants: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    value: PropTypes.number,
  })),
  tokens: PropTypes.number,
  description: PropTypes.string,
};

const EMPTY_REWARD = {};

class ManageRewardsContainer extends React.Component {
  static propTypes = {
    arcadeActive: PropTypes.arrayOf(PropTypes.shape(REWARD_PROPS)),
    arcadeArchived: PropTypes.arrayOf(PropTypes.shape(REWARD_PROPS)),
    customActive: PropTypes.arrayOf(PropTypes.shape(REWARD_PROPS)),
    customArchived: PropTypes.arrayOf(PropTypes.shape(REWARD_PROPS)),
    apiManageRewardsArchiveRequest: PropTypes.func.isRequired,
    apiManageRewardsRestoreRequest: PropTypes.func.isRequired,
    apiManageRewardsCreateRequest: PropTypes.func.isRequired,
    apiManageRewardsEditRequest: PropTypes.func.isRequired,
    apiManageRewardsCustomArchiveRequest: PropTypes.func.isRequired,
    apiManageRewardsCustomRestoreRequest: PropTypes.func.isRequired,
    apiManageRewardsIndexRequest: PropTypes.func.isRequired,
    apiManageRewardsCustomIndexRequest: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      id: PropTypes.string,
    }),
    dispatch: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isRequesting: PropTypes.shape({ id: PropTypes.string }).isRequired,
    rewardsById: PropTypes.objectOf(PropTypes.shape(REWARD_PROPS)).isRequired,
    routeManage: PropTypes.func.isRequired,
    routeManageRewards: PropTypes.func.isRequired,
    routeManageRewardsCreate: PropTypes.func.isRequired,
    routeManageRewardsEdit: PropTypes.func.isRequired,
    routeManageRewardsShow: PropTypes.func.isRequired,
    locationPayload: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    locationType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    arcadeActive: [],
    arcadeArchived: [],
    currentUser: {},
    customActive: [],
    customArchived: [],
  };

  state = {
    value: 0,
  };

  componentDidMount() {
    this.handleApiRequests();

    this.currentReward = this.props.rewardsById[this.props.locationPayload.id];

    if (this.currentReward) {
      this.changeFormValues(this.currentReward);
    }
  }

  componentWillReceiveProps(nextProps) {
    const isChangingLocationPayload = this.props.locationPayload !== nextProps.locationPayload;
    const isChangingRewardsById = this.props.rewardsById !== nextProps.rewardsById;

    if (isChangingLocationPayload || isChangingRewardsById) {
      const nextReward = nextProps.rewardsById[nextProps.locationPayload.id] || EMPTY_REWARD;

      if (nextReward !== this.currentReward) {
        this.currentReward = nextReward;
        this.changeFormValues(this.currentReward);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.handleApiRequests();
    }
  }


  get isCreating() {
    return this.props.locationType === routeTypes.ROUTE_MANAGE_REWARDS_CREATE;
  }

  get isEditable() {
    const isEditable = this.currentReward ? !this.currentReward.isArcade : true;

    return this.isCreating || this.isEditing || isEditable;
  }

  get isEditing() {
    return this.props.locationType === routeTypes.ROUTE_MANAGE_REWARDS_EDIT;
  }

  get isShowing() {
    return this.props.locationType === routeTypes.ROUTE_MANAGE_REWARDS_SHOW;
  }

  currentReward = {};

  changeFormValues = (values) => {
    if (!this.isEditable) {
      return;
    }

    this.props.dispatch(forms.actions.change('rewards.create', {
      values: {
        ...values,
        hasVariant: !!values.variants && !!values.variants.length,
        variants: values.variants && values.hasVariant ? values.variants.map(variant => ({
          label: variant.label,
          value: variant.value,
        })) : [],
      },
    }));
  };

  handleApiRequests() {
    if (!this.props.currentUser.id) {
      return;
    }

    this.props.apiManageRewardsIndexRequest();
    this.props.apiManageRewardsCustomIndexRequest();
  }

  handleArchive = (e, reward) => {
    const currentReward = reward || this.currentReward;

    if (!currentReward.id) {
      return;
    }

    if (currentReward.isArcade) {
      this.props.apiManageRewardsArchiveRequest(currentReward.id);
    } else {
      this.props.apiManageRewardsCustomArchiveRequest(currentReward.id);
    }
  };

  handleChange = (e, value) => {
    this.setState({ value });
  };

  handleClickAdd = () => {
    this.currentReward = {
      name: '',
      description: '',
      image: '',
      cost: '',
      hasVariant: false,
    };

    this.props.routeManageRewardsCreate();
    this.changeFormValues(this.currentReward);
  };

  handleClickEdit = (e, reward) => {
    this.props.routeManageRewardsEdit(reward.id);
  };

  handleClickShow = (e, reward) => {
    this.props.routeManageRewardsShow(reward.id);
  };

  handleCancel = () => {
    this.props.routeManageRewards();
  };

  handleRestore = (e, reward) => {
    const currentReward = reward || this.currentReward;

    if (!currentReward.id) {
      return;
    }

    if (currentReward.isArcade) {
      this.props.apiManageRewardsRestoreRequest(currentReward.id);
    } else {
      this.props.apiManageRewardsCustomRestoreRequest(currentReward.id);
    }
  };

  handleRewardsCreate = (e, reward) => {
    this.props.apiManageRewardsCreateRequest(reward);
  };

  handleRewardsEdit = (e, reward) => {
    this.props.apiManageRewardsEditRequest({ ...reward, id: this.currentReward.id });
  };

  handleSubmitAndRestore = (e, reward) => {
    this.props.apiManageRewardsEditRequest({ ...reward, id: this.currentReward.id });

    if (this.currentReward.isArcade) {
      this.props.apiManageRewardsRestoreRequest(this.currentReward.id);
    } else {
      this.props.apiManageRewardsCustomRestoreRequest(this.currentReward.id);
    }
  };

  render() {
    const { value } = this.state;

    return (
      <ArcMainView
        isViewing
        hasScroll={false}
        hasNoPaddingLeftLaunch
        style={stylePositionRel}
      >
        <ArcViewTitleBar
          onClickBack={this.props.routeManage}
          title="Manage Rewards"
        />
        <ArcScroll>
          <ArcView>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Active" />
              <Tab label="Archived" />
            </Tabs>
            <HiddenManageRewardsList
              isMounted={value === 0}
              arcade={this.props.arcadeActive}
              custom={this.props.customActive}
              onArchive={this.handleArchive}
              onRestore={this.handleRestore}
              onClickAdd={this.handleClickAdd}
              onClickEdit={this.handleClickEdit}
              onClickShow={this.handleClickShow}
              isRequesting={this.props.isRequesting}
            />
            <HiddenManageRewardsList
              isMounted={value === 1}
              arcade={this.props.arcadeArchived}
              custom={this.props.customArchived}
              onArchive={this.handleArchive}
              onRestore={this.handleRestore}
              onClickAdd={this.handleClickAdd}
              onClickEdit={this.handleClickEdit}
              onClickShow={this.handleClickShow}
              isRequesting={this.props.isRequesting}
              placeholderText="No Archived Rewards"
            />
          </ArcView>
        </ArcScroll>
        <ArcLoader in={this.props.isFetching} />
        {(this.isCreating || this.isEditing || this.isShowing) && (
          <FormRewardCreate
            isCreating={this.isCreating}
            isEditable={this.isEditable}
            isEditing={this.isEditing}
            isFetching={this.props.isFetching}
            onArchive={this.handleArchive}
            onCancel={this.handleCancel}
            onRestore={this.handleRestore}
            currentReward={this.currentReward}
            onSubmit={this.isEditing ? this.handleRewardsEdit : this.handleRewardsCreate}
            onSubmitAndRestore={this.handleSubmitAndRestore}
          />
        )}
      </ArcMainView>

    );
  }
}

const getState = state => ({
  arcadeActive: getManageRewardsArcadeActive(state),
  arcadeArchived: getManageRewardsArcadeArchived(state),
  currentUser: getCurrentUser(state),
  customActive: getManageRewardsCustomActive(state),
  customArchived: getManageRewardsCustomArchived(state),
  isFetching: getIsFetching(state),
  isRequesting: getIsRequesting(state),
  locationType: getLocationType(state),
  locationPayload: getLocationPayload(state),
  rewardsById: fromById.getManageRewardsById(state),
});

const getActions = dispatch => bindActionCreators({
  routeManage: routes.routeManage,
  routeManageRewards: routesRewards.routeManageRewards,
  routeManageRewardsCreate: routesRewards.routeManageRewardsCreate,
  routeManageRewardsEdit: routesRewards.routeManageRewardsEdit,
  routeManageRewardsShow: routesRewards.routeManageRewardsShow,
  apiManageRewardsArchiveRequest: actions.apiManageRewardsArchiveRequest,
  apiManageRewardsRestoreRequest: actions.apiManageRewardsRestoreRequest,
  apiManageRewardsCreateRequest: actions.apiManageRewardsCreateRequest,
  apiManageRewardsEditRequest: actions.apiManageRewardsEditRequest,
  apiManageRewardsCustomArchiveRequest: actions.apiManageRewardsCustomArchiveRequest,
  apiManageRewardsCustomRestoreRequest: actions.apiManageRewardsCustomRestoreRequest,
  apiManageRewardsIndexRequest: actions.apiManageRewardsIndexRequest,
  apiManageRewardsCustomIndexRequest: actions.apiManageRewardsCustomIndexRequest,
  dispatch,
}, dispatch);

export default connect(getState, getActions)(ManageRewardsContainer);
