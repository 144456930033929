import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import SubdirectoryArrowLeft from '@material-ui/icons/SubdirectoryArrowLeft';

import { ArcButton, ArcView, ArcText } from 'arcade-frontend-ui';

const buttonStyle = {
  padding: 8,
  justifyContent: 'flex-start',
  textAlign: 'left',
  textTransform: 'initial',
};

const iconStyle = {
  transform: 'rotate(90deg)',
};

const textContainerStyle = {
  marginLeft: 18,
};

const textStyle = {
  fontSize: 9,
};

const ArcAudienceListUpOneLevel = ({ name, tooltipText, ...props }) => {
  const button = (
    <ArcButton fullWidth style={buttonStyle} {...props}>
      <SubdirectoryArrowLeft
        style={iconStyle}
        color="action"
        fontSize="small"
      />
      <ArcView style={textContainerStyle}>
        <ArcText>{'Up one level'}</ArcText>
        <ArcText style={textStyle} visibility={!name ? 'hidden' : undefined}>
          {name || 'N/A'}
        </ArcText>
      </ArcView>
    </ArcButton>
  );

  return (
    <ArcView row align="center" marginTop="8">
      {tooltipText ? (
        <Tooltip
          aria-label={tooltipText}
          title={tooltipText}
          placement="bottom"
        >
          <ArcView>{button}</ArcView>
        </Tooltip>
      ) : (
        button
      )}
    </ArcView>
  );
};

ArcAudienceListUpOneLevel.displayName = 'ArcAudienceListUpOneLevel';

ArcAudienceListUpOneLevel.propTypes = {
  name: PropTypes.string,
  tooltipText: PropTypes.string,
};

ArcAudienceListUpOneLevel.defaultProps = {
  name: '',
  tooltipText: '',
};

export default ArcAudienceListUpOneLevel;
