import * as components from './helpers/components';
import * as currency from './helpers/utils/currency';
import * as date from './helpers/utils/date';
import * as loaders from './helpers/utils/loaders';
import * as parsers from './helpers/utils/parsers';
import * as validators from './helpers/utils/validators';
import * as download from './helpers/utils/open_link';

import * as theme from './styles/variables';

import * as forms from './forms/createForm';

export { default as ArcStyles } from './styles';
export { default as ArcAppBar } from './helpers/arc/appBar';
export { default as ArcConsole } from './helpers/arc/console';
export { default as Resource } from './helpers/utils/resource';
export { default as ArcPropTypes } from './helpers/arc/propTypes';
export {
  createReducer,
  createSharedReducer,
  createIsFetchingReducer,
  createIsRequestingReducer,
  createRequestingStatusReducer,
} from './helpers/utils/reducer';

export { makeApiActions, makeApiActionTypes } from './helpers/utils/actions';

export {
  components,
  currency,
  download,
  date,
  forms,
  loaders,
  parsers,
  theme,
  validators,
};

export { default as percentToArcColor } from './styles/colors/percentToArcColor';

export { default as ArcToken } from './assets/ArcToken';
export { default as ArcTokenOutlined } from './assets/ArcTokenOutlined';
export { default as ArcCustomReward } from './assets/ArcCustomReward';

export { default as ArcBounty } from './assets/ArcBounty/ArcBounty';
export { default as ArcChallenge } from './assets/ArcChallenge';
export { default as ArcRace } from './assets/ArcRace';
export { default as ArcTournament } from './assets/ArcTournament';

export { default as ArcAddEmojiButton } from './components/ArcAddEmojiButton';
export { default as ArcBreadcrumbs } from './components/ArcBreadcrumbs';
export { default as ArcConfirmDialog } from './components/ArcConfirmDialog';
export { default as ArcDeleteDialog } from './components/ArcDeleteDialog';
export { default as ArcEmojiButton } from './components/ArcEmojiButton';
export { default as ArcFlatList } from './components/ArcFlatList';

export { default as ArcGallery } from './components/ArcGallery';
export { default as ArcFileGallery } from './components/ArcFileGallery';
export { default as ArcImageGallery } from './components/ArcImageGallery';
export { default as ArcViewContent } from './components/ArcView/ArcViewContent';
export { default as ArcViewContentBody } from './components/ArcView/ArcViewContentBody';
export { default as ArcViewContentHeader } from './components/ArcView/ArcViewContentHeader';
export { default as ArcViewHeader } from './components/ArcView/ArcViewHeader';
export { default as ArcViewContentParagraph } from './components/ArcView/ArcViewContentParagraph';
export { default as ArcViewTitleBar } from './components/ArcView/ArcViewTitleBar';

export { default as ArcInterlaced } from './animations/ArcInterlaced';
export { default as ArcFadeIn } from './animations/ArcFadeIn';
export { default as ArcLoader } from './animations/ArcLoader';
export { default as ArcAttachedFile } from './components/ArcAttachedFile';
export { default as ArcAttachedFileList } from './components/ArcAttachedFileList';
export { default as ArcBadge } from './components/ArcBadge';
export { default as ArcCard } from './components/ArcCard';
export { default as ArcCardActionButton } from './components/ArcCardActionButton';
export { default as ArcCardContent } from './components/ArcCard/ArcCardContent';
export { default as ArcCardHeader } from './components/ArcCard/ArcCardHeader';
export { default as ArcCardNav } from './components/ArcCard/ArcCardNav';
export { default as ArcCardNavItem } from './components/ArcCard/ArcCardNavItem';
export { default as ArcCardNavItemIcon } from './components/ArcCard/ArcCardNavItemIcon';
export { default as ArcCellContent } from './components/ArcResponsiveDataTable/ArcCellContent';
export { default as ArcCircuit } from './components/ArcCircuit/ArcCircuit';
export { default as ArcChecklist } from './components/ArcChecklist';
export { default as ArcChecklistItem } from './components/ArcChecklist/ArcChecklistItem';
export { default as ArcChestImage } from './components/ArcChestImage';
export { default as ArcChestSelect } from './components/ArcChestSelect';
export { default as ArcDataTableCell } from './components/ArcResponsiveDataTable/ArcDataTableCell';
export { default as ArcDataTableRow } from './components/ArcResponsiveDataTable/ArcDataTableRow';
export { default as ArcEmoji } from './components/ArcEmoji';
export { default as ArcEntity } from './components/ArcEntity';
export { default as ArcEntityAvatar } from './components/ArcEntityAvatar';
export { default as ArcErrorBoundary } from './components/ArcErrorBoundary';
export { default as ArcErrorDialog } from './components/ArcErrorDialog';
export { default as ArcFireworksScore } from './components/ArcFireworksScore';
export { default as ArcForm } from './components/ArcForm';
export { default as ArcFormField } from './components/ArcFormField';
export { default as ArcFormStepper } from './components/ArcFormStepper';
export { default as ArcFormSummary } from './components/ArcFormSummary';
export { default as ArcGameCard } from './components/ArcGameCard';
export { default as ArcInternalLink } from './components/ArcInternalLink';
export { default as ArcLoaderButton } from './components/ArcLoaderButton';
export { default as ArcManageChecklist } from './components/ArcManageChecklist';
export { default as ArcMarkdownContent } from './components/ArcMarkdownContent';
export { default as ArcMountain } from './components/ArcMountain';
export { default as ArcMobileStepper } from './components/ArcMobileStepper';
export { default as ArcNoResultsMessage } from './components/ArcNoResultsMessage';
export { default as ArcPeopleList } from './components/ArcPeopleList';
export { default as ArcTeam } from './components/ArcPeopleList';
export { default as ArcTeamLetter } from './components/ArcTeamLetter';
export { default as ArcPeopleSelector } from './components/ArcPeopleSelector';
export { default as ArcPlaceholder } from './components/ArcPlaceholder';
export { default as ArcProgress } from './components/ArcProgress';
export { default as ArcResponsiveDataTable } from './components/ArcResponsiveDataTable';
export { default as ArcResponsiveDialog } from './components/ArcResponsiveDialog';
export { default as ArcRunningTrack } from './components/ArcRunningTrack';
export { default as ArcScreenBlocker } from './components/ArcScreenBlocker';
export { default as ArcStepper } from './components/ArcStepper';
export { default as ArcStepperNew } from './components/ArcStepperNew_OLD';
export { default as ArcScoreboard } from './components/ArcScoreboard';
export { default as ArcScoreboardList } from './components/ArcScoreboard/ArcScoreboardList';
export { default as ArcTokenBalanceCard } from './components/ArcTokenBalanceCard';
export { default as ArcTokenTopUpDialog } from './components/ArcTokenTopUpDialog';
export { default as ArcTokenTransferDialog } from './components/ArcTokenTransferDialog';
export { default as ArcUserInput } from './components/ArcUserInput';
export { default as ArcVine } from './components/ArcVine';

export { ArcMenu, ArcMenuItem, ArcEasyMenu } from './elements/ArcMenu';
export { default as ArcTooltip } from './elements/ArcTooltip';
export { default as ArcButton } from './elements/ArcButton';
export { default as ArcButtonGroup } from './elements/ArcButtonGroup';
export { default as ArcCheckbox } from './elements/ArcCheckbox';
export { default as ArcDatePicker } from './elements/ArcDatePicker';
export { default as ArcDateRange } from './elements/ArcDateRange';
export { default as ArcDialog } from './elements/ArcDialog';
export { default as ArcSimpleDialog } from './elements/ArcSimpleDialog';
export { default as ArcHidden } from './elements/ArcHidden';
export { default as ArcIconButton } from './elements/ArcIconButton';
export { default as ArcLink } from './elements/ArcLink';
export { default as ArcListSelectable } from './elements/ArcList/ArcListSelectable';
export {
  default as ArcListItem,
  ArcListItemIcon,
} from './elements/ArcListItem';
export { default as ArcLogo } from './elements/ArcLogo';
export { default as ArcPaper } from './elements/ArcPaper';
export { default as ArcRadioButtonGroup } from './elements/ArcRadioButtonGroup';
export { default as ArcSelect } from './elements/ArcSelect';
export { default as ArcSpacer } from './elements/ArcSpacer';
export { default as ArcTable } from './elements/ArcTable';
export { default as ArcTableCellPerson } from './elements/ArcTable/ArcTableCellPerson';
export { default as ArcTextField } from './elements/ArcTextField';
export { default as ArcToggle } from './elements/ArcToggle';
export { default as ArcDateTime } from './elements/ArcDateTime';

export { default as ArcFormConfirmation } from './forms/ArcFormConfirmation';
export { default as ArcFormControl } from './forms/ArcFormControl';
export { default as ArcFormCurrencyField } from './forms/ArcFormCurrencyField';
export { default as ArcFormDatePicker } from './forms/ArcFormDatePicker';
export { default as ArcFormDateSelector } from './forms/ArcFormDateSelector';
export { default as ArcFormNumberField } from './forms/ArcFormNumberField';
export { default as ArcFormPeopleSelector } from './forms/ArcFormPeopleSelector';
export { default as ArcFormRadioButtonGroup } from './forms/ArcFormRadioButtonGroup';
export { default as ArcFormSelect } from './forms/ArcFormSelect';
export { default as ArcFormTextField } from './forms/ArcFormTextField';

export { default as Icon } from './icons/Icon/Icon';

export { default as ArcGrid } from './layout/ArcGrid';
export { default as ArcMain } from './layout/ArcMain';
export { default as ArcMainNav } from './layout/ArcMainNav';
export { default as ArcMainView } from './layout/ArcMainView';
export { default as ArcScroll } from './layout/ArcScroll';

export { default as ArcImage } from './primitives/ArcImage';
export { default as ArcScaledImage } from './primitives/ArcScaledImage';
export { default as ArcText } from './primitives/ArcText';
export { default as ArcTouchable } from './primitives/ArcTouchable';
export { default as ArcView } from './primitives/ArcView';

export { default as ArcThemeProvider } from './providers/ArcThemeProvider';
export { default as ArcBenjoThemeProvider } from './providers/ArcBenjoThemeProvider';

export { default as palette } from './styles/palette';
export { default as createWithStyles } from './styles/createWithStyles';

export { default as ArcDateText } from './typography/ArcDateText';
export { default as ArcHeading } from './typography/ArcHeading';
export { default as ArcParagraph } from './typography/ArcParagraph';
export { default as ArcSubheading } from './typography/ArcSubheading';

export { default as AppBar } from '@material-ui/core/AppBar';
export { default as ButtonBase } from '@material-ui/core/ButtonBase';
export { default as Drawer } from '@material-ui/core/Drawer';
export { default as List } from '@material-ui/core/List';
export { default as ListItem } from '@material-ui/core/ListItem';
export { default as ListItemIcon } from '@material-ui/core/ListItemIcon';
export { default as ListItemText } from '@material-ui/core/ListItemText';
export { default as MenuItem } from '@material-ui/core/MenuItem';
export { default as Toolbar } from '@material-ui/core/Toolbar';
export { default as Tooltip } from '@material-ui/core/Tooltip';
export { default as Typography } from '@material-ui/core/Typography';

export { default as DialogTitle } from '@material-ui/core/DialogTitle';
export { default as Dialog } from '@material-ui/core/Dialog';
export { default as Grid } from '@material-ui/core/Grid';

export { Platform } from 'react-primitives';

export { ActivityIndicator } from 'react-native';
