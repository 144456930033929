import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  ArcMainView,
  ArcScroll,
  ArcView,
  ArcViewTitleBar,
  ArcText,
  Typography,
} from 'arcade-frontend-ui';
import ArcDeleteDialog from 'arcade-frontend-ui/src/components/ArcDeleteDialog';
import { routes as routesManage } from 'arcade-frontend-features/src/actions/manage';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import { pluralize } from 'arcade-frontend-core/src/helpers/pluralize';
import { ArcFormDateRangePickerContainer } from 'arcade-frontend-widgets/src/containers/ArcFormDateRangePickerContainer';

import { actions } from '../../actions';
import {
  getActivityHistory,
  getTotalActivities,
} from '../../reducers/activityHistory';
import { getActivityHistoryDetails } from '../../reducers/activityHistoryDetails';
import { getRequestStatus } from '../../reducers/requestStatus';

import ActivityHistoryList from '../../components/ActivityHistoryList';
import ActivityDetailsDialog from '../../components/ActivityDetailsDialog';

class NewsfeedActivityHistoryContainer extends React.PureComponent {
  static propTypes = {
    activities: PropTypes.arrayOf(PropTypes.object),
    activityDetails: PropTypes.shape({}),
    apiActivityHistoryDetailsRequest: PropTypes.func,
    apiActivityHistoryDeleteRequest: PropTypes.func,
    apiActivityHistoryIndexRequest: PropTypes.func,
    currentUser: PropTypes.shape({
      id: PropTypes.string,
    }),
    requestStatus: PropTypes.shape({
      ACTIVITY_HISTORY_INDEX: PropTypes.string,
      ACTIVITY_HISTORY_DETAILS: PropTypes.string,
      ACTIVITY_HISTORY_DELETE: PropTypes.string,
    }),
    routeManage: PropTypes.func.isRequired,
    totalActivities: PropTypes.number,
  };

  static defaultProps = {
    activities: null,
    activityDetails: null,
    apiActivityHistoryDetailsRequest: global.noop,
    apiActivityHistoryDeleteRequest: global.noop,
    apiActivityHistoryIndexRequest: global.noop,
    currentUser: {},
    requestStatus: {},
    totalActivities: 0,
  };

  state = {
    activityDetailsDialogOpen: false,
    deleteActivityDialogOpen: false,
    deleteActivityId: null,
    page: 0,
    rowsPerPage: 50,
    dateRange: {
      type: 'time period',
      timePeriod: 'last_30_days',
      fromDate: undefined,
      toDate: undefined,
    },
  };

  componentDidUpdate(prevProps) {
    const shouldReload =
      (prevProps.requestStatus.ACTIVITY_HISTORY_DELETE === 'REQUEST' &&
        this.props.requestStatus.ACTIVITY_HISTORY_DELETE === 'SUCCESS') ||
      prevProps.currentUser !== this.props.currentUser;

    if (shouldReload) {
      this.reloadPosts();
    }
  }

  componentDidMount() {
    this.reloadPosts();
  }

  get isRequestingIndex() {
    return this.props.requestStatus.ACTIVITY_HISTORY_INDEX === 'REQUEST';
  }

  get isRequestingDetails() {
    return this.props.requestStatus.ACTIVITY_HISTORY_DETAILS === 'REQUEST';
  }

  closeActivityDetailsDialog = () =>
    this.setState({ activityDetailsDialogOpen: false });

  closeDeleteActivityDialog = () =>
    this.setState({ deleteActivityDialogOpen: false });

  handleChangePage = page => {
    this.setState({ page }, this.reloadPosts);
  };

  handleChangeRowsPerPage = rowsPerPage => {
    this.setState({ rowsPerPage }, this.reindexPosts);
  };

  reindexPosts = () => {
    if (!this.props.currentUser.id) {
      return;
    }

    const { dateRange, rowsPerPage } = this.state;

    this.props.apiActivityHistoryIndexRequest({
      page: 0,
      rowsPerPage,
      ...dateRange,
    });
  };

  reloadPosts = () => {
    if (!this.props.currentUser.id) {
      return;
    }

    const { dateRange, page, rowsPerPage } = this.state;

    this.props.apiActivityHistoryIndexRequest({
      page,
      rowsPerPage,
      ...dateRange,
    });
  };

  handleDateRangeChange = ({ type, timePeriod, fromDate, toDate }) => {
    if (type === 'time period') {
      this.setState(
        {
          dateRange: {
            type,
            timePeriod,
            fromDate: undefined,
            toDate: undefined,
          },
        },
        this.reindexPosts,
      );
    } else {
      this.setState(
        {
          dateRange: {
            type,
            timePeriod: undefined,
            fromDate,
            toDate,
          },
        },
        this.reindexPosts,
      );
    }
  };

  handleDelete = () => {
    this.props.apiActivityHistoryDeleteRequest({
      id: this.state.deleteActivityId,
    });
    this.closeDeleteActivityDialog();
  };

  handleClickActivityDetails = (id, section) => {
    this.props.apiActivityHistoryDetailsRequest(id);
    this.showActivityDetailsDialog(section);
  };

  handleClickDeleteActivity = id => {
    this.showDeleteActivityDialog(id);
  };

  showActivityDetailsDialog = section => {
    this.setState({
      activityDetailsDialogOpen: true,
      activityDetailsStartingTab: section,
    });
  };

  showDeleteActivityDialog = id => {
    this.setState({ deleteActivityDialogOpen: true, deleteActivityId: id });
  };

  renderActivityDetailsDialog() {
    return (
      <ActivityDetailsDialog
        isRequesting={this.isRequestingDetails}
        open={this.state.activityDetailsDialogOpen}
        onClose={this.closeActivityDetailsDialog}
        startingTab={this.state.activityDetailsStartingTab}
        {...this.props.activityDetails}
      />
    );
  }

  renderDeleteActivityDialog() {
    return (
      <ArcDeleteDialog
        open={this.state.deleteActivityDialogOpen}
        onClose={this.closeDeleteActivityDialog}
        onDelete={this.handleDelete}
      />
    );
  }

  render() {
    const { dateRange, page } = this.state;
    const { activities, totalActivities } = this.props;

    return (
      <ArcMainView
        isViewing
        hasScroll={false}
        hasNoPaddingLeftLaunch
        position="relative"
      >
        <ArcViewTitleBar
          onClickBack={this.props.routeManage}
          title="Manage Posts"
        />
        <ArcScroll color="paper">
          <ArcView fullHeight fullWidth color="paper">
            <ArcView row padding="8" paddingLeft="16">
              <ArcView row align="center">
                <Typography variant="h5" style={{ marginRight: 8 }}>
                  {totalActivities}
                </Typography>
                <ArcText>{pluralize(totalActivities, 'post')}</ArcText>
              </ArcView>
              <ArcView spacer />
              <ArcFormDateRangePickerContainer
                value={dateRange}
                onChange={this.handleDateRangeChange}
              />
            </ArcView>
            <ActivityHistoryList
              activities={activities}
              isRequesting={this.isRequestingIndex}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              onClickActivity={this.handleClickActivityDetails}
              onClickDeleteActivity={this.handleClickDeleteActivity}
              page={page}
              rowsPerPage={this.state.rowsPerPage}
              totalActivities={this.props.totalActivities}
            />
            {this.renderActivityDetailsDialog()}
            {this.renderDeleteActivityDialog()}
          </ArcView>
        </ArcScroll>
      </ArcMainView>
    );
  }
}

const getState = (state, props) => ({
  activities: getActivityHistory(state),
  activityDetails: getActivityHistoryDetails(state),
  currentUser: getCurrentUser(state),
  requestStatus: getRequestStatus(state),
  totalActivities: getTotalActivities(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      apiActivityHistoryIndexRequest: actions.apiActivityHistoryIndexRequest,
      apiActivityHistoryDetailsRequest:
        actions.apiActivityHistoryDetailsRequest,
      apiActivityHistoryDeleteRequest: actions.apiActivityHistoryDeleteRequest,
      routeManage: routesManage.routeManage,
    },
    dispatch,
  );

export default connect(getState, getActions)(NewsfeedActivityHistoryContainer);
