import React from 'react';
import { Errors } from 'react-redux-form';

import ArcPropTypes from '../helpers/arc/propTypes';
import ArcView from '../primitives/ArcView/ArcView';

const FirstErrorText = ({ children }) => (<ArcView>{children[0]}</ArcView>);

FirstErrorText.propTypes = {
  children: ArcPropTypes.children.isRequired,
};

const ArcFormError = (form, model, messages, label) => (
  form.touched && !form.valid ? (
    <Errors
      show="touched"
      model={model}
      wrapper={FirstErrorText}
      messages={messages}
    />
  ) : label
);

export default ArcFormError;
