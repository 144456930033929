import React from 'react';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';

import ArcPropTypes from '../helpers/arc/propTypes';
import { isArray } from '../helpers/utils/array';
// import { trimStringByDelimiter } from '../helpers/utils/string';
import { getProperty } from '../helpers/utils/objects';
import ArcView from '../primitives/ArcView/ArcView';

import ArcFormRadioButtonGroup from './ArcFormRadioButtonGroup';
import ArcFormPeopleList from './ArcFormPeopleList';


const trimStringByDelimiter = (string, delimiter = '.') => {
  const arr = string.split(delimiter);
  if (arr.length && arr.length > 1) arr.pop();

  return arr.join('.');
};


const styleAssignField = {
  marginBottom: 32,
};

const styleHeading = {
  fontSize: 20,
  fontWeight: '300',
  marginBottom: 16,
};

const styleTeams = {
  marginBottom: 32,
};

const teamsModel = '.teamIds';
const peopleModel = '.peopleIds';

const PEOPLE = 'people';
const TEAMS = 'teams';
const BOTH = 'both';


const assignAllPeople = {
  value: 'everyone',
  label: 'Assign to all people',
};

const assignSelectPeople = {
  value: 'people',
  label: 'Select people',
};

const assignAllTeams = {
  value: 'all_teams',
  label: 'Assign to all teams',
};

const assignSelectTeams = {
  value: 'teams',
  label: 'Select teams',
};

const assignPeopleItems = [
  assignAllPeople,
  assignSelectPeople,
];

const assignTeamItems = [
  assignAllTeams,
  assignSelectTeams,
];

const assignBothItems = [
  assignAllPeople,
  assignSelectPeople,
];

class ArcFormPeopleSelector extends React.PureComponent {
  static propTypes = {
    dispatch: ArcPropTypes.func.isRequired,
    model: ArcPropTypes.string.isRequired,

    type: ArcPropTypes.oneOf([PEOPLE, TEAMS, BOTH]),
    people: ArcPropTypes.people,
    teams: (props, propName, componentName) => {
      ArcPropTypes.checkPropTypes({ teams: ArcPropTypes.teams }, props, propName, componentName);

      if (props.type === BOTH) {
        props[propName].forEach((team) => {
          if (!team.peopleIds || !isArray(team.peopleIds)) {
            throw new Error(`\`${propName}\` require an array of \`peopleIds\` if ArcFormPeopleSelector type is \`both\``);
          }
        });
      }
    },
    selectedPeople: ArcPropTypes.ids,
    selectedTeams: ArcPropTypes.ids,

    onError: ArcPropTypes.func,
    minSelect: ArcPropTypes.number,
    assign: ArcPropTypes.string,
  };

  static defaultProps = {
    type: BOTH,
    people: [],
    teams: [],
    selectedPeople: [],
    selectedTeams: [],

    onError: ArcPropTypes.noop,
    minSelect: 0,
    assign: 'everyone',
  };

  constructor(props) {
    super(props);

    this.teamPeopleMap = {};
    this.peopleTeamMap = {};

    this.props.teams.forEach((team) => {
      const peopleIds = team.peopleIds || [];
      this.teamPeopleMap[team.id] = peopleIds;

      peopleIds.forEach((id) => {
        this.peopleTeamMap[id] = team.id;
      });
    });
  }

  state = {
    assign: this.props.assign,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.assign !== this.state.assign) {
      this.setState({
        assign: nextProps.assign,
      });
    }
  }

  get shouldRenderTeams() {
    const { type, teams } = this.props;

    return (type === TEAMS || type === BOTH) && !!teams.length;
  }

  handleChangeAssign = (e, value) => {
    if (value === 'everyone' || value === 'teams') {
      const action = actions.resetValidity(this.props.model);
      this.props.dispatch(action);
      if (this.props.onError) this.props.onError(null);
    }

    if (value !== this.state.assign) {
      this.setState({ assign: value });
    }
  };

  handleChangeTeam = (e, values, type, model, id, checked) => {
    if (this.props.type === TEAMS) return;

    let { selectedPeople } = this.props;
    let people = [];

    if (values.length) {
      people = values.map((teamId) => {
        const teamPeople = this.teamPeopleMap[teamId] || [];
        const selectedTeamPeople = selectedPeople.filter(personId => teamPeople.includes(personId));
        const { length } = selectedTeamPeople;
        const isPartiallySelected = length && length < teamPeople.length;

        if (id === teamId) {
          return checked ? teamPeople : [];
        }

        return isPartiallySelected ? selectedTeamPeople : teamPeople;
      });

      if (id) {
        selectedPeople = selectedPeople.filter(personId => !this.teamPeopleMap[id].includes(personId));
      }

      people = [].concat(selectedPeople).concat(...people);
      people = people.unique();
    }

    const peopleFullModel = trimStringByDelimiter(model) + peopleModel;
    const action = actions.change(peopleFullModel, people);
    this.props.dispatch(action);
  };

  handleChangePeople = (e, values, type, model) => {
    if (this.props.type === PEOPLE) return;

    let teams = values.map((personId) => {
      const teamId = this.peopleTeamMap[personId];
      const teamPeople = this.teamPeopleMap[teamId] || [];

      const selectedTeamPeople = values.filter(val => teamPeople.includes(val));
      const { length } = selectedTeamPeople;

      return length === teamPeople.length ? teamId : [];
    });

    teams = [].concat(...teams);
    teams = teams.unique();

    const teamsFullModel = trimStringByDelimiter(model) + teamsModel;
    const action = actions.change(teamsFullModel, teams);
    this.props.dispatch(action);
  };

  renderPeople() {
    return (
      <ArcFormPeopleList
        model={peopleModel}
        type="people"
        label="People"
        isSelectable
        people={this.props.people}
        selectedTeams={this.props.selectedTeams}
        validations={{
          minSelect: this.state.assign === 'people' ? this.props.minSelect : 0,
        }}
        onChange={this.handleChangePeople}
        onError={this.props.onError}
        showSelectAll={this.props.type === PEOPLE}
      />
    );
  }

  renderTeams() {
    return (
      <ArcFormPeopleList
        model={teamsModel}
        type="teams"
        label="Teams"
        isSelectable
        teams={this.props.teams}
        selectedPeople={this.props.selectedPeople}
        onChange={this.handleChangeTeam}
        onError={this.props.onError}
        style={styleTeams}
      />
    );
  }

  renderTeamsAndPeople() {
    const { assign } = this.state;

    if (assign === 'everyone' || assign === 'all_teams') return null;

    return (
      <ArcView>
        {this.shouldRenderTeams && (<ArcView style={styleHeading}>Teams</ArcView>)}
        {this.shouldRenderTeams && this.renderTeams()}

        {
          ((this.props.type === PEOPLE || this.props.type === BOTH) && this.props.type !== TEAMS)
          && (<ArcView style={styleHeading}>Individuals</ArcView>)
        }

        {this.props.type !== TEAMS && this.renderPeople()}
      </ArcView>
    );
  }

  renderAssignItems() {
    switch (this.props.type) {
      case PEOPLE:
        return assignPeopleItems;
      case TEAMS:
        return assignTeamItems;
      case BOTH:
      default:
        return assignBothItems;
    }
  }

  render() {
    return (
      <ArcView>
        <ArcFormRadioButtonGroup
          model=".assign"
          onChange={this.handleChangeAssign}
          items={this.renderAssignItems()}
          style={styleAssignField}
        />
        {this.renderTeamsAndPeople()}
      </ArcView>
    );
  }
}

const mapStateToProps = (state, props) => ({
  assign: getProperty(props.model, state).assign || 'everyone',
  selectedPeople: getProperty(props.model, state).peopleIds || [],
  selectedTeams: getProperty(props.model, state).teamIds || [],
});

export default connect(mapStateToProps)(ArcFormPeopleSelector);
