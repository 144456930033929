import React from 'react';
import PropTypes from 'prop-types';

import { createWithStyles } from '../../styles';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';
import ArcResponsiveDialog from '../../components/ArcResponsiveDialog';
import Close from '../../icons/Close';
import ArcIconButton from '../ArcIconButton';

const paperProps = { style: { height: '90%', width: '90%' } };


const styles = {
  DialogHeader: theme => ({
    root: {
      backgroundColor: theme.palette.grey.tint,
    },
  }),
};

const DialogHeader = createWithStyles(styles.DialogHeader)(ArcView);

const ArcSimpleDialog = ({ open, onClose, title, children }) => (
  <ArcResponsiveDialog open={open} onClose={onClose} PaperProps={paperProps}>
    <DialogHeader row align="center">
      <ArcView
        row
        flexGrow={1}
        paddingTop={24}
        paddingRight={8}
        paddingLeft={24}
        paddingBottom={24}
      >
        <ArcText
          isLarger
          color="grey"
        >
          {title}
        </ArcText>
      </ArcView>
      <ArcIconButton
        onClick={onClose}
        style={{ width: 56, height: 56 }}
        label="Close"
      >
        <Close />
      </ArcIconButton>
    </DialogHeader>
    {children}
  </ArcResponsiveDialog>
);

ArcSimpleDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ArcSimpleDialog.defaultProps = {
  open: false,
  children: null,
};

export default ArcSimpleDialog;
