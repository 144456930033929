import { makeApiActionTypes } from 'arcade-frontend-ui';

export const MANAGE_METRICS_CREATE = makeApiActionTypes('MANAGE_METRICS_CREATE');
export const MANAGE_METRICS_UPDATE = makeApiActionTypes('MANAGE_METRICS_UPDATE');
export const MANAGE_METRICS_TEAMS_INDEX = makeApiActionTypes('MANAGE_METRICS_TEAMS_INDEX');
export const MANAGE_METRICS_EDIT = makeApiActionTypes('MANAGE_METRICS_EDIT');
export const MANAGE_METRICS_ARCHIVE = makeApiActionTypes('MANAGE_METRICS_ARCHIVE');
export const MANAGE_METRICS_RESTORE = makeApiActionTypes('MANAGE_METRICS_RESTORE');
export const MANAGE_METRICS_INDEX = makeApiActionTypes('MANAGE_METRICS_INDEX');
export const MANAGE_ACTIVE_METRICS_INDEX = makeApiActionTypes('MANAGE_ACTIVE_METRICS_INDEX');
export const MANAGE_ARCHIVED_METRICS_INDEX = makeApiActionTypes('MANAGE_ARCHIVED_METRICS_INDEX');

export const MANAGE_METRICS_RANKINGS_INDEX = makeApiActionTypes('MANAGE_METRICS_RANKINGS_INDEX');
export const MANAGE_METRICS_ACTIVITIES_INDEX = makeApiActionTypes('MANAGE_METRICS_ACTIVITIES_INDEX');
