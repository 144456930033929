import React from 'react';
import PropTypes from 'prop-types';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';
import pluralize from 'pluralize';

import {
  ArcText,
  ArcView,
  ArcButton,
  ArcButtonGroup,
  ArcPlaceholder,
  ArcCheckbox,
  createWithStyles,
} from 'arcade-frontend-ui';

import { audienceObject, levelObject } from '../../propTypes';

const styles = {
  Title: theme => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      borderWidth: 0,
      borderBottomWidth: 1,
      borderColor: theme.palette.grey.light,
      borderStyle: 'solid',
      paddingBottom: theme.spacing(1),
    },
  }),
  Identifier: theme => ({
    root: {
      color: theme.palette.common.white,
      flexShrink: 1,
      borderRadius: 3,
      width: 24,
      height: 24,
      fontSize: 12,
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
    },
  }),
  Row: () => ({
    root: {
      padding: '3px 0',
      cursor: 'pointer',
    },
  }),
};

const Title = createWithStyles(styles.Title)(ArcView);
const Identifier = createWithStyles(styles.Identifier)(ArcView);
const Row = createWithStyles(styles.Row)(ArcView);

const AudienceItem = ({ audience, selected }) => (
  <ArcView row align="center">
    <ArcCheckbox checked={selected} />
    <Identifier style={{ backgroundColor: audience.color }}>
      {audience.initial}
    </Identifier>
    <ArcView marginLeft={8} marginRight={8}>
      {audience.name}
    </ArcView>
  </ArcView>
);

AudienceItem.propTypes = {
  audience: audienceObject.isRequired,
  selected: PropTypes.bool,
};
AudienceItem.defaultProps = {
  selected: false,
};

const Placeholders = () => (
  <ArcView>
    <ArcPlaceholder style={{ width: '100%', marginBottom: 8 }}>
      1
    </ArcPlaceholder>
    <ArcPlaceholder style={{ width: '75%', marginBottom: 8 }}>2</ArcPlaceholder>
    <ArcPlaceholder style={{ width: '50%', marginBottom: 8 }}>3</ArcPlaceholder>
  </ArcView>
);

const NG_HEADER_HEIGHT = 44;

const PAPER_PROPS = {
  square: false,
  style: {
    marginTop: global.Platform.OS === 'ios' ? -(NG_HEADER_HEIGHT - 8) : 8,
  },
};

class ArcAudienceSelector extends React.Component {
  static displayName = 'ArcAudienceSelector';

  static propTypes = {
    audiences: PropTypes.arrayOf(audienceObject),
    isDisabled: PropTypes.bool,
    isEditable: PropTypes.bool,
    filter: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    }),
    levels: PropTypes.arrayOf(levelObject),
    loadAudienceTypes: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    onFilterToggle: PropTypes.func.isRequired,
    onOpenAudienceTool: PropTypes.func.isRequired,
    requestStatus: PropTypes.objectOf(PropTypes.string),
    selectedIds: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    audiences: [],
    isDisabled: false,
    isEditable: false,
    filter: null,
    levels: null,
    onFocus: global.noop,
    onBlur: global.noop,
    requestStatus: {},
    selectedIds: [],
  };

  state = {
    controlsAnchorEl: null,
    isOpen: false,
  };

  componentDidMount() {
    this.props.loadAudienceTypes();
  }

  get container() {
    if (this.state.controlsAnchorEl && global.Platform.OS === 'ios') {
      return this.state.controlsAnchorEl.closest('.arc-scroll-content');
    }

    return null;
  }

  get shareToLabel() {
    const { length } = this.props.selectedIds;

    if (!this.props.audiences) {
      return 'Everyone';
    }

    if (length === 1) {
      const audience = this.props.audiences.filter(
        item => item.id === this.props.selectedIds[0],
      )[0];

      return audience && audience.name ? audience.name : '1 Audience';
    }

    if (length > 1) {
      return `${length} ${pluralize('audiences', length)}`;
    }

    return 'Everyone';
  }

  setControlsAnchorEl = (controlsAnchorEl, cb) =>
    this.setState({ controlsAnchorEl }, cb);

  setIsOpen = (isOpen, cb) => this.setState({ isOpen }, cb);

  toggleFilter(steps = 1) {
    const { levels, filter } = this.props;

    this.props.onFilterToggle({ levels, filter, steps });
  }

  handleCloseControls = () => {
    this.setIsOpen(false, this.props.onBlur);
  };

  handleFilterToggle = () => this.toggleFilter();

  handleLevelNext = () => this.toggleFilter(1);

  handleLevelPrev = () => this.toggleFilter(-1);

  handleOpenControls = e => {
    if (this.props.isEditable) {
      this.setIsOpen(true);
      this.setControlsAnchorEl(e.currentTarget, this.props.onFocus);
    }
  };

  handleSelect = audience => {
    this.props.onSelect(audience);
    this.forceUpdate();
  };

  renderAudience = audience => {
    const selected = this.props.selectedIds.includes(audience.id);

    return (
      <Row
        key={audience.id}
        aria-checked={selected ? 'true' : 'false'}
        role="menuitemcheckbox"
        onClick={() => this.handleSelect(audience)}
      >
        <AudienceItem audience={audience} selected={selected} />
      </Row>
    );
  };

  renderAudiences() {
    const { audiences } = this.props;
    const { length } = audiences;

    if (this.props.requestStatus.AUDIENCES_GET_AUDIENCES === 'REQUEST') {
      return <Placeholders />;
    }

    if (length === 0) {
      return <ArcText isLight>{'No Audiences Found'}</ArcText>;
    }

    return <ArcView role="menu">{audiences.map(this.renderAudience)}</ArcView>;
  }

  renderLevel = level => (
    <ArcButton
      key={level.id}
      className={level.id === this.props.filter.id ? 'isActive' : undefined}
      onClick={this.handleFilterToggle}
      size="small"
    >
      <ArcText textOverflow="ellipsis">{level.description}</ArcText>
    </ArcButton>
  );

  renderLevels() {
    const { levels } = this.props;
    const { length } = levels;

    if (length > 2) {
      return (
        <React.Fragment>
          <ArcButton onClick={this.handleLevelPrev}>
            <ChevronLeft color="action" fontSize="small" />
          </ArcButton>
          {this.renderLevel(this.props.filter)}
          <ArcButton onClick={this.handleLevelNext}>
            <ChevronRight color="action" fontSize="small" />
          </ArcButton>
        </React.Fragment>
      );
    }

    return levels.map(this.renderLevel);
  }

  renderFilter() {
    return (
      <ArcView>
        <ArcView marginTop={8} marginBottom={8} row>
          <ArcButtonGroup fullWidth>{this.renderLevels()}</ArcButtonGroup>
        </ArcView>
        {this.renderAudiences()}
      </ArcView>
    );
  }

  render() {
    const { isEditable } = this.props;

    return (
      <React.Fragment>
        <ArcButton
          size="small"
          onClick={this.handleOpenControls}
          textTransform="initial"
          disabled={this.props.isDisabled}
        >
          <ArcView paddingLeft="8">
            <ArcText isSmaller textAlign="left">
              {'Share to'}
            </ArcText>
            <ArcView marginTop="2">{this.shareToLabel}</ArcView>
          </ArcView>

          {isEditable && (
            <ArcView marginLeft="4">
              <ExpandMore color="action" fontSize="small" />
            </ArcView>
          )}
        </ArcButton>

        <Popover
          open={this.state.isOpen}
          anchorEl={this.state.controlsAnchorEl}
          onClose={this.handleCloseControls}
          container={this.container}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          elevation={1}
          PaperProps={PAPER_PROPS}
        >
          <ArcView padding="8">
            <Title>
              <ArcView marginRight="16">{'Select Audience'}</ArcView>
              <ArcView spacer />
              <ArcButton
                variant="outlined"
                color="blue"
                onClick={this.props.onOpenAudienceTool}
                size="small"
              >
                {'New'}
              </ArcButton>
            </Title>
            {!!this.props.filter && this.renderFilter()}
          </ArcView>
        </Popover>
      </React.Fragment>
    );
  }
}

export default ArcAudienceSelector;
