import React from 'react';
import PropTypes from 'prop-types';

import IconClose from '@material-ui/icons/Close';

import ArcButton from '../../elements/ArcButton';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';

import createWithStyles from '../../styles/createWithStyles';

const styles = {
  ButtonText: () => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    hidden: {
      visibility: 'hidden',
    },
  }),

  ButtonLoader: () => ({
    root: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },

    hidden: {
      visibility: 'hidden',
    },
  }),
};

const rootStyle = {
  display: 'flex',
  position: 'relative',
};

const ButtonText = createWithStyles(styles.ButtonText)(ArcText);
const ButtonLoader = createWithStyles(styles.ButtonLoader)(ArcView);

class ArcLoaderButton extends React.PureComponent {
  static displayName = 'ArcLoaderButton';

  static propTypes = {
    component: PropTypes.elementType,
    disabled: PropTypes.bool,
    loaderColor: PropTypes.string,
    label: PropTypes.node.isRequired,
    loadingState: PropTypes.oneOf(['DEFAULT', 'REQUEST', 'SUCCESS', 'FAILURE']),
    onClick: PropTypes.func,
    colour: PropTypes.oneOf(['WHITE', 'BLUE']),
    variant: PropTypes.string,
  };

  static defaultProps = {
    component: ArcButton,
    disabled: false,
    loaderColor: 'default',
    loadingState: 'DEFAULT',
    onClick: global.noop,
    colour: 'WHITE',
    variant: undefined,
  };

  state = {
    isRequesting: false,
  }

  handleClick = (e) => {
    if (this.props.loadingState === 'REQUEST') {
      return;
    }

    this.setIsRequesting(true);

    this.props.onClick(e);
  };

  setIsRequesting = (isRequesting) => {
    this.setState({ isRequesting });
  }

  render() {
    const {
      disabled,
      component,
      label,
      loaderColor,
      loadingState,
      ...rest
    } = this.props;

    const Component = component;

    return (
      <Component
        onClick={this.handleClick}
        disabled={disabled || loadingState !== 'DEFAULT'}
        style={rootStyle}
        {...rest}
      >
        <React.Fragment>
          <ButtonText
            hidden={loadingState !== 'DEFAULT'}
          >
            {label}
          </ButtonText>
          <ButtonLoader
            hidden={loadingState === 'DEFAULT'}
          >
            <ArcView
              className={loadingState === 'REQUEST'
                ? `circle-loader ${loaderColor}`
                : `circle-loader load-complete ${this.props.variant === 'text' ? 'filled' : ''}`
              }
            >
              <ArcView
                className={loadingState === 'SUCCESS' && 'checkmark draw'}
              />
            </ArcView>
          </ButtonLoader>
          <ButtonLoader
            hidden={loadingState !== 'FAILURE'}
          >
            <IconClose />
          </ButtonLoader>
        </React.Fragment>
      </Component>
    );
  }
}

export default ArcLoaderButton;
