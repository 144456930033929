import React from 'react';

import Menu from '@material-ui/core/Menu';
import ArcPropTypes from '../../helpers/arc/propTypes';


const ArcMenu = props => (
  <Menu {...props}>
    {props.children}
  </Menu>
);

ArcMenu.propTypes = {
  children: ArcPropTypes.children,
};

ArcMenu.defaultProps = {
  children: null,
};


export default ArcMenu;
