import { react2angular } from 'react2angular';

import { ArcNewsfeedFABContainer } from 'arcade-frontend-widgets/src/containers';

import { createProvidedContainer } from './helpers';

const ProvidedArcNewsfeedFABContainer = createProvidedContainer(ArcNewsfeedFABContainer);

export default react2angular(ProvidedArcNewsfeedFABContainer, [
  'showBackToTop',
  'onNavigateToPostHistory',
  'onScrollToTop',
]);
