import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/games';

const handleManageGamesResultsIndexAction = (state, action) => {
  if (action.type === types.MANAGE_GAMES_RESULTS_INDEX.REQUEST) {
    return { ...state, results: true };
  }
  return { ...state, results: false };
};

const initialState = { results: false };

const handlers = {
  [types.MANAGE_GAMES_RESULTS_INDEX.REQUEST]:
    handleManageGamesResultsIndexAction,
  [types.MANAGE_GAMES_RESULTS_INDEX.SUCCESS]:
    handleManageGamesResultsIndexAction,
  [types.MANAGE_GAMES_RESULTS_INDEX.FAILURE]:
    handleManageGamesResultsIndexAction,
};

const isFetchingResults = createReducer(initialState, handlers);

export default isFetchingResults;

export const getIsFetchingResults = state => state.manage.games.isFetchingResults;
