import React from 'react';
import TimePicker from 'rc-time-picker';
// import moment from 'moment';
import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcButton from '../ArcButton';
import ArcView from '../../primitives/ArcView';


const styleBlocker = {
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

class ArcTimePicker extends React.PureComponent {
  static propTypes = {
    disabled: ArcPropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  state = {
    open: false,
  };

  setOpen = open => this.setState({ open });

  handleClick = () => {
    if (this.props.disabled) {
      return;
    }

    this.setOpen(true);
  };

  handleClose = () => {
    this.setOpen(false);
  };

  handleOk = (e) => {
    e.stopPropagation();
    this.setOpen(false);
  };

  renderFooter = () => (
    <ArcButton
      fullWidth
      color="primary"
      label="Ok"
      onClick={this.handleOk}
    />
  );

  render() {
    return (
      <ArcView onClick={this.handleClick}>
        {this.state.open && <ArcView style={styleBlocker} />}
        <TimePicker
          showSecond={false}
          inputReadOnly
          focusOnOpen
          open={this.state.open}
          addon={this.renderFooter}
          onClose={this.handleClose}
          {...this.props}
        />
      </ArcView>
    );
  }
}

export default ArcTimePicker;
