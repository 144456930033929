const isImage = type => /image\//.test(type);

const defaultIcon = 'https://d17rkelr6jjme9.cloudfront.net/app/icons/file-icon-default.svg';

const iconPath = type => `https://d17rkelr6jjme9.cloudfront.net/app/icons/file-icon-${type}.svg`;

const extensionForFilename = (filename) => {
  const result = filename.match(/.+\.([^.]+)$/);
  if (result) {
    return result[1].toLowerCase();
  }
  return null;
};

const iconForExtension = (extension) => {
  switch (extension) {
    case 'doc':
    case 'docx':
    case 'pages':
      return iconPath('doc');
    case 'ppt':
    case 'pptx':
    case 'key':
      return iconPath('ppt');
    case 'md':
    case 'txt':
      return iconPath('txt');
    case 'xls':
    case 'xlsx':
    case 'numbers':
    case 'csv':
      return iconPath('csv');
    case 'pdf':
      return iconPath('pdf');
    default:
      return defaultIcon;
  }
};

const urlToFile = (url, filename, mimeType) => (fetch(url)
  .then(res => res.arrayBuffer())
  .then(buf => new File([buf], filename, { type: mimeType }))
);

export {
  isImage,
  defaultIcon,
  iconPath,
  extensionForFilename,
  iconForExtension,
  urlToFile,
};
