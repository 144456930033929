import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const RunningMan = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.688 6.79a1.5 1.5 0 00-2.108.233l-.656.819c-.4.504-1.08.694-1.684.472l-7-2.544a4.513 4.513 0 00-4.991 1.35L3.1 8.5a1.5 1.5 0 102.3 1.92l1.153-1.38a1.506 1.506 0 011.665-.449l1.675.609-1.881 5.274A1.5 1.5 0 016.589 15.5H3.75a1.5 1.5 0 000 3h2.839a4.493 4.493 0 004.048-2.53l1.124.45a1.506 1.506 0 01.9 1.756l-.865 3.46a1.5 1.5 0 002.91.728l.864-3.464a4.517 4.517 0 00-2.695-5.269l-1.207-.482 1.044-2.928 2.5.909a4.5 4.5 0 005.052-1.417l.656-.813a1.5 1.5 0 00-.232-2.11z"
      clipRule="evenodd"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21.75 12l-1.121-2.722m-1.147-2.787L18.25 3.5"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.75 5.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
      clipRule="evenodd"
    />
  </Icon>
);

export default RunningMan;
