import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const handleTeamsIndexSuccess = (state, action) => {
  const { data } = action.payload;
  const newState = {};

  data.forEach((team) => {
    newState[team.id] = team;
  });

  return newState;
};

const initialState = {};

const handlers = {
  [types.APP_TAGGABLE_TEAMS_INDEX.SUCCESS]: handleTeamsIndexSuccess,
};

const teamById = createReducer(initialState, handlers);

export default teamById;

export const getAppTeamsById = state => state.app.teamsById;

export const getAppTeams = state => Object.values(getAppTeamsById(state));
