import React from 'react';

import { G, Path, Rect } from 'react-primitives-svg';

import Icon from '../Icon';

const CreditCard = props => (
  <Icon {...props}>
    <G
      clipPath="url(#CreditCardClip0)"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <Rect
        x="1"
        y="4"
        width="22"
        height="16"
        rx="2"
        fill="transparent"
      />
      <Path
        fill="transparent"
        d="M16 14.031l1.88-3.789a.436.436 0 01.825.158l.3 3.569M16.598 12.828h2.328M11.113 9.969l-.812 4.062M4.957 10h1.281l.362 3.6a.448.448 0 00.85.148L9.3 9.891M14.832 9.922s-1.375-.36-1.844.422c-.469.782.266 1.281.75 1.578.484.297 1.016.75.875 1.281-.141.531-.734 1.313-2.3.766"
      />
    </G>
    <defs>
      <clipPath id="CreditCardClip0">
        <path d="M0 0h24v24H0V0z" fill="transparent" />
      </clipPath>
    </defs>
  </Icon>
);

export default CreditCard;
