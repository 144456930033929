import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { ActivityIndicator } from 'react-native';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';

import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import usePermission from 'arcade-frontend-core/src/hooks/usePermission';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcTextField from 'arcade-frontend-ui/src/elements/ArcTextField';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcSelect from 'arcade-frontend-ui/src/elements/ArcSelect';
import SearchOutline from 'arcade-frontend-ui/src/icons/SearchOutline';
import UsersAddOutline from 'arcade-frontend-ui/src/icons/UsersAddOutline';

import { getTeamsStatus } from '../../../reducers/teams';
import { getIsFetching } from '../../../reducers/isFetching';

const useStyles = makeStyles(theme => ({
  searchField: {
    minWidth: 150,
    backgroundColor: theme.palette.grey.tint,
    [theme.breakpoints.down('xs')]: {
      width: 100,
      flexGrow: 1,
    },
  },
  headerText: {
    fontSize: theme.font.getFontSize(1.5),
    fontWeight: 500,
    lineHeight: '150%',
    marginRight: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.getFontSize(1.25),
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  sortByDropdown: {
    width: 115,
    fontSize: 14,
    color: '#373A3D',
    backgroundColor: '#EBEBEB',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },
}));

const sortByDropdownOptions = [
  { label: 'By Name', value: 'by-name' },
  { label: 'By Team', value: 'by-team' },
  { label: 'Locked', value: 'locked' },
];

function PeopleListHeader({
  onChangeSearch,
  onChangeSortBy,
  onCreateTeamClick,
  onInvitePersonClick,
  type,
}) {
  const classes = useStyles();
  const [sortedBy, setSortedBy] = useState(sortByDropdownOptions[0].value);

  const statusGetTeams = useSelector(getTeamsStatus);
  const isFetchingPeople = useSelector(getIsFetching);
  const hasFeaturePeopleV2 = useFeatureFlag(FEATURE_FLAGS.PEOPLE_V2);
  const canInviteUsers = usePermission(PERMISSIONS.INVITE_USER);
  const canCreateTeams = usePermission(PERMISSIONS.MANAGE_TEAMS);

  const handleChangeSearch = useCallback(debounce(300, onChangeSearch), [
    onChangeSearch,
  ]);

  const handleChangeSort = event => {
    setSortedBy(event.target.value);
    onChangeSortBy(event.target.value);
  };

  const shouldShowCreateButton =
    type === 'members' ? canInviteUsers : canCreateTeams;

  return (
    <ArcBox
      display="flex"
      flexDirection="column"
      flexShrink={0}
      mt={1}
      mb={0}
      mx={[2, 3]}
    >
      <ArcBox display="flex" flexDirection="row" alignItems="center">
        <ArcTextField
          className={classes.searchField}
          placeholder={'Search'}
          onChange={event => handleChangeSearch(event.target.value)}
          variant="outlined"
          margin="dense"
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <ArcBox mr={[0.5, 1]} mt={0.5}>
                  <SearchOutline color="#A7A8AA" />
                </ArcBox>
              </InputAdornment>
            ),
          }}
        />

        {type === 'members' && (
          <ArcSelect
            className={classes.sortByDropdown}
            items={sortByDropdownOptions}
            name="sorting"
            value={sortedBy}
            onChange={handleChangeSort}
            variant="outlined"
            margin="dense"
          />
        )}
      </ArcBox>

      <ArcBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <ArcBox display="flex" flexDirection="row" justifyContent="flex-start">
          <ArcText className={classes.headerText}>
            {`All ${type === 'members' ? 'Members' : 'Teams'}`}
          </ArcText>

          <ActivityIndicator
            animating={statusGetTeams.pending || isFetchingPeople}
            hidesWhenStopped
            color="#0290C4"
            size={16}
          />
        </ArcBox>

        {hasFeaturePeopleV2 && shouldShowCreateButton && (
          <ArcButton
            className={classes.capitalize}
            label={type === 'members' ? 'Invite' : 'Create Team'}
            color="primary"
            onClick={
              type === 'members' ? onInvitePersonClick : onCreateTeamClick
            }
          >
            <ArcBox mr={0.5} mt={0.5}>
              <UsersAddOutline height={23} width={23} />
            </ArcBox>
            {type === 'members' ? 'Invite' : 'Create Team'}
          </ArcButton>
        )}
      </ArcBox>
    </ArcBox>
  );
}

PeopleListHeader.displayName = 'PeopleListHeader';

PeopleListHeader.propTypes = {
  onChangeSearch: PropTypes.func,
  onChangeSortBy: PropTypes.func,
  onCreateTeamClick: PropTypes.func,
  onInvitePersonClick: PropTypes.func,
  type: PropTypes.oneOf(['members', 'teams']),
};

PeopleListHeader.defaultProps = {
  onChangeSearch: global.noop,
  onChangeSortBy: global.noop,
  onCreateTeamClick: global.noop,
  onInvitePersonClick: global.noop,
  type: 'members',
};

export default PeopleListHeader;
