import { combineReducers } from 'redux';

import alert from './alert';
import appLoginStatusCode from './appLoginStatusCode';
import appReady from './appReady';
import canGoBack from './canGoBack';
import checklist from './checklist';
import hashTags from './hashTags';
import integrationWidgetStatus from './integrationWidgetStatus';
import isGeofenceRestricted from './isGeofenceRestricted';
import isMuted from './isMuted';
import leftMenuStatus from './leftMenuStatus';
import menu from './menu';
import onboardingTourOpen from './onboardingTourOpen';
import peopleById from './peopleById';
import passwordResetStatusCode from './passwordResetStatusCode';
import requestErrorMessage from './requestErrorMessage';
import requestStatus from './requestStatus';
import routeType from './routeType';
import teams from './teams';
import entities from './entities';
import teamsById from './teamsById';
import unreadMessages from './unreadMessages';
import unreadRewards from './unreadRewards';
import user from './user';
import sizzle from './sizzle';
import network from './network';

const core = combineReducers({
  alert,
  appLoginStatusCode,
  appReady,
  canGoBack,
  checklist,
  hashTags,
  integrationWidgetStatus,
  isGeofenceRestricted,
  isMuted,
  leftMenuStatus,
  menu,
  onboardingTourOpen,
  peopleById,
  teams,
  entities,
  teamsById,
  passwordResetStatusCode,
  requestErrorMessage,
  requestStatus,
  routeType,
  user,
  unreadMessages,
  unreadRewards,
  sizzle,
  network,
});

export default core;
