import { actions } from 'arcade-frontend-core/src/actions';

import store from '../../store';

/*
This machiner is reponsible for attaching the integration widget on demand and means that we can load it only 
for pages that actually require it instead of loading it at the top level app.

There is currently a bug with the HotGlue script that means that this approach does not work. 
The HotGlue team are working on resolving this but for now we have had to swap to a standard "index.html" 
inclusion and mount. Because of this the code contained here is currently useless.
*/

const handleOnLoad = () => {
  window.HotGlue.mount({
    api_key: 'fvoNn41wDH2V37gnkvjqs92RCzXjnPuma0590GSf',
    env_id: 'dev.hotglue.arcade.co',
  });

  store.dispatch(actions.setIntegrationWidgetLoaded(true));
};

const integrationWidget = null;

export const attachIntegrationWidget = () => {
  // if (integrationWidget) return;

  // if (document) {
  //   integrationWidget = document.createElement('script');
  //   integrationWidget.type = 'text/javascript';
  //   integrationWidget.id = 'hotglue-widget';
  //   integrationWidget.src = 'https://hotglue.xyz/widget.js';
  //   integrationWidget.onload = handleOnLoad;
  //   document.head.appendChild(integrationWidget);
  // }

  store.dispatch(actions.setIntegrationWidgetLoaded(true));
};

export const detachIntegrationWidget = () => {
  if (integrationWidget) {
    integrationWidget.remove();
  }
};
