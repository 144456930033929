import React from 'react';
import { connect } from 'react-redux';
import { NOT_FOUND } from 'redux-first-router';
import {
  ArcPropTypes,
  ArcConsole,
} from 'arcade-frontend-ui';

import { getRouteState } from '../reducers/location';
import store from '../store';

export const linkTo = (action) => {
  store.dispatch(action);
};

export const linkToRouteType = (type, payload, query) => {
  linkTo({
    type,
    payload,
    query,
  });
};

export const getRouteView = (platform, route, views) => {
  if (!views[platform]) {
    ArcConsole.warn('routes/getRouteView()', 'invalid platform', platform);
    return views[ArcPropTypes.PLATFORMS.DEVELOPMENT][NOT_FOUND];
  }

  if (!views[platform][route]) {
    return views[platform][NOT_FOUND];
  }

  return views[platform][route];
};

const createRouteViews = views => (
  class RouteViews extends React.PureComponent {
    static propTypes = {
      route: ArcPropTypes.string.isRequired,
      platform: ArcPropTypes.platform.isRequired,
    };

    render() {
      const {
        platform,
        route,
      } = this.props;

      const View = getRouteView(platform, route, views);

      return (<View />);
    }
  }
);

const mapStateToProps = (state, ownProps) => ({
  ...getRouteState(state),
  ...ownProps,
});

export const createRoutes = views => connect(mapStateToProps)(createRouteViews(views));
