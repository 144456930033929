import React from 'react';
import Clear from '@material-ui/icons/Clear';
import AddCircle from '@material-ui/icons/AddCircle';
import {
  ArcBadge,
  ArcPropTypes,
  ArcIconButton,
  ArcView,
  ArcText,
  ArcCardHeader,
  createWithStyles,
} from 'arcade-frontend-ui';

const styleMetricUnit = {
  fontSize: '16px',
  paddingRight: 4,
  color: '#666666',
};

const styleCloseButton = {
  position: 'absolute',
  zIndex: 100,
  top: 0,
  right: 0,
  width: 64,
  height: 64,
  fontSize: 40,
};

const styles = {
  MetricIconButton: theme => ({
    root: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 50,
      height: 50,
      fontSize: 26,
      color: theme.palette.icon.primary,

      [theme.breakpoints.up('sm')]: {
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  }),
};

const MetricIconButton = createWithStyles(styles.MetricIconButton)(
  ArcIconButton,
);

const formatNumberWithCommas = x =>
  parseFloat(x, 10)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

class MetricCardHeader extends React.PureComponent {
  static propTypes = {
    onClose: ArcPropTypes.func,
    onCreate: ArcPropTypes.func,

    lineOneHasBorder: ArcPropTypes.bool,
    progress: ArcPropTypes.number,
    name: ArcPropTypes.node,
    symbol: ArcPropTypes.string,
    verb: ArcPropTypes.node,
    canAddSales: ArcPropTypes.bool,
    isCalculated: ArcPropTypes.bool,
  };

  static defaultProps = {
    onClose: null,
    onCreate: null,

    lineOneHasBorder: true,
    progress: null,
    name: null,
    symbol: null,
    verb: null,
    canAddSales: true,
    isCalculated: false,
  };

  static displayName = 'MetricCardHeader';

  renderActionClose() {
    return (
      <ArcIconButton
        onClick={this.props.onClose}
        style={styleCloseButton}
        color="primary"
        disableRipple
      >
        <Clear color="disabled" fontSize="inherit" />
      </ArcIconButton>
    );
  }

  renderActionCreate() {
    return this.props.onCreate && this.props.canAddSales ? (
      <MetricIconButton onClick={this.props.onCreate}>
        <AddCircle color="inherit" fontSize="inherit" />
      </MetricIconButton>
    ) : null;
  }

  renderAction() {
    return this.props.onClose
      ? this.renderActionClose()
      : this.renderActionCreate();
  }

  renderBadge() {
    return this.props.isCalculated ? (
      <ArcBadge color="primary">Calculated</ArcBadge>
    ) : null;
  }

  render() {
    const { name, progress, symbol, verb } = this.props;

    return (
      <ArcView row justify="space-between">
        <ArcCardHeader
          lineOneHasBorder={this.props.lineOneHasBorder}
          lineOne={lineOneStyle => (
            <ArcView row align="center">
              <ArcText style={styleMetricUnit}>{symbol}</ArcText>
              <ArcText style={lineOneStyle}>
                {progress ? formatNumberWithCommas(progress) : 0}
              </ArcText>
            </ArcView>
          )}
          lineTwo={() => (
            <ArcText>
              {name} {verb}
              {this.renderBadge()}
            </ArcText>
          )}
        />
        {this.renderAction()}
      </ArcView>
    );
  }
}

export default MetricCardHeader;
