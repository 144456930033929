import createCachedSelector from 're-reselect';

import { createReducer } from 'arcade-frontend-ui';

import { selectors as activitySelectors } from './activities';
import { activitySetEncodedKey } from './utils';
import { types } from '../actions';

const initialState = {};

const handleNewsfeedLoadIndexSuccess = (state, action) => {
  const ids = state.main || [];
  const { activities } = action.payload.data;
  const newIds = Object.values(activities).map(({ id }) => id);
  return { ...state, main: [...new Set([...ids, ...newIds])] };
};

const handleSummaryActivitySuccess = (state, action) => {
  const { payload } = action.meta.requestAction;
  const activities = action.payload.data;
  const ids = activities.map(({ id }) => id);
  const key = activitySetEncodedKey(payload);
  return { ...state, [key]: ids };
};

const handlers = {
  [types.NEWSFEED_INDEX.SUCCESS]: handleNewsfeedLoadIndexSuccess,
  [types.NEWSFEED_GET_SUMMARY_ACTIVITIES.SUCCESS]: handleSummaryActivitySuccess,
};

export default createReducer(initialState, handlers);

export const getActivitySets = state => state.newsfeed.activitySets;

const activityIdsForSet = (state, set) => {
  const key = activitySetEncodedKey(set);
  return state.newsfeed.activitySets[key] || [];
};

export const filterBySet = (state, props) => activitySetEncodedKey(props);

const activitiesForSet = createCachedSelector(
  activityIdsForSet,
  activitySelectors.activitiesById,
  (idSet, activitiesById) =>
    !idSet
      ? null
      : idSet.reduce((hash, id) => {
          const activity = activitiesById[id];
          if (activity) {
            hash[id] = activity; // eslint-disable-line no-param-reassign
          }
          return hash;
        }, {}),
)(filterBySet);

export const selectors = { activityIdsForSet, activitiesForSet };
