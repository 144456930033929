/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import glamorous from 'glamorous';

import ArcPropTypes from '../../helpers/arc/propTypes';
import * as characters from './ArcInterlacedCharacters';

const CharacterContainer = glamorous.div('arcInterlacedCharacter', {}, props => props.style);
const getComponentByCharacter = character => characters[`Letter${character.toUpperCase()}`];

const styleCharacterContainer = {
  '& > svg': {
    width: '100%',
    maxWidth: '80px',
  },
};

const styleCharacterContainerSpace = {
  '& > svg': {
    width: '100%',
    maxWidth: '40px',
  },
};

class ArcInterlacedCharacter extends React.Component {
  static propTypes = {
    character: ArcPropTypes.string,
  }

  static defaultProps = {
    character: 'a',
  };

  renderCharacter = () => getComponentByCharacter(this.props.character);

  render() {
    const {
      character,
      ...rest
    } = this.props;
    const isSpace = character === ' ';
    const containerStyle = (isSpace) ? styleCharacterContainerSpace : styleCharacterContainer;
    const viewBoxWidth = (isSpace) ? 40 : 80;
    const Character = this.renderCharacter();
    return (
      <CharacterContainer
        {...rest}
        style={containerStyle}
      >
        <svg
          viewBox={`0 0 ${viewBoxWidth} 80`}
        >
          <Character />
        </svg>
      </CharacterContainer>
    );
  }
}

export default ArcInterlacedCharacter;
