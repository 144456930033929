import { react2angular } from 'react2angular';

import NewsfeedView from 'arcade-frontend-newsfeed/src/views/NewsfeedView';

import { createProvidedContainer } from './helpers';

const ProvidedNewsfeedView = createProvidedContainer(NewsfeedView);

export default react2angular(ProvidedNewsfeedView, [
  'scrollClassName',
]);
