import React from 'react';
// import ArcPropTypes from '../../helpers/arc/propTypes';
import { createWithStyles } from '../../styles';
import ArcView from '../../primitives/ArcView/ArcView';
// import ArcImage from '../../primitives/ArcImage/ArcImage';
// import percentToArcColor from '../../styles/colors/percentToArcColor';
import RunningTrack from '../../assets/RunningTrack';

const styles = {
  Container: () => ({
    root: {
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
    },
  }),

  TrackWrapper: () => ({
    root: {
      position: 'relative',
      marginTop: '-36px',
      marginBottom: '-4px',
      width: 275,
      height: 300,
      margin: '0 auto',
    },
  }),

  Sky: () => ({
    root: {
      position: 'relative',
      backgroundColor: '#DAF7FF',
      height: 150,
      left: '-10%',
      width: '120%',

      '& > .Cloud': {
        flexShrink: 0,
      },
    },
  }),

  Field: () => ({
    root: {
      position: 'relative',
      width: '100%',
      backgroundColor: '#AAE46C',
      // backgroundImage: 'linear-gradient(to top, #d4fc79 0%, #96e6a1 100%)',
      // borderTop: '2px solid rgba(0,0,0,0.54)',
    },
  }),
};
const Container = createWithStyles(styles.Container)(ArcView);
const Sky = createWithStyles(styles.Sky)(ArcView);
const Field = createWithStyles(styles.Field)(ArcView);
const TrackWrapper = createWithStyles(styles.TrackWrapper)(ArcView);

const ArcMountain = () => (
  <Container>
    <Sky />
    <Field>
      <TrackWrapper>
        <RunningTrack />
      </TrackWrapper>
    </Field>
  </Container>
);


export default ArcMountain;
