import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const AlertCircle = props => (
  <Icon {...props}>
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.986 2.002a10.171 10.171 0 00-7.09 3.044A9.832 9.832 0 002 12.174 9.815 9.815 0 0011.834 22h.178c5.565-.057 10.033-4.608 9.988-10.173a9.804 9.804 0 00-10.014-9.825zm-1.235 13.784a1.23 1.23 0 011.207-1.275h.022a1.272 1.272 0 011.27 1.225 1.229 1.229 0 01-1.208 1.275h-.022a1.274 1.274 0 01-1.27-1.225zm.416-8.368v5a.833.833 0 001.667 0v-5a.833.833 0 00-1.667 0z"
      clipRule="evenodd"
    />
  </Icon>
);

export default AlertCircle;
