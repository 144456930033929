import React from 'react';
import CountUp from 'react-countup';

import IconToggleStar from '@material-ui/icons/Star';
import IconActionThumbUp from '@material-ui/icons/ThumbUp';
import IconActionCardGiftcard from '@material-ui/icons/CardGiftcard';
import IconCommunicationChatBubble from '@material-ui/icons/ChatBubble';
import IconCommunicationMessage from '@material-ui/icons/Message';
import IconImageViewCompact from '@material-ui/icons/ViewCompact';

import {
  Sparklines,
  SparklinesCurve,
} from 'react-sparklines';

import {
  ArcPropTypes,
  ArcView,
} from 'arcade-frontend-ui';

import sampleData from './sampleData';
import AliSvg from './AliSvg';
import CarsonSvg from './CarsonSvg';
import DaveSvg from './DaveSvg';

const tileColors = {
  chat: {
    dark: '#0D74AD',
    main: '#00A3D6',
    light: '#02CDEB',
  },
  social: {
    dark: '#00967E',
    main: '#03C8A2',
    light: '#07D2B6',
  },
  reward: {
    dark: '#FF8F14',
    main: '#FFAE14',
    light: '#FFCA13',
  },
};

const styleEngagementTile = props => ({
  position: 'relative',
  margin: 8,
  backgroundColor: tileColors[props.type].dark,
  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',

  flexGrow: 1,
  flexBasis: 250,
  maxWidth: 400,
});

const styleHeader = {
  color: '#fff',
  padding: 24,
  fontWeight: 200,
  lineHeight: '1.5em',
};

const styleNumber = {
  fontSize: '2em',
  marginBottom: 8,
};

const sentimentsMap = {
  Joy: 'joyful',
  Anger: 'angry',
  Confusion: 'confusing',
  Complacent: 'complacent',
};

const styleIconTile = {
  color: '#fff',
  marginRight: 16,
  width: 32,
  height: 32,
};

const EngagementTile = props => (
  <ArcView style={styleEngagementTile(props)} justify="space-between">
    <ArcView style={styleHeader}>
      <ArcView row style={styleNumber} align="center">
        {props.Icon && (<props.Icon style={styleIconTile} />)}
        <CountUp
          start={0}
          end={props.total}
          duration={0.8}
          separator=","
        />
      </ArcView>
      {props.children}
    </ArcView>

    <Sparklines
      data={props.data}
      margin={0}
      min={3}
    >
      <SparklinesCurve
        color={tileColors[props.type].light}
        style={{
          fill: tileColors[props.type].light,
          fillOpacity: 0.54,
        }}
      />
    </Sparklines>
  </ArcView>
);

EngagementTile.propTypes = {
  children: ArcPropTypes.children,
  Icon: ArcPropTypes.children,
  type: ArcPropTypes.oneOf(Object.keys(tileColors)).isRequired,
  data: ArcPropTypes.arrayOf(ArcPropTypes.number).isRequired,
  total: ArcPropTypes.number.isRequired,
};

EngagementTile.defaultProps = {
  children: undefined,
  Icon: undefined,
};

const SocialEngagementTile = props => (props.sentiment ? (
  <EngagementTile type="social" {...props}>
    <div>
      <span>
        {props.title}
        {' '}
      </span>
      <strong>
        {props.dateRangeReadable}
        {' '}
      </strong>
      <span>with mostly </span>
      <strong>
        {sentimentsMap[props.sentiment]}
        {' '}
      </strong>
      <span>sentiments</span>
    </div>
  </EngagementTile>
) : (
  <EngagementTile type="social" {...props}>
    <div>
      <span>
        {props.title}
        {' '}
      </span>
      <strong>{props.dateRangeReadable}</strong>
    </div>
  </EngagementTile>
));

SocialEngagementTile.propTypes = {
  dateRangeReadable: ArcPropTypes.string.isRequired,
  sentiment: ArcPropTypes.string,
  title: ArcPropTypes.string.isRequired,
};

SocialEngagementTile.defaultProps = {
  sentiment: undefined,
};

const RewardEngagementTile = props => (
  <EngagementTile type="reward" {...props}>
    <div>
      <span>
        {props.title}
        {' '}
      </span>
      <strong>
        {props.dateRangeReadable}
        {' '}
      </strong>
      <span>with mostly </span>
      <strong>
        {props.reward}
        {' '}
      </strong>
      <span>being rewarded</span>
    </div>
  </EngagementTile>
);

RewardEngagementTile.propTypes = {
  dateRangeReadable: ArcPropTypes.string.isRequired,
  reward: ArcPropTypes.string.isRequired,
  title: ArcPropTypes.string.isRequired,
};

const styleCompanyStarsTile = {
  overflow: 'hidden',
  // ...styleEngagementTile({ type: 'reward' }),
  minHeight: 240,
  flexBasis: 300,
  flexGrow: 0,
  flexShrink: 0,
  margin: 8,
  paddingLeft: 8,
  paddingRight: 8,
  fontWeight: 200,
  lineHeight: '1.5em',
};

const stylePerson = {
  position: 'relative',
  zIndex: 1,
  width: 56,
  height: 56,
  borderRadius: 80,
};

const styleStarContainer = {
  height: '100%',
};

const styleStarBase = {
  position: 'relative',
};

const styleStarOne = {
  ...styleStarBase,
  height: '99%',
};

const styleStarTwo = {
  ...styleStarBase,
  height: '75%',
  marginLeft: 8,
  marginRight: 8,
};

const styleStarThree = {
  ...styleStarBase,
  height: '60%',
};

const styleIconStar = {
  position: 'absolute',
  top: 0,
  left: '50%',
  width: 82,
  height: 82,
  color: tileColors.reward.light,
  transform: 'translate3d(-50%, -16px, 0)',
};

const styleBar = {
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexGrow: 1,
  backgroundColor: tileColors.reward.main,
  padding: 4,
  color: '#fff',
  fontWeight: 'bold',
};

const styleStarSubheading = {
  fontSize: '0.85em',
};

const CompanyStarsTile = props => (
  <ArcView style={styleCompanyStarsTile} justify="flex-start">
    <ArcView align="flex-end">
      Top 3 Rising Stars
    </ArcView>

    <ArcView align="flex-end" style={styleStarSubheading}>
      <span>
Stars earned
        <strong>{props.dateRangeReadable}</strong>
      </span>
    </ArcView>

    <ArcView row justify="space-between" align="flex-end" style={styleStarContainer}>
      <ArcView style={styleStarOne}>
        <DaveSvg style={stylePerson} />
        <IconToggleStar style={styleIconStar} />
        <ArcView style={styleBar}>
          <ArcView>33</ArcView>
        </ArcView>
      </ArcView>

      <ArcView style={styleStarTwo}>
        <AliSvg style={stylePerson} />
        <IconToggleStar style={styleIconStar} />
        <ArcView style={styleBar}>
          <ArcView>22</ArcView>
        </ArcView>
      </ArcView>

      <ArcView style={styleStarThree}>
        <CarsonSvg style={stylePerson} />
        <IconToggleStar style={styleIconStar} />
        <ArcView style={styleBar}>
          <ArcView>11</ArcView>
        </ArcView>
      </ArcView>
    </ArcView>
  </ArcView>
);

CompanyStarsTile.propTypes = {
  dateRangeReadable: ArcPropTypes.string.isRequired,
};

const styleSubheading = {
  fontSize: '2em',
  marginTop: 32,
  marginBottom: 16,
  borderBottom: '4px solid #f3f3f3',
  paddingBottom: 16,
  color: 'rgba(0,0,0,0.54)',
  fontWeight: 200,
};

const EngagementTiles = props => (
  <ArcView>
    <ArcView style={styleSubheading}>Chat &amp; Social Engagement</ArcView>
    <ArcView row wrap="wrap">
      <SocialEngagementTile
        type="chat"
        sentiment="Complacent"
        title="Messages sent"
        Icon={IconCommunicationChatBubble}
        {...sampleData.messages[props.viewingType][props.dateType]}
        {...props}
      />

      <SocialEngagementTile
        sentiment="Joy"
        title="Activities created"
        Icon={IconImageViewCompact}
        {...sampleData.activities[props.viewingType][props.dateType]}
        {...props}
      />

      <SocialEngagementTile
        title="Activities liked"
        Icon={IconActionThumbUp}
        {...sampleData.liked[props.viewingType][props.dateType]}
        {...props}
      />

      <SocialEngagementTile
        sentiment="Joy"
        title="Comments made"
        Icon={IconCommunicationMessage}
        {...sampleData.comments[props.viewingType][props.dateType]}
        {...props}
      />
    </ArcView>

    <ArcView style={styleSubheading}>Rewards &amp; Recognition Engagement</ArcView>
    <ArcView row wrap="wrap">
      <RewardEngagementTile
        reward="tokens"
        title="Chests opened"
        Icon={IconActionCardGiftcard}
        {...sampleData.chests[props.viewingType][props.dateType]}
        {...props}
      />
      <CompanyStarsTile {...props} />
    </ArcView>
  </ArcView>
);

EngagementTiles.propTypes = {
  dateType: ArcPropTypes.string.isRequired,
  viewingType: ArcPropTypes.string.isRequired,
};


export default EngagementTiles;
