import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../actions/uploadManager';

const initialState = false;

const setTrue = () => true;
const setFalse = () => false;

const handlers = {
  [types.UPLOAD_MANAGER_OPENED]: setTrue,
  [types.UPLOAD_MANAGER_CANCELLED]: setFalse,
  [types.UPLOAD_MANAGER_FILE_SELECTED]: setFalse,
};

export default createReducer(initialState, handlers);

export const getOpen = state => state.uploadManager.open;
