import React from 'react';

import Badge from '@material-ui/core/Badge';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

import IconFilterList from '@material-ui/icons/FilterList';

import { snakeToTitle } from 'arcade-frontend-features/src/helpers/resources/transformResourceProps';

import ArcPropTypes from '../../helpers/arc/propTypes';
import createWithStyles from '../../styles/createWithStyles';
import ArcCheckbox from '../../elements/ArcCheckbox';
import ArcSelect from '../../elements/ArcSelect';

const styleBadge = {
  lineHeight: 'normal',
};

const styles = {
  CheckboxList: theme => ({
    root: {
      marginLeft: theme.spacing(2),
    },
  }),
};

const CheckboxList = createWithStyles(styles.CheckboxList)(FormGroup);

const LABELS_BY_CODE = {
  award: 'Awarded Tokens',
  buy_chest: 'Buy Chest',
  chest_to_holding: 'Chest Balance to Company Account',
  company_spend: 'Company Reward Purchased',
  decrease_chest_balance: 'Tokens Removed by Arcade',
  decrease_company_balance: 'Tokens Removed by Arcade',
  distribute: 'Tokens Allocated from Arcade',
  holding_to_chest: 'Company Account to Chest Balance',
  open_chest: 'Open Chest',
  top_up_chest: 'Tokens Awarded by Arcade',
};

class ArcTransactionLogFilterSelector extends React.Component {
  static propTypes = {
    onChange: ArcPropTypes.func.isRequired,
    transferTypes: ArcPropTypes.arrayOf(
      ArcPropTypes.shape({
        code: ArcPropTypes.string,
      }),
    ),
    filter: ArcPropTypes.arrayOf(ArcPropTypes.string),
  };

  static defaultProps = {
    transferTypes: [],
    filter: [],
  };

  state = {
    filter: this.props.filter,
  };

  toggleFilterItem = (show, code) => {
    const { onChange, filter } = this.props;

    if (show) {
      onChange([...filter, code]);
    } else {
      onChange(filter.filter(c => c !== code));
    }
  };

  renderValue = () => {
    const { filter, transferTypes } = this.props;
    return (
      <Badge
        badgeContent={
          filter.length === transferTypes.length ? 0 : filter.length
        }
        color="secondary"
        style={styleBadge}
      >
        <IconFilterList />
      </Badge>
    );
  };

  render() {
    const { transferTypes, filter } = this.props;
    const filterTypes = transferTypes.map(({ code }) => ({
      value: code,
      label: LABELS_BY_CODE[code] || snakeToTitle(code),
    }));

    return (
      <ArcSelect
        disabled={!filterTypes.length}
        value={filter}
        renderValue={this.renderValue}
        multiple
        variant="outlined"
        style={{
          height: '56px',
        }}
      >
        <CheckboxList>
          {transferTypes.map(({ code }) => (
            <FormControlLabel
              key={code}
              control={
                <ArcCheckbox
                  checked={filter.includes(code)}
                  onChange={e => this.toggleFilterItem(e.target.checked, code)}
                />
              }
              label={LABELS_BY_CODE[code] || snakeToTitle(code)}
            />
          ))}
        </CheckboxList>
      </ArcSelect>
    );
  }
}

export default ArcTransactionLogFilterSelector;
