import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  ArcPropTypes,
  ArcMain,
  ArcMainNav,
  ArcLoader,
} from 'arcade-frontend-ui';

import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
import { actions as actionsApp } from 'arcade-frontend-core/src/actions';
import TokenUserBudgetListView from 'arcade-frontend-tokens/src/views/TokenUserBudgetListView';
import BillingView from 'arcade-frontend-billing/src/views/BillingView';
import IntegrationHistoryView from 'arcade-frontend-integrations/src/views/IntegrationHistoryView';

import * as typesRoutes from 'arcade-frontend-core/src/types/routes';

import ManageGamesView from 'arcade-frontend-games/src/views/ManageGamesView';
import ManageNavContainer from '../containers/manage/ManageNavContainer';

import ManageChecklistsContainer from '../containers/manage/checklists/ManageChecklistsContainer';
import ManageIntegrationsContainer from '../containers/manage/integrations/ManageIntegrationsContainer';
import ManageMetricsContainer from '../containers/manage/metrics/ManageMetricsContainer';
import ManagePeopleContainer from '../containers/manage/people/ManagePeopleContainer';
import ManagePostsContainer from '../containers/manage/posts/ManagePostsContainer';
import ManageRewardsContainer from '../containers/manage/rewards/ManageRewardsContainer';
import ManageSalesContainer from '../containers/manage/sales/ManageSalesContainer';
import ManageTokensContainer from '../containers/manage/tokens/ManageTokensContainer';

import { getManageGames } from '../reducers/manage/games/manageGamesById';
import { getManageGamesTemplates } from '../reducers/manage/games/templates';
import { getManageMetrics } from '../reducers/manage/metrics/manageMetricsById';
import { getLocation } from '../reducers/location';

import ManageTeamsView from './ManageTeamsView';
import ManageTransactionsView from './ManageTransactionsView';

import {
  attachIntegrationWidget,
  detachIntegrationWidget,
} from '../helpers/integrationWidget/integrationWidget';

const Placeholder = () => <ArcViewBox />;

const manageRouteContainers = {
  [typesRoutes.ROUTE_MANAGE_ACTIVITIES]: ManageSalesContainer,
  [typesRoutes.ROUTE_MANAGE_BILLING2]: BillingView,
  [typesRoutes.ROUTE_MANAGE_CHECKLISTS]: ManageChecklistsContainer,
  [typesRoutes.ROUTE_MANAGE_GAMES]: ManageGamesView,
  [typesRoutes.ROUTE_MANAGE_INTEGRATIONS]: ManageIntegrationsContainer,
  [typesRoutes.ROUTE_MANAGE_INTEGRATION_HISTORY]: IntegrationHistoryView,
  [typesRoutes.ROUTE_MANAGE_METRICS]: ManageMetricsContainer,
  [typesRoutes.ROUTE_MANAGE_PEOPLE]: ManagePeopleContainer,
  [typesRoutes.ROUTE_MANAGE_POSTS]: ManagePostsContainer,
  [typesRoutes.ROUTE_MANAGE_REWARDS]: ManageRewardsContainer,
  [typesRoutes.ROUTE_MANAGE_TEAMS]: ManageTeamsView,
  [typesRoutes.ROUTE_MANAGE_TOKENS]: ManageTokensContainer,
  [typesRoutes.ROUTE_MANAGE_TOKEN_BUDGETS]: TokenUserBudgetListView,
  [typesRoutes.ROUTE_MANAGE_TRANSACTIONS]: ManageTransactionsView,
  DEFAULT: Placeholder,
};
const manageRoutes = Object.keys(manageRouteContainers);

class ManageView extends React.Component {
  static propTypes = {
    setIsViewingMain: ArcPropTypes.func.isRequired,
    location: ArcPropTypes.shape({
      type: ArcPropTypes.string,
    }).isRequired,

    manageGames: ArcPropTypes.ids.isRequired,
    manageMetrics: ArcPropTypes.ids.isRequired,

    isFetching: ArcPropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
  };

  static displayName = 'ManageView';

  constructor(props) {
    super(props);

    this.currentRoute = props.location.type;

    const routeMatch = manageRoutes.findIndex(
      route => this.currentRoute.indexOf(route) === 0,
    );
    const rootRoute = routeMatch > -1 ? manageRoutes[routeMatch] : 'DEFAULT';
    this.Container = manageRouteContainers[rootRoute];
    this.props.setIsViewingMain(rootRoute !== 'DEFAULT');
    attachIntegrationWidget();
  }

  state = {
    hasLoader: this.hasResources,
  };

  componentDidMount() {
    this.toggleBodyClass();
  }

  componentWillUnmount() {
    detachIntegrationWidget();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isFetching && !this.hasResources) {
      this.showLoader();
    } else if (this.state.hasLoader && !nextProps.isFetching) {
      this.hideLoader();
    }

    const { type } = nextProps.location;

    if (type !== this.props.location.type) {
      this.currentRoute = type;

      const routeMatch = manageRoutes.findIndex(
        route => this.currentRoute.indexOf(route) === 0,
      );
      const rootRoute = routeMatch > -1 ? manageRoutes[routeMatch] : 'DEFAULT';
      this.Container = manageRouteContainers[rootRoute];
      this.props.setIsViewingMain(rootRoute !== 'DEFAULT');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.type !== this.props.location.type) {
      this.toggleBodyClass();
    }
  }

  get isViewing() {
    return this.props.location.type !== typesRoutes.ROUTE_MANAGE;
  }

  get hasResources() {
    return (
      this.isViewing &&
      (!this.props.manageGames.length || !this.props.manageMetrics.length)
    );
  }

  toggleBodyClass() {
    if (window?.document) {
      window.document.body.classList.toggle(
        'two-pane--view-open',
        this.isViewing,
      );
    }
  }

  showLoader() {
    this.setState({ hasLoader: true });
  }

  hideLoader() {
    setTimeout(() => this.setState({ hasLoader: false }), 250);
  }

  render() {
    return (
      <ArcMain>
        <ArcMainNav isViewing={this.isViewing}>
          <ManageNavContainer />
        </ArcMainNav>

        <this.Container />
        <ArcLoader in={this.state.hasLoader} />
      </ArcMain>
    );
  }
}

const getState = state => ({
  currentUser: state.app.user.currentUser,
  location: getLocation(state),
  manageGames: getManageGames(state),
  manageMetrics: Object.keys(getManageMetrics(state)),
  templates: getManageGamesTemplates(state),
});

export const getActions = dispatch =>
  bindActionCreators(
    {
      setIsViewingMain: actionsApp.setIsViewingMain,
    },
    dispatch,
  );

export default connect(getState, getActions)(ManageView);
