import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import track from 'arcade-frontend-core/src/helpers/track';
import { routeManageTokens } from 'arcade-frontend-features/src/actions/manage/tokens/routes';
import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';

import { resources } from '../../resources/users';
import TokenUserBudgetList from '../../components/TokenUserBudgetList';

function TokenUserBudgetListContainer() {
  const dispatch = useDispatch();
  const apiGetUsersWithDistributePermission = useResourceAction(
    resources.apiGetUsersWithDistributePermission,
  );

  useEffect(() => {
    apiGetUsersWithDistributePermission.requestData();
  }, []);

  const handleRouteViewTokenHistory = selectedPerson => {
    track('navigate to route: /arcade/manage/tokens', {
      payload: { event: 'token-budgets:navigate' },
    });
    dispatch(routeManageTokens(selectedPerson));
  };

  const data = apiGetUsersWithDistributePermission.hasData
    ? apiGetUsersWithDistributePermission.data.resources
    : [];

  return (
    <TokenUserBudgetList
      data={data}
      userBudgetsRequestStatus={apiGetUsersWithDistributePermission.status}
      onViewTokenHistory={handleRouteViewTokenHistory}
      onBudgetLimitSubmit={apiGetUsersWithDistributePermission.requestData}
      onTokenBudgetCancel={apiGetUsersWithDistributePermission.requestData}
    />
  );
}

TokenUserBudgetListContainer.displayName = 'TokenUserBudgetListContainer';

export default TokenUserBudgetListContainer;
