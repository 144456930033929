import React from 'react';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';

import {
  userInput,
  getHashTags,
} from '../../helpers/utils/parsers';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import { createWithStyles } from '../../styles';

import ArcSuggestionCommand from './ArcSuggestionCommand';
import ArcSuggestionHashTag from './ArcSuggestionHashTag';
import ArcSuggestionTag from './ArcSuggestionTag';
import * as styles from './ArcUserInput.style';

import { taggableItem } from './taggableTypes';

const KEY = { TAB: 9, RETURN: 13, ESC: 27, UP: 38, DOWN: 40 };
const TYPE = { TAG: 'tag', COMMAND: 'command', HASH_TAG: 'hashtag' };

const commands = [
  { id: '0', display: '/roll' },
  { id: '1', display: '/invite' },
];

const initialState = {
  displayValue: '',
  modelValue: '',
  mentions: [],
  mentionIds: [],
  hashTags: [],
  isOpen: false,
};

const hashTag = PropTypes.shape({
  id: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
});

const ArcUserInputView = createWithStyles(styles.ArcUserInputView)(ArcView);

class ArcUserInput extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    taggableData: PropTypes.arrayOf(taggableItem),
    hashTags: PropTypes.arrayOf(hashTag),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onSelect: PropTypes.func,
    onSubmit: PropTypes.func,
    hasHashTags: PropTypes.bool,
    hasSaveButton: PropTypes.bool,
    autoComplete: PropTypes.oneOf(['off', 'on']),
    placeholder: PropTypes.string,
    value: PropTypes.string,
    submitOnEnter: PropTypes.bool,
    resetOnSubmit: PropTypes.bool,
    events: ArcPropTypes.emitter,
  };

  static defaultProps = {
    disabled: false,
    taggableData: [],
    onBlur: global.noop,
    onClick: global.noop,
    onChange: global.noop,
    onFocus: global.noop,
    onSelect: global.noop,
    onSubmit: global.noop,
    hashTags: [],
    hasHashTags: true,
    hasSaveButton: false,
    autoComplete: 'on',
    placeholder: undefined,
    value: '',
    submitOnEnter: true,
    resetOnSubmit: true,
    events: ArcPropTypes.nullEmitter,
  };

  state = (this.props.value ? { ...initialState, modelValue: this.props.value } : initialState);

  componentDidMount() {
    this.setCompositionHandlers();
    this.props.events.on('clear', this.clear);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        modelValue: nextProps.value,
      });
    }
  }

  componentWillUnmount() {
    this.props.events.off('clear', this.clear);

    if (this.dirtyRef) {
      this.dirtyRef.removeEventListener('compositionupdate', this.handleCompositionUpdate);
    }
  }

  get hasCommands() {
    return this.state.modelValue.length <= 1;
  }

  get allTaggableData() {
    return [this.everyonePerson, ...this.props.taggableData];
  }

  get numberOfTaggableUsers() {
    return this.props.taggableData.filter(entry => entry.type === 'person').length;
  }

  setCompositionHandlers = () => {
    if (!this.hasCompositionHandlers && this.refArcView && this.refArcView.querySelector) {
      this.dirtyRef = this.refArcView.querySelector('.ArcUserInput .ArcUserInput__input');
      this.dirtyRef.addEventListener('compositionupdate', this.handleCompositionUpdate);
      this.hasCompositionHandlers = true;
    }
  }

  clear = () => {
    this.setState(initialState);
  }

  everyonePerson = { id: '0', display: 'everyone', type: 'person', name: 'Everyone', teamName: '', imageUrl: '' };

  hasCompositionHandlers = false;

  filterMentionIds = mentions => (
    mentions.filter(mention => mention.type === TYPE.TAG)
      .map(mention => mention.id)
      .filter(mention => mention)
  );

  filterHashTags = mentions => (
    mentions.filter(mention => mention.type === TYPE.HASH_TAG)
      .map(mention => mention.display)
      .filter(mention => mention)
  );

  handleCompositionUpdate = (event) => {
    const { value } = event.target;
    const commandTags = [
      '@',
      '#',
    ];

    const lastInput = value[value.length - 1];
    const shouldTriggerCommand = commandTags.indexOf(lastInput) > -1;

    if (shouldTriggerCommand) {
      // Manually triggering compositionend for ReactMentionInput to show suggestions
      // https://stackoverflow.com/questions/2856513/how-can-i-trigger-an-onchange-event-manually
      if ('createEvent' in document) {
        const evt = document.createEvent('HTMLEvents');
        evt.initEvent('compositionend', true, true);
        event.target.dispatchEvent(evt);
      } else {
        event.target.fireEvent('oncompositionend');
      }
    }
  };

  handleChange = (event, modelValue, displayValue, mentions) => {
    const mentionIds = this.filterMentionIds(mentions).unique();
    const hashTags = this.filterHashTags(mentions).concat(getHashTags(displayValue)).unique();
    this.setState({
      displayValue,
      modelValue,
      mentions,
      mentionIds,
      hashTags,
    });

    this.props.onChange(event, modelValue, displayValue, mentionIds, hashTags);
  };

  handleSubmit = (event) => {
    const {
      modelValue,
      displayValue,
      mentionIds,
      hashTags,
      mentions,
    } = this.state;

    const parsedHtml = userInput(modelValue);
    this.props.onSubmit(event, modelValue, displayValue, mentions, parsedHtml, mentionIds, hashTags);

    if (this.props.resetOnSubmit) {
      this.setState({ ...initialState });
    }
  };

  handleKeyDown = (event) => {
    switch (event.keyCode) {
      case KEY.RETURN:
        if (!event.shiftKey && this.props.submitOnEnter) {
          this.handleSubmit(event);
          return event.preventDefault();
        }

        if (event.metaKey) {
          this.handleSubmit(event);
          return event.preventDefault();
        }

        return true;
      default:
        return true;
    }
  };

  displayTransform = (id, display, type) => {
    switch (type) {
      case TYPE.TAG:
        return `@${display}`;
      case TYPE.HASH_TAG:
        return display;
      case TYPE.COMMAND:
      default:
        return display;
    }
  };

  renderSuggestionTag = entry => (
    <ArcSuggestionTag
      isSelected={this.state.mentionIds.indexOf(entry.id) > -1}
      entry={entry}
      totalUsers={this.numberOfTaggableUsers}
    />
  );

  renderSuggestionCommand = entry => (
    <ArcSuggestionCommand
      id={entry.id}
      name={entry.display}
    />
  );

  renderSuggestionHashTag = entry => (
    <ArcSuggestionHashTag
      id={entry.id}
      name={entry.display}
    />
  );

  renderTags() {
    return (
      <Mention
        className="ArcUserInput__List"
        trigger="@"
        type="tag"
        data={this.allTaggableData}
        renderSuggestion={this.renderSuggestionTag}
        appendSpaceOnAdd
      />
    );
  }

  renderHashTags() {
    return (
      <Mention
        className="ArcUserInput__List"
        trigger="#"
        type="hashtag"
        data={this.props.hashTags}
        renderSuggestion={this.renderSuggestionHashTag}
        appendSpaceOnAdd
      />
    );
  }

  renderCommands() {
    return (
      <Mention
        className="ArcUserInput__List"
        trigger="/"
        type="command"
        data={commands}
        renderSuggestion={this.renderSuggestionCommand}
        appendSpaceOnAdd
      />
    );
  }

  render() {
    return (
      <ArcUserInputView
        internalRef={(ref) => { this.refArcView = ref; }}
        hasSaveButton={this.props.hasSaveButton}
      >
        <MentionsInput
          onSelect={this.props.onSelect}
          onBlur={this.props.onBlur}
          onClick={this.props.onClick}
          onFocus={this.props.onFocus}
          className="ArcUserInput"
          value={this.state.modelValue}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          displayTransform={this.displayTransform}
          allowSpaceInQuery
          markup="@[__display__][__type__:__id__]"
          title={this.props.placeholder}
          placeholder={this.props.placeholder}
          autoComplete={this.props.autoComplete}
          autoCorrect="on"
          autoCapitalize="sentences"
          spellCheck="false"
          disabled={this.props.disabled}
        >

          {this.renderTags()}
          {this.props.hasHashTags ? this.renderHashTags() : undefined}

        </MentionsInput>
      </ArcUserInputView>
    );
  }
}

export default ArcUserInput;
