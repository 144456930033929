import React from 'react';

import {
  Circle,
  G,
  Rect,
} from 'react-primitives-svg';

import Icon from '../Icon';

const Remove = props => (
  <Icon {...props}>
    <G fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <G transform="translate(1 1)" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <Circle cx="11" cy="11" r="11" />
        <Rect x="4" y="9" width="14" height="4" rx="1" />
      </G>
    </G>
  </Icon>
);

export default Remove;
