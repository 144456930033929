import React from 'react';

import {
  ArcMain,
  ArcMainView,
  ArcScroll,
} from 'arcade-frontend-ui';

import PeopleContainer from 'arcade-frontend-people/src/containers/PeopleContainer';

const PeopleView = () => (
  <ArcMain>
    <ArcMainView
      isViewing
      hasNav={false}
    >
      <ArcScroll>
        <PeopleContainer />
      </ArcScroll>
    </ArcMainView>
  </ArcMain>
);

export default PeopleView;
