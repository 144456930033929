/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { createWithStyles } from '../../styles';
import styles from './dataTableStyles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import EmojiEvents from '@material-ui/icons/EmojiEventsOutlined';

import ArcView from '../../primitives/ArcView';
import ArcText from '../../primitives/ArcText';

import ArcCellContent from './ArcCellContent';
import ArcDataTableCell from './ArcDataTableCell';
import ArcDataTableRow from './ArcDataTableRow';
import ArcNoResultsMessage from '../ArcNoResultsMessage';
import ArcResourceSpinner from '../ArcResourceSpinner';

const DataTableBody = createWithStyles(styles.DataTableBody)(TableBody);
const DataTable = createWithStyles(styles.DataTable)(Table);

const ArcResponsiveDataTable = ({ columns, rowCount, getRecord, noResultsMessage, isFetching }) => {
  const renderers = {};
  columns.forEach(({ key, render }) => {
    renderers[key] = render || ((_, record) => <ArcCellContent>{record[key]}</ArcCellContent>);
  });
  const field = (key, row) => renderers[key](key, getRecord(key, row));

  if (isFetching) {
    return (
      <ArcView align="center" justify="center" fillToParent>
        <ArcResourceSpinner size={64} status={{ pending: true }} />
      </ArcView>
    );
  }

  if (!rowCount && !isFetching) {
    return (
        <ArcNoResultsMessage
        iconComponent={EmojiEvents}
        header={noResultsMessage.header}
        subheader={noResultsMessage.subheader}
        />
    );
  }

  return (
    <DataTable>
      <TableHead>
        <TableRow>
          {columns.map(({ label, key }) =>
            <ArcDataTableCell key={key} headerCell>{label}</ArcDataTableCell>,
          )}
        </TableRow>
      </TableHead>
      <DataTableBody>
        {[...Array(rowCount)].map((_, row) =>
          (<ArcDataTableRow key={row.toString()} shade={row % 2 === 0}>
            {columns.map(({ key }) =>
              <ArcDataTableCell key={key}>{field(key, row, key)}</ArcDataTableCell>,
            )}
          </ArcDataTableRow>),
        )}
      </DataTableBody>
    </DataTable>
  );
};

ArcResponsiveDataTable.displayName = 'ArcResponsiveDataTable';

ArcResponsiveDataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  rowCount: PropTypes.number.isRequired,
  getRecord: PropTypes.func.isRequired,
  noResultsMessage: PropTypes.shape({
    header: PropTypes.string,
    subheader: PropTypes.string
  }),
  isFetching: PropTypes.bool,
};

ArcResponsiveDataTable.defaultProps = {
  noResultsMessage: {
    header: 'No games found',
    subheader: undefined
  },
  isFetching: false,
};

export default ArcResponsiveDataTable;
