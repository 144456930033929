import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const handleSet = (state, action) => action.payload.status || null;
const handleReset = () => null;

const initialState = null;

const actions = {
  [types.APP_LOGIN.SUCCESS]: handleSet,
  [types.APP_LOGIN.FAILURE]: handleSet,
  [types.APP_LOGIN.REQUEST]: handleReset,
};

const appLoginStatusCode = createReducer(initialState, actions);

export default appLoginStatusCode;

export const getAppLoginStatusCode = state => state.app.appLoginStatusCode;
