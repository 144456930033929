import React from 'react';

const SummitFlag = props => (
  <svg
    width="46"
    height="67"
    viewBox="0 0 46 67"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <g fill="none" fillRule="evenodd"><path fill="#FFD43D" d="M1.917 1.914H5.75v3.829H1.917zM1.917 5.743H5.75v3.829H1.917zM5.75 1.914h3.833v3.829H5.75zM5.75 5.743h3.833v3.829H5.75z" /><path d="M46 17.229H30.667v30.629H46z" fill="#00AB8B" /><path fill="#00967E" d="M32.583 44.029H34.5v1.914h-1.917z" /><path fill="#00967E" d="M30.667 44.029h1.917v1.914h-1.917z" /><path fill="#00967E" d="M30.667 45.943h1.917v1.914h-1.917z" /><path fill="#FFD43D" d="M7.667 3.829H11.5v3.829H7.667zM3.833 0h3.833v3.829H3.833zM0 3.829h3.833v3.829H0z" /><path fill="#FFAE14" d="M0 3.829h1.917v3.829H0z" /><path fill="#FFCA13" d="M1.917 5.743h1.917v1.914H1.917zM3.833 9.571H5.75v1.914H3.833zM5.75 9.571h1.917v1.914H5.75zM3.833 7.657H5.75v1.914H3.833z" /><path fill="#FFAE14" d="M1.917 7.657h1.917v1.914H1.917z" /><path fill="#FFD43D" d="M3.833 13.4h3.833V67H3.833z" /><path fill="#FFAE14" d="M3.833 44.029H5.75V67H3.833zM3.833 11.486h3.833v3.829H3.833z" /><path d="M34.5 13.4H3.833v30.629H34.5z" fill="#03C8A2" /><path fill="#00AB8B" d="M3.833 13.4H5.75v7.657H3.833zM3.833 28.714H5.75v7.657H3.833z" /><path fill="#00AB8B" d="M3.833 21.057H5.75v7.657H3.833zM3.833 36.371H5.75v7.657H3.833z" />
    </g>
  </svg>
);

SummitFlag.propTypes = {
};

SummitFlag.defaultProps = {
};

export default SummitFlag;
