import React, { FC, useState } from 'react'
import useSWR from 'swr';
import { arcadeApiClient } from 'arcade-frontend-core/src/helpers/arcadeApiClient';

import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import { ArcWindowedSelect } from 'arcade-frontend-ui/src/components/ArcWindowedSelect'
import ArcView from 'arcade-frontend-ui/src/primitives/ArcView'

import { Person, PeopleResponseItem } from './types'
import { styles } from './styles'

const fetcher = normalizer => url => arcadeApiClient.get(url).then(res => res.data?.users?.map?.(normalizer));

const normalizePeople = (person: PeopleResponseItem): Person => ({
  id: person?.id?.toString(),
  imageUrl: person?.profileImage,
  label: person?.name,
  value: person?.id?.toString(),
});

export interface PeopleFilterContainerProps {
  onSelect: (options: Person[]) => void
}

export const PeopleFilterContainer: FC<PeopleFilterContainerProps> = ({
  onSelect,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const hasPeopleFilter = useFeatureFlag(FEATURE_FLAGS.PEOPLE_FILTER);

  const { data: peopleOptions, error } = useSWR(`/v5/people?search=${searchValue}`, fetcher(normalizePeople));

  const isLoading = !peopleOptions && !error;

  const handleChange = options => {
    setSelectedOptions(options);
    onSelect(options);
  }

  const handleInputChange = value => {
    setSearchValue(value);
  }

  return (
    hasPeopleFilter && <ArcView>
      <ArcWindowedSelect
        isLoading={isLoading}
        options={peopleOptions}
        onChange={handleChange}
        onInputChange={handleInputChange}
        value={selectedOptions}
        styles={styles}
      />
    </ArcView>
  )
}
