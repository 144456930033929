import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as routeTypes from 'arcade-frontend-core/src/types/routes';

import ManageChecklistsCreateContainer from './ManageChecklistsCreateContainer';
import ManageChecklistsViewContainer from './ManageChecklistsViewContainer';

class ManageChecklistsContainer extends React.PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      type: PropTypes.string,
    }).isRequired,
  };

  renderView() {
    switch (this.props.location.type) {
      case routeTypes.ROUTE_MANAGE_CHECKLISTS_CREATE:
        return <ManageChecklistsCreateContainer />;
      case routeTypes.ROUTE_MANAGE_CHECKLISTS:
      case routeTypes.ROUTE_MANAGE_CHECKLISTS_SHOW:
      default:
        return <ManageChecklistsViewContainer />;
    }
  }

  render() {
    return this.renderView();
  }
}

const mapStateToProps = state => ({
  location: state.location,
});

export default connect(mapStateToProps)(ManageChecklistsContainer);
