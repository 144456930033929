import React from 'react';

import ArcPropTypes from '../arc/propTypes';

import ArcView from '../../primitives/ArcView';

const styleShow = {
  display: 'flex',
};

const styleHide = {
  display: 'none',
};

const withHiddenUnmount = (Component) => {
  const HiddenUnmount = ({ isMounted, ...rest }) => (
    <ArcView style={isMounted ? styleShow : styleHide}>
      <Component {...rest} />
    </ArcView>
  );

  HiddenUnmount.propTypes = {
    isMounted: ArcPropTypes.bool.isRequired,
  };

  return HiddenUnmount;
};

export default withHiddenUnmount;
