import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import {
  ArcView,
  ArcText,
  ArcLink,
  ArcButton,
  ArcBadge,
  ArcDataTableRow,
  ArcDataTableCell,
  date,
} from 'arcade-frontend-ui';

const statusColors = {
  successful: 'secondary',
  reverted: 'warning',
  error: 'danger',
};

const IntegrationHistoryListRow = ({
  run,
  shade,
  onWithdrawRequest,
  onShowSalesRequest,
  onShowMessages,
}) => {
  const {
    id,
    sheetName,
    salesCount,
    createdAt,
    status,
    integrationErrorsCount,
    integrationWarningsCount,
  } = run;

  const statusLabel =
    status === 'successful' && integrationErrorsCount > 0 ? 'error' : status;
  const statusColor = statusColors[statusLabel];

  return (
    <ArcDataTableRow shade={shade}>
      <ArcDataTableCell>
        <ArcView flexGrow={1}>
          <ArcView>
            <ArcText isLarge>{sheetName}</ArcText>
          </ArcView>
          <ArcView>
            <ArcText>
              {date.simpleLocaleDateTime({ datetime: createdAt })}
            </ArcText>
          </ArcView>
        </ArcView>
      </ArcDataTableCell>
      <ArcDataTableCell>
        <ArcView row>
          <ArcBadge color={statusColor}>{statusLabel}</ArcBadge>
        </ArcView>
      </ArcDataTableCell>
      <ArcDataTableCell>
        <ArcLink onPress={() => onShowSalesRequest(id)}>{salesCount}</ArcLink>
      </ArcDataTableCell>
      <ArcDataTableCell>
        <ArcView>
          {integrationErrorsCount > 0 && (
            <ArcText>
              <ArcLink onPress={() => onShowMessages({ type: 'error', run })}>
                {integrationErrorsCount}{' '}
                {pluralize('error', integrationErrorsCount)}
              </ArcLink>
            </ArcText>
          )}
          {integrationWarningsCount > 0 && (
            <ArcText>
              <ArcLink onPress={() => onShowMessages({ type: 'warning', run })}>
                {integrationWarningsCount}{' '}
                {pluralize('warning', integrationWarningsCount)}
              </ArcLink>
            </ArcText>
          )}
        </ArcView>
      </ArcDataTableCell>
      <ArcDataTableCell align="right">
        {status === 'successful' && (
          <ArcButton
            variant="contained"
            color="red"
            onClick={() => onWithdrawRequest(id)}
          >
            Withdraw
          </ArcButton>
        )}
      </ArcDataTableCell>
    </ArcDataTableRow>
  );
};

IntegrationHistoryListRow.propTypes = {
  run: PropTypes.shape({
    id: PropTypes.string,
    sheetName: PropTypes.string,
    createdAt: PropTypes.string,
    salesCount: PropTypes.number,
    status: PropTypes.string,
    integrationErrorsCount: PropTypes.number,
    integrationWarningsCount: PropTypes.number,
  }).isRequired,
  shade: PropTypes.bool,
  onWithdrawRequest: PropTypes.func.isRequired,
  onShowSalesRequest: PropTypes.func.isRequired,
  onShowMessages: PropTypes.func.isRequired,
};

IntegrationHistoryListRow.defaultProps = {
  shade: false,
};

export default IntegrationHistoryListRow;
