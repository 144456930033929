import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../../actions/manage/games/types';

const initialState = {
  ids: [],
};

const setVerifiedGameId = (state, action) => {
  const { payload } = action;
  const { id } = payload;
  return { ids: [...state.ids, id] };
};

const handlers = {
  [types.MANAGE_GAMES_SET_VERIFIED_GAME]: setVerifiedGameId,
};

const verifiedGameIds = createReducer(initialState, handlers);

export default verifiedGameIds;

export const getVerifiedGameIds = state =>
  state.manage.games.verifiedGameIds.ids;
