/* eslint-disable no-unused-vars */

import React from 'react';
// import ArcPropTypes from '../../helpers/arc/propTypes';
import { createWithStyles } from '../../styles';
import ArcView from '../../primitives/ArcView/ArcView';
import PlayerPortrait from '../PlayerPortrait';
import InitialVine from '../../assets/InitialVine';
import RepeatingVine from '../../assets/RepeatingVine';
import { displaysScoresType } from '../PositionedPlayers';
import { distribute } from '../../helpers/utils/clustering';
import ArcClouds from '../ArcClouds/ArcClouds';

const styles = {
  Container: () => ({
    root: {
      position: 'relative',
      width: '100%',
      height: 410,
      overflow: 'hidden',
    },
  }),

  VineWrapper: () => ({
    root: {
      position: 'absolute',
      bottom: 100,
    },

    slot0: {
      left: '10%',
      bottom: 120,
      opacity: 0.7,
    },

    slot1: {
      left: '30%',
    },

    slot2: {
      left: '50%',
    },

    slot3: {
      left: '70%',
    },
  }),

  Sky: () => ({
    root: {
      backgroundColor: '#DAF7FF',
      width: '100%',
      height: '100%',
    },
  }),

  Field: () => ({
    root: {
      backgroundColor: '#AAE46C',
      height: 120,
      width: '100%',
      position: 'absolute',
      bottom: 0,
    },
  }),
  Leaves: () => ({
    root: {
      height: 24,
      width: 51,
      background: 'url(https://d17rkelr6jjme9.cloudfront.net/app/games/repeating-vine.svg)',
    },
  }),
  Stem: () => ({
    root: {
      height: 12,
      width: 51,
      background: 'url(https://d17rkelr6jjme9.cloudfront.net/app/games/initial-vine.svg) bottom center',
    },
  }),
  PlayerPosition: () => ({
    root: {
      position: 'absolute',
      left: 26,
      top: -18,
    },
  }),
  FirstPlace: () => ({
    root: {},
  }),
  NoScore: () => ({
    root: {
      position: 'absolute',
      top: '40%',
      left: '40%',
    },
  }),
};

const VineWrapper = createWithStyles(styles.VineWrapper)(ArcView);
const Container = createWithStyles(styles.Container)(ArcView);
const Sky = createWithStyles(styles.Sky)(ArcView);
const Field = createWithStyles(styles.Field)(ArcView);
const Leaves = createWithStyles(styles.Leaves)(ArcView);
const Stem = createWithStyles(styles.Stem)(ArcView);
const PlayerPosition = createWithStyles(styles.PlayerPosition)(ArcView);
const FirstPlace = createWithStyles(styles.FirstPlace)(ArcView);
const NoScore = createWithStyles(styles.NoScore)(ArcView);

const ArcVine = ({ scores, currentUser }) => {
  const rankings = scores.map(s => ({ score: s.score, l: s }));
  const distribution = distribute(rankings, i => i.id === currentUser.toString());
  // console.log(distribution);

  const { slot1, slot2, slot3, outlier, max } = distribution;
  const slots = [slot1, slot2, slot3];

  return (
    <Container>
      <Sky>
        <ArcClouds />
        <Field />
        {/* { max === 0 &&
          <NoScore>Be the first to score!</NoScore>
        } */}
        {/* { !!outlier &&
          <VineWrapper key="0" slot={0}>
            <FirstPlace>1st Place</FirstPlace>
            <Leaves style={{ height: 24 * 8 }} />
            <InitialVine />
          </VineWrapper>
        } */}
        { slots.map((slot, i) => !slot.missing && (
          <VineWrapper key={i.toString()} slot={i + 1}>
            <PlayerPosition>
              <PlayerPortrait
                isSelf={slot.item.is_self}
                image={slot.item.image}
                title={slot.item.catch_up || 'You\'re doing great!'}
              />
            </PlayerPosition>
            <Stem />
            <Leaves style={{ height: 24 * Math.round(8 * slot.fraction) }} />
            <InitialVine />
          </VineWrapper>
        ))}
      </Sky>
    </Container>
  );
};

ArcVine.propTypes = displaysScoresType;

export default ArcVine;
