import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcIconButton from 'arcade-frontend-ui/src/elements/ArcIconButton';
import RefreshOutline from 'arcade-frontend-ui/src/icons/RefreshOutline';

function GamesRefreshButton({ variant, onRefresh }) {
  switch (variant) {
    case 'button':
      return (
        <ArcBox
          component={ArcIconButton}
          onClick={onRefresh}
          ml={1}
          color={'#666'}
          style={{ borderRadius: 50 }}
        >
          <RefreshOutline fontSize="medium" />
        </ArcBox>
      );
    case 'menuItem':
      return (
        <ArcBox component={MenuItem} onClick={onRefresh}>
          <ArcBox>{'Refresh'}</ArcBox>
        </ArcBox>
      );
    default:
      return null;
  }
}

GamesRefreshButton.displayName = 'GamesRefreshButton';

GamesRefreshButton.propTypes = {
  onRefresh: PropTypes.func,
  variant: PropTypes.oneOf(['button', 'menuItem']),
};

GamesRefreshButton.defaultProps = {
  onRefresh: global.noop,
  variant: 'button',
};

export default GamesRefreshButton;
