import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import {
  ArcConfirmDialog,
  ArcViewHeader,
  ArcView,
  createWithStyles,
} from 'arcade-frontend-ui';

import { pluralize } from 'arcade-frontend-core/src/helpers/pluralize';

import { ArcFormDateRangePickerContainer } from 'arcade-frontend-widgets/src/containers/ArcFormDateRangePickerContainer';
import { PeopleFilterContainer } from '../PeopleFilterContainer';

import { actions, routes } from '../../../actions/manage/metrics';

import {
  actions as actionsMetrics,
  routes as routesMetrics,
} from '../../../actions/metrics';

import {
  getManageMetricsActive,
  getManageMetricsArchived,
} from '../../../reducers/manage/metrics/manageMetricsById';

import { getIsFetchingArchived } from '../../../reducers/manage/metrics/isFetchingArchived';
import ManageMetricCard from '../../../components/manage/metrics/ManageMetricCard';

const styles = {
  MetricArcViewHeader: theme => ({
    root: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },

    isArchived: {
      alignItems: 'center',
      padding: '0 16px',
      marginBottom: 16,

      [theme.breakpoints.up('sm')]: {
        marginBottom: 32,
      },
    },

    isViewing: {
      marginBottom: 0,

      [theme.breakpoints.up('sm')]: {
        marginBottom: 0,
      },
    },
  }),

  LoadingWrapper: {
    root: {
      width: 64,
      margin: '64px auto',
    },
  },
};

const MetricArcViewHeader = createWithStyles(styles.MetricArcViewHeader)(
  ArcViewHeader,
);
const LoadingWrapper = createWithStyles(styles.LoadingWrapper)(ArcView);

class ManageMetricsViewIndexContainer extends React.PureComponent {
  static propTypes = {
    apiManageMetricsArchiveRequest: PropTypes.func.isRequired,
    apiManageMetricsRestoreRequest: PropTypes.func.isRequired,
    routeManageMetricsCreate: PropTypes.func.isRequired,
    routeManageMetricsEdit: PropTypes.func.isRequired,
    routeManageMetricsShow: PropTypes.func.isRequired,
    routeManageMetrics: PropTypes.func.isRequired,

    manageMetricsActive: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        name: PropTypes.string,
        verb: PropTypes.string,
        progress: PropTypes.number,
        symbol: PropTypes.string,
        archived: PropTypes.bool,
      }),
    ).isRequired,
    manageMetricsArchived: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        name: PropTypes.string,
        verb: PropTypes.string,
        progress: PropTypes.number,
        symbol: PropTypes.string,
        archived: PropTypes.bool,
      }),
    ).isRequired,
    onDateRangeChange: PropTypes.func,
    onPeopleChange: PropTypes.func,
    isFetchingArchived: PropTypes.bool,
    metricType: PropTypes.string,
    dateRange: PropTypes.shape({
      type: PropTypes.oneOf(['time period', 'date range']),
      timePeriod: PropTypes.string,
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
    }),
    setMetricType: PropTypes.func,
  };

  static defaultProps = {
    metricType: 'active',
    onDateRangeChange: global.noop,
    onPeopleChange: global.noop,
    setMetricType: global.noop,
    dateRange: undefined,
    isFetchingArchived: false,
  };

  state = {
    isViewingAlert: false,
    isArchivingMetric: true,
    metricId: null,
    alertContent: '',
  };

  get hasArchivedMetrics() {
    const { manageMetricsArchived } = this.props;

    return !!manageMetricsArchived && !!manageMetricsArchived.length;
  }

  get hasActiveMetrics() {
    const { manageMetricsActive } = this.props;
    return !!manageMetricsActive && !!manageMetricsActive.length;
  }

  handleClickNav = (e, id, type) => this.props.routeManageMetricsShow(id, type);

  handleClickEdit = (e, id) => this.props.routeManageMetricsEdit(id);

  getMetricById = id => this.currentMetrics.find(metric => metric.id === id);

  handleArchive = (e, id) => {
    const { title, verb } = this.getMetricById(id);

    this.setState({
      isViewingAlert: true,
      isArchivingMetric: true,
      metricId: id,
      alertContent: `You are about to archive ${title} ${verb}`,
    });
  };

  handleRestore = (e, id) => {
    const { title, verb } = this.getMetricById(id);

    this.setState({
      isViewingAlert: true,
      isArchivingMetric: false,
      metricId: id,
      alertContent: `You are about to restore ${title} ${verb}`,
    });
  };

  handleAlertConfirm = () => {
    if (this.state.isArchivingMetric) {
      this.props.apiManageMetricsArchiveRequest(this.state.metricId);
    } else {
      this.props.apiManageMetricsRestoreRequest(this.state.metricId);
    }

    this.setState({
      isViewingAlert: false,
    });
  };

  handleAlertDismiss = () => {
    this.setState({
      isViewingAlert: false,
    });
  };

  handleMetricTypeChange = (event, newType) => {
    this.props.setMetricType(newType);
  };

  get currentMetrics() {
    const { metricType, manageMetricsActive, manageMetricsArchived } =
      this.props;

    return metricType === 'active'
      ? manageMetricsActive
      : manageMetricsArchived;
  }

  renderHeader() {
    const { length } = this.currentMetrics;
    const { metricType, isFetchingArchived } = this.props;

    let heading = '';
    if (!isFetchingArchived) {
      heading = length
        ? `${length} ${pluralize(length, 'Metric')}`
        : `No ${metricType} metrics.`;
    }

    return (
      <ArcView>
        <MetricArcViewHeader heading={heading}>
          <ArcBox
            display="flex"
            flexDirection="row"
            flexGrow={1}
            justifyContent="flex-end"
            mr={1}
          >
            <ToggleButtonGroup
              value={this.props.metricType}
              exclusive
              onChange={this.handleMetricTypeChange}
            >
              <ToggleButton value="active" aria-label="active">
                Active
              </ToggleButton>
              <ToggleButton value="archived" aria-label="archived">
                Archived
              </ToggleButton>
            </ToggleButtonGroup>
          </ArcBox>

          <ArcBox display="inline-flex" mr={1}>
            <PeopleFilterContainer onSelect={this.props.onPeopleChange} />
          </ArcBox>

          <ArcBox display="inline-flex">
            <ArcFormDateRangePickerContainer
              value={this.props.dateRange}
              onChange={this.props.onDateRangeChange}
            />
          </ArcBox>
        </MetricArcViewHeader>
      </ArcView>
    );
  }

  renderActiveMetrics() {
    return (
      <ArcView row wrap="wrap">
        {this.props.manageMetricsActive.map(metric => (
          <ManageMetricCard
            key={metric.id}
            metricId={metric.id}
            onClickNav={this.handleClickNav}
            onEdit={this.handleClickEdit}
            onArchive={this.handleArchive}
            name={metric.title}
            {...metric}
          />
        ))}
      </ArcView>
    );
  }

  renderArchivedMetrics() {
    return (
      <ArcView row wrap="wrap">
        {this.props.manageMetricsArchived.map(metric => (
          <ManageMetricCard
            key={metric.id}
            metricId={metric.id}
            onClickNav={this.handleClickNav}
            hasEdit={false}
            onArchive={this.handleArchive}
            onRestore={this.handleRestore}
            name={metric.title}
            {...metric}
          />
        ))}
      </ArcView>
    );
  }

  renderAlert() {
    return (
      <ArcConfirmDialog
        open={this.state.isViewingAlert}
        title="Are you sure?"
        content={this.state.alertContent}
        onConfirm={this.handleAlertConfirm}
        onClose={this.handleAlertDismiss}
        confirmButtonColor={this.state.isArchivingMetric ? 'danger' : 'success'}
        confirmLabel={this.state.isArchivingMetric ? 'Archive' : 'Restore'}
      />
    );
  }

  renderMetrics() {
    const { metricType } = this.props;

    return metricType === 'active'
      ? this.renderActiveMetrics()
      : this.renderArchivedMetrics();
  }

  render() {
    const { isFetchingArchived } = this.props;

    return (
      <ArcView>
        {this.renderHeader()}

        {isFetchingArchived ? (
          <LoadingWrapper>
            <CircularProgress size={64} />
          </LoadingWrapper>
        ) : (
          this.renderMetrics()
        )}

        {this.renderAlert()}
      </ArcView>
    );
  }
}

const mapStateToProps = state => ({
  manageMetricsActive: getManageMetricsActive(state),
  manageMetricsArchived: getManageMetricsArchived(state),
  isFetchingArchived: getIsFetchingArchived(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
      ...routes,
      ...actionsMetrics,
      ...routesMetrics,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageMetricsViewIndexContainer);
