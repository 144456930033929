import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import usePermission from 'arcade-frontend-core/src/hooks/usePermission';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LockIcon from '@material-ui/icons/Lock';
import TokenAmountCell from 'arcade-frontend-games-v2/src/components/TokenAmountCell';

import {
  ArcEntity,
  ArcDataTableCell,
  ArcDataTableRow,
  ArcIconButton,
  ArcView,
} from 'arcade-frontend-ui';

import usePersonActions from '../../hooks/usePersonActions';
import PersonDetailsActionMenuItems from '../PersonDetailsActionMenuItems';

const useStyles = makeStyles(theme => ({
  containerWrapper: {
    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      margin: 0,
      borderRadius: 0,
    },
  },
  fixedWidthDataTableCell: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      width: '25%',
    },
    [theme.breakpoints.down('md')]: {
      width: '35%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '60%',
    },
  },
  pullRight: {
    marginLeft: 'auto',
  },

  lockIcon: {
    color: theme.palette.grey[500],
    padding: theme.spacing(1.5),
    marginLeft: 'auto',
  },
}));

function PeopleTable({
  currentUser,
  people,
  canAwardChests,
  canAwardTokens,
  onUpdateProfileSuccess,
  onUpdateTeamSuccess,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedPerson, setSelectedPerson] = React.useState(null);
  const isManager = usePermission(PERMISSIONS.MANAGE_GAMES);
  const canManageRewards = usePermission(PERMISSIONS.MANAGE_REWARDS);
  const hasTokenBalance = useFeatureFlag(FEATURE_FLAGS.TOKEN_BALANCE);
  const { navigateProfile } = usePersonActions();

  const handleClickRow = (event, personId) => {
    if (currentUser === personId) {
      return;
    }
    navigateProfile(event, personId);
  };

  const handleClickThreeDotMenu = (event, person) => {
    setSelectedPerson(person);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderActionsItems = person => {
    if (person.locked) {
      return (
        <ArcView className={classes.lockIcon}>
          <LockIcon />
        </ArcView>
      );
    }

    return (
      <ArcIconButton
        className={classes.pullRight}
        onClick={event => handleClickThreeDotMenu(event, person)}
      >
        <MoreVertIcon />
      </ArcIconButton>
    );
  };

  return (
    <>
      <ArcView className={classes.containerWrapper}>
        <Table>
          <TableBody>
            {people.map((person, index) => (
              <ArcDataTableRow
                data-testid="PeopleTable-Row"
                key={person.id}
                shade={index % 2 === 1}
              >
                <ArcDataTableCell
                  slim
                  className={classes.fixedWidthDataTableCell}
                >
                  <ArcEntity
                    style={{ color: '#23282C' }}
                    type="person"
                    name={person.name}
                    imageUrl={person.image}
                    onClick={event => handleClickRow(event, person.id)}
                  />
                </ArcDataTableCell>
                <ArcDataTableCell slim>{person.team}</ArcDataTableCell>
                {isManager && canManageRewards && hasTokenBalance && (
                  <ArcDataTableCell slim>
                    <TokenAmountCell count={person.tokens} />
                  </ArcDataTableCell>
                )}
                <ArcDataTableCell slim>
                  <ArcView className={classes.pullRight}>
                    {currentUser.id !== person.id && renderActionsItems(person)}
                  </ArcView>
                </ArcDataTableCell>
              </ArcDataTableRow>
            ))}
          </TableBody>
        </Table>
      </ArcView>

      {selectedPerson && selectedPerson.id && (
        <PersonDetailsActionMenuItems
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          canAwardChests={canAwardChests}
          canAwardTokens={canAwardTokens}
          id={selectedPerson.id}
          name={selectedPerson.name}
          team={selectedPerson.team}
          image={selectedPerson.image}
          primaryTeamId={selectedPerson.primaryTeamId}
          secondaryTeams={selectedPerson.secondaryTeams}
          onUpdateProfileSuccess={onUpdateProfileSuccess}
          onUpdateTeamSuccess={onUpdateTeamSuccess}
        />
      )}
    </>
  );
}

PeopleTable.displayName = 'PeopleTable';

PeopleTable.propTypes = {
  canAwardChests: PropTypes.bool,
  canAwardTokens: PropTypes.bool,
  currentUser: PropTypes.shape({ id: PropTypes.string }),
  people: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      image: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      team: PropTypes.string,
    }),
  ),
  onUpdateProfileSuccess: PropTypes.func,
  onUpdateTeamSuccess: PropTypes.func,
};

PeopleTable.defaultProps = {
  canAwardChests: false,
  canAwardTokens: false,
  currentUser: {},
  people: [],
  onUpdateProfileSuccess: global.noop,
  onUpdateTeamSuccess: global.noop,
};

export default PeopleTable;
