import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions';

const handleActivityHistoryDetailsSuccess = (state, action) => {
  const { data } = action.payload;

  return data;
};

const initialState = {};

const handlers = {
  [types.ACTIVITY_HISTORY_DETAILS.SUCCESS]: handleActivityHistoryDetailsSuccess,
};

const activityHistoryDetails = createReducer(initialState, handlers);

export default activityHistoryDetails;

export const getActivityHistoryDetails = state =>
  state.newsfeed.activityHistoryDetails;
