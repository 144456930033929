import React from 'react';

import { Svg, G, Path } from 'react-primitives-svg';

import Icon from '../../icons/Icon';

const Inbox = props => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="104" height="65" fill="none">
    <Path
      fill="#D8D8D8"
      d="M12.8 3L3 22.5h29.4C32.4 33.27 41.175 42 52 42c10.825 0 19.6-8.73 19.6-19.5H101L91.2 3H12.8z"
    />
    <Path
      fill="#B4B4B4"
      d="M91.2 3H52v39c10.825 0 19.6-8.73 19.6-19.5H101L91.2 3z"
    />
    <Path
      fill="#EBEBEB"
      d="M103.999 22.665a2.992 2.992 0 00-.055-.557l-.009-.051a2.494 2.494 0 00-.061-.237c-.008-.03-.018-.06-.027-.089a2.868 2.868 0 00-.072-.195l-.035-.083c-.02-.045-.04-.09-.062-.134l-9.79-19.63A3.046 3.046 0 0091.162 0H12.838a3.046 3.046 0 00-2.725 1.689L.322 21.319a3.697 3.697 0 00-.169.412l-.027.089a3.07 3.07 0 00-.061.237l-.01.051a3.103 3.103 0 00-.054.557l-.001.02v39.26A3.05 3.05 0 003.047 65h97.906A3.05 3.05 0 00104 61.946V22.684l-.001-.02zM14.721 6.11H89.28l6.743 13.521H71.582a3.05 3.05 0 00-3.046 3.055c0 9.14-7.418 16.575-16.535 16.575s-16.534-7.436-16.534-16.575a3.05 3.05 0 00-3.047-3.055H7.977L14.721 6.11z"
    />
    <Path
      fill="#D8D8D8"
      d="M103.999 22.665a3.419 3.419 0 00-.021-.333 2.862 2.862 0 00-.034-.224l-.009-.051a2.837 2.837 0 00-.088-.326 3.524 3.524 0 00-.072-.195l-.035-.083a3.37 3.37 0 00-.062-.134l-9.79-19.63A3.046 3.046 0 0091.161 0H52v6.109h37.28l6.743 13.521H71.581a3.05 3.05 0 00-3.047 3.055c0 9.14-7.417 16.576-16.534 16.576V65h48.953A3.05 3.05 0 00104 61.946V22.684l-.001-.02z"
    />
  </Svg>
);

export default Inbox;
