import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import track from 'arcade-frontend-core/src/helpers/track';

import {
  ArcMainView,
  ArcGrid,
  ArcScroll,
  ArcTokenBalanceCard,
  ArcTokenTopUpDialog,
  ArcTokenTransferDialog,
  ArcView,
  ArcViewTitleBar,
} from 'arcade-frontend-ui';

import RewardsTransactionsIndexContainer from 'arcade-frontend-rewards/src/containers/RewardsTransactionsIndexContainer';
import { RewardsTransactionsIndexContainerV2 } from 'arcade-frontend-rewards/src/containers/RewardsTransactionsIndexContainerV2';

import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import {
  getCurrentUserPermission,
  getCurrentUser,
  getCurrentUserFeatures,
} from 'arcade-frontend-core/src/reducers/user';

import { MANAGE_TOKENS_V2 } from 'arcade-frontend-core/src/types/feature-flags';
import { routes as routesManage } from '../../../actions/manage';
import { actions } from '../../../actions/manage/tokens';

import { getAccounts } from '../../../reducers/manage/tokens/accounts';
import { getIsFetching } from '../../../reducers/manage/tokens/isFetching';
import { getRequestStatus } from '../../../reducers/manage/tokens/requestStatus';
import { getLocationPayload } from '../../../reducers/location';
import { getHasToppedUp } from '../../../reducers/manage/tokens/hasToppedUp';
import { getTopUpError } from '../../../reducers/manage/tokens/topUpError';
import { getTopUpResponse } from '../../../reducers/manage/tokens/topUpResponse';
import { getTopUpUrl } from '../../../reducers/manage/tokens/topUpUrl';

const PLACEHOLDERS = [
  {
    key: 0,
    name: 'Loading...',
    balance: 1000,
    isFetching: true,
  },
  {
    key: 1,
    name: 'Loading...',
    balance: 500000,
    isFetching: true,
  },
  {
    key: 2,
    name: 'Loading...',
    balance: 16500,
    isFetching: true,
  },
];

class ManageTokensContainer extends React.PureComponent {
  static propTypes = {
    accounts: PropTypes.arrayOf(
      PropTypes.shape({
        balance: PropTypes.number.isRequired,
        key: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    apiManageTokensIndexRequest: PropTypes.func.isRequired,
    apiManageTokensTopUpRequest: PropTypes.func.isRequired,
    apiManageTokensTransferRequest: PropTypes.func.isRequired,
    canRequestTokenTopup: PropTypes.bool,
    canTransferTokens: PropTypes.bool,
    currentUser: PropTypes.shape({
      id: PropTypes.string,
    }),
    hasToppedUp: PropTypes.bool,
    hasManageTokensV2: PropTypes.bool,
    isFetching: PropTypes.bool,
    manageTokensSetHasToppedUp: PropTypes.func.isRequired,
    manageTokensSetTopUpError: PropTypes.func.isRequired,
    manageTokensSetTopUpResponse: PropTypes.func.isRequired,
    manageTokensSetTopUpUrl: PropTypes.func.isRequired,
    requestStatus: PropTypes.objectOf(
      PropTypes.oneOf(['DEFAULT', 'REQUEST', 'SUCCESS', 'FAILURE']),
    ).isRequired,
    routeManage: PropTypes.func.isRequired,
    topUpError: PropTypes.string.isRequired,
    topUpResponse: PropTypes.string.isRequired,
    topUpUrl: PropTypes.string.isRequired,
    locationPayload: PropTypes.shape({
      id: PropTypes.number,
    }),
  };

  static defaultProps = {
    canRequestTokenTopup: false,
    canTransferTokens: false,
    currentUser: {},
    hasToppedUp: true,
    hasManageTokensV2: false,
    isFetching: true,
    locationPayload: {},
  };

  static displayName = 'ManageTokensContainer';

  state = {
    accountName: this.props.accounts[0] ? this.props.accounts[0].key : '',
    isEditing: false,
    isToppingUp: false,
    editingAccount: {},
  };

  constructor(props) {
    super(props);
    this.props.apiManageTokensIndexRequest();
  }

  componentWillReceiveProps(nextProps) {
    const { requestStatus } = this.props;

    if (nextProps.requestStatus !== requestStatus) {
      if (
        nextProps.requestStatus.MANAGE_TOKENS_TRANSFER === 'DEFAULT' &&
        requestStatus.MANAGE_TOKENS_TRANSFER !== 'DEFAULT'
      ) {
        this.setIsEditing(false);
      }
    }

    if (nextProps.accounts !== this.props.accounts) {
      if (nextProps.accounts[0] && !this.state.accountKey) {
        this.setAccountKey(nextProps.accounts[0].key);
      }
    }
  }

  setAccountKey = accountKey => this.setState({ accountKey });

  setEditingAccount = editingAccount => this.setState({ editingAccount });

  setIsEditing = isEditing => this.setState({ isEditing });

  setIsToppingUp = isToppingUp => this.setState({ isToppingUp });

  setUser = user => this.setState({ user });

  closeTopUp() {
    this.setIsToppingUp(false);
    this.props.manageTokensSetHasToppedUp(false);
    this.props.manageTokensSetTopUpError('');
    this.props.manageTokensSetTopUpResponse('');
    this.props.manageTokensSetTopUpUrl('');
  }

  clickHandlers = {};

  createHandleClick = account => {
    if (!this.clickHandlers[account.key]) {
      this.clickHandlers[account.key] = () => {
        this.setAccountKey(account.key);
      };
    }

    return this.clickHandlers[account.key];
  };

  transferHandlers = {};

  createHandleTransfer = account => {
    if (!this.transferHandlers[account.key]) {
      this.transferHandlers[account.key] = () => {
        this.setIsEditing(true);
        this.setEditingAccount(account);
      };
    }

    return this.transferHandlers[account.key];
  };

  handleChangeAccountName = accountName => this.setAccountKey(accountName);

  handleCloseDialog = () => this.setIsEditing(false);

  handleCloseTopUp = () => {
    this.closeTopUp();
  };

  handleTopUp = () => this.setIsToppingUp(true);

  handleTopUpContinue = () => {
    if (global?.window) {
      window.open(this.props.topUpUrl);
    }

    this.closeTopUp();
  };

  handleSubmitTopUp = values => {
    track('submitted top up request', {
      amount: values.amount,
    });
    this.props.apiManageTokensTopUpRequest(values);
  };

  handleSubmitTransfer = values => {
    track('submitted token transfer', {
      amount: values.amount,
      fromAccount: values.fromAccountKey,
      toAccount: values.toAccountKey,
    });

    this.props.apiManageTokensTransferRequest(values);
  };

  renderAccount = account => (
    <ArcGrid key={account.key} item xs={12} sm={12} md="auto">
      <ArcTokenBalanceCard
        account={account.key}
        accountName={account.name}
        balance={account.balance}
        color={account.key === this.state.accountKey ? 'primary' : 'default'}
        canRequestTokenTopup={this.props.canRequestTokenTopup}
        canTransferTokens={this.props.canTransferTokens}
        onTransfer={this.createHandleTransfer(account)}
        onTopUp={this.handleTopUp}
        isFetching={account.isFetching}
        canBeToppedUp={account.canBeToppedUp}
        isTransferable={account.transferable}
        onClick={this.createHandleClick(account)}
      />
    </ArcGrid>
  );

  renderPlaceholders() {
    return PLACEHOLDERS.map(this.renderAccount);
  }

  renderAccounts() {
    const { accounts } = this.props;

    if (this.props.isFetching && !accounts.length) {
      return this.renderPlaceholders();
    }

    return accounts.map(this.renderAccount);
  }

  render() {
    return (
      <ArcMainView
        isViewing
        hasScroll={false}
        hasNoPaddingLeftLaunch
        position="relative"
      >
        <ArcViewTitleBar
          onClickBack={this.props.routeManage}
          title="Manage Tokens"
        />
        <ArcScroll color="paper">
          <ArcView padding="8">
            <ArcGrid container spacing={1}>
              {this.renderAccounts()}
            </ArcGrid>
          </ArcView>

          <ArcView>
            {this.props.hasManageTokensV2 ? (
              <RewardsTransactionsIndexContainerV2
                accountName={this.state.accountKey}
                accounts={this.props.accounts}
                user={this.props.locationPayload}
              />
            ) : (
              <RewardsTransactionsIndexContainer
                accountName={this.state.accountKey}
                accounts={this.props.accounts}
                user={this.props.locationPayload}
              />
            )}
          </ArcView>

          <ArcTokenTopUpDialog
            open={this.state.isToppingUp}
            hasToppedUp={this.props.hasToppedUp}
            onClose={this.handleCloseTopUp}
            onSubmit={this.handleSubmitTopUp}
            requestStatus={this.props.requestStatus.MANAGE_TOKENS_TOP_UP}
            topUpError={this.props.topUpError}
            topUpResponse={this.props.topUpResponse}
            topUpUrl={this.props.topUpUrl}
            onTopUpContinue={this.handleTopUpContinue}
          />

          <ArcTokenTransferDialog
            accounts={this.props.accounts}
            open={this.state.isEditing}
            onClose={this.handleCloseDialog}
            onSubmit={this.handleSubmitTransfer}
            fromAccountKey={this.state.editingAccount.key}
            isFetching={this.props.isFetching}
            requestStatus={this.props.requestStatus.MANAGE_TOKENS_TRANSFER}
          />
        </ArcScroll>
      </ArcMainView>
    );
  }
}

const getState = (state, props) => ({
  accounts: getAccounts(state),
  currentUser: getCurrentUser(state),
  requestStatus: getRequestStatus(state),
  hasToppedUp: getHasToppedUp(state),
  hasManageTokensV2: getCurrentUserFeatures(state, MANAGE_TOKENS_V2),
  isFetching: getIsFetching(state),
  locationPayload: getLocationPayload(state),
  canRequestTokenTopup: getCurrentUserPermission(
    state,
    PERMISSIONS.REQUEST_TOPUP,
  ),
  canTransferTokens: getCurrentUserPermission(
    state,
    PERMISSIONS.TRANSFER_TOKENS,
  ),
  topUpError: getTopUpError(state),
  topUpResponse: getTopUpResponse(state),
  topUpUrl: getTopUpUrl(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      apiManageTokensTopUpRequest: actions.apiManageTokensTopUpRequest,
      apiManageTokensTransferRequest: actions.apiManageTokensTransferRequest,
      apiManageTokensIndexRequest: actions.apiManageTokensIndexRequest,
      manageTokensSetTopUpUrl: actions.manageTokensSetTopUpUrl,
      manageTokensSetHasToppedUp: actions.manageTokensSetHasToppedUp,
      manageTokensSetTopUpError: actions.manageTokensSetTopUpError,
      manageTokensSetTopUpResponse: actions.manageTokensSetTopUpResponse,
      routeManage: routesManage.routeManage,
    },
    dispatch,
  );

export default connect(getState, getActions)(ManageTokensContainer);
