import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const handleGameEventsLoadSuccess = (state, action) => {
  const { data, id } = action.payload;

  const newState = { ...state };

  newState[id] = data;

  return newState;
};

const initialState = {};

const handlers = {
  [types.GAMES_EVENTS_INDEX.SUCCESS]: handleGameEventsLoadSuccess,
};

const eventsByGameId = createReducer(initialState, handlers);

export default eventsByGameId;
