import React from 'react';

import { Circle, G } from 'react-primitives-svg';

import Icon from '../Icon';

const Tokens3 = props => (
  <Icon {...props}>
    <G fill="transparent">
      <Circle cx="9.5" cy="9.5" r="9" stroke="currentColor" />
      <Circle cx="9.5" cy="9.5" r="6" stroke="currentColor" />
    </G>
  </Icon>
);

export default Tokens3;
