import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import {
  ArcIconButton,
  ArcResponsiveDialog,
  ArcText,
  ArcView,
} from 'arcade-frontend-ui';

const RewardHistoryViewRewardDialog = ({ title, open, onClose }) => (
  <ArcResponsiveDialog onClose={onClose} open={open}>
    <DialogTitle>
      <ArcView row align="center">
        <ArcText>{title}</ArcText>
        <ArcView spacer />
        <ArcIconButton onClick={onClose} size="small">
          <CloseIcon />
        </ArcIconButton>
      </ArcView>
    </DialogTitle>
    <ArcView style={{ padding: '16px 24px' }}>
      <ArcText>[ Reward stuffs ]</ArcText>
    </ArcView>
  </ArcResponsiveDialog>
);

RewardHistoryViewRewardDialog.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  open: PropTypes.bool,
};

RewardHistoryViewRewardDialog.defaultProps = {
  onClose: global.noop,
  title: 'How to Redeem',
  open: false,
};

export default RewardHistoryViewRewardDialog;
