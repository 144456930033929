import { combineReducers } from 'redux';

import peoplePermissions from './peoplePermissions';
import currentProfile from './currentProfile';
import isFetching from './isFetching';
import requestErrorMessage from './requestErrorMessage';
import requestStatus from './requestStatus';
import peopleById from './peopleById';

const people = combineReducers({
  peoplePermissions,
  currentProfile,
  isFetching,
  requestErrorMessage,
  requestStatus,
  peopleById,
});

export default people;
