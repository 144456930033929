import React from 'react';

import { G, Path } from 'react-primitives-svg';
import Icon from '../Icon';

const ClockOutline = props => (
  <Icon {...props}>
    <G
      stroke="currentColor"
      strokeWidth=".854"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <Path d="M12.25 22.074c5.425 0 9.823-4.398 9.823-9.823 0-5.425-4.398-9.823-9.823-9.823-5.425 0-9.823 4.398-9.823 9.823 0 5.425 4.398 9.823 9.823 9.823zM17.375 17.376l-4.222-4.221" />
      <Path d="M12.25 13.532a1.281 1.281 0 100-2.562 1.281 1.281 0 000 2.562zM12.25 7.553v3.417" />
    </G>
  </Icon>
);

export default ClockOutline;
