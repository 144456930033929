import React from 'react';

import arcUserReducer, { Action, ArcUserState } from '../arc-user-reducer';

export const ArcUserStateContext = React.createContext<ArcUserState>(null);

export const ArcUserDispatchContext = React.createContext<
  React.Dispatch<Action>
>(() => null);

export interface ArcUserProviderProps {
  children: React.ReactNode;
  initialArcUser?: ArcUserState;
}

export default function ArcUserProvider({
  children,
  initialArcUser = null,
}: ArcUserProviderProps): React.ReactNode {
  const [state, dispatch] = React.useReducer(arcUserReducer, initialArcUser);

  return (
    <ArcUserStateContext.Provider value={state}>
      <ArcUserDispatchContext.Provider value={dispatch}>
        {children}
      </ArcUserDispatchContext.Provider>
    </ArcUserStateContext.Provider>
  );
}

ArcUserProvider.defaultProps = {
  initialArcUser: null,
};
