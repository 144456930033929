import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useArcUser } from 'arcade-frontend-auth';
import { actions } from 'arcade-frontend-core/src/actions';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import { getAppReady } from 'arcade-frontend-core/src/reducers/appReady';
import DevelopmentPlatform from 'arcade-frontend-features/src/platforms/DevelopmentPlatform';
import { getLocation } from 'arcade-frontend-features/src/reducers/location';
import { getUnreadMessages } from 'arcade-frontend-core/src/reducers/unreadMessages';
import startApp from 'arcade-frontend-features/src/start-app';
import usePrevious from 'arcade-frontend-core/src/hooks/usePrevious';
import removeBootLogo from 'arcade-frontend-core/src/helpers/removeBootLogo';
import { ChakraProvider } from '@workplacearcade/ui';

import WebLoadingLogo from './components/WebLoadingLogo';

const INITIAL_REQUESTS_DELAY = 3000;

function WebAppContainer() {
  const { arcUser, login, logout } = useArcUser();

  const dispatch = useDispatch();
  const appReady = useSelector(getAppReady);
  const currentUser = useSelector(getCurrentUser);
  const isViewingMain = useSelector(state => state.app.user.isViewingMain);
  const location = useSelector(getLocation);
  const unreadMessages = useSelector(getUnreadMessages);
  const prevArcUser = usePrevious(arcUser);
  const [appError, setAppError] = React.useState(null);

  React.useEffect(() => {
    startApp().catch(error => {
      removeBootLogo();
      setAppError(error);
      if (window?.arcPostMessage) {
        window.arcPostMessage('native.appReady');
      }
    });
  }, []);

  React.useEffect(() => {
    if (currentUser && currentUser.id) {
      login(currentUser);
    } else {
      logout();
    }
  }, [currentUser, login, logout]);

  React.useEffect(() => {
    if (!arcUser || (prevArcUser && arcUser && prevArcUser.id !== arcUser.id)) {
      return undefined;
    }

    const timeout = setTimeout(() => {
      dispatch(actions.appTaggablePeopleIndexRequest());
      dispatch(actions.appTaggableTeamsIndexRequest());
      dispatch(actions.appTaggableEntitiesIndexRequest());
      dispatch(actions.appHashTagsIndexRequest());
      dispatch(actions.prepareWebpushCredentials());
    }, INITIAL_REQUESTS_DELAY);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [arcUser, dispatch, prevArcUser]);

  return (
    <ChakraProvider>
      {appReady && (
        <DevelopmentPlatform
          currentUser={currentUser}
          networkState={false}
          location={location}
          isViewingMain={isViewingMain}
          unreadMessages={unreadMessages}
          platform="development"
        />
      )}
      {!!appError && <WebLoadingLogo hasError visible />}
    </ChakraProvider>
  );
}

WebAppContainer.displayName = 'WebAppContainer';

export default WebAppContainer;
