import getTransformedResponseFromResourceAction from '../../helpers/getTransformedResponseFromResourceAction';

import * as resources from './chatAudiences.resources';

import apiGetChatEntitiesRaw1 from './raw/apiGetChatEntitiesRaw-1.json';

export const apiGetChatEntitiesRaw = apiGetChatEntitiesRaw1;

export const apiGetChatEntities = getTransformedResponseFromResourceAction(
  resources.apiGetChatEntities,
  apiGetChatEntitiesRaw,
);
