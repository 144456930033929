import React from 'react';

import IconDateRange from '@material-ui/icons/DateRange';

import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';
import ArcDateRange from '../../elements/ArcDateRange';
import ArcDialog from '../../elements/ArcDialog';
import ArcSelect from '../../elements/ArcSelect';
import ArcProptypes from '../../helpers/arc/propTypes';

import * as date from '../../helpers/utils/date';

const dateTypeItems = {
  today: {
    value: 'today',
    label: 'Today',
  },
  yesterday: {
    value: 'yesterday',
    label: 'Yesterday',
  },
  pastWeek: {
    value: 'pastWeek',
    label: 'Past 7 Days',
  },

  currentWeek: {
    value: 'currentWeek',
    label: 'Current Week',
  },

  currentMonth: {
    value: 'currentMonth',
    label: 'Current Month',
  },

  dateRange: {
    value: 'dateRange',
    label: 'Date Range',
  },
};

const dateRangeSelectors = {
  currentMonth: date.beginningOfMonth,
  currentWeek: date.beginningOfWeek,
  pastWeek: date.oneWeekAgo,
  yesterday: date.removeOneDay,
  today: date.startOfDay,
  selectedDay: date.startOfDay,
};

const getDateRange = (range, fromDate) => dateRangeSelectors[range](fromDate);

class ArcTransactionLogDateSelector extends React.PureComponent {
  static propTypes = {
    className: ArcProptypes.string,
    onSubmit: ArcProptypes.func,
    onChange: ArcProptypes.func,
  };

  static defaultProps = {
    className: '',
    onSubmit: ArcProptypes.noop,
    onChange: ArcProptypes.noop,
  };

  state = {
    viewingType: null,
    dateType: 'currentMonth',
    dateRange: {
      from: null,
      to: null,
    },
  };

  getDateRange = (dateType) => {
    let from = date.startOfDay(this.todayDate);
    let to = date.endOfDay(this.todayDate);

    switch (dateType) {
      case 'dateRange':
        from = date.startOfDay(this.state.dateRange.from);
        to = date.endOfDay(this.state.dateRange.to);
        break;
      case 'selectedDay':
        from = date.startOfDay(this.state.selectedDay);
        to = date.endOfDay(this.state.selectedDay);
        break;
      case 'yesterday':
        from = date.startOfDay(this.yesterdayDate);
        to = date.endOfDay(this.yesterdayDate);
        break;
      default:
        from = getDateRange(dateType, this.todayDate);
        from = date.startOfDay(from);
        break;
    }

    return { from, to, dateType };
  };

  getValueAsText(value) {
    const { dateType, dateRange, selectedDay } = this.state;

    const todayReadable = date.formatDate(this.todayDate);
    const yesterdayReadable = date.formatDate(this.yesterdayDate);

    switch (dateType) {
      case 'today':
        return `${dateTypeItems[value].label} — ${todayReadable}`;
      case 'yesterday':
        return `${dateTypeItems[value].label} — ${yesterdayReadable}`;
      case 'dateRange':
        if (dateRange.from && dateRange.to) {
          return `${date.formatDate(dateRange.from)} to ${date.formatDate(
            dateRange.to,
          )}`;
        }

        return dateTypeItems[value].label;
      case 'currentMonth':
        return `${date.formatDate(
          date.beginningOfMonth(this.todayDate),
        )} to ${todayReadable}`;
      case 'currentWeek':
        return `${date.formatDate(
          date.beginningOfWeek(this.todayDate),
        )} to ${todayReadable}`;
      case 'pastWeek':
        return `${date.formatDate(
          date.oneWeekAgo(this.todayDate),
        )} to ${todayReadable}`;
      case 'selectedDay':
        if (selectedDay) {
          return `Selected — ${date.formatDate(selectedDay)}`;
        }

        return dateTypeItems[value].label;
      default:
        return dateTypeItems[value].label;
    }
  }

  setDateRange = dateRange => this.setState({ dateRange });

  setDateType = dateType => this.setState({ dateType });

  setViewingType = (viewingType) => {
    const shouldSubmit = viewingType !== 'dateRange' && viewingType !== 'selectedDay';
    this.setState({ viewingType }, shouldSubmit ? this.props.onSubmit : null);
  };

  todayDate = new Date();

  yesterdayDate = date.removeOneDay(this.todayDate);

  handleChangeType = (event) => {
    const { value } = event.target;

    this.setDateType(value);
    this.setViewingType(value);

    if (value === 'dateRange') {
      return;
    }

    this.props.onChange(this.getDateRange(value));
  };

  handleChangeRange = (value) => {
    this.setDateRange(value);
    this.props.onChange({ ...value, dateType: 'dateRange' });
  };

  handleClose = () => this.setViewingType(null);

  handleOk = () => this.setViewingType(null);

  renderDateRange() {
    const { from, to } = this.getDateRange(this.state.dateType);

    return (
      <ArcDialog
        open={this.state.viewingType === 'dateRange'}
        onClose={this.handleClose}
      >
        <div>
          <ArcDateRange
            from={from}
            to={to}
            onOk={this.handleClose}
            onCancel={this.handleClose}
            onChange={this.handleChangeRange}
          />
        </div>
      </ArcDialog>
    );
  }

  renderDateSelector() {
    const { dateType } = this.state;

    switch (dateType) {
      case 'dateRange':
        return this.renderDateRange();
      default:
        return null;
    }
  }

  renderValue = value => (
    <ArcView row align="center">
      <ArcView marginRight="8">
        <IconDateRange />
      </ArcView>
      <ArcText>{this.getValueAsText(value)}</ArcText>
    </ArcView>
  );

  render() {
    const items = [
      dateTypeItems.today,
      dateTypeItems.yesterday,
      dateTypeItems.pastWeek,
      dateTypeItems.currentWeek,
      dateTypeItems.currentMonth,
      dateTypeItems.dateRange,
    ];

    return (
      <ArcView row align="center" className={this.props.className}>
        {this.renderDateSelector()}

        <ArcSelect
          items={items}
          onChange={this.handleChangeType}
          value={this.state.dateType}
          renderValue={this.renderValue}
          variant="outlined"
        />
      </ArcView>
    );
  }
}

export default ArcTransactionLogDateSelector;
