import React from 'react';
import PropTypes from 'prop-types';

import {
  ArcView,
} from 'arcade-frontend-ui';

import ArcEntity from 'arcade-frontend-ui/src/components/ArcEntity';
import ArcStackedEntities from 'arcade-frontend-ui/src/components/ArcStackedEntities';


function ArcEntitySelections({
  maxPeople,
  people,
  ...props
}) {
  const length = people ? people.length : 0;

  if (!length) {
    return null;
  }

  const shouldCollapsePeople = length > maxPeople;

  return (
    <ArcView
      fullWidth
      wrap="wrap"
      justify="center"
      {...props}
    >
      {shouldCollapsePeople ? (
        <ArcStackedEntities
          type="person"
          entities={people}
        />
      ) : people.map(person => (
        <ArcView key={person.id}>
          <ArcEntity
            type="person"
            imageUrl={person.imageUrl}
            name={person.name}
            teamName={person.teamName}
          />
        </ArcView>
      ))}
    </ArcView>
  );
}

ArcEntitySelections.displayName = 'ArcEntitySelections';

ArcEntitySelections.propTypes = {
  maxPeople: PropTypes.number,
  people: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
  })),
};

ArcEntitySelections.defaultProps = {
  maxPeople: 5,
  people: [],
};

export default ArcEntitySelections;
