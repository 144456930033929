export const flatten = list => list.reduce(
  (a, b) => a.concat(Array.isArray(b) ? flatten(b) : b),
  [],
);

export const isArray = arr => arr.constructor === Array;

export const sortByProp = (array, prop, desc = false) => (
  array.sort((a, b) => {
    if (a[prop] < b[prop]) {
      return desc ? 1 : -1;
    }

    if (a[prop] > b[prop]) {
      return desc ? -1 : 1;
    }

    return 0;
  })
);

export const uniqByAttribute = (array, attribute) => Object.values(array.reduce((hash, object) => {
  if (process.env.NODE_ENV !== 'production') {
    if (!object.hasOwnProperty(attribute)) {
      console.warn(`You are attempting to sort ${object} on an undefined attribute: ${attribute}`);
    }
  }

  hash[object[attribute]] = object; // eslint-disable-line no-param-reassign

  return hash;
}, {}));
