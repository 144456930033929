import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../actions/audiences';

const initialState = null;

const handleAudienceTypeLoadSuccess = (state, action) => {
  const { payload } = action;
  const { data } = payload;

  return data;
};

const handlers = {
  [types.AUDIENCES_GET_AUDIENCE_TYPES.SUCCESS]: handleAudienceTypeLoadSuccess,
};

export default createReducer(initialState, handlers);

export const getLevels = state => state.audiences.levels;
