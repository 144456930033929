import sagas from 'arcade-frontend-core/src/helpers/sagas';
import { forms } from 'arcade-frontend-ui';

import { actions as actionsApp } from 'arcade-frontend-core/src/actions';
import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';
import {
  getLocationType,
  getLocationQuery,
} from 'arcade-frontend-features/src/reducers/location';

import { actions, types, routes } from '../../actions/manage/games';
import { api } from '../../resources/manage/games';

import { apiManageGamesListRequest } from '../../actions/manage/games/actions';

const onSuccessGameCreateReload = [
  (_, state) => {
    switch (state.location.query.tab) {
      case 'overview':
      case 'active':
      case 'upcoming':
        return apiManageGamesListRequest({
          listType: state.location.query.tab,
        });
      default:
        return undefined;
    }
  },
];

const onSuccessEdit = [
  ({ data }) => forms.actions.change('peopleSelector', { values: data }),
  ({ data }) => forms.actions.change('games.create', { values: data }),
];

const onGameRejectAlert = () =>
  actionsApp.setAlert({
    type: 'info',
    title: 'Results Rejected',
    content: 'A member of the Arcade team will be in touch!',
  });

const onGameAcceptAlert = () =>
  actionsApp.setAlert({
    type: 'success',
    title: 'Game Accepted!',
    content: 'The prizes will be given out shortly',
  });

const onFailureAlert = () =>
  actionsApp.setAlert({
    type: 'error',
    title: 'Oops!',
    content: 'Something went wrong...',
  });

const handleGamesOrManageGamesRedirect = (_, state) => {
  const locationType = getLocationType(state);

  if (locationType === ROUTE_TYPES.ROUTE_GAMES_CREATE) {
    return routes.routeGames({}, state.location.query);
  }

  return routes.routeManageGames({}, state.location.query);
};

const handleGamesRejectOrVerifyRedirect = (resp, state) => {
  const locationType = getLocationType(state);

  if (locationType === ROUTE_TYPES.ROUTE_GAMES) {
    const locationQuery = getLocationQuery(state);

    return {
      type: ROUTE_TYPES.ROUTE_GAMES,
      payload: {},
      query: {
        ...locationQuery,
        form: undefined,
        formGameId: undefined,
      },
    };
  }

  return routes.routeManageGames();
};

const handleVerifiedGameIds = resp => {
  const { url } = resp.config;
  const regex = 'campaigns/(.*)/verify';
  const id = url.match(regex)[1];
  return actions.setManageGamesVerifiedId(id);
};

const takeLatest = [
  {
    type: types.MANAGE_GAMES_INDEX,
    call: api.index,
  },
  {
    type: types.MANAGE_GAMES_LIST,
    call: api.list,
  },
  {
    type: types.MANAGE_GAMES_CREATE,
    call: api.create,
    onSuccess: [...onSuccessGameCreateReload, handleGamesOrManageGamesRedirect],
  },
  {
    type: types.MANAGE_GAMES_SHOW,
    call: api.show,
  },
  {
    type: types.MANAGE_GAMES_TEMPLATES_INDEX,
    call: api.templatesIndex,
  },
  {
    type: types.MANAGE_GAMES_ELIGIBLE_PEOPLE_INDEX,
    call: api.eligiblePeopleIndex,
  },
  {
    type: types.MANAGE_GAMES_RANKINGS_INDEX,
    call: api.rankingsIndex,
  },
  {
    type: types.MANAGE_GAMES_RESULTS_INDEX,
    call: api.resultsIndex,
  },
  {
    type: types.MANAGE_GAMES_EVENTS_INDEX,
    call: api.eventsIndex,
  },
  {
    type: types.MANAGE_GAMES_UPDATE,
    call: api.update,
    onSuccess: [
      ...onSuccessGameCreateReload,
      (_, state) => routes.routeManageGames({}, state.location.query),
    ],
  },
  {
    type: types.MANAGE_GAMES_TEMPLATE_CREATE,
    call: api.createFromTemplate,
  },
  {
    type: types.MANAGE_GAMES_EDIT,
    call: api.edit,
    onSuccess: onSuccessEdit,
  },
  {
    type: types.MANAGE_GAMES_VERIFY,
    call: api.verify,
    onSuccess: [
      handleGamesRejectOrVerifyRedirect,
      onGameAcceptAlert,
      handleVerifiedGameIds,
    ],
    onFailure: onFailureAlert,
  },
  {
    type: types.MANAGE_GAMES_REJECT,
    call: api.reject,
    onSuccess: [handleGamesRejectOrVerifyRedirect, onGameRejectAlert],
    onFailure: onFailureAlert,
  },
  {
    type: types.MANAGE_GAMES_VALID_METRICS_INDEX,
    call: api.validMetricsIndex,
  },
  {
    type: types.MANAGE_GAMES_GET_ESCROWABLE_FUNDS,
    call: api.escrowableFunds,
  },
];

const games = takeLatest.map(sagas.makeTakeLatest);

export default games;
