import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import Check from '@material-ui/icons/Check';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Close from '@material-ui/icons/Close';

import createWithStyles from '../../styles/createWithStyles';

import ArcResourceErrorDialog from '../ArcResourceErrorDialog';
import ArcLoaderButton from '../ArcLoaderButton';
import ArcButton from '../../elements/ArcButton';
import ArcHidden from '../../elements/ArcHidden';
import ArcIconButton from '../../elements/ArcIconButton';
import ArcScroll from '../../layout/ArcScroll';
import ArcView from '../../primitives/ArcView';
import ArcText from '../../primitives/ArcText';
import ArcMobileStepper from '../ArcMobileStepper';
import ArcStepper from '../ArcStepper';

const styles = {
  Actions: theme => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexShrink: 0,
      flexGrow: 0,

      borderTopColor: theme.palette.divider,
      borderTopStyle: 'solid',
      borderTopWidth: 1,

      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
  }),

  Container: theme => ({
    root: {
      flexShrink: 10,
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.paper,

      [theme.breakpoints.up('sm')]: {
        border: `4px solid ${theme.palette.divider}`,
      },
    },
  }),

  Content: theme => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 100,
      padding: theme.spacing(3),

      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },
    },
  }),

  Header: theme => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexShrink: 0,
      flexGrow: 0,
      minHeight: 48,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),

      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,

      [theme.breakpoints.up('sm')]: {
        padding: 0,
      },
    },
  }),
};

const Actions = createWithStyles(styles.Actions)(ArcView);
const Container = createWithStyles(styles.Container)(ArcView);
const Content = createWithStyles(styles.Content)(ArcScroll);
const Header = createWithStyles(styles.Header)(ArcView);

const styleMarginRight = {
  marginRight: 8,
};

class ArcFormStepper extends React.PureComponent {
  static propTypes = {
    activeStep: PropTypes.number,
    children: PropTypes.node,
    confirmLabel: PropTypes.string,
    hasConfirm: PropTypes.bool,
    isValid: PropTypes.bool,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onFinish: PropTypes.func,
    onNext: PropTypes.func,
    onStep: PropTypes.func,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        isValid: PropTypes.bool.isRequired,
        isTouched: PropTypes.bool,
        label: PropTypes.string.isRequired,
      }),
    ),
    onConfirm: PropTypes.func,
    requestStatus: PropTypes.string,
    requestErrors: PropTypes.objectOf(PropTypes.any),
    submitLabel: PropTypes.string,
  };

  static defaultProps = {
    activeStep: 0,
    children: null,
    confirmLabel: 'Confirm',
    hasConfirm: false,
    isValid: false,
    onBack: global.noop,
    onCancel: global.noop,
    onFinish: global.noop,
    onNext: global.noop,
    onStep: global.noop,
    steps: [],
    onConfirm: global.noop,
    requestStatus: 'DEFAULT',
    requestErrors: {},
    submitLabel: 'Finish',
  };

  static displayName = 'ArcFormStepper';

  state = {
    dialogOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.activeStep !== this.props.activeStep) {
      this.scrollToTop();
    }

    if (
      prevProps.requestStatus === 'REQUEST' &&
      this.props.requestStatus === 'FAILURE'
    ) {
      this.setDialogOpen(true);
    }
  }

  get currentStep() {
    return this.props.steps[this.props.activeStep];
  }

  get currentStepLabel() {
    if (this.currentStep && this.currentStep.label) {
      return this.currentStep.label;
    }

    if (this.isLastStep && this.props.hasConfirm) {
      return this.props.confirmLabel;
    }

    return '';
  }

  get isLastStep() {
    if (this.props.hasConfirm) {
      return this.props.activeStep === this.props.steps.length;
    }

    return this.props.activeStep === this.props.steps.length - 1;
  }

  get maxSteps() {
    return this.props.steps.length;
  }

  setDialogOpen = dialogOpen => this.setState({ dialogOpen });

  setScrollRef = scrollRef => {
    this.scrollRef = scrollRef;
  };

  scrollToTop = () => {
    if (this.scrollRef) {
      this.scrollRef.scrollTo(0, 0);
    }
  };

  handleBack = () => {
    this.scrollToTop();
    this.props.onBack();
  };

  handleDialogClose = () => this.setDialogOpen(false);

  handleNext = () => {
    this.scrollToTop();
    this.props.onNext();
  };

  handleStep = step => {
    this.scrollToTop();
    this.props.onStep(step);
  };

  handleFinish = () => {
    this.props.onFinish();
  };

  normalizeError = () => {
    const { requestErrors } = this.props;

    if (!requestErrors.error) {
      return null;
    }

    return {
      data: {
        response: {
          data: {
            error: requestErrors.error,
          },
          status: requestErrors.status,
          statusText: requestErrors.error,
        },
      },
    };
  };

  renderActions() {
    return (
      <React.Fragment>
        <ArcButton
          label="Cancel"
          onClick={this.props.onCancel}
          isSpaced
          size="large"
        />

        <ArcView spacer />

        {this.renderBack()}
        {this.renderNext()}
      </React.Fragment>
    );
  }

  renderActionsMobile() {
    return (
      <ArcMobileStepper
        position="static"
        activeStep={this.props.activeStep}
        steps={this.props.steps.length}
        backButton={this.renderBack({
          isSpaced: false,
          size: 'small',
          label: (
            <React.Fragment>
              <KeyboardArrowLeft style={styleMarginRight} />
              Back
            </React.Fragment>
          ),
        })}
        nextButton={this.renderNext({
          isSpaced: false,
          size: 'small',
          variant: 'text',
          label: (
            <React.Fragment>
              <ArcText style={styleMarginRight}>
                {this.isLastStep ? this.props.submitLabel : 'Next'}
              </ArcText>
              {this.isLastStep ? <Check /> : <KeyboardArrowRight />}
            </React.Fragment>
          ),
        })}
      />
    );
  }

  renderBack(props) {
    return (
      <ArcButton
        disabled={this.props.activeStep === 0}
        label="Back"
        onClick={this.handleBack}
        isSpaced
        size="large"
        {...props}
      />
    );
  }

  renderNext(props) {
    return (
      <ArcLoaderButton
        data-testid="ArcFormStepper-NextButton"
        label={this.isLastStep ? this.props.submitLabel : 'Next'}
        disabled={this.isLastStep ? !this.props.isValid : false}
        variant="contained"
        color={this.isLastStep ? 'secondary' : 'primary'}
        onClick={this.isLastStep ? this.handleFinish : this.handleNext}
        isSpaced
        size="large"
        loadingState={this.props.requestStatus}
        {...props}
      />
    );
  }

  renderStepper() {
    return (
      <ArcStepper
        activeStep={this.props.activeStep}
        confirmLabel={this.props.confirmLabel}
        hasConfirm={this.props.hasConfirm}
        onConfirm={this.props.onConfirm}
        onStep={this.handleStep}
        steps={this.props.steps}
      />
    );
  }

  render() {
    return (
      <Container>
        <ArcHidden xsDown>{this.renderStepper()}</ArcHidden>

        <ArcHidden smUp>
          <Header row align="center">
            <Typography variant="h6">{this.currentStepLabel}</Typography>
            <ArcView spacer />

            <ArcIconButton onClick={this.props.onCancel} size="small">
              <Close />
            </ArcIconButton>
          </Header>
        </ArcHidden>

        <Content scrollRef={this.setScrollRef}>{this.props.children}</Content>

        <ArcView spacer />

        <Actions>
          <ArcHidden xsDown>{this.renderActions()}</ArcHidden>

          <ArcHidden smUp>{this.renderActionsMobile()}</ArcHidden>
        </Actions>

        <ArcResourceErrorDialog
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}
          error={this.normalizeError()}
        />
      </Container>
    );
  }
}

export default ArcFormStepper;
