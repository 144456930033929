import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/games';

const handleManageGamesEligibleTeamsLoadSuccess = (state, action) => {
  const { data } = action.payload;

  return data.teams;
};

const initialState = [];

const handlers = {
  [types.MANAGE_GAMES_ELIGIBLE_PEOPLE_INDEX.SUCCESS]:
    handleManageGamesEligibleTeamsLoadSuccess,
};

const eligibleTeams = createReducer(initialState, handlers);

export default eligibleTeams;

export const getManageGamesEligibleTeams = state =>
  state.manage.games.eligibleTeams;
