function transformUserToFlatUser(item) {
  return {
    ...item,
    userId: item.user.id ? item.user.id.toString() : '',
    userImage: item.user.image,
    userName: item.user.name,
  };
}

export default transformUserToFlatUser;
