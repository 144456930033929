import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import * as GAME_SCENE_SIZES from 'arcade-frontend-core/src/types/game-scene-sizes';
import * as GAME_STATUSES from 'arcade-frontend-core/src/types/game-statuses';
import { isRPAType } from 'arcade-frontend-core/src/types/game-formats';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import { getCurrentUserPermission } from 'arcade-frontend-core/src/reducers/user';

import GamesUpcomingGameScene from '../GamesUpcomingGameScene';
import GamesGameCardActions from './GamesGameCardActions';
import GamesGameCardTabs from './GamesGameCardTabs';
import GamesGameCardTitle from './GamesGameCardTitle';
import GamesGameScene from '../GamesGameScene';

const CARD_WIDTH = '100%';

function GamesGameCardLarge({
  id,
  allowManualAdd,
  campaignImage,
  expiresAt,
  name,
  onClick,
  onClose,
  participantCount,
  pendingCount,
  rewards,
  rules,
  type,
  onVerify,
  status,
  startsAt,
  activeTab,
  onTabChange,
  rankings,
  isManager,
  winners,
  score,
  ...props
}) {
  const canManageGames = useSelector(state =>
    getCurrentUserPermission(state, PERMISSIONS.MANAGE_GAMES),
  );
  const showVerify =
    canManageGames &&
    status === GAME_STATUSES.NEEDS_VERIFICATION &&
    !isRPAType(type);

  function handleTabChange(tab) {
    const nextTab = tab === activeTab ? null : tab;

    onTabChange(nextTab);
  }

  function handleTabReset() {
    onTabChange(null);
  }

  return (
    <ArcBox
      role="article"
      display="flex"
      width={CARD_WIDTH}
      height="100%"
      overflow="hidden"
      position="relative"
      bgcolor="background.paper"
      {...props}
    >
      <ArcBox
        zIndex={1}
        position="relative"
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
      >
        <ArcBox
          overflow="auto"
          position="relative"
          display="flex"
          flexDirection="column"
          flex={1000}
        >
          {!activeTab && (
            <>
              {status !== GAME_STATUSES.UPCOMING ? (
                <ArcBox flex={0}>
                  <GamesGameScene
                    borderRadius={0}
                    isManager={isManager}
                    participantCount={participantCount}
                    rankings={rankings}
                    rewards={rewards}
                    rules={rules}
                    type={type}
                    score={score}
                    pendingCount={pendingCount}
                    width="100%"
                    size={GAME_SCENE_SIZES.LARGE}
                  />
                </ArcBox>
              ) : (
                <GamesUpcomingGameScene
                  flex={1000}
                  expiresAt={expiresAt}
                  rewards={rewards}
                  startsAt={startsAt}
                  type={type}
                />
              )}
            </>
          )}

          <ArcBox
            fontSize="24px"
            lineHeight="150%"
            py={2}
            px={3}
            borderTop={1}
            borderColor="divider"
          >
            <GamesGameCardTitle
              goal={rules.goal}
              metric={rules.metric}
              rewards={rewards}
              type={type}
            />
          </ArcBox>

          <GamesGameCardTabs
            activeTab={activeTab}
            onClose={onClose}
            onBack={handleTabReset}
            gameId={id}
            rules={rules}
            type={type}
            isManager={canManageGames}
            status={status}
          />
        </ArcBox>
        <GamesGameCardActions
          gameId={id}
          status={status}
          activeTab={activeTab}
          onTabChange={handleTabChange}
          showLeaderboard
          showAddMetric={status === GAME_STATUSES.ACTIVE && allowManualAdd}
          showVerify={showVerify}
          pendingEventsCount={pendingCount}
        />
      </ArcBox>
    </ArcBox>
  );
}

GamesGameCardLarge.displayName = 'GamesGameCardLarge';

function GamesGameCardLargePlaceholder({
  id,
  allowManualAdd,
  campaignImage,
  expiresAt,
  name,
  onClick,
  onClose,
  participantCount,
  pendingCount,
  rewards,
  rules,
  type,
  onVerify,
  status,
  startsAt,
  activeTab,
  onTabChange,
  rankings,
  isManager,
  winners,
  ...props
}) {
  return (
    <ArcBox
      role="article"
      display="flex"
      width={CARD_WIDTH}
      height="100%"
      overflow="hidden"
      position="relative"
      bgcolor="background.paper"
      {...props}
    >
      <ArcBox
        zIndex={1}
        position="relative"
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <ArcBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          height={72}
          px={3}
        >
          <Skeleton variant="text" height={36} width={100} />
          <ArcBox mr={1} flex={100} />
          <Skeleton variant="circle" width={36} height={36} />
        </ArcBox>
        <ArcBox display="flex" flexDirection="column" px={3}>
          <ArcBox height={68}>
            <Skeleton variant="rect" height={36} width="100%" />
            <Skeleton variant="text" height={36} width={150} />
          </ArcBox>

          <ArcBox flex={100} />

          <Skeleton
            component={ArcBox}
            borderRadius={8}
            variant="rect"
            width="100%"
            height={307}
            mt={3}
          />

          <ArcBox
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            pt={1}
            pb={2}
          >
            <Skeleton
              component={ArcBox}
              borderRadius={8}
              variant="rect"
              height={29}
              width={150}
            />
          </ArcBox>
        </ArcBox>
        <ArcBox
          display="flex"
          flexDirection="row"
          borderTop={1}
          borderColor="divider"
          pt={1}
          pb={2}
          px={3}
        >
          <Skeleton
            component={ArcBox}
            variant="text"
            height={30}
            width={100}
            mr={1}
          />
          <Skeleton
            component={ArcBox}
            variant="text"
            height={30}
            width={100}
            mr={1}
          />
          <Skeleton component={ArcBox} variant="text" height={30} width={100} />
        </ArcBox>
      </ArcBox>
    </ArcBox>
  );
}

GamesGameCardLarge.Placeholder = GamesGameCardLargePlaceholder;

GamesGameCardLargePlaceholder.propTypes = GamesGameCardLarge.propTypes;
GamesGameCardLargePlaceholder.defaultProps = GamesGameCardLarge.defaultProps;

GamesGameCardLarge.propTypes = {
  id: PropTypes.string,
  allowManualAdd: PropTypes.bool,
  campaignImage: PropTypes.string,
  expiresAt: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  participantCount: PropTypes.number,
  pendingCount: PropTypes.number,
  type: PropTypes.string,
  rankings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      goal: PropTypes.number,
      isSelf: PropTypes.bool,
      rank: PropTypes.number,
      score: PropTypes.number,
    }),
  ),
  rewards: PropTypes.arrayOf(PropTypes.shape({})),
  rules: PropTypes.shape({
    metric: PropTypes.string,
    goal: PropTypes.number,
  }),
  score: PropTypes.number,
  onVerify: PropTypes.func,
  startsAt: PropTypes.string,
  status: PropTypes.oneOf(Object.values(GAME_STATUSES)),
  activeTab: PropTypes.string,
  onTabChange: PropTypes.func,
  isManager: PropTypes.bool,
  winners: PropTypes.arrayOf(PropTypes.any),
};

GamesGameCardLarge.defaultProps = {
  id: null,
  allowManualAdd: false,
  campaignImage: '',
  expiresAt: '',
  name: '',
  onClick: global.noop,
  onClose: global.noop,
  participantCount: 0,
  pendingCount: 0,
  type: '',
  rankings: [],
  rewards: [],
  rules: {},
  startsAt: '',
  status: GAME_STATUSES.ACTIVE,
  activeTab: null,
  onTabChange: global.noop,
  isManager: false,
  winners: [],
  score: 0,
};

export default GamesGameCardLarge;
