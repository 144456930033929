import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import ArcView from '../../primitives/ArcView/ArcView';
import ArcImage from '../../primitives/ArcImage/ArcImage';
import ArcPropTypes from '../../helpers/arc/propTypes';
import { createWithStyles } from '../../styles';

const styles = {
  UserImageContainer: () => ({
    root: {
      transform: 'translate(-50%, -50%)',
      width: 40,
      height: 40,
      textAlign: 'center',
      border: '1px solid #F3F3F3',
      borderRadius: 32,
      backgroundColor: 'white',
    },

    isSelf: {
      width: 56,
      height: 56,
      border: '4px solid #FFD43D',
      zIndex: 1,
    },
  }),

  UserImage: () => ({
    root: {
      height: '100%',
      borderRadius: 32,
      opacity: 0.5,
    },

    isSelf: {
      opacity: 1,
    },
  }),
};

const UserImageContainer = createWithStyles(styles.UserImageContainer)(ArcView);
const UserImage = createWithStyles(styles.UserImage)(ArcImage);

const PlayerPortrait = ({ isSelf, image, title }) => (
  <Tooltip
    title={title}
  >
    <UserImageContainer isSelf={isSelf}>
      <UserImage isSelf={isSelf} src={image} />
    </UserImageContainer>
  </Tooltip>
);

PlayerPortrait.propTypes = {
  isSelf: ArcPropTypes.bool,
  image: ArcPropTypes.string.isRequired,
  title: ArcPropTypes.string.isRequired,
};

PlayerPortrait.defaultProps = {
  isSelf: false,
};

export default PlayerPortrait;
