import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const ChestAlt = props => (
  <Icon {...props}>
    <Path fill="transparent" fillRule="evenodd" clipRule="evenodd" d="M23.5 9.5H0.5V18.5C0.5 19.605 1.395 20.5 2.5 20.5H21.5C22.605 20.5 23.5 19.605 23.5 18.5V9.5Z" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="transparent" d="M0.5 11.5H9.5C9.776 11.5 10 11.724 10 12V12.5C10 13.605 10.895 14.5 12 14.5C13.105 14.5 14 13.605 14 12.5V12C14 11.724 14.224 11.5 14.5 11.5H23.5" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path d="M17.5039 11.5V20.5Z" fill="white" />
    <Path fill="transparent" d="M17.5039 11.5V20.5" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path d="M12.0039 11.5V12.5Z" fill="white" />
    <Path fill="transparent" d="M12.0039 11.5V12.5" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path d="M6.50391 11.5V20.5Z" fill="white" />
    <Path fill="transparent" d="M6.50391 11.5V20.5" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="transparent" fillRule="evenodd" clipRule="evenodd" d="M23.5 7.5C23.5 5.291 21.709 3.5 19.5 3.5H4.5C2.291 3.5 0.5 5.291 0.5 7.5V9.5H23.5V7.5Z" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="transparent" d="M14.5 3.5C17.5 3.5 17.5 7.5 17.5 7.5V9.5" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="transparent" d="M9.5 3.5C6.5 3.5 6.5 7.5 6.5 7.5V9.5" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

export default ChestAlt;
