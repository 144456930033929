import { setRollbar } from 'arcade-frontend-ui/src/helpers/utils/resource';
import Rollbar from 'arcade-frontend-ui/src/providers/RollbarProvider/client';
import getEndpointsByEmail from '../getEndpointsByEmail';

setRollbar(Rollbar);

export async function setup(user) {
  const { id, firstname, lastname, email, endpoint } = user;

  if (id && firstname && lastname && email) {
    const fullName = `${firstname} ${lastname}`;

    const endpointName = endpoint
      ? endpoint.replace('https://', '').replace('.arcadehub.co', '')
      : 'NO_ENDPOINT';

    const uuid = `${endpointName}#${id}`;

    Rollbar.setPerson(uuid, fullName, email);
  }
}

export async function destroy() {
  Rollbar.clearPerson();
}
