import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  RACE,
  TOURNAMENT,
  BOUNTY,
  RPA_ONE_TIME,
  RPA_EVERY_TIME,
} from 'arcade-frontend-core/src/types/game-formats';

import {
  ArcButton,
  ArcView,
  ArcHeading,
  ArcPlaceholder,
  ArcText,
  createWithStyles,
  ArcIconButton,
} from 'arcade-frontend-ui';

import CloseIcon from '@material-ui/icons/Close';

import GameVerificationList from '../GameVerificationList';

const renderLoadingPlaceholder = () =>
  [0, 1, 2, 3, 4].map(() => <ArcPlaceholder padding="24" marginBottom="8" />);

const styles = {
  ButtonRow: theme => ({
    root: {
      margin: theme.spacing(1),
      width: '100%',
    },
  }),

  DescriptionText: theme => ({
    root: {
      marginBottom: theme.spacing(2),
    },
  }),

  HeadingText: () => ({
    root: {
      textAlign: 'center',
    },
  }),
};

const ButtonRow = createWithStyles(styles.ButtonRow)(ArcView);
const DescriptionText = createWithStyles(styles.DescriptionText)(ArcView);
const HeadingText = createWithStyles(styles.HeadingText)(ArcHeading);

class GameVerification extends React.PureComponent {
  static displayName = 'GameVerification';

  static propTypes = {
    onClickReject: PropTypes.func,
    onClickVerify: PropTypes.func,
    onClose: PropTypes.func,
    results: PropTypes.arrayOf(PropTypes.object),
    rewards: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string,
    inDialog: PropTypes.bool,
    isFetchingResults: PropTypes.bool,
    type: PropTypes.string,
    goal: PropTypes.number,
    rules: PropTypes.shape({
      goal: PropTypes.number,
      metric: PropTypes.string,
      person: PropTypes.string,
      subject: PropTypes.string,
      unit: PropTypes.string,
      winners: PropTypes.number,
    }),
    startsAt: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    completedAt: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  };

  static defaultProps = {
    onClickReject: global.noop,
    onClickVerify: global.noop,
    onClose: global.noop,
    results: [],
    rewards: [],
    id: null,
    inDialog: false,
    isFetchingResults: false,
    type: null,
    startsAt: null,
    completedAt: null,
    rules: null,
    goal: null,
  };

  state = {
    isEditing: false,
  };

  handleClickVerify = scores => {
    this.props.onClickVerify(this.props.id, scores);
  };

  handleClickReject = () => {
    this.props.onClickReject(this.props.id);
  };

  handleClickEdit = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  renderResultsList = results => {
    const { rules, rewards, type, goal } = this.props;

    return (
      <GameVerificationList
        results={results}
        isEditing={this.state.isEditing}
        onClickEdit={this.handleClickEdit}
        onClickVerify={this.handleClickVerify}
        rewards={rewards}
        type={type}
        goal={goal}
        participantType={rules ? rules.subject : undefined}
      />
    );
  };

  renderVerificationButtons = () => (
    <ButtonRow row justify="flex-end">
      <ArcButton
        onClick={this.handleClickEdit}
        variant="contained"
        color="primary"
        label="Edit"
        isSpaced
      />
      <ArcButton
        color="secondary"
        variant="contained"
        label="Verify"
        onClick={this.handleClickVerify}
      />
    </ButtonRow>
  );

  renderRulesDescription = rules => {
    const theFirst =
      rules.winners > 1 ? `The first ${rules.winners}` : 'The first';

    switch (this.props.type) {
      case RACE:
        return (
          <ArcText>
            {`${theFirst} to reach ${rules.goal} for ${rules.metric}`}
          </ArcText>
        );
      case TOURNAMENT:
        return (
          <ArcText>
            {`The top ${rules.winners} for ${rules.metric} when the game ended`}
          </ArcText>
        );
      case BOUNTY:
        return (
          <ArcText>
            {`Everyone who reached ${rules.goal} for ${rules.metric}`}
          </ArcText>
        );
      case RPA_ONE_TIME:
      case RPA_EVERY_TIME:
        return null;
      // case TYPES.KEEPABOVE:
      //   return (
      //     <ArcText>
      //       {`Everyone who was above ${rules.goal} at the end of the game`}
      //     </ArcText>
      //   );
      default:
        return "There aren't any rules!";
    }
  };

  renderDescription = (rules, startedAt, completedAt) => {
    const start = startedAt ? moment(startedAt).format('lll') : 'No Start';
    const end = completedAt ? moment(completedAt).format('lll') : 'No End';

    return (
      <ArcView>
        <DescriptionText>{this.renderRulesDescription(rules)}</DescriptionText>
        <DescriptionText>{`${start} - ${end}`}</DescriptionText>
      </ArcView>
    );
  };

  render() {
    const { results, rules, startsAt, completedAt } = this.props;

    return (
      <ArcView
        style={{
          maxWidth: 600,
          padding: this.props.inDialog ? 40 : 22,
        }}
      >
        <ArcView row style={{ alignItems: 'center' }}>
          <HeadingText>Verify Game Results</HeadingText>
          {this.props.inDialog && (
            <ArcIconButton
              style={{ marginLeft: 'auto' }}
              onClick={this.props.onClose}
              size="small"
            >
              <CloseIcon />
            </ArcIconButton>
          )}
        </ArcView>
        {this.props.isFetchingResults ? (
          <ArcView>{renderLoadingPlaceholder()}</ArcView>
        ) : (
          <ArcView>
            {rules && this.renderDescription(rules, startsAt, completedAt)}
            {this.renderResultsList(results)}
          </ArcView>
        )}
      </ArcView>
    );
  }
}

export default GameVerification;
