import axios from 'axios';
import { camelizeKeys } from 'humps';

export const arcadeApiClient = axios.create();

arcadeApiClient.interceptors.request.use(async config => {
  const store = require('arcade-frontend-features/src/store').default;
  const { getCurrentUser } = require('../../reducers/user');
  const currentUser = getCurrentUser(store.getState());

  if (!currentUser) {
    console.error('No access token found in cache');
  } else {
    /* eslint-disable no-param-reassign */
    config.headers['X-User-Email'] = currentUser.email;
    config.headers['X-User-Token'] = currentUser.authenticationToken;
    config.baseURL = currentUser.endpoint;
    /* eslint-enable no-param-reassign */
  }

  return config;
});

arcadeApiClient.interceptors.response.use(response => {
  response.data = camelizeKeys(response.data);

  return response;
});
