/*
  Distribute `k` values across `t` slots, preserving order and making a best
  match to layout.
*/

export const build = (slotCount, values, total = undefined) => {
  if (values.length > slotCount) {
    throw new Error(`Can't distribute ${values.length} values into ${slotCount} slots.`);
  }
  const slots = slotCount - 1;
  const largestValue = Math.max.apply(null, values.map(v => v.value));
  if (total && largestValue > total) {
    throw new Error(`Can't calculate position for ${largestValue} when maximum is ${total}`);
  }

  const buckets = [];
  for (let i = 0; i <= slots; i += 1) {
    buckets.push([]);
  }

  const normalisedScore = score => (total ? score / total : score);

  values.forEach((value) => {
    let index = Math.round((normalisedScore(value.score) * slots) - 0.4999);

    if (index >= buckets.length) {
      index = buckets.length - 1;
    } else if (index < 0) {
      index = 0;
    }

    buckets[index].push(value);
  });

  return buckets;
};

export const redistribute = (buckets) => {
  const lastIndex = buckets.length - 1;

  const flattener = forward => (bucket, i) => {
    if (i === lastIndex) return;

    if (bucket.length > 1) {
      if (forward) {
        const v = bucket.shift();
        buckets[i + 1].push(v);
      } else {
        const v = bucket.pop();
        buckets[i + 1].unshift(v);
      }
    }
  };

  buckets.reverse();
  buckets.forEach(flattener(true));
  buckets.reverse();
  buckets.forEach(flattener(false));
  return buckets;
};

export const isFinished = buckets => !buckets.some(b => b.length > 1);

export const distribute = (slots, values, total) => {
  let result = build(slots, values, total);
  while (!isFinished(result)) {
    result = redistribute(result);
  }
  return result.map(r => r[0]);
};
