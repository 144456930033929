import { types } from 'arcade-frontend-core/src/actions';

// import {
//   ROUTE_CHAT,
//   ROUTE_CHAT_SHOW,
// } from 'arcade-frontend-core/src/types/routes';

const initialState = {
  appBar: null,
  ngRootScope: null,
  ngState: null,
  platform: null,
};


const platform = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ARC_APP_BAR:
      return {
        ...state,
        appBar: action.payload.appBar,
      };

    case types.SET_NG_PROPS:
      return {
        ...state,
        ngRootScope: action.payload.ngRootScope,
        ngState: action.payload.ngState,
      };

    case types.SET_PLATFORM:
      return {
        ...state,
        platform: action.payload.platform,
      };

      // case ROUTE_CHAT:
      //   if (state.ngState) {
      //     state.ngState.go('arcade.messages');
      //   }
      //   return state;
      // case ROUTE_CHAT_SHOW:
      //   if (state.ngState) {
      //     state.ngState.go('arcade.messages.show', {
      //       id: action.payload.chatId,
      //     });
      //   }
      //   return state;

    default:
      return state;
  }
};

export default platform;

export const getAppPlatform = state => state.platform.platform;
