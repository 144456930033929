import React from 'react';

import { ArcButton, ArcMain, ArcMainView, ArcScroll } from 'arcade-frontend-ui';

import FormRewardCreate from '../forms/create';

class RewardCreateView extends React.PureComponent {
  state = {
    isCreating: false,
  };

  setIsCreating = isCreating => this.setState({ isCreating });

  handleCancel = () => this.setIsCreating(false);

  handleClickCreate = () => this.setIsCreating(true);

  render() {
    return (
      <ArcMain>
        <ArcMainView isViewing hasNav={false}>
          <ArcScroll>
            <ArcButton onClick={this.handleClickCreate}>
              Create Reward
            </ArcButton>

            {this.state.isCreating && (
              <FormRewardCreate onCancel={this.handleCancel} />
            )}
          </ArcScroll>
        </ArcMainView>
      </ArcMain>
    );
  }
}

export default RewardCreateView;
