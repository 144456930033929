import React from 'react';
import { Path } from 'react-primitives-svg';
import Icon from '../Icon';

const Star = props => (
  <Icon {...props}>
    <Path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.533 1.571a.5.5 0 01.936 0L15 8.747h7.148a.5.5 0 01.325.88L16.5 14.579l2.5 7.51a.5.5 0 01-.77.561L12 18.079 5.767 22.65A.5.5 0 015 22.089l2.5-7.51-5.974-4.952a.5.5 0 01.325-.88H9l2.533-7.176z"
    />
  </Icon>
);

Star.displayName = 'Star';

export default Star;
