import { getResources, getStatus, resourceReducer } from 'redux-resource';

const gameLeaderboard = resourceReducer('gameLeaderboard');

export default gameLeaderboard;

export const getGameLeaderboard = gameId => state =>
  getResources(state.gamesV2.gameLeaderboard, gameId);

export const getGameLeaderboardStatus = gameId => state =>
  getStatus(state, `gamesV2.gameLeaderboard.requests.${gameId}.status`);
