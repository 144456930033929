import React from 'react';
import IconOpenInNew from '@material-ui/icons/OpenInNew';

import PropTypes from 'prop-types';

import { createWithStyles, font } from '../../styles';

const styles = {
  Link: theme => ({
    root: {
      color: theme ? theme.palette.primary.main : undefined,
      fontSize: font.getFontSize(0.875),
      cursor: 'pointer',
      fontWeight: '400',
      textDecoration: 'none',
    },

    isExternal: {
      textDecoration: 'underline',
    },

    isLarge: {
      fontSize: font.getFontSize(1.25),
    },

    sizeInherit: {
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },

    displayFlex: {
      display: 'flex',
    },

    alignCenter: {
      alignItems: 'center',
    },

    disabled: {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  }),
};

const styleIcon = {
  marginLeft: 4,
  verticalAlign: 'middle',
};

const Link = createWithStyles(styles.Link)('a');

const ArcLink = ({ href, onClick, onPress, disabled, children, ...rest }) => {
  let icon;

  const handleClick = () => {
    if (disabled) {
      return;
    }

    const clickHandler = onClick || onPress;

    if (clickHandler) {
      clickHandler();
    }
  };
  const isExternal = !!href && href.indexOf('http') === 0;

  if (isExternal) {
    icon = <IconOpenInNew fontSize="inherit" style={styleIcon} />;
  }

  return (
    <Link
      href={href}
      isExternal={isExternal}
      {...rest}
      onClick={handleClick}
      title={rest.title || children}
      target={isExternal ? '_blank' : undefined}
      disabled={disabled}
    >
      {children}
      {icon}
    </Link>
  );
};

ArcLink.propTypes = {
  onClick: PropTypes.func,
  onPress: PropTypes.func,
  children: PropTypes.node,
  href: PropTypes.string,
  size: PropTypes.oneOf(['inherit']),
  title: PropTypes.string,
  isLarge: PropTypes.bool,
  disabled: PropTypes.bool,
};

ArcLink.defaultProps = {
  onClick: undefined,
  onPress: undefined,
  children: undefined,
  href: undefined,
  size: undefined,
  title: '',
  isLarge: false,
  disabled: false,
};

export default ArcLink;
