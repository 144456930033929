import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ArcView } from 'arcade-frontend-ui';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import {
  getCurrentUser,
  getCurrentUserPermission,
  getCurrentUserFeatures,
} from 'arcade-frontend-core/src/reducers/user';

import PersonDetailsDialog from '../components/PersonDetailsDialog';
import { getPeopleById, getSortedPeopleIds } from '../reducers/people';
import { getTeamPeople } from '../reducers/teamPeople';
import { getIsFetching } from '../reducers/isFetching';
import { actions } from '../actions';
import { getPeopleDisplayType } from '../reducers/peopleDisplayType';
import PeopleList from '../components/PeopleList/PeopleList';

class PeopleContainer extends React.PureComponent {
  static propTypes = {
    canAwardChests: PropTypes.bool,
    canAwardTokens: PropTypes.bool,
    canClawbackTokens: PropTypes.bool,
    hasFeaturePeopleV2: PropTypes.bool,
    currentUser: PropTypes.shape({ id: PropTypes.string }),
    isFetching: PropTypes.bool,
    peopleById: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.string,
        badge: PropTypes.string,
        email: PropTypes.string,
        image: PropTypes.string,
        name: PropTypes.string,
        phone: PropTypes.string,
        team: PropTypes.string,
        level: PropTypes.number,
      }),
    ),
    peopleIds: PropTypes.arrayOf(PropTypes.string),
    teamPeople: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    peopleDisplayType: PropTypes.string,
    apiPeopleIndexRequest: PropTypes.func.isRequired,
    updatePeopleDisplayType: PropTypes.func.isRequired,
  };

  static defaultProps = {
    canAwardChests: false,
    canAwardTokens: false,
    canClawbackTokens: false,
    hasFeaturePeopleV2: false,
    currentUser: {},
    isFetching: false,
    peopleById: null,
    peopleIds: null,
    teamPeople: null,
    peopleDisplayType: '',
  };

  constructor(props) {
    super(props);

    props.apiPeopleIndexRequest();
  }

  state = {
    personDetailsDialogOpen: false,
    selectedPerson: null,
  };

  handleClickCard = (e, personId) => {
    this.showDetailsDialog(personId);
  };

  handleUpdatePeopleDisplayTypes = peopleDisplayType => {
    this.props.updatePeopleDisplayType(peopleDisplayType);
  };

  showDetailsDialog = personId =>
    this.setState({ personDetailsDialogOpen: true, selectedPerson: personId });

  closePersonDetailsDialog = () =>
    this.setState({
      personDetailsDialogOpen: false,
      selectedPerson: null,
    });

  renderPersonDetailsDialog() {
    return (
      <PersonDetailsDialog
        open={this.state.personDetailsDialogOpen}
        onClose={this.closePersonDetailsDialog}
        person={this.props.peopleById[this.state.selectedPerson]}
        canAwardChests={this.props.canAwardChests}
        canAwardTokens={this.props.canAwardTokens}
        canClawbackTokens={this.props.canClawbackTokens}
      />
    );
  }

  render() {
    return (
      <ArcView style={{ height: '100%', width: '100%' }}>
        <PeopleList
          onClickCard={this.handleClickCard}
          onUpdatePeopleDisplayTypes={this.handleUpdatePeopleDisplayTypes}
          peopleById={this.props.peopleById}
          peopleIds={this.props.peopleIds}
          canAwardChests={this.props.canAwardChests}
          canAwardTokens={this.props.canAwardTokens}
          hasFeaturePeopleV2={this.props.hasFeaturePeopleV2}
          teamPeople={this.props.teamPeople}
          isFetching={this.props.isFetching}
          currentUser={this.props.currentUser}
          peopleDisplayType={this.props.peopleDisplayType}
          onRefreshData={this.props.apiPeopleIndexRequest}
          onGetLockedPeople={this.props.apiPeopleIndexRequest}
        />

        {this.renderPersonDetailsDialog()}
      </ArcView>
    );
  }
}

const getState = (state, props) => ({
  peopleById: getPeopleById(state),
  peopleIds: getSortedPeopleIds(state),
  peopleDisplayType: getPeopleDisplayType(state),
  teamPeople: getTeamPeople(state),
  canAwardChests: getCurrentUserPermission(state, PERMISSIONS.AWARD_CHESTS),
  canAwardTokens: getCurrentUserPermission(
    state,
    PERMISSIONS.DISTRIBUTE_TOKENS,
  ),
  canClawbackTokens: getCurrentUserPermission(
    state,
    PERMISSIONS.CLAWBACK_TOKENS,
  ),
  hasFeaturePeopleV2: getCurrentUserFeatures(state, FEATURE_FLAGS.PEOPLE_V2),
  isFetching: getIsFetching(state),
  currentUser: getCurrentUser(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      apiPeopleIndexRequest: actions.apiPeopleIndexRequest,
      updatePeopleDisplayType: actions.updatePeopleDisplayType,
    },
    dispatch,
  );

export default connect(getState, getActions)(PeopleContainer);
