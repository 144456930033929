import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  ArcFormStepper,
  ArcConfirmDialog,
  ArcText,
  ArcForm,
  date,
  validators,
} from 'arcade-frontend-ui';

import Tooltip from '@material-ui/core/Tooltip';

import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import * as ASSIGN_TYPES from 'arcade-frontend-core/src/types/game-assignments';
import * as FORMAT_TYPES from 'arcade-frontend-core/src/types/game-formats';
import * as REWARD_TYPES from 'arcade-frontend-core/src/types/rewards';
import { getCurrentUserFeatures } from 'arcade-frontend-core/src/reducers/user';

import { actions } from '../../../actions/manage/games';

import { getManageGamesMetricsById } from '../../../reducers/manage/games/metricsById';
import { getManageGamesValidMetrics } from '../../../reducers/manage/games/validMetrics';
import { getRequestStatus } from '../../../reducers/manage/games/requestStatus';
import { getManageGamesEscrowAvailable } from '../../../reducers/manage/games/escrowAvailable';

import GameCardPreview from './GameCardPreview';
import GamesCreateConfirmationForm, {
  checkAutoVerifyType,
} from './GamesCreateConfirmationForm';
import GamesCreateStepOneForm from './GamesCreateStepOneForm';
import GamesCreateStepTwoForm from './GamesCreateStepTwoForm';
import GamesCreateStepThreeForm from './GamesCreateStepThreeForm';
import { getManageGamesErrors } from '../../../reducers/manage/games/manageGamesErrors';

const initialStartDateTime = date.toNextMinuteInterval(new Date(), 15);
const initialEndDateTime = date.addOneDay(initialStartDateTime);

const initialValues = {
  format: FORMAT_TYPES.RACE,
  name: '',
  metric: '',
  target: '',
  gameGoal: '',

  assignType: ASSIGN_TYPES.USERS,
  selectType: GamesCreateStepTwoForm.SELECT_TYPES.ALL,
  peopleSelector: {
    teamIds: [],
    peopleIds: [],
  },

  rewardType: REWARD_TYPES.CHESTS,
  rewards: {},

  recurringTimePeriod: 'none',
  recurringNumberOfDays: '',
  startDateTime: initialStartDateTime,
  endDateTime: initialEndDateTime,
  autoVerify: false,
  autoVerifyDaysAfterFinish: 1,
  autoVerifyAt: '',
  limitWinners: '',
};

const getInitialValues = currentGame => {
  if (currentGame && currentGame.id) {
    const { assign } = currentGame;
    const selectType =
      (assign && assign.indexOf('all') === 0) || assign === 'everyone'
        ? 'all'
        : 'selected';
    let assignType = assign;

    if (assign === 'all_teams') {
      assignType = 'teams';
    } else if (assign === 'everyone' || assign === 'people') {
      assignType = 'users';
    }

    const rewards = {};

    const currentGameRewards = currentGame.rewards || [];

    currentGameRewards.forEach(reward => {
      rewards[reward.receivingRank] = reward;
    });

    return {
      format: currentGame.typeRaw,
      metric: currentGame.metricId,
      name: currentGame.name,
      target: currentGame.target,
      gameGoal: currentGame.gameGoal,

      assignType,
      selectType,
      peopleSelector: {
        teamIds: currentGame.teamIds,
        peopleIds: currentGame.peopleIds,
      },

      rewardType: rewards[0] ? rewards[0].type : undefined,
      rewards,

      recurringTimePeriod: currentGame.recurringTimePeriod || 'weekly',
      recurringNumberOfDays: currentGame.recurringNumberOfDays,
      startDateTime: currentGame.startDate,
      endDateTime: currentGame.expiresAt,
      autoVerify: currentGame.autoVerify,
      autoVerifyAt: currentGame.autoVerifyAt,
      limitWinners: currentGame.limitWinners,
    };
  }

  return undefined;
};

export class GamesCreateForm extends React.Component {
  static displayName = 'GamesCreateForm';

  static getInitialValues = getInitialValues;

  static propTypes = {
    apiManageGamesCreateRequest: PropTypes.func,
    apiManageGamesEligiblePeopleIndexRequest: PropTypes.func,
    apiManageGamesUpdateRequest: PropTypes.func,
    apiManageGamesValidMetricsIndexRequest: PropTypes.func,
    apiGetEscrowableFundsRequest: PropTypes.func,
    currentGame: PropTypes.objectOf(PropTypes.any),
    inDialog: PropTypes.bool,
    initialValues: PropTypes.objectOf(PropTypes.any),
    isCloning: PropTypes.bool,
    manageGamesMetricsById: PropTypes.objectOf(PropTypes.any),
    onCancel: PropTypes.func,
    requestStatus: PropTypes.shape({
      MANAGE_GAMES_CREATE: PropTypes.string,
      MANAGE_GAMES_UPDATE: PropTypes.string,
    }),
    validMetrics: PropTypes.arrayOf(PropTypes.shape({})),
    escrowAvailable: PropTypes.number,
    hasEscrowFeature: PropTypes.bool,
    hasAutoVerifyFeature: PropTypes.bool,
    manageGamesErrors: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    apiManageGamesCreateRequest: global.noop,
    apiManageGamesEligiblePeopleIndexRequest: global.noop,
    apiManageGamesUpdateRequest: global.noop,
    apiManageGamesValidMetricsIndexRequest: global.noop,
    apiGetEscrowableFundsRequest: global.noop,
    currentGame: {},
    inDialog: false,
    initialValues,
    isCloning: false,
    manageGamesMetricsById: {},
    onCancel: global.noop,
    requestStatus: {},
    validMetrics: [],
    escrowAvailable: 0,
    hasAutoVerifyFeature: false,
    hasEscrowFeature: false,
    currentUserCreated: false,
    manageGamesErrors: {},
  };

  static FORMAT_TYPES = FORMAT_TYPES;

  constructor(props) {
    super(props);

    props.apiManageGamesValidMetricsIndexRequest();
    props.apiGetEscrowableFundsRequest();

    const { metric } = props.initialValues;
    if (metric) {
      props.apiManageGamesEligiblePeopleIndexRequest(metric);
    }
  }

  state = {
    activeStep: 0,
    isCancelling: false,
  };

  componentDidUpdate(prevProps) {
    const { metric } = this.props.initialValues;
    if (prevProps.initialValues?.metric !== metric && metric) {
      this.props.apiManageGamesEligiblePeopleIndexRequest(metric);
    }
  }

  get currentRequestStatus() {
    return this.props.requestStatus[this.requestStatus];
  }

  get requestStatus() {
    if (this.props.currentGame.id) {
      return 'MANAGE_GAMES_UPDATE';
    }

    return 'MANAGE_GAMES_CREATE';
  }

  get currentEscrowValue() {
    return this.props.currentGame.escrowedValue || 0;
  }

  get currentUserCreated() {
    return this.props.currentGame.currentUserCreated;
  }

  get isEditing() {
    return !!this.props.currentGame.id;
  }

  get validMetrics() {
    return this.props.validMetrics.map(validMetric => ({
      value: validMetric.id,
      label: validMetric.name,
    }));
  }

  setActiveStep = activeStep => this.setState({ activeStep });

  setIsCancelling = isCancelling => this.setState({ isCancelling });

  getTemplatesByFormat = values => {
    const { target, metric } = values || initialValues;

    const selectedMetric = this.props.manageGamesMetricsById[metric];

    const metricName = selectedMetric ? (
      <ArcText isStrong>{selectedMetric.name}</ArcText>
    ) : (
      <Tooltip title="Select a metric from step 1">
        <ArcText color="disabled">the selected metric</ArcText>
      </Tooltip>
    );

    const targetValue = target ? (
      <ArcText isStrong>{target}</ArcText>
    ) : (
      <Tooltip title="Select a target from step 1">
        <ArcText color="disabled">the target</ArcText>
      </Tooltip>
    );

    const templatesByFormat = {
      threshold: {
        name: 'Bounty',
        explainer: (
          <ArcText>
            {'Anyone who reaches'} {targetValue} {'for'} {metricName}{' '}
            {"before time's up gets a prize!"}
          </ArcText>
        ),
      },
      ranking: {
        name: 'Tournament',
        explainer: (
          <ArcText>
            {'Create a game for everyone to compete against one another!'}
            <br />
            {'The participants will be ranked on their performance for'}{' '}
            {metricName} {'and the top performers will be rewarded.'}
          </ArcText>
        ),
      },
      first: {
        name: 'Race',
        explainer: (
          <ArcText>
            {'The heat is on! The first one to hit'} {targetValue} {'for'}{' '}
            {metricName} {'will be rewarded.'}
          </ArcText>
        ),
      },
      rpa_one_time: {
        name: 'One Time',
        explainer: (
          <ArcText>
            {'Get it done! Players will earn a reward when they complete '}{' '}
            {metricName}
          </ArcText>
        ),
      },
      rpa_every_time: {
        name: 'Every Time',
        explainer: (
          <ArcText>
            {
              'Pump those numbers! Players will earn a reward every time they reach'
            }{' '}
            {targetValue} {'for'} {metricName}
          </ArcText>
        ),
      },
    };

    return templatesByFormat;
  };

  incrementActiveStep = (steps = 1) =>
    this.setState(prevState => ({
      activeStep: prevState.activeStep + steps,
    }));

  handleBack = () => this.incrementActiveStep(-1);

  handleCancel = () => this.setIsCancelling(true);

  handleCloseCancellingDialog = () => this.setIsCancelling(false);

  handleNext = () => this.incrementActiveStep(1);

  handleStep = step => this.setActiveStep(step);

  handleSubmit = values => {
    const normalizedValues = { ...values };
    const autoVerifyType = checkAutoVerifyType(values.format);

    if (autoVerifyType || !this.props.hasAutoVerifyFeature) {
      delete normalizedValues.autoVerify;
      delete normalizedValues.autoVerifyAt;
      delete normalizedValues.autoVerifyDaysAfterFinish;
    }

    if (this.props.currentGame.id) {
      this.props.apiManageGamesUpdateRequest(
        this.props.currentGame.id,
        normalizedValues,
      );
    } else {
      this.props.apiManageGamesCreateRequest(normalizedValues);
    }
  };

  totalRewardTokens = rewards => {
    let totalRewardTokens = 0;
    rewards.forEach(reward => {
      totalRewardTokens += parseInt(reward.value, 10);
    });
    return totalRewardTokens;
  };

  validateRewards(rewardType, rewards) {
    if (!rewards.length) return false;

    if (rewards.some(({ value }) => !value)) return false;

    if (rewardType === REWARD_TYPES.TOKENS && this.props.hasEscrowFeature) {
      const requiredEscrowValue =
        this.totalRewardTokens(rewards) - this.currentEscrowValue;
      if (this.props.escrowAvailable < requiredEscrowValue) return false;
    }

    return rewards.every(({ value }) => {
      if (!value) return false;

      switch (rewardType) {
        case REWARD_TYPES.CHESTS:
          return Object.values(value).some(
            qty => !!qty && qty >= 1 && qty <= 99,
          );
        case REWARD_TYPES.TOKENS:
          return validators.isNumber(value);
        default:
          return true;
      }
    });
  }

  renderActiveStep(formikProps) {
    return this.renderStep(this.state.activeStep, formikProps);
  }

  renderStep = (step, formikProps = {}) => {
    const validFormats = Object.entries(
      FORMAT_TYPES.DISPLAYABLE_TITLE_BY_TYPE,
    ).map(([value, label]) => ({ value, label }));

    switch (step) {
      case 0:
        return (
          <GamesCreateStepOneForm
            key={step}
            values={formikProps.values}
            touched={formikProps.touched}
            setFieldValue={formikProps.setFieldValue}
            setFieldTouched={formikProps.setFieldTouched}
            validMetrics={this.validMetrics}
          />
        );
      case 1:
        return (
          <GamesCreateStepTwoForm
            key={step}
            values={formikProps.values}
            setFieldValue={formikProps.setFieldValue}
            setFieldTouched={formikProps.setFieldTouched}
          />
        );
      case 2:
        return (
          <GamesCreateStepThreeForm
            key={step}
            errors={formikProps.errors}
            touched={formikProps.touched}
            values={formikProps.values}
            setFieldValue={formikProps.setFieldValue}
            setFieldTouched={formikProps.setFieldTouched}
            currentEscrowValue={this.currentEscrowValue}
            escrowAvailable={this.props.escrowAvailable}
            hasEscrowFeature={this.props.hasEscrowFeature}
            currentUserCreated={this.currentUserCreated}
            isEditing={this.isEditing}
          />
        );
      case 3:
        return (
          <GamesCreateConfirmationForm
            key={step}
            values={formikProps.values}
            setFieldValue={formikProps.setFieldValue}
            setFieldTouched={formikProps.setFieldTouched}
            summaryFields={GamesCreateStepOneForm.FIELDS}
            validMetrics={this.validMetrics}
            validFormats={validFormats}
            hasAutoVerifyFeature={this.props.hasAutoVerifyFeature}
          />
        );
      default:
        return null;
    }
  };

  renderGameCard = (formikProps = {}) => {
    const templatesByFormat = this.getTemplatesByFormat(formikProps.values);

    const template =
      templatesByFormat && templatesByFormat[formikProps.values.format]
        ? templatesByFormat[formikProps.values.format]
        : {};

    const { explainer } = template;
    const imageUrl = FORMAT_TYPES.IMAGE_BY_TYPE[formikProps.values.format];

    return <GameCardPreview explainer={explainer} imageUrl={imageUrl} />;
  };

  renderCancelDialog() {
    return (
      <ArcConfirmDialog
        title="Close editor?"
        content="You will lose all unsaved information."
        open={this.state.isCancelling}
        onClose={this.handleCloseCancellingDialog}
        onConfirm={this.props.onCancel}
        cancelLabel="No"
        confirmLabel="Yes"
      />
    );
  }

  renderForm = formikProps => {
    const getIsValid = (fields, errors) => {
      let isValid = true;

      fields.forEach(field => {
        if (errors[field]) {
          isValid = false;
        }
      });

      return isValid;
    };

    const {
      rewardType,
      peopleSelector,
      rewards,
      selectType,
      assignType,
      metric,
      startDateTime,
      endDateTime,
      recurringTimePeriod,
    } = formikProps.values;
    const { peopleIds, teamIds } = peopleSelector;

    let isValidStepTwo = false;

    if (selectType === GamesCreateStepTwoForm.SELECT_TYPES.ALL) {
      isValidStepTwo = true;
    } else {
      isValidStepTwo =
        assignType === ASSIGN_TYPES.USERS
          ? !!peopleIds && !!peopleIds.length
          : !!teamIds && !!teamIds.length;
    }

    const isValidStepThree = this.validateRewards(
      rewardType,
      Object.values(rewards),
    );

    const isValidByIndex = {
      0: getIsValid(GamesCreateStepOneForm.FIELDS, formikProps.errors),
      1: !!metric && isValidStepTwo,
      2: !!metric && isValidStepThree,
      3: getIsValid(GamesCreateConfirmationForm.FIELDS, formikProps.errors),
    };

    const isTouchedByIndex = {
      0: getIsValid(GamesCreateStepOneForm.FIELDS, formikProps.touched),
      1: getIsValid(GamesCreateStepTwoForm.FIELDS, formikProps.touched),
      2: getIsValid(GamesCreateStepThreeForm.FIELDS, formikProps.touched),
      3: getIsValid(GamesCreateConfirmationForm.FIELDS, formikProps.touched),
    };

    const endDateTimeHasError =
      date.isAfter(startDateTime, endDateTime) ||
      date.isEqual(startDateTime, endDateTime);

    const formHasError =
      !recurringTimePeriod ||
      (recurringTimePeriod === 'none' && endDateTimeHasError) ||
      (recurringTimePeriod === 'custom' &&
        !!formikProps.errors.recurringNumberOfDays);

    const formIsValid =
      Object.values(isValidByIndex).indexOf(false) === -1 && !formHasError;

    const submitLabel = this.props.isCloning ? 'Clone' : 'Finish';

    return (
      <ArcFormStepper
        activeStep={this.state.activeStep}
        hasConfirm
        isValid={formIsValid}
        onBack={this.handleBack}
        onCancel={this.handleCancel}
        onConfirm={this.handleStep}
        onFinish={formikProps.handleSubmit}
        onNext={this.handleNext}
        onStep={this.handleStep}
        requestStatus={this.currentRequestStatus}
        requestErrors={this.props.manageGamesErrors}
        submitLabel={submitLabel}
        steps={[
          {
            label: 'Details',
            isValid: isValidByIndex[0],
            isTouched: isTouchedByIndex[0],
          },
          {
            label: 'Participants',
            isValid: isValidByIndex[1],
            isTouched: isTouchedByIndex[1],
          },
          {
            label: 'Rewards',
            isValid: isValidByIndex[2],
            isTouched: isTouchedByIndex[2],
          },
        ]}
      >
        {this.state.activeStep > 0 && this.renderGameCard(formikProps)}
        {this.renderActiveStep(formikProps)}
        {this.renderCancelDialog()}
      </ArcFormStepper>
    );
  };

  render() {
    return (
      <ArcForm
        inDialog={this.props.inDialog}
        initialValues={{
          ...initialValues,
          ...this.props.initialValues,
        }}
        enableReinitialize
        onSubmit={this.handleSubmit}
      >
        {this.renderForm}
      </ArcForm>
    );
  }
}

const getState = (state, props) => ({
  manageGamesMetricsById: getManageGamesMetricsById(state),
  manageGamesErrors: getManageGamesErrors(state),
  validMetrics: getManageGamesValidMetrics(state),
  requestStatus: getRequestStatus(state),
  escrowAvailable: getManageGamesEscrowAvailable(state),
  hasEscrowFeature: getCurrentUserFeatures(state, FEATURE_FLAGS.GAME_ESCROW),
  hasAutoVerifyFeature: getCurrentUserFeatures(
    state,
    FEATURE_FLAGS.GAME_AUTO_VERIFY,
  ),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      apiManageGamesCreateRequest: actions.apiManageGamesCreateRequest,
      apiManageGamesEligiblePeopleIndexRequest:
        actions.apiManageGamesEligiblePeopleIndexRequest,
      apiManageGamesUpdateRequest: actions.apiManageGamesUpdateRequest,
      apiManageGamesValidMetricsIndexRequest:
        actions.apiManageGamesValidMetricsIndexRequest,
      apiGetEscrowableFundsRequest: actions.apiGetEscrowableFundsRequest,
    },
    dispatch,
  );

export default connect(getState, getActions)(GamesCreateForm);
