import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';

const useStyles = makeStyles(() => ({
  campaignImage: props => ({
    zIndex: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    backgroundImage: `url(${props.campaignImage})`,
    backgroundPosition: 'calc(100% - 16px) 48px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 120px',
  }),

  upper: {
    zIndex: 1,
    position: 'absolute',
    backgroundColor: 'rgba(103, 111, 128, 0.9)',
    width: '100%',
  },
}));

function GamesGameCardBackground({ campaignImage, ...props }) {
  const classes = useStyles({ campaignImage });

  const upperClasses = cx([classes.upper]);

  return (
    <>
      <ArcBox className={classes.campaignImage} />
      <ArcBox className={upperClasses} {...props} />
    </>
  );
}

GamesGameCardBackground.displayName = 'GamesGameCardBackground';

GamesGameCardBackground.propTypes = {
  campaignImage: PropTypes.string,
};

GamesGameCardBackground.defaultProps = {
  campaignImage: '',
};

export default GamesGameCardBackground;
