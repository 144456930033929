import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import { ArcFormDateRangePickerContainer } from 'arcade-frontend-widgets/src/containers/ArcFormDateRangePickerContainer';

import GamesCompletedContainer from '../../containers/GamesCompletedContainer';
import GamesPendingListContainer from '../../containers/GamesPendingListContainer';

function GamesCompletedTabPanel({ onCardClick }) {
  const [dateRange, setDateRange] = useState({
    type: 'time period',
    timePeriod: 'last_30_days',
  });

  return (
    <ArcBox className="animated fadeIn">
      <ArcBox mb={5}>
        <GamesPendingListContainer onCardClick={onCardClick} />
      </ArcBox>

      <ArcBox pb={1} display="flex" flexDirection="row" alignItems="center">
        <ArcBox flexGrow={1}>
          <ArcText sectionTitle>Completed Games</ArcText>
        </ArcBox>
        <ArcBox display="flex" flexDirection="row">
          <ArcFormDateRangePickerContainer
            value={dateRange}
            onChange={setDateRange}
          />
        </ArcBox>
      </ArcBox>
      <ArcBox pt={1} pb={1}>
        <GamesCompletedContainer dateRange={dateRange} />
      </ArcBox>
    </ArcBox>
  );
}

GamesCompletedTabPanel.displayName = 'GamesCompletedTabPanel';

GamesCompletedTabPanel.propTypes = {
  onCardClick: PropTypes.func,
};

GamesCompletedTabPanel.defaultProps = {
  onCardClick: global.noop,
};

export default GamesCompletedTabPanel;
