import moment from 'moment';
import { date } from 'arcade-frontend-ui';
import humps from 'humps';

import { DISPLAYABLE_TITLE_BY_TYPE } from 'arcade-frontend-core/src/types/game-formats';

const displayableFormat = format =>
  DISPLAYABLE_TITLE_BY_TYPE[format] || 'No Format Selected';

export const kpiGroup = props => ({
  ...props,
  id: props.id.toString(),
  title: props.title,
  symbol: props.unit,
});

const getRecurrencePeriodFrom = game => {
  const { recurrencePeriod } = game;

  switch (recurrencePeriod) {
    case 'daily':
    case 'weekly':
    case 'monthly':
      return recurrencePeriod;
    default:
      return recurrencePeriod ? 'custom' : 'none';
  }
};

const getRecurringNumberOfDaysFrom = game =>
  getRecurrencePeriodFrom(game) === 'custom'
    ? game.recurrencePeriod.toString()
    : undefined;

// Incoming
const fromManageGameIndex = props => ({
  id: props.campaign_id.toString(),
  status: props.status,
  winnerId: props.winner_id ? props.winner_id.toString() : null,
  imageUrl: props.campaign_image,
  currentUser: props.user_position || props.relevant_quest,
  currentAmount: props.user_score,
  expiresAt: props.expires_at && new Date(props.expires_at.raw),
  completedAt: props.completed_at && new Date(props.completed_at.raw),
  kpiGroup: props.kpi_group ? kpiGroup(props.kpi_group) : {},
  metricId: props.kpi_id ? props.kpi_id.toString() : null,
  name: props.name,
  overtake: props.overtake_information && {
    ...props.overtake_information,
    scoreDifference: props.overtake_information.score_difference,
  },
  rankings:
    props.rankings &&
    props.rankings.map(ranking => ({
      ...ranking,
      id: ranking.id.toString(),
    })),
  rules: props.rules,
  rewards: props.rewards.map(reward => ({
    ...reward,
    receivingRank: reward.receiving_rank,
    value: reward.type === 'tokens' ? parseInt(reward.value, 10) : reward.value,
  })),
  goal: props.goal,
  hasReachedGoal: props.has_reached_goal,
  participants: props.participants,
  startsAt: props.starts_at.raw,
  startsIn: moment(props.starts_at.formatted).fromNow(),
  timeRemaining: props.expires_at.formatted,
  displayableFormat: displayableFormat(props.type),
  type: props.type,
  winners: props.winners,
  escrowValue: props.escrow,
});

const fromManageGameList = props => ({
  ...props,
  id: props.id.toString(),
  startsAt: props.starts_at,
  expiresAt: props.expires_at,
  loot: parseInt(props.loot, 10),
  totalScore: parseInt(props.total_score, 10),
  tokensAwarded: parseInt(props.tokens_awarded, 10),
  escrowedFunds: props.escrowed_funds,
  currentUserCreated: props.current_user_created,
  participants: props.participants.map(p => ({
    ...p,
    id: p.id.toString(),
    assigneeId: p.assignee_id.toString(),
  })),
  rewards: props.rewards.map(reward => ({
    ...reward,
    receivingRank: reward.receiving_rank,
    value: reward.type === 'tokens' ? parseInt(reward.value, 10) : reward.value,
  })),
  type: props.type,
  displayableFormat: displayableFormat(props.type),
  gameGoal: props.game_goal,
});

const fromManageGameShow = props => {
  const game = humps.camelizeKeys(props);
  const recurringTimePeriod = getRecurrencePeriodFrom(game);
  const recurringNumberOfDays =
    recurringTimePeriod === 'custom'
      ? parseInt(game.recurrencePeriod, 10)
      : undefined;

  const form = {
    id: props.campaign_id.toString(),
    typeRaw: props.type,
    type: displayableFormat(props.type),
    target: props.rules.goal,
    gameGoal: props.game_goal,
    name: props.name,
    verb: props.verb,
    metric: props.kpi_group ? props.kpi_group.id.toString() : null,
    metricId: props.kpi_group ? props.kpi_group.id.toString() : null,
    people: props.users,
    teams: props.teams || [],
    startDate: props.starts_at ? new Date(props.starts_at.raw) : null,
    expiresAt: props.expires_at ? new Date(props.expires_at.raw) : null,
    rules: props.rules,
    recurrencePeriod: props.recurrence_period,
    recurring: !!game.recurrencePeriod,
    recurringTimePeriod,
    recurringNumberOfDays,
    assign: props.assign,
    peopleIds: props.user_ids ? props.user_ids.map(id => id.toString()) : [],
    teamIds: props.team_ids ? props.team_ids.map(id => id.toString()) : [],
    rewards: props.rewards.map(reward => ({
      ...reward,
      receivingRank: reward.receiving_rank,
      value:
        reward.type === 'tokens' ? parseInt(reward.value, 10) : reward.value,
    })),
    autoVerify: props.auto_verify,
    autoVerifyAt: props.auto_verify_at,
    limitWinners: props.limit_winners,
  };

  Object.keys(form).forEach(key => {
    if (form[key] === null || typeof form[key] === 'undefined') {
      form[key] = '';
    }
  });

  if (!form.startDate) {
    form.startDate = new Date();
  }

  return form;
};

const getTeamPeople = props => {
  const { team: teams, users } = props;

  if (!teams || !users) {
    return {};
  }

  const teamPeople = {};

  teams.forEach(team => {
    teamPeople[team.id] = teamPeople[team.id] || [];

    team.users.forEach(user => {
      teamPeople[team.id].push({
        ...user,
        id: user.id.toString(),
        imageUrl: user.image,
      });

      if (teamPeople[team.id].length === 0) {
        delete teamPeople[team.id];
      }
    });
  });

  return teamPeople;
};

const fromManageGameEligiblePeople = props => ({
  people: props.users.map(user => ({
    ...user,
    id: user.id.toString(),
    imageUrl: user.image,
  })),
  teams: props.team.map(team => ({
    id: team.id.toString(),
    name: team.name,
    peopleIds: team.users.map(({ id }) => id.toString()),
    peopleAmount: team.users.length,
    people: team.users.map(user => ({
      ...user,
      id: user.id.toString(),
      imageUrl: user.image,
    })),
    letter: window.teamDisplayTools.getTeamLetter(team.name),
    color: window.teamDisplayTools.getTeamColor(team.id),
  })),
  teamPeople: getTeamPeople(props),
});

const fromManageGameTemplate = props => ({
  name: props.name,
  imageUrl: props.image,
  endpoint: props.endpoint,
  explainer: props.explainer,
  type: props.type,
});

const fromGameRanking = props => ({
  id: props.user_id.toString(),
  name: props.username,
  score: props.progress,
  rank: props.rank,
  imageUrl: props.user_image,
});

const normalizeQuest = quest => ({
  id: quest.id.toString(),
  userId: quest.assignee_id.toString(),
  name: quest.name,
  score: quest.score,
  rank: quest.position,
  imageUrl: quest.image,
  letter: window.teamDisplayTools.getTeamLetter(quest.name),
  color: window.teamDisplayTools.getTeamColor(quest.id),
});

const normalizeRewards = reward => ({
  displayName: reward.display_name,
  receivingRank: reward.receiving_rank,
  type: reward.type,
});

const fromGameResults = props => ({
  id: props.id.toString(),
  rankedResults: props.ranked_quests.map(normalizeQuest),
  rewards: props.rewards.map(normalizeRewards),
});

const fromGameEvent = props => ({
  id: props.id.toString(),
  name: props.user_name,
  score: {
    unit: props.unit,
    quantity: props.quantity,
  },
  rank: null,
  imageUrl: props.user_image,
  createdAt: props.created_at,
  formattedCreatedAt: date.formatFullDateTime(props.created_at),
});

const getKpiGroupId = game => {
  if (game.kpiGroup && game.kpiGroup.id) {
    return game.kpiGroup.id.toString();
  }

  return undefined;
};

const fromManageGameCreateForm = game => ({
  name: game.name || undefined,
  verb: game.verb || undefined,
  metric: getKpiGroupId(game),
  format: game.typeRaw || undefined,
  target: game.goal ? game.goal.toString() : undefined,
  recurring: !!game.recurrencePeriod,
  recurringTimePeriod: getRecurrencePeriodFrom(game),
  recurringNumberOfDays: getRecurringNumberOfDaysFrom(game),

  assign: game.people.length ? 'users' : 'everyone',
  peopleIds: game.people.map(id => id.toString()),
  teamIds: game.teams.map(id => id.toString()),

  rewards: game.rewards.map(reward => ({
    ...reward,
    receivingRank: reward.receiving_rank,
    value: reward.type === 'tokens' ? parseInt(reward.value, 10) : reward.value,
  })),

  startDate: new Date(game.startDate),
  startTime: new Date(game.startDate),
  endDate: new Date(game.endDate),
  endTime: new Date(game.endDate),
});

const fromManageGamesValidMetricsIndex = props => ({
  ...props,
  id: props.id.toString(),
});

// Outgoing

const getRecurrencePeriodTo = form => {
  switch (form.recurringTimePeriod) {
    case 'custom':
      return parseInt(form.recurringNumberOfDays, 10);
    case 'daily':
    case 'weekly':
    case 'monthly':
    default:
      return form.recurringTimePeriod;
  }
};

const toManageGameCreateFormStepOne = form => ({
  name: form.name,
  type: form.format,
  kpi_group_id: form.metric,
  goal: form.target,
  game_goal: form.gameGoal,
});

const getStepTwoIds = form => {
  switch (form.assignType) {
    case 'users':
      return {
        quest_user_ids: form.peopleSelector.peopleIds,
        quest_team_ids: null,
      };
    case 'teams':
      return {
        quest_user_ids: null,
        quest_team_ids: form.peopleSelector.teamIds,
      };
    default:
      return {
        quest_user_ids: [],
        quest_team_ids: null,
      };
  }
};

const getAssignType = form => {
  if (form.selectType === 'all') {
    return form.assignType === 'teams' ? 'all_teams' : 'everyone';
  }

  return form.assignType;
};

const toManageGameCreateFormStepTwo = form => ({
  assign: getAssignType(form),
  ...getStepTwoIds(form),
});

const toManageGameCreateFormStepThree = form =>
  Object.values(form.rewards).map(reward => ({
    receiving_rank: reward.receivingRank,
    type: form.rewardType,
    value: reward.value,
  }));

const manageGameTime = (timeString, dateString) => {
  if (typeof timeString === 'undefined' || typeof dateString === 'undefined') {
    return '';
  }

  const selectedDate = new Date(dateString);
  const selectedTime = new Date(timeString);

  const startHour = selectedTime.getHours();
  const startMinutes = selectedTime.getMinutes();

  selectedDate.setHours(startHour);
  selectedDate.setMinutes(startMinutes);

  return selectedDate.toString();
};

const toManageGameCreateFormSummary = form => ({
  starts_at: form.startDateTime.toString(),
  expires_at: form.endDateTime.toString(),
  recurrence_period:
    form.recurringTimePeriod !== 'none'
      ? getRecurrencePeriodTo(form)
      : undefined,
  auto_verify: form.autoVerify,
  auto_verify_days_after_finish: form.autoVerifyDaysAfterFinish,
  auto_verify_at: form.autoVerifyAt,
  limit_winners: form.limitWinners,
});

const toManageGamesCreate = form => ({
  ...toManageGameCreateFormStepOne(form),
  ...toManageGameCreateFormStepTwo(form),
  rewards: toManageGameCreateFormStepThree(form),
  ...toManageGameCreateFormSummary(form),
});

const normalizers = {
  fromManageGameCreateForm,
  fromManageGameIndex,
  fromManageGameList,
  fromManageGameEligiblePeople,
  fromManageGameShow,
  fromManageGameTemplate,
  fromManageGamesValidMetricsIndex,

  fromGameRanking,
  fromGameResults,
  fromGameEvent,

  toManageGamesCreate,

  // Todo remove this export from FormGameCreateSummary
  manageGameTime,
};

export default normalizers;
