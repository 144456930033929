import React from 'react';
import PropTypes from 'prop-types';

import * as GAME_FORMATS from 'arcade-frontend-core/src/types/game-formats';
import * as GAME_STATUSES from 'arcade-frontend-core/src/types/game-statuses';
import * as GAME_PLAYER_STATUSES from 'arcade-frontend-core/src/types/game-player-statuses';
import { pluralize } from 'arcade-frontend-core/src/helpers/pluralize';
import { i18nFormatDateTime } from 'arcade-frontend-ui/src/helpers/utils/date';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import { ArcText } from 'arcade-frontend-ui';
import CalendarOutlined from 'arcade-frontend-ui/src/assets/CalendarOutlined';
import FlagOutlined from 'arcade-frontend-ui/src/assets/FlagOutlined';
import UsersOutlined from 'arcade-frontend-ui/src/assets/UsersOutlined';

import GamesRewardAndPlacementText from '../GamesRewardAndPlacementText';
import GamesGameCardMetricGoalText from './GamesGameCardMetricGoalText';
import GamesGameScene from '../GamesGameScene';

const DetailsRow = ({ iconComponent: Icon, title, subtitle }) => (
  <ArcBox
    display="flex"
    flexDirection="row"
    alignItems="center"
    py={1}
    px={1.5}
    color="common.black"
  >
    <Icon />
    <ArcBox display="flex" flexDirection="column" p={0.5}>
      <ArcText marginLeft="8" size16px isStrong>
        {title}
      </ArcText>
      {subtitle && (
        <ArcText marginLeft="8" size16px>
          {subtitle}
        </ArcText>
      )}
    </ArcBox>
  </ArcBox>
);

function GamesGameCardBody({
  borderRadius,
  expiresAt,
  completedAt,
  isManager,
  participantCount,
  status,
  startsAt,
  finalScore,
  score,
  rankings,
  rewards,
  rules,
  type,
  width,
  verifiedAt,
  winners,
  onClick,
  pendingCount,
  playerStatus,
}) {
  const getEndTimeByStatus = () => {
    switch (status) {
      case GAME_STATUSES.NEEDS_VERIFICATION:
        return i18nFormatDateTime(completedAt);
      case GAME_STATUSES.COMPLETED:
        return (
          i18nFormatDateTime(verifiedAt) || i18nFormatDateTime(completedAt)
        );
      default:
        return i18nFormatDateTime(expiresAt);
    }
  };

  const renderGameScene = () => (
    <GamesGameScene
      borderRadius={0}
      isManager={isManager}
      participantCount={participantCount}
      rankings={rankings}
      rewards={rewards}
      rules={rules}
      type={type}
      score={score}
      pendingCount={pendingCount}
      width="100%"
    />
  );

  const renderActiveBody = () => (
    <ArcBox
      overflow="hidden"
      position="relative"
      width={width}
      height={307}
      bgcolor="green.200"
      mx="auto"
      borderRadius={borderRadius}
    >
      {renderGameScene()}
    </ArcBox>
  );

  const renderUpcomingBody = () => {
    const { goal, metric } = rules;

    return (
      <ArcBox display="flex" flexDirection="column" p={1} mt={1}>
        <DetailsRow
          iconComponent={CalendarOutlined}
          title={`${i18nFormatDateTime(startsAt)} - ${getEndTimeByStatus()}`}
        />
        <DetailsRow
          iconComponent={FlagOutlined}
          title={
            <GamesGameCardMetricGoalText
              goal={goal}
              metric={metric}
              rewards={rewards}
              type={type}
            />
          }
        />
        <DetailsRow
          iconComponent={UsersOutlined}
          title={`${participantCount} ${pluralize(
            participantCount,
            'Participant',
          )}`}
        />
      </ArcBox>
    );
  };

  const renderCompletedBody = () => (
    <ArcBox display="flex" flexDirection="column" p={1}>
      <ArcBox
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        p={0.5}
        px={1}
        color="common.black"
      >
        <GamesRewardAndPlacementText
          playerStatus={playerStatus}
          winners={winners}
          finalScore={finalScore}
        />
      </ArcBox>
    </ArcBox>
  );

  const renderGameCardBody = () => {
    switch (status) {
      case GAME_STATUSES.ACTIVE:
        return renderActiveBody();
      case GAME_STATUSES.UPCOMING:
        return renderUpcomingBody();
      case GAME_STATUSES.NEEDS_VERIFICATION:
        return renderCompletedBody();
      case GAME_STATUSES.COMPLETED:
        return renderCompletedBody();
      default:
        return null;
    }
  };

  return (
    <ArcBox
      height={status === GAME_STATUSES.ACTIVE ? 307 : 0}
      bgcolor={status === GAME_STATUSES.ACTIVE ? 'none' : 'common.white'}
      flexGrow={100}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'auto' }}
    >
      {renderGameCardBody()}
    </ArcBox>
  );
}

GamesGameCardBody.displayName = 'GamesGameCardBody';

GamesGameCardBody.propTypes = {
  borderRadius: PropTypes.number,
  completedAt: PropTypes.string,
  expiresAt: PropTypes.string,
  isManager: PropTypes.bool,
  participantCount: PropTypes.number,
  rankings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      goal: PropTypes.number,
      isSelf: PropTypes.bool,
      rank: PropTypes.number,
      score: PropTypes.number,
    }),
  ),
  rewards: PropTypes.arrayOf(PropTypes.shape({})),
  rules: PropTypes.shape({
    metric: PropTypes.string,
    goal: PropTypes.number,
    unit: PropTypes.string,
  }),
  score: PropTypes.number,
  finalScore: PropTypes.number,
  status: PropTypes.string,
  startsAt: PropTypes.string,
  type: PropTypes.oneOf(GAME_FORMATS.GAME_TYPES),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  verifiedAt: PropTypes.string,
  winners: PropTypes.arrayOf(PropTypes.any),
  onClick: PropTypes.func,
  pendingCount: PropTypes.number,
  playerStatus: PropTypes.oneOf(GAME_PLAYER_STATUSES.ALL_PARTICIPANT_TYPES),
};

GamesGameCardBody.defaultProps = {
  borderRadius: 8,
  completedAt: '',
  expiresAt: '',
  isManager: false,
  participantCount: 0,
  rankings: [],
  rewards: [],
  rules: {
    metric: '',
    goal: 0,
  },
  score: 0,
  finalScore: 0,
  status: '',
  startsAt: '',
  type: GAME_FORMATS.RACE,
  width: 328,
  verifiedAt: '',
  winners: [],
  onClick: global.noop,
  pendingCount: 0,
  playerStatus: null,
};

DetailsRow.propTypes = {
  iconComponent: PropTypes.elementType,
  title: PropTypes.node,
  subtitle: PropTypes.string,
};

DetailsRow.defaultProps = {
  iconComponent: null,
  title: '',
  subtitle: null,
};

export default GamesGameCardBody;
