import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';

import {
  ArcPropTypes,
  ArcButton,
  ArcButtonGroup,
  ArcGrid,
  Typography,
  ArcImage,
  ArcFormField,
  ArcView,
  ArcText,
  ArcParagraph,
  createWithStyles,
} from 'arcade-frontend-ui';

import * as FORMAT_TYPES from 'arcade-frontend-core/src/types/game-formats';

import { actions } from '../../../actions/manage/games';
import { actions as actionsMetrics } from '../../../actions/metrics';

const EXPLAINERS_BY_FORMAT_TYPE = {
  [FORMAT_TYPES.BOUNTY]:
    "Any player who reaches the target before time's up gets a prize!",
  [FORMAT_TYPES.RACE]:
    'The heat is on! The first one to hit the target will be rewarded.',
  [FORMAT_TYPES.TOURNAMENT]:
    'The team members who finish in the top 3 will be rewarded!',
  [FORMAT_TYPES.RPA_ONE_TIME]:
    'Get it done! Players earn a reward for completing a single action',
  [FORMAT_TYPES.RPA_EVERY_TIME]:
    'Pump those numbers! Players earn rewards each time they complete an action',
};

const ORDERED_FORMAT_TYPES = [
  FORMAT_TYPES.RACE,
  FORMAT_TYPES.BOUNTY,
  FORMAT_TYPES.TOURNAMENT,
  FORMAT_TYPES.RPA_ONE_TIME,
  FORMAT_TYPES.RPA_EVERY_TIME,
];

const STRINGS = {
  'GAMES/RPA_TARGET_DESCRIPTION':
    'This indicates how much each player must achieve to be eligible for a reward.',
  'GAMES/RPA_TARGET_EXPLAINER':
    "Note: it's possible for a player to earn multiple rewards with one action (ex. target is 10, action logged is 20). Make sure to choose a challenging value.",
  'GAMES/GAME_GOAL_DESCRIPTION':
    'The Game Goal is used by Arcade to measure the success of your incentive. Enter the total score you are targeting for the game.',
};

const styleTitle = {
  fontWeight: '300',
  marginBottom: 32,
};

const styleImage = {
  marginBottom: 8,
};

const styleMutedImage = {
  ...styleImage,
  opacity: 0.5,
};

const styles = {
  GameTypeButtonGroup: theme => ({
    root: {
      width: 135,
      height: 135,
      marginRight: 12,
      marginBottom: 12,
      [theme.breakpoints.down(350)]: {
        maxWidth: 600,
        width: 115,
        height: 115,
        padding: 4,
      },
    },
  }),
};

const FIELDS = [
  'format',
  'metric',
  'name',
  'target',
  'rpaWinLimit',
  'gameGoal',
];

const GameTypeButtonGroup = createWithStyles(styles.GameTypeButtonGroup)(
  ArcButton,
);

class GamesCreateStepOneForm extends React.PureComponent {
  static FIELDS = FIELDS;

  static propTypes = {
    apiManageGamesEligiblePeopleIndexRequest: ArcPropTypes.func.isRequired,
    validMetrics: ArcPropTypes.arrayOf(ArcPropTypes.any),
    setFieldTouched: ArcPropTypes.func,
    setFieldValue: ArcPropTypes.func,
    values: ArcPropTypes.objectOf(ArcPropTypes.any),
    touched: ArcPropTypes.objectOf(ArcPropTypes.any),
  };

  static defaultProps = {
    setFieldTouched: global.noop,
    setFieldValue: global.noop,
    validMetrics: [],
    values: {},
    touched: {},
  };

  componentWillUnmount() {
    FIELDS.forEach(field => {
      this.props.setFieldTouched(field, true, false);
    });
  }

  get isTournament() {
    return this.props.values.format === FORMAT_TYPES.TOURNAMENT;
  }

  get isEveryTime() {
    return this.props.values.format === FORMAT_TYPES.RPA_EVERY_TIME;
  }

  get isRPA() {
    return FORMAT_TYPES.isRPAType(this.props.values.format);
  }

  handleClickFormat = type => {
    this.props.setFieldValue('format', type);

    if (FORMAT_TYPES.isRPAType(type) && !this.props.values.target) {
      this.props.setFieldValue('target', 1);
    } else if (
      !FORMAT_TYPES.isRPAType(type) &&
      !this.props.touched.target &&
      this.props.values.target === 1
    ) {
      this.props.setFieldValue('target', '');
    }
  };

  handleChangeMetric = e => {
    this.props.setFieldValue('peopleSelector', {
      teamIds: [],
      peopleIds: [],
    });

    this.props.apiManageGamesEligiblePeopleIndexRequest(e.target.value);
  };

  renderExplainer() {
    return (
      <ArcParagraph hasNoMargin style={{ maxWidth: 450 }}>
        {EXPLAINERS_BY_FORMAT_TYPE[this.props.values.format]}
      </ArcParagraph>
    );
  }

  render() {
    return (
      <ArcView style={{ maxWidth: 600 }}>
        <Typography style={styleTitle} variant="h5">
          {'Game Type'}
        </Typography>
        <ArcButtonGroup wrap="wrap" style={{ maxWidth: 450 }}>
          {ORDERED_FORMAT_TYPES.map(type => (
            <GameTypeButtonGroup
              key={type}
              aria-checked={
                this.props.values.format === type ? 'true' : 'false'
              }
              className={cx([this.props.values.format === type && 'isActive'])}
              label={FORMAT_TYPES.DISPLAYABLE_TITLE_BY_TYPE[type]}
              data-testid={type}
              onClick={() => this.handleClickFormat(type)}
            >
              <ArcView align="center">
                <ArcImage
                  width="64px"
                  height="64px"
                  src={FORMAT_TYPES.IMAGE_BY_TYPE[type]}
                  style={
                    this.props.values.format !== type
                      ? styleMutedImage
                      : styleImage
                  }
                />
                <ArcText style={{ textTransform: 'uppercase' }}>
                  {FORMAT_TYPES.DISPLAYABLE_TITLE_BY_TYPE[type]}
                </ArcText>
              </ArcView>
            </GameTypeButtonGroup>
          ))}
        </ArcButtonGroup>

        <ArcGrid component={ArcView} container spacing={2} wrap="nowrap">
          <ArcGrid item xs={12}>
            {this.renderExplainer()}
          </ArcGrid>

          <ArcGrid item xs={12}>
            <ArcFormField
              fullWidth
              name="name"
              type="text"
              label="Game Name"
              validations={{
                isRequired: true,
                maxLength: 30,
              }}
              autoComplete="off"
            />
          </ArcGrid>

          <ArcGrid item xs={12}>
            <ArcFormField
              fullWidth
              name="metric"
              type="select"
              description="Only metrics with at least 2 assigned people will be displayed in the drop down list."
              disabled={
                !this.props.validMetrics || !this.props.validMetrics.length
              }
              items={this.props.validMetrics}
              label="Metric / Score"
              onChange={this.handleChangeMetric}
              validations={{
                isRequired: true,
              }}
            />
          </ArcGrid>

          <ArcGrid item xs={12}>
            <ArcFormField
              fullWidth
              name="target"
              type="number"
              disabled={this.isTournament}
              label={
                this.isTournament
                  ? 'Participant Target is not required'
                  : 'Participant Target'
              }
              description={
                this.isRPA ? STRINGS['GAMES/RPA_TARGET_DESCRIPTION'] : ''
              }
              validations={{
                isRequired: !this.isTournament,
              }}
            />

            {this.isEveryTime && (
              <ArcView style={{ maxWidth: 435 }}>
                <ArcText style={{ lineHeight: '150%', fontSize: 14 }}>
                  {STRINGS['GAMES/RPA_TARGET_EXPLAINER']}
                </ArcText>
              </ArcView>
            )}
          </ArcGrid>
          <ArcGrid item xs={12}>
            <ArcFormField
              fullWidth
              name="gameGoal"
              type="number"
              label="Game Goal"
              description={STRINGS['GAMES/GAME_GOAL_DESCRIPTION']}
              validations={{
                isRequired: true,
              }}
            />
          </ArcGrid>
        </ArcGrid>
      </ArcView>
    );
  }
}

const getActions = dispatch =>
  bindActionCreators(
    {
      apiManageGamesEditRequest: actions.apiManageGamesEditRequest,
      apiManageGamesCreateRequest: actions.apiManageGamesCreateRequest,
      apiManageGamesEligiblePeopleIndexRequest:
        actions.apiManageGamesEligiblePeopleIndexRequest,
      apiMetricsIndexRequest: actionsMetrics.apiMetricsIndexRequest,
    },
    dispatch,
  );

export default connect(null, getActions)(GamesCreateStepOneForm);
