import createApiAction from 'arcade-frontend-core/src/helpers/createApiAction';

export const apiChatSearch = createApiAction('CHAT/SEARCH', {
  api: payload => ({
    method: 'PUT',
    url: '/v4/conversations/search',
    params: {
      search_term: payload.searchValue,
      page: 0,
      per_page: 20,
    },

    transformResponse: data =>
      data.conversations.map(conversation => ({
        ...conversation,
        id: conversation.id.toString(),
        participants: conversation.participants.map(participant => ({
          ...participant,
          id: participant.id.toString(),
        })),
      })),
  }),
});
