import {
  ArcPropTypes,
} from 'arcade-frontend-ui';

import getComponentFromDirective from 'arcade-frontend-core/src/helpers/getComponentFromDirective';
import * as types from 'arcade-frontend-core/src/types/routes';

const { PLATFORMS } = ArcPropTypes;

const ArcadeAngularChatView = getComponentFromDirective('arcadeMessaging');

const base = {
  [types.ROUTE_CHAT]: ArcadeAngularChatView,
  [types.ROUTE_CHAT_SHOW]: ArcadeAngularChatView,
  [types.ROUTE_CHAT_CREATE]: ArcadeAngularChatView,
  [types.ROUTE_CHAT_JOIN]: ArcadeAngularChatView,
};

const benjo = base;
const development = base;
const launch = base;

export default {
  [PLATFORMS.BENJO]: benjo,
  [PLATFORMS.DEVELOPMENT]: development,
  [PLATFORMS.LAUNCH]: launch,
};
