import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/people';

const handleManagePeopleProfileSuccess = (state, action) => {
  const { data } = action.payload;

  return data;
};


const initialState = {};

const handlers = {
  [types.MANAGE_PEOPLE_PROFILE.SUCCESS]: handleManagePeopleProfileSuccess,
};

const currentProfile = createReducer(initialState, handlers);

export default currentProfile;

export const getCurrentProfile = state => state.manage.people.currentProfile;
