import React from 'react';

function LightBody(props) {
  return (
    <svg viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.6746 11.149C10.3944 8.03805 8.49147 2.02581e-07 5.54485 -5.84689e-08C2.59822 -3.19519e-07 0.0038872 8 0.00388721 11.149C0.284007 14.26 2.59822 13.4326 5.54485 13.4326C8.49147 13.4326 10.9547 14.26 10.6746 11.149Z" fill="currentColor" />
    </svg>
  );
}

LightBody.displayName = 'LightBody';

export default LightBody;
