import React from 'react';
import PropTypes from 'prop-types';

import ArcImage from '../../primitives/ArcImage';

import vimeoLogoSvg from './vimeo-icon.svg';
import youtubeLogoSvg from './youtube-icon.svg';


const TYPES = {
  VIMEO: 'vimeo',
  YOUTUBE: 'youtube',
};

const SRC_BY_TYPE = {
  [TYPES.VIMEO]: vimeoLogoSvg,
  [TYPES.YOUTUBE]: youtubeLogoSvg,
};


const BrandLogoImage = ({ type, ...props }) => (
  <ArcImage
    {...props}
    src={SRC_BY_TYPE[type]}
  />
);

BrandLogoImage.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
};

export default BrandLogoImage;
