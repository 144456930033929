import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const initialState = {};

const handleGetViewsSuccess = (state, action) => {
  const { data } = action.payload;
  const { id } = action.meta.requestAction.payload;
  return { ...state, [id]: data };
};

const handlers = {
  [types.NEWSFEED_GET_ACTIVITY_VIEWS.SUCCESS]: handleGetViewsSuccess,
};

const views = state => state.newsfeed.views;

export default createReducer(initialState, handlers);

export const selectors = { views };
