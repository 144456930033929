import React, { FC } from 'react';

import { ChakraProvider } from '@workplacearcade/ui';
import theme from '@workplacearcade/ui/styles/theme';

import CbLogin from './CbLogin';

export interface CbLoginContainerProps {}
const CbLoginContainer: FC<CbLoginContainerProps> = ({}) => {
  return (
    <ChakraProvider theme={theme}>
      <CbLogin />
    </ChakraProvider>
  );
};

export default CbLoginContainer;
