import React from 'react';
import PropTypes from 'prop-types';

import { Path } from 'react-primitives-svg';
import Icon from '../Icon';

const ChevronDown = props => (
  <Icon {...props}>
    <Path
      fill={props.fill || 'transparent'}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.376 19.342l10.8-12.47a.5.5 0 00-.052-.707L21.3 4.606a.5.5 0 00-.706.056l-8.4 9.892a.249.249 0 01-.381 0l-8.4-9.892a.5.5 0 00-.713-.056L.881 6.165a.5.5 0 00-.053.707l10.792 12.47a.5.5 0 00.756 0z"
    />
  </Icon>
);

ChevronDown.displayName = 'ChevronDown';

ChevronDown.propTypes = {
  fill: PropTypes.string,
};

ChevronDown.defaultProps = {
  fill: 'transparent',
};

export default ChevronDown;
