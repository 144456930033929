import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../../actions/manage/metrics/types';

const handleMetricActivitiesLoadSuccess = (state, action) => {
  const { metricId, data } = action.payload;

  return {
    ...state,
    [metricId]: data,
  };
};

const initialState = {};

const handlers = {
  [types.MANAGE_METRICS_ACTIVITIES_INDEX.SUCCESS]: handleMetricActivitiesLoadSuccess,
};

const activitiesByMetricId = createReducer(initialState, handlers);

export default activitiesByMetricId;
