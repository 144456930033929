import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ArcPropTypes from '../helpers/arc/propTypes';
import ArcStyles from '../styles';

const style = {
  marginBottom: 24,
  color: ArcStyles.theme.arcTextColorMuted,
};

const styleChecked = {
  color: ArcStyles.theme.brandPrimary,
};

const styleCheckedWithMargin = {
  ...style,
  ...styleChecked,
};

class ArcFormRadioButton extends React.PureComponent {
  static propTypes = {
    checked: ArcPropTypes.bool,
    label: ArcPropTypes.string,
  };

  static defaultProps = {
    checked: undefined,
    label: undefined,
  };

  render() {
    const { checked, ...rest } = this.props;

    if (this.props.label) {
      return (
        <FormControlLabel
          control={(
            <Radio
              checked={checked}
              style={checked ? styleChecked : undefined}
            />
          )}
          {...rest}
        />
      );
    }

    return (
      <Radio
        style={checked ? styleCheckedWithMargin : style}
        {...this.props}
      />
    );
  }
}

export default ArcFormRadioButton;
