import React from 'react';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcCheckbox from '../../elements/ArcCheckbox/ArcCheckbox';
import ArcPlaceholder from '../ArcPlaceholder';
import ArcView from '../../primitives/ArcView/ArcView';
import ArcPerson from './ArcPerson';
import ArcTeam from './ArcTeam';
import { styles } from './ArcPeopleListItem.style';

const stylePlaceholder = {
  color: 'rgb(102, 102, 102)',
  lineHeight: '24px',
  padding: '16px 8px',
  borderBottom: '1px solid rgb(243, 243, 243)',
  flex: '2 0 auto',
  height: 64,
};

const stylePlaceholderImage = {

  backgroundColor: '#f3f3f3',
  width: 40,
  marginRight: 16,
};

const Placeholder = () => (
  <ArcView row style={stylePlaceholder}>
    <ArcView style={stylePlaceholderImage}>
      <ArcPlaceholder />
    </ArcView>
    <ArcPlaceholder />
  </ArcView>
);

class ArcPeopleListItem extends React.PureComponent {
  static displayName = 'ArcPeopleListItem';

  static propTypes = {
    id: ArcPropTypes.id.isRequired,
    name: ArcPropTypes.string.isRequired,

    onChange: ArcPropTypes.func,

    selectedPeople: ArcPropTypes.ids,

    checked: ArcPropTypes.bool,
    hasNoBorderBottom: ArcPropTypes.bool,
    hasPanelView: ArcPropTypes.bool,
    isPartiallyChecked: ArcPropTypes.bool,
    isSelectable: ArcPropTypes.bool,
    imageUrl: ArcPropTypes.string,
    type: ArcPropTypes.string,
    teamName: ArcPropTypes.string,
  };

  static defaultProps = {
    onChange: ArcPropTypes.noop,
    selectedPeople: [],
    checked: false,
    hasNoBorderBottom: false,
    hasPanelView: false,
    isPartiallyChecked: false,
    isSelectable: false,
    imageUrl: undefined,
    type: undefined,
    teamName: undefined,
  };

  static Placeholder = Placeholder;

  get styleWidth() {
    if (this.props.hasPanelView) {
      return this.props.type === 'people' ? { width: 'calc(100% - 32px)' } : { width: '100%' };
    }
    return null;
  }

  handleClick = (event) => {
    this.props.onChange(event, !this.props.checked, this.props.id);
  };

  handleChangeCheckbox = (event, checked) => {
    this.props.onChange(event, checked, this.props.id);
  };

  render() {
    const {
      id,
      name,
      imageUrl,
      isPartiallyChecked,
      isSelectable,
      selectedPeople,
      checked,
      onChange,
      hasNoBorderBottom,
      hasPanelView,
      ...rest
    } = this.props;

    if (isSelectable) {
      return (
        <ArcView
          row
          align="center"
          style={this.styleWidth}
        >
          {this.props.type === 'teams' ? (
            <ArcTeam
              name={name}
              style={styles(this.props)}
              onClick={this.handleClick}
              isSelected={checked}
              {...rest}
            />
          ) : (
            <ArcPerson
              name={name}
              imageUrl={imageUrl}
              style={styles(this.props)}
              onClick={this.handleClick}
              {...rest}
            />
          )}
          <ArcView>
            <ArcCheckbox
              id={id}
              checked={
                !isPartiallyChecked && (checked || selectedPeople.indexOf(id) !== -1)
              }
              partiallyChecked={isPartiallyChecked}
              onChange={this.handleChangeCheckbox}
            />
          </ArcView>
        </ArcView>
      );
    }

    if (this.props.type === 'teams') {
      return (
        <ArcTeam
          name={name}
          style={styles(this.props)}
          {...rest}
        />
      );
    }

    return (
      <ArcPerson
        name={name}
        imageUrl={imageUrl}
        style={styles(this.props)}
        {...rest}
      />
    );
  }
}

export default ArcPeopleListItem;
