import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';

import { data, resources } from '../../resources/notifications';
import NotificationsPanelMyInbox from '../../components/NotificationsPanelMyInbox';

const NotificationsPanelMyInboxContainer = () => {
  const currentUser = useSelector(getCurrentUser);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(null);

  const apiGetNotificationsMyInbox = useResourceAction(
    resources.apiGetNotificationsMyInbox,
  );

  const {
    notifications: notificationsRaw,
    unread_count: unreadCountRaw,
  } = data.apiGetNotificationsMyInboxRaw;

  const getInboxNotifications = () => {
    apiGetNotificationsMyInbox.requestData().then(() => {
      setNotifications(notificationsRaw);
      setUnreadCount(unreadCountRaw);
    });
  };

  const handleRefresh = () => {
    getInboxNotifications();
  };

  useEffect(() => {
    if (currentUser.id) {
      getInboxNotifications();
    }
  }, [currentUser.id]);

  return (
    <NotificationsPanelMyInbox
      notifications={notifications}
      onRefresh={handleRefresh}
      statusGetNotifications={apiGetNotificationsMyInbox.status}
      unreadCount={unreadCount}
    />
  );
};

NotificationsPanelMyInboxContainer.displayName =
  'NotificationsPanelMyInboxContainer';

export default NotificationsPanelMyInboxContainer;
