import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import { actions as quicklinkActions } from 'arcade-frontend-people/src/actions';

import { actions } from '../../actions';
import { selectors as activitySelectors } from '../../reducers/tagged_activities';
import {
  getReactionsByActivityId,
  getReactionsIsRequestingByActivityId,
} from '../../reducers/activity_reactions';
import { getRequestStatus } from '../../reducers/requestStatus';
import { getCommentCreateActivityId } from '../../reducers/rrComments';
import NewsfeedSummaryDialog from '../../components/NewsfeedSummaryDialog';

class TaggedActivitiesDialogContainer extends React.Component {
  static propTypes = {
    activities: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentTag: PropTypes.string,
    onRespect: PropTypes.func,
    onCommentDelete: PropTypes.func,
    onCommentSave: PropTypes.func,
    onQuicklinkUser: PropTypes.func,
    savingCommentId: PropTypes.string,
    commentParticipants: PropTypes.arrayOf(PropTypes.string),
    user: PropTypes.shape({}),
    reactionsByActivityId: PropTypes.shape({}),
    reactionsIsRequestingByActivityId: PropTypes.shape({}),
    onReactionSelect: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    requestStatus: PropTypes.shape({
      NEWSFEED_TAGGED_ACTIVITIES: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    currentTag: null,
    onRespect: global.noop,
    onCommentDelete: global.noop,
    onCommentSave: global.noop,
    savingCommentId: null,
    commentParticipants: [],
    user: {},
    reactionsByActivityId: {},
    reactionsIsRequestingByActivityId: {},
    onReactionSelect: global.noop,
    onQuicklinkUser: global.noop,
    onClose: global.noop,
    onOpen: global.noop,
  };

  componentDidMount() {
    if (global.canUseDOM && window.ngRootScope) {
      this.rootscopeListener = window.ngRootScope.$on(
        'newsfeed:modal:tagged:show',
        this.openDialog,
      );
    }
  }

  componentWillUnmount() {
    if (this.rootscopeListener) {
      this.rootscopeListener();
    }
  }

  get title() {
    return `Activities tagged '${this.props.currentTag}'`;
  }

  get isLoading() {
    return this.props.requestStatus.NEWSFEED_TAGGED_ACTIVITIES === 'REQUEST';
  }

  openDialog = (_, tag) => {
    this.props.onOpen(tag);
  };

  render() {
    return (
      <NewsfeedSummaryDialog
        title={this.title}
        open={!!this.props.currentTag}
        summaries={this.props.activities}
        isLoading={this.isLoading}
        onRespect={this.props.onRespect}
        onReactionSelect={this.props.onReactionSelect}
        onCommentSave={this.props.onCommentSave}
        onCommentDelete={this.props.onCommentDelete}
        commentParticipants={this.props.commentParticipants}
        savingCommentId={this.props.savingCommentId}
        reactionsByActivityId={this.props.reactionsByActivityId}
        reactionsIsRequestingByActivityId={
          this.props.reactionsIsRequestingByActivityId
        }
        currentUser={this.props.user}
        onQuicklinkUser={this.props.onQuicklinkUser}
        onClose={this.props.onClose}
      />
    );
  }
}

const getState = (state, props) => ({
  currentTag: activitySelectors.getCurrentTag(state),
  activities: activitySelectors.getTaggedActivities(state),
  savingCommentId: getCommentCreateActivityId(state),
  reactionsByActivityId: getReactionsByActivityId(state),
  reactionsIsRequestingByActivityId: getReactionsIsRequestingByActivityId(
    state,
  ),
  user: getCurrentUser(state),
  requestStatus: getRequestStatus(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      taggedActivities: actions.taggedActivities,
      onReactionSelect: actions.saveActivityReaction,
      onRespect: actions.toggleRespectAndUpdateActivity,
      onCommentDelete: actions.deleteCommentAndUpdateActivity,
      onCommentSave: actions.saveCommentAndUpdateActivity,
      onQuicklinkUser: quicklinkActions.showQuicklinksUser,
      onOpen: actions.openTaggedActivities,
      onClose: actions.closeTaggedActivities,
    },
    dispatch,
  );

export default connect(getState, getActions)(TaggedActivitiesDialogContainer);
