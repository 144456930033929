import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const handleResetPasswordStatus = (state, action) => action.payload.status;
const handleResetPasswordRequest = () => null;

const initialState = null;

const actions = {
  [types.APP_RESET_PASSWORD.SUCCESS]: handleResetPasswordStatus,
  [types.APP_RESET_PASSWORD.FAILURE]: handleResetPasswordStatus,
  [types.APP_RESET_PASSWORD.REQUEST]: handleResetPasswordRequest,
};

const passwordResetStatusCode = createReducer(initialState, actions);

export default passwordResetStatusCode;

export const getPasswordResetStatusCode = state => state.app.passwordResetStatusCode;
