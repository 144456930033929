import React from 'react';
import PropTypes from 'prop-types';
import ChevronRight from '@material-ui/icons/ChevronRight';

import {
  ArcView,
  ArcText,
  ArcImage,
  ArcEntity,
  createWithStyles,
} from 'arcade-frontend-ui';

const styles = {
  ResultRow: theme => ({
    root: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      alignItems: 'center',
      cursor: 'pointer',
      '&:not(:last-child)': {
        borderWidth: 0,
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid',
        borderBottomWidth: 1,
      },

      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
  }),
  ResultImage: theme => ({
    root: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      borderRadius: 4,
    },
  }),
  ChevronWrapper: theme => ({
    root: {
      fontSize: theme.spacing(8),
      color: '#B5B5B5',
    },
  }),
};

const ResultRow = createWithStyles(styles.ResultRow)(ArcView);
const ResultImage = createWithStyles(styles.ResultImage)(ArcImage);
const ChevronWrapper = createWithStyles(styles.ChevronWrapper)(ArcView);

function ChatSearchResult({
  currentUserId,
  id,
  name,
  image,
  participants,
  conversationType,
  onClick,
}) {
  const participantsWithoutCurrentUser = React.useMemo(
    () => participants.filter(participant => participant.id !== currentUserId),
    [participants, currentUserId],
  );

  const initialParticipants = participantsWithoutCurrentUser.slice(0, 3);

  const participantNames = initialParticipants.map(p => p.name).join(', ');

  const { length } = participantsWithoutCurrentUser;

  let displayName = name;

  let subtitle =
    length > 3
      ? `${participantNames} and ${length - 3} more`
      : participantNames;

  if (!name) {
    switch (participantsWithoutCurrentUser.length) {
      case 1:
        displayName = participantsWithoutCurrentUser[0].name;
        subtitle = 'Direct Message';
        break;
      default:
        displayName = subtitle;
        subtitle = 'Group Chat';
    }
  }

  return (
    <ResultRow row onClick={() => onClick(id)}>
      {conversationType === 'group' ? (
        <ArcEntity letter={displayName.charAt(0)} color="#0580b8" />
      ) : (
        <ResultImage src={image} alt={displayName} />
      )}
      <ArcView marginLeft={16} flex={1} overflow="hidden">
        <ArcText marginBottom="8" size="14px" isStrong textOverflow="ellipsis">
          {displayName}
        </ArcText>
        <ArcText size="12px" textOverflow="ellipsis">
          {subtitle}
        </ArcText>
      </ArcView>

      <ChevronWrapper>
        <ChevronRight />
      </ChevronWrapper>
    </ResultRow>
  );
}

ChatSearchResult.propTypes = {
  currentUserId: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  conversationType: PropTypes.oneOf(['conversation', 'group', 'channel']),
  onClick: PropTypes.func,
};

ChatSearchResult.defaultProps = {
  id: '',
  name: '',
  image: '',
  participants: [],
  conversationType: 'conversation',
  onClick: PropTypes.func,
};

export default React.memo(ChatSearchResult);
