import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const handleRewardsTransactionsIndexSuccess = (state, action) => {
  const { data } = action.payload;
  const { transactions } = data;
  const newState = { ...state };

  transactions.forEach(transaction => {
    newState[transaction.id] = transaction;
  });

  return newState;
};

const initialState = {};

const handlers = {
  [types.REWARDS_TRANSACTIONS_INDEX
    .SUCCESS]: handleRewardsTransactionsIndexSuccess,
  [types.REWARDS_TRANSACTIONS_INDEX_BY_USER_ID
    .SUCCESS]: handleRewardsTransactionsIndexSuccess,
};

const transactionsById = createReducer(initialState, handlers);

export default transactionsById;

export const getTransactionsById = state => state.rewards.transactionsById;
