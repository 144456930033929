import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcNoResultsMessage from 'arcade-frontend-ui/src/components/ArcNoResultsMessage';
import ArcToken from 'arcade-frontend-ui/src/assets/ArcToken';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcLink from 'arcade-frontend-ui/src/elements/ArcLink';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import { getLocationQuery } from 'arcade-frontend-features/src/reducers/location';
import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';

const STRINGS = {
  MANAGER_SUBHEADER:
    'You have no games scheduled for your employees. Easily launch over six exciting game types designed to keep everyone engaged and productive.',
};

const UserSubHeader = () => (
  <ArcText>
    There are no active games at the moment. Check back here later!
  </ArcText>
);

const GamesActiveEmpty = ({ isManager }) => {
  const dispatch = useDispatch();
  const locationQuery = useSelector(getLocationQuery);

  const handleCreate = () => {
    const routeAction = {
      type: ROUTE_TYPES.ROUTE_GAMES_CREATE,
      payload: {},
      query: locationQuery,
    };

    dispatch(routeAction);
  };

  return (
    <ArcBox maxWidth={400}>
      <ArcNoResultsMessage
        iconComponent={() => (
          <ArcToken variant="grey" height={175} width={175} />
        )}
        header="No Active Games"
        subheader={isManager ? STRINGS.MANAGER_SUBHEADER : <UserSubHeader />}
      />
      {isManager && (
        <ArcBox
          display="flex"
          flexDirection="row"
          justifyContent="center"
          mt={4}
        >
          <ArcBox display="flex" flexDirection="row" alignItems="center" mr={2}>
            <ArcLink size="inherit" href="https://help.arcade.co/games">
              Learn More
            </ArcLink>
          </ArcBox>
          <ArcButton
            variant="contained"
            color="primary"
            label="Create a game"
            onClick={handleCreate}
          />
        </ArcBox>
      )}
    </ArcBox>
  );
};

GamesActiveEmpty.displayName = 'GamesActiveEmpty';

GamesActiveEmpty.propTypes = {
  isManager: PropTypes.bool,
};

GamesActiveEmpty.defaultProps = {
  isManager: false,
};

export default GamesActiveEmpty;

// iconComponent: SearchIcon,
// iconStyles: {},
// shapeStyles: {},
// header: 'No results found',
// subheader: null,
// showShape: true,
