import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/people';

const handleManagePeopleProfileAction = (state, action) => {
  if (action.type === types.MANAGE_PEOPLE_PROFILE.REQUEST) {
    return { ...state, profile: true };
  }
  return { ...state, profile: false };
};

const handleManagePeopleAction = (state, action) => {
  if (action.type === types.MANAGE_PEOPLE_INDEX.REQUEST) {
    return { ...state, people: true };
  }
  return { ...state, people: false };
};

const handleManagePeoplePermissionsAction = (state, action) => {
  if (action.type === types.MANAGE_PEOPLE_PERMISSIONS_INDEX.REQUEST) {
    return { ...state, permissions: true };
  }
  return { ...state, permissions: false };
};

const initialState = { profile: false };

const handlers = {
  [types.MANAGE_PEOPLE_PROFILE.REQUEST]:
    handleManagePeopleProfileAction,
  [types.MANAGE_PEOPLE_PROFILE.SUCCESS]:
    handleManagePeopleProfileAction,
  [types.MANAGE_PEOPLE_PROFILE.FAILURE]:
    handleManagePeopleProfileAction,
  [types.MANAGE_PEOPLE_INDEX.REQUEST]:
    handleManagePeopleAction,
  [types.MANAGE_PEOPLE_INDEX.SUCCESS]:
    handleManagePeopleAction,
  [types.MANAGE_PEOPLE_INDEX.FAILURE]:
    handleManagePeopleAction,
  [types.MANAGE_PEOPLE_PERMISSIONS_INDEX.REQUEST]:
    handleManagePeoplePermissionsAction,
  [types.MANAGE_PEOPLE_PERMISSIONS_INDEX.SUCCESS]:
    handleManagePeoplePermissionsAction,
  [types.MANAGE_PEOPLE_PERMISSIONS_INDEX.FAILURE]:
    handleManagePeoplePermissionsAction,
};

const isFetching = createReducer(initialState, handlers);

export default isFetching;

export const getIsFetching = state => state.manage.people.isFetching;
