import { Platform } from 'react-native';
import asyncStorage from '@callstack/async-storage';

import axios from 'axios';
import humps from 'humps';
import Resource from 'arcade-frontend-ui/src/helpers/utils/resource';

import * as ROUTES from 'arcade-frontend-core/src/types/routes';
import RollbarClient from 'arcade-frontend-ui/src/providers/RollbarProvider/client';
import getEndpointsByEmail from '../getEndpointsByEmail';

import { resources } from '../../resources/users';

export const CURRENT_USER_KEY = Platform.select({
  web: 'user',
  default: 'currentUser',
});

export const ROUTE_KEY = 'arcadeRoute';

export async function getAsyncStorageCurrentUser() {
  let currentUser;
  try {
    currentUser = await asyncStorage.getItem(CURRENT_USER_KEY);
    currentUser = humps.camelizeKeys(JSON.parse(currentUser));

    if (currentUser && currentUser.email) {
      const token = currentUser.authenticationToken || currentUser.token;

      const { endpoint } = currentUser.endpoint
        ? currentUser
        : getEndpointsByEmail(currentUser.email);

      let nextUser;

      try {
        const axiosConfig = resources.apiPostUserSignIn.api({
          resources: [
            {
              user: {
                username: currentUser.email,
                token,
              },
            },
          ],
        });

        axiosConfig.transformResponse = [];

        nextUser = await axios({
          ...axiosConfig,
          responseType: 'json',
          headers: {
            'Content-Type': 'application/json',
            'X-User-Token': token,
            'X-User-Email': currentUser.email,
          },
          baseURL: endpoint,
        });
      } catch (error) {
        console.error(error);

        if (error?.response?.status === 401) {
          console.log(
            '[core/helpers/asyncStorage] user request failed',
            error.response,
          );
          RollbarClient.info(
            '[core/helpers/asyncStorage] user request failed',
            error.response,
          );
        }

        nextUser = {
          data:
            error.response && error.response.status === 401 ? {} : currentUser,
        };
      }

      currentUser = humps.camelizeKeys(nextUser.data);
      currentUser.id = currentUser.id ? currentUser.id.toString() : '';
      currentUser.entityId = currentUser.entityId
        ? currentUser.entityId.toString()
        : '';
      currentUser.parentEntityId = currentUser.parentEntityId
        ? currentUser.parentEntityId.toString()
        : '';
      currentUser.token = currentUser.authenticationToken;
      currentUser.endpoint = endpoint;
      currentUser.features = nextUser.data.features;

      Resource.setEndpoint(endpoint);
    } else {
      currentUser = {};
    }
  } catch (error) {
    // currentUser = {};
    console.error(error);
  }

  return currentUser;
}

export async function setAsyncStorageCurrentUser(user) {
  const { endpoint, chatEndpoint } = user.endpoint
    ? user
    : getEndpointsByEmail(user.email);

  const token = user.token || user.authenticationToken;

  const currentUser = {
    ...user,
    token,
    authentication_token: token,
    endpoint,
    chatEndpoint,
  };

  return asyncStorage.setItem(CURRENT_USER_KEY, JSON.stringify(currentUser));
}

export async function clearAsyncStorage() {
  return Promise.all([
    asyncStorage.removeItem(CURRENT_USER_KEY),
    asyncStorage.removeItem(ROUTE_KEY),
  ]);
}

export async function setAsyncStorageRoute(routeAction) {
  const { type, payload, query } = routeAction;

  asyncStorage.setItem(ROUTE_KEY, JSON.stringify({ type, payload, query }));
}

export async function getAsyncStorageRoute() {
  let currentRoute = null;

  try {
    currentRoute = await asyncStorage.getItem(ROUTE_KEY);
    currentRoute = JSON.parse(currentRoute);

    if (currentRoute) {
      const { type, payload, query } = currentRoute;
      currentRoute =
        type === ROUTES.NOT_FOUND ? null : { type, payload, query };
    }
  } catch (error) {
    currentRoute = null;
    console.error(error);
  }

  return currentRoute;
}

export async function setAsyncStorageMenu(menu) {
  asyncStorage.setItem('arcade.menu', JSON.stringify(menu));
}

export async function getAsyncStorageMenu() {
  let menu = null;
  try {
    menu = await asyncStorage.getItem('arcade.menu');
    menu = JSON.parse(menu);

    return menu;
  } catch (error) {
    menu = null;
    console.error(error);
  }
  return menu;
}

export async function setAsyncStorageUser(user) {
  asyncStorage.setItem(CURRENT_USER_KEY, JSON.stringify(user));
}

export async function getAsyncStorageUser() {
  let user = null;
  try {
    user = await asyncStorage.getItem(CURRENT_USER_KEY);
    user = JSON.parse(user);

    return user || null;
  } catch (error) {
    user = null;
    console.error(error);
  }
  return user;
}

export async function setAsyncStoragePermissions(permissions) {
  asyncStorage.setItem('arcade.permissions', JSON.stringify(permissions));
}

export async function getAsyncStoragePermissions() {
  let permissions = null;
  try {
    permissions = await asyncStorage.getItem('arcade.permissions');
    permissions = JSON.parse(permissions);

    return permissions;
  } catch (error) {
    permissions = null;
    console.error(error);
  }
  return permissions;
}

export async function getArcadeAsyncStorageItems() {
  return {
    user: await getAsyncStorageUser(),
    menu: await getAsyncStorageMenu(),
    permissions: await getAsyncStoragePermissions(),
  };
}

export async function removeArcadeAsyncStorageItems() {
  return Promise.all([
    asyncStorage.removeItem(CURRENT_USER_KEY),
    asyncStorage.removeItem('arcade.menu'),
    asyncStorage.removeItem('arcade.permissions'),
  ]);
}

export default asyncStorage;

if (global.window) {
  window.asyncStorage = asyncStorage;
}
