import React from 'react';
import { Animated, Platform, ImageBackground } from 'react-native';
import { Card } from 'react-native-paper';

import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcIconButton from 'arcade-frontend-ui/src/elements/ArcIconButton';
import ArcGifVideo from 'arcade-frontend-ui/src/components/ArcGifVideo';
import ArcMarkdownText from 'arcade-frontend-ui/src/components/ArcMarkdownText';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
import Close from 'arcade-frontend-ui/src/icons/Close';

import {
  NotificationsTypeThreeDialogProps,
  CloseButtonProps,
} from './NotificationsTypeThreeDialog.d';

const SOURCE_BY_VIDEO_TYPE = {
  TOKENS_AWARDED: require('./videos/tokens-awarded.mp4'),
  CHESTS_AWARDED: require('./videos/chests-awarded.mp4'),
  BADGE_AWARDED: require('./videos/chests-awarded.mp4'),
};

const AnimatedArcButton = Animated.createAnimatedComponent(ArcButton);

const styles = {
  buttonStyles: {
    textTransform: 'capitalize',
    letterSpacing: 'normal',
    fontWeight: 400,
  },
  cardBaseStyles: {
    overflow: 'hidden',
    borderRadius: 8,
    width: '100%',
    minWidth: 300,
    maxWidth: 500,
  },
  closeButtonStyles: {
    backgroundColor: 'rgba(11, 11, 11, 0.25)',
    borderRadius: 48,
    padding: 8,
  },
  imageBorderRadiusStyles: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  imageMobileStyles: {
    width: '100%',
    minHeight: 200,
    maxHeight: 215,
  },
  imageWebStyles: {
    minWidth: '100%',
    minHeight: 275,
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  markdownParagraphStyles: {
    marginTop: 0,
    fontSize: 14,
    lineHeight: 22,
    color: '#616264',
  },
  titleStyles: {
    fontSize: 20,
    lineHeight: 24,
    minHeight: 24,
    marginTop: 8,
    textAlign: 'center',
  },
};

const CloseButtonContainer: React.FC<CloseButtonProps> = ({ children }) => (
  <ArcViewBox alignItems="flex-end">
    <ArcViewBox position="absolute" top="9px" right="9px" zIndex={1}>
      {children}
    </ArcViewBox>
  </ArcViewBox>
);

const NotificationsTypeThreeDialog: React.FC<NotificationsTypeThreeDialogProps> = ({
  content,
  hasClose,
  imageUrl,
  onClose,
  onLinkPress,
  onPrimaryAction,
  onSecondaryAction,
  primaryActionLabel,
  primaryActionColor,
  secondaryActionLabel,
  secondaryActionColor,
  title,
  videoType,
  ...props
}) => {
  const isWeb = Platform.OS === 'web';

  const closeButtonWeb = (
    <ArcIconButton onClick={onClose} style={styles.closeButtonStyles}>
      <Close height={12} width={12} color="#FFF" strokeWidth={3} />
    </ArcIconButton>
  );

  const closeButtonMobile = (
    <AnimatedArcButton onClick={onClose}>
      <ArcViewBox style={styles.closeButtonStyles}>
        <Close height={12} width={12} color="#FFF" strokeWidth={3} />
      </ArcViewBox>
    </AnimatedArcButton>
  );

  const cardImage = (
    <ArcViewBox alignItems="center">
      {isWeb ? (
        <ArcViewBox width="100%">
          {hasClose && closeButtonWeb}
          <ArcViewBox
            style={{
              ...styles.imageWebStyles,
              ...styles.imageBorderRadiusStyles,
              backgroundImage: `url(${imageUrl})`,
            }}
          />
        </ArcViewBox>
      ) : (
        <ArcViewBox style={styles.imageMobileStyles}>
          <ImageBackground
            source={{ uri: imageUrl }}
            imageStyle={styles.imageBorderRadiusStyles}
            style={{
              ...styles.imageBorderRadiusStyles,
              height: '100%',
              overflow: 'hidden',
            }}
          >
            {closeButtonMobile}
          </ImageBackground>
        </ArcViewBox>
      )}
    </ArcViewBox>
  );

  return (
    <Card
      elevation={2}
      style={styles.cardBaseStyles}
      data-testid="NotificationsTypeThreeDialog"
    >
      {/* {imageUrl && cardImage} */}
      {!!videoType && <ArcGifVideo source={SOURCE_BY_VIDEO_TYPE[videoType]} />}
      {/* @ts-ignore - titleStyles.textAlign needs to be of type 'center' but casting it throws an error on CI */}
      <Card.Title title={title} titleStyle={styles.titleStyles} />
      <ArcViewBox alignItems="center">
        <ArcViewBox
          px={3}
          pt={1}
          pb={2}
          textAlign="center"
          maxWidth={350}
          minHeight={80}
        >
          <ArcMarkdownText
            onLinkPress={onLinkPress}
            style={{ paragraph: styles.markdownParagraphStyles }}
          >
            {content}
          </ArcMarkdownText>
        </ArcViewBox>

        <ArcViewBox marginTop="auto" width="100%">
          <ArcViewBox height={1} backgroundColor="#EBEBEB" width="100%" />
          <ArcViewBox
            flexDirection="row"
            justifyContent="center"
            width="100%"
            backgroundColor="#F9F9F9"
            borderBottomLeftRadius={8}
            borderBottomRightRadius={8}
            p={3}
          >
            {secondaryActionLabel && (
              <AnimatedArcButton
                color={secondaryActionColor}
                label={secondaryActionLabel}
                style={styles.buttonStyles}
                onClick={onSecondaryAction}
              />
            )}
            {primaryActionLabel && (
              <ArcViewBox ml={2}>
                <AnimatedArcButton
                  color={primaryActionColor}
                  variant="contained"
                  label={primaryActionLabel}
                  style={styles.buttonStyles}
                  onClick={onPrimaryAction}
                />
              </ArcViewBox>
            )}
          </ArcViewBox>
        </ArcViewBox>
      </ArcViewBox>
    </Card>
  );
};

NotificationsTypeThreeDialog.displayName = 'NotificationsTypeThreeDialog';

NotificationsTypeThreeDialog.defaultProps = {
  content: '',
  onClose: global.noop,
  onLinkPress: undefined,
  onPrimaryAction: global.noop,
  onSecondaryAction: global.noop,
  title: '',
  imageUrl: undefined,
  primaryActionLabel: undefined,
  primaryActionColor: 'primary',
  secondaryActionLabel: undefined,
  secondaryActionColor: 'primary',
};

export default NotificationsTypeThreeDialog;
