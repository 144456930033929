/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';

import { userInput } from '../../helpers/utils/parsers';

const useStyles = makeStyles(theme => {
  const base = {
    margin: 0,
    padding: 0,
  };

  const typography = {
    h1: {
      ...base,
      fontWeight: '500',
      fontSize: '1.5rem',
      marginBottom: theme.spacing(1),
    },
    h2: {
      ...base,
      fontWeight: '500',
      fontSize: '1.25rem',
      marginBottom: theme.spacing(1),
    },
    h3: {
      ...base,
      fontWeight: '500',
      fontSize: '1.125rem',
      marginBottom: theme.spacing(1),
    },
    h4: {
      ...base,
      fontWeight: '500',
      fontSize: '1rem',
      marginBottom: theme.spacing(1),
    },
    h5: {
      ...base,
      fontWeight: '500',
      fontSize: '1rem',
      marginBottom: theme.spacing(1),
      textTransform: 'uppercase',
    },
    h6: {
      ...base,
      fontSize: '1rem',
      marginBottom: theme.spacing(1),
    },
    body: {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },
  };

  return {
    root: {
      fontSize: '14px',
      lineHeight: 'normal',
      color: theme.palette.grey.dark,
      margin: 0,
      padding: 0,

      /* Headings */
      // heading one
      '& h3': { ...typography.h1 },
      // heading two
      '& h4': { ...typography.h2 },
      // heading three
      '& h5': { ...typography.h3 },
      // heading four
      '& h6': { ...typography.h4 },
      // heading five
      '& h7': { ...typography.h5 },
      // heading six
      '& h8': {
        ...typography.h6,
        display: 'block',
      },

      /* Paragraphs */
      '& p': {
        margin: 0,
        ...typography.body,
      },
      '& p:not(:last-child)': {
        marginBottom: theme.spacing(2),
        ...typography.body,
      },
      '& p+p': {
        marginTop: 0,
      },
      // Adjusted heading under paragraphs
      '& p+h3': {
        marginTop: theme.spacing(2.5),
      },
      '& p+h4': {
        marginTop: theme.spacing(2.5),
      },
      '& p+h5': {
        marginTop: theme.spacing(2.5),
      },
      '& p+h6': {
        marginTop: theme.spacing(1.5),
      },
      '& p+h7': {
        marginTop: theme.spacing(1.5),
      },
      '& p+h8': {
        marginTop: theme.spacing(1),
      },

      /* Quote */
      '& blockquote': {
        marginLeft: theme.spacing(2),
      },
      '& blockquote p': {
        ...typography.h3,
        fontWeight: 400,
        position: 'relative',
        borderLeft: `3px solid ${theme.palette.grey[400]}`,
        paddingLeft: theme.spacing(2.5),
      },

      /* Images */
      '& img': {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1),
      },

      /* Lists */
      '& ol': {
        ...typography.body,
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
      },
      '& ol > li': {
        marginBottom: theme.spacing(1),
      },
      '& ul': {
        ...typography.body,
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
      },
      // adjusted lists under lists
      '& li > ol': {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
      },
      '& li > ul': {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
      },
      // adjusted lists under headings
      '& h5+ol': {
        marginTop: theme.spacing(0.5),
      },
      '& h6+ol': {
        marginTop: theme.spacing(0.5),
      },
      '& h7+ol': {
        marginTop: theme.spacing(0.5),
      },
      '& h8+ol': {
        marginTop: theme.spacing(0.5),
      },
      '& p+ol': {
        marginTop: theme.spacing(0.5),
      },
      '& h5+ul': {
        marginTop: theme.spacing(0.5),
      },
      '& h6+ul': {
        marginTop: theme.spacing(0.5),
      },
      '& h7+ul': {
        marginTop: theme.spacing(0.5),
      },
      '& h8+ul': {
        marginTop: theme.spacing(0.5),
      },

      /* Rule */
      '& hr': {
        height: 1,
        color: theme.palette.grey[300],
        backgroundColor: theme.palette.grey[300],
        border: 'none',
        marginBottom: theme.spacing(2),
      },

      /* Table */
      '& table': {
        marginBottom: theme.spacing(2),
        borderCollapse: 'collapse',
      },
      '& table thead': {
        backgroundColor: theme.palette.grey[200],
      },
      '& table th': {
        padding: theme.spacing(1),
        borderCollapse: 'collapse',
      },
      '& table td': {
        padding: theme.spacing(1),
        borderCollapse: 'collapse',
      },

      /* Code */
      '& code': {
        position: 'relative',
        backgroundColor: theme.palette.grey[200],
        padding: '8px 8px',
        borderRadius: '6px',
        fontSize: '.85rem',
      },
      '& pre': {
        position: 'relative',
        paddingTop: theme.spacing(3),
      },
      '& pre > code': {
        fontSize: '.85rem',
        backgroundColor: 'transparent',
      },

      /* Links */
      '& a': {
        wordBreak: 'break-all',
        color: theme.palette.blue.main,
      },
    },

    'variant-light': {
      color: theme.palette.grey.main,
      '& a': {
        color: theme.palette.blue.main,
      },
    },
  };
});

function ArcMarkdownContent({ content, variant, ...props }) {
  const classes = useStyles();

  return (
    <div
      {...props}
      className={cx([classes.root, classes[`variant-${variant}`]])}
      dangerouslySetInnerHTML={{ __html: userInput(content) }}
    />
  );
}

ArcMarkdownContent.propTypes = {
  content: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'light']),
};
ArcMarkdownContent.defaultProps = {
  variant: 'default',
};

export default ArcMarkdownContent;
