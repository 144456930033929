/* eslint-disable no-useless-computed-key */
// import { css } from 'glamor';
import ArcStyles from '../../styles';

const css = { keyframes: () => '' };

const rules = {
  ArcInterlaced: {
    background: 'transparent',
    '& > .arcInterlacedCharacter': {
      marginRight: '1%',
    },
    '& > .arcInterlacedCharacter:last-child': {
      marginRight: '0',
    },
  },
};

const getDefaultColor = props => (
  props.color ? props.color : ArcStyles.theme.gray3
);
const getFromColor = props => (
  props.fromColor ? props.fromColor : ArcStyles.theme.gray8
);
const getToColor = props => (
  props.toColor ? props.toColor : ArcStyles.theme.gray3
);

const flashSequence = props => css.keyframes({
  '0%': { fill: `${getFromColor(props)}` },
  '100%': { fill: `${getToColor(props)}` },
});

const fadeInUpSequence = props => css.keyframes({
  '0%': { fill: `${getFromColor(props)}` },
  '100%': { fill: `${getToColor(props)}` },
});

const flash = props => ({
  '& > .arcInterlacedCharacter svg g g': {
    animation: `${flashSequence(props)} 0.75s infinite ease-in-out alternate`,
  },
});

const fadeInUp = props => ({
  '& > .arcInterlacedCharacter svg': {
    '& > g': {
      fill: `${getFromColor(props)}`,
    },
    '& > g g': {
      animationName: `${fadeInUpSequence(props)}`,
      animationDirection: 'normal',
      animationIterationCount: 1,
      animationDuration: '1s',
      animationFillMode: 'forwards',
      animationTimingFunction: 'ease-in',
    },
    '& > g g#zero': {
      animationDelay: '1.5s',
    },
    '& > g g#one': {
      animationDelay: '2s',
    },
    '& > g g#two': {
      animationDelay: '2.5s',
    },
    '& > g g#three': {
      animationDelay: '3s',
    },
    '& > g g#four': {
      animationDelay: '3.5s',
    },
    '& > g g#five': {
      animationDelay: '4s',
    },
    '& > g g#six': {
      animationDelay: '4.5s',
    },
  },
});

const color = props => ({
  '& > .arcInterlacedCharacter svg': {
    '& > g': {
      fill: `${getDefaultColor(props)}`,
    },
  },
});

const styles = props => ArcStyles.flatten([
  rules.ArcInterlaced,
  props.color && color(props),
  props.flash && flash(props),
  props.fadeInUp && fadeInUp(props),
  props.style,
]);

export { rules, styles };
