import { react2angular } from 'react2angular';

import ArcAwardTokenFormContainer from 'arcade-frontend-widgets/src/containers/ArcAwardTokenFormContainer';

import { createProvidedContainer } from './helpers';

const ProvidedArcAwardTokenFormContainer = createProvidedContainer(
  ArcAwardTokenFormContainer,
);

export default react2angular(ProvidedArcAwardTokenFormContainer, [
  'open',
  'onCancel',
  'inDialog',
  'initialValues',
  'hasFieldPeople',
  'hasFieldIsPublic',
]);
