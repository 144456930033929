export enum ActionType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export interface ArcUser {
  id: string;
  token: string;
  email: string;
}

export interface Action {
  type: ActionType;
  payload?: ArcUser;
}

export type ArcUserState = undefined | null | ArcUser;

const initialState: ArcUserState = null;

export default function arcUserReducer(
  state = initialState,
  action: Action,
): ArcUserState {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case ActionType.LOGIN:
      return action.payload;
    case ActionType.LOGOUT:
      return null;
    default:
      return state;
  }
}
