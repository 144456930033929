import { getResources, resourceReducer } from 'redux-resource';

const completedGames = resourceReducer('completedGames');

export default completedGames;

export const getCompletedGames = state =>
  getResources(state.gamesV2.completedGames, 'index');

export const getCompletedGamesMeta = state =>
  state.gamesV2.completedGames.meta.apiGetCompletedGamesIndex;
