export default {
  typeOne: [
    {
      title: 'New Message',
      content: 'You have a new message from Ali',
      dialogType: 'TYPE_ONE',
      imageUrl: '',
    },
    {
      title: 'New Chest',
      content: 'You have an Epic Chest waiting to be unlocked!',
      dialogType: 'TYPE_ONE',
      imageUrl: '',
    },
    {
      title: 'New Quest Assigned',
      content:
        "You've been assigned a new quest! Check out the quest section in your profile for more info.",
      dialogType: 'TYPE_ONE',
      imageUrl: '',
    },
  ],
  typeTwo: [
    {
      title: 'New Message',
      content: 'You have a new message from Ali',
      dialogType: 'TYPE_TWO',
      imageUrl: '',
    },
    {
      title: 'New Chest',
      content: 'You have an Epic Chest waiting to be unlocked!',
      dialogType: 'TYPE_TWO',
      imageUrl: '',
    },
    {
      title: 'New Quest Assigned',
      content:
        "You've been assigned a new quest! Check out the quest section in your profile for more info.",
      dialogType: 'TYPE_TWO',
      imageUrl: '',
    },
  ],
  typeThree: [
    {
      videoType: 'TOKENS_AWARDED',
      title: "You've been Awarded!",
      content:
        '**1,000 Tokens** by Chris Desrochers for helping me get the logos I need for the MUFC show!',
      dialogType: 'TYPE_THREE',
      imageUrl:
        'https://d21lizqa15vhen.cloudfront.net/uploads/resized/message/image/350025/large_7c205d23-8a4b-4905-b5d6-ebe6033a06ea.gif',
    },
    {
      videoType: 'BADGE_AWARDED',
      title: 'Badge Unlocked!',
      content:
        "You have unlocked a **Scribe Badge** for commenting like there's no tomorrow. You can equip the badge on your profile picture.",
      dialogType: 'TYPE_THREE',
      imageUrl: 'https://media.giphy.com/media/3o72FcJmLzIdYJdmDe/giphy.gif',
    },
    {
      videoType: 'CHESTS_AWARDED',
      title: 'You won 2 Chests!',
      content: 'Pending Manager Verification',
      dialogType: 'TYPE_THREE',
      imageUrl:
        'https://d21lizqa15vhen.cloudfront.net/uploads/resized/message/image/350070/large_cb95b582-ff33-4ee4-96d6-344441b1fffa.gif',
    },
    {
      videoType: 'CHESTS_AWARDED',
      title: 'Good job!',
      content: 'Keep up the great work, ma dude',
      dialogType: 'TYPE_THREE',
      imageUrl:
        'https://media0.giphy.com/media/XreQmk7ETCak0/giphy.gif?cid=ecf05e473751re9uh4bsetub5uu2hul1wvima35hczup268i&rid=giphy.gif',
    },
  ],
};
