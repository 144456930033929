import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/games';

const handleManageGamesEligiblePeopleIndexSuccess = (_, action) => {
  const { data } = action.payload;

  const newState = {};

  data.teams.forEach(team => {
    newState[team.id] = team;
  });

  return newState;
};

const initialState = {};

const handlers = {
  [types.MANAGE_GAMES_ELIGIBLE_PEOPLE_INDEX.SUCCESS]:
    handleManageGamesEligiblePeopleIndexSuccess,
};

const teamsById = createReducer(initialState, handlers);

export default teamsById;

export const getManageGamesTeamsById = state => state.manage.games.teamsById;
