import React from 'react';

const IconTarget = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor" fillRule="nonzero">
      <path d="M11.032 22c4.987 0 9.029-4.025 9.029-8.99a8.918 8.918 0 0 0-1.056-4.225 1.598 1.598 0 0 1-.354.01l-1.149-.088-.826.823a6.56 6.56 0 0 1 .995 3.48c0 3.649-2.972 6.608-6.636 6.608-3.665 0-6.639-2.959-6.639-6.609s2.972-6.608 6.636-6.608c1.284 0 2.48.363 3.495.99l.748-.745-.101-1.311a1.72 1.72 0 0 1 .004-.313A9.023 9.023 0 0 0 11.03 4.02c-4.984 0-9.026 4.025-9.026 8.99 0 4.966 4.042 8.991 9.028 8.991z" />
      <path d="M11.032 10.686c.06 0 .118.002.177.007l1.667-1.66.036-.036a4.432 4.432 0 0 0-1.88-.416c-2.455 0-4.444 1.981-4.444 4.426 0 2.445 1.99 4.428 4.444 4.428 2.456 0 4.445-1.98 4.445-4.426 0-.669-.15-1.303-.417-1.872l-.036.036-1.667 1.66a2.328 2.328 0 0 1-2.327 2.5 2.326 2.326 0 0 1-2.33-2.324 2.33 2.33 0 0 1 2.332-2.323z" />
      <path d="M20.337 4.993l.535-.533a.619.619 0 0 0 0-.88l-.37-.371a.624.624 0 0 0-.882 0l-.613.61-.126-1.626A.207.207 0 0 0 18.67 2a.206.206 0 0 0-.148.06L16.11 4.465a1.048 1.048 0 0 0-.305.822l.006.095.116 1.505-.873.868-1.57 1.564-.035.036-1.52 1.513-.674.671a.517.517 0 0 0-.152.33l-.042.527a.525.525 0 0 0 .525.566h.027l.558-.027a.52.52 0 0 0 .346-.153l.668-.666 1.52-1.513.036-.035 1.57-1.564.948-.944 1.378.105.061.004a1.062 1.062 0 0 0 .826-.304l2.411-2.401a.21.21 0 0 0-.132-.357l-1.465-.113z" />
    </g>
  </svg>
);

export default IconTarget;
