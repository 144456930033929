import { combineReducers } from 'redux';

import open from './open';
import requestStatus from './requestStatus';
import source from './source';
import uploadStatus from './uploadStatus';

export default combineReducers({
  open,
  requestStatus,
  source,
  uploadStatus,
});
