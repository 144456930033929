import React from 'react';
import ArcPropTypes from '../../helpers/arc/propTypes';

const Grass = ({ color, colorDark, ...props }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 8 4"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M1 3V1h1v1h2V1h1v1h2V1h1v3H1V3z"
        fill={color}
      />
      <path
        d="M7 1v1H6V0h1v1zM4 1v1H3V0h1v1zM0 2V0h1v4H0V2z"
        fill={colorDark}
      />
    </g>
  </svg>
);

Grass.propTypes = {
  color: ArcPropTypes.string,
  colorDark: ArcPropTypes.string,
};

Grass.defaultProps = {
  color: '#8DC257',
  colorDark: '#76A349',
};

export default Grass;
