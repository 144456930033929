import React from 'react';
import ReactTable from 'react-table';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import ArcStyles, { createWithStyles } from '../../styles';

import {
  ranking,
  list,
} from './types';


const styles = {
  Container: theme => ({
    root: {
      [theme.breakpoints.up('md')]: {
        maxWidth: 600,

        '& .show-large': {
          display: 'flex !important',
        },

        '& .show-small': {
          display: 'none !important',
        },
      },


      [theme.breakpoints.down('md')]: {
        '& .show-large': {
          display: 'none !important',
        },

        '& .show-small': {
          display: 'flex !important',
        },
      },
    },

    isResponsive: {
      [theme.breakpoints.down(400)]: {
        '& .rt-thead': {
          display: 'none !important',
        },

        '& .rt-tbody .rt-tr': {
          flexDirection: 'column',
          padding: '8px !important',
        },

        '& .rt-tbody .rt-tr > .rt-td': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100% !important',
          maxWidth: 'initial !important',
          padding: '8px !important',

          '&:before': {
            content: 'attr(data-td-label)',
            fontSize: 14,
            color: ArcStyles.theme.grey5,
            marginBottom: 4,
          },
        },

        '& .ArcTable--ranking': {
          '& .rt-tbody .rt-tr': {
            flexDirection: 'row !important',
            flexWrap: 'wrap',
            padding: '8px 0 16px !important',
          },
          '& .rt-tbody .rt-tr > .rt-td': {
            padding: '0 !important',
            width: 'initial !important',
          },
          '& .rt-tbody .rt-tr > .rt-td:nth-of-type(1)': {
            flex: '1 0 16% !important',
            order: 1,
            fontSize: 32,
            fontWeight: 500,
            '&:before': {
              display: 'none',
            },
          },
          '& .rt-tbody .rt-tr > .rt-td:nth-of-type(2)': {
            flexDirection: '1 1 80%',
            order: 2,
            borderBottom: `solid 1px ${ArcStyles.theme.grey6}`,
            padding: '8px 0 !important',
            marginBottom: '4px !important',
            marginRight: '16px !important',
            '&:before': {
              display: 'none',
            },
          },
          '& .rt-tbody .rt-tr > .rt-td:nth-of-type(3)': {
            flex: '1 1 100% !important',
            flexDirection: 'row',
            maxWidth: 'initial !important',
            justifyContent: 'flex-start',
            order: 3,
            color: ArcStyles.theme.grey2,
            paddingLeft: '16% !important',
            '&:before': {
              marginRight: 8,
            },
          },
        },
      },
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);

const arcColumns = {
  ranking,
  list,
};

const ArcTableTd = props => (
  <ArcView row className="rt-td" {...props}>{props.children}</ArcView>
);

ArcTableTd.propTypes = {
  children: ArcPropTypes.children.isRequired,
};


class ArcTable extends React.PureComponent {
  static displayName = 'ArcTable';

  static propTypes = {
    reactTableData: ArcPropTypes.reactTableData,
    reactTableColumns: ArcPropTypes.reactTableData,

    isResponsive: ArcPropTypes.bool,
    isFullWidth: ArcPropTypes.bool,
    arcColumnType: ArcPropTypes.oneOf(['ranking', 'list']),
  };

  static defaultProps = {
    reactTableData: [],
    reactTableColumns: [],
    isResponsive: false,
    isFullWidth: false,
    arcColumnType: null,
  };

  render() {
    const {
      arcColumnType,
      reactTableData,
      reactTableColumns,
      isResponsive,
      isFullWidth,
      ...rest
    } = this.props;

    const columns = arcColumnType
      ? arcColumns[arcColumnType]
      : reactTableColumns;

    if (isResponsive) {
      return (
        <Container
          isResponsive
          fullWidth={isFullWidth}
        >
          <ReactTable
            isResponsive
            data={reactTableData}
            columns={columns}
            className="-striped -highlight"
            showPageJump={false}
            showPageSizeOptions={false}
            getTdProps={(state, rowInfo, column) => {
              const tdProps = {};
              tdProps.className = 'rt-td';
              if (rowInfo !== undefined) tdProps['data-td-label'] = column.Header;
              return tdProps;
            }}
            TdComponent={ArcTableTd}
            {...rest}
          />
        </Container>
      );
    }

    return (
      <Container>
        <ReactTable
          isResponsive
          data={reactTableData}
          columns={columns}
          className="-striped -highlight"
          showPageJump={false}
          showPageSizeOptions={false}
          {...rest}
        />
      </Container>
    );
  }
}

export default ArcTable;
