import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

import Clear from '@material-ui/icons/Clear';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcCheckbox from '../ArcCheckbox';
import ArcIconButton from '../ArcIconButton';
import ArcNumberField from '../ArcNumberField';
import ArcSelect from '../ArcSelect';
import ArcTextField from '../ArcTextField';
import ArcView from '../../primitives/ArcView';

const styleSpacer = {
  marginRight: 8,
  minWidth: 96,
  flexGrow: 1,
};

const styleButton = {
  marginTop: 8,
};

const styleRow = {
  marginBottom: 8,
};

const selectableUnits = [
  {
    label: '#',
    value: '#',
  },
  {
    label: '$',
    value: '$',
  },
];

const styleSelect = {
  marginRight: 8,
  maxWidth: 80,
};

const findWithProp = (array, attr, value) => array.map(arrayItem => arrayItem[attr]).indexOf(value);

class ArcMultiAddDemo extends React.PureComponent {
  static propTypes = {
    fullWidth: ArcPropTypes.bool,
    maxRows: ArcPropTypes.number,
    onChange: ArcPropTypes.func,
    value: ArcPropTypes.arrayOf(ArcPropTypes.shape({
      label: ArcPropTypes.string.isRequired,
      value: ArcPropTypes.number.isRequired,
    })),
  };

  static defaultProps = {
    fullWidth: false,
    maxRows: undefined,
    onChange: ArcPropTypes.noop,
    value: [],
  };

  state = this.initialState;

  get initialIdCount() {
    return this.state.rows.length || -1;
  }

  get initialState() {
    let rows = [];

    if (this.props.value) {
      rows = this.props.value.map((row, index) => ({
        ...row,
        id: index,
      }));
    }

    return {
      rows,
    };
  }

  get canAddRow() {
    const { maxRows } = this.props;

    if (typeof maxRows === 'undefined') {
      return true;
    }

    return this.state.rows.length < maxRows;
  }

  setRows = rows => this.setState({ rows });

  updateRow = (row, prop, value) => {
    const index = findWithProp(this.state.rows, 'id', row.id);
    const nextRow = {
      ...row,
      [prop]: value,
    };

    const nextRows = [...this.state.rows];
    nextRows[index] = nextRow;
    this.setRows(nextRows);
  };

  idCount = this.initialIdCount;

  handleBlur = () => this.props.onChange(this.state.rows);

  handleChangeTitle = (e, row) => this.updateRow(row, 'title', e.target.value);

  handleChangeVerb = (e, row) => this.updateRow(row, 'verb', e.target.value);

  handleChangeUnit = (e, row) => this.updateRow(row, 'unit', e.target.value);

  handleChangeQuantityFrom = (e, row) => this.updateRow(row, 'quantityFrom', e.target.value);

  handleChangeQuantityTo = (e, row) => this.updateRow(row, 'quantityTo', e.target.value);

  handleChangeTarget = (e, row) => this.updateRow(row, 'target', e.target.value);

  handleToggle = (e, row) => {
    this.updateRow(row, 'createGame', !row.createGame);
  }

  handleClickAdd = () => {
    if (!this.canAddRow) {
      return;
    }

    this.idCount += 1;
    const nextRows = [...this.state.rows, {
      id: this.idCount,
      title: '',
      verb: '',
      quantityFrom: '',
      quantityTo: '',
      target: '',
      unit: '#',
      createGame: false,
    }];

    this.setRows(nextRows);
    this.props.onChange(nextRows);
  };

  handleClickRemove = (row) => {
    const nextRows = this.state.rows.filter(r => r.id !== row.id);
    this.setRows(nextRows);
    this.props.onChange(nextRows);
  };

  renderClearButton(row) {
    return (
      <ArcIconButton
        onClick={() => this.handleClickRemove(row)}
        disableRipple
      >
        <Clear
          color="disabled"
          fontSize="inherit"
        />
      </ArcIconButton>
    );
  }

  renderRow = row => (
    <ArcView key={row.id} row style={styleRow}>
      <ArcCheckbox
        label="Create Game"
        checked={row.createGame}
        onChange={e => this.handleToggle(e, row)}
        value={row.createGame}
        onBlur={this.handleBlur}
      />
      <ArcTextField
        label="Title"
        onBlur={this.handleBlur}
        onChange={e => this.handleChangeTitle(e, row)}
        value={row.title}
        style={styleSpacer}
      />
      <ArcTextField
        label="Verb"
        onBlur={this.handleBlur}
        onChange={e => this.handleChangeVerb(e, row)}
        value={row.verb}
        style={styleSpacer}
      />
      <ArcView
        style={styleSelect}
      >
        <ArcSelect
          label="Unit"
          items={selectableUnits}
          onBlur={this.handleBlur}
          onChange={e => this.handleChangeUnit(e, row)}
          value={row.unit}
        />
      </ArcView>
      <ArcNumberField
        label="Min Sales per User"
        onBlur={this.handleBlur}
        onChange={e => this.handleChangeQuantityFrom(e, row)}
        value={row.quantityFrom}
        style={styleSpacer}
      />
      <ArcNumberField
        label="Max Sales per User"
        onBlur={this.handleBlur}
        onChange={e => this.handleChangeQuantityTo(e, row)}
        value={row.quantityTo}
        style={styleSpacer}
      />
      <ArcNumberField
        label="Target"
        onBlur={this.handleBlur}
        onChange={e => this.handleChangeTarget(e, row)}
        value={row.target}
        style={styleSpacer}
      />
      {this.renderClearButton(row)}
    </ArcView>
  );

  render() {
    return (
      <FormControl fullWidth={this.props.fullWidth}>
        {this.state.rows.map(row => this.renderRow(row))}
        <Button
          disabled={!this.canAddRow}
          onClick={this.handleClickAdd}
          style={styleButton}
          variant="contained"
        >
          Add Another Metric
        </Button>
      </FormControl>
    );
  }
}

export default ArcMultiAddDemo;
