import * as types from 'arcade-frontend-core/src/types/routes';

export const routeRewards = () => ({
  type: types.ROUTE_REWARDS,
  payload: {},
});

export const routeRewardsAward = () => ({
  type: types.ROUTE_REWARDS_AWARD,
  payload: {},
});

export const routeRewardsHistory = () => ({
  type: types.ROUTE_REWARDS_HISTORY,
  payload: {},
});

export const routeRewardsTransactionsIndex = () => ({
  type: types.ROUTE_REWARDS_TRANSACTIONS,
  payload: {},
  meta: {
    analytics: {
      payload: {
        event: 'Viewed rewards shop',
      },
    },
  },
});
