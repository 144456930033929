import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import getTeamLetter from 'arcade-frontend-core/src/helpers/getTeamLetter';
import getTeamColor from 'arcade-frontend-core/src/helpers/getTeamColor';
import { pluralize } from 'arcade-frontend-core/src/helpers/pluralize';

import MenuItem from '@material-ui/core/MenuItem';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconErrorOutlined from '@material-ui/icons/ErrorOutlined';
import GameControllerTeam from 'arcade-frontend-ui/src/icons/GameControllerTeam';
import Ticket from 'arcade-frontend-ui/src/icons/Ticket';

import {
  ArcButton,
  ArcResponsiveDialog,
  ArcFormField,
  ArcForm,
  ArcText,
  ArcView,
} from 'arcade-frontend-ui';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcEntityAvatar from 'arcade-frontend-ui/src/components/ArcEntityAvatar';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';
import ArcCollapsibleExplainer from 'arcade-frontend-ui/src/components/ArcCollapsibleExplainer';

const styleMenuItem = {
  height: 'auto',
};

const styleIconError = {
  marginRight: 4,
  height: 20,
  width: 20,
};

const NO_TEAM = {
  id: '-1',
  name: 'No Team',
};

const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  companyIdentifier: '',
  teamId: '-1',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  shouldBeGeofenced: Yup.string(),
});

class InvitePersonForm extends React.PureComponent {
  static propTypes = {
    identifierLabel: PropTypes.string,
    hasGeofenceField: PropTypes.bool,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    requestErrorMessage: PropTypes.string,
    statusGetTeams: PropTypes.objectOf(PropTypes.bool),
    statusInviteUser: PropTypes.objectOf(PropTypes.bool),
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    companyIdentifierExplainer: PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string,
    }),
    hasFeatureRemainingSeats: PropTypes.bool,
    remainingSeats: PropTypes.number,
    requestMoreSeats: PropTypes.func,
    setRequestErrorMessage: PropTypes.func,
  };

  static defaultProps = {
    hasGeofenceField: false,
    identifierLabel: 'Company Identifier',
    open: false,
    onClose: global.noop,
    onSubmit: global.noop,
    statusGetTeams: {},
    statusInviteUser: {},
    requestErrorMessage: '',
    teams: [],
    companyIdentifierExplainer: {},
    hasFeatureRemainingSeats: false,
    remainingSeats: null,
    requestMoreSeats: global.noop,
    setRequestErrorMessage: global.noop,
  };

  get hasTeams() {
    return this.props.teams.length;
  }

  get isRequesting() {
    return this.props.statusGetTeams.pending;
  }

  get teams() {
    const { teams } = this.props;
    const teamsByName = teams.sort((a, b) => a.name.localeCompare(b.name));
    return [NO_TEAM, ...teamsByName];
  }

  renderTeam = team => (
    <ArcBox display="flex" flexDirection="row" alignItems="center">
      {team.id !== '-1' && (
        <ArcEntityAvatar
          id={team.id}
          type="team"
          shape="circle"
          letter={getTeamLetter(team.name)}
          color={getTeamColor(team.id)}
          size={22}
        />
      )}

      <ArcBox
        component={ArcText}
        fontSize={14}
        color="palette.common.black"
        overflow="hidden"
        textOverflow="ellipsis"
        lineHeight={1.5}
        width={240}
        ml={1}
      >
        {team.name}
      </ArcBox>
    </ArcBox>
  );

  renderTeamItem = team => (
    <MenuItem key={team.id} style={styleMenuItem} value={team.id}>
      {this.renderTeam(team)}
    </MenuItem>
  );

  renderTeamItems = () => this.teams.map(this.renderTeamItem);

  renderTeamValue = value => {
    const team = this.teams.filter(t => t.id === value)[0];

    if (this.hasTeams && team) {
      return this.renderTeam(team);
    }

    return (
      <ArcView row align="center">
        <ArcView
          className="shimmer"
          padding="12"
          flexGrow="100"
          marginRight="8"
        />
      </ArcView>
    );
  };

  renderForm = formikProps => {
    const {
      companyIdentifierExplainer,
      identifierLabel,
      hasFeatureRemainingSeats,
      remainingSeats,
      requestMoreSeats,
      setRequestErrorMessage,
    } = this.props;
    const { values, handleChange: formikChange } = formikProps;

    const handleChange = e => {
      setRequestErrorMessage('');
      formikChange(e);
    };

    const primaryTeamExplainerName =
      values.firstName.length > 3 ? values.firstName : 'the user';
    const hasRemainingSeats = remainingSeats > 0;
    const shouldDisableForm = hasFeatureRemainingSeats && remainingSeats === 0;

    return (
      <>
        <DialogTitle>
          <ArcView marginBottom="16">
            <ArcBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              margin={1}
            >
              <ArcBox bgcolor="blue.100" color="blue.main">
                <GameControllerTeam height={32} width={32} />
              </ArcBox>

              {hasFeatureRemainingSeats && remainingSeats !== null && (
                <ArcBox
                  color={hasRemainingSeats ? 'green.main' : 'red.main'}
                  bgcolor={hasRemainingSeats ? 'green.tint' : 'red.tint'}
                  px={1}
                  borderRadius={8}
                  display="flex"
                  alignItems="center"
                >
                  <Ticket height={24} width={24} marginRight={1} />

                  <ArcBox
                    component={ArcText}
                    fontWeight="bold"
                    fontSize={14}
                    marginTop={1}
                    marginBottom={1}
                  >
                    {`${remainingSeats} ${pluralize(
                      remainingSeats,
                      'Seat',
                    )} Left`}
                  </ArcBox>
                </ArcBox>
              )}
            </ArcBox>

            <ArcBox
              component={ArcText}
              fontWeight="bold"
              fontSize={20}
              marginTop={1}
              marginBottom={1}
            >
              {'Invite to Arcade'}
            </ArcBox>
          </ArcView>
        </DialogTitle>

        <DialogContent>
          {shouldDisableForm ? (
            <ArcBox
              border={1}
              borderColor="divider"
              borderRadius={8}
              display="flex"
              flexDirection="column"
              px={2}
              py={20}
            >
              <ArcBox
                component={ArcText}
                fontWeight="bold"
                fontSize={18}
                align="center"
                marginBottom={1}
                color="grey.main"
              >
                {'Submit a request for more'}
              </ArcBox>
              <ArcBox
                component={ArcText}
                fontSize={14}
                marginBottom={2}
                color="grey.main"
                align="center"
              >
                {
                  'There are not enough seats remaining. You will not be able to invite any further users.'
                }
              </ArcBox>
              <ArcButton color="primary" onClick={requestMoreSeats}>
                {'Request more seats'}
              </ArcButton>
            </ArcBox>
          ) : (
            <ArcBox display="flex" flexDirection="column" height="100%">
              <ArcFormField
                name="firstName"
                type="text"
                label="First Name"
                disabled={this.isRequesting}
                autoComplete="off"
                fullWidth
                onChange={handleChange}
                validations={{
                  isRequired: true,
                  maxLength: 30,
                }}
              />

              <ArcFormField
                name="lastName"
                type="text"
                label="Last Name"
                disabled={this.isRequesting}
                autoComplete="off"
                fullWidth
                onChange={handleChange}
                validations={{
                  isRequired: true,
                  maxLength: 30,
                }}
              />

              <ArcFormField
                name="email"
                type="email"
                label="Email"
                disabled={this.isRequesting}
                autoComplete="off"
                fullWidth
                onChange={handleChange}
                validations={{
                  isRequired: true,
                  isEmail: true,
                }}
              />

              <ArcFormField
                name="companyIdentifier"
                type="text"
                label={identifierLabel}
                disabled={this.isRequesting}
                fullWidth
                hasNoMarginBottom
                onChange={handleChange}
              />
              {!!companyIdentifierExplainer &&
                companyIdentifierExplainer.description && (
                  <ArcCollapsibleExplainer
                    mt={1}
                    title={companyIdentifierExplainer.label}
                    body={companyIdentifierExplainer.description}
                    maxWidth="100%"
                  />
                )}

              <ArcBox my={2}>
                <ArcBox
                  component={ArcText}
                  color="#616264"
                  fontSize={16}
                  fontWeight={600}
                  lineHeight="150%"
                >
                  {'Select a Team'}
                </ArcBox>
              </ArcBox>

              <ArcFormField
                name="teamId"
                type="select"
                margin="dense"
                disabled={this.isRequesting || !this.hasTeams}
                onChange={handleChange}
                validations={{
                  isRequired: true,
                }}
                renderValue={this.renderTeamValue}
                style={{
                  fontSize: 14,
                  color: '#373A3D',
                  backgroundColor: '#EBEBEB',
                  width: '60%',
                }}
              >
                {this.renderTeamItems()}
              </ArcFormField>

              <ArcBox
                component={ArcText}
                fontSize={12}
                lineHeight="150%"
                color="#616264"
              >
                {`This is the team ${primaryTeamExplainerName} will see on their profile and represent in team based games.`}
              </ArcBox>

              {this.props.hasGeofenceField && (
                <ArcFormField
                  name="shouldBeGeofenced"
                  type="checkbox"
                  label="Should be geofenced"
                />
              )}

              {this.props.requestErrorMessage && (
                <ArcBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  className="animated fadeIn"
                  marginTop="10px"
                >
                  <IconErrorOutlined color="error" style={styleIconError} />
                  <ArcBox component={ArcText} color="red.main" fontSize={12}>
                    {this.props.requestErrorMessage}
                  </ArcBox>
                </ArcBox>
              )}
            </ArcBox>
          )}
        </DialogContent>

        <DialogActions>
          <ArcBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            p={2}
            pt={0}
          >
            <ArcButton label="Close" onClick={this.props.onClose} />
            <ArcBox ml={1}>
              <ArcResourceButton
                type="submit"
                color="primary"
                disabled={
                  this.isRequesting ||
                  (formikProps.submitCount > 0 && !formikProps.isValid) ||
                  shouldDisableForm
                }
                variant="contained"
                label="Send Invite"
                onClick={
                  formikProps.isValid ? formikProps.handleSubmit : undefined
                }
                status={this.props.statusInviteUser}
              />
            </ArcBox>
          </ArcBox>
        </DialogActions>
      </>
    );
  };

  render() {
    return (
      <ArcResponsiveDialog
        open={this.props.open}
        maxWidth={350}
        onClose={this.props.onClose}
      >
        <ArcForm
          onSubmit={this.props.onSubmit}
          initialValues={INITIAL_VALUES}
          validationSchema={VALIDATION_SCHEMA}
        >
          {this.renderForm}
        </ArcForm>
      </ArcResponsiveDialog>
    );
  }
}

export default InvitePersonForm;
