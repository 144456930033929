import { react2angular } from 'react2angular';
import ConnectedArcUserInput from 'arcade-frontend-core/src/containers/ArcUserInputContainer';

import { createProvidedContainer } from './helpers';

const ProvidedArcUserInput = createProvidedContainer(ConnectedArcUserInput);

export default react2angular(ProvidedArcUserInput, [
  'autoComplete',
  'hasHashTags',
  'onChange',
  'onSubmit',
  'placeholder',
  'resetOnSubmit',
  'submitOnEnter',
  'value',
  'taggingRules',
]);
