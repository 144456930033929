import React from 'react';

import {
  ArcPropTypes,
  ArcImage,
  ArcParagraph,
  ArcPlaceholder,
  ArcView,

  createWithStyles,
} from 'arcade-frontend-ui';

const styles = {
  Header: theme => ({
    root: {
      border: `4px solid ${theme.palette.divider}`,
      padding: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
  }),

  GameImage: () => ({
    root: {
      width: 72,
      height: 72,
    },
  }),

  Container: theme => ({
    root: {
      maxWidth: 600,
      marginBottom: 48,

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
  }),

  Content: theme => ({
    root: {
      alignSelf: 'stretch',
      flexShrink: 1,
      width: '100%',

      [theme.breakpoints.down('xs')]: {
        marginTop: 16,
      },
    },
  }),

  ParagraphLoading: theme => ({
    root: {
      overflow: 'hidden',
      background: theme.palette.divider,
      marginBottom: theme.spacing(1.5),
      height: 16,
    },
    hasNoMargin: {
      marginTop: 0,
      marginBottom: 0,
    },
  }),
};

const Header = createWithStyles(styles.Header)(ArcView);
const GameImage = createWithStyles(styles.GameImage)(ArcImage);
const GameImageLoading = createWithStyles(styles.GameImage)(ArcView);
const Container = createWithStyles(styles.Container)(ArcView);
const Content = createWithStyles(styles.Content)(ArcView);
const ParagraphLoading = createWithStyles(styles.ParagraphLoading)(ArcView);

const Placeholder = () => (
  <>
    <Header>
      <GameImageLoading>
        <ArcPlaceholder />
      </GameImageLoading>
    </Header>
    <Content justify="space-between">
      <ArcView>
        <ParagraphLoading>
          <ArcPlaceholder />
        </ParagraphLoading>
        <ParagraphLoading>
          <ArcPlaceholder />
        </ParagraphLoading>
      </ArcView>
      <ParagraphLoading hasNoMargin>
        <ArcPlaceholder />
      </ParagraphLoading>
    </Content>
  </>
);

const GameCardPreview = (props) => {
  const isLoading = props.explainer === undefined || props.imageUrl === undefined;
  return (
    <Container className="animated fadeIn" align="flex-start">
      {isLoading ? (
        <Placeholder />
      ) : (
        <>
          <Header>
            <GameImage src={props.imageUrl} />
          </Header>
          <Content justify="space-between">
            <ArcParagraph>
              {props.explainer}
            </ArcParagraph>
            {props.children}
          </Content>
        </>
      )}
    </Container>
  );
};

GameCardPreview.propTypes = {
  children: ArcPropTypes.children,
  imageUrl: ArcPropTypes.string.isRequired,
  explainer: ArcPropTypes.children.isRequired,
};

GameCardPreview.defaultProps = {
  children: null,
};

GameCardPreview.Placeholder = Placeholder;

export default GameCardPreview;
