import * as types from 'arcade-frontend-core/src/types/routes';

export default {
  [types.ROUTE_REWARDS]: '/arcade/prize',
  [types.ROUTE_REWARDS_AWARD]: '/arcade/prize/distribute',
  [types.ROUTE_REWARDS_CHESTS]: '/arcade/prize/chests',
  [types.ROUTE_REWARDS_HISTORY]: '/arcade/prize/history',
  [types.ROUTE_REWARDS_SHOP]: '/arcade/prize/shop',
  [types.ROUTE_REWARDS_TRANSACTIONS]: '/arcade/prize/transactions',

};
