import getTransformedResponseFromResourceAction from 'arcade-frontend-core/src/helpers/getTransformedResponseFromResourceAction';

import * as resources from './game-leaderboard.resources';

import apiGetGameLeaderboardRaw1 from './raw/apiGetGameLeaderboardRaw-1.json';

export const apiGetGameLeaderboardRaw = apiGetGameLeaderboardRaw1;

const META_PAYLOAD = {
  meta: {
    gameId: 0,
  },
};

export const apiGetGameLeaderboard = getTransformedResponseFromResourceAction(
  resources.apiGetGameLeaderboard,
  apiGetGameLeaderboardRaw,
  META_PAYLOAD,
);
