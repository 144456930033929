import React from 'react';
import PropTypes from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionTypes } from 'redux-resource';

import withResourceAction from 'arcade-frontend-core/src/helpers/withResourceAction';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';

import { resources } from '../../resources/comments';
import { getDeleteStatus } from '../../reducers/rrComments';

const STRINGS = {
  'NEWSFEED/ACTIVITY_COMMENT_DELETE_LABEL': 'Delete Comment',
};

class NewsfeedActivityCommentsDeleteContainer extends React.PureComponent {
  static displayName = 'NewsfeedActivityCommentsDeleteContainer';

  static propTypes = {
    activityId: PropTypes.string,
    commentId: PropTypes.string,
    dispatch: PropTypes.func,
    onStatusReset: PropTypes.func,
    requestData: PropTypes.func,
    status: PropTypes.shape({
      idle: PropTypes.bool,
      pending: PropTypes.bool,
      failed: PropTypes.bool,
      succeeded: PropTypes.bool,
    }),
  };

  static defaultProps = {
    activityId: '',
    commentId: null,
    dispatch: global.noop,
    onStatusReset: global.noop,
    requestData: global.noop,
    status: {},
  };

  requestData = () =>
    this.props
      .requestData({
        resources: [
          {
            id: this.props.commentId,
            activityId: this.props.activityId,
          },
        ],
      })
      .then(() => {
        this.props.dispatch({
          type: actionTypes.UPDATE_RESOURCES,
          meta: {
            rrComments: {
              [this.props.commentId]: {
                deleted: true,
              },
            },
          },
        });
        this.props.onStatusReset();
      });

  render() {
    const { status } = this.props;

    const deleteButtonStyle = {
      width: 92,
      height: 26,
      minHeight: 26,
      padding: '6px 0',
      fontSize: 9,
    };

    return (
      <ArcResourceButton
        onClick={this.requestData}
        size="small"
        variant="outlined"
        color="danger"
        style={deleteButtonStyle}
        label={STRINGS['NEWSFEED/ACTIVITY_COMMENT_DELETE_LABEL']}
        status={status}
        disabled={false}
      />
    );
  }
}

const getState = (state, props) => ({
  status: getDeleteStatus(state),
  ...props,
});

const getActions = dispatch => bindActionCreators({}, dispatch);

export default compose(
  connect(getState, getActions),
  withResourceAction(resources.apiDeleteComment),
)(NewsfeedActivityCommentsDeleteContainer);
