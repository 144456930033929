import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  ArcView,
  ArcDataTableCell,
  ArcNoResultsMessage,
  createWithStyles,
} from 'arcade-frontend-ui';

import IntegrationHistoryListBodyPending from '../IntegrationHistoryListBodyPending';
import IntegrationHistoryListRow from '../IntegrationHistoryListRow';

const styles = {
  TableWrapper: theme => ({
    root: {
      borderWidth: '1px 0',
      borderColor: theme.palette.grey[300],
      borderStyle: 'solid',
      backgroundColor: theme.palette.common.white,
      height: '100%',
      overflow: 'auto',
    },
  }),
};

const TableWrapper = createWithStyles(styles.TableWrapper)(ArcView);

const IntegrationHistoryList = ({
  runs,
  hasFailed,
  isPending,
  onWithdrawRequest,
  onShowSalesRequest,
  onShowMessages,
}) => {
  if (hasFailed) return 'failed';

  if (runs && runs.length === 0) return <ArcNoResultsMessage />;

  return (
    <TableWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <ArcDataTableCell headerCell>Run</ArcDataTableCell>
            <ArcDataTableCell headerCell>Status</ArcDataTableCell>
            <ArcDataTableCell headerCell>Sales</ArcDataTableCell>
            <ArcDataTableCell headerCell>Details</ArcDataTableCell>
            <ArcDataTableCell headerCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {runs === null || isPending ? (
            <IntegrationHistoryListBodyPending />
          ) : (
            runs.map((run, index) => (
              <IntegrationHistoryListRow
                key={run.id}
                run={run}
                shade={index % 2 === 0}
                onWithdrawRequest={onWithdrawRequest}
                onShowSalesRequest={onShowSalesRequest}
                onShowMessages={onShowMessages}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

IntegrationHistoryList.propTypes = {
  runs: PropTypes.arrayOf(PropTypes.object),
  isPending: PropTypes.bool,
  hasFailed: PropTypes.bool,
  onWithdrawRequest: PropTypes.func.isRequired,
  onShowSalesRequest: PropTypes.func.isRequired,
  onShowMessages: PropTypes.func.isRequired,
};

IntegrationHistoryList.defaultProps = {
  runs: null,
  isPending: false,
  hasFailed: false,
};

export default IntegrationHistoryList;
