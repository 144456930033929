import { actionTypes } from 'redux-resource';

import { camelizeKeys } from 'humps';
import { createSelector } from 'reselect';
import { resources } from 'arcade-frontend-core/src/resources/profile';
import { resources as userResources } from 'arcade-frontend-core/src/resources/users';
import { CHONKY_LOGIN } from 'arcade-frontend-core/src/types/feature-flags';

import { setArcUserHeaders } from '../helpers/axios';

import { types } from '../actions';

import { getById } from './teams/byId';

const normalizeUser = props => ({
  ...camelizeKeys(props),
  ...props,
  id: props.id ? props.id.toString() : '',
});

const initialState = {
  currentUser: {},
  endpoint: undefined,
  isViewingMain: false,
  permissions: {},
  chatEndpoint: undefined,
};

const handleAppLoginSuccess = (state, action) => {
  const { requestAction } = action.meta;
  const { data } = action.payload;

  let user = data;

  if (requestAction && requestAction.payload.meta) {
    user = {
      ...requestAction.payload.meta,
      ...user,
    };
  }

  return {
    ...state,
    currentUser: normalizeUser(user),
  };
};

const getPermissionsByName = (state, nextPermissions) => {
  if (!nextPermissions) {
    return state;
  }

  const permissions = {};

  nextPermissions.forEach(permission => {
    permissions[permission.identifier] = true;
  });

  return {
    ...state,
    permissions,
  };
};

const handleAppPermissionsSuccess = (state, action) =>
  getPermissionsByName(state, action.payload.data);

const handleSetAppPermissions = (state, action) =>
  getPermissionsByName(state, action.payload);

const handleAppFeaturesSuccess = (state, action) => {
  const { features } = action.payload.data;

  return {
    ...state,
    features,
  };
};

const handleAppSetFirstSignIn = (state, action) => ({
  ...state,
  currentUser: {
    ...state.currentUser,
    firstSignIn: action.payload,
  },
});

const handleWebviewSetProfileImageUrl = (state, action) => {
  const { payload } = action;

  if (!payload) {
    return state;
  }

  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      profileImage: payload,
    },
  };
};

const handleWebviewSetCurrentUser = (state, action) => {
  const { payload } = action;

  if (!payload) {
    return state;
  }

  setArcUserHeaders(payload.authenticationToken, payload.email);

  return {
    ...state,
    currentUser: payload,
  };
};

const setCurrentUser = (state, action) => {
  const { currentUser } = action.payload;

  if (!currentUser || !currentUser.authenticationToken) {
    return {
      ...initialState,
      platform: state.platform,
    };
  }

  const { authenticationToken, email } = currentUser;
  setArcUserHeaders(authenticationToken, email);

  return {
    ...state,
    currentUser: normalizeUser(currentUser),
    endpoint: action.payload.endpoint,
    chatEndpoint: action.payload.chatEndpoint,
    features: currentUser.features,
  };
};

const handleAppLogout = () => ({
  ...initialState,
  currentUser: {},
});

const handlePutProfileSucceeded = (state, action) => {
  const nextCurrentUser = action.resources[0];

  const currentUser = {
    ...state.currentUser,
    ...nextCurrentUser,
  };

  if (window.ngHttp) {
    window.ngHttp.defaults.headers.common['X-User-Email'] = currentUser.email;
  }

  if (window.localStorage) {
    window.localStorage.setItem('user', JSON.stringify(currentUser));
    window.localStorage.setItem('email', currentUser.email);
  }

  return {
    ...state,
    currentUser,
  };
};

const handlePostUserSignInSucceeded = (state, action) => {
  const nextCurrentUser = action.resources[0];

  const currentUser = {
    ...state.currentUser,
    ...nextCurrentUser,
  };

  if (window.ngHttp) {
    window.ngHttp.defaults.headers.common['X-User-Email'] = currentUser.email;
  }

  setArcUserHeaders(currentUser.authenticationToken, currentUser.email);

  return {
    ...state,
    currentUser,
  };
};

const handleCreateResourcesSucceeded = (state, action) => {
  switch (action.requestKey) {
    case userResources.apiPostUserSignIn.requestKey:
      return handlePostUserSignInSucceeded(state, action);
    default:
      return state;
  }
};

const handleUpdateResourcesSucceeded = (state, action) => {
  switch (action.requestKey) {
    case resources.apiPutProfile.requestKey:
      return handlePutProfileSucceeded(state, action);
    default:
      return state;
  }
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_APP_PERMISSIONS:
      return handleSetAppPermissions(state, action);
    case types.SET_CURRENT_USER:
      return setCurrentUser(state, action);

    case types.SET_IS_VIEWING_MAIN:
      return {
        ...state,
        isViewingMain: action.payload.isViewingMain,
      };

    case types.APP_LOGIN.SUCCESS:
      return handleAppLoginSuccess(state, action);

    case types.APP_PERMISSIONS.SUCCESS:
      return handleAppPermissionsSuccess(state, action);

    case types.APP_FEATURES.SUCCESS:
    case types.APP_UPDATE_FEATURES.SUCCESS:
      return handleAppFeaturesSuccess(state, action);

    case types.APP_SET_FIRST_SIGN_IN:
      return handleAppSetFirstSignIn(state, action);

    case types.LOGOUT:
    case types.APP_LOGOUT.REQUEST:
      return handleAppLogout(state);

    case actionTypes.CREATE_RESOURCES_SUCCEEDED:
      return handleCreateResourcesSucceeded(state, action);

    case actionTypes.UPDATE_RESOURCES_SUCCEEDED:
      return handleUpdateResourcesSucceeded(state, action);

    case types.WEBVIEW_SET_PROFILE_IMAGE_URL:
      return handleWebviewSetProfileImageUrl(state, action);

    case types.WEBVIEW_SET_CURRENT_USER:
      return handleWebviewSetCurrentUser(state, action);

    default:
      return state;
  }
};

export default user;

export const getCurrentUser = state => state.app.user.currentUser;
export const getCurrentUserEndpoint = state => state.app.user.endpoint;
export const getCurrentUserEndpointChat = state => state.app.user.chatEndpoint;
export const getCurrentUserId = state => getCurrentUser(state).id;
export const getCurrentUserPermission = (state, permission) =>
  !!state.app.user.permissions[permission];
export const getCurrentUserPermissions = state => state.app.user.permissions;
export const getCurrentUserFeatures = (state, feature) =>
  !!state.app.user.features && !!state.app.user.features[feature];

export const getUserTeamMembers = createSelector(
  getCurrentUser,
  getById,
  (u, teams) => {
    if (u.team_id && teams[u.team_id]) {
      return teams[u.team_id].peopleIds;
    }
    return [];
  },
);
