import { react2angular } from 'react2angular';

import {
  ArcDatePicker,
} from 'arcade-frontend-ui';

import { createProvidedContainer } from './helpers';

const ProvidedArcDatePickerContainer = createProvidedContainer(ArcDatePicker);

export default react2angular(ProvidedArcDatePickerContainer, [
  'minDate',
  'onChange',
  'value',
]);
