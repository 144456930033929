import { compose } from 'redux';

import createResourceAction from 'arcade-frontend-core/src/helpers/createResourceAction';
import transformIdToString from 'arcade-frontend-core/src/helpers/transformIdToString';
import transformUserToFlatUser from 'arcade-frontend-core/src/helpers/transformUserToFlatUser';

const transformGetActivityCommentReaction = compose(
  transformIdToString,
  transformUserToFlatUser,
);

const transformCommentReactions = comment => ({
  ...comment,
  reactions: comment.reactions
    ? comment.reactions.map(transformGetActivityCommentReaction)
    : [],
});

const mapCommentFields = comment => ({
  ...comment,
  readableDate: comment.readableDateData,
  userName: comment.username,
});

const transformComment = compose(
  transformIdToString,
  mapCommentFields,
  transformCommentReactions,
);

export const apiGetActivityComments = createResourceAction('read', {
  resourceType: 'rrComments',
  requestKey: 'apiGetActivityComments',

  api: payload => ({
    method: 'GET',
    url: `/v4/activities/${payload.resources[0]}/comments`,
    transformResponse: data => ({
      resources: data.comments.map(transformComment),
    }),
  }),
});

export const apiCreateComment = createResourceAction('create', {
  resourceType: 'rrComments',
  requestKey: 'apiCreateComment',

  api: payload => {
    const { resources, list } = payload;
    const [comment] = resources;
    const id = list;

    return {
      method: 'POST',
      url: '/v3/comments',
      data: { comment },

      transformResponse: [
        data => data.comment,
        transformComment,
        resource => ({ resources: [resource], list: id }),
      ],
    };
  },
});

export const apiDeleteComment = createResourceAction('delete', {
  resourceType: 'rrComments',
  requestKey: 'apiDeleteComment',

  api: payload => ({
    method: 'DELETE',
    url: `/v4/activities/${payload.resources[0].activityId}/comments/${payload.resources[0].id}`,
    transformResponse: () => ({ resources: [{ id: payload.resources[0].id }] }),
  }),
});

export const apiUpdateComment = createResourceAction('read', {
  resourceType: 'rrComments',
  requestKey: 'apiGetComment',

  api: payload => ({
    method: 'GET',
    url: `/v4/comments/${payload.resources[0].id}`,
    transformResponse: () => ({ resources: [{ id: payload.resources[0].id }] }),
  }),
});
