import React from 'react';
import PropTypes from 'prop-types';

import * as GAME_STATUSES from 'arcade-frontend-core/src/types/game-statuses';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';
import { INITIAL_STATUS } from 'arcade-frontend-core/src/hooks/useResourceAction';
import {
  PENDING,
  CANCELLABLE_APPROVAL,
  CANCELLABLE_REJECTION,
  APPROVED,
  REJECTED,
  SALE_CREATED,
  GAME_EVENT_STATUSES,
} from 'arcade-frontend-core/src/types/game-events';

function GamesEventStatus({
  gameStatus,
  canVerify,
  status,
  onVerifyClick,
  updateStatus,
}) {
  const showButton =
    status === PENDING ||
    (status === CANCELLABLE_APPROVAL && !updateStatus.idle);

  if (showButton && gameStatus === GAME_STATUSES.COMPLETED) {
    return <ArcBox color="text.disabled">Unverified</ArcBox>;
  }

  if (canVerify && showButton) {
    return (
      <ArcBox>
        <ArcResourceButton
          label="verify"
          color="primary"
          size="small"
          status={updateStatus}
          onClick={onVerifyClick}
        />
      </ArcBox>
    );
  }

  switch (status) {
    case PENDING:
      return <ArcBox color="text.disabled">Pending</ArcBox>;
    case CANCELLABLE_APPROVAL:
    case APPROVED:
      return <ArcBox color="success.main">Approved</ArcBox>;
    case CANCELLABLE_REJECTION:
    case REJECTED:
      return <ArcBox color="warning.main">Rejected</ArcBox>;
    case SALE_CREATED:
      return <ArcBox color="success.main">Confirmed</ArcBox>;
    default:
      return null;
  }
}

GamesEventStatus.displayName = 'GamesEventStatus';

GamesEventStatus.propTypes = {
  gameStatus: PropTypes.oneOf(Object.values(GAME_STATUSES)),
  canVerify: PropTypes.bool,
  status: PropTypes.oneOf(GAME_EVENT_STATUSES),
  onVerifyClick: PropTypes.func,
  updateStatus: PropTypes.shape({
    idle: PropTypes.bool,
  }),
};

GamesEventStatus.defaultProps = {
  gameStatus: GAME_STATUSES.ACTIVE,
  canVerify: false,
  status: PENDING,
  onVerifyClick: global.noop,
  updateStatus: INITIAL_STATUS,
};

export default GamesEventStatus;
