import React from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';

import IconClear from '@material-ui/icons/Clear';
import IconList from '@material-ui/icons/List';

import {
  ArcCard,
  ArcCardContent,
  ArcCardHeader,
  ArcIconButton,
  ArcLoader,
  ArcManageChecklist,
  ArcPropTypes,
  ArcScroll,
  ArcView,
} from 'arcade-frontend-ui';

const styleCloseButton = {
  position: 'absolute',
  top: 2,
  right: 16,
  width: 56,
  height: 54,
  fontSize: 40,
  padding: 0,
};

const styleChecklistContainer = {
  margin: 8,
  maxWidth: 600,
};

const checklist = {
  id: '0',
  title: 'Onboarding',
  items: [
    {
      value: 'send_message',
      label: 'Send a message',
      percent: 100,
      users: [
        {
          id: '0',
          name: 'David Cherrie',
          completed: true,
          completedAt: new Date(),
        },
        {
          id: '1',
          name: 'James Mclaren',
          completed: false,
        },
      ],
    },
    {
      value: 'equip_badge',
      label: 'Equip a badge',
      percent: 36,
      users: [
        {
          id: '0',
          name: 'David Cherrie',
          completed: true,
          completedAt: new Date(),
        },
        {
          id: '1',
          name: 'James Mclaren',
          completed: false,
        },
      ],
    },
    {
      value: 'post_newsfeed',
      label: 'Post on the newsfeed',
      percent: 24,
      users: [
        {
          id: '0',
          name: 'David Cherrie',
          completed: true,
          completedAt: new Date(),
        },
        {
          id: '1',
          name: 'James Mclaren',
          completed: false,
        },
      ],
    },
    {
      value: 'open_chest',
      label: 'Open a chest',
      percent: 55,
      users: [
        {
          id: '0',
          name: 'David Cherrie',
          completed: true,
          completedAt: new Date(),
        },
        {
          id: '1',
          name: 'James Mclaren',
          completed: false,
        },
      ],
    },
    {
      value: 'add_picture',
      label: 'Update your profile picture',
      percent: 76,
      users: [
        {
          id: '0',
          name: 'David Cherrie',
          completed: true,
          completedAt: new Date(),
        },
        {
          id: '1',
          name: 'James Mclaren',
          completed: false,
        },
      ],
    },
  ],
};


class ManageChecklistsDetailsContainer extends React.PureComponent {
  static propTypes = {
    onClose: ArcPropTypes.func.isRequired,
    // title: ArcPropTypes.string,
  };

  static defaultProps = {
    // title: '',
  };

  state = {
    isReady: true,
  };

  setIsReady = isReady => setTimeout(() => this.setState({ isReady }), 800);

  renderHeader() {
    return (
      <ArcView justify="space-between">
        <ArcCardHeader lineOne={<IconList />} lineTwo="Onboarding" />

        <ArcIconButton
          onClick={this.props.onClose}
          disableRipple
          style={styleCloseButton}
        >
          <IconClear
            color="disabled"
            fontSize="inherit"
          />
        </ArcIconButton>
      </ArcView>
    );
  }

  render() {
    const {
      isReady,
    } = this.state;

    if (!isReady) return <ArcLoader in={false} />;

    return (
      <ArcCard hasDetails>
        {this.renderHeader()}
        <ArcCardContent>
          <ArcScroll>
            <ArcView style={styleChecklistContainer}>
              <ArcManageChecklist {...checklist} />
            </ArcView>
          </ArcScroll>
        </ArcCardContent>
      </ArcCard>
    );
  }
}

// const mapStateToProps = state => ({

// });

// const mapDispatchToProps = dispatch => bindActionCreators({

// }, dispatch);

// export default connect(mapStateToProps, mapDispatchToProps)(ManageChecklistsDetailsContainer);

export default ManageChecklistsDetailsContainer;
