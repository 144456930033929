import React from 'react';
import {
  ArcView,
  ArcPropTypes,
} from 'arcade-frontend-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getNewSizzleState } from 'arcade-frontend-core/src/reducers/sizzle';

import { actions } from 'arcade-frontend-core/src/actions';

import {
  sizzleTypes,
} from './Sizzles';

const containerStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: 0,
  zIndex: 9999,
};

export class SizzleContainer extends React.Component {
  static propTypes = {
    newSizzle: ArcPropTypes.shape({}),
    clearNewSizzle: ArcPropTypes.func.isRequired,
  };

  static defaultProps = {
    newSizzle: undefined,
  }

  state = {
    sizzles: [],
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.newSizzle) {
      this.addSizzle(nextProps.newSizzle);
      this.props.clearNewSizzle();
    }
  }

  componentWillUnmount() {
    Object.values(this.timeouts).map(c => clearTimeout(c));
  }

  timeouts = {}

  removeSizzle(id) {
    delete this.timeouts[id];
    const sizzles = this.state.sizzles.filter(s => s.id !== id);
    this.setState({ sizzles });
  }

  addSizzle(event) {
    const { type, content } = event;
    const { renderer, duration } = sizzleTypes[type];
    const id = new Date().valueOf().toString();

    const timeout = setTimeout(() => {
      this.removeSizzle(id);
    }, duration);

    this.timeouts[id] = timeout;

    this.setState({
      sizzles: [
        { id, renderer, content },
        ...this.state.sizzles,
      ],
    });
  }

  render() {
    return (
      <ArcView style={containerStyle}>
        { this.state.sizzles.map(item => <item.renderer key={item.id} {...item.content} />,
        ) }
      </ArcView>
    );
  }
}

const mapStateToProps = state => getNewSizzleState(state);
const mapDispatchToProps = dispatch => bindActionCreators({
  clearNewSizzle: actions.appClearNewSizzle,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SizzleContainer);
