import React from 'react';

import { ArcSelect } from 'arcade-frontend-ui';

import RewardItem from './RewardItem';

const styleSelect = {
  marginBottom: 8,
};

const getDefaultSelectItem = (isDisabled = false) => {
  if (isDisabled) {
    return [{ value: 0, label: 'View', cost: 0 }];
  }
  return [{ value: 0, label: 'Select', cost: 0 }];
};

export default class GiftCard extends RewardItem {
  static propTypes = RewardItem.propTypes;

  static defaultProps = {
    variantLabel: 'Value',
    variants: [],
    ...RewardItem.defaultProps,
  };

  constructor(props) {
    super(props);

    this.setSmallestTokenCost();
  }

  componentWillReceiveProps(props) {
    if (props.variants) {
      this.setSmallestTokenCost();
    }
  }

  getVariantCost = variant => parseInt(variant.cost, 10);

  setSmallestTokenCost() {
    const validVariants = this.props.variants.filter(
      variant => !Number.isNaN(this.getVariantCost(variant)),
    );

    if (validVariants.length === 0) {
      this.smallestTokenCost = 0;
    } else {
      this.smallestTokenCost = Math.min(
        ...validVariants.map(this.getVariantCost),
      );
    }

    this.tokenCost = this.smallestTokenCost;
  }

  get currentSelectedBaseCost() {
    return this.state.selectedDenomination.label || 0;
  }

  get currentSelectedTokenCost() {
    return this.state.selectedDenomination.cost || 0;
  }

  get currentSelectedTokenValue() {
    return this.state.selectedDenomination.value || 0;
  }

  get finalPurchaseTokenValue() {
    return this.currentSelectedTokenCost;
  }

  get rewardItemSelected() {
    return this.currentSelectedTokenCost;
  }

  get rewardItemDisabled() {
    return this.smallestTokenCost > this.props.userBalance;
  }

  getValueMenuItems() {
    const items = this.props.variants.map(variant => ({
      ...variant,
      disabled: variant.cost > this.props.userBalance,
    }));

    return getDefaultSelectItem(this.rewardItemDisabled).concat(items);
  }

  setSelectedDenomination = selectedDenomination => {
    this.setState({ selectedDenomination });
  };

  handleSelectValue = event => {
    const selectedDenomination = this.denominationByBaseCost(
      event.target.value,
    );
    this.setSelectedDenomination(selectedDenomination);
  };

  denominationByBaseCost(selectedBaseValue) {
    return (
      this.props.variants.find(
        denomination => denomination.value === selectedBaseValue,
      ) || {}
    );
  }

  rewardItemControls() {
    return (
      <React.Fragment>
        <ArcSelect
          label={this.props.variantLabel}
          items={this.getValueMenuItems()}
          value={this.currentSelectedTokenValue}
          onChange={this.handleSelectValue}
          style={styleSelect}
        />
      </React.Fragment>
    );
  }
}
