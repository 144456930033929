import { makeApiActionTypes } from 'arcade-frontend-ui';

const UPLOAD_MANAGER_OPENED = 'UPLOAD_MANAGER_OPENED';
const UPLOAD_MANAGER_FILE_SELECTED = 'UPLOAD_MANAGER_FILE_SELECTED';
const UPLOAD_MANAGER_CANCELLED = 'UPLOAD_MANAGER_CANCELLED';
const UPLOAD_MANAGER_GET_MY_UPLOADS = makeApiActionTypes(
  'UPLOAD_MANAGER_GET_MY_UPLOADS',
);
const UPLOAD_MANAGER_GET_SHARED_UPLOADS = makeApiActionTypes(
  'UPLOAD_MANAGER_GET_SHARED_UPLOADS',
);
const UPLOAD_MANAGER_SEARCH_UPLOADS = makeApiActionTypes(
  'UPLOAD_MANAGER_SEARCH_UPLOADS',
);
const UPLOAD_MANAGER_START_UPLOAD = 'UPLOAD_MANAGER_START_UPLOAD';
const UPLOAD_MANAGER_CREATE_UPLOAD = makeApiActionTypes(
  'UPLOAD_MANAGER_CREATE_UPLOAD',
);
const UPLOAD_MANAGER_DELETE_UPLOAD = makeApiActionTypes(
  'UPLOAD_MANAGER_DELETE_UPLOAD',
);
const UPLOAD_MANAGER_UPDATE_UPLOAD = makeApiActionTypes(
  'UPLOAD_MANAGER_UPDATE_UPLOAD',
);
const UPLOAD_MANAGER_UPDATE_UPLOAD_FILE = makeApiActionTypes(
  'UPLOAD_MANAGER_UPDATE_UPLOAD_FILE',
);
const AUDIENCES_GET_AUDIENCES = makeApiActionTypes('AUDIENCES_GET_AUDIENCES');
const AUDIENCES_GET_AUDIENCE_TYPES = makeApiActionTypes(
  'AUDIENCES_GET_AUDIENCE_TYPES',
);
const AUDIENCES_TOGGLE_FILTER = 'AUDIENCES_TOGGLE_FILTER';
const AUDIENCES_SET_FILTER = 'AUDIENCES_SET_FILTER';
const SCROLL_NEWSFEED_TO_TOP = 'SCROLL_NEWSFEED_TO_TOP';

// File Types
const UPLOAD_MANAGER_GET_MY_FILES = makeApiActionTypes(
  'UPLOAD_MANAGER_GET_MY_FILES',
);
const UPLOAD_MANAGER_GET_SHARED_FILES = makeApiActionTypes(
  'UPLOAD_MANAGER_GET_SHARED_FILES',
);

const UPLOAD_MANAGER_RESET_SEARCH = 'UPLOAD_MANAGER_RESET_SEARCH';
const UPLOAD_MANAGER_START_FILE = 'UPLOAD_MANAGER_START_FILE';
const UPLOAD_MANAGER_CREATE_FILE = makeApiActionTypes(
  'UPLOAD_MANAGER_CREATE_FILE',
);
const UPLOAD_MANAGER_DELETE_FILE = makeApiActionTypes(
  'UPLOAD_MANAGER_DELETE_FILE',
);
const UPLOAD_MANAGER_UPDATE_FILE = makeApiActionTypes(
  'UPLOAD_MANAGER_UPDATE_FILE',
);

// Actions
const uploadManagerFileSelected = ({ selectedFile, source }) => ({
  type: UPLOAD_MANAGER_FILE_SELECTED,
  payload: { selectedFile, source },
});

const uploadManagerCancelled = () => ({
  type: UPLOAD_MANAGER_CANCELLED,
  payload: {},
});

const uploadManagerOpened = source => ({
  type: UPLOAD_MANAGER_OPENED,
  payload: { source },
});

const uploadManagerGetMyUploads = payload => ({
  type: UPLOAD_MANAGER_GET_MY_UPLOADS.REQUEST,
  payload,
});

const uploadManagerGetSharedUploads = payload => ({
  type: UPLOAD_MANAGER_GET_SHARED_UPLOADS.REQUEST,
  payload,
});

const uploadManagerSearchUploads = term => ({
  type: UPLOAD_MANAGER_SEARCH_UPLOADS.REQUEST,
  payload: term,
});

const uploadManagerResetSearch = () => ({
  type: UPLOAD_MANAGER_RESET_SEARCH,
  payload: {},
});

const uploadManagerCreateUpload = payload => ({
  type: UPLOAD_MANAGER_CREATE_UPLOAD.REQUEST,
  payload,
});

const uploadManagerDeleteUpload = ({ id }) => ({
  type: UPLOAD_MANAGER_DELETE_UPLOAD.REQUEST,
  payload: { id },
});

const uploadManagerStartUpload = payload => ({
  type: UPLOAD_MANAGER_START_UPLOAD,
  payload,
});

const uploadManagerUpdateUpload = props => ({
  type: UPLOAD_MANAGER_UPDATE_UPLOAD.REQUEST,
  payload: props,
});

const uploadManagerUpdateUploadFile = props => ({
  type: UPLOAD_MANAGER_UPDATE_UPLOAD_FILE.REQUEST,
  payload: props,
});

const uploadManagerGetMyFiles = payload => ({
  type: UPLOAD_MANAGER_GET_MY_FILES.REQUEST,
  payload,
});

const uploadManagerGetSharedFiles = payload => ({
  type: UPLOAD_MANAGER_GET_SHARED_FILES.REQUEST,
  payload,
});

const uploadManagerDeleteFile = ({ id }) => ({
  type: UPLOAD_MANAGER_DELETE_FILE.REQUEST,
  payload: { id },
});

const audiencesLoadAudiences = filter => ({
  type: AUDIENCES_GET_AUDIENCES.REQUEST,
  payload: filter,
});

const audiencesLoadAudienceTypes = () => ({
  type: AUDIENCES_GET_AUDIENCE_TYPES.REQUEST,
  payload: {},
});

const audiencesToggleFilter = payload => ({
  type: AUDIENCES_TOGGLE_FILTER,
  payload,
});

const audiencesSetFilter = filter => ({
  type: AUDIENCES_SET_FILTER,
  payload: filter,
});

const scrollNewsfeedToTop = () => ({
  type: SCROLL_NEWSFEED_TO_TOP,
  payload: {},
});

export const actions = {
  uploadManagerOpened,
  uploadManagerFileSelected,
  uploadManagerCancelled,
  uploadManagerGetMyUploads,
  uploadManagerGetSharedUploads,
  uploadManagerSearchUploads,
  uploadManagerResetSearch,
  uploadManagerStartUpload,
  uploadManagerCreateUpload,
  uploadManagerDeleteUpload,
  uploadManagerUpdateUpload,
  uploadManagerUpdateUploadFile,
  audiencesLoadAudiences,
  audiencesLoadAudienceTypes,
  audiencesToggleFilter,
  audiencesSetFilter,
  uploadManagerGetMyFiles,
  uploadManagerGetSharedFiles,
  uploadManagerDeleteFile,
  scrollNewsfeedToTop,
};

export const types = {
  UPLOAD_MANAGER_OPENED,
  UPLOAD_MANAGER_FILE_SELECTED,
  UPLOAD_MANAGER_CANCELLED,
  UPLOAD_MANAGER_GET_MY_UPLOADS,
  UPLOAD_MANAGER_GET_SHARED_UPLOADS,
  UPLOAD_MANAGER_SEARCH_UPLOADS,
  UPLOAD_MANAGER_RESET_SEARCH,
  UPLOAD_MANAGER_START_UPLOAD,
  UPLOAD_MANAGER_CREATE_UPLOAD,
  UPLOAD_MANAGER_DELETE_UPLOAD,
  UPLOAD_MANAGER_UPDATE_UPLOAD,
  UPLOAD_MANAGER_UPDATE_UPLOAD_FILE,
  AUDIENCES_GET_AUDIENCES,
  AUDIENCES_GET_AUDIENCE_TYPES,
  AUDIENCES_TOGGLE_FILTER,
  AUDIENCES_SET_FILTER,
  UPLOAD_MANAGER_GET_MY_FILES,
  UPLOAD_MANAGER_GET_SHARED_FILES,
  UPLOAD_MANAGER_START_FILE,
  UPLOAD_MANAGER_CREATE_FILE,
  UPLOAD_MANAGER_DELETE_FILE,
  UPLOAD_MANAGER_UPDATE_FILE,
  SCROLL_NEWSFEED_TO_TOP,
};
