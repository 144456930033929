import { createIsFetchingReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const initialState = false;

const apiActionTypes = [types.PEOPLE_INDEX];

const isFetching = createIsFetchingReducer(initialState, apiActionTypes);

export default isFetching;

export const getIsFetching = state => state.people.isFetching;
