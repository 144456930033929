import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ArcAudienceCreateForm from '../../components/ArcAudienceCreateForm';
import { actions } from '../../actions/customAudience';
import { getEntitiesByUuid } from '../../reducers/customAudience/entitiesByUuid';
import { getOpen } from '../../reducers/customAudience/open';
import { getRequestStatus } from '../../reducers/customAudience/requestStatus';

class ArcCustomAudienceContainer extends React.PureComponent {
  static displayName = 'ArcCustomAudienceContainer';

  static propTypes = {
    apiCustomAudienceCreateRequest: PropTypes.func.isRequired,
    apiCustomAudienceEntitiesRequest: PropTypes.func.isRequired,
    entitiesByUuid: PropTypes.objectOf(
      PropTypes.shape({
        children: PropTypes.array,
        level: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    onClose: PropTypes.func,
    open: PropTypes.bool,
    requestStatus: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    entitiesByUuid: {},
    onClose: global.noop,
    open: false,
  };

  render() {
    return (
      <ArcAudienceCreateForm
        {...this.props}
        confirmationRequestStatus={
          this.props.requestStatus.CUSTOM_AUDIENCE_CREATE
        }
        browserRequestStatus={this.props.requestStatus.CUSTOM_AUDIENCE_ENTITIES}
        nameRequired
        onEntitiesRequest={this.props.apiCustomAudienceEntitiesRequest}
        onSubmit={this.props.apiCustomAudienceCreateRequest}
      />
    );
  }
}

const getState = (state, props) => ({
  entitiesByUuid: getEntitiesByUuid(state),
  requestStatus: getRequestStatus(state),
  open: getOpen(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      apiCustomAudienceCreateRequest: actions.apiCustomAudienceCreateRequest,
      apiCustomAudienceEntitiesRequest:
        actions.apiCustomAudienceEntitiesRequest,
      onClose: () => actions.setOpenCustomAudienceDialog(false),
    },
    dispatch,
  );

export default connect(getState, getActions)(ArcCustomAudienceContainer);
