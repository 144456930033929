import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions';

const handleActivityHistorySuccess = (state, action) => {
  const { data } = action.payload;

  return data;
};

const initialState = {
  activities: [],
  totalActivities: 0,
};

const handlers = {
  [types.ACTIVITY_HISTORY_INDEX.SUCCESS]: handleActivityHistorySuccess,
};

const activityHistory = createReducer(initialState, handlers);

export default activityHistory;

export const getActivityHistory = state =>
  state.newsfeed.activityHistory.activities;
export const getTotalActivities = state =>
  state.newsfeed.activityHistory.totalActivities;
