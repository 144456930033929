import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { back } from 'redux-first-router';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import Arc404 from 'arcade-frontend-ui/src/assets/Arc404';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import { ROUTE_NEWSFEED } from 'arcade-frontend-core/src/types/routes';
import { getLocation } from '../reducers/location';

function RouteNotFound() {
  const location = useSelector(getLocation);
  const dispatch = useDispatch();

  function handleNewsfeedClick() {
    dispatch({
      type: ROUTE_NEWSFEED,
    });
  }

  return (
    <ArcBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      border="1px solid #D8D8D8"
      borderRadius={8}
      m={[1, 3]}
      py={15}
      px={1}
      textAlign="center"
    >
      <Arc404 />
      <ArcBox
        component={ArcText}
        fontSize={36}
        lineHeight="150%"
        fontWeight="600"
        color="#252529"
        my={1}
      >
        {'Sorry, we missed.'}
      </ArcBox>

      <ArcBox
        component={ArcText}
        fontSize={16}
        lineHeight="150%"
        color="#616264"
        mb={4}
      >
        {'The link you clicked may be broken or the page may have been removed.'}
      </ArcBox>

      <ArcBox
        display="flex"
      >
        <ArcBox mr={1}>
          <ArcButton
            color="primary"
            label="Go to Newsfeed"
            onClick={handleNewsfeedClick}
          />
        </ArcBox>
        {!!location.prev && !!location.prev.type && (
          <ArcButton
            variant="contained"
            color="primary"
            label="Go Back"
            onClick={back}
          />
        )}
      </ArcBox>
    </ArcBox>
  );
}

RouteNotFound.displayName = 'RouteNotFound';

export default RouteNotFound;
