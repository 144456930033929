import React from 'react';

// @ts-ignore
import ArcTextV2 from 'arcade-frontend-ui/src/components/ArcTextV2';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';

import { NotificationsPanelNoResultProps } from './NotificationsPanelNoResult.d';

const defaultProps: NotificationsPanelNoResultProps = {
  title: 'Nothing to show',
};

const NotificationsPanelNoResult: React.FC<NotificationsPanelNoResultProps> = ({
  title,
  subtitle,
  icon,
  content,
}) => (
  <ArcViewBox
    alignItems="center"
    justifyContent="center"
    border="1px solid #D8D8D8"
    borderRadius={8}
    padding={3}
    minHeight={325}
  >
    {icon}
    <ArcViewBox mt={3} mb={2}>
      <ArcTextV2 fontSize={24} fontWeight="bold" lineHeight="30px">
        {title}
      </ArcTextV2>
    </ArcViewBox>

    {subtitle && (
      <ArcViewBox maxWidth={300}>
        <ArcTextV2
          fontSize={14}
          lineHeight="20px"
          textAlign="center"
          color="#616264"
        >
          {subtitle}
        </ArcTextV2>
      </ArcViewBox>
    )}
    {content}
  </ArcViewBox>
);

NotificationsPanelNoResult.displayName = 'NotificationsPanelNoResult';

NotificationsPanelNoResult.defaultProps = defaultProps;

export default NotificationsPanelNoResult;
