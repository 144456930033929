import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import ArcBox from '../ArcBox';

function ArcResourceSpinner({
  className,
  getStatus,
  size,
  status,
  ...props
}) {
  const getStatusSelector = getStatus || global.noop;
  const storeStatus = useSelector(getStatusSelector);
  const currentStatus = status || storeStatus;

  return (
    <ArcBox
      {...props}
      visibility={currentStatus.pending ? 'visible' : 'hidden'}
    >
      <CircularProgress
        size={size}
      />
    </ArcBox>
  );
}

ArcResourceSpinner.displayName = 'ArcResourceSpinner';

ArcResourceSpinner.propTypes = {
  className: PropTypes.string,
  getStatus: PropTypes.func,
  size: PropTypes.number,
  status: PropTypes.shape({
    idle: PropTypes.bool,
    pending: PropTypes.bool,
  }),
};

ArcResourceSpinner.defaultProps = {
  className: '',
  getStatus: global.noop,
  size: 16,
  status: undefined,
};

export default ArcResourceSpinner;
