import React from 'react';

import parseFilePath from 'parse-filepath';
import filesize from 'filesize';

import { iconForExtension } from 'arcade-frontend-core/src/helpers/files';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import ArcText from '../../primitives/ArcText';
import ArcImage from '../../primitives/ArcImage';
import createWithStyles from '../../styles/createWithStyles';
import { openFileForPlatform } from '../../helpers/utils/open_link';


const styles = {
  FileIcon: () => ({
    root: {
      width: 80,
      height: 80,
    },

    isResponsive: {
      width: 'auto',
      height: '100%',
    },
  }),
  FilePanel: theme => ({
    root: {
      color: theme.palette.grey.main,
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(1),
      '&:hover': {
        color: theme.palette.blue.main,
      },
    },
  }),
  FileDetails: theme => ({
    root: {
      marginLeft: theme.spacing(1),
    },
  }),
  FileName: theme => ({
    root: {
      marginBottom: theme.spacing(1),
      flexDirection: 'row',
      alignItems: 'baseline',
    },
  }),
  FileStats: () => ({
    root: {
      flexDirection: 'row',
    },
  }),
  Download: theme => ({
    root: {
      marginRight: theme.spacing(1),
    },
  }),
  FileSize: theme => ({
    root: {
      color: theme.palette.grey.main,
    },
  }),
  Prefix: theme => ({
    root: {
      fontSize: 20,
      color: theme.palette.grey[800],
    },
  }),
  Suffix: theme => ({
    root: {
      color: theme.palette.grey.light,
    },
  }),

};

const FilePanel = createWithStyles(styles.FilePanel)(ArcView);
const FileIcon = createWithStyles(styles.FileIcon)(ArcImage);
const FileDetails = createWithStyles(styles.FileDetails)(ArcView);
const FileName = createWithStyles(styles.FileName)(ArcView);
const Prefix = createWithStyles(styles.Prefix)(ArcView);
const Suffix = createWithStyles(styles.Suffix)(ArcView);
const FileStats = createWithStyles(styles.FileStats)(ArcView);
const Download = createWithStyles(styles.Download)(ArcText);
const FileSize = createWithStyles(styles.FileSize)(ArcText);


const ArcAttachedFile = ({ name, icon, isResponsive, size, link, iconOnly }) => {
  const filepath = parseFilePath(name);
  const iconUrl = icon || iconForExtension(filepath.ext.replace('.', ''));

  const renderedIcon = (
    <FileIcon
      src={iconUrl}
      isResponsive={isResponsive}
    />
  );

  if (iconOnly) {
    return renderedIcon;
  }

  return (
    <FilePanel onClick={() => link && openFileForPlatform(link)}>
      {renderedIcon}
      <FileDetails>
        <FileName>
          <Prefix>{filepath.name}</Prefix>
          <Suffix>{filepath.ext}</Suffix>
        </FileName>

        <FileStats>
          { link && <Download>Download file</Download>}
          <FileSize>{filesize(size)}</FileSize>
        </FileStats>
      </FileDetails>
    </FilePanel>
  );
};
ArcAttachedFile.propTypes = {
  link: ArcPropTypes.url,
  name: ArcPropTypes.string.isRequired,
  size: ArcPropTypes.number.isRequired,
  icon: ArcPropTypes.string,
  isResponsive: ArcPropTypes.bool,
  iconOnly: ArcPropTypes.bool,
};
ArcAttachedFile.defaultProps = {
  link: null,
  date: null,
  icon: null,
  isResponsive: false,
  iconOnly: false,
};

export default ArcAttachedFile;
