import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { NEEDS_VERIFICATION } from 'arcade-frontend-core/src/types/game-statuses';
import { getLocationQuery } from 'arcade-frontend-features/src/reducers/location';

import GamesGameCardList from '../../components/GamesGameCardList';
import { resources } from '../../resources/games';
import {
  getPendingGamesByInterest,
  getPendingGamesStatus,
} from '../../reducers/games';

function GamesPendingListContainer(props) {
  const apiGetPendingGamesIndex = useResourceAction(
    resources.apiGetPendingGamesIndex,
  );
  const locationQuery = useSelector(getLocationQuery);
  const pendingGames = useSelector(getPendingGamesByInterest);
  const pendingGamesStatus = useSelector(getPendingGamesStatus);

  React.useEffect(() => {
    apiGetPendingGamesIndex
      .requestData()
      .then(apiGetPendingGamesIndex.onStatusReset)
      .catch(global.noop);
  }, [locationQuery.form]);

  if (!apiGetPendingGamesIndex.hasData && !apiGetPendingGamesIndex.isPending) {
    return null;
  }

  return (
    <GamesGameCardList
      status={NEEDS_VERIFICATION}
      title="Pending Verification"
      games={pendingGames}
      hasData={apiGetPendingGamesIndex.hasData}
      isPending={pendingGamesStatus.pending}
      onVerify={props.onVerify}
      {...props}
    />
  );
}

GamesPendingListContainer.displayName = 'GamesPendingListContainer';

GamesPendingListContainer.propTypes = {
  onVerify: PropTypes.func,
};

GamesPendingListContainer.defaultProps = {
  onVerify: global.noop,
};

export default GamesPendingListContainer;
