import { createWithStyles } from '../../styles';
import ArcHeading from '../../typography/ArcHeading';

const ArcViewContentHeader = theme => ({
  root: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: '300',
    marginTop: 32,
    marginBottom: 8,
    marginLeft: 16,
    marginRight: 16,

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
});

export default createWithStyles(ArcViewContentHeader)(ArcHeading);
