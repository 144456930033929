import React from 'react';
import { angular2react } from 'angular2react';

const views = {};

const directiveStyle = {
  zIndex: 0,
  position: 'relative',
  height: '100%',
};

function getComponentFromDirective(componentName, hasWrapper = true) {
  function ArcadeAngularComponent(props) {
    const directive = window.ArcadeAngular[componentName];

    if (!views[componentName] && window.$injector && directive) {
      views[componentName] = angular2react(componentName, directive, window.$injector);
    }

    const Component = views[componentName];

    if (Component) {
      return hasWrapper
        ? (<div style={directiveStyle}><Component {...props} /></div>)
        : (<Component {...props} />);
    }

    const message = `getComponentFromDirective could not find a valid component for ${componentName}`;
    throw (message);
  }

  ArcadeAngularComponent.displayName = componentName;

  return ArcadeAngularComponent;
}

export default getComponentFromDirective;
