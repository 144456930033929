import React from 'react';

import PropTypes from 'prop-types';

import ArcGallery from '../ArcGallery';
import ArcRemoteImage from '../ArcRemoteImage';

class ArcImageGallery extends React.PureComponent {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      preview: PropTypes.string.isRequired,
      large: PropTypes.string,
    })),

    onRemove: PropTypes.func,
    renderAttachmentMessage: PropTypes.func,
  };

  static defaultProps = {
    images: [],

    onRemove: global.noop,
    renderAttachmentMessage: global.noop,
  };

  renderImage = item => item && (
    <ArcRemoteImage
      alt={item.name}
      src={item.preview}
      linkTo={item.large}
    />
  );

  renderThumbnail = (item, index, viewingIndex) => (
    <ArcRemoteImage
      alt={item.name}
      src={item.preview}
      onClick={viewingIndex}
    />
  );

  render() {
    return (
      <ArcGallery
        items={this.props.images}
        onRemove={this.props.onRemove}
        renderItem={this.renderImage}
        renderThumbnail={this.renderThumbnail}
        renderAttachmentMessage={this.props.renderAttachmentMessage}
      />
    );
  }
}

export default ArcImageGallery;
