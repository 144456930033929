import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

import { ArcIconButton } from 'arcade-frontend-ui';
import ArcClawbackTokensFormContainer from 'arcade-frontend-widgets/src/containers/ArcClawbackTokensFormContainer';

const STRINGS = {
  PEOPLE_MANAGE_MENU_CLAWBACK_TOKENS_LABEL: 'Clawback Tokens',
};

function PeopleManageMenu({ canClawbackTokens, person }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const [clawbackTokensOpen, setClawbackTokensOpen] = React.useState(false);
  const handleClawbackTokensOpen = () => setClawbackTokensOpen(true);
  const handleClawbackTokensCancel = () => setClawbackTokensOpen(false);

  return (
    <React.Fragment>
      <ArcIconButton
        aria-controls="people-manage-menu"
        aria-haspopup="true"
        onClick={handleClick}
        title="Manage"
      >
        <MoreHoriz color="disabled" fontSize="inherit" />
      </ArcIconButton>

      <Menu
        id="people-manage-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        {canClawbackTokens && (
          <MenuItem onClick={handleClawbackTokensOpen}>
            {STRINGS.PEOPLE_MANAGE_MENU_CLAWBACK_TOKENS_LABEL}
          </MenuItem>
        )}
      </Menu>

      <ArcClawbackTokensFormContainer
        open={clawbackTokensOpen}
        onCancel={handleClawbackTokensCancel}
        initialValues={{
          people: [person],
          tokens: person.userBalance,
        }}
      />
    </React.Fragment>
  );
}

PeopleManageMenu.displayName = 'PeopleManageMenu';

PeopleManageMenu.propTypes = {
  canClawbackTokens: PropTypes.bool,
  person: PropTypes.shape({
    userBalance: PropTypes.number,
  }),
};

PeopleManageMenu.defaultProps = {
  canClawbackTokens: false,
  person: {},
};

export default PeopleManageMenu;
