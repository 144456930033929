import React from 'react';
import { connect } from 'react-redux';
import { actions, Control } from 'react-redux-form';

import { makeValidators } from '../helpers/utils/validators';
import ArcPropTypes from '../helpers/arc/propTypes';
import { ArcPeopleList } from '../components/ArcPeopleList';
import ArcFormError from './ArcFormError';

class ArcFormPeopleList extends React.PureComponent {
  static propTypes = {
    model: ArcPropTypes.string.isRequired,
    label: ArcPropTypes.string,
    people: ArcPropTypes.people,
    teams: ArcPropTypes.teams,
    validations: ArcPropTypes.objectOf(ArcPropTypes.oneOfType([
      ArcPropTypes.bool,
      ArcPropTypes.number,
    ])),

    onChange: ArcPropTypes.func,
    onError: ArcPropTypes.func,

    selectedPeople: ArcPropTypes.ids,
    selectedTeams: ArcPropTypes.ids,
    style: ArcPropTypes.style,

    showSelectAll: ArcPropTypes.bool,
    type: ArcPropTypes.string,
  };

  static defaultProps = {
    validations: null,
    people: [],
    teams: [],
    label: null,
    onChange: ArcPropTypes.noop,
    onError: undefined,
    style: undefined,
    showSelectAll: undefined,
    selectedPeople: [],
    selectedTeams: [],
    type: 'people',
  };

  constructor(props) {
    super(props);

    const {
      label,
      validations,
      people,
      teams,
      showSelectAll,
      type,
      style,
    } = props;

    let errorText;
    this.validators = undefined;

    if (validations) {
      const {
        messages,
        validators,
      } = makeValidators(label, validations);

      errorText = ({ fieldValue, model }) => ArcFormError(fieldValue, model, messages);

      this.validators = validators;
    }

    const isTeams = type === 'teams';
    this.teamPeopleMap = {};

    if (isTeams) {
      teams.forEach((team) => {
        this.teamPeopleMap[team.id] = team.userIds;
      });
    }

    const onChange = ({ dispatch, model }) => (event, values, id, checked) => {
      if (isTeams) {
        const selectedTeams = [...values];

        props.onChange(event, selectedTeams, type, model, id, checked);
        dispatch(actions.change(model, selectedTeams));
      } else {
        props.onChange(event, values, type, model, id, checked);
        dispatch(actions.change(model, values));
      }
    };

    const selectedEntity = type === 'people' ? 'selectedPeople' : 'selectedTeams';

    this.mapProps = {
      onChange,
      people,
      teams,
      label,
      errorText,
      isSelectable: true,
      selectedPeople: () => props.selectedPeople,
      selectedTeams: () => props.selectedTeams,
      [selectedEntity]: ({ modelValue }) => modelValue,
      showSelectAll,
      type,
      style,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.type === 'teams' && nextProps.selectedPeople !== this.props.selectedPeople) {
      this.mapProps.selectedPeople = nextProps.selectedPeople;
    }
  }

  render() {
    return (
      <Control.custom
        persist
        model={this.props.model}
        component={ArcPeopleList}
        mapProps={this.mapProps}
        validators={this.validators}
        onError={this.props.onError}
      />
    );
  }
}


export default connect()(ArcFormPeopleList);
