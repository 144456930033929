import { createIsFetchingReducer } from 'arcade-frontend-ui';
import { types } from '../../../actions/manage/rewards';

const initialState = false;

const apiActionTypes = [
  types.MANAGE_REWARDS_CREATE,
  types.MANAGE_REWARDS_EDIT,
  types.MANAGE_REWARDS_INDEX,
  types.MANAGE_REWARDS_CUSTOM_INDEX,
  types.MANAGE_REWARDS_CUSTOM_INDEX,
];

const isFetching = createIsFetchingReducer(initialState, apiActionTypes);

export default isFetching;

export const getIsFetching = state => state.manage.rewards.isFetching;
