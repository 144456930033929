import React from 'react';

import {
  ArcPropTypes,
  ArcButton,
  ArcImage,
  ArcLink,
  ArcView,
  createWithStyles,
  theme as baseTheme,
  Typography,
} from 'arcade-frontend-ui';

import DisabledToken from './DisabledToken';
import rewardItemProps from './types';

const styles = {
  PlaceholderRow: theme => ({
    root: {
      backgroundColor: theme.palette.divider,
      height: 16,
      flexGrow: 100,
    },
  }),

  SpacingRow: () => ({
    root: {
      flexGrow: 100,
    },
  }),

  PlaceholderImage: theme => ({
    root: {
      backgroundColor: theme.palette.divider,
      width: 100,
      height: 100,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),

  RewardItemView: theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      height: '100%',
      boxShadow: theme.shadows[7],
    },
  }),

  Header: theme => ({
    root: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
  }),

  RewardItemTitle: () => ({
    root: {
      width: '100%',
      flexGrow: 2,
      flexShrink: 1,
    },

    isDisabled: {
      opacity: 0.5,
    },
  }),

  RewardItemDetails: theme => ({
    root: {
      flexGrow: 1,
      flexShrink: 1,
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  }),

  RewardItemName: () => ({
    root: {
      flexShrink: 1,
    },
  }),

  RewardDescription: theme => ({
    root: {
      flexGrow: 2,
      marginTop: theme.spacing(0.5),
      // height: theme.spacing(2),
    },
  }),

  PriceImage: theme => ({
    root: {
      height: 24,
      width: 24,
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.25),
    },
  }),

  PurchaseButtonView: theme => ({
    root: {
      padding: theme.spacing(1),
    },
  }),

  RewardItemSelect: theme => ({
    root: {
      margin: theme.spacing(1),
      flexGrow: 1,
    },
  }),
};

const PlaceholderRow = createWithStyles(styles.PlaceholderRow)(ArcView);
const SpacingRow = createWithStyles(styles.SpacingRow)(ArcView);
const PlaceholderImage = createWithStyles(styles.PlaceholderImage)(ArcView);
const RewardItemView = createWithStyles(styles.RewardItemView)(ArcView);
const RewardItemDetails = createWithStyles(styles.RewardItemDetails)(ArcView);
const RewardItemHeader = createWithStyles(styles.Header)(ArcView);
const RewardItemTitle = createWithStyles(styles.RewardItemTitle)(ArcView);
const RewardItemName = createWithStyles(styles.RewardItemName)(ArcView);
const PriceImage = createWithStyles(styles.PriceImage)(ArcImage);
const PurchaseButtonView = createWithStyles(styles.PurchaseButtonView)(ArcView);
const RewardItemSelect = createWithStyles(styles.RewardItemSelect)(ArcView);
const RewardDescription = createWithStyles(styles.RewardDescription)(ArcView);

const RewardItemImage = ({ src, isDisabled }) => (
  <ArcView
    style={{
      backgroundImage: `url(${src})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      width: 100,
      height: 100,
      margin: '0 10px 0 5px',
      opacity: isDisabled ? 0.5 : 1,
    }}
  />
);

RewardItemImage.propTypes = {
  src: ArcPropTypes.string.isRequired,
  isDisabled: ArcPropTypes.bool.isRequired,
};

class RewardItem extends React.PureComponent {
  static propTypes = {
    onClickPurchase: ArcPropTypes.func.isRequired,
    purchaseEvent: ArcPropTypes.emitter,
    userBalance: ArcPropTypes.number,
    ...rewardItemProps,
  };

  static defaultProps = {
    userBalance: 0,
    rewardTokenCost: 0,
    denominations: [],
    variants: [],
    purchaseEvent: ArcPropTypes.nullEmitter,
  };

  static displayName = 'RewardItem';

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      selectedDenomination: {},
      isShowingMore: false,
    };
  }

  componentDidMount() {
    this.props.purchaseEvent.on('purchaseComplete', this.onPurchaseComplete);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userBalance !== this.props.userBalance) {
      this.setState(this.getInitialState());
    }
  }

  componentWillUnmount() {
    this.props.purchaseEvent.off('purchaseComplete', this.onPurchaseComplete);
  }

  onPurchaseComplete = ({ data }) => {
    if (data.rewardItemID === this.props.id) {
      this.setState(this.getInitialState());
    }
  };

  get canShowMore() {
    return this.props.description && this.props.description.length > 120;
  }

  get truncatedDescription() {
    return this.state.isShowingMore
      ? this.props.description
      : this.props.description.slice(0, 120);
  }

  toggleShowMore = () =>
    this.setState({ isShowingMore: !this.state.isShowingMore });

  handleClickPurchase = () => {
    if (this.rewardItemSelected) {
      this.props.onClickPurchase(
        this.props.id,
        this.props.name,
        this.state.selectedDenomination,
      );
    }
  };

  rewardItemControls() {
    if (process.env.NODE_ENV !== 'production') {
      console.error('rewardItemControls is not implemented in', this.name); // eslint-disable-line no-console
    }
  }

  renderHiderLabel() {
    return (
      <ArcLink size="inherit" onClick={this.toggleShowMore}>
        {this.state.isShowingMore ? 'See less' : 'See more'}
      </ArcLink>
    );
  }

  render() {
    return (
      <RewardItemView>
        <ArcView>
          <RewardItemHeader align="flex-start">
            <RewardItemTitle isDisabled={this.rewardItemDisabled}>
              {this.props.name ? (
                <RewardItemName>{this.props.name}</RewardItemName>
              ) : (
                <PlaceholderRow />
              )}
            </RewardItemTitle>
            <RewardDescription>
              <Typography variant="caption">
                {this.canShowMore
                  ? this.truncatedDescription
                  : this.props.description}
                {this.canShowMore && this.renderHiderLabel()}
              </Typography>
            </RewardDescription>
          </RewardItemHeader>
          <RewardItemDetails row align={'center'}>
            {this.props.imageUrl ? (
              <RewardItemImage
                src={this.props.imageUrl}
                isDisabled={this.rewardItemDisabled}
              />
            ) : (
              <PlaceholderImage />
            )}
            <RewardItemSelect>{this.rewardItemControls()}</RewardItemSelect>
          </RewardItemDetails>
        </ArcView>
        <SpacingRow />
        <PurchaseButtonView>
          {this.rewardItemSelected ? (
            <ArcButton
              variant="contained"
              color="secondary"
              onClick={this.handleClickPurchase}
            >
              Purchase for
              <PriceImage src={baseTheme.images.coin} />
              {this.finalPurchaseTokenValue}
            </ArcButton>
          ) : (
            <ArcButton variant="contained" color="secondary" disabled>
              From
              <DisabledToken
                width="24px"
                height="24px"
                style={{ margin: '0 0 0 4px' }}
              />
              {this.tokenCost}
            </ArcButton>
          )}
        </PurchaseButtonView>
      </RewardItemView>
    );
  }
}

export default RewardItem;
