import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Close from '@material-ui/icons/Close';

import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';
import {
  getLocationPayload,
  getLocationQuery,
} from 'arcade-frontend-features/src/reducers/location';
import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcResponsiveDialog from 'arcade-frontend-ui/src/components/ArcResponsiveDialog';
import ArcIconButton from 'arcade-frontend-ui/src/elements/ArcIconButton';

import { resources } from '../../resources/games';
import { getGames } from '../../reducers/games';
import GamesGameCard from '../../components/GamesGameCard';

const PAPER_PROPS = {
  style: {
    width: '100%',
    alignSelf: 'center',
    height: 608,
    maxWidth: 600,
  },
};

function GamesShowContainer(props) {
  const dispatch = useDispatch();

  let locationPayload = useSelector(getLocationPayload);
  if (props.locationPayload) {
    ({ locationPayload } = props);
  }

  let locationQuery = useSelector(getLocationQuery);
  if (props.locationQuery) {
    ({ locationQuery } = props);
  }

  const apiGetGamesShow = useResourceAction(resources.apiGetGamesShow, {
    getInitialData: getGames(locationPayload.id),
  });

  function requestData() {
    apiGetGamesShow.requestData({
      resources: [locationPayload.id],
    });
  }

  React.useEffect(() => {
    if (!locationPayload.id) {
      return;
    }

    requestData();
  }, [locationPayload.id, locationQuery.form]);

  function handleClose() {
    const routeAction = {
      type: ROUTE_TYPES.ROUTE_GAMES,
      payload: {},
      query: {
        ...locationQuery,
        tab: undefined,
      },
    };

    dispatch(routeAction);
  }

  function handleTabChange(tab) {
    const nextTab = tab || undefined;

    const routeAction = {
      type: ROUTE_TYPES.ROUTE_GAMES_SHOW,
      payload: locationPayload,
      query: {
        ...locationQuery,
        tab: nextTab,
      },
    };

    dispatch(routeAction);
  }

  const game = apiGetGamesShow.data.resources[0] || {};

  const GameCardOrPlaceholder =
    apiGetGamesShow.hasData && game.id === locationPayload.id
      ? GamesGameCard
      : GamesGameCard.Placeholder;

  return (
    <ArcResponsiveDialog
      open={!!locationPayload.id}
      onClose={handleClose}
      PaperProps={PAPER_PROPS}
      maxWidth={600}
    >
      {!locationQuery.tab && (
        <DialogTitle>
          <ArcBox display="flex" flexDirection="row" alignItems="center" pb={1}>
            <ArcBox fontSize={28} lineHeight={1.5} fontWeight="600">
              {'Overview'}
            </ArcBox>
            <ArcBox flex={100} />

            <ArcIconButton aria-label="Close" onClick={handleClose}>
              <Close />
            </ArcIconButton>
          </ArcBox>
        </DialogTitle>
      )}
      <DialogContent style={{ padding: 0, height: '100%' }}>
        <GameCardOrPlaceholder
          variant="large"
          id={game.id}
          allowManualAdd={game.rules && game.rules.allowManualAdd}
          activeTab={locationQuery.tab}
          onClose={handleClose}
          onTabChange={handleTabChange}
          campaignImage={game.campaignImage}
          expiresAt={game.expiresAt}
          startsAt={game.startsAt}
          status={game.status}
          name={game.name}
          score={game.currentUserScore}
          finalScore={game.finalScore}
          type={game.type}
          participantCount={game.participantCount}
          rankings={game.rankings}
          rewards={game.rewards}
          rules={game.rules}
        />
      </DialogContent>
    </ArcResponsiveDialog>
  );
}

GamesShowContainer.propTypes = {
  locationPayload: PropTypes.shape({
    id: PropTypes.string,
  }),
  locationQuery: PropTypes.shape({
    tab: PropTypes.string,
    form: PropTypes.string,
  }),
};

GamesShowContainer.defaultProps = {
  locationPayload: undefined,
  locationQuery: undefined,
};

export default GamesShowContainer;
