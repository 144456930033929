import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/games';

export const sorted = (metrics, prop) => {
  const metricIds = Object.keys(metrics);

  return metricIds.sort((a, b) => {
    if (metrics[a][prop] < metrics[b][prop]) {
      return -1;
    }
    if (metrics[a][prop] > metrics[b][prop]) {
      return 1;
    }

    return 0;
  });
};

const handleManageGamesMetricsLoadSuccess = (state, action) => {
  const { data } = action.payload;

  const newState = { ...state };

  data.forEach(metric => {
    newState[metric.id] = metric;
  });

  return newState;
};

const initialState = {};

const handlers = {
  [types.MANAGE_GAMES_VALID_METRICS_INDEX.SUCCESS]:
    handleManageGamesMetricsLoadSuccess,
};

const metricsById = createReducer(initialState, handlers);

export default metricsById;

export const getManageGamesMetricsById = state =>
  state.manage.games.metricsById;
