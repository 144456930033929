import { combineReducers } from 'redux';

import isFetching from './isFetching';
import isRequesting from './isRequesting';
import byId from './byId';
import arcade from './arcade';
import custom from './custom';

const rewards = combineReducers({
  isFetching,
  isRequesting,
  byId,
  arcade,
  custom,
});

export default rewards;
