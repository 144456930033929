import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Lazy } from 'react-lazy';

import track from 'arcade-frontend-core/src/helpers/track';
import ArcLinkPreviewList from 'arcade-frontend-ui/src/components/ArcLinkPreview';
import BlankProfileIcon from 'arcade-frontend-ui/src/icons/BlankProfileIcon';
import {
  ArcAttachedFile,
  ArcEasyMenu,
  ArcMarkdownContent,
  ArcText,
  ArcView,
  ArcAttachedFileList,
  ArcImageGallery,
  createWithStyles,
} from 'arcade-frontend-ui';
import ArcVideo from 'arcade-frontend-widgets/src/components/ArcVideo';

import { attachedFileRecord } from '../../propTypes';
import Portrait from '../Portrait';
import AcknowledgePin from '../AcknowledgePin';
import NewsfeedActivityCommentsContainer from '../../containers/NewsfeedActivityCommentsContainer';
import { customActivities, getActivityComponent } from '../activities';
import NewsfeedCommentForm from '../NewsfeedCommentForm';
import NewsfeedActivityReactions from '../NewsfeedActivityReactions';
import NewsfeedPostedToBadge from '../NewsfeedPostedToBadge';

const styles = {
  CommentsWrapper: theme => ({
    root: {
      borderTopWidth: 4,
      borderTopColor: theme.palette.grey[200],
      borderTopStyle: 'solid',
      backgroundColor: theme.palette.grey[100],
      borderRadius: '0 0 4px 4px',
    },
  }),
  MarkdownContent: () => ({
    root: {
      maxHeight: 200,
      overflow: 'hidden',
    },
    showMore: {
      maxHeight: 'none',
      overflow: 'auto',
    },
  }),
  ContentHider: theme => ({
    root: {
      position: 'absolute',
      width: '100%',
      bottom: 0,
      background:
        'linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%)',
      height: theme.spacing(8),
    },
    showMore: {
      background: 'none',
      height: theme.spacing(4),
      position: 'relative',
    },
  }),
  HiderLabel: theme => ({
    root: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      position: 'absolute',
      bottom: 0,
      fontSize: '14px',
    },
  }),
};

const CommentsWrapper = createWithStyles(styles.CommentsWrapper)(ArcView);
const MarkdownContent = createWithStyles(styles.MarkdownContent)(ArcView);
const ContentHider = createWithStyles(styles.ContentHider)(ArcView);
const HiderLabel = createWithStyles(styles.HiderLabel)(ArcView);

const AttachedFile = ({ file }) => {
  const { link, name, size, icon } = file;
  return <ArcAttachedFile link={link} name={name} size={size} icon={icon} />;
};
AttachedFile.propTypes = attachedFileRecord.isRequired;

const MAX_WIDTH = 584;

const placeholderStyle = {
  width: 'calc(100% - 32px)',
  maxWidth: MAX_WIDTH,
};

const PLACEHOLDER = (
  <ArcView
    elevation="1"
    color="paper"
    margin="16"
    padding="8"
    rounded
    flex="1"
    style={placeholderStyle}
  >
    <ArcView row marginBottom="8">
      <ArcView padding="32" marginRight="8" className="shimmer" />
      <ArcView flexGrow="100">
        <ArcView padding="24" className="shimmer" marginBottom="8" />
        <ArcView padding="8" className="shimmer" />
      </ArcView>
    </ArcView>
    <ArcView padding="48" className="shimmer" marginBottom="8" />
    <ArcView padding="16" className="shimmer" />
  </ArcView>
);

const ACKNOWLEDGED_TYPES = {
  NOT_PINNED: 'not_pinned',
  ACKING: 'acking',
  PINNED: 'pinned',
};

const ACTIVITY_TYPES = {
  USER_ACTIVITY: 'UserActivity',
  ANNOUNCEMENT_ACTIVITY: 'AnnouncementActivity',
  BADGE_ACTIVITY: 'BadgeActivity',
  COMPETITION_ACTIVITY: 'CompetitionActivity',
  CHEST_ACTIVITY: 'ChestActivity',
  GAMEANNOUNCEMENT_ACTIVITY: 'GameAnnouncementActivity',
  GAMELEADERBOARD_ACTIVITY: 'GameLeaderboardActivity',
  HUDDLE_ACTIVITY: 'HuddleActivity',
  LEADERBOARD_ACTIVITY: 'LeaderboardActivity',
  PERFORMANCE_ACTIVITY: 'PerformanceActivity',
  QUEST_ACTIVITY: 'QuestActivity',
  QUIZ_ACTIVITY: 'QuizActivity',
  RECOGNITION_ACTIVITY: 'RecognitionActivity',
  REWARD_ACTIVITY: 'RewardActivity',
  SALE_ACTIVITY: 'SaleActivity',
  WEEKLY_SUPERSTAR_ACTIVITY: 'WeeklySuperstarActivity',
};

const DEFAULT_DATE_STRING = '[No date]';
const DEFAULT_CONTENT_STRING = '[No content]';
const DEFAULT_USER_NAME = '[No name]';

class NewsfeedActivityCard extends React.PureComponent {
  static propTypes = {
    audienceId: PropTypes.string,
    audienceName: PropTypes.string,
    activityHistoryView: PropTypes.bool,
    acknowledged: PropTypes.oneOf(Object.values(ACKNOWLEDGED_TYPES)),
    acknowledgePin: PropTypes.func,
    canDeletePosts: PropTypes.bool,
    canEdit: PropTypes.bool,
    canPin: PropTypes.bool,
    canPromote: PropTypes.bool,
    createdAt: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.instanceOf(moment),
    ]),
    currentUserCreated: PropTypes.bool,
    currentUserId: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.shape({})),
    commentCount: PropTypes.number,
    content: PropTypes.string,
    edited: PropTypes.bool,
    file: PropTypes.shape({}),
    files: PropTypes.arrayOf(PropTypes.shape({})),
    hasBehaviourDelete: PropTypes.bool,
    hasBehaviourReport: PropTypes.bool,
    hasBehaviourShowAllReactions: PropTypes.bool,
    hasFeatureNewsfeedFiltering: PropTypes.bool,
    hideReactions: PropTypes.bool,
    id: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    isSavingComment: PropTypes.bool,
    onActivityAcksRequest: PropTypes.func,
    onActivityViewsRequest: PropTypes.func,
    onCommentDelete: PropTypes.func,
    onCommentReactionDialogOpen: PropTypes.func,
    onCommentReactionSelect: PropTypes.func,
    onCommentSave: PropTypes.func,
    onEdit: PropTypes.func,
    onDialogOpen: PropTypes.func,
    onReactionSelect: PropTypes.func,
    onRespect: PropTypes.func,
    onQuicklinkUser: PropTypes.func,
    participants: PropTypes.arrayOf(PropTypes.string),
    pinnedByCurrentUser: PropTypes.bool,
    pinnedByName: PropTypes.string,
    reactions: PropTypes.arrayOf(PropTypes.object),
    reactionsIsRequesting: PropTypes.bool,
    requestStatus: PropTypes.shape({
      NEWSFEED_DELETE_COMMENT: PropTypes.string,
      NEWSFEED_GET_ACTIVITY_COMMENTS: PropTypes.string,
    }),
    showRespects: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(ACTIVITY_TYPES)),
    userBadge: PropTypes.string,
    userId: PropTypes.string,
    userImage: PropTypes.string,
    userName: PropTypes.string,
    video: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
  };

  static defaultProps = {
    audienceId: '',
    audienceName: 'No audience',
    activityHistoryView: false,
    acknowledged: ACKNOWLEDGED_TYPES.NOT_PINNED,
    acknowledgePin: global.noop,
    canDeletePosts: false,
    canEdit: false,
    canPin: false,
    canPromote: false,
    createdAt: null,
    currentUserCreated: false,
    currentUserId: '',
    comments: [],
    commentCount: 0,
    content: '',
    edited: false,
    file: null,
    files: null,
    hasBehaviourDelete: false,
    hasBehaviourReport: false,
    hasBehaviourShowAllReactions: false,
    hasFeatureNewsfeedFiltering: false,
    hideReactions: false,
    id: null,
    images: null,
    isLoading: false,
    isSavingComment: false,
    onActivityAcksRequest: global.noop,
    onActivityViewsRequest: global.noop,
    onCommentDelete: global.noop,
    onCommentReactionDialogOpen: global.noop,
    onCommentReactionSelect: global.noop,
    onCommentSave: global.noop,
    onEdit: global.noop,
    onDialogOpen: global.noop,
    onReactionSelect: global.noop,
    onRespect: global.noop,
    onQuicklinkUser: global.noop,
    participants: [],
    pinnedByCurrentUser: false,
    pinnedByName: '',
    reactions: [],
    reactionsIsRequesting: false,
    requestStatus: {},
    showRespects: true,
    type: null,
    userBadge: '',
    userId: '',
    userImage: '',
    userName: '',
    video: null,
  };

  static ACKNOWLEDGED_TYPES = ACKNOWLEDGED_TYPES;

  static ACTIVITY_TYPES = ACTIVITY_TYPES;

  static PLACEHOLDER = PLACEHOLDER;

  static displayName = 'NewsfeedActivityCard';

  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
  }

  state = {
    isTall: false,
    isShowingMore: false,
  };

  componentDidMount() {
    if (this.contentRef.current) {
      const { offsetHeight } = this.contentRef.current;
      this.setState({
        isTall: offsetHeight >= 200,
      }); /* eslint-disable-line react/no-did-mount-set-state */
    }
  }

  get createdAtFormatted() {
    const { createdAt } = this.props;

    let createdAtFormatted = DEFAULT_DATE_STRING;

    if (createdAt) {
      const momentCreatedAt = moment(createdAt);

      const oneWeekAgo = moment().subtract(7, 'd');
      const dateFormat = momentCreatedAt.isBefore(oneWeekAgo)
        ? 'MMMM D, h:mma'
        : 'dddd, h:mma';

      createdAtFormatted = momentCreatedAt.format(dateFormat);
    }

    return createdAtFormatted + (this.props.edited ? ' - edited' : '');
  }

  get menuOptions() {
    const menuOptions = [];

    const {
      currentUserCreated,
      hasBehaviourDelete,
      hasBehaviourReport,
      canDeletePosts,
      canEdit,
      canPin,
      canPromote,
      pinnedByCurrentUser,
    } = this.props;

    if (this.props.onActivityViewsRequest !== global.noop) {
      menuOptions.push({
        children: "Who's Viewed",
        onClick: this.handleViewsRequest,
      });
    }

    if (hasBehaviourDelete && (canDeletePosts || currentUserCreated)) {
      menuOptions.push({
        children: 'Remove Post',
        onClick: this.handleRemoveRequest,
      });
    }

    if (currentUserCreated) {
      if (this.props.onEdit !== global.noop && canEdit) {
        menuOptions.unshift({
          children: 'Edit Post',
          onClick: this.handleEdit,
        });
      }
    } else if (hasBehaviourReport) {
      menuOptions.push({
        children: 'Report Post',
        onClick: this.handleReportRequest,
      });
    }

    if (canPin) {
      if (pinnedByCurrentUser) {
        menuOptions.push({ children: 'Unpin Post', onClick: this.handleUnpin });
        menuOptions.push({
          children: "Who's Acknowledged",
          onClick: this.handleAcksRequest,
        });
      } else {
        menuOptions.push({ children: 'Pin Post', onClick: this.handlePin });
      }
    }

    if (canPromote) {
      // menuOptions.push({
      //   children: 'Share Post',
      //   onClick: this.handlePromoteRequest,
      // });
    }

    return menuOptions;
  }

  get filesToDisplay() {
    const { file, files } = this.props;

    if (files && files.length > 0) return files;
    if (file) return [file];
    return null;
  }

  get imagesToDisplay() {
    const { images } = this.props;
    if (images) return images;
    return null;
  }

  toggleShowMore = () => {
    const state = this.state.isShowingMore ? 'closed' : 'opened';
    track(`show more ${state}`, {
      payload: { event: 'newsfeed:showmore', properties: { state } },
    });
    this.setState({ isShowingMore: !this.state.isShowingMore });
  };

  openDialog = currentDialog => {
    this.props.onDialogOpen(this.props.id, currentDialog);
  };

  handleAcknowledge = () => {
    track('Pin acknowledged', {
      payload: { event: 'newsfeed:acknowledge-pin' },
    });
    this.props.acknowledgePin({ id: this.props.id });
  };

  handleAcksRequest = () => {
    track('Opened acknowledgement dialog', {
      payload: { event: 'newsfeed:acknowledgement-dialog' },
    });
    this.props.onActivityAcksRequest({ id: this.props.id });
    this.openDialog('who-acked');
  };

  handleCommentReactionDialogOpen = commentId => {
    track('Opened comment reaction dialog', {
      payload: { event: 'newsfeed:comment-reaction-dialog' },
    });
    this.props.onCommentReactionDialogOpen(commentId);
  };

  handleReactionSelect = (id, reaction) => {
    track('Chose activity reaction', {
      payload: {
        event: 'newsfeed:chose-activity-reaction',
        properties: { reaction },
      },
    });
    this.props.onReactionSelect(id, reaction);
  };

  handleCommentReactionSelect = (id, reaction) => {
    track('Chose comment reaction', {
      payload: {
        event: 'newsfeed:chose-comment-reaction',
        properties: { reaction },
      },
    });
    this.props.onCommentReactionSelect(id, reaction, this.props.id);
  };

  handleEdit = () => {
    track('edit activity', { payload: { event: 'newsfeed:edit-activity' } });
    this.props.onEdit({ id: this.props.id });
  };

  handleRemoveRequest = () => {
    track('open delete activity dialog', {
      payload: { event: 'newsfeed:delete-activity-dialog' },
    });
    this.openDialog('delete-post');
  };

  handleReportRequest = () => {
    track('open report activity dialog', {
      payload: { event: 'newsfeed:report-activity-dialog' },
    });
    this.openDialog('report-post');
  };

  handlePromoteRequest = () => {
    track('open promote activity dialog', {
      payload: { event: 'newsfeed:promote-activity-dialog' },
    });
    this.openDialog('promote-post');
  };

  handleReactionShowMore = () => {
    track('show activity reactions dialog', {
      payload: { event: 'newsfeed:activity-reactions-dialog' },
    });
    this.openDialog('reactions');
  };

  handleUnpin = () => {
    track('show activity unpin dialog', {
      payload: { event: 'newsfeed:activity-unpin-dialog' },
    });
    this.openDialog('unpin-post');
  };

  handlePin = () => {
    track('show activity pin dialog', {
      payload: { event: 'newsfeed:activity-pin-dialog' },
    });
    this.openDialog('pin-post');
  };

  handleViewsRequest = () => {
    track('show activity views dialog', {
      payload: { event: 'newsfeed:activity-view-dialog' },
    });
    this.props.onActivityViewsRequest({ id: this.props.id });
    this.openDialog('who-viewed');
  };

  handleRespect = () => {
    track('click activity respect', {
      payload: { event: 'newsfeed:activity-respected' },
    });
    this.props.onRespect({ id: this.props.id });
  };

  handleClickAuthor = () => {
    track('activity author clicked', {
      payload: { event: 'newsfeed:activity-author-clicked' },
    });
    if (this.props.type !== ACTIVITY_TYPES.QUEST_ACTIVITY) {
      this.props.onQuicklinkUser(this.props.userId, 'person');
    }
  };

  handleRecipientClick = () => {
    track('activity reward recipients clicked', {
      payload: { event: 'newsfeed:activity-reward-recipients-clicked' },
    });
    this.openDialog('recipients');
  };

  renderComments() {
    if (this.props.activityHistoryView) {
      return null;
    }

    const isLazy = false;

    const comments = (
      <NewsfeedActivityCommentsContainer
        activityId={this.props.id}
        currentUserId={this.props.currentUserId}
        comments={this.props.comments}
        commentCount={this.props.commentCount}
        onDelete={this.props.onCommentDelete}
        onReactionDialogOpen={this.handleCommentReactionDialogOpen}
        onReactionSelect={this.handleCommentReactionSelect}
        onQuicklinkUser={this.props.onQuicklinkUser}
      />
    );

    return (
      <CommentsWrapper>
        {isLazy ? <Lazy clientOnly>{comments}</Lazy> : comments}

        {this.props.onCommentSave !== global.noop && (
          <NewsfeedCommentForm
            activityId={this.props.id}
            onSave={this.props.onCommentSave}
            participants={this.props.participants}
            isSaving={this.props.isSavingComment}
          />
        )}
      </CommentsWrapper>
    );
  }

  renderContent() {
    const { video, onQuicklinkUser } = this.props;

    let mainContent = null;

    if (customActivities.includes(this.props.type)) {
      mainContent = getActivityComponent(this.props, {
        onQuicklinkUser,
        onRecipientsClick: this.handleRecipientClick,
      });
    } else {
      const isEmpty =
        !this.props.content &&
        !this.filesToDisplay &&
        !this.imagesToDisplay &&
        !video;
      const textContent = isEmpty ? (
        DEFAULT_CONTENT_STRING
      ) : (
        <ArcView>
          <MarkdownContent
            showMore={this.state.isShowingMore}
            internalRef={this.contentRef}
          >
            <ArcMarkdownContent content={this.props.content} />
          </MarkdownContent>

          {this.state.isTall && (
            <ContentHider showMore={this.state.isShowingMore}>
              <HiderLabel onClick={this.toggleShowMore}>
                {this.state.isShowingMore ? 'See less' : 'See more'}
              </HiderLabel>
            </ContentHider>
          )}
        </ArcView>
      );

      mainContent = (
        <ArcView
          position="relative"
          marginLeft="4"
          marginBottom="4"
          data-testid="NewsfeedActivityCard-MainContent"
        >
          {textContent}
        </ArcView>
      );
    }

    return (
      <>
        <ArcView row padding="8">
          <ArcView
            align="center"
            row
            onClick={this.handleClickAuthor}
            cursor={
              this.props.type === ACTIVITY_TYPES.QUEST_ACTIVITY
                ? 'none'
                : 'pointer'
            }
          >
            {this.props.userImage ? (
              <Portrait
                url={this.props.userImage}
                badge={this.props.userBadge}
              />
            ) : (
              <ArcView padding="16" border="default" borderWidth="1">
                <BlankProfileIcon color="disabled" />
              </ArcView>
            )}

            <ArcView marginLeft="16">
              <ArcView marginBottom="8">
                <ArcText size="18px" color="grey" lineHeight="1em">
                  {this.props.userName || DEFAULT_USER_NAME}
                </ArcText>
              </ArcView>
              <ArcText size="14px" color="grey500" lineHeight="1em">
                {this.createdAtFormatted}
              </ArcText>
            </ArcView>
          </ArcView>

          <ArcView spacer />

          {!this.props.activityHistoryView && this.menuOptions.length > 0 && (
            <ArcEasyMenu options={this.menuOptions} />
          )}
        </ArcView>

        <ArcView component="figcaption" overflow="hidden">
          <ArcView padding="8">{mainContent}</ArcView>
        </ArcView>

        {(!!this.filesToDisplay || !!this.imagesToDisplay || !!video) && (
          <ArcView marginTop="24" marginBottom="24">
            {!!video && <ArcVideo sourcesByEncoding={video} />}
            {!!this.imagesToDisplay && (
              <ArcImageGallery images={this.imagesToDisplay} />
            )}
            {!!this.filesToDisplay && (
              <ArcAttachedFileList files={this.filesToDisplay} />
            )}
          </ArcView>
        )}
      </>
    );
  }

  renderRespects() {
    if (this.props.hideReactions) {
      return null;
    }

    return (
      <ArcView padding="8">
        <NewsfeedActivityReactions
          activityId={this.props.id}
          currentUserId={this.props.currentUserId}
          onSelect={this.handleReactionSelect}
          onShowMore={
            this.props.hasBehaviourShowAllReactions
              ? this.handleReactionShowMore
              : undefined
          }
          reactions={this.props.reactions}
          isRequesting={this.props.reactionsIsRequesting}
        />
      </ArcView>
    );
  }

  render() {
    if (this.props.isLoading) {
      return PLACEHOLDER;
    }

    return (
      <ArcView
        component="figure"
        rounded
        elevation="1"
        color="paper"
        marginBottom="32"
        marginLeft="16"
        marginRight="16"
      >
        {!this.props.activityHistoryView && (
          <AcknowledgePin
            acknowledged={this.props.acknowledged}
            onAcknowledge={this.handleAcknowledge}
            pinnedByCurrentUser={this.props.pinnedByCurrentUser}
            pinnedByName={this.props.pinnedByName}
            openAcks={this.handleAcksRequest}
          />
        )}

        {this.renderContent()}

        <ArcView marginLeft="4">
          <ArcLinkPreviewList elevation={0} text={this.props.content} />
        </ArcView>

        {this.props.hasFeatureNewsfeedFiltering && !!this.props.audienceId && (
          <ArcView padding="8">
            <NewsfeedPostedToBadge
              id={this.props.audienceId}
              name={this.props.audienceName}
            />
          </ArcView>
        )}

        {this.props.showRespects && this.renderRespects()}

        {this.renderComments()}
      </ArcView>
    );
  }
}

export default NewsfeedActivityCard;
