import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/games';

const handleManageGamesEligiblePeopleLoadSuccess = (state, action) => {
  const { data } = action.payload;

  return data.people;
};

const initialState = [];

const handlers = {
  [types.MANAGE_GAMES_ELIGIBLE_PEOPLE_INDEX.SUCCESS]:
    handleManageGamesEligiblePeopleLoadSuccess,
};

const eligiblePeople = createReducer(initialState, handlers);

export default eligiblePeople;

export const getManageGamesEligiblePeople = state =>
  state.manage.games.eligiblePeople;
