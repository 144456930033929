import { createReducer } from 'arcade-frontend-ui';
import { getStateById } from 'arcade-frontend-ui/src/helpers/utils/reducer';
import { createSelector } from 'reselect';

import { types } from '../actions/uploadManager';

const initialState = {
  byId: {},
  mine: [],
  shared: [],
  loading: false,
  hasMore: false,
  sharedHasMore: false,
};

const handleFileLoadStart = state => ({ ...state, loading: true });

const handleFilesSuccess = (state, action) => ({
  ...state,
  loading: false,
  hasMore: action.payload.raw.data.has_more_resources,
  mine: action.payload.data.map(file => file.id),
  byId: {
    ...state.byId,
    ...getStateById(action.payload.data),
  },
});

const handleSharedFilesSuccess = (state, action) => ({
  ...state,
  loading: false,
  sharedHasMore: action.payload.raw.data.has_more_resources,
  shared: action.payload.data.map(file => file.id),
  byId: {
    ...state.byId,
    ...getStateById(action.payload.data),
  },
});

const handleFileLoadDelete = (state, action) => {
  const { id } = action.payload;

  if (state.byId[id]) {
    const nextState = {
      ...state,
      byId: {
        ...state.byId,
      },
      shared: state.shared.filter(sharedId => sharedId !== id),
      mine: state.mine.filter(mineId => mineId !== id),
    };

    delete nextState.byId[id];

    return nextState;
  }

  return state;
};

const handlers = {
  [types.UPLOAD_MANAGER_GET_MY_FILES.REQUEST]: handleFileLoadStart,
  [types.UPLOAD_MANAGER_GET_MY_FILES.SUCCESS]: handleFilesSuccess,
  [types.UPLOAD_MANAGER_GET_SHARED_FILES.REQUEST]: handleFileLoadStart,
  [types.UPLOAD_MANAGER_GET_SHARED_FILES.SUCCESS]: handleSharedFilesSuccess,
  [types.UPLOAD_MANAGER_DELETE_FILE.REQUEST]: handleFileLoadDelete,
};

export default createReducer(initialState, handlers);

export const getFilesById = state => state.files.byId;

export const getFilesMine = createSelector(
  getFilesById,
  state => state.files.mine,
  (byId, mine) => mine?.map(id => byId[id]),
);

export const getFilesShared = createSelector(
  getFilesById,
  state => state.files.shared,
  (byId, shared) => shared?.map(id => byId[id]),
);

export const getFilesIsLoading = state => state.files.loading;
export const getFilesHasMore = state => state.files.hasMore;
export const getSharedFilesHasMore = state => state.files.sharedHasMore;
