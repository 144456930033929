import React from 'react';
import PropTypes from 'prop-types';

import ArcConfirmDialog from '../ArcConfirmDialog';

class ArcDeleteDialog extends React.PureComponent {
  static propTypes = {
    onDelete: PropTypes.func,
    title: PropTypes.string,
  };

  static defaultProps = {
    onDelete: global.noop,
    title: 'Confirm Delete',
  };

  render() {
    const {
      onDelete,
      ...rest
    } = this.props;

    return (
      <ArcConfirmDialog
        onConfirm={onDelete}
        confirmButtonColor="danger"
        confirmLabel="Delete"
        {...rest}
      />
    );
  }
}

export default ArcDeleteDialog;
