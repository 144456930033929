import React from 'react';

import {
  ArcPropTypes,
  ArcView,
} from 'arcade-frontend-ui';

import MetricBenchmarkProgress from './MetricBenchmarkProgress';
import MetricBenchmarkInformation from './MetricBenchmarkInformation';

const MetricBenchmark = ({ percentOfBenchmark, benchmarkValue, progress, symbol, name, verb }) => (
  <ArcView>
    <MetricBenchmarkProgress
      percentOfBenchmark={percentOfBenchmark}
    />
    <MetricBenchmarkInformation
      benchmarkValue={benchmarkValue}
      progress={progress}
      symbol={symbol}
      name={name}
      verb={verb}
    />
  </ArcView>
);

MetricBenchmark.propTypes = {
  benchmarkValue: ArcPropTypes.number,
  percentOfBenchmark: ArcPropTypes.number,
  progress: ArcPropTypes.number,
  symbol: ArcPropTypes.string,
  name: ArcPropTypes.string,
  verb: ArcPropTypes.string,
};

MetricBenchmark.defaultProps = {
  benchmarkValue: null,
  percentOfBenchmark: 0,
  progress: null,
  symbol: null,
  name: null,
  verb: null,
};

export default MetricBenchmark;
