import { combineReducers } from 'redux';

import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';

import {
  getCurrentUserId,
  getCurrentUserPermission,
} from 'arcade-frontend-core/src/reducers/user';

import currentGameId, {
  getCurrentGameId,
} from './currentGameId';

import {
  getCurrentGameDetailsType,
} from './currentGameDetailsType';

import eventsByGameId from './eventsByGameId';

import gamesById, {
  getGames,
  getGamesById,
  getGamesActive,
  getGamesPrelaunch,
  getGamesCompleted,
  getGamesVerifying,
} from './gamesById';

import isFetching, {
  getIsFetching,
} from './isFetching';

import rankingsByGameId from './rankingsByGameId';

const games = combineReducers({
  currentGameId,
  eventsByGameId,
  gamesById,
  isFetching,
  rankingsByGameId,
});

export default games;

export const getGamesViewState = state => ({
  currentGameId: getCurrentGameId(state),
  isFetching: getIsFetching(state),
  games: getGames(state),
  gamesById: getGamesById(state),
});

export const getGamesIndexState = state => ({
  canManage: getCurrentUserPermission(state, PERMISSIONS.MANAGE_GAMES),
  currentGameId: getCurrentGameId(state),
  gamesById: getGamesById(state),
  gamesActive: getGamesActive(state),
  gamesPrelaunch: getGamesPrelaunch(state),
  gamesCompleted: getGamesCompleted(state),
  gamesVerifying: getGamesVerifying(state),
  isFetching: getIsFetching(state),
});

export const getGameDetailsState = state => ({
  gamesById: getGamesById(state),
  currentGameId: getCurrentGameId(state),
  currentGameDetailsType: getCurrentGameDetailsType(state),

  currentRankings: state.games.rankingsByGameId[state.games.currentGameId],
  currentEvents: state.games.eventsByGameId[state.games.currentGameId],

  currentUserId: getCurrentUserId(state),
});
