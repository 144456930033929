import showdown from 'showdown';
// import showdownEmoji from 'showdown-emoji';
// import showdownHighlight from 'showdown-highlight';

import copyToClipboard from 'arcade-frontend-core/src/helpers/copyToClipboard';

import '../../styles/parsers';
import shortcutMap from './emojis';

const emojiShortcutConverter = content => {
  let convertedContent = content;

  Object.keys(shortcutMap).forEach(shortcut => {
    const indexOfShortcut = convertedContent.indexOf(shortcut);
    const indexOfNextLetter = indexOfShortcut + shortcut.length;
    const nextLetter = convertedContent[indexOfNextLetter];

    if (!nextLetter || nextLetter === ' ') {
      convertedContent = convertedContent.replace(
        shortcut,
        shortcutMap[shortcut],
      );
    }
  });

  return convertedContent;
};

const emojiUnderscoreConverter = content => {
  let convertedContent = content;

  const regex = /:([\w\\]+):/g;
  let matches;

  do {
    matches = regex.exec(convertedContent);

    if (matches) {
      const escapedEmoji = matches[0].replace(/_/g, '\\_');
      convertedContent = convertedContent.replace(matches[0], escapedEmoji);
    }
  } while (matches);

  return convertedContent;
};

const markdownConverter = new showdown.Converter({
  extensions: [
    // showdownEmoji,
    // showdownHighlight,
  ],

  excludeTrailingPunctuationFromURLs: true,
  github: true,
  openLinksInNewWindow: true,
  requireSpaceBeforeHeadingText: true,
  simplifiedAutoLink: true,
  simpleLineBreaks: true,
  strikethrough: true,
  tables: true,
  tasklists: true,
  literalMidWordUnderscores: true,
  headerLevelStart: 3,
  disableForced4SpacesIndentedSublists: true,
});

export const extractMentionTypes = mentionIds => {
  const mentionTypes = { team: [], person: [], entity: [] };
  mentionIds.forEach(id => {
    const groups = id.match(/^(person|team|entity)?(\d+)$/);
    if (groups) {
      const type = groups[1] || 'person';
      mentionTypes[type].push(groups[2]);
    }
  });
  return mentionTypes;
};

// const userTags = new RegExp(/(^|\s)(\\)?(@\[([a-z\d\s'\-~.]+\]))(?=[.!?;,[\]()]|\s|$)\[tag:(person|team)?([0-9]+)*\]/gmi);
const userTags = new RegExp(
  /(^|\s)(\\)?(@\[([^[\]]+)\])(?=[.!?;,[\]()]|\s|$)\[tag:(person|team|entity)?([0-9]+)*\]/gim,
);

const hashTags = new RegExp(/@\[(#\w*)\](\[hashtag:(#\w*)\])/gim);
const rawHashTags = new RegExp(/(?!\b)(#\w*[a-zA-Z]+\w*)(?!\B|\S)/gim);

export const getHashTags = value => value.match(rawHashTags);

const arcProfileLinkAnchor = (groups, hasMentions = true) => {
  const id = groups[6];
  const taggable = groups[5] || 'person';
  const name = groups[4];

  const onclick = hasMentions
    ? `onclick="return window.quicklink(${id}, '${taggable}')"`
    : '';

  return ` <a ${onclick}>@${name}</a>`;
};

const arcRawHashTagReactMention = (...groups) => {
  const tag = groups[0];
  return `@[${tag}][hashtag:${tag}]`;
};

const arcHashTagLink = (...groups) => {
  const tag = groups[1];
  return `<a class="arc-clickable" onclick="window.showTags('${tag}')">${tag}</a>`;
};

export const parseTagsToLinks = (content, hasMentions) =>
  content.replace(userTags, (...groups) =>
    arcProfileLinkAnchor(groups, hasMentions),
  );

export const parseHashTagsToLinks = content =>
  content.replace(hashTags, arcHashTagLink);
export const parseRawHashTagsToReactMention = content =>
  content.replace(rawHashTags, arcRawHashTagReactMention);

export const parseUserInputToContent = content => {
  let preContent = content;

  // Parse for Emoji Shortcuts
  preContent = emojiShortcutConverter(preContent);

  // Escape underscores within emojis to avoid emphasising the text
  // in markdownConverter
  preContent = emojiUnderscoreConverter(preContent);

  return preContent;
};

export const parseContentToHtml = content =>
  markdownConverter.makeHtml(content);

export const chatMessage = {
  pre: parseUserInputToContent,
  parse: parseContentToHtml,
};

export default {
  chatMessage,
  parseTagsToLinks,
  parseHashTagsToLinks,
  parseUserInputToContent,
  parseContentToHtml,
};

const codeCopyBtn =
  '<a onclick="window.handleCopy(this)" class="code-copy">copy</a>';
const codeCopyTitle = language =>
  `<div class="code-copy-title">${language}</div>`;
const codeCopyTooltip = '<div class="code-copy-tooltip animated">Copied!</div>';

const codeLanguageRegExp = new RegExp(
  /<code class="([^\s]+) language-[\w]*">/g,
);
const codeRegExp = new RegExp(/<pre><code>/g);

const addTitleToCodeBlock = (...groups) =>
  `<div class="arcade-scroll">${codeCopyTitle(
    groups[1],
  )}${codeCopyTooltip}${codeCopyBtn}${groups[0]}`;

const addCopyButtonToCodeBlockLanguage = content =>
  content.replace(codeLanguageRegExp, addTitleToCodeBlock);

const addCopyButtonToCodeBlock = content =>
  content.replace(
    codeRegExp,
    `<pre><div class="arcade-scroll">${codeCopyTooltip}${codeCopyBtn}<code>`,
  );

const addClosingTagForArcScroll = content =>
  content.replace(/<\/code><\/pre>/g, '</code></div></pre>');

const baseUrl =
  typeof window !== 'undefined' && window.location
    ? window.location.host
    : 'https://launch.arcadehub.co';
const internalAnchorRegExp = new RegExp(
  `<a href=".+?//${baseUrl}/#/arcade/(.+)" target="_blank">(.)+?</a>`,
  'gim',
);

export const replaceInternalAnchorTag = str =>
  str.replace(internalAnchorRegExp, (...groups) =>
    groups[0].replace(' target="_blank"', ''),
  );

export const userInput = (content, options = {}) => {
  if (typeof content === typeof undefined || content === null) return '';

  const { hasMentions = true } = options;

  let parsed = parseRawHashTagsToReactMention(content);

  parsed = parseHashTagsToLinks(parsed);
  parsed = parseTagsToLinks(parsed, hasMentions);
  parsed = parsed.replace(/&gt;/g, '>');
  parsed = parsed.replace(/&amp;gt;/g, '>');

  // parsed = parsed.replace(/&lt;/g, '<');
  // parsed = parsed.replace(/&amp;lt;/g, '<');

  parsed = parseContentToHtml(parsed);

  const parsedWithCodeBlockLanguage = addCopyButtonToCodeBlockLanguage(parsed);

  if (parsedWithCodeBlockLanguage === parsed) {
    // Content did not contain any code blocks with a language specified
    parsed = addCopyButtonToCodeBlock(parsed);
  } else {
    parsed = parsedWithCodeBlockLanguage;
  }

  parsed = addClosingTagForArcScroll(parsed);
  parsed = replaceInternalAnchorTag(parsed);

  return parsed;
};

const replaceComponentCode = str =>
  str.replace(/`<([A-z]+) \/>`/gim, (...groups) =>
    window.docsByComponentName[groups[1]]
      ? `[${groups[0]}](/#/${groups[1]})`
      : groups[0],
  );

const replaceDocumentationAnchorTag = str =>
  str.replace(
    /<a href="\/#\/([A-z]+)" target="_blank">(.)+?<\/a>/gim,
    (...groups) =>
      groups[0].replace(
        '<a href="/#/',
        `<a onclick="window.handleClickItem(event, '${groups[1]}')" href="/#/`,
      ),
  );

export const documentationUserInput = str => {
  let html = replaceComponentCode(str);
  html = userInput(html);
  html = replaceDocumentationAnchorTag(html);
  return html;
};

const handleCopy = element => {
  const tooltip = element.previousSibling;
  const code = element.nextSibling.innerText;
  copyToClipboard(code);
  tooltip.classList.add('is-visible', 'fadeOut');

  setTimeout(() => {
    tooltip.classList.remove('is-visible', 'fadeOut');
  }, 2000);
};

// https://ctrlq.org/code/20294-regex-extract-links-javascript
const getUrlRegExp = /(((https?:\/\/)|(www\.))(\S+))/gi;

const getVideoUrlRegExp = /((https?:\/\/)?(www\.)?(youtube\.com|vimeo\.com|player\.vimeo\.com|youtu\.be|twitter\.com)(\/(\S+)?)?)/gi;

export const linkedText = (text = '') => (text ? text.match(getUrlRegExp) : []);
export const videoLinkedText = (text = '') =>
  text ? text.match(getVideoUrlRegExp) : [];

if (typeof window !== 'undefined') {
  window.handleCopy = handleCopy;

  window.ngParsers = {
    userInput,
    documentationUserInput,
    linkedText,
  };
}
