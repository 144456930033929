import React from 'react';
import cx from 'classnames';
import MobileStepper from '@material-ui/core/MobileStepper';
import { withStyles } from '@material-ui/styles';

import ArcPropTypes from '../../helpers/arc/propTypes';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },

  'dotActive-primary': {
    backgroundColor: theme.palette.primary.main,
  },

  'dotActive-secondary': {
    backgroundColor: theme.palette.secondary.main,
  },

  'dotActive-danger': {
    backgroundColor: theme.palette.danger.main,
  },

  'dotActive-blue': {
    backgroundColor: theme.palette.blue.main,
  },

  'dotActive-green': {
    backgroundColor: theme.palette.green.main,
  },

  'dotActive-purple': {
    backgroundColor: theme.palette.purple.main,
  },

  'dotActive-white': {
    backgroundColor: theme.palette.common.white,
  },

  'variant-squared': {
    margin: 8,
    borderRadius: 0,
    width: '100%',
  },

  dotsSquared: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },

  'dotsBackgroundColor-blue': {
    backgroundColor: theme.palette.blue.main,
  },

  'dotsBackgroundColor-paper': {
    backgroundColor: theme.palette.background.paper,
  },
});

class ArcMobileStepper extends React.PureComponent {
  static propTypes = {
    color: ArcPropTypes.string,
    classes: ArcPropTypes.objectOf(ArcPropTypes.string),
    dotsClassName: ArcPropTypes.string,
    dotsBackgroundColor: ArcPropTypes.oneOf([
      '',
      'paper',
      'blue',
    ]),
    steps: ArcPropTypes.number,
    variant: ArcPropTypes.oneOf([
      'dots',
      'squared',
      'text',
      'progress',
    ]),
  };

  static defaultProps = {
    color: undefined,
    classes: {},
    dotsClassName: '',
    dotsBackgroundColor: '',
    steps: 0,
    variant: 'dots',
  };

  render() {
    const {
      color,
      classes,
      dotsBackgroundColor,
      dotsClassName,
      variant,
      ...props
    } = this.props;

    return (
      <MobileStepper
        {...props}
        classes={{
          root: cx([
            classes.root,
          ]),
          dots: cx([
            variant === 'squared' && classes.dotsSquared,
            classes[`dotsBackgroundColor-${dotsBackgroundColor}`],
            dotsClassName,
          ]),
          dot: cx([
            variant && classes[`variant-${variant}`],
          ]),
          dotActive: cx([
            color && classes[`dotActive-${color}`],
          ]),
        }}
        variant={variant !== 'squared' ? variant : undefined}
      />
    );
  }
}

export default withStyles(styles)(ArcMobileStepper);
