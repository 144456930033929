import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../../actions/manage/games/types';

const handleGameRankingsLoadSuccess = (state, action) => {
  const { id, data } = action.payload;

  const newState = { ...state };

  newState[id] = data;

  return newState;
};

const initialState = {};

const handlers = {
  [types.MANAGE_GAMES_RANKINGS_INDEX.SUCCESS]: handleGameRankingsLoadSuccess,
};

export default createReducer(initialState, handlers);

export const rankingsByGameId = state => state.manage.games.rankingsByGameId;
