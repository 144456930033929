import { makeApiActionTypes } from 'arcade-frontend-ui';

export const APP_CHECKLIST_INDEX = makeApiActionTypes('APP_CHECKLIST_INDEX');
export const APP_CHECKLIST_CLAIM_REWARD = makeApiActionTypes(
  'APP_CHECKLIST_CLAIM_REWARD',
);
export const APP_PROFILE_UPDATE = makeApiActionTypes('APP_PROFILE_UPDATE');
export const APP_GET_MUTE = makeApiActionTypes('APP_GET_MUTE');
export const APP_HASH_TAGS_INDEX = makeApiActionTypes('APP_HASH_TAGS_INDEX');
export const APP_INVITE_PERSON = makeApiActionTypes('APP_INVITE_PERSON');
export const APP_LOGIN = makeApiActionTypes('APP_LOGIN');
export const APP_LOGOUT = makeApiActionTypes('APP_LOGOUT');
export const APP_MENU = makeApiActionTypes('APP_MENU');
export const APP_FEATURES = makeApiActionTypes('APP_FEATURES');
export const APP_UPDATE_FEATURES = makeApiActionTypes('APP_UPDATE_FEATURES');
export const APP_PERMISSIONS = makeApiActionTypes('APP_PERMISSIONS');
export const APP_PEOPLE_INDEX = makeApiActionTypes('APP_PEOPLE_INDEX');
export const APP_RESET_PASSWORD = makeApiActionTypes('APP_RESET_PASSWORD');
export const APP_TAGGABLE_PEOPLE_INDEX = makeApiActionTypes(
  'APP_TAGGABLE_PEOPLE_INDEX',
);
export const APP_TAGGABLE_TEAMS_INDEX = makeApiActionTypes(
  'APP_TAGGABLE_TEAMS_INDEX',
);
export const APP_TAGGABLE_ENTITIES_INDEX = makeApiActionTypes(
  'APP_TAGGABLE_ENTITIES_INDEX',
);
export const APP_TOGGLE_MUTE = makeApiActionTypes('APP_TOGGLE_MUTE');
export const APP_CHAT_REGISTRATION = makeApiActionTypes(
  'APP_CHAT_REGISTRATION',
);
export const APP_CHAT_NOTIFICATION = 'APP_CHAT_NOTIFICATION';

export const APP_TOGGLE_FEATURE_FLAG = 'APP_TOGGLE_FEATURE_FLAG';

export const APP_SIZZLE_CLEAR = 'APP_SIZZLE_CLEAR';

export const SET_APP_READY = 'SET_APP_READY';
export const SET_ARC_APP_BAR = 'SET_ARC_APP_BAR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_NG_PROPS = 'SET_NG_PROPS';
export const SET_PLATFORM = 'SET_PLATFORM';

export const SET_IS_VIEWING_MAIN = 'SET_IS_VIEWING_MAIN';
export const SET_IS_VIEWING_ALERT = 'SET_IS_VIEWING_ALERT';

export const SET_ALERT = 'SET_ALERT';

export const SOCKET_IO_MESSAGE = 'SOCKET_IO_MESSAGE';
export const SOCKET_IO_TYPING_ADD = 'SOCKET_IO_TYPING_ADD';
export const SOCKET_IO_TYPING_REMOVE = 'SOCKET_IO_TYPING_REMOVE';

export const APP_ROOT_SCOPE_BROADCAST = 'APP_ROOT_SCOPE_BROADCAST';

export const APP_SET_FIRST_SIGN_IN = 'APP_SET_FIRST_SIGN_IN';
export const APP_SET_ONBOARDING_TOUR_OPEN = 'APP_SET_ONBOARDING_TOUR_OPEN';
export const APP_SET_NETWORK_STATUS = 'APP_SET_NETWORK_STATUS';

export const SET_GEOFENCE_STATUS = 'SET_GEOFENCE_STATUS';
export const WEBVIEW_SET_APP_READY = 'WEBVIEW_SET_APP_READY';
export const WEBVIEW_SET_CAN_GO_BACK = 'WEBVIEW_SET_CAN_GO_BACK';
export const WEBVIEW_SET_CURRENT_USER = 'WEBVIEW_SET_CURRENT_USER';
export const WEBVIEW_SET_PROFILE_IMAGE_URL = 'WEBVIEW_SET_PROFILE_IMAGE_URL';
export const WEBVIEW_SET_IS_MUTED = 'WEBVIEW_SET_IS_MUTED';
export const WEBVIEW_SET_ROUTE_TYPE = 'WEBVIEW_SET_ROUTE_TYPE';
export const WEBVIEW_SET_UNREAD_MESSAGES = 'WEBVIEW_SET_UNREAD_MESSAGES';
export const SET_UNREAD_REWARDS = 'SET_UNREAD_REWARDS';

export const APP_PREPARE_WEBPUSH_CREDENTIALS =
  'APP_PREPARE_WEBPUSH_CREDENTIALS';
export const APP_SAVE_WEBPUSH_CREDENTIALS = makeApiActionTypes(
  'APP_SAVE_WEBPUSH_CREDENTIALS',
);

export const LOGOUT = 'LOGOUT';

export const SET_INTEGRATION_WIDGET_LOADED = 'SET_INTEGRATION_WIDGET_LOADED';
export const SET_SUPPORT_WIDGET_LOADED = 'SET_SUPPORT_WIDGET_LOADED';

export const SET_APP_MENU = 'SET_APP_MENU';

export const SET_APP_PERMISSIONS = 'SET_APP_PERMISSIONS';
