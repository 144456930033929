import React from 'react';
import Close from '@material-ui/icons/Close';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcButton from '../../elements/ArcButton/ArcButton';
import ArcView from '../../primitives/ArcView/ArcView';
import ArcText from '../../primitives/ArcText';
import ArcStyles, { createWithStyles } from '../../styles';

const show = 'translate3d(-50%, -50%, 0) scale(1)';
const hide = 'translate3d(-50%, -50%, 0) scale(0)';
const BUTTON_SIZE = 48;

const styles = {
  ArcViewTitleBarButton: () => ({
    root: {
      width: BUTTON_SIZE,
      height: BUTTON_SIZE,
      minWidth: BUTTON_SIZE,
      padding: 8,
    },
  }),

  ArcViewTitleBarText: () => ({
    root: {
      display: 'block',
      position: 'absolute',
      transform: 'translate3d(-50%, -50%, 0)',
      top: '50%',
      left: '50%',
      width: `calc(100% - ${BUTTON_SIZE * 2}px)`,
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 14,
      color: ArcStyles.theme.brandPrimary,
      lineHeight: '48px',
      height: '100%',
    },
  }),

  ArcViewTitleBarView: () => ({
    root: {
      position: 'relative',
      zIndex: 100,
      backgroundColor: '#fff',
      borderBottomWidth: 2,
      borderBottomStyle: 'solid',
      borderBottomColor: '#e0e0e0',
      flexShrink: 0,
    },
  }),

  IconView: theme => ({
    root: {
      '& .ViewTitleBarIcon': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transition: 'transform 0.2 ease-in-out',
        transform: hide,
        color: ArcStyles.theme.brandPrimary,
      },

      '& .ViewTitleBarIcon.Close': {

        [theme.breakpoints.up('sm')]: {
          transform: show,
        },
      },

      '& .ViewTitleBarIcon.Back': {
        transform: show,

        [theme.breakpoints.up('sm')]: {
          transform: hide,
        },
      },
    },
  }),
};

const ArcViewTitleBarButton = createWithStyles(styles.ArcViewTitleBarButton)(ArcButton);
const ArcViewTitleBarText = createWithStyles(styles.ArcViewTitleBarText)(ArcView);
const ArcViewTitleBarView = createWithStyles(styles.ArcViewTitleBarView)(ArcView);
const IconView = createWithStyles(styles.IconView)(ArcView);

const styleIconClose = showIconBack => ({
  color: ArcStyles.theme.brandPrimary,
  transform: showIconBack ? hide : null,
});

const styleIconBack = showIconBack => ({
  color: ArcStyles.theme.brandPrimary,
  transform: showIconBack ? show : null,
});

class ArcViewTitleBar extends React.PureComponent {
  static propTypes = {
    children: ArcPropTypes.node,
    onClickBack: ArcPropTypes.func,
    showIconBack: ArcPropTypes.bool,
    title: ArcPropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    onClickBack: ArcPropTypes.noop,
    title: '',
    showIconBack: false,
  };

  render() {
    const {
      onClickBack,
      showIconBack,
      title,
    } = this.props;

    return (
      <ArcViewTitleBarView row align="center">
        <ArcViewTitleBarButton onClick={onClickBack} label="back">
          <IconView>
            <Close
              className="ViewTitleBarIcon Close"
              style={styleIconClose(showIconBack)}
            />
            <ChevronLeft
              className="ViewTitleBarIcon Back"
              style={styleIconBack(showIconBack)}
            />
          </IconView>
        </ArcViewTitleBarButton>

        <ArcViewTitleBarText>
          <ArcText>{title}</ArcText>
        </ArcViewTitleBarText>

        <ArcView flexGrow="100" />

        {this.props.children}
      </ArcViewTitleBarView>
    );
  }
}

export default ArcViewTitleBar;
