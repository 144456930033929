import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import ArcStyles from '../../styles';
import ArcView from '../../primitives/ArcView/ArcView';
import ArcEntityAvatar from '../ArcEntityAvatar';


const styleArcPerson = {
  color: '#8C8C8C',
  lineHeight: '24px',
};

const stylePeopleAmount = {
  color: ArcStyles.theme.grey3,
  marginLeft: 4,
};

const stylePeopleAmountSelected = {
  ...stylePeopleAmount,
  color: ArcStyles.theme.blue3,
};

const ArcEntity = ({ id,
  color,
  letter,
  name,
  style,
  peopleAmount,
  isSelected,
  type,
  shape,
  imageUrl,
  ...rest
}) => {
  const entityColor = color || window.teamDisplayTools.getTeamColor(id);
  const entityLetter = letter || window.teamDisplayTools.getTeamLetter(name);

  return (
    <ArcView
      row
      align="center"
      {...rest}
      style={{
        ...styleArcPerson,
        ...style,
      }}
    >
      <ArcEntityAvatar
        color={entityColor}
        imageUrl={imageUrl}
        letter={entityLetter}
        size={32}
        type={type}
        shape={shape}
      />

      <ArcView row marginLeft={8}>
        <ArcView>{name}</ArcView>
        {!!peopleAmount && (
        <ArcView style={isSelected ? stylePeopleAmountSelected : stylePeopleAmount}>
          &mdash; {peopleAmount} {pluralize('People', peopleAmount)}
        </ArcView>
        )}
      </ArcView>
    </ArcView>
  );
};

ArcEntity.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  isSelected: PropTypes.bool,
  letter: PropTypes.string,
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  peopleAmount: PropTypes.number,
  shape: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
};

ArcEntity.defaultProps = {
  color: null,
  id: null,
  type: null,
  isSelected: false,
  letter: null,
  imageUrl: null,
  peopleAmount: null,
  shape: null,
  style: {},
};

export default ArcEntity;
