import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const handleRewardsTransactionsIndexSuccess = (_state, action) => {
  const { data } = action.payload;
  const { summary = {} } = data;
  return summary;
};

const initialState = {};

const handlers = {
  [types.REWARDS_TRANSACTIONS_INDEX
    .SUCCESS]: handleRewardsTransactionsIndexSuccess,
  [types.REWARDS_TRANSACTIONS_INDEX_BY_USER_ID
    .SUCCESS]: handleRewardsTransactionsIndexSuccess,
};

const summary = createReducer(initialState, handlers);

export default summary;

export const getSummary = state => state.rewards.summary;
