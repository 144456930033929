import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  ArcPropTypes,
  ArcViewTitleBar,
  ArcMainView,
} from 'arcade-frontend-ui';

import {
  // actions,
  routes,
} from '../../../actions/manage/checklists';

import FormChecklistsCreate from '../../../forms/checklists/create';

class ManageChecklistsCreateContainer extends React.PureComponent {
  static propTypes = {
    routeManageChecklists: ArcPropTypes.func.isRequired,
  };

  static defaultProps = {
  }

  render() {
    return (
      <ArcMainView
        isViewing
        hasNoPaddingLeftLaunch
      >
        <ArcViewTitleBar
          onClickBack={this.props.routeManageChecklists}
          showIconBack
          title="New Checklist"
        />

        <FormChecklistsCreate />
      </ArcMainView>
    );
  }
}

const mapStateToProps = () => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  // ...actions,
  // ...routes,
  routeManageChecklists: routes.routeManageChecklists,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManageChecklistsCreateContainer);
