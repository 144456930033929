import React from 'react';

import StarFill from 'arcade-frontend-ui/src/icons/StarFill';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';

export default function WeeklySuperstarActivityBanner() {
  return (
    <ArcViewBox
      overflow="hidden"
      position="absolute"
      width="100%"
      height="100%"
      top={0}
      right={0}
      bottom={0}
      left={0}
    >
      <StarFill
        position="absolute"
        color="#044F78"
        width="13%"
        height="72px"
        left="8px"
        top="-16px"
        style={{
          transform: 'rotate(15deg)',
        }}
      />
      <StarFill
        position="absolute"
        color="#044F78"
        width="27%"
        height="160px"
        left="30px"
        bottom="-50%"
        style={{
          transform: 'rotate(-15deg)',
        }}
      />
      <StarFill
        position="absolute"
        color="#044F78"
        width="17%"
        height="92px"
        left="64%"
        bottom="-27%"
      />
      <StarFill
        position="absolute"
        color="#044F78"
        width="24%"
        height="128px"
        right="-5%"
        top="-5%"
        style={{
          transform: 'rotate(15deg)',
        }}
      />
    </ArcViewBox>
  );
}
