import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  ArcPropTypes,
  ArcView,
  ArcMainView,
  ArcViewTitleBar,
} from 'arcade-frontend-ui';

import { routeManage } from '../../../actions/manage/routes';

import {
  // actions,
  routes,
} from '../../../actions/manage/checklists';

import { getCurrentChecklistId } from '../../../reducers/manage/checklists/currentChecklistId';

import ManageChecklistsDetailsContainer from './ManageChecklistsDetailsContainer';
import ManageChecklistsIndexContainer from './ManageChecklistsIndexContainer';


const stylePositionRel = {
  position: 'relative',
};

class ManageChecklistsViewContainer extends React.PureComponent {
  static propTypes = {
    currentChecklistId: ArcPropTypes.string,
    routeManage: ArcPropTypes.func.isRequired,
    routeManageChecklists: ArcPropTypes.func.isRequired,
  };

  static defaultProps = {
    currentChecklistId: null,
  };

  render() {
    return (
      <>
        <ArcMainView
          isViewing
          hasScroll={false}
          hasNoPaddingLeftLaunch
          style={stylePositionRel}
        >
          <ArcViewTitleBar
            onClickBack={this.props.routeManage}
            title="Manage Checklists"
          />
          <ManageChecklistsIndexContainer />
        </ArcMainView>
        {this.props.currentChecklistId !== null && (
          <ArcView
            zIndex="1000"
            position="absolute"
            fillToParent
          >
            <ManageChecklistsDetailsContainer
              onClose={this.props.routeManageChecklists}
            />
          </ArcView>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentChecklistId: getCurrentChecklistId(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  routeManageChecklists: routes.routeManageChecklists,
  routeManage,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManageChecklistsViewContainer);
