import React from 'react';

const ArcadePercentage = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 18H6V20H8V18Z" fill="currentColor" />
    <path d="M10 15H8V17H10V15Z" fill="currentColor" />
    <path d="M12 12H10V14H12V12Z" fill="currentColor" />
    <path d="M14 9H12V11H14V9Z" fill="currentColor" />
    <path d="M16 6H14V8H16V6Z" fill="currentColor" />
    <path d="M6 4H4V6H6V4Z" fill="currentColor" />
    <path d="M8 4H6V6H8V4Z" fill="currentColor" />
    <path d="M6 6H4V8H6V6Z" fill="currentColor" />
    <path d="M20 16H18V18H20V16Z" fill="currentColor" />
    <path d="M20 14H18V16H20V14Z" fill="currentColor" />
    <path d="M18 16H16V18H18V16Z" fill="currentColor" />
    <path d="M18 3H16V5H18V3Z" fill="currentColor" />
  </svg>
);

export default ArcadePercentage;
