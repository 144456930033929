import { ArcPropTypes } from 'arcade-frontend-ui';
import RewardsView from 'arcade-frontend-rewards/src/views/RewardsView';
import RewardCreateView from 'arcade-frontend-rewards/src/views/RewardCreateView';
import * as types from 'arcade-frontend-core/src/types/routes';
import RewardHistoryView from 'arcade-frontend-rewards/src/views/RewardHistoryView';
import RewardsAwardView from 'arcade-frontend-rewards/src/views/RewardsAwardView/RewardsAwardView';

import getComponentFromDirective from 'arcade-frontend-core/src/helpers/getComponentFromDirective';

const ArcadeAngularRewardsView = getComponentFromDirective('arcadeRewards');
const { PLATFORMS } = ArcPropTypes;

const base = {
  // [routeTypes.ROUTE_REWARDS]: ArcadeAngularRewardsView,
  // [routeTypes.ROUTE_REWARDS_TRANSACTIONS]: ArcadeAngularRewardsView,
  // [routeTypes.ROUTE_REWARDS_HISTORY]: ArcadeAngularRewardsView,
  // [routeTypes.ROUTE_REWARDS_AWARD]: ArcadeAngularRewardsView,
  [types.ROUTE_REWARDS]: ArcadeAngularRewardsView,
  [types.ROUTE_REWARDS_AWARD]: ArcadeAngularRewardsView,
  [types.ROUTE_REWARDS_CHESTS]: ArcadeAngularRewardsView,
  [types.ROUTE_REWARDS_HISTORY]: ArcadeAngularRewardsView,
  [types.ROUTE_REWARDS_SHOP]: ArcadeAngularRewardsView,
  [types.ROUTE_REWARDS_TRANSACTIONS]: ArcadeAngularRewardsView,
  // [routeTypes.ROUTE_REWARDS]: RewardsView,
  // [routeTypes.ROUTE_REWARDS_TRANSACTIONS]: RewardCreateView,
  // [routeTypes.ROUTE_REWARDS_HISTORY]: RewardHistoryView,
  // [routeTypes.ROUTE_REWARDS_AWARD]: RewardsAwardView,
};

const benjo = base;
const development = base;
const launch = base;

export default {
  [PLATFORMS.BENJO]: benjo,
  [PLATFORMS.DEVELOPMENT]: development,
  [PLATFORMS.LAUNCH]: launch,
};
