import React from 'react';

const ArcadeHashtag = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 16.207v-2.805h2.793V10.61H5V7.804h2.793V5h2.805v2.804h2.793V5h2.805v2.804H19v2.805h-2.804v2.793H19v2.805h-2.804V19H13.39v-2.793h-2.793V19H7.793v-2.793H5zm5.598-2.805h2.793V10.61h-2.793v2.793z" fill="currentColor" fillRule="evenodd" />
  </svg>
);

export default ArcadeHashtag;
