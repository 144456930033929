import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { ArcView, createWithStyles } from 'arcade-frontend-ui';

import { ManageMetricsContainerPackage } from 'arcade-frontend-metrics';

import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import {
  getCurrentUserFeatures,
  getCurrentUser,
} from 'arcade-frontend-core/src/reducers/user';
import { ArcFormDateRangePickerContainer } from 'arcade-frontend-widgets/src/containers/ArcFormDateRangePickerContainer';

import * as routeTypes from 'arcade-frontend-core/src/types/routes';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import { actions } from '../../../actions/manage/metrics';

import ManageMetricsViewContainer from './ManageMetricsViewContainer';
import ManageMetricsEditContainer from './ManageMetricsEditContainer';

const styles = {
  TabBar: () => ({
    root: {
      backgroundColor: '#FFF',
    },
  }),

  TabContent: () => ({
    root: {
      height: '100%',
      overflow: 'auto',
    },
  }),

  DateSelector: theme => ({
    root: {
      margin: theme.spacing(4),
      marginBottom: 0,
    },
  }),
};

const TabBar = createWithStyles(styles.TabBar)(ArcView);
const TabContent = createWithStyles(styles.TabContent)(ArcView);
const DateSelector = createWithStyles(styles.DateSelector)(ArcView);

class ManageMetricsContainer extends React.PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      type: PropTypes.string,
    }).isRequired,
    apiManageMetricsTeamsIndexRequest: PropTypes.func.isRequired,
    apiManageMetricsActiveIndexRequest: PropTypes.func.isRequired,
    apiManageMetricsArchivedIndexRequest: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      id: PropTypes.string,
    }),
    showNew: PropTypes.bool,
    type: PropTypes.string,
  };

  static defaultProps = {
    currentUser: {},
    showNew: false,
    type: null,
  };

  state = {
    value: 0,
    metricType: 'active',
    dateRange: {
      type: 'time period',
      timePeriod: 'last_30_days',
      fromDate: undefined,
      toDate: undefined,
    },
  };

  componentDidMount() {
    this.requestAll();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.requestAll();
    }
  }

  setMetricType = metricType => {
    const { dateRange } = this.state;
    this.setState({ metricType }, () => {
      this.requestMetrics({ ...dateRange });
    });
  };

  requestMetrics() {
    if (!this.props.currentUser.id) {
      return;
    }
    const { dateRange, people } = this.state;

    if (this.state.metricType === 'active') {
      this.props.apiManageMetricsActiveIndexRequest({ ...dateRange, people });
    } else {
      this.props.apiManageMetricsArchivedIndexRequest({ ...dateRange, people });
    }
  }

  requestAll() {
    if (!this.props.currentUser.id) {
      return;
    }

    this.props.apiManageMetricsTeamsIndexRequest();
    this.requestMetrics();
  }

  handleChange = (_event, value) => {
    this.setState({ value });
  };

  handleDateRangeChange = ({ type, timePeriod, fromDate, toDate }) => {
    if (type === 'time period') {
      this.setState(
        {
          dateRange: {
            type,
            timePeriod,
            fromDate: undefined,
            toDate: undefined,
          },
        },
        this.requestMetrics,
      );
    } else {
      this.setState(
        {
          dateRange: {
            type,
            timePeriod: undefined,
            fromDate,
            toDate,
          },
        },
        this.requestMetrics,
      );
    }
  };

  handlePeopleChange = people => {
    const peopleIds = people.map(person => person.id);

    this.setState({ people: peopleIds }, this.requestMetrics);
  };

  renderTabs() {
    const { value, dateRange } = this.state;

    return (
      <ArcView flex={1} fullHeight>
        <TabBar>
          <Tabs
            value={value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Overview" />
            <Tab label="By Group" />
          </Tabs>
        </TabBar>

        {value === 0 && <TabContent>{this.renderView()}</TabContent>}

        {value === 1 && (
          <TabContent style={{ height: 'calc(100% - 48px)' }}>
            <DateSelector>
              <ArcBox display="inline-flex">
                <ArcFormDateRangePickerContainer
                  value={dateRange}
                  onChange={this.handleDateRangeChange}
                />
              </ArcBox>
            </DateSelector>
            <ManageMetricsContainerPackage dateRange={dateRange} />
          </TabContent>
        )}
      </ArcView>
    );
  }

  renderView() {
    const { metricType, dateRange, people } = this.state;
    const { showNew } = this.props;

    switch (this.props.location.type) {
      case routeTypes.ROUTE_MANAGE_METRICS:
      case routeTypes.ROUTE_MANAGE_METRICS_SHOW:
        return (
          <ManageMetricsViewContainer
            showNew={showNew}
            metricType={metricType}
            setMetricType={this.setMetricType}
            people={people}
            dateRange={dateRange}
            onDateRangeChange={this.handleDateRangeChange}
            onPeopleChange={this.handlePeopleChange}
            flexGrow={1}
          />
        );
      case routeTypes.ROUTE_MANAGE_METRICS_CREATE:
        return (
          <ArcView style={{ height: 'calc(100% - 48px)' }} flexGrow={1}>
            <ManageMetricsEditContainer isCreate />
          </ArcView>
        );
      case routeTypes.ROUTE_MANAGE_METRICS_EDIT:
        return (
          <ArcView style={{ height: 'calc(100% - 48px' }} flexGrow={1}>
            <ManageMetricsEditContainer />
          </ArcView>
        );
      default:
        return null;
    }
  }

  render() {
    return this.props.showNew ? this.renderTabs() : this.renderView();
  }
}

const mapStateToProps = (state, props) => ({
  currentUser: getCurrentUser(state),
  location: state.location,
  showNew: getCurrentUserFeatures(state, FEATURE_FLAGS.NEW_METRICS),
  ...props,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageMetricsContainer);
