import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const Torch3 = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.253 22.524a1.247 1.247 0 01-2.434 0L9.036 14.5h6l-1.783 8.024z"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.2 13.61a2 2 0 01-1.664.89h-7a2 2 0 01-1.664-.891L5.466 11.5h13.141L17.2 13.61z"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.163 9.521c.174-.469.26-.965.256-1.465a5.489 5.489 0 00-1.634-3.813 1.885 1.885 0 01-2.179 1.634A4.569 4.569 0 0114.517.5c-3.4 0-5.583 2.009-5.992 4.9a2 2 0 01-2.179-1.632A4.871 4.871 0 005.059 9.58"
    />
  </Icon>
);

Torch3.displayName = 'Torch3';

export default Torch3;
