import React from 'react';
import PropTypes from 'prop-types';

import {
  RACE,
  TOURNAMENT,
  BOUNTY,
  GAME_TYPES,
} from 'arcade-frontend-core/src/types/game-formats';
import { ArcText } from 'arcade-frontend-ui';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import { formatByGameUnit } from 'arcade-frontend-core/src/helpers/numbers/numbers';

const HelperText = ({ textAlign, textSize, children }) => (
  <ArcBox
    component={ArcText}
    fontSize={textSize}
    color="#616264"
    width="100%"
    lineHeight="150%"
    textAlign={textAlign}
  >
    {children}
  </ArcBox>
);

const Emphasis = ({ children }) => (
  <ArcBox
    display="block"
    component={ArcText}
    color="#00a77c"
    fontWeight="bold"
    lineHeight="150%"
    whiteSpace="nowrap"
  >
    {children}
  </ArcBox>
);

function GamesHowToWinText({
  gameType,
  metric,
  rankings,
  unit,
  textAlign,
  textSize,
}) {
  const renderBountyText = () => (
    <HelperText textAlign={textAlign} textSize={textSize}>
      {'Get the most'}
      <Emphasis>{metric}</Emphasis>
      {'to win!'}
    </HelperText>
  );

  const renderFirstPlaceText = (goal, score) => {
    const remainder = formatByGameUnit(unit, goal - score);
    const formattedGoal = formatByGameUnit(unit, goal);

    return (
      <>
        {gameType === TOURNAMENT ? (
          <HelperText textAlign={textAlign}>
            {'You are'}
            <Emphasis>{`${remainder} ahead of ${metric}`}</Emphasis>
          </HelperText>
        ) : (
          <HelperText textAlign={textAlign}>
            {`${remainder} to`}
            <Emphasis>{`${formattedGoal} ${metric}`}</Emphasis>
          </HelperText>
        )}
      </>
    );
  };

  const renderZeroScoreText = () => (
    <HelperText textAlign={textAlign}>
      {'Make 1'}
      <Emphasis>{metric}</Emphasis>
      {'to get started!'}
    </HelperText>
  );

  const renderOvertakePersonAheadText = (currentUser, personAhead) => {
    const remainder = formatByGameUnit(
      unit,
      personAhead.score - currentUser.score,
    );

    return (
      <HelperText textAlign={textAlign}>
        {'Make'}
        <Emphasis>{`${remainder} ${metric}`}</Emphasis>
        {'to overtake the person ahead!'}
      </HelperText>
    );
  };

  const renderYouCanDoItText = () => (
    <HelperText textAlign={textAlign}>{'You can do it!'}</HelperText>
  );

  const currentUserRanking =
    rankings && rankings.find(ranking => ranking.isSelf);
  const bottomThreshold = rankings && Math.round(0.9 * rankings.length);

  if (!currentUserRanking) {
    return renderYouCanDoItText();
  }

  const { goal, position, score } = currentUserRanking;
  if (gameType === BOUNTY) {
    return renderBountyText();
  }

  if (score === 0) {
    return renderZeroScoreText();
  }

  if (position >= bottomThreshold) {
    return renderYouCanDoItText();
  }

  if (position === 1) {
    return renderFirstPlaceText(goal, score);
  }

  const personAheadRanking =
    rankings &&
    rankings.find(
      ranking => ranking.position + 1 === currentUserRanking.position,
    );
  if (personAheadRanking) {
    return renderOvertakePersonAheadText(
      currentUserRanking,
      personAheadRanking,
    );
  }
  return null;
}

GamesHowToWinText.displayName = 'GamesHowToWinText';

GamesHowToWinText.propTypes = {
  gameType: PropTypes.oneOf(GAME_TYPES),
  metric: PropTypes.string,
  unit: PropTypes.oneOf(['#', '$']),
  textAlign: PropTypes.string,
  textSize: PropTypes.number,
  rankings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      assigneeId: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      goal: PropTypes.number,
      isSelf: PropTypes.bool,
      position: PropTypes.number,
      score: PropTypes.number,
    }),
  ),
};

GamesHowToWinText.defaultProps = {
  gameType: RACE,
  metric: null,
  unit: '#',
  textAlign: 'center',
  textSize: 14,
  rankings: [],
};

HelperText.propTypes = {
  children: PropTypes.node,
  textAlign: PropTypes.string,
  textSize: PropTypes.number,
};

HelperText.defaultProps = {
  children: null,
  textAlign: 'center',
  textSize: 14,
};

Emphasis.propTypes = {
  children: PropTypes.node,
};

Emphasis.defaultProps = {
  children: null,
};

export default GamesHowToWinText;
