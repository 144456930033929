import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';

import ArcIconButton from '../../elements/ArcIconButton';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: theme.spacing(6.25),
    background: 'rgba(37, 37, 41, 0.2)',
    transition: '.3s ease-out',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(37, 37, 41, 0.7)',
      transition: '.3s ease',
    },
  },
  iconButton: {
    color: theme.palette.common.white,
    padding: theme.spacing(0.75),
  },
}));

function ArcCardActionButton({ onClick }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <ArcIconButton
        aria-label="Person Actions"
        className={classes.iconButton}
        data-testid="ArcCardActionButton-MenuButton"
        style={{ borderRadius: 50 }}
        onClick={onClick}
      >
        <MoreVertIcon />
      </ArcIconButton>
    </Box>
  );
}

ArcCardActionButton.displayName = 'ArcCardActionButton';

ArcCardActionButton.propTypes = {
  onClick: PropTypes.func,
};

ArcCardActionButton.defaultProps = {
  onClick: global.noop,
};

export default ArcCardActionButton;
