import React from 'react';
import PropTypes from 'prop-types';

import {
  G,
  Path,
} from 'react-primitives-svg';

import Icon from '../Icon';


const AlertDiamond = ({ stroke, ...props }) => (
  <Icon {...props}>
    <G stroke={stroke} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"><path d="M11.43 2.293l-9.15 9.585a1.034 1.034 0 000 1.415l9.15 9.585c.372.39.977.39 1.35 0l9.15-9.585a1.034 1.034 0 000-1.415l-9.15-9.585a.924.924 0 00-1.35 0zM12 14V7" /><Path d="M12.226 16a.225.225 0 00-.163.078.246.246 0 00-.063.177.26.26 0 00.073.172.237.237 0 00.166.073h.008a.228.228 0 00.164-.078.247.247 0 00.063-.177.259.259 0 00-.071-.171.237.237 0 00-.164-.074M12.009 16H12" /></G>
  </Icon>
);

AlertDiamond.propTypes = {
  stroke: PropTypes.string,
};

AlertDiamond.defaultProps = {
  stroke: 'currentColor',
};

export default AlertDiamond;
