/* eslint-disable */
import React from 'react';

import { ArcView, ArcButton } from 'arcade-frontend-ui';
import ConnectedArcUserInput from 'arcade-frontend-core/src/containers/ArcUserInputContainer';

import createForm, {
  createFormReducer,
  propTypes,
} from 'arcade-frontend-ui/src/forms/createForm';

const messageStyle = {
  margin: 3,
  padding: 5,
  borderRadius: 5,
  backgroundColor: '#EEE',
}
class WrappedInput extends React.Component {
  state = { messages: [] }

  handleSubmit = (event, modelValue, displayValue, mentions, parsedHtml, mentionIds, hashTags) => {
    this.setState({ messages: [{key: new Date().getTime(), modelValue, displayValue}, ...this.state.messages] })
  };

  render() {
    return <ArcView>
      <ConnectedArcUserInput placeholder={'ArcUserInput'} onSubmit={this.handleSubmit} />
      { this.state.messages.map((message) =>
        <ArcView style={messageStyle} key={message.key}>
          <ArcView dangerouslySetInnerHTML={{__html: window.ngParsers.userInput(message.modelValue)}} />
          <ArcView style={{fontSize: 10}}>{message.modelValue}</ArcView>
        </ArcView>
      )}
    </ArcView>;
  }
}


const model = 'test';

const initialState = {
  checkbox: false,
  currency: '',
  date: '',
  number: '',
  peopleList: '',
  select: '',
  text: '',
  time: '',
};

export const test = createFormReducer(model, initialState);

const fields = {
  checkbox: {
    type: 'checkbox',
    label: 'ArcCheckbox',
    description: 'ArcCheckbox description text',
  },

  currency: {
    type: 'currency',
    label: 'ArcCurrencyField',
    description: 'ArcCurrencyField description text',
  },

  date: {
    type: 'date',
    label: 'ArcDateField',
    description: 'ArcDateField description text',
  },

  number: {
    type: 'number',
    label: 'ArcNumberField',
    description: 'ArcNumberField description text',
    placeholder: 'Placeholder',
  },


  select: {
    type: 'select',
    label: 'ArcSelect',
    description: 'ArcSelect description text',
    tooltip: "Tooltip text! ...that's really long! And has many, many words!",
    validations: {
      isRequired: true,
    },
    items: [
      {
        value: 'daily',
        label: 'Daily',
      },
      {
        value: 'weekly',
        label: 'Weekly',
      },
      {
        value: 'monthly',
        label: 'Monthly',
      },
      {
        value: 'numOfDays',
        label: 'Number of days',
      },
    ],
  },

  text: {
    type: 'text',
    label: 'ArcTextField',
    description: 'ArcTextField description text',
    placeholder: 'Placeholder',
    validations: {
      isRequired: true,
      minLength: 4,
      maxLength: 20,
    },
  },

  user_input: {
    type: 'user_input',
    label: 'ArcUserInput',
    description: 'ArcUserInput input text',
    placeholder: 'Placeholder',
    taggableData: [{
      id: '1',
      type: 'person',
      name: 'Simon Hildebrandt',
      display: 'Simon',
      teamName: 'TeamAwesome',
      imageUrl: 'https://d21lizqa15vhen.cloudfront.net/uploads/resized/user/avatar/2485/medium_7674a1ad-b747-48a3-aca5-38feabb51072.jpg'
    },
    {
      id: '2',
      type: 'team',
      name: 'TeamAwesome!',
      display: 'TeamAwesome',
      color: 'red',
      letter: 'TA',
      peopleAmount: 10,
    }],
  },

  time: {
    type: 'time',
    label: 'ArcTimeField',
    description: 'ArcTimeField description text',
  },
};

const styleForm = {
  width: '100%',
  maxWidth: 600,
  padding: 32,
  border: '4px solid rgb(243, 243, 243)',
  backgroundColor: '#fff',
};

const TestForm = props => (
  <div style={styleForm}>
    <WrappedInput/>
    {props.renderFields.checkbox()}
    {props.renderFields.currency()}
    {props.renderFields.date()}
    {props.renderFields.number()}
    {props.renderFields.select()}
    {props.renderFields.text()}
    {props.renderFields.user_input()}
    {/*props.renderFields.time() currently broken */}
    {props.values.checkbox && props.values.select === 'daily' && 'conditional render'}
    <ArcButton onClick={props.onSubmit}>Submit</ArcButton>
  </div>
);

TestForm.propTypes = propTypes;

// const onSubmit = (e, values) => {
//   console.log('onSubmit', values);
// };

// const onError = (e, errors) => {
//   console.log('onError', errors);
// };

const selector = state => state.newForms[model];

const formProps = {
  fields,
  // onError,
  // onSubmit,
  selector,
};

export default createForm(model, formProps)(TestForm);
