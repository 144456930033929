export const BOUNTY = 'threshold';
export const RACE = 'first';
export const TOURNAMENT = 'ranking';
export const KEEP_ABOVE = 'keep_above';
export const RPA_ONE_TIME = 'rpa_one_time';
export const RPA_EVERY_TIME = 'rpa_every_time';
export const RPA_AVERAGE = 'rpa_average';

export const CORE_TYPES = [BOUNTY, RACE, TOURNAMENT];
export const RPA_TYPES = [RPA_ONE_TIME, RPA_EVERY_TIME]; // , RPA_AVERAGE

export const GAME_TYPES = [...CORE_TYPES, ...RPA_TYPES];

export const DISPLAYABLE_TITLE_BY_TYPE = {
  [BOUNTY]: 'Bounty',
  [RACE]: 'Race',
  [TOURNAMENT]: 'Tournament',
  [RPA_ONE_TIME]: 'One Time',
  [RPA_EVERY_TIME]: 'Every Time',
};

export const IMAGE_BY_TYPE = {
  [BOUNTY]: 'https://d17rkelr6jjme9.cloudfront.net/app/games/bounty.svg',
  [RACE]: 'https://d17rkelr6jjme9.cloudfront.net/app/games/race.svg',
  [TOURNAMENT]: 'https://d17rkelr6jjme9.cloudfront.net/app/games/tournament.svg',
  [RPA_ONE_TIME]: 'https://d17rkelr6jjme9.cloudfront.net/app/games/one-time.svg',
  [RPA_EVERY_TIME]: 'https://d17rkelr6jjme9.cloudfront.net/app/games/every-time.svg',
};

export function isRPAType(type) {
  return RPA_TYPES.includes(type);
}
