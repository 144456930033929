import React from 'react';
import PropTypes from 'prop-types';
import ArcView from '../../primitives/ArcView';
import ArcToken from '../../assets/ArcToken';

function ArcTokenValue({
  value,
  ...props
}) {
  const formattedValue = value.toLocaleString ? value.toLocaleString() : value;

  return (
    <ArcView
      row
      align="center"
      justify="center"
      display="inline-flex"
      {...props}
    >
      <ArcToken
        width="16"
        height="16"
      />
      <ArcView marginLeft="4">
        {formattedValue}
      </ArcView>
    </ArcView>
  );
}

ArcTokenValue.displayName = 'ArcTokenValue';

ArcTokenValue.propTypes = {
  value: PropTypes.number,
};

ArcTokenValue.defaultProps = {
  value: 0,
};

export default ArcTokenValue;
