import { request as axiosRequest } from 'arcade-frontend-core/src/helpers/axios';
import { DEFAULT_ENDPOINTS, RTPOS_ENDPOINTS } from 'arcade-frontend-core/src/helpers/getEndpointsByEmail';

let currentResource = null;
let currentRollbar = null;

class ResourceError extends Error {
  constructor(payload = {}, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor

    if (params.length === 0) {
      super(payload.data);
    } else {
      super(...params);
    }

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResourceError);
    }

    this.date = new Date();

    this.data = payload.data;
    this.status = payload.status;
    this.message = payload.message;
  }
}

const getErrorMessage = (method, url, message, config) => [
  `${method.toUpperCase()} ${url} failed with ${message}.`,
  config ? `Using config object: ${JSON.stringify(config)}` : '',
].join('\n');

const getStatusErrorMessage = (method, url, status, exception, config) => [
  `${method.toUpperCase()} ${url} failed with ${status} (${exception})`,
  config ? `Using config object: ${JSON.stringify(config)}` : '',
].join('\n');

const handleError = (error) => {
  const { response, message } = error;
  const { data, status, config } = response;
  const { method, url } = config;

  const exception = data.exception || data.message || data.error || message;

  // if (exception.toString === '[object Object]') {
  //   exception = snakeToTitleCaseResource(exception);
  // }

  const errorMessage = exception
    ? getStatusErrorMessage(method, url, status, exception)
    : getErrorMessage(method, url, exception);

  const resourceError = new ResourceError({ data: exception, status, message: data.message }, errorMessage);

  if (currentRollbar) {
    currentRollbar.error(resourceError);
  }

  throw resourceError;
};

export const getResource = () => currentResource;

export const getRollbar = () => currentRollbar;

export const setResource = endpoint => currentResource.setEndpoint(endpoint);

export const setRollbar = (nextRollbar) => {
  currentRollbar = nextRollbar;
};

class Resource {
  constructor(props = {}) {
    const fallbackEndpoint = process.env.ARCADE_WHITELABEL_ID === 'rtpos' ? RTPOS_ENDPOINTS.endpoint : DEFAULT_ENDPOINTS.endpoint;
    this.endpoint = props.endpoint || fallbackEndpoint;
    currentResource = this;

    // if (process.env.NODE_ENV === 'development') {
    if (window) {
      window.resource = this;
    }
  }

  request(props) {
    const params = {
      ...props,
      url: this.endpoint + props.url,
    };

    return axiosRequest(params).catch(handleError);
  }

  setEndpoint(endpoint) {
    this.endpoint = endpoint;
  }
}

export default new Resource();
