import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import IconPerson from '@material-ui/icons/PersonOutline';

import {
  ArcCardActionButton,
  ArcImage,
  ArcPaper,
  ArcPlaceholder,
  ArcText,
  ArcView,
  createWithStyles,
} from 'arcade-frontend-ui';
import * as routeTypes from 'arcade-frontend-core/src/types/routes';

import PersonDetailsActionMenuItems from '../../PersonDetailsActionMenuItems';
import TokenAmountBadge from '../../../../../games-v2/src/components/TokenAmountBadge/TokenAmountBadge';

const styles = {
  PersonCardSurface: theme => ({
    root: {
      width: 200,
      height: 300,
      [theme.breakpoints.only('xs')]: {
        width: 160,
        height: 260,
      },
    },
  }),

  PersonCard: () => ({
    root: {
      position: 'relative',
      height: '100%',
    },
  }),

  PersonCardPlaceholder: theme => ({
    root: {
      height: 226,
      margin: 8,
      marginBottom: 0,
      backgroundSize: '800px, 226px',
      [theme.breakpoints.only('xs')]: {
        height: 188,
      },
    },
  }),
  ProfileImage: theme => ({
    root: {
      height: '100%',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '4px',
      transition: '.5s ease',
      cursor: 'pointer',
    },

    hover: {
      [theme.breakpoints.up('sm')]: {
        opacity: 0.5,
        borderRadius: '4px 4px 0 0',
      },
    },
  }),
  ProfileBadge: () => ({
    root: {
      position: 'absolute',
      height: 32,
      bottom: 8,
      right: 8,
      zIndex: 100,
    },
  }),
  TeamName: theme => ({
    root: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'inherit',
      [theme.breakpoints.only('xs')]: {
        fontSize: theme.font.getFontSize(0.75),
      },
    },
  }),

  PersonName: theme => ({
    root: {
      margin: theme.spacing(1),
      marginBottom: 0,
      color: 'inherit',
      [theme.breakpoints.only('xs')]: {
        fontSize: theme.font.getFontSize(0.85),
      },
    },
  }),

  PersonDetailsSection: theme => ({
    root: {
      width: '100%',
      maxHeight: theme.spacing(11.5),
      position: 'absolute',
      bottom: 0,
      backgroundImage:
        'linear-gradient(0deg, rgba(0,0,0,0.5) 50%, rgba(255,255,255,0) 100%);',
      transition: 'all .3s linear',
      overflow: 'hidden',
      borderRadius: '0 0 4px 4px',
      color: '#FFF',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },

    hover: {
      [theme.breakpoints.up('sm')]: {
        transition: 'all .4s ease-out',
        maxHeight: theme.spacing(12),
        backgroundImage:
          'linear-gradient(0deg, rgba(0,0,0,0.6) 70%, rgba(0,0,0,0) 100%)',
        paddingTop: theme.spacing(2.25),
        paddingBottom: theme.spacing(3),
      },
    },
  }),
  PersonTokenWidgetZone: theme => ({
    root: {
      position: 'absolute',
      top: '4px',
      left: 0,
      padding: theme.spacing(0.75),
      cursor: 'pointer',
    },
  }),
  PersonActionsDeadZone: theme => ({
    root: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: theme.spacing(0.75),
      cursor: 'pointer',
    },
  }),
  PersonActionsWrapper: theme => ({
    root: {
      borderRadius: theme.spacing(6.25),
      background: 'rgba(37, 37, 41, 0.4)',
      margin: theme.spacing(1),
      transition: '.3s ease-out',
      '&:hover': {
        backgroundColor: 'rgba(37, 37, 41, 0.7)',
        transition: '.3s ease',
      },
    },
  }),
  PersonActionsIconButton: theme => ({
    root: {
      color: theme.palette.common.white,
      padding: theme.spacing(0.75),
    },
  }),

  MainDetails: theme => ({
    root: {
      height: theme.spacing(7),
      textShadow: '0px 1px 0px rgba(0,0,0,.4)',
    },

    hover: {
      textShadow: 'none',
    },
  }),
  IconPersonStyle: () => ({
    root: {
      width: '100%',
      height: '100%',
      fill: '#D5D5D5',
    },
  }),
  IconLockedStyle: () => ({
    root: {
      fill: '#fff',
      height: 16,
    },
  }),
};

const PersonCardSurface = createWithStyles(styles.PersonCardSurface)(ArcPaper);
const ProfileImage = createWithStyles(styles.ProfileImage)(ArcView);
const ProfileBadge = createWithStyles(styles.ProfileBadge)(ArcImage);
const TeamName = createWithStyles(styles.TeamName)(ArcText);
const PersonName = createWithStyles(styles.PersonName)(ArcText);
const PersonDetailsSection = createWithStyles(styles.PersonDetailsSection)(
  ArcView,
);
const PersonActionsDeadZone = createWithStyles(styles.PersonActionsDeadZone)(
  ArcView,
);
const PersonTokenWidgetZone = createWithStyles(styles.PersonTokenWidgetZone)(
  ArcView,
);
const PersonCard = createWithStyles(styles.PersonCard)(ArcView);
const MainDetails = createWithStyles(styles.MainDetails)(ArcView);
const IconPersonStyle = createWithStyles(styles.IconPersonStyle)(IconPerson);
const IconLockedStyle = createWithStyles(styles.IconLockedStyle)(LockIcon);
const PersonCardPlaceholder = createWithStyles(styles.PersonCardPlaceholder)(
  ArcView,
);

const renderLoadingPlaceholder = () => (
  <PersonCardSurface className="animated fadeIn">
    <ArcView justify="flex-end" fullHeight>
      <PersonCardPlaceholder
        align="center"
        justify="center"
        className="shimmer"
      >
        <ArcView style={{ width: 48, height: 48 }}>
          <IconPersonStyle />
        </ArcView>
      </PersonCardPlaceholder>
      <ArcPlaceholder padding="24" margin="8" />
    </ArcView>
  </PersonCardSurface>
);

class PeopleGridItem extends React.PureComponent {
  static propTypes = {
    badge: PropTypes.string,
    canAwardChests: PropTypes.bool,
    canAwardTokens: PropTypes.bool,
    canManageRewards: PropTypes.bool,
    dispatch: PropTypes.func,
    id: PropTypes.string,
    image: PropTypes.string,
    tokens: PropTypes.number,
    isCurrentUser: PropTypes.bool,
    isManager: PropTypes.bool,
    hasTokenBalance: PropTypes.bool,
    isFetching: PropTypes.bool,
    name: PropTypes.string,
    team: PropTypes.string,
    primaryTeamId: PropTypes.string,
    secondaryTeams: PropTypes.arrayOf(PropTypes.string),
    locked: PropTypes.bool,
    theme: PropTypes.shape({
      breakpoints: PropTypes.shape({
        values: PropTypes.shape({
          sm: PropTypes.number,
        }),
      }),
      palette: PropTypes.any,
    }),
    onUpdateProfileSuccess: PropTypes.func,
    onUpdateTeamSuccess: PropTypes.func,
  };

  static defaultProps = {
    badge: null,
    canAwardChests: false,
    canAwardTokens: false,
    canManageRewards: false,
    dispatch: global.noop,
    id: null,
    image: null,
    tokens: null,
    isCurrentUser: false,
    isManager: false,
    hasTokenBalance: false,
    isFetching: null,
    name: null,
    team: null,
    primaryTeamId: null,
    secondaryTeams: [],
    locked: false,
    theme: null,
    onUpdateProfileSuccess: global.noop,
    onUpdateTeamSuccess: global.noop,
  };

  state = {
    isHover: false,
    anchorEl: null,
  };

  setHoverState = isHover => this.setState({ isHover });

  setAnchorEl = anchorEl => this.setState({ anchorEl });

  handleClickCard = () => {
    if (this.props.isCurrentUser) {
      return;
    }

    this.props.dispatch({
      type: routeTypes.ROUTE_PROFILE,
      payload: {
        id: this.props.id,
      },
    });
  };

  handleMouseEnter = () => this.setHoverState(true);

  handleMouseLeave = () => this.setHoverState(false);

  handleClick = event => this.setAnchorEl(event.currentTarget);

  handleClose = () => {
    this.setAnchorEl(null);
  };

  renderActionButtons = () => (
    <PersonDetailsActionMenuItems
      keepMounted
      anchorEl={this.state.anchorEl}
      open={Boolean(this.state.anchorEl)}
      onClose={this.handleClose}
      canAwardChests={this.props.canAwardChests}
      canAwardTokens={this.props.canAwardTokens}
      id={this.props.id}
      name={this.props.name}
      tokens={this.props.tokens}
      team={this.props.team}
      image={this.props.image}
      primaryTeamId={this.props.primaryTeamId}
      secondaryTeams={this.props.secondaryTeams}
      onUpdateProfileSuccess={this.props.onUpdateProfileSuccess}
      onUpdateTeamSuccess={this.props.onUpdateTeamSuccess}
    />
  );

  render() {
    const {
      badge,
      canManageRewards,
      image,
      isCurrentUser,
      isManager,
      hasTokenBalance,
      isFetching,
      name,
      team,
      tokens,
      locked,
    } = this.props;

    if (isFetching) {
      return renderLoadingPlaceholder();
    }

    return (
      <PersonCardSurface cursor="pointer" className="animated fadeIn">
        <PersonCard
          data-testid="PersonGridItem-PersonCard"
          align="center"
          justify="center"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {!isCurrentUser && this.renderActionButtons()}
          <ProfileImage
            onClick={this.handleClickCard}
            style={{
              backgroundImage: `url("${image}")`,
            }}
          />
          {badge && <ProfileBadge src={badge} />}
          {isManager && canManageRewards && hasTokenBalance && (
            <PersonTokenWidgetZone>
              <TokenAmountBadge count={tokens} onClick={this.handleClickCard} />
            </PersonTokenWidgetZone>
          )}
          {!isCurrentUser && !locked && (
            <PersonActionsDeadZone>
              <ArcCardActionButton onClick={this.handleClick} />
            </PersonActionsDeadZone>
          )}

          <PersonDetailsSection
            hover={this.state.isHover}
            cursor="pointer"
            onClick={this.handleClickCard}
          >
            <MainDetails hover={this.state.isHover}>
              <PersonName>
                {locked && <IconLockedStyle />}
                {name}
              </PersonName>
              <TeamName isSmall>{team}</TeamName>
            </MainDetails>
          </PersonDetailsSection>
        </PersonCard>
      </PersonCardSurface>
    );
  }
}

export default connect()(withTheme(PeopleGridItem));
