import React from 'react';
import PropTypes from 'prop-types';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import { ArcImage, ArcText, theme } from 'arcade-frontend-ui';
import { formatNumber } from 'arcade-frontend-core/src/helpers/numbers/numbers';

const tokenImageStyle = {
  width: '20px',
  height: '20px',
  paddingLeft: '2px',
};

function TokenAmountCell({ count }) {
  const tokenAmount = formatNumber(count);

  return (
    <ArcBox
      display="flex"
      flexDirection="row"
      alignItems="center"
      paddingY={0.5}
      paddingX={1}
      borderRadius={8}
    >
      <ArcImage src={theme.images.coin} alt="coin" style={tokenImageStyle} />
      <ArcBox
        component={ArcText}
        marginLeft={0.5}
        marginRight={1}
        fontWeight={'normal'}
        fontSize={14}
        lineHeight="150%"
      >
        {tokenAmount}
      </ArcBox>
    </ArcBox>
  );
}

TokenAmountCell.displayName = 'TokenAmountCell';

TokenAmountCell.propTypes = {
  count: PropTypes.number,
};

TokenAmountCell.defaultProps = {
  count: 0,
};

export default TokenAmountCell;
