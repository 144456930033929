import { types } from '../actions';

const handleAction = (state, action) => action.payload;

const initialState = false;

const isGeofenceRestricted = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_GEOFENCE_STATUS:
      return handleAction(state, action);
    default:
      return state;
  }
};

export const getIsGeofenceRestricted = state => state.app.isGeofenceRestricted;

export default isGeofenceRestricted;
