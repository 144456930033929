import {
  createRequestingStatusReducer,
} from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/games';

const initialState = {
  MANAGE_GAMES_INDEX: 'DEFAULT',
  MANAGE_GAMES_CREATE: 'DEFAULT',
  MANAGE_GAMES_UPDATE: 'DEFAULT',
  MANAGE_GAMES_VERIFY: 'DEFAULT',
  MANAGE_GAMES_REJECT: 'DEFAULT',
  MANAGE_GAMES_RESULTS_INDEX: 'DEFAULT',
};

const actions = [
  types.MANAGE_GAMES_INDEX,
  types.MANAGE_GAMES_CREATE,
  types.MANAGE_GAMES_UPDATE,
  types.MANAGE_GAMES_VERIFY,
  types.MANAGE_GAMES_REJECT,
  types.MANAGE_GAMES_RESULTS_INDEX,
];

const requestStatus = createRequestingStatusReducer(initialState, actions);

export default requestStatus;

export const getRequestStatus = state => state.manage.games.requestStatus;
