import { ArcStyles, date } from 'arcade-frontend-ui';

import { AVERAGE_METRIC } from 'arcade-frontend-core/src/types/metrics';

const getBenchmark = benchmark => {
  if (!benchmark) {
    return 'blank';
  }
  if (Object.keys(benchmark).length) {
    return 'daily';
  }

  return 'everyday';
};

const fromIndex = props => ({
  id: props.id.toString(),
  title: props.title,
  name: props.title,
  verb: props.verb,
  progress: props.progress,
  symbol: props.unit || '#',
  archived: !!props.archived,
  integrationIdentifier: props.company_identifier,
  type: props.type,
  isAverage: props.type === AVERAGE_METRIC,
  average: props.average,
  isCalculated: !!props.is_calculated || false,
});

const fromCreate = props => ({
  id: props.id.toString(),
  message: props.message,
});

const getTeamLetter = name => {
  if (name.toLowerCase().indexOf('the') === 0) return name[4] + name[5];
  if (name.toLowerCase().indexOf('team') === 0) return name[5] + name[6];
  return name[0] + name[1];
};

let teamColorIndex = 0;
const colors = ['blue', 'green', 'red', 'yellow'];
const teamColorPalette = [];

for (let idx = 1; idx < 5; idx += 1) {
  colors.forEach(color =>
    teamColorPalette.push(ArcStyles.theme[`${color}${idx}`]),
  );
}

const getTeamColor = () => {
  if (teamColorIndex >= teamColorPalette.length) teamColorIndex = 0;
  const color = teamColorPalette[teamColorIndex];
  teamColorIndex += 1;

  return color;
};

const fromTeamsIndex = props => ({
  id: props.id.toString(),
  name: props.name,
  letter: getTeamLetter(props.name),
  color: getTeamColor(),
  peopleIds: props.user_ids.map(id => id.toString()),
  peopleAmount: props.user_ids.length,
});

const fromRankingsIndex = props => ({
  personId: props.user_id.toString(),
  rank: props.rank && props.rank.toString(),
  name: props.fullname,
  score: typeof props.sum === 'number' && props.sum.toFixed(2),
  imageUrl: props.profile_image,
});

const fromActivitiesIndex = props => ({
  id: props.id ? props.id.toString() : null,
  name: props.kpi_title,
  person: props.user_name,
  kpiId: props.kpi_id ? props.kpi_id.toString() : null,
  createdAt: props.created_at,
  formattedCreatedAt: date.formatFullDateTime(props.created_at),
  quantity: typeof props.quantity === 'number' && props.quantity.toFixed(2),
  imageUrl: props.profile_image,
});

const fromShow = props => {
  const stepThree = {
    benchmark: getBenchmark(props.benchmark),
  };

  if (props.benchmark) {
    if (Object.keys(props.benchmark).length) {
      stepThree.dailyBenchmark = props.benchmark;
    } else {
      stepThree.totalBenchmark = props.benchmark.toString();
    }
  }

  return {
    id: props.id.toString(),
    name: props.title,
    unit: props.unit,
    symbol: props.unit,
    verb: props.verb,
    average: props.type === AVERAGE_METRIC,
    isAverage: props.type === AVERAGE_METRIC,

    canAddSales: props.allow_manual_add,
    integrationIdentifier: props.company_identifier,

    assign: props.all_teams_assigned ? 'everyone' : 'people',
    teamIds: props.assigned_teams
      .filter(id => id)
      .map(id => id.toString())
      .unique(),
    peopleIds: props.assigned_users
      .filter(id => id)
      .map(id => id.toString())
      .unique(),

    benchmark: props.benchmark,
    archived: !!props.archived,
    isCalculated: props.is_calculated,
    // ...stepThree,
  };
};

const fromArchive = props => ({
  id: props.id.toString(),
});

const getAssignType = form => {
  if (form.selectType === 'all') {
    return form.assignType === 'teams' ? 'all_teams' : 'everyone';
  }

  return form.assignType;
};

const getDailyTargets = form => {
  const targets = {};
  const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  days.forEach(day => {
    targets[day] = form[day];
  });

  return targets;
};

const getTargets = form => {
  switch (form.targetType) {
    case 'daily':
      return getDailyTargets(form);
    case 'everyday':
      return form.target;
    case 'none':
    default:
      return null;
  }
};

const toUpdate = form => ({
  kpi_group: {
    title: form.name,
    verb: form.verb,
    unit: form.unit,
    assign: getAssignType(form),
    teams: form.peopleSelector.teamIds.map(id => id.toString()).unique(),
    users: form.peopleSelector.peopleIds.map(id => id.toString()).unique(),
    benchmark: getTargets(form),
    allow_manual_add: form.canAddSales,
    company_identifier: form.integrationIdentifier,
    average: form.average,
  },
});

const normalizers = {
  fromIndex,
  fromCreate,
  fromTeamsIndex,
  fromShow,
  fromRankingsIndex,
  fromActivitiesIndex,
  fromArchive,
  toUpdate,
};

export default normalizers;
