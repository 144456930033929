import React from 'react';

import PropTypes from 'prop-types';

import { ArcView, createWithStyles } from 'arcade-frontend-ui';


const styles = {
  PortraitImage: theme => ({
    root: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(2),
      borderWidth: theme.spacing(0.25),
      borderStyle: 'solid',
      borderColor: theme.palette.common.white,
    },
  }),
};

const PortraitImage = createWithStyles(styles.PortraitImage)(ArcView);

const Portrait = ({ person }) => {
  const backgroundImage = `url(${person.image})`;
  return <PortraitImage style={{ backgroundImage }} />;
};
Portrait.propTypes = {
  person: PropTypes.shape({
    image: PropTypes.string.isRequired,
  }).isRequired,
};

export default Portrait;
