import moment from 'moment';

import { ArcPropTypes } from 'arcade-frontend-ui';

const activityTypes = [
  'AnnouncementActivity',
  'BadgeActivity',
  'CompetitionActivity',
  'GameAnnouncementActivity',
  'GameLeaderboardActivity',
  'HuddleActivity',
  'LeaderboardActivity',
  'PerformanceActivity',
  'QuestActivity',
  'QuizActivity',
  'RecognitionActivity',
  'RewardActivity',
  'SaleActivity',
];

export const summaryType = ArcPropTypes.oneOf(activityTypes);
export const additionalInformationType = ArcPropTypes.shape({
  id: ArcPropTypes.id,
});

export const summaryRecord = ArcPropTypes.shape({
  counts: ArcPropTypes.objectOf(ArcPropTypes.number).isRequired, // keys of activityTypes
  newest: ArcPropTypes.instanceOf(moment).isRequired,
  oldest: ArcPropTypes.instanceOf(moment).isRequired,
});

export const userRecord = ArcPropTypes.shape({
  username: ArcPropTypes.string.isRequired,
  userId: ArcPropTypes.string.isRequired,
  userImage: ArcPropTypes.string.isRequired,
  createdAt: ArcPropTypes.number.isRequired,
});

export const attachedFileRecord = ArcPropTypes.shape({
  link: ArcPropTypes.url.isRequired,
  name: ArcPropTypes.string.isRequired,
  type: ArcPropTypes.string.isRequired,
  date: ArcPropTypes.number.isRequired,
  user: ArcPropTypes.string.isRequired,
  size: ArcPropTypes.number.isRequired,
  icon: ArcPropTypes.string.isRequired,
});

export const pinnedRecord = ArcPropTypes.shape({
  pinned: ArcPropTypes.bool,
  pinnedByName: ArcPropTypes.string,
  pinnedByCurrentUser: ArcPropTypes.bool,
});

export const commentRecord = ArcPropTypes.shape({
  id: ArcPropTypes.id.isRequired,
  username: ArcPropTypes.string.isRequired,
  userId: ArcPropTypes.string.isRequired,
  userImage: ArcPropTypes.string.isRequired,
  content: ArcPropTypes.string.isRequired,
  date_data: ArcPropTypes.number.isRequired,
  readableDate: ArcPropTypes.string.isRequired,
  user_can_delete: ArcPropTypes.bool,
  user: ArcPropTypes.shape({
    id: ArcPropTypes.string.isRequired,
    image: ArcPropTypes.string.isRequired,
    badge: ArcPropTypes.string,
  }).isRequired,
});

export const activityUserRecord = ArcPropTypes.shape({
  id: ArcPropTypes.oneOfType([ArcPropTypes.string, ArcPropTypes.number])
    .isRequired,
  image: ArcPropTypes.url.isRequired,
  name: ArcPropTypes.string.isRequired,
});

export const activityRecord = ArcPropTypes.shape({
  id: ArcPropTypes.id,
  user: activityUserRecord,
  type: summaryType,
  content: ArcPropTypes.string,
  userRespected: false,
  respectCount: ArcPropTypes.number,
  comments: ArcPropTypes.arrayOf(commentRecord),
  image: ArcPropTypes.url,
  image_download: ArcPropTypes.url,
  currentUserCreated: ArcPropTypes.bool,
  userCreated: ArcPropTypes.bool,
  additionalInformation: additionalInformationType,
  edited: ArcPropTypes.bool,
  file: attachedFileRecord,
  viewsCount: ArcPropTypes.number,
  interestingAt: ArcPropTypes.instanceOf(moment),
  pinnedInformation: pinnedRecord,
});

export const activityElement = ArcPropTypes.shape({
  date: ArcPropTypes.instanceOf(moment).isRequired,
  type: ArcPropTypes.oneOf(['activity', 'summary']).isRequired,
  content: ArcPropTypes.oneOfType([summaryRecord, activityRecord]).isRequired,
});
