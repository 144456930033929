import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  ArcButton,
  ArcCheckbox,
  ArcText,
  ArcView,
  createWithStyles,
  ArcEntityAvatar,
  ArcResponsiveDialog,
} from 'arcade-frontend-ui';

import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';

const renderLoadingPlaceholder = () => [0, 1].map(index => (
  <PlaceholderGroupWrapper key={index}>
    <SkeletonWrapper key={`header-${index}`}>
      <Skeleton
        variant="rect"
        height={18}
        width="50%"
      />
    </SkeletonWrapper>

    {[0, 1, 2].map(rowIndex => (
      <SkeletonWrapper key={`rows-${rowIndex}`}>
        <Skeleton
          variant="rect"
          height={20}
          width="75%"
        />
      </SkeletonWrapper>
    ))}

  </PlaceholderGroupWrapper>
));

const paperProps = {
  style: {
    margin: 16,
    width: '100%',
    maxWidth: 380,
  },
};

const styles = {
  SkeletonWrapper: theme => ({
    root: {
      margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    },
  }),

  PlaceholderGroupWrapper: theme => ({
    root: {
      marginTop: theme.spacing(3),
    },
  }),

  PermissionGroupNameText: theme => ({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      color: '#616264',
      fontSize: theme.font.getFontSize(0.9),
      fontWeight: 600,
      lineHeight: '150%',
    },
  }),

  PermissionsListWrapper: () => ({
    root: {
      padding: '8px 24px',
    },
  }),

  PersonNameText: theme => ({
    root: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: theme.font.getFontSize(1.125),
      lineHeight: '150%',
    },
  }),

  EntityAvatarWrapper: theme => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 0,
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      borderBottom: '1px solid #EBEBEB',
    },
  }),

  NoResultWrapper: theme => ({
    root: {
      padding: theme.spacing(4),
      alignItems: 'center',
    },
  }),
  NoResultText: theme => ({
    root: {
      lineHeight: '150%',
      textAlign: 'center',
      color: theme.palette.grey[500],
      fontWeight: 600,
    },
  }),
};

const PlaceholderGroupWrapper = createWithStyles(styles.PlaceholderGroupWrapper)(ArcView);
const SkeletonWrapper = createWithStyles(styles.SkeletonWrapper)(ArcView);
const PermissionGroupNameText = createWithStyles(styles.PermissionGroupNameText)(ArcText);
const PersonNameText = createWithStyles(styles.PersonNameText)(ArcText);
const EntityAvatarWrapper = createWithStyles(styles.EntityAvatarWrapper)(ArcView);
const PermissionsListWrapper = createWithStyles(styles.PermissionsListWrapper)(ArcView);
const NoResultWrapper = createWithStyles(styles.NoResultWrapper)(ArcView);
const NoResultText = createWithStyles(styles.NoResultText)(ArcText);

class ManagePeoplePermissionsModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onRetry: PropTypes.func,
    permissions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        identifier: PropTypes.string,
        enabled: PropTypes.bool,
      })),
    person: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      display: PropTypes.string,
      teamName: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
    getPermissionsRequestStatus: PropTypes.shape({
      failed: PropTypes.bool,
      idle: PropTypes.bool,
      pending: PropTypes.bool,
      succeeded: PropTypes.bool,
    }),
    getPermissionRequestHasError: PropTypes.bool,
    updatePermissionRequestStatus: PropTypes.shape({
      failed: PropTypes.bool,
      idle: PropTypes.bool,
      pending: PropTypes.bool,
      succeeded: PropTypes.bool,
    }),

  };

  static defaultProps = {
    onClose: global.noop,
    onSubmit: global.noop,
    onRetry: global.noop,
    open: false,
    permissions: {},
    person: {},
    getPermissionsRequestStatus: {},
    getPermissionRequestHasError: false,
    updatePermissionRequestStatus: {},
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedPermissions: this.props.permissions,
      updatedPermissions: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    const permissionsChanged = this.props.permissions !== nextProps.permissions;

    if (permissionsChanged) {
      this.setState({ selectedPermissions: nextProps.permissions });
    }
  }

  handleSubmit = () => {
    this.props.onSubmit(this.props.person.id, this.state.updatedPermissions);
  }

  handleChangeCheckbox = (e, selectedPermission) => {
    const newPermissionsList = { ...this.state.selectedPermissions };

    const updatedPermission = {
      ...selectedPermission,
      enabled: !selectedPermission.enabled,
    };

    Object.values(newPermissionsList).forEach((permission, index) => {
      const [groupName, permissionItems] = Object.entries(permission)[0];
      permissionItems.forEach((permissionItem, itemIndex) => {
        if (permissionItem.identifier === selectedPermission.identifier) {
          newPermissionsList[index][groupName][itemIndex] = updatedPermission;
        }
      });
    });

    this.setState({
      selectedPermissions: newPermissionsList,
      updatedPermissions: {
        ...this.state.updatedPermissions,
        [updatedPermission.identifier]: updatedPermission.enabled,
      },
    });
  }

  renderRetryButton = () => (
    <NoResultWrapper>
      <NoResultText>
        {'Could not load permissions'}
      </NoResultText>
      <ArcResourceButton
        style={{ maxWidth: 100 }}
        label="Retry"
        variant="text"
        color="primary"
        status={this.props.getPermissionsRequestStatus}
        onClick={this.props.onRetry}
      />
    </NoResultWrapper>
  )

  renderPermissionsChecklist = permissionGroupItems => permissionGroupItems
    .map(permission => (
      <ArcCheckbox
        key={permission.identifier}
        label={permission.label}
        checked={permission.enabled}
        onChange={e => this.handleChangeCheckbox(e, permission)}
      />
    ));


  renderPermissions() {
    if (!this.state.selectedPermissions) {
      return null;
    }

    return Object.values(this.state.selectedPermissions).map((permission) => {
      const [permissionGroupName, permissionGroupItems] = Object.entries(permission)[0];

      return (
        <PermissionsListWrapper key={permissionGroupName}>

          <PermissionGroupNameText>
            {permissionGroupName}
          </PermissionGroupNameText>

          {this.renderPermissionsChecklist(permissionGroupItems)}
        </PermissionsListWrapper>
      );
    });
  }

  renderProfile() {
    const {
      person,
    } = this.props;

    return (
      <EntityAvatarWrapper>
        <ArcEntityAvatar
          imageUrl={person.imageUrl}
          type="person"
        />
        <ArcView>
          <PersonNameText>
            {person.display}
          </PersonNameText>
        </ArcView>
      </EntityAvatarWrapper>
    );
  }

  render() {
    const {
      getPermissionRequestHasError,
      getPermissionsRequestStatus,
      updatePermissionRequestStatus,
    } = this.props;
    return (
      <ArcResponsiveDialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
        PaperProps={paperProps}
      >
        <DialogTitle>
          <ArcText isStrong>Edit Permissions</ArcText>
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <ArcView>
            {this.props.person ? this.renderProfile() : 'Nothing to see here'}

            {!getPermissionRequestHasError && getPermissionsRequestStatus.pending
              ? renderLoadingPlaceholder() : this.renderPermissions()}

            {getPermissionRequestHasError && this.renderRetryButton()}

          </ArcView>
        </DialogContent>
        <DialogActions style={{ padding: 22 }}>
          <ArcButton
            onClick={this.props.onClose}
          >
            {'Close'}
          </ArcButton>

          <ArcResourceButton
            type="submit"
            label="Save"
            color="primary"
            variant="contained"
            status={updatePermissionRequestStatus}
            onClick={this.handleSubmit}
            disabled={updatePermissionRequestStatus.pending || getPermissionsRequestStatus.pending}
          />

        </DialogActions>
      </ArcResponsiveDialog>
    );
  }
}

export default ManagePeoplePermissionsModal;
