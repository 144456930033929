import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../../actions/manage/metrics/types';

const initialState = false;

const handlers = {
  [types.MANAGE_METRICS_INDEX.SUCCESS]: () => true,
};

const hasLoaded = createReducer(initialState, handlers);

export default hasLoaded;

export const getHasLoaded = state => state.manage.metrics.hasLoaded;
