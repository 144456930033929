import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { makeStyles } from '@material-ui/core';

import { ArcText, Dialog } from 'arcade-frontend-ui';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

function GamesCompletionDialog({
  open,
  pendingSalesCount,
  onClose,
  onComplete,
  onVerifyAll,
  completeStatus,
  verifyAllStatus,
  hasError,
}) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <ArcBox display="flex" flexDirection="column" textAlign="center" p={4}>
        <ArcBox
          component={ArcText}
          fontWeight={600}
          fontSize={20}
          lineHeight="150%"
          mb={0.5}
        >
          {'Are you sure?'}
        </ArcBox>
        {!pendingSalesCount || pendingSalesCount <= 0 ? (
          <ArcBox
            component={ArcText}
            fontWeight={400}
            fontSize={16}
            color="#616264"
            lineHeight="150%"
            maxWidth={300}
          >
            {
              'By completing this game early you will prevent people from adding additional scores.'
            }
          </ArcBox>
        ) : (
          <>
            <ArcBox
              component={ArcText}
              fontWeight={400}
              fontSize={16}
              color="#616264"
              lineHeight="150%"
              maxWidth={300}
            >
              {'You still have'}
              <ArcText marginLeft="4" marginRight="4" isStrong>
                {`${pendingSalesCount} pending`}
              </ArcText>
              {pluralize('event', pendingSalesCount)}
            </ArcBox>
            <ArcBox
              component={ArcText}
              fontWeight={400}
              fontSize={16}
              color="#616264"
              lineHeight="150%"
              maxWidth={275}
            >
              <ArcText>
                {
                  'Pending events will be rejected and those users will not be rewarded.'
                }
              </ArcText>
            </ArcBox>
          </>
        )}

        <ArcBox mt={2} mb={1}>
          <ArcResourceButton
            variant="contained"
            color="primary"
            label="Complete Anyway"
            className={classes.fullWidth}
            onClick={onComplete}
            status={completeStatus}
          />
        </ArcBox>

        {pendingSalesCount > 0 && (
          <ArcBox my={1}>
            <ArcResourceButton
              variant="outlined"
              color="primary"
              label="Verify and Complete"
              className={classes.fullWidth}
              onClick={onVerifyAll}
              status={verifyAllStatus}
            />
          </ArcBox>
        )}

        <ArcBox my={1}>
          <ArcButton
            label="Cancel"
            color="default"
            onClick={onClose}
            className={classes.fullWidth}
          />
        </ArcBox>
        {hasError && (
          <ArcBox
            component={ArcText}
            fontWeight={400}
            fontSize={11}
            className="animated fadeIn"
            color="rgb(243, 96, 79)"
            lineHeight="150%"
          >
            {'Could not verify right now - please try again'}
          </ArcBox>
        )}
      </ArcBox>
    </Dialog>
  );
}

GamesCompletionDialog.displayName = 'GamesCompletionDialog';

GamesCompletionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  onVerifyAll: PropTypes.func,
  completeStatus: PropTypes.shape({
    failed: PropTypes.bool,
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    succeeded: PropTypes.bool,
  }),
  verifyAllStatus: PropTypes.shape({
    failed: PropTypes.bool,
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    succeeded: PropTypes.bool,
  }),
  pendingSalesCount: PropTypes.number,
  hasError: PropTypes.bool,
};

GamesCompletionDialog.defaultProps = {
  game: {},
  onClose: global.noop,
  onComplete: global.noop,
  onVerifyAll: global.noop,
  open: false,
  completeStatus: {},
  verifyAllStatus: {},
  pendingSalesCount: 0,
  hasError: false,
};

export default GamesCompletionDialog;
