import { REQUEST, SUCCESS, FAILURE, RESET } from './actions';

/* eslint-disable */
export const createReducer = (initialState, handlers) => (
  state = initialState,
  action,
) =>
  action && handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action)
    : state;

export const createSharedReducer = (initialState, handlers) => (
  state = initialState,
  action,
  sharedState,
) =>
  action && handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action, sharedState)
    : state;

const setTrue = () => true;
const setFalse = () => false;

export const createIsFetchingReducer = (initialState, apiActionTypes) => {
  const handlers = {};

  apiActionTypes.forEach(type => {
    handlers[type[REQUEST]] = setTrue;
    handlers[type[SUCCESS]] = setFalse;
    handlers[type[FAILURE]] = setFalse;
  });

  return createReducer(initialState, handlers);
};

const getIdFromPayload = payload => {
  if (payload.id) {
    return payload.id;
  }

  if (payload.data && payload.data.id) {
    return payload.data.id;
  }

  return payload;
};

const setSingleTrue = (state, action) => {
  const id = getIdFromPayload(action.payload);
  return { ...state, [id]: true };
};

const setSingleFalse = (state, action) => {
  const id = getIdFromPayload(action.payload);
  return { ...state, [id]: false };
};

export const createIsRequestingReducer = (initialState, apiActionTypes) => {
  const handlers = {};

  apiActionTypes.forEach(type => {
    handlers[type[REQUEST]] = setSingleTrue;
    handlers[type[SUCCESS]] = setSingleFalse;
    handlers[type[FAILURE]] = setSingleFalse;
  });

  return createReducer(initialState, handlers);
};

const setRequestingStatus = (key, status) => (state, action) => {
  return { ...state, [key]: status };
};

export const createRequestingStatusReducer = (initialState, apiActionTypes) => {
  const handlers = {};

  apiActionTypes.forEach(type => {
    if (!type.key) {
      console.warn(
        'We are missing a key when attemping to create requesting status reducer',
      );
    }

    handlers[type[REQUEST]] = setRequestingStatus(type.key, 'REQUEST');
    handlers[type[SUCCESS]] = setRequestingStatus(type.key, 'SUCCESS');
    handlers[type[FAILURE]] = setRequestingStatus(type.key, 'FAILURE');
    handlers[type[RESET]] = setRequestingStatus(type.key, 'DEFAULT');
  });

  return createReducer(initialState, handlers);
};

export function getStateById(data) {
  const state = {};

  data.forEach(item => {
    state[item.id] = item;
  });

  return state;
}
