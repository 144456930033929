import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';

import { getLocationQuery } from 'arcade-frontend-features/src/reducers/location';
import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import usePermission from 'arcade-frontend-core/src/hooks/usePermission';
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcIconButton from 'arcade-frontend-ui/src/elements/ArcIconButton';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcMain from 'arcade-frontend-ui/src/layout/ArcMain';
import ArcMainView from 'arcade-frontend-ui/src/layout/ArcMainView';
import ArcScroll from 'arcade-frontend-ui/src/layout/ArcScroll';
import ArcTab from 'arcade-frontend-ui/src/components/ArcTab';
import ArcTabs from 'arcade-frontend-ui/src/components/ArcTabs';

import { resources } from '../../resources/games';
import GamesActiveTabPanel from '../../components/GamesActiveTabPanel';
import GamesUpcomingTabPanel from '../../components/GamesUpcomingTabPanel';
import GamesCompletedTabPanel from '../../components/GamesCompletedTabPanel';
import GamesRefreshButton from '../../components/GamesRefreshButton/GamesRefreshButton';
import GamesShowContainer from '../../containers/GamesShowContainer';
import GamesNewContainer from '../../containers/GamesNewContainer';
import GamesLogActionContainer from '../../containers/GamesLogActionContainer';
import GamesCompletionContainer from '../../containers/GamesCompletionContainer';
import GamesVerificationContainer from '../../containers/GamesVerificationContainer/GamesVerificationContainer';

function GamesView(props) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isTiny = useMediaQuery('(max-width:420px)');
  const canManageGames = usePermission(PERMISSIONS.MANAGE_GAMES);
  const hasGameCenterCreate = useFeatureFlag(FEATURE_FLAGS.GAME_CENTER_CREATE);
  let locationQuery = useSelector(getLocationQuery);

  const apiGetActiveGamesIndex = useResourceAction(
    resources.apiGetActiveGamesIndex,
  );
  const apiGetUpcomingGamesIndex = useResourceAction(
    resources.apiGetUpcomingGamesIndex,
  );
  const apiGetCompletedGamesIndex = useResourceAction(
    resources.apiGetCompletedGamesIndex,
  );
  const apiGetPendingGamesIndex = useResourceAction(
    resources.apiGetPendingGamesIndex,
  );

  const requestCompletedPendingGames = () => {
    apiGetCompletedGamesIndex.requestData().catch(global.noop);
    apiGetPendingGamesIndex.requestData().catch(global.noop);
  };

  if (props.locationQuery) {
    ({ locationQuery } = props);
  }

  const activeTab = locationQuery.list || 'active';

  const handleRefreshAction = () => {
    switch (activeTab) {
      case 'active':
        return apiGetActiveGamesIndex.requestData().catch(global.noop);
      case 'upcoming':
        return apiGetUpcomingGamesIndex.requestData().catch(global.noop);
      case 'completed':
        return requestCompletedPendingGames();
      default:
        return null;
    }
  };

  const setActiveTab = tab =>
    dispatch({
      type: ROUTE_TYPES.ROUTE_GAMES,
      query: {
        list: tab,
      },
    });

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleCardClick(id) {
    const routeAction = {
      type: ROUTE_TYPES.ROUTE_GAMES_SHOW,
      payload: {
        id,
      },
      query: locationQuery,
    };

    dispatch(routeAction);
  }

  function handleGamesNavigationMenuClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleGamesNavigationMenuClose() {
    setAnchorEl(null);
  }

  function handleCompletedClick() {
    setAnchorEl(null);
    setActiveTab('completed');
  }

  function handleManageGamesClick() {
    setAnchorEl(null);
    dispatch({
      type: ROUTE_TYPES.ROUTE_MANAGE_GAMES,
    });
  }

  function handleNewGameClick() {
    setAnchorEl(null);
    dispatch({
      type: ROUTE_TYPES.ROUTE_GAMES_CREATE,
      query: locationQuery,
    });
  }

  function renderActiveTab() {
    switch (activeTab) {
      case 'active':
        return <GamesActiveTabPanel onCardClick={handleCardClick} />;
      case 'upcoming':
        return <GamesUpcomingTabPanel onCardClick={handleCardClick} />;
      case 'completed':
        return <GamesCompletedTabPanel onCardClick={handleCardClick} />;
      default:
        return null;
    }
  }

  function renderGamesDesktopButtons() {
    return canManageGames ? (
      <>
        {hasGameCenterCreate && (
          <ArcButton
            color={'primary'}
            variant={'outlined'}
            size="small"
            label={'Create New Game'}
            onClick={handleNewGameClick}
          >
            <AddIcon fontSize="small" /> New Game
          </ArcButton>
        )}
        <ArcBox display="flex" marginLeft={1} height="38px">
          <ArcButton
            color="primary"
            variant="outlined"
            size="small"
            label="Manage Games"
            onClick={handleManageGamesClick}
          />
        </ArcBox>
      </>
    ) : null;
  }

  return (
    <ArcMain>
      <ArcMainView isViewing hasNav={false}>
        <ArcScroll>
          <ArcBox>
            <ArcBox
              bgcolor="background.paper"
              boxShadow={1}
              display="flex"
              flexDirection="row"
              alignItems="center"
              pl={4}
              pr={3}
              py={2}
              m={[0, 3]}
              mb={[1, 3]}
              borderRadius={[0, 4]}
            >
              <ArcTabs
                value={activeTab}
                onChange={tab => setActiveTab(tab)}
                alignItems="center"
              >
                <ArcTab value="active" label="Active" />
                <ArcTab value="upcoming" label="Upcoming" />
                {!isTiny && <ArcTab value="completed" label="Completed" />}
              </ArcTabs>

              {!isTiny && (
                <GamesRefreshButton
                  variant="button"
                  onRefresh={handleRefreshAction}
                />
              )}

              <ArcBox
                display="flex"
                flexDirection="row"
                flexGrow={1}
                alignItems="center"
                justifyContent="flex-end"
              >
                {isDesktop ? (
                  renderGamesDesktopButtons()
                ) : (
                  <>
                    <ArcIconButton
                      aria-label="More Games Navigation"
                      aria-controls="GamesViewNavigationMenu"
                      aria-haspopup="true"
                      onClick={handleGamesNavigationMenuClick}
                    >
                      <MoreVert color="disabled" fontSize="small" />
                    </ArcIconButton>

                    <Menu
                      id="GamesViewNavigationMenu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleGamesNavigationMenuClose}
                    >
                      {isTiny && (
                        <MenuItem onClick={handleCompletedClick}>
                          <ArcBox
                            color={
                              activeTab === 'completed'
                                ? 'primary.main'
                                : undefined
                            }
                          >
                            {'Completed'}
                          </ArcBox>
                        </MenuItem>
                      )}

                      {isTiny && (
                        <GamesRefreshButton
                          variant="menuItem"
                          onRefresh={handleRefreshAction}
                        />
                      )}
                      {canManageGames && hasGameCenterCreate && (
                        <MenuItem onClick={handleNewGameClick}>
                          {'Create New Game'}
                        </MenuItem>
                      )}
                      {canManageGames && (
                        <MenuItem onClick={handleManageGamesClick}>
                          {'Manage Games'}
                        </MenuItem>
                      )}
                    </Menu>
                  </>
                )}
              </ArcBox>
            </ArcBox>

            <ArcBox mx={[1, 3]}>{renderActiveTab()}</ArcBox>
          </ArcBox>

          <GamesNewContainer />
          <GamesShowContainer />
          <GamesCompletionContainer />
          <GamesLogActionContainer />
          <GamesVerificationContainer />
        </ArcScroll>
      </ArcMainView>
    </ArcMain>
  );
}

GamesView.propTypes = {
  locationQuery: PropTypes.shape({ list: PropTypes.string }),
};

GamesView.defaultProps = {
  locationQuery: null,
};

export default GamesView;
