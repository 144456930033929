import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// import { useTheme } from '@material-ui/styles';

import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';

import ArcButton from '../../elements/ArcButton';


const STRINGS = {
  'UI/RESOURCE_SUCCESS_DIALOG_TITLE': 'Success',
  'UI/RESOURCE_SUCCESS_DIALOG_OK': 'Ok',
};

const iconStyle = {
  color: '#00A77C',
};

const paperProps = {
  style: {
    // minHeight: 338,
    maxWidth: 338,
    width: '100%',
  },
};

function ArcResourceSuccessDialog({
  actionChildren,
  children,
  onClose,
  open,
}) {
  // const theme = useTheme();


  return (

    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="arc-resource-success-dialog-title"
      aria-describedby="arc-resource-success-dialog-description"
      PaperProps={paperProps}
    >
      <DialogTitle id="arc-resource-success-dialog-title">
        <Box
          display="flex"
          alignItems="center"
        >
          <Box
            alignSelf="center"
            display="flex"
            mr={1}
          >
            <CheckCircleOutlined style={iconStyle} />
          </Box>
          {STRINGS['UI/RESOURCE_SUCCESS_DIALOG_TITLE']}
        </Box>
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        {actionChildren}
        <ArcButton
          autoFocus
          onClick={onClose}
          variant="outlined"
          label={STRINGS['UI/RESOURCE_SUCCESS_DIALOG_OK']}
        />
      </DialogActions>
    </Dialog>
  );
}

ArcResourceSuccessDialog.displayName = 'ArcResourceSuccessDialog';

ArcResourceSuccessDialog.propTypes = {
  actionChildren: PropTypes.node,
  children: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

ArcResourceSuccessDialog.defaultProps = {
  actionChildren: null,
  children: null,
  onClose: global.noop,
  open: false,
};

export default ArcResourceSuccessDialog;
