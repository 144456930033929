import React from 'react';
import PropTypes from 'prop-types';

import { react2angular } from 'react2angular';

import * as ICONS_BY_NAME from 'arcade-frontend-ui/src/icons';
import { createProvidedContainer } from './helpers';

function ArcadeReactIcon({ name, ...props }) {
  const getIcon = ICONS_BY_NAME[name];

  if (!getIcon) {
    return null;
  }

  const Icon = getIcon();

  return Icon ? <Icon {...props} /> : null;
}

ArcadeReactIcon.displayName = 'ArcadeReactIcon';

ArcadeReactIcon.propTypes = {
  name: PropTypes.oneOf(Object.keys(ICONS_BY_NAME)),
};

ArcadeReactIcon.defaultProps = {
  name: '',
};

const ProvidedArcadeReactIcon = createProvidedContainer(ArcadeReactIcon);

export default react2angular(ProvidedArcadeReactIcon, [
  'color',
  'name',
  'style',
]);
