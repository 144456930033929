import React from 'react';
import Clear from '@material-ui/icons/Clear';
import ArtTrack from '@material-ui/icons/ArtTrack';
import Edit from '@material-ui/icons/Edit';
import Style from '@material-ui/icons/Style';

import {
  ArcButton,
  ArcIconButton,
  ArcPropTypes,
  ArcLoader,
  ArcScroll,
  ArcSubheading,
  ArcText,
  ArcView,
  createWithStyles,
  forms,
} from 'arcade-frontend-ui';

import { GiftCard, Reward } from '../components/RewardItem';

const model = 'rewards.create';

const initialState = {
  id: null,
  cost: '',
  image: '',
  name: '',
  description: '',
  hasVariant: false,
  variantLabel: 'Color',
  variants: [{ label: 'Red', value: 10000 }],
};

export const create = forms.createFormReducer(model, initialState);

const selector = state => state.newForms.rewards.create;

const fields = {
  cost: {
    type: 'number',
    label: 'Cost',
  },

  name: {
    type: 'text',
    label: 'Name',
    validations: {
      isRequired: true,
    },
  },

  description: {
    type: 'text',
    label: 'Description',
    multiline: true,
  },

  hasVariant: {
    type: 'checkbox',
    label: 'This reward has variants',
  },

  image: {
    type: 'image',
    label: 'Image',
    showPreview: true,
    multiple: true,
  },

  variantLabel: {
    type: 'text',
    label: 'Variant Name',
    placeholder: 'Color, denomination, size...',
  },

  variants: {
    type: 'multiAdd',
    label: 'Variants',
    maxRows: 5,
  },
};

const formProps = {
  fields,
  selector,
};

const SCALE_IN_TIMEOUT = 150;
const stopPropagation = e => e.stopPropagation();

const styleContainerIn = {
  zIndex: 1000,
  position: 'absolute',
  backgroundColor: 'rgba(0,0,0,0.87)',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',

  opacity: 1,
  transition: `opacity ease-in ${SCALE_IN_TIMEOUT}ms 0ms`,
};

const styleContainerOut = {
  ...styleContainerIn,

  opacity: 0,
  transition: `opacity ease-out ${SCALE_IN_TIMEOUT}ms 0ms`,
};

const styleHeading = {
  marginTop: 24,
  marginLeft: 16,
  marginRight: 16,
  marginBottom: 16,
};

const styleMarginBottom = {
  marginBottom: 16,
};

const styleTitleIcon = {
  marginRight: 16,
};

const styles = {
  FormActions: theme => ({
    root: {
      padding: theme.spacing(2),
    },
  }),
  FormContainer: theme => ({
    root: {
      position: 'relative',
      flexShrink: 1,
      backgroundColor: theme.palette.background.paper,
      margin: 8,
      borderColor: theme.palette.divider,
      borderStyle: 'solid',
      borderWidth: 4,

      minWidth: 320,
      height: '100%',

      [theme.breakpoints.up('sm')]: {
        width: 400,
        height: 'auto',
        maxHeight: 'calc(100% - 64px)',
        margin: '32px auto',
      },
    },
  }),

  FormContent: theme => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
};

const FormActions = createWithStyles(styles.FormActions)(ArcView);
const FormContainer = createWithStyles(styles.FormContainer)(ArcView);
const FormContent = createWithStyles(styles.FormContent)(ArcView);

const noop = () => {};

const styleButtonArchive = {
  marginRight: 16,
};

class FormRewardCreate extends React.PureComponent {
  static propTypes = {
    currentReward: ArcPropTypes.shape({
      archived: ArcPropTypes.bool,
      id: ArcPropTypes.string,
      imageUrl: ArcPropTypes.string,
      variants: ArcPropTypes.arrayOf(
        ArcPropTypes.shape({
          label: ArcPropTypes.string.isRequired,
          value: ArcPropTypes.number.isRequired,
        }),
      ),
    }),
    id: ArcPropTypes.string,
    isCreating: ArcPropTypes.bool,
    isEditable: ArcPropTypes.bool,
    isEditing: ArcPropTypes.bool,
    isFetching: ArcPropTypes.bool,
    renderFields: ArcPropTypes.objectOf(ArcPropTypes.func).isRequired,
    setFieldError: ArcPropTypes.func,
    onArchive: ArcPropTypes.func,
    onCancel: ArcPropTypes.func,
    onReset: ArcPropTypes.func,
    onRestore: ArcPropTypes.func,
    onSubmit: ArcPropTypes.func,
    onSubmitAndRestore: ArcPropTypes.func,
    values: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
  };

  static defaultProps = {
    currentReward: {},
    id: undefined,
    isCreating: true,
    isEditable: false,
    isEditing: false,
    isFetching: false,
    setFieldError: global.noop,
    onArchive: global.noop,
    onCancel: global.noop,
    onReset: global.noop,
    onRestore: global.noop,
    onSubmit: global.noop,
    onSubmitAndRestore: global.noop,
  };

  state = {
    isReady: false,
    imageUrl: this.props.currentReward.imageUrl,
  };

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyup);

    setTimeout(() => {
      this.setIsReady(true);
    }, SCALE_IN_TIMEOUT);
  }

  componentWillUnmount() {
    this.props.onReset();
    document.removeEventListener('keyup', this.handleKeyup);
  }

  get amount() {
    const { amount } = this.props.values;
    return amount ? parseFloat(amount, 10) : undefined;
  }

  get heading() {
    const { currentReward } = this.props;

    if (this.props.isEditable) {
      if (currentReward && currentReward.archived) {
        return 'Edit Archived Reward';
      }

      return currentReward && currentReward.id
        ? 'Edit Reward'
        : 'Create New Reward';
    }

    return 'Preview Reward';
  }

  get submitLabel() {
    if (this.props.values.id) {
      return 'Save';
    }

    return 'Submit';
  }

  get name() {
    return this.props.values.name;
  }

  get description() {
    return this.props.values.description;
  }

  get variants() {
    return this.props.values.variants || [];
  }

  setIsReady = isReady => this.setState({ isReady });

  setImageUrl = imageUrl => this.setState({ imageUrl });

  handleImageSelected = image => this.setImageUrl(image.imagePreviewUrl);

  handleCancel = () => {
    this.setIsReady(false);
    setTimeout(this.props.onCancel, SCALE_IN_TIMEOUT);
  };

  handleChangeHasVariant = e => {
    if (e.target.checked) {
      this.props.setFieldError('cost', []);
    } else {
      this.setState({ hasErrors: false });
    }
  };

  handleErrorVariants = errors => {
    this.setState({ hasErrors: !!errors.length });
  };

  handleKeyup = e => {
    if (e.which === 27) {
      this.handleCancel();
    }
  };

  handleSubmitAndRestore = e => {
    this.props.onSubmitAndRestore(e, this.props.values);
  };

  renderFields() {
    const { hasVariant } = this.props.values;

    return (
      <FormContent>
        <ArcView>
          {this.props.renderFields.name()}
          {this.props.renderFields.description()}
          {this.props.renderFields.image({
            onImageSelected: this.handleImageSelected,
          })}
          {this.props.renderFields.cost({
            disabled: !!hasVariant,
            label: hasVariant ? 'Cost not applicable' : 'Cost',
            validations: {
              isRequired: !hasVariant,
            },
          })}
          {this.props.renderFields.hasVariant({
            onChange: this.handleChangeHasVariant,
          })}
        </ArcView>
      </FormContent>
    );
  }

  renderPreview() {
    const { cost, hasVariant } = this.props.values;
    const RewardPreviewCard = hasVariant ? GiftCard : Reward;

    return (
      <ArcView>
        <ArcSubheading style={styleHeading}>
          <ArcView row align="center">
            <ArtTrack color="action" fontSize="large" style={styleTitleIcon} />
            <ArcText>Preview</ArcText>
          </ArcView>
        </ArcSubheading>

        <FormContent>
          <RewardPreviewCard
            {...this.props.currentReward}
            id="-1"
            variants={this.variants}
            name={this.name}
            description={this.description}
            imageUrl={this.state.imageUrl || this.props.values.image}
            rewardTokenCost={cost ? parseInt(cost, 10) : 0}
            onClickPurchase={noop}
            userBalance={100000}
            variantLabel={this.props.values.variantLabel || undefined}
          />
        </FormContent>
      </ArcView>
    );
  }

  renderSelected() {
    const { variants } = this.props.currentReward;
    const RewardPreviewCard = variants && variants.length ? GiftCard : Reward;

    return (
      <FormContent>
        <RewardPreviewCard
          {...this.props.currentReward}
          imageUrl={this.state.imageUrl || this.props.values.image}
          onClickPurchase={noop}
          userBalance={100000}
        />
      </FormContent>
    );
  }

  renderVariants() {
    return (
      <ArcView>
        <ArcSubheading style={styleHeading}>
          <ArcView row align="center">
            <Style color="action" fontSize="large" style={styleTitleIcon} />
            <ArcText>Variants</ArcText>
          </ArcView>
        </ArcSubheading>

        <FormContent>
          {this.props.renderFields.variantLabel({
            validations: {
              isRequired: this.props.values.hasVariant,
            },
          })}
          {this.props.renderFields.variants({
            onError: this.handleErrorVariants,
          })}
        </FormContent>
      </ArcView>
    );
  }

  renderActions() {
    if (this.props.isEditable) {
      return (
        <ArcView>
          <ArcButton
            disabled={this.state.hasErrors}
            variant="contained"
            color="secondary"
            label={this.submitLabel}
            onClick={this.props.onSubmit}
            style={styleMarginBottom}
          />
          {this.props.currentReward && this.props.currentReward.archived && (
            <ArcButton
              variant="contained"
              color="primary"
              label="Save and Restore"
              onClick={this.handleSubmitAndRestore}
              style={styleMarginBottom}
            />
          )}
          <ArcButton label="Cancel" onClick={this.handleCancel} />
        </ArcView>
      );
    }

    const action = this.props.currentReward.archived
      ? this.props.onRestore
      : this.props.onArchive;
    const handleClick = e => action(e, this.props.currentReward);

    return (
      <ArcView row justify="flex-end">
        <ArcButton
          label={this.props.currentReward.archived ? 'Restore' : 'Archive'}
          onClick={handleClick}
          style={styleButtonArchive}
        />
        <ArcButton
          color="primary"
          label="Ok"
          onClick={this.handleCancel}
          variant="contained"
        />
      </ArcView>
    );
  }

  renderForm() {
    const { isEditable } = this.props;

    return (
      <FormContainer onClick={stopPropagation}>
        <ArcView row justify="space-between" align="stretch">
          <ArcSubheading style={styleHeading}>
            <ArcView row align="center">
              {isEditable ? (
                <Edit color="action" fontSize="large" style={styleTitleIcon} />
              ) : (
                <ArtTrack
                  color="action"
                  fontSize="large"
                  style={styleTitleIcon}
                />
              )}
              <ArcText>{this.heading}</ArcText>
            </ArcView>
          </ArcSubheading>
          <ArcIconButton onClick={this.handleCancel}>
            <Clear />
          </ArcIconButton>
        </ArcView>

        <ArcScroll display="flex">
          {isEditable && this.renderFields()}
          {isEditable && this.props.values.hasVariant && this.renderVariants()}

          {isEditable && <ArcView spacer />}

          {isEditable && this.renderPreview()}
          {!isEditable && this.renderSelected()}

          <FormActions>{this.renderActions()}</FormActions>
        </ArcScroll>
        <ArcLoader in={this.props.isFetching} />
      </FormContainer>
    );
  }

  render() {
    return (
      <ArcView
        style={this.state.isReady ? styleContainerIn : styleContainerOut}
        onClick={this.handleCancel}
      >
        {this.renderForm()}
      </ArcView>
    );
  }
}

export default forms.createForm(model, formProps)(FormRewardCreate);
