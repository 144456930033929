import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import activitiesByMetricId from './activitiesByMetricId';
import currentMetricId from './currentMetricId';
import currentMetricDetailsType from './currentMetricDetailsType';
import hasLoaded from './hasLoaded';
import isCreating from './isCreating';
import isFetching from './isFetching';
import isFetchingArchived from './isFetchingArchived';
import manageMetricsById, {
  manageActiveMetricsById,
  manageArchivedMetricsById,
} from './manageMetricsById';
import rankingsByMetricId from './rankingsByMetricId';
import requestStatus from './requestStatus';
import teamsById, { getManageMetricsTeamsById } from './teamsById';
import teamIds, { getManageMetricsTeamIds } from './teamIds';
import peopleByTeam from './peopleByTeam';

const metrics = combineReducers({
  activitiesByMetricId,
  currentMetricId,
  currentMetricDetailsType,
  hasLoaded,
  isCreating,
  isFetching,
  isFetchingArchived,
  manageMetricsById,
  manageActiveMetricsById,
  manageArchivedMetricsById,
  rankingsByMetricId,
  requestStatus,
  teamsById,
  teamIds,
  peopleByTeam,
});

export default metrics;

export const getManageMetricsTeams = createSelector(
  getManageMetricsTeamIds,
  getManageMetricsTeamsById,
  (manageMetricsTeamIds, manageMetricsTeamsById) =>
    manageMetricsTeamIds.map(id => manageMetricsTeamsById[id]),
);
