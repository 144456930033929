import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import ArcResourceErrorDialog from 'arcade-frontend-ui/src/components/ArcResourceErrorDialog';

import TokenUserBudgetLimitForm from '../../components/TokenUserBudgetLimitForm';
import { resources } from '../../resources/users';

const TokenUserBudgetLimitFormContainer = ({
  open,
  onClose,
  onSubmit,
  person,
  ...props
}) => {
  const currentUser = useSelector(getCurrentUser);

  const apiPutUserBudgetLimit = useResourceAction(
    resources.apiPutUserBudgetLimit,
  );

  const handleSubmit = values => {
    apiPutUserBudgetLimit
      .requestData({
        resources: [
          {
            id: person.id,
            monthlyLimit: parseInt(values.tokens, 10),
          },
        ],
      })
      .then(() => {
        setTimeout(() => {
          apiPutUserBudgetLimit.onStatusReset();
          onClose();
          onSubmit();
        }, 200);
      })
      .catch(global.noop);
  };

  function handleErrorDialogClose() {
    apiPutUserBudgetLimit.onErrorReset();
    apiPutUserBudgetLimit.onStatusReset();
  }

  return (
    <React.Fragment>
      <TokenUserBudgetLimitForm
        open={open}
        onClose={onClose}
        onSubmit={handleSubmit}
        person={person}
        status={apiPutUserBudgetLimit.status}
        {...props}
      />

      <ArcResourceErrorDialog
        entityId={currentUser.entityId}
        error={apiPutUserBudgetLimit.error}
        onClose={handleErrorDialogClose}
        open={apiPutUserBudgetLimit.hasError}
        userId={currentUser.id}
      />
    </React.Fragment>
  );
};

TokenUserBudgetLimitFormContainer.displayName =
  'TokenUserBudgetLimitFormContainer';

TokenUserBudgetLimitFormContainer.propTypes = {
  open: PropTypes.bool,
  person: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

TokenUserBudgetLimitFormContainer.defaultProps = {
  open: false,
  person: {},
  onClose: global.noop,
  onSubmit: global.noop,
};

export default TokenUserBudgetLimitFormContainer;
