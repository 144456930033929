import getTransformedResponseFromResourceAction from 'arcade-frontend-core/src/helpers/getTransformedResponseFromResourceAction';

import apiGetAccountsRaw from './raw/apiGetAccountsRaw.json';
import * as resources from './accounts.resources';

export { apiGetAccountsRaw };

export const apiGetAccounts = getTransformedResponseFromResourceAction(
  resources.apiGetAccounts,
  apiGetAccountsRaw,
);
