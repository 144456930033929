import React from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import ArrowForward from '@material-ui/icons/ArrowForward';

import { ArcBreadcrumbs, ArcView, ArcButton } from 'arcade-frontend-ui';

import ArcAudienceList from '../ArcAudienceList';

const LEVELS = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
};

const audienceListStyle = {
  paddingBottom: 8,
  flexGrow: 100,
};

const iconStyle = {
  position: 'absolute',
  right: 12,
  top: '50%',
  transform: 'translateY(-50%)',
  fontSize: 16,
};

class ArcAudienceBrowser extends React.PureComponent {
  static displayName = 'ArcAudienceBrowser';

  static propTypes = {
    breadcrumbItems: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.node,
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    currentEntity: PropTypes.shape({
      isRoot: PropTypes.bool,
    }),
    currentUserId: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        color: PropTypes.string,
        letter: PropTypes.string,
        teamName: PropTypes.string,
        imageUrl: PropTypes.string,
      }),
    ),
    disableSelf: PropTypes.bool,
    fadeDirection: PropTypes.string,
    fadeOut: PropTypes.bool,
    hasChange: PropTypes.bool,
    isPreSelecting: PropTypes.bool,
    isSearching: PropTypes.bool,
    level: PropTypes.oneOf(Object.values(LEVELS)),
    onBack: PropTypes.func,
    onBreadcrumbsClick: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onSelectAll: PropTypes.func,
    onViewPicks: PropTypes.func,
    partiallySelectedEntitiesByUuid: PropTypes.objectOf(PropTypes.bool),
    preSelectingEntitiesByUuid: PropTypes.objectOf(PropTypes.bool),
    requestStatus: PropTypes.string,
    selectAllDisabled: PropTypes.bool,
    selectAllName: PropTypes.string,
    selectedEntitiesByUuid: PropTypes.objectOf(PropTypes.bool),
  };

  static defaultProps = {
    breadcrumbItems: [],
    currentEntity: {},
    currentUserId: '',
    data: [],
    disableSelf: false,
    fadeDirection: undefined,
    fadeOut: false,
    hasChange: true,
    isPreSelecting: false,
    isSearching: false,
    level: LEVELS.TOP,
    onBack: global.noop,
    onBreadcrumbsClick: global.noop,
    onChange: global.noop,
    onClick: global.noop,
    onSelectAll: global.noop,
    onViewPicks: global.noop,
    partiallySelectedEntitiesByUuid: {},
    preSelectingEntitiesByUuid: {},
    requestStatus: 'DEFAULT',
    selectAllDisabled: false,
    selectAllName: '',
    selectedEntitiesByUuid: {},
  };

  static LEVELS = LEVELS;

  state = {
    data: [],
  };

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    const shouldUpdateData =
      prevProps.data !== this.props.data ||
      prevProps.isPreSelecting !== this.props.isPreSelecting ||
      prevProps.partiallySelectedEntitiesByUuid !==
        this.props.partiallySelectedEntitiesByUuid ||
      prevProps.selectedEntitiesByUuid !== this.props.selectedEntitiesByUuid;

    if (shouldUpdateData) {
      this.updateData();
    }
  }

  get previousLevelName() {
    const { breadcrumbItems } = this.props;

    return breadcrumbItems.length > 1
      ? breadcrumbItems[breadcrumbItems.length - 2].label
      : null;
  }

  get currentLevelName() {
    const { breadcrumbItems } = this.props;

    return breadcrumbItems.length
      ? breadcrumbItems[breadcrumbItems.length - 1].label
      : null;
  }

  get numberOfPicks() {
    return Object.values(this.props.selectedEntitiesByUuid).filter(Boolean)
      .length;
  }

  setData = data => this.setState({ data });

  updateData() {
    const {
      // partiallySelectedEntitiesByUuid,
      selectedEntitiesByUuid,
    } = this.props;

    const nextData = this.props.data.map(item => {
      let navigationDisabled =
        item.type !== 'person' &&
        // && (partiallySelectedEntitiesByUuid[item.uuid] || selectedEntitiesByUuid[item.uuid]);
        selectedEntitiesByUuid[item.uuid];

      let entityTooltipTitle = navigationDisabled
        ? `Can't navigate to ${item.name} because it's already in your picks.`
        : undefined;

      if (this.props.isPreSelecting) {
        navigationDisabled = true;
        entityTooltipTitle = "Can't navigate while adding from groups";
      }

      return {
        ...item,
        navigationDisabled,
        entityTooltipTitle,
      };
    });

    this.setData(nextData);
  }

  render() {
    let upOneLevelTooltipText = this.props.isPreSelecting
      ? "Can't navigate while adding from groups"
      : undefined;

    if (this.props.currentEntity.isRoot) {
      upOneLevelTooltipText = 'Already at the top';
    }

    if (this.props.isSearching) {
      upOneLevelTooltipText = "Can't navigate while searching";
    }

    return (
      <ArcView
        data-testid="ArcAudienceBrowser"
        flexGrow="1"
        flexShrink="1"
        fullHeight
      >
        <ArcView
          row
          borderBottom="default"
          borderBottomWidth="1"
          paddingBottom="8"
        >
          <ArcBreadcrumbs
            items={this.props.breadcrumbItems}
            onClick={this.props.onBreadcrumbsClick}
            isDisabled={this.props.isPreSelecting}
            tooltipText={
              this.props.isPreSelecting
                ? "Can't navigate while adding from groups"
                : ''
            }
          />
        </ArcView>
        <ArcAudienceList
          currentLevelName={this.currentLevelName}
          currentUserId={this.props.currentUserId}
          data={this.state.data}
          disableSelf={this.props.disableSelf}
          fadeDirection={this.props.fadeDirection}
          fadeOut={this.props.fadeOut}
          hasBack
          hasChange={this.props.hasChange}
          hasClick
          hasLetterHeaders
          onBack={this.props.onBack}
          onChange={this.props.onChange}
          onClick={this.props.onClick}
          onSelectAll={this.props.onSelectAll}
          level={this.props.level}
          partiallySelectedEntitiesByUuid={
            this.props.partiallySelectedEntitiesByUuid
          }
          previousLevelName={this.previousLevelName}
          preSelectingEntitiesByUuid={this.props.preSelectingEntitiesByUuid}
          requestStatus={this.props.requestStatus}
          selectAllDisabled={this.props.selectAllDisabled}
          selectAllName={this.props.selectAllName}
          selectedEntitiesByUuid={this.props.selectedEntitiesByUuid}
          style={audienceListStyle}
          upOneLevelDisabled={
            this.props.isPreSelecting || this.props.isSearching
          }
          upOneLevelTooltipText={upOneLevelTooltipText}
        />

        <Hidden smUp>
          {this.numberOfPicks ? (
            <ArcView borderTop="default" borderTopWidth="1" paddingTop="8">
              <ArcButton
                color="blue"
                variant="outlined"
                size="small"
                fullWidth
                onClick={this.props.onViewPicks}
              >
                <ArcView>
                  {`Confirm Your Picks (${this.numberOfPicks})`}
                </ArcView>
                <ArrowForward fontSize="small" style={iconStyle} />
              </ArcButton>
            </ArcView>
          ) : (
            <ArcView />
          )}
        </Hidden>
      </ArcView>
    );
  }
}

export default ArcAudienceBrowser;
