import React from 'react';
import { Platform } from 'react-native';

import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
// @ts-ignore
import ArcTextV2 from 'arcade-frontend-ui/src/components/ArcTextV2';

import NotificationsPanelCard from '../NotificationsPanelCard';
import NotificationsPanelNoResult from '../NotificationsPanelNoResult';
import { NotificationsPanelCardListProps } from './NotificationsPanelCardList.d';

const boxShadow = Platform.select({
  default: {
    borderRadius: 4,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.06)',
  },
  ios: {
    shadowOpacity: 0.75,
    shadowRadius: 2,
    shadowColor: 'rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.06)',
    shadowOffset: { height: 2, width: 0 },
  },
  android: {
    elevation: 4,
  },
});

const STRINGS = {
  NO_RESULT_TITLE: 'Nothing to Show',
  NOTIFICATION_LIST_END: 'You have reached the end',
};

const PlaceholderEmptyNode = (
  <ArcViewBox m={3}>
    <NotificationsPanelNoResult title={STRINGS.NO_RESULT_TITLE} />
  </ArcViewBox>
);

const PlaceholderPendingNode = (
  <ArcViewBox flexGrow={1}>
    <ArcViewBox {...boxShadow} m={2}>
      <NotificationsPanelCard
        header
        roundedTop
        roundedBottom
        content={null}
        title={null}
        elevation={Platform.OS === 'android' ? 4 : 0}
        isPending
      />
    </ArcViewBox>
    <ArcViewBox {...boxShadow} m={2}>
      <NotificationsPanelCard
        header
        roundedTop
        roundedBottom={false}
        content={null}
        title={null}
        elevation={Platform.OS === 'android' ? 4 : 0}
        isPending
      />
      <NotificationsPanelCard
        header={false}
        roundedTop={false}
        roundedBottom
        content={null}
        title={null}
        elevation={Platform.OS === 'android' ? 4 : 0}
        isPending
      />
    </ArcViewBox>
    {Platform.OS === 'web' && (
      <ArcViewBox {...boxShadow} m={2}>
        <NotificationsPanelCard
          header
          roundedTop
          roundedBottom
          content={null}
          title={null}
          elevation={0}
          isPending
        />
      </ArcViewBox>
    )}
  </ArcViewBox>
);

const defaultProps: NotificationsPanelCardListProps = {
  emptyNode: PlaceholderEmptyNode,
  isPending: false,
  notifications: [],
  pendingNode: PlaceholderPendingNode,
};

const NotificationsPanelCardList: React.FC<NotificationsPanelCardListProps> = ({
  emptyNode,
  isPending,
  notifications,
  pendingNode,
}) => {
  if (isPending && !notifications.length) {
    return pendingNode;
  }

  if (!isPending && !notifications.length) {
    return emptyNode;
  }

  return (
    <ArcViewBox flexGrow={1}>
      {notifications.map(notification => (
        <ArcViewBox key={notification.title} {...boxShadow} m={2}>
          {notification.activities.map((activity, index) => (
            <NotificationsPanelCard
              key={activity.id}
              header={index === 0 ? notification.title : undefined}
              title={activity.subject}
              subtitle={activity.date}
              roundedTop={index === 0}
              roundedBottom={index === notification.activities.length - 1}
              elevation={Platform.OS === 'android' ? 4 : 0}
              {...activity}
            />
          ))}
        </ArcViewBox>
      ))}
      <ArcViewBox alignItems="center" mt={3} mb={12}>
        <ArcTextV2 fontSize={14} lineHeight="30px" color="#B4B4B4">
          {STRINGS.NOTIFICATION_LIST_END}
        </ArcTextV2>
      </ArcViewBox>
    </ArcViewBox>
  );
};

NotificationsPanelCardList.displayName = 'NotificationsPanelCardList';

NotificationsPanelCardList.defaultProps = defaultProps;

export default NotificationsPanelCardList;
