import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import * as GAME_STATUSES from 'arcade-frontend-core/src/types/game-statuses';
import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';
import * as GAME_PLAYER_STATUSES from 'arcade-frontend-core/src/types/game-player-statuses';
import { isRPAType } from 'arcade-frontend-core/src/types/game-formats';
import { getLocationQuery } from 'arcade-frontend-features/src/reducers/location';

import GamesGameCardActions from './GamesGameCardActions';
import GamesGameCardBackground from './GamesGameCardBackground';
import GamesGameCardHeader from './GamesGameCardHeader';
import GamesGameCardBody from './GamesGameCardBody';
import GamesGameCardFooter from './GamesGameCardFooter';
import GamesGameCardTabs from './GamesGameCardTabs';

const CARD_WIDTH = 375;

function GamesGameCard({
  id,
  allowManualAdd,
  campaignImage,
  expiresAt,
  name,
  onClick,
  onTabReset,
  participantCount,
  pendingCount,
  playerStatus,
  rewards,
  rankings,
  rules,
  type,
  onVerify,
  finalScore,
  score,
  status,
  startsAt,
  isManager,
  winners,
  leaderboardActivityId,
  hideVerify,
  hideMenu,
  hideComments,
  ...props
}) {
  const dispatch = useDispatch();
  const locationQuery = useSelector(getLocationQuery);
  const [showEventCount, setShowEventCount] = useState(true);

  const showVerify =
    isManager && status === GAME_STATUSES.NEEDS_VERIFICATION && !hideVerify;

  const isParticipantType = !GAME_PLAYER_STATUSES.NON_PARTICIPANT_TYPES.includes(
    playerStatus,
  );

  const handleMenuSelect = tab => {
    if (tab === 'verify') {
      onVerify(id);
      return;
    }

    const routeAction = {
      type: ROUTE_TYPES.ROUTE_GAMES_SHOW,
      payload: { id },
      query: {
        ...locationQuery,
        tab,
      },
    };

    dispatch(routeAction);
  };

  const [activeTab, setActiveTab] = React.useState(null);

  function handleTabChange(tab) {
    if (tab === 'events') {
      setShowEventCount(false);
    }

    if (tab === activeTab) {
      setActiveTab(null);
    } else {
      setActiveTab(tab);
    }
  }

  function handleTabReset() {
    onTabReset();
    setActiveTab(null);
  }

  function handleNavigateToActivity() {
    const routeAction = {
      type: ROUTE_TYPES.ROUTE_NEWSFEED_SHOW,
      payload: { id: leaderboardActivityId },
    };

    dispatch(routeAction);
  }

  return (
    <ArcBox
      role="article"
      display="flex"
      width={CARD_WIDTH}
      maxWidth="100%"
      overflow="hidden"
      position="relative"
      bgcolor="background.paper"
      borderRadius={8}
      boxShadow={2}
      {...props}
    >
      <ArcBox
        zIndex={1}
        position="relative"
        display="flex"
        flexDirection="column"
        color="common.white"
        width="100%"
      >
        <ArcBox
          overflow="hidden"
          position="relative"
          display="flex"
          flexDirection="column"
          flex={100}
        >
          <ArcBox
            position={status === GAME_STATUSES.ACTIVE ? undefined : 'relative'}
          >
            <GamesGameCardBackground
              status={status}
              campaignImage={campaignImage}
              height={status === GAME_STATUSES.ACTIVE ? '50%' : '100%'}
            />
            <ArcBox position="relative" zIndex="1">
              <GamesGameCardHeader
                expiresAt={expiresAt}
                startsAt={startsAt}
                isManager={isManager}
                name={name}
                onClick={onClick}
                onMenuSelect={handleMenuSelect}
                pendingCount={pendingCount}
                playerStatus={playerStatus}
                rewards={rewards}
                rules={rules}
                finalScore={finalScore}
                status={status}
                showVerify={showVerify}
                type={type}
                winners={winners}
                hideMenu={hideMenu}
              />
            </ArcBox>
          </ArcBox>

          <ArcBox position="relative" zIndex="1">
            <GamesGameCardBody
              onClick={onClick}
              expiresAt={expiresAt}
              startsAt={startsAt}
              playerStatus={playerStatus}
              participantCount={participantCount}
              rankings={rankings}
              rewards={rewards}
              rules={rules}
              finalScore={finalScore}
              score={score}
              status={status}
              type={type}
              winners={winners}
              pendingCount={pendingCount}
            />
          </ArcBox>
          <GamesGameCardFooter
            onNavigateToActivity={handleNavigateToActivity}
            expiresAt={expiresAt}
            status={status}
            playerStatus={playerStatus}
            hideComments={hideComments || !leaderboardActivityId}
          />
          <GamesGameCardTabs
            activeTab={activeTab}
            onClose={handleTabReset}
            gameId={id}
            type={type}
            rules={rules}
            isManager={isManager}
            rewards={rewards}
          />
        </ArcBox>

        <GamesGameCardActions
          activeTab={activeTab}
          gameId={id}
          onTabChange={handleTabChange}
          showAddMetric={
            status === GAME_STATUSES.ACTIVE &&
            allowManualAdd &&
            isParticipantType
          }
          showLeaderboard={
            status !== GAME_STATUSES.NEEDS_VERIFICATION && !isRPAType(type)
          }
          showVerify={showVerify}
          type={type}
          pendingEventsCount={showEventCount ? pendingCount : 0}
        />
      </ArcBox>
    </ArcBox>
  );
}

GamesGameCard.displayName = 'GamesGameCard';

function GamesGameCardPlaceholder({
  id,
  campaignImage,
  expiresAt,
  name,
  onClick,
  participantCount,
  playerStatus,
  rewards,
  rankings,
  rules,
  type,
  onVerify,
  score,
  status,
  startsAt,
  isManager,
  winners,
  pendingCount,
  allowManualAdd,
  ...props
}) {
  return (
    <ArcBox
      role="article"
      display="flex"
      width={CARD_WIDTH}
      maxWidth="100%"
      overflow="hidden"
      position="relative"
      bgcolor="background.paper"
      borderRadius={8}
      boxShadow={2}
      {...props}
    >
      <ArcBox
        zIndex={1}
        position="relative"
        display="flex"
        flexDirection="column"
        color="common.white"
        width="100%"
      >
        <GamesGameCardBackground height="50%" />

        <ArcBox
          zIndex="1"
          position="relative"
          display="flex"
          flexDirection="row"
          alignItems="center"
          height={72}
          px={3}
        >
          <Skeleton variant="text" height={36} width={100} />
          <ArcBox mr={1} flex={100} />
          <Skeleton variant="circle" width={36} height={36} />
        </ArcBox>
        <ArcBox display="flex" flexDirection="column" px={3} minHeight={150}>
          <ArcBox height={68}>
            <Skeleton variant="rect" height={36} width="100%" />
            <Skeleton variant="text" height={36} width={150} />
          </ArcBox>

          <ArcBox flex={100} />

          {status === GAME_STATUSES.ACTIVE && (
            <Skeleton
              component={ArcBox}
              borderRadius={8}
              variant="rect"
              width="100%"
              height={307}
              mt={3}
            />
          )}

          {status === GAME_STATUSES.ACTIVE && (
            <ArcBox
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              pt={1}
              pb={2}
            >
              <Skeleton
                component={ArcBox}
                borderRadius={8}
                variant="rect"
                height={29}
                width={150}
              />
            </ArcBox>
          )}
        </ArcBox>
        <ArcBox
          display="flex"
          flexDirection="row"
          borderTop={1}
          borderColor="divider"
          pt={1}
          pb={2}
          px={3}
        >
          <Skeleton
            component={ArcBox}
            variant="text"
            height={30}
            width={100}
            mr={1}
          />
          <Skeleton
            component={ArcBox}
            variant="text"
            height={30}
            width={100}
            mr={1}
          />
          <Skeleton component={ArcBox} variant="text" height={30} width={100} />
        </ArcBox>
      </ArcBox>
    </ArcBox>
  );
}

GamesGameCardPlaceholder.displayName = 'GamesGameCardPlaceholder';

GamesGameCardPlaceholder.propTypes = GamesGameCard.propTypes;

GamesGameCardPlaceholder.defaultProps = GamesGameCard.defaultProps;

GamesGameCard.Placeholder = GamesGameCardPlaceholder;

GamesGameCard.propTypes = {
  id: PropTypes.string,
  allowManualAdd: PropTypes.bool,
  campaignImage: PropTypes.string,
  expiresAt: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  participantCount: PropTypes.number,
  playerStatus: PropTypes.oneOf(Object.values(GAME_PLAYER_STATUSES)),
  type: PropTypes.string,
  rankings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      goal: PropTypes.number,
      isSelf: PropTypes.bool,
      position: PropTypes.number,
      score: PropTypes.number,
    }),
  ),
  rewards: PropTypes.arrayOf(PropTypes.object),
  rules: PropTypes.shape({
    metric: PropTypes.string,
    goal: PropTypes.number,
  }),
  onTabReset: PropTypes.func,
  onVerify: PropTypes.func,
  score: PropTypes.number,
  startsAt: PropTypes.string,
  status: PropTypes.oneOf(Object.values(GAME_STATUSES)),
  isManager: PropTypes.bool,
  winners: PropTypes.arrayOf(PropTypes.any),
  leaderboardActivityId: PropTypes.string,
  pendingCount: PropTypes.number,
  finalScore: PropTypes.number,

  // Props specific for newsfeed embedding
  hideVerify: PropTypes.bool,
  hideMenu: PropTypes.bool,
  hideComments: PropTypes.bool,
};

GamesGameCard.defaultProps = {
  id: null,
  allowManualAdd: false,
  campaignImage: '',
  expiresAt: '',
  name: '',
  onClick: global.noop,
  participantCount: 0,
  playerStatus: GAME_PLAYER_STATUSES.PLAYER,
  type: '',
  rankings: [],
  rewards: [],
  rules: {},
  onTabReset: global.noop,
  onVerify: global.noop,
  score: 0,
  startsAt: '',
  status: GAME_STATUSES.ACTIVE,
  isManager: false,
  winners: [],
  leaderboardActivityId: null,
  pendingCount: 0,
  finalScore: 0,

  hideVerify: false,
  hideMenu: false,
  hideComments: false,
};

export default GamesGameCard;
