import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ArcViewTitleBar,
} from 'arcade-frontend-ui';

import { routeManage } from 'arcade-frontend-features/src/actions/manage/routes';
import getComponentFromDirective from 'arcade-frontend-core/src/helpers/getComponentFromDirective';

import ArcMainView from 'arcade-frontend-ui/src/layout/ArcMainView';
import ArcScroll from 'arcade-frontend-ui/src/layout/ArcScroll';

const ManageTeamsContainer = getComponentFromDirective('arcadeManageViewTeams');

export default function ManageTeamsView() {
  const dispatch = useDispatch();

  const goBack = () => dispatch(routeManage());

  return (
    <ArcMainView isViewing>
      <ArcViewTitleBar
        onClickBack={goBack}
        title="Manage Teams"
      />
      <ArcScroll>
        <ManageTeamsContainer />
      </ArcScroll>
    </ArcMainView>
  );
}
