import React from 'react';
import Transition from 'react-transition-group/Transition';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView/ArcView';
import ArcLoader from '../ArcLoader';

const duration = 450;

const defaultStyle = {
  zIndex: 500,
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  transitionProperty: 'opacity',
  transitionTimingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',
  transitionDuration: `${duration}ms`,
  transitionDelay: 0,

  opacity: 0,
};

const transitionStyles = {
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
};

const styleLoader = {
  backgroundColor: 'none',
};

const ArcFadeIn = ({ in: inProp, hasLoader, style, children, ...rest }) => (
  <Transition in={inProp} timeout={duration} {...rest}>
    {state => (
      <ArcView style={{
        ...defaultStyle,
        ...transitionStyles[state],
        ...style,
      }}
      >
        {children}

        {
          hasLoader && (
            <ArcLoader
              in={inProp}
              style={styleLoader}
            />
          )
        }
      </ArcView>
    )}
  </Transition>
);

ArcFadeIn.propTypes = {
  in: ArcPropTypes.bool.isRequired,
  hasLoader: ArcPropTypes.bool,
  children: ArcPropTypes.children,
  style: ArcPropTypes.style,
};

ArcFadeIn.defaultProps = {
  hasLoader: false,
  style: null,
  children: null,
};

export default ArcFadeIn;
