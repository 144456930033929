import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import makeStyles from '@material-ui/styles/makeStyles';

import {
  ArcResponsiveDialog,
  ArcView,
  ArcButton,
  ArcForm,
  ArcFormField,
  ArcText,
  ArcToken,
  ArcIconButton,
} from 'arcade-frontend-ui';
import ArcEntityChip from 'arcade-frontend-ui/src/components/ArcEntityChip';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';

const INITIAL_VALUES = {
  isPublic: false,
  person: {},
  tokens: '',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  tokens: Yup.number(),
});

const useStyles = makeStyles(theme => ({
  textDescription: {
    marginBottom: theme.spacing(1),
    fontSize: theme.font.getFontSize(1),
    lineHeight: 1.5,
  },
  textDisclaimer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1.5),
    fontSize: theme.font.getFontSize(0.875),
    color: '#616264',
    backgroundColor: '#F0FBFE',
    lineHeight: 1.5,
    borderRadius: 4,
  },
}));

function TokenUserBudgetLimitForm({
  initialValues,
  onClose,
  onSubmit,
  open,
  person,
  status,
}) {
  const classes = useStyles();

  const STRINGS = {
    'UI/USER_TOKEN_BUDGET_LIMIT_TITLE': 'Set Monthly Budget Limit',
    'UI/USER_TOKEN_BUDGET_LIMIT_DESCRIPTION':
      'Setting the monthly budget limit allows the user to decide how they want allocate tokens each month.',
    'UI/USER_TOKEN_BUDGET_LIMIT_DISCLAIMER_BALANCE_NULL':
      'This user currently has access to the entire company budget. In order to set a monthly token budget for this user, specify the token amount in the field below.',
    'UI/USER_TOKEN_BUDGET_LIMIT_DISCLAIMER_BALANCE_ZERO':
      'This user has a default balance of 0. In order to set a monthly token budget for this user, specify the token amount in the field below.',
    'UI/USER_TOKEN_BUDGET_LIMIT_APPLY_LABEL': 'Apply limit to:',
  };

  const shouldRenderDisclaimer =
    !person.monthlyLimit || person.monthlyLimit === 0;

  const renderForm = formikProps => {
    const { tokens } = formikProps.values;

    const numTokens = parseInt(tokens, 10) || 0;
    const minTokens = 0;
    const maxTokens = 1000000;

    const addButtonDisabled = numTokens >= maxTokens;

    const addButton = (
      <ArcIconButton
        size="small"
        disabled={addButtonDisabled}
        onClick={() => {
          if (numTokens + 100 > maxTokens) {
            return;
          }

          formikProps.setFieldValue('tokens', numTokens + 100);
          formikProps.setFieldTouched('tokens', true);
        }}
      >
        <Add color="inherit" size="small" />
      </ArcIconButton>
    );

    const removeButtonDisabled = numTokens <= minTokens;

    const removeButton = (
      <ArcIconButton
        size="small"
        disabled={removeButtonDisabled}
        onClick={() => {
          if (numTokens - 100 < minTokens) {
            return;
          }

          formikProps.setFieldValue('tokens', numTokens - 100);
          formikProps.setFieldTouched('tokens', true);
        }}
      >
        <Remove color="inherit" size="small" />
      </ArcIconButton>
    );

    const removeButtonAdornment =
      removeButtonDisabled && minTokens > 0 ? (
        <Tooltip title={`Min amount is ${minTokens}`}>
          <ArcView>{removeButton}</ArcView>
        </Tooltip>
      ) : (
        removeButton
      );

    const addButtonAdornment = addButtonDisabled ? (
      <Tooltip title={`Max amount is ${maxTokens}`}>
        <ArcView>{addButton}</ArcView>
      </Tooltip>
    ) : (
      addButton
    );

    const startAdornment = (
      <InputAdornment position="start">
        <ArcToken />
      </InputAdornment>
    );

    const endAdornment = (
      <React.Fragment>
        {removeButtonAdornment}
        {addButtonAdornment}
      </React.Fragment>
    );

    return (
      <ArcView>
        <ArcView padding="16">
          <ArcView marginBottom="24">
            <ArcView marginBottom="24">
              <ArcText className={classes.textDescription}>
                {STRINGS['UI/USER_TOKEN_BUDGET_LIMIT_DESCRIPTION']}
              </ArcText>
            </ArcView>

            <ArcText size14px isStrong marginBottom="8">
              {STRINGS['UI/USER_TOKEN_BUDGET_LIMIT_APPLY_LABEL']}
            </ArcText>

            <ArcView row wrap="wrap">
              <ArcEntityChip
                type="person"
                name={person.name}
                imageUrl={person.profileImage}
              />
            </ArcView>

            {shouldRenderDisclaimer && (
              <ArcView row className={classes.textDisclaimer} marginTop="8">
                <ArcText>
                  <ArcText isStrong>{'Note: '}</ArcText>
                  {person.monthlyLimit === null ? (
                    <ArcText>
                      {
                        STRINGS[
                          'UI/USER_TOKEN_BUDGET_LIMIT_DISCLAIMER_BALANCE_NULL'
                        ]
                      }
                    </ArcText>
                  ) : (
                    <ArcText>
                      {
                        STRINGS[
                          'UI/USER_TOKEN_BUDGET_LIMIT_DISCLAIMER_BALANCE_ZERO'
                        ]
                      }
                    </ArcText>
                  )}
                </ArcText>
              </ArcView>
            )}
          </ArcView>

          <ArcView row wrap="wrap" />
          <ArcFormField
            autoComplete="off"
            id="tokens"
            type="number"
            name="tokens"
            fullWidth
            label="Tokens"
            placeholder="Tokens per month"
            validations={{
              isRequired: false,
              minNumber: 0,
              maxNumber: 1000000,
            }}
            InputProps={{
              startAdornment,
              endAdornment,
            }}
          />
        </ArcView>
        <ArcView row justify="flex-end" padding="4">
          <ArcView padding="4">
            <ArcButton onClick={onClose} label="Cancel" />
          </ArcView>
          <ArcView padding="4">
            <ArcResourceButton
              type="submit"
              label="Submit"
              color="primary"
              variant="contained"
              status={status}
            />
          </ArcView>
        </ArcView>
      </ArcView>
    );
  };

  return (
    <ArcResponsiveDialog open={open} onClose={onClose}>
      <ArcView flexGrow="10" flexShrink="10" fullHeight>
        <ArcView
          position="relative"
          row
          padding="8"
          paddingLeft="16"
          align="flex-end"
          color="grey-tint"
          borderBottom="default"
          borderBottomWidth="1"
        >
          <Typography variant="h5">
            {STRINGS['UI/USER_TOKEN_BUDGET_LIMIT_TITLE']}
          </Typography>

          <ArcView spacer />

          <ArcButton size="small" onClick={onClose}>
            <Close color="action" fontSize="small" />
          </ArcButton>
        </ArcView>
      </ArcView>

      <ArcForm
        initialValues={{
          ...initialValues,
          tokens: person.monthlyLimit,
        }}
        onSubmit={onSubmit}
        validateOnBlur
        validateOnChange
        validationSchema={VALIDATION_SCHEMA}
      >
        {renderForm}
      </ArcForm>
    </ArcResponsiveDialog>
  );
}

TokenUserBudgetLimitForm.displayName = 'TokenUserBudgetLimitForm';

TokenUserBudgetLimitForm.propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  person: PropTypes.shape({
    name: PropTypes.string,
    monthlyLimit: PropTypes.number,
    profileImage: PropTypes.string,
  }),
  status: PropTypes.objectOf(PropTypes.bool),
};

TokenUserBudgetLimitForm.defaultProps = {
  initialValues: INITIAL_VALUES,
  onClose: global.noop,
  onSubmit: global.noop,
  open: false,
  person: {},
  status: {},
};

export default TokenUserBudgetLimitForm;
