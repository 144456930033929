/* eslint-disable react/prop-types */

import React from 'react';
import cx from 'classnames';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import {
  getClassNamesAndNonConsumedProps,
  getName,
  getRef,
} from './common';

const createWithStyles = (styles, options) => (BaseComponent) => {
  const name = getName(BaseComponent, styles, options);

  const opts = {
    name,
    ...options,
    muiName: name,
  };

  const styleProps = (props) => {
    const {
      nonConsumedProps,
      classNames,
    } = getClassNamesAndNonConsumedProps(props, props.classes, name);

    return ({
      ...nonConsumedProps,
      theme: opts.withTheme ? props.theme : undefined,
      className: cx(classNames),
    });
  };

  const StyledComponent = React.forwardRef((props, ref) => {
    const Component = props.component || BaseComponent;
    return (
      <Component
        {...styleProps(props)}
        {...getRef(Component, (ref || props.internalRef))}
      >
        {props.children}
      </Component>
    );
  });

  StyledComponent.displayName = name;

  const ComponentWithStyles = compose(
    withStyles(styles, opts),
    React.memo,
  )(StyledComponent);

  ComponentWithStyles.displayName = name;

  return ComponentWithStyles;
};

export default createWithStyles;
