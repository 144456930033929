import { NOT_FOUND as ROUTER_NOT_FOUND } from 'redux-first-router';

export const NOT_FOUND = ROUTER_NOT_FOUND;

export const ROUTE_BASE = 'route/ROUTE_BASE';

export const ROUTE_HOME = 'route/ROUTE_HOME';
export const ROUTE_LOGIN = 'route/ROUTE_LOGIN';
export const ROUTE_LOGIN_VIA_LINK = 'route/ROUTE_LOGIN_VIA_LINK';

export const ROUTE_ACADEMY = 'route/ROUTE_ACADEMY';
export const ROUTE_ACADEMY_NEW = 'route/ROUTE_ACADEMY_NEW';
export const ROUTE_ACADEMY_SHOW = 'route/ROUTE_ACADEMY_SHOW';
export const ROUTE_ACADEMY_EDIT = 'route/ROUTE_ACADEMY_EDIT';
export const ROUTE_ACADEMY_START = 'route/ROUTE_ACADEMY_START';

export const ROUTE_CHAT = 'route/ROUTE_CHAT';
export const ROUTE_CHAT_SHOW = 'route/ROUTE_CHAT_SHOW';
export const ROUTE_CHAT_CREATE = 'route/ROUTE_CHAT_CREATE';
export const ROUTE_CHAT_JOIN = 'route/ROUTE_CHAT_JOIN';

export const ROUTE_GAMES = 'route/ROUTE_GAMES';
export const ROUTE_GAMES_CREATE = 'route/ROUTE_GAMES_CREATE';
export const ROUTE_GAMES_EDIT = 'route/ROUTE_GAMES_EDIT';
export const ROUTE_GAMES_SHOW = 'route/ROUTE_GAMES_SHOW';

export const ROUTE_GOALS = 'route/ROUTE_GOALS';
export const ROUTE_GOALS_METRICS = 'route/ROUTE_GOALS_METRICS';
export const ROUTE_GOALS_METRICS_SHOW = 'route/ROUTE_GOALS_METRICS_SHOW';
export const ROUTE_GOALS_GAMES = 'route/ROUTE_GOALS_GAMES';
export const ROUTE_GOALS_MANAGE = 'route/ROUTE_GOALS_MANAGE';

export const ROUTE_HUB = 'route/ROUTE_HUB';

export const ROUTE_HUDDLE = 'route/ROUTE_HUDDLE';

export const ROUTE_INSIGHTS = 'route/ROUTE_INSIGHTS';

export const ROUTE_LEAGUE = 'route/ROUTE_LEAGUE';

export const ROUTE_METRICS = 'route/ROUTE_METRICS';
export const ROUTE_METRICS_SHOW = 'route/ROUTE_METRICS_SHOW';

export const ROUTE_MANAGE = 'route/ROUTE_MANAGE';

export const ROUTE_MANAGE_ACCOUNT = 'route/ROUTE_MANAGE_ACCOUNT';

export const ROUTE_MANAGE_ACTIVITIES = 'route/ROUTE_MANAGE_ACTIVITIES';

export const ROUTE_MANAGE_INTEGRATIONS = 'route/ROUTE_MANAGE_INTEGRATIONS';

export const ROUTE_MANAGE_BILLING = 'route/ROUTE_MANAGE_BILLING';
export const ROUTE_MANAGE_BILLING2 = 'route/ROUTE_MANAGE_BILLING2';

export const ROUTE_MANAGE_CHECKLISTS = 'route/ROUTE_MANAGE_CHECKLISTS';
export const ROUTE_MANAGE_CHECKLISTS_CREATE =
  'route/ROUTE_MANAGE_CHECKLISTS_CREATE';
export const ROUTE_MANAGE_CHECKLISTS_EDIT =
  'route/ROUTE_MANAGE_CHECKLISTS_EDIT';
export const ROUTE_MANAGE_CHECKLISTS_SHOW =
  'route/ROUTE_MANAGE_CHECKLISTS_SHOW';

export const ROUTE_MANAGE_ENGAGEMENT = 'route/ROUTE_MANAGE_ENGAGEMENT';

export const ROUTE_MANAGE_GAMES = 'route/ROUTE_MANAGE_GAMES';
export const ROUTE_MANAGE_GAMES_CREATE = 'route/ROUTE_MANAGE_GAMES_CREATE';
export const ROUTE_MANAGE_GAMES_SHOW = 'route/ROUTE_MANAGE_GAMES_SHOW';
export const ROUTE_MANAGE_GAMES_EDIT = 'route/ROUTE_MANAGE_GAMES_EDIT';
export const ROUTE_MANAGE_GAMES_CLONE = 'route/ROUTE_MANAGE_GAMES_CLONE';

export const ROUTE_MANAGE_INTEGRATION_HISTORY =
  'route/ROUTE_MANAGE_INTEGRATION_HISTORY';

export const ROUTE_MANAGE_METRICS = 'route/ROUTE_MANAGE_METRICS';
export const ROUTE_MANAGE_METRICS_CREATE = 'route/ROUTE_MANAGE_METRICS_CREATE';
export const ROUTE_MANAGE_METRICS_EDIT = 'route/ROUTE_MANAGE_METRICS_EDIT';
export const ROUTE_MANAGE_METRICS_SHOW = 'route/ROUTE_MANAGE_METRICS_SHOW';

export const ROUTE_MANAGE_PEOPLE = 'route/ROUTE_MANAGE_PEOPLE';

export const ROUTE_MANAGE_POSTS = 'route/ROUTE_MANAGE_POSTS';

export const ROUTE_MANAGE_REWARDS = 'route/ROUTE_MANAGE_REWARDS';
export const ROUTE_MANAGE_REWARDS_CREATE = 'route/ROUTE_MANAGE_REWARDS_CREATE';
export const ROUTE_MANAGE_REWARDS_SHOW = 'route/ROUTE_MANAGE_REWARDS_SHOW';
export const ROUTE_MANAGE_REWARDS_EDIT = 'route/ROUTE_MANAGE_REWARDS_EDIT';

export const ROUTE_MANAGE_TEAMS = 'route/ROUTE_MANAGE_TEAMS';

export const ROUTE_MANAGE_TOKENS = 'route/ROUTE_MANAGE_TOKENS';

export const ROUTE_MANAGE_TOKEN_BUDGETS = 'route/ROUTE_MANAGE_TOKEN_BUDGETS';

export const ROUTE_MANAGE_TRANSACTIONS = 'route/ROUTE_MANAGE_TRANSACTIONS';

export const ROUTE_NEWSFEED = 'route/ROUTE_NEWSFEED';
export const ROUTE_NEWSFEED_CATEGORY = 'route/ROUTE_NEWSFEED_CATEGORY';
export const ROUTE_NEWSFEED_SHOW = 'route/ROUTE_NEWSFEED_SHOW';

export const ROUTE_PEOPLE = 'route/ROUTE_PEOPLE';

export const ROUTE_PROFILE = 'route/ROUTE_PROFILE';
export const ROUTE_PROFILE_EDIT = 'route/ROUTE_PROFILE_EDIT';

export const ROUTE_REWARDS = 'route/ROUTE_REWARDS';
export const ROUTE_REWARDS_AWARD = 'route/ROUTE_REWARDS_AWARD';
export const ROUTE_REWARDS_CHESTS = 'route/ROUTE_REWARDS_CHESTS';
export const ROUTE_REWARDS_SHOP = 'route/ROUTE_REWARDS_SHOP';
export const ROUTE_REWARDS_TRANSACTIONS = 'route/ROUTE_REWARDS_TRANSACTIONS';
export const ROUTE_REWARDS_HISTORY = 'route/ROUTE_REWARDS_HISTORY';
