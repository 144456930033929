import React, { FC } from 'react';

import {
  Table,
  TableHead,
  TableBody,
  TablePagination,
  Hidden,
} from '@material-ui/core';

import CloudDownload from '@material-ui/icons/CloudDownload';

import track from 'arcade-frontend-core/src/helpers/track';
import { createCsv, downloadCsv } from '../../helpers/utils/csv';

import ArcTransactionLogHeader from './ArcTransactionLogHeader';
import ArcTransactionLogItem from './ArcTransactionLogItem';
import ArcScroll from '../../layout/ArcScroll';
import { createWithStyles } from '../../styles';
import ArcView from '../../primitives/ArcView';
import ArcText from '../../primitives/ArcText';
import ArcButton from '../../elements/ArcButton/ArcButton';
import ArcTransactionLogFilter from './ArcTransactionLogFilter';
import ArcParagraph from '../../typography/ArcParagraph';
import { Spinner } from '@chakra-ui/spinner';

const CSV_HEADINGS = [
  {
    key: 'when',
    title: 'When',
  },
  {
    key: 'fromDisplayName',
    title: 'From',
  },
  {
    key: 'fromCompanyId',
    title: 'From Company Identifier',
  },
  {
    key: 'fromTeamName',
    title: 'From Team Name',
  },
  {
    key: 'toDisplayName',
    title: 'To',
  },
  {
    key: 'toCompanyId',
    title: 'To Company Identifier',
  },
  {
    key: 'toTeamName',
    title: 'To Team Name',
  },
  {
    key: 'event',
    title: 'Event',
  },
  {
    key: 'amount',
    title: 'Amount',
  },
  {
    key: 'balance',
    title: 'Balance',
  },
];
const styles = {
  ArcTransactionLogView: () => ({
    root: {
      backgroundColor: '#ffffff',
      textAlign: 'left',
      fontSize: 14,
      fontWeight: '300',
      lineHeight: '16px',
    },
  }),

  ArcTransactionLogFooter: theme => ({
    root: {
      padding: theme.spacing(1),
      justifyContent: 'flex-end',
      flexDirection: 'row',
      alignItems: 'center',
    },
  }),

  ArcTransactionLogPlaceholder: () => ({
    root: {
      fontSize: 16,
      lineHeight: '24px',
      textAlign: 'center',
    },
  }),

  AccountNameText: theme => ({
    root: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
  }),

  Divider: theme => ({
    root: {
      margin: theme.spacing(2),
    },
  }),
};
const ArcTransactionLogView = createWithStyles(styles.ArcTransactionLogView)(
  ArcView,
);
const AccountNameText = createWithStyles(styles.AccountNameText)(ArcText);
const Divider = createWithStyles(styles.Divider)(ArcView);
const ArcTransactionLogFooter = createWithStyles(
  styles.ArcTransactionLogFooter,
)(ArcView);
const ArcTransactionLogPlaceholder = createWithStyles(
  styles.ArcTransactionLogPlaceholder,
)(ArcParagraph);

interface User {
  id: number;
  name: string;
  profileImage: string;
  monthlyLimit: number;
  remainingBalance: number;
}
type DateFilterType = 'time period' | 'date range';
export interface Summary {
  incoming: number;
  outgoing: number;
  change: number;
}

interface DateFilterDateRange {
  type: 'date range';
  fromDate: string;
  toDate: string;
}
interface DateFilterTimePeriod {
  type: 'time period';
  timePeriod:
    | 'all_dates'
    | 'today'
    | 'yesterday'
    | 'this_week'
    | 'this_month'
    | 'this_year'
    | 'last_week'
    | 'last_month'
    | 'last_7_days'
    | 'last_30_days';
}
export type DateFilter = DateFilterDateRange | DateFilterTimePeriod;
interface ArcTransactionLogProps {
  transactions: any[];
  accounts: any[];
  accountName: string;
  isFetching: boolean;
  onDateRangeChange: (dateFilter: DateFilter) => void;
  onChangeFilter: (filters: string[]) => void;
  onDeleteUserFilter: () => void;
  onPeopleChange: (peopleIds: string[]) => void;
  dateRange: DateFilter;
  transferTypes: { code: string }[];
  filter: string[];
  summary: Summary;
  user?: User;
  page: number;
  rowsPerPage: number;
  setPage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  totalCount?: number;
}
const ArcTransactionLog: FC<ArcTransactionLogProps> = ({
  transactions,
  accountName,
  isFetching,
  accounts,
  onDateRangeChange,
  onChangeFilter,
  onDeleteUserFilter,
  onPeopleChange,
  dateRange,
  transferTypes,
  filter,
  summary,
  user,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalCount,
}) => {
  const totalRowCount =
    typeof totalCount !== 'undefined' ? totalCount : transactions.length;
  const isFirstPage = page === 0;
  const isLastPage = (page + 1) * rowsPerPage >= totalRowCount;

  const accountDisplayName = () => {
    const selectedAccount = accounts?.find(
      account => account.key === accountName,
    );
    return selectedAccount ? selectedAccount.name : '';
  };
  const csvTitle = `${accountDisplayName()} Transactions.csv`;
  const csvObjects = createCsv(CSV_HEADINGS, transactions);

  const handleClickDownloadCsv = () => {
    downloadCsv(csvObjects, csvTitle);

    track('downloaded transaction csv', {
      title: csvTitle,
    });
  };
  const handleChangeRowsPerPage = ({ target }) => {
    setRowsPerPage(target.value);
    setPage(0);
  };

  const handleChangePage = (_, pg) => {
    setPage(pg);
  };

  return (
    <>
      <ArcTransactionLogView>
        <Divider borderBottom="default" />
        <AccountNameText isLarge>
          {`${accountDisplayName()} History`}
        </AccountNameText>
        <ArcTransactionLogFilter
          dateRange={dateRange}
          onDateRangeChange={onDateRangeChange}
          onChangeFilter={onChangeFilter}
          onDeleteUserFilter={onDeleteUserFilter}
          onPeopleChange={onPeopleChange}
          accountName={accountName}
          transferTypes={transferTypes}
          filter={filter}
          accounts={accounts}
          summary={summary}
          user={user}
        />
        <ArcScroll isHorizontalOnly>
          <Table padding="checkbox" size="small" style={{ width: '100%' }}>
            <TableHead>
              <ArcTransactionLogHeader />
            </TableHead>
            <TableBody>
              <ArcTransactionLogItem isFetching />
              {transactions.map(row => (
                <ArcTransactionLogItem
                  key={row.id}
                  accountName={accountName}
                  {...row}
                />
              ))}
            </TableBody>
          </Table>
          {isFetching && !transactions.length && (
            <ArcView style={{ height: 100 }} align="center" justify="center">
              <Spinner size="lg" />
            </ArcView>
          )}
          {!isFetching && accounts?.length && !transactions.length && (
            <ArcTransactionLogPlaceholder>
              No transactions for selected filters.
            </ArcTransactionLogPlaceholder>
          )}
        </ArcScroll>
      </ArcTransactionLogView>
      <ArcTransactionLogFooter>
        <Hidden smDown>
          <ArcButton
            color="primary"
            onClick={handleClickDownloadCsv}
            variant="outlined"
          >
            <CloudDownload />
            <ArcView marginLeft="8">{'Download CSV'}</ArcView>
          </ArcButton>
        </Hidden>

        <TablePagination
          backIconButtonProps={{ disabled: isFetching || isFirstPage }}
          nextIconButtonProps={{ disabled: isFetching || isLastPage }}
          count={totalRowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={[25, 50, 100]}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangePage={handleChangePage}
        />
      </ArcTransactionLogFooter>
    </>
  );
};

export default ArcTransactionLog;
