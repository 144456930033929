import { types } from '../actions';

const initialState = { supportWidgetLoaded: false };

const leftMenuStatus = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SUPPORT_WIDGET_LOADED:
      return { ...state, supportWidgetLoaded: action.payload };
    default:
      return state;
  }
};

export const getLeftMenuStatus = state => state.app.leftMenuStatus;

export default leftMenuStatus;
