// import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcText from '../../primitives/ArcText';
import ArcStyles, { createWithStyles } from '../../styles';

const styles = {
  ArcSubheading: theme => ({
    root: {
      ...theme.typography.subheading,
      display: 'block',
      fontWeight: '200',
      fontSize: ArcStyles.ptSizeInPx(1.25),
      marginTop: ArcStyles.ptSizeInPx(0.67),
      marginBottom: ArcStyles.ptSizeInPx(0.67),

      [theme.breakpoints.down('sm')]: {
        fontSize: ArcStyles.ptSizeInPx(1.125),
      },
    },
  }),
};

const ArcSubheading = createWithStyles(styles.ArcSubheading)(ArcText);

export default ArcSubheading;
