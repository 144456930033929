import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Close from '@material-ui/icons/Close';

import {
  ArcButton,
  ArcForm,
  ArcView,
  ArcText,
  ArcFormField,
} from 'arcade-frontend-ui';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcEntityChip from 'arcade-frontend-ui/src/components/ArcEntityChip';
import ArcErrorDialog from 'arcade-frontend-ui/src/components/ArcErrorDialog';
import ArcResponsiveDialog from 'arcade-frontend-ui/src/components/ArcResponsiveDialog';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';
import ArcPeopleMultiSelect from 'arcade-frontend-ui/src/components/ArcPeopleMultiSelect';
import ArcTokenValue from 'arcade-frontend-ui/src/components/ArcTokenValue';
import ArcInternalLink from 'arcade-frontend-ui/src/components/ArcInternalLink';
import * as ESCROWABLE_FUNDS from 'arcade-frontend-core/src/types/escrowable-funds';

import ArcAwardTokenFormConfirmationDialog from './ArcAwardTokenFormConfirmationDialog';

const INITIAL_VALUES = {
  tokens: 100,
  isPublic: false,
  message: '',
  reason: '',
  people: [],
};

const MAX_NUM_CONFIRMATION_PEOPLE = 5;

const STRINGS = {
  'UI/AWARD_TOKEN_FORM_TITLE': 'Award Tokens',
  'UI/AWARD_TOKEN_FORM_MESSAGE_HEADER': 'Public Message',
  'UI/AWARD_TOKEN_FORM_MESSAGE_TEXT':
    'The public message is shown on the newsfeed',
  'UI/AWARD_TOKEN_FORM_INTERNAL_REASON_HEADER': 'Reason for award',
  'UI/AWARD_TOKEN_FORM_INTERNAL_REASON_TEXT':
    'The reason is displayed in the company account history and is sent to the recipient',
};

const LABELS_BY_ESCROWABLE_FUNDS = {
  [ESCROWABLE_FUNDS.COMPANY_BUDGET]: 'Award tokens to users in Arcade.',
  [ESCROWABLE_FUNDS.USER_BUDGET]:
    'Award tokens to users in Arcade from your own budget.',
};

const subheadingStyle = {
  color: '#616264',
};

function ArcAwardTokenFormPlaceholder({ inDialog, open, onCancel }) {
  const title = inDialog ? (
    <ArcView
      position="relative"
      row
      padding="8"
      paddingLeft="16"
      align="flex-end"
      color="grey-tint"
      borderBottom="default"
      borderBottomWidth="1"
    >
      <Typography variant="h5">
        {STRINGS['UI/AWARD_TOKEN_FORM_TITLE']}
      </Typography>

      <ArcView spacer />

      <ArcButton size="small" onClick={onCancel}>
        <Close color="action" fontSize="small" />
      </ArcButton>
    </ArcView>
  ) : null;

  const form = (
    <ArcBox flexGrow="10" flexShrink="10" height="100%">
      {title}
      <ArcBox display="flex" flexDirection="column" p={2}>
        <Skeleton variant="rect" component={ArcBox} height={48} mb={3} />

        <Skeleton variant="text" height={36} width={280} />
        <Skeleton variant="text" height={36} width={150} />

        <Skeleton variant="rect" component={ArcBox} height={40} my={3} />
      </ArcBox>
    </ArcBox>
  );

  if (inDialog) {
    return (
      <ArcResponsiveDialog onClose={onCancel} open={open}>
        {form}
      </ArcResponsiveDialog>
    );
  }

  return form;
}

class ArcAwardTokenForm extends React.PureComponent {
  static displayName = 'ArcAwardTokenForm';

  static Placeholder = ArcAwardTokenFormPlaceholder;

  static propTypes = {
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    escrowableFundsBalance: PropTypes.number,
    escrowableFundsType: PropTypes.oneOf(Object.values(ESCROWABLE_FUNDS)),
    hasConfirmation: PropTypes.bool,
    hasError: PropTypes.bool,
    hasFailed: PropTypes.bool,
    hasFieldIsPublic: PropTypes.bool,
    hasFieldPeople: PropTypes.bool,
    inDialog: PropTypes.bool,
    initialValues: PropTypes.shape({}),
    isIdle: PropTypes.bool,
    isPending: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    onErrorReset: PropTypes.func,
    onStatusReset: PropTypes.func,
    onSubmit: PropTypes.func,
    open: PropTypes.bool,
    people: PropTypes.arrayOf(PropTypes.string),
    peopleById: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string,
        imageUrl: PropTypes.string,
      }),
    ),
    status: PropTypes.objectOf(PropTypes.bool),
    submitLabel: PropTypes.string,
  };

  static defaultProps = {
    error: {},
    escrowableFundsBalance: 0,
    escrowableFundsType: ESCROWABLE_FUNDS.COMPANY_BUDGET,
    hasConfirmation: false,
    hasError: false,
    hasFailed: false,
    hasFieldIsPublic: false,
    hasFieldPeople: false,
    inDialog: false,
    initialValues: INITIAL_VALUES,
    isIdle: true,
    isPending: false,
    max: undefined,
    min: 1,
    onCancel: global.noop,
    onDelete: global.noop,
    onErrorReset: global.noop,
    onStatusReset: global.noop,
    onSubmit: global.noop,
    open: false,
    people: [],
    peopleById: {},
    status: undefined,
    submitLabel: 'Submit',
  };

  static MAX_NUM_CONFIRMATION_PEOPLE = MAX_NUM_CONFIRMATION_PEOPLE;

  state = {
    hasCustomMessage: false,
    isConfirmingSubmit: false,
  };

  setHasCustomMessage = hasCustomMessage => this.setState({ hasCustomMessage });

  setIsConfirmingSubmit = isConfirmingSubmit =>
    this.setState({ isConfirmingSubmit });

  hasSubmitted = false;

  handleConfirmingSubmitCancel = () => this.setIsConfirmingSubmit(false);

  handleToggleEditMessage = () =>
    this.setHasCustomMessage(!this.state.hasCustomMessage);

  renderForm = formikProps => {
    const {
      escrowableFundsBalance,
      escrowableFundsType,
      min,
      max,
    } = this.props;

    const { people, tokens, reason } = formikProps.values;

    const peopleWithData = people?.map(id => {
      return this.props.peopleById[id];
    });
    const peopleHasError = this.props.hasFieldPeople
      ? !people || people.length === 0
      : false;

    const reasonHasError = (reason === '' || !reason) && true;

    const peopleIsEmpty = !this.props.people || !this.props.people.length;

    let tokensHasError = false;

    if (!tokens || tokens <= 0 || max === 0) {
      tokensHasError = true;
    }

    if (escrowableFundsType === ESCROWABLE_FUNDS.USER_BUDGET && tokens > max) {
      tokensHasError = true;
    }

    const hasError = tokensHasError || peopleHasError;
    const isTouched = formikProps.touched.tokens || formikProps.touched.people;

    const submitDisabled =
      (hasError && isTouched && this.hasSubmitted) ||
      (escrowableFundsType === ESCROWABLE_FUNDS.USER_BUDGET && tokens > max) ||
      !this.props.isIdle;

    let tokenError = `Tokens needs to be between ${min} and ${max}`;

    if (typeof min !== 'number') {
      tokenError = `Tokens can't be more than ${max}`;
    } else if (typeof max !== 'number') {
      tokenError = `Tokens need to be at least ${min}`;
    } else if (max === 0) {
      tokenError = "You don't have any available tokens in your budget.";
    }

    return (
      <ArcView>
        <ArcView padding={this.props.inDialog ? '16' : undefined}>
          {!peopleIsEmpty && (
            <ArcView marginBottom="24" row wrap="wrap">
              {peopleWithData.map(person => (
                <ArcEntityChip
                  key={person.id}
                  onDelete={() => this.props.onDelete(person)}
                  {...person}
                />
              ))}
            </ArcView>
          )}

          {this.props.hasFieldPeople && (
            <ArcView marginBottom="24">
              <ArcPeopleMultiSelect
                error={formikProps.touched.people && peopleHasError}
                onChange={value => {
                  const userIds = value?.map(person => Number(person.id));
                  formikProps.setFieldValue('people', userIds);
                  formikProps.setFieldTouched('people', true);
                }}
                value={peopleWithData}
                options={Object.values(this.props.peopleById).map(person => ({
                  ...person,
                  value: person.id,
                  label: person.name,
                }))}
                placeholder={
                  formikProps.touched.people && peopleHasError
                    ? 'Select at least one person'
                    : 'Select people'
                }
              />
            </ArcView>
          )}

          <ArcView marginBottom="32" style={subheadingStyle}>
            <ArcView marginBottom="8">
              {LABELS_BY_ESCROWABLE_FUNDS[escrowableFundsType]}
            </ArcView>
            <ArcView display="block">
              <ArcText>{'You have '}</ArcText>
              <ArcTokenValue
                value={escrowableFundsBalance}
                verticalAlign="bottom"
              />
              <ArcText>{' available to spend.'}</ArcText>

              {!escrowableFundsBalance && (
                <ArcView display="block" marginTop="16">
                  <ArcText>
                    {'Visit '}
                    <ArcInternalLink
                      title="manage tokens"
                      href="/arcade/manage/tokens"
                      size="inherit"
                    >
                      {'manage tokens'}
                    </ArcInternalLink>
                    {' to top up your token balance.'}
                  </ArcText>
                </ArcView>
              )}
            </ArcView>
          </ArcView>

          {tokensHasError && (formikProps.touched.tokens || max === 0) && (
            <ArcView marginBottom="16">
              <ArcText color="danger">{tokenError}</ArcText>
            </ArcView>
          )}

          <ArcFormField
            autoComplete="off"
            id="tokens"
            type="tokens"
            name="tokens"
            fullWidth
            hasLabel={false}
            label={'Tokens'}
            min={min}
            max={max}
            disabled={this.props.isPending || max === 0}
            error={tokensHasError}
          />

          <ArcText marginBottom="8">
            {STRINGS['UI/AWARD_TOKEN_FORM_INTERNAL_REASON_HEADER']}
          </ArcText>

          {reasonHasError && formikProps.touched.reason && (
            <ArcView marginBottom="16">
              <ArcText color="danger">{'Please provide a reason'}</ArcText>
            </ArcView>
          )}
          <ArcFormField
            isRequired
            type="text"
            multiline
            name="reason"
            label="Reason"
          />

          <ArcView marginBottom="16">
            <ArcText color="disabled">
              {STRINGS['UI/AWARD_TOKEN_FORM_INTERNAL_REASON_TEXT']}
            </ArcText>
          </ArcView>

          {this.props.hasFieldIsPublic && (
            <ArcFormField
              type="checkbox"
              name="isPublic"
              label="Announce this to the newsfeed"
            />
          )}

          {formikProps.values.isPublic && (
            <React.Fragment>
              <ArcText marginBottom="8">
                {STRINGS['UI/AWARD_TOKEN_FORM_MESSAGE_HEADER']}
              </ArcText>
              <ArcFormField
                type="text"
                name="message"
                label="Message"
                multiline
              />
              <ArcView marginBottom="16">
                <ArcText color="disabled">
                  {STRINGS['UI/AWARD_TOKEN_FORM_MESSAGE_TEXT']}
                </ArcText>
              </ArcView>
            </React.Fragment>
          )}
        </ArcView>

        <ArcView row justify="flex-end" padding="4">
          <ArcView padding="4">
            <ArcButton onClick={this.props.onCancel} label="Cancel" />
          </ArcView>
          <ArcView padding="4">
            <ArcResourceButton
              disabled={submitDisabled}
              type="submit"
              onClick={evt => {
                evt.preventDefault();
                if (hasError) {
                  this.hasSubmitted = true;

                  formikProps.setFieldTouched('tokens', true);
                  formikProps.setFieldTouched('people', true);
                } else if (this.props.hasConfirmation) {
                  this.setIsConfirmingSubmit(true);
                } else {
                  formikProps.handleSubmit();
                }
              }}
              label={this.props.submitLabel}
              color="primary"
              variant="contained"
              status={this.props.status}
            />
          </ArcView>
        </ArcView>

        {this.props.hasConfirmation && (
          <ArcAwardTokenFormConfirmationDialog
            tokens={tokens}
            maxPeople={MAX_NUM_CONFIRMATION_PEOPLE}
            open={this.state.isConfirmingSubmit}
            onClose={this.handleConfirmingSubmitCancel}
            onConfirm={() => {
              this.setIsConfirmingSubmit(false);

              formikProps.handleSubmit();
            }}
            people={peopleWithData}
          />
        )}
      </ArcView>
    );
  };

  render() {
    const { inDialog } = this.props;

    const title = inDialog ? (
      <ArcView
        position="relative"
        row
        padding="8"
        paddingLeft="16"
        align="flex-end"
        color="grey-tint"
        borderBottom="default"
        borderBottomWidth="1"
      >
        <Typography variant="h5">
          {STRINGS['UI/AWARD_TOKEN_FORM_TITLE']}
        </Typography>

        <ArcView spacer />

        <ArcButton size="small" onClick={this.props.onCancel}>
          <Close color="action" fontSize="small" />
        </ArcButton>
      </ArcView>
    ) : null;

    const form = (
      <>
        <ArcView flexGrow="10" flexShrink="10" fullHeight>
          {title}
          <ArcForm
            initialValues={{
              ...INITIAL_VALUES,
              ...this.props.initialValues,
            }}
            onSubmit={this.props.onSubmit}
          >
            {this.renderForm}
          </ArcForm>
        </ArcView>

        <ArcErrorDialog
          open={this.props.hasFailed}
          onClose={this.props.onStatusReset}
          onConfirm={this.props.onStatusReset}
          onExited={this.props.onErrorReset}
          content={this.props.hasError ? this.props.error.message : undefined}
        />
      </>
    );

    if (inDialog) {
      return (
        <ArcResponsiveDialog
          onClose={this.props.onCancel}
          open={this.props.open}
        >
          {form}
        </ArcResponsiveDialog>
      );
    }

    return form;
  }
}

ArcAwardTokenFormPlaceholder.displayName = 'ArcAwardTokenFormPlaceholder';

ArcAwardTokenFormPlaceholder.propTypes = ArcAwardTokenForm.propTypes;
ArcAwardTokenFormPlaceholder.defaultProps = ArcAwardTokenForm.defaultProps;

export default ArcAwardTokenForm;
