import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Card } from 'react-native-paper';

import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';

import { NotificationsPanelCardPlaceholderProps } from './NotificationsPanelCardPlaceholder.d';

const NotificationsPanelCardPlaceholder: React.FC<NotificationsPanelCardPlaceholderProps> = ({
  elevation,
  header,
  roundedTop,
  roundedBottom,
}) => (
  <ArcViewBox>
    <Card
      elevation={elevation}
      style={{
        borderTopLeftRadius: roundedTop ? 4 : 0,
        borderTopRightRadius: roundedTop ? 4 : 0,
        borderBottomLeftRadius: roundedBottom ? 4 : 0,
        borderBottomRightRadius: roundedBottom ? 4 : 0,
      }}
    >
      {!!header && (
        <ArcViewBox ml={2} p={2}>
          <Skeleton height={25} width={75} />
        </ArcViewBox>
      )}

      <ArcViewBox>
        <ArcViewBox height={1} backgroundColor="#EBEBEB" />

        <ArcViewBox m={2}>
          <ArcViewBox flexDirection="row" alignItems="center" m={2}>
            <ArcViewBox mr={2}>
              <Skeleton variant="circle" height={45} width={45} />
            </ArcViewBox>
            <Skeleton height={35} width={125} />
          </ArcViewBox>

          <Card.Content>
            <Skeleton height={35} width="75%" />
          </Card.Content>

          <ArcViewBox
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            mt={3.5}
            mx={2}
          >
            <ArcViewBox mr={1}>
              <Skeleton height={45} width={100} />
            </ArcViewBox>
            <Skeleton height={45} width={100} />
          </ArcViewBox>
        </ArcViewBox>
      </ArcViewBox>
    </Card>
  </ArcViewBox>
);

NotificationsPanelCardPlaceholder.displayName =
  'NotificationsPanelCardPlaceholder';

export default NotificationsPanelCardPlaceholder;
