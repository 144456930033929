import axios from 'axios';
import { camelizeKeys } from 'humps';
import { actions } from 'arcade-frontend-core/src/actions';
import { resources } from 'arcade-frontend-core/src/resources/users';
import getResource from 'arcade-frontend-core/src/helpers/getResource';
import * as auth from 'arcade-frontend-core/src/helpers/auth';
import store from './store';

export const setRtposUser = async (token, email, callback) => {
  try {
    const payload = await axios({
      method: 'GET',
      url: 'https://rtposapi.arcadehub.co/v2/profile/user',
      headers: { 'Content-Type': 'application/json', 'X-User-Email': email, 'X-User-Token': token },
    });

    const currentUser = camelizeKeys({
      ...payload.data,
      token,
      authenticationToken: token,
      endpoint: 'https://rtposapi.arcadehub.co',
      chatEndpoint: 'https://rtpos-chat.arcadehub.co',
    });

    store.dispatch(actions.setCurrentUser({
      currentUser,
      endpoint: currentUser.endpoint,
      chatEndpoint: currentUser.chatEndpoint,
    }));

    const source = global.Platform.select({
      web: 'launch',
      default: 'app',
    });

    const featuresRequest = getResource(
      resources.apiGetUserFeatures,
      { resources: [{ source }] },
      {
        baseURL: currentUser.endpoint,
        transformResponse: [],
      },
    ).then((response) => {
      if (window?.setArcadeFeatures) {
        window.setArcadeFeatures(response.data.features);
      }

      store.dispatch(actions.appFeaturesSuccess(response));
    }).catch((error) => {
      console.error('[features/start-app] features request failed', error);
      throw error;
    });

    const permissionsRequest = getResource(
      resources.apiGetCurrentUserPermissions,
      { resources: [{ source }] },
      {
        baseURL: currentUser.endpoint,
        transformResponse: [],
      },
    ).then((response) => {
      store.dispatch(actions.appPermissionsSuccess(response));
    }).catch((error) => {
      console.error('[features/start-app] permissions request failed', error);
      throw error;
    });

    await Promise.all([
      featuresRequest,
      permissionsRequest,
    ]);

    await auth.signIn(currentUser);

    store.dispatch(actions.setAppReady(true));

    window.authenticateUser(currentUser);

    if (callback) {
      callback();
    }
  } catch (error) {
    console.log({ error });
    window.ngRootScope.$apply(() => {
      if (error?.response?.data?.error) {
        window.ngRootScope.authentication_error = error.response.data.error;
      } else {
        window.ngRootScope.authentication_error = 'Something went wrong';
      }
    });
  }
};
