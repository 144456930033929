import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import getRequestStatusFromResourceStatus from 'arcade-frontend-core/src/helpers/getRequestStatusFromResourceStatus';
import { resources } from 'arcade-frontend-core/src/resources/chatAudiences';
import ArcAudienceCreateForm from 'arcade-frontend-widgets/src/components/ArcAudienceCreateForm';

function ChatEditFormContainer(props) {
  const currentUser = useSelector(getCurrentUser);

  const apiGetChatEntities = useResourceAction(resources.apiGetChatEntities);
  const apiPutChatEntities = useResourceAction(resources.apiPutChatEntities);

  React.useEffect(() => {
    apiGetChatEntities.requestData().then(apiGetChatEntities.onStatusReset);
  }, []);

  function handleSubmit(data) {
    const normalizedData = {
      id: props.id,
      entity_ids: data.entity,
      excluded_user_ids: data.excluded,
      name: data.name,
      team_ids: data.team,
      user_ids: data.person,
      excluded_entities_by_parent_uuid: data.excludedEntitiesByParentUuid,
      parent_entity_uuids_by_selected_entity_uuid:
        data.parentEntityUuidsBySelectedEntityUuid,
    };

    apiPutChatEntities
      .requestData({
        resources: [normalizedData],
      })
      .then(resp => {
        if (!window.ArcadeMessagingService) {
          return;
        }
        const { id } = resp.data.conversation;

        window.ArcadeMessagingService.getConversations(() => {
          window.ArcadeMessagingService.selectConversation(id);
        });
      });
  }

  if (!apiGetChatEntities.hasData) {
    return null;
  }

  return (
    <ArcAudienceCreateForm
      title="Participants"
      confirmDialogContent="You will lose unsaved changes"
      currentUserId={currentUser.id}
      disableSelf
      entitiesByUuid={apiGetChatEntities.data.resources}
      initialValues={props.initialValues}
      initialNamePlaceholder="Chat Name"
      onClose={apiPutChatEntities.onStatusReset}
      onSubmit={handleSubmit}
      browserRequestStatus={getRequestStatusFromResourceStatus(
        apiGetChatEntities.status,
      )}
      confirmationRequestStatus={getRequestStatusFromResourceStatus(
        apiPutChatEntities.status,
      )}
      {...props}
    />
  );
}

ChatEditFormContainer.displayName = 'ChatEditFormContainer';

ChatEditFormContainer.propTypes = {
  id: PropTypes.string,
  initialValues: PropTypes.objectOf(PropTypes.any),
};

ChatEditFormContainer.defaultProps = {
  id: '',
  initialValues: {},
};

export default ChatEditFormContainer;
