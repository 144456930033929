import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styleTableCellGrow = {
  width: '100%',
  minWidth: 180,
};

class ArcTransactionLogHeader extends React.PureComponent {
  render() {
    return (
      <TableRow {...this.props}>
        <TableCell>When</TableCell>
        <TableCell>From</TableCell>
        <TableCell>To</TableCell>
        <TableCell style={styleTableCellGrow}>Description</TableCell>
        <TableCell align="right">Amount</TableCell>
        <TableCell align="right">Balance</TableCell>
      </TableRow>
    );
  }
}

export default ArcTransactionLogHeader;
