import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import ArcStyles from '../../styles';
import ArcView from '../../primitives/ArcView/ArcView';

const styleArcPerson = {
  color: '#8C8C8C',
  lineHeight: '24px',
};

const styleLetter = color => ({
  overflow: 'hidden',
  width: 32,
  height: 32,
  marginRight: 8,
  backgroundColor: '#fff',
  borderRadius: 2,
  color,
  border: `2px solid ${color}`,
  fontWeight: 'bold',
  lineHeight: '30px',
  textAlign: 'center',
});

const stylePeopleAmount = {
  color: ArcStyles.theme.grey3,
  marginLeft: 4,
};

const stylePeopleAmountSelected = {
  ...stylePeopleAmount,
  color: ArcStyles.theme.blue3,
};

class ArcTeam extends React.PureComponent {
  static displayName = 'ArcTeam';

  static propTypes = {
    color: PropTypes.string,
    id: PropTypes.string,
    isSelected: PropTypes.bool,
    letter: PropTypes.string,
    name: PropTypes.string.isRequired,
    peopleAmount: PropTypes.number,
    style: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    color: '',
    id: null,
    isSelected: false,
    letter: '',
    peopleAmount: undefined,
    style: null,
  };


  render() {
    const {
      id,
      color,
      letter,
      name,
      style,
      peopleAmount,
      isSelected,
      ...rest
    } = this.props;

    const teamColor = color || window.teamDisplayTools.getTeamColor(id);
    const teamLetter = letter || window.teamDisplayTools.getTeamLetter(name);

    return (
      <ArcView
        row
        align="center"
        {...rest}
        style={{
          ...styleArcPerson,
          ...style,
        }}
      >
        <ArcView style={styleLetter(teamColor)}>
          {teamLetter}
        </ArcView>
        <ArcView row>
          <ArcView>{name}</ArcView>
          {!!peopleAmount && (
            <ArcView style={isSelected ? stylePeopleAmountSelected : stylePeopleAmount}>
              &mdash; {peopleAmount} {pluralize('People', peopleAmount)}
            </ArcView>
          )}
        </ArcView>
      </ArcView>
    );
  }
}

export default ArcTeam;
