import React from 'react';
import PropTypes from 'prop-types';

import ArcTextV2 from 'arcade-frontend-ui/src/components/ArcTextV2';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';

import { ActivityIndicator, Platform } from 'react-native';
import NotificationsPanelNoResult from '../NotificationsPanelNoResult';
import NotificationsPanelCardList from '../NotificationsPanelCardList';

const STRINGS = {
  NO_RESULT_SUBTITLE:
    'Chat, comments, reactions and tagged activities will show up here.',
  NO_RESULT_TITLE: 'Nothing to show',
};

const emptyNode = (
  <ArcViewBox m={3}>
    <NotificationsPanelNoResult
      title={STRINGS.NO_RESULT_TITLE}
      subtitle={STRINGS.NO_RESULT_SUBTITLE}
    />
  </ArcViewBox>
);

const NotificationsPanelActivity = ({
  notifications,
  onMarkAsRead,
  statusGetNotificationsActivity,
  statusPutNotificationsMarkAsRead,
}) => {
  const markAsReadPendingOrFailed =
    statusGetNotificationsActivity.pending ||
    statusGetNotificationsActivity.failed;

  return (
    <ArcViewBox width="100%">
      <ArcViewBox
        mt={Platform.OS === 'web' ? 0 : 3}
        mb={Platform.OS === 'web' ? 0 : 2}
        mx={2}
        pl={2}
        pr={0}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        height={50}
      >
        <ArcViewBox flexDirection="row">
          <ArcViewBox mr={2}>
            <ArcTextV2 fontWeight={500} fontSize={20} lineHeight="30px">
              {'Activity'}
            </ArcTextV2>
          </ArcViewBox>
          <ActivityIndicator
            size={16}
            animating={statusGetNotificationsActivity.pending}
          />
        </ArcViewBox>
        <ArcResourceButton
          color="primary"
          label="Mark as read"
          disabled={markAsReadPendingOrFailed}
          display={markAsReadPendingOrFailed ? 'none' : 'flex'}
          onClick={onMarkAsRead}
          status={statusPutNotificationsMarkAsRead}
          style={{ textTransform: 'none' }}
        />
      </ArcViewBox>

      <NotificationsPanelCardList
        emptyNode={emptyNode}
        notifications={notifications}
        isPending={statusGetNotificationsActivity.pending}
      />
    </ArcViewBox>
  );
};

NotificationsPanelActivity.displayName = 'NotificationsPanelActivity';

NotificationsPanelActivity.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  onMarkAsRead: PropTypes.func,
  statusGetNotificationsActivity: PropTypes.objectOf(PropTypes.bool),
  statusPutNotificationsMarkAsRead: PropTypes.objectOf(PropTypes.bool),
};

NotificationsPanelActivity.defaultProps = {
  notifications: [],
  onMarkAsRead: global.noop,
  statusGetNotificationsActivity: {},
  statusPutNotificationsMarkAsRead: {},
};

export default NotificationsPanelActivity;
