import React, { useState, useEffect } from 'react';
import { Platform } from 'react-native';
import * as Animatable from 'react-native-animatable';

import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';

import NotificationsTypeThreeDialog from '../../NotificationsTypeThreeDialog';

const ABSOLUTE_TOP_POSITION = Platform.select({
  android: '20%',
  ios: '30%',
  default: '20%',
});

interface NotificationsDialogStackCardProps {
  notification: any;
  onClose?: (id: string) => void;
  onPrimaryAction?: (id: string) => void;
  onSecondaryAction?: (id: string) => void;
}

const NotificationsDialogStackCard: React.FC<NotificationsDialogStackCardProps> = ({
  notification,
  onClose = global.noop,
  onPrimaryAction = global.noop,
  onSecondaryAction = global.noop,
}) => {
  const [isHiding, setIsHiding] = useState(false);

  const handleDismissCard = (id: string, callback: (id: string) => void) => {
    setIsHiding(true);
    setTimeout(() => {
      callback(id);
    }, 175);
  };

  const { id, imageUrl, title, content, videoType } = notification;

  return (
    <ArcViewBox
      position="absolute"
      top={ABSOLUTE_TOP_POSITION}
      left={0}
      right={0}
      m="auto"
    >
      <ArcViewBox
        width="100%"
        alignItems="center"
        position="absolute"
        p={[3, 0]}
      >
        <Animatable.View
          animation={isHiding ? 'zoomOut' : 'fadeInUp'}
          duration={500}
        >
          <NotificationsTypeThreeDialog
            videoType={videoType}
            imageUrl={imageUrl}
            title={title}
            content={content}
            onPrimaryAction={() => handleDismissCard(id, onPrimaryAction)}
            onSecondaryAction={() => handleDismissCard(id, onSecondaryAction)}
            onClose={() => handleDismissCard(id, onClose)}
            primaryActionLabel="Yay"
            secondaryActionLabel="Back to Games"
            hasClose
          />
        </Animatable.View>
      </ArcViewBox>
    </ArcViewBox>
  );
};

NotificationsDialogStackCard.displayName = 'NotificationsDialogStackCard';

export default NotificationsDialogStackCard;
