/* eslint-disable */

// https://gist.github.com/jed/1044540
Array.prototype.unique=function(a){return function(){return this.filter(a)}}(function(a,b,c){return c.indexOf(a,b+1)<0});

// https://stackoverflow.com/a/12675966
Array.prototype.last = function() {
    return this[this.length-1];
}

// https://stackoverflow.com/a/24137301
Array.prototype.random = function () {
  return this[Math.floor((Math.random()*this.length))];
}
