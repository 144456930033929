import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const initialState = {
  menuItems: [],
};

const handleAppMenuSuccess = (state, action) => action.payload.data;

const handlers = {
  [types.APP_MENU.SUCCESS]: handleAppMenuSuccess,
  [types.SET_APP_MENU]: (_, action) => action.payload,
};

const menu = createReducer(initialState, handlers);

export default menu;

export const getMenu = state => state.app.menu;
