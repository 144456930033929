import { Platform, Text } from 'react-primitives';

import ArcPropTypes from '../../helpers/arc/propTypes';
import { createWithStyles, font } from '../../styles';

const styles = {
  ArcText: theme => ({
    root: {

      ...Platform.select({
        web: {
          fontSize: 'inherit',
        },
      }),
    },

    isLarge: {
      fontSize: font.getFontSize(1.2),
    },

    isLarger: {
      fontSize: font.getFontSize(1.4),
    },

    isLightest: {
      fontWeight: '200',
    },

    isLighter: {
      fontWeight: '400',
    },

    isLight: {
      fontWeight: '500',
    },

    isSmall: {
      fontSize: font.getFontSize(0.8),
    },

    isSmaller: {
      fontSize: font.getFontSize(0.6),
    },

    isStrong: {
      fontWeight: 'bold',
    },

    isSpaced: {
      marginRight: 4,
    },

    textOverflowEllipsis: {
      overflow: 'hidden',

      ...Platform.select({
        web: {
          display: 'block',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      }),
    },

    variantTitle: {
      fontSize: font.getFontSize(2),
    },

    sectionTitle: {
      fontSize: font.getFontSize(1.5),
      fontWeight: '500',
    },

    weight300: {
      fontWeight: '300',
    },

    weight500: {
      fontWeight: '500',
    },

    colorAction: {
      color: theme ? theme.palette.action.active : undefined,
    },

    positionRelative: {
      position: 'relative',
    },

    colorWhite: {
      color: theme.palette.common.white,
    },

    colorDanger: {
      color: theme.palette.danger.main,
    },

    colorWarning: {
      color: theme.palette.warning.main,
    },

    colorWarningDark: {
      color: theme.palette.warning.dark,
    },

    colorDisabled: {
      color: theme.palette.text.disabled,
    },

    colorSuccess: {
      color: theme.palette.success.main,
    },

    colorGreyLight: {
      color: theme.palette.grey.light,
    },

    colorGrey: {
      color: theme.palette.grey.main,
    },

    colorGrey500: {
      color: theme.palette.grey[500],
    },

    colorGrey600: {
      color: theme.palette.grey[600],
    },

    colorBlue: {
      color: theme.palette.blue.main,
    },

    colorInherit: {
      ...Platform.select({
        web: {
          color: 'inherit',
        },
      }),
    },

    fontArcade: {
      fontFamily: 'Arcade, monospace',
      ...Platform.select({
        web: {
          wordSpacing: '-6px',
        },
      }),
    },

    textAlignCenter: {
      textAlign: 'center',
    },

    textAlignLeft: {
      textAlign: 'left',
    },

    textTransformCapitalize: {
      ...Platform.select({
        web: {
          textTransform: 'capitalize',
        },
      }),
    },

    textTransformInitial: {
      ...Platform.select({
        web: {
          textTransform: 'initial',
        },
      }),
    },

    textTransformUppercase: {
      textTransform: 'uppercase',
    },

    hiddenOnlyMouse: {
      ...Platform.select({
        web: {
          '@media (hover: hover) and (pointer: fine)': {
            display: 'none',
          },
        },
      }),
    },

    hiddenOnlyTouch: {
      ...Platform.select({
        web: {
          '@media (hover: none)': {
            display: 'none',
          },
        },
        default: {
          display: 'none',
        },
      }),
    },

    marginLeft4: {
      marginLeft: 4,
    },

    marginLeft8: {
      marginLeft: 8,
    },

    marginRight4: {
      marginRight: 4,
    },

    marginRight8: {
      marginRight: 8,
    },

    marginBottom8: {
      marginBottom: theme.spacing(1),
    },

    displayFlex: {
      display: 'flex',
    },

    displayInlineBlock: {
      ...Platform.select({
        web: {
          display: 'inline-block',
        },
      }),
    },

    verticalAlignMiddle: {
      ...Platform.select({
        web: {
          verticalAlign: 'middle',
        },
      }),
    },

    visibilityHidden: {
      ...Platform.select({
        web: {
          visibility: 'hidden',
        },
      }),
    },

    cursorPointer: {
      ...Platform.select({
        web: {
          cursor: 'pointer',
        },
      }),
    },

    'size0.85': {
      ...Platform.select({
        web: {
          fontSize: '0.85em',
        },
        default: {
          fontSize: 0.85,
        },
      }),
    },

    size1: {
      ...Platform.select({
        web: {
          fontSize: '1em',
        },
        default: {
          fontSize: 1,
        },
      }),
    },

    'size1.25': {
      ...Platform.select({
        web: {
          fontSize: '1.25em',
        },
        default: {
          fontSize: 1.25,
        },
      }),
    },

    size14px: {
      fontSize: 14,
    },

    size16px: {
      fontSize: 16,
    },

    size18px: {
      fontSize: 18,
    },

    size12px: {
      fontSize: 12,
    },

    size10px: {
      fontSize: 10,
    },

    size8px: {
      fontSize: 8,
    },

    lineHeight1em: {
      ...Platform.select({
        web: {
          lineHeight: '1em',
        },
        default: {
        },
      }),
    },

    lineHeight24px: {
      ...Platform.select({
        web: {
          lineHeight: '24px',
        },
        default: {
          lineHeight: 24,
        },
      }),
    },
  }),
};

const PrimitiveText = Platform.OS === 'web' ? 'span' : Text;

const ArcText = createWithStyles(styles.ArcText)(PrimitiveText);

ArcText.propTypes = {
  isLarge: ArcPropTypes.bool,
  isLighter: ArcPropTypes.bool,
  isLight: ArcPropTypes.bool,
  isSmall: ArcPropTypes.bool,
  isSpaced: ArcPropTypes.bool,
  isStrong: ArcPropTypes.bool,
};

ArcText.defaultProps = {
  isLarge: false,
  isLighter: false,
  isLight: false,
  isSmall: false,
  isSpaced: false,
  isStrong: false,
};

export default ArcText;
