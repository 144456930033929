import { createReducer } from 'arcade-frontend-ui';
import { createSelector } from 'reselect';

import { types } from '../actions';
import { selectors as activitySelectors } from './activities';

const initialState = {};

const handleAcknowledging = (state, action) => {
  const newState = {
    ...state,
    [action.payload.id]: 'acking',
  };
  return newState;
};

const handleAcknowledged = (state, action) => {
  const newState = {
    ...state,
    [action.meta.requestAction.payload.id]: 'not_pinned',
  };
  return newState;
};

const handleAcknowledgingFailed = (state, action) => {
  const newState = { ...state };
  delete newState[action.meta.requestAction.payload.id];
  return newState;
};

const handleActivityRefresh = (state, action) => {
  const newState = { ...state };
  delete newState[action.payload.data.id];
  return newState;
};

const handlers = {
  [types.NEWSFEED_ACK_PIN.REQUEST]: handleAcknowledging,
  [types.NEWSFEED_ACK_PIN.SUCCESS]: handleAcknowledged,
  [types.NEWSFEED_ACK_PIN.FAILURE]: handleAcknowledgingFailed,
  [types.NEWSFEED_ACTIVITY.SUCCESS]: handleActivityRefresh,
};

const currentlyAcknowledging = state => state.newsfeed.acknowledgement;

export default createReducer(initialState, handlers);

const acknowledgedState = createSelector(
  activitySelectors.activitiesById,
  currentlyAcknowledging,
  (activities, acknowledging) => {
    const data = Object.entries(activities).reduce((hash, [id, act]) => {
      const { pinned } = act;
      hash[id] = acknowledging[id] || (pinned ? 'pinned' : 'not_pinned'); // eslint-disable-line no-param-reassign
      return hash;
    }, {});
    return data;
  },
);

export const selectors = { currentlyAcknowledging, acknowledgedState };
