import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const handleManageMetricsEntitySuccess = (state, action) => {
  const { data } = action.payload;

  return data;
};

const initialState = [];

const handlers = {
  [types.MANAGE_METRICS_ENTITY_INDEX.SUCCESS]: handleManageMetricsEntitySuccess,
};

const entities = createReducer(initialState, handlers);

export default entities;

export const getEntities = state => state.metricsPackage.entities;
