import { QueryParams } from '../../types/http';
import { snakeCase } from '../../helpers/snakeCase';

export const makeParams = (
  params: QueryParams,
  keys: string[] = [],
): QueryParams =>
  keys.length
    ? Object.entries(params).reduce((acc, [key, value]) => {
        if (keys.includes(key)) acc[snakeCase(key)] = value;
        return acc;
      }, {} as QueryParams)
    : ({} as QueryParams);

const dateFilterKeys = [
  'from_date',
  'fromDate',
  'to_date',
  'toDate',
  'time_period',
  'timePeriod',
  'filtered_attribute',
  'filteredAttribute',
];

export const makeDateFilterParams = (
  params: QueryParams = { timePeriod: 'this_month' },
): QueryParams => makeParams(params, dateFilterKeys);

const paginationKeys = ['page', 'rowsPerPage', 'rows_per_page'];

export const makePaginationParams = (
  params: QueryParams = { page: 0 },
): QueryParams => makeParams(params, paginationKeys);
