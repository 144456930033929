import React from 'react';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import ArcStyles, { createWithStyles } from '../../styles';

// import ArcChecklistItem from '../ArcChecklist/ArcChecklistItem';

import ArcManageChecklistItem from './ArcManageChecklistItem';

const calcPercent = (items) => {
  const { length } = items;
  const checked = items.filter(item => item.checked).length;

  return (checked / length) * 100;
};

const styles = {
  Container: theme => ({
    root: {
      overflow: 'hidden',
      textAlign: 'left',
      maxHeight: 'calc(100% - 8px)',
      background: theme.palette.background.paper,
    },

    onClick: {
      cursor: 'pointer',
    },
  }),

  ScrollContainer: () => ({
    root: {
      ...ArcStyles.rules.scroll,
      flexShrink: 1,
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);
// const ScrollContainer = createWithStyles(styles.ScrollContainer)(ArcView);

/** **Description**
  *
  * Renders a list of `<ArcManageChecklistItem />`'s
  *
*/

class ArcManageChecklist extends React.PureComponent {
  static propTypes = {
    onClick: ArcPropTypes.func.isRequired,
    id: ArcPropTypes.string.isRequired,
    items: ArcPropTypes.arrayOf(ArcPropTypes.shape({
      value: ArcPropTypes.string.isRequired,
      label: ArcPropTypes.string.isRequired,

      disabled: ArcPropTypes.bool,
      labelInfo: ArcPropTypes.string,
    })),
  };

  static defaultProps = {
    items: [],
  };

  state = {
    percent: calcPercent(this.props.items),
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items) {
      this.setState({
        percent: calcPercent(nextProps.items),
      });
    }
  }

  handleClick = (e) => {
    e.stopPropagation();

    this.props.onClick(this.props.id);
  };

  renderItems() {
    return (
      <ArcView>
        {this.props.items.map(item => (
          <ArcManageChecklistItem
            key={item.value}
            {...item}
          />
        ))}
      </ArcView>
    );
  }

  render() {
    return (
      <Container onClick={this.props.onClick ? this.handleClick : undefined}>
        {this.renderItems()}
      </Container>
    );
  }
}

export default ArcManageChecklist;
