import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { formatNumber } from 'arcade-frontend-core/src/helpers/numbers/numbers';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Skeleton from '@material-ui/lab/Skeleton';

import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';

import { ActivityIndicator } from 'react-native';
import LockIcon from '@material-ui/icons/Lock';

import {
  ArcButton,
  ArcText,
  ArcTextField,
  ArcView,
  ArcEntityAvatar,
  createWithStyles,
  ArcToken,
  ArcConfirmDialog,
  ArcResponsiveDialog,
} from 'arcade-frontend-ui';

const PeopleProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'is too short')
    .max(50, 'is too long')
    .required('is required'),
  lastName: Yup.string()
    .min(2, 'is too short')
    .max(50, 'is too long')
    .required('is required'),
  email: Yup.string().email('is invalid').required('is required'),
  recognitionStarCount: Yup.number()
    .notOneOf([0])
    .max(100, 'Weekly maximum is 100'),
});

const paperProps = {
  style: {
    margin: 16,
    width: '100%',
    maxWidth: 360,
  },
};

const styles = {
  InputField: theme => ({
    root: {
      marginTop: theme.spacing(2),
    },
  }),

  ActionButton: theme => ({
    root: {
      marginTop: theme.spacing(2),
    },
  }),

  StylePlaceholder: () => ({
    root: {
      color: 'rgb(102, 102, 102)',
      lineHeight: '24px',
      padding: '16px 8px',
      borderBottom: '1px solid rgb(243, 243, 243)',
      flex: '2 0 auto',
      height: 64,
    },
  }),

  SubmitButton: () => ({
    root: {
      position: 'relative',
    },
  }),

  EntityAvatarWrapper: theme => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: theme.spacing(2),
    },
  }),

  PersonNameText: theme => ({
    root: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: theme.font.getFontSize(1.125),
      lineHeight: '150%',
    },
  }),

  PersonDetailsText: theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.font.getFontSize(0.875),
      marginBottom: theme.spacing(1),
    },
  }),

  TokenWrapper: theme => ({
    root: {
      display: 'inline-flex',
      marginRight: theme.spacing(1.25),
      height: 12,
      width: 12,
    },
  }),

  SkeletonWrapper: theme => ({
    root: {
      margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    },
  }),

  HelperTextWrapper: theme => ({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
  }),

  HelperText: theme => ({
    root: {
      margin: 0,
      fontSize: theme.font.getFontSize(0.85),
    },
    error: {
      color: '#FD3A27',
    },
    success: {
      color: '#0290C3',
    },
  }),

  DialogContentWrapper: () => ({
    root: {
      maxWidth: 325,
      textAlign: 'left',
    },
  }),

  ErrorText: theme => ({
    root: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
  }),

  ConfirmationDialogText: theme => ({
    root: {
      marginBottom: theme.spacing(1),
      lineHeight: '150%',
      color: theme.palette.grey[700],
    },
  }),

  IconLockedStyle: theme => ({
    root: {
      height: 16,
      width: 16,
      marginRight: theme.spacing(0.25),
    },
  }),
};

const InputField = createWithStyles(styles.InputField)(ArcTextField);
const ActionButton = createWithStyles(styles.ActionButton)(ArcResourceButton);
const SubmitButton = createWithStyles(styles.SubmitButton)(ArcResourceButton);
const HelperText = createWithStyles(styles.HelperText)(ArcText);
const HelperTextWrapper = createWithStyles(styles.HelperTextWrapper)(ArcView);
const EntityAvatarWrapper = createWithStyles(styles.EntityAvatarWrapper)(
  ArcView,
);
const PersonNameText = createWithStyles(styles.PersonNameText)(ArcText);
const PersonDetailsText = createWithStyles(styles.PersonDetailsText)(ArcText);
const TokenWrapper = createWithStyles(styles.TokenWrapper)(ArcView);
const SkeletonWrapper = createWithStyles(styles.SkeletonWrapper)(ArcView);
const DialogContentWrapper = createWithStyles(styles.DialogContentWrapper)(
  ArcView,
);
const ErrorText = createWithStyles(styles.ErrorText)(ArcText);
const ConfirmationDialogText = createWithStyles(styles.ConfirmationDialogText)(
  ArcText,
);
const IconLockedStyle = createWithStyles(styles.IconLockedStyle)(LockIcon);

const STRINGS = {
  CONFIRMATION_LOCK:
    'Locking this account will prevent the user from accessing Arcade.',
  CONFIRMATION_UNLOCK:
    'Unlocking this account will allow the user access to Arcade.',
  CONFIRMATION_RESET:
    'This will reset the users password and send them an email containing the new password.',
};

class ManagePeopleProfileModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onClickEdit: PropTypes.func,
    onLockAccount: PropTypes.func,
    onUnlockAccount: PropTypes.func,
    canSetRecognitionStarCount: PropTypes.bool,
    person: PropTypes.shape({
      companyIdentifier: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
      currentBalance: PropTypes.number,
      email: PropTypes.string,
      id: PropTypes.string,
      profileImage: PropTypes.string,
      locked: PropTypes.bool,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      display: PropTypes.string,
      teamName: PropTypes.string,
      recognitionStarCount: PropTypes.number,
    }),
    onResetPassword: PropTypes.func,
    onGetUserProfile: PropTypes.func,

    statusGetUserProfile: PropTypes.shape({
      failed: PropTypes.bool,
      idle: PropTypes.bool,
      pending: PropTypes.bool,
      succeeded: PropTypes.bool,
    }),
    statusLockUserProfile: PropTypes.shape({
      failed: PropTypes.bool,
      idle: PropTypes.bool,
      pending: PropTypes.bool,
      succeeded: PropTypes.bool,
    }),
    statusResetUserPassword: PropTypes.shape({
      failed: PropTypes.bool,
      idle: PropTypes.bool,
      pending: PropTypes.bool,
      succeeded: PropTypes.bool,
    }),
    statusUpdateUserProfile: PropTypes.shape({
      failed: PropTypes.bool,
      idle: PropTypes.bool,
      pending: PropTypes.bool,
      succeeded: PropTypes.bool,
    }),
    statusUnlockUserProfile: PropTypes.shape({
      failed: PropTypes.bool,
      idle: PropTypes.bool,
      pending: PropTypes.bool,
      succeeded: PropTypes.bool,
    }),
    hasDataGetUserProfile: PropTypes.bool,
    hasErrorLockUnlockAccount: PropTypes.bool,
    hasErrorGetUserProfile: PropTypes.bool,
    hasFeatureRemainingSeats: PropTypes.bool,
    remainingSeats: PropTypes.number,
  };

  static defaultProps = {
    onLockAccount: global.noop,
    onUnlockAccount: global.noop,
    onClickEdit: global.noop,
    onClose: global.noop,
    open: false,
    canSetRecognitionStarCount: false,
    person: {},
    onResetPassword: global.noop,
    onGetUserProfile: global.noop,
    statusGetUserProfile: {
      failed: false,
      idle: true,
      pending: false,
      succeeded: false,
    },
    statusLockUserProfile: {
      failed: false,
      idle: true,
      pending: false,
      succeeded: false,
    },
    statusResetUserPassword: {
      failed: false,
      idle: true,
      pending: false,
      succeeded: false,
    },
    statusUnlockUserProfile: {
      failed: false,
      idle: true,
      pending: false,
      succeeded: false,
    },
    statusUpdateUserProfile: {
      failed: false,
      idle: true,
      pending: false,
      succeeded: false,
    },
    hasDataGetUserProfile: false,
    hasErrorGetUserProfile: false,
    hasErrorLockUnlockAccount: false,
    hasFeatureRemainingSeats: false,
    remainingSeats: null,
  };

  state = {
    openedDialog: '',
    isChangingId: true,
  };

  handleClickReset = () =>
    this.setState({ openedDialog: 'confirmation-reset' });

  handleEditProfile = values => this.props.onClickEdit(values);

  handleClickAccount = () => {
    const selectedPerson = this.props.person;

    if (selectedPerson.locked) {
      this.setState({ openedDialog: 'confirmation-unlock' });
    } else {
      this.setState({ openedDialog: 'confirmation-lock' });
    }
  };

  handleCloseConfirmationDialog = () => {
    this.setState({ openedDialog: '' });
  };

  handleConfirmUnlockAccount = () => {
    this.handleCloseConfirmationDialog();
    this.props.onUnlockAccount();
  };

  handleConfirmLockAccount = () => {
    this.handleCloseConfirmationDialog();
    this.props.onLockAccount();
  };

  handleConfirmResetPassword = () => {
    this.handleCloseConfirmationDialog();
    this.props.onResetPassword();
  };

  renderForm() {
    const { person, canSetRecognitionStarCount } = this.props;

    if (!person) {
      return null;
    }

    const displayTeamName = person.teamName
      ? person.teamName
      : 'No Team Assigned';
    const displayCurrentBalance =
      person.currentBalance > 0 ? formatNumber(person.currentBalance) : 0;

    return (
      <Formik
        enableReinitialize
        initialValues={person}
        validationSchema={PeopleProfileSchema}
        onSubmit={this.handleEditProfile}
      >
        {({
          dirty,
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
        }) => (
          <>
            <DialogContent style={{ paddingTop: 8 }}>
              <EntityAvatarWrapper>
                <ArcEntityAvatar imageUrl={person.profileImage} type="person" />
                <ArcView>
                  <PersonNameText>
                    {person.locked && <IconLockedStyle />}
                    {`${person.firstName} ${person.lastName}`}
                  </PersonNameText>
                </ArcView>
              </EntityAvatarWrapper>

              <ArcView>
                <PersonDetailsText>
                  {'Team: '}
                  <ArcText marginLeft="4" isStrong>
                    {displayTeamName}
                  </ArcText>
                </PersonDetailsText>

                <PersonDetailsText>
                  {'Current Balance: '}
                  <ArcText isStrong>
                    <TokenWrapper>
                      <ArcToken />
                    </TokenWrapper>
                    {displayCurrentBalance}
                  </ArcText>
                </PersonDetailsText>
              </ArcView>

              <ArcView>
                <InputField
                  label="First Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.firstName}
                  variant="outlined"
                />
                <InputField
                  label="Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.lastName}
                  variant="outlined"
                />
                <InputField
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email}
                  variant="outlined"
                />
                {canSetRecognitionStarCount && (
                  <InputField
                    label="Weekly Recognition Star Limit"
                    name="recognitionStarCount"
                    value={values.recognitionStarCount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.recognitionStarCount}
                    variant="outlined"
                  />
                )}
                <InputField
                  label={
                    values.companyIdentifier && values.companyIdentifier.label
                      ? values.companyIdentifier.label
                      : 'Company Identifier'
                  }
                  value={
                    values.companyIdentifier && values.companyIdentifier.id
                  }
                  onChange={handleChange}
                  name="companyIdentifier.id"
                  onBlur={handleBlur}
                  error={errors.email}
                  variant="outlined"
                />
              </ArcView>
              <ArcView align="flex-start">
                {this.props.statusResetUserPassword.succeeded ? (
                  <HelperTextWrapper>
                    <HelperText
                      success
                      className="animated animated--slow fadeIn"
                    >
                      <ArcText isStrong>{'Success!'}</ArcText>
                      {' Reset password email sent.'}
                    </HelperText>
                  </HelperTextWrapper>
                ) : (
                  <ActionButton
                    size="small"
                    label={'Reset Password'}
                    status={this.props.statusResetUserPassword}
                    variant="text"
                    color="primary"
                    onClick={this.handleClickReset}
                  />
                )}

                {person.locked ? (
                  <ActionButton
                    size="small"
                    label={'Unlock Account'}
                    status={this.props.statusUnlockUserProfile}
                    variant="text"
                    color="danger"
                    onClick={this.handleClickAccount}
                  />
                ) : (
                  <>
                    {this.props.hasErrorLockUnlockAccount ? (
                      <HelperTextWrapper>
                        <HelperText
                          error
                          className="animated animated--slow fadeIn"
                        >
                          <ArcText isStrong>{'Error'}</ArcText>
                          {` - Unable to ${
                            person.locked ? 'unlock' : 'lock'
                          } account.`}
                        </HelperText>
                      </HelperTextWrapper>
                    ) : (
                      <ActionButton
                        size="small"
                        label={'Lock Account'}
                        status={this.props.statusLockUserProfile}
                        variant="text"
                        color="danger"
                        onClick={this.handleClickAccount}
                      />
                    )}
                  </>
                )}
              </ArcView>
            </DialogContent>

            <DialogActions style={{ padding: 20 }}>
              <ArcButton onClick={this.props.onClose}>{'Close'}</ArcButton>
              <SubmitButton
                label={'Save'}
                status={this.props.statusUpdateUserProfile}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                color="primary"
                disabled={!dirty}
              />
            </DialogActions>
          </>
        )}
      </Formik>
    );
  }

  renderErrorMessage = () => (
    <ArcView
      justify="center"
      align="center"
      className="animated fadeIn"
      style={{
        position: 'absolute',
        backgroundColor: 'rgba(255, 255, 255, 0.9',
        height: '100%',
        width: '100%',
        zIndex: 1,
      }}
    >
      <ArcView className="sa">
        <ArcView className="sa-warning">
          <ArcView className="sa-warning-body" />
          <ArcView className="sa-warning-dot" />
        </ArcView>
      </ArcView>

      <ErrorText isStrong style={{ marginTop: 16, marginBottom: 32 }}>
        {'Could not load user right now'}
      </ErrorText>
      <ArcButton onClick={this.props.onGetUserProfile} color="primary">
        {'Retry'}
      </ArcButton>
    </ArcView>
  );

  renderPlaceholder = () => (
    <>
      <SkeletonWrapper row style={{ alignItems: 'center' }}>
        <Skeleton
          variant="circle"
          height={30}
          width="10%"
          style={{ marginRight: 8 }}
        />
        <Skeleton variant="rect" height={25} width="67%" />
      </SkeletonWrapper>
      <SkeletonWrapper>
        <Skeleton variant="rect" height={20} width="80%" />
      </SkeletonWrapper>
      <SkeletonWrapper>
        <Skeleton
          variant="rect"
          height={20}
          width="80%"
          style={{ marginBottom: 16 }}
        />
      </SkeletonWrapper>
      {[0, 1, 2, 3].map(index => (
        <SkeletonWrapper key={`header-${index}`}>
          <ArcView>
            <Skeleton variant="rect" height={45} width="100%" />
          </ArcView>
        </SkeletonWrapper>
      ))}
      <SkeletonWrapper>
        <Skeleton variant="rect" height={30} width="35%" />
      </SkeletonWrapper>
      <SkeletonWrapper>
        <Skeleton
          variant="rect"
          height={30}
          width="35%"
          style={{ marginBottom: 72 }}
        />
      </SkeletonWrapper>
    </>
  );

  renderConfirmationDialog = () => {
    const { openedDialog } = this.state;
    const { hasFeatureRemainingSeats, remainingSeats } = this.props;
    const disableForm = hasFeatureRemainingSeats && remainingSeats < 1;

    switch (openedDialog) {
      case 'confirmation-reset':
        return (
          <ArcConfirmDialog
            open={openedDialog === 'confirmation-reset'}
            title={'Reset Password'}
            confirmButtonColor="primary"
            cancelLabel="Close"
            confirmLabel="Reset"
            onClose={this.handleCloseConfirmationDialog}
            onConfirm={this.handleConfirmResetPassword}
            textContent={false}
            content={
              <DialogContentWrapper>
                <ConfirmationDialogText>
                  {'Are you sure you want to reset this users password?'}
                </ConfirmationDialogText>
                <ConfirmationDialogText>
                  {STRINGS.CONFIRMATION_RESET}
                </ConfirmationDialogText>
              </DialogContentWrapper>
            }
          />
        );
      case 'confirmation-lock':
        return (
          <ArcConfirmDialog
            open={openedDialog === 'confirmation-lock'}
            title={'Lock Account'}
            confirmButtonColor="danger"
            cancelLabel="Close"
            confirmLabel="Lock"
            onClose={this.handleCloseConfirmationDialog}
            onConfirm={this.handleConfirmLockAccount}
            textContent={false}
            content={
              <DialogContentWrapper>
                <ConfirmationDialogText>
                  {'Are you sure you want to lock this users account?'}
                </ConfirmationDialogText>

                <ConfirmationDialogText>
                  {STRINGS.CONFIRMATION_LOCK}
                </ConfirmationDialogText>
              </DialogContentWrapper>
            }
          />
        );
      case 'confirmation-unlock':
        return (
          <ArcConfirmDialog
            open={openedDialog === 'confirmation-unlock'}
            title={'Unlock Account'}
            confirmButtonColor="danger"
            cancelLabel="Close"
            confirmLabel={disableForm ? 'Contact support' : 'Unlock'}
            onClose={this.handleCloseConfirmationDialog}
            onConfirm={this.handleConfirmUnlockAccount}
            textContent={false}
            content={
              disableForm ? (
                <DialogContentWrapper>
                  <ConfirmationDialogText>
                    {'You do not have any seats remaining in your account.'}
                  </ConfirmationDialogText>
                </DialogContentWrapper>
              ) : (
                <DialogContentWrapper>
                  <ConfirmationDialogText>
                    {'Are you sure you want to unlock this users account?'}
                  </ConfirmationDialogText>

                  <ConfirmationDialogText>
                    {STRINGS.CONFIRMATION_UNLOCK}
                  </ConfirmationDialogText>
                </DialogContentWrapper>
              )
            }
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { person, hasDataGetUserProfile, statusGetUserProfile } = this.props;

    const showLoader = statusGetUserProfile.pending && hasDataGetUserProfile;

    return (
      <>
        <ArcResponsiveDialog
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby="form-dialog-title"
          PaperProps={paperProps}
          maxWidth={360}
        >
          <DialogTitle>
            <ArcView row align="center">
              <ArcText isStrong>
                {person && person.locked ? 'View Profile' : 'Edit Account'}
              </ArcText>

              <ArcView marginLeft="8">
                <ActivityIndicator
                  animating={showLoader}
                  hidesWhenStopped
                  color={'#0290C4'}
                  size={16}
                />
              </ArcView>
            </ArcView>
          </DialogTitle>

          {hasDataGetUserProfile && person && person.id
            ? this.renderForm()
            : this.renderPlaceholder()}

          {!!this.props.hasErrorGetUserProfile && this.renderErrorMessage()}
        </ArcResponsiveDialog>

        {!!this.state.openedDialog && this.renderConfirmationDialog()}
      </>
    );
  }
}

export default ManagePeopleProfileModal;
