import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcLink from 'arcade-frontend-ui/src/elements/ArcLink';
import * as GAME_SCENE_SIZES from 'arcade-frontend-core/src/types/game-scene-sizes';
import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';
import {
  IMAGE_BY_TYPE,
  DISPLAYABLE_TITLE_BY_TYPE,
  RACE,
  BOUNTY,
  TOURNAMENT,
  RPA_ONE_TIME,
  RPA_EVERY_TIME,
  GAME_TYPES,
} from 'arcade-frontend-core/src/types/game-formats';
import { getLocationQuery } from 'arcade-frontend-features/src/reducers/location';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcImage from 'arcade-frontend-ui/src/primitives/ArcImage';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcCircuitV2 from 'arcade-frontend-ui/src/components/ArcCircuit/ArcCircuitV2';
import ArcMountain from 'arcade-frontend-ui/src/components/ArcMountain/ArcMountainV2';
import ArcVine from 'arcade-frontend-ui/src/components/ArcVine/ArcVineV2';

import GamesSceneOneTime from '../GamesSceneOneTime';
import GamesSceneEveryTime from '../GamesSceneEveryTime';

const STRINGS = {
  'NEW_FROM_TEMPLATE_CARD/THRESHOLD_EXPLAINER': `Climb every mountain with Bounties, a personal challenge where you compete against yourself.
         Watch yourself soar up the mountain as your reach your goal. Can you get there before time expires?`,

  'NEW_FROM_TEMPLATE_CARD/FIRST_EXPLAINER': `The heat is on! Races will have you speeding around the racetrack as you attempt to overtake the
        competition and claim 1st place. Compete against your coworkers to claim the top spot.`,

  'NEW_FROM_TEMPLATE_CARD/RANKING_EXPLAINER': `The players who finish 1st, 2nd, and 3rd in the tournament will win prizes.
         Tournaments are great for encouraging progress towards longer-term goals.`,

  'NEW_FROM_TEMPLATE_CARD/RPA_ONE_TIME_EXPLAINER': `Set a prize for a one-time action!
        Each player or team competes for themselves — when they achieve the goal you set, they get the prize you choose.`,

  'NEW_FROM_TEMPLATE_CARD/RPA_EVERY_TIME_EXPLAINER': `Create an every time game if you want to attach a prize for every action.
        Employees will receive a prize of your choosing every time they hit your goal.`,
};

const useStyles = makeStyles(theme => ({
  gameTitle: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: theme.font.getFontSize(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  gameExplainerText: {
    lineHeight: theme.font.getLineHeight(1.5),
    paddingBottom: theme.spacing(1),
  },
}));

function GamesNewFromTemplateCard({ gameType }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locationQuery = useSelector(getLocationQuery);

  const handleClick = () => {
    const routeAction = {
      type: ROUTE_TYPES.ROUTE_GAMES_CREATE,
      payload: {},
      query: {
        ...locationQuery,
        gameType,
      },
    };

    dispatch(routeAction);
  };

  const renderGameTitle = () => (
    <ArcText className={classes.gameTitle}>
      {DISPLAYABLE_TITLE_BY_TYPE[gameType]}
    </ArcText>
  );

  const explainerKey = `NEW_FROM_TEMPLATE_CARD/${gameType.toUpperCase()}_EXPLAINER`;
  const gameExplainerText = STRINGS[explainerKey];

  const renderGameExplainer = () => (
    <ArcBox
      component={ArcText}
      width="100%"
      maxWidth={320}
      lineHeight="150%"
      pb={1}
    >
      {gameExplainerText}
    </ArcBox>
  );

  const renderGameScene = () => {
    switch (gameType) {
      case BOUNTY:
        return (
          <ArcBox height={318} overflow="hidden" borderRadius={8}>
            <ArcMountain isDemo size={GAME_SCENE_SIZES.SMALL} />
          </ArcBox>
        );
      case RACE:
        return (
          <ArcCircuitV2 isDemo size={GAME_SCENE_SIZES.SMALL} borderRadius={8} />
        );
      case TOURNAMENT:
        return (
          <ArcVine isDemo size={GAME_SCENE_SIZES.SMALL} borderRadius={8} />
        );
      case RPA_ONE_TIME:
        return (
          <ArcBox width="100%" height="100%" position="relative">
            <GamesSceneOneTime />
          </ArcBox>
        );
      case RPA_EVERY_TIME:
        return (
          <ArcBox width="100%" height="100%" position="relative">
            <GamesSceneEveryTime isDemo />
          </ArcBox>
        );
      default:
        return null;
    }
  };

  return (
    <ArcBox
      bgcolor="#F2F2F2"
      p={[2, 3]}
      pt={[2, 4]}
      display="flex"
      flexDirection="column"
      borderRadius={8}
      width={375}
      maxWidth="100%"
      mx="auto"
    >
      <ArcBox display="flex" flexDirection="column" height={270}>
        <ArcImage
          src={IMAGE_BY_TYPE[gameType]}
          alt={gameType}
          height={40}
          width={60}
        />
        {renderGameTitle()}
        {renderGameExplainer()}
      </ArcBox>

      <ArcBox height={300} overflow="hidden" borderRadius={8}>
        {renderGameScene()}
      </ArcBox>

      <ArcBox
        display="flex"
        flexDirection="row"
        alignSelf="flex-end"
        alignItems="baseline"
        p={1}
        mt={1}
      >
        <ArcLink href={'https://help.arcade.co/games'} title="Learn More">
          {'Learn More'}
        </ArcLink>

        <ArcBox ml={2}>
          <ArcButton
            variant="contained"
            color="primary"
            label="Create a Game"
            onClick={handleClick}
          />
        </ArcBox>
      </ArcBox>
    </ArcBox>
  );
}

GamesNewFromTemplateCard.displayName = 'GamesNewFromTemplateCard';

GamesNewFromTemplateCard.propTypes = {
  gameType: PropTypes.oneOf(GAME_TYPES),
};

GamesNewFromTemplateCard.defaultProps = {
  gameType: BOUNTY,
};

export default GamesNewFromTemplateCard;
