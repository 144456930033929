import getTransformedResponseFromResourceAction from 'arcade-frontend-core/src/helpers/getTransformedResponseFromResourceAction';

import * as resources from './profile.resources';

import apiGetProfileRaw1 from './raw/apiGetProfileRaw-1.json';

export const apiGetProfileRaw = apiGetProfileRaw1;

export const apiGetProfile = getTransformedResponseFromResourceAction(
  resources.apiGetProfile,
  apiGetProfileRaw,
  {
    resources: ['1397'],
  },
);
