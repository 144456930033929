import React from 'react';
import {
  ArcPropTypes,
  ArcTable,
  ArcTableCellPerson,
  ArcStyles,
  ArcView,
  ArcText,
} from 'arcade-frontend-ui';

const styleDate = {
  style: {
    color: ArcStyles.theme.arcSubheaderColor,
    textTransform: 'uppercase',
    fontSize: '0.8em',
  },
};

const date = {
  Header: 'Date',
  accessor: 'formattedCreatedAt',
  width: 120,
  getProps: () => styleDate,
};

const team = {
  Header: 'Team',
  accessor: 'teamName',
  headerClassName: 'show-large',
  className: 'show-large',
  Cell: row => (
    <ArcView row align="center">
      <ArcText>{row.value}</ArcText>
    </ArcView>
  ),
};

const name = {
  Header: 'Name',
  accessor: 'name',
  Cell: ArcTableCellPerson,
};

const amount = {
  Header: 'Amount',
  accessor: 'score',
  Cell: row => (
    <ArcView row align="center">
      <ArcText isSmall isLight isSpaced>{row.value.unit}</ArcText>
      <ArcText>{row.value.quantity}</ArcText>
    </ArcView>
  ),
};

const hasTeams = events => events.filter(event => !!event.teamName).length > 0;

class GameEvents extends React.PureComponent {
  static displayName = 'GameEvents';

  static propTypes = {
    events: ArcPropTypes.gameEvents.isRequired,
  };

  constructor(props) {
    super(props);
    this.setColumns(props.events);
  }

  componentWillReceiveProps(nextProps) {
    const { events } = nextProps;

    if (events !== this.props.events) {
      this.setColumns(events);
    }
  }

  setColumns(events) {
    if (hasTeams(events)) {
      this.columns = [
        date,
        team,
        name,
        amount,
      ];
    } else {
      this.columns = [
        date,
        name,
        amount,
      ];
    }
  }

  render() {
    return (
      <ArcTable
        isResponsive
        isFullWidth
        reactTableColumns={this.columns}
        reactTableData={this.props.events}
        defaultPageSize={6}
        showPageJump={false}
        showPageSizeOptions={false}
        minRows={this.props.events.length ? 0 : 5}
      />
    );
  }
}

export default GameEvents;
