import Axios from 'axios';

const API = 'https://api.linkpreview.net';
const key = '5d43a67933fdaf1ce00c04b55f6c2d825498cc86a296e';
//
// const data = {
//   description: 'Support Grey making videos: https://www.patreon.com/cgpgrey ## Robots, Etc: Terex Port automation: http://www.terex.com/port-solutions/en/products/new-equipm...',
//   image: 'http://i2.ytimg.com/vi/7Pq-S557XQU/hqdefault.jpg',
//   title: 'Humans Need Not Apply',
//   url: 'https://www.youtube.com/watch?v=7Pq-S557XQU',
// };

export const unfurling = url => Axios.get(`${API}/?key=${key}&q=${url}`);
// export const unfurling = url => Promise.resolve({ data });
