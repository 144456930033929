import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ArcImage from '../../primitives/ArcImage';
import ArcViewBox, { ArcViewBoxProps } from '../ArcViewBox';

export interface ArcCoverAlignedImageProps extends ArcViewBoxProps {
  alt: string;
  height: string | number;
  src: string;
  width: string | number;
}

export function ArcCoverAlignedImage({
  alt,
  height,
  src,
  style,
  width,
  ...props
}: ArcCoverAlignedImageProps) {
  const rootStyle = useMemo(
    () => ({
      backgroundImage: `url(${src})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      ...style,
    }),
    [src, style],
  );

  return (
    <ArcViewBox
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      style={rootStyle}
      {...props}
    />
  );
}

ArcCoverAlignedImage.defaultProps = {
  width: '24px',
  height: '24px',
};

export default ArcCoverAlignedImage;
