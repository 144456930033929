function getRequestStatusFromResourceStatus(resourceStatus) {
  if (resourceStatus.pending) {
    return 'REQUEST';
  }

  if (resourceStatus.succeeded) {
    return 'SUCCESS';
  }

  if (resourceStatus.failed) {
    return 'FAILURE';
  }

  return 'DEFAULT';
}

export default getRequestStatusFromResourceStatus;
