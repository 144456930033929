import sagas from 'arcade-frontend-core/src/helpers/sagas';
import RollbarClient from 'arcade-frontend-ui/src/providers/RollbarProvider/client';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';

import { api } from '../../resources/manage/tokens';
import { actions, types } from '../../actions/manage/tokens';

const takeLatestSaga = [
];

const takeEverySaga = [
  {
    type: types.MANAGE_TOKENS_INDEX,
    call: api.index,
  },
  {
    type: types.MANAGE_TOKENS_TRANSFER,
    call: api.transfer,
    onSuccess: actions.apiManageTokensIndexRequest,
    onFailure: actions.apiManageTokensIndexRequest,
  },
  {
    type: types.MANAGE_TOKENS_TOP_UP,
    call: api.topUp,
    onSuccess: [
      () => actions.manageTokensSetHasToppedUp(true),
      resp => actions.manageTokensSetTopUpUrl(resp.data.redirect_link),
      resp => actions.manageTokensSetTopUpResponse(resp.data.message),
    ],
    onFailure: [
      () => actions.manageTokensSetHasToppedUp(true),
      (error, state) => {
        RollbarClient.critical('TOKEN TOP UP FAILED', {
          error,
          currentUser: getCurrentUser(state),
        });
        return actions.manageTokensSetTopUpError(error);
      },
    ],
  },
];

const tokens = [
  ...takeLatestSaga.map(sagas.makeTakeLatest),
  ...takeEverySaga.map(sagas.makeTakeEvery),
];

export default tokens;
