import React from 'react';

import {
  ArcPropTypes,
  ArcChecklist as ArcElementsChecklist,
} from 'arcade-frontend-ui';

const styleArcChecklist = {
  background: '#ffffff',
};

const styleArcChecklistPopover = {
  ...styleArcChecklist,
  position: 'fixed',
  zIndex: 99999,
  top: 0,
  width: 320,
  maxWidth: 'calc(100% - 16px)',
  left: '50%',
  transform: 'translateX(-50%)',
  boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
};

class ArcChecklist extends React.PureComponent {
  static propTypes = {
    checklist: ArcPropTypes.shape({
      objectives: ArcPropTypes.arrayOf(ArcPropTypes.shape({})),
    }),

    onClose: ArcPropTypes.func,
    onClaimReward: ArcPropTypes.func,
    isPopover: ArcPropTypes.bool,
    onClickItem: ArcPropTypes.func,
  };

  static defaultProps = {
    checklist: {
      objectives: [],
    },

    onClose: undefined,
    onClaimReward: undefined,
    isPopover: false,
    onClickItem: ArcPropTypes.noop,
  };

  render() {
    const {
      checklist,
      onClose,
      onClaimReward,
      onClickItem,
      isPopover,
      ...rest
    } = this.props;

    return (
      <ArcElementsChecklist
        items={checklist}
        labelPlaceholder="Nothing todo today"
        title="TO DOs"
        style={isPopover ? styleArcChecklistPopover : styleArcChecklist}
        hasClose={isPopover}
        onClose={onClose}
        onClaimReward={onClaimReward}
        onClickItem={onClickItem}
        {...rest}
      />
    );
  }
}

export default ArcChecklist;
