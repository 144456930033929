import { makeApiActions } from 'arcade-frontend-ui';

import normalizers from './normalizers';

const people = ({ showLockedUsers }) => ({
  method: 'GET',
  url: showLockedUsers ? '/manager/v4/users' : '/v4/users',
  rootKey: 'users',
  normalizer: normalizers.fromUserIndex,
});

const quicklinks = ({ userId }) => ({
  method: 'GET',
  url: '/v2/profile/quick',
  rootKey: 'user',
  params: {
    id: userId,
  },
});

const endpoints = {
  people,
  quicklinks,
};

const api = makeApiActions(endpoints);

export default api;
