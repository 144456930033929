import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const GameControllerTeam = props => (
  <Icon {...props}>
    <Path
      stroke="currentColor"
      fill="transparent"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M22 12.87a3.652 3.652 0 00-3.652-3.653H4.652A3.652 3.652 0 001 12.87v7.304C1 21.182 1.818 22 2.826 22c1.337 0 2.606-1.643 4.291-4.16a.913.913 0 01.758-.405h7.242c.305 0 .589.152.758.404C17.565 20.352 18.834 22 20.167 22a1.826 1.826 0 001.826-1.826L22 12.87z"
      clipRule="evenodd"
    />
    <Path
      stroke="currentColor"
      fill="transparent"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.435 15.38c.126 0 .228.103.228.229m-.457 0a.23.23 0 01.229-.229m0 .457a.228.228 0 01-.229-.228m.457 0a.228.228 0 01-.228.228m-11.87-3.88v3.652m1.826-1.826H3.74m11.869-.229c.126 0 .228.102.228.229m-.457 0c0-.127.103-.229.229-.229m0 .456a.228.228 0 01-.229-.227m.457 0a.228.228 0 01-.228.228m3.651-.457a.23.23 0 01.23.229m-.457 0c0-.127.102-.229.228-.229m-.001.456a.228.228 0 01-.227-.227m.457 0a.228.228 0 01-.23.228m-1.825-2.283c.126 0 .228.103.228.229m-.457 0c0-.127.103-.229.229-.229m0 .457a.228.228 0 01-.229-.229m.457.001a.228.228 0 01-.228.228m3.652-4.794a3.71 3.71 0 00-6.391 0 3.71 3.71 0 00-6.392 0 3.71 3.71 0 00-6.39 0"
    />
    <Path
      stroke="currentColor"
      fill="transparent"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.891 4.652a1.826 1.826 0 100-3.652 1.826 1.826 0 000 3.652zm-6.391 0A1.826 1.826 0 1011.5 1a1.826 1.826 0 000 3.652zm-6.391 0a1.826 1.826 0 100-3.652 1.826 1.826 0 000 3.652z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GameControllerTeam;
