import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import ArcPropTypes from '../../helpers/arc/propTypes';


const PaperProps = {
  square: true,
  style: {
    border: '4px solid #f3f3f3',
  },
};

class ArcDialog extends React.PureComponent {
  static propTypes = {
    children: ArcPropTypes.children,
  };

  static defaultProps = {
    children: null,
  };

  render() {
    return (
      <Dialog
        PaperProps={PaperProps}
        {...this.props}
      >
        {this.props.children}
      </Dialog>
    );
  }
}

export default ArcDialog;
