import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import SwipeableViews from 'react-swipeable-views';

import {
  createWithStyles,
  ArcButton,
  ArcIconButton,
  ArcImage,
  ArcLink,
  ArcMobileStepper,
  ArcText,
  ArcView,
} from 'arcade-frontend-ui';
import Logo from 'arcade-frontend-ui/src/assets/Logo';

import ArcProfileForm from '../ArcProfileForm';
import chest from './assets/chest.svg';
import clouds from './assets/clouds.png';
import community from './assets/community.svg';
import games from './assets/games.svg';
import ground from './assets/ground.svg';
import prizes from './assets/prizes.svg';

const animationStyles = {
  animationDelay500: {
    animationDelay: 500,
  },

  animationDelay1000: {
    animationDelay: 1000,
  },

  animationDelay1500: {
    animationDelay: 1500,
  },

  animationDelay2000: {
    animationDelay: 2000,
  },

  animationDelay2500: {
    animationDelay: 2500,
  },

  animationDelay3000: {
    animationDelay: 3000,
  },

  animationDelay3500: {
    animationDelay: 3500,
  },

  animationDelay4000: {
    animationDelay: 4000,
  },
};

const styles = {
  Text: theme => ({
    root: {
      fontWeight: '400',
    },

    sizeMedium: {
      fontSize: theme.font.getFontSize(1.125),
      lineHeight: theme.font.getLineHeight(1.85),
    },
    sizeLarge: {
      fontSize: theme.font.getFontSize(1.5),
      lineHeight: theme.font.getLineHeight(2),
    },

    isSpaced: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },

    alignCenter: {
      textAlign: 'center',
    },

    ...animationStyles,
  }),

  TextContent: theme => ({
    root: {
      padding: theme.spacing(1),
    },
  }),

  Title: theme => ({
    root: {
      width: '100%',
      fontWeight: '600',
      fontSize: theme.font.getFontSize(1.85),
      marginBottom: theme.spacing(2),
    },

    ...animationStyles,

    marginTopExtra: {
      marginTop: theme.spacing(13),
    },

    noMarginBottom: {
      marginBottom: 0,
    },

    wrap: {
      maxWidth: 256,
      alignSelf: 'flex-start',
      lineHeight: theme.font.getLineHeight(3),
    },
  }),

  Container: ({ palette, shadows, ...theme }) => ({
    root: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%',
      background: palette.background.paper,
      boxShadow: [shadows[2], shadows[4], shadows[16]],
      margin: 'auto',
      userSelect: 'none',

      borderColor: palette.purple.main,

      borderWidth: 4,
      borderStyle: 'solid',

      transition: theme.transitions.create([
        'background-color',
        'border-color',
      ]),

      [theme.breakpoints.up('sm')]: {
        maxWidth: 420,
        maxHeight: 736,
      },
    },

    isFirstSignIn: {
      position: 'relative',
      background: '#e9fbff',
      borderColor: 'transparent',
      backgroundImage: `url(${ground})`,
      backgroundRepeat: 'repeat-x',
      backgroundPositionY: 'calc(100% + 24px)',
      backgroundPositionX: 'left',
      '& > *': {
        zIndex: 99,
      },
    },

    hasStarted: {
      background: palette.background.paper,
      borderColor: palette.primary.main,
    },

    borderColorBlue: {
      borderColor: palette.blue.main,
    },

    borderColorGreen: {
      borderColor: palette.green.main,
    },

    borderColorPurple: {
      borderColor: palette.purple.main,
    },
  }),

  EndTourContainer: () => ({
    root: {
      position: 'absolute',
      top: 16,
      left: 8,
    },
  }),

  SwipeableContainer: () => ({
    root: {
      flexGrow: 10,
      flexShrink: 10,
      height: 'calc(100% - 58px)',

      '& .react-swipeable-view-container': {
        height: '100%',
      },

      '& > div': {
        overflow: 'hidden',
        flexGrow: 10,
      },
    },
  }),

  Content: theme => ({
    root: {
      position: 'relative',
      overflow: 'hidden',
      flexGrow: 10,
      padding: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2),
      },
    },

    fullHeight: {
      height: '100%',
    },
  }),

  ImageContainer: theme => ({
    root: {
      height: '40%',
      width: '100%',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),

      '& img': {
        height: '100%',
      },
    },

    noMarginBottom: {
      marginBottom: 0,
    },

    clouds: {
      width: 'initial',
      height: 'initial',
      position: 'absolute',
      top: '10%',
      zIndex: -1,
      opacity: 0.5,
      marginBottom: 0,
      animation: 'animateClouds 35s linear infinite',
      transform: 'translateZ(0)',
    },

    '@keyframes animateClouds': {
      from: {
        marginLeft: '-100%',
      },
      to: {
        marginLeft: '100%',
      },
    },
  }),

  Element: () => ({
    root: {},

    ...animationStyles,
  }),
};

const styleButton = {
  minWidth: 90,
};

const Container = createWithStyles(styles.Container)(ArcView);
const Content = createWithStyles(styles.Content)(ArcView);
const EndTourContainer = createWithStyles(styles.EndTourContainer)(ArcView);
const ImageContainer = createWithStyles(styles.ImageContainer)(ArcView);
const SwipeableContainer = createWithStyles(styles.SwipeableContainer)(ArcView);
const Title = createWithStyles(styles.Title)(ArcText);
const Text = createWithStyles(styles.Text)(ArcText);
const TextContent = createWithStyles(styles.TextContent)(ArcView);
const Element = createWithStyles(styles.Element)(ArcView);

const styleBackdrop = {
  zIndex: 99999,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0,0,0,0.77)',
};

const styleMarginRight = {
  marginRight: 8,
};

const typographyStyle = {
  color: '#3B3B3B',
};

const ANIMATED_DURATION = 500;
const IS_SWITCHING_DELAY = 1000;

const handleStopPropagation = e => e.stopPropagation();

const COLORS_BY_STEP = {
  '-1': 'purple',
  0: 'purple',
  1: 'blue',
  2: 'green',
};

const titleScreen = (
  <Content align="center" className="animated fadeIn">
    <ArcView spacer />

    <Element
      align="center"
      justify="center"
      animationDelay={500}
      className="animated fadeInDown"
    >
      <Logo size="lg" />
    </Element>

    <Title
      textAlign="center"
      animationDelay={1500}
      className="animated fadeInUp"
      marginTopExtra
      noMarginBottom
    >
      {'Help & Support'}
    </Title>

    <Element
      spacer
      align="center"
      justify="center"
      animationDelay={1500}
      className="animated fadeInLeft"
    >
      <Text isSpaced align="center">
        <ArcLink target="_blank" href="https://arcade.co/support" isLarge>
          {'View Guides'}
        </ArcLink>
      </Text>
      <Text isSpaced align="center">
        <ArcLink
          target="_blank"
          href="https://arcade.co/support/request"
          isLarge
        >
          {'Open a Support Request'}
        </ArcLink>
      </Text>
    </Element>

    <ArcView spacer />

    <Element
      spacer
      align="center"
      justify="center"
      animationDelay={3000}
      className="animated fadeIn"
    >
      <Text isSpaced align="center">
        {'Want to take the tour again?'}
      </Text>
    </Element>
  </Content>
);

class ArcTourStepper extends React.PureComponent {
  static propTypes = {
    activeStep: PropTypes.number,
    canDismiss: PropTypes.bool,
    firstName: PropTypes.string,
    hasFinishedForm: PropTypes.bool,
    hasStartedForm: PropTypes.bool,
    isFirstSignIn: PropTypes.bool,
    isViewingForm: PropTypes.bool,
    lastName: PropTypes.string,
    onFinish: PropTypes.func,
    onProfileFormSubmit: PropTypes.func,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.node.isRequired,
        imageUrl: PropTypes.string.isRequired,
        imageStyle: PropTypes.shape({}),
      }),
    ),
  };

  static defaultProps = {
    activeStep: -1,
    canDismiss: false,
    firstName: '',
    hasFinishedForm: false,
    hasStartedForm: false,
    isFirstSignIn: false,
    isViewingForm: false,
    lastName: '',
    onFinish: global.noop,
    onProfileFormSubmit: global.noop,
    steps: [
      {
        title: 'Earn Rewards & Prizes',
        description:
          'From movie tickets to an extra vacation, earn prizes in Arcade when you do well at work.',
        imageUrl: prizes,
      },
      {
        title: 'Play Games At Work',
        description:
          'Engage with co-workers as you compete in exciting mini-games based on your performance each day.',
        imageUrl: games,
        imageStyle: {
          // Magic number here to line the image up on the right edge
          marginRight: -24,
          marginLeft: 'auto',
        },
      },
      {
        title: 'Celebrate Success Together',
        description:
          'Connect with peers across the company with comments, likes, tags, and more. Give public shout-outs to celebrate your co-workers.',
        imageUrl: community,
      },
    ],
  };

  state = {
    activeStep: this.props.activeStep,
    hasFinishedForm: this.props.hasFinishedForm,
    hasStartedForm: this.props.hasStartedForm,
    isFinished: false,
    isViewingForm: this.props.isViewingForm,
  };

  componentDidMount() {
    this.hasMounted = true;

    setTimeout(() => {
      this.isSwitching = false;
    }, IS_SWITCHING_DELAY);
  }

  componentWillUnmount() {
    this.hasMounted = false;
  }

  get hasStarted() {
    return this.state.activeStep >= 0;
  }

  get isLastStep() {
    return this.state.activeStep === this.maxSteps - 1;
  }

  get maxSteps() {
    return this.props.steps.length;
  }

  setActiveStep = activeStep => this.setState({ activeStep });

  setHasFinishedForm = hasFinishedForm => this.setState({ hasFinishedForm });

  setHasStartedForm = hasStartedForm => this.setState({ hasStartedForm });

  setIsFinished = isFinished => this.setState({ isFinished });

  setIsViewingForm = isViewingForm => this.setState({ isViewingForm });

  isSwitching = true;

  incrementActiveStep = (steps = 1) =>
    this.setState(prevState => ({
      activeStep: prevState.activeStep + steps,
    }));

  finish = () => {
    if (!this.hasMounted) {
      return;
    }
    this.setIsFinished(true);

    setTimeout(() => {
      this.reset();
      this.props.onFinish();
    }, ANIMATED_DURATION);
  };

  reset = () => {
    if (!this.hasMounted) {
      return;
    }

    this.setIsFinished(false);
    this.setActiveStep(-1);
  };

  handleBack = () => {
    this.incrementActiveStep(-1);

    if (this.state.isViewingForm) {
      this.setIsViewingForm(false);
    }
  };

  handleClickBackdrop = () => {
    if (this.isSwitching || !this.props.canDismiss) {
      return;
    }

    this.finish();
  };

  handleFinish = () => {
    if (this.props.isFirstSignIn && !this.state.isViewingForm) {
      this.setIsViewingForm(true);
    } else {
      this.finish();
    }
  };

  handleFormStartClick = () => {
    this.setHasStartedForm(true);
  };

  handleNext = () => this.incrementActiveStep(1);

  handleProfileFormFinish = () => this.finish();

  handleProfileFormSubmit = values => {
    this.setHasFinishedForm(true);
    this.props.onProfileFormSubmit(values);
  };

  handleStepChange = activeStep => this.setActiveStep(activeStep);

  handleStartTour = () => this.setActiveStep(0);

  handleSlideEnd = () => {
    window.clearTimeout(this.isSwitchingTimeout);

    this.isSwitchingTimeout = setTimeout(() => {
      this.isSwitching = false;
    }, 1000);
  };

  handleSlideStart = () => {
    this.isSwitching = true;
  };

  renderMainScreen() {
    if (this.state.isViewingForm) {
      return this.renderForm();
    }

    return (
      <SwipeableContainer className="animated fadeIn">
        <SwipeableViews
          enableMouseEvents
          index={this.state.activeStep}
          onChangeIndex={this.handleStepChange}
          onMouseDown={this.handleSlideStart}
          onMouseUp={this.handleSlideEnd}
          onTouchEnd={this.handleSlideEnd}
          onTouchStart={this.handleSlideStart}
          onTransitionEnd={this.handleSlideEnd}
        >
          {this.props.steps.map(this.renderStep)}
        </SwipeableViews>
      </SwipeableContainer>
    );
  }

  renderForm() {
    return (
      <ArcView
        align="center"
        className="animated fadeIn"
        flexGrow="10"
        flexShrink="10"
        padding="24"
        marginTop="32"
        textAlign="center"
      >
        <ArcView marginTop="32" marginBottom="32">
          <Typography variant="h4" style={typographyStyle}>
            <ArcText weight="500">{'Earn an Epic Chest'}</ArcText>
          </Typography>
        </ArcView>
        <ArcView marginBottom="32">
          <ArcImage src={chest} alt={'chest'} width="180px" height="140px" />
        </ArcView>
        <ArcView marginBottom="32">
          <Typography variant="h6" style={typographyStyle}>
            {'Fill out your profile to unlock'}
          </Typography>
        </ArcView>
        <ArcView marginBottom="32">
          <ArcButton
            color="purple"
            label="Start Now"
            variant="contained"
            size="large"
            onClick={this.handleFormStartClick}
          >
            <ArcView marginRight="16">{'Start Now'}</ArcView>
            <KeyboardArrowRight />
          </ArcButton>
        </ArcView>
        <ArcView marginBottom="32">
          <Typography variant="caption" style={typographyStyle}>
            {'Quest is available for 14 days'}
          </Typography>
        </ArcView>
      </ArcView>
    );
  }

  renderStep = step => (
    <Content key={step.title} align="center" fullHeight>
      <ImageContainer>
        <ArcImage
          src={step.imageUrl}
          alt={step.title}
          style={step.imageStyle}
        />
      </ImageContainer>

      <TextContent>
        <Title wrap>{step.title}</Title>
        <Text size="medium">{step.description}</Text>
      </TextContent>
    </Content>
  );

  renderStepper() {
    const { activeStep, isViewingForm } = this.state;

    let buttonIcon = <KeyboardArrowRight />;
    let buttonText = 'Next';
    let color = COLORS_BY_STEP[activeStep] || 'secondary';

    if (this.isLastStep) {
      buttonIcon = isViewingForm ? null : <Check />;
      buttonText = isViewingForm ? 'Maybe Later' : 'Done';
      color = isViewingForm ? 'purple' : 'secondary';
    }

    return (
      <ArcMobileStepper
        activeStep={activeStep}
        color={color}
        steps={this.maxSteps}
        position="static"
        backButton={
          <ArcButton
            onClick={this.handleBack}
            size="small"
            style={styleButton}
            label="Back"
          >
            <KeyboardArrowLeft style={styleMarginRight} />
            {'Back'}
          </ArcButton>
        }
        nextButton={
          <ArcButton
            color={color}
            onClick={this.isLastStep ? this.handleFinish : this.handleNext}
            size="small"
            variant={
              this.isLastStep && !isViewingForm ? 'contained' : 'outlined'
            }
            style={styleButton}
            label={buttonText}
          >
            <ArcText style={buttonIcon ? styleMarginRight : undefined}>
              {buttonText}
            </ArcText>
            {buttonIcon}
          </ArcButton>
        }
        variant="squared"
      />
    );
  }

  renderEndTour() {
    if (!this.props.canDismiss || this.isLastStep) {
      return null;
    }

    return (
      <EndTourContainer>
        <ArcIconButton onClick={this.handleFinish}>
          <Close />
        </ArcIconButton>
      </EndTourContainer>
    );
  }

  renderStartTour() {
    return (
      <ArcView row justify="space-between" padding="8">
        <ArcButton
          onClick={this.handleFinish}
          size="small"
          variant="text"
          style={styleButton}
        >
          <Close style={styleMarginRight} />
          Close
        </ArcButton>

        <ArcButton
          color="purple"
          onClick={this.handleStartTour}
          size="small"
          variant="contained"
        >
          <ArcText style={styleMarginRight}>{'Start Tour'}</ArcText>
          <KeyboardArrowRight />
        </ArcButton>
      </ArcView>
    );
  }

  renderFirstScreen() {
    if (this.props.isFirstSignIn) {
      return (
        <Content align="center" className="animated fadeIn">
          <ImageContainer clouds>
            <ArcImage src={clouds} alt={'clouds'} />
          </ImageContainer>

          <ArcView spacer />

          <Element
            align="center"
            justify="center"
            animationDelay={500}
            className="animated bounceInDown"
          >
            <Logo size="lg" />
          </Element>

          <Title
            textAlign="center"
            animationDelay={1500}
            className="animated fadeInUp"
            marginTopExtra
            noMarginBottom
          >
            {'Welcome!'}
          </Title>

          <Text
            align="center"
            animationDelay={2500}
            className="animated fadeIn"
            isSpaced
            size="large"
          >
            {'Arcade makes work more fun and helps everyone succeed.'}
          </Text>

          <ArcView spacer />

          <Element animationDelay={4000} className="animated fadeInUp">
            <ArcButton
              color="purple"
              fullWidth
              label="Get Started"
              onClick={this.handleStartTour}
              variant="contained"
            />
          </Element>

          <ArcView spacer />
        </Content>
      );
    }

    return (
      <ArcView align="center" flexGrow="10">
        {titleScreen}
      </ArcView>
    );
  }

  renderChildren() {
    if (this.state.hasStartedForm) {
      return (
        <ArcProfileForm
          onSubmit={this.handleProfileFormSubmit}
          onFinish={this.handleProfileFormFinish}
          initialValues={{
            firstname: this.props.firstName,
            lastname: this.props.lastName,
          }}
        />
      );
    }

    const { isFirstSignIn } = this.props;

    return (
      <React.Fragment>
        {this.hasStarted ? this.renderMainScreen() : this.renderFirstScreen()}
        {!this.hasStarted && !isFirstSignIn && this.renderStartTour()}
        {this.hasStarted && !isFirstSignIn && this.renderEndTour()}
        {this.hasStarted && this.renderStepper()}
      </React.Fragment>
    );
  }

  render() {
    const { isFirstSignIn } = this.props;

    let color = COLORS_BY_STEP[this.state.activeStep] || 'green';

    if (this.isLastStep) {
      color = this.state.isViewingForm ? 'purple' : 'green';
    }

    if (this.state.hasStartedForm) {
      color = 'blue';
    }

    if (this.state.hasFinishedForm) {
      color = 'purple';
    }

    return (
      <ArcView
        className={
          this.state.isFinished ? 'animated fadeOut' : 'animated fadeIn'
        }
        onClick={this.handleClickBackdrop}
        style={styleBackdrop}
      >
        <Container
          className={
            this.state.isFinished ? 'animated zoomOut' : 'animated zoomIn'
          }
          onClick={handleStopPropagation}
          isFirstSignIn={isFirstSignIn}
          borderColor={color}
          hasStarted={this.hasStarted}
        >
          {this.renderChildren()}
        </Container>
      </ArcView>
    );
  }
}

export default ArcTourStepper;
