import { types } from '../actions';

const initialState = false;

const onboardingTourOpen = (state = initialState, action) => {
  switch (action.type) {
    case types.APP_SET_ONBOARDING_TOUR_OPEN:
      return action.payload;
    default:
      return state;
  }
};

export const getOnboardingTourOpen = state => state.app.onboardingTourOpen;

export default onboardingTourOpen;
