import React from 'react';

function ArcOneTime(props) {
  return (
    <svg
      viewBox="0 0 403 489"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144 92.424C163.564 92.424 179.424 76.5641 179.424 57C179.424 37.4358 163.564 21.576 144 21.576C124.436 21.576 108.576 37.4358 108.576 57C108.576 76.5641 124.436 92.424 144 92.424Z"
        fill="#FFCA13"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.2 84.5241L138.103 91.8998C135.877 91.5265 133.722 90.9453 131.657 90.1769L131.797 81.7248C134.967 83.2986 138.483 84.28 142.2 84.5241ZM145.802 84.5282L149.897 91.8997C152.123 91.5263 154.279 90.9451 156.343 90.1766L156.204 81.757C153.033 83.3209 149.517 84.2928 145.802 84.5282ZM159.337 79.9464L166.552 84.2731C168.27 82.8511 169.851 81.27 171.273 79.5524L166.947 72.337C164.934 75.3446 162.344 77.9337 159.337 79.9464ZM168.757 69.2038L177.177 69.3432C177.945 67.2786 178.526 65.1229 178.9 62.8968L171.529 58.8024C171.293 62.5177 170.321 66.033 168.757 69.2038ZM171.525 55.1998L178.9 51.1032C178.526 48.8771 177.945 46.7214 177.177 44.6568L168.726 44.7967C170.3 47.9666 171.281 51.4825 171.525 55.1998ZM166.926 41.6968L171.273 34.4475C169.851 32.7299 168.27 31.1488 166.552 29.7268L159.286 34.0839C162.305 36.0947 164.905 38.6854 166.926 41.6968ZM156.203 32.3066L156.343 23.8232C154.279 23.0547 152.123 22.4735 149.897 22.1001L145.768 29.5338C149.495 29.7653 153.022 30.7382 156.203 32.3066ZM142.235 29.5378L138.103 22.1C135.877 22.4733 133.722 23.0545 131.657 23.8229L131.798 32.3388C134.977 30.7605 138.505 29.778 142.235 29.5378ZM128.749 34.1047L121.447 29.7266C119.73 31.1484 118.149 32.7294 116.727 34.447L121.105 41.7486C123.124 38.7254 125.726 36.124 128.749 34.1047ZM119.339 44.7978L110.823 44.6568C110.054 46.7213 109.473 48.8768 109.1 51.1028L116.539 55.235C116.779 51.5052 117.761 47.9774 119.339 44.7978ZM116.535 58.7672L109.1 62.8972C109.473 65.1232 110.054 67.2787 110.823 69.3432L119.308 69.2028C117.739 66.0221 116.766 62.495 116.535 58.7672ZM121.085 72.2852L116.727 79.5529C118.149 81.2705 119.73 82.8515 121.447 84.2734L128.698 79.9256C125.686 77.9043 123.096 75.3044 121.085 72.2852Z"
        fill="#FFE215"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144 84.552C159.216 84.552 171.552 72.2165 171.552 57C171.552 41.7834 159.216 29.4479 144 29.4479C128.783 29.4479 116.448 41.7834 116.448 57C116.448 72.2165 128.783 84.552 144 84.552Z"
        fill="#FBE140"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.348 71.7386V73.0435H160.044V57C160.044 48.1395 152.861 40.9565 144 40.9565C135.139 40.9565 127.957 48.1395 127.957 57C127.957 65.8606 135.139 73.0435 144 73.0435C146.255 73.0435 148.401 72.5783 150.348 71.7386ZM150.348 57C150.348 60.506 147.506 63.3482 144 63.3482C140.494 63.3482 137.652 60.506 137.652 57C137.652 53.494 140.494 50.6519 144 50.6519C147.506 50.6519 150.348 53.494 150.348 57Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185 108.424C204.564 108.424 220.424 92.5641 220.424 73C220.424 53.4358 204.564 37.576 185 37.576C165.436 37.576 149.576 53.4358 149.576 73C149.576 92.5641 165.436 108.424 185 108.424Z"
        fill="#FFCA13"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.2 100.524L179.103 107.9C176.877 107.526 174.722 106.945 172.657 106.177L172.797 97.7248C175.967 99.2986 179.483 100.28 183.2 100.524ZM186.802 100.528L190.897 107.9C193.123 107.526 195.279 106.945 197.343 106.177L197.204 97.757C194.033 99.3209 190.517 100.293 186.802 100.528ZM200.337 95.9464L207.552 100.273C209.27 98.8511 210.851 97.27 212.273 95.5524L207.947 88.337C205.934 91.3446 203.344 93.9337 200.337 95.9464ZM209.757 85.2038L218.177 85.3432C218.945 83.2786 219.526 81.1229 219.9 78.8968L212.529 74.8024C212.293 78.5177 211.321 82.033 209.757 85.2038ZM212.525 71.1998L219.9 67.1032C219.526 64.8771 218.945 62.7214 218.177 60.6568L209.726 60.7967C211.3 63.9666 212.281 67.4825 212.525 71.1998ZM207.926 57.6968L212.273 50.4475C210.851 48.7299 209.27 47.1488 207.552 45.7268L200.286 50.0839C203.305 52.0947 205.905 54.6854 207.926 57.6968ZM197.203 48.3066L197.343 39.8232C195.279 39.0547 193.123 38.4735 190.897 38.1001L186.768 45.5338C190.495 45.7653 194.022 46.7382 197.203 48.3066ZM183.235 45.5378L179.103 38.1C176.877 38.4733 174.722 39.0545 172.657 39.8229L172.798 48.3388C175.977 46.7605 179.505 45.778 183.235 45.5378ZM169.749 50.1047L162.447 45.7266C160.73 47.1484 159.149 48.7294 157.727 50.447L162.105 57.7486C164.124 54.7254 166.726 52.124 169.749 50.1047ZM160.339 60.7978L151.823 60.6568C151.054 62.7213 150.473 64.8768 150.1 67.1028L157.539 71.235C157.779 67.5052 158.761 63.9774 160.339 60.7978ZM157.535 74.7672L150.1 78.8972C150.473 81.1232 151.054 83.2787 151.823 85.3432L160.308 85.2028C158.739 82.0221 157.766 78.495 157.535 74.7672ZM162.085 88.2852L157.727 95.5529C159.149 97.2705 160.73 98.8515 162.447 100.273L169.698 95.9256C166.686 93.9043 164.096 91.3044 162.085 88.2852Z"
        fill="#FFE215"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185 100.552C200.216 100.552 212.552 88.2165 212.552 73C212.552 57.7834 200.216 45.4479 185 45.4479C169.783 45.4479 157.448 57.7834 157.448 73C157.448 88.2165 169.783 100.552 185 100.552Z"
        fill="#FBE140"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191.348 87.7386V89.0435H201.044V73C201.044 64.1395 193.861 56.9565 185 56.9565C176.139 56.9565 168.957 64.1395 168.957 73C168.957 81.8606 176.139 89.0435 185 89.0435C187.255 89.0435 189.401 88.5783 191.348 87.7386ZM191.348 73C191.348 76.506 188.506 79.3482 185 79.3482C181.494 79.3482 178.652 76.506 178.652 73C178.652 69.494 181.494 66.6519 185 66.6519C188.506 66.6519 191.348 69.494 191.348 73Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M226 91.424C245.564 91.424 261.424 75.5641 261.424 56C261.424 36.4358 245.564 20.576 226 20.576C206.436 20.576 190.576 36.4358 190.576 56C190.576 75.5641 206.436 91.424 226 91.424Z"
        fill="#FFCA13"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M224.2 83.5241L220.103 90.8998C217.877 90.5265 215.722 89.9453 213.657 89.1769L213.797 80.7248C216.967 82.2986 220.483 83.28 224.2 83.5241ZM227.802 83.5282L231.897 90.8997C234.123 90.5263 236.279 89.9451 238.343 89.1766L238.204 80.757C235.033 82.3209 231.517 83.2928 227.802 83.5282ZM241.337 78.9464L248.552 83.2731C250.27 81.8511 251.851 80.27 253.273 78.5524L248.947 71.337C246.934 74.3446 244.344 76.9337 241.337 78.9464ZM250.757 68.2038L259.177 68.3432C259.945 66.2786 260.526 64.1229 260.9 61.8968L253.529 57.8024C253.293 61.5177 252.321 65.033 250.757 68.2038ZM253.525 54.1998L260.9 50.1032C260.526 47.8771 259.945 45.7214 259.177 43.6568L250.726 43.7967C252.3 46.9666 253.281 50.4825 253.525 54.1998ZM248.926 40.6968L253.273 33.4475C251.851 31.7299 250.27 30.1488 248.552 28.7268L241.286 33.0839C244.305 35.0947 246.905 37.6854 248.926 40.6968ZM238.203 31.3066L238.343 22.8232C236.279 22.0547 234.123 21.4735 231.897 21.1001L227.768 28.5338C231.495 28.7653 235.022 29.7382 238.203 31.3066ZM224.235 28.5378L220.103 21.1C217.877 21.4733 215.722 22.0545 213.657 22.8229L213.798 31.3388C216.977 29.7605 220.505 28.778 224.235 28.5378ZM210.749 33.1047L203.447 28.7266C201.73 30.1484 200.149 31.7294 198.727 33.447L203.105 40.7486C205.124 37.7254 207.726 35.124 210.749 33.1047ZM201.339 43.7978L192.823 43.6568C192.054 45.7213 191.473 47.8768 191.1 50.1028L198.539 54.235C198.779 50.5052 199.761 46.9774 201.339 43.7978ZM198.535 57.7672L191.1 61.8972C191.473 64.1232 192.054 66.2787 192.823 68.3432L201.308 68.2028C199.739 65.0221 198.766 61.495 198.535 57.7672ZM203.085 71.2852L198.727 78.5529C200.149 80.2705 201.73 81.8515 203.447 83.2734L210.698 78.9256C207.686 76.9043 205.096 74.3044 203.085 71.2852Z"
        fill="#FFE215"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M226 83.552C241.216 83.552 253.552 71.2165 253.552 56C253.552 40.7834 241.216 28.4479 226 28.4479C210.783 28.4479 198.448 40.7834 198.448 56C198.448 71.2165 210.783 83.552 226 83.552Z"
        fill="#FBE140"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M232.348 70.7386V72.0435H242.044V56C242.044 47.1395 234.861 39.9565 226 39.9565C217.139 39.9565 209.957 47.1395 209.957 56C209.957 64.8606 217.139 72.0435 226 72.0435C228.255 72.0435 230.401 71.5783 232.348 70.7386ZM232.348 56C232.348 59.506 229.506 62.3482 226 62.3482C222.494 62.3482 219.652 59.506 219.652 56C219.652 52.494 222.494 49.6519 226 49.6519C229.506 49.6519 232.348 52.494 232.348 56Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191 71.424C210.564 71.424 226.424 55.5641 226.424 36C226.424 16.4358 210.564 0.575989 191 0.575989C171.436 0.575989 155.576 16.4358 155.576 36C155.576 55.5641 171.436 71.424 191 71.424Z"
        fill="#FFCA13"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.2 63.5241L185.103 70.8998C182.877 70.5265 180.722 69.9453 178.657 69.1769L178.797 60.7248C181.967 62.2986 185.483 63.28 189.2 63.5241ZM192.802 63.5282L196.897 70.8997C199.123 70.5263 201.279 69.9451 203.343 69.1766L203.204 60.757C200.033 62.3209 196.517 63.2928 192.802 63.5282ZM206.337 58.9464L213.552 63.2731C215.27 61.8511 216.851 60.27 218.273 58.5524L213.947 51.337C211.934 54.3446 209.344 56.9337 206.337 58.9464ZM215.757 48.2038L224.177 48.3432C224.945 46.2786 225.526 44.1229 225.9 41.8968L218.529 37.8024C218.293 41.5177 217.321 45.033 215.757 48.2038ZM218.525 34.1998L225.9 30.1032C225.526 27.8771 224.945 25.7214 224.177 23.6568L215.726 23.7967C217.3 26.9666 218.281 30.4825 218.525 34.1998ZM213.926 20.6968L218.273 13.4475C216.851 11.7299 215.27 10.1488 213.552 8.72684L206.286 13.0839C209.305 15.0947 211.905 17.6854 213.926 20.6968ZM203.203 11.3066L203.343 2.82316C201.279 2.05474 199.123 1.47351 196.897 1.1001L192.768 8.53376C196.495 8.76534 200.022 9.73816 203.203 11.3066ZM189.235 8.53777L185.103 1.09998C182.877 1.47333 180.722 2.0545 178.657 2.82286L178.798 11.3388C181.977 9.76045 185.505 8.77805 189.235 8.53777ZM175.749 13.1047L168.447 8.72656C166.73 10.1484 165.149 11.7294 163.727 13.447L168.105 20.7486C170.124 17.7254 172.726 15.124 175.749 13.1047ZM166.339 23.7978L157.823 23.6568C157.054 25.7213 156.473 27.8768 156.1 30.1028L163.539 34.235C163.779 30.5052 164.761 26.9774 166.339 23.7978ZM163.535 37.7672L156.1 41.8972C156.473 44.1232 157.054 46.2787 157.823 48.3432L166.308 48.2028C164.739 45.0221 163.766 41.495 163.535 37.7672ZM168.085 51.2852L163.727 58.5529C165.149 60.2705 166.73 61.8515 168.447 63.2734L175.698 58.9256C172.686 56.9043 170.096 54.3044 168.085 51.2852Z"
        fill="#FFE215"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191 63.552C206.216 63.552 218.552 51.2165 218.552 36C218.552 20.7834 206.216 8.44794 191 8.44794C175.783 8.44794 163.448 20.7834 163.448 36C163.448 51.2165 175.783 63.552 191 63.552Z"
        fill="#FBE140"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.348 50.7386V52.0435H207.044V36C207.044 27.1395 199.861 19.9565 191 19.9565C182.139 19.9565 174.957 27.1395 174.957 36C174.957 44.8606 182.139 52.0435 191 52.0435C193.255 52.0435 195.401 51.5783 197.348 50.7386ZM197.348 36C197.348 39.506 194.506 42.3482 191 42.3482C187.494 42.3482 184.652 39.506 184.652 36C184.652 32.494 187.494 29.6519 191 29.6519C194.506 29.6519 197.348 32.494 197.348 36Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258 104.424C277.564 104.424 293.424 88.5641 293.424 69C293.424 49.4358 277.564 33.576 258 33.576C238.436 33.576 222.576 49.4358 222.576 69C222.576 88.5641 238.436 104.424 258 104.424Z"
        fill="#FFCA13"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M256.2 96.5241L252.103 103.9C249.877 103.526 247.722 102.945 245.657 102.177L245.797 93.7248C248.967 95.2986 252.483 96.28 256.2 96.5241ZM259.802 96.5282L263.897 103.9C266.123 103.526 268.279 102.945 270.343 102.177L270.204 93.757C267.033 95.3209 263.517 96.2928 259.802 96.5282ZM273.337 91.9464L280.552 96.2731C282.27 94.8511 283.851 93.27 285.273 91.5524L280.947 84.337C278.934 87.3446 276.344 89.9337 273.337 91.9464ZM282.757 81.2038L291.177 81.3432C291.945 79.2786 292.526 77.1229 292.9 74.8968L285.529 70.8024C285.293 74.5177 284.321 78.033 282.757 81.2038ZM285.525 67.1998L292.9 63.1032C292.526 60.8771 291.945 58.7214 291.177 56.6568L282.726 56.7967C284.3 59.9666 285.281 63.4825 285.525 67.1998ZM280.926 53.6968L285.273 46.4475C283.851 44.7299 282.27 43.1488 280.552 41.7268L273.286 46.0839C276.305 48.0947 278.905 50.6854 280.926 53.6968ZM270.203 44.3066L270.343 35.8232C268.279 35.0547 266.123 34.4735 263.897 34.1001L259.768 41.5338C263.495 41.7653 267.022 42.7382 270.203 44.3066ZM256.235 41.5378L252.103 34.1C249.877 34.4733 247.722 35.0545 245.657 35.8229L245.798 44.3388C248.977 42.7605 252.505 41.778 256.235 41.5378ZM242.749 46.1047L235.447 41.7266C233.73 43.1484 232.149 44.7294 230.727 46.447L235.105 53.7486C237.124 50.7254 239.726 48.124 242.749 46.1047ZM233.339 56.7978L224.823 56.6568C224.054 58.7213 223.473 60.8768 223.1 63.1028L230.539 67.235C230.779 63.5052 231.761 59.9774 233.339 56.7978ZM230.535 70.7672L223.1 74.8972C223.473 77.1232 224.054 79.2787 224.823 81.3432L233.308 81.2028C231.739 78.0221 230.766 74.495 230.535 70.7672ZM235.085 84.2852L230.727 91.5529C232.149 93.2705 233.73 94.8515 235.447 96.2734L242.698 91.9256C239.686 89.9043 237.096 87.3044 235.085 84.2852Z"
        fill="#FFE215"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258 96.552C273.216 96.552 285.552 84.2165 285.552 69C285.552 53.7834 273.216 41.4479 258 41.4479C242.783 41.4479 230.448 53.7834 230.448 69C230.448 84.2165 242.783 96.552 258 96.552Z"
        fill="#FBE140"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M264.348 83.7386V85.0435H274.044V69C274.044 60.1395 266.861 52.9565 258 52.9565C249.139 52.9565 241.957 60.1395 241.957 69C241.957 77.8606 249.139 85.0435 258 85.0435C260.255 85.0435 262.401 84.5783 264.348 83.7386ZM264.348 69C264.348 72.506 261.506 75.3482 258 75.3482C254.494 75.3482 251.652 72.506 251.652 69C251.652 65.494 254.494 62.6519 258 62.6519C261.506 62.6519 264.348 65.494 264.348 69Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.352 440.872C302.059 440.872 380.456 413.135 380.456 378.92C380.456 344.705 302.059 316.968 205.352 316.968C108.645 316.968 30.248 344.705 30.248 378.92C30.248 413.135 108.645 440.872 205.352 440.872Z"
        fill="#363636"
        fillOpacity="0.13"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.637 141.864H197.365C197.365 141.864 197.365 368.281 197.365 426.536C155.12 426.333 123.029 421.896 101.109 415.272C90.9964 412.216 40.9911 391.318 44.7894 345.64C46.9624 260.65 123.637 141.864 123.637 141.864Z"
        fill="#7157C2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M270.888 141.864H197.16C197.16 141.864 197.16 368.281 197.16 426.536C239.405 426.333 271.496 421.896 293.416 415.272C303.529 412.216 353.534 391.318 349.736 345.64C347.563 260.65 270.888 141.864 270.888 141.864Z"
        fill="#5F48A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.2021 66.4199C99.6269 61.3126 105.959 61.1169 117.73 63.3479C129.501 65.5789 131.708 69.4801 153.57 70.5159C162.944 70.9601 175.445 64.7758 188.386 66.4199C190.895 66.7387 192.69 67.9849 197.602 69.4429C209.509 72.9772 228.903 77.7564 242.658 73.5879C277.667 62.9789 280.116 62.9279 290.786 64.3719C300.008 66.162 300.339 75.5312 296.93 85.8759C289.429 108.635 268.608 130.932 268.608 130.932H197.602H125.922C125.922 130.932 86.8666 84.4929 95.2021 66.4199Z"
        fill="#7157C2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.16 70.127C209.066 73.6613 228.46 77.0648 242.216 72.8964C277.225 62.2874 279.674 62.2364 290.344 63.6804C299.566 65.4705 299.897 74.8396 296.488 85.1844C288.987 107.943 268.166 130.24 268.166 130.24H197.16C197.16 130.24 197.16 98.0039 197.16 70.127Z"
        fill="#5F48A8"
      />
      <rect
        x="115.24"
        y="128.552"
        width="163.84"
        height="14.336"
        rx="7.168"
        fill="#4F3C8C"
      />
      <path
        d="M172.024 253.873C171.401 218.995 182.575 140.913 232.262 107.609C281.948 74.3062 223.656 62.9095 211.31 76.7858C201.582 81.0362 184.67 104.009 196.344 130.112C210.935 162.74 249.098 233.622 220.289 270"
        stroke="#4F3C8C"
        strokeWidth="11"
        strokeLinecap="round"
      />
      <path
        d="M196 132C188.897 102.792 170.578 44.9084 149.643 86.6057C128.707 128.303 170.952 120.22 196 132Z"
        stroke="#4F3C8C"
        strokeWidth="11"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.452 431.805C119.754 419.15 119.289 389.547 102.358 384.717C84.5558 371.367 55.2872 370.804 36.9847 383.459C16.9249 387.841 18.2765 417.197 36.0785 430.547C53.8806 443.898 83.1492 444.461 101.452 431.805Z"
        fill="#DBAC09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.897 426.673C119.199 414.017 119.605 392.935 101.803 379.585C84.001 366.235 54.7324 365.671 36.4298 378.327C18.1273 390.983 17.7216 412.065 35.5237 425.415C53.3258 438.765 82.5944 439.329 100.897 426.673Z"
        fill="#FFCA13"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.0253 422.446L94.9038 430.13C92.4815 431.315 89.936 432.313 87.3054 433.124L79.7454 427.266C84.143 426.289 88.3254 424.685 92.0253 422.446ZM95.3984 420.119L105.937 422.501C107.68 420.806 109.167 419.015 110.399 417.155L102.608 411.5C101.064 414.618 98.66 417.555 95.3984 420.119ZM103.891 408.237L114.578 406.522C114.891 404.441 114.932 402.339 114.699 400.247L104.086 398.122C104.94 401.477 104.873 404.918 103.891 408.237ZM102.929 394.813L110.932 389.462C109.773 387.556 108.355 385.709 106.678 383.948L96.0576 385.922C99.2178 388.61 101.507 391.637 102.929 394.813ZM92.7756 383.466L95.9471 375.9C93.5721 374.623 91.0669 373.528 88.4694 372.616L80.691 378.178C85.0475 379.323 89.1648 381.088 92.7756 383.466ZM76.1866 377.227L73.6567 369.468C70.7635 369.216 67.8457 369.16 64.945 369.3L62.1119 376.974C66.766 376.393 71.5554 376.479 76.1866 377.227ZM57.6101 377.756L50.0221 371.876C47.3915 372.687 44.846 373.685 42.4237 374.87L45.3248 382.614C49.0228 380.361 53.2075 378.743 57.6101 377.756ZM42.0234 384.902L31.3903 382.499C29.6475 384.194 28.1597 385.985 26.9275 387.845L34.8084 393.565C36.3466 390.431 38.7528 387.479 42.0234 384.902ZM33.5625 396.742L22.7481 398.478C22.4351 400.559 22.3946 402.661 22.6273 404.753L33.3673 406.903C32.5054 403.534 32.5718 400.076 33.5625 396.742ZM34.49 410.126L26.3943 415.539C27.554 417.445 28.9717 419.291 30.6479 421.052L41.3671 419.06C38.1978 416.359 35.9067 413.316 34.49 410.126ZM44.5774 421.473L41.3801 429.1C43.7549 430.378 46.26 431.473 48.8574 432.384L56.6675 426.8C52.3059 425.644 48.1863 423.867 44.5774 421.473ZM61.1346 427.754L63.6709 435.532C66.564 435.785 69.4817 435.841 72.3824 435.7L75.2093 428.043C70.5526 428.612 65.7631 428.513 61.1346 427.754Z"
        fill="#FFE215"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.734 421.301C107.969 411.458 108.285 395.061 94.4388 384.677C80.5927 374.294 57.8282 373.856 43.5929 383.699C29.3575 393.542 29.042 409.939 42.8881 420.323C56.7342 430.706 79.4987 431.145 93.734 421.301Z"
        fill="#FBE140"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.0134 408.451L89.2008 409.342L98.2709 403.07L83.6724 392.122C75.6099 386.076 62.3542 385.82 54.065 391.552C45.7758 397.284 45.592 406.832 53.6546 412.878C61.7171 418.925 74.9728 419.18 83.262 413.448C85.3716 411.989 86.9561 410.283 88.0134 408.451ZM74.6023 398.394C77.7925 400.786 77.7198 404.564 74.4399 406.832C71.16 409.1 65.9149 408.999 62.7247 406.607C59.5345 404.214 59.6072 400.436 62.8871 398.168C66.167 395.9 71.4121 396.001 74.6023 398.394Z"
        fill="white"
      />
      <g clipPath="url(#clip0_4_4299)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M189.452 463.805C207.754 451.15 207.289 421.547 190.358 416.717C172.556 403.367 143.287 402.804 124.985 415.459C104.925 419.841 106.276 449.197 124.079 462.547C141.881 475.898 171.149 476.461 189.452 463.805Z"
          fill="#DBAC09"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188.897 458.673C207.199 446.017 207.605 424.935 189.803 411.585C172.001 398.235 142.732 397.671 124.43 410.327C106.127 422.983 105.722 444.065 123.524 457.415C141.326 470.765 170.594 471.329 188.897 458.673Z"
          fill="#FFCA13"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180.025 454.446L182.904 462.13C180.481 463.315 177.936 464.313 175.305 465.124L167.745 459.266C172.143 458.289 176.325 456.685 180.025 454.446ZM183.398 452.119L193.937 454.501C195.68 452.806 197.167 451.015 198.399 449.155L190.608 443.5C189.064 446.618 186.66 449.555 183.398 452.119ZM191.891 440.237L202.578 438.522C202.891 436.441 202.932 434.339 202.699 432.247L192.086 430.122C192.94 433.477 192.873 436.918 191.891 440.237ZM190.929 426.813L198.932 421.462C197.773 419.556 196.355 417.709 194.678 415.948L184.058 417.922C187.218 420.61 189.507 423.637 190.929 426.813ZM180.776 415.466L183.947 407.9C181.572 406.623 179.067 405.528 176.469 404.616L168.691 410.178C173.048 411.323 177.165 413.088 180.776 415.466ZM164.187 409.227L161.657 401.468C158.764 401.216 155.846 401.16 152.945 401.3L150.112 408.974C154.766 408.393 159.555 408.479 164.187 409.227ZM145.61 409.756L138.022 403.876C135.391 404.687 132.846 405.685 130.424 406.87L133.325 414.614C137.023 412.361 141.208 410.743 145.61 409.756ZM130.023 416.902L119.39 414.499C117.647 416.194 116.16 417.985 114.928 419.845L122.808 425.565C124.347 422.431 126.753 419.479 130.023 416.902ZM121.563 428.742L110.748 430.478C110.435 432.559 110.395 434.661 110.627 436.753L121.367 438.903C120.505 435.534 120.572 432.076 121.563 428.742ZM122.49 442.126L114.394 447.539C115.554 449.445 116.972 451.291 118.648 453.052L129.367 451.06C126.198 448.359 123.907 445.316 122.49 442.126ZM132.577 453.473L129.38 461.1C131.755 462.378 134.26 463.473 136.857 464.384L144.668 458.8C140.306 457.644 136.186 455.867 132.577 453.473ZM149.135 459.754L151.671 467.532C154.564 467.785 157.482 467.841 160.382 467.7L163.209 460.043C158.553 460.612 153.763 460.513 149.135 459.754Z"
          fill="#FFE215"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M181.734 453.301C195.969 443.458 196.285 427.061 182.439 416.677C168.593 406.294 145.828 405.856 131.593 415.699C117.358 425.542 117.042 441.939 130.888 452.323C144.734 462.706 167.499 463.145 181.734 453.301Z"
          fill="#FBE140"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M176.013 440.451L177.201 441.342L186.271 435.07L171.672 424.122C163.61 418.076 150.354 417.82 142.065 423.552C133.776 429.284 133.592 438.832 141.655 444.878C149.717 450.925 162.973 451.18 171.262 445.448C173.372 443.989 174.956 442.283 176.013 440.451ZM162.602 430.394C165.792 432.786 165.72 436.564 162.44 438.832C159.16 441.1 153.915 440.999 150.725 438.607C147.534 436.214 147.607 432.436 150.887 430.168C154.167 427.9 159.412 428.001 162.602 430.394Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip1_4_4299)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284.452 463.805C302.754 451.15 302.289 421.547 285.358 416.717C267.556 403.367 238.287 402.804 219.985 415.459C199.925 419.841 201.276 449.197 219.079 462.547C236.881 475.898 266.149 476.461 284.452 463.805Z"
          fill="#DBAC09"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M283.897 458.673C302.199 446.017 302.605 424.935 284.803 411.585C267.001 398.235 237.732 397.671 219.43 410.327C201.127 422.983 200.722 444.065 218.524 457.415C236.326 470.765 265.594 471.329 283.897 458.673Z"
          fill="#FFCA13"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M275.025 454.446L277.904 462.13C275.481 463.315 272.936 464.313 270.305 465.124L262.745 459.266C267.143 458.289 271.325 456.685 275.025 454.446ZM278.398 452.119L288.937 454.501C290.68 452.806 292.167 451.015 293.399 449.155L285.608 443.5C284.064 446.618 281.66 449.555 278.398 452.119ZM286.891 440.237L297.578 438.522C297.891 436.441 297.932 434.339 297.699 432.247L287.086 430.122C287.94 433.477 287.873 436.918 286.891 440.237ZM285.929 426.813L293.932 421.462C292.773 419.556 291.355 417.709 289.678 415.948L279.058 417.922C282.218 420.61 284.507 423.637 285.929 426.813ZM275.776 415.466L278.947 407.9C276.572 406.623 274.067 405.528 271.469 404.616L263.691 410.178C268.048 411.323 272.165 413.088 275.776 415.466ZM259.187 409.227L256.657 401.468C253.764 401.216 250.846 401.16 247.945 401.3L245.112 408.974C249.766 408.393 254.555 408.479 259.187 409.227ZM240.61 409.756L233.022 403.876C230.391 404.687 227.846 405.685 225.424 406.87L228.325 414.614C232.023 412.361 236.208 410.743 240.61 409.756ZM225.023 416.902L214.39 414.499C212.647 416.194 211.16 417.985 209.928 419.845L217.808 425.565C219.347 422.431 221.753 419.479 225.023 416.902ZM216.563 428.742L205.748 430.478C205.435 432.559 205.395 434.661 205.627 436.753L216.367 438.903C215.505 435.534 215.572 432.076 216.563 428.742ZM217.49 442.126L209.394 447.539C210.554 449.445 211.972 451.291 213.648 453.052L224.367 451.06C221.198 448.359 218.907 445.316 217.49 442.126ZM227.577 453.473L224.38 461.1C226.755 462.378 229.26 463.473 231.857 464.384L239.668 458.8C235.306 457.644 231.186 455.867 227.577 453.473ZM244.135 459.754L246.671 467.532C249.564 467.785 252.482 467.841 255.382 467.7L258.209 460.043C253.553 460.612 248.763 460.513 244.135 459.754Z"
          fill="#FFE215"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M276.734 453.301C290.969 443.458 291.285 427.061 277.439 416.677C263.593 406.294 240.828 405.856 226.593 415.699C212.358 425.542 212.042 441.939 225.888 452.323C239.734 462.706 262.499 463.145 276.734 453.301Z"
          fill="#FBE140"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M271.013 440.451L272.201 441.342L281.271 435.07L266.672 424.122C258.61 418.076 245.354 417.82 237.065 423.552C228.776 429.284 228.592 438.832 236.655 444.878C244.717 450.925 257.973 451.18 266.262 445.448C268.372 443.989 269.956 442.283 271.013 440.451ZM257.602 430.394C260.792 432.786 260.72 436.564 257.44 438.832C254.16 441.1 248.915 440.999 245.725 438.607C242.534 436.214 242.607 432.436 245.887 430.168C249.167 427.9 254.412 428.001 257.602 430.394Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M366.452 435.805C384.754 423.15 384.289 393.547 367.358 388.717C349.556 375.367 320.287 374.804 301.985 387.459C281.925 391.841 283.276 421.197 301.079 434.547C318.881 447.898 348.149 448.461 366.452 435.805Z"
        fill="#DBAC09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M365.897 430.673C384.199 418.017 384.605 396.935 366.803 383.585C349.001 370.235 319.732 369.671 301.43 382.327C283.127 394.983 282.722 416.065 300.524 429.415C318.326 442.765 347.594 443.329 365.897 430.673Z"
        fill="#FFCA13"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.025 426.446L359.904 434.13C357.481 435.315 354.936 436.313 352.305 437.124L344.745 431.266C349.143 430.289 353.325 428.685 357.025 426.446ZM360.398 424.119L370.937 426.501C372.68 424.806 374.167 423.015 375.399 421.155L367.608 415.5C366.064 418.618 363.66 421.555 360.398 424.119ZM368.891 412.237L379.578 410.522C379.891 408.441 379.932 406.339 379.699 404.247L369.086 402.122C369.94 405.477 369.873 408.918 368.891 412.237ZM367.929 398.813L375.932 393.462C374.773 391.556 373.355 389.709 371.678 387.948L361.058 389.922C364.218 392.61 366.507 395.637 367.929 398.813ZM357.776 387.466L360.947 379.9C358.572 378.623 356.067 377.528 353.469 376.616L345.691 382.178C350.048 383.323 354.165 385.088 357.776 387.466ZM341.187 381.227L338.657 373.468C335.764 373.216 332.846 373.16 329.945 373.3L327.112 380.974C331.766 380.393 336.555 380.479 341.187 381.227ZM322.61 381.756L315.022 375.876C312.391 376.687 309.846 377.685 307.424 378.87L310.325 386.614C314.023 384.361 318.208 382.743 322.61 381.756ZM307.023 388.902L296.39 386.499C294.647 388.194 293.16 389.985 291.928 391.845L299.808 397.565C301.347 394.431 303.753 391.479 307.023 388.902ZM298.563 400.742L287.748 402.478C287.435 404.559 287.395 406.661 287.627 408.753L298.367 410.903C297.505 407.534 297.572 404.076 298.563 400.742ZM299.49 414.126L291.394 419.539C292.554 421.445 293.972 423.291 295.648 425.052L306.367 423.06C303.198 420.359 300.907 417.316 299.49 414.126ZM309.577 425.473L306.38 433.1C308.755 434.378 311.26 435.473 313.857 436.384L321.668 430.8C317.306 429.644 313.186 427.867 309.577 425.473ZM326.135 431.754L328.671 439.532C331.564 439.785 334.482 439.841 337.382 439.7L340.209 432.043C335.553 432.612 330.763 432.513 326.135 431.754Z"
        fill="#FFE215"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M358.734 425.301C372.969 415.458 373.285 399.061 359.439 388.677C345.593 378.294 322.828 377.856 308.593 387.699C294.358 397.542 294.042 413.939 307.888 424.323C321.734 434.706 344.499 435.145 358.734 425.301Z"
        fill="#FBE140"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M353.013 412.451L354.201 413.342L363.271 407.07L348.672 396.122C340.61 390.076 327.354 389.82 319.065 395.552C310.776 401.284 310.592 410.832 318.655 416.878C326.717 422.925 339.973 423.18 348.262 417.448C350.372 415.989 351.956 414.283 353.013 412.451ZM339.602 402.394C342.792 404.786 342.72 408.564 339.44 410.832C336.16 413.1 330.915 412.999 327.725 410.607C324.534 408.214 324.607 404.436 327.887 402.168C331.167 399.9 336.412 400.001 339.602 402.394Z"
        fill="white"
      />
      <g clipPath="url(#clip2_4_4299)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M327.452 435.805C345.754 423.15 345.289 393.547 328.358 388.717C310.556 375.367 281.287 374.804 262.985 387.459C242.925 391.841 244.276 421.197 262.079 434.547C279.881 447.898 309.149 448.461 327.452 435.805Z"
          fill="#DBAC09"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M326.897 430.673C345.199 418.017 345.605 396.935 327.803 383.585C310.001 370.235 280.732 369.671 262.43 382.327C244.127 394.983 243.722 416.065 261.524 429.415C279.326 442.765 308.594 443.329 326.897 430.673Z"
          fill="#FFCA13"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M318.025 426.446L320.904 434.13C318.481 435.315 315.936 436.313 313.305 437.124L305.745 431.266C310.143 430.289 314.325 428.685 318.025 426.446ZM321.398 424.119L331.937 426.501C333.68 424.806 335.167 423.015 336.399 421.155L328.608 415.5C327.064 418.618 324.66 421.555 321.398 424.119ZM329.891 412.237L340.578 410.522C340.891 408.441 340.932 406.339 340.699 404.247L330.086 402.122C330.94 405.477 330.873 408.918 329.891 412.237ZM328.929 398.813L336.932 393.462C335.773 391.556 334.355 389.709 332.678 387.948L322.058 389.922C325.218 392.61 327.507 395.637 328.929 398.813ZM318.776 387.466L321.947 379.9C319.572 378.623 317.067 377.528 314.469 376.616L306.691 382.178C311.048 383.323 315.165 385.088 318.776 387.466ZM302.187 381.227L299.657 373.468C296.764 373.216 293.846 373.16 290.945 373.3L288.112 380.974C292.766 380.393 297.555 380.479 302.187 381.227ZM283.61 381.756L276.022 375.876C273.391 376.687 270.846 377.685 268.424 378.87L271.325 386.614C275.023 384.361 279.208 382.743 283.61 381.756ZM268.023 388.902L257.39 386.499C255.647 388.194 254.16 389.985 252.928 391.845L260.808 397.565C262.347 394.431 264.753 391.479 268.023 388.902ZM259.563 400.742L248.748 402.478C248.435 404.559 248.395 406.661 248.627 408.753L259.367 410.903C258.505 407.534 258.572 404.076 259.563 400.742ZM260.49 414.126L252.394 419.539C253.554 421.445 254.972 423.291 256.648 425.052L267.367 423.06C264.198 420.359 261.907 417.316 260.49 414.126ZM270.577 425.473L267.38 433.1C269.755 434.378 272.26 435.473 274.857 436.384L282.668 430.8C278.306 429.644 274.186 427.867 270.577 425.473ZM287.135 431.754L289.671 439.532C292.564 439.785 295.482 439.841 298.382 439.7L301.209 432.043C296.553 432.612 291.763 432.513 287.135 431.754Z"
          fill="#FFE215"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319.734 425.301C333.969 415.458 334.285 399.061 320.439 388.677C306.593 378.294 283.828 377.856 269.593 387.699C255.358 397.542 255.042 413.939 268.888 424.323C282.734 434.706 305.499 435.145 319.734 425.301Z"
          fill="#FBE140"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M314.013 412.451L315.201 413.342L324.271 407.07L309.672 396.122C301.61 390.076 288.354 389.82 280.065 395.552C271.776 401.284 271.592 410.832 279.655 416.878C287.717 422.925 300.973 423.18 309.262 417.448C311.372 415.989 312.956 414.283 314.013 412.451ZM300.602 402.394C303.792 404.786 303.72 408.564 300.44 410.832C297.16 413.1 291.915 412.999 288.725 410.607C285.534 408.214 285.607 404.436 288.887 402.168C292.167 399.9 297.412 400.001 300.602 402.394Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip3_4_4299)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M231.452 448.805C249.754 436.15 249.289 406.547 232.358 401.717C214.556 388.367 185.287 387.804 166.985 400.459C146.925 404.841 148.276 434.197 166.079 447.547C183.881 460.898 213.149 461.461 231.452 448.805Z"
          fill="#DBAC09"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230.897 443.673C249.199 431.017 249.605 409.935 231.803 396.585C214.001 383.235 184.732 382.671 166.43 395.327C148.127 407.983 147.722 429.065 165.524 442.415C183.326 455.765 212.594 456.329 230.897 443.673Z"
          fill="#FFCA13"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M222.025 439.446L224.904 447.13C222.481 448.315 219.936 449.313 217.305 450.124L209.745 444.266C214.143 443.289 218.325 441.685 222.025 439.446ZM225.398 437.119L235.937 439.501C237.68 437.806 239.167 436.015 240.399 434.155L232.608 428.5C231.064 431.618 228.66 434.555 225.398 437.119ZM233.891 425.237L244.578 423.522C244.891 421.441 244.932 419.339 244.699 417.247L234.086 415.122C234.94 418.477 234.873 421.918 233.891 425.237ZM232.929 411.813L240.932 406.462C239.773 404.556 238.355 402.709 236.678 400.948L226.058 402.922C229.218 405.61 231.507 408.637 232.929 411.813ZM222.776 400.466L225.947 392.9C223.572 391.623 221.067 390.528 218.469 389.616L210.691 395.178C215.048 396.323 219.165 398.088 222.776 400.466ZM206.187 394.227L203.657 386.468C200.764 386.216 197.846 386.16 194.945 386.3L192.112 393.974C196.766 393.393 201.555 393.479 206.187 394.227ZM187.61 394.756L180.022 388.876C177.391 389.687 174.846 390.685 172.424 391.87L175.325 399.614C179.023 397.361 183.208 395.743 187.61 394.756ZM172.023 401.902L161.39 399.499C159.647 401.194 158.16 402.985 156.928 404.845L164.808 410.565C166.347 407.431 168.753 404.479 172.023 401.902ZM163.563 413.742L152.748 415.478C152.435 417.559 152.395 419.661 152.627 421.753L163.367 423.903C162.505 420.534 162.572 417.076 163.563 413.742ZM164.49 427.126L156.394 432.539C157.554 434.445 158.972 436.291 160.648 438.052L171.367 436.06C168.198 433.359 165.907 430.316 164.49 427.126ZM174.577 438.473L171.38 446.1C173.755 447.378 176.26 448.473 178.857 449.384L186.668 443.8C182.306 442.644 178.186 440.867 174.577 438.473ZM191.135 444.754L193.671 452.532C196.564 452.785 199.482 452.841 202.382 452.7L205.209 445.043C200.553 445.612 195.763 445.513 191.135 444.754Z"
          fill="#FFE215"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223.734 438.301C237.969 428.458 238.285 412.061 224.439 401.677C210.593 391.294 187.828 390.856 173.593 400.699C159.358 410.542 159.042 426.939 172.888 437.323C186.734 447.706 209.499 448.145 223.734 438.301Z"
          fill="#FBE140"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M218.013 425.451L219.201 426.342L228.271 420.07L213.672 409.122C205.61 403.076 192.354 402.82 184.065 408.552C175.776 414.284 175.592 423.832 183.655 429.878C191.717 435.925 204.973 436.18 213.262 430.448C215.372 428.989 216.956 427.283 218.013 425.451ZM204.602 415.394C207.792 417.786 207.72 421.564 204.44 423.832C201.16 426.1 195.915 425.999 192.725 423.607C189.534 421.214 189.607 417.436 192.887 415.168C196.167 412.9 201.412 413.001 204.602 415.394Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.452 428.805C158.754 416.15 158.289 386.547 141.358 381.717C123.556 368.367 94.2872 367.804 75.9847 380.459C55.9249 384.841 57.2765 414.197 75.0785 427.547C92.8806 440.898 122.149 441.461 140.452 428.805Z"
        fill="#DBAC09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.897 423.673C158.199 411.017 158.605 389.935 140.803 376.585C123.001 363.235 93.7324 362.671 75.4298 375.327C57.1273 387.983 56.7216 409.065 74.5237 422.415C92.3258 435.765 121.594 436.329 139.897 423.673Z"
        fill="#FFCA13"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.025 419.446L133.904 427.13C131.481 428.315 128.936 429.313 126.305 430.124L118.745 424.266C123.143 423.289 127.325 421.685 131.025 419.446ZM134.398 417.119L144.937 419.501C146.68 417.806 148.167 416.015 149.399 414.155L141.608 408.5C140.064 411.618 137.66 414.555 134.398 417.119ZM142.891 405.237L153.578 403.522C153.891 401.441 153.932 399.339 153.699 397.247L143.086 395.122C143.94 398.477 143.873 401.918 142.891 405.237ZM141.929 391.813L149.932 386.462C148.773 384.556 147.355 382.709 145.678 380.948L135.058 382.922C138.218 385.61 140.507 388.637 141.929 391.813ZM131.776 380.466L134.947 372.9C132.572 371.623 130.067 370.528 127.469 369.616L119.691 375.178C124.048 376.323 128.165 378.088 131.776 380.466ZM115.187 374.227L112.657 366.468C109.764 366.216 106.846 366.16 103.945 366.3L101.112 373.974C105.766 373.393 110.555 373.479 115.187 374.227ZM96.6101 374.756L89.0221 368.876C86.3915 369.687 83.846 370.685 81.4237 371.87L84.3248 379.614C88.0228 377.361 92.2075 375.743 96.6101 374.756ZM81.0234 381.902L70.3903 379.499C68.6475 381.194 67.1597 382.985 65.9275 384.845L73.8084 390.565C75.3466 387.431 77.7528 384.479 81.0234 381.902ZM72.5625 393.742L61.7481 395.478C61.4351 397.559 61.3946 399.661 61.6273 401.753L72.3673 403.903C71.5054 400.534 71.5718 397.076 72.5625 393.742ZM73.49 407.126L65.3943 412.539C66.554 414.445 67.9717 416.291 69.6479 418.052L80.3671 416.06C77.1978 413.359 74.9067 410.316 73.49 407.126ZM83.5774 418.473L80.3801 426.1C82.7549 427.378 85.26 428.473 87.8574 429.384L95.6675 423.8C91.3059 422.644 87.1863 420.867 83.5774 418.473ZM100.135 424.754L102.671 432.532C105.564 432.785 108.482 432.841 111.382 432.7L114.209 425.043C109.553 425.612 104.763 425.513 100.135 424.754Z"
        fill="#FFE215"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.734 418.301C146.969 408.458 147.285 392.061 133.439 381.677C119.593 371.294 96.8282 370.856 82.5929 380.699C68.3575 390.542 68.042 406.939 81.8881 417.323C95.7342 427.706 118.499 428.145 132.734 418.301Z"
        fill="#FBE140"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.013 405.451L128.201 406.342L137.271 400.07L122.672 389.122C114.61 383.076 101.354 382.82 93.065 388.552C84.7758 394.284 84.592 403.832 92.6546 409.878C100.717 415.925 113.973 416.18 122.262 410.448C124.372 408.989 125.956 407.283 127.013 405.451ZM113.602 395.394C116.792 397.786 116.72 401.564 113.44 403.832C110.16 406.1 104.915 405.999 101.725 403.607C98.5345 401.214 98.6072 397.436 101.887 395.168C105.167 392.9 110.412 393.001 113.602 395.394Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_4_4299">
          <rect
            width="93.2654"
            height="93.2654"
            fill="white"
            transform="matrix(0.822516 -0.568742 0.800026 0.599966 81 433.044)"
          />
        </clipPath>
        <clipPath id="clip1_4_4299">
          <rect
            width="93.2654"
            height="93.2654"
            fill="white"
            transform="matrix(0.822516 -0.568742 0.800026 0.599966 176 433.044)"
          />
        </clipPath>
        <clipPath id="clip2_4_4299">
          <rect
            width="93.2654"
            height="93.2654"
            fill="white"
            transform="matrix(0.822516 -0.568742 0.800026 0.599966 219 405.044)"
          />
        </clipPath>
        <clipPath id="clip3_4_4299">
          <rect
            width="93.2654"
            height="93.2654"
            fill="white"
            transform="matrix(0.822516 -0.568742 0.800026 0.599966 123 418.044)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

ArcOneTime.displayName = 'ArcOneTime';

export default ArcOneTime;
