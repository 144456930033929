export const snakeToTitle = str => str.split('_').map(item => item.charAt(0).toUpperCase() + item.substring(1)).join(' ');
export const snakeToCamel = str => str.replace(/(_\w)/g, m => m[1].toUpperCase());

export const camelToSentence = str => str.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
export const camelToSnake = (string) => {
  const upperChars = string.match(/([A-Z])/g);
  if (!upperChars) {
    return string;
  }

  let str = string.toString();
  let i;
  const n = upperChars.length;

  for (i = 0; i < n; i += 1) {
    str = str.replace(new RegExp(upperChars[i]), `_${upperChars[i].toLowerCase()}`);
  }

  if (str.slice(0, 1) === '_') {
    str = str.slice(1);
  }

  return str;
};

export const camelToTitle = str => camelToSentence(str).toLowerCase().replace(/_/g, ' ').replace(/\b([a-z\u00C0-\u00ff])/g, (_, initial) => initial.toUpperCase())
  .replace(/(\s(?:de|a|o|e|da|do|em|ou|[\u00C0-\u00ff]))\b/ig, (_, match) => match.toLowerCase());


// export const camelToTitle = (str) => {
//   const parsed = str.replace(/([A-Z])/g, ' $1');
//   return parsed.charAt(0).toUpperCase() + parsed.slice(1);
// };

const transformResourceProps = (transformFn, props) => {
  if (!props) {
    return props;
  }

  const resource = {};

  Object.keys(props).forEach((propName) => {
    const nextPropName = transformFn(propName);
    resource[nextPropName] = props[propName];

    if (propName === 'id') {
      resource[nextPropName] = resource[nextPropName].toString();
    }

    if (resource[nextPropName]) {
      if (resource[nextPropName].constructor === Array && typeof resource[nextPropName][0] !== 'string') {
        resource[nextPropName] = resource[nextPropName].map(obj => transformResourceProps(transformFn, obj));
      } else if (resource[nextPropName].toString && resource[nextPropName].toString() === '[object Object]') {
        resource[nextPropName] = transformResourceProps(transformFn, resource[nextPropName]);
      }
    }
  });

  return resource;
};

export const camelCaseResource = props => transformResourceProps(snakeToCamel, props);
export const snakeCaseResource = props => transformResourceProps(camelToSnake, props);
export const snakeToTitleCaseResource = props => transformResourceProps(snakeToTitle, props);
