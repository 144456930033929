const fromManageMetricsEntitiesIndex = props => ({
  id: props.id.toString(),
  name: props.name,
  metrics: props.metrics,
  color: window.teamDisplayTools.getTeamColor(props.id),
});

const normalizers = {
  fromManageMetricsEntitiesIndex,
};

export default normalizers;
