import React from 'react';
import PropTypes from 'prop-types';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import { ArcImage, ArcText, theme } from 'arcade-frontend-ui';
import { formatNumber } from 'arcade-frontend-core/src/helpers/numbers/numbers';

const tokenImageStyle = {
  width: '20px',
  height: '20px',
  paddingLeft: '2px',
};

function TokenAmountBadge({ count, onClick }) {
  const tokenAmount = formatNumber(count);

  return (
    <ArcBox
      display="flex"
      flexDirection="row"
      alignItems="center"
      paddingY={0.5}
      marginLeft="4px"
      paddingX={1}
      bgcolor={`rgba(37, 37, 41, 0.4)`}
      borderRadius={8}
      onClick={onClick}
    >
      <ArcImage src={theme.images.coin} alt="coin" style={tokenImageStyle} />
      <ArcBox
        component={ArcText}
        marginLeft={0.5}
        marginRight={1}
        fontWeight={'normal'}
        fontSize={10}
        lineHeight="150%"
        color="white"
      >
        {tokenAmount}
      </ArcBox>
    </ArcBox>
  );
}

TokenAmountBadge.displayName = 'TokenAmountBadge';

TokenAmountBadge.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func,
};

TokenAmountBadge.defaultProps = {
  count: 0,
  onClick: null,
};

export default TokenAmountBadge;
