import React from 'react';
import PropTypes from 'prop-types';

import withApiData from 'arcade-frontend-core/src/helpers/withApiData';
import { ArcView } from 'arcade-frontend-ui';

import { api } from '../../actions';
import ChatSearch from '../../components/ChatSearch';

class ChatSearchContainer extends React.PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    hasData: PropTypes.bool,
    hasError: PropTypes.bool,
    initialValue: PropTypes.string,
    isRequesting: PropTypes.bool,
    onResultSelected: PropTypes.func,
    requestData: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    hasData: false,
    hasError: false,
    initialValue: '',
    isRequesting: false,
    onResultSelected: global.noop,
    requestData: global.noop,
  };

  handleSearch = searchValue => this.props.requestData({ searchValue });

  render() {
    return (
      <ArcView overflow="hidden" flexGrow={1} flexShrink={1}>
        <ChatSearch
          initialValue={this.props.initialValue}
          onSearch={this.handleSearch}
          isLoading={this.props.isRequesting}
          hasError={this.props.hasError}
          onResultSelected={this.props.onResultSelected}
          searchResults={this.props.hasData ? this.props.data : []}
        />
      </ArcView>
    );
  }
}

export default withApiData(api.apiChatSearch)(ChatSearchContainer);
