/* eslint-disable no-underscore-dangle */

import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from '@redux-saga/core';
import thunk from 'redux-thunk';

import reducers from '../reducers';

import {
  middleware as routesMiddleware,
  enhancer as routesEnhancer,
} from './routes';

import analyticsMiddleware from './middleware/analytics';
import angularMiddleware from './middleware/angular';
import authenticationMiddleware from './middleware/authentication';
import asyncStorageMiddleware from './middleware/asyncStorageMiddleware';
import loadingButtonMiddleware from './middleware/loading_button';
import nativeActionSyncMiddleware from './middleware/nativeActionSyncMiddleware';
import remoteMiddleware from './middleware/remote';

const sagasMiddleware = createSagaMiddleware();

let composeEnhancers = compose;

// Use Redux DevTools Extension if available
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25,
  });
}

function createArcadeStore(middleware = [], sagas) {
  const composeMiddlewares = applyMiddleware(
    thunk,
    routesMiddleware,
    sagasMiddleware,
    nativeActionSyncMiddleware,
    authenticationMiddleware,
    analyticsMiddleware,
    angularMiddleware,
    loadingButtonMiddleware,
    asyncStorageMiddleware,
    remoteMiddleware,
    ...middleware,
  );

  const store = createStore(
    reducers,
    composeEnhancers(routesEnhancer, composeMiddlewares),
  );

  sagasMiddleware.run(sagas);

  if (window) {
    window.store = store;
  }

  return store;
}

export default createArcadeStore;
