/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ArcView from '../../primitives/ArcView';
import ArcButton from '../../elements/ArcButton';

import ArcBreadcrumbsMenu from './ArcBreadcrumbsMenu';

const DEFAULT_SEPERATOR = (
  <ArcView
    marginRight="2"
    marginLeft="2"
  >
    <ChevronRightIcon
      color="disabled"
      fontSize="small"
    />
  </ArcView>
);

function insertSeparators(items, separator) {
  return items.reduce((acc, current, index) => {
    let nextAcc = [...acc];

    if (index < items.length - 1) {
      nextAcc = nextAcc.concat(
        current,
        <div aria-hidden key={`separator-${index}`}>
          {separator}
        </div>,
      );
    } else {
      nextAcc.push(current);
    }

    return nextAcc;
  }, []);
}


class ArcBreadcrumbs extends React.PureComponent {
  static propTypes = {
    isDisabled: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.node,
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })),
    onClick: PropTypes.func,
    separator: PropTypes.node,
    tooltipText: PropTypes.string,
  };

  static defaultProps = {
    isDisabled: false,
    items: [],
    onClick: global.noop,
    separator: DEFAULT_SEPERATOR,
    tooltipText: '',
  };

  handleFirstClick = () => this.props.onClick(this.props.items[0].key);

  renderItemsBeforeAndAfter = allItems => (allItems.length > 2 ? [
    ...allItems.slice(0, 1),
    <ArcBreadcrumbsMenu
      key="ArcBreadcrumbsMenu"
      items={this.props.items.slice(0, allItems.length - 1).reverse()}
      onClick={this.props.onClick}
    />,
    ...allItems.slice(allItems.length - 1, allItems.length),
  ] : allItems);

  render() {
    const itemsLength = this.props.items.length;

    if (!itemsLength) {
      return null;
    }

    const allItems = this.props.items.map((item, index) => {
      const isLastItem = index === itemsLength - 1;

      const icon = index === 0 ? item.icon : null;
      const label = (index !== 0 || itemsLength === 1) ? item.label : null;

      return (
        <ArcButton
          key={item.key || `item-${index}`}
          textTransform="initial"
          component={isLastItem ? 'span' : 'a'}
          textOnly={isLastItem}
          isDisabled={isLastItem}
          type={null}
          size="small"
          onClick={this.handleFirstClick}
        >
          {icon}
          <ArcView marginLeft={(icon && label) ? 8 : undefined}>
            {label}
          </ArcView>
        </ArcButton>
      );
    });

    const content = (
      <ArcView
        position="relative"
        aria-label="breadcrumb"
        row
        align="center"
      >
        {insertSeparators(this.renderItemsBeforeAndAfter(allItems), this.props.separator)}
        {this.props.isDisabled && (
          <ArcView
            position="absolute"
            fillToParent
          />
        )}
      </ArcView>
    );

    return this.props.tooltipText ? (
      <Tooltip
        aria-label={this.props.tooltipText}
        title={this.props.tooltipText}
        placement="bottom"
      >
        {content}
      </Tooltip>
    ) : content;
  }
}

export default ArcBreadcrumbs;
