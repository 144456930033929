import React from 'react';
import PropTypes from 'prop-types';

const ArcTournament = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 360 240"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="metrics/games" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" fillRule="nonzero">
        <g id="podium" transform="translate(0.000000, 59.000000)">
          <rect id="Rectangle-path" fill="#FFD15C" x="7.82608696" y="19.2832618" width="103.043478" height="160.686695" />
          <rect id="Rectangle-path" fill="#F7B64C" x="7.82608696" y="19.2832618" width="103.043478" height="7.21030043" />
          <rect id="Rectangle-path" fill="#FFD15C" x="0" y="0.74248927" width="117.391304" height="19.5708155" />
          <path d="M65.7622918,77.1922747 C68.0150345,74.8237539 68.7661835,73.0475021 68.7661835,70.6795365 C68.7661835,68.3115708 68.0150345,67.1270329 66.5134407,65.3507811 C65.0118468,64.1667983 62.759104,62.9822604 59.7552123,62.9822604 C54.4985778,62.9822604 49.9930922,65.942495 45.4876067,71.2712504 L30.4695561,65.350226 C34.2238927,60.6137396 37.9782294,57.653505 42.483715,55.2849843 C46.9892005,52.9164635 52.245835,51.7324807 59.7545083,51.7324807 C66.5127367,51.7324807 72.5198161,53.5087325 77.7764506,57.0612361 C83.0330851,60.6137396 85.2858279,65.350226 85.2858279,71.2712504 C85.2858279,74.231485 84.534679,77.7839886 82.2819362,80.7447783 C80.0291934,83.7050129 76.2748568,87.2575165 71.0182223,91.9945579 L56.7513206,103.836052 L88.2897196,103.836052 L88.2897196,116.269814 L31.9711499,116.269814 L31.9711499,105.612303 L55.2497267,86.6658026 C59.7552123,82.52103 63.509549,79.5607954 65.7622918,77.1922747 Z" id="2" fill="#FFFFFF" />
        </g>
        <g id="podium" transform="translate(242.307692, 94.000000)">
          <rect id="Rectangle-path" fill="#F3705A" x="6.82274247" y="20.3347639" width="103.043478" height="125.665236" />
          <rect id="Rectangle-path" fill="#F05540" x="6.82274247" y="20.3347639" width="103.043478" height="7.21030043" />
          <rect id="Rectangle-path" fill="#F3705A" x="0.301003344" y="0.763948498" width="117.391304" height="19.5708155" />
          <path d="M35.9635704,63.5072961 L35.9635704,51.6658026 L84.7727628,51.6658026 L84.7727628,61.7310443 L68.2524144,76.5333276 C74.2594938,77.1255966 79.5161283,79.4935622 83.2704649,83.0460658 C87.0248016,86.5985694 88.5270994,90.151073 88.5270994,94.8875594 C88.5270994,101.400298 85.5232077,106.729053 80.2672772,110.281557 C74.2601978,113.83406 67.5019694,115.610312 58.4909983,115.610312 C49.4800271,115.610312 40.469056,113.241791 31.4580848,108.505305 L38.9667581,97.2560801 C46.4761354,101.400853 53.2343638,103.768818 59.2414432,103.768818 C62.9957799,103.768818 65.9996716,103.176549 68.2524144,101.992567 C70.5051571,100.808584 72.006751,98.4400629 72.006751,96.0715422 C72.006751,93.7030215 70.5051571,91.3350558 68.2524144,90.1505179 C65.2485226,88.3742661 62.2453349,87.7819971 56.9887004,87.7819971 C54.7359576,87.7819971 50.981621,88.3742661 46.4761354,89.5582489 L46.4761354,79.4935622 L61.494186,65.2835479 L35.9628664,65.2835479 L35.9628664,63.5072961 L35.9635704,63.5072961 Z" id="3" fill="#FFFFFF" />
        </g>
        <g id="podium" transform="translate(120.659341, 0.000000)">
          <rect id="Rectangle-path" fill="#5BC7B1" x="8.47109412" y="19.5708155" width="103.043478" height="220.429185" />
          <rect id="Rectangle-path" fill="#4ABAA5" x="8.47109412" y="19.5708155" width="103.043478" height="7.21030043" />
          <rect id="Rectangle-path" fill="#5BC7B1" x="0.645007167" y="0" width="117.391304" height="19.5708155" />
          <polygon id="1" fill="#FFFFFF" points="43.6884854 50.472103 76.2971811 50.472103 76.2971811 113.304721 58.8556844 113.304721 58.8556844 62.3276495 43.6884854 62.3276495" />
        </g>
      </g>
    </g>
  </svg>
);

export default ArcTournament;

ArcTournament.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

ArcTournament.defaultProps = {
  height: 360,
  width: 240,
};
