export const VIEWING_MANAGER = 'viewing_manager';
export const PARTICIPATING_MANAGER = 'participating_manager';
export const PLAYER = 'player';
export const SPECTATOR = 'spectator';

export const NON_PARTICIPANT_TYPES = [VIEWING_MANAGER, SPECTATOR];
export const MANAGER_PLAYER_TYPES = [VIEWING_MANAGER, PARTICIPATING_MANAGER];
export const ALL_PARTICIPANT_TYPES = [VIEWING_MANAGER, PARTICIPATING_MANAGER, PLAYER, SPECTATOR];

export const LABEL_BY_STATUS = {
  [VIEWING_MANAGER]: 'Manager View',
  [SPECTATOR]: 'Spectator View',
};

export const TOOLTIP_BY_STATUS = {
  [VIEWING_MANAGER]: 'You are not assigned to this game but you are a manager for it. You will see a summary of scores where possible',
  [SPECTATOR]: 'You are viewing this game as a spectator. You will see information about other players',
};
