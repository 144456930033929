import { react2angular } from 'react2angular';

import ChatEditFormContainer from 'arcade-frontend-chat/src/containers/ChatEditFormContainer';

import { createProvidedContainer } from './helpers';

const ProvidedChatEditFormContainer = createProvidedContainer(ChatEditFormContainer);

export default react2angular(ProvidedChatEditFormContainer, [
  'id',
  'initialValues',
  'open',
  'onClose',
]);
