import ReduxStore from '@uppy/store-redux';
import createCachedSelector from 're-reselect';

export const uppy = ReduxStore.reducer;

const uppyState = store => store.uppy || {};
const uppyStateForUploader = (state, uppyId) => uppyState(state)[uppyId] || {};

export const createUppySelector = createCachedSelector(
  uppyStateForUploader,
  state => state,
)((_, uppyId) => uppyId);

export const createUppyUploadingSelector = createCachedSelector(
  uppyStateForUploader,
  state => state.currentUploads || {},
)((_, uppyId) => uppyId);

export const createUppyCurrentlyUploadingSelector = createCachedSelector(
  createUppyUploadingSelector,
  state => {
    const activeUploads = Object.values(state).filter(
      u => u.fileIDs.length > 0,
    );
    return activeUploads.length > 0;
  },
)((_, uppyId) => uppyId);

export const createUppyUploadProgressSelector = createCachedSelector(
  uppyStateForUploader,
  state => {
    const { files = {}, currentUploads = {} } = state;
    const uploads = {};
    Object.entries(currentUploads).forEach(([id, upload]) => {
      const { fileIDs } = upload;
      const [fileID] = fileIDs;
      const file = files[fileID] || {};
      const { progress = {} } = file;
      uploads[id] = { ...upload, progress };
    });
    return uploads;
  },
)((_, uppyId) => uppyId);
