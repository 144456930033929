import { makeApiActionTypes } from 'arcade-frontend-ui';

export const MANAGE_PEOPLE_INDEX = makeApiActionTypes('MANAGE_PEOPLE_INDEX');

export const MANAGE_PEOPLE_PERMISSIONS_INDEX = makeApiActionTypes('MANAGE_PEOPLE_PERMISSIONS_INDEX');
export const MANAGE_PEOPLE_PERMISSIONS_UPDATE = makeApiActionTypes('MANAGE_PEOPLE_PERMISSIONS_UPDATE');

export const MANAGE_PEOPLE_PROFILE = makeApiActionTypes('MANAGE_PEOPLE_PROFILE');

export const MANAGE_PEOPLE_PROFILE_UPDATE = makeApiActionTypes('MANAGE_PEOPLE_PROFILE_UPDATE');

export const RESET_PASSWORD = makeApiActionTypes('RESET_PASSWORD');
export const LOCK_ACCOUNT = makeApiActionTypes('LOCK_ACCOUNT');
export const UNLOCK_ACCOUNT = makeApiActionTypes('UNLOCK_ACCOUNT');
