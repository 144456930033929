import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { emphasize, makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import ArcView from '../../primitives/ArcView';

import ArcEntity from '../ArcEntity';


const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0.5, 0.25),
  },
  isFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },

  labelNode: {
    padding: 0,
  },
}));


function ArcEntityChip({
  isFocused,
  onDelete,
  ...props
}) {
  const classes = useStyles();

  return (
    <>
      {onDelete !== global.noop ? (
        <Chip
          onDelete={onDelete}
          tabIndex={-1}
          label={(
            <ArcView
              paddingLeft="4"
              paddingRight="8"
            >
              <ArcEntity
                type="person"
                padding="0"
                {...props}
              />
            </ArcView>
      )}
          classes={{
            label: cx([
              classes.labelNode,
            ]),
          }}
          className={cx([
            classes.root,
            isFocused && classes.isFocused,
          ])}
        />
      ) : (
        <Chip
          tabIndex={-1}
          label={(
            <ArcView
              paddingLeft="4"
              paddingRight="8"
            >
              <ArcEntity
                type="person"
                padding="0"
                {...props}
              />
            </ArcView>
      )}
          classes={{
            label: cx([
              classes.labelNode,
            ]),
          }}
          className={cx([
            classes.root,
            isFocused && classes.isFocused,
          ])}
        />
      )}
    </>
  );
}

ArcEntityChip.displayName = 'ArcEntityChip';

ArcEntityChip.propTypes = {
  isFocused: PropTypes.bool,
  onDelete: PropTypes.func,
};

ArcEntityChip.defaultProps = {
  isFocused: false,
  onDelete: global.noop,
};

export default ArcEntityChip;
