import React from 'react';
import { connect } from 'react-redux';

import { getCurrentUserFeatures } from 'arcade-frontend-core/src/reducers/user';

import FeatureSwitcher from '../../../components/app/FeatureSwitcher';


const getState = (state, { feature }) => ({
  hasFeature: getCurrentUserFeatures(state, feature),
});

const Switcher = props => <FeatureSwitcher {...props} />;

export default connect(getState)(Switcher);
