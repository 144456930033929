/* eslint-disable */
// -----------------------------------------------------------------------------
// This file contains all application-wide Style variables.
// -----------------------------------------------------------------------------


export const white     = "#ffffff";
export const black     = "#000000";
export const black1   = black;
export const black2   = "#23282c";
export const black3   = "#3b3b3b";
export const blue1    = "#0D74AD";
export const blue2    = "#0290C3";
export const blue3    = "#00A3D6";
export const blue4    = "#01C0E6";
export const blue5    = "#02CDEB";
export const blue6    = "#ABF4FF";
export const green1   = "#00967E";
export const green2   = "#00AB8B";
export const green3   = "#03C8A2";
export const green4   = "#07D2B6";
export const green5   = "#03DAC9";
export const red1     = "#CF1D1A";
export const red2     = "#EB2219";
export const red3     = "#F92514";
export const red4     = "#FF261C";
export const red5     = "#FF4E32";
export const yellow1  = "#FF8F14";
export const yellow2  = "#FFAE14";
export const yellow3  = "#FFCA13";
export const yellow4  = "#FFE215";
export const yellow5  = "#FFEA54";
export const grey1    = "#353535";
export const grey2    = "#666666";
export const grey3    = "#8C8C8C";
export const grey4    = "#B4B4B4";
export const grey5    = "#C4C4C4";
export const grey6    = "#D8D8D8";
export const grey7    = "#F3F3F3";
export const grey8    = "#F8F8F8";
export const gray1    = grey1;
export const gray2    = grey2;
export const gray3    = grey3;
export const gray4    = grey4;
export const gray5    = grey5;
export const gray6    = grey6;
export const gray7    = grey7;
export const gray8    = grey8;
export const materialGrey = '#e0e0e0';


// Core color palette
export const blue    = blue2;
export const green   = green1;
export const red     = red1;
export const yellow  = yellow1;


export const brand            = blue;
export const brandPrimary     = brand;
export const brandSecondary   = green;
export const grayDark         = black2;

// core
export const borderColorLight1    = grey7;
export const lightBgColor1        = white;
export const lightBgColor2        = grey8;
export const headerTextColor      = black2;
export const defaultTextColor     = grey2;
export const defaultHeadingColor  = black3;

// typography
export const arcTextColor  = 'rgba(0,0,0,0.87)';
// export const arcTextColor  = defaultTextColor; // TODO
export const arcTextHeadingColor  = black3;
export const arcTextColorFaded = grey4;
export const arcTextColorMuted = grey4;

export const arcTextContentHeadingStyles = {
  fontSize: '20',
  lineHeight: '24px',
  fontWeight: '300',
  color: arcTextHeadingColor,
  marginTop: 8,
  marginBottom: 8,
};

export const arcSubheaderColor  = 'rgba(0,0,0,0.54)';

// layout related
export const borderColor1 = '#f3f3f3';

// form
export const arcFormInputActiveColor = blue3;
export const arcFormInputErrorColor = yellow2;
export const arcFormInputUnderlineColor = materialGrey;

export const arcIconDefaultColor = materialGrey;
export const arcIconDefaultHoverColor = blue3;

export const palette = [
  blue,
  green,
  red,
  yellow,
];


export const fontFamilyDefault = '"Roboto", Helvetica, Arial, sans-serif';
export const fontFamilyMono = '"SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace';
export const imageHost = 'https://d17rkelr6jjme9.cloudfront.net'
const baseImages = {
  coin: 'prize-images/ArcadeCoin.svg',
  raysLegendary: 'chests/rays/rays-legendary.png',
  raysEpic: 'chests/rays/rays-epic.png',
  raysRare: 'chests/rays/rays-rare.png',
  raysCommon: 'chests/rays/rays-common.png',
  chestLegendaryOpen: 'chests/opened/legendary-open.png',
  chestEpicOpen: 'chests/opened/epic-open.png',
  chestRareOpen: 'chests/opened/rare-open.png',
  chestCommonOpen: 'chests/opened/common-open.png',
  chestLegendaryClosed: 'chests/closed/legendary-closed.png',
  chestEpicClosed: 'chests/closed/epic-closed.png',
  chestRareClosed: 'chests/closed/rare-closed.png',
  chestCommonClosed: 'chests/closed/common-closed.png',
}

export const images = Object.entries(baseImages)
  .map(([key, path]) => [key, `${imageHost}/${path}`] )
  .reduce((acc, [k, v]) => {acc[k] = v; return acc}, {})

// Preload images
Object.entries(images).map(([key, url]) => {
  const img = new Image();
  img.src = url;
});

// /// Relative or absolute URL where all assets are served from
// /// @type String
// /// @example scss - When using a CDN
// ///   $base-url: 'http://cdn.example.com/assets/';
// $base-url: '/assets/' !default;

// // Mq Responsive Configuration
// $mq-responsive: true;
// $xs: 480px;
// $sm: 768px;
// $md: 992px;
// $lg: 1200px;
// $xl: 1440px;
