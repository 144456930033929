import { Platform } from 'react-primitives';

export const ArcView = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    ...Platform.select({
      web: {
        flexBasis: 'auto',
      },
    }),
  },

  row: {
    flexDirection: 'row',
  },

  alignFlexStart: {
    alignItems: 'flex-start',
  },

  alignFlexEnd: {
    alignItems: 'flex-end',
  },

  alignCenter: {
    alignItems: 'center',
  },

  alignSelfFlexStart: {
    alignSelf: 'flex-start',
  },

  alignSpaceAround: {
    ...Platform.select({
      web: {
        alignItems: 'space-around',
      },
    }),
  },

  alignSpaceBetween: {
    ...Platform.select({
      web: {
        alignItems: 'space-between',
      },
    }),
  },

  alignStretch: {
    alignItems: 'stretch',
  },

  alignBaseline: {
    alignItems: 'baseline',
  },

  elevation1: {
    ...Platform.select({
      web: {
        boxShadow: theme.shadows[1],
      },
    }),
  },

  elevation2: {
    ...Platform.select({
      web: {
        boxShadow: theme.shadows[2],
      },
    }),
  },

  elevation8: {
    ...Platform.select({
      web: {
        boxShadow: theme.shadows[8],
      },
    }),
  },

  colorPaper: {
    backgroundColor: theme.palette.background.paper,
  },

  colorBlack: {
    backgroundColor: theme.palette.common.black,
  },

  colorBlue: {
    backgroundColor: theme.palette.blue.main,
  },

  colorDivider: {
    backgroundColor: theme.palette.divider,
  },

  colorGreen: {
    backgroundColor: theme.palette.green.main,
  },

  colorDefault: {
    backgroundColor: theme.palette.background.default,
  },

  colorGreyTint: {
    backgroundColor: theme.palette.default.tint,
  },

  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },

  colorPrimaryDark: {
    backgroundColor: theme.palette.primary.dark,
  },

  colorSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },

  colorSecondaryDark: {
    backgroundColor: theme.palette.secondary.dark,
  },

  colorDanger: {
    backgroundColor: theme.palette.danger.main,
  },

  colorSuccess: {
    backgroundColor: theme.palette.success.main,
  },

  colorYellow: {
    backgroundColor: theme.palette.yellow.main,
  },

  colorRed: {
    backgroundColor: theme.palette.red.main,
  },

  cursorPointer: {
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
  },

  borderDefault: {
    borderWidth: 4,
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
  },

  borderPrimary: {
    borderWidth: 4,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },

  borderSecondary: {
    borderWidth: 4,
    borderColor: theme.palette.secondary.main,
    borderStyle: 'solid',
  },

  borderDanger: {
    borderWidth: 4,
    borderColor: theme.palette.danger.main,
    borderStyle: 'solid',
  },

  borderTopDefault: {
    borderTopWidth: 4,
    borderTopColor: theme.palette.divider,
    ...Platform.select({
      web: {
        borderTopStyle: 'solid',
      },
      default: {
        borderStyle: 'solid',
      },
    }),
  },

  borderColorTransparent: {
    borderColor: 'transparent',
  },

  borderWidth1: {
    borderWidth: 1,
  },

  borderTopWidth1: {
    borderTopWidth: 1,
  },

  borderRightDefault: {
    borderRightWidth: 4,
    borderRightColor: theme.palette.divider,
    ...Platform.select({
      web: {
        borderRightStyle: 'solid',
      },
      default: {
        borderStyle: 'solid',
      },
    }),
  },

  borderBottomDefault: {
    borderBottomWidth: 4,
    borderBottomColor: theme.palette.divider,
    ...Platform.select({
      web: {
        borderBottomStyle: 'solid',
      },
      default: {
        borderStyle: 'solid',
      },
    }),
  },

  borderBottomPrimary: {
    borderBottomWidth: 4,
    borderBottomColor: theme.palette.primary.main,
    ...Platform.select({
      web: {
        borderBottomStyle: 'solid',
      },
      default: {
        borderStyle: 'solid',
      },
    }),
  },

  borderBottomSecondary: {
    borderBottomWidth: 4,
    borderBottomColor: theme.palette.secondary.main,
    ...Platform.select({
      web: {
        borderBottomStyle: 'solid',
      },
      default: {
        borderStyle: 'solid',
      },
    }),
  },

  borderBottomDanger: {
    borderBottomWidth: 4,
    borderBottomColor: theme.palette.danger.main,
    ...Platform.select({
      web: {
        borderBottomStyle: 'solid',
      },
      default: {
        borderStyle: 'solid',
      },
    }),
  },

  borderBottomWidth1: {
    borderBottomWidth: 1,
  },

  borderLeftDefault: {
    borderLeftWidth: 4,
    borderLeftColor: theme.palette.divider,
    ...Platform.select({
      web: {
        borderLeftStyle: 'solid',
      },
      default: {
        borderStyle: 'solid',
      },
    }),
  },

  borderLeftWidth1: {
    borderLeftWidth: 1,
  },

  displayNone: {
    display: 'none',
  },

  displayBlock: {
    ...Platform.select({
      web: {
        display: 'block',
      },
    }),
  },

  displayInlineFlex: {
    ...Platform.select({
      web: {
        display: 'inline-flex',
      },
    }),
  },

  displayTable: {
    ...Platform.select({
      web: {
        display: 'table',
      },
    }),
  },

  displayTableRow: {
    ...Platform.select({
      web: {
        display: 'table-row',
      },
    }),
  },

  displayTableCell: {
    ...Platform.select({
      web: {
        display: 'table-cell',
      },
    }),
  },

  fillToParent: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },

  flex1: {
    flex: 1,
  },

  flex9999: {
    flex: 9999,
  },

  'flexBasis100%': {
    flexBasis: '100%',
  },

  'flexBasis50%': {
    flexBasis: '50%',
  },

  flexGrow0: {
    flexGrow: 0,
  },

  flexGrow1: {
    flexGrow: 1,
  },

  flexGrow10: {
    flexGrow: 10,
  },

  flexGrow100: {
    flexGrow: 100,
  },

  flexGrow1000: {
    flexGrow: 1000,
  },

  flexShrink1: {
    flexShrink: 1,
  },

  flexShrink10: {
    flexShrink: 10,
  },

  flexShrink100: {
    flexShrink: 100,
  },

  flexShrink1000: {
    flexShrink: 1000,
  },

  fullWidth: {
    width: '100%',
  },

  fullHeight: {
    height: '100%',
  },

  height0: {
    height: 0,
  },

  hiddenOnlyMouse: {
    ...Platform.select({
      web: {
        '@media (hover: hover) and (pointer: fine)': {
          display: 'none',
        },
      },
    }),
  },

  hiddenOnlyTouch: {
    ...Platform.select({
      web: {
        '@media (hover: none)': {
          display: 'none',
        },
      },
      default: {
        display: 'none',
      },
    }),
  },

  justifyFlexEnd: {
    justifyContent: 'flex-end',
  },

  justifyFlexStart: {
    justifyContent: 'flex-start',
  },

  justifyCenter: {
    justifyContent: 'center',
  },

  justifySpaceAround: {
    justifyContent: 'space-around',
  },

  justifySpaceBetween: {
    justifyContent: 'space-between',
  },

  justifySpaceEvenly: {
    justifyContent: 'space-evenly',
  },

  justifyStretch: {
    ...Platform.select({
      web: {
        justifyContent: 'stretch',
      },
    }),
  },

  positionRelative: {
    position: 'relative',
  },

  positionAbsolute: {
    position: 'absolute',
  },

  positionFixed: {
    ...Platform.select({
      web: {
        position: 'fixed',
      },
    }),
  },

  wrapWrap: {
    flexWrap: 'wrap',
  },

  wrapNowrap: {
    flexWrap: 'nowrap',
  },

  wrapWrapReverse: {
    ...Platform.select({
      web: {
        flexWrap: 'wrap-reverse',
      },
    }),
  },

  marginAuto: {
    margin: 'auto',
  },

  margin2: {
    margin: theme.spacing(0.25),
  },

  margin4: {
    margin: theme.spacing(0.5),
  },

  margin8: {
    margin: theme.spacing(1),
  },

  margin16: {
    margin: theme.spacing(2),
  },

  margin24: {
    margin: theme.spacing(3),
  },

  margin32: {
    margin: theme.spacing(4),
  },

  margin40: {
    margin: theme.spacing(5),
  },

  margin48: {
    margin: theme.spacing(6),
  },

  marginTopAuto: {
    marginTop: 'auto',
  },

  marginTop2: {
    marginTop: theme.spacing(0.25),
  },

  marginTop4: {
    marginTop: theme.spacing(0.5),
  },

  marginTop8: {
    marginTop: theme.spacing(1),
  },

  marginTop12: {
    marginTop: theme.spacing(1.5),
  },

  marginTop16: {
    marginTop: theme.spacing(2),
  },

  marginTop24: {
    marginTop: theme.spacing(3),
  },

  marginTop32: {
    marginTop: theme.spacing(4),
  },

  marginTop40: {
    marginTop: theme.spacing(5),
  },

  marginTop48: {
    marginTop: theme.spacing(6),
  },

  marginRightAuto: {
    marginRight: 'auto',
  },

  marginRight2: {
    marginRight: theme.spacing(0.25),
  },

  marginRight4: {
    marginRight: theme.spacing(0.5),
  },

  marginRight8: {
    marginRight: theme.spacing(1),
  },

  marginRight12: {
    marginRight: theme.spacing(1.5),
  },

  marginRight16: {
    marginRight: theme.spacing(2),
  },

  marginRight24: {
    marginRight: theme.spacing(3),
  },

  marginRight32: {
    marginRight: theme.spacing(4),
  },

  marginRight40: {
    marginRight: theme.spacing(5),
  },

  marginRight48: {
    marginRight: theme.spacing(6),
  },

  marginBottomAuto: {
    marginBottom: 'auto',
  },

  marginBottom0: {
    marginBottom: 0,
  },

  marginBottom2: {
    marginBottom: theme.spacing(0.25),
  },

  marginBottom4: {
    marginBottom: theme.spacing(0.5),
  },

  marginBottom8: {
    marginBottom: theme.spacing(1),
  },

  marginBottom12: {
    marginBottom: theme.spacing(1.5),
  },

  marginBottom16: {
    marginBottom: theme.spacing(2),
  },

  marginBottom24: {
    marginBottom: theme.spacing(3),
  },

  marginBottom32: {
    marginBottom: theme.spacing(4),
  },

  marginBottom40: {
    marginBottom: theme.spacing(5),
  },

  marginBottom48: {
    marginBottom: theme.spacing(6),
  },

  marginLeftAuto: {
    marginLeft: 'auto',
  },

  marginLeft2: {
    marginLeft: theme.spacing(0.25),
  },

  marginLeft4: {
    marginLeft: theme.spacing(0.5),
  },

  marginLeft8: {
    marginLeft: theme.spacing(1),
  },

  marginLeft12: {
    marginLeft: theme.spacing(1.5),
  },

  marginLeft16: {
    marginLeft: theme.spacing(2),
  },

  marginLeft24: {
    marginLeft: theme.spacing(3),
  },

  marginLeft32: {
    marginLeft: theme.spacing(4),
  },

  marginLeft40: {
    marginLeft: theme.spacing(5),
  },

  marginLeft48: {
    marginLeft: theme.spacing(6),
  },

  overflowAuto: {
    ...Platform.select({
      web: {
        overflow: 'auto',
      },
      default: {
        overflow: 'scroll',
      },
    }),
  },

  overflowYAuto: {
    ...Platform.select({
      web: {
        overflowY: 'auto',
      },
    }),
  },

  overflowHidden: {
    overflow: 'hidden',
  },

  padding0: {
    padding: 0,
  },

  padding2: {
    padding: theme.spacing(0.25),
  },

  padding4: {
    padding: theme.spacing(0.5),
  },

  padding8: {
    padding: theme.spacing(1),
  },

  padding12: {
    padding: theme.spacing(1.5),
  },

  padding16: {
    padding: theme.spacing(2),
  },

  padding24: {
    padding: theme.spacing(3),
  },

  padding32: {
    padding: theme.spacing(4),
  },

  padding40: {
    padding: theme.spacing(5),
  },

  padding48: {
    padding: theme.spacing(6),
  },

  padding64: {
    padding: theme.spacing(8),
  },

  paddingTop2: {
    paddingTop: theme.spacing(0.25),
  },

  paddingTop4: {
    paddingTop: theme.spacing(0.5),
  },

  paddingTop8: {
    paddingTop: theme.spacing(1),
  },

  paddingTop12: {
    paddingTop: theme.spacing(1.5),
  },

  paddingTop16: {
    paddingTop: theme.spacing(2),
  },

  paddingTop24: {
    paddingTop: theme.spacing(3),
  },

  paddingTop32: {
    paddingTop: theme.spacing(4),
  },

  paddingTop40: {
    paddingTop: theme.spacing(5),
  },

  paddingTop48: {
    paddingTop: theme.spacing(6),
  },

  paddingTop64: {
    paddingTop: theme.spacing(8),
  },

  paddingRight2: {
    paddingRight: theme.spacing(0.25),
  },

  paddingRight4: {
    paddingRight: theme.spacing(0.5),
  },

  paddingRight8: {
    paddingRight: theme.spacing(1),
  },

  paddingRight12: {
    paddingRight: theme.spacing(1.5),
  },

  paddingRight16: {
    paddingRight: theme.spacing(2),
  },

  paddingRight24: {
    paddingRight: theme.spacing(3),
  },

  paddingRight32: {
    paddingRight: theme.spacing(4),
  },

  paddingRight40: {
    paddingRight: theme.spacing(5),
  },

  paddingRight48: {
    paddingRight: theme.spacing(6),
  },

  paddingRight64: {
    paddingRight: theme.spacing(8),
  },

  paddingBottom2: {
    paddingBottom: theme.spacing(0.25),
  },

  paddingBottom4: {
    paddingBottom: theme.spacing(0.5),
  },

  paddingBottom8: {
    paddingBottom: theme.spacing(1),
  },

  paddingBottom12: {
    paddingBottom: theme.spacing(1.5),
  },

  paddingBottom16: {
    paddingBottom: theme.spacing(2),
  },

  paddingBottom24: {
    paddingBottom: theme.spacing(3),
  },

  paddingBottom32: {
    paddingBottom: theme.spacing(4),
  },

  paddingBottom40: {
    paddingBottom: theme.spacing(5),
  },

  paddingBottom48: {
    paddingBottom: theme.spacing(6),
  },

  paddingBottom64: {
    paddingBottom: theme.spacing(8),
  },

  paddingLeft2: {
    paddingLeft: theme.spacing(0.25),
  },

  paddingLeft4: {
    paddingLeft: theme.spacing(0.5),
  },

  paddingLeft8: {
    paddingLeft: theme.spacing(1),
  },

  paddingLeft12: {
    paddingLeft: theme.spacing(1.5),
  },

  paddingLeft16: {
    paddingLeft: theme.spacing(2),
  },

  paddingLeft24: {
    paddingLeft: theme.spacing(3),
  },

  paddingLeft32: {
    paddingLeft: theme.spacing(4),
  },

  paddingLeft40: {
    paddingLeft: theme.spacing(5),
  },

  paddingLeft48: {
    paddingLeft: theme.spacing(6),
  },

  paddingLeft64: {
    paddingLeft: theme.spacing(8),
  },

  rounded: {
    borderRadius: 4,
  },

  spacer: {
    flexGrow: 100,
  },

  textAlignCenter: {
    textAlign: 'center',
  },

  textAlignRight: {
    textAlign: 'right',
  },

  userSelectNone: {
    ...Platform.select({
      web: {
        userSelect: 'none',
      },
    }),
  },

  verticalAlignBottom: {
    ...Platform.select({
      web: {
        verticalAlign: 'bottom',
      },
    }),
  },

  verticalAlignMiddle: {
    ...Platform.select({
      web: {
        verticalAlign: 'middle',
      },
    }),
  },

  visibilityHidden: {
    ...Platform.select({
      web: {
        visibility: 'hidden',
      },
    }),
  },

  whiteSpaceNowrap: {
    ...Platform.select({
      web: {
        whiteSpace: 'nowrap',
      },
    }),
  },

  zIndex1: {
    zIndex: 1,
  },

  zIndex10: {
    zIndex: 10,
  },

  zIndex1000: {
    zIndex: 1000,
  },

  scrollParent: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
  },

  scrollParentX: {
    flexGrow: 1,
    flexShrink: 1,
    ...Platform.select({
      web: {
        overflowX: 'hidden',
      },
      default: {
        overflow: 'hidden',
      },
    }),
  },

  scrollParentY: {
    flexGrow: 1,
    flexShrink: 1,
    ...Platform.select({
      web: {
        overflowY: 'hidden',
      },
      default: {
        overflow: 'hidden',
      },
    }),
  },

  minWidth0: {
    minWidth: 0,
  },
});
