import React, { FC } from 'react';

import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';

const BillingNotManagerMessage: FC = () => (
  <ArcBox display="flex" flexDirection="column" margin={2}>
    <ArcBox
      component={ArcText}
      fontSize={[20, 24]}
      lineHeight="150%"
      fontWeight="500"
    >
      {'Billing controls only available to primary Arcade manager.'}
    </ArcBox>
    <ArcBox component={ArcText} marginTop={2}>
      {'Contact your primary Arcade manager or support for more information.'}
    </ArcBox>
  </ArcBox>
);

export default BillingNotManagerMessage;
