import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import useDynamicRowsPerPage from 'arcade-frontend-core/src/hooks/useDynamicRowsPerPage';
import ArcLink from 'arcade-frontend-ui/src/elements/ArcLink';
import ArcEntity from 'arcade-frontend-ui/src/components/ArcEntity';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcScoreboard from 'arcade-frontend-ui/src/components/ArcScoreboard';

const ROW_HEIGHT = 59;

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.common.white,

    '& th': {
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: 1.5,
    },
  },

  body: {
    backgroundColor: theme.palette.common.white,

    '& td': {
      whiteSpace: 'nowrap',
    },
  },

  row: {
    '&:nth-child(even)': {
      backgroundColor: theme.palette.common.white,
    },

    '&:nth-child(odd)': {
      backgroundColor: theme.palette.grey[100],
    },
  },

  currentUserRow: {
    backgroundColor: theme.palette.blue.tint,
  },

  fullWidthScrollable: {
    width: '100%',
    overflowX: 'auto',
    scrollbar: 'floating',
  },

  paginationCaption: {
    fontSize: 12,
  },

  toolbar: {
    minHeight: 38,
  },
}));

const GamesLeaderboardTableHead = React.forwardRef((_, ref) => {
  const classes = useStyles();

  return (
    <TableHead ref={ref}>
      <TableRow className={classes.header}>
        <TableCell>{'#'}</TableCell>
        <TableCell>{'Name'}</TableCell>
        <TableCell align="right">{'Score'}</TableCell>
      </TableRow>
    </TableHead>
  );
});

const GamesLeaderboardTablePagination = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <ArcBox bgcolor="background.paper">
      <TablePagination
        ref={ref}
        classes={{
          toolbar: classes.toolbar,
          caption: classes.paginationCaption,
        }}
        component="div"
        rowsPerPageOptions={[]}
        {...props}
      />
    </ArcBox>
  );
});

function GamesLeaderboardTable({
  currentUserId,
  data,
  hasFailed,
  total,
  page,
  onChangePage,
  onChangeRowsPerPage,
  onRetry,
  rowsPerPage,
}) {
  const classes = useStyles();

  const contentRef = React.useRef(null);
  const tableHeadRef = React.useRef(null);
  const firstTableRowRef = React.useRef(null);

  let contentHeight = 0;

  if (contentRef.current && tableHeadRef.current) {
    contentHeight =
      contentRef.current.clientHeight - tableHeadRef.current.clientHeight;
  }

  const rowHeight = firstTableRowRef.current
    ? firstTableRowRef.current.clientHeight
    : ROW_HEIGHT;

  const dynamicRowsPerPage = useDynamicRowsPerPage(
    rowsPerPage,
    contentHeight,
    rowHeight,
  );

  const sliceUpTo = page * dynamicRowsPerPage + dynamicRowsPerPage;

  const rows = data.slice(page * dynamicRowsPerPage, sliceUpTo);

  const paginationButtonProps =
    total <= dynamicRowsPerPage
      ? { size: 'small', disabled: true }
      : { size: 'small' };

  const emptyOrErrorMessage = hasFailed
    ? 'Something went wrong'
    : 'No events found';

  const emptyOrErrorAction = (
    <ArcLink title="Try again" onClick={onRetry}>
      <ArcBox ml={0.5}>{'Try again'}</ArcBox>
    </ArcLink>
  );

  const emptyOrErrorRow = (
    <ArcBox
      display="flex"
      flexDirection="row"
      color="text.secondary"
      justifyContent="center"
    >
      {emptyOrErrorMessage} &mdash; {emptyOrErrorAction}
    </ArcBox>
  );

  return (
    <ArcBox display="flex" flexDirection="column" width="100%" flex={100}>
      <ArcBox
        flex={100}
        className={classes.fullWidthScrollable}
        ref={contentRef}
      >
        <Table>
          <GamesLeaderboardTableHead ref={tableHeadRef} />
          <TableBody className={classes.body}>
            {total
              ? rows.map((item, idx) => (
                  <TableRow
                    key={item.id}
                    ref={idx === 0 ? firstTableRowRef : undefined}
                    className={cx([
                      'animated animated--fast fadeIn',
                      currentUserId === item.userId
                        ? classes.currentUserRow
                        : classes.row,
                    ])}
                  >
                    <TableCell>{item.rank}</TableCell>
                    <TableCell>
                      <ArcEntity
                        type={ArcEntity.TYPES.PERSON}
                        name={item.username}
                        teamName={item.teamName}
                        imageUrl={item.userImage}
                        padding="0"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <ArcScoreboard
                        score={item.progress}
                        numberWidth={14}
                        style={{ justifyContent: 'flex-end' }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : Array.from(new Array(dynamicRowsPerPage)).map((_, idx) => (
                  <TableRow
                    key={idx.toString()}
                    ref={idx === 0 ? firstTableRowRef : undefined}
                    className={cx([
                      'animated animated--fast fadeIn',
                      classes.row,
                    ])}
                  >
                    <TableCell align="center" colSpan="1000">
                      <ArcBox minHeight={24}>
                        {idx === 0 && emptyOrErrorRow}
                      </ArcBox>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </ArcBox>
      <GamesLeaderboardTablePagination
        backIconButtonProps={paginationButtonProps}
        count={total}
        labelDisplayedRows={total ? undefined : global.noop}
        nextIconButtonProps={paginationButtonProps}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        page={page}
        rowsPerPage={dynamicRowsPerPage}
      />
    </ArcBox>
  );
}

GamesLeaderboardTable.displayName = 'GamesLeaderboardTable';

GamesLeaderboardTable.propTypes = {
  currentUserId: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      username: PropTypes.string,
      progress: PropTypes.number,
      rank: PropTypes.number,
      userImage: PropTypes.string,
    }),
  ),
  hasFailed: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onRetry: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  total: PropTypes.number,
};

GamesLeaderboardTable.defaultProps = {
  currentUserId: '',
  data: [],
  hasFailed: false,
  onChangePage: global.noop,
  onChangeRowsPerPage: global.noop,
  onRetry: global.noop,
  page: 0,
  rowsPerPage: 5,
  total: 0,
};

function GamesLeaderboardTablePlaceholder({
  currentUserId,
  data,
  hasFailed,
  total,
  page,
  onChangePage,
  onChangeRowsPerPage,
  onRetry,
  rowsPerPage,
  ...props
}) {
  const classes = useStyles();

  const contentRef = React.useRef(null);
  const tableHeadRef = React.useRef(null);
  const firstTableRowRef = React.useRef(null);

  let contentHeight = 0;

  if (contentRef.current && tableHeadRef.current) {
    contentHeight =
      contentRef.current.clientHeight - tableHeadRef.current.clientHeight;
  }

  const rowHeight = firstTableRowRef.current
    ? firstTableRowRef.current.clientHeight
    : ROW_HEIGHT;

  const dynamicRowsPerPage = useDynamicRowsPerPage(
    rowsPerPage,
    contentHeight,
    rowHeight,
  );

  return (
    <ArcBox display="flex" flexDirection="column" width="100%" {...props}>
      <ArcBox flex={100} ref={contentRef}>
        <Table>
          <GamesLeaderboardTableHead ref={tableHeadRef} />
          <TableBody className={classes.body}>
            {Array.from(new Array(dynamicRowsPerPage)).map((_, idx) => (
              <TableRow
                key={idx.toString()}
                ref={idx === 0 ? firstTableRowRef : undefined}
                className={cx(['animated animated--fast fadeIn', classes.row])}
              >
                <TableCell colSpan="1000">
                  <Skeleton height={24} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ArcBox>
      <GamesLeaderboardTablePagination
        backIconButtonProps={{
          disabled: true,
          size: 'small',
        }}
        count={0}
        labelDisplayedRows={() => <Skeleton width={40} />}
        nextIconButtonProps={{
          disabled: true,
          size: 'small',
        }}
        onChangePage={global.noop}
        onChangeRowsPerPage={global.noop}
        page={0}
        rowsPerPage={0}
      />
    </ArcBox>
  );
}

GamesLeaderboardTablePlaceholder.displayName =
  'GamesLeaderboardTablePlaceholder';

GamesLeaderboardTablePlaceholder.propTypes = GamesLeaderboardTable.propTypes;

GamesLeaderboardTablePlaceholder.defaultProps =
  GamesLeaderboardTable.defaultProps;

GamesLeaderboardTable.Placeholder = GamesLeaderboardTablePlaceholder;

export default GamesLeaderboardTable;
