import React from 'react';
import PropTypes from 'prop-types';
import { Svg, G, Path } from 'react-primitives-svg';

const VerticalRecapBar = ({ colorString, ...props }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 112"
    width="32px"
    height="112px"
    {...props}
  >
    <G
      fill={colorString}
      fillRule="evenodd"
    >
      <Path
        d="M0 0h32v112H0V0zm8 23.416h4.403v-4.403h2.206v2.197h2.198v2.206h6.609V21.21h-2.197v-2.197h-2.206v-2.206h4.403v-6.61h-2.197V8H8v15.416zm0 20h15.416V41.21H12.403v-4.403h8.816v-2.198h-8.816v-4.412h11.013V28H8v15.416zm0 15.597h2.206v2.197h2.197v2.206h8.816V61.21h2.197v-2.197h-4.403v2.197h-4.404v-2.197h-2.206v-6.61h2.206v-2.206h4.404v2.206h4.403v-2.206h-2.197V48h-8.816v2.197h-2.197v2.206H8v6.61zm0 24.403h4.403v-4.403h6.61v4.403h4.403V72.403h-2.197v-2.206h-2.206V68h-6.61v2.197h-2.197v2.206H8v11.013zm0 20h4.403v-4.403h8.816v-2.206h2.197v-6.61h-2.197V88H8v15.416z"
        fillRule="nonzero"
      />
      <Path d="M12.403 16.807h4.404v-2.198h2.206v-4.412h-6.61zM12.403 76.807h6.61v-4.404h-2.206v-2.206h-2.198v2.206h-2.206zM12.403 96.807h6.61v-6.61h-6.61z" />
    </G>
  </Svg>
);

VerticalRecapBar.propTypes = {
  colorString: PropTypes.string,
};

VerticalRecapBar.defaultProps = {
  colorString: '#000',
};

export default VerticalRecapBar;
