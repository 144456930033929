import * as types from './types';

export const setArcAppBar = appBar => ({
  type: types.SET_ARC_APP_BAR,
  payload: {
    appBar,
  },
});

export const setAppReady = (payload = true) => ({
  type: types.SET_APP_READY,
  payload,
});

export const setCurrentUser = props => ({
  type: types.SET_CURRENT_USER,
  payload: {
    // arcSocket: props.arcSocket,
    currentUser: props.currentUser,
    resource: props.resource,
    endpoint: props.endpoint,
    chatEndpoint: props.chatEndpoint,
    features: props.features,
  },
});

export const setNgProps = props => ({
  type: types.SET_NG_PROPS,
  payload: {
    ngRootScope: props.ngRootScope,
    ngState: props.ngState,
  },
});

export const setPlatform = platform => ({
  type: types.SET_PLATFORM,
  payload: {
    platform,
  },
});

export const setIsViewingMain = isViewingMain => ({
  type: types.SET_IS_VIEWING_MAIN,
  payload: {
    isViewingMain,
  },
});

export const setIsViewingAlert = isViewing => ({
  type: types.SET_IS_VIEWING_ALERT,
  payload: {
    isViewing,
  },
});

export const setAlert = ({ type, title, content }) => ({
  type: types.SET_ALERT,
  payload: {
    type,
    title,
    content,
  },
});

export const socketIoMessage = chatMessage => ({
  type: types.SOCKET_IO_MESSAGE,
  payload: {
    chatMessage,
  },
});

export const socketIoTypingAdd = chatId => ({
  type: types.SOCKET_IO_TYPING_ADD,
  payload: {
    chatId,
  },
});

export const socketIoTypingRemove = chatId => ({
  type: types.SOCKET_IO_TYPING_REMOVE,
  payload: {
    chatId,
  },
});

export const appLoginRequest = (email, password, meta) => ({
  type: types.APP_LOGIN.REQUEST,
  payload: {
    email,
    password,
    meta,
  },
});

export const apiAppPeopleIndexRequest = () => ({
  type: types.APP_PEOPLE_INDEX.REQUEST,
  payload: {},
});

export const appLoginSuccess = user => ({
  type: types.APP_LOGIN.SUCCESS,
  payload: {
    user,
  },
});

export const appLogoutRequest = () => ({
  type: types.APP_LOGOUT.REQUEST,
  payload: {},
});

export const appFeaturesRequest = (payload = {}) => ({
  type: types.APP_FEATURES.REQUEST,
  payload,
});

export const appFeaturesSuccess = (payload = {}) => ({
  type: types.APP_FEATURES.SUCCESS,
  payload,
});

export const appUpdateFeaturesRequest = (payload = {}) => ({
  type: types.APP_UPDATE_FEATURES.REQUEST,
  payload,
});

export const appPermissionsRequest = (payload = {}) => ({
  type: types.APP_PERMISSIONS.REQUEST,
  payload,
});

export const appPermissionsSuccess = (payload = {}) => ({
  type: types.APP_PERMISSIONS.SUCCESS,
  payload,
});

export const appResetPasswordRequest = (
  email,
  endpoint = 'https://login-proxy.arcade.co',
) => ({
  type: types.APP_RESET_PASSWORD.REQUEST,
  payload: {
    email,
    endpoint,
  },
});

export const appTaggablePeopleIndexRequest = () => ({
  type: types.APP_TAGGABLE_PEOPLE_INDEX.REQUEST,
  payload: {},
});

export const appTaggableTeamsIndexRequest = () => ({
  type: types.APP_TAGGABLE_TEAMS_INDEX.REQUEST,
  payload: {},
});

export const appTaggableEntitiesIndexRequest = () => ({
  type: types.APP_TAGGABLE_ENTITIES_INDEX.REQUEST,
  payload: {},
});

export const appGetMuteRequest = () => ({
  type: types.APP_GET_MUTE.REQUEST,
  payload: {},
});

export const appToggleMuteRequest = () => ({
  type: types.APP_TOGGLE_MUTE.REQUEST,
  payload: {},
});

export const appHashTagsIndexRequest = () => ({
  type: types.APP_HASH_TAGS_INDEX.REQUEST,
  payload: {},
});

export const appChecklistIndexRequest = () => ({
  type: types.APP_CHECKLIST_INDEX.REQUEST,
  payload: {},
});

export const appChecklistClaimRewardRequest = () => ({
  type: types.APP_CHECKLIST_CLAIM_REWARD.REQUEST,
  payload: {},
});

export const appInvitePersonRequest = form => ({
  type: types.APP_INVITE_PERSON.REQUEST,
  payload: form,
});

export const appMenuRequest = () => ({
  type: types.APP_MENU.REQUEST,
  payload: {},
});

export const appRootScopeBroadcast = event => ({
  type: types.APP_ROOT_SCOPE_BROADCAST,
  payload: event,
});

export const appSetFirstSignIn = firstSignIn => ({
  type: types.APP_SET_FIRST_SIGN_IN,
  payload: firstSignIn,
});

export const appSetOnboardingTourOpen = payload => ({
  type: types.APP_SET_ONBOARDING_TOUR_OPEN,
  payload,
});

export const setIsGeofenceRestricted = payload => ({
  type: types.SET_GEOFENCE_STATUS,
  payload,
});

export const webviewSetAppReady = payload => ({
  type: types.WEBVIEW_SET_APP_READY,
  payload,
});

export const webviewSetCanGoBack = canGoBack => ({
  type: types.WEBVIEW_SET_CAN_GO_BACK,
  payload: canGoBack,
});

export const webviewSetCurrentUser = currentUser => ({
  type: types.WEBVIEW_SET_CURRENT_USER,
  payload: currentUser,
});

export const webviewSetProfileImageUrl = profileImageUrl => ({
  type: types.WEBVIEW_SET_PROFILE_IMAGE_URL,
  payload: profileImageUrl,
});

export const webviewSetRouteType = routeType => ({
  type: types.WEBVIEW_SET_ROUTE_TYPE,
  payload: routeType,
});

export const webviewSetIsMuted = isMuted => ({
  type: types.WEBVIEW_SET_IS_MUTED,
  payload: isMuted,
});

export const webviewSetUnreadMessages = unreadMessages => ({
  type: types.WEBVIEW_SET_UNREAD_MESSAGES,
  payload: unreadMessages,
});

export const setUnreadRewards = unreadRewards => ({
  type: types.SET_UNREAD_REWARDS,
  payload: unreadRewards,
});

export const appChatRegistrationRequest = () => ({
  type: types.APP_CHAT_REGISTRATION.REQUEST,
  payload: {},
});

export const appChatNotification = payload => ({
  type: types.APP_CHAT_NOTIFICATION,
  payload,
});

export const appShortNotification = message =>
  appChatNotification({
    type: 'short_notification',
    content: { message },
  });

export const appClearNewSizzle = () => ({
  type: types.APP_SIZZLE_CLEAR,
  payload: {},
});

export const prepareWebpushCredentials = () => ({
  type: types.APP_PREPARE_WEBPUSH_CREDENTIALS,
  payload: {},
});

export const saveWebpushCredentials = payload => ({
  type: types.APP_SAVE_WEBPUSH_CREDENTIALS.REQUEST,
  payload,
});

export const appProfileUpdate = payload => ({
  type: types.APP_PROFILE_UPDATE.REQUEST,
  payload,
});

export const appLogout = (payload = {}) => ({
  type: types.LOGOUT,
  payload,
});

export const setSupportWidgetLoaded = (payload = false) => ({
  type: types.SET_SUPPORT_WIDGET_LOADED,
  payload,
});

export const setAppMenu = (payload = {}) => ({
  type: types.SET_APP_MENU,
  payload,
});

export const setAppPermissions = (payload = []) => ({
  type: types.SET_APP_PERMISSIONS,
  payload,
});

export const setIntegrationWidgetLoaded = (payload = false) => ({
  type: types.SET_INTEGRATION_WIDGET_LOADED,
  payload,
});
