import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { getLocation } from 'arcade-frontend-features/src/reducers/location';

import GamesLogActionForm from '../../components/GamesLogActionForm';
import { resources } from '../../resources/games';

const ANIMATION_DELAY = 500;

function GamesLogActionContainer() {
  const {
    hasError,
    requestData,
    status,
    onStatusReset,
    onErrorReset,
  } = useResourceAction(resources.apiPostGamesLogAction);

  const dispatch = useDispatch();
  const location = useSelector(getLocation);
  const gameId = location.query ? location.query.formGameId : '';

  const handleClose = () => {
    onErrorReset();

    const routeAction = {
      ...location,
      query: {
        ...location.query,
        form: undefined,
        formGameId: undefined,
      },
    };

    dispatch(routeAction);
  };

  const handleSubmit = formValues => {
    const { score } = formValues;

    onErrorReset();

    requestData({ resources: [{ id: gameId, score }] })
      .then(() => {
        setTimeout(() => {
          handleClose();
          onStatusReset();
        }, ANIMATION_DELAY);
      })
      .catch(() => {
        setTimeout(() => {
          onStatusReset();
        }, ANIMATION_DELAY);
      });
  };

  return (
    <GamesLogActionForm
      open={location.query && location.query.form === 'add-sale'}
      gameId={gameId}
      status={status}
      onClose={handleClose}
      onSubmit={handleSubmit}
      hasError={hasError}
    />
  );
}

GamesLogActionContainer.displayName = 'GamesLogActionContainer';

export default GamesLogActionContainer;
