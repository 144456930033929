import PropTypes from 'prop-types';

const FeatureSwitcher = ({ hasFeature, active, inactive }) => (hasFeature ? active() : inactive());

FeatureSwitcher.propTypes = {
  hasFeature: PropTypes.bool,
  active: PropTypes.func,
  inactive: PropTypes.func,
};
FeatureSwitcher.defaultProps = {
  hasFeature: false,
  active: () => null,
  inactive: () => null,
};
export default FeatureSwitcher;
