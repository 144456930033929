import { createRequestingStatusReducer } from 'arcade-frontend-ui';

import { types } from '../../actions/audiences';

export default createRequestingStatusReducer(
  {
    AUDIENCES_GET_AUDIENCES: 'DEFAULT',
  },
  [types.AUDIENCES_GET_AUDIENCES],
);

export const getRequestStatus = state => state.audiences.requestStatus;
