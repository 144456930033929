import { setAsyncStorageUser, clearAsyncStorage } from '../asyncStorage';

// import * as backgroundGeolocation from './background-geolocation';
import * as oneSignal from './one-signal';
import * as resources from './resources';
import * as rollbar from './rollbar';

export function signIn(user) {
  return resources.setup(user).then(() =>
    Promise.all([
      setAsyncStorageUser(user),
      // backgroundGeolocation.setup(user),
      oneSignal.setup(user),
      rollbar.setup(user),
    ]),
  );
}

export function signOut() {
  const supportWidget = require('arcade-frontend-features/src/helpers/supportWidget');
  supportWidget.closeSupportWidget();
  return Promise.all([
    // backgroundGeolocation.destroy(),
    resources.destroy(),
    oneSignal.destroy(),
    rollbar.destroy(),
    clearAsyncStorage(),
  ]);
}
