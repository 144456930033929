const normalizeUser = user => ({
  id: user.id.toString(),
  companyIdentifier: user.company_identifier,
  currentBalance: user.current_balance,
  email: user.email,
  firstName: user.firstname,
  lastName: user.lastname,
  imageUrl: user.profile_image,
  locked: user.locked,
  teamName: user.team_name,
});

const fromShowPermissions = payload => ({
  id: payload.user_id.toString(),
  permissions: payload.permissions,
  editable: payload.editable,
});

const fromUserProfile = normalizeUser;

const fromManagePeople = normalizeUser;

const toUserEdit = payload => ({
  firstname: payload.firstName,
  lastname: payload.lastName,
  email: payload.email,
  company_identifier: payload.companyIdentifier.id,
});

const fromUserEdit = normalizeUser;

const normalizers = {
  fromManagePeople,
  fromShowPermissions,
  fromUserProfile,
  fromUserEdit,
  toUserEdit,
};

export default normalizers;
