import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView/ArcView';
import ArcText from '../../primitives/ArcText/ArcText';
import ArcPeopleListItem from './ArcPeopleListItem';

const PEOPLE = 'people';
const TEAMS = 'teams';
const PEOPLE_BY_TEAM = 'peopleByTeam';
const TYPES = { PEOPLE, TEAMS, PEOPLE_BY_TEAM };

const renderPlaceholder = () => (
  <ArcText style={{ margin: 8 }}>{'No results found'}</ArcText>
);

const renderLoadingPlaceholder = () =>
  [0, 1, 2, 3, 4, 5].map(index => (
    <ArcPeopleListItem.Placeholder key={index} />
  ));

const stylePeopleByTeamItem = {
  marginTop: 8,
};

/** **Description**
 *
 * Renders a list of people or teams based on `type` prop.
 *
 * **Requirements**
 *   - `type` of `'people'` or `'teams'`
 *   - `people` or `teams` depending on type
 *   - `selectedPeople` or `selectedTeams` depending on type
 *
 * **Options**
 *   - `isSelectable` to enable checkboxes
 *   - `showSelectAll` to enable select all option
 */
class ArcPeopleList extends React.PureComponent {
  static displayName = 'ArcPeopleList';

  static propTypes = {
    errorText: ArcPropTypes.children,
    people: ArcPropTypes.people,
    peopleByTeam: ArcPropTypes.objectOf(
      ArcPropTypes.arrayOf(ArcPropTypes.object),
    ),
    teams: ArcPropTypes.teams,
    selectedPeople: ArcPropTypes.ids,
    selectedTeams: ArcPropTypes.ids,
    teamsById: ArcPropTypes.shape({}),
    type: ArcPropTypes.oneOf(Object.values(TYPES)),
    style: ArcPropTypes.style,

    onChangePeople: ArcPropTypes.func,
    onChangeTeams: ArcPropTypes.func,
    onError: ArcPropTypes.func,

    isSelectable: ArcPropTypes.bool,
    showSelectAll: ArcPropTypes.bool,
    isFetching: ArcPropTypes.bool,
  };

  static defaultProps = {
    errorText: null,
    people: [],
    peopleByTeam: {},
    teams: [],
    selectedPeople: [],
    selectedTeams: [],
    teamsById: {},
    type: TYPES.PEOPLE,
    style: null,

    onChangePeople: ArcPropTypes.noop,
    onChangeTeams: ArcPropTypes.noop,
    onError: ArcPropTypes.noop,

    isSelectable: false,
    showSelectAll: true,
    isFetching: false,
  };

  static TYPES = TYPES;

  constructor(props) {
    super(props);
    props.onError(props.errorText);
  }

  state = {
    expanded: {},
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.errorText !== this.props.errorText) {
      nextProps.onError(nextProps.errorText);
    }
  }

  get isCheckedSelectAll() {
    return this.allEntitiesSelected(this.props.type);
  }

  get currentType() {
    return this.props.type === PEOPLE ? 'selectedPeople' : 'selectedTeams';
  }

  get hasItems() {
    if (this.props.type === TYPES.PEOPLE_BY_TEAM) {
      return !!Object.keys(this.props[this.props.type]).length;
    }
    return !!this.props[this.props.type].length;
  }

  allEntitiesSelected = () =>
    this.allIds().filter(id => !this.props[this.currentType].includes(id))
      .length === 0;

  allIds = () =>
    this.props[this.props.type]
      .filter(entity => !!entity)
      .map(entity => entity.id);

  allSelected = () =>
    this.allIds().filter(id => !this.props[this.currentType].includes(id))
      .length === 0;

  selectAll = () => (this.allSelected() ? [] : this.allIds());

  handleSelectAll = e => {
    const selectedEntities = this.selectAll();
    if (this.props.type === TEAMS) {
      this.props.onChangeTeams(
        e,
        selectedEntities,
        'all',
        !!selectedEntities.length,
      );
    }
    this.props.onChangePeople(
      e,
      selectedEntities,
      'all',
      !!selectedEntities.length,
    );
  };

  handleChangeArcPeopleListItem = (event, checked, id) => {
    const selectedListType = this.props[this.currentType];
    const selectedItemIndex = selectedListType.indexOf(id);

    const newSelectedItems =
      selectedItemIndex > -1
        ? selectedListType.filter(
            (person, index) => index !== selectedItemIndex,
          )
        : [...selectedListType, id];

    this.props.onChangePeople(event, newSelectedItems, id, checked);
  };

  handleChangePerson = (event, checked, id) => {
    const selectedListType = this.props.selectedPeople;
    const selectedItemIndex = selectedListType.indexOf(id);

    const newSelectedItems =
      selectedItemIndex > -1
        ? selectedListType.filter(
            (person, index) => index !== selectedItemIndex,
          )
        : [...selectedListType, id];

    this.props.onChangePeople(event, newSelectedItems, id, checked);
  };

  handleChangeTeam = (event, checked, id) => {
    const selectedListType = this.props.selectedTeams;
    const selectedItemIndex = selectedListType.indexOf(id);

    const newSelectedItems =
      selectedItemIndex > -1
        ? selectedListType.filter(
            (person, index) => index !== selectedItemIndex,
          )
        : [...selectedListType, id];

    this.props.onChangeTeams(event, newSelectedItems, id, checked);
  };

  changePanelHandlers = {};

  createHandleChangePanel = teamId => {
    if (!this.changePanelHandlers[teamId]) {
      this.changePanelHandlers[teamId] = (event, expanded) => {
        // This whole thing seems dodgy, the real fix is to imlement our own ArcAccordion
        if (
          event.target.tagName === 'svg' ||
          event.target.tagName === 'path' ||
          event.target.getAttribute('role') === 'button'
        ) {
          this.setState({
            expanded: {
              ...this.state.expanded,
              [teamId]: expanded,
            },
          });
        }
      };
    }

    return this.changePanelHandlers[teamId];
  };

  teamIsPartiallyChecked(team) {
    const isChecked = this.props.selectedTeams.indexOf(team.id) > -1;

    const { selectedPeople } = this.props;
    const selectedTeamMembers = team.peopleIds.filter(id =>
      selectedPeople.includes(id),
    );
    const { length } = selectedTeamMembers;

    return length > 0 && length < team.peopleIds.length && !isChecked;
  }

  renderPersonItem = person => (
    <ArcPeopleListItem
      key={person.id}
      id={person.id}
      name={person.name}
      imageUrl={person.imageUrl}
      isSelectable={this.props.isSelectable}
      checked={this.props.selectedPeople.indexOf(person.id) > -1}
      onChange={this.handleChangePerson}
      type={PEOPLE}
      hasPanelView={this.props.type === PEOPLE_BY_TEAM}
      {...person}
    />
  );

  renderTeamItem = team => (
    <ArcPeopleListItem
      key={team.id}
      id={team.id}
      name={team.name}
      imageUrl={team.imageUrl}
      isSelectable={this.props.isSelectable}
      isPartiallyChecked={this.teamIsPartiallyChecked(team)}
      checked={this.props.selectedTeams.indexOf(team.id) > -1}
      onChange={this.handleChangeTeam}
      type={TEAMS}
      hasNoBorderBottom={this.props.type === PEOPLE_BY_TEAM}
      hasPanelView={this.props.type === PEOPLE_BY_TEAM}
      {...team}
    />
  );

  renderPeopleByTeamItem = ([teamId, teamPeople]) => {
    const team = this.props.teamsById[teamId];

    const mappedPeople = teamPeople.sort((a, b) => a.name.localeCompare(b.name))
                                   .map(person => this.renderPersonItem(person));

    return (
      <ArcView key={teamId} style={stylePeopleByTeamItem}>
        <Accordion
          expanded={this.state.expanded[teamId] || false}
          onChange={this.createHandleChangePanel(teamId)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {this.renderTeamItem(team)}
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {mappedPeople}
          </AccordionDetails>
        </Accordion>
      </ArcView>
    );
  };

  renderSelectAll() {
    if (!this.hasItems) {
      return null;
    }

    return (
      <ArcPeopleListItem
        key="-1"
        id="-1"
        name="Select All"
        isSelectable
        onChange={this.hasItems ? this.handleSelectAll : undefined}
        checked={this.isCheckedSelectAll}
      />
    );
  }

  renderPeople() {
    return this.props.people
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter(entity => !!entity)
      .map(this.renderPersonItem);
  }

  renderTeams() {
    return this.props.teams.filter(entity => !!entity).map(this.renderTeamItem);
  }

  renderPeopleByTeam() {
    return Object.entries(this.props.peopleByTeam).map(
      this.renderPeopleByTeamItem,
    );
  }

  renderByType() {
    switch (this.props.type) {
      case TYPES.PEOPLE:
        return this.renderPeople();
      case TYPES.TEAMS:
        return this.renderTeams();
      case TYPES.PEOPLE_BY_TEAM:
        return this.renderPeopleByTeam();
      default:
        return null;
    }
  }

  renderPeopleList() {
    if (this.props.isFetching) {
      return renderLoadingPlaceholder();
    }
    return this.hasItems ? this.renderByType() : renderPlaceholder();
  }

  render() {
    return (
      <ArcView style={this.props.style}>
        {this.props.showSelectAll && this.renderSelectAll()}
        {this.renderPeopleList()}
      </ArcView>
    );
  }
}

export default ArcPeopleList;
