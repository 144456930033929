import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import ArcPropTypes from '../../helpers/arc/propTypes';


const ArcMenuItem = React.forwardRef((props, ref) => (
  <MenuItem
    ref={ref}
    {...props}
  >
    {props.children}
  </MenuItem>
));

ArcMenuItem.propTypes = {
  children: ArcPropTypes.children,
};

ArcMenuItem.defaultProps = {
  children: null,
};


export default ArcMenuItem;
