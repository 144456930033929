import { getResource } from './resource';

export {
  default,
  getRollbar,
  setResource,
  setRollbar,
} from './resource';


export { getResource };

if (window) {
  window.getResource = getResource;
}
