import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const UsersAddOutline = props => (
  <Icon {...props}>
    <Path
      clipRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M18.5 23.5a5 5 0 100-10 5 5 0 000 10z"
      fill="transparent"
    />
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M18.5 16.5v4M16.5 18.5h4"
      fill="transparent"
    />
    <Path
      clipRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
      d="M.5 13.5c.01-.928.202-1.846.565-2.7.4-.8 2.063-1.35 4.153-2.124.565-.209.472-1.684.222-1.96a4.167 4.167 0 01-1.085-3.231A2.725 2.725 0 017 .5a2.725 2.725 0 012.645 2.985A4.167 4.167 0 018.56 6.717c-.25.276-.343 1.751.222 1.96 2.09.774 3.754 1.327 4.153 2.124.363.854.555 1.772.565 2.7L.5 13.5z"
    />
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
      d="M10.912.737A2.573 2.573 0 0112 .5a2.725 2.725 0 012.645 2.985 4.167 4.167 0 01-1.085 3.232c-.25.276-.343 1.751.222 1.96 2.09.774 3.754 1.327 4.153 2.124.024.049.048.1.07.15"
    />
  </Icon>
);


export default UsersAddOutline;
