/* eslint-disable react/no-array-index-key */
import React from 'react';

import ArcView from '../../primitives/ArcView';
import ArcPropTypes from '../../helpers/arc/propTypes';
import { styles } from './ArcInterlaced.style';
import ArcInterlacedCharacter from './ArcInterlacedCharacter';

class ArcInterlaced extends React.PureComponent {
  static displayName = 'ArcInterlaced';

  static propTypes = {
    phrase: ArcPropTypes.string,
    fadeInUp: ArcPropTypes.bool,
    flash: ArcPropTypes.bool,
    color: ArcPropTypes.string,
    fromColor: ArcPropTypes.string,
    toColor: ArcPropTypes.string,
  };

  static defaultProps = {
    phrase: 'arcade',
    fadeInUp: false,
    flash: false,
    color: null,
    toColor: null,
    fromColor: null,
  };

  render() {
    const {
      phrase,
      fadeInUp,
      flash,
      ...rest
    } = this.props;
    return (
      <ArcView
        row
        style={styles(this.props)}
      >
        {
          phrase.split('').map((character, i) => (
            <ArcInterlacedCharacter
              key={i}
              character={character}
              fadeInUp={fadeInUp}
              flash={flash}
              {...rest}
            />
          ))
        }
      </ArcView>
    );
  }
}

export default ArcInterlaced;
