import React from 'react';
import { connect } from 'react-redux';

import {
  ArcPropTypes,
  ArcMain,
  ArcMainView,
  ArcScroll,
  ArcLoader,
  ArcView,
} from 'arcade-frontend-ui';

import { getMetricsViewState } from '../reducers/metrics';

import MetricsIndexContainer from '../containers/metrics/MetricsIndexContainer';
import MetricDetailsContainer from '../containers/metrics/MetricDetailsContainer';

class MetricsView extends React.Component {
  static propTypes = {
    metrics: ArcPropTypes.ids.isRequired,
    currentMetricId: ArcPropTypes.string,
    isFetching: ArcPropTypes.bool,
  };

  static defaultProps = {
    currentMetricId: null,
    isFetching: false,
  };

  state = {
    hasLoader: !this.props.metrics.length,
    dateRange: {
      type: 'time period',
      timePeriod: 'last_30_days',
      fromDate: undefined,
      toDate: undefined,
    },
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isFetching && !this.props.metrics.length) {
      this.showLoader();
    } else if (this.state.hasLoader && !nextProps.isFetching) {
      this.hideLoader();
    }
  }

  handleDateRangeChange = ({ type, timePeriod, fromDate, toDate }) => {
    if (type === 'time period') {
      this.setState({
        dateRange: {
          type,
          timePeriod,
          fromDate: undefined,
          toDate: undefined,
        },
      });
    } else {
      this.setState({
        dateRange: {
          type,
          timePeriod: undefined,
          fromDate,
          toDate,
        },
      });
    }
  };

  showLoader() {
    this.setState({ hasLoader: true });
  }

  hideLoader() {
    setTimeout(() => this.setState({ hasLoader: false }), 250);
  }

  render() {
    const { dateRange } = this.state;

    return (
      <ArcMain>
        {this.props.currentMetricId !== null && (
          <ArcView zIndex="1000" position="absolute" fillToParent>
            <MetricDetailsContainer
              dateRange={dateRange}
              onDateRangeChange={this.handleDateRangeChange}
            />
          </ArcView>
        )}

        <ArcMainView isViewing hasNav={false}>
          <ArcScroll>
            <MetricsIndexContainer
              dateRange={dateRange}
              onDateRangeChange={this.handleDateRangeChange}
            />
          </ArcScroll>
        </ArcMainView>

        <ArcLoader in={this.state.hasLoader} />
      </ArcMain>
    );
  }
}

export default connect(getMetricsViewState)(MetricsView);
