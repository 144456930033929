import React from 'react';
import PropTypes from 'prop-types';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';

function GamesNoResultsBox({ children, ...props }) {
  return (
    <ArcBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      border={1}
      borderColor="grey.300"
      borderRadius={8}
      padding={2}
      minHeight={560}
      {...props}
    >
      {children}
    </ArcBox>
  );
}

GamesNoResultsBox.propTypes = {
  children: PropTypes.node,
};

GamesNoResultsBox.defaultProps = {
  children: null,
};

export default GamesNoResultsBox;
