
export const distribute = (scores, identifier = null) => {
  let first = 0;
  let max = 0;
  let selectedIndex = 0;
  let outlier = scores[0];

  if (scores.length > 0) {
    max = scores[0].score;

    scores.some((e, i) => {
      if (identifier && identifier(e.l)) {
        selectedIndex = i;
        return true;
      }
      return false;
    });

    const upperBound = Math.max(0, scores.length - 3);
    first = Math.min(Math.max(selectedIndex - 1, 0), upperBound);

    if (first === 0) {
      outlier = false;
    }
  }

  const dataForSlot = (index) => {
    const record = scores[index];
    if (record) {
      return { score: record.score, item: record.l, fraction: record.score / (max || 1) };
    }
    return { missing: true };
  };

  const slot1 = dataForSlot(first);
  const slot2 = dataForSlot(first + 1);
  const slot3 = dataForSlot(first + 2);
  return { first, max, selectedIndex, outlier, slot1, slot2, slot3 };
};
