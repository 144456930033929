import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';

const MANAGER_ACTION_MENU_ITEMS = [
  {
    identifier: 'manage_permissions',
    label: 'Permissions',
    requiredPermission: PERMISSIONS.MANAGE_PERMISSIONS,
  },
  {
    identifier: 'edit_profile',
    label: 'Edit Profile',
    requiredPermission: PERMISSIONS.MANAGE_USERS,
  },
  {
    identifier: 'team-assignments',
    label: 'Team Assignments',
    requiredPermission: PERMISSIONS.MANAGE_TEAMS,
  },
];

export default MANAGER_ACTION_MENU_ITEMS;
