import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const ACTIVITY_PAGE_SIZE = 10;

const initialState = false;

const handleNewsfeedLoadIndexSuccess = (state, action) => {
  const { data } = action.payload;
  const { activities } = data;
  return activities.length < ACTIVITY_PAGE_SIZE;
};

const handleclearActivities = () => initialState;

const handlers = {
  [types.NEWSFEED_INDEX.SUCCESS]: handleNewsfeedLoadIndexSuccess,
  [types.NEWSFEED_CLEAR_ACTIVITIES]: handleclearActivities,
};

const getFetchedLastActivity = state => state.newsfeed.fetchedLastActivity;

export default createReducer(initialState, handlers);
export const selectors = { getFetchedLastActivity };
