import React from 'react';
import { useSelector } from 'react-redux';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import getRequestStatusFromResourceStatus from 'arcade-frontend-core/src/helpers/getRequestStatusFromResourceStatus';
import { resources } from 'arcade-frontend-core/src/resources/chatAudiences';
import ArcAudienceCreateForm from 'arcade-frontend-widgets/src/components/ArcAudienceCreateForm';

function ChatCreateFormContainer(props) {
  const currentUser = useSelector(getCurrentUser);

  const apiGetChatEntities = useResourceAction(resources.apiGetChatEntities);
  const apiPostChatEntities = useResourceAction(resources.apiPostChatEntities);

  React.useEffect(() => {
    apiGetChatEntities.requestData().then(apiGetChatEntities.onStatusReset);
  }, []);

  function handleSubmit(data) {
    const normalizedData = {
      entity_ids: data.entity,
      excluded_user_ids: data.excluded,
      name: data.name,
      team_ids: data.team,
      user_ids: data.person,
      excluded_entities_by_parent_uuid: data.excludedEntitiesByParentUuid,
      parent_entity_uuids_by_selected_entity_uuid:
        data.parentEntityUuidsBySelectedEntityUuid,
    };

    apiPostChatEntities
      .requestData({
        resources: [normalizedData],
      })
      .then(resp => {
        if (!window.ArcadeMessagingService) {
          return;
        }
        const { id } = resp.data.conversation;

        window.ArcadeMessagingService.getConversations(() => {
          window.ArcadeMessagingService.selectConversation(id);
        });
      });
  }

  return (
    <ArcAudienceCreateForm
      title="New Chat"
      confirmDialogContent="You will lose this unsaved chat"
      currentUserId={currentUser.id}
      disableSelf
      entitiesByUuid={
        apiGetChatEntities.hasData
          ? apiGetChatEntities.data.resources
          : undefined
      }
      initialNamePlaceholder="Chat Name"
      onClose={apiGetChatEntities.onStatusReset}
      onSubmit={handleSubmit}
      browserRequestStatus={getRequestStatusFromResourceStatus(
        apiGetChatEntities.status,
      )}
      confirmationRequestStatus={getRequestStatusFromResourceStatus(
        apiPostChatEntities.status,
      )}
      {...props}
    />
  );
}

ChatCreateFormContainer.displayName = 'ChatCreateFormContainer';

export default ChatCreateFormContainer;
