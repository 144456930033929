import { createRequestingStatusReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/metrics';

const initialState = {
  MANAGE_METRICS_CREATE: 'DEFAULT',
  MANAGE_METRICS_UPDATE: 'DEFAULT',
  MANAGE_METRICS_EDIT: 'DEFAULT',
};

const actions = [
  types.MANAGE_METRICS_CREATE,
  types.MANAGE_METRICS_UPDATE,
  types.MANAGE_METRICS_EDIT,
];

const requestStatus = createRequestingStatusReducer(initialState, actions);

export default requestStatus;

export const getRequestStatus = state => state.manage.metrics.requestStatus;
