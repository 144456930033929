import { react2angular } from 'react2angular';

import ArcChecklistContainer from '../containers/app/ArcChecklistContainer';

import { createProvidedContainer } from './helpers';

const ProvidedArcChecklistContainer = createProvidedContainer(ArcChecklistContainer);

export default react2angular(ProvidedArcChecklistContainer, [
  'onClickItem',
]);
