import { createReducer } from 'arcade-frontend-ui';

// import * as types from '../../actions/metrics/types';
import * as typesRoutes from 'arcade-frontend-core/src/types/routes';

const setMetricId = (state, action) => {
  const { id } = action.payload;

  return id.toString();
};

const setManageMetricId = (state, action) => {
  const { id } = action.payload;

  return id.toString();
};

const handleRouteMetric = () => null;
const handleRouteMetricShow = setMetricId;
const handleRouteMetricEdit = setManageMetricId;

const initialState = null;

const handlers = {
  [typesRoutes.ROUTE_METRICS]: handleRouteMetric,
  [typesRoutes.ROUTE_METRICS_SHOW]: handleRouteMetricShow,
  [typesRoutes.ROUTE_MANAGE_METRICS_EDIT]: handleRouteMetricEdit,
};

const currentMetricId = createReducer(initialState, handlers);

export default currentMetricId;

export const getCurrentMetricId = state => state.metrics.currentMetricId;
