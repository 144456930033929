const getOptionColor = state => {
  if (state.isSelected) return '#F0FBFE'
  if (state.isFocused) return '#F8F8F8'
  return 'transparent'
}

const fontSizeStyle = 16
const fontSizeWeight = 400
const minWidthStyle = 230

export const styles = {
  control: (base, state) => ({
    ...base,
    fontSize: fontSizeStyle,
    fontWeight: fontSizeWeight,
    minHeight: 56,
    minWidth: minWidthStyle,
    maxWidth: 310,
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      borderColor: state.isFocused ? '#0290C3' : '#23282C',
    },
    borderColor: state.isFocused ? '#0290C3' : base.borderColor,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: getOptionColor(state),
    cursor: 'pointer',
    color: state.isSelected ? '#21afdb' : 'inherit',
    fontSize: fontSizeStyle,
    fontWeight: fontSizeWeight,
    '&:active': {
      backgroundColor: '#F0FBFE',
      color: '#21afdb',
    },
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: '#F8F8F8',
  }),
  menu: base => ({
    ...base,
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    position: 'fixed',
    minWidth: minWidthStyle,
    top: 'auto',
    width: 'auto',
  }),
}