import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import ArcCheckbox from '../../elements/ArcCheckbox';
import ArcCurrencyField from '../../elements/ArcCurrencyField';
import ArcChestSelect from '../ArcChestSelect';
import ArcDatePicker from '../../elements/ArcDatePicker';
import ArcDateRange from '../../elements/ArcDateRange';
import ArcDateTime from '../../elements/ArcDateTime';
import ArcImageSelect from '../../elements/ArcImageSelect';
import ArcMultiAdd from '../../elements/ArcMultiAdd';
import ArcNumberField from '../../elements/ArcNumberField';
import ArcRadioButtonGroup from '../../elements/ArcRadioButtonGroup';
import ArcSelect from '../../elements/ArcSelect';
import ArcTextField from '../../elements/ArcTextField';
import ArcTimePicker from '../../elements/ArcTimePicker';
import ArcUserInput from '../ArcUserInput/ArcUserInput';

import { makeValidators } from '../../helpers/utils/validators';

import ArcFormControl from '../../forms/ArcFormControl';

import ArcTokenField from '../ArcTokenField';

export const fieldsByType = {
  checkbox: ArcCheckbox,
  currency: ArcCurrencyField,
  'chest-select': ArcChestSelect,
  date: ArcDatePicker,
  dateRange: ArcDateRange,
  datetime: ArcDateTime,
  email: ArcTextField,
  image: ArcImageSelect,
  multiAdd: ArcMultiAdd,
  number: ArcNumberField,
  password: ArcTextField,
  radio: ArcRadioButtonGroup,
  select: ArcSelect,
  text: ArcTextField,
  tel: ArcTextField,
  tokens: ArcTokenField,
  time: ArcTimePicker,
  user_input: ArcUserInput,
};

const getDefaultValidations = (type) => {
  switch (type) {
    case 'email':
      return {
        isEmail: true,
      };
    case 'currency':
      return {
        isCurrency: true,
      };
    case 'number':
      return {
        isNumber: true,
      };
    default:
      return {};
  }
};

class ArcSmartField extends React.Component {
  static propTypes = {
    fullWidth: PropTypes.bool,
    hasLabel: PropTypes.bool,
    isTouched: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.bool),
      PropTypes.bool,
    ]),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(fieldsByType)),
    validations: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ])),
    validator: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.any]),
    form: PropTypes.shape({
      setFieldError: PropTypes.func.isRequired,
      setFieldTouched: PropTypes.func.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    hasNoMarginBottom: PropTypes.bool,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    fullWidth: undefined,
    hasLabel: true,
    isTouched: false,
    label: '',
    placeholder: '',
    type: 'text',
    validator: null,
    validations: {},
    value: undefined,
    hasNoMarginBottom: false,
    onBlur: global.noop,
    description: undefined,
  };

  state = {
    errors: this.getErrors(),
  };

  componentDidUpdate(prevProps) {
    if (prevProps.validations !== this.props.validations
      || prevProps.label !== this.props.label
      || prevProps.placeholder !== this.props.placeholder
      || prevProps.value !== this.props.value
    ) {
      const nextErrors = this.getErrors();
      this.setErrors(nextErrors);
    }
  }

  componentWillUnmount() {
    this.props.form.setFieldError(this.props.name, this.state.errors[0]);
  }

  get label() {
    return this.props.label || this.props.placeholder;
  }

  get hasError() {
    return this.props.isTouched && !!this.state.errors.length;
  }

  getErrors() {
    const errors = [];

    const defaultValidations = getDefaultValidations(this.props.type);

    if (this.props.validations || defaultValidations) {
      const { isRequired, ...fieldValidations } = (this.props.validations || {});

      const validations = {
        isRequired,
        ...defaultValidations,
        ...(fieldValidations || {}),
      };

      const { messages, validators } = makeValidators(this.label, validations);

      Object.keys(validators).forEach((validator) => {
        const isValid = validators[validator](this.props.value);
        const errorMessage = messages[validator];

        if (!isValid && errors.indexOf(errorMessage) === -1) {
          errors.push(errorMessage);
        }
      });
    }

    if (this.props.validator) {
      this.props.validator(errors);
    }

    return errors;
  }

  setErrors = errors => this.setState({ errors });

  render() {
    const {
      form,
      name,
      description,
      fullWidth,
      hasLabel,
      hasNoMarginBottom,
      isTouched,
      validations,
      validator,
      ...props
    } = this.props;

    const ArcField = fieldsByType[this.props.type] || ArcTextField;
    const placeholder = this.hasError && this.props.placeholder ? this.state.errors[0] : this.props.placeholder;

    let label = this.hasError ? this.state.errors[0] : this.props.label;

    if (!hasLabel) {
      label = props.value && this.hasError ? label : '';
    }

    let error = this.hasError;

    if (props.type === 'checkbox') {
      // checked = props.value.target ? !!props.value.target.value : props.value;
      error = undefined;
    }

    let controlDescription = description;

    if (this.hasError && !hasLabel && typeof description === 'undefined') {
      controlDescription = this.state.errors[0];
    }

    return (
      <ArcFormControl
        description={controlDescription}
        fullWidth={fullWidth}
        hasNoMarginBottom={hasNoMarginBottom}
      >
        <ArcField
          data-testid={`ArcFormField-${props.name}`}
          id={props.name}
          fullWidth={fullWidth}
          {...props}
          checked={props.type === 'checkbox' ? props.value : undefined}
          error={error}
          label={label}
          onBlur={(e) => {
            this.props.onBlur(e);
            form.setFieldTouched(name, true);
            setTimeout(() => {
              form.setFieldError(name, this.state.errors[0]);
            });
          }}
          placeholder={placeholder}
          type={props.type === 'number' ? 'tel' : props.type}
          value={props.type === 'checkbox' ? props.name : props.value}
          variant={ArcField === ArcSelect || ArcField === ArcTextField || ArcField === ArcNumberField ? 'outlined' : undefined}
        />
      </ArcFormControl>
    );
  }
}

const ArcFormField = ({ name, children, ...props }) => (
  <Field
    name={name}
  >
    {({ field, form }) => (
      <ArcSmartField
        id={name}
        name={name}
        {...field}
        {...props}
        form={form}
        isTouched={props.isTouched || (form.touched && form.touched[name])}
        onBlur={() => {
          if (props.onBlur) {
            props.onBlur(form.values[name]);
          }
          form.handleBlur(name);
        }}
        onChange={(evtOrVal) => {
          if (evtOrVal && evtOrVal.target) {
            field.onChange(evtOrVal);
          } else {
            form.setFieldValue(name, evtOrVal);
          }

          if (props.onChange) {
            props.onChange(evtOrVal);
          }
        }}
      >
        {children}
      </ArcSmartField>
    )}
  </Field>
);

ArcFormField.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  isTouched: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.bool),
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

ArcFormField.defaultProps = {
  children: undefined,
  name: undefined,
  isTouched: undefined,
  onChange: undefined,
  onBlur: global.noop,
};

export default ArcFormField;
