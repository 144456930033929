import { useSelector } from 'react-redux';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import { getCurrentUserFeatures } from 'arcade-frontend-core/src/reducers/user';

const flagNames = Object.values(FEATURE_FLAGS);

export default function useFeatureFlag(flag) {
  const hasFeature = useSelector(state => getCurrentUserFeatures(state, flag));

  if (flagNames.includes(flag)) {
    return hasFeature;
  }
  console.error(`useFeatureFlag called with unrecognised flag ${flag}`);
  return false;
}
