import createResourceAction from 'arcade-frontend-core/src/helpers/createResourceAction';
import {
  makeDateFilterParams,
  makePaginationParams,
} from 'arcade-frontend-core/src/actions/helpers';

import { normalizeGames, normalizeCompletedGames } from '../normalizers';
import {
  getActiveGames,
  getPendingGames,
  getUpcomingGames,
} from '../../reducers/games';
import { getCompletedGames } from '../../reducers/completed-games';

export const apiGetGamesIndex = createResourceAction('read', {
  resourceType: 'games',
  requestKey: 'apiGetGamesIndex',
  list: 'index',

  api: () => ({
    method: 'GET',
    url: '/v4/games',
    transformResponse: data => ({
      resources: normalizeGames(data.games),
    }),
  }),
});

export const apiGetGamesShow = createResourceAction('read', {
  resourceType: 'games',
  requestKey: 'apiGetGamesShow',

  api: props => {
    const id = props.resources[0];

    return {
      method: 'GET',
      url: `/v5/games/${id}`,
      transformResponse: data => ({
        resources: normalizeGames([data.game]),
      }),
    };
  },
});

export const apiGetActiveGamesIndex = createResourceAction('update', {
  resourceType: 'games',
  requestKey: 'apiGetActiveGamesIndex',
  list: 'active',
  mergeListIds: false,
  getInitialData: getActiveGames,

  api: () => ({
    method: 'GET',
    url: '/v5/games',
    params: {
      status: 'active',
    },
    transformResponse: data => ({
      resources: normalizeGames(data.games),
    }),
  }),
});

export const apiGetPendingGamesIndex = createResourceAction('update', {
  resourceType: 'games',
  requestKey: 'apiGetPendingGamesIndex',
  list: 'pending',
  mergeListIds: false,
  getInitialData: getPendingGames,

  api: () => ({
    method: 'GET',
    url: '/v5/games',
    params: {
      status: 'needs_verification',
    },
    transformResponse: data => ({
      resources: normalizeGames(data.games),
    }),
  }),
});

export const apiGetUpcomingGamesIndex = createResourceAction('read', {
  resourceType: 'games',
  requestKey: 'apiGetUpcomingGamesIndex',
  list: 'upcoming',
  getInitialData: getUpcomingGames,

  api: () => ({
    method: 'GET',
    url: '/v5/games',
    params: {
      status: 'upcoming',
    },
    transformResponse: data => ({
      resources: normalizeGames(data.games),
    }),
  }),
});

export const apiGetCompletedGamesIndex = createResourceAction('read', {
  resourceType: 'completedGames',
  requestKey: 'apiGetCompletedGamesIndex',
  list: 'index',
  getInitialData: getCompletedGames,

  api: props => {
    const { meta = {} } = props;
    const {
      page = 0,
      rowsPerPage = 5,
      type,
      timePeriod,
      fromDate,
      toDate,
    } = meta;

    return {
      method: 'GET',
      params: {
        ...makePaginationParams({ page, rowsPerPage }),
        ...makeDateFilterParams({ type, timePeriod, fromDate, toDate }),
      },
      url: '/v5/games/completed',
      transformResponse: data => ({
        resources: normalizeCompletedGames(data.games),
        meta: {
          ...meta,
          totalGames: data.totalGames,
        },
      }),
    };
  },
});

export const apiPostGamesLogAction = createResourceAction('update', {
  resourceType: 'gameLogActions',
  requestKey: 'apiPostGamesLogAction',

  api: payload => {
    const { id, score, date } = payload.resources[0];

    return {
      method: 'POST',
      url: `/v5/games/${id}/sales`,
      data: {
        quantity: score,
        date,
      },
    };
  },
});

export const apiPutGamesEventsComplete = createResourceAction('read', {
  resourceType: 'gamesEventsComplete',
  requestKey: 'apiPutGamesEventsComplete',
  api: payload => {
    const { id } = payload.resources[0];
    return {
      method: 'PUT',
      url: `/v5/games/${id}/complete_rpa`,
      transformResponse: data => ({
        resources: [data],
      }),
    };
  },
});

export const apiPostGamesEventsVerifyAll = createResourceAction('read', {
  resourceType: 'gameEventsVerifyAll',
  requestKey: 'apiPostGamesEventsVerifyAll',
  api: payload => {
    const { id } = payload.resources[0];
    return {
      method: 'POST',
      url: `/v5/games/${id}/pending_sales/approve_all_outstanding`,
      transformResponse: data => ({
        resources: [data],
      }),
    };
  },
});
