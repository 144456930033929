import ArcStyles from '../../styles';

const styleConfirmationContainer = {
  width: '100%',
  height: '100%',
  paddingBottom: 32,
};

const styleConfirmationInner = {
  padding: 8,
  position: 'relative',
  width: '100%',
  zIndex: 2,
};

const styleConfirmationMessage = {
  ...ArcStyles.theme.arcTextContentHeadingStyles,
  fontSize: 32,
  lineHeight: '40px',
  marginBottom: 32,
};

const styleFireworksContainer = {
  position: 'relative',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
};

const styleScoreboardContainer = {
  width: '100%',
};

const styleConfirmationButton = {
  marginTop: 32,
};

export {
  styleConfirmationContainer,
  styleConfirmationInner,
  styleConfirmationMessage,
  styleFireworksContainer,
  styleScoreboardContainer,
  styleConfirmationButton,
};
