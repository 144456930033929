import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const ManageIntegrations = props => (
  <Icon {...props}>
    <Path d="M10.49 23.514H1.48999C1.22477 23.514 0.97042 23.4087 0.782883 23.2211C0.595347 23.0336 0.48999 22.7792 0.48999 22.514V1.51401C0.48999 1.24879 0.595347 0.994437 0.782883 0.806901C0.97042 0.619364 1.22477 0.514008 1.48999 0.514008H14.783C15.0482 0.514064 15.3025 0.619458 15.49 0.807008L19.2 4.51401C19.3875 4.7015 19.4929 4.95581 19.493 5.22101V8.51401" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
    <Path d="M3.48999 15.514H9.48999" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
    <Path d="M4.98999 10.514H5.98999C6.1226 10.514 6.24978 10.5667 6.34354 10.6605C6.43731 10.7542 6.48999 10.8814 6.48999 11.014V15.514H4.48999V11.014C4.48999 10.8814 4.54267 10.7542 4.63644 10.6605C4.73021 10.5667 4.85738 10.514 4.98999 10.514V10.514Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
    <Path d="M10.49 12.514V8.01401C10.49 7.8814 10.4373 7.75422 10.3435 7.66045C10.2498 7.56669 10.1226 7.51401 9.98999 7.51401H8.98999C8.85738 7.51401 8.73021 7.56669 8.63644 7.66045C8.54267 7.75422 8.48999 7.8814 8.48999 8.01401V15.514" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
    <Path d="M17.74 18.483C18.5336 18.483 19.177 17.8396 19.177 17.046C19.177 16.2524 18.5336 15.609 17.74 15.609C16.9464 15.609 16.303 16.2524 16.303 17.046C16.303 17.8396 16.9464 18.483 17.74 18.483Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
    <Path d="M18.961 11.485L19.385 12.877C19.4549 13.1081 19.61 13.3039 19.8188 13.425C20.0277 13.546 20.2748 13.5832 20.51 13.529L21.921 13.202C22.1884 13.1417 22.4681 13.1687 22.7191 13.2789C22.9701 13.3892 23.1792 13.5769 23.3157 13.8146C23.4522 14.0523 23.509 14.3275 23.4777 14.5999C23.4465 14.8722 23.3288 15.1274 23.142 15.328L22.155 16.393C21.992 16.5702 21.9015 16.8022 21.9015 17.043C21.9015 17.2838 21.992 17.5158 22.155 17.693L23.142 18.758C23.3288 18.9586 23.4465 19.2138 23.4777 19.4861C23.509 19.7585 23.4522 20.0337 23.3157 20.2714C23.1792 20.5091 22.9701 20.6968 22.7191 20.8071C22.4681 20.9173 22.1884 20.9443 21.921 20.884L20.51 20.557C20.2748 20.5028 20.0277 20.54 19.8188 20.661C19.61 20.7821 19.4549 20.9779 19.385 21.209L18.961 22.601C18.8821 22.8635 18.7207 23.0935 18.5008 23.257C18.2808 23.4206 18.0141 23.5089 17.74 23.5089C17.4659 23.5089 17.1992 23.4206 16.9792 23.257C16.7593 23.0935 16.5979 22.8635 16.519 22.601L16.1 21.214C16.0303 20.983 15.8754 20.7872 15.6667 20.6661C15.458 20.545 15.2111 20.5078 14.976 20.562L13.565 20.889C13.2976 20.9493 13.0179 20.9223 12.7669 20.8121C12.5159 20.7018 12.3068 20.5141 12.1703 20.2764C12.0338 20.0387 11.977 19.7635 12.0083 19.4911C12.0395 19.2188 12.1572 18.9636 12.344 18.763L13.331 17.698C13.494 17.5208 13.5845 17.2888 13.5845 17.048C13.5845 16.8072 13.494 16.5752 13.331 16.398L12.344 15.333C12.1549 15.1331 12.035 14.8777 12.0021 14.6045C11.9692 14.3313 12.025 14.0548 12.1613 13.8157C12.2976 13.5767 12.5071 13.3878 12.759 13.2769C13.0108 13.166 13.2917 13.1391 13.56 13.2L14.971 13.527C15.2061 13.5812 15.453 13.544 15.6617 13.4229C15.8704 13.3018 16.0253 13.106 16.095 12.875L16.519 11.483C16.5981 11.2206 16.7597 10.9907 16.9798 10.8273C17.1998 10.664 17.4667 10.5759 17.7407 10.5761C18.0148 10.5764 18.2815 10.6649 18.5013 10.8286C18.7211 10.9923 18.8823 11.2225 18.961 11.485V11.485Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
  </Icon>
);

export default ManageIntegrations;
