import ArcView from '../../primitives/ArcView';
import ArcStyles, { createWithStyles } from '../../styles';

const ArcViewContentBody = theme => ({
  root: {
    lineHeight: '24px',
    margin: '0 16px 8px 16px',
    fontWeight: '300',
    color: ArcStyles.theme.defaultTextColor,

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
});

export default createWithStyles(ArcViewContentBody)(ArcView);
