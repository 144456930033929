export const sampleCsvHeadings = [
  {
    key: 'formattedCreatedAt',
    title: 'Date',
  },
  {
    key: 'user',
    title: 'Person',
  },
  {
    key: 'quantity',
    title: 'Amount',
  },
];

export const sampleCsvData = [
  {
    id: '131627',
    name: 'Cards Closed',
    kpiId: null,
    createdAt: 'December 21, 2017 13:49',
    formattedCreatedAt: 'Dec 21, 1:49pm',
    quantity: 1,
    user: 'Chris Odicho',
  },
  {
    id: '131626',
    name: 'Cards Closed',
    kpiId: null,
    createdAt: 'December 21, 2017 13:48',
    formattedCreatedAt: 'Dec 21, 1:48pm',
    quantity: 2,
    user: 'Chris Odicho',
  },
];

const createValidRow = (item) => {
  const strItem = item ? item.toString() : '';

  if (strItem.indexOf(',') > -1) {
    return `"${strItem}"`;
  }

  return strItem;
};

const createCsvRow = (headings, item) => {
  const row = [];

  headings.forEach((heading) => {
    const validRow = createValidRow(item[heading.key]);
    row.push(validRow);
  });

  return row;
};

export const createCsv = (headings, items) => {
  const rows = [headings.map(heading => heading.title)];

  items.forEach((item) => {
    const row = createCsvRow(headings, item);
    rows.push(row);
  });

  let csv = '';

  rows.forEach((row) => {
    csv += `${row.join(',')}\r\n`;
  });

  const csvData = new Blob([csv], { type: 'text/csv' });

  return URL.createObjectURL(csvData);
};

export const downloadCsv = (csvUrl, title = 'download') => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.download = title;
  a.href = csvUrl;
  a.click();
  document.body.removeChild(a);
};
