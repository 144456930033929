export const capitalize = str => str
  .replace(/-\w/g, matches => matches[1].toUpperCase())
  .replace(/^\w/, c => c.toUpperCase());


// https://eslint.org/docs/rules/no-prototype-builtins
const objHasOwnProperty = (obj = {}, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

export const getClassNamesAndNonConsumedProps = (props, classes, name) => {
  const {
    children,
    classes: classesFromProps,
    className,
    component,
    style,
    theme,
    internalRef,
    ...rest
  } = props;

  if (process.env.NODE_ENV === 'development' && !classes.root && name !== 'WithStyles') {
    // Throw a warning if we forgot to include a `root` key in the styles object
    console.error([ // eslint-disable-line no-console
      'createWithStyles could not find a `root` class for the component',
      `\`${name}\``,
    ].join(' '));
  }

  const nonConsumedProps = { children };
  const classNames = [className, classes.root];

  Object.keys(rest).forEach((key) => {
    const propValue = typeof rest[key] === 'number' ? rest[key].toString() : rest[key];
    const propType = typeof rest[key];

    if (propType === 'function') {
      // always pass down function props
      nonConsumedProps[key] = rest[key];
    }

    if (objHasOwnProperty(classes, key)) {
      classNames.push(rest[key] && classes[key]);
    } else if ((propType === 'string' || propType === 'number') && classes[`${key}${capitalize(propValue)}`]) {
      classNames.push(classes[`${key}${capitalize(propValue)}`]);
    } else if (rest[key] && rest[key].constructor === Array) {
      rest[key].forEach((property) => {
        if (typeof property === 'string') {
          classNames.push(classes[`${key}${capitalize(property)}`]);
        }
      });
    } else if (propType !== 'undefined') {
      nonConsumedProps[key] = rest[key];
    }
  });

  if (style && typeof style === 'object' && !style.root) {
    // always pass down inline style objects
    nonConsumedProps.style = style;
  }

  return {
    classNames,
    nonConsumedProps,
  };
};

let counter = -1;

export const getName = (Component, styles, options = {}) => {
  if (options.name) {
    return options.name;
  }

  if (styles && typeof styles === 'function' && styles.name.toLowerCase() !== styles.name) {
    return styles.name;
  }

  if (typeof Component === 'string') {
    counter += 1;

    return `${Component}${counter}`;
  }

  return Component.name || Component.constructor.name;
};

export const getRef = (Component, internalRef) => {
  if (typeof Component === 'string') {
    return internalRef ? { ref: internalRef } : undefined;
  }

  if (internalRef) {
    return { internalRef };
  }

  return undefined;
};
