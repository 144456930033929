import React from 'react';

import ArcButton from '../../elements/ArcButton';
import ArcLink from '../../elements/ArcLink';
import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import { createWithStyles } from '../../styles';

const styles = {
  Container: () => ({
    root: {
      paddingRight: 16,
    },
  }),

  RewardText: theme => ({
    root: {
      ...theme.typography.fontFamily,
      margin: 0,
      padding: 8,
      paddingLeft: 16,
      maxWidth: 160,
      lineHeight: '20px',
      fontSize: 14,
      color: theme.palette.text.primary,
    },
  }),

  RewardImageContainer: () => ({
    root: {
      padding: 0,
    },
  }),

  RewardImage: () => ({
    root: {
      marginRight: 8,
      width: 56,
      height: 56,
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);
const RewardText = createWithStyles(styles.RewardText)(ArcView);
const RewardImageContainer = createWithStyles(styles.RewardImageContainer)(ArcView);
const RewardImage = createWithStyles(styles.RewardImage)('img');

const styleButton = {
  borderRadius: 0,
  borderLeft: '1px solid #f3f3f3',
};

const styleDisabled = {
  opacity: 0.2,
};


/** **Description**
  *
  * Renders a reward footer for  `<ArcChecklist />`
  *
*/

class ArcChecklistReward extends React.PureComponent {
  static displayName = 'ArcChecklistReward';

  static propTypes = {
    onClaim: ArcPropTypes.func,
    percent: ArcPropTypes.number,
    value: ArcPropTypes.string,
  };

  static defaultProps = {
    onClaim: ArcPropTypes.noop,
    percent: 0,
    value: '',
  };

  renderClaimButton() {
    return (
      <ArcLink onClick={this.props.onClaim}>
        Claim Reward
      </ArcLink>
    );
  }

  render() {
    const { value } = this.props;

    return (
      <Container>
        <RewardImageContainer row align="center">
          <RewardText>
            {this.props.percent >= 100 ? 'Nice work!' : `Complete to unlock an ${value.toLowerCase()} chest!`}
          </RewardText>
          <ArcView>
            <ArcButton
              color="primary"
              onClick={this.props.onClaim}
              disabled={this.props.percent < 100}
              style={styleButton}
              size="small"
            >
              <RewardImage
                src={`https://d17rkelr6jjme9.cloudfront.net/chests/${value.toLowerCase()}.svg`}
                alt="Common Chest"
                style={this.props.percent < 100 ? styleDisabled : undefined}
              />
              Claim Reward
            </ArcButton>
          </ArcView>
        </RewardImageContainer>
      </Container>
    );
  }
}

export default ArcChecklistReward;
