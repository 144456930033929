import React from 'react';
import PropTypes from 'prop-types';

import ArcView from '../../primitives/ArcView';


function ArcMultiSelectValueContainer(props) {
  return (
    <ArcView
      overflow="hidden"
      row
      align="center"
      wrap="wrap"
      flex="1"
      padding="4"
    >
      {props.children}
    </ArcView>
  );
}

ArcMultiSelectValueContainer.displayName = 'ArcMultiSelectValueContainer';

ArcMultiSelectValueContainer.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  selectProps: PropTypes.shape({}).isRequired,
};

ArcMultiSelectValueContainer.defaultProps = {
  children: null,
};

export default ArcMultiSelectValueContainer;
