import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ArcToken from '../../assets/ArcToken';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';


const styleArcToken = {
  width: 16,
  height: 16,
};

class ArcTransactionLogTile extends React.PureComponent {
  static propTypes = {
    isFetching: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string,
  };

  static defaultProps = {
    isFetching: false,
    label: '',
    value: '',
  };

  get value() {
    if (this.props.isFetching) {
      return '10,000';
    }

    return this.props.value;
  }

  render() {
    return (
      <ArcView
        row
      >
        <ArcView
          padding="8"
          justify="center"
        >
          <ArcToken style={styleArcToken} />
        </ArcView>

        <ArcView>
          <ArcView
            marginBottom="8"
            className={cx([
              this.props.isFetching && 'shimmer',
            ])}
          >
            <ArcText isLarge>
              {this.value}
            </ArcText>
          </ArcView>
          <ArcText
            isSmaller
            textTransform="uppercase"
          >
            {this.props.label}
          </ArcText>
        </ArcView>
      </ArcView>
    );
  }
}

export default ArcTransactionLogTile;
