import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoney from '@material-ui/icons/AttachMoney';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView/ArcView';
import { isNumber } from '../../helpers/utils/validators';

const styleSymbol = {
  fontSize: 18,
  marginBottom: 4,
};

const styleInputAdornment = {
  marginRight: 0,
};

const renderInputAdornment = () => (
  <InputAdornment
    position="start"
    style={styleInputAdornment}
  >
    <AttachMoney style={styleSymbol} />
  </InputAdornment>
);

class ArcCurrencyField extends PureComponent {
  static propTypes = {
    precision: ArcPropTypes.number,
    separator: ArcPropTypes.string,
    delimiter: ArcPropTypes.string,
    symbol: ArcPropTypes.string,
    onChange: ArcPropTypes.func,
    onBlur: ArcPropTypes.func,
    onFocus: ArcPropTypes.func,
    value: ArcPropTypes.string,
  };

  static defaultProps = {
    precision: 2,
    separator: ',',
    delimiter: '.',
    symbol: '$',
    onChange: ArcPropTypes.noop,
    onBlur: ArcPropTypes.noop,
    onFocus: ArcPropTypes.noop,
    value: '',
  };

  state = {
    renderedValue: '',
  };

  onBlur = (event) => {
    const { value } = event.target;
    const isValid = isNumber(value);
    if (!isValid) return;

    const splitValue = value.split('.');
    const rawCharacteristic = splitValue[0];
    const rawMantissa = splitValue[1];

    const formattedValue = this.finalFormat(rawCharacteristic, rawMantissa);
    this.setState({ renderedValue: formattedValue });
  };

  onChange = (event) => {
    this.setState({ renderedValue: event.target.value });
  };

  finalFormat = (characteristic, mantissa) => {
    const numCharacteristic = characteristic === '' ? '0' : characteristic;
    const roundedWholeValue = this.roundWholeValue(numCharacteristic, mantissa);

    const splitValue = `${roundedWholeValue}`.split('.');
    const rawCharacteristic = splitValue[0];
    const finalMantissa = this.zeroPadMantissa(splitValue[1]);
    const finalCharacteristic = this.formatCharacteristic(rawCharacteristic);

    const finalValue = [finalCharacteristic, finalMantissa].join(this.props.delimiter);
    return finalValue;
  };

  roundWholeValue = (characteristic, mantissa) => {
    const formattedMantissa = this.formatMantissa(mantissa);
    return parseFloat(characteristic) + parseFloat(formattedMantissa);
  };

  formatCharacteristic = (characteristic) => {
    let formattedCharacteristic = this.checkLeadingZeros(characteristic);
    formattedCharacteristic = this.addThousandsSeparator(formattedCharacteristic);
    return formattedCharacteristic;
  };

  removeLeadingZeros = (characteristicElement = []) => {
    let foundNum = false;

    return characteristicElement.reduce((accumulator, element) => {
      if (element !== '0' || foundNum) {
        foundNum = true;
        accumulator.push(element);
      }
      return accumulator;
    }, []);
  };

  checkLeadingZeros = (characteristic) => {
    const characteristicElement = characteristic.split('');

    if (characteristicElement.length === 1 && characteristic === '0') {
      return ['0'];
    }
    return this.removeLeadingZeros(characteristicElement);
  };

  addThousandsSeparator = (characteristic) => {
    const { length } = characteristic;
    const newArray = [];

    for (let i = 0; i < length - 1; i += 1) {
      newArray.push(characteristic[i]);
      if ((length - (i + 1)) % 3 === 0) {
        newArray.push(this.props.separator);
      }
    }
    newArray.push(characteristic[length - 1]);
    return newArray.join('');
  };

  formatMantissa = (mantissa = '') => {
    const { precision } = this.props;

    let beforeMantissa = mantissa.substring(0, this.props.precision);

    if (mantissa.length > precision) {
      beforeMantissa = this.roundMantissa(mantissa, beforeMantissa);
    } else if (mantissa.length < precision) {
      beforeMantissa = this.zeroPadMantissa(mantissa);
    } else {
      return beforeMantissa / (10 ** this.props.precision);
    }

    return beforeMantissa / (10 ** this.props.precision);
  };

  zeroPadMantissa = (mantissa = '') => {
    const numbers = this.props.precision - mantissa.length;
    const zeroPadMantissa = String(0).repeat(numbers);
    return `${mantissa}${zeroPadMantissa}`;
  };

  roundMantissa = (mantissa) => {
    let newMantissa = parseInt(mantissa, 10);
    newMantissa /= (10 ** (mantissa.length - this.props.precision));
    const roundedMantissa = Math.round(newMantissa);
    return roundedMantissa;
  };

  selectAll = (event) => {
    event.target.select();
  };

  handleOnChange = (e, val) => {
    this.props.onChange(e, val);
    this.onChange(e, val);
  };

  handleOnBlur = (e, val) => {
    this.props.onBlur(e, val);
    this.onBlur(e, val);
  };

  handleOnFocus = (e, val) => {
    this.props.onFocus(e, val);
    this.selectAll(e, val);
  };

  render() {
    const {
      precision,
      separator,
      delimiter,
      symbol,
      onBlur,
      onChange,
      onFocus,
      value,
      ...rest
    } = this.props;

    return (
      <ArcView
        row
        align="flex-end"
      >
        <TextField
          placeholder="Enter a value"
          onBlur={this.handleOnBlur}
          onChange={this.handleOnChange}
          onFocus={this.handleOnFocus}
          value={this.state.renderedValue}
          InputProps={{
            startAdornment: renderInputAdornment(),
          }}

          {...rest}
        />
      </ArcView>
    );
  }
}

export default ArcCurrencyField;
