import { makeApiActions } from 'arcade-frontend-ui';
import normalizers from './normalizers';

const archive = payload => ({
  method: 'PUT',
  url: `/manager/rewards/${payload.id}/archive`,
});

const restore = payload => ({
  method: 'PUT',
  url: `/manager/rewards/${payload.id}/restore`,
});

const archiveCompanyReward = payload => ({
  method: 'PUT',
  url: `/manager/company_rewards/${payload.id}/archive`,
  normalizer: data => ({ id: data.id.toString() }),
});

const restoreCompanyReward = payload => ({
  method: 'PUT',
  url: `/manager/company_rewards/${payload.id}/restore`,
});

const rewardsIndex = () => ({
  method: 'GET',
  url: '/manager/rewards.json',
  normalizer: normalizers.rewardsIndex,
  rootKey: 'rewards',
});

const rewardsCreate = payload => ({
  method: 'POST',
  url: '/manager/company_rewards',
  data: {
    company_reward: normalizers.toRewardsCreate(payload.reward),
  },
  normalizer: normalizers.fromRewardsCreate,
  rootKey: 'company_reward',
});

const rewardsEdit = payload => ({
  method: 'PUT',
  url: `/manager/company_rewards/${payload.reward.id}`,
  data: {
    company_reward: normalizers.toRewardsCreate(payload.reward),
  },
  // normalizer: normalizers.fromRewardsCreate,
  rootKey: 'company_reward',
});

const customRewardsIndex = () => ({
  method: 'GET',
  url: '/manager/company_rewards.json',
  normalizer: normalizers.customRewardsIndex,
  rootKey: 'company_rewards',
});

const endpoints = {
  archive,
  restore,
  archiveCompanyReward,
  rewardsIndex,
  customRewardsIndex,

  rewardsCreate,
  rewardsEdit,
  restoreCompanyReward,
};

const api = makeApiActions(endpoints);

export default api;
