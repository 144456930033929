import { camelCaseResource } from '../helpers/resources';
import getTeamColor from '../helpers/getTeamColor';
import getTeamLetter from '../helpers/getTeamLetter';

import toTitleCase from '../helpers/toTitleCase';

export const fromHashTags = props => ({
  id: props.hash_tag,
  display: props.hash_tag,
  useCount: props.count,
});

export const toLogin = props => ({
  user: {
    username: props.email,
    password: props.password,
  },
});

export const fromLogin = props => ({
  ...props,
  email: props.email,
  authentication_token: props.authentication_token,
  token: props.authentication_token,
  can_access_manager: props.can_access_manager,
  can_create_training: props.can_create_training,
  created_at: props.created_at,
  entity_id: props.entity_id,
  first_sign_in: props.first_sign_in,
  firstname: props.firstname,
  fullname: props.fullname || (`${this.firstname} ${this.lastname}`),
  id: props.id || props.userid,
  lastname: props.lastname,
  profile_image: props.profile_image,
  team_id: props.team_id,
  username: props.username,
  features: props.features || {},
});

export const fromMenu = camelCaseResource;
export const fromPermissions = props => ({
  ...props,
});

window.teamDisplayTools = { getTeamLetter, getTeamColor };

export const fromPeople = props => ({
  id: props.id.toString(),
  active: props.active,
  imageUrl: props.image,
  name: props.name,
});

export const fromTaggablePeople = props => ({
  id: props.id.toString(),
  name: props.name,
  display: props.name,
  teamName: props.team_name,
  imageUrl: props.image,
});

export const fromTaggableTeams = props => ({
  id: props.id.toString(),
  name: props.name,
  display: props.name,
  letter: getTeamLetter(props.name),
  color: getTeamColor(props.id),
  peopleIds: (props.users || []).map(id => id.toString()),
  peopleAmount: props.users ? props.users.length : (props.user_count || 0),
});

export const fromTaggableEntities = props => ({
  id: props.id.toString(),
  name: props.name,
  display: props.name,
  letter: getTeamLetter(props.name),
  color: getTeamColor(props.id),
  peopleIds: (props.users || []).map(id => id.toString()),
  peopleAmount: props.users ? props.users.length : (props.user_count || 0),
});

export const fromChecklist = props => ({
  objectives: props.objectives.map(obj => ({
    id: obj.id.toString(),
    value: obj.id.toString(),
    iconType: obj.icon_type,
    checked: obj.checked,
    label: obj.action,
    location: obj.location,
    params: obj.params,
  })),
  reward: props.reward,
});

export const fromPermissionsAsArray = props => Object.entries(props)
  .map(permissionGroup => ({
    [toTitleCase(permissionGroup[0])]: permissionGroup[1],
  }));

export const fromUserProfile = props => ({
  companyIdentifier: props.companyIdentifier,
  currentBalance: props.currentBalance,
  email: props.email,
  firstName: props.firstname,
  id: props.id ? props.id.toString() : '',
  lastName: props.lastname,
  locked: props.locked,
  profileImage: props.profileImage,
  teamName: props.teamName,
  recognitionStarCount: props.recognitionStarCount,
});
