/* eslint-disable */

import { react2angular } from 'react2angular';

import TokenUserBudgetListView from 'arcade-frontend-tokens/src/views/TokenUserBudgetListView';
import { createProvidedContainer } from './helpers';


const ProvidedTokenUserBudgetListContainer = createProvidedContainer(TokenUserBudgetListView);

export default react2angular(ProvidedTokenUserBudgetListContainer);
