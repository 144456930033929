import React from 'react';

import {
  ArcView,
  ArcText,
  ArcDataTableRow,
  ArcDataTableCell,
  ArcPlaceholder,
} from 'arcade-frontend-ui';

export default () =>
  [0, 1, 2].map(index => (
    <ArcDataTableRow key={index.toString()} shade={index % 2 === 0}>
      <ArcDataTableCell>
        <ArcView>
          <ArcText isLarge>
            <ArcPlaceholder>sheet name</ArcPlaceholder>
          </ArcText>
        </ArcView>
        <ArcView>
          <ArcText>
            <ArcPlaceholder>created at</ArcPlaceholder>
          </ArcText>
        </ArcView>
      </ArcDataTableCell>
      <ArcDataTableCell>
        <ArcText>
          <ArcPlaceholder>status</ArcPlaceholder>
        </ArcText>
      </ArcDataTableCell>
      <ArcDataTableCell>
        <ArcText>
          <ArcPlaceholder>sales count</ArcPlaceholder>
        </ArcText>
      </ArcDataTableCell>
      <ArcDataTableCell>
        <ArcText>
          <ArcPlaceholder>warnings and errors</ArcPlaceholder>
        </ArcText>
      </ArcDataTableCell>
      <ArcDataTableCell>
        <ArcPlaceholder>withdraw button</ArcPlaceholder>
      </ArcDataTableCell>
    </ArcDataTableRow>
  ));
