import React, { useState } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import makeStyles from '@material-ui/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Skeleton from '@material-ui/lab/Skeleton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PeopleIcon from '@material-ui/icons/People';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import { formatNumber } from 'arcade-frontend-core/src/helpers/numbers/numbers';
import getTeamLetter from 'arcade-frontend-core/src/helpers/getTeamLetter';
import getTeamColor from 'arcade-frontend-core/src/helpers/getTeamColor';
import usePermission from 'arcade-frontend-core/src/hooks/usePermission';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcEntityAvatar from 'arcade-frontend-ui/src/components/ArcEntityAvatar';
import ArcIconButton from 'arcade-frontend-ui/src/elements/ArcIconButton';
import ArcNoResultsMessage from 'arcade-frontend-ui/src/components/ArcNoResultsMessage';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';

const useStyles = makeStyles(theme => ({
  containerWrapper: {
    paddingTop: 0,
    paddingBottom: 0,
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',

    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  },
  pullRight: {
    marginLeft: 'auto',
  },

  noPaddingY: {
    paddingTop: 0,
    paddingBottom: 0,
  },

  listItem: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    minHeight: theme.spacing(6.5),

    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },

  teamIcon: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 40,
      marginLeft: theme.spacing(0.5),
    },
  },
  menuItem: {
    color: theme.palette.grey[700],
    '&:hover': {
      backgroundColor: '#F0FBFE',
      color: '#0580B8',
    },
  },
}));

function PeopleTeamList({ onEditTeamDetails, onDeleteTeam, teams }) {
  const classes = useStyles();
  const canManageTeams = usePermission(PERMISSIONS.MANAGE_TEAMS);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditTeamDetailsClick = () => {
    onEditTeamDetails(selectedTeam);
    setAnchorEl(null);
  };

  const handleMoreActionsClick = (event, team) => {
    setAnchorEl(event.currentTarget);
    setSelectedTeam(team);
  };

  const handleRemoveTeamClick = () => {
    setAnchorEl(null);
    onDeleteTeam(selectedTeam);
    setSelectedTeam(null);
  };

  return (
    <>
      <ArcBox
        display="flex"
        flexDirection="column"
        width="100%"
        mt={1}
        className="animated fadeIn"
      >
        <ArcBox className={classes.containerWrapper}>
          <List className={classes.noPaddingY}>
            {teams &&
              teams.map((team, index) => (
                <ListItem
                  key={team.id}
                  className={classes.listItem}
                  style={{
                    backgroundColor: index % 2 === 1 ? '#F8F8F8' : '#FFF',
                  }}
                >
                  <ListItemIcon className={classes.teamIcon}>
                    <ArcEntityAvatar
                      id={team.id}
                      type="team"
                      shape="circle"
                      letter={getTeamLetter(team.name)}
                      color={getTeamColor(team.id)}
                    />
                  </ListItemIcon>

                  <ArcBox
                    component={ArcText}
                    fontSize={14}
                    color="common.black"
                    lineHeight={1.5}
                    mr={[2, 3]}
                    width={240}
                  >
                    {team.name}
                  </ArcBox>

                  <ArcBox minWidth={100} mr={[0, 2]}>
                    <ArcBox
                      component={ArcText}
                      fontSize={14}
                      color="common.black"
                      lineHeight={1.5}
                    >
                      {`${formatNumber(team.userCount)} ${pluralize(
                        'Member',
                        team.userCount,
                      )}`}
                    </ArcBox>
                  </ArcBox>

                  {canManageTeams && (
                    <ArcBox alignItems="center" ml="auto">
                      <ArcIconButton
                        className={classes.pullRight}
                        onClick={e => handleMoreActionsClick(e, team)}
                      >
                        <MoreVertIcon />
                      </ArcIconButton>
                    </ArcBox>
                  )}
                </ListItem>
              ))}
          </List>
        </ArcBox>
      </ArcBox>

      {canManageTeams && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={handleEditTeamDetailsClick}
          >
            {'Edit Team Details'}
          </MenuItem>

          <MenuItem
            className={classes.menuItem}
            onClick={handleRemoveTeamClick}
          >
            {'Remove Team'}
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

PeopleTeamList.displayName = 'PeopleTeamList';

function PeopleTeamListPlaceholder({ teams, ...props }) {
  const classes = useStyles();
  return (
    <ArcBox
      className={classes.containerWrapper}
      display="flex"
      flexDirection="column"
      bgcolor="#fff"
      mt={2}
      {...props}
    >
      <ArcBox>
        {[0, 1, 2, 3, 4].map(index => (
          <ArcBox
            key={index}
            p={1}
            bgcolor={index % 2 === 1 ? '#F8F8F8' : '#FFF'}
          >
            <ArcBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              mt={1}
              mb={1}
            >
              <ArcBox mr={1}>
                <Skeleton variant="circle" height={30} width={30} />
              </ArcBox>
              <Skeleton variant="rect" height={30} width="95%" />
            </ArcBox>
          </ArcBox>
        ))}
      </ArcBox>
    </ArcBox>
  );
}

function PeopleTeamListEmptyState({
  teams,
  onRetryGetTeams,
  searchTerm,
  ...props
}) {
  return (
    <ArcBox display="flex" flexDirection="column" mt={2} {...props}>
      <ArcBox mt={5} display="flex" flexDirection="column" alignItems="center">
        <ArcNoResultsMessage
          iconComponent={PeopleIcon}
          shapeStyles={{ backgroundColor: '#efefef' }}
          header="No teams found"
          subheader={
            searchTerm
              ? `Couldn't find any results for '${searchTerm}'`
              : 'Could not retrieve teams right now'
          }
        />

        {!searchTerm && (
          <ArcBox maxWidth={150}>
            <ArcButton
              label="Retry"
              color="primary"
              onClick={onRetryGetTeams}
            />
          </ArcBox>
        )}
      </ArcBox>
    </ArcBox>
  );
}

PeopleTeamListPlaceholder.displayName = 'PeopleTeamListPlaceholder';

PeopleTeamListEmptyState.displayName = 'PeopleTeamListEmptyState';

PeopleTeamListPlaceholder.propTypes = PeopleTeamList.propTypes;

PeopleTeamListEmptyState.propTypes = {
  ...PeopleTeamList.propTypes,
  searchTerm: PropTypes.string,
  onRetryGetTeams: PropTypes.func,
};

PeopleTeamListPlaceholder.defaultProps = PeopleTeamList.defaultProps;

PeopleTeamListEmptyState.defaultProps = {
  ...PeopleTeamList.defaultProps,
  searchTerm: null,
  onRetryGetTeams: global.noop,
};

PeopleTeamList.Placeholder = PeopleTeamListPlaceholder;

PeopleTeamList.EmptyState = PeopleTeamListEmptyState;

PeopleTeamList.propTypes = {
  onEditTeamDetails: PropTypes.func,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      userCount: PropTypes.number,
      companyIdentifier: PropTypes.string,
      location: PropTypes.string,
    }),
  ),
};

PeopleTeamList.defaultProps = {
  onEditTeamDetails: global.noop,
  teams: [],
};

export default PeopleTeamList;
