import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../actions/metrics/types';

export const sorted = (metrics, prop, desc = false) => {
  const metricIds = Object.keys(metrics);

  return metricIds.sort((a, b) => {
    if (metrics[a][prop] < metrics[b][prop]) {
      return desc ? 1 : -1;
    } if (metrics[a][prop] > metrics[b][prop]) {
      return desc ? -1 : 1;
    }

    return 0;
  });
};

export const byKpiId = (metrics, kpiId) => {
  const metricIds = Object.keys(metrics);
  return metricIds.filter(metricId => metrics[metricId].kpiId === kpiId);
};

const handleMetricsLoadSuccess = (state, action) => {
  const { data } = action.payload;
  const newState = { ...state };

  data.forEach((metric) => {
    newState[metric.id] = metric;
  });

  return newState;
};

const initialState = {};

const handlers = {
  [types.METRICS_INDEX.SUCCESS]: handleMetricsLoadSuccess,
};

const metricsById = createReducer(initialState, handlers);

export default metricsById;

export const getMetricsById = state => state.metrics.metricsById;
export const getMetrics = state => Object.keys(getMetricsById(state));
export const getSortedMetrics = state => sorted(getMetricsById(state), 'rank');
