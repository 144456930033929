import { types } from '../actions';

const initialState = { integrationWidgetLoaded: false };

const integrationWidgetStatus = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_INTEGRATION_WIDGET_LOADED:
      return { ...state, integrationWidgetLoaded: action.payload };
    default:
      return state;
  }
};

export const getIntegrationWidgetStatus = state =>
  state.app.integrationWidgetStatus;

export default integrationWidgetStatus;
