// import ArcPropTypes from '../../helpers/arc/propTypes';
import { Platform } from 'react-primitives';
import ArcStyles, { createWithStyles } from '../../styles';
import ArcText from '../../primitives/ArcText';

const styles = {
  ArcHeading: theme => ({
    root: {
      fontSize: ArcStyles.ptSizeInPx(1.5),
      marginTop: ArcStyles.ptSizeInPx(0.67),
      marginBottom: ArcStyles.ptSizeInPx(0.67),
      fontWeight: '100',

      [theme.breakpoints.down('sm')]: {
        fontSize: ArcStyles.ptSizeInPx(1.25),
      },
    },
  }),
};


const Text = Platform.OS === 'web' ? 'h1' : ArcText;
const ArcHeading = createWithStyles(styles.ArcHeading)(Text);

export default ArcHeading;
