import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withStyles } from '@material-ui/styles';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import { ArcText } from 'arcade-frontend-ui';

const SpectatorTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: theme.font.getFontSize(1),
    boxShadow:
      '0px 4px 6px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.06)',
    overflowY: 'auto',
    maxHeight: 420,
    padding: 0,
  },
}))(Tooltip);

function GamesSpectatorViewBadge({ label, tooltipContent }) {
  const renderTooltipContent = () => (
    <ArcBox m={1} p={1}>
      <ArcText size="12px">{tooltipContent}</ArcText>
    </ArcBox>
  );

  return (
    <SpectatorTooltip title={renderTooltipContent()}>
      <ArcBox
        component={ArcText}
        fontSize={12}
        fontWeight={500}
        color="grey.main"
        display="flex"
        flexDirection="row"
        alignItems="center"
        cursor="pointer"
        zIndex={1000} // TODO: This is a hack that is needed to have the tooltip function as expected. It should be removed
      >
        <ArcBox
          component={InfoOutlinedIcon}
          height={16}
          width={16}
          ml={0.5}
          mr={0.5}
        />
        {label}
      </ArcBox>
    </SpectatorTooltip>
  );
}

GamesSpectatorViewBadge.displayName = 'GamesSpectatorViewBadge';

GamesSpectatorViewBadge.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipContent: PropTypes.string.isRequired,
};

GamesSpectatorViewBadge.defaultProps = {};

export default GamesSpectatorViewBadge;
