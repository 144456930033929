import { makeApiActionTypes } from 'arcade-frontend-ui';

export const REWARDS_SHOP_INDEX = makeApiActionTypes('REWARDS_SHOP_INDEX');
export const REWARDS_SHOP_PURCHASE = makeApiActionTypes(
  'REWARDS_SHOP_PURCHASE',
);
export const REWARDS_TRANSACTIONS_INDEX = makeApiActionTypes(
  'REWARDS_TRANSACTIONS_INDEX',
);
export const REWARDS_TRANSACTIONS_INDEX_BY_USER_ID = makeApiActionTypes(
  'REWARDS_TRANSACTIONS_INDEX_BY_USER_ID',
);
export const REWARDS_ACCOUNTS_INDEX = makeApiActionTypes(
  'REWARDS_ACCOUNTS_INDEX',
);
