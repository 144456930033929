import React from 'react';
import PropTypes from 'prop-types';
import Markdown, { MarkdownIt } from 'react-native-markdown-display';

import { ArcMarkdownTextProps } from './ArcMarkdownText.d';

const markdownit = MarkdownIt({
  typographer: true,
  linkify: true,
});

const ArcMarkdownText: React.FunctionComponent<ArcMarkdownTextProps> = ({
  children,
  onLinkPress,
  style,
  ...props
}) => {
  function handleLinkPress(url: string): boolean {
    if (onLinkPress) {
      return onLinkPress(url);
    }

    return false;
  }

  return (
    <Markdown
      markdownit={markdownit}
      onLinkPress={handleLinkPress}
      style={style}
      {...props}
    >
      {children}
    </Markdown>
  );
};

ArcMarkdownText.propTypes = {
  children: PropTypes.string,
  onLinkPress: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any),
};

const defaultProps: ArcMarkdownTextProps = {
  children: '',
  onLinkPress: undefined,
  style: undefined,
};

ArcMarkdownText.defaultProps = defaultProps;

export default ArcMarkdownText;
