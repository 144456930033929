import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../actions/uploadManager';

const initialState = 'DEFAULT';

const setDefault = () => 'DEFAULT';
const setRequest = () => 'REQUEST';
const setSuccess = () => 'SUCCESS';
const setFailure = () => 'FAILURE';

const handlers = {
  [types.UPLOAD_MANAGER_START_UPLOAD]: setRequest,
  [types.UPLOAD_MANAGER_CREATE_UPLOAD.SUCCESS]: setSuccess,
  [types.UPLOAD_MANAGER_CREATE_UPLOAD.FAILURE]: setFailure,
  [types.UPLOAD_MANAGER_CREATE_UPLOAD.RESET]: setDefault,
};

export default createReducer(initialState, handlers);

export const getUploadStatus = state => state.uploadManager.uploadStatus;
