import { connect } from 'react-redux';

import {
  ArcUserInput,
} from 'arcade-frontend-ui';
import * as FEATURE_FLAGS from '../types/feature-flags';
import { getCurrentUserFeatures } from '../reducers/user';

import { getAppPeople } from '../reducers/peopleById';
import { getTeams } from '../reducers/teams';
import { getEntities } from '../reducers/entities';
import { getAppHashTagsSorted } from '../reducers/hashTags';

function assignType(type, list) {
  return list.map(item => ({ ...item, type }));
}

function filterUsers(list, taggingRules = {}) {
  const { restrictToUsers, prioritiseUsers } = taggingRules;
  let newList = list;
  if (restrictToUsers && restrictToUsers.length > 0) {
    newList = newList.filter(({ id }) => restrictToUsers.includes(id));
  }
  if (prioritiseUsers) {
    newList.sort(({ id }) => (prioritiseUsers.includes(id) ? -1 : 1));
  }
  return newList;
}

function filterTeams(list, taggingRules = {}) {
  const { excludeTeams } = taggingRules;

  if (excludeTeams) {
    return [];
  }
  return list;
}

function filterEntities(list, taggingRules = {}) {
  const { excludeEntities } = taggingRules;

  if (excludeEntities) {
    return [];
  }
  return list;
}

function prependTypeToId(type, list) {
  return list.map(item => ({ ...item, id: `${type}${item.id}` }));
}

const getAppMentionState = (state, props) => {
  const {
    taggingRules = {},
  } = props;

  const hasFeatureEntityTagging = getCurrentUserFeatures(state, FEATURE_FLAGS.ENTITY_TAGGING);
  const entities = hasFeatureEntityTagging
    ? filterEntities(prependTypeToId('entity', assignType('entity', getEntities(state))), taggingRules)
    : [];

  const taggableData = [
    ...filterUsers(assignType('person', getAppPeople(state)), taggingRules),
    ...filterTeams(prependTypeToId('team', assignType('team', getTeams(state))), taggingRules),
    ...entities,
  ];

  return {
    ...props,
    taggableData,
    hashTags: getAppHashTagsSorted(state),
  };
};

export default connect(getAppMentionState, null, null, {
  forwardRef: true,
})(ArcUserInput);
