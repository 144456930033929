import { createSelector } from 'reselect';

import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/games';

// Helpers
const collectionById = (state, action) => {
  if (!action.payload) return state;

  const { data } = action.payload;
  if (!data) return state;

  const { length } = data;
  if (!length) return {};

  const newState = {};

  let idx = 0;
  for (idx; idx < length; idx += 1) {
    const { id } = data[idx];
    newState[id] = {
      ...state[id],
      ...data[idx],
    };
  }

  return newState;
};

const resourceById = (state, action) => {
  if (!action.payload) return state;

  const { data } = action.payload;
  if (!data) return state;

  const newData = {
    ...state[data.id],
    ...data,
    metric: state[data.id].metric,
  };

  return {
    ...state,
    [data.id]: newData,
  };
};

const sortCollectionByProp = (collection, prop, desc) =>
  Object.keys(collection).sort((a, b) => {
    if (collection[a][prop] === collection[b][prop]) return 0;
    if (desc) return 1;
    return -1;
  });

const filterCollectionByPropValue = (
  collection,
  prop,
  value,
  sort,
  sortDirection = 'ascending',
) => {
  const ids = Object.keys(collection).filter(
    id => collection[id][prop] === value,
  );

  if (sort && sortDirection === 'descending') {
    return ids.sort((a, b) => collection[b][sort] - collection[a][sort]);
  }
  if (sort && sortDirection === 'ascending') {
    return ids.sort((a, b) => collection[a][sort] - collection[b][sort]);
  }
  return ids;
};

// Reducer
const handleManageGamesIndexSuccess = collectionById;
const handleManageGamesListSuccess = collectionById;
const handleManageGamesShowSuccess = resourceById;

const initialState = {};

const handlers = {
  [types.MANAGE_GAMES_INDEX.SUCCESS]: handleManageGamesIndexSuccess,
  [types.MANAGE_GAMES_LIST.SUCCESS]: handleManageGamesListSuccess,
  [types.MANAGE_GAMES_SHOW.SUCCESS]: handleManageGamesShowSuccess,
};

const manageGamesById = createReducer(initialState, handlers);

// Selectors
export const getManageGamesById = state => state.manage.games.manageGamesById;
export const getManageGamesIsLoading = state => state.manage.games.isFetching;

export const getManageGames = state =>
  Object.keys(state.manage.games.manageGamesById);

const getManageGamesSortProp = prop => () => prop;
const getManageGamesSortDesc = desc => () => desc;

const getManageGamesFilterProp = prop => () => prop;
const getManageGamesFilterValue = value => () => value;
const getManageGamesSortValue = sort => () => sort;
const getManageGamesSortDirection = direction => () => direction;

// Memoized Selector Creators
const createSortSelector = (prop, desc) =>
  createSelector(
    [
      getManageGamesById,
      getManageGamesSortProp(prop),
      getManageGamesSortDesc(desc),
    ],
    sortCollectionByProp,
  );

const createFilterSelector = (prop, value, sort, direction) =>
  createSelector(
    [
      getManageGamesById,
      getManageGamesFilterProp(prop),
      getManageGamesFilterValue(value),
      getManageGamesSortValue(sort),
      getManageGamesSortDirection(direction),
    ],
    filterCollectionByPropValue,
  );

// Memoized Selectors
export const getManageGamesSortedNameState = createSortSelector('name');

export const getManageGamesFilterActiveState = createFilterSelector(
  'status',
  'active',
  'expiresAt',
);
export const getManageGamesFilterPendingState = createFilterSelector(
  'status',
  'pending',
  'startsAt',
);
export const getManageGamesFilterPrelaunchState = createFilterSelector(
  'status',
  'prelaunch',
  'startsAt',
);
export const getManageGamesFilterNeedsVerificationState = createFilterSelector(
  'status',
  'needs_verification',
  'completedAt',
  'descending',
);
export const getManageGamesFilterCompletedState = createFilterSelector(
  'status',
  'completed',
  'completedAt',
  'descending',
);

export default manageGamesById;
