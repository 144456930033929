import React from 'react';

import { gql, useSubscription } from 'arcade-frontend-core/src/helpers/apollo';
import { DIALOG_TYPES } from 'arcade-frontend-core/src/types/notifications.d';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';

import NotificationsCardStack from '../../components/NotificationsCardStack';

const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription {
    notificationAdded {
      id
      title
      content
      dialogType
    }
  }
`;

const CARD_ANIMATION_DELAY = 0;

function NotificationsTypeOneContainer() {
  const [alreadyEnqueued] = React.useState(() => new Set());
  const [notifications, setNotifications] = React.useState([]);

  const { data, loading } = useSubscription(NOTIFICATIONS_SUBSCRIPTION);

  function handleEmpty() {
    setNotifications([]);
  }

  function handleHide(id) {
    const nextNotifications = notifications.filter(item => item.id !== id);
    setNotifications(nextNotifications);
  }

  React.useEffect(() => {
    if (data && !loading) {
      const { id } = data.notificationAdded;

      const isNewNotification =
        !alreadyEnqueued.has(id) &&
        !notifications.filter(item => item.id === id).length;

      if (
        isNewNotification &&
        data.notificationAdded.dialogType === DIALOG_TYPES.TYPE_ONE
      ) {
        alreadyEnqueued.add(id);
        setTimeout(() => {
          setNotifications(
            [data.notificationAdded, ...notifications].filter(
              notification => notification.dialogType === DIALOG_TYPES.TYPE_ONE,
            ),
          );
        }, CARD_ANIMATION_DELAY);
      }
    }
  }, [data, loading]);

  return (
    <ArcViewBox position="relative">
      {!!notifications.length && (
        <NotificationsCardStack
          notifications={notifications}
          onDismiss={handleHide}
          onEmpty={handleEmpty}
          onPrimaryAction={handleHide}
          onSecondaryAction={handleHide}
        />
      )}
    </ArcViewBox>
  );
}

NotificationsTypeOneContainer.displayName = 'NotificationsTypeOneContainer';

export default NotificationsTypeOneContainer;
