import sagas from 'arcade-frontend-core/src/helpers/sagas';

import * as rewardsActions from 'arcade-frontend-rewards/src/actions/actions';
import { api } from '../../resources/manage/rewards';
import { types, actions, routes } from '../../actions/manage/rewards';

const takeLatestSaga = [
  {
    type: types.MANAGE_REWARDS_INDEX,
    call: api.rewardsIndex,
  },
  {
    type: types.MANAGE_REWARDS_CUSTOM_INDEX,
    call: api.customRewardsIndex,
  },
  {
    type: types.MANAGE_REWARDS_CREATE,
    call: api.rewardsCreate,
    onSuccess: [
      routes.routeManageRewards,
      actions.apiManageRewardsCustomIndexRequest,
      actions.apiManageRewardsIndexRequest,
      rewardsActions.apiRewardsShopIndexRequest,
    ],
  },
  {
    type: types.MANAGE_REWARDS_EDIT,
    call: api.rewardsEdit,
    onSuccess: [
      routes.routeManageRewards,
      actions.apiManageRewardsCustomIndexRequest,
      actions.apiManageRewardsIndexRequest,
      rewardsActions.apiRewardsShopIndexRequest,
    ],
  },
];

const takeEverySaga = [
  {
    type: types.MANAGE_REWARDS_ARCHIVE,
    call: api.archive,
    onSuccess: [
      rewardsActions.apiRewardsShopIndexRequest,
    ],
  },
  {
    type: types.MANAGE_REWARDS_RESTORE,
    call: api.restore,
    onSuccess: [
      rewardsActions.apiRewardsShopIndexRequest,
    ],
  },
  {
    type: types.MANAGE_REWARDS_CUSTOM_ARCHIVE,
    call: api.archiveCompanyReward,
    onSuccess: [
      rewardsActions.apiRewardsShopIndexRequest,
    ],
  },
  {
    type: types.MANAGE_REWARDS_CUSTOM_RESTORE,
    call: api.restoreCompanyReward,
    onSuccess: [
      rewardsActions.apiRewardsShopIndexRequest,
    ],
  },
];

const rewards = [...takeLatestSaga.map(sagas.makeTakeLatest), ...takeEverySaga.map(sagas.makeTakeEvery)];

export default rewards;
