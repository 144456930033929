import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { makeStyles } from '@material-ui/styles';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcSkyline from 'arcade-frontend-ui/src/components/ArcSkyline';
import ArcScoreboard from 'arcade-frontend-ui/src/components/ArcScoreboard';

import GamesGameProgressIndicator from '../GamesGameProgressIndicator';
import { RPA_EVERY_TIME } from '../../../../core/src/types/game-formats';

const styles = makeStyles({
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: '#8874CE',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    color: 'white',
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  skyline: {
    marginTop: 16,
    width: '100%',
  },
  content: {
    zIndex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  outlinePanel: {
    width: '70%',
    border: '3px dotted white',
    padding: 8,
    borderRadius: 16,
    backgroundColor: '#342467',
    color: '#FBBB38',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  labels: {
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  rule: {
    fontSize: 18,
  },
  verification: {
    color: '#A994EB',
  },
  totals: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    padding: 10,
    fontSize: 14,
  },
});

function GamesSceneEveryTime({
  unit,
  score,
  metric,
  goal,
  pendingCount,
  isDemo,
}) {
  const classes = styles();
  const timesWon = Math.floor(score / goal);

  return (
    <ArcBox className={classes.wrapper}>
      <ArcBox className={classes.content}>
        <ArcBox className={classes.outlinePanel} mt={isDemo ? 14 : 8}>
          <ArcBox>
            <ArcScoreboard numberWidth={28} score={timesWon} />
          </ArcBox>
          <ArcBox mt={1}>Rewards Won</ArcBox>
        </ArcBox>
        {!isDemo && (
          <ArcBox mt={2} className={classes.labels}>
            {goal > 1 && (
              <GamesGameProgressIndicator
                score={score}
                goal={goal}
                metric={metric}
                unit={unit}
                type={RPA_EVERY_TIME}
              />
            )}

            {pendingCount > 0 && (
              <ArcBox mt={3} className={classes.verification}>
                {pendingCount} pending {pluralize('verification', pendingCount)}
              </ArcBox>
            )}
          </ArcBox>
        )}
      </ArcBox>
      <ArcBox className={classes.background}>
        <ArcSkyline className={classes.skyline} />
      </ArcBox>
    </ArcBox>
  );
}

GamesSceneEveryTime.displayName = 'GamesSceneEveryTime';

GamesSceneEveryTime.propTypes = {
  metric: PropTypes.string,
  score: PropTypes.number,
  unit: PropTypes.string,
  goal: PropTypes.number,
  pendingCount: PropTypes.number,
  isDemo: PropTypes.bool,
};

GamesSceneEveryTime.defaultProps = {
  metric: '',
  score: 0,
  unit: '',
  goal: 0,
  pendingCount: 0,
  isDemo: false,
};

export default GamesSceneEveryTime;
