import { createReducer } from 'arcade-frontend-ui';

import * as routeTypes from 'arcade-frontend-core/src/types/routes';

const setId = (state, action) => {
  if (!action.payload) return state;

  const { id } = action.payload;

  return id.toString();
};

const initialState = null;
const reset = () => initialState;

const handlers = {
  [routeTypes.ROUTE_MANAGE_CHECKLISTS_SHOW]: setId,
  [routeTypes.ROUTE_MANAGE_CHECKLISTS_EDIT]: setId,
  [routeTypes.ROUTE_MANAGE_CHECKLISTS]: reset,
};

const currentChecklistId = createReducer(initialState, handlers);

export default currentChecklistId;

export const getCurrentChecklistId = state => state.manage.checklists.currentChecklistId;
