import React from 'react';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import ArcText from '../../primitives/ArcText';

import { createWithStyles } from '../../styles';

const styles = {
  ConfirmationTextLabel: theme => ({
    root: {
      paddingTop: 16,
      paddingBottom: 16,

      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,

      ...theme.typography.body,
    },
  }),

  NoneText: {
    root: {
      opacity: 0.4,
    },
  },
};

const styleContainer = {
  maxWidth: 480,
};

const ConfirmationTextLabel = createWithStyles(styles.ConfirmationTextLabel)(ArcView);
const NoneText = createWithStyles(styles.NoneText)(ArcText);

const getValueContent = (key, values, itemsByField) => {
  const items = itemsByField[key];
  const value = values[key];

  if (items && value) {
    // Pull up the readable label instead of the form control value
    const fieldItem = items.filter(item => item.value === value)[0];

    if (fieldItem) {
      return fieldItem.label;
    }

    return '';
  }

  const valueType = typeof value;
  switch (valueType) {
    case 'boolean':
      return value ? 'Yes' : 'No';
    case 'string':
    default:
      return value || <NoneText>N/A</NoneText>;
  }
};


class ArcFormSummary extends React.PureComponent {
  static propTypes = {
    itemsByField: ArcPropTypes.objectOf(
      ArcPropTypes.arrayOf(ArcPropTypes.shape({
        label: ArcPropTypes.string.isRequired,
      })),
    ),
    labelsByField: ArcPropTypes.objectOf(ArcPropTypes.string).isRequired,
    fields: ArcPropTypes.arrayOf(ArcPropTypes.string).isRequired,
    values: ArcPropTypes.objectOf(ArcPropTypes.any),
  };

  static defaultProps = {
    itemsByField: {},
    values: {},
  };

  get hasValues() {
    return !!this.props.values && !!Object.keys(this.props.values).length;
  }

  render() {
    if (!this.hasValues) {
      return (
        <ArcView>
          <ArcText>No details entered yet</ArcText>
        </ArcView>
      );
    }

    return (
      <ArcView style={styleContainer}>
        {this.props.fields.map(key => this.props.labelsByField[key] && (
          <ArcView key={key}>
            <ConfirmationTextLabel row justify="space-between">
              {this.props.labelsByField[key]}
              <ArcText>{getValueContent(key, this.props.values, this.props.itemsByField)}</ArcText>
            </ConfirmationTextLabel>
          </ArcView>
        ))}
      </ArcView>
    );
  }
}

export default ArcFormSummary;
