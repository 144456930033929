import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const VideoCamera = props => (
  <Icon {...props}>
    <Path
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.5 17.5L22.577 19.615C22.874 19.785 23.253 19.682 23.423 19.385C23.49 19.268 23.517 19.133 23.5 19V13C23.542 12.66 23.301 12.35 22.962 12.308C22.829 12.291 22.694 12.318 22.577 12.385L17.5 14.5"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.5 11H16.5C17.052 11 17.5 11.448 17.5 12V20C17.5 20.552 17.052 21 16.5 21H2.5C1.948 21 1.5 20.552 1.5 20V12C1.5 11.448 1.948 11 2.5 11Z"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13 4C14.381 4 15.5 5.119 15.5 6.5C15.5 7.881 14.381 9 13 9C11.619 9 10.5 7.881 10.5 6.5C10.5 5.119 11.619 4 13 4Z"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.5 3C6.1572 3 7.5 4.3428 7.5 6C7.5 7.6572 6.1572 9 4.5 9C2.8428 9 1.5 7.6572 1.5 6C1.5 4.3428 2.8428 3 4.5 3Z"
    />
  </Icon>
);

export default VideoCamera;
