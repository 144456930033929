import { types } from '../actions';

const initialState = {
  newSizzle: undefined,
};


const sizzle = (state = initialState, action) => {
  switch (action.type) {
    case types.APP_CHAT_NOTIFICATION: {
      const { type, content } = action.payload;
      return { newSizzle: { type, content } };
    }
    case types.APP_SIZZLE_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default sizzle;

export const getNewSizzleState = state => state.app.sizzle;
