import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import * as GAME_FORMATS from 'arcade-frontend-core/src/types/game-formats';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcBounty from 'arcade-frontend-ui/src/assets/ArcBounty';
import ArcChallenge from 'arcade-frontend-ui/src/assets/ArcChallenge';
import ArcTournament from 'arcade-frontend-ui/src/assets/ArcTournament';
import ArcOneTime from 'arcade-frontend-ui/src/assets/ArcOneTime';
import ArcEveryTime from 'arcade-frontend-ui/src/assets/ArcEveryTime';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import FlagOutlined from 'arcade-frontend-ui/src/assets/FlagOutlined';
import RunningManOutlined from 'arcade-frontend-ui/src/assets/RunningManOutlined';
import { capitalize } from 'arcade-frontend-ui/src/styles/createWithStyles/common';

const STRINGS = {
  EXPLAINERS: {
    [GAME_FORMATS.RACE]:
      "In races, the point is to reach the goal before everyone else! Hit your target and win prizes. Let's go!",
    [GAME_FORMATS.TOURNAMENT]:
      "Tournaments are a fun way to compete against other employees or teams. You'll win a prize if you have one of the top 3 highest scores when time expires.",
    [GAME_FORMATS.BOUNTY]:
      'Everyone can win with a bounty! Complete your target before time expires to win big!',
    [GAME_FORMATS.RPA_ONE_TIME]:
      'Hit the goal and win a prize! Your manager will need to verify your actions before they appear as a score',
    [GAME_FORMATS.RPA_EVERY_TIME]:
      'Every time you hit the goal you win! Your manager will need to verify your actions before they appear as a score',
  },
};

const icon = type => {
  const iconStyles = {
    height: 40,
    width: 60,
  };

  switch (type) {
    case GAME_FORMATS.BOUNTY:
      return <ArcBounty {...iconStyles} />;
    case GAME_FORMATS.RACE:
      return <ArcChallenge {...iconStyles} />;
    case GAME_FORMATS.TOURNAMENT:
      return <ArcTournament {...iconStyles} />;
    case GAME_FORMATS.RPA_ONE_TIME:
      return <ArcOneTime {...iconStyles} />;
    case GAME_FORMATS.RPA_EVERY_TIME:
      return <ArcEveryTime {...iconStyles} />;
    default:
      return null;
  }
};

const headline = (type, goal, metric, unit, rewards) => {
  const rewardsCount = (!!rewards && rewards.length) || '';
  const placementText =
    rewardsCount === 1
      ? 'Be the first person'
      : `Be one of the first ${rewardsCount} people`;

  switch (type) {
    case GAME_FORMATS.BOUNTY:
      return `Achieve ${goal} ${metric}`;
    case GAME_FORMATS.RACE:
      return `${placementText} to hit ${goal} ${metric}`;
    case GAME_FORMATS.TOURNAMENT:
      return `Be in the top 3 for ${metric}`;
    case GAME_FORMATS.RPA_ONE_TIME:
      return `Unlock with ${unit}${goal} ${metric}`;
    case GAME_FORMATS.RPA_EVERY_TIME:
      return `For every ${unit}${goal} ${metric}`;
    default:
      return null;
  }
};

const rules = (type, goal, metric, rewards) => {
  const rewardsCount = (!!rewards && rewards.length) || '';
  const placementText =
    rewardsCount === 1 ? 'Be the first' : `Be in the first ${rewardsCount}`;

  switch (type) {
    case GAME_FORMATS.BOUNTY:
      return `It's time to climb! Reach ${goal} ${metric} and win prizes.`;
    case GAME_FORMATS.RACE:
      return `Time is ticking! ${placementText} to reach ${goal} ${metric} and win prizes.`;
    case GAME_FORMATS.TOURNAMENT:
      return `The heat is on! Score one of the top three highest scores for ${metric} and win prizes.`;
    case GAME_FORMATS.RPA_ONE_TIME:
      return `Rise to the challenge! Reach ${goal} ${metric} and win prizes.`;
    case GAME_FORMATS.RPA_EVERY_TIME:
      return `Stack up the wins! Earn Prizes for every ${goal} ${metric}.`;
    default:
      return null;
  }
};

const Section = ({ iconComponent, title, body }) => {
  const Icon = iconComponent;

  const iconStyles = {
    height: 24,
    width: 24,
  };

  return (
    <ArcBox display="flex" flexDirection="row" mt={1} py={1} color="#616264">
      <ArcBox display="flex" minWidth={24} mr={1} mt={0.5}>
        <Icon stroke="#616264" {...iconStyles} />
      </ArcBox>

      <ArcBox display="flex" flexDirection="column">
        <ArcBox
          component={ArcText}
          fontWeight={600}
          fontSize={18}
          lineHeight="150%"
          ml={1}
        >
          {title}
        </ArcBox>
        <ArcBox
          component={ArcText}
          fontWeight="normal"
          fontSize={16}
          lineHeight="150%"
          ml={1}
        >
          {body}
        </ArcBox>
      </ArcBox>
    </ArcBox>
  );
};

function GamesRules({ type, goal, metric, unit, rewards }) {
  const isTiny = useMediaQuery('(max-width:330px)');

  return (
    <ArcBox display="flex" flexDirection="column">
      <ArcBox
        display="flex"
        flexDirection="column"
        bgcolor="#F2F2F2"
        p={4}
        pt={2}
        height={isTiny ? 'auto' : '100%'}
      >
        {icon(type)}
        <ArcBox
          component={ArcText}
          fontWeight={600}
          fontSize={24}
          lineHeight="150%"
          py={2}
          borderBottom="2px solid #EBEBEB"
        >
          {headline(type, goal, metric, unit, rewards)}
        </ArcBox>

        <Section
          iconComponent={FlagOutlined}
          title="Game Rules"
          body={rules(type, goal, metric, rewards)}
        />
        <Section
          iconComponent={RunningManOutlined}
          title={`What is a ${capitalize(
            GAME_FORMATS.DISPLAYABLE_TITLE_BY_TYPE[type],
          )}?`}
          body={STRINGS.EXPLAINERS[type]}
        />
      </ArcBox>
    </ArcBox>
  );
}

GamesRules.displayName = 'GamesRules';

GamesRules.propTypes = {
  type: PropTypes.oneOf(GAME_FORMATS.GAME_TYPES),
  metric: PropTypes.string,
  goal: PropTypes.number,
  unit: PropTypes.string,
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.string,
      receivingRank: PropTypes.number,
    }),
  ),
};

GamesRules.defaultProps = {
  type: GAME_FORMATS.RACE,
  metric: '',
  goal: 0,
  unit: '',
  rewards: [],
};

Section.propTypes = {
  iconComponent: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
};

Section.defaultProps = {
  iconComponent: null,
  title: '',
  body: '',
};

export default GamesRules;
