import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const XCircleOutline = props => (
  <Icon {...props}>
    <Path
      fill="currentColor"
      d="M17.666 6.333C17.4317 6.09826 17.1137 5.96635 16.782 5.96635C16.4503 5.96635 16.1323 6.09826 15.898 6.333L12.176 10.055C12.0784 10.1523 11.9206 10.1523 11.823 10.055L8.1 6.333C7.78763 6.00166 7.31979 5.8664 6.87881 5.97994C6.43783 6.09347 6.09347 6.43783 5.97994 6.87881C5.8664 7.31979 6.00166 7.78763 6.333 8.1L10.055 11.822C10.102 11.8689 10.1284 11.9326 10.1284 11.999C10.1284 12.0654 10.102 12.1291 10.055 12.176L6.333 15.9C5.84524 16.3884 5.84524 17.1796 6.333 17.668C6.82537 18.1461 7.60862 18.1461 8.101 17.668L11.823 13.946C11.8697 13.899 11.9332 13.8726 11.9995 13.8726C12.0657 13.8726 12.1293 13.899 12.176 13.946L15.9 17.666C16.393 18.1425 17.175 18.1425 17.668 17.666C18.1558 17.1776 18.1558 16.3864 17.668 15.898L13.946 12.176C13.899 12.1291 13.8725 12.0654 13.8725 11.999C13.8725 11.9326 13.899 11.8689 13.946 11.822L17.666 8.1C17.9004 7.86574 18.0321 7.54791 18.0321 7.2165C18.0321 6.88508 17.9004 6.56726 17.666 6.333Z"
    />
    <Path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6244 0.00716485 23.9928 5.37555 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM2 12C2 17.5228 6.47715 22 12 22C17.5203 21.9939 21.9939 17.5203 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z"
    />
  </Icon>
);

export default XCircleOutline;
