import React from 'react';
import PropTypes from 'prop-types';
import IconClear from '@material-ui/icons/Clear';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import Portrait from 'arcade-frontend-games/src/components/ManageGamesGameList/Portrait';
import {
  ArcView,
  ArcText,
  ArcScroll,
  ArcIconButton,
  ArcResponsiveDialog,
  ArcDataTableRow,
  ArcDataTableCell,
  createWithStyles,
} from 'arcade-frontend-ui';

const paperProps = { style: { height: '100%' } };

const styles = {
  DialogHeader: theme => ({
    root: {
      backgroundColor: theme.palette.grey.tint,
    },
  }),
};

const DialogHeader = createWithStyles(styles.DialogHeader)(ArcView);

function RecipientDialog({ open, onClose, onQuicklinkUser, recipients }) {
  return (
    <ArcResponsiveDialog open={open} onClose={onClose} PaperProps={paperProps}>
      <DialogHeader row align="center">
        <ArcView row flexGrow={1} paddingLeft={16}>
          <ArcText isLarger>Recipients</ArcText>
        </ArcView>
        <ArcIconButton onClick={onClose}>
          <IconClear />
        </ArcIconButton>
      </DialogHeader>
      <ArcScroll>
        <Table style={{ tableLayout: 'fixed' }}>
          <TableBody>
            {recipients.map((recipient, i) => (
              <ArcDataTableRow
                key={recipient.id}
                shade={i % 2 === 1}
                onClick={() => onQuicklinkUser(recipient.id, 'person')}
                style={{ cursor: 'pointer' }}
              >
                <ArcDataTableCell style={{ width: 50 }}>
                  <Portrait person={recipient} />
                </ArcDataTableCell>
                <TableCell>{recipient.name}</TableCell>
              </ArcDataTableRow>
            ))}
          </TableBody>
        </Table>
      </ArcScroll>
    </ArcResponsiveDialog>
  );
}

RecipientDialog.propTypes = {
  open: PropTypes.bool,
  onQuicklinkUser: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
};

RecipientDialog.defaultProps = {
  open: false,
  onQuicklinkUser: global.noop,
  recipients: [],
};

export default RecipientDialog;
