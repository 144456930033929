import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ArcPropTypes, ArcView, loaders } from 'arcade-frontend-ui';

import { actions } from 'arcade-frontend-features/src/actions/manage/games';

import GamesCreateForm from 'arcade-frontend-features/src/forms/games/GamesCreateForm';
import { getManageGamesById } from 'arcade-frontend-features/src/reducers/manage/games/manageGamesById';
import { getManageGamesIsFetching } from 'arcade-frontend-features/src/reducers/manage/games/isFetching';
import { getManageGamesIsCreating } from 'arcade-frontend-features/src/reducers/manage/games/isCreating';

class GamesCloneFormContainer extends React.PureComponent {
  static displayName = 'GamesCloneFormContainer';

  static propTypes = {
    apiManageGamesShowRequest: ArcPropTypes.func.isRequired,
    apiManageGamesValidMetricsIndexRequest: ArcPropTypes.func.isRequired,
    gameId: ArcPropTypes.string,
    isCreating: ArcPropTypes.bool.isRequired,
    isFetching: ArcPropTypes.bool.isRequired,
    manageGamesById: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
    onCancel: ArcPropTypes.func.isRequired,
  };

  static defaultProps = {
    gameId: null,
  };

  constructor(props) {
    super(props);
    props.apiManageGamesValidMetricsIndexRequest();
    props.apiManageGamesShowRequest(props.gameId);
  }

  render() {
    const currentGame = this.props.manageGamesById[this.props.gameId];

    const initialValues = {
      ...GamesCreateForm.getInitialValues(currentGame),
      startDateTime: '',
      endDateTime: '',
      autoVerify: false,
      autoVerifyAt: '',
    };

    if (this.props.isFetching) {
      return (
        <ArcView padding="4" flexGrow="1">
          <ArcView flexGrow="1" align="center" justify="center" color="paper">
            <loaders.LoaderBars />
          </ArcView>
        </ArcView>
      );
    }

    return (
      <GamesCreateForm
        onCancel={this.props.onCancel}
        isCreating={this.props.isCreating}
        initialValues={initialValues}
        isCloning
        inDialog
      />
    );
  }
}

const mapStateToProps = state => ({
  isCreating: getManageGamesIsCreating(state),
  isFetching: getManageGamesIsFetching(state),
  manageGamesById: getManageGamesById(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      apiManageGamesShowRequest: actions.apiManageGamesShowRequest,
      apiManageGamesValidMetricsIndexRequest:
        actions.apiManageGamesValidMetricsIndexRequest,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GamesCloneFormContainer);
