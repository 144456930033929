import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import PropTypes from 'prop-types';

import { ArcDataTableRow, ArcDataTableCell } from 'arcade-frontend-ui';
import Portrait from './Portrait';

function sortByName(a, b) {
  const nameA = a?.name;
  const nameB = b?.name;

  if (nameA > nameB) {
    return 1;
  }

  if (nameA < nameB) {
    return -1;
  }

  return 0;
}

const ParticipantList = ({ participants }) => (
  <Table style={{ tableLayout: 'fixed' }}>
    <TableBody>
      {participants.sort(sortByName).map((person, i) => (
        <ArcDataTableRow key={person.id} shade={i % 2 === 1} onClick={() => window.quicklink(person.assigneeId, 'person')}>
          <ArcDataTableCell style={{ width: 50 }}><Portrait person={person} /></ArcDataTableCell>
          <TableCell>{person.name}</TableCell>
        </ArcDataTableRow>
      ))}
    </TableBody>
  </Table>
);

ParticipantList.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.shape({ assigneeId: PropTypes.string })).isRequired,
};

export default ParticipantList;
