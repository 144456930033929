import {
  makeParams,
  makeDateFilterParams,
} from 'arcade-frontend-core/src/actions/helpers';
import * as types from './types';

export const apiManageGamesIndexRequest = params => ({
  type: types.MANAGE_GAMES_INDEX.REQUEST,
  payload: { ...makeDateFilterParams(params) },
});

export const apiManageGamesListRequest = params => ({
  type: types.MANAGE_GAMES_LIST.REQUEST,
  payload: {
    ...makeDateFilterParams(params),
    ...makeParams(params, ['list_type', 'listType']),
  },
});

export const apiManageGamesShowRequest = id => ({
  type: types.MANAGE_GAMES_SHOW.REQUEST,
  payload: { id },
  meta: {
    analytics: {
      payload: {
        event: 'Viewed an individual game',
      },
    },
  },
});

export const apiManageGamesEligiblePeopleIndexRequest = kpiGroupId => ({
  type: types.MANAGE_GAMES_ELIGIBLE_PEOPLE_INDEX.REQUEST,
  payload: { kpiGroupId },
});

export const apiManageGamesEventsIndexRequest = id => ({
  type: types.MANAGE_GAMES_EVENTS_INDEX.REQUEST,
  payload: {
    id,
  },
});

export const apiManageGamesRankingsIndexRequest = id => ({
  type: types.MANAGE_GAMES_RANKINGS_INDEX.REQUEST,
  payload: {
    id,
  },
});

export const apiManageGamesResultsIndexRequest = id => ({
  type: types.MANAGE_GAMES_RESULTS_INDEX.REQUEST,
  payload: {
    id,
  },
});

export const apiManageGamesCreateRequest = data => ({
  type: types.MANAGE_GAMES_CREATE.REQUEST,
  payload: data,
  meta: {
    analytics: {
      payload: {
        event: 'Created a game',
      },
    },
  },
});

export const apiManageGamesUpdateRequest = (id, data) => ({
  type: types.MANAGE_GAMES_UPDATE.REQUEST,
  payload: {
    id,
    data,
  },
  meta: {
    analytics: {
      payload: {
        event: 'Updated a game',
      },
    },
  },
});

export const apiManageGamesEditRequest = id => ({
  type: types.MANAGE_GAMES_EDIT.REQUEST,
  payload: {
    id,
  },
  meta: {
    analytics: {
      payload: {
        event: 'Started editing a game',
      },
    },
  },
});

export const apiManageGamesTemplateCreateRequest = template => ({
  type: types.MANAGE_GAMES_TEMPLATE_CREATE.REQUEST,
  payload: {
    template,
  },
  meta: {
    analytics: {
      payload: {
        event: 'Created a game from a template',
      },
    },
  },
});

export const apiManageGamesTemplatesIndexRequest = () => ({
  type: types.MANAGE_GAMES_TEMPLATES_INDEX.REQUEST,
  payload: {},
});

export const apiManageGamesValidMetricsIndexRequest = () => ({
  type: types.MANAGE_GAMES_VALID_METRICS_INDEX.REQUEST,
  payload: {},
});
export const apiManageGamesVerifyRequest = (id, scores) => ({
  type: types.MANAGE_GAMES_VERIFY.REQUEST,
  payload: {
    id,
    scores,
  },
  meta: {
    analytics: {
      payload: {
        event: 'Verified game results',
      },
    },
  },
});

export const apiManageGamesRejectRequest = id => ({
  type: types.MANAGE_GAMES_REJECT.REQUEST,
  payload: {
    id,
  },
  meta: {
    analytics: {
      payload: {
        event: 'Rejected Game results',
      },
    },
  },
});

export const apiManageGamesCancelRequest = id => ({
  type: types.MANAGE_GAMES_CANCEL.REQUEST,
  payload: {
    id,
  },
  meta: {
    analytics: {
      payload: {
        event: 'Cancelled a game',
      },
    },
  },
});

export const setManageGamesTypeFilter = type => ({
  type: types.MANAGE_GAMES_SET_TYPE_FILTER,
  payload: { type },
});

export const setManageGamesDateFilter = ({ from, to }) => ({
  type: types.MANAGE_GAMES_SET_DATE_FILTER,
  payload: { from, to },
});

export const setManageGamesPageFilter = page => ({
  type: types.MANAGE_GAMES_SET_PAGE_FILTER,
  payload: { page },
});

export const apiGetEscrowableFundsRequest = () => ({
  type: types.MANAGE_GAMES_GET_ESCROWABLE_FUNDS.REQUEST,
  payload: {},
});

export const setManageGamesVerifiedId = id => ({
  type: types.MANAGE_GAMES_SET_VERIFIED_GAME,
  payload: { id },
});
