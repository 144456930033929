const DELAY = 1300;

const loadingButtonMiddleware = store => next => (action) => {
  ['_SUCCESS', '_FAILURE'].forEach((type) => {
    if (action.type.indexOf(type) > -1) {
      setTimeout(() => {
        const resetAction = {
          type: action.type.replace(type, '_RESET'),
          payload: {},
        };

        store.dispatch(resetAction);
      }, DELAY);
    }
  });

  return next(action);
};


export default loadingButtonMiddleware;
