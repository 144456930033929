import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const PresentOutline = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M22 10.158a.913.913 0 01-.913.913H1.913A.913.913 0 011 10.158V8.33a.913.913 0 01.913-.913h19.174A.913.913 0 0122 8.33v1.827zm-1.826 10.956a.913.913 0 01-.913.913H3.739a.913.913 0 01-.913-.913V11.071h17.348v10.043zM8.304 7.418v14.61-14.61zm6.392 14.61V7.417v14.61zm1.76-17.951c-.698.698-4.043 1.515-4.043 1.515s.822-3.344 1.516-4.042a1.787 1.787 0 112.526 2.527v0zm-9.911 0c.697.698 4.042 1.515 4.042 1.515S9.765 2.248 9.071 1.55a1.787 1.787 0 00-2.526 2.527v0z"
      clipRule="evenodd"
    />
  </Icon>
);

export default PresentOutline;
