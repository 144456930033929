import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import {
  createWithStyles,
  ArcButton,
  ArcIconButton,
  ArcForm,
  ArcImage,
  ArcMobileStepper,
  ArcView,
  ArcText,
  ArcInternalLink,
} from 'arcade-frontend-ui';

import chest from '../ArcTourStepper/assets/chest.svg';
import ArcProfileFormStepContact from './ArcProfileFormStepContact';
import ArcProfileFormStepPicture from './ArcProfileFormStepPicture';
import ArcProfileFormStepAbout from './ArcProfileFormStepAbout';
import ArcProfileFormStepWork from './ArcProfileFormStepWork';
import ArcProfileFormStepPassword from './ArcProfileFormStepPassword';

const STEPS = [
  {
    key: 'contact',
    step: 0,
    PartialForm: ArcProfileFormStepContact,
  },
  {
    key: 'about',
    step: 1,
    PartialForm: ArcProfileFormStepAbout,
  },
  {
    key: 'work',
    step: 2,
    PartialForm: ArcProfileFormStepWork,
  },
  {
    key: 'picture',
    step: 3,
    PartialForm: ArcProfileFormStepPicture,
  },
  {
    key: 'password',
    step: 4,
    PartialForm: ArcProfileFormStepPassword,
  },
];

const prize = (
  <ArcView
    position="absolute"
    fillToParent
    className="animated fadeOutLeft"
    align="center"
    padding="24"
    marginTop="32"
  >
    <ArcView marginTop="32" marginBottom="32">
      <Typography variant="h4">
        <ArcText weight="500">{'Earn an Epic Chest'}</ArcText>
      </Typography>
    </ArcView>
    <ArcView marginBottom="32">
      <ArcImage src={chest} alt={'chest'} width="180px" height="140px" />
    </ArcView>
    <ArcView marginBottom="32">
      <Typography variant="h6">{'Fill out your profile to unlock'}</Typography>
    </ArcView>
  </ArcView>
);

const buttonStyle = {
  minWidth: 90,
};

const closeButtonStyle = {
  color: '#fff',
};

const chestStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
};

const styles = {
  CloseFormContainer: () => ({
    root: {
      position: 'absolute',
      top: 32,
      right: 8,
    },
  }),
  SwipeableContainer: () => ({
    root: {
      height: '100%',
      flexGrow: 10,
      flexShrink: 10,

      '& .react-swipeable-view-container': {
        height: '100%',
      },

      '& > div': {
        height: '100%',
        overflow: 'hidden',
        flexGrow: 10,
      },
    },
  }),
};

const CloseFormContainer = createWithStyles(styles.CloseFormContainer)(ArcView);
const SwipeableContainer = createWithStyles(styles.SwipeableContainer)(ArcView);

const INITIAL_VALUES = {
  firstname: '',
  lastname: '',
  contact_number: '',
  avatar: '',
  remote_image_url: '',
  birthday: '',
  anniversary: '',
  password: '',
  password_confirmation: '',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  firstname: Yup.string().required('Required'),
  lastname: Yup.string().required('Required'),
  birthday: Yup.string().required('Required'),
  avatar: Yup.string().required('Required'),
  password: Yup.string().required('Required').min(6).max(256),
  password_confirmation: Yup.string().required('Required').min(6).max(256),
});

const STEPS_BY_FIELD = {
  firstname: 0,
  lastname: 0,
  contact_number: 0,
  birthday: 1,
  anniversary: 2,
  avatar: 3,
  password: 4,
  password_confirmation: 4,
};

const formStyle = {
  height: 'calc(100% - 58px)',
};

class ArcProfileForm extends React.PureComponent {
  static propTypes = {
    activeStep: PropTypes.number,
    initialValues: PropTypes.objectOf(PropTypes.any),
    isFinished: PropTypes.bool,
    onFinish: PropTypes.func,
    onSubmit: PropTypes.func,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        step: PropTypes.number.isRequired,
        PartialForm: PropTypes.func.isRequired,
      }),
    ),
  };

  static defaultProps = {
    activeStep: 0,
    initialValues: INITIAL_VALUES,
    isFinished: false,
    onFinish: global.noop,
    onSubmit: global.noop,
    steps: STEPS,
  };

  state = {
    activeStep: this.props.activeStep,
    isFinished: this.props.isFinished,
  };

  get isLastStep() {
    return this.state.activeStep === this.maxSteps - 1;
  }

  get maxSteps() {
    return this.props.steps.length;
  }

  setActiveStep = activeStep => this.setState({ activeStep });

  setIsFinished = isFinished => this.setState({ isFinished });

  scrollTopByStep = {};

  incrementActiveStep = (steps = 1) => {
    if (window.arcPostMessage) {
      window.arcPostMessage('native.keyboard.dismiss');
    }

    this.setState(prevState => ({
      activeStep: prevState.activeStep + steps,
    }));
  };

  handleBack = () => this.incrementActiveStep(-1);

  handleNext = () => this.incrementActiveStep(1);

  handleStepChange = activeStep => {
    this.setActiveStep(activeStep);
  };

  handleGoToRewards = () => {
    if (window.ngState && window.ngState.get('arcade.prize.chests')) {
      window.ngState.go('arcade.prize.chests');
    }

    this.props.onFinish();
  };

  renderStepper = formikProps => {
    const { activeStep } = this.state;

    const buttonIcon = this.isLastStep ? <Check /> : <KeyboardArrowRight />;
    const buttonText = this.isLastStep ? 'Finish' : 'Next';

    const handleSubmit = evt => {
      evt.preventDefault();

      if (
        formikProps.values.password !== formikProps.values.password_confirmation
      ) {
        formikProps.setFieldError('password', 'does not match');
        return;
      }

      formikProps.validateForm().then(errors => {
        formikProps.handleSubmit();

        const firstFieldName = Object.keys(errors)[0];

        if (firstFieldName) {
          this.setActiveStep(STEPS_BY_FIELD[firstFieldName]);

          const field = document.getElementById(firstFieldName);

          if (field) {
            field.focus();
          }
        } else {
          this.setIsFinished(true);
        }
      });
    };

    return (
      <ArcMobileStepper
        activeStep={activeStep}
        color="white"
        dotsBackgroundColor="blue"
        dotsClassName="animated fadeIn"
        steps={this.maxSteps}
        position="static"
        backButton={
          <ArcButton
            disabled={activeStep === 0}
            label="Back"
            onClick={this.handleBack}
            size="small"
            style={buttonStyle}
          >
            <KeyboardArrowLeft />
            <ArcView marginLeft="8">{'Back'}</ArcView>
          </ArcButton>
        }
        nextButton={
          <ArcButton
            type={this.isLastStep ? 'submit' : undefined}
            label={buttonText}
            color="blue"
            onClick={this.isLastStep ? handleSubmit : this.handleNext}
            size="small"
            variant={this.isLastStep ? 'contained' : 'outlined'}
            style={buttonStyle}
          >
            <ArcView marginRight="8">{buttonText}</ArcView>
            {buttonIcon}
          </ArcButton>
        }
        variant="squared"
      />
    );
  };

  renderFinishScreen() {
    return (
      <ArcView
        align="center"
        justify="center"
        position="absolute"
        fillToParent
        className="animated fadeIn"
        color="paper"
        padding="24"
        textAlign="center"
      >
        <ArcView marginBottom="16" marginTop="32">
          <Typography variant="h4">
            <ArcText weight="500">
              {'Go to rewards to get your first prize!'}
            </ArcText>
          </Typography>
        </ArcView>

        <ArcInternalLink
          href="/arcade/prize/chests"
          style={chestStyle}
          title="Go to Rewards"
          onClick={this.handleGoToRewards}
        >
          <ArcImage src={chest} alt={'chest'} width="180px" height="140px" />
        </ArcInternalLink>

        <ArcView spacer />

        <ArcInternalLink
          href="/arcade/prize/chests"
          title="Go to Rewards"
          onClick={this.props.onFinish}
        >
          <ArcButton
            color="purple"
            variant="contained"
            size="large"
            label="Go to Rewards"
          />
        </ArcInternalLink>
      </ArcView>
    );
  }

  renderForm = formikProps => (
    <ArcView fullHeight flexGrow="10" flexShrink="10">
      <ArcView
        flexGrow="10"
        flexShrink="10"
        className="animated fadeInRight"
        style={formStyle}
      >
        <SwipeableContainer>
          <SwipeableViews
            enableMouseEvents={false}
            index={this.state.activeStep}
            onChangeIndex={this.handleStepChange}
          >
            {this.props.steps.map(step => (
              <step.PartialForm key={step.key} {...formikProps} />
            ))}
          </SwipeableViews>
        </SwipeableContainer>
      </ArcView>

      {this.renderStepper(formikProps)}
    </ArcView>
  );

  render() {
    return (
      <ArcView
        position="relative"
        overflow="hidden"
        align="center"
        flexGrow="10"
        flexShrink="10"
        fullWidth
      >
        {prize}

        <ArcView position="absolute" fillToParent>
          <ArcForm
            inDialog
            initialValues={{ ...INITIAL_VALUES, ...this.props.initialValues }}
            onSubmit={this.props.onSubmit}
            validationSchema={VALIDATION_SCHEMA}
          >
            {this.renderForm}
          </ArcForm>
        </ArcView>

        {this.state.isFinished && this.renderFinishScreen()}
        {!this.state.isFinished && (
          <CloseFormContainer>
            <ArcIconButton
              style={closeButtonStyle}
              onClick={this.props.onFinish}
            >
              <Close />
            </ArcIconButton>
          </CloseFormContainer>
        )}
      </ArcView>
    );
  }
}

export default ArcProfileForm;
