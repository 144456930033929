import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { ArcView } from 'arcade-frontend-ui';

const containerStyle = {
  minHeight: 42,
};

const ArcAudienceListPlaceholder = ({ title, ...props }) => (
  <ArcView
    display="block"
    align="center"
    paddingLeft="8"
    paddingRight="8"
    marginTop="8"
    marginBottom="8"
    style={containerStyle}
    {...props}
  >
    <Typography variant="caption">{title}</Typography>
  </ArcView>
);

ArcAudienceListPlaceholder.displayName = 'ArcAudienceListPlaceholder';

ArcAudienceListPlaceholder.propTypes = {
  title: PropTypes.node,
};

ArcAudienceListPlaceholder.defaultProps = {
  title: '',
};

export default ArcAudienceListPlaceholder;
