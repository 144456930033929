import React from 'react';
import ArcView from '../../primitives/ArcView/ArcView';
import ArcPropTypes from '../../helpers/arc/propTypes';
import { distribute } from '../../helpers/utils/distribution';
import PlayerPortrait from '../PlayerPortrait';

const offset = p => ({
  position: 'absolute',
  left: p.x,
  top: p.y });

const UserProgress = ({ index, player, slots }) => {
  const slot = slots[index % (slots.length)];
  return (
    <ArcView style={offset(slot)}>
      <PlayerPortrait
        title={player.catchUp || 'You\'re doing great!'}
        isSelf={player.isSelf}
        image={player.image}
      />
    </ArcView>
  );
};

const playerType = ArcPropTypes.shape({
  image: ArcPropTypes.string.isRequired,
  catchUp: ArcPropTypes.string,
  isSelf: ArcPropTypes.bool,
});

UserProgress.propTypes = {
  slots: ArcPropTypes.arrayOf(ArcPropTypes.shape({})).isRequired,
  index: ArcPropTypes.number.isRequired,
  player: playerType.isRequired,
};

export const displaysScoresType = {
  scores: ArcPropTypes.arrayOf(playerType),
  goal: ArcPropTypes.number,
};

export const PositionedPlayers = ({ slots, scores, goal }) => {
  const target = goal;
  const reversed = [...scores].reverse();
  const distribution = distribute(slots.length, reversed, target);

  return (
    <React.Fragment>
      {distribution.map((player, index) => player && (
        <UserProgress key={index.toString()} slots={slots} index={index} player={player} />
      ))}
    </React.Fragment>
  );
};

PositionedPlayers.propTypes = {
  slots: ArcPropTypes.arrayOf(ArcPropTypes.shape({})).isRequired,
  ...displaysScoresType,
};
