import React from 'react';
import PropTypes from 'prop-types';

import MoreVert from '@material-ui/icons/MoreVert';

import ArcIconButton from '../ArcIconButton';

import ArcMenu from './ArcMenu';
import ArcMenuItem from './ArcMenuItem';


// TODO Hooks!
class ArcEasyMenu extends React.Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      children: PropTypes.node.isRequired,
      onClick: PropTypes.func.isRequired,
    })),
  };

  static defaultProps = {
    options: [],
  };

  state = {
    anchorEl: null,
  };

  setMenuState = (anchorEl) => {
    this.setState({ anchorEl });
  };

  handleClick = (event) => {
    this.setMenuState(event.currentTarget);
  };

  handleClose = () => {
    this.setMenuState(null);
  };

  renderItem = (item, idx) => (
    <ArcMenuItem
      key={idx.toString()}
      {...item}
      onClick={() => {
        this.handleClose();
        if (item.onClick) {
          item.onClick();
        }
      }}
    >
      {item.children}
    </ArcMenuItem>
  );

  render() {
    const { anchorEl } = this.state;
    const { options } = this.props;

    return (
      <React.Fragment>
        <ArcIconButton onClick={this.handleClick}>
          <MoreVert />
        </ArcIconButton>
        <ArcMenu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={this.handleClose}
        >
          {options.map(this.renderItem)}
        </ArcMenu>
      </React.Fragment>
    );
  }
}

export default ArcEasyMenu;
