import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/games';

const initialState = [];

const handleManageGamesValidMetricsIndexSuccess = (state, action) => {
  if (!action.payload) return state;

  const { data } = action.payload;

  return data;
};

const handlers = {
  [types.MANAGE_GAMES_VALID_METRICS_INDEX.SUCCESS]:
    handleManageGamesValidMetricsIndexSuccess,
};

const validMetrics = createReducer(initialState, handlers);

export default validMetrics;

export const getManageGamesValidMetrics = state =>
  state.manage.games.validMetrics;
