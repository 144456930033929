import { react2angular } from 'react2angular';

import ArcVideo from 'arcade-frontend-widgets/src/components/ArcVideo';

import { createProvidedContainer } from './helpers';

const ProvidedArcadeVideo = createProvidedContainer(ArcVideo);

export default react2angular(ProvidedArcadeVideo, [
  'sourcesByEncoding',
]);
