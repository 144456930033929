import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const handleRewardsAccountsIndexSuccess = (state, action) => {
  const { data } = action.payload;
  return data;
};

const initialState = [];

const handlers = {
  [types.REWARDS_ACCOUNTS_INDEX.SUCCESS]: handleRewardsAccountsIndexSuccess,
};

const accounts = createReducer(initialState, handlers);

export default accounts;

export const getAccounts = state => state.rewards.accounts;
