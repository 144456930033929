import React from 'react';
import { Path } from 'react-primitives-svg';
import Icon from '../Icon';

const Play = props => (
  <Icon {...props}>
    <Path
      d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
      fill="currentColor"
    />
    <Path
      d="M17.917 11.5461L8.96557 7.06985C8.86379 7.01924 8.75082 6.99545 8.63729 7.00072C8.52376 7.00595 8.41345 7.04006 8.31676 7.09985C8.22012 7.15964 8.14027 7.24308 8.08486 7.34229C8.02945 7.44151 8.00022 7.55324 8 7.66689V16.6189C8.00017 16.7326 8.02936 16.8443 8.08477 16.9436C8.14023 17.0428 8.22007 17.1262 8.31676 17.186C8.41345 17.2457 8.52376 17.2798 8.63733 17.285C8.75086 17.2902 8.86383 17.2662 8.96557 17.2155L17.917 12.7397C18.0278 12.6843 18.121 12.5991 18.1861 12.4937C18.2513 12.3882 18.2858 12.2668 18.2858 12.1429C18.2858 12.019 18.2513 11.8975 18.1861 11.7921C18.121 11.6867 18.0278 11.6015 17.917 11.5461Z"
      fill="white"
    />
  </Icon>
);

Play.displayName = 'Play';

export default Play;
