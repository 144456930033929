import React from 'react';

export interface ArcGifVideoProps {
  autoPlay: boolean;
  loop: boolean;
  muted: boolean;
  onLoad: () => void;
  repeat: boolean;
  playsInline: boolean;
  source: string;
}

const ArcGifVideo: React.FC<ArcGifVideoProps> = ({
  repeat = true,
  source,
  onLoad,
}) => {
  const videoEl: React.Ref<HTMLVideoElement> = React.useRef(null);

  React.useEffect(() => {
    if (videoEl && videoEl.current) {
      videoEl.current.addEventListener('loadeddata', onLoad);
    }

    return () => {
      if (videoEl && videoEl.current) {
        videoEl.current.removeEventListener('loadeddata', onLoad);
      }
    };
  }, []);

  return (
    <video
      ref={videoEl}
      autoPlay
      loop={repeat}
      muted
      playsInline
      src={source}
      style={{
        position: 'relative',
        top: 0,
        width: '100%',
        height: 'auto',
      }}
    />
  );
};


export default ArcGifVideo;
