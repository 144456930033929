import moment from 'moment';

import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const initialState = {};

const handleNewsfeedLoadIndexSuccess = (state, action) => {
  const { data } = action.payload;
  const summaries = data.summaries.reduce((hash, summary) => {
    hash[moment(summary.oldest).valueOf()] = summary; // eslint-disable-line no-param-reassign
    return hash;
  }, {});
  return { ...state, ...summaries };
};

const handleclearActivities = () => initialState;

const handlers = {
  [types.NEWSFEED_INDEX.SUCCESS]: handleNewsfeedLoadIndexSuccess,
  [types.NEWSFEED_CLEAR_ACTIVITIES]: handleclearActivities,
};

const summaries = state => state.newsfeed.summaries;

export default createReducer(initialState, handlers);
export const selectors = { summaries };
