import getTransformedResponseFromResourceAction from 'arcade-frontend-core/src/helpers/getTransformedResponseFromResourceAction';

import * as resources from './notifications.resources';
import apiGetNotificationsMyInboxRaw1 from './raw/apiGetNotificationsMyInboxRaw-1.json';
import apiGetNotificationsActivityRaw1 from './raw/apiGetNotificationsActivityRaw-1.json';
import apiGetNotificationsHistoryRaw1 from './raw/apiGetNotificationsHistoryRaw-1.json';

export const apiGetNotificationsMyInboxRaw = apiGetNotificationsMyInboxRaw1;
export const apiGetNotificationsActivityRaw = apiGetNotificationsActivityRaw1;
export const apiGetNotificationsHistoryRaw = apiGetNotificationsHistoryRaw1;

export const apiGetNotificationsActivity = getTransformedResponseFromResourceAction(
  resources.apiGetNotificationsActivity,
  apiGetNotificationsActivityRaw,
);

export const apiGetNotificationsHistory = getTransformedResponseFromResourceAction(
  resources.apiGetNotificationsHistory,
  apiGetNotificationsHistoryRaw,
);

export const apiGetNotificationsMyInbox = getTransformedResponseFromResourceAction(
  resources.apiGetNotificationsMyInbox,
  apiGetNotificationsMyInboxRaw,
);
