import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';
import ArcResponsiveDialog from 'arcade-frontend-ui/src/components/ArcResponsiveDialog';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';

const STRINGS = {
  'PEOPLE/DELETE_TEAM_DESCRIPTION_1': 'Removing this team will cause',
  'PEOPLE/DELETE_TEAM_DESCRIPTION_2':
    'to be without a team. You can reassign them to other teams by choosing "Change Team Assignment" from the members tab.',
  'PEOPLE/DELETE_TEAM_DESCRIPTION_NO_MEMBERS':
    "This team currently has no members, removing it won't impact any users.",
};

function PeopleDeleteTeamModal({
  open,
  onClose,
  onDeleteTeamConfirmation,
  team,
  deleteTeamStatus,
  deleteTeamHasError,
}) {
  return (
    <ArcResponsiveDialog open={open} onClose={onClose} maxWidth={400}>
      <DialogTitle>
        <ArcText isStrong>{'Delete Team'}</ArcText>
      </DialogTitle>

      <DialogContent>
        <ArcBox
          display="flex"
          flexDirection="column"
          textAlign="left"
          color="common.black"
          lineHeight="150%"
          mb={1}
        >
          <ArcBox component={ArcText} mb={2}>
            {'Are you sure you want to delete '}
            <ArcText isStrong>
              {team && team.name ? team.name : 'this team'}
            </ArcText>
            {'?'}
          </ArcBox>
          {team && !!team.userCount ? (
            <ArcText>
              {STRINGS['PEOPLE/DELETE_TEAM_DESCRIPTION_1']}
              <ArcText isStrong>{` ${team.userCount} ${pluralize(
                'member',
                team.userCount,
              )} `}</ArcText>
              {STRINGS['PEOPLE/DELETE_TEAM_DESCRIPTION_2']}
            </ArcText>
          ) : (
            <ArcText>
              {STRINGS['PEOPLE/DELETE_TEAM_DESCRIPTION_NO_MEMBERS']}
            </ArcText>
          )}
        </ArcBox>
      </DialogContent>

      <DialogActions>
        <ArcBox
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="flex-end"
          minHeight={75}
          p={1}
        >
          {deleteTeamHasError && (
            <ArcBox
              component={ArcText}
              className="animated fadeIn"
              color="red.main"
              lineHeight="150%"
              fontSize={12}
              m={0.5}
            >
              {'Could not delete team. Please try again later.'}
            </ArcBox>
          )}
          <ArcBox display="flex" flexDirection="row">
            <ArcButton onClick={onClose} label="Close" />
            <ArcBox ml={0.5}>
              <ArcResourceButton
                onClick={onDeleteTeamConfirmation}
                color="danger"
                variant="outlined"
                label="Delete Team"
                status={deleteTeamStatus}
              />
            </ArcBox>
          </ArcBox>
        </ArcBox>
      </DialogActions>
    </ArcResponsiveDialog>
  );
}

PeopleDeleteTeamModal.displayName = 'PeopleDeleteTeamModal';

PeopleDeleteTeamModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onDeleteTeamConfirmation: PropTypes.func,
  team: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    userCount: PropTypes.number,
    companyIdentifier: PropTypes.string,
    location: PropTypes.string,
  }),
  deleteTeamStatus: PropTypes.objectOf(PropTypes.bool),
  deleteTeamHasError: PropTypes.bool,
};

PeopleDeleteTeamModal.defaultProps = {
  open: false,
  onClose: global.noop,
  onDeleteTeamConfirmation: global.noop,
  team: null,
  deleteTeamStatus: {},
  deleteTeamHasError: false,
};

export default PeopleDeleteTeamModal;
