import { getResources, getStatus, resourceReducer } from 'redux-resource';

const rewardPurchases = resourceReducer('rewardPurchases');

export default rewardPurchases;

export const getData = state => state.rewards.rewardPurchases;
export const getMeta = state => state.rewards.rewardPurchases.meta;

export const getRewardPurchaseStatus = state =>
  getStatus(
    state,
    'rewards.rewardPurchases.requests.apiGetRewardPurchases.status',
  );
export const getCancelRewardPurchaseStatus = state =>
  getStatus(
    state,
    'rewards.rewardPurchases.requests.apiCancelRewardPurchase.status',
  );

export const getRewardPurchases = state =>
  getResources(getData(state), 'index');

export const getRewardMetaStatus = (state, id, type = 'update') =>
  getStatus(state, `rewards.rewardPurchases.meta[${id}].${type}Status`);
