import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination, TableFooter } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { formatDateTimeByLocale } from 'arcade-frontend-ui/src/helpers/utils/date';
import {
  ArcView,
  ArcDataTableCell,
  ArcDataTableRow,
  ArcCellContent,
  ArcText,
  ArcToken,
  ArcLink,
  createWithStyles,
  ArcBadge,
} from 'arcade-frontend-ui';
import track from 'arcade-frontend-core/src/helpers/track';

import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';

import RewardHowToRedeemDialog from '../RewardHowToRedeemDialog';
import RewardHistoryViewRewardDialog from '../RewardHistoryViewRewardDialog';
import RewardHistoryCancelContainer from '../../containers/RewardHistoryCancelContainer';

const styles = {
  TableWrapper: theme => ({
    root: {
      borderWidth: 1,
      borderColor: theme.palette.grey[300],
      borderStyle: 'solid',
      backgroundColor: theme.palette.common.white,
      overflow: 'auto',
    },
  }),
  RewardImageWrapper: () => ({
    root: {
      width: 50,
      height: 50,
      backgroundColor: 'red',
    },
  }),
  RewardTextWrapper: () => ({
    root: {
      flexDirection: 'column',
      marginLeft: 20,
    },
  }),
  RewardDetailsWrapper: () => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      minWidth: 420,
    },
  }),
  StatusSubheader: theme => ({
    root: {
      fontSize: theme.font.getFontSize(0.75),
      color: theme.palette.grey[600],
    },
  }),
  DenominationText: theme => ({
    root: {
      marginRight: theme.spacing(1),
      textTransform: 'capitalize',
    },
  }),
  TokenHolder: () => ({
    root: {
      height: 21,
      width: 16,
      marginRight: 6,
      float: 'left',
    },
  }),
  TokenCostText: theme => ({
    root: {
      fontSize: theme.font.getFontSize(1),
    },
  }),
  HowToRedeemLink: theme => ({
    root: {
      color: theme.palette.grey[600],
      fontWeight: 300,
      marginLeft: theme.spacing(0.75),
      '&:hover': {
        color: theme.palette.grey[600],
      },
      '&:hover svg': {
        color: theme.palette.grey[700],
      },
    },
  }),

  Info: theme => ({
    root: {
      height: 16,
      width: 16,
      color: theme.palette.grey[600],
      marginLeft: 4,
      marginBottom: 2,
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  }),
};

const tableLookup = {
  name: { label: 'Reward', key: 'name' },
  tokenCost: { label: 'Token Cost', key: 'tokenCost' },
  createdAt: { label: 'Purchase Date', key: 'createdAt' },
  redemptionLink: { key: 'actionLink' },
};

const TableWrapper = createWithStyles(styles.TableWrapper)(ArcView);
const RewardDetailsWrapper = createWithStyles(styles.RewardDetailsWrapper)(
  ArcView,
);
const RewardTextWrapper = createWithStyles(styles.RewardTextWrapper)(ArcView);
const StatusSubheader = createWithStyles(styles.StatusSubheader)(ArcText);
const DenominationText = createWithStyles(styles.DenominationText)(ArcText);
const TokenHolder = createWithStyles(styles.TokenHolder)(ArcView);
const TokenCostText = createWithStyles(styles.TokenCostText)(ArcText);
const HowToRedeemLink = createWithStyles(styles.HowToRedeemLink)(ArcLink);
const Info = createWithStyles(styles.Info)(InfoIcon);

const RewardHistoryList = ({
  rewardPurchaseCount,
  rewardPurchases,
  status,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openedDialog, setOpenedDialog] = useState('');
  const [selectedReward, setSelectedReward] = useState({});

  const hasInstantRewardsFeature = useFeatureFlag(
    FEATURE_FLAGS.INSTANT_REWARDS,
  );

  const handleChangePage = (event, newPage) => {
    track(`change page from ${page} to ${newPage}`, {
      payload: {
        event: 'reward:change-page',
        rowsPerPage,
        prevPage: page,
        nextPage: newPage,
      },
    });
    setPage(newPage);

    onChangePage(event, newPage);
  };

  const handleChangeRowsPerPage = event => {
    track(`change rows per page from ${rowsPerPage} to ${event.target.value}`, {
      payload: {
        event: 'reward:change-rows-per-page',
        prevRowsPerPage: rowsPerPage,
        nextRowsPerPage: event.target.value,
        page,
      },
    });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    onChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleHowToRedeem = reward => {
    track('open dialog how-to-redeem-dialog', {
      payload: { event: 'reward:open-dialog-how-to-redeem-dialog' },
    });
    setOpenedDialog('how-to-redeem-dialog');
    setSelectedReward(reward);
  };

  const handleCloseDialog = () => {
    track(`close dialog ${openedDialog}`, {
      payload: { event: `reward:close-dialog-${openedDialog}` },
    });
    setOpenedDialog('');

    setTimeout(() => {
      setSelectedReward({});
    }, 300);
  };

  const renderPlaceholderRows = rowToRender => {
    const placeholderRows = [];
    for (let index = 0; index < rowToRender; index += 1) {
      placeholderRows.push(
        <ArcDataTableRow key={index}>
          <ArcDataTableCell>
            <RewardDetailsWrapper>
              <ArcView
                className="shimmer"
                style={{
                  borderRadius: 6,
                  width: 85,
                  height: 60,
                }}
              />
              <RewardTextWrapper />
              <ArcView className="shimmer" padding="16" flex="1" />
            </RewardDetailsWrapper>
          </ArcDataTableCell>
          <ArcDataTableCell>
            <ArcView className="shimmer" padding="16" />
          </ArcDataTableCell>
          <ArcDataTableCell>
            <ArcView className="shimmer" padding="16" />
          </ArcDataTableCell>
          <ArcDataTableCell>
            <ArcView className="shimmer" padding="16" />
          </ArcDataTableCell>
        </ArcDataTableRow>,
      );
    }
    return placeholderRows;
  };

  const PLACEHOLDERS = (
    <TableWrapper>
      <Table data-testid="placeholder-reward-history-table">
        <TableHead>
          <TableRow>
            <ArcDataTableCell>
              <ArcView className="shimmer" padding="16" />
            </ArcDataTableCell>
            <ArcDataTableCell>
              <ArcView className="shimmer" padding="16" />
            </ArcDataTableCell>
            <ArcDataTableCell>
              <ArcView className="shimmer" padding="16" />
            </ArcDataTableCell>
            <ArcDataTableCell>
              <ArcView className="shimmer" padding="16" />
            </ArcDataTableCell>
            <ArcDataTableCell />
          </TableRow>
        </TableHead>
        <TableBody>{renderPlaceholderRows(10)}</TableBody>
        <TableFooter>
          <TableRow />
        </TableFooter>
      </Table>
    </TableWrapper>
  );

  const renderRewardStatusChip = rewardStatus => {
    switch (rewardStatus) {
      case 'delivered':
        return <ArcBadge color="secondary">{'Delivered'}</ArcBadge>;
      case 'cancelled':
        return (
          <ArcBadge color="danger" variant="text">
            {'Cancelled'}
          </ArcBadge>
        );
      default:
        return <ArcBadge>{rewardStatus}</ArcBadge>;
    }
  };

  const renderTableHeader = () => (
    <TableRow>
      {Object.values(tableLookup).map(lookupItem => (
        <ArcDataTableCell key={lookupItem.key} headerCell>
          {lookupItem.label}
        </ArcDataTableCell>
      ))}
    </TableRow>
  );

  const renderTableBody = rewardsToDisplay =>
    rewardsToDisplay.map((reward, index) => {
      const {
        id,
        imageUrl,
        name,
        createdAt,
        tokenCost,
        expectedFulfilmentDate,
        denomination,
        quantity,
      } = reward;

      const rewardStatus = reward.status;

      return (
        <ArcDataTableRow key={id} shade={index % 2 === 0}>
          <ArcDataTableCell>
            <RewardDetailsWrapper>
              <ArcView
                style={{
                  backgroundColor: '#f3f3f3',
                  backgroundImage: `url(${imageUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: 6,
                  width: 85,
                  height: 60,
                }}
              />
              <RewardTextWrapper>
                <ArcCellContent title>{name}</ArcCellContent>

                <StatusSubheader>
                  <ArcView row align="center">
                    {rewardStatus && renderRewardStatusChip(rewardStatus)}

                    {rewardStatus !== 'cancelled' && (
                      <HowToRedeemLink
                        size="inherit"
                        onClick={() => handleHowToRedeem(reward)}
                      >
                        {rewardStatus === 'received'
                          ? "We've received your order, what's next?"
                          : 'How to redeem?'}
                        <Info />
                      </HowToRedeemLink>
                    )}
                  </ArcView>
                  <ArcView row align="center" style={{ marginTop: 6 }}>
                    <DenominationText>
                      Type:<ArcText isStrong> {denomination}</ArcText>
                    </DenominationText>
                    <DenominationText>
                      Qty: <ArcText isStrong> {quantity} </ArcText>
                    </DenominationText>
                    {rewardStatus === 'received' && (
                      <DenominationText>
                        Delivery:{' '}
                        <ArcText isStrong>{expectedFulfilmentDate} </ArcText>
                      </DenominationText>
                    )}
                  </ArcView>
                </StatusSubheader>
              </RewardTextWrapper>
            </RewardDetailsWrapper>
          </ArcDataTableCell>
          <ArcDataTableCell>
            <TokenHolder row align="center">
              <ArcToken />
            </TokenHolder>
            <TokenCostText>
              {window.Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2,
              }).format(tokenCost)}
            </TokenCostText>
          </ArcDataTableCell>
          <ArcDataTableCell>
            {formatDateTimeByLocale(createdAt)}
          </ArcDataTableCell>
          <ArcDataTableCell>
            {!hasInstantRewardsFeature && rewardStatus && (
              <RewardHistoryCancelContainer reward={reward} />
            )}
          </ArcDataTableCell>
        </ArcDataTableRow>
      );
    });

  const isLoading = !rewardPurchases.length && status.pending;

  const rewardsToDisplay = rewardPurchases.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <TableWrapper>
      {isLoading ? (
        PLACEHOLDERS
      ) : (
        <Table data-testid="reward-history-table">
          <TableHead>{renderTableHeader()}</TableHead>
          <TableBody>{renderTableBody(rewardsToDisplay)}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[25, 50, 100]}
                page={page}
                colSpan={5}
                count={rewardPurchaseCount}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
      <RewardHowToRedeemDialog
        open={openedDialog === 'how-to-redeem-dialog'}
        reward={selectedReward}
        onClose={handleCloseDialog}
      />

      <RewardHistoryViewRewardDialog
        open={openedDialog === 'view-reward-dialog'}
        title={'View Reward'}
        onClose={handleCloseDialog}
      />
    </TableWrapper>
  );
};

RewardHistoryList.displayName = 'RewardHistoryList';

RewardHistoryList.propTypes = {
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  rewardPurchaseCount: PropTypes.number,
  rewardPurchases: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      quantity: PropTypes.number,
      amount: PropTypes.number,
      denomination: PropTypes.string,
      createdAt: PropTypes.string,
      expectedFulfillmentDate: PropTypes.string,
      status: PropTypes.string,
      redemptionLink: PropTypes.string,
      redemptionInformation: PropTypes.string,
      tokenCost: PropTypes.number,
    }),
  ),
  status: PropTypes.shape({
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    failed: PropTypes.bool,
    succeeded: PropTypes.bool,
  }),
};

RewardHistoryList.defaultProps = {
  rewardPurchases: [],
  rewardPurchaseCount: 0,
  onChangePage: global.noop,
  onChangeRowsPerPage: global.noop,
  status: {},
};

export default RewardHistoryList;
