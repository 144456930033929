import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import ArcView from '../../primitives/ArcView';

const styles = () => ({
  root: {
    overflow: 'hidden',
    height: 0,
    paddingTop: `${(9 / 16) * 100}%`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
});

const linkStyle = {
  display: 'block',
  height: '100%',
  width: '100%',
};


class ArcRemoteImage extends React.PureComponent {
  static displayName = 'ArcRemoteImage';

  static propTypes = {
    alt: PropTypes.string.isRequired,
    classes: PropTypes.objectOf(PropTypes.string),
    linkTo: PropTypes.string,
    src: PropTypes.string.isRequired,
  };

  static defaultProps = {
    classes: {},
    linkTo: '',
  };

  handleClick = (evt) => {
    if (window.arcPostMessage) {
      evt.preventDefault();

      window.arcPostMessage(`native.imageShow.${this.props.linkTo}`, '*');
    }
  };

  renderImage() {
    const {
      classes,
      src,
    } = this.props;

    return (
      <ArcView
        position="relative"
        className={cx([
          classes.root,
        ])}
        fullWidth
        style={{
          backgroundImage: `url(${src})`,
        }}
      />
    );
  }

  renderLinkedImage() {
    return (
      <a
        alt={`Link to ${this.props.alt}`}
        href={this.props.linkTo}
        onClick={this.handleClick}
        target="_blank"
        rel="noopener noreferrer"
        style={linkStyle}
      >
        {this.renderImage()}
      </a>
    );
  }

  render() {
    return this.props.linkTo ? this.renderLinkedImage() : this.renderImage();
  }
}

export default withStyles(styles)(ArcRemoteImage);
