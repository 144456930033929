import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import ButtonBase from '@material-ui/core/ButtonBase';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  ArcAddEmojiButton,
  ArcEmojiButton,
  ArcIconButton,
  ArcView,
  ArcText,
  ArcEmoji,
} from 'arcade-frontend-ui';

import { reactionOptions } from '../../reactionData';

const showMoreButtonStyle = {
  padding: 0,
  paddingLeft: 6,
  height: '100%',
};

const showMoreSmallButtonStyle = {
  height: 16,
  width: 16,
};

const STRINGS = {
  'NEWSFEED/ACTIVITY_REACTIONS_EMPTY': 'Be the first to react to this',
};

function NewsfeedActivityReactions({
  activityId,
  reactions,
  isRequesting,
  initialIsHovered,
  currentUserId,
  onRequest,
  onSelect,
  onShowMore,
}) {
  useEffect(() => {
    onRequest(activityId);
  }, []);

  const isSmallScreen = useMediaQuery('(max-width:450px)');

  const [anchorEl, setMenuState] = useState(null);

  const handleOpen = event => {
    setMenuState(event.currentTarget);
  };

  const handleClose = () => {
    setMenuState(null);
  };

  const handleReactionSelection = useMemo(
    () =>
      debounce(300, reaction => {
        onSelect(activityId, reaction);
        handleClose();
      }),
    [onSelect, handleClose, activityId, reactions],
  );

  const [isHovered, setIsHovered] = useState(initialIsHovered);

  const handleMouseEnter = () => !initialIsHovered && setIsHovered(true);

  const handleMouseLeave = () => !initialIsHovered && setIsHovered(false);

  const reactionTotals = useMemo(
    () =>
      reactionOptions.map(reaction => {
        const thisReactions = reactions.filter(r => r.reaction === reaction);
        const total = thisReactions.length;

        let names = '';

        if (total > 2) {
          const thisReactionsTruncated = thisReactions.slice(0, 2);
          const numRemainingReactions = total - thisReactionsTruncated.length;

          const textRemainingReactions =
            numRemainingReactions === 1
              ? '1 other person'
              : `${numRemainingReactions} other people`;

          names = `${thisReactionsTruncated
            .map(r => r.userName)
            .join(', ')} and ${textRemainingReactions}`;
        } else {
          names = `${thisReactions.map(r => r.userName).join(' and ')}`;
        }

        return {
          reaction,
          currentUserSelected:
            thisReactions.filter(r => r.userId === currentUserId).length > 0,
          total,
          tooltipTitle: `${names} reacted to this with ${reaction}`,
        };
      }),
    [activityId, reactions, reactionOptions],
  );

  const sortedReactionTotals = [...reactionTotals].sort(
    (a, b) => b.total - a.total,
  );

  const topThreeReactionTotals = sortedReactionTotals.slice(0, 3);
  const hiddenReactionCount =
    sortedReactionTotals.filter(reaction => reaction.total > 0).length - 3;

  const canSelect = () => onSelect !== global.noop;

  const renderReactionsExtended = () => (
    <ArcView row>
      {sortedReactionTotals.map(
        ({ currentUserSelected, reaction, total, tooltipTitle }) =>
          total > 0 && (
            <Tooltip key={reaction} title={tooltipTitle} placement="bottom">
              <ArcView marginLeft="4">
                <ArcEmojiButton
                  onClick={() => handleReactionSelection(reaction)}
                  disabled={isRequesting}
                  data-testid="emoji-button"
                  label={reaction}
                  emoji={reaction}
                  isSelected={currentUserSelected}
                  total={total}
                  color={currentUserSelected ? 'primary' : 'default'}
                />
              </ArcView>
            </Tooltip>
          ),
      )}

      {onShowMore !== global.noop && (
        <ArcView
          row
          marginLeft="4"
          paddingRight="16"
          visibility={isHovered ? 'visible' : 'hidden'}
        >
          <ArcIconButton
            onClick={onShowMore}
            variant="outlined"
            style={showMoreButtonStyle}
          >
            <VisibilityOutlined color="default" />
          </ArcIconButton>
        </ArcView>
      )}
    </ArcView>
  );

  const renderReactionsCondensed = () => (
    <ArcView row>
      {topThreeReactionTotals.map(
        ({ currentUserSelected, reaction, total, tooltipTitle }) =>
          total > 0 && (
            <Tooltip key={reaction} title={tooltipTitle} placement="bottom">
              <ArcView marginLeft="4">
                <ArcEmojiButton
                  onClick={() => handleReactionSelection(reaction)}
                  disabled={isRequesting}
                  data-testid="emoji-button"
                  label={reaction}
                  emoji={reaction}
                  isSelected={currentUserSelected}
                  total={total}
                  color={currentUserSelected ? 'primary' : 'default'}
                />
              </ArcView>
            </Tooltip>
          ),
      )}

      {onShowMore !== global.noop && (
        <ArcView
          row
          marginLeft="4"
          paddingRight="16"
          visibility={isSmallScreen || isHovered ? 'visible' : 'hidden'}
        >
          {hiddenReactionCount > 0 ? (
            <ArcIconButton
              onClick={onShowMore}
              variant="outlined"
              style={showMoreButtonStyle}
            >
              <VisibilityOutlined
                color="primary"
                style={showMoreSmallButtonStyle}
              />
              <ArcView row padding="4">
                <ArcText color={isRequesting ? 'disabled' : 'blue'} size="12px">
                  {`${hiddenReactionCount} more`}
                </ArcText>
              </ArcView>
            </ArcIconButton>
          ) : (
            <ArcIconButton
              onClick={onShowMore}
              variant="outlined"
              style={showMoreButtonStyle}
            >
              <VisibilityOutlined color="default" />
            </ArcIconButton>
          )}
        </ArcView>
      )}
    </ArcView>
  );

  const renderReactions = () =>
    isSmallScreen ? renderReactionsCondensed() : renderReactionsExtended();

  return (
    <ArcView
      row
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {canSelect() && (
        <ArcAddEmojiButton onClick={handleOpen} disabled={isRequesting} />
      )}

      <Popover anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {reactionOptions.map(emoji => (
          <ArcIconButton
            key={emoji}
            onClick={() => handleReactionSelection(emoji)}
          >
            <ArcEmoji role="img" label={emoji} emoji={emoji} />
          </ArcIconButton>
        ))}
      </Popover>

      {reactions.length === 0 ? (
        <ButtonBase onClick={handleOpen} disabled={isRequesting}>
          <ArcView padding="8">
            <ArcText color={isRequesting ? 'disabled' : 'blue'} size="12px">
              {STRINGS['NEWSFEED/ACTIVITY_REACTIONS_EMPTY']}
            </ArcText>
          </ArcView>
        </ButtonBase>
      ) : (
        <>{renderReactions()}</>
      )}
    </ArcView>
  );
}

NewsfeedActivityReactions.propTypes = {
  activityId: PropTypes.string,
  isRequesting: PropTypes.bool,
  initialIsHovered: PropTypes.bool,
  reactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      reaction: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
      userImage: PropTypes.string.isRequired,
    }),
  ),
  currentUserId: PropTypes.string,
  onRequest: PropTypes.func,
  onSelect: PropTypes.func,
  onShowMore: PropTypes.func,
};

NewsfeedActivityReactions.defaultProps = {
  activityId: null,
  isRequesting: false,
  reactions: [],
  currentUserId: null,
  initialIsHovered: global.Platform.select({
    web: false,
    default: true,
  }),
  onRequest: global.noop,
  onSelect: global.noop,
  onShowMore: global.noop,
};

NewsfeedActivityReactions.displayName = 'NewsfeedActivityReactions';

export default NewsfeedActivityReactions;
