import * as routes from '../types/routes';

export const routeChat = (payload, query) => ({
  type: routes.ROUTE_CHAT,
  payload,
  query,
});

export const routeNewsfeed = (payload, query) => ({
  type: routes.ROUTE_NEWSFEED,
  payload,
  query,
});

export const routeNewsfeedShow = (payload, query) => ({
  type: routes.ROUTE_NEWSFEED_SHOW,
  payload,
  query,
});

export const routeProfile = ({ id }, query) => ({
  type: routes.ROUTE_PROFILE,
  payload: {
    id,
  },
  query,
});
