import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';

import {
  ArcIconButton,
  ArcResponsiveDialog,
  ArcView,
  ArcNoResultsMessage,
} from 'arcade-frontend-ui';

import NewsfeedActivityCard from '../NewsfeedActivityCard';

const PAPER_PROPS = {
  style: {
    width: '100%',
    height: '100%',
  },
};

const NewsfeedActivityDialog = ({
  acknowledgedById,
  activity,
  currentUser,
  onClose,
  open,
  reactionsByActivityId,
  reactionsIsRequestingByActivityId,
  savingCommentId,
  requestStatus,
  ...props
}) => {
  const [hasNoResult, setHasNoResults] = useState(false);

  useEffect(() => {
    if (requestStatus.NEWSFEED_ACTIVITY === 'FAILURE') {
      setHasNoResults(true);
    }
  }, [requestStatus]);

  useEffect(() => {
    if (activity.id && hasNoResult) {
      setHasNoResults(false);
    }
  }, [activity.id]);

  return (
    <ArcResponsiveDialog onClose={onClose} open={open} PaperProps={PAPER_PROPS}>
      <DialogTitle>
        <ArcView row align="center">
          <ArcView>{'Activity'}</ArcView>

          <ArcView spacer />

          <ArcIconButton onClick={onClose} size="small">
            <CloseIcon />
          </ArcIconButton>
        </ArcView>
      </DialogTitle>

      <>
        {hasNoResult ? (
          <ArcNoResultsMessage
            data-testid="newsfeed-activity-dialog-no-result"
            iconComponent={DeleteTwoToneIcon}
            header="This post has been removed"
          />
        ) : (
          <>
            {activity && activity.id ? (
              <NewsfeedActivityCard
                {...activity}
                {...props}
                acknowledged={acknowledgedById[activity.id]}
                currentUserId={currentUser.id}
                hasBehaviourDelete
                hasBehaviourReport
                hasBehaviourRespectDialog
                hasBehaviourShowAllReactions
                isSavingComment={savingCommentId === activity.id}
                reactions={reactionsByActivityId[activity.id]}
                reactionsIsRequesting={
                  reactionsIsRequestingByActivityId[activity.id]
                }
              />
            ) : (
              NewsfeedActivityCard.PLACEHOLDER
            )}
          </>
        )}
      </>
    </ArcResponsiveDialog>
  );
};

NewsfeedActivityDialog.displayName = 'NewsfeedActivityDialog';

NewsfeedActivityDialog.propTypes = {
  acknowledgedById: PropTypes.objectOf(PropTypes.string),
  activity: PropTypes.shape({
    id: PropTypes.string,
  }),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onCommentDelete: PropTypes.func,
  onCommentSave: PropTypes.func,
  savingCommentId: PropTypes.string,
  onReactionSelect: PropTypes.func,
  reactionsByActivityId: PropTypes.shape({}),
  reactionsIsRequestingByActivityId: PropTypes.shape({}),
  currentUser: PropTypes.shape({ id: PropTypes.string }),
  requestStatus: PropTypes.shape({
    NEWSFEED_GET_ACTIVITY_VIEWS: PropTypes.string.isRequired,
    NEWSFEED_GET_ACTIVITY_RESPECTS: PropTypes.string.isRequired,
    NEWSFEED_GET_ACTIVITY_ACKS: PropTypes.string.isRequired,
    NEWSFEED_ACTIVITY: PropTypes.string.isRequired,
  }),
};

NewsfeedActivityDialog.defaultProps = {
  acknowledgedById: {},
  activity: {},
  onClose: global.noop,
  open: false,
  onCommentDelete: global.noop,
  onCommentSave: global.noop,
  onReactionSelect: global.noop,
  savingCommentId: null,
  reactionsByActivityId: {},
  reactionsIsRequestingByActivityId: {},
  currentUser: {},
  requestStatus: {},
};

export default NewsfeedActivityDialog;
