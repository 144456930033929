import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import InsertEmoticon from '@material-ui/icons/InsertEmoticon';

import ArcView from '../../primitives/ArcView';
import ArcText from '../../primitives/ArcText';

const buttonStyle = {
  minWidth: 42,
};

const addIconStyle = {
  fontSize: 12,
};

const insertEmoticonStyle = {
  fontSize: 24,
};

function ArcAddEmojiButton({
  disabled,
  ...props
}) {
  return (
    <Button
      disabled={disabled}
      size="small"
      variant="outlined"
      color="default"
      style={buttonStyle}
      {...props}
    >
      <ArcView
        position="relative"
        row
        align="center"
      >
        <ArcText display="flex" color={disabled ? 'inherit' : 'blue'}>
          <Add
            color="inherit"
            style={addIconStyle}
          />
        </ArcText>
        <ArcText display="flex" color={disabled ? 'inherit' : 'blue'}>
          <InsertEmoticon
            color="inherit"
            style={insertEmoticonStyle}
          />
        </ArcText>
      </ArcView>
    </Button>
  );
}

ArcAddEmojiButton.propTypes = {
  disabled: PropTypes.bool,
};

ArcAddEmojiButton.defaultProps = {
  disabled: false,
};

export default ArcAddEmojiButton;
