import React from 'react';
import PropTypes from 'prop-types';

import {
  ArcView,
  ArcText,
  ArcButton,
  createWithStyles,
} from 'arcade-frontend-ui';

const styles = {
  AcknowledgePin: () => ({
    root: {
      backgroundColor: 'green',
    },
  }),
  PinnedMessage: theme => ({
    root: {
      flexDirection: 'row',
      backgroundColor: theme.palette.grey[200],
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
  }),
  ShowMore: theme => ({
    root: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  }),
  AckButton: theme => ({
    root: {
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1.5),
      },
    },
  }),
  MessageContainer: theme => ({
    root: {
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
  }),
};

const PinnedMessage = createWithStyles(styles.PinnedMessage)(ArcView);
const ShowMore = createWithStyles(styles.ShowMore)(ArcText);
const AckButton = createWithStyles(styles.AckButton)(ArcButton);
const MessageContainer = createWithStyles(styles.MessageContainer)(ArcView);

const AcknowledgePin = ({
  pinnedByName,
  pinnedByCurrentUser,
  acknowledged,
  onAcknowledge,
  openAcks,
}) => (
  <React.Fragment>
    {/* acknowledged === 'acking' && <ArcView>Loading</ArcView> */}

    {acknowledged === 'pinned' && (
      <PinnedMessage>
        {pinnedByCurrentUser ? (
          <ArcText>
            You pinned this post.{' '}
            <ShowMore onClick={openAcks}>Click here</ShowMore> to see who has
            acknowledged it.
          </ArcText>
        ) : (
          <MessageContainer row align="center" fullWidth>
            <ArcView row={false} justify="flex-start" paddingRight32>
              <ArcText>{`This post was pinned by ${pinnedByName}.`}</ArcText>
              <ArcView marginTop4>
                <ArcText isSmall>{"Let them know you've seen it!"}</ArcText>
              </ArcView>
            </ArcView>
            <ArcView spacer />
            <AckButton
              variant="contained"
              color="secondary"
              onClick={onAcknowledge}
            >
              GOT IT
            </AckButton>
          </MessageContainer>
        )}
      </PinnedMessage>
    )}
  </React.Fragment>
);

AcknowledgePin.propTypes = {
  acknowledged: PropTypes.string.isRequired,
  onAcknowledge: PropTypes.func.isRequired,
  pinnedByName: PropTypes.string,
  pinnedByCurrentUser: PropTypes.bool,
  openAcks: PropTypes.func.isRequired,
};

AcknowledgePin.defaultProps = {
  pinnedByName: '',
  pinnedByCurrentUser: false,
};

export default AcknowledgePin;
