import React, { FC, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useDispatch } from 'react-redux';

import track from 'arcade-frontend-core/src/helpers/track';
import ArcInternalLink from 'arcade-frontend-ui/src/components/ArcInternalLink';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import { ArcFormDateRangePickerContainer } from 'arcade-frontend-widgets/src/containers/ArcFormDateRangePickerContainer';
import newsfeedFilter from 'arcade-frontend-core/src/helpers/newsfeedFilter';
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';

import { createWithStyles } from 'arcade-frontend-ui';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
import { ArcFormDateRangePickerValues } from 'arcade-frontend-ui/src/forms/ArcFormDateRangePicker/helpers';

import { actions } from '../../actions';
import NewsfeedActivitiesFilterContainer from '../NewsfeedActivitiesFilterContainer';

const STRINGS = {
  'NEWSFEED/ACTIVITY_EDITOR_SHARED_SUMMARY_TEXT': 'See my',
  'NEWSFEED/ACTIVITY_EDITOR_MANAGE_POSTS_BUTTON_TEXT': 'Post History',
};

const styles = {
  ArcFormDateRangePickerContainer: theme => ({
    root: {
      marginLeft: '5px',
      height: '40px',
    },
  }),
};
const DateRangeWrapper = createWithStyles(
  styles.ArcFormDateRangePickerContainer,
)(ArcViewBox);

export interface NewsfeedActivityToolbarContainerProps {
  hasFeatureNewsfeedFiltering?: boolean;
  hasFeatureNewsfeedDateFiltering?: boolean;
  canViewHistory?: boolean;
  onFilterSubmit: () => void;
}
export const NewsfeedActivityToolbarContainer: FC<NewsfeedActivityToolbarContainerProps> = ({
  hasFeatureNewsfeedFiltering,
  hasFeatureNewsfeedDateFiltering,
  canViewHistory,
  onFilterSubmit,
  ...props
}) => {
  const dispatch = useDispatch()
  const hasAllDatesDefault = useFeatureFlag(FEATURE_FLAGS.ALL_DATES_DEFAULT)
  const [dateRange, setDateRange] = useState({
    type: 'time period',
    timePeriod: 'all_dates',
  } as ArcFormDateRangePickerValues)

  const handleDateRangeChange = dateFilter => {
    dispatch(actions.clearActivities());
    newsfeedFilter.set({ dateFilter });
    setDateRange(dateFilter)
    dispatch(
      actions.activityIndexRequest({
        page: 1,
        numberOfComments: 5,
        newsfeedFilter: newsfeedFilter.get(),
      }),
    )
    onFilterSubmit()
  }

  useEffect(() => {
    handleDateRangeChange({
      type: 'time period',
      timePeriod: hasAllDatesDefault ? 'all_dates' : 'this_month',
    })
  }, [hasAllDatesDefault])


  function handleClickManagePosts() {
    track('click post history in newsfeed', {
      payload: {
        event: 'newsfeed:click-post-history-link',
      },
    });
  }

  return (
    <Box
      data-testid="newsfeed-activity-toolbar-container"
      display="flex"
      flexDirection="row"
      alignItems="center"
      width={1}
      {...props}
    >
      {hasFeatureNewsfeedFiltering && (
        <NewsfeedActivitiesFilterContainer onSubmit={onFilterSubmit} />
      )}
      {hasFeatureNewsfeedDateFiltering && (
        <DateRangeWrapper>
          <ArcFormDateRangePickerContainer
            value={dateRange}
            onChange={handleDateRangeChange}
            style={{
              minWidth: 90,
              height: 40,
              color: '#666666',
              fontSize: '14px',
            }}
          />
        </DateRangeWrapper>
      )}
      <Box flexGrow={100} />
      {canViewHistory && (
        <Box marginX={1}>
          <ArcText color="disabled" size12px>
            {`${STRINGS['NEWSFEED/ACTIVITY_EDITOR_SHARED_SUMMARY_TEXT']} `}
            <ArcInternalLink
              onClick={handleClickManagePosts}
              href="/arcade/manage/posts"
              size="inherit"
            >
              {STRINGS['NEWSFEED/ACTIVITY_EDITOR_MANAGE_POSTS_BUTTON_TEXT']}
            </ArcInternalLink>
          </ArcText>
        </Box>
      )}
    </Box>
  );
};

export default NewsfeedActivityToolbarContainer;
