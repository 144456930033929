import React from 'react';
import { GAME_TYPES } from 'arcade-frontend-core/src/types/game-formats';

import { ArcText } from 'arcade-frontend-ui';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcGrid from 'arcade-frontend-ui/src/layout/ArcGrid';

import GamesNewFromTemplateCard from '../GamesNewFromTemplateCard/GamesNewFromTemplateCard';

const GamesNewFromTemplateBox = props => {
  return (
    <ArcBox {...props}>
      <ArcBox mt={3} mb={2}>
        <ArcBox component={ArcText} fontSize={24} lineHeight="150%" pl={1}>
          {'Game Types'}
        </ArcBox>
      </ArcBox>
      <ArcGrid container spacing={2}>
        {GAME_TYPES.map(type => (
          <ArcGrid key={type} item xs={12} sm="auto">
            <GamesNewFromTemplateCard gameType={type} />
          </ArcGrid>
        ))}
      </ArcGrid>
    </ArcBox>
  );
};

GamesNewFromTemplateBox.displayName = 'GamesNewFromTemplateBox';

export default GamesNewFromTemplateBox;
