/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import ArcAppBar from './appBar';
import { validators } from '../utils/validators';

const ArcPropTypes = { ...PropTypes };

ArcPropTypes.noop = () => {};
ArcPropTypes.NoopComponent = () => null;

ArcPropTypes.emitter = PropTypes.shape({ on: PropTypes.func, off: PropTypes.func });
ArcPropTypes.nullEmitter = { on: () => {}, off: () => {} };

ArcPropTypes.extendWith = (props) => {
  Object.keys(props).forEach((key) => {
    ArcPropTypes[key] = props[key];
  });
};

// ////////////////////////////////////////////////////////////////////////////
// Demo

ArcPropTypes.sampleData = PropTypes.shape({
  v2search: PropTypes.func,
});

ArcPropTypes.sampleUsers = PropTypes.array;
ArcPropTypes.reactTableData = PropTypes.array;
ArcPropTypes.reactTableColumns = PropTypes.array;

// ////////////////////////////////////////////////////////////////////////////
// Platform

ArcPropTypes.PLATFORMS = {
  BENJO: 'benjo',
  DEVELOPMENT: 'development',
  LAUNCH: 'launch',
};

const allPlatforms = Object.keys(ArcPropTypes.PLATFORMS).map(key => ArcPropTypes.PLATFORMS[key]);

ArcPropTypes.platform = PropTypes.oneOf(allPlatforms);


// ////////////////////////////////////////////////////////////////////////////
// Types

ArcPropTypes.children = PropTypes.oneOfType([PropTypes.node, PropTypes.func]);

ArcPropTypes.style = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.object,
]);

ArcPropTypes.innerHTML = PropTypes.shape({
  __html: PropTypes.string,
});

ArcPropTypes.userProps = PropTypes.shape({
  email: PropTypes.string,
  token: PropTypes.string,
  id: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  fullname: PropTypes.string,
  profile_image: PropTypes.string,
});

ArcPropTypes.listItemRoute = PropTypes.shape({
  label: PropTypes.string.isRequired,
  leftIcon: ArcPropTypes.children,
  route: PropTypes.string,
  onClick: PropTypes.func,
});

// ////////////////////////////////////////////////////////////////////////////
// Models

ArcPropTypes.url = PropTypes.string;

ArcPropTypes.id = PropTypes.string;
ArcPropTypes.ids = PropTypes.arrayOf(ArcPropTypes.id);

ArcPropTypes.appBar = PropTypes.instanceOf(ArcAppBar);

ArcPropTypes.date = PropTypes.oneOfType([
  PropTypes.instanceOf(Date),
  PropTypes.string,
]);

ArcPropTypes.dateString = PropTypes.string;

ArcPropTypes.targetDate = ArcPropTypes.date;
ArcPropTypes.countdownFormat = PropTypes.object;

ArcPropTypes.person = PropTypes.shape({
  id: ArcPropTypes.id.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: ArcPropTypes.url.isRequired,
});

ArcPropTypes.people = PropTypes.arrayOf(ArcPropTypes.person);
ArcPropTypes.peopleById = PropTypes.objectOf(ArcPropTypes.person);

ArcPropTypes.team = PropTypes.shape({
  id: ArcPropTypes.id.isRequired,
  name: PropTypes.string.isRequired,
  letter: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  peopleIds: ArcPropTypes.ids,
});

ArcPropTypes.teams = PropTypes.arrayOf(ArcPropTypes.team);
ArcPropTypes.teamsById = PropTypes.objectOf(ArcPropTypes.team);


// ////////////////////////////////////////////////////////////////////////////
// Collections / Resources

ArcPropTypes.ngRootScope = PropTypes.object;
ArcPropTypes.ngState = PropTypes.object;


// ////////////////////////////////////////////////////////////////////////////
// Forms

ArcPropTypes.form = PropTypes.objectOf(ArcPropTypes.object);

const validations = {};
Object.keys(validators).forEach((validator) => {
  validations[validator] = PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]);
});

ArcPropTypes.validations = PropTypes.shape(validations);

ArcPropTypes.formRadioItem = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

ArcPropTypes.formCheckboxItem = PropTypes.shape({
  value: PropTypes.string.isRequired,
});

ArcPropTypes.formSelectItem = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  label: PropTypes.string.isRequired,
});

ArcPropTypes.formSelectItems = PropTypes.arrayOf(ArcPropTypes.formSelectItem);
ArcPropTypes.formRadioItems = PropTypes.arrayOf(ArcPropTypes.formRadioItem);
ArcPropTypes.formCheckboxItems = PropTypes.arrayOf(ArcPropTypes.formCheckboxItem);


ArcPropTypes.classes = PropTypes.objectOf(PropTypes.string);

export default ArcPropTypes;
