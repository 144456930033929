import React from 'react';

import { Path } from 'react-primitives-svg';
import Icon from '../Icon';


const PeopleOutline = props => (
  <Icon {...props}>
    <Path fill="none" fillRule="evenodd" clipRule="evenodd" d="M7.5 12C9.84721 12 11.75 10.0972 11.75 7.75C11.75 5.40279 9.84721 3.5 7.5 3.5C5.15279 3.5 3.25 5.40279 3.25 7.75C3.25 10.0972 5.15279 12 7.5 12Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="none" fillRule="evenodd" clipRule="evenodd" d="M0.5 20.5C0.5 16.634 3.63401 13.5 7.5 13.5C11.366 13.5 14.5 16.634 14.5 20.5H0.5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="none" d="M16.5 20.5H23.5C23.5 18.5804 22.4991 16.7997 20.8593 15.8018C19.2195 14.8038 17.178 14.733 15.473 15.615" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="none" fillRule="evenodd" clipRule="evenodd" d="M17.944 13.5C19.7389 13.5 21.194 12.0449 21.194 10.25C21.194 8.45507 19.7389 7 17.944 7C16.1491 7 14.694 8.45507 14.694 10.25C14.694 12.0449 16.1491 13.5 17.944 13.5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

export default PeopleOutline;
