import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const initialState = false;

const handleNewsfeedIndexSuccess = (state, action) =>
  typeof action.payload.data.is_protected === 'undefined'
    ? state
    : action.payload.data.is_protected;

const actions = {
  [types.NEWSFEED_INDEX.SUCCESS]: handleNewsfeedIndexSuccess,
};

const isViewingProtectedAudience = createReducer(initialState, actions);

export default isViewingProtectedAudience;

export const getIsViewingProtectedAudience = state =>
  state.newsfeed.isViewingProtectedAudience;
