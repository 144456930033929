import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Dialog from '@material-ui/core/Dialog';


function ArcResponsiveDialog({ fullScreenAt, maxWidth, PaperProps, ...props }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(fullScreenAt));

  const paperProps = PaperProps || {
    style: {
      width: '100%',
      maxWidth,
    },
  };

  return (
    <Dialog
      {...props}
      PaperProps={!fullScreen ? paperProps : undefined}
      fullScreen={fullScreen}
    >
      {props.children}
    </Dialog>
  );
}

ArcResponsiveDialog.propTypes = {
  children: PropTypes.node,
  fullScreenAt: PropTypes.oneOf([
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
  ]),
  maxWidth: PropTypes.number,
  PaperProps: PropTypes.objectOf(PropTypes.any),
};

ArcResponsiveDialog.defaultProps = {
  children: null,
  fullScreenAt: 'xs',
  maxWidth: 600,
  PaperProps: null,
};

export default ArcResponsiveDialog;
