import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';

import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';

import ArcGrid from '../../layout/ArcGrid';
import ArcView from '../../primitives/ArcView';
import ArcIconButton from '../../elements/ArcIconButton';
import ArcNumberField from '../../elements/ArcNumberField';

import ArcChestImage from '../ArcChestImage';

const textFieldStyle = {
  width: 96,
};

const textFieldInputStyle = {
  textAlign: 'center',
};

const VARIANTS = {
  COMMON: 'Common',
  RARE: 'Rare',
  EPIC: 'Epic',
  LEGENDARY: 'Legendary',
};

class ArcChestSelect extends React.PureComponent {
  static propTypes = {
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.objectOf(PropTypes.number),
    variantsAvailable: PropTypes.arrayOf(PropTypes.oneOf(Object.values(VARIANTS))),
  };

  static defaultProps = {
    autoComplete: undefined,
    disabled: false,
    error: false,
    id: '',
    max: 99,
    min: 0,
    onBlur: global.noop,
    onChange: global.noop,
    value: {},
    variantsAvailable: Object.values(VARIANTS),
  };

  static displayName = 'ArcChestSelect';

  static VARIANTS = VARIANTS;

  isValid = value => (value >= this.props.min && value <= this.props.max) || !value;

  renderChest = (variant) => {
    const {
      disabled,
      id,
      max,
      min,
      onBlur,
      onChange,
      value,
    } = this.props;

    let numValue = parseInt(value[variant], 10);

    if (Number.isNaN(numValue)) {
      numValue = 0;
    }

    const addButtonDisabled = numValue >= max;

    const addButton = (
      <ArcIconButton
        size="small"
        disabled={disabled || addButtonDisabled}
        onClick={() => this.isValid(numValue + 1) && onChange({ ...value, [variant]: numValue + 1 })}
      >
        <Add color="inherit" size="small" />
      </ArcIconButton>
    );

    const removeButtonDisabled = numValue <= min;

    const removeButton = (
      <ArcIconButton
        size="small"
        disabled={disabled || removeButtonDisabled}
        onClick={() => this.isValid(numValue - 1) && onChange({ ...value, [variant]: numValue - 1 })}
      >
        <Remove color="inherit" size="small" />
      </ArcIconButton>
    );

    const uniqueId = id ? `${id}-${variant}` : variant;

    const handleChange = (evt) => {
      const isValid = this.isValid(evt.target.value);

      if (isValid) {
        const parsedValue = evt.target.value
          ? parseInt(evt.target.value, 10)
          : 0;

        onChange({ ...value, [variant]: parsedValue });
      }
    };

    return (
      <ArcGrid
        key={variant}
        item
        component={ArcView}
        align="center"
      >
        <label htmlFor={uniqueId}>
          <ArcChestImage
            variant={variant.toLowerCase()}
            label={variant}
            open={numValue > 0}
          />
        </label>
        <ArcNumberField
          autoComplete={this.props.autoComplete}
          id={uniqueId}
          error={this.props.error}
          disabled={disabled}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder=""
          style={textFieldStyle}
          variant="outlined"
          value={numValue}
          InputProps={{
            margin: 'dense',
            style: {
              padding: '0 5px',
            },
            inputProps: {
              style: textFieldInputStyle,
            },
            startAdornment: removeButtonDisabled && min > 0 ? (
              <Tooltip title={`Min amount is ${min}`}>
                <ArcView>
                  {removeButton}
                </ArcView>
              </Tooltip>
            ) : removeButton,
            endAdornment: addButtonDisabled ? (
              <Tooltip title={`Max amount is ${max}`}>
                <ArcView>
                  {addButton}
                </ArcView>
              </Tooltip>
            ) : addButton,
          }}
        />
      </ArcGrid>
    );
  };

  render() {
    const {
      variantsAvailable,
    } = this.props;

    return (
      <ArcGrid
        container
        spacing={2}
      >
        {!!variantsAvailable && variantsAvailable.map(this.renderChest)}
      </ArcGrid>
    );
  }
}

export default ArcChestSelect;
