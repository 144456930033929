function navigateTo(url, queryParams) {
  if (!window.ngState) {
    return console.log('navigateTo', { url, queryParams });
  }

  // To pull up the angular state from a URL we had to apply a patch to ui-router
  // https://github.com/angular-ui/ui-router/issues/1651
  // https://stackoverflow.com/questions/26824628/ui-router-state-current-wrapper-for-arbitary-state/26848546#26848546

  window.ngState.get().forEach((state) => {
    if (!state.$$state) {
      return;
    }

    const ngRouteConfig = state.$$state();
    const ngRoutePayload = ngRouteConfig.url.exec(url, queryParams);

    if (ngRoutePayload) {
      window.ngState.go(state.name, ngRoutePayload);
    }
  });

  return null;
}

if (window) {
  window.navigateTo = navigateTo;
}

export default navigateTo;
