import React from 'react';
import { throttle } from 'throttle-debounce';
import {
  ArcCheckbox,
  ArcPlaceholder,
  ArcPropTypes,
  ArcTextField,
  ArcText,
  ArcView,
  createWithStyles,
} from 'arcade-frontend-ui';

import ManagePeopleListItem from './ManagePeopleListItem';


const styles = {
  PeopleView: theme => ({
    root: {
      width: '100%',
      maxWidth: theme.spacing(60),
      margin: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
      },
    },
  }),

  PeopleList: () => ({
    root: {
      alignSelf: 'center',
      width: '100%',
    },
  }),

  SearchField: theme => ({
    root: {
      margin: theme.spacing(1),
      width: 240,
    },
  }),

  StylePlaceholder: () => ({
    root: {
      color: 'rgb(102, 102, 102)',
      lineHeight: '24px',
      padding: '16px 8px',
      borderBottom: '1px solid rgb(243, 243, 243)',
      flex: '2 0 auto',
      height: 64,
      width: 480,
    },
  }),
};

const PeopleList = createWithStyles(styles.PeopleList)(ArcView);
const PeopleView = createWithStyles(styles.PeopleView)(ArcView);
const SearchField = createWithStyles(styles.SearchField)(ArcTextField);
const StylePlaceholder = createWithStyles(styles.StylePlaceholder)(ArcView);


const fullName = person => `${person.firstName} ${person.lastName}`;

class ManagePeopleList extends React.PureComponent {
  static propTypes = {
    availablePeople: ArcPropTypes.arrayOf(ArcPropTypes.string),
    isFetchingPeople: ArcPropTypes.bool,
    onClickPermissions: ArcPropTypes.func,
    onClickProfile: ArcPropTypes.func,
    peopleById: ArcPropTypes.shape({}),
  };

  static defaultProps = {
    availablePeople: [],
    isFetchingPeople: false,
    onClickPermissions: ArcPropTypes.noop,
    onClickProfile: ArcPropTypes.noop,
    peopleById: {},
  };

  state = {
    searchTerm: '',
    userList: [],
    showLocked: false,
  };

  componentDidMount() {
    this.updateList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.availablePeople !== prevProps.availablePeople || this.props.peopleById !== prevProps.peopleById) {
      this.updateList();
    }
  }

  get hasPeople() {
    return this.state.filteredPeople.length >= 1;
  }

  setSearchTerm = (searchTerm, cb) => {
    this.setState({ searchTerm }, cb);
  };

  setShowLocked = showLocked => this.setState({ showLocked }, this.updateList);

  containsSearchTerm = (person, searchTerm) => fullName(person).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
    || person.email.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;


  handleChangeSearchTerm = (event) => {
    this.setSearchTerm(event.target.value, this.updateListThrottled);
  };

  handleChangeShowLocked = () => this.setShowLocked(!this.state.showLocked);

  isLockedState = (person, showLocked) => showLocked || !person.locked;

  updateList = () => this.setState({ userList: this.sortedFilteredPeople() })

  updateListThrottled = throttle(300, this.updateList)

  sortedFilteredPeople = () => this.props.availablePeople.map(id => this.props.peopleById[id])
    .filter(person => this.containsSearchTerm(person, this.state.searchTerm))
    .filter(person => this.isLockedState(person, this.state.showLocked))
    .sort((a, b) => fullName(a).localeCompare(fullName(b)))

  renderPeopleListItem = person => (
    <ManagePeopleListItem
      key={person.id}
      person={person}
      onClickProfile={this.props.onClickProfile}
      onClickPermissions={this.props.onClickPermissions}
    />
  );

  renderEmpty = () => (
    <ArcText style={{ margin: 8 }}>
      {'No results found'}
    </ArcText>
  );

  renderLoadingPlaceholder = () => [0, 1, 2, 3, 4].map(index => (
    <StylePlaceholder
      row
      key={index}
    >
      <ArcPlaceholder
        flexGrow="1"
      />
    </StylePlaceholder>
  ));


  renderPeopleList() {
    const { userList } = this.state;

    if (userList.length === 0) {
      return this.renderEmpty();
    }

    return (
      <PeopleList>
        { userList.map(this.renderPeopleListItem) }
      </PeopleList>
    );
  }

  render() {
    return (
      <ArcView align="center">
        <PeopleView>
          <ArcView row justify="space-between" align="center">
            <SearchField
              placeholder="Search"
              value={this.state.searchTerm}
              variant="outlined"
              onChange={this.handleChangeSearchTerm}
            />
            <ArcCheckbox
              style={{ height: 32 }}
              label={'Show Locked'}
              checked={this.state.showLocked}
              onChange={this.handleChangeShowLocked}
            />
          </ArcView>
          {this.props.isFetchingPeople ? this.renderLoadingPlaceholder() : this.renderPeopleList()}
        </PeopleView>
      </ArcView>
    );
  }
}

export default ManagePeopleList;
