import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const FlagAlt = props => (
  <Icon {...props}>
    <Path
      d="M5 1.5h6a1 1 0 011 1v2h10c.55 0 .649.281.219.625L15.5 10.5l7.5 6H8.6a.5.5 0 01-.464-.688C8.83 14.094 12 13.5 12 13.5H5M12 4.5v9M3 .5v23"
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default FlagAlt;
