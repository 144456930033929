import { put, takeEvery as RSTakeEvery } from '@redux-saga/core/effects';

import sagas from 'arcade-frontend-core/src/helpers/sagas';

import { types, actions } from '../actions';
import { api } from '../resources';

const takeLatest = [
  {
    type: types.PEOPLE_INDEX,
    call: api.people,
  },

  {
    type: types.PEOPLE_QUICKLINKS,
    call: api.quicklinks,
  },
];

function* showQuicklinksUser(action) {
  const { payload } = action;
  const { userId } = payload;

  if (userId) {
    yield put(actions.apiPeopleQuicklinksRequest(userId));
  }
}

export default [
  RSTakeEvery(types.SHOW_QUICKLINKS_USER, showQuicklinksUser),
  ...takeLatest.map(sagas.makeTakeLatest),
];
