import { Platform } from 'react-native';

// https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system/21742107
function getPlatformOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows';
  }

  if (/(android)/i.test(userAgent)) {
    return 'android';
  }


  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'web';
}

const OS = Platform.select({
  web: getPlatformOS(),
  default: Platform.OS,
});

const select = spec => (OS in spec ? spec[OS] : spec.default);

global.Platform = {
  OS,
  select,
};
