import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';

import {
  ArcButton,
  ArcForm,
  ArcView,
  ArcText,
  ArcFormField,
} from 'arcade-frontend-ui';
import ArcErrorDialog from 'arcade-frontend-ui/src/components/ArcErrorDialog';
import ArcResponsiveDialog from 'arcade-frontend-ui/src/components/ArcResponsiveDialog';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';
import ArcPeopleMultiSelect from 'arcade-frontend-ui/src/components/ArcPeopleMultiSelect';

import ArcAwardChestFormConfirmationDialog from './ArcAwardChestFormConfirmationDialog';

const INITIAL_VALUES = {
  chests: {
    Common: 1,
    Epic: 0,
    Rare: 0,
    Legendary: 0,
  },
  isPublic: false,
  message: '',
  reason: '',
  people: [],
};

const MAX_NUM_CONFIRMATION_PEOPLE = 5;

function getTotalNumOfChests(formikProps) {
  return Object.values(formikProps.values.chests).reduce((total, current) => {
    total += current; // eslint-disable-line no-param-reassign
    return total;
  });
}

const STRINGS = {
  'UI/AWARD_CHEST_FORM_MESSAGE_HEADER': 'Public Message',
  'UI/AWARD_CHEST_FORM_MESSAGE_TEXT':
    'The public message is shown on the newsfeed',
  'UI/AWARD_CHEST_FORM_INTERNAL_REASON_HEADER': 'Reason for award',
  'UI/AWARD_CHEST_FORM_INTERNAL_REASON_TEXT':
    'The reason is sent to the recipient',
};

class ArcAwardChestForm extends React.PureComponent {
  static displayName = 'ArcAwardChestForm';

  static propTypes = {
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    hasConfirmation: PropTypes.bool,
    hasError: PropTypes.bool,
    hasFailed: PropTypes.bool,
    hasFieldIsPublic: PropTypes.bool,
    hasFieldPeople: PropTypes.bool,
    inDialog: PropTypes.bool,
    initialValues: PropTypes.shape({}),
    isPending: PropTypes.bool,
    onCancel: PropTypes.func,
    onErrorReset: PropTypes.func,
    onStatusReset: PropTypes.func,
    onSubmit: PropTypes.func,
    open: PropTypes.bool,
    peopleById: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string,
        imageUrl: PropTypes.string,
      }),
    ),
    status: PropTypes.objectOf(PropTypes.bool),
    submitLabel: PropTypes.string,
  };

  static defaultProps = {
    error: {},
    hasConfirmation: false,
    hasError: false,
    hasFailed: false,
    hasFieldIsPublic: false,
    hasFieldPeople: false,
    inDialog: false,
    initialValues: INITIAL_VALUES,
    isPending: false,
    onCancel: global.noop,
    onErrorReset: global.noop,
    onStatusReset: global.noop,
    onSubmit: global.noop,
    open: false,
    peopleById: {},
    status: undefined,
    submitLabel: 'Submit',
  };

  static MAX_NUM_CONFIRMATION_PEOPLE = MAX_NUM_CONFIRMATION_PEOPLE;

  state = {
    hasCustomMessage: false,
    isConfirmingSubmit: false,
  };

  // getDefaultMessage(formikProps) {
  //   if (this.currentUser.id) {
  //     const numOfChests = Object.values(formikProps.values.chests).reduce((total, current) => {
  //       total += current; // eslint-disable-line no-param-reassign

  //       return total;
  //     }, 0);

  //     return `Congratulations to ${this.currentUser.name}! You have earned ${numOfChests} chests`;
  //   }

  //   return '';
  // }

  setHasCustomMessage = hasCustomMessage => this.setState({ hasCustomMessage });

  setIsConfirmingSubmit = isConfirmingSubmit =>
    this.setState({ isConfirmingSubmit });

  hasSubmitted = false;

  handleConfirmingSubmitCancel = () => this.setIsConfirmingSubmit(false);

  handleToggleEditMessage = () =>
    this.setHasCustomMessage(!this.state.hasCustomMessage);

  renderForm = formikProps => {
    const { people, reason } = formikProps.values;

    const peopleWithData = people?.map(id => {
      return this.props.peopleById[id];
    });

    const peopleHasError = this.props.hasFieldPeople
      ? !people || people.length === 0
      : false;

    const chestHasError = getTotalNumOfChests(formikProps) === 0;

    const reasonHasError = !reason && true;

    const hasError = peopleHasError || chestHasError;
    const isTouched = formikProps.touched.chests || formikProps.touched.people;

    const submitDisabled = hasError && isTouched && this.hasSubmitted;

    return (
      <ArcView data-testid="ArcAwardChestForm-Form">
        <ArcView padding={this.props.inDialog ? '16' : undefined}>
          {this.props.hasFieldPeople && (
            <ArcView marginBottom="24">
              <ArcPeopleMultiSelect
                error={formikProps.touched.people && peopleHasError}
                onChange={value => {
                  const userIds = value?.map(person => Number(person.id));
                  formikProps.setFieldValue('people', userIds);
                  formikProps.setFieldTouched('people', true);
                }}
                value={peopleWithData}
                options={Object.values(this.props.peopleById).map(person => ({
                  ...person,
                  value: person.id,
                  label: person.name,
                }))}
                placeholder={
                  formikProps.touched.people && peopleHasError
                    ? 'Select at least one person'
                    : 'Select people'
                }
              />
            </ArcView>
          )}

          {chestHasError && formikProps.touched.chests && (
            <ArcView marginBottom="16">
              <ArcText color="danger">{'Select at least one chest'}</ArcText>
            </ArcView>
          )}

          <ArcFormField
            autoComplete="off"
            id="chests"
            type="chest-select"
            name="chests"
            fullWidth
            hasLabel={false}
            label={'Chest'}
            onChange={chests => {
              formikProps.setFieldTouched('chests', true);
              formikProps.setFieldValue('chests', chests);
            }}
            value={formikProps.values.chests}
            isTouched={formikProps.touched.chests}
            min={0}
            max={99}
            disabled={this.props.isPending}
            error={chestHasError}
          />

          <ArcText marginBottom="8">
            {STRINGS['UI/AWARD_CHEST_FORM_INTERNAL_REASON_HEADER']}
          </ArcText>
          {reasonHasError && formikProps.touched.reason && (
            <ArcView marginBottom="16">
              <ArcText color="danger">{'Please provide a reason'}</ArcText>
            </ArcView>
          )}
          <ArcFormField type="text" multiline name="reason" label="Reason" />
          <ArcView marginBottom="8">
            <ArcText color="disabled">
              {STRINGS['UI/AWARD_CHEST_FORM_INTERNAL_REASON_TEXT']}
            </ArcText>
          </ArcView>

          {this.props.hasFieldIsPublic && (
            <ArcFormField
              type="checkbox"
              name="isPublic"
              label="Announce this to the newsfeed"
            />
          )}

          {formikProps.values.isPublic && (
            <React.Fragment>
              <ArcText marginBottom="8">
                {STRINGS['UI/AWARD_CHEST_FORM_MESSAGE_HEADER']}
              </ArcText>
              <ArcFormField
                type="text"
                name="message"
                label="Message"
                multiline
              />
              <ArcView marginBottom="8">
                <ArcText color="disabled">
                  {STRINGS['UI/AWARD_CHEST_FORM_MESSAGE_TEXT']}
                </ArcText>
              </ArcView>
            </React.Fragment>
          )}
        </ArcView>

        <ArcView row justify="flex-end" padding="4">
          <ArcView padding="4">
            <ArcButton onClick={this.props.onCancel} label="Cancel" />
          </ArcView>
          <ArcView padding="4">
            <ArcResourceButton
              disabled={submitDisabled}
              type="submit"
              onClick={evt => {
                evt.preventDefault();
                if (hasError) {
                  this.hasSubmitted = true;

                  formikProps.setFieldTouched('chests', true);
                  formikProps.setFieldTouched('people', true);
                } else if (this.props.hasConfirmation) {
                  this.setIsConfirmingSubmit(true);
                } else {
                  formikProps.handleSubmit();
                }
              }}
              label={this.props.submitLabel}
              color="primary"
              variant="contained"
              status={this.props.status}
            />
          </ArcView>
        </ArcView>

        {this.props.hasConfirmation && (
          <ArcAwardChestFormConfirmationDialog
            chests={formikProps.values.chests}
            maxPeople={MAX_NUM_CONFIRMATION_PEOPLE}
            open={this.state.isConfirmingSubmit}
            onClose={this.handleConfirmingSubmitCancel}
            onConfirm={() => {
              this.setIsConfirmingSubmit(false);

              formikProps.handleSubmit();
            }}
            people={peopleWithData}
          />
        )}
      </ArcView>
    );
  };

  render() {
    const { inDialog } = this.props;

    const title = inDialog ? (
      <ArcView
        position="relative"
        row
        padding="8"
        paddingLeft="16"
        align="flex-end"
        color="grey-tint"
        borderBottom="default"
        borderBottomWidth="1"
      >
        <Typography variant="h5">{'Award Chest'}</Typography>

        <ArcView spacer />

        <ArcButton size="small" onClick={this.props.onCancel}>
          <Close color="action" fontSize="small" />
        </ArcButton>
      </ArcView>
    ) : null;

    const form = (
      <>
        <ArcView flexGrow="10" flexShrink="10" fullHeight>
          {title}
          <ArcForm
            initialValues={{
              ...INITIAL_VALUES,
              ...this.props.initialValues,
            }}
            onSubmit={this.props.onSubmit}
          >
            {this.renderForm}
          </ArcForm>
        </ArcView>

        <ArcErrorDialog
          open={this.props.hasFailed}
          onClose={this.props.onStatusReset}
          onConfirm={this.props.onStatusReset}
          onExited={this.props.onErrorReset}
          content={this.props.hasError ? this.props.error.message : undefined}
        />
      </>
    );

    if (inDialog) {
      return (
        <ArcResponsiveDialog
          onClose={this.props.onCancel}
          open={this.props.open}
        >
          {form}
        </ArcResponsiveDialog>
      );
    }
    return form;
  }
}

export default ArcAwardChestForm;
