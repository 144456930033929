import React from 'react';
import PropTypes from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withResourceAction from 'arcade-frontend-core/src/helpers/withResourceAction';
import { getAppPeopleById } from 'arcade-frontend-core/src/reducers/peopleById';
import { actions } from 'arcade-frontend-core/src/actions';
import { resources } from 'arcade-frontend-core/src/resources/users';

import ArcAwardChestForm from '../../components/ArcAwardChestForm';

class ArcAwardChestFormContainer extends React.PureComponent {
  static displayName = 'ArcAwardChestFormContainer';

  static propTypes = {
    appTaggablePeopleIndexRequest: PropTypes.func,
    onCancel: PropTypes.func,
    onStatusReset: PropTypes.func,
    peopleById: PropTypes.objectOf(PropTypes.object),
    requestData: PropTypes.func,
  };

  static defaultProps = {
    appTaggablePeopleIndexRequest: global.noop,
    onCancel: global.noop,
    onStatusReset: global.noop,
    peopleById: {},
    requestData: global.noop,
  };

  constructor(props) {
    super(props);

    if (!Object.keys(props.peopleById).length) {
      this.props.appTaggablePeopleIndexRequest();
    }
  }

  handleSubmit = values => {
    const data = {
      award: {
        chests: values.chests,
        user_ids: values.people,
        is_public: values.isPublic,
        message: values.message,
        internal_reason: values.reason,
      },
    };

    return this.props
      .requestData({
        resources: [data],
      })
      .then(this.props.onStatusReset)
      .then(this.props.onCancel);
  };

  render() {
    const { appTaggablePeopleIndexRequest, ...props } = this.props;

    return <ArcAwardChestForm {...props} onSubmit={this.handleSubmit} />;
  }
}

const getState = (state, props) => ({
  peopleById: getAppPeopleById(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      appTaggablePeopleIndexRequest: actions.appTaggablePeopleIndexRequest,
    },
    dispatch,
  );

export default compose(
  connect(getState, getActions),
  // withResourceAction(resources.apiPostUserChests, { shouldDispatch: false }),
  withResourceAction(resources.apiPostUserChests),
)(ArcAwardChestFormContainer);
