import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Phone';

import { ArcFormField, ArcView, ArcText } from 'arcade-frontend-ui';

const subtitleStyle = {
  fontSize: '1rem',
  fontWeight: '400',
};

class ArcProfileFormStepContact extends React.PureComponent {
  render() {
    return (
      <ArcView>
        <ArcView color="blue" padding="24">
          <ArcView marginTop="16">
            <Typography variant="h4">
              <ArcText color="white">{'Contact Details'}</ArcText>
            </Typography>
          </ArcView>
        </ArcView>
        <ArcView padding="24">
          <ArcView marginBottom="16">
            <ArcView marginBottom="16">
              <Typography variant="h5">{'What is your name?'}</Typography>
            </ArcView>

            <ArcFormField
              name="firstname"
              type="text"
              label="First Name"
              validations={{
                isRequired: true,
              }}
            />

            <ArcFormField
              name="lastname"
              type="text"
              label="Last Name"
              validations={{
                isRequired: true,
              }}
            />
          </ArcView>

          <ArcView marginBottom="16">
            <ArcView marginBottom="16">
              <Typography variant="h5">{'Add your phone'}</Typography>
              <Typography variant="caption" style={subtitleStyle}>
                {'So others can contact you.'}
              </Typography>
            </ArcView>

            <ArcFormField
              name="contact_number"
              type="tel"
              label="Phone"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ArcView>
        </ArcView>
      </ArcView>
    );
  }
}

export default ArcProfileFormStepContact;
