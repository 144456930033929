import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const Send = props => (
  <Icon {...props}>
    <Path
      fill="currentColor"
      d="M23.854.2A.5.5 0 0023.3.091l-23 9.9a.5.5 0 00-.036.9l6.67 3.53a.248.248 0 00.276-.02L17.5 6.1a.25.25 0 01.352.351l-8.3 10.29a.249.249 0 00-.026.274l3.531 6.67a.5.5 0 00.442.266h.019a.501.501 0 00.439-.3l10-22.9A.5.5 0 0023.854.2z"
    />
  </Icon>
);

export default Send;
