import { makeApiActionTypes } from 'arcade-frontend-ui';

export const MANAGE_REWARDS_CREATE = makeApiActionTypes('MANAGE_REWARDS_CREATE');
export const MANAGE_REWARDS_EDIT = makeApiActionTypes('MANAGE_REWARDS_EDIT');
export const MANAGE_REWARDS_ARCHIVE = makeApiActionTypes('MANAGE_REWARDS_ARCHIVE');
export const MANAGE_REWARDS_RESTORE = makeApiActionTypes('MANAGE_REWARDS_RESTORE');
export const MANAGE_REWARDS_CUSTOM_ARCHIVE = makeApiActionTypes('MANAGE_REWARDS_CUSTOM_ARCHIVE');
export const MANAGE_REWARDS_CUSTOM_RESTORE = makeApiActionTypes('MANAGE_REWARDS_CUSTOM_RESTORE');
export const MANAGE_REWARDS_INDEX = makeApiActionTypes('MANAGE_REWARDS_INDEX');
export const MANAGE_REWARDS_CUSTOM_INDEX = makeApiActionTypes('MANAGE_REWARDS_CUSTOM_INDEX');
