import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconStar from '@material-ui/icons/StarBorderRounded';
import IconChat from '@material-ui/icons/ChatBubbleOutline';

import CallAt from 'arcade-frontend-ui/src/icons/CallAt';
import ChestOutline from 'arcade-frontend-ui/src/icons/ChestOutline';
import {
  ArcButton,
  ArcView,
  ArcGrid,
  ArcText,
  ArcTokenOutlined,
  createWithStyles,
  ArcStyles,
} from 'arcade-frontend-ui';
import ArcAwardChestFormContainer from 'arcade-frontend-widgets/src/containers/ArcAwardChestFormContainer';
import ArcAwardTokenFormContainer from 'arcade-frontend-widgets/src/containers/ArcAwardTokenFormContainer';

import { usePersonActions } from '../../hooks/usePersonActions';

const styles = {
  ActionButton: theme => ({
    root: {
      height: theme.spacing(6),
      width: theme.spacing(8),
      margin: theme.spacing(1),
      backgroundColor: 'white',
    },
  }),
  ActionText: theme => ({
    root: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      textAlign: 'center',
      color: ArcStyles.theme.arcTextColor,
    },
  }),
};

const ActionButton = createWithStyles(styles.ActionButton)(ArcButton);
const ActionText = createWithStyles(styles.ActionText)(ArcText);

function PersonDetailsActionButtons({
  canAwardChests,
  canAwardTokens,
  id,
  onClose,
  phoneNumber,
}) {
  const {
    navigateAwardToken,
    navigateCall,
    navigateChat,
    isPhoneNumberValid,
  } = usePersonActions();

  const [awardChestOpen, setAwardChestOpen] = useState(false);
  const [awardTokenOpen, setAwardTokenOpen] = useState(false);

  const handleAwardChestClick = () => setAwardChestOpen(true);

  const handleAwardChestCancel = () => setAwardChestOpen(false);

  const handleAwardTokenClick = () => setAwardTokenOpen(true);
  const handleAwardTokenCancel = () => setAwardTokenOpen(false);

  const handleClickRecognize = event => {
    navigateAwardToken(event, id);
    onClose();
  };

  const handleClickChat = event => {
    navigateChat(event, id);
    onClose();
  };

  const handleCall = () => {
    navigateCall(phoneNumber);
    onClose();
  };

  const initialAwardFormValues = () => ({
    people: [Number(id)],
  });

  return (
    <React.Fragment>
      <ArcGrid container justify="center" style={{ width: 200 }}>
        <ArcGrid item xs={6}>
          <ArcView align="center">
            <ActionButton
              variant="outlined"
              color="primary"
              onClick={handleClickRecognize}
            >
              <IconStar />
            </ActionButton>
            <ActionText>{'Recognize'}</ActionText>
          </ArcView>
        </ArcGrid>

        <ArcGrid item xs={6}>
          <ArcView align="center">
            <ActionButton
              variant="outlined"
              color="secondary"
              onClick={handleClickChat}
            >
              <IconChat style={{ height: 18 }} />
            </ActionButton>
            <ActionText>{'Message'}</ActionText>
          </ArcView>
        </ArcGrid>

        {canAwardTokens && (
          <React.Fragment>
            <ArcGrid item xs={6}>
              <ArcView align="center">
                <ActionButton
                  variant="outlined"
                  color="warning"
                  onClick={handleAwardTokenClick}
                >
                  <ArcView>
                    <ArcTokenOutlined color="#FFAE14" />
                  </ArcView>
                </ActionButton>
                <ActionText>{'Give Tokens'}</ActionText>
              </ArcView>
            </ArcGrid>
            {awardTokenOpen && (
              <ArcAwardTokenFormContainer
                open
                onCancel={handleAwardTokenCancel}
                initialValues={initialAwardFormValues()}
                hasConfirmation
                hasFieldIsPublic
                inDialog
                hasFieldPeople
              />
            )}
          </React.Fragment>
        )}

        {canAwardChests && (
          <React.Fragment>
            <ArcGrid item xs={6}>
              <ArcView align="center">
                <ActionButton
                  variant="outlined"
                  color="purple"
                  onClick={handleAwardChestClick}
                >
                  <ArcView>
                    <ChestOutline color="inherit" />
                  </ArcView>
                </ActionButton>
                <ActionText>{'Give Chests'}</ActionText>
              </ArcView>
            </ArcGrid>

            <ArcAwardChestFormContainer
              hasConfirmation
              hasFieldIsPublic
              hasFieldPeople
              inDialog
              initialValues={initialAwardFormValues()}
              onCancel={handleAwardChestCancel}
              open={awardChestOpen}
            />
          </React.Fragment>
        )}

        {isPhoneNumberValid(phoneNumber) && (
          <ArcGrid item xs={6}>
            <ArcView align="center">
              <ActionButton
                variant="outlined"
                color="info"
                onClick={() => handleCall(phoneNumber)}
              >
                <ArcView>
                  <CallAt />
                </ArcView>
              </ActionButton>
              <ActionText>Call</ActionText>
            </ArcView>
          </ArcGrid>
        )}
      </ArcGrid>
    </React.Fragment>
  );
}

PersonDetailsActionButtons.displayName = 'PersonDetailsActionButtons';

PersonDetailsActionButtons.propTypes = {
  canAwardChests: PropTypes.bool,
  canAwardTokens: PropTypes.bool,
  id: PropTypes.string,
  onClose: PropTypes.func,
  phoneNumber: PropTypes.string,
};

PersonDetailsActionButtons.defaultProps = {
  canAwardChests: false,
  canAwardTokens: false,
  id: null,
  onClose: global.noop,
  phoneNumber: null,
};

export default PersonDetailsActionButtons;
