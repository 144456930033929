import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getLocationQuery } from 'arcade-frontend-features/src/reducers/location';
import { capitalize } from 'arcade-frontend-ui/src/styles/createWithStyles/common';
import useResourceAction, {
  INITIAL_STATUS,
} from 'arcade-frontend-core/src/hooks/useResourceAction';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import { BOUNTY } from 'arcade-frontend-core/src/types/game-formats';

import LegacyGamesEventsTable from '../../components/GamesEventsTable/LegacyGamesEventsTable';
import GamesEventUndoBar from '../../components/GamesEventUndoBar';
import GamesEventsMenu from '../../components/GamesEventsMenu';
import { resources } from '../../resources/game-events';
import {
  getGameEvents,
  getGameEventsUpdateStatusById,
  getGameEventsRevertStatus,
} from '../../reducers/game-events';

const EVENT_UPDATE_TIMEOUT = 2000;

function GamesEventsTableContainer({ gameId, ...props }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const data = useSelector(getGameEvents(gameId));

  const locationQuery = useSelector(getLocationQuery);

  const apiGetGameEvents = useResourceAction(resources.apiGetGameEvents, {
    treatIdleAsPending: true,
    getInitialData: getGameEvents(gameId),
  });

  function requestData() {
    return apiGetGameEvents
      .requestData({
        requestKey: `game-${gameId}`,
        meta: { gameId },
      })
      .then(resp => {
        const action = apiGetGameEvents.succeeded({
          requestKey: `game-${gameId}`,
          list: gameId,
          resources: resp.data.resources,
        });

        dispatch(action);
      })
      .catch(global.noop);
  }

  useEffect(() => {
    if (!gameId) {
      return;
    }

    requestData();
  }, [gameId, locationQuery.form]);

  const [page, setPage] = useState(0);

  function handleChangePage(_, nextPage) {
    setPage(nextPage);
  }

  function handleRetry() {
    apiGetGameEvents.onStatusReset();
    requestData().catch(global.noop);
  }

  const apiApproveGameEvent = useResourceAction(resources.apiApproveGameEvent);
  const apiRejectGameEvent = useResourceAction(resources.apiRejectGameEvent);
  const apiRevertGameEvent = useResourceAction(resources.apiRevertGameEvent);

  const eventIds = data.map(event => event.id);
  const eventAproveUpdateStatus = useSelector(
    getGameEventsUpdateStatusById(eventIds, 'approve'),
  );
  function getApproveUpdateStatus(id) {
    return eventAproveUpdateStatus[id] || INITIAL_STATUS;
  }
  const eventRevertStatus = useSelector(getGameEventsRevertStatus);

  const [lastRevertableAction, setLRA] = useState({ verb: '', eventId: '' });
  const [showUndo, setShowUndo] = useState(false);

  function approveEvent(eventId) {
    apiRevertGameEvent.onStatusReset();

    apiApproveGameEvent
      .requestData({
        requestKey: `approve${eventId}`,
        meta: { gameId, eventId },
      })
      .then(resp => {
        setLRA({ verb: 'verified', eventId });
        setShowUndo(true);
        requestData().catch(global.noop);

        const action = apiApproveGameEvent.succeeded({
          requestKey: `approve${eventId}`,
          resources: resp.data.resources,
        });
        dispatch(action);
      })
      .then(() =>
        setTimeout(() => {
          apiApproveGameEvent.onStatusReset();
        }, EVENT_UPDATE_TIMEOUT),
      )
      .catch(global.noop);
  }

  function revertEvent(eventId) {
    return apiRevertGameEvent
      .requestData({
        requestKey: `revert${eventId}`,
        meta: { gameId, eventId },
      })
      .then(resp => {
        requestData().catch(global.noop);
        setShowUndo(false);
        const action = apiRevertGameEvent.succeeded({
          requestKey: `revert${eventId}`,
          resources: resp.data.resources,
        });

        dispatch(action);
      })
      .catch(global.noop);
  }

  function rejectEvent(eventId) {
    apiRevertGameEvent.onStatusReset();

    apiRejectGameEvent
      .requestData({
        requestKey: `reject${eventId}`,
        meta: { gameId, eventId },
      })
      .then(resp => {
        setLRA({ verb: 'rejected', eventId });
        setShowUndo(true);
        requestData().catch(global.noop);

        const action = apiRejectGameEvent.succeeded({
          requestKey: `reject${eventId}`,
          resources: resp.data.resources,
        });
        dispatch(action);
      })
      .then(() =>
        setTimeout(() => {
          apiRevertGameEvent.onStatusReset();
        }, EVENT_UPDATE_TIMEOUT),
      )
      .catch(global.noop);
  }

  const undoRevertableAction = () => {
    revertEvent(lastRevertableAction.eventId);
  };

  const closeUndoBar = () => {
    apiRevertGameEvent.onStatusReset();
    setShowUndo(false);
  };

  const [menuEventId, setMenuEventId] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const openMenu = id => {
    setMenuEventId(id);
    setMenuOpen(true);
  };

  const handleMenuClose = () => setMenuOpen(false);
  const menuEvent = data.find(event => event.id === menuEventId);

  const handleAction = (action, id) => {
    switch (action) {
      case 'verify':
        return approveEvent(id);
      case 'reject':
        return rejectEvent(id);
      case 'revert':
        return revertEvent(id);
      case 'menu':
        return openMenu(id);
      default:
        return null;
    }
  };

  const handleMenuAction = action => {
    handleMenuClose();
    handleAction(action, menuEventId);
  };

  if (!apiGetGameEvents.hasData && apiGetGameEvents.isPending) {
    return <LegacyGamesEventsTable.Placeholder />;
  }

  return (
    <>
      <GamesEventsMenu
        open={menuOpen}
        onClose={handleMenuClose}
        onAction={handleMenuAction}
        status={menuEvent && menuEvent.status}
      />
      <LegacyGamesEventsTable
        gameId={gameId}
        currentUserId={currentUser.id}
        data={data}
        hasFailed={apiGetGameEvents.hasFailed}
        onChangePage={handleChangePage}
        onRetry={handleRetry}
        page={page}
        total={data.length}
        onEventAction={handleAction}
        getUpdateStatus={getApproveUpdateStatus}
        {...props}
      />
      <GamesEventUndoBar
        open={showUndo}
        message={`Event ${capitalize(lastRevertableAction.verb)}`}
        onUndo={undoRevertableAction}
        onClose={closeUndoBar}
        updateStatus={eventRevertStatus}
      />
    </>
  );
}

GamesEventsTableContainer.displayName = 'GamesEventsTableContainer';

GamesEventsTableContainer.propTypes = {
  gameId: PropTypes.string,
  type: PropTypes.string,
  isManager: PropTypes.bool,
};

GamesEventsTableContainer.defaultProps = {
  gameId: null,
  type: BOUNTY,
  isManager: false,
};

export default GamesEventsTableContainer;
