import React from 'react';
import PropTypes from 'prop-types';

import * as GAME_FORMATS from 'arcade-frontend-core/src/types/game-formats';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';

function GamesGameCardMetricGoalText({
  metric,
  goal,
  rewards,
  type,
  ...props
}) {
  let title = metric;

  if (goal) {
    title = `${goal} ${metric}`;
  }

  if (type === GAME_FORMATS.RACE) {
    title = `First three for ${metric}`;
  } else if (type === GAME_FORMATS.TOURNAMENT) {
    title = `Top ${rewards.length} for ${metric}`;
  }

  return <ArcText {...props}>{title}</ArcText>;
}

GamesGameCardMetricGoalText.displayName = 'GamesGameCardMetricGoalText';

GamesGameCardMetricGoalText.propTypes = {
  metric: PropTypes.string,
  goal: PropTypes.number,
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ),
  type: PropTypes.oneOf(GAME_FORMATS.GAME_TYPES),
};

GamesGameCardMetricGoalText.defaultProps = {
  metric: '',
  goal: null,
  rewards: [],
  type: null,
};

export default GamesGameCardMetricGoalText;
