import React from 'react';

import ArcPropTypes from '../../helpers/arc/propTypes';

const CalendarRecurring = ({ isRecurring = true, ...props }) => (
  <svg
    height="24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    {isRecurring ? (
      <path
        fill="currentColor"
        d="M48.351 69.349c-2.808-6.307-1.504-13.504 3.322-18.333 4.828-4.826 12.029-6.131 18.34-3.323.06.026.122.037.182.06l-3.092 2.426a2.97 2.97 0 0 0 3.666 4.672l7.837-6.148a2.969 2.969 0 0 0 1.074-2.942l-2.039-9.784a2.97 2.97 0 1 0-5.815 1.212l.942 4.52c-.026-.013-.048-.031-.074-.042-8.826-3.927-18.906-2.089-25.686 4.685-6.773 6.778-8.611 16.858-4.683 25.681a3.297 3.297 0 0 0 6.092-.16 3.298 3.298 0 0 0-.066-2.524zm36.003-16.022a3.298 3.298 0 0 0-6.028 2.678c2.805 6.313 1.5 13.511-3.325 18.335-4.729 4.729-11.729 6.069-17.945 3.482l3.086-2.42a2.97 2.97 0 1 0-3.666-4.672l-7.839 6.149a2.966 2.966 0 0 0-1.074 2.941l2.038 9.784a2.97 2.97 0 0 0 5.814-1.211l-.938-4.499a23.328 23.328 0 0 0 9.021 1.837c5.951 0 11.759-2.319 16.168-6.729 6.771-6.768 8.611-16.847 4.688-25.675z"
        fillRule="nonzero"
      />
    ) : (
      <path fill="currentColor" d="M44 82.11v-5.883h11.742V52.742H49.86V46.86h5.883V41h11.742v35.227h11.743v5.882z" />
    )}
    <path
      fill="currentColor"
      d="M20.082 29.459H68.15v.007h5.15V18.112a3.444 3.444 0 0 0-3.437-3.434H62.14v-2.544A3.146 3.146 0 0 0 59.005 9h-2.312a3.144 3.144 0 0 0-3.135 3.134v2.544H34.673v-2.544A3.144 3.144 0 0 0 31.538 9h-2.312a3.147 3.147 0 0 0-3.135 3.134v2.544h-7.724a3.444 3.444 0 0 0-3.434 3.434v51.496a3.446 3.446 0 0 0 3.434 3.437h16.127v-5.819H20.082V29.459z"
      fillRule="nonzero"
    />
  </svg>
);

CalendarRecurring.propTypes = {
  isRecurring: ArcPropTypes.bool,
};

CalendarRecurring.defaultProps = {
  isRecurring: true,
};

export default CalendarRecurring;
