import {
  ArcStyles,
} from 'arcade-frontend-ui';

export const styleProgressContainer = {
  maxWidth: '400px',
  marginLeft: 8,
};

export const styleProgressIndicator = {
  height: '56px',
  minWidth: '64px',
  padding: '1px 8px',
  background: ArcStyles.theme.grey7,
  border: `solid 4px ${ArcStyles.theme.grey6}`,
  borderRadius: '8px',
};

export const styleProgressIndicatorText = {
  fontSize: '20px',
  fontWeight: '500',
  margin: 0,
  color: ArcStyles.theme.white,
  textShadow: 'rgba(0, 0, 0, 0.28) 1px 1px 0px',
  '& > span': {
    fontWeight: '300',
    fontSize: 14,
  },
};
