import { createReducer } from 'arcade-frontend-ui';
import { getStateById } from 'arcade-frontend-ui/src/helpers/utils/reducer';
import { createSelector } from 'reselect';

import { types } from '../actions/uploadManager';

const initialState = {
  byId: {},
  mine: [],
  shared: [],
  loading: false,
  hasMore: false,
  sharedHasMore: false,
};

const handleVideoLoadStart = state => ({ ...state, loading: true });

const handleVideoLoadSuccess = (state, action) => ({
  ...state,
  loading: false,
  hasMore: action.payload.raw.data.has_more_resources,
  mine: action.payload.data.map(video => video.id),
  byId: {
    ...state.byId,
    ...getStateById(action.payload.data),
  },
});

const handleSharedVideoLoadSuccess = (state, action) => ({
  ...state,
  loading: false,
  sharedHasMore: action.payload.raw.data.has_more_resources,
  shared: action.payload.data.map(video => video.id),
  byId: {
    ...state.byId,
    ...getStateById(action.payload.data),
  },
});

const handleVideoLoadDelete = (state, action) => {
  const { id } = action.payload;

  if (state.byId[id]) {
    const nextState = {
      ...state,
      byId: {
        ...state.byId,
      },
      shared: state.shared.filter(sharedId => sharedId !== id),
      mine: state.mine.filter(mineId => mineId !== id),
    };

    delete nextState.byId[id];

    return nextState;
  }

  return state;
};

const handlers = {
  [types.UPLOAD_MANAGER_GET_MY_UPLOADS.REQUEST]: handleVideoLoadStart,
  [types.UPLOAD_MANAGER_GET_MY_UPLOADS.SUCCESS]: handleVideoLoadSuccess,
  [types.UPLOAD_MANAGER_GET_SHARED_UPLOADS.REQUEST]: handleVideoLoadStart,
  [types.UPLOAD_MANAGER_GET_SHARED_UPLOADS
    .SUCCESS]: handleSharedVideoLoadSuccess,
  [types.UPLOAD_MANAGER_DELETE_UPLOAD.REQUEST]: handleVideoLoadDelete,
};

export default createReducer(initialState, handlers);

export const getVideosById = state => state.videos.byId;

export const getVideosMine = createSelector(
  getVideosById,
  state => state.videos.mine,
  (byId, mine) => mine?.map(id => byId[id]),
);

export const getVideosShared = createSelector(
  getVideosById,
  state => state.videos.shared,
  (byId, shared) => shared?.map(id => byId[id]),
);

export const uploadedVideosUpdating = state => state.videos.loading;

export const getVideosIsLoading = state => state.videos.loading;
export const getVideosHasMore = state => state.videos.hasMore;
export const getSharedVideosHasMore = state => state.videos.sharedHasMore;
