import React from 'react';

import ArcProgress from '../ArcProgress';
import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import ArcStyles, { createWithStyles } from '../../styles';

import ArcChecklistItem from './ArcChecklistItem';
import ArcChecklistReward from './ArcChecklistReward';
import ArcChecklistTitle from './ArcChecklistTitle';

const styleProgress = {
  width: '100%',
  padding: 0,
  bottom: 0,
};

const calcPercent = (items) => {
  const { length } = items;
  const checked = items.filter(item => item.checked).length;

  return (checked / length) * 100;
};

const styles = {
  Container: theme => ({
    root: {
      overflow: 'hidden',
      textAlign: 'left',
      maxHeight: 'calc(100% - 8px)',
      background: theme.palette.background.paper,
    },
  }),

  ScrollContainer: () => ({
    root: {
      ...ArcStyles.rules.scroll,
      flexShrink: 1,
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);
const ScrollContainer = createWithStyles(styles.ScrollContainer)(ArcView);

/** **Description**
  *
  * Renders a list of `<ArcChecklistItem />`'s with a `<ArcChecklistTitle />` and `<ArcChecklistReward />`
  *
*/

class ArcChecklist extends React.PureComponent {
  static displayName = 'ArcChecklist';

  static propTypes = {
    items: ArcPropTypes.arrayOf(ArcPropTypes.shape({
      checked: ArcPropTypes.bool.isRequired,
      value: ArcPropTypes.string.isRequired,
      label: ArcPropTypes.string.isRequired,
      iconType: ArcPropTypes.string.isRequired,

      disabled: ArcPropTypes.bool,
      labelInfo: ArcPropTypes.string,
    })),
    onClose: ArcPropTypes.func,
    onClickInfo: ArcPropTypes.func,
    onClickNavigation: ArcPropTypes.func,
    onClaimReward: ArcPropTypes.func,
    onClickItem: ArcPropTypes.func,
    hasClose: ArcPropTypes.bool,
    hasProgress: ArcPropTypes.bool,
    hasTitle: ArcPropTypes.bool,
    labelPlaceholder: ArcPropTypes.string,
    title: ArcPropTypes.string,
    reward: ArcPropTypes.shape({
      type: ArcPropTypes.string,
      value: ArcPropTypes.string,
    }),
  };

  static defaultProps = {
    items: [],
    onClose: ArcPropTypes.noop,
    onClickInfo: ArcPropTypes.noop,
    onClickNavigation: ArcPropTypes.noop,
    onClaimReward: ArcPropTypes.noop,
    onClickItem: ArcPropTypes.noop,
    hasClose: false,
    hasProgress: true,
    hasTitle: true,
    labelPlaceholder: null,
    title: 'Checklist',
    reward: {
      type: null,
      value: null,
    },
  };

  state = {
    percent: calcPercent(this.props.items),
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items) {
      this.setState({
        percent: calcPercent(nextProps.items),
      });
    }
  }

  handleClick = (e) => {
    e.stopPropagation();
  };

  handleClickCheckbox = (e, value, checked) => {
    const newItems = this.props.items.map((item) => {
      if (item.value === value) {
        return {
          ...item,
          checked,
        };
      }

      return item;
    });

    this.setState({
      percent: calcPercent(newItems),
    });
  };

  handleClaimReward = () => {
    this.props.onClaimReward();
    this.props.onClose();
  }

  renderTitle() {
    return (
      <ArcChecklistTitle
        title={this.props.title}
        percent={this.state.percent}
        hasClose={this.props.hasClose}
        onClose={this.props.onClose}
      />
    );
  }

  renderProgress() {
    return (
      <ArcProgress
        height={4}
        style={styleProgress}
        percent={this.state.percent}
      />
    );
  }

  renderReward() {
    const { reward } = this.props;

    return (
      <ArcChecklistReward
        value={reward.value}
        progress={this.state.progress}
        onClaim={this.handleClaimReward}
      />
    );
  }

  renderChecklistItems() {
    return this.props.items.map(item => (
      <ArcChecklistItem
        key={item.value}
        {...item}
        onClose={this.props.onClose}
        onClickCheckbox={this.handleClickCheckbox}
        onClickInfo={this.props.onClickInfo}
        onClickNavigation={this.props.onClickNavigation}
        onClickItem={this.props.onClickItem}
      />
    ));
  }

  renderPlaceholder() {
    return this.props.labelPlaceholder;
  }

  renderItems() {
    return (
      <ArcView>
        {this.props.items.length
          ? this.renderChecklistItems()
          : this.renderPlaceholder()
        }
      </ArcView>
    );
  }

  render() {
    const { reward } = this.props;

    return (
      <Container onClick={this.handleClick}>
        {this.props.hasTitle && this.renderTitle()}
        {this.props.hasProgress && this.renderProgress()}
        <ScrollContainer>
          {this.renderItems()}
        </ScrollContainer>
        {reward && reward.value && this.renderReward()}
      </Container>
    );
  }
}

export default ArcChecklist;
