import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ArcView, ArcText, createWithStyles } from 'arcade-frontend-ui';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';

import ChatSearchResult from '../ChatSearchResult';

const STRINGS = {
  'CHAT/SEARCH_RESULTS_NONE_FOUND': 'No Results',
  'CHAT/SEARCH_RESULTS_USE_SEARCH': 'Use the search bar to find conversations',
};

const styles = {
  ResultsWrapper: theme => ({
    root: {
      // borderWidth: 1,
      // borderColor: theme.palette.grey.light,
      // borderStyle: 'solid',
      // borderRadius: theme.spacing(1),
      // backgroundColor: theme.palette.common.white,
    },
  }),
};

const ResultsWrapper = createWithStyles(styles.ResultsWrapper)(ArcView);

function ChatSearchResults({ searchResults, isUpdating, onResultSelected }) {
  const currentUser = useSelector(getCurrentUser);

  if (isUpdating) {
    return (
      <ArcView>
        <ArcView className="shimmer" padding="32" marginBottom="16" />
        <ArcView className="shimmer" padding="32" marginBottom="16" />
        <ArcView className="shimmer" padding="32" marginBottom="16" />
      </ArcView>
    );
  }

  if (searchResults.length === 0) {
    return (
      <ArcView align="center" justify="center" fullHeight>
        <ArcText marginBottom="8" color="grey" textAlign="center">
          {STRINGS['CHAT/SEARCH_RESULTS_NONE_FOUND']}
        </ArcText>
        <ArcText color="grey-500" textAlign="center">
          {STRINGS['CHAT/SEARCH_RESULTS_USE_SEARCH']}
        </ArcText>
      </ArcView>
    );
  }

  return (
    <ResultsWrapper overflow="hidden">
      {searchResults.map(result => (
        <ChatSearchResult
          key={result.id}
          currentUserId={currentUser.id}
          onClick={onResultSelected}
          {...result}
        />
      ))}
    </ResultsWrapper>
  );
}

ChatSearchResults.propTypes = {
  isUpdating: PropTypes.bool,
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
  onResultSelected: PropTypes.func,
};

ChatSearchResults.defaultProps = {
  isUpdating: false,
  searchResults: [],
  onResultSelected: global.noop,
};

export default ChatSearchResults;
