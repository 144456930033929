import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from 'arcade-frontend-core/src/actions';
import { getAppChecklist } from 'arcade-frontend-core/src/reducers/checklist';

import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import { ArcChecklistPortal } from '../../../components/app/ArcChecklist';

class ArcChecklistContainer extends React.Component {
  static propTypes = {
    appChecklistIndexRequest: PropTypes.func.isRequired,
    appRootScopeBroadcast: PropTypes.func.isRequired,
    appChecklistClaimRewardRequest: PropTypes.func.isRequired,
    checklist: PropTypes.shape({
      objectives: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
      })),
    }),
    currentUser: PropTypes.shape({
      id: PropTypes.string,
    }),
    onClickItem: PropTypes.func,
  };

  static defaultProps = {
    checklist: {
      objectives: [],
    },
    currentUser: {},
    onClickItem: global.noop,
  };

  componentDidMount() {
    if (this.props.currentUser.id) {
      this.props.appChecklistIndexRequest();
    }
  }

  handleClickNavigation = (e, value) => {
    const {
      location,
      params,
    } = this.props.checklist.objectives.filter(item => item.value === value)[0];

    this.props.appRootScopeBroadcast({
      event: '$state.go',
      data: {
        location,
        params,
      },
    });
  };

  render() {
    return (
      <ArcChecklistPortal
        checklist={this.props.checklist}
        onClickNavigation={this.handleClickNavigation}
        onOpen={this.props.appChecklistIndexRequest}
        onClaimReward={this.props.appChecklistClaimRewardRequest}
        onClickItem={this.props.onClickItem}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  checklist: getAppChecklist(state),
  onClickItem: ownProps.onClickItem,
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  appChecklistIndexRequest: actions.appChecklistIndexRequest,
  appChecklistClaimRewardRequest: actions.appChecklistClaimRewardRequest,
  appRootScopeBroadcast: actions.appRootScopeBroadcast,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ArcChecklistContainer);
