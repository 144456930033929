const BROADCAST_TYPES = {
  'api/APP_FEATURES_SUCCESS': 'user:features:loaded',
};

const broadcastAction = (key, data) => {
  if (window.ngRootScope) {
    window.ngRootScope.$broadcast(key, data);
  }
};

export default () => next => (action) => {
  const {
    type,
    payload,
  } = action;

  const data = payload ? payload.data : {};

  if (BROADCAST_TYPES[type]) {
    broadcastAction(BROADCAST_TYPES[type], data);
  }

  switch (type) {
    case 'api/REWARDS_SHOP_PURCHASE_SUCCESS':
      window.updateUserBalance(action.payload.data.purchase_amount);
      break;
    default:
      break;
  }

  return next(action);
};
