import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ArcUploadAnchor from '../../components/ArcUploadAnchor';
import { getUploadStatus } from '../../reducers/uploadManager/uploadStatus';
import { createUppyCurrentlyUploadingSelector } from '../../reducers/uppy';
import { actions } from '../../actions/uploadManager';

const getState = (state, props) => ({
  isUploading: createUppyCurrentlyUploadingSelector(state, 'ArcUploadManager'),
  uploadStatus: getUploadStatus(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      onClick: actions.uploadManagerOpened,
    },
    dispatch,
  );

export default connect(getState, getActions)(ArcUploadAnchor);
