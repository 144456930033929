import getTransformedResponseFromResourceAction from 'arcade-frontend-core/src/helpers/getTransformedResponseFromResourceAction';

import * as resources from './games.resources';

import apiGetGamesIndexRaw1 from './raw/apiGetGamesIndexRaw-1.json';
import apiGetActiveGamesIndexRaw1 from './raw/apiGetActiveGamesIndexRaw-1.json';
import apiGetActiveGamesIndexRaw2 from './raw/apiGetActiveGamesIndexRaw-2.json';
import apiGetUpcomingGamesIndexRaw1 from './raw/apiGetUpcomingGamesIndexRaw-1.json';
import apiGetCompletedGamesIndexRaw1 from './raw/apiGetCompletedGamesIndexRaw-1.json';

export const apiGetGamesIndexRaw = apiGetGamesIndexRaw1;
export const apiGetActiveGamesIndexRaw = apiGetActiveGamesIndexRaw1;
export const apiGetActiveGamesIndexMultipleRaw = apiGetActiveGamesIndexRaw2;
export const apiGetCompletedGamesIndexRaw = apiGetCompletedGamesIndexRaw1;
export const apiGetPendingGamesIndexRaw = apiGetGamesIndexRaw1;
export const apiGetUpcomingGamesIndexRaw = apiGetUpcomingGamesIndexRaw1;

const META_PAYLOAD = {
  meta: {
    page: 0,
    rowsPerPage: 5,
  },
};

export const apiGetGamesIndex = getTransformedResponseFromResourceAction(
  resources.apiGetGamesIndex,
  apiGetGamesIndexRaw,
);

export const apiGetActiveGamesIndex = getTransformedResponseFromResourceAction(
  resources.apiGetActiveGamesIndex,
  apiGetActiveGamesIndexRaw,
);

export const apiGetActiveGamesIndexMultiple = getTransformedResponseFromResourceAction(
  resources.apiGetActiveGamesIndex,
  apiGetActiveGamesIndexMultipleRaw,
);

export const apiGetCompletedGamesIndex = getTransformedResponseFromResourceAction(
  resources.apiGetCompletedGamesIndex,
  apiGetCompletedGamesIndexRaw,
  META_PAYLOAD,
);

export const apiGetPendingGamesIndex = getTransformedResponseFromResourceAction(
  resources.apiGetPendingGamesIndex,
  apiGetPendingGamesIndexRaw,
);

export const apiGetUpcomingGamesIndex = getTransformedResponseFromResourceAction(
  resources.apiGetUpcomingGamesIndex,
  apiGetUpcomingGamesIndexRaw,
);
