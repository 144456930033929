import React from 'react';

import {
  Path,
} from 'react-primitives-svg';

import Icon from '../Icon';

const Location = props => (
  <Icon {...props}>
    <Path
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 20v3m11-10.984h-3m-16 0H1M12 20a8 8 0 100-16 8 8 0 000 16zm0-19v3"
    />
  </Icon>
);

export default Location;
