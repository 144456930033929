import React from 'react';
// import ArcPropTypes from '../../helpers/arc/propTypes';

const FinishFlag = props => (
  <svg
    width="24"
    height="35"
    viewBox="0 0 24 35"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  ><g fill="none" fillRule="evenodd"><path fill="#FFD43D" d="M1 1h2v2H1zM1 3h2v2H1zM3 1h2v2H3zM3 3h2v2H3z" /><path fill="#4A4A4A" d="M24 9H12v16h12z" /><path fill="#F3F3F3" d="M20 9h-4v4h4zM24 21h-4v4h4zM24 13h-4v4h4zM20 17h-4v4h4zM16 13h-4v4h4zM16 21h-4v4h4z" /><path fill="#D8D8D8" d="M13 23h1v1h-1zM12 23h1v1h-1zM12 24h1v1h-1z" /><path fill="#FFD43D" d="M4 2h2v2H4zM2 0h2v2H2zM0 2h2v2H0z" /><path fill="#FFAE14" d="M0 2h1v2H0z" /><path fill="#FFCA13" d="M1 3h1v1H1zM2 5h1v1H2zM3 5h1v1H3zM2 4h1v1H2z" /><path fill="#FFAE14" d="M1 4h1v1H1z" /><path fill="#FFD43D" d="M2 7h2v28H2z" /><path fill="#FFAE14" d="M2 23h1v12H2zM2 6h2v2H2z" /><g><path fill="#4A4A4A" d="M14 7H2v16h12z" /><path fill="#FFF" d="M10 7H6v4h4zM10 15H6v4h4zM14 11h-4v4h4zM14 19h-4v4h4z" /><path fill="#FFF" d="M10 15H6v4h4zM6 11H2v4h4zM6 19H2v4h4z" /></g><path fill="#000" d="M2 7h1v4H2zM2 15h1v4H2z" /><path fill="#D8D8D8" d="M2 11h1v4H2zM2 19h1v4H2z" /></g>
  </svg>
);

FinishFlag.propTypes = {
};

FinishFlag.defaultProps = {
};

export default FinishFlag;
