import React from 'react';
import PropTypes from 'prop-types';

import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';

import NotificationsPanelCardList from '../NotificationsPanelCardList';
import NotificationsPanelNoResult from '../NotificationsPanelNoResult';
import NotificationsPanelHistoryHeader from './NotificationsPanelHistoryHeader';

const STRINGS = {
  NO_RESULT_SUBTITLE:
    'Previous Game updates, pending verifications, and rewards will show up here.',
  NO_RESULT_TITLE: 'Nothing to show',
};

const emptyNode = (
  <ArcViewBox m={3}>
    <NotificationsPanelNoResult
      title={STRINGS.NO_RESULT_TITLE}
      subtitle={STRINGS.NO_RESULT_SUBTITLE}
    />
  </ArcViewBox>
);

const NotificationsPanelHistory = ({
  notifications,
  onDateRangeChange,
  statusGetNotifications,
}) => (
  <ArcViewBox width="100%">
    <NotificationsPanelHistoryHeader
      isPending={statusGetNotifications.pending}
      onChangeDateRange={onDateRangeChange}
    />

    <NotificationsPanelCardList
      emptyNode={emptyNode}
      notifications={notifications}
      isPending={statusGetNotifications.pending}
    />
  </ArcViewBox>
);

NotificationsPanelHistory.displayName = 'NotificationsPanelHistory';

NotificationsPanelHistory.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  onDateRangeChange: PropTypes.func,
  statusGetNotifications: PropTypes.objectOf(PropTypes.bool),
};

NotificationsPanelHistory.defaultProps = {
  notifications: [],
  onDateRangeChange: global.noop,
  statusGetNotifications: {},
};

export default NotificationsPanelHistory;
