function getEndpointUrlFromAxiosConfig(axiosConfig) {
  const {
    baseURL,
    url,
    method,
  } = axiosConfig;

  return `${method.toUpperCase()} ${url.replace(baseURL, '')}`;
}

export default getEndpointUrlFromAxiosConfig;
