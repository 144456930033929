import React from 'react';
import PropTypes from 'prop-types';

const FlagOutlined = ({ height, width, stroke, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.41699 1.5H9.66699C10.2423 1.5 10.7087 1.94772 10.7087 2.5V2.5C10.7087 3.60457 11.6041 4.5 12.7087 4.5H19.042C19.6173 4.5 20.0837 4.94772 20.0837 5.5V15.5C20.0837 16.0523 19.6173 16.5 19.042 16.5H7.16699C6.99386 16.5001 6.83199 16.4176 6.73508 16.2799C6.63818 16.1421 6.61891 15.9671 6.68366 15.813V15.813C6.88611 15.3316 6.43201 13.5 5.90976 13.5H3.41699" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.7083 4.5V13.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.33333 0.5V23.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

FlagOutlined.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  stroke: PropTypes.string,
};

FlagOutlined.defaultProps = {
  height: 25,
  width: 25,
  stroke: 'black',
};

export default FlagOutlined;
