import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator } from 'react-native';

import ArcButton from '../../elements/ArcButton';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';
import theme from '../../styles/theme';

const buttonStyle = {
  overflow: 'hidden',
  transition: theme.transitions.create(['border-color', 'color']),
  paddingLeft: 24,
  paddingRight: 24,
};

const buttonOverlayStyle = {
  transition: theme.transitions.create('width'),
};

const labelStyle = {
  transition: theme.transitions.create('transform'),
};

const labelRequestingStyle = {
  ...labelStyle,
  transform: 'translateX(8px)',
};

const progressStyle = {
  transition: theme.transitions.create(['width', 'background-color']),
  transitionDelay: 300,
};

class ArcProgressButton extends React.PureComponent {
  static propTypes = {
    color: PropTypes.string,
    isSpaced: PropTypes.bool,
    label: PropTypes.string.isRequired,
    progress: PropTypes.number,
    requestStatus: PropTypes.oneOf([
      'DEFAULT',
      'REQUEST',
      'SUCCESS',
      'FAILURE',
    ]),
    style: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    color: undefined,
    isSpaced: false,
    progress: 0,
    requestStatus: 'DEFAULT',
    style: undefined,
  };

  state = {
    buttonWidth: 0,
    shouldStayCompleted: false,
  };

  componentDidMount() {
    this.updateButtonWidth();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.label !== this.props.label) {
      this.updateButtonWidth();
    }

    const { requestStatus } = this.props;

    let shouldStayCompleted = false;

    this.prevProgress = prevProps.progress;

    if (prevProps.requestStatus !== requestStatus) {
      shouldStayCompleted = requestStatus === 'SUCCESS' || requestStatus === 'FAILURE';
    }

    if (prevProps.progress === 100 && this.props.progress === 0 && requestStatus === 'REQUEST') {
      shouldStayCompleted = true;
    }

    this.setShouldStayCompleted(shouldStayCompleted);

    if (shouldStayCompleted) {
      if (this.listener) {
        window.clearTimeout(this.listener);
      }

      this.listener = setTimeout(() => {
        this.setShouldStayCompleted(false);
      }, 3000);
    }
  }

  get color() {
    switch (this.props.requestStatus) {
      case 'REQUEST':
        return 'yellow';
      case 'SUCCESS':
        return 'success';
      case 'FAILURE':
        return 'danger';
      case 'DEFAULT':
      default:
        return 'blue';
    }
  }

  get isRequesting() {
    return this.props.requestStatus === 'REQUEST';
  }

  get isDoneRequesting() {
    const { requestStatus } = this.props;

    return this.state.shouldStayCompleted
      || requestStatus === 'SUCCESS'
      || requestStatus === 'FAILURE';
  }

  setButtonWidth = buttonWidth => this.setState({ buttonWidth });

  setShouldStayCompleted = shouldStayCompleted => this.setState({ shouldStayCompleted });

  containerRef = React.createRef();

  updateButtonWidth = () => {
    if (this.containerRef.current) {
      const { width } = this.containerRef.current.getBoundingClientRect();
      this.setButtonWidth(width);
    }
  };

  render() {
    const {
      color,
      label,
      isSpaced,
      progress,
      requestStatus,
      style,
      ...rest
    } = this.props;

    const {
      buttonWidth,
    } = this.state;

    let progressWidth = progress || 0;

    if (progressWidth > 100) {
      progressWidth = 100;
    }

    if (this.prevProgress === 100 && progress === 0 && requestStatus === 'REQUEST') {
      progressWidth = 100;
    }

    const labelText = (
      <div style={this.isRequesting ? labelRequestingStyle : labelStyle}>
        {label}
      </div>
    );

    const marginRight = isSpaced ? '8' : undefined;

    return (
      <ArcView
        overflow="hidden"
        position="relative"
        internalRef={this.containerRef}
        marginRight={marginRight}
      >
        <ArcButton
          variant="outlined"
          {...rest}
          color={this.color}
          style={{ ...buttonStyle, ...style }}
        >
          {labelText}

          <ArcView
            overflow="hidden"
            position="absolute"
            fillToParent
            color={this.color}
            style={{
              ...progressStyle,
              width: `${this.isDoneRequesting ? 100 : progressWidth}%`,
            }}
          >
            <ArcView
              position="absolute"
              fillToParent
              align="center"
              justify="center"
              style={{
                ...buttonOverlayStyle,
                width: buttonWidth,
              }}
            >
              <ArcText color="white">
                {labelText}
              </ArcText>
            </ArcView>
          </ArcView>

          <ArcView
            position="absolute"
            fillToParent
            align="flex-start"
            justify="center"
            paddingLeft="12"
          >
            <ActivityIndicator
              animating={this.isRequesting}
              hidesWhenStopped
              color={progressWidth > 16 ? '#fff' : '#FFCA13'}
              size={12}
            />
          </ArcView>
        </ArcButton>
      </ArcView>
    );
  }
}

export default ArcProgressButton;
