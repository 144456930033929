import {
  put,
  select,
  takeEvery as RSTakeEvery,
} from '@redux-saga/core/effects';

import sagas from 'arcade-frontend-core/src/helpers/sagas';
import { actions as coreActions } from 'arcade-frontend-core/src/actions';

import { actions, types } from './actions/uploadManager';
import {
  actions as audiencesActions,
  types as audiencesTypes,
} from './actions/audiences';
import { types as customAudienceTypes } from './actions/customAudience';
import api from './api';
import { getLevels } from './reducers/audiences/levels';

const takeLatest = [
  {
    type: types.UPLOAD_MANAGER_GET_MY_UPLOADS,
    call: api.getMyUploads,
  },
  {
    type: types.UPLOAD_MANAGER_GET_SHARED_UPLOADS,
    call: api.getSharedUploads,
  },
  {
    type: types.UPLOAD_MANAGER_GET_MY_FILES,
    call: api.getMyFiles,
  },
  {
    type: types.UPLOAD_MANAGER_GET_SHARED_FILES,
    call: api.getSharedFiles,
  },
  {
    type: types.UPLOAD_MANAGER_SEARCH_UPLOADS,
    call: api.searchUploads,
  },
  {
    type: audiencesTypes.AUDIENCES_GET_AUDIENCE_TYPES,
    call: api.getAudienceTypes,
  },
  {
    type: audiencesTypes.AUDIENCES_GET_AUDIENCES,
    call: api.getAudiences,
  },
];

const onSuccess = [
  actions.uploadManagerGetMyUploads,
  actions.uploadManagerGetSharedUploads,
  actions.uploadManagerGetMyFiles,
  actions.uploadManagerGetSharedFiles,
];

const onSuccessUpdateVideo = [
  actions.uploadManagerGetMyUploads,
  actions.uploadManagerGetSharedUploads,
];

const onSuccessUpdateFile = [
  actions.uploadManagerGetMyFiles,
  actions.uploadManagerGetSharedFiles,
];

const takeEvery = [
  {
    type: types.UPLOAD_MANAGER_CREATE_UPLOAD,
    call: api.createUpload,
    onSuccess,
  },
  {
    type: types.UPLOAD_MANAGER_DELETE_UPLOAD,
    call: api.deleteUpload,
    onSuccess,
  },
  {
    type: types.UPLOAD_MANAGER_UPDATE_UPLOAD,
    call: api.updateUpload,
    onSuccess: onSuccessUpdateVideo,
  },
  {
    type: types.UPLOAD_MANAGER_UPDATE_UPLOAD_FILE,
    call: api.updateUploadFile,
    onSuccess: onSuccessUpdateFile,
  },
  {
    type: customAudienceTypes.CUSTOM_AUDIENCE_CREATE,
    call: api.createCustomAudience,
  },
  {
    type: customAudienceTypes.CUSTOM_AUDIENCE_ENTITIES,
    call: api.getCustomAudienceEntities,
  },
];

function* announceFileSelected(data) {
  const angularAction = { event: 'arc:uploadManager:videoSelected', data };
  yield put(coreActions.appRootScopeBroadcast(angularAction));
}

function* updateAudienceOptions(action) {
  const { payload } = action;
  const { data } = payload;
  const filter = data[0];
  if (filter) {
    yield put(audiencesActions.audiencesSetFilter(filter));
    yield put(audiencesActions.audiencesLoadAudiences(filter));
  }
}

function* toggleAudienceFilter(action) {
  const { payload } = action;
  const { levels, filter, steps } = payload;

  let nextIndex = levels.findIndex(({ id }) => id === filter.id) + steps;

  if (nextIndex === -1) {
    nextIndex = levels.length - 1;
  }

  const nextFilter = levels[nextIndex % levels.length];

  yield put(audiencesActions.audiencesSetFilter(nextFilter));
  yield put(audiencesActions.audiencesLoadAudiences(nextFilter));
}

function* handleCustomAudienceCreateSucess() {
  const levels = yield select(getLevels);
  const filter = levels ? levels.find(level => level.id === '0') : null;

  yield put(audiencesActions.audiencesSetFilter(filter));
  yield put(audiencesActions.audiencesLoadAudiences(filter));
}

function* scrollNewsfeedToTop() {
  const angularAction = { event: 'arc:Newsfeed:scrollToTop', data: {} };
  yield put(coreActions.appRootScopeBroadcast(angularAction));
}

export default [
  ...takeEvery.map(sagas.makeTakeEvery),
  ...takeLatest.map(sagas.makeTakeLatest),
  RSTakeEvery(types.UPLOAD_MANAGER_FILE_SELECTED, announceFileSelected),
  RSTakeEvery(
    audiencesTypes.AUDIENCES_GET_AUDIENCE_TYPES.SUCCESS,
    updateAudienceOptions,
  ),
  RSTakeEvery(audiencesTypes.AUDIENCES_TOGGLE_FILTER, toggleAudienceFilter),
  RSTakeEvery(
    customAudienceTypes.CUSTOM_AUDIENCE_CREATE.SUCCESS,
    handleCustomAudienceCreateSucess,
  ),
  RSTakeEvery(types.SCROLL_NEWSFEED_TO_TOP, scrollNewsfeedToTop),
];
