import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import IconErrorOutlined from '@material-ui/icons/ErrorOutlined';

import getTeamLetter from 'arcade-frontend-core/src/helpers/getTeamLetter';
import getTeamColor from 'arcade-frontend-core/src/helpers/getTeamColor';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcEntityAvatar from 'arcade-frontend-ui/src/components/ArcEntityAvatar';
import ArcForm from 'arcade-frontend-ui/src/components/ArcForm';
import ArcFormField from 'arcade-frontend-ui/src/components/ArcFormField';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';
import ArcResponsiveDialog from 'arcade-frontend-ui/src/components/ArcResponsiveDialog';
import ArcSecondaryTeamsFormField from 'arcade-frontend-forms/src/components/ArcSecondaryTeamsFormField';
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import { TEAMS_V2 } from 'arcade-frontend-core/src/types/feature-flags';

const INITIAL_VALUES = {
  primaryTeamId: '',
  secondaryTeamIds: [],
};

const NO_TEAM = {
  id: '-1',
  name: 'No Primary Team',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  primaryTeamId: Yup.string().optional(),
  secondaryTeamIds: Yup.array().optional(),
});

const STRINGS = {
  'PEOPLE/TEAM_ASSIGNMENT_ERROR':
    'Unable to update team. Please try again later.',
  'PEOPLE/TEAM_ASSIGNMENT_PRIMARY_TEAM_EXPLAINER_1': 'This is the team that',
  'PEOPLE/TEAM_ASSIGNMENT_PRIMARY_TEAM_EXPLAINER_2':
    'will see on their profile and represent in team based games.',
  'PEOPLE/TEAM_ASSIGNMENT_PRIMARY_TEAM_HEADER': 'Primary Team',
  'PEOPLE/TEAM_ASSIGNMENT_SECONDARY_TEAM_EXPLAINER':
    'This will add the user to the team chat and allow them to be searched by this team.',
  'PEOPLE/TEAM_ASSIGNMENT_SECONDARY_TEAM_HEADER': 'Secondary Team',
};

const useStyles = makeStyles(theme => ({
  iconError: {
    color: theme.palette.red.main,
    marginRight: 4,
    height: 20,
    width: 20,
  },

  menuItem: {
    height: 'auto',
  },

  dropdown: {
    fontSize: 14,
    color: theme.palette.common.black,
    backgroundColor: '#EBEBEB',
    width: '60%',
  },
}));

function PeopleTeamAssignmentFormDialog({
  open,
  onClose,
  onSubmit,
  teams,
  name,
  primaryTeamId,
  primaryTeamName,
  secondaryTeams,
  statusGetTeams,
  statusUpdateUserTeam,
  hasErrorUpdateUserTeam,
}) {
  const classes = useStyles();
  const hasTeamsV2 = useFeatureFlag(TEAMS_V2);

  const currentTeam =
    !!teams.length &&
    [NO_TEAM, ...teams].find(team => team.id === primaryTeamId);

  const handleClose = formProps => {
    formProps.resetForm();
    onClose();
  };

  const renderTeam = team => (
    <ArcBox display="flex" flexDirection="row" alignItems="center">
      {team.id !== '-1' && (
        <ArcEntityAvatar
          id={team.id}
          type="team"
          shape="circle"
          letter={getTeamLetter(team.name)}
          color={getTeamColor(team.id)}
          size={22}
        />
      )}

      <ArcBox
        component={ArcText}
        fontSize={14}
        color="common.black"
        overflow="hidden"
        textOverflow="ellipsis"
        lineHeight={1.5}
        width={240}
        ml={1}
      >
        {team.name}
      </ArcBox>
    </ArcBox>
  );

  const renderTeamItem = teamItem => (
    <MenuItem
      key={teamItem.id}
      className={classes.menuItem}
      value={teamItem.id}
    >
      {renderTeam(teamItem)}
    </MenuItem>
  );

  const renderTeamItems = () =>
    !!teams.length && [NO_TEAM, ...teams].map(renderTeamItem);

  const renderTeamValue = value => {
    const filteredTeam =
      !!teams.length && teams.find(team => team.id === value);

    if (teams && filteredTeam) {
      return renderTeam(filteredTeam);
    }

    if (statusGetTeams.pending) {
      return (
        <ArcBox mr={1}>
          <Skeleton variant="rect" height={20} width="100" />
        </ArcBox>
      );
    }
    return renderTeam(NO_TEAM);
  };

  const renderForm = formProps => {
    const shouldDisableSubmit =
      statusUpdateUserTeam.pending || !teams.length || !formProps.dirty;
    const teamsWithoutCurrentPrimary = teams.filter(
      team => team.id !== formProps.values.primaryTeamId,
    );

    return (
      <ArcResponsiveDialog
        open={open}
        onClose={() => handleClose(formProps)}
        maxWidth={340}
      >
        <DialogTitle>
          <ArcBox fontWeight="bold">{'Change Team'}</ArcBox>
        </DialogTitle>

        <DialogContent>
          <ArcBox mb={2}>
            <ArcBox
              component={ArcText}
              color="#616264"
              fontSize={16}
              fontWeight={600}
              lineHeight="150%"
            >
              {STRINGS['PEOPLE/TEAM_ASSIGNMENT_PRIMARY_TEAM_HEADER']}
            </ArcBox>
          </ArcBox>

          <ArcBox mb={3}>
            <ArcFormField
              name="primaryTeamId"
              type="select"
              margin="dense"
              disabled={statusUpdateUserTeam.pending || !teams.length}
              className={classes.dropdown}
              renderValue={renderTeamValue}
            >
              {renderTeamItems()}
            </ArcFormField>

            <ArcBox
              component={ArcText}
              fontSize={12}
              lineHeight="150%"
              color="#616264"
            >
              {`${STRINGS['PEOPLE/TEAM_ASSIGNMENT_PRIMARY_TEAM_EXPLAINER_1']} ${name} ${STRINGS['PEOPLE/TEAM_ASSIGNMENT_PRIMARY_TEAM_EXPLAINER_2']}`}
            </ArcBox>
          </ArcBox>

          {hasTeamsV2 && (
            <ArcBox>
              <ArcBox display="flex" flexDirection="column">
                <ArcBox
                  component={ArcText}
                  color="#616264"
                  fontSize={16}
                  fontWeight={600}
                  lineHeight="150%"
                >
                  {'Additional Teams'}
                </ArcBox>

                <ArcBox
                  component={ArcText}
                  fontSize={12}
                  lineHeight="150%"
                  color="#616264"
                  my={1}
                >
                  {STRINGS['PEOPLE/TEAM_ASSIGNMENT_SECONDARY_TEAM_EXPLAINER']}
                </ArcBox>
              </ArcBox>
              <ArcSecondaryTeamsFormField
                teams={teamsWithoutCurrentPrimary}
                formValues={formProps.values}
              />
            </ArcBox>
          )}

          {hasErrorUpdateUserTeam && (
            <ArcBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              className="animated fadeIn"
              mt={1}
            >
              <IconErrorOutlined className={classes.iconError} />

              <ArcBox
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
                className="animated fadeIn"
                mt={1}
              >
                <IconErrorOutlined className={classes.iconError} />

                <ArcBox component={ArcText} color="red.main" fontSize={12}>
                  {STRINGS['PEOPLE/TEAM_ASSIGNMENT_ERROR']}
                </ArcBox>
              </ArcBox>
            </ArcBox>
          )}
        </DialogContent>

        <DialogActions>
          <ArcBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            p={2}
            pt={0}
          >
            <ArcButton label="Close" onClick={() => handleClose(formProps)} />

            <ArcBox ml={1}>
              <ArcResourceButton
                type="submit"
                label="Save"
                color="primary"
                variant="contained"
                disabled={shouldDisableSubmit}
                status={statusUpdateUserTeam}
                onClick={formProps.handleSubmit}
              />
            </ArcBox>
          </ArcBox>
        </DialogActions>
      </ArcResponsiveDialog>
    );
  };

  const initialValues = teams
    ? {
        ...INITIAL_VALUES,
        primaryTeamId: currentTeam ? currentTeam.id : '',
        secondaryTeamIds: secondaryTeams || [],
      }
    : INITIAL_VALUES;

  return (
    <ArcForm
      initialValues={initialValues}
      enableReinitialize
      validateOnBlur
      validateOnChange
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {renderForm}
    </ArcForm>
  );
}

PeopleTeamAssignmentFormDialog.displayName = 'PeopleTeamAssignmentFormDialog';

PeopleTeamAssignmentFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  primaryTeamId: PropTypes.string,
  secondaryTeams: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  primaryTeamName: PropTypes.string,
  statusGetTeams: PropTypes.objectOf(PropTypes.bool),
  statusUpdateUserTeam: PropTypes.objectOf(PropTypes.bool),
  hasErrorUpdateUserTeam: PropTypes.bool,
};

PeopleTeamAssignmentFormDialog.defaultProps = {
  open: false,
  onClose: global.noop,
  onSubmit: global.noop,
  teams: [],
  primaryTeamId: null,
  secondaryTeams: [],
  name: '',
  primaryTeamName: '',
  statusGetTeams: {},
  statusUpdateUserTeam: {},
  hasErrorUpdateUserTeam: false,
};

export default PeopleTeamAssignmentFormDialog;
