import moment, { MomentInput } from 'moment';

import { formatDate, endOfToday } from '../../helpers/utils/date';

interface GraylistOptions {
  whitelist?: Array<any>;
  blacklist?: Array<any>;
  prop?: string;
}

export const applyGraylist = <T>(
  array: Array<T>,
  options?: GraylistOptions,
): Array<T> => {
  if (array.length < 1 || !options) return array;

  let result = [...array];

  if (options?.whitelist && options?.whitelist.length) {
    result = result.filter(item =>
      options?.whitelist?.includes(options?.prop ? item[options.prop] : item),
    );
  }
  if (options?.blacklist && options?.blacklist.length) {
    result = result.filter(
      item =>
        !options?.blacklist?.includes(
          options?.prop ? item[options.prop] : item,
        ),
    );
  }

  return result;
};

export interface ArcFormDateRangePickerValues {
  type: 'time period' | 'date range';
  timePeriod?: string;
  fromDate?: string;
  toDate?: string;
}
export interface DateFilter {
  label: string;
  value: {
    type: 'time period' | 'date range';
    timePeriod?: string;
    fromDate?: string;
    toDate?: string;
  };
  selectedText: string;
}

export const makeRangeText = ({
  fromDate,
  toDate,
}: DateFilter['value']): string =>
  fromDate && toDate ? `${formatDate(fromDate)} - ${formatDate(toDate)}` : '';
export const disabledDays = (day: Date, futureIsDisabled = true): boolean =>
  futureIsDisabled ? moment(day).isAfter(endOfToday) : false;
export const dateAsParam = (date: MomentInput) =>
  moment(date).startOf('day').format('YYYY-MM-DD');
