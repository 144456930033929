/* -eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import Clear from '@material-ui/icons/Clear';

import {
  ArcView,
  createWithStyles,
  ArcIconButton,
  ArcAttachedFileList,
  ArcImageGallery,
} from 'arcade-frontend-ui';

import ArcVideo from '../ArcVideo';

const styles = {
  PreviewList: theme => ({
    root: {
      backgroundColor: theme.palette.common.white,
    },
  }),
  PreviewRemove: theme => ({
    root: {
      position: 'absolute',
      right: theme.spacing(1),
      border: '1px solid #b4b4b4',
      top: theme.spacing(1),
      '& :hover': {
        color: theme.palette.red.main,
      },
    },
  }),
};

const PreviewList = createWithStyles(styles.PreviewList)(ArcView);
const PreviewRemove = createWithStyles(styles.PreviewRemove)(ArcIconButton);

class Previews extends React.PureComponent {
  static propTypes = {
    numberOfAttachments: PropTypes.shape({
      files: PropTypes.number,
      images: PropTypes.number,
      videos: PropTypes.number,
    }),
    isDisabled: PropTypes.bool,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        preview: PropTypes.string,
        size: PropTypes.number,
        type: PropTypes.string,
      }),
    ).isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        preview: PropTypes.string,
        size: PropTypes.number,
        type: PropTypes.string,
      }),
    ).isRequired,
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        assets: PropTypes.shape({}),
        name: PropTypes.string,
        size: PropTypes.number,
        type: PropTypes.string,
      }),
    ).isRequired,
    remove: PropTypes.func.isRequired,
    removeVideo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isDisabled: false,
    numberOfAttachments: {
      files: 1,
      images: 1,
      videos: 1,
    },
  };

  renderVideo = (video, idx) => (
    <ArcView position="relative" key={video.id || video.name || idx}>
      <ArcVideo sourcesByEncoding={video.assets} />
      <PreviewRemove
        onClick={() => this.props.removeVideo(video)}
        disabled={this.props.isDisabled}
      >
        <Clear fontSize="small" />
      </PreviewRemove>
    </ArcView>
  );

  renderFiles = files => (
    <ArcAttachedFileList files={files} onRemove={this.props.remove} />
  );

  renderImages = images => (
    <ArcImageGallery
      images={images}
      renderAttachmentMessage={() => 'This is an attachment message'}
      onRemove={this.props.remove}
    />
  );

  render() {
    const { files, images, videos } = this.props;

    const hasFiles = files.length > 0;
    const hasImages = images.length > 0;
    const hasVideos = videos.length > 0;

    const marginTop = hasFiles || hasImages || hasVideos ? 8 : undefined;

    return (
      <PreviewList marginTop={marginTop}>
        {hasFiles && this.renderFiles(files)}
        {hasImages && this.renderImages(images)}
        {hasVideos && videos.map(this.renderVideo)}
      </PreviewList>
    );
  }
}

export default Previews;
