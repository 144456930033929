import * as types from 'arcade-frontend-core/src/types/routes';

export const routeManageTokens = (payload = {}) => ({
  type: types.ROUTE_MANAGE_TOKENS,
  payload,
});

export const routeManageTokenBudgets = () => ({
  type: types.ROUTE_MANAGE_TOKEN_BUDGETS,
  payload: {},
});
