import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionTypes } from 'redux-resource';

import getResource from '../../helpers/getResource';
import { getCurrentUser } from '../../reducers/user';

import useIsMounted from '../useIsMounted';


const DEFAULT_OPTS = {
  shouldDispatch: true,
  treatIdleAsPending: false,
};

export const STATUS_TYPES = {
  idle: 'idle',
  pending: 'pending',
  succeeded: 'succeeded',
  failed: 'failed',
};

export const EMPTY_STATUS = {
  idle: false,
  pending: false,
  succeeded: false,
  failed: false,
};

export const INITIAL_STATUS = {
  ...EMPTY_STATUS,
  idle: true,
};

export const INITIAL_STATUS_PENDING = {
  ...EMPTY_STATUS,
  pending: true,
};

const ELAPSED_TIME_TICK_MS = 50;
const MIN_REQUEST_TIME = 300;

export function useResourceAction(resourceAction, opts = DEFAULT_OPTS) {
  if (!global.isProduction && !resourceAction) {
    console.error('[useResourceAction] requires a `resourceAction`, you supplied:', resourceAction);
  }

  const options = {
    ...DEFAULT_OPTS,
    ...opts,
  };

  const getInitialData = opts.getInitialData || resourceAction.getInitialData;

  const initialData = useSelector(getInitialData || global.noop);

  const initialDataObject = getInitialData
    ? ({ resources: initialData })
    : undefined;

  const [data, setData] = React.useState(initialDataObject);
  const [error, setError] = React.useState(undefined);
  const [elapsedTime, setElapsedTime] = React.useState(0);

  const initialStatus = options.treatIdleAsPending
    ? INITIAL_STATUS_PENDING
    : INITIAL_STATUS;

  const [status, setStatusState] = React.useState(initialStatus);

  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  const isAuthorized = !!currentUser && !!currentUser.token;

  const isMounted = useIsMounted();

  const getBaseURL = () => {
    if (isAuthorized) {
      return currentUser.endpoint;
    }

    return undefined;
  };

  const baseURL = getBaseURL();

  const headers = isAuthorized
    ? {
      'X-User-Token': currentUser.token,
      'X-User-Email': currentUser.email,
    }
    : {};

  const baseAxiosConfig = {
    headers,
    baseURL,
  };

  function setStatus(nextStatus) {
    if (!isMounted.current) {
      return;
    }

    setStatusState({
      ...EMPTY_STATUS,
      [nextStatus]: true,
    });
  }

  let elapsedTimeInterval;

  let requestPayload;

  const waitForMinRequestTime = resp => new Promise((resolve) => {
    window.clearInterval(elapsedTimeInterval);

    if (isMounted.current) {
      setElapsedTime(0);
    }

    if (elapsedTime >= MIN_REQUEST_TIME || process.env.NODE_ENV === 'test') {
      resolve(resp);
    } else {
      const remainingTime = MIN_REQUEST_TIME - elapsedTime;

      setTimeout(() => {
        resolve(resp);
      }, remainingTime);
    }
  });

  function handleErrorReset() {
    setError(null);
    setStatus(STATUS_TYPES.idle);
  }

  function handleFailure(err) {
    console.error(err);
    const { response } = err?.payload?.data;
    const errorObject = { ...err, response };

    if (options.shouldDispatch) {
      dispatch(resourceAction.failed(errorObject));
    }

    if (!isMounted.current) {
      return err;
    }

    setError(errorObject);
    setStatus(STATUS_TYPES.failed);

    return err;
  }

  function handleStatusReset() {
    if (options.shouldDispatch) {
      dispatch(resourceAction.idle(requestPayload));
    }

    if (!isMounted.current) {
      return;
    }

    setStatus(STATUS_TYPES.idle);
  }

  function handleSuccess(resp) {
    if (options.shouldDispatch) {
      dispatch(resourceAction.succeeded({
        ...resp.data,
        requestProperties: resp.requestProperties,
      }));
    }

    if (resp.data.meta) {
      dispatch({
        type: actionTypes.UPDATE_RESOURCES,
        meta: {
          [resourceAction.resourceType]: {
            [resourceAction.requestKey]: resp.data.meta,
          },
        },
      });
    }

    if (!isMounted.current) {
      return resp;
    }

    setData(resp.data);
    setStatus(STATUS_TYPES.succeeded);

    return resp;
  }

  function requestData(payload, dynamicAxiosConfig) {
    setElapsedTime(0);

    elapsedTimeInterval = setInterval(() => {
      if (!isMounted.current) {
        return;
      }

      setElapsedTime(elapsedTime + ELAPSED_TIME_TICK_MS);
    }, ELAPSED_TIME_TICK_MS);

    requestPayload = payload;

    setStatus(STATUS_TYPES.pending);

    if (options.shouldDispatch) {
      dispatch(resourceAction.pending(payload));
    }

    const axiosConfig = {
      ...baseAxiosConfig,
      ...dynamicAxiosConfig,
    };

    return new Promise((resolve, reject) => {
      getResource(resourceAction, payload, axiosConfig)
        .then(waitForMinRequestTime)
        .then(handleSuccess)
        .then(resolve)
        .catch((err) => {
          waitForMinRequestTime().then(() => {
            handleFailure(err);
            reject(err);
          });
        });
    });
  }

  function getHasData() {
    if (typeof data === typeof undefined) {
      return false;
    }

    if (data && data.resources && data.resources.constructor === Array) {
      return !!data.resources.length;
    }

    return true;
  }

  function getHasResources() {
    return getHasData() && !!data.resources;
  }

  return {
    data,
    error,
    failed: resourceAction.failed,
    hasData: getHasData(),
    hasResources: getHasResources(),
    hasError: !!error,
    hasFailed: status.failed,
    hasSucceeded: status.succeeded,
    idle: resourceAction.idle,
    isIdle: status.idle,
    isPending: status.pending,
    onErrorReset: handleErrorReset,
    onStatusReset: handleStatusReset,
    pending: resourceAction.pending,
    requestData,
    setData,
    setError,
    setStatus,
    status,
    succeeded: resourceAction.succeeded,
  };
}

export default useResourceAction;
