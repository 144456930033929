import ArcView from '../../primitives/ArcView';

import { createWithStyles } from '../../styles';

const styles = {
  ArcCellContent: theme => ({
    root: {
      whiteSpace: 'nowrap',
      alignItems: 'center',
      flexDirection: 'row',
    },
    title: {
      fontSize: 18,
    },
    subheader: {
      fontWeight: 'bold',
    },
    hint: {
      color: theme.palette.text.hint,
    },
    action: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    primary: {
      color: theme.palette.primary.main,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    danger: {
      color: theme.palette.danger.main,
    },
    info: {
      color: theme.palette.info.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
  }),
};

const ArcCellContent = createWithStyles(styles.ArcCellContent)(ArcView);

export default ArcCellContent;
