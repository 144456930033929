import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const initialState = {
  objectives: [],
  reward: null,
};

const handleChecklistIndexSuccess = (state, action) => action.payload.data[0] || initialState;
const handleChecklistClaimRewardSuccess = () => initialState;

const handlers = {
  [types.APP_CHECKLIST_INDEX.SUCCESS]: handleChecklistIndexSuccess,
  [types.APP_CHECKLIST_CLAIM_REWARD.SUCCESS]: handleChecklistClaimRewardSuccess,
};

const checklist = createReducer(initialState, handlers);

export default checklist;

export const getAppChecklist = state => state.app.checklist;
