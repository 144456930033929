import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const handleWebviewSetRouteType = (state, action) => action.payload;

const initialState = 'NEWSFEED';

const handlers = {
  [types.WEBVIEW_SET_ROUTE_TYPE]: handleWebviewSetRouteType,
};

const routeType = createReducer(initialState, handlers);

export default routeType;

export const getRouteType = state => state.app.routeType;
