import React from 'react';
import PropTypes from 'prop-types';

import { ArcView, ArcSimpleDialog } from 'arcade-frontend-ui';

import ChatSearchContainer from '../ChatSearchContainer';

function ChatSearchDialog({
  open,
  onClose,
  initialValue,
  onSearch,
  isLoading,
  hasError,
  onResultSelected,
  searchResults,
}) {
  return (
    <ArcSimpleDialog open={open} onClose={onClose} title="Search">
      <ArcView flexGrow={1} flexShrink={1} overflow="hidden">
        <ChatSearchContainer
          initialValue={initialValue}
          onSearch={onSearch}
          isLoading={isLoading}
          hasError={hasError}
          onResultSelected={onResultSelected}
          searchResults={searchResults}
        />
      </ArcView>
    </ArcSimpleDialog>
  );
}

ChatSearchDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onResultSelected: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  searchResults: PropTypes.arrayOf(PropTypes.object),
};

ChatSearchDialog.defaultProps = {
  open: false,
  initialValue: '',
  isLoading: false,
  hasError: false,
  searchResults: [],
};

export default ChatSearchDialog;
