import {
  all,
  fork,
  select,
  spawn,
  take,
  cancel,
} from '@redux-saga/core/effects';
import { NOT_FOUND } from 'redux-first-router';

import core from 'arcade-frontend-core/src/sagas';
import { types } from 'arcade-frontend-core/src/actions';

import rewards from 'arcade-frontend-rewards/src/sagas';
import people from 'arcade-frontend-people/src/sagas';
import metricsPackage from 'arcade-frontend-metrics/src/sagas';
import { sagas as newsfeed } from 'arcade-frontend-newsfeed';
import { sagas as widgets } from 'arcade-frontend-widgets';

import * as routeTypes from 'arcade-frontend-core/src/types/routes';
import { getLocationType } from '../reducers/location';

// Route Sagas
// import app from './app';
// import chat from './chat';
import games from './games';
import metrics from './metrics';
import manage from './manage';

// mobile still need to use some of these saga so going to export it as array
export const webSagas = [
  ...core,
  // fork(chat),
  ...games,
  ...metrics,
  fork(manage),
  ...rewards,
  ...newsfeed,
  ...widgets,
  ...people,
  ...metricsPackage,
];

const allSagas = function* () {
  yield all(webSagas);
};

// Routes that require side effects on load are mapped here, type to saga.
const routesMap = {
  [NOT_FOUND]: allSagas,
};

const allRouteTypes = {
  ...routeTypes,
};

Object.keys(allRouteTypes).forEach((route) => {
  routesMap[allRouteTypes[route]] = allSagas;
});

const sagasLoaded = {};
const containerSagasLoaded = {};

Object.keys(routesMap).forEach((key) => {
  sagasLoaded[key] = false;
  if (routesMap[key]) {
    containerSagasLoaded[routesMap[key].name] = false;
  }
});

// On application boot we check state to see if we should load anything, then
// we watch for future changes.
function* routes() {
  const initialRoute = yield select(getLocationType);
  // Run saga in route map that matches initialRoute if exists
  if (routesMap[initialRoute]) {
    sagasLoaded[initialRoute] = true;
    containerSagasLoaded[routesMap[initialRoute].name] = true;
    yield spawn(routesMap[initialRoute]);
  }
  // Watch for future navigation events and run the correct saga if needed.
  while (true) {
    const { type } = yield take(Object.keys(routesMap));

    if (!sagasLoaded[type] && !containerSagasLoaded[routesMap[type].name]) {
      sagasLoaded[type] = true;
      containerSagasLoaded[routesMap[type].name] = true;
      yield spawn(routesMap[type]);
    }
  }
}

export default function* sagas() {
  const task = yield fork(routes);

  yield take(types.LOGOUT);
  yield cancel(task);
}
