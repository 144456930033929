import React from 'react';
import { createWithStyles } from '../../styles';
import ArcView from '../../primitives/ArcView/ArcView';

import { displaysScoresType, PositionedPlayers } from '../PositionedPlayers';
import positions from './positions';

import BountyMountain from '../../assets/BountyMountain';
import ColdSpectators from '../../assets/ColdSpectators';
import SummitFlag from '../../assets/SummitFlag';
import Grass from '../../assets/Grass';
import Snowfield from './Snowfield';

const styles = {
  MountainWrapper: () => ({
    root: {
      width: 273,
      height: 255,
      marginLeft: 'auto',
      marginTop: 85,
      position: 'relative',
    },
  }),
  Sky: () => ({
    root: {
      backgroundColor: '#DAF7FF',
      width: '100%',
      position: 'relative',
    },
  }),
  Field: () => ({
    root: {
      backgroundColor: '#AAE46C',
      height: 70,
      width: '100%',
    },
  }),
};

const MountainWrapper = createWithStyles(styles.MountainWrapper)(ArcView);
const Sky = createWithStyles(styles.Sky)(ArcView);
const Field = createWithStyles(styles.Field)(ArcView);

const ArcMountain = ({ scores, goal }) => (
  <Sky>
    <MountainWrapper>
      <BountyMountain />
      <SummitFlag
        style={{ position: 'absolute', top: -67, left: 195 }}
      />
      <ColdSpectators
        style={{ position: 'absolute', top: -32, left: 185 }}
      />
      {!!scores && (
        <PositionedPlayers
          slots={positions}
          scores={scores}
          goal={goal}
        />
      )}
    </MountainWrapper>
    <Field />
    <Grass
      style={{ position: 'absolute', top: 340, left: 8 }}
      width="16"
      height="16"
    />
    <Grass
      style={{ position: 'absolute', top: 350, left: 90 }}
      width="16"
      height="16"
    />
    <Grass
      style={{ position: 'absolute', top: 360, left: 32 }}
      width="16"
      height="16"
    />
    <Grass
      style={{ position: 'absolute', top: 355, left: 250 }}
      width="16"
      height="16"
    />
    <Grass
      style={{ position: 'absolute', top: 345, left: 220 }}
      width="16"
      height="16"
    />
    <Grass
      style={{ position: 'absolute', top: 340, left: 280 }}
      width="16"
      height="16"
    />
    <Snowfield
      total={50}
      maxSize={3}
      duration={15}
    />
  </Sky>
);

ArcMountain.propTypes = displaysScoresType;

export default ArcMountain;
