import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import {
  RPA_ONE_TIME,
  RPA_TYPES,
} from 'arcade-frontend-core/src/types/game-formats';

const GamesProgressBar = withStyles(() => ({
  root: {
    width: 150,
    height: 8,
    border: 'none',
    borderRadius: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  bar: {
    borderRadius: 20,
  },
}))(LinearProgress);

const EveryTimeProgress = withStyles(() => ({
  root: { backgroundColor: '#6F59BE' },
  bar: { backgroundColor: '#A994EB' },
}))(GamesProgressBar);

const OneTimeProgress = withStyles(() => ({
  root: { backgroundColor: '#616264' },
  bar: { backgroundColor: '#B4B4B4' },
}))(GamesProgressBar);

function GamesGameProgressIndicator({
  score,
  unit,
  goal,
  metric,
  type,
  color,
}) {
  const displayableUnit = unit === '$' ? unit : '';

  const timesWon = Math.floor(score / goal);
  const displayableScore = score - timesWon * goal;
  const progress = (displayableScore / goal) * 100;

  return (
    <ArcBox>
      <ArcBox mt={1}>
        <ArcBox mb={1} textAlign="center" color={color} fontSize={14}>
          {displayableScore} / {displayableUnit}
          {goal} {metric}
        </ArcBox>

        {type === RPA_ONE_TIME ? (
          <OneTimeProgress variant="determinate" value={progress} />
        ) : (
          <EveryTimeProgress variant="determinate" value={progress} />
        )}
      </ArcBox>

      <ArcBox mt={1} textAlign="center" color={color} fontSize={12}>
        Progress
      </ArcBox>
    </ArcBox>
  );
}

GamesGameProgressIndicator.displayName = 'GamesGameProgressIndicator';

GamesGameProgressIndicator.propTypes = {
  goal: PropTypes.number,
  metric: PropTypes.string,
  score: PropTypes.number,
  unit: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.oneOf(Object.values(RPA_TYPES)),
};

GamesGameProgressIndicator.defaultProps = {
  goal: 0,
  metric: '',
  score: 0,
  unit: '',
  color: 'common.white',
  type: RPA_TYPES.RPA_ONE_TIME,
};

export default GamesGameProgressIndicator;
