import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import moment from 'moment';
import { throttle, debounce } from 'throttle-debounce';
import { ActivityIndicator } from 'react-native';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Fab from '@material-ui/core/Fab';

import {
  ArcFlatList,
  ArcView,
  ArcText,
  Platform,
  createWithStyles,
} from 'arcade-frontend-ui';
import withResourceAction from 'arcade-frontend-core/src/helpers/withResourceAction';
import track from 'arcade-frontend-core/src/helpers/track';
import newsfeedFilter from 'arcade-frontend-core/src/helpers/newsfeedFilter';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import {
  getCurrentUser,
  getCurrentUserPermission,
  getCurrentUserFeatures,
} from 'arcade-frontend-core/src/reducers/user';
import { getLocationQuery } from 'arcade-frontend-features/src/reducers/location';
import { linkToRouteType } from 'arcade-frontend-features/src/routes/helpers';
import { ROUTE_NEWSFEED_CATEGORY } from 'arcade-frontend-core/src/types/routes';
import { actions as quicklinkActions } from 'arcade-frontend-people/src/actions';

import { actions } from '../../actions';
import { selectors } from '../../reducers/activities';
import {
  getCommentsById,
  getCommentCreateActivityId,
} from '../../reducers/rrComments';
import { selectors as helperSelectors } from '../../reducers/activity_helpers';
import { getRequestStatus } from '../../reducers/requestStatus';
import {
  getReactionsByActivityId,
  getReactionsIsRequestingByActivityId,
} from '../../reducers/activity_reactions';
import {
  getActivitySetsLoading,
  selectors as setLoadingSelectors,
} from '../../reducers/activity_sets_loading';
import { selectors as acknowledgedSelectors } from '../../reducers/acknowledgement';
import { getIsViewingProtectedAudience } from '../../reducers/isViewingProtectedAudience';
import NewsfeedActivityCard from '../../components/NewsfeedActivityCard';
import NewsfeedDateHeader from '../../components/NewsfeedDateHeader';
import NewsfeedSummaryCard from '../../components/NewsfeedSummaryCard';
import NewsfeedActivityEditorContainer from '../NewsfeedActivityEditorContainer';
import { resources } from '../../resources/comments';

import NewsfeedToolbarContainer from './NewsfeedActivityToolbarContainer';

const rootStyle = {
  backgroundColor: '#f1f1f1',
};

const contentContainerStyle = {
  width: '100%',
  maxWidth: 584,
  margin: '0 auto',
};

const summaryContainerStyle = {
  width: 'calc(100% - 96px)',
  marginLeft: 48,
  marginRight: 48,
};

const styles = {
  ScrollButton: theme => ({
    root: {
      position: 'absolute',
      right: 16,
      bottom: 16,
      backgroundColor: 'white',

      [theme.breakpoints.up('sm')]: {
        bottom: 16,
      },

      ...Platform.select({
        web: {
          transition: theme.transitions.create('transform'),
          transform: 'translateY(150%)',
        },
      }),
    },

    isVisible: {
      ...Platform.select({
        web: {
          transform: 'translateY(0)',
        },
      }),
    },
  }),
};

const ScrollButton = createWithStyles(styles.ScrollButton)(Fab);

const getPlaceholders = (amount = 10) =>
  [...Array(amount)].map((_, id) => ({
    type: 'placeholder',
    key: `placeholder-${id}`,
  }));

const getSummaries = item => {
  const summaries = Object.entries(item.data.counts).map(([id, count]) => ({
    type: id,
    count,
    userImages: item.data.summaryUsers[id].map(u => u.user_image),
    newest: item.data.newest,
    oldest: item.data.oldest,
  }));

  return summaries;
};

const today = moment();

const FIRST_DATE = {
  type: 'date',
  key: 'date-first',
  data: today,
  index: 0,
};

const PLACEHOLDER_DATA = {
  stickyHeaderIndices: [],
  data: [
    {
      type: 'editor',
      key: 'editor',
    },
    {
      type: 'toolbar',
      key: 'toolbar',
    },
    FIRST_DATE,
    ...getPlaceholders(20),
  ],
};

const STRINGS = {
  'NEWSFEED/ACTIVITIES_INDEX_EMPTY': 'No posts have been shared yet.',
  'NEWSFEED/ACTIVITIES_LOAD_ERROR':
    'There was an error loading activities. Please try again later',
};

class NewsfeedActivitiesContainer extends React.Component {
  static propTypes = {
    acknowledgedById: PropTypes.objectOf(
      PropTypes.oneOf(Object.values(NewsfeedActivityCard.ACKNOWLEDGED_TYPES)),
    ),
    acknowledgePin: PropTypes.func,
    activitiesById: PropTypes.objectOf(PropTypes.object),
    canDeletePosts: PropTypes.bool,
    canPin: PropTypes.bool,
    canPostToNewsfeed: PropTypes.bool,
    canPostToProtectedAudiences: PropTypes.bool,
    canViewHistory: PropTypes.bool,
    canPromote: PropTypes.bool,
    dateBlocks: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.instanceOf(moment).isRequired,
        sections: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string.isRequired,
            content: PropTypes.any,
          }),
        ).isRequired,
      }),
    ),
    hasFeatureNewsfeedFiltering: PropTypes.bool,
    hasFeatureNewsfeedDateFiltering: PropTypes.bool,
    isError: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isViewingProtectedAudience: PropTypes.bool,
    locationQuery: PropTypes.shape({
      activityId: PropTypes.string,
      audienceId: PropTypes.string,
      dialog: PropTypes.string,
    }),
    onActivitiesClear: PropTypes.func.isRequired,
    onActivityAcksRequest: PropTypes.func.isRequired,
    onActivityCommentsRequest: PropTypes.func.isRequired,
    onActivityEdit: PropTypes.func.isRequired,
    onActivityIndexRequest: PropTypes.func.isRequired,
    onActivityViewsRequest: PropTypes.func.isRequired,
    onSummaryRequest: PropTypes.func.isRequired,
    onCommentDelete: PropTypes.func.isRequired,
    onCommentReactionDialogOpen: PropTypes.func,
    onCommentReactionSelect: PropTypes.func,
    onCommentSave: PropTypes.func.isRequired,
    onDialogOpen: PropTypes.func,
    onReactionSelect: PropTypes.func,
    onRespect: PropTypes.func.isRequired,
    onQuicklinkUser: PropTypes.func,
    onScroll: PropTypes.func,
    pinnedActivities: PropTypes.arrayOf(PropTypes.object),
    reactionsByActivityId: PropTypes.objectOf(PropTypes.array),
    reactionsIsRequestingByActivityId: PropTypes.objectOf(PropTypes.bool),
    requestStatus: PropTypes.shape({
      NEWSFEED_GET_ACTIVITY_COMMENTS: PropTypes.string,
    }),
    scrollClassName: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      token: PropTypes.string,
    }).isRequired,
    commentsById: PropTypes.objectOf(PropTypes.object),
    requestData: PropTypes.func.isRequired,
    onStatusReset: PropTypes.func.isRequired,
    savingCommentId: PropTypes.string,
  };

  static defaultProps = {
    acknowledgedById: {},
    acknowledgePin: global.noop,
    activitiesById: {},
    canDeletePosts: false,
    canPin: false,
    canPromote: false,
    canPostToNewsfeed: true,
    canPostToProtectedAudiences: false,
    canViewHistory: false,
    dateBlocks: [],
    hasFeatureNewsfeedFiltering: false,
    hasFeatureNewsfeedDateFiltering: false,
    isViewingProtectedAudience: false,
    locationQuery: {},
    onCommentReactionDialogOpen: global.noop,
    onCommentReactionSelect: global.noop,
    onDialogOpen: global.noop,
    onReactionSelect: global.noop,
    onScroll: global.noop,
    onQuicklinkUser: global.noop,
    pinnedActivities: [],
    reactionsByActivityId: {},
    reactionsIsRequestingByActivityId: {},
    requestStatus: {},
    savingCommentId: null,
    scrollClassName: '',
    commentsById: {},
  };

  static displayName = 'NewsfeedActivitiesContainer';

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();

    const { data, stickyHeaderIndices } = this.getData();

    this.state = {
      isAtTop: true,
      currentActivityId: null,
      currentPage: 1,
      data,
      stickyHeaderIndices,
    };
  }

  componentDidMount() {
    if (global.canUseDOM && window.ngRootScope) {
      this.rootscopeListener = window.ngRootScope.$on(
        'arcade:react:newsfeed:reload',
        this.handlePullToRefresh,
      );
    }

    this.clearActivities();

    newsfeedFilter.clear();

    if (this.props.user.token) {
      this.getActivities();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading) {
      if (!this.props.isLoading && global.canUseDOM && window.ngRootScope) {
        window.ngRootScope.$broadcast('arc:pull-to-refresh:resolve');
      }
    }

    if (
      prevProps.dateBlocks !== this.props.dateBlocks &&
      this.props.dateBlocks.length
    ) {
      this.updateData();
    }

    if (prevProps.isLoading !== this.props.isLoading) {
      this.updateData();
    }

    const previousAudience = prevProps.locationQuery.audienceId;
    const newAudience = this.props.locationQuery.audienceId;

    if (previousAudience !== newAudience) {
      this.handleAudienceChange();
    }

    if (prevProps.user !== this.props.user) {
      if (this.props.user.token) {
        this.getActivities();
      }
    }
  }

  componentWillUnmount() {
    if (this.rootscopeListener) {
      this.rootscopeListener();
    }
  }

  getActivities = (currentPage = this.state.currentPage) => {
    this.props.onActivityIndexRequest({
      page: currentPage,
      audienceId: this.props.locationQuery.audienceId,
      newsfeedFilter: newsfeedFilter.get(),
    });
  };

  get shouldShowEditor() {
    const {
      isViewingProtectedAudience,
      canPostToProtectedAudiences,
      canPostToNewsfeed,
    } = this.props;

    const canPostToCurrentAudience =
      !isViewingProtectedAudience ||
      (isViewingProtectedAudience && canPostToProtectedAudiences);

    return canPostToNewsfeed && canPostToCurrentAudience;
  }

  getData() {
    if (this.props.isLoading && !this.hasActivities) {
      return PLACEHOLDER_DATA;
    }

    const data = [];

    data.push({
      type: 'editor',
      key: 'editor',
    });

    data.push({
      type: 'toolbar',
      key: 'toolbar',
    });

    this.props.pinnedActivities.forEach(activity => {
      data.push({
        type: 'activity-pinned',
        key: activity.id,
        data: activity,
      });
    });

    this.props.dateBlocks.forEach((dateBlock, index) => {
      if (!dateBlock.date) {
        return;
      }

      data.push({
        type: 'date',
        key: index === 0 ? 'date-first' : dateBlock.date.toISOString(),
        data: dateBlock.date,
        index,
      });

      dateBlock.sections.forEach((section, sectionIndex) => {
        if (section.type === 'activity') {
          data.push({
            type: 'activity',
            key: section.content.id,
            data: section.content,
            index: sectionIndex,
          });
        } else {
          data.push({
            type: 'summary',
            key: `summary-${section.date.toISOString()}`,
            data: section.content,
            index: sectionIndex,
          });
        }
      });
    });

    // hasActivities will be false both for empty responses and errors
    if (this.props.isError) {
      data.push({
        type: 'error',
        key: 'error',
      });
    } else if (!this.hasActivities) {
      data.push(FIRST_DATE);

      data.push({
        type: 'empty',
        key: 'empty',
      });
    }

    const stickyHeaderIndices = data
      .map((item, idx) => (item.sticky ? idx : null))
      .filter(item => !!item);

    return {
      data,
      stickyHeaderIndices,
    };
  }

  setData = (data, stickyHeaderIndices) =>
    this.setState({ data, stickyHeaderIndices });

  get hasActivities() {
    return (
      this.props.pinnedActivities.length > 0 ||
      this.props.dateBlocks.length > 0 ||
      Object.keys(this.props.activitiesById).length > 0
    );
  }

  setIsAtTop = isAtTop => this.setState({ isAtTop });

  clearActivities = () => this.props.onActivitiesClear();

  scrollToTop = () => {
    if (this.scrollRef.current.scrollToIndex) {
      this.scrollRef.current.scrollToIndex({
        index: 0,
      });
    } else {
      this.scrollRef.current.scrollTo({
        top: 0,
      });
    }
  };

  updateData = () => {
    const { data, stickyHeaderIndices } = this.getData();
    this.setData(data, stickyHeaderIndices);
  };

  updateIsAtTop = debounce(300, evt => {
    const isAtTop = evt?.nativeEvent?.contentOffset
      ? evt.nativeEvent.contentOffset.y === 0
      : evt.target.scrollTop === 0;

    if (isAtTop !== this.state.isAtTop) {
      this.setIsAtTop(isAtTop);
    }
  });

  handleAudienceChange = () => {
    this.setState(
      {
        currentPage: 1,
        isAtTop: true,
      },
      () => {
        this.props.onActivitiesClear();
        this.getActivities();
      },
    );
  };

  handleEdit = ({ id }) => {
    setTimeout(() => {
      this.scrollToTop();
    }, 1);
    this.props.onActivityEdit({ id });
  };

  handleEndReached = () => {
    if (this.props.isLoading || !this.props.dateBlocks.length) {
      return;
    }

    this.advancePage();
  };

  handlePullToRefresh = () => {
    this.getActivities(1);
  };

  handleScroll = throttle(16, evt => {
    this.updateIsAtTop(evt);
    this.props.onScroll(evt);
  });

  advancePage = () => {
    this.setState(
      { currentPage: this.state.currentPage + 1 },
      this.getActivities,
    );
  };

  resetPage = () => this.setState({ currentPage: 1 });

  handleSummaryRequest = ({ type, oldest, newest }) => {
    const properties = {
      type,
      oldest: oldest.toString(),
      newest: newest.toString(),
    };
    track('opened summary dialog', {
      payload: { event: 'newsfeed:opened-summary-dialog', properties },
    });
    linkToRouteType(
      ROUTE_NEWSFEED_CATEGORY,
      { type, oldest: oldest.valueOf(), newest: newest.valueOf() },
      { ...this.props.locationQuery },
    );
  };

  createComment = (id, comment) => {
    this.props.requestData({
      resources: [comment],
      list: id,
      requestProperties: { activityId: id },
    });
  };

  renderItem = ({ item, index }) => {
    switch (item.type) {
      case 'activity':
      case 'activity-pinned':
        return (
          !item.data.skipInList && (
            <NewsfeedActivityCard
              key={item.key}
              {...item.data}
              acknowledged={this.props.acknowledgedById[item.data.id]}
              acknowledgePin={this.props.acknowledgePin}
              canEdit
              canPin={this.props.canPin}
              canPromote={this.props.canPromote}
              canDeletePosts={this.props.canDeletePosts}
              currentUserId={this.props.user.id}
              hasFeatureNewsfeedFiltering={
                this.props.hasFeatureNewsfeedFiltering
              }
              isSavingComment={this.props.savingCommentId === item.data.id}
              onActivityAcksRequest={this.props.onActivityAcksRequest}
              onActivityViewsRequest={this.props.onActivityViewsRequest}
              onActivityCommentsRequest={this.props.onActivityCommentsRequest}
              onCommentDelete={this.props.onCommentDelete}
              onCommentReactionDialogOpen={
                this.props.onCommentReactionDialogOpen
              }
              onCommentReactionSelect={this.props.onCommentReactionSelect}
              onCommentSave={this.createComment}
              onDialogOpen={this.props.onDialogOpen}
              onEdit={this.handleEdit}
              onReactionSelect={this.props.onReactionSelect}
              onRespect={this.props.onRespect}
              reactions={this.props.reactionsByActivityId[item.data.id]}
              reactionsIsRequesting={
                this.props.reactionsIsRequestingByActivityId[item.data.id]
              }
              requestStatus={this.props.requestStatus}
              onQuicklinkUser={this.props.onQuicklinkUser}
              hasBehaviourDelete
              hasBehaviourReport
              hasBehaviourRespectDialog
              hasBehaviourShowAllReactions
            />
          )
        );
      case 'date':
        return (
          <NewsfeedDateHeader key={item.key}>
            {item.data.format('ddd, MMM D, YYYY')}
          </NewsfeedDateHeader>
        );
      case 'editor':
        return (
          this.shouldShowEditor && (
            <ArcView key={item.key} marginBottom="16">
              <NewsfeedActivityEditorContainer />
            </ArcView>
          )
        );
      case 'error':
        return (
          <ArcView
            key={item.key}
            align="center"
            marginTop32={this.props.isViewingProtectedAudience}
          >
            <ArcText color="disabled">
              {STRINGS['NEWSFEED/ACTIVITIES_LOAD_ERROR']}
            </ArcText>
          </ArcView>
        );
      case 'empty':
        return (
          <ArcView
            key={item.key}
            align="center"
            marginTop32={this.props.isViewingProtectedAudience}
          >
            <ArcText color="disabled">
              {STRINGS['NEWSFEED/ACTIVITIES_INDEX_EMPTY']}
            </ArcText>
          </ArcView>
        );
      case 'placeholder':
        return (
          <ArcView key={item.key}>
            {index === 2
              ? NewsfeedSummaryCard.PLACEHOLDER
              : NewsfeedActivityCard.PLACEHOLDER}
          </ArcView>
        );
      case 'spinner':
        return (
          <ArcView key={item.key} padding="16" align="center">
            <ActivityIndicator />
          </ArcView>
        );
      case 'summary':
        return (
          <ArcView key={item.key} style={summaryContainerStyle}>
            <NewsfeedSummaryCard
              onSummaryRequest={this.handleSummaryRequest}
              summaries={getSummaries(item)}
            />
          </ArcView>
        );
      case 'toolbar':
        return (
          <ArcView
            key={item.key}
            marginLeft="16"
            marginRight="16"
            marginBottom="24"
          >
            <NewsfeedToolbarContainer
              onFilterSubmit={this.resetPage}
              canViewHistory={this.props.canViewHistory}
              hasFeatureNewsfeedFiltering={
                this.props.hasFeatureNewsfeedFiltering
              }
              hasFeatureNewsfeedDateFiltering={
                this.props.hasFeatureNewsfeedDateFiltering
              }
            />
          </ArcView>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <ArcView style={rootStyle} fullWidth fullHeight flex="1">
        <ArcView fullWidth fullHeight className={this.props.scrollClassName}>
          <ArcFlatList
            data={this.state.data}
            onEndReached={this.handleEndReached}
            onEndReachedThreshold={2}
            onScroll={this.handleScroll}
            renderItem={this.renderItem}
            ref={this.scrollRef}
            stickyHeaderIndices={this.state.stickyHeaderIndices}
            contentContainerStyle={contentContainerStyle}
            extraData={[
              this.props.acknowledgedById,
              this.props.canPostToNewsfeed,
              this.props.canViewHistory,
              this.props.hasFeatureNewsfeedFiltering,
              this.props.hasFeatureNewsfeedDateFiltering,
              this.props.reactionsByActivityId,
              this.props.reactionsIsRequestingByActivityId,
              this.props.user,
              this.props.commentsById,
              this.props.savingCommentId,
            ]}
          />
        </ArcView>
        <ScrollButton
          size="small"
          isVisible={!this.state.isAtTop}
          onClick={this.scrollToTop}
        >
          <KeyboardArrowUp />
        </ScrollButton>
      </ArcView>
    );
  }
}

const getState = (state, props) => ({
  activitySetsLoading: getActivitySetsLoading(state),
  activitiesById: selectors.activitiesById(state),
  acknowledgedById: acknowledgedSelectors.acknowledgedState(state),
  canPin: getCurrentUserPermission(state, PERMISSIONS.PIN_NEWSFEED_POSTS),
  canPromote: getCurrentUserPermission(
    state,
    PERMISSIONS.PROMOTE_NEWSFEED_POSTS,
  ),
  canPostToNewsfeed: getCurrentUserPermission(
    state,
    PERMISSIONS.POST_TO_NEWSFEED,
  ),
  canPostToProtectedAudiences: getCurrentUserPermission(
    state,
    PERMISSIONS.POST_TO_PROTECTED_AUDIENCES,
  ),
  canDeletePosts: getCurrentUserPermission(state, PERMISSIONS.DELETE_POSTS),
  canViewHistory: getCurrentUserPermission(
    state,
    PERMISSIONS.VIEW_POST_HISTORY,
  ),
  dateBlocks: helperSelectors.sortedNewsfeedList(state, 'main'),
  hasFeatureNewsfeedFiltering: getCurrentUserFeatures(
    state,
    FEATURE_FLAGS.NEWSFEED_FILTERING,
  ),
  hasFeatureNewsfeedDateFiltering: getCurrentUserFeatures(
    state,
    FEATURE_FLAGS.NEWSFEED_DATE_FILTERING,
  ),
  isError: setLoadingSelectors.loadingStateForSet(state, 'main', 'ERROR'),
  isLoading: setLoadingSelectors.loadingStateForSet(state, 'main', 'REQUEST'),
  isViewingProtectedAudience: getIsViewingProtectedAudience(state),
  locationQuery: getLocationQuery(state),
  pinnedActivities: helperSelectors.pinnedActivityList(state),
  reactionsByActivityId: getReactionsByActivityId(state),
  reactionsIsRequestingByActivityId: getReactionsIsRequestingByActivityId(
    state,
  ),
  requestStatus: getRequestStatus(state),
  user: getCurrentUser(state),
  commentsById: getCommentsById(state),
  savingCommentId: getCommentCreateActivityId(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      acknowledgePin: actions.acknowledgePinAndUpdateActivity,
      onActivityAcksRequest: actions.getActivityAcks,
      onActivityCommentsRequest: actions.getActivityComments,
      onActivityEdit: actions.editActivity,
      onActivityIndexRequest: actions.activityIndexRequest,
      onActivityViewsRequest: actions.getActivityViews,
      onActivitiesClear: actions.clearActivities,
      onCommentDelete: actions.deleteCommentAndUpdateActivity,
      onCommentSave: actions.saveCommentAndUpdateActivity,
      onCommentReactionSelect: actions.saveCommentReaction,
      onReactionSelect: actions.saveActivityReaction,
      onRespect: actions.toggleRespectAndUpdateActivity,
      onSummaryRequest: actions.getSummaryActivities,
      onQuicklinkUser: quicklinkActions.showQuicklinksUser,
      // createComment: () => {}
    },
    dispatch,
  );

export default compose(
  connect(getState, getActions),
  withResourceAction(resources.apiCreateComment),
)(NewsfeedActivitiesContainer);
