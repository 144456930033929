import React from 'react';
import PropTypes from 'prop-types';
import { Platform } from 'react-native';
import MuiIconButton from '@material-ui/core/IconButton';
import ArcButton from '../ArcButton';

const IconButton = Platform.OS === 'web' ? MuiIconButton : ArcButton;

const style = {
  borderRadius: 0,
};

const ArcIconButton = props => (
  <IconButton
    aria-label={props.label ? props.label : undefined}
    {...props}
    style={{ ...style, ...props.style }}
  >
    {props.children}
  </IconButton>
);

ArcIconButton.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  style: PropTypes.shape({}),
};

ArcIconButton.defaultProps = {
  children: undefined,
  label: '',
  style: undefined,
};

export default ArcIconButton;
