import { reducer as location } from '../store/routes';
import { getAppPlatform } from './platform';

export default location;

export const getLocation = state => state.location;
export const getLocationType = state => state.location.type;
export const getLocationPayload = state => state.location.payload;
export const getLocationQuery = state => state.location.query || {};

export const getRouteState = state => ({
  route: getLocationType(state),
  payload: getLocationPayload(state),
  platform: getAppPlatform(state),
});
