import { ArcPropTypes } from 'arcade-frontend-ui';
import * as types from 'arcade-frontend-core/src/types/routes';

import ManageView from '../../views/ManageView';

const { PLATFORMS } = ArcPropTypes;

const base = {
  [types.ROUTE_MANAGE]: ManageView,

  [types.ROUTE_MANAGE_ACCOUNT]: ManageView,

  [types.ROUTE_MANAGE_ACTIVITIES]: ManageView,

  [types.ROUTE_MANAGE_BILLING]: ManageView,
  [types.ROUTE_MANAGE_BILLING2]: ManageView,

  [types.ROUTE_MANAGE_CHECKLISTS]: ManageView,
  [types.ROUTE_MANAGE_CHECKLISTS_SHOW]: ManageView,
  [types.ROUTE_MANAGE_CHECKLISTS_CREATE]: ManageView,
  [types.ROUTE_MANAGE_CHECKLISTS_EDIT]: ManageView,

  [types.ROUTE_MANAGE_ENGAGEMENT]: ManageView,

  [types.ROUTE_MANAGE_INTEGRATIONS]: ManageView,

  [types.ROUTE_MANAGE_METRICS]: ManageView,
  [types.ROUTE_MANAGE_METRICS_SHOW]: ManageView,
  [types.ROUTE_MANAGE_METRICS_CREATE]: ManageView,
  [types.ROUTE_MANAGE_METRICS_EDIT]: ManageView,

  [types.ROUTE_MANAGE_GAMES]: ManageView,
  [types.ROUTE_MANAGE_GAMES_SHOW]: ManageView,
  [types.ROUTE_MANAGE_GAMES_CREATE]: ManageView,
  [types.ROUTE_MANAGE_GAMES_EDIT]: ManageView,
  [types.ROUTE_MANAGE_GAMES_CLONE]: ManageView,

  [types.ROUTE_MANAGE_INTEGRATION_HISTORY]: ManageView,

  [types.ROUTE_MANAGE_REWARDS]: ManageView,
  [types.ROUTE_MANAGE_REWARDS_CREATE]: ManageView,
  [types.ROUTE_MANAGE_REWARDS_EDIT]: ManageView,
  [types.ROUTE_MANAGE_REWARDS_SHOW]: ManageView,

  [types.ROUTE_MANAGE_PEOPLE]: ManageView,

  [types.ROUTE_MANAGE_POSTS]: ManageView,

  [types.ROUTE_MANAGE_TOKENS]: ManageView,
  [types.ROUTE_MANAGE_TOKEN_BUDGETS]: ManageView,
  [types.ROUTE_MANAGE_TEAMS]: ManageView,

  [types.ROUTE_MANAGE_TRANSACTIONS]: ManageView,
};

const benjo = base;
const development = base;
const launch = base;

export default {
  [PLATFORMS.BENJO]: benjo,
  [PLATFORMS.DEVELOPMENT]: development,
  [PLATFORMS.LAUNCH]: launch,
};
