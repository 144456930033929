import getNormalizedEntities from 'arcade-frontend-core/src/helpers/getNormalizedEntities';

export const videoNormalizer = video => ({
  ...video,
  id: video.id.toString(),
  createdAt: video.created_at,
  user: {
    id: video.user.id.toString(),
    name: video.user.name.toString(),
    imageUrl: video.user.profile_image.toString(),
  },
});

export const fileNormalizer = file => ({
  ...file,
  id: file.id.toString(),
  createdAt: file.created_at,
  user: {
    id: file.user.id.toString(),
    name: file.user.name.toString(),
    imageUrl: file.user.profile_image.toString(),
  },
});

export const entityLevelNormalizer = entityLevel => ({
  ...entityLevel,
  id: entityLevel.id.toString(),
});

export const audienceNormalizer = audience => ({
  ...audience,
  id: audience.id.toString(),
});

export const createCustomAudience = payload => ({
  name: payload.name || 'Custom Audience',
  entity_ids: payload.group,
  excluded_user_ids: payload.excluded,
  team_ids: payload.team,
  user_ids: payload.person,
});

export const getCustomAudienceEntities = getNormalizedEntities;
