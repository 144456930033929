import {
  createReducer,
} from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/games';

const initialState = {};

const handleManageGamesError = (state, action) => ({ ...state, ...action.payload });

const handlers = {
  [types.MANAGE_GAMES_CREATE.FAILURE]: handleManageGamesError,
};

const manageGamesErrors = createReducer(initialState, handlers);

export const getManageGamesErrors = state => state.manage.games.manageGamesErrors;

export default manageGamesErrors;
