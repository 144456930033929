import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ArcLinkPreviewList from 'arcade-frontend-ui/src/components/ArcLinkPreview/ArcLinkPreviewList';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import { getCurrentUserFeatures } from 'arcade-frontend-core/src/reducers/user';

const ArcLinkPreviewContainer = ({ shouldRender, ...props }) =>
  shouldRender && <ArcLinkPreviewList {...props} />;

ArcLinkPreviewContainer.propTypes = {
  shouldRender: PropTypes.bool,
  text: PropTypes.string,
};

ArcLinkPreviewContainer.defaultProps = {
  shouldRender: false,
  text: null,
};

const getState = (state, ownProps) => ({
  shouldRender: getCurrentUserFeatures(state, FEATURE_FLAGS.LINK_UNFURLING),
  ...ownProps,
});

export default connect(getState)(ArcLinkPreviewContainer);
