import { createSelector } from 'reselect';
import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const handlePeopleIndexSuccess = (state, action) => {
  const { data } = action.payload;
  const newState = {};

  data.forEach(person => {
    newState[person.id] = person;
  });

  return newState;
};

const initialState = {};

const handlers = {
  [types.PEOPLE_INDEX.SUCCESS]: handlePeopleIndexSuccess,
};

const peopleById = createReducer(initialState, handlers);

export default peopleById;

export const getPeopleById = state => state.people.people;

export const getPersonById = id => state => state.app.peopleById[id];

const sortByName = byId => {
  const ids = Object.keys(byId);

  return ids.sort((a, b) => {
    if (byId[a].name < byId[b].name) {
      return -1;
    }

    if (byId[a].name > byId[b].name) {
      return 1;
    }

    return 0;
  });
};

export const getSortedPeople = createSelector(getPeopleById, byId =>
  sortByName(byId).map(id => byId[id]),
);

export const getSortedPeopleIds = createSelector(
  getSortedPeople,
  sortedPeople => sortedPeople.map(person => person.id),
);
