import React from 'react';

import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { fade } from '@material-ui/core/styles/colorManipulator';

import ArcEmoji from '../ArcEmoji';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';
import createWithStyles from '../../styles/createWithStyles';

const styles = {
  Reaction: theme => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '100%',
      minWidth: 42,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: 0,
      paddingBottom: theme.spacing(0.25),
      borderRadius: 4,
    },
    isSelected: {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
    },
    isStrong: {
      fontWeight: '700',
    },
    disabled: {
      opacity: '0.2',
    },
  }),
};

const ReactionButton = createWithStyles(styles.Reaction)(Button);
const ReactionView = createWithStyles(styles.Reaction)(ArcView);

const emojiStyle = {
  lineHeight: '14px',
};

function ArcEmojiButton({
  disabled,
  emoji,
  label,
  onClick,
  total,
  ...props
}) {
  const Component = onClick ? ReactionButton : ReactionView;

  return (
    <Component
      variant="outlined"
      onClick={onClick}
      {...props}
    >
      <ArcEmoji
        role="img"
        label={label}
        emoji={emoji}
      />
      {!!total && (
        <ArcView paddingTop="4" style={emojiStyle}>
          <ArcText isSmaller color={disabled ? 'disabled' : 'inherit'}>
            {total}
          </ArcText>
        </ArcView>
      )}
    </Component>
  );
}

ArcEmojiButton.propTypes = {
  disabled: PropTypes.bool,
  emoji: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  total: PropTypes.number,
};

ArcEmojiButton.defaultProps = {
  disabled: false,
  emoji: '',
  label: '',
  onClick: null,
  total: 0,
};

export default ArcEmojiButton;
