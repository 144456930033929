/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';

import { ArcView } from 'arcade-frontend-ui';

class ArcVideo extends React.Component {
  static propTypes = {
    autoplay: PropTypes.bool,
    controlBar: PropTypes.shape({
      volumePanel: PropTypes.shape({
        inline: PropTypes.bool,
      }),
    }),
    controls: PropTypes.bool,
    fill: PropTypes.bool,
    fluid: PropTypes.bool,
    fullHeight: PropTypes.bool,
    onPlayerReady: PropTypes.func,
    sourcesByEncoding: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          ssl_url: PropTypes.string,
          url: PropTypes.string,
          mime: PropTypes.string.isRequired,
        }),
      ),
    ),
  };

  static defaultProps = {
    autoplay: false,
    controlBar: {
      volumePanel: {
        inline: false,
      },
    },
    controls: true,
    fill: true,
    fluid: true,
    fullHeight: false,
    onPlayerReady: global.noop,

    sourcesByEncoding: {},
  };

  constructor(props) {
    super(props);

    this.player = null;
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    this.setup();
  }

  componentWillUnmount() {
    this.destroy();
  }

  setup() {
    const {
      autoplay,
      controlBar,
      controls,
      fluid,
      fill,
      sourcesByEncoding,
    } = this.props;

    const sources = Object.values(sourcesByEncoding).map(([item]) => ({
      src: item.ssl_url || item.url,
      type: item.mime,
    }));

    const playerProps = {
      autoplay,
      controlBar,
      controls,
      fill,
      fluid,
      sources,
    };

    if (this.videoRef.current) {
      this.player = videojs(
        this.videoRef.current,
        playerProps,
        this.handlePlayerReady,
      );
      this.videoRef.current.addEventListener(
        'contextmenu',
        this.handleContextMenu,
      );

      this.player.bigPlayButton.on(['tap', 'click'], this.handleClickPlay);

      this.player.bigPlayButton.addClass('arc-button--contained');
      this.player.bigPlayButton.addClass('arc-button--primary');
    }
  }

  destroy() {
    if (this.player) {
      this.player.dispose();
    }
    if (this.videoRef.current) {
      this.videoRef.current.removeEventListener(
        'contextmenu',
        this.handleContextMenu,
      );
    }
  }

  handleContextMenu = e => {
    e.preventDefault();
  };

  handlePlayerReady = () => {
    this.props.onPlayerReady();
  };

  handleClickPlay = () => {
    // this.player.requestFullscreen();
  };

  get posterImage() {
    try {
      return this.props.sourcesByEncoding.thumbnail[0].ssl_url;
    } catch {
      return null;
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <ArcView
        fullWidth
        fullHeight={this.props.fullHeight}
        overflow="hidden"
        position="relative"
      >
        <div data-vjs-player>
          <video
            className="video-js"
            poster={this.posterImage}
            ref={this.videoRef}
          />
        </div>
      </ArcView>
    );
  }
}

export default ArcVideo;
