import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import { ArcButton, ArcDialog } from 'arcade-frontend-ui';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';

const CONFIRM_WITHDRAW_MESSAGE = `Are you sure? This will undo all sales that were
created by the integration run. This cannot be reversed and you will need to upload the sheet again.`;

const IntegrationHistoryWithdrawDialog = ({
  open,
  onClose,
  onConfirm,
  status,
}) => (
  <ArcDialog open={open} onClose={onClose}>
    <DialogTitle id="confirm-dialog-title">
      Withdraw integration run
    </DialogTitle>

    <DialogContent>
      <DialogContentText id="confirm-dialog-description">
        {CONFIRM_WITHDRAW_MESSAGE}
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      <ArcButton onClick={onClose}>Close</ArcButton>

      <ArcResourceButton
        onClick={onConfirm}
        color="blue"
        variant="outlined"
        label="Withdraw"
        status={status}
      />
    </DialogActions>
  </ArcDialog>
);

IntegrationHistoryWithdrawDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  status: PropTypes.shape({
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    succeeded: PropTypes.bool,
    failed: PropTypes.bool,
  }),
};

IntegrationHistoryWithdrawDialog.defaultProps = {
  open: false,
  status: {},
};

export default IntegrationHistoryWithdrawDialog;
