import React from 'react';

import Cloud from '../../assets/Cloud';

const CLOUDS = [
  {
    id: 0,
    style: {
      animationDuration: '28s',
      position: 'absolute',
      top: 0,
      left: -4,
      transform: 'scale(1.8)',
    },
  },
  {
    id: 1,
    style: {
      animationDuration: '42s',
      position: 'absolute',
      top: 15,
      left: 59,
      transform: 'scale(0.7)',
    },
  },
  {
    id: 2,
    style: {
      animationDuration: '27s',
      position: 'absolute',
      top: 30,
      left: 86,
      transform: 'scale(1.7)',
    },
  },
  {
    id: 3,
    style: {
      animationDuration: '19s',
      position: 'absolute',
      top: 45,
      left: -10,
      transform: 'scale(1.6)',
    },
  },
  {
    id: 4,
    style: {
      animationDuration: '48s',
      position: 'absolute',
      top: 60,
      left: -164,
      transform: 'scale(0.9)',
    },
  },
  {
    id: 5,
    style: {
      animationDuration: '19s',
      position: 'absolute',
      top: 60,
      left: 305,
      transform: 'scale(1.6)',
    },
  },
  {
    id: 6,
    style: {
      animationDuration: '42s',
      position: 'absolute',
      top: 75,
      left: 224,
      transform: 'scale(0.7)',
    },
  },
  // {
  //   id: 7,
  //   style: {
  //     animationDuration: '28s',
  //     position: 'absolute',
  //     top: 90,
  //     left: -251,
  //     transform: 'scale(1)',
  //   },
  // },
  // {
  //   id: 8,
  //   style: {
  //     animationDuration: '47s',
  //     position: 'absolute',
  //     top: 105,
  //     left: 295,
  //     transform: 'scale(0.9)',
  //   },
  // },
  // {
  //   id: 9,
  //   style: {
  //     animationDuration: '32s',
  //     position: 'absolute',
  //     top: 120,
  //     left: 346,
  //     transform: 'scale(0.6)',
  //   },
  // },
  // {
  //   id: 10,
  //   style: {
  //     animationDuration: '46s',
  //     position: 'absolute',
  //     top: 125,
  //     left: 363,
  //     transform: 'scale(0.4)',
  //   },
  // },
];

const Clouds = props => CLOUDS.map(cloud => (
  <Cloud
    key={cloud.id}
    className="Cloud animate--cloud"
    width="60"
    height="35"
    {...cloud}
    {...props}
  />
));

export default Clouds;
