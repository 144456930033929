import RewardItem from './RewardItem';

export default class Reward extends RewardItem {
  getInitialState() {
    return {};
  }

  get finalPurchaseTokenValue() {
    return this.tokenCost;
  }

  get tokenCost() {
    return this.props.rewardTokenCost;
  }

  get rewardItemDisabled() {
    return (
      this.tokenCost > this.props.userBalance || this.props.userBalance === 0
    );
  }

  get rewardItemSelected() {
    return !this.rewardItemDisabled;
  }

  // eslint-disable-next-line class-methods-use-this
  rewardItemControls() {
    return null;
  }
}
