import { makeApiActionTypes } from 'arcade-frontend-ui';

export const GAMES_INDEX = makeApiActionTypes('GAMES_INDEX');

export const GAMES_GET = makeApiActionTypes('GAMES_GET');

export const GAMES_RANKINGS_INDEX = makeApiActionTypes('GAMES_RANKINGS_INDEX');
export const GAMES_EVENTS_INDEX = makeApiActionTypes('GAMES_EVENTS_INDEX');
export const GAMES_ACTIVITIES_INDEX = makeApiActionTypes('GAMES_ACTIVITIES_INDEX');

export const GAMES_ACTIVITIES_CREATE = makeApiActionTypes('GAMES_ACTIVITIES_CREATE');
