import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { useTheme } from '@material-ui/core/styles';

import ArcView from '../../primitives/ArcView';

import ArcMultiSelectControl from './ArcMultiSelectControl';
import ArcMultiSelectEmpty from './ArcMultiSelectEmpty';
import ArcMultiSelectMenu from './ArcMultiSelectMenu';
import ArcMultiSelectOption from './ArcMultiSelectOption';
import ArcMultiSelectPlaceholder from './ArcMultiSelectPlaceholder';
import ArcMultiSelectValue from './ArcMultiSelectValue';
import ArcMultiSelectValueContainer from './ArcMultiSelectValueContainer';

const components = {
  Control: ArcMultiSelectControl,
  Menu: ArcMultiSelectMenu,
  MultiValue: ArcMultiSelectValue,
  NoOptionsMessage: ArcMultiSelectEmpty,
  Option: ArcMultiSelectOption,
  Placeholder: ArcMultiSelectPlaceholder,
  SingleValue: ArcMultiSelectValue,
  ValueContainer: ArcMultiSelectValueContainer,
};

function ArcMultiSelect({
  disabled,
  id,
  label,
  onChange,
  options,
  placeholder,
  renderItem,
  renderValue,
  value,
  ...props
}) {
  const theme = useTheme();

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <ArcView fullWidth>
      <Select
        components={components}
        isDisabled={disabled}
        inputId={id}
        isMulti
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        styles={selectStyles}
        renderValue={renderValue}
        renderItem={renderItem}
        TextFieldProps={{
          label,
          InputLabelProps: {
            htmlFor: id,
            shrink: true,
          },
          ...props,
        }}
        value={value}
      />
    </ArcView>
  );
}

ArcMultiSelect.displayName = 'ArcMultiSelect';

ArcMultiSelect.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
  renderItem: PropTypes.func,
  renderValue: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
  })),
};

ArcMultiSelect.defaultProps = {
  disabled: false,
  id: '',
  label: '',
  onChange: global.noop,
  options: [],
  placeholder: '',
  renderItem: undefined,
  renderValue: undefined,
  value: [],
};

export default ArcMultiSelect;
