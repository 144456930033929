class ResourceError extends Error {
  constructor(payload = {}, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor

    if (params.length === 0) {
      super(payload.data);
    } else {
      super(...params);
    }

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResourceError);
    }

    if (payload.data) {
      this.data = payload.data;

      if (payload.data.toJSON) {
        this.data = payload.data.toJSON();
      }
    }

    this.payload = payload;
    this.meta = payload.meta;
  }

  get message() {
    if (this?.payload?.data?.response?.data?.error) {
      return this.payload.data.response.data.error;
    }

    return '';
  }
}

export default ResourceError;
