import { createRequestingStatusReducer } from 'arcade-frontend-ui';

import * as types from '../actions';

const initialState = {
  ACTIVITY_HISTORY_INDEX: 'DEFAULT',
  ACTIVITY_HISTORY_DETAILS: 'DEFAULT',
  ACTIVITY_HISTORY_DELETE: 'DEFAULT',
  NEWSFEED_CREATE_ACTIVITY: 'DEFAULT',
  NEWSFEED_UPDATE_ACTIVITY: 'DEFAULT',
  NEWSFEED_GET_ACTIVITY_VIEWS: 'DEFAULT',
  NEWSFEED_GET_ACTIVITY_RESPECTS: 'DEFAULT',
  NEWSFEED_GET_ACTIVITY_ACKS: 'DEFAULT',
  NEWSFEED_GET_ACTIVITY_COMMENTS: 'DEFAULT',
  NEWSFEED_TAGGED_ACTIVITIES: 'DEFAULT',
  NEWSFEED_ACTIVITY: 'DEFAULT',
};

const apiActionTypes = [
  types.ACTIVITY_HISTORY_INDEX,
  types.ACTIVITY_HISTORY_DETAILS,
  types.ACTIVITY_HISTORY_DELETE,
  types.NEWSFEED_CREATE_ACTIVITY,
  types.NEWSFEED_UPDATE_ACTIVITY,
  types.NEWSFEED_GET_ACTIVITY_VIEWS,
  types.NEWSFEED_GET_ACTIVITY_RESPECTS,
  types.NEWSFEED_GET_ACTIVITY_ACKS,
  types.NEWSFEED_GET_ACTIVITY_COMMENTS,
  types.NEWSFEED_TAGGED_ACTIVITIES,
  types.NEWSFEED_ACTIVITY,
];

const requestStatus = createRequestingStatusReducer(
  initialState,
  apiActionTypes,
);

export default requestStatus;

export const getRequestStatus = state => state.newsfeed.requestStatus;
