import { combineReducers } from 'redux';

import isFetching from './isFetching';
import people from './people';
import teams from './teams';
import quicklinkUser from './quicklinkUser';
import teamPeople from './teamPeople';
import peopleDisplayType from './peopleDisplayType';

export default combineReducers({
  isFetching,
  people,
  peopleDisplayType,
  quicklinkUser,
  teamPeople,
  teams,
});
