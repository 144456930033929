import { actions } from 'arcade-frontend-core/src/actions';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';

import store from '../../store';

export const openSupportWidget = () => {
  if (global?.window?.zE) {
    const { fullname, email } = getCurrentUser(store.getState());
    window.zE('webWidget', 'identify', { name: fullname, email });
    global.window.zE.activate();
  }
};

export const closeSupportWidget = () => {
  if (global?.window?.zE) {
    global.window.zE.hide();
  }
};

export const toggleSupportWidget = () => {
  if (global?.window?.zE) {
    if (window.zE('webWidget:get', 'display') === 'hidden') {
      openSupportWidget();
    } else {
      closeSupportWidget();
    }
  }
};

const handleOnLoad = () => {
  store.dispatch(actions.setSupportWidgetLoaded(true));
};

export const attachSupportWidget = () => {
  window.zESettings = {
    webWidget: {
      chat: {
        suppress: true,
      },
    },
  };

  if (document) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.async = true;
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=263adcd8-fb0b-4048-a3fb-8d9e4e55c0e3';
    script.onload = handleOnLoad;
    document.head.appendChild(script);
  }
};
