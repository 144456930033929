import createResourceAction from 'arcade-frontend-core/src/helpers/createResourceAction';

export const apiGetNotificationsActivity = createResourceAction('read', {
  resourceType: 'notifications',
  requestKey: 'apiGetNotificationsActivity',

  api: () => ({
    method: 'GET',
    url: '/v2/notifications',
    transformResponse: data => ({
      resources: [data],
    }),
  }),
});

export const apiGetNotificationsHistory = createResourceAction('read', {
  resourceType: 'notifications',
  requestKey: 'apiGetNotificationsHistory',

  api: () => ({
    method: 'GET',
    url: '/v2/notifications',
    transformResponse: data => ({
      resources: [data],
    }),
  }),
});

export const apiGetNotificationsMyInbox = createResourceAction('read', {
  resourceType: 'notifications',
  requestKey: 'apiGetNotificationsMyInbox',

  api: () => ({
    method: 'GET',
    url: '/v2/notifications',
    transformResponse: data => ({
      resources: [data],
    }),
  }),
});

export const apiPutNotificationsMarkAsRead = createResourceAction('update', {
  resourceType: 'notifications',
  requestKey: 'apiPutNotificationsMarkAsRead',

  api: () => ({
    method: 'PUT',
    url: '/v2/notifications',
  }),
});
