import { actions } from 'arcade-frontend-core/src/actions';
import { getAsyncStorageCurrentUser } from 'arcade-frontend-core/src/helpers/asyncStorage';
import { resources } from 'arcade-frontend-core/src/resources/users';
import getResource from 'arcade-frontend-core/src/helpers/getResource/getResource';
import navigateToEntryRoute from 'arcade-frontend-core/src/helpers/navigateToEntryRoute';
import RollbarClient from 'arcade-frontend-ui/src/providers/RollbarProvider/client';
import { removeBootLogo } from 'arcade-frontend-core/src/helpers/removeBootLogo';
import * as auth from 'arcade-frontend-core/src/helpers/auth';
import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';

import {
  getLocationType,
  getLocationQuery,
  getLocationPayload,
} from './reducers/location';

import './postMessageListener';
import store from './store';
import * as supportWidget from './helpers/supportWidget';

async function startApp() {
  const state = store.getState();
  const locationType = getLocationType(state);
  const locationPayload = getLocationPayload(state);

  const isRtpos = process.env.ARCADE_WHITELABEL_ID === 'rtpos';

  if (isRtpos) {
    return null;
  }

  supportWidget.attachSupportWidget();

  if (locationType === ROUTE_TYPES.ROUTE_LOGIN_VIA_LINK) {
    store.dispatch(actions.setAppReady(true));
    return null;
  }

  let currentUser;

  try {
    currentUser = await getAsyncStorageCurrentUser();
  } catch (error) {
    console.error('[features/start-app] getAsyncStorageCurrentUser', { error });
  }

  if (!currentUser || !currentUser.token) {
    const locationQuery = getLocationQuery(state);

    await auth.signOut();

    const shouldRedirectToLogin =
      locationType !== ROUTE_TYPES.ROUTE_LOGIN &&
      locationType !== ROUTE_TYPES.ROUTE_LOGIN_VIA_LINK;

    if (shouldRedirectToLogin) {
      store.dispatch({
        type: ROUTE_TYPES.ROUTE_LOGIN,
        query: locationQuery,
        payload: locationPayload,
      });
    }

    store.dispatch(actions.setAppReady(true));

    return null;
  }

  store.dispatch(
    actions.setCurrentUser({
      currentUser,
      endpoint: currentUser.endpoint,
      chatEndpoint: currentUser.chatEndpoint,
    }),
  );

  await auth.signIn(currentUser);

  const source = global.Platform.select({
    web: 'launch',
    default: 'app',
  });

  const featuresRequest = getResource(
    resources.apiGetUserFeatures,
    { resources: [{ source }] },
    {
      baseURL: currentUser.endpoint,
      transformResponse: [],
    },
  )
    .then(response => {
      if (window?.setArcadeFeatures) {
        window.setArcadeFeatures(response.data.features);
      }

      store.dispatch(actions.appFeaturesSuccess(response));
    })
    .catch(error => {
      console.error('[features/start-app] features request failed', error);
      RollbarClient.error('[features/start-app] features request failed', {
        ...error,
      });
      throw error;
    });

  const permissionsRequest = getResource(
    resources.apiGetCurrentUserPermissions,
    { resources: [{ source }] },
    {
      baseURL: currentUser.endpoint,
      transformResponse: [],
    },
  )
    .then(response => {
      store.dispatch(actions.appPermissionsSuccess(response));
    })
    .catch(error => {
      console.error('[features/start-app] permissions request failed', error);
      RollbarClient.error('[features/start-app] permissions request failed', {
        ...error,
      });
      throw error;
    });

  try {
    await Promise.all([featuresRequest, permissionsRequest]).then(async () => {
      store.dispatch(actions.setAppReady(true));
      navigateToEntryRoute(state.location, store.dispatch);
      removeBootLogo();
    });
  } catch (error) {
    throw error;
  }

  return currentUser;
}

export default startApp;
