import React from 'react';

import {
  ArcPropTypes,
  ArcHeading,
  ArcParagraph,
  ArcText,
  ArcView,
} from 'arcade-frontend-ui';

import {
  styleInformationContainer,
  styleInformationCurrency,
  styleInformationRewardContainer,
  styleInformationText,
  styleInformationDetailsContainer,
  styleInformationRow,
  styleInformationNumber,
  styleInformationRewardText,
  styleInformationRewardImageContainer,
  styleInformationRewardImage,
} from './MetricBenchmarkInformation.style';

class MetricBenchmarkInformation extends React.Component {
  static propTypes = {
    benchmarkValue: ArcPropTypes.number,
    progress: ArcPropTypes.number,
    symbol: ArcPropTypes.string,
  };

  static defaultProps = {
    benchmarkValue: null,
    progress: null,
    symbol: null,
  };

  renderProgressDifference() {
    const difference = this.props.benchmarkValue - this.props.progress;
    const displayDifference = this.props.symbol === '$'
      ? Math.abs(difference).toFixed(2)
      : Math.abs(difference).toFixed(0);
    const symbol = this.props.symbol === '$' ? '$' : null;
    const message = this.props.progress >= this.props.benchmarkValue ? 'Over the benchmark! You champ!' : 'Left to go';
    const style = this.props.symbol === '$' ? styleInformationCurrency : styleInformationNumber;

    return (
      <ArcView
        row
        align="center"
        style={styleInformationRow}
      >
        <ArcHeading style={style}>
          {symbol}
          {' '}
          {displayDifference}
        </ArcHeading>
        <ArcText style={styleInformationText}>
          {message}
        </ArcText>
      </ArcView>
    );
  }

  renderProgress() {
    return this.props.progress === this.props.benchmarkValue ? (
      <ArcView
        row
        align="center"
        style={styleInformationRow}
      >
        <ArcHeading style={styleInformationNumber}>
          0
        </ArcHeading>
        <ArcText style={styleInformationText}>
          Left to go
        </ArcText>
      </ArcView>
    )
      : this.renderProgressDifference();
  }

  renderBenchmark() {
    const { benchmarkValue } = this.props;
    const benchmarkValueDisplayed = this.props.symbol === '$'
      ? Math.abs(benchmarkValue).toFixed(2)
      : Math.abs(benchmarkValue).toFixed(0);
    const symbol = this.props.symbol === '$' ? '$' : null;
    const style = this.props.symbol === '$' ? styleInformationCurrency : styleInformationNumber;

    return (
      <ArcView
        row
        align="center"
        style={styleInformationRow}
      >
        <ArcHeading style={style}>
          {symbol}
          {' '}
          {benchmarkValueDisplayed}
        </ArcHeading>
        <ArcText style={styleInformationText}>
          Expected Today
        </ArcText>
      </ArcView>
    );
  }

  renderReward() {
    const message = this.props.progress >= this.props.benchmarkValue ? 'You have reached your goal! Head over to Rewards to open your chest.' : 'Complete your daily goal and receive a common chest!';

    return (
      <ArcView
        style={styleInformationRewardContainer}
      >
        <ArcView
          row
          align="center"
          style={styleInformationRewardImageContainer}
        >
          <img
            src="https://d17rkelr6jjme9.cloudfront.net/chests/common.svg"
            alt="Common Chest"
            style={styleInformationRewardImage}
          />
          <ArcParagraph
            style={styleInformationRewardText}
          >
            {message}
          </ArcParagraph>
        </ArcView>
      </ArcView>
    );
  }

  renderContent() {
    return (
      <ArcView>

        <ArcView
          align="flex-start"
          style={styleInformationDetailsContainer}
        >
          <ArcView
            row
            align="center"
            style={styleInformationRow}
          >
            {this.renderBenchmark()}
          </ArcView>
          {this.renderProgress()}
        </ArcView>
        {this.renderReward()}
      </ArcView>
    );
  }

  render() {
    return (
      <ArcView style={styleInformationContainer}>
        { this.renderContent() }
      </ArcView>
    );
  }
}

export default MetricBenchmarkInformation;
