import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ArcBox from '../ArcBox';
import ArcText from '../../primitives/ArcText';

const useStyles = makeStyles(theme => ({
  iconComponent: {
    color: theme.palette.grey[300],
    fontSize: theme.font.getFontSize(14),
    alignSelf: 'center',
    position: 'relative',
    top: '50%',
    transform: 'translate(-11.5%, -50%)',
  },
}));

function ArcNoResultsMessage({
  header,
  subheader,
  iconComponent: IconComponent,
  iconStyles,
  shapeStyles,
  showShape,
  ...props
}) {
  const classes = useStyles();

  return (
    <ArcBox
      display="flex"
      flexDirection="column"
      margin="auto"
      {...props}
    >
      {showShape ? (
        <ArcBox
          alignSelf="center"
          bgcolor="grey.100"
          borderRadius="50%"
          height={175}
          width={175}
          margin={2}
          style={{ ...shapeStyles }}
        >
          <IconComponent
            className={classes.iconComponent}
            style={{ ...iconStyles }}
          />
        </ArcBox>
      ) : (
        <IconComponent
          className={classes.iconComponent}
          style={{ ...iconStyles }}
        />
      )}

      <ArcBox
        color="grey.600"
        fontSize="h5.fontSize"
        lineHeight="h5.lineHeight"
        fontWeight="400"
        margin={0.5}
        textAlign="center"
      >
        <ArcText>{header}</ArcText>
      </ArcBox>
      {subheader && (
        <ArcBox
          color="grey.500"
          fontSize="body.fontSize"
          lineHeight={1.6}
          textAlign="center"
        >
          <ArcText>{subheader}</ArcText>
        </ArcBox>
      )}
    </ArcBox>
  );
}

ArcNoResultsMessage.displayName = 'ArcNoResultsMessage';

ArcNoResultsMessage.propTypes = {
  iconComponent: PropTypes.elementType,
  iconStyles: PropTypes.objectOf(PropTypes.any),
  shapeStyles: PropTypes.objectOf(PropTypes.any),
  header: PropTypes.node,
  subheader: PropTypes.node,
  showShape: PropTypes.bool,
};

ArcNoResultsMessage.defaultProps = {
  iconComponent: SearchIcon,
  iconStyles: {},
  shapeStyles: {},
  header: 'No results found',
  subheader: null,
  showShape: true,
};

export default ArcNoResultsMessage;
