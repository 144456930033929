import React from 'react';

import NotificationsPortalProvider from '../../providers/NotificationsPortalProvider';
import NotificationsPortalContainer from '../../containers/NotificationsPortalContainer';

const NotificationsPortal = () => (
  <NotificationsPortalProvider>
    <NotificationsPortalContainer />
  </NotificationsPortalProvider>
);

NotificationsPortal.displayName = 'NotificationsPortal';

export default NotificationsPortal;
