import { createReducer } from 'arcade-frontend-ui';

import * as routeTypes from 'arcade-frontend-core/src/types/routes';

const setGameId = (state, action) => {
  if (!action.payload) return state;

  const { id } = action.payload;

  return id.toString();
};

const handleRouteManageGamesShow = setGameId;
const handleRouteManageGamesEdit = setGameId;
const handleRouteManageGamesClone = setGameId;

const initialState = null;
const reset = () => initialState;

const handlers = {
  [routeTypes.ROUTE_MANAGE_GAMES_SHOW]: handleRouteManageGamesShow,
  [routeTypes.ROUTE_MANAGE_GAMES_EDIT]: handleRouteManageGamesEdit,
  [routeTypes.ROUTE_MANAGE_GAMES_CLONE]: handleRouteManageGamesClone,
  [routeTypes.ROUTE_MANAGE_GAMES]: reset,
};

const currentGameId = createReducer(initialState, handlers);

export default currentGameId;

export const getManageCurrentGameId = state => state.manage.games.currentGameId;
export const getManageGamesCurrentGameId = state =>
  state.manage.games.currentGameId;
