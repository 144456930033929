import React from 'react';
import PropTypes from 'prop-types';

const ArcChallenge = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 360 240"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="metrics/games" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" fillRule="nonzero">
        <g id="Group">
          <g id="trophy" transform="translate(61.318681, 0.000000)">
            <g id="base" transform="translate(59.340659, 166.000000)">
              <polygon id="Shape" fillOpacity="0.769361413" fill="#FF4E32" points="81.0989011 31.3333333 37.5824176 31.3333333 44.9997795 15.5053305 51.4285069 0.666666667 67.2528118 0.666666667 73.6815392 15.5053305" />
              <path d="M108.604965,31.3333333 L9.87317862,31.3333333 C4.44288314,31.3333333 0,35.8509578 0,41.3726055 L0,74 L117.984437,74 L117.984437,41.3726055 C118.478143,35.8509578 114.03526,31.3333333 108.604965,31.3333333 Z" id="Shape" fill="#FFD15C" />
              <path d="M73.1868132,15.3797898 C69.2763862,17.3644625 65.3659592,19.3486605 61.4550644,20.8371651 L59.0112229,21.3333333 L56.5669137,20.3409969 C52.6564867,18.8524923 48.7460596,16.8682943 44.8351648,14.8836216 L51.1899011,0 L66.3432151,0 L73.1868132,15.3797898 Z" id="shadow" fill="#F1543F" />
            </g>
            <path d="M0,27.4283864 L0,47.8752273 C0,97.7454545 30.6320739,140.633659 73.615516,158.587227 C87.4492185,164.57175 102.765255,167.563773 118.575404,167.563773 C134.385552,167.563773 149.701589,164.57175 163.535292,158.587227 C206.518734,140.634136 237.150808,97.7454545 237.150808,47.8757045 L237.150808,27.4283864 L0,27.4283864 Z M217.387768,47.8752273 C217.387768,71.8128409 208.988818,93.7559318 194.661004,111.210273 C176.380771,133.152886 149.207005,147.615682 118.575404,147.615682 C87.9438028,147.615682 60.7700363,133.652114 42.4898034,111.210273 C28.6561009,94.2542045 19.7630401,71.8128409 19.7630401,47.8752273 L19.7630401,47.3764773 L216.893656,47.3764773 L216.893656,47.8752273 L217.387768,47.8752273 Z" id="Shape" fill="#F7B64C" />
            <path d="M195.824176,12.6666667 L195.824176,66.6129168 C195.824176,116.563237 165.164665,162.018076 118.681319,180 C72.1979721,161.518525 41.5384615,116.563237 41.5384615,66.1133658 L41.5384615,12.6666667 L195.824176,12.6666667 Z" id="Shape" fill="#FFD15C" />
            <g id="lip" transform="translate(34.615385, 0.000000)">
              <rect id="shadow" fill="#FFC952" x="6.92307692" y="12.6666667" width="153.626374" height="7.33333333" />
              <path d="M161.033843,0 L6.88572304,0 C3.42741561,0 0.956858281,2.99202273 0.956858281,5.98452273 C0.956858281,9.47529545 3.42694278,12.4677955 6.88572304,12.4677955 L161.033843,12.4677955 C164.49215,12.4677955 166.962707,9.47577273 166.962707,6.48327273 C166.962235,2.99202273 164.49215,0 161.033843,0 Z" id="Shape" fill="#F7B64C" />
            </g>
            <path d="M157.356328,77.8812883 C159.09323,76.1742281 158.128285,73.1868728 155.73522,72.8377014 L133.464281,69.5787684 C132.499336,69.4235811 131.688782,68.8416288 131.264206,67.9493019 L121.30597,47.6585641 C120.225231,45.4471453 117.098808,45.4471453 116.01807,47.6585641 L106.098431,67.9493019 C105.673855,68.802832 104.824703,69.4235811 103.898356,69.5787684 L81.6274172,72.8377014 C79.2343528,73.1868728 78.2694074,76.1742281 80.006309,77.8812883 L96.1015975,93.6715947 C96.7963581,94.3699375 97.1051407,95.3398581 96.9507494,96.2709818 L93.1681636,118.540357 C92.7435877,120.94576 95.2910434,122.808008 97.452521,121.644103 L117.368993,111.130165 C118.218145,110.664603 119.260286,110.664603 120.109438,111.130165 L140.02591,121.644103 C142.187387,122.769211 144.696245,120.94576 144.310267,118.540357 L140.489084,96.2709818 C140.334692,95.3010612 140.643475,94.3311407 141.338236,93.6715947 L157.356328,77.8812883 Z" id="Shape" fill="#FFFFFF" />
          </g>
          <g id="leaf" transform="translate(251.208791, 65.000000)" fill="#03C8A2">
            <path d="M62.5986708,65.0279608 C61.0460712,70.3587364 65.5513825,83.2932972 79.8676444,86 C85.035583,72.5267465 72.9058988,64.4618769 62.5986708,65.0279608 Z" id="Shape" />
            <path d="M58.102587,86 C53.5407949,93.359412 58.3395264,107.426268 68.5770091,112 C76.6688211,101.577525 70.3361192,89.170697 58.102587,86 Z" id="Shape" />
            <path d="M84.0659341,87.5816974 C91.9843936,91.6540057 108.799148,87.4708087 108.791206,68.2966432 C97.2020086,66.175155 85.7945493,75.7257311 84.0659341,87.5816974 Z" id="Shape" />
            <path d="M79.1208791,32.189037 C78.18142,24.4926431 67.3921775,14.0658088 53.4065934,24.196521 C56.7732914,32.4286225 68.8686001,36.6572831 79.1208791,32.189037 Z" id="Shape" />
            <path d="M88.1917776,58.8844044 C103.830529,60.523832 109.474169,44.3693257 105.667655,33 C92.9036741,34.3593104 86.9096073,48.5622718 88.1917776,58.8844044 Z" id="Shape" />
            <path d="M60.3296703,44.2171431 C61.6172332,56.3361481 76.0276586,61.0695317 83.0261482,59.8003338 C83.9271181,46.6821531 72.6997184,40.1065979 60.3296703,44.2171431 Z" id="Shape" />
            <path d="M93.6531569,0 C80.2924093,5.7350348 80.5804359,23.3684455 84.2805409,30 C100.22385,26.2853828 100.511877,10.2603248 93.6531569,0 Z" id="Shape" />
            <path d="M50.3595696,133 C61.5427209,125.718434 58.2943128,111.519807 47.9216295,105 C38.8122678,114.565421 45.9612866,129.307958 50.3595696,133 Z" id="Shape" />
            <path d="M53.4065934,135.994153 C59.6094001,146.746767 75.7694871,147.412709 84.0659341,133.41639 C75.239946,124.550132 61.1612371,127.39312 53.4065934,135.994153 Z" id="Shape" />
            <path d="M32.654994,119 C24.052157,123.602977 22.7887129,137.631077 27.2274914,146 C37.5852357,143.071553 41.5566335,129.431892 32.654994,119 Z" id="Shape" />
            <path d="M72.1978022,114.859359 C79.8071595,121.949668 95.1200245,121.084674 100.879121,103.133568 C88.524945,96.1532122 74.8039957,107.95026 72.1978022,114.859359 Z" id="Shape" />
            <path d="M28.6813187,151.183943 C31.908447,161.801841 44.2991007,167.97823 59.3406593,157.83843 C56.264998,151.317079 43.7585439,143.501431 28.6813187,151.183943 Z" id="Shape" />
            <path d="M0.989010989,157.54387 C0.989010989,170.13611 12.9241695,179.233209 28.6813187,172.969769 C27.9381277,167.122594 18.5544038,154.201237 0.989010989,157.54387 Z" id="Shape" />
            <path d="M13.869535,129 C6.16073537,129.874428 -2.04510369,140.787182 0.458849482,153 C12.8130919,153 19.3482221,141.511087 13.869535,129 Z" id="Shape" />
          </g>
          <g id="leaf" transform="translate(54.395604, 152.500000) scale(-1, 1) translate(-54.395604, -152.500000) translate(0.000000, 65.000000)" fill="#03C8A2">
            <path d="M62.5986708,65.0279608 C61.0460712,70.3587364 65.5513825,83.2932972 79.8676444,86 C85.035583,72.5267465 72.9058988,64.4618769 62.5986708,65.0279608 Z" id="Shape" />
            <path d="M58.102587,86 C53.5407949,93.359412 58.3395264,107.426268 68.5770091,112 C76.6688211,101.577525 70.3361192,89.170697 58.102587,86 Z" id="Shape" />
            <path d="M84.0659341,87.5816974 C91.9843936,91.6540057 108.799148,87.4708087 108.791206,68.2966432 C97.2020086,66.175155 85.7945493,75.7257311 84.0659341,87.5816974 Z" id="Shape" />
            <path d="M79.1208791,32.189037 C78.18142,24.4926431 67.3921775,14.0658088 53.4065934,24.196521 C56.7732914,32.4286225 68.8686001,36.6572831 79.1208791,32.189037 Z" id="Shape" />
            <path d="M88.1917776,58.8844044 C103.830529,60.523832 109.474169,44.3693257 105.667655,33 C92.9036741,34.3593104 86.9096073,48.5622718 88.1917776,58.8844044 Z" id="Shape" />
            <path d="M60.3296703,44.2171431 C61.6172332,56.3361481 76.0276586,61.0695317 83.0261482,59.8003338 C83.9271181,46.6821531 72.6997184,40.1065979 60.3296703,44.2171431 Z" id="Shape" />
            <path d="M93.6531569,0 C80.2924093,5.7350348 80.5804359,23.3684455 84.2805409,30 C100.22385,26.2853828 100.511877,10.2603248 93.6531569,0 Z" id="Shape" />
            <path d="M50.3595696,133 C61.5427209,125.718434 58.2943128,111.519807 47.9216295,105 C38.8122678,114.565421 45.9612866,129.307958 50.3595696,133 Z" id="Shape" />
            <path d="M53.4065934,135.994153 C59.6094001,146.746767 75.7694871,147.412709 84.0659341,133.41639 C75.239946,124.550132 61.1612371,127.39312 53.4065934,135.994153 Z" id="Shape" />
            <path d="M32.654994,119 C24.052157,123.602977 22.7887129,137.631077 27.2274914,146 C37.5852357,143.071553 41.5566335,129.431892 32.654994,119 Z" id="Shape" />
            <path d="M72.1978022,114.859359 C79.8071595,121.949668 95.1200245,121.084674 100.879121,103.133568 C88.524945,96.1532122 74.8039957,107.95026 72.1978022,114.859359 Z" id="Shape" />
            <path d="M28.6813187,151.183943 C31.908447,161.801841 44.2991007,167.97823 59.3406593,157.83843 C56.264998,151.317079 43.7585439,143.501431 28.6813187,151.183943 Z" id="Shape" />
            <path d="M0.989010989,157.54387 C0.989010989,170.13611 12.9241695,179.233209 28.6813187,172.969769 C27.9381277,167.122594 18.5544038,154.201237 0.989010989,157.54387 Z" id="Shape" />
            <path d="M13.869535,129 C6.16073537,129.874428 -2.04510369,140.787182 0.458849482,153 C12.8130919,153 19.3482221,141.511087 13.869535,129 Z" id="Shape" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ArcChallenge;

ArcChallenge.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

ArcChallenge.defaultProps = {
  height: 360,
  width: 240,
};
