import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../../actions/manage/games/types';

const handleManageGamesListSuccess = (_, action) => {
  const { data } = action.payload;
  const newState = {};
  Object.values(data).forEach(campaign => {
    newState[campaign.id] = campaign;
  });
  return newState;
};

const initialState = {};

const handlers = {
  [types.MANAGE_GAMES_LIST.SUCCESS]: handleManageGamesListSuccess,
};

export default createReducer(initialState, handlers);

export const getExtraGameDataById = state =>
  state.manage.games.extraGameDataById;
