import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconErrorOutlined from '@material-ui/icons/ErrorOutlined';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcForm from 'arcade-frontend-ui/src/components/ArcForm';
import ArcFormField from 'arcade-frontend-ui/src/components/ArcFormField';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';
import ArcResponsiveDialog from 'arcade-frontend-ui/src/components/ArcResponsiveDialog';
import ArcCollapsibleExplainer from 'arcade-frontend-ui/src/components/ArcCollapsibleExplainer';
import GameControllerTeam from 'arcade-frontend-ui/src/icons/GameControllerTeam';

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required(),
  location: Yup.string(),
  companyIdentifier: Yup.string(),
});

const INITIAL_VALUES = {
  name: '',
  location: '',
  companyIdentifier: '',
};

const useStyles = makeStyles(theme => ({
  iconGameController: {
    height: 32,
    width: 32,
    top: '50%',
    transform: 'translate(2%, -50%)',
    alignSelf: 'center',
    position: 'relative',
    color: theme.palette.blue.main,
  },
  iconError: {
    color: theme.palette.red.main,
    marginRight: 4,
    height: 20,
    width: 20,
  },
}));

const STRINGS = {
  'PEOPLE/CREATE_TEAM_DESCRIPTION':
    'Teams are groups of team members who share common goals. You can manage memberships below.',
};

function PeopleCreateTeamFormModal({
  open,
  onClose,
  onSubmit,
  companyIdentifierExplainer,
  team,
  statusCreateTeam,
  statusUpdateTeam,
  hasErrorCreateTeam,
  hasErrorUpdateTeam,
}) {
  const classes = useStyles();
  const hasError = hasErrorUpdateTeam || hasErrorCreateTeam;

  const renderDialogHeader = () => {
    if (team) {
      return (
        <ArcBox fontWeight="bold" mb={1}>
          {'Edit Team Details'}
        </ArcBox>
      );
    }

    return (
      <ArcBox display="flex" flexDirection="column">
        <ArcBox
          borderRadius="50%"
          height={32}
          width={32}
          margin={1}
          bgcolor="blue.100"
        >
          <GameControllerTeam className={classes.iconGameController} />
        </ArcBox>

        <ArcBox
          component={ArcText}
          fontWeight="bold"
          fontSize={20}
          marginTop={1}
          marginBottom={1}
        >
          {'Create New Team'}
        </ArcBox>

        <ArcBox
          component={ArcText}
          fontSize={12}
          lineHeight="150%"
          color="#616264"
        >
          {STRINGS['PEOPLE/CREATE_TEAM_DESCRIPTION']}
        </ArcBox>
      </ArcBox>
    );
  };

  const renderForm = formProps => (
    <ArcResponsiveDialog open={open} onClose={onClose} maxWidth={340}>
      <DialogTitle>{renderDialogHeader()}</DialogTitle>

      <DialogContent>
        <ArcFormField
          id="name"
          name="name"
          type="text"
          label="Team Name"
          autoComplete="off"
          fullWidth
          validations={{
            isRequired: true,
          }}
        />

        <ArcFormField
          id="location"
          name="location"
          type="text"
          fullWidth
          label="Location (optional)"
        />

        <ArcFormField
          hasNoMarginBottom
          id="companyIdentifier"
          name="companyIdentifier"
          type="text"
          label="Company Identifier (optional)"
          fullWidth
          placeholder="12345-6"
          autoComplete="off"
        />

        {!!companyIdentifierExplainer &&
          companyIdentifierExplainer.description && (
            <ArcCollapsibleExplainer
              mt={0.5}
              title={companyIdentifierExplainer.label}
              body={companyIdentifierExplainer.description}
            />
          )}

        {hasError && (
          <ArcBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            className="animated fadeIn"
            mt={1}
          >
            <IconErrorOutlined className={classes.iconError} />

            <ArcBox component={ArcText} color="red.main" fontSize={12}>
              {`Unable to ${hasErrorCreateTeam ? 'create' : 'update'} team. 
               Please try again later.`}
            </ArcBox>
          </ArcBox>
        )}
      </DialogContent>

      <DialogActions>
        <ArcBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          p={2}
          pt={0}
        >
          <ArcButton
            label="Close"
            onClick={() => {
              formProps.resetForm();
              onClose();
            }}
          />
          <ArcBox ml={1}>
            <ArcResourceButton
              type="submit"
              label={team ? 'Update' : 'Create Team'}
              color="primary"
              variant="contained"
              status={team ? statusUpdateTeam : statusCreateTeam}
              onClick={formProps.handleSubmit}
            />
          </ArcBox>
        </ArcBox>
      </DialogActions>
    </ArcResponsiveDialog>
  );

  return (
    <ArcForm
      initialValues={{ ...INITIAL_VALUES, ...team }}
      validateOnBlur
      validateOnChange
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {renderForm}
    </ArcForm>
  );
}

PeopleCreateTeamFormModal.displayName = 'PeopleCreateTeamModal';

PeopleCreateTeamFormModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  team: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.string,
    companyIdentifier: PropTypes.string,
  }),
  companyIdentifierExplainer: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
  }),
  statusCreateTeam: PropTypes.objectOf(PropTypes.bool),
  statusUpdateTeam: PropTypes.objectOf(PropTypes.bool),
  hasErrorCreateTeam: PropTypes.bool,
  hasErrorUpdateTeam: PropTypes.bool,
};

PeopleCreateTeamFormModal.defaultProps = {
  open: false,
  onClose: global.noop,
  onSubmit: global.noop,
  team: null,
  companyIdentifierExplainer: {},
  statusCreateTeam: {},
  statusUpdateTeam: {},
  hasErrorCreateTeam: false,
  hasErrorUpdateTeam: false,
};

export default PeopleCreateTeamFormModal;
