/* eslint-disable */
import React from 'react';

import ArcPropTypes from '../helpers/arc/propTypes';
import ArcView from '../primitives/ArcView';
import ArcText from '../primitives/ArcText';

import { createWithStyles } from '../styles';

import ArcFormControl from './ArcFormControl';

const styles = {
  ConfirmationTextLabel: theme => ({
    root: {
      paddingTop: 16,
      paddingBottom: 16,

      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,

      ...theme.typography.body,
    },
  }),

  NoneText: {
    root: {
      opacity: 0.4,
    },
  },
};

const ConfirmationTextLabel = createWithStyles(styles.ConfirmationTextLabel)(ArcView);
const NoneText = createWithStyles(styles.NoneText)(ArcText);

const getValueContent = (key, values, fields) => {
  const field = fields[key];
  const value = values[key];

  if ((field.type === 'select' || field.type === 'radio') && value) {
    // Pull up the readable label instead of the form control value
    const fieldItem = field.items.filter(item => item.value === value)[0];

    if (fieldItem) {
      return fieldItem.label;
    } else {
      return '';
    }
  }

  const valueType = typeof value;
  switch (valueType) {
    case 'boolean':
      return value ? 'Yes' : 'No';
    case 'string':
    default:
      return value || <NoneText>N/A</NoneText>;
  }
};


class ArcFormConfirmation extends React.PureComponent {
  static propTypes = {
    fields: ArcPropTypes.objectOf(ArcPropTypes.shape({
      label: ArcPropTypes.string.isRequired,
    })).isRequired,
    fieldsToRender: ArcPropTypes.arrayOf(ArcPropTypes.shape({ key: ArcPropTypes.string.isRequired })).isRequired,
    values: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
  };

  render() {
    const { fields, fieldsToRender, values } = this.props;
    return (
      <ArcView>
        {fieldsToRender.map(({ key }) => (
          <ArcView key={key}>
            <ConfirmationTextLabel row justify="space-between">
              {fields[key].label}
              <ArcText>{getValueContent(key, values, fields)}</ArcText>
            </ConfirmationTextLabel>
          </ArcView>
        ))}
      </ArcView>
    );
  }
}

export default ArcFormConfirmation;