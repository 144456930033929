import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';

import { ArcView, ArcText } from 'arcade-frontend-ui';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 10,
    backgroundColor: '#f1f1f1',
    padding: '8px 16px',
  },

  text: {
    fontSize: theme.font.getFontSize(0.875),

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.font.getFontSize(1.1),
    },
  },
}));

function NewsfeedDateHeader({ children, className, rightElement, ...props }) {
  const classes = useStyles();
  return (
    <ArcView
      row
      align="center"
      justify="flex-start"
      marginBottom="8"
      className={cx([classes.root, className])}
      {...props}
    >
      <ArcText className={cx([classes.text])}>{children}</ArcText>
      <ArcView spacer />

      <ArcView>{rightElement}</ArcView>
    </ArcView>
  );
}

NewsfeedDateHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  rightElement: PropTypes.node,
};

NewsfeedDateHeader.defaultProps = {
  children: null,
  className: '',
  rightElement: null,
};

export default NewsfeedDateHeader;
