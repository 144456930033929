import { createWithStyles } from '../../styles';
import ArcView from '../../primitives/ArcView/ArcView';

const Spacer = {
  root: {
    flexGrow: 100,
  },
};

export default createWithStyles(Spacer)(ArcView);
