import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import ExpandLess from '@material-ui/icons/ExpandLess';

import { ArcView, createWithStyles } from 'arcade-frontend-ui';

const styles = {
  Wrapper: theme => ({
    root: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 100,
      transition: theme.transitions.create('transform'),
      transform: 'translateY(100%)',
    },
    isActive: {
      transform: 'translateY(0)',
    },
  }),

  NewsfeedFab: theme => ({
    root: {
      backgroundColor: 'white',
      width: 48,
      height: 48,
      margin: theme.spacing(1),
      boxShadow:
        'rgba(15, 15, 15, 0.03) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px',
    },
  }),
};

const NewsfeedFab = createWithStyles(styles.NewsfeedFab)(Fab);
const Wrapper = createWithStyles(styles.Wrapper)(ArcView);

const NewsfeedFAB = ({ showBackToTop, onScrollToTop }) => (
  <Wrapper isActive={showBackToTop}>
    <NewsfeedFab onClick={onScrollToTop}>
      <ExpandLess />
    </NewsfeedFab>
  </Wrapper>
);

NewsfeedFAB.propTypes = {
  showBackToTop: PropTypes.bool,
  onScrollToTop: PropTypes.func.isRequired,
};

NewsfeedFAB.defaultProps = {
  showBackToTop: false,
};

export default NewsfeedFAB;
