import React from 'react';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcNoResultsMessage from 'arcade-frontend-ui/src/components/ArcNoResultsMessage';
import ArcToken from 'arcade-frontend-ui/src/assets/ArcToken';

import GamesNoResultsBox from '../GamesNoResultsBox';

export default function () {
  return (
    <GamesNoResultsBox>
      <ArcBox maxWidth={400}>
        <ArcNoResultsMessage
          iconComponent={() => (
            <ArcToken variant="grey" height={175} width={175} />
          )}
          header="No completed games!"
          subheader="You have no completed games."
        />
      </ArcBox>
    </GamesNoResultsBox>
  );
}
