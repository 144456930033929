const normalizeReward = reward => ({
  ...reward,
  id: reward.id.toString(),
  cost: reward.tokens,
  description: reward.description,
  image: reward.image_url || reward.image,
  imageUrl: reward.image_url || reward.image,
  variants: reward.variants.map(variant => ({
    label: variant.label,
    value: variant.value ? parseInt(variant.value, 10) : '',
  })),
  variantLabel: reward.variant_label,
  hasVariant: !!reward.variants && !!reward.variants.length,
});

const rewardsIndex = reward => ({
  ...normalizeReward(reward),
  isArcade: true,
});

const customRewardsIndex = normalizeReward;

const getVariants = variants => (variants && variants.length ? variants : []);

const toRewardsCreate = reward => ({
  name: reward.name,
  variants: reward.hasVariant ? getVariants(reward.variants) : [],
  image: reward.image,
  variant_label: reward.variantLabel,
  description: reward.description,
  tokens: reward.cost,
});

const fromRewardsCreate = data => ({
  id: data.id.toString(),
  archived: data.archived,
  name: data.name,
  variants: data.variants.map(variant => ({
    label: variant.label,
    value: variant.value ? parseInt(variant.value, 10) : '',
  })),
  image: data.image_url,
  variantLabel: data.variant_label,
  description: data.description,
  cost: data.tokens,
});

const normalizers = {
  customRewardsIndex,
  rewardsIndex,
  toRewardsCreate,
  fromRewardsCreate,
};

export default normalizers;
