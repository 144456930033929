import { combineReducers } from 'redux';

import activities from './activities';
import fetchedLastActivity from './fetched_last_activity';
import acknowledgement from './acknowledgement';
import editing from './editing';
import respects from './activity_respects';
import views from './activity_views';
import acks from './activity_acks';
import summaries from './summaries';
import activitySets from './activity_sets';
import activitySetsLoading from './activity_sets_loading';
import activityHistory from './activityHistory';
import activityHistoryDetails from './activityHistoryDetails';
import requestStatus from './requestStatus';
import activityReactions from './activity_reactions';
import isViewingProtectedAudience from './isViewingProtectedAudience';
import taggedActivities from './tagged_activities';
import rrComments from './rrComments';

export default combineReducers({
  activities,
  summaries,
  fetchedLastActivity,
  acknowledgement,
  editing,
  respects,
  views,
  acks,
  activitySets,
  activitySetsLoading,
  activityHistory,
  activityHistoryDetails,
  requestStatus,
  activityReactions,
  isViewingProtectedAudience,
  taggedActivities,
  rrComments,
});
