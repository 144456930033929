import { createIsFetchingReducer } from 'arcade-frontend-ui';
import { types as typesRewards } from 'arcade-frontend-rewards/src/actions';

import { types } from '../../../actions/manage/tokens';

const initialState = false;

const apiActionTypes = [
  typesRewards.REWARDS_ACCOUNTS_INDEX,
  types.MANAGE_TOKENS_TRANSFER,
  types.MANAGE_TOKENS_TOP_UP,
];

const isFetching = createIsFetchingReducer(initialState, apiActionTypes);

export default isFetching;

export const getIsFetching = state => state.manage.tokens.isFetching;
