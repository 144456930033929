import { makeApiActionTypes } from 'arcade-frontend-ui';

export const types = {
  CUSTOM_AUDIENCE_ENTITIES: makeApiActionTypes('CUSTOM_AUDIENCE_ENTITIES'),
  CUSTOM_AUDIENCE_CREATE: makeApiActionTypes('CUSTOM_AUDIENCE_CREATE'),

  SET_OPEN_CUSTOM_AUDIENCE_DIALOG: 'SET_OPEN_CUSTOM_AUDIENCE_DIALOG',
};

export const actions = {
  apiCustomAudienceEntitiesRequest: payload => ({
    type: types.CUSTOM_AUDIENCE_ENTITIES.REQUEST,
    payload,
  }),

  apiCustomAudienceCreateRequest: payload => ({
    type: types.CUSTOM_AUDIENCE_CREATE.REQUEST,
    payload,
  }),

  setOpenCustomAudienceDialog: payload => ({
    type: types.SET_OPEN_CUSTOM_AUDIENCE_DIALOG,
    payload,
  }),
};
