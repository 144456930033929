import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { resources as userResources } from 'arcade-frontend-core/src/resources/users';
import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';

import { resources as teamResources } from '../../resources/teams';
import PeopleTeamAssignmentFormDialog from '../../components/PeopleTeamAssignmentFormDialog';

const ANIMATION_DELAY = 500;

function PeopleTeamAssignmentFormDialogContainer({
  name,
  open,
  onClose,
  onUpdateTeamSuccess,
  teamName,
  primaryTeamId,
  secondaryTeams,
  userId,
}) {
  const currentUser = useSelector(getCurrentUser);
  const apiPutUpdateUserTeam = useResourceAction(
    userResources.apiPutUpdateUserTeam,
  );
  const apiGetTeams = useResourceAction(teamResources.apiGetTeams);

  const teams = apiGetTeams.hasData ? apiGetTeams.data.resources : [];

  useEffect(() => {
    if (open && currentUser.id) {
      apiGetTeams.requestData().catch(global.noop);
    }
  }, [currentUser.id, open, userId]);

  const handleSubmit = values => {
    if (currentUser.id) {
      apiPutUpdateUserTeam.onErrorReset();
      apiPutUpdateUserTeam
        .requestData({
          resources: [
            {
              primaryId: values.primaryTeamId,
              secondaryIds: values.secondaryTeamIds.filter(
                teamId => teamId !== values.primaryTeamId,
              ),
              userId,
            },
          ],
        })
        .then(() => {
          setTimeout(() => {
            onClose();
            onUpdateTeamSuccess();
            apiPutUpdateUserTeam.onStatusReset();
          }, ANIMATION_DELAY);
        })
        .catch(() => {
          setTimeout(() => {
            apiPutUpdateUserTeam.onStatusReset();
          }, ANIMATION_DELAY);
        });
    }
  };

  return (
    <PeopleTeamAssignmentFormDialog
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      teams={teams}
      userId={userId}
      name={name}
      primaryTeamId={primaryTeamId}
      primaryTeamName={teamName}
      secondaryTeams={secondaryTeams}
      statusGetTeams={apiGetTeams.status}
      statusUpdateUserTeam={apiPutUpdateUserTeam.status}
      hasErrorUpdateUserTeam={apiPutUpdateUserTeam.hasError}
    />
  );
}

PeopleTeamAssignmentFormDialogContainer.displayName =
  'PeopleTeamAssignmentFormDialogContainer';

PeopleTeamAssignmentFormDialogContainer.propTypes = {
  name: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdateTeamSuccess: PropTypes.func,
  primaryTeamId: PropTypes.string,
  secondaryTeams: PropTypes.arrayOf(PropTypes.string),
  teamName: PropTypes.string,
  userId: PropTypes.string,
};

PeopleTeamAssignmentFormDialogContainer.defaultProps = {
  name: '',
  open: false,
  onClose: global.noop,
  onUpdateTeamSuccess: global.noop,
  primaryTeamId: null,
  secondaryTeams: [],
  teamName: '',
  userId: '',
};

export default PeopleTeamAssignmentFormDialogContainer;
