import React from 'react';
import PropTypes from 'prop-types';
import { fade } from '@material-ui/core/styles/colorManipulator';

import {
  ArcPaper,
  ArcText,
  ArcView,
  createWithStyles,
} from 'arcade-frontend-ui';

import ManageMetricEntityCardListItem from './ManageMetricEntityCardListItem';

const renderNoMetrics = () => (
  <ArcText style={{ padding: 8, paddingLeft: 16 }}>
    {'No metrics found'}
  </ArcText>
);

const styles = {
  EntityCard: theme => ({
    root: {
      width: theme.spacing(35),
      height: theme.spacing(50),
      margin: theme.spacing(1),
      position: 'relative',
    },
  }),

  EntityHeader: theme => ({
    root: {
      position: 'absolute',
      padding: theme.spacing(2),
      width: '100%',
      backgroundColor: 'white',
      borderRadius: '4px 4px 0 0',
    },
  }),

  EntityInitial: theme => ({
    root: {
      height: theme.spacing(4),
      width: theme.spacing(4),
      marginRight: theme.spacing(1),
      borderRadius: 4,
      textAlign: 'center',
    },
  }),

  EntityMetricsList: theme => ({
    root: {
      height: '100%',
      // padding: theme.spacing(1),
      paddingTop: theme.spacing(11),
      overflow: 'scroll',
    },
  }),

  ColumnHeaders: theme => ({
    root: {
      position: 'absolute',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(7),
      width: '100%',
      backgroundColor: 'white',
    },
  }),
};

const EntityCard = createWithStyles(styles.EntityCard)(ArcPaper);
const EntityHeader = createWithStyles(styles.EntityHeader)(ArcView);
const EntityInitial = createWithStyles(styles.EntityInitial)(ArcView);
const EntityMetricsList = createWithStyles(styles.EntityMetricsList)(ArcView);
const ColumnHeaders = createWithStyles(styles.ColumnHeaders)(ArcView);

class ManageMetricEntityCard extends React.PureComponent {
  static propTypes = {
    color: PropTypes.string,
    metrics: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
      }),
    ),
    name: PropTypes.string,
  };

  static defaultProps = {
    color: '#F00',
    metrics: null,
    name: null,
  };

  get hasMetrics() {
    return this.props.metrics.length >= 1;
  }

  renderListItem = (metric, index) => (
    <ManageMetricEntityCardListItem
      key={metric.title}
      shade={index % 2 === 0}
      metric={metric}
    />
  );

  renderEntityHeader = () => {
    const { color, name } = this.props;

    const initial = name ? name.charAt(0) : '';

    return (
      <EntityHeader row align="center">
        <EntityInitial
          alignCenter
          justifyCenter
          style={{
            color,
            border: `2px ${color} solid`,
            backgroundColor: fade(color, 0.1),
          }}
        >
          {initial}
        </EntityInitial>
        <ArcText isLarge>{name}</ArcText>
      </EntityHeader>
    );
  };

  renderColumnHeaders = () => (
    <ColumnHeaders row justify="space-between">
      <ArcText isSmall>{'Metric'}</ArcText>
      <ArcText isSmall>{'Amount'}</ArcText>
    </ColumnHeaders>
  );

  render() {
    const { metrics } = this.props;

    return (
      <EntityCard>
        {this.renderEntityHeader()}
        {this.renderColumnHeaders()}
        <EntityMetricsList>
          {this.hasMetrics
            ? metrics.map(this.renderListItem)
            : renderNoMetrics()}
        </EntityMetricsList>
      </EntityCard>
    );
  }
}

export default ManageMetricEntityCard;
