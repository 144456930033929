import React from 'react';
import { Path } from 'react-primitives-svg';
import Icon from '../Icon';

const QuestionCircle = props => (
  <Icon {...props}>
    <Path
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 10.005a3 3 0 114.2 2.75 2 2 0 00-1.2 1.833V15m0 8.001c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11zM12 17a.25.25 0 100 .5.25.25 0 000-.5z"
    />
  </Icon>
);

QuestionCircle.displayName = 'QuestionCircle';

export default QuestionCircle;
