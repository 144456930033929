import ArcPropTypes from '../../helpers/arc/propTypes';

export const propTypes = {
  isValid: ArcPropTypes.bool,
  onError: ArcPropTypes.func,
  onSubmit: ArcPropTypes.func,
  renderFields: ArcPropTypes.objectOf(ArcPropTypes.children),
};

export const defaultProps = {
  isValid: undefined,
  onError: undefined,
  onSubmit: undefined,
  renderFields: {},
};

export const FORM_CHANGE = 'forms/FORM_CHANGE';
