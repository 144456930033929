import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import Search from '@material-ui/icons/Search';

import { ArcView, ArcIconButton } from 'arcade-frontend-ui';
import FeatureSwitcherContainer from 'arcade-frontend-features/src/containers/app/FeatureSwitcherContainer';

import ChatSearchDialog from '../../containers/ChatSearchDialog';

const navItemStyle = {
  height: 44,
};

const ChatSearchAnchor = ({
  label,
  onResultSelected,
  closeOnResultSelected,
}) => {
  const [isShowingDialog, setDialogSate] = useState(false);
  const openDialog = () => setDialogSate(true);
  const closeDialog = () => setDialogSate(false);

  const handleResultSelect = result => {
    onResultSelected(result);

    if (closeOnResultSelected) {
      closeDialog();
    }
  };

  const active = label ? (
    <ArcView>
      <ButtonBase onClick={openDialog}>
        <ArcView row fullWidth align="center" style={navItemStyle}>
          <ArcView marginRight="8">{label}</ArcView>
          <ArcView spacer />
          <Search />
        </ArcView>
      </ButtonBase>
      <ChatSearchDialog
        open={isShowingDialog}
        onClose={closeDialog}
        onResultSelected={handleResultSelect}
      />
    </ArcView>
  ) : (
    <React.Fragment>
      <ArcIconButton onClick={openDialog}>
        <Search />
      </ArcIconButton>
      <ChatSearchDialog
        open={isShowingDialog}
        onClose={closeDialog}
        onResultSelected={onResultSelected}
      />
    </React.Fragment>
  );

  return (
    <FeatureSwitcherContainer feature="chat_search" active={() => active} />
  );
};

ChatSearchAnchor.propTypes = {
  closeOnResultSelected: PropTypes.bool,
  label: PropTypes.node,
  onResultSelected: PropTypes.func.isRequired,
};

ChatSearchAnchor.defaultProps = {
  closeOnResultSelected: true,
  label: null,
};

export default ChatSearchAnchor;
