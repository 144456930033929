import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const handleAppLoginFailure = (state, action) => action.payload.error || 'An error occurred';
const handleAppLoginRequest = () => null;

const initialState = null;

const actions = {
  [types.APP_LOGIN.FAILURE]: handleAppLoginFailure,
  [types.APP_LOGIN.REQUEST]: handleAppLoginRequest,
};

const requestErrorMessage = createReducer(initialState, actions);

export default requestErrorMessage;

export const getRequestErrorMessage = state => state.app.requestErrorMessage;
