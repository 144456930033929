import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Divider } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/styles/makeStyles';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';

import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import usePermission from 'arcade-frontend-core/src/hooks/usePermission';
import { ArcView, ArcTokenOutlined } from 'arcade-frontend-ui';
import ArcAwardChestFormContainer from 'arcade-frontend-widgets/src/containers/ArcAwardChestFormContainer';
import ArcAwardTokenFormContainer from 'arcade-frontend-widgets/src/containers/ArcAwardTokenFormContainer';
import ChestOutline from 'arcade-frontend-ui/src/icons/ChestOutline';
import CallAt from 'arcade-frontend-ui/src/icons/CallAt';

import MANAGER_ACTION_MENU_ITEMS from '../../types/manager-menu-items';
import { usePersonActions } from '../../hooks/usePersonActions';
import PeopleManagePermissionsModalContainer from '../../containers/PeopleManagePermissionsModalContainer';
import PeopleEditProfileModalContainer from '../../containers/PeopleEditProfileModalContainer';
import PeopleTeamAssignmentFormDialogContainer from '../../containers/PeopleTeamAssignmentFormDialogContainer';

const useStyles = makeStyles(theme => ({
  peopleActionMenuItems: {
    color: theme.palette.grey[700],
    '&:hover': {
      backgroundColor: '#F0FBFE',
      color: '#0580B8',
    },
  },
}));

function PersonDetailsActionMenuItems({
  anchorEl,
  canAwardChests,
  canAwardTokens,
  id,
  image,
  name,
  onClose,
  onUpdateProfileSuccess,
  onUpdateTeamSuccess,
  phoneNumber,
  primaryTeamId,
  secondaryTeams,
  team,
}) {
  const classes = useStyles();

  const hasFeaturePeopleV2 = useFeatureFlag(FEATURE_FLAGS.PEOPLE_V2);

  const canManagePermissions = true;
  const canManageTeams = usePermission(PERMISSIONS.MANAGE_TEAMS);
  const canManageUsers = usePermission(PERMISSIONS.MANAGE_USERS);

  const currentUserPermissions = [
    { name: PERMISSIONS.MANAGE_PERMISSIONS, value: canManagePermissions },
    { name: PERMISSIONS.MANAGE_TEAMS, value: canManageTeams },
    { name: PERMISSIONS.MANAGE_USERS, value: canManageUsers },
  ];

  const hasManagerActions =
    canManagePermissions || canManageTeams || canManageUsers;

  const {
    navigateAwardToken,
    navigateCall,
    navigateChat,
    isPhoneNumberValid,
  } = usePersonActions();

  const [openedDialog, setOpenedDialog] = useState('');

  const handleCloseDialog = () => setOpenedDialog('');

  const handleAwardChestClick = () => {
    onClose();
    setOpenedDialog('award_chest');
  };

  const handleAwardTokenClick = () => {
    onClose();
    setOpenedDialog('award_token');
  };

  const handleManageClick = menuItem => {
    onClose();
    if (id) setOpenedDialog(menuItem);
  };

  const handleClickRecognize = event => {
    navigateAwardToken(event, id);
    onClose();
  };

  const handleClickChat = event => {
    navigateChat(event, id);
    onClose();
  };

  const handleCall = () => {
    navigateCall(phoneNumber);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const initialFormValues = () => ({
    people: [Number(id)],
  });

  const hasPermission = requiredPermission =>
    currentUserPermissions.find(
      permission => permission.name === requiredPermission,
    ).value;

  const renderManagerActions = () =>
    MANAGER_ACTION_MENU_ITEMS.map(menuItem => {
      if (hasPermission(menuItem.requiredPermission)) {
        return (
          <MenuItem
            key={menuItem.identifier}
            onClick={() => handleManageClick(menuItem.identifier)}
            className={classes.peopleActionMenuItems}
          >
            {menuItem.label}
          </MenuItem>
        );
      }
      return null;
    });

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-testid="PersonDetailsActionMenu"
      >
        <MenuItem
          data-testid={`PersonDetailsActionMenuItems-Item-${id}`}
          className={classes.peopleActionMenuItems}
          onClick={handleClickChat}
        >
          <ArcView marginRight="8">
            <ChatBubbleOutlineOutlinedIcon />
          </ArcView>
          {'Send Message'}
        </MenuItem>

        <MenuItem
          data-testid={`PersonDetailsActionMenuItems-Item-${id}`}
          className={classes.peopleActionMenuItems}
          onClick={handleClickRecognize}
        >
          <ArcView marginRight="8">
            <StarBorderOutlinedIcon />
          </ArcView>
          {'Award Star'}
        </MenuItem>

        {canAwardTokens && (
          <MenuItem
            data-testid={`PersonDetailsActionMenuItems-Item-${id}`}
            className={classes.peopleActionMenuItems}
            onClick={handleAwardTokenClick}
          >
            <ArcView marginRight="8">
              <ArcTokenOutlined color="#666" width={22} />
            </ArcView>
            {'Give Tokens'}
          </MenuItem>
        )}

        {canAwardChests && (
          <MenuItem
            data-testid={`PersonDetailsActionMenuItems-Item-${id}`}
            className={classes.peopleActionMenuItems}
            onClick={handleAwardChestClick}
          >
            <ArcView marginRight="8">
              <ChestOutline />
            </ArcView>
            {'Give Chests'}
          </MenuItem>
        )}

        {isPhoneNumberValid(phoneNumber) && (
          <MenuItem
            data-testid={`PersonDetailsActionMenuItems-Item-${id}`}
            className={classes.peopleActionMenuItems}
            onClick={handleCall}
          >
            <ArcView marginRight="8">
              <CallAt />
            </ArcView>
            {'Call'}
          </MenuItem>
        )}

        {hasFeaturePeopleV2 && hasManagerActions && <Divider />}
        {hasFeaturePeopleV2 && hasManagerActions && renderManagerActions()}
      </Menu>

      {openedDialog === 'award_chest' && (
        <ArcAwardChestFormContainer
          open
          onCancel={handleCloseDialog}
          initialValues={initialFormValues()}
          hasConfirmation
          hasFieldIsPublic
          inDialog
          hasFieldPeople
        />
      )}

      {openedDialog === 'award_token' && (
        <ArcAwardTokenFormContainer
          open
          onCancel={handleCloseDialog}
          initialValues={initialFormValues()}
          hasConfirmation
          hasFieldIsPublic
          inDialog
          hasFieldPeople
        />
      )}

      {openedDialog === 'manage_permissions' && (
        <PeopleManagePermissionsModalContainer
          open
          onClose={handleCloseDialog}
          id={id}
        />
      )}

      {openedDialog === 'edit_profile' && (
        <PeopleEditProfileModalContainer
          open
          id={id}
          onClose={handleCloseDialog}
          onUpdateProfileSuccess={onUpdateProfileSuccess}
        />
      )}

      {openedDialog === 'team-assignments' && (
        <PeopleTeamAssignmentFormDialogContainer
          open
          userId={id}
          name={name}
          teamName={team}
          primaryTeamId={primaryTeamId}
          secondaryTeams={secondaryTeams}
          onClose={handleCloseDialog}
          onUpdateTeamSuccess={onUpdateTeamSuccess}
        />
      )}
    </>
  );
}

PersonDetailsActionMenuItems.displayName = 'PersonDetailsActionMenuItems';

PersonDetailsActionMenuItems.propTypes = {
  anchorEl: PropTypes.objectOf(PropTypes.any),
  canAwardChests: PropTypes.bool,
  canAwardTokens: PropTypes.bool,
  id: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func,
  onUpdateProfileSuccess: PropTypes.func,
  onUpdateTeamSuccess: PropTypes.func,
  phoneNumber: PropTypes.string,
  primaryTeamId: PropTypes.string,
  secondaryTeams: PropTypes.arrayOf(PropTypes.string),
  team: PropTypes.string,
};

PersonDetailsActionMenuItems.defaultProps = {
  anchorEl: null,
  canAwardChests: false,
  canAwardTokens: false,
  id: null,
  image: null,
  name: null,
  onClose: global.noop,
  onUpdateProfileSuccess: global.noop,
  onUpdateTeamSuccess: global.noop,
  phoneNumber: null,
  primaryTeamId: null,
  secondaryTeams: [],
  team: null,
};

export default PersonDetailsActionMenuItems;
