import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../actions/metrics/types';

const handleMetricsActivitiesLoadSuccess = (state, action) => {
  const { data, metricId } = action.payload;
  const newState = { ...state };

  newState[metricId] = data;

  return newState;
};

const initialState = {};

const handlers = {
  [types.METRICS_ACTIVITIES_INDEX.SUCCESS]: handleMetricsActivitiesLoadSuccess,
};

const activitiesById = createReducer(initialState, handlers);

export default activitiesById;

export const getActivitiesById = state => state.metrics.activitiesById;
export const getActivities = state => Object.keys(getActivitiesById(state));
