import React from 'react';

import { G, Path } from 'react-primitives-svg';

import Icon from '../Icon';

const CallAt = props => (
  <Icon {...props}>
    <G
      clipPath="url(#clip0)"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <Path
        fill="currentColor"
        clipRule="evenodd"
        d="M10.227 9.028a1.55 1.55 0 00.115-2.181L8.792 5.3a1.548 1.548 0 00-2.181.115l-.944.943a2.383 2.383 0 00-.444 2.926 30.61 30.61 0 009.5 9.5c.96.548 2.171.365 2.925-.443l.944-.944a1.549 1.549 0 00.114-2.182l-1.549-1.549a1.549 1.549 0 00-2.18.114l-.575.574A31.255 31.255 0 019.653 9.6l.574-.572z"
      />
      <Path
        fill="transparent"
        d="M18 18c.5.5 1.56 5.5-6 5.5C5.649 23.499.501 18.35.501 11.999.502 5.648 5.651.499 12.002.5c6.351.001 11.499 5.15 11.499 11.501a11.5 11.5 0 01-2.013 6.499"
      />
    </G>
    <defs>
      <clipPath id="clip0">
        <Path d="M0 0h24v24H0V0z" fill="transparent" />
      </clipPath>
    </defs>
  </Icon>
);

export default CallAt;
