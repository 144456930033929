import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-native-paper';

const NotificationsPortalProvider = ({ children }) => (
  <Portal.Host>{children}</Portal.Host>
);

NotificationsPortalProvider.displayName = 'NotificationsPortalProvider';

NotificationsPortalProvider.propTypes = {
  children: PropTypes.node,
};

NotificationsPortalProvider.defaultProps = {
  children: null,
};

export default NotificationsPortalProvider;
