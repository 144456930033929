import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import FilterList from '@material-ui/icons/FilterList';

import useIsMounted from 'arcade-frontend-core/src/hooks/useIsMounted';
import useTimeout from 'arcade-frontend-core/src/hooks/useTimeout';
import ArcResourceStatusIcon from 'arcade-frontend-ui/src/components/ArcResourceStatusIcon';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcView from 'arcade-frontend-ui/src/primitives/ArcView';
import GamesOutline from 'arcade-frontend-ui/src/icons/GamesOutline';
import PresentOutline from 'arcade-frontend-ui/src/icons/PresentOutline';
import NewsfeedActivitiesFilterActivityType from './NewsfeedActivitiesFilterType';
import NewsfeedActivitiesFilterItem from './NewsFeedActivitiesFilterItem';

const HEADER_MIN_WIDTH = 260;

const INITIAL_REQUEST_DELAY = 3000;

const STRINGS = {
  'NEWSFEED/ACTIVITIES_FILTER_LABEL_SINGULAR': 'Filter',
  'NEWSFEED/ACTIVITIES_FILTER_LABEL_PLURAL': 'Filters',
  'NEWSFEED/ACTIVITIES_FILTER_AUDIENCES_CLEAR': 'Clear',
  'NEWSFEED/ACTIVITIES_FILTER_AUDIENCES_SELECT_ALL': 'Select All',
  'NEWSFEED/ACTIVITIES_FILTER_NO_DATA': 'None available',
  'NEWSFEED/ACTIVITIES_FILTER_AUDIENCES_TITLE': 'Who',
  'NEWSFEED/ACTIVITIES_FILTER_ACTIVITY_TYPES_TITLE': 'Type',
};

const RENDERED_EMPTY_PLACEHOLDER = (
  <MenuItem disabled>
    <ArcText size="12px">
      {STRINGS['NEWSFEED/ACTIVITIES_FILTER_NO_DATA']}
    </ArcText>
  </MenuItem>
);

const RENDERED_PENDING_PLACEHOLDER = <MenuItem disabled className="shimmer" />;

const ICONS_BY_ID = {
  DISABLED_GameLeaderboardActivity: (
    <GamesOutline color="action" fontSize="small" />
  ),
  DISABLED_RewardActivity: <PresentOutline color="action" fontSize="small" />,
};

const headerStyle = {
  minWidth: HEADER_MIN_WIDTH,
};

const selectAllButtonStyle = {
  minWidth: 98,
};

function getSelectedStateFromAllData(allData, isSelected) {
  const selectedActivityTypesById = {};
  const selectedAudiencesById = {};

  allData.activityTypes.forEach(activityType => {
    selectedActivityTypesById[activityType.id] =
      typeof isSelected === typeof undefined
        ? activityType.selected
        : isSelected;
  });

  allData.audiences.forEach(audience => {
    selectedAudiencesById[audience.id] =
      typeof isSelected === typeof undefined ? audience.selected : isSelected;
  });

  return {
    selectedActivityTypesById,
    selectedAudiencesById,
  };
}

function NewsfeedActivitiesFilter({
  activityTypes,
  audiences,
  hasData,
  initialRequestDelay,
  isPending,
  status,
  menuProps,
  onClose,
  onOpen,
  onSubmit,
  requestData,
  ...props
}) {
  function getInitialSelectedAll() {
    const selectedAllAudiences =
      audiences.length > 0 &&
      audiences.filter(item => item.selected).length === audiences.length;

    const selectedAllActivityTypes =
      activityTypes.length > 0 &&
      activityTypes.filter(item => item.selected).length ===
        activityTypes.length;

    return selectedAllAudiences && selectedAllActivityTypes;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [hasChanged, setHasChanged] = React.useState(false);

  const [selectedAll, setSelectedAll] = React.useState(getInitialSelectedAll);

  const [selectedAudiencesById, setSelectedAudiencesById] = React.useState({});

  const [
    selectedActivityTypesById,
    setSelectedActivityTypesById,
  ] = React.useState({});

  const isMounted = useIsMounted();

  async function setup() {
    if (hasData) {
      return undefined;
    }

    const resp = await requestData();

    if (!isMounted.current) {
      return undefined;
    }

    const data = resp.data.resources[0];

    const nextSelectedState = getSelectedStateFromAllData(data);

    setSelectedAudiencesById(nextSelectedState.selectedAudiencesById);
    setSelectedActivityTypesById(nextSelectedState.selectedActivityTypesById);

    const nextSelectedAll = getInitialSelectedAll();

    setSelectedAll(nextSelectedAll);

    return undefined;
  }

  useTimeout(setup, initialRequestDelay);

  function getNextSelectAll() {
    const selectedAudiences = Object.values(selectedAudiencesById);
    const selectedActivityTypes = Object.values(selectedActivityTypesById);

    const hasSomeData =
      !!selectedAudiences.length || !!selectedActivityTypes.length;

    const selectedAllAudiences = selectedAudiences.length
      ? selectedAudiences.filter(Boolean).length === selectedAudiences.length
      : hasSomeData;

    const selectedAllActivityTypes = selectedActivityTypes.length
      ? selectedActivityTypes.filter(Boolean).length ===
        selectedActivityTypes.length
      : hasSomeData;

    const nextSelectedAll = selectedAllAudiences && selectedAllActivityTypes;

    setSelectedAll(nextSelectedAll);
  }

  React.useEffect(getNextSelectAll, [
    selectedAudiencesById,
    selectedActivityTypesById,
  ]);

  const toggleSelectedAudienceId = id => {
    setHasChanged(true);

    setSelectedAudiencesById({
      ...selectedAudiencesById,
      [id]: !selectedAudiencesById[id],
    });
  };

  const toggleSelectedActivityTypeId = id => {
    setHasChanged(true);

    setSelectedActivityTypesById({
      ...selectedActivityTypesById,
      [id]: !selectedActivityTypesById[id],
    });
  };

  const setSelectedAllItems = nextSelectedAll => {
    setHasChanged(true);

    const nextSelectedState = getSelectedStateFromAllData(
      {
        audiences,
        activityTypes,
      },
      nextSelectedAll,
    );

    setSelectedAll(nextSelectedAll);
    setSelectedAudiencesById(nextSelectedState.selectedAudiencesById);
    setSelectedActivityTypesById(nextSelectedState.selectedActivityTypesById);
  };

  const toggleSelectedAll = () => setSelectedAllItems(!selectedAll);

  const handleClear = () => {
    setHasChanged(true);
    setSelectedAllItems(false);
  };

  const handleClick = evt => {
    setAnchorEl(evt.currentTarget);
    onOpen();
  };

  const handleClose = () => {
    if (hasChanged) {
      onSubmit({
        selectedAudiences: Object.keys(selectedAudiencesById).filter(
          key => selectedAudiencesById[key],
        ),
        selectedActivityTypes: Object.keys(selectedActivityTypesById).filter(
          key => selectedActivityTypesById[key],
        ),
      });
    }

    onClose();
    setAnchorEl(null);
  };

  const isDisabled = !hasData;

  const renderedAudiences =
    !isPending && !audiences.length
      ? RENDERED_EMPTY_PLACEHOLDER
      : audiences.map(item => (
          <NewsfeedActivitiesFilterItem
            item={item}
            key={item.id}
            onClick={toggleSelectedAudienceId}
            selectedById={selectedAudiencesById}
          />
        ));

  const renderedActivityTypes =
    !isPending && !activityTypes.length
      ? RENDERED_EMPTY_PLACEHOLDER
      : activityTypes.map(item => (
          <NewsfeedActivitiesFilterActivityType
            item={item}
            key={item.id}
            onClick={toggleSelectedActivityTypeId}
            selectedById={selectedActivityTypesById}
          />
        ));

  const totalSelected =
    Object.values(selectedActivityTypesById).filter(Boolean).length +
    Object.values(selectedAudiencesById).filter(Boolean).length;

  const filterLabel =
    totalSelected === 1
      ? STRINGS['NEWSFEED/ACTIVITIES_FILTER_LABEL_SINGULAR']
      : STRINGS['NEWSFEED/ACTIVITIES_FILTER_LABEL_PLURAL'];

  const label = totalSelected ? `${totalSelected} ${filterLabel}` : filterLabel;

  return (
    <React.Fragment>
      <ArcButton
        aria-controls="newsfeed-activities-filter"
        aria-haspopup="true"
        data-testid="NewsfeedActivitiesFilter-Button"
        disabled={isDisabled}
        label={label}
        onClick={handleClick}
        variant="outlined"
        color={totalSelected ? 'primary' : 'default'}
        {...props}
      >
        <ArcView position="relative">
          <ArcResourceStatusIcon
            idleIcon={<FilterList color="inherit" fontSize="small" />}
            status={status}
          />
        </ArcView>
        <ArcText marginLeft="8" marginRight="8" textTransform="initial">
          {label}
        </ArcText>
        <ArrowDropDown color="inherit" fontSize="small" />
      </ArcButton>
      <Menu
        id="newsfeed-activities-filter"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        {...menuProps}
      >
        <ArcView
          row
          align="center"
          paddingTop="12"
          paddingRight="16"
          paddingBottom="12"
          paddingLeft="16"
          style={headerStyle}
        >
          <ArcView spacer />
          <ArcView marginRight="4">
            <ArcButton
              color="primary"
              disabled={isDisabled || !selectedAll}
              onClick={handleClear}
              size="small"
            >
              {STRINGS['NEWSFEED/ACTIVITIES_FILTER_AUDIENCES_CLEAR']}
            </ArcButton>
          </ArcView>
          <ArcButton
            color="primary"
            disabled={isDisabled || selectedAll}
            onClick={toggleSelectedAll}
            size="small"
            style={selectAllButtonStyle}
            variant="outlined"
          >
            {STRINGS['NEWSFEED/ACTIVITIES_FILTER_AUDIENCES_SELECT_ALL']}
          </ArcButton>
        </ArcView>

        <MenuItem disabled>
          {STRINGS['NEWSFEED/ACTIVITIES_FILTER_AUDIENCES_TITLE']}
        </MenuItem>
        {renderedAudiences}
        {isPending && !audiences.length && RENDERED_PENDING_PLACEHOLDER}

        <MenuItem disabled>
          {STRINGS['NEWSFEED/ACTIVITIES_FILTER_ACTIVITY_TYPES_TITLE']}
        </MenuItem>
        {renderedActivityTypes}
        {isPending && !activityTypes.length && RENDERED_PENDING_PLACEHOLDER}
      </Menu>
    </React.Fragment>
  );
}

NewsfeedActivitiesFilter.displayName = 'NewsfeedActivitiesFilter';

NewsfeedActivitiesFilter.propTypes = {
  activityTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  audiences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  hasData: PropTypes.bool,
  initialRequestDelay: PropTypes.number,
  isPending: PropTypes.bool,
  menuProps: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  requestData: PropTypes.func,
  status: PropTypes.objectOf(PropTypes.bool),
};

NewsfeedActivitiesFilter.defaultProps = {
  activityTypes: [],
  audiences: [],
  hasData: false,
  initialRequestDelay: INITIAL_REQUEST_DELAY,
  isPending: false,
  menuProps: {},
  onClose: global.noop,
  onOpen: global.noop,
  onSubmit: global.noop,
  open: false,
  requestData: global.noop,
  status: undefined,
};

export default NewsfeedActivitiesFilter;
