import React from 'react';

import { Svg, G, Path } from 'react-primitives-svg';


const Arc404 = props => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="164"
    height="78"
    viewBox="0 0 164 78"
    {...props}
  >
    <G fill="none">
      <Path
        fill="#FFD15C"
        d="M80.94 47.753l-8.939 24.48-1.925 5.364h-9.627l3.438-9.627 7.29-20.217h9.764-.001zm23.793 29.844h-9.627l-1.925-5.364-8.94-24.48h9.765l7.289 20.217 3.438 9.627z"
      />
      <Path
        fill="#F8B64C"
        d="M71.176 47.753h9.765l-8.802 24.48c-2.888-.962-5.639-2.475-8.252-4.263l7.289-20.217zm30.119 20.217c-2.476 1.788-5.226 3.163-8.252 4.263l-8.802-24.48h9.765l7.289 20.217z"
      />
      <Path
        fill="#00A3D6"
        d="M82.5 72.15c16.725 0 30.283-13.558 30.283-30.283S99.225 11.583 82.5 11.583 52.217 25.142 52.217 41.867 65.775 72.15 82.5 72.15z"
      />
      <Path
        fill="#F2F2F2"
        d="M82.637 64.75c-12.51 0-22.747-10.236-22.747-22.746 0-12.511 10.236-22.747 22.747-22.747 12.51 0 22.747 10.236 22.747 22.747 0 12.51-10.236 22.746-22.747 22.746z"
      />
      <Path
        fill="#FF7058"
        d="M82.363 56.803c-8.29 0-15.073-6.783-15.073-15.073s6.783-15.073 15.073-15.073 15.073 6.782 15.073 15.073c0 8.29-6.783 15.073-15.073 15.073z"
      />
      <Path
        fill="#CDD6E0"
        d="M82.5 49.403c-4.145 0-7.537-3.391-7.537-7.536s3.392-7.537 7.537-7.537 7.537 3.392 7.537 7.537-3.392 7.536-7.537 7.536z"
      />
      <Path
        fill="#FFD15C"
        d="M82.5 49.403c-4.145 0-7.537-3.391-7.537-7.536s3.392-7.537 7.537-7.537 7.537 3.392 7.537 7.537-3.392 7.536-7.537 7.536z"
      />
      <Path
        fill="#00A3D6"
        d="M.482 62.867h28.666V74h10.387V62.867h7.688v-8.935h-7.688V14H25.98L.482 54.225v8.642zm28.784-8.935H11.632v-.47l17.164-27.157h.47v27.627zm87.731 8.935h28.668V74h10.385V62.867h7.688v-8.935h-7.688V14h-13.555l-25.498 40.225v8.642zm28.784-8.935h-17.634v-.47l17.165-27.157h.469v27.627z"
      />
      <Path
        fill="#075D8C"
        d="M39.5 34v-4l-4.844-2.468a1.97 1.97 0 011.143 0c-.38 1.015.127 2.158 1.138 2.539L39.5 34z"
        opacity="0.2"
      />
      <Path
        fill="#40596B"
        d="M54.514 12.242L35.97 30.678a1.989 1.989 0 110 1.96l18.67-18.31a1.93 1.93 0 01-.126 2.633v-4.719z"
      />
      <Path
        fill="#FF7058"
        d="M56.469 7.794l-6.03 5.909V5.909L56.47 0v7.794z"
      />
      <Path
        fill="#F1543F"
        d="M58.234 13.702l5.908-6.03h-7.794l-5.908 6.03h7.794z"
      />
    </G>
  </Svg>
);

export default Arc404;
