import React from 'react';
import { Platform } from 'react-primitives';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';
import { createWithStyles, font } from '../../styles';
import Touchable from '../Touchable';

const LEFT_MENU_COLOR = '#21afdb';

const styles = {
  ArcListItemView: theme => ({
    root: {
      position: 'relative',
      padding: 16,
      backgroundColor: '#ffffff',

      ...Platform.select({
        web: {
          color: theme.palette.default[600],
          cursor: 'pointer',
          userSelect: 'none',

          '&:hover': {
            backgroundColor: theme.palette.default[100],
          },
        },
      }),
    },

    hasRightIcon: {
      paddingRight: 56,
    },

    isDisabled: {
      opacity: 0.5,
    },
    isSelected: {
      backgroundColor: '#F0FBFE',
      color: LEFT_MENU_COLOR,

      ...Platform.select({
        web: {
          '&:hover': {
            backgroundColor: '#dcf3f9',
          },
        },
      }),
    },
  }),

  ArcListItemIcon: theme => ({
    root: {
      display: 'flex',
      color: theme.palette.default[600],
      width: 24,
      height: 24,
      fontSize: 24,

      ...Platform.select({
        sketch: {
          backgroundColor: theme.palette.default[100],
        },
      }),
    },

    isSelected: {
      color: LEFT_MENU_COLOR,
    },

    hasMarginLeft: {
      marginLeft: 16,
    },

    hasMarginRight: {
      marginRight: 16,
    },

    positionRelative: {
      position: 'relative',
    },
  }),

  ArcListItemText: theme => ({
    root: {
      color: theme.palette.default[600],
      fontFamily: theme.typography.fontFamily,
      fontSize: font.getFontSize(0.875),
      lineHeight: font.getLineHeight(1),
    },

    hasLeftIcon: {
      marginLeft: 16,
    },

    isSelected: {
      color: LEFT_MENU_COLOR,
    },
  }),
};

const ArcListItemView = createWithStyles(styles.ArcListItemView)(ArcView);
export const ArcListItemIcon = createWithStyles(styles.ArcListItemIcon)(
  ArcText,
);
const ArcListItemText = createWithStyles(styles.ArcListItemText)(ArcText);

const getLabel = (children, label) => {
  if (!children && !label) {
    return '';
  }

  return children || label;
};

const ArcListItem = ({
  children,
  isDisabled,
  isSelected,
  label,
  leftIcon: LeftIcon,
  onClick,
  onLongPress,
  onPress,
  renderLeftIcon,
  rightIcon: RightIcon,
  selectedDivider,
  ...props
}) => (
  <Touchable
    onPress={onPress || onClick}
    onLongPress={onLongPress}
    disabled={isDisabled}
  >
    <ArcListItemView
      align="center"
      hasRightIcon={!!RightIcon}
      isDisabled={isDisabled}
      isSelected={isSelected}
      row
      {...props}
    >
      {!!LeftIcon && !renderLeftIcon && (
        <ArcListItemIcon isSelected={isSelected}>
          <LeftIcon
            width="24px"
            height="24px"
            color={isSelected ? LEFT_MENU_COLOR : 'rgba(0,0,0,0.54)'}
          />
        </ArcListItemIcon>
      )}

      {renderLeftIcon}

      <ArcListItemText hasLeftIcon={!!LeftIcon} isSelected={isSelected}>
        {getLabel(children, label)}
      </ArcListItemText>

      {!!RightIcon && (
        <ArcListItemIcon isSelected={isSelected}>
          <RightIcon
            width="24px"
            height="24px"
            color={isSelected ? LEFT_MENU_COLOR : 'rgba(0,0,0,0.54)'}
          />
        </ArcListItemIcon>
      )}

      {isSelected && selectedDivider}
    </ArcListItemView>
  </Touchable>
);

ArcListItem.propTypes = {
  onClick: ArcPropTypes.func,
  onPress: ArcPropTypes.func,
  onLongPress: ArcPropTypes.func,
  children: ArcPropTypes.node,
  label: ArcPropTypes.children,
  leftIcon: ArcPropTypes.oneOfType([
    ArcPropTypes.elementType,
    ArcPropTypes.node,
  ]),
  renderLeftIcon: ArcPropTypes.children,
  rightIcon: ArcPropTypes.children,
  selectedDivider: ArcPropTypes.children,
  isDisabled: ArcPropTypes.bool,
  isSelected: ArcPropTypes.bool,
};

ArcListItem.defaultProps = {
  onClick: undefined,
  onPress: undefined,
  onLongPress: undefined,
  children: undefined,
  label: undefined,
  leftIcon: undefined,
  renderLeftIcon: undefined,
  rightIcon: undefined,
  selectedDivider: undefined,
  isDisabled: false,
  isSelected: false,
};

export default ArcListItem;
