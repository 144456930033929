/* eslint-disable */
export default [
    // { "x": 0, "y": 255, "px": 0, "py": 100 },
    { "x": 24.874725341796875, "y": 221.03126525878906, "px": 9.111621004321199, "py": 86.6789275524663 },
    { "x": 49.74945068359375, "y": 187.0625457763672, "px": 18.223242008642398, "py": 73.35786108877144 },
    { "x": 74.62419891357422, "y": 153.0938262939453, "px": 27.334871396913634, "py": 60.036794625076595 },
    { "x": 99.49893188476562, "y": 119.1250991821289, "px": 36.44649519588484, "py": 46.71572516946232 },
    { "x": 124.37367248535156, "y": 85.15637969970703, "px": 45.55812178950607, "py": 33.39465870576747 },
    { "x": 149.2484130859375, "y": 51.18764877319336, "px": 54.66974838312729, "py": 20.073587754193476 },
    { "x": 174.12313842773438, "y": 17.21892738342285, "px": 63.78136938744849, "py": 6.7525205425187655 },
    { "x": 208, "y": 1, "px": 76.19047619047619, "py": 0.39215686274509803 }
]