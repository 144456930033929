import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Platform } from 'react-native';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcUserInput from 'arcade-frontend-ui/src/components/ArcUserInput';
import ArcTokenField from 'arcade-frontend-ui/src/components/ArcTokenField';
import ArcChestSelect from 'arcade-frontend-ui/src/components/ArcChestSelect';

import ArcCheckbox from 'arcade-frontend-ui/src/elements/ArcCheckbox';
import ArcCurrencyField from 'arcade-frontend-ui/src/elements/ArcCurrencyField';
import ArcDatePicker from 'arcade-frontend-ui/src/elements/ArcDatePicker';
import ArcDateRange from 'arcade-frontend-ui/src/elements/ArcDateRange';
import ArcDateTime from 'arcade-frontend-ui/src/elements/ArcDateTime';
import ArcImageSelect from 'arcade-frontend-ui/src/elements/ArcImageSelect';
import ArcMultiAdd from 'arcade-frontend-ui/src/elements/ArcMultiAdd';
import ArcNumberField from 'arcade-frontend-ui/src/elements/ArcNumberField';
import ArcRadioButtonGroup from 'arcade-frontend-ui/src/elements/ArcRadioButtonGroup';
import ArcSelect from 'arcade-frontend-ui/src/elements/ArcSelect';
import ArcTextField from 'arcade-frontend-ui/src/elements/ArcTextField';
import ArcTimePicker from 'arcade-frontend-ui/src/elements/ArcTimePicker';

export const fieldsByType = {
  checkbox: ArcCheckbox,
  currency: ArcCurrencyField,
  'chest-select': ArcChestSelect,
  date: ArcDatePicker,
  'date-range': ArcDateRange,
  datetime: ArcDateTime,
  email: ArcTextField,
  image: ArcImageSelect,
  'multi-add': ArcMultiAdd,
  number: ArcNumberField,
  password: ArcTextField,
  radio: ArcRadioButtonGroup,
  select: ArcSelect,
  text: ArcTextField,
  tel: ArcTextField,
  tokens: ArcTokenField,
  time: ArcTimePicker,
  'user-input': ArcUserInput,
};

function ArcFormField({
  // Cross platform props
  children,
  description,
  fullWidth,
  hasNoMarginBottom,
  mb,
  name,
  onBlur,
  onChange,
  type,

  // Native only props
  autoCompleteType,
  keyboardType,
  onSubmitEditing,
  returnKeyType,
  textContentType,
  autoCorrect,
  clearButtonMode,
  secureTextEntry,

  ...props
}) {
  const nativeProps = Platform.select({
    web: {},
    default: {
      autoCompleteType,
      keyboardType,
      onSubmitEditing,
      returnKeyType,
      textContentType,
      autoCorrect,
      clearButtonMode,
      secureTextEntry,
    },
  });

  const ArcField = fieldsByType[type] || ArcTextField;

  const variant =
    ArcField === ArcSelect ||
    ArcField === ArcTextField ||
    ArcField === ArcNumberField
      ? 'outlined'
      : undefined;

  function renderField(formikProps) {
    const showError =
      !!formikProps.form.errors[name] && formikProps.form.touched[name];

    const helperText =
      variant === 'outlined' && showError
        ? formikProps.form.errors[name]
        : null;

    const defaultMarginBottom = showError ? 3 : 2;
    const marginBottom =
      typeof mb === typeof undefined ? defaultMarginBottom : mb;

    return (
      <ArcBox
        mb={hasNoMarginBottom ? undefined : marginBottom}
        width={fullWidth ? '100%' : undefined}
      >
        <ArcField
          data-testid={`ArcFormField-${name}`}
          id={name}
          name={name}
          fullWidth={fullWidth}
          helperText={helperText}
          disabled={formikProps.form.isSubmitting}
          {...nativeProps}
          {...props}
          {...formikProps.field}
          onBlur={evtOrValue => {
            const value = evtOrValue.target
              ? evtOrValue.target.value
              : evtOrValue;

            onBlur(value);
            formikProps.form.setFieldTouched(name, true);
            formikProps.field.onBlur(name);
          }}
          onChange={evtOrValue => {
            const value = evtOrValue.target
              ? evtOrValue.target.value
              : evtOrValue;

            onChange(value);
            formikProps.form.setFieldValue(name, value);
            formikProps.field.onChange(value);
          }}
          checked={type === 'checkbox' ? formikProps.field.value : undefined}
          error={showError}
          type={type}
          value={type === 'checkbox' ? name : formikProps.field.value}
          variant={variant}
        />
      </ArcBox>
    );
  }

  return (
    <>
      {!!description && (
        <ArcBox fontSize="caption.fontSize">{description}</ArcBox>
      )}

      <Field name={name}>{renderField}</Field>

      {children}
    </>
  );
}

ArcFormField.propTypes = {
  autoCompleteType: PropTypes.oneOf([
    'off',
    'username',
    'password',
    'email',
    'name',
    'tel',
    'street-address',
    'postal-code',
    'cc-number',
    'cc-csc',
    'cc-exp',
    'cc-exp-month',
    'cc-exp-year',
  ]),
  autoCorrect: PropTypes.bool,
  children: PropTypes.node,
  clearButtonMode: PropTypes.oneOf([
    'never',
    'while-editing',
    'unless-editing',
    'always',
  ]),
  description: PropTypes.string,
  // field: PropTypes.shape({
  //   onChange: PropTypes.func.isRequired,
  //   value: PropTypes.oneOfType([PropTypes.any]).isRequired,
  // }).isRequired,
  // form: PropTypes.shape({
  //   setFieldValue: PropTypes.func.isRequired,
  //   values: PropTypes.objectOf(PropTypes.any).isRequired,
  //   errors: PropTypes.objectOf(PropTypes.string).isRequired,
  //   touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  // }).isRequired,
  fullWidth: PropTypes.bool,
  hasNoMarginBottom: PropTypes.bool,
  keyboardType: PropTypes.string,
  mb: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onSubmitEditing: PropTypes.func,
  returnKeyType: PropTypes.oneOf([
    // Cross platform
    'done',
    'go',
    'next',
    'search',
    'send',

    // Android only
    'none',
    'previous',

    // iOS only
    'default',
    'emergency-call',
    'google',
    'join',
    'route',
    'yahoo',
  ]),
  secureTextEntry: PropTypes.bool,
  textContentType: PropTypes.oneOf([
    'none',
    'URL',
    'addressCity',
    'addressCityAndState',
    'addressState',
    'countryName',
    'creditCardNumber',
    'emailAddress',
    'familyName',
    'fullStreetAddress',
    'givenName',
    'jobTitle',
    'location',
    'middleName',
    'name',
    'namePrefix',
    'nameSuffix',
    'nickname',
    'organizationName',
    'postalCode',
    'streetAddressLine1',
    'streetAddressLine2',
    'sublocality',
    'telephoneNumber',
    'username',
    'password',
  ]),
  type: PropTypes.oneOf(Object.keys(fieldsByType)),
};

ArcFormField.defaultProps = {
  autoCompleteType: undefined,
  autoCorrect: undefined,
  children: undefined,
  clearButtonMode: undefined,
  description: undefined,
  fullWidth: true,
  hasNoMarginBottom: false,
  keyboardType: undefined,
  mb: undefined,
  onBlur: global.noop,
  onChange: global.noop,
  onSubmitEditing: undefined,
  returnKeyType: undefined,
  secureTextEntry: undefined,
  textContentType: undefined,
  type: 'text',
};

export default ArcFormField;
