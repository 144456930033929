import {
  makeParams,
  makeDateFilterParams,
} from 'arcade-frontend-core/src/actions/helpers';
import { makeApiActions } from 'arcade-frontend-ui';

import normalizers from './normalizers';

const index = params => ({
  method: 'GET',
  url: '/manager/v4/campaigns',
  rootKey: 'campaigns',
  normalizer: normalizers.fromManageGameIndex,
  params: { ...makeDateFilterParams(params) },
});

const list = params => ({
  method: 'GET',
  url: '/manager/v4/campaigns/list',
  rootKey: 'campaigns',
  normalizer: normalizers.fromManageGameList,
  params: {
    ...makeDateFilterParams(params),
    ...makeParams(params, ['list_type', 'listType']),
  },
});

const create = data => ({
  method: 'POST',
  url: '/manager/v4/campaigns',
  data: {
    campaign: normalizers.toManageGamesCreate(data),
  },
  normalizer: ({ message }) => ({
    message,
  }),
});

const show = ({ id }) => ({
  method: 'GET',
  url: `/manager/v4/campaigns/${id}`,
  rootKey: 'campaign',
  normalizer: normalizers.fromManageGameShow,
});

const edit = show;

const update = ({ id, data }) => ({
  method: 'PUT',
  url: `/manager/v4/campaigns/${id}`,
  data: {
    campaign: normalizers.toManageGamesCreate(data),
  },
});

const templatesIndex = () => ({
  method: 'GET',
  url: '/v3/game_templates',
  rootKey: 'game_templates',
  normalizer: normalizers.fromManageGameTemplate,
});

const verify = ({ id, scores }) => {
  const scoresArray = Object.entries(scores)
    .sort((a, b) => b[1] - a[1])
    .map(a => ({ id: a[0], score: a[1] }));

  return {
    method: 'PUT',
    url: `/manager/v4/campaigns/${id}/verify`,
    data: {
      verify: {
        quests: scoresArray,
      },
    },
  };
};

const reject = ({ id }) => ({
  method: 'PUT',
  url: '/manager/campaigns/reject',
  data: {
    campaign_id: id,
  },
});

const results = ({ id }) => ({
  method: 'GET',
  url: '/manager/campaigns/results',
  data: {
    campaign_id: id,
  },
});

const cancel = ({ id }) => ({
  method: 'PUT',
  url: `/manager/campaigns/${id}/cancel`,
});

const escrowableFunds = () => ({
  method: 'GET',
  url: '/manager/v4/campaigns/escrowable_funds',
});

const endpoints = {
  list,
  index,
  create,
  show,
  edit,
  update,
  templatesIndex,
  verify,
  reject,
  results,
  cancel,
  escrowableFunds,

  teamsIndex: () => ({
    method: 'GET',
    url: '/v2/search',
    rootKey: 'teams',
    normalizer: normalizers.fromManageGameTeam,
  }),

  eligiblePeopleIndex: ({ kpiGroupId }) => ({
    method: 'GET',
    url: `/manager/v4/kpi_groups/${kpiGroupId}/assigned_users`,
    normalizer: normalizers.fromManageGameEligiblePeople,
  }),

  rankingsIndex: ({ id }) => ({
    method: 'GET',
    url: `/v3/games/${id}/leaderboard`,
    rootKey: 'games',
    rootData: { id },
    normalizer: normalizers.fromGameRanking,
  }),

  resultsIndex: ({ id }) => ({
    method: 'GET',
    url: `/manager/v4/campaigns/${id}/results`,
    rootKey: 'campaign_results',
    rootData: { id },
    normalizer: normalizers.fromGameResults,
  }),

  eventsIndex: ({ id }) => ({
    method: 'GET',
    url: `/v3/games/${id}/events`,
    rootKey: 'games',
    rootData: { id },
    normalizer: normalizers.fromGameEvent,
  }),

  createFromTemplate: ({ template }) => ({
    method: 'GET',
    url: `/v3/templates/${template}`,
  }),

  validMetricsIndex: () => ({
    method: 'GET',
    url: '/manager/v4/kpi_groups/for_game',
    normalizer: normalizers.fromManageGamesValidMetricsIndex,
    rootKey: 'kpi_groups',
  }),
};

const api = makeApiActions(endpoints);

export default api;
