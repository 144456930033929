import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,

    '& > div': {
      maxHeight: 200,
    },
  },
}));

function ArcMultiSelectMenu(props) {
  const classes = useStyles();

  return (
    <Paper square className={classes.root} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

ArcMultiSelectMenu.displayName = 'ArcMultiSelectMenu';

ArcMultiSelectMenu.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.element.isRequired,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: PropTypes.shape({}).isRequired,
  selectProps: PropTypes.shape({}).isRequired,
};

export default ArcMultiSelectMenu;
