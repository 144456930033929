import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  date,
  ArcView,
  ArcText,
  ArcScroll,
  ArcSimpleDialog,
  ArcDataTableCell,
  ArcDataTableRow,
  ArcPlaceholder,
} from 'arcade-frontend-ui';

const IntegrationHistoryMessageDialog = ({
  open,
  type,
  run,
  messages,
  onClose,
  isPending,
}) => {
  if (!run) return null;

  const { sheetName, createdAt } = run;

  return (
    <ArcSimpleDialog
      open={open}
      title={`Integration run ${type}s`}
      onClose={onClose}
    >
      <ArcView padding={16}>
        <ArcText isLight color="grey">
          {sheetName} ({date.simpleLocaleDateTime({ datetime: createdAt })})
        </ArcText>
      </ArcView>
      <ArcView scrollParent>
        <ArcScroll>
          <Table>
            <TableHead>
              <TableRow>
                <ArcDataTableCell headerCell>Message</ArcDataTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!messages || isPending
                ? [0, 1, 2].map(index => (
                    <ArcDataTableRow
                      key={index.toString()}
                      shade={index % 2 === 0}
                    >
                      <ArcDataTableCell>
                        <ArcPlaceholder>Message</ArcPlaceholder>
                      </ArcDataTableCell>
                    </ArcDataTableRow>
                  ))
                : messages.map(({ id, readableMessage, key }, index) => (
                    <ArcDataTableRow key={id} shade={index % 2 === 0}>
                      <ArcDataTableCell>
                        {readableMessage || key}
                      </ArcDataTableCell>
                    </ArcDataTableRow>
                  ))}
            </TableBody>
          </Table>
        </ArcScroll>
      </ArcView>
    </ArcSimpleDialog>
  );
};

IntegrationHistoryMessageDialog.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string.isRequired,
  run: PropTypes.shape({
    id: PropTypes.string,
    sheetName: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
    }),
  ),
  onClose: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
};

IntegrationHistoryMessageDialog.defaultProps = {
  open: false,
  run: false,
  messages: null,
  isPending: false,
};

export default IntegrationHistoryMessageDialog;
