import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

import { ArcCheckbox, ArcEntity, ArcView, ArcButton } from 'arcade-frontend-ui';

const TYPES = {
  ENTITY: 'entity',
  GROUP: 'group',
  PERSON: 'person',
  TEAM: 'team',
};

class ArcAudienceMember extends React.PureComponent {
  static displayName = 'ArcAudienceMember';

  static propTypes = {
    checkboxDisabled: PropTypes.bool,
    checkboxTooltipTitle: PropTypes.string,
    checked: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
    entityDisabled: PropTypes.bool,
    entityTooltipTitle: PropTypes.string,
    hasChange: PropTypes.bool,
    hasClick: PropTypes.bool,
    hasRemove: PropTypes.bool,
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    isNested: PropTypes.bool,
    isPreSelected: PropTypes.bool,
    letter: PropTypes.string,
    name: PropTypes.string,
    navigationDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onRemove: PropTypes.func,
    partiallyChecked: PropTypes.bool,
    teamName: PropTypes.string,
    type: PropTypes.oneOf(Object.values(TYPES)),
  };

  static defaultProps = {
    checkboxDisabled: false,
    checkboxTooltipTitle: '',
    checked: false,
    className: 'animated fadeIn',
    color: '',
    entityDisabled: false,
    entityTooltipTitle: '',
    hasChange: false,
    hasClick: false,
    hasRemove: false,
    imageUrl: '',
    isNested: false,
    isPreSelected: false,
    letter: '',
    name: '',
    navigationDisabled: false,
    onChange: global.noop,
    onClick: global.noop,
    onRemove: global.noop,
    partiallyChecked: false,
    teamName: '',
    type: TYPES.PERSON,
  };

  static TYPES = TYPES;

  handleChange = () => {
    const { id, type } = this.props;

    this.props.onChange({ id, type, checked: !this.props.checked });
  };

  handleClick = () => {
    const { id, type } = this.props;

    this.props.onClick({ id, type });
  };

  handleRemove = () => {
    const { id, type } = this.props;

    this.props.onRemove({ id, type });
  };

  render() {
    const {
      checkboxDisabled,
      checkboxTooltipTitle,
      className,
      entityDisabled,
      entityTooltipTitle,
      hasChange,
      hasClick,
      hasRemove,
      isNested,
      isPreSelected,
      navigationDisabled,
      onChange,
      onClick,
      onRemove,
      ...rest
    } = this.props;

    const checkbox = hasChange && (
      <ArcCheckbox
        data-testid="ArcAudienceMember-ArcCheckbox"
        checked={this.props.checked}
        disabled={checkboxDisabled}
        partiallyChecked={this.props.partiallyChecked}
        onClick={onChange ? this.handleChange : undefined}
        icon={<CheckBoxOutlineBlank fontSize="small" />}
        checkedIcon={<CheckBox fontSize="small" />}
      />
    );

    const entity = (
      <ArcEntity
        {...rest}
        onClick={
          hasClick && onClick && !navigationDisabled
            ? this.handleClick
            : undefined
        }
        disabled={entityDisabled}
      >
        {hasClick && this.props.type !== TYPES.PERSON && (
          <ArcView>
            <ChevronRightIcon
              color={navigationDisabled ? 'disabled' : 'action'}
            />
          </ArcView>
        )}
      </ArcEntity>
    );

    return (
      <ArcView
        role="listitem"
        data-testid={`ArcAudienceMember-${this.props.type}-${this.props.id}`}
        position="relative"
        row
        align="center"
        className={className}
        color={isPreSelected ? 'grey-tint' : undefined}
        marginLeft={isNested ? '16' : undefined}
        borderLeft={isNested ? 'default' : undefined}
        borderLeftWidth={isNested ? '1' : undefined}
      >
        {isPreSelected && (
          <ArcView
            position="absolute"
            style={{
              backgroundColor: this.props.color,
              top: 0,
              left: 0,
              bottom: 0,
              width: 2,
              height: '100%',
            }}
          />
        )}

        {checkboxTooltipTitle ? (
          <Tooltip
            aria-label={checkboxTooltipTitle}
            title={checkboxTooltipTitle}
            placement="right"
          >
            <ArcView>{checkbox}</ArcView>
          </Tooltip>
        ) : (
          checkbox
        )}

        {entityTooltipTitle ? (
          <Tooltip
            aria-label={entityTooltipTitle}
            title={entityTooltipTitle}
            placement="top"
          >
            <ArcView flex="9999">{entity}</ArcView>
          </Tooltip>
        ) : (
          entity
        )}

        {hasRemove && <ArcView spacer />}

        {hasRemove && (
          <ArcButton color="danger" onClick={this.handleRemove} size="small">
            <RemoveCircleOutline color="action" fontSize="small" />
          </ArcButton>
        )}
      </ArcView>
    );
  }
}

export default ArcAudienceMember;
