import React from 'react';
import PropTypes from 'prop-types';
import { Platform } from 'react-primitives';
import { Svg } from 'react-primitives-svg';


import ArcBox from '../../components/ArcBox';

const RootComponent = Platform.select({
  web: ArcBox,
  default: Svg,
});

const Icon = props => (
  <RootComponent
    component={Svg}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    {props.children}
  </RootComponent>
);

Icon.propTypes = {
  children: PropTypes.node,
};

Icon.defaultProps = {
  children: null,
};

export default Icon;
