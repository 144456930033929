import React from 'react';
import PropTypes from 'prop-types';

import {
  ArcView,
  ArcText,
  ArcMarkdownContent,
  ArcLink,
  createWithStyles,
} from 'arcade-frontend-ui';

import { activityRecord } from '../../propTypes';
import Portrait from '../Portrait';

const styles = {
  Title: () => ({
    root: {
      fontSize: 24,
      textAlign: 'center',
    },
  }),
  PortraitWrapper: () => ({
    root: {
      width: 0,
      position: 'relative',
    },
  }),
  PortraitContainer: () => ({
    root: {
      position: 'absolute',
      left: -44,
    },
  }),
  Background: theme => ({
    root: {
      margin: theme.spacing(4),
      marginLeft: -8,
      marginRight: -8,
      backgroundImage:
        'url(https://s3.amazonaws.com/arcade-assets-public/app/coins-bg.png)',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      paddingLeft: 44,
      paddingRight: 44,
      height: 88,
    },
  }),
  Body: () => ({
    root: {
      textAlign: 'center',
    },
  }),
  Recipients: theme => ({
    root: {
      marginBottom: theme.spacing(2),
      color: theme.palette.grey.main,
      cursor: 'pointer',
    },
  }),
};
const Title = createWithStyles(styles.Title)(ArcView);
const Background = createWithStyles(styles.Background)(ArcView);
const PortraitWrapper = createWithStyles(styles.PortraitWrapper)(ArcView);
const PortraitContainer = createWithStyles(styles.PortraitContainer)(ArcView);
const Body = createWithStyles(styles.Body)(ArcView);
const Recipients = createWithStyles(styles.Recipients)(ArcView);

const RewardActivity = ({ activity, onQuicklinkUser, onRecipientsClick }) => {
  const { additionalInformation, content } = activity;
  const { rewardedUsers, reward } = additionalInformation;

  const handleRecipientClick = () => {
    if (rewardedUsers && rewardedUsers.length === 1) {
      onQuicklinkUser(rewardedUsers[0].id, 'person');
    } else {
      onRecipientsClick();
    }
  };

  const totalUsers = rewardedUsers.length;

  const joiner = totalUsers > 2 ? ', ' : ' and ';
  const names = rewardedUsers
    .slice(0, 2)
    .map(u => u.name)
    .join(joiner);
  const moreClause = totalUsers > 2 && (
    <ArcLink size="inherit">and {totalUsers - 2} others</ArcLink>
  );
  const wereClause = totalUsers > 1 ? 'were' : 'was';
  const eachClause = totalUsers > 1 && 'each';
  const limitedUsers =
    totalUsers > 5 ? rewardedUsers.slice(0, 5) : rewardedUsers;

  return (
    <ArcView>
      <Title>Tokens Awarded!</Title>
      <Background>
        {limitedUsers.map(user => (
          <PortraitWrapper key={user.id.toString()}>
            <PortraitContainer>
              <Portrait
                onClick={handleRecipientClick}
                url={user.image}
                size={88}
                badge={user.badge}
              />
            </PortraitContainer>
          </PortraitWrapper>
        ))}
      </Background>
      <Body>
        {reward && (
          <Recipients onClick={handleRecipientClick}>
            <ArcText isSmall>
              {names} {moreClause} {wereClause} awarded{' '}
              {reward.amount.toLocaleString()} tokens {eachClause}
            </ArcText>
          </Recipients>
        )}
        <ArcMarkdownContent content={content} />
      </Body>
    </ArcView>
  );
};

RewardActivity.displayName = 'RewardActivity';

RewardActivity.propTypes = {
  activity: activityRecord.isRequired,
  onQuicklinkUser: PropTypes.func,
  onRecipientsClick: PropTypes.func,
};

RewardActivity.defaultProps = {
  onQuicklinkUser: global.noop,
  onRecipientsClick: global.noop,
};

export default RewardActivity;
