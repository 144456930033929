import React from 'react';
import { connect } from 'react-redux';
import { actions, Control } from 'react-redux-form';
// import SelectField from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ArcSelect from '../../elements/ArcSelect';
import { makeValidators } from '../../helpers/utils/validators';
// import ArcStyles from '../../styles';
import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcFormError from '../ArcFormError';

export class ArcFormSelect extends React.PureComponent {
  static propTypes = {
    model: ArcPropTypes.string.isRequired,
    label: ArcPropTypes.string.isRequired,
    items: ArcPropTypes.formSelectItems.isRequired,
    validations: ArcPropTypes.objectOf(ArcPropTypes.oneOfType([
      ArcPropTypes.bool,
      ArcPropTypes.number,
    ])),
    onChange: ArcPropTypes.func,
    initialValue: ArcPropTypes.oneOfType([
      ArcPropTypes.number,
      ArcPropTypes.string,
    ]),
    persist: ArcPropTypes.bool,
    fullWidth: ArcPropTypes.bool,
    hideLabel: ArcPropTypes.bool,
    style: ArcPropTypes.style,
  };

  static defaultProps = {
    validations: null,
    initialValue: null,
    onChange: ArcPropTypes.noop,
    persist: true,
    hideLabel: false,
    fullWidth: true,
    style: null,
  };

  constructor(props) {
    super(props);

    const {
      label,
      validations,
      fullWidth,
      style,
    } = props;

    let errorText;

    this.validators = undefined;

    if (validations) {
      const {
        messages,
        validators,
      } = makeValidators(label, validations);

      errorText = ({ fieldValue, model }) => ArcFormError(fieldValue, model, messages);

      this.validators = validators;
    }

    const onChange = ({ dispatch, model }) => (event) => {
      const { value } = event.target;
      dispatch(actions.change(model, value));
      this.props.onChange(event, value, model);
    };

    const value = ({ modelValue }) => modelValue || props.initialValue;

    this.mapProps = {
      value,
      onChange,
      error: errorText,
      label: props.hideLabel ? undefined : label,
      fullWidth,
      style,
    };
  }

  render() {
    return (
      <Control.custom
        persist={this.props.persist}
        model={this.props.model}
        component={ArcSelect}
        mapProps={this.mapProps}
        validators={this.validators}
      >
        {this.props.items && this.props.items.map(({ label, ...item }) => (
          <MenuItem key={item.value} {...item}>
            {label}
          </MenuItem>
        ))}
      </Control.custom>
    );
  }
}

export default connect()(ArcFormSelect);
