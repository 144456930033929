import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  ArcButton,
  ArcMainView,
  ArcScroll,
  ArcView,
  ArcViewTitleBar,
} from 'arcade-frontend-ui';

import { actions as actionsCore } from 'arcade-frontend-core/src/actions';
import { getTeams } from 'arcade-frontend-core/src/reducers/teams';

import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';

import { getCurrentUserFeatures, getCurrentUserPermission, getCurrentUser } from 'arcade-frontend-core/src/reducers/user';

import PeopleEditProfileModalContainer from 'arcade-frontend-people/src/containers/PeopleEditProfileModalContainer';
import PeopleInvitePersonFormContainer from 'arcade-frontend-people/src/containers/PeopleInvitePersonFormContainer';
import PeopleManagePermissionsModalContainer from 'arcade-frontend-people/src/containers/PeopleManagePermissionsModalContainer';

import { routes } from '../../../actions/manage';
import { actions } from '../../../actions/manage/people';
import { getManagePeopleById, getActiveSortedManagePeopleIds } from '../../../reducers/manage/people/peopleById';
import { getPeoplePermissions } from '../../../reducers/manage/people/peoplePermissions';
import { getCurrentProfile } from '../../../reducers/manage/people/currentProfile';
import { getIsFetching } from '../../../reducers/manage/people/isFetching';
import { getRequestStatus } from '../../../reducers/manage/people/requestStatus';

import ManagePeopleList from '../../../components/manage/people/ManagePeopleList';

const CLOSE_DELAY = 1300;

class ManagePeopleContainer extends React.PureComponent {
  static propTypes = {
    apiManagePeopleIndexRequest: PropTypes.func.isRequired,
    apiManagePeoplePermissionsIndexRequest: PropTypes.func.isRequired,
    apiManagePeoplePermissionsUpdateRequest: PropTypes.func.isRequired,
    apiManagePeopleProfileUpdateRequest: PropTypes.func.isRequired,
    appInvitePersonRequest: PropTypes.func.isRequired,
    appTaggableTeamsIndexRequest: PropTypes.func.isRequired,
    availablePeople: PropTypes.arrayOf(PropTypes.string),
    canGeofence: PropTypes.bool,
    canInviteUsers: PropTypes.bool,
    currentProfile: PropTypes.shape({}),
    currentUser: PropTypes.shape({
      id: PropTypes.string,
    }),
    isFetching: PropTypes.shape({
      people: PropTypes.bool,
      permissions: PropTypes.bool,
      profile: PropTypes.bool,
    }),
    requestStatus: PropTypes.shape({
      APP_INVITE_PERSON: PropTypes.string,
      APP_TAGGABLE_TEAMS_INDEX: PropTypes.string,
      LOCK_ACCOUNT: PropTypes.string,
      UNLOCK_ACCOUNT: PropTypes.string,
      MANAGE_PEOPLE_PERMISSIONS_UPDATE: PropTypes.string,
      MANAGE_PEOPLE_PROFILE_UPDATE: PropTypes.string,
      RESET_PASSWORD: PropTypes.string,
    }),
    peopleById: PropTypes.shape({}),
    peoplePermissions: PropTypes.shape({}),
    resetPassword: PropTypes.func,
    routeManage: PropTypes.func.isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape({})),
    lockAccount: PropTypes.func,
    unlockAccount: PropTypes.func,
  };

  static defaultProps = {
    availablePeople: [],
    canGeofence: false,
    canInviteUsers: false,
    currentProfile: {},
    currentUser: {},
    isFetching: false,
    requestStatus: {},
    peopleById: {},
    peoplePermissions: {},
    resetPassword: global.noop,
    teams: [],
    lockAccount: global.noop,
    unlockAccount: global.noop,
  };

  constructor(props) {
    super(props);

    this.requestAll();
  }

  state = {
    editingPerson: null,
    peoplePermissionsModalOpen: false,
    peopleProfileModalOpen: false,
    peopleInviteModalOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.requestAll();
    }

    const statusChanged = this.props.requestStatus.APP_INVITE_PERSON !== prevProps.requestStatus.APP_INVITE_PERSON;

    if (statusChanged && this.props.requestStatus.APP_INVITE_PERSON === 'SUCCESS') {
      setTimeout(this.handleClosePeopleInviteModal, CLOSE_DELAY);
    }
  }

  get identifierLabel() {
    if (this.props.availablePeople.length === 0 || !this.props.availablePeople[0].companyIdentifier) {
      return 'Company Identifier';
    }

    return this.props.availablePeople[0].companyIdentifier.label;
  }

  setPeopleInviteModalOpen = peopleInviteModalOpen => this.setState({ peopleInviteModalOpen });

  requestAll() {
    if (!this.props.currentUser.id) {
      return;
    }

    this.props.apiManagePeopleIndexRequest();
  }

  handleClickProfile = (e, person) => {
    this.showPeopleProfileModal(person);
  };

  handleClickPermissions = (e, person) => {
    this.props.apiManagePeoplePermissionsIndexRequest(person.id);
    this.showPeoplePermissionsModal(person);
  };

  handleClickCreate = () => {
    this.showPeopleCreateProfileModal();
  };

  handleClickReset = (e, person) => {
    this.props.resetPassword(person.id);
  };

  handleClickLockAccount = (e, person) => {
    this.props.lockAccount(person.id);
  };

  handleClickUnlockAccount = (e, person) => {
    this.props.unlockAccount(person.id);
  };

  handleClickEditProfile = (person) => {
    this.props.apiManagePeopleProfileUpdateRequest(person);
  };

  handleClickEditPermissions = (person, permissions) => {
    this.props.apiManagePeoplePermissionsUpdateRequest(person.id, permissions);
  };

  handleClickInvite = () => {
    if (!this.props.teams.length && this.props.requestStatus.APP_TAGGABLE_TEAMS_INDEX !== 'REQUEST') {
      this.props.appTaggableTeamsIndexRequest();
    }

    this.setPeopleInviteModalOpen(true);
  };

  handleClosePeopleInviteModal = () => this.setPeopleInviteModalOpen(false);

  handleSubmitInvite = values => this.props.appInvitePersonRequest(values);

  closePeoplePermissionsModal = () => this.setState({ peoplePermissionsModalOpen: false }, this.setState({ editingPerson: null }));

  showPeoplePermissionsModal = person => this.setState({ peoplePermissionsModalOpen: true, editingPersonId: person.id });

  closePeopleProfileModal = () => this.setState({ peopleProfileModalOpen: false }, this.setState({ editingPerson: null }));

  showPeopleProfileModal = person => this.setState({ peopleProfileModalOpen: true, editingPersonId: person.id });

  renderManagePeoplePermissionsModal() {
    return (
      <PeopleManagePermissionsModalContainer
        id={this.state.editingPersonId}
        open={this.state.peoplePermissionsModalOpen}
        onClose={this.closePeoplePermissionsModal}
      />
    );
  }

  // To be included in a future release
  // renderManagePeopleCreateProfileModal() {
  //   return (
  //     <ManagePeopleCreateProfileModal
  //       open={this.state.peopleCreateProfileModalOpen}
  //       onClose={this.closePeopleCreateProfileModal}
  //     />
  //   );
  // }

  renderManagePeopleProfileModal() {
    return (
      <>
        {this.state.peopleProfileModalOpen
      && (
      <PeopleEditProfileModalContainer
        id={this.state.editingPersonId}
        open={this.state.peopleProfileModalOpen}
        onClose={this.closePeopleProfileModal}
        onUpdateProfileSuccess={this.props.apiManagePeopleIndexRequest}
      />
      )
    }
      </>
    );
  }

  renderInvitePeopleForm() {
    return (
      <PeopleInvitePersonFormContainer
        open={this.state.peopleInviteModalOpen}
        onClose={this.handleClosePeopleInviteModal}
        identifierLabel={this.identifierLabel}
        canGeofence={this.props.canGeofence}
      />
    );
  }

  renderInvitePeopleButton() {
    return (
      <ArcView marginRight="8">
        <ArcButton
          color="primary"
          variant="outlined"
          onClick={this.handleClickInvite}
        >
          {'Invite'}
        </ArcButton>
      </ArcView>
    );
  }

  render() {
    return (
      <ArcMainView
        isViewing
        position="relative"
      >
        <ArcViewTitleBar
          onClickBack={this.props.routeManage}
          title="Manage People"
        >
          {this.props.canInviteUsers && this.renderInvitePeopleButton()}
        </ArcViewTitleBar>
        <ArcScroll
          color="paper"
        >
          <ManagePeopleList
            availablePeople={this.props.availablePeople}
            peopleById={this.props.peopleById}
            onClickProfile={this.handleClickProfile}
            onClickPermissions={this.handleClickPermissions}
            onClickCreate={this.showPeopleCreateProfileModal}
            isFetchingPeople={this.props.isFetching.people}
          />
          {this.state.peoplePermissionsModalOpen && this.renderManagePeoplePermissionsModal()}
          {this.renderManagePeopleProfileModal()}
          {this.props.canInviteUsers && this.renderInvitePeopleForm()}
        </ArcScroll>
      </ArcMainView>
    );
  }
}

const getState = state => ({
  availablePeople: getActiveSortedManagePeopleIds(state),
  canGeofence: getCurrentUserFeatures(state, FEATURE_FLAGS.CAN_GEOFENCE),
  canInviteUsers: getCurrentUserPermission(state, PERMISSIONS.INVITE_USER),
  currentProfile: getCurrentProfile(state),
  currentUser: getCurrentUser(state),
  isFetching: getIsFetching(state),
  peopleById: getManagePeopleById(state),
  peoplePermissions: getPeoplePermissions(state),
  requestStatus: getRequestStatus(state),
  teams: getTeams(state),
});

const getActions = dispatch => bindActionCreators({
  routeManage: routes.routeManage,
  apiManagePeopleIndexRequest: actions.apiManagePeopleIndexRequest,
  apiManagePeoplePermissionsIndexRequest: actions.apiManagePeoplePermissionsIndexRequest,
  apiManagePeoplePermissionsUpdateRequest: actions.apiManagePeoplePermissionsUpdateRequest,
  apiManagePeopleProfileUpdateRequest: actions.apiManagePeopleProfileUpdateRequest,
  appTaggableTeamsIndexRequest: actionsCore.appTaggableTeamsIndexRequest,
  appInvitePersonRequest: actionsCore.appInvitePersonRequest,
  resetPassword: actions.resetPassword,
  lockAccount: actions.lockAccount,
  unlockAccount: actions.unlockAccount,
  resetRequestState: actions.resetRequestState,
}, dispatch);

export default connect(getState, getActions)(ManagePeopleContainer);
