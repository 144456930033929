import { createReducer } from 'arcade-frontend-ui';
import { createSelector } from 'reselect';

import * as types from '../actions/types';

export const getIds = games => Object.keys(games);

const addGamesToState = (state, data) => {
  const newState = { ...state };

  data.forEach((game) => {
    newState[game.id] = game;
  });

  return newState;
};

const handleGameLoadSuccess = (state, action) => {
  if (!action.payload) return state;

  const { data } = action.payload;
  return addGamesToState(state, [data]);
};

const handleGamesLoadSuccess = (state, action) => {
  if (!action.payload) return state;

  const { data } = action.payload;
  return addGamesToState(state, data);
};

const filterCollectionByPropValue = (collection, prop, value, sort, sortDirection = 'ascending') => {
  const ids = Object.keys(collection).filter(id => collection[id][prop] === value);

  if (sort && sortDirection === 'descending') {
    return ids.sort((a, b) => collection[b][sort] - collection[a][sort]);
  } if (sort && sortDirection === 'ascending') {
    return ids.sort((a, b) => collection[a][sort] - collection[b][sort]);
  }
  return ids;
};

// Selectors
export const getGamesById = state => state.games.gamesById;
const getGamesFilterProp = prop => () => prop;
const getGamesFilterValue = value => () => value;
const getGamesSortValue = sort => () => sort;
const getGamesSortDirection = direction => () => direction;

const createFilterSelector = (prop, value, sort, direction) => (
  createSelector(
    [
      getGamesById,
      getGamesFilterProp(prop),
      getGamesFilterValue(value),
      getGamesSortValue(sort),
      getGamesSortDirection(direction),
    ],
    filterCollectionByPropValue,
  )
);

const initialState = {};

const handlers = {
  [types.GAMES_INDEX.SUCCESS]: handleGamesLoadSuccess,
  [types.GAMES_GET.SUCCESS]: handleGameLoadSuccess,
};

const gamesById = createReducer(initialState, handlers);

export default gamesById;

export const getGames = state => Object.keys(getGamesById(state));

export const getGamesActive = createFilterSelector('status', 'active', 'expiresAt');
export const getGamesPrelaunch = createFilterSelector('status', 'prelaunch', 'startsAt');
export const getGamesCompleted = createFilterSelector('status', 'completed', 'completedAt', 'descending');
export const getGamesVerifying = createFilterSelector('status', 'needs_verification', 'completedAt', 'descending');
