import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../actions/customAudience';

const initialState = {};

const handleCustomAudienceEntitiesSuccess = (state, action) => {
  const { data } = action.payload;

  return data;
};

const handlers = {
  [types.CUSTOM_AUDIENCE_ENTITIES.SUCCESS]: handleCustomAudienceEntitiesSuccess,
};

const entitiesByUuid = createReducer(initialState, handlers);

export const getEntitiesByUuid = state => state.customAudience.entitiesByUuid;

export default entitiesByUuid;
