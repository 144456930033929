import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { routes } from 'arcade-frontend-features/src/actions/manage';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import { ArcMainView, ArcViewTitleBar, ArcScroll } from 'arcade-frontend-ui';
import {
  getCurrentUserFeatures,
  getCurrentUserPermission,
} from 'arcade-frontend-core/src/reducers/user';

import TokenUserBudgetListContainer from '../containers/TokenUserBudgetListContainer';
import TokenBudgetFundsTileContainer from '../containers/TokenBudgetFundsTileContainer';

const TokenUserBudgetListView = ({
  goBack,
  hasFeatureTokenBudgets,
  canViewTokenBudgetFunds,
}) => (
  <>
    <ArcMainView isViewing fullHeight hasScroll={false}>
      {hasFeatureTokenBudgets && (
        <>
          <ArcViewTitleBar onClickBack={goBack} title="Token Budgets" />
          <ArcScroll>
            {canViewTokenBudgetFunds && <TokenBudgetFundsTileContainer />}
            <TokenUserBudgetListContainer />
          </ArcScroll>
        </>
      )}
    </ArcMainView>
  </>
);

TokenUserBudgetListView.displayName = 'TokenUserBudgetListView';

TokenUserBudgetListView.propTypes = {
  goBack: PropTypes.func.isRequired,
  hasFeatureTokenBudgets: PropTypes.bool.isRequired,
  canViewTokenBudgetFunds: PropTypes.bool.isRequired,
};

const getState = (state, props) => ({
  hasFeatureTokenBudgets: getCurrentUserFeatures(
    state,
    FEATURE_FLAGS.TOKEN_BUDGETS,
  ),
  canViewTokenBudgetFunds: getCurrentUserPermission(
    state,
    PERMISSIONS.MANAGE_TOKENS,
  ),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      goBack: routes.routeManage,
    },
    dispatch,
  );

export default connect(getState, getActions)(TokenUserBudgetListView);
