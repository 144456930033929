import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';

import {
  ArcIconButton,
  ArcResponsiveDialog,
  ArcText,
  ArcView,
  ArcButton,
} from 'arcade-frontend-ui';

const RewardCancelPurchaseMessageDialog = ({
  open,
  onClose,
  onTryAgain,
  message,
  cancellationCode,
}) => (
  <ArcResponsiveDialog onClose={onClose} open={open}>
    <DialogTitle>
      <ArcView row align="center">
        <ArcText>
          {cancellationCode === 'purchase_cancelled'
            ? 'Reward Purchase cancelled'
            : 'Reward Cancellation failed'}
        </ArcText>

        <ArcView spacer />

        <ArcIconButton onClick={onClose} size="small">
          <CloseIcon />
        </ArcIconButton>
      </ArcView>
    </DialogTitle>

    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>

    <ArcView spacer />

    <DialogActions>
      {cancellationCode !== 'purchase_cancelled' && (
        <ArcButton onClick={onClose} label={'Close'} />
      )}
      <ArcButton
        variant="contained"
        onClick={
          cancellationCode === 'purchase_cancelled' ? onClose : onTryAgain
        }
        label={cancellationCode === 'purchase_cancelled' ? 'Okay' : 'Try Again'}
        color={cancellationCode === 'purchase_cancelled' ? 'primary' : 'danger'}
      />
    </DialogActions>
  </ArcResponsiveDialog>
);

RewardCancelPurchaseMessageDialog.displayName =
  'RewardCancelPurchaseMessageDialog';

RewardCancelPurchaseMessageDialog.propTypes = {
  onClose: PropTypes.func,
  onTryAgain: PropTypes.func,
  open: PropTypes.bool,
  message: PropTypes.string,
  cancellationCode: PropTypes.string,
};

RewardCancelPurchaseMessageDialog.defaultProps = {
  onClose: global.noop,
  onTryAgain: global.noop,
  open: false,
  message: '',
  cancellationCode: '',
};

export default RewardCancelPurchaseMessageDialog;
