import React from 'react';
import PropTypes from 'prop-types';

const ArcRace = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 360 240"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="metrics/games" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" fillRule="nonzero">
        <g id="legs" transform="translate(16.875000, 99.512195)">
          <polygon id="Shape" fill="#FF7761" points="22.0640625 2.62478049 22.0640625 139.939317 0.0084375 139.939317 0.0084375 0.422634146" />
          <polygon id="Shape" fill="#FF7761" points="326.234531 0.429073171 326.234531 139.939317 304.178906 139.939317 304.178906 2.62478049" />
          <g id="shadow" transform="translate(0.000000, 0.000000)" fill="#F1543F">
            <path d="M0.0084375,0.422634146 L22.0640625,2.62478049 L22.0640625,23.7266341 C14.7100781,23.0476098 7.35539062,22.313561 0.0084375,21.5244878 L0.0084375,0.422634146 Z" id="Shape" />
            <path d="M326.234531,0.429073171 L326.234531,21.5250732 C318.887578,22.3141463 311.533594,23.0481951 304.178906,23.7272195 L304.178906,2.62536585 L326.234531,0.429073171 Z" id="Shape" />
          </g>
        </g>
        <g id="banner">
          <path d="M360,0.548487805 L360,98.0329756 C309.930469,103.966244 259.538203,107.409366 209.100938,108.369951 C139.260938,109.690537 69.3260156,106.246829 0,98.0323902 L0,0.548487805 C17.0451563,2.56682927 34.126875,4.2977561 51.2303906,5.74126829 L51.2374219,5.74126829 C153.969609,14.4081951 257.642578,12.6772683 360,0.548487805 Z" id="Shape" fill="#FFD978" />
          <path d="M209.100937,108.369366 C139.260938,109.690537 69.3260156,106.246829 0,98.0323902 L0,0.548487805 C17.0451563,2.56682927 34.126875,4.2977561 51.2303906,5.74126829 C51.2303906,5.74126829 96.9651563,102.03278 209.006016,108.363512 C209.034844,108.363512 209.072109,108.369366 209.100937,108.369366 Z" id="Shape" fill="#FFC363" />
        </g>
        <g id="text" transform="translate(70.219780, 35.000000)">
          <path d="M5.02407622,0.267997551 C2.69137169,0.504939592 0.921303926,2.50802 0.941701634,4.88664204 L0.941701634,50.8039392 C0.94283484,53.3550918 2.98147242,55.4214335 5.49435671,55.4202833 C8.00610779,55.4191331 10.0413457,53.3533665 10.0430456,50.8039392 L10.0430456,9.43799959 L23.5610598,9.43799959 C26.0558128,9.65136245 28.2485664,7.77135388 28.4582095,5.23802939 C28.6678526,2.7064302 26.8156274,0.481360408 24.3208744,0.267422449 C24.0681695,0.246143673 23.8137648,0.246143673 23.5610598,0.267422449 L5.49209029,0.267422449 C5.33627447,0.259946122 5.17989204,0.259946122 5.02407622,0.267997551 L5.02407622,0.267997551 Z" id="Shape" fill="#FFFFFF" />
          <path d="M0.941701634,3.88999939 L0.941701634,50.8039392 C0.94283484,53.3550918 2.98147242,55.4214335 5.49435671,55.4202833 C8.00610779,55.4191331 10.0413457,53.3533665 10.0430456,50.8039392 L10.0430456,12.4400024 L1.78021978,2 C1.62440396,1.99252367 1.78021978,2 0.941701634,3.88999939 Z" id="Shape" fill="#EBEBEB" />
          <path d="M7.76756793,23.2272212 C5.25411704,23.2013416 3.19678156,25.24928 3.17185103,27.7998576 C3.14692049,30.3510102 5.16402716,32.4386306 7.67691145,32.4645102 C7.7069414,32.4645102 7.73697136,32.4645102 7.76756793,32.4645102 L14.4597159,32.4645102 C16.9731668,32.4386306 18.9902735,30.3504351 18.9647763,27.7998576 C18.9404124,25.2849363 16.9374708,23.2519506 14.4597159,23.2272212 L7.76756793,23.2272212 Z" id="Shape" fill="#EBEBEB" />
          <path d="M86.53445,0.267997551 C84.2028787,0.540020816 82.4605745,2.57300653 82.5189346,4.95392898 L82.5189346,34.2300735 L63.7808069,2.57703224 C62.5036837,0.391069388 59.7222296,-0.330108571 57.5691382,0.967321633 C56.1832273,1.80179469 55.3378557,3.32063918 55.3486211,4.95622939 L55.3486211,50.8062396 C55.1378448,53.3378388 56.9895034,55.5640588 59.484823,55.7779967 C61.979576,55.9925098 64.1728962,54.1125012 64.3831059,51.5803269 C64.4046368,51.3232563 64.4046368,51.0638853 64.3831059,50.8062396 L64.3831059,21.3937959 L83.1880927,53.1825612 C84.4652159,55.3685241 87.24667,56.089702 89.3997614,54.7922718 C90.7856723,53.9577988 91.6310439,52.4389543 91.6202785,50.8033641 L91.6202785,4.95335388 C91.6854378,2.41255306 89.7091266,0.299628163 87.2064412,0.234641633 C86.9820664,0.228890612 86.757125,0.239817551 86.53445,0.267997551 L86.53445,0.267997551 Z" id="Shape" fill="#FFFFFF" />
          <path d="M104.670279,0.267997551 C102.338707,0.540020816 100.596403,2.57300653 100.654763,4.95392898 L100.654763,50.8039392 C100.655896,53.3550918 102.694534,55.4214335 105.207418,55.4202833 C107.719169,55.4191331 109.754407,53.3533665 109.756107,50.8039392 L109.756107,4.95392898 C109.821267,2.41312816 107.844955,0.300203265 105.34227,0.235216735 C105.117895,0.228890612 104.892954,0.239817551 104.670279,0.267997551 Z" id="Shape" fill="#FFFFFF" />
          <polygon id="Shape" fill="#FFFFFF" points="168.647123 23.2956584 168.647123 32.4645102 195.884295 32.4645102 195.884295 23.2956584" />
          <path d="M168.111683,0.267997551 C165.780112,0.540020816 164.037807,2.57300653 164.096168,4.95392898 L164.096168,50.8039392 C164.097301,53.3550918 166.135938,55.4214335 168.648823,55.4202833 C171.160574,55.4191331 173.195812,53.3533665 173.197511,50.8039392 L173.197511,4.95392898 C173.262671,2.41312816 171.28636,0.300203265 168.783674,0.235216735 C168.559299,0.228890612 168.334925,0.239817551 168.111683,0.267997551 Z" id="Shape" fill="#FFFFFF" />
          <path d="M195.348856,0.267997551 C193.017284,0.540020816 191.27498,2.57300653 191.33334,4.95392898 L191.33334,50.8039392 C191.122564,53.3355384 192.974222,55.5617584 195.468975,55.7756963 C197.963728,55.9902094 200.157049,54.1107759 200.367258,51.5780265 C200.388789,51.3209559 200.388789,51.0615849 200.367258,50.8039392 L200.367258,4.95392898 C200.427885,2.41312816 198.448174,0.303653878 195.945489,0.242117959 C195.746611,0.237517143 195.547167,0.246718776 195.348856,0.267997551 Z" id="Shape" fill="#FFFFFF" />
          <path d="M41.2283078,0.267997551 C38.8967365,0.540020816 37.1544323,2.57300653 37.2127924,4.95392898 L37.2127924,50.8039392 C37.0020161,53.3355384 38.8536747,55.5617584 41.3484277,55.7756963 C43.8431807,55.9902094 46.0365009,54.1107759 46.2467106,51.5780265 C46.2682415,51.3209559 46.2682415,51.0615849 46.2467106,50.8039392 L46.2467106,4.95392898 C46.3073371,2.41312816 44.3276262,0.303653878 41.8249408,0.242117959 C41.6260632,0.237517143 41.4266189,0.246718776 41.2283078,0.267997551 Z" id="Shape" fill="#FFFFFF" />
          <path d="M37.2127924,36.9499969 L37.2127924,50.8039392 C37.0020161,53.3355384 38.8536747,55.5617584 41.3484277,55.7756963 C43.8431807,55.9902094 46.0365009,54.1107759 46.2467106,51.5780265 C46.2682415,51.3209559 46.2682415,51.0615849 46.2467106,50.8039392 L46.2467106,42.9499969 C43.7802198,42 40.7802198,40 37.2127924,36.9499969 Z" id="Shape" fill="#EBEBEB" />
          <path d="M132.108029,0.472733878 C123.981809,1.80812082 118.186593,9.33045551 118.857451,17.657358 C119.528309,25.9848355 126.486194,32.4852139 134.717802,32.4650853 L135.053798,32.4650853 C135.077028,32.4650853 135.099693,32.4650853 135.122923,32.4650853 C138.71292,32.4570339 141.589563,35.1496616 141.88193,38.7820061 C142.173164,42.4132004 139.736772,45.6032914 136.193803,46.1864449 C132.650268,46.7661478 129.350939,44.5623567 128.497634,41.0226037 C128.118577,38.5134335 125.806837,36.7904278 123.333615,37.1745959 C120.860959,37.5599143 119.163417,39.9063306 119.542474,42.416651 C119.582703,42.6817731 119.645596,42.9434445 119.730586,43.1970645 C121.687633,51.3111792 129.540184,56.6245469 137.665838,55.2862845 C145.790925,53.9474469 151.587273,46.3607008 150.916415,38.0343735 C150.247257,29.7258743 143.329601,23.3031347 135.122923,23.2939331 L134.721202,23.2939331 C134.697971,23.2939331 134.675307,23.2939331 134.652076,23.2939331 C131.06208,23.3019845 128.185436,20.5386192 127.893069,16.9091502 C127.601835,13.2779559 130.038795,10.156302 133.581197,9.57314857 C137.124732,8.99344571 140.424061,11.1983869 141.277365,14.7358396 C141.655289,17.24616 143.966463,18.9691657 146.439685,18.5861478 C148.912907,18.2019796 150.611016,15.8567135 150.233092,13.3463931 C150.192863,13.0801208 150.12997,12.8172992 150.044413,12.562529 C148.087366,4.44726408 140.234816,-0.866103673 132.109162,0.47330898 L132.108029,0.472733878 Z" id="Shape" fill="#FFFFFF" />
        </g>
      </g>
    </g>
  </svg>
);

export default ArcRace;

ArcRace.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

ArcRace.defaultProps = {
  height: 360,
  width: 240,
};
