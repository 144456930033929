import React, { FC, useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/core';
import { ARCADE_LOGIN_PROXY_URL } from 'react-native-dotenv';
import axios, { AxiosResponse } from 'axios';

import {
  ArcLoginLogoAnimation,
  ArcLoginBackgroundAnimation,
  ArcLoginSpinnerAnimation,
  ArcLoginFormContainerAnimation,
} from '@workplacearcade/ui/components/Animations';

import { removeBootLogo } from 'arcade-frontend-core/src/helpers/removeBootLogo';

import { ArcadeLogo } from './ArcadeLogo';
import { useLogin } from './useLogin';

const LOGIN_PROXY_URL =
  ARCADE_LOGIN_PROXY_URL ||
  process.env.ARCADE_LOGIN_PROXY_URL ||
  'https://login-proxy.arcade.co';

const initialAnimations = {
  loading: false,
  logoMoveUp: true,
  backgroundExit: false,
};

const CbLogin: FC = () => {
  const [animations, setAnimations] = useState(initialAnimations);
  const [authState, setAuthState] = useState(null);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const { encodedObject, handleLoginSuccess, loginViaLink } = useLogin();

  useEffect(() => {
    let timeout;

    if (encodedObject) {
      loginViaLink()
        .catch(error => {
          console.log('CbLogin loginViaLink failed with', error.message);
          if (window.arcPostMessage) {
            window.arcPostMessage('native.networkTimeout');
          }
        })
        .finally(() => {
          setBackgroundLoaded(true);
        });
    } else {
      removeBootLogo();
      timeout = setTimeout(() => {
        setBackgroundLoaded(true);
      }, 800);
    }

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [encodedObject]);

  const handleBackgroundEnterComplete = (): void => {
    setAnimations(stale => ({ ...stale, loading: false }));
  };
  const handleBackgroundExitComplete = (): void => {
    handleLoginSuccess(authState);
  };
  const handleFormSubmit = (authInfo): void => {
    setAnimations({
      loading: true,
      logoMoveUp: false,
      backgroundExit: true,
    });
    setBackgroundLoaded(false);
    setAuthState(authInfo);
  };
  const handleLoginNetworkCall = (values): Promise<AxiosResponse> => {
    return axios({
      method: 'POST',
      baseURL: LOGIN_PROXY_URL,
      url: '/users/sign_in.json',
      data: {
        user: {
          ...values,
          username: values?.email,
        },
      },
    });
  };
  const handleResetNetworkCall = (values): Promise<AxiosResponse> => {
    return axios({
      method: 'PUT',
      baseURL: LOGIN_PROXY_URL,
      url: '/v4/passwords/reset',
      data: {
        email: values?.email,
      },
    });
  };

  if (window.arcPostMessage) {
    return null;
  }

  return (
    <>
      <ArcLoginBackgroundAnimation
        animate={animations.backgroundExit}
        onEnterComplete={handleBackgroundEnterComplete}
        onExitComplete={handleBackgroundExitComplete}
      />
      <Flex width="100%" flexDirection="column">
        <ArcLoginLogoAnimation
          Logo={ArcadeLogo}
          animate={animations.logoMoveUp}
        />
        <ArcLoginSpinnerAnimation animate={animations.loading} />
        <ArcLoginFormContainerAnimation
          animate={backgroundLoaded}
          onSubmit={handleFormSubmit}
          networkCall={handleLoginNetworkCall}
          resetNetworkCall={handleResetNetworkCall}
        />
      </Flex>
    </>
  );
};

export default CbLogin;
