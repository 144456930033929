import * as types from 'arcade-frontend-core/src/types/routes';

export const routeGames = (payload = {}, { tab } = {}) => ({
  type: types.ROUTE_GAMES,
  payload,
  query: {
    tab,
  },
});

export const routeManageGames = (payload = {}, { tab } = {}) => ({
  type: types.ROUTE_MANAGE_GAMES,
  payload,
  query: {
    tab,
  },
});

export const routeManageGamesCreate = (type, tab) => ({
  type: types.ROUTE_MANAGE_GAMES_CREATE,
  payload: {
    type,
  },
  query: {
    tab,
  },
});

export const routeManageGamesShow = (id, gameDetailsType) => ({
  type: types.ROUTE_MANAGE_GAMES_SHOW,
  payload: {
    id,
    gameDetailsType,
  },
});

export const routeManageGamesEdit = (id, tab) => ({
  type: types.ROUTE_MANAGE_GAMES_EDIT,
  payload: {
    id,
  },
  query: {
    tab,
  },
});

export const routeManageGamesClone = (id, tab) => ({
  type: types.ROUTE_MANAGE_GAMES_CLONE,
  payload: {
    id,
  },
  query: {
    tab,
  },
});
