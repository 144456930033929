import ArcNumberField from '../elements/ArcNumberField';
import createFormField from './createFormField';

const baseProps = {
  fullWidth: true,
  validations: {
    isNumber: true,
  },
};

const ArcFormNumberField = createFormField('text', baseProps)(ArcNumberField);

export default ArcFormNumberField;
