import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { resources as userResources } from 'arcade-frontend-core/src/resources/users';

import { resources as teamResources } from '../../resources/teams';
import PeopleCreateTeamModal from '../../components/PeopleCreateTeamFormModal';

const ANIMATION_DELAY = 500;

function PeopleCreateTeamModalContainer({ open, onClose, team }) {
  const apiPostCreateTeam = useResourceAction(teamResources.apiPostCreateTeam);
  const apiPutUpdateTeam = useResourceAction(teamResources.apiPutUpdateTeam);
  const apiGetCompanyIdentifierExplainer = useResourceAction(
    userResources.apiGetCompanyIdentifierExplainer,
  );

  useEffect(() => {
    apiGetCompanyIdentifierExplainer.requestData().catch(() => {
      apiGetCompanyIdentifierExplainer.onStatusReset();
    });
  }, []);

  const handleSubmit = (values, formProps) => {
    let resourceAction = apiPostCreateTeam;

    if (values.id && values.id !== '-1') {
      resourceAction = apiPutUpdateTeam;
    }

    resourceAction.onErrorReset();

    resourceAction
      .requestData({ resources: [{ team: values }] })
      .then(() => {
        setTimeout(() => {
          resourceAction.onStatusReset();
          onClose();
          formProps.resetForm();
        }, ANIMATION_DELAY);
      })
      .catch(() => {
        setTimeout(() => {
          resourceAction.onStatusReset();
        }, ANIMATION_DELAY);
      });
  };

  const companyIdentifierExplainer = apiGetCompanyIdentifierExplainer.hasData
    ? apiGetCompanyIdentifierExplainer.data.resources
    : {};

  return (
    <PeopleCreateTeamModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      companyIdentifierExplainer={companyIdentifierExplainer}
      team={team}
      statusCreateTeam={apiPostCreateTeam.status}
      statusUpdateTeam={apiPutUpdateTeam.status}
      hasErrorCreateTeam={apiPostCreateTeam.hasError}
      hasErrorUpdateTeam={apiPutUpdateTeam.hasError}
    />
  );
}

PeopleCreateTeamModalContainer.displayName = 'PeopleCreateTeamModalContainer';

PeopleCreateTeamModalContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  team: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.string,
    companyIdentifier: PropTypes.string,
  }),
};

PeopleCreateTeamModalContainer.defaultProps = {
  open: false,
  onClose: global.noop,
  team: null,
};

export default PeopleCreateTeamModalContainer;
