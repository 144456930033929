import { Platform, StyleSheet } from 'react-primitives';

import * as colors from './colors';
import font from './font';
import * as theme from './variables';
import * as themes from './themes';
import { flatten } from './flatten';
import { getBreakpointTitleByWidth, breakpoints, media } from './media';
import shadows from './shadows';
import * as typography from './typography';
import { PLATFORMS, getArcPlatform, setArcPlatform } from './platform';

const APP_BAR_HEIGHT = 48;
const LEFT_MENU_WIDTH = 240;
const NAV_WIDTH = 300;


const rules = {
  scroll: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
  },
};

const { ptSizeInPx } = typography;

const ArcStyles = {
  APP_BAR_HEIGHT,
  LEFT_MENU_WIDTH,
  NAV_WIDTH,
  PLATFORMS,
  getArcPlatform,
  setArcPlatform,

  breakpoints,
  colors,
  flatten,
  font,
  getBreakpointTitleByWidth,
  media,
  ptSizeInPx,
  rules,
  shadows,
  theme,
  themes,
  typography,

  Platform,
  StyleSheet,
};

export default ArcStyles;

export { default as createWithStyles } from './createWithStyles';
export { default as font } from './font';
export { default as palette } from './palette';
export { default as stylesheet } from './stylesheet';
