import { ArcPropTypes } from 'arcade-frontend-ui';

export const ranking = ArcPropTypes.shape({
  personId: ArcPropTypes.string.isRequired,
  rank: ArcPropTypes.number.isRequired,
  name: ArcPropTypes.string.isRequired,
  score: ArcPropTypes.string.isRequired,
});

export const activity = ArcPropTypes.shape({
  id: ArcPropTypes.string.isRequired,
  name: ArcPropTypes.string,
  kpiId: ArcPropTypes.string,
  createdAt: ArcPropTypes.string,
  formattedCreatedAt: ArcPropTypes.string,
  quantity: ArcPropTypes.number,
});

export const metric = ArcPropTypes.shape({
  id: ArcPropTypes.string.isRequired,
  kpiId: ArcPropTypes.string,
  title: ArcPropTypes.string,
  progress: ArcPropTypes.number,
  symbol: ArcPropTypes.string,
  percentOfBenchmark: ArcPropTypes.number,
  benchmarkValue: ArcPropTypes.number,
});

export const metrics = ArcPropTypes.arrayOf(metric);
export const metricsById = ArcPropTypes.objectOf(metric);

export const activities = ArcPropTypes.arrayOf(activity);
export const activitiesById = ArcPropTypes.objectOf(activities);

export const rankings = ArcPropTypes.arrayOf(ranking);
export const rankingsById = ArcPropTypes.objectOf(rankings);

ArcPropTypes.extendWith({
  ranking,
  activity,
  activities,
  activitiesById,
  metric,
  metrics,
  metricsById,
  rankings,
  rankingsById,
});
