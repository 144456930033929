import React from 'react';
import { Path } from 'react-primitives-svg';
import Icon from '../Icon';

const HelpCircle = props => (
  <Icon {...props}>
    <Path
      fillRule="evenodd"
      d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.993 5.376 18.624.007 12 0zm0 19a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm1-5.163a1 1 0 01.6-.917v.001A4 4 0 108 9.255a1 1 0 102 0 2 2 0 112.8 1.832 3 3 0 00-1.8 2.75 1 1 0 102 0z"
      clipRule="evenodd"
      fill="currentColor"
    />
  </Icon>
);

HelpCircle.displayName = 'HelpCircle';

export default HelpCircle;
