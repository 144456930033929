import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const Settings = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeWidth="0.926"
      d="M12.108 15.812c-1.502 0-2.855-.952-3.425-2.333a3.66 3.66 0 01.825-4.014 3.721 3.721 0 014.053-.765 3.677 3.677 0 012.25 3.434c0 2.03-1.658 3.678-3.703 3.678z"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeWidth="0.926"
      d="M20.433 12.109l2.36-2.778a.5.5 0 000-.61l-2.485-4.36a.528.528 0 00-.555-.25l-3.541.666-1.194-3.402a.528.528 0 00-.5-.375H9.436a.528.528 0 00-.5.361L7.81 4.777 4.2 4.11a.528.528 0 00-.556.25l-2.54 4.402a.5.5 0 000 .611l2.36 2.777-2.36 2.777a.5.5 0 000 .611l2.54 4.319a.5.5 0 00.459.264H4.2l3.61-.68 1.194 3.401a.527.527 0 00.5.361h5.082a.528.528 0 00.5-.36l1.194-3.403 3.541.667h.098a.5.5 0 00.458-.264l2.54-4.402a.5.5 0 000-.61l-2.485-2.722z"
    />
  </Icon>
);

export default Settings;
