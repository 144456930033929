import React, { FC, useEffect, useMemo, useState } from 'react';
import humps from 'humps';
import useSWR from 'swr';

import ArcTransactionLog, {
  DateFilter,
} from 'arcade-frontend-ui/src/components/ArcTransactionLog/ArcTransactionLog';
import { arcadeApiClient } from 'arcade-frontend-core/src/helpers/arcadeApiClient';

interface Account {
  key: string;
  name: string;
  balance: number;
  transferTypes: { code: string }[];
}

export interface RewardsTransactionsIndexContainerV2Props {
  accountName: string;
  accounts: Account[];
  user?: {
    id: number;
    name: string;
    profileImage: string;
    monthlyLimit: number;
    remainingBalance: number;
  };
}

const INITIAL_DATE_RANGE: DateFilter = {
  type: 'time period',
  timePeriod: 'all_dates',
};

const ARCADE_IMAGE_URL =
  'https://s3.amazonaws.com/arcade-assets-public/logo/80x80.png';

function normalizeTransaction(props) {
  return {
    id: props.id.toString(),
    type: props.code,
    when: new Date(props.createdAt),
    fromAccountCode: props.awardedBy.accountCode,
    fromDisplayName: props.awardedBy.name || 'Arcade',
    fromImage: props.awardedBy.image || ARCADE_IMAGE_URL,
    fromPersonId: props.awardedBy.id ? props.awardedBy.id.toString() : '',
    fromCompanyId: props.awardedBy.companyIdentifier,
    fromTeamName: props.awardedBy.teamName,
    toAccountCode: props.awardedTo.accountCode,
    toDisplayName: props.awardedTo.name || '',
    toCompanyId: props.awardedTo.companyIdentifier,
    toTeamName: props.awardedTo.teamName,
    toImage: props.awardedTo.image || ARCADE_IMAGE_URL,
    toPersonId: props.awardedTo.id ? props.awardedTo.id.toString() : '',
    event: props.description,
    amount: Math.abs(props.awardedAmount),
    balance: props.postAwardBalance,
    isPositiveChange: props.awardedAmount > 0,
  };
}

export const RewardsTransactionsIndexContainerV2: FC<RewardsTransactionsIndexContainerV2Props> =
  ({
    accountName,
    accounts,
    user,
  }: RewardsTransactionsIndexContainerV2Props) => {
    const currentAccount = accounts.find(acc => acc.key === accountName);

    const [initialUserFilter, setInitialUserFilter] = useState(user);
    const [page, setPage] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [dateRange, setDateRange] = useState<DateFilter>(INITIAL_DATE_RANGE);
    const [filter, setFilter] = useState<string[]>(
      currentAccount?.transferTypes.map(({ code }) => code) || [],
    );
    const [peopleIds, setPeopleIds] = useState<string[]>([]);

    useEffect(() => {
      if (currentAccount) {
        setFilter(currentAccount.transferTypes.map(({ code }) => code));
      }
    }, [currentAccount, setFilter]);

    const config = useMemo(
      () => ({
        params: humps.decamelizeKeys({
          accountName,
          ...dateRange,
          filter,
          page,
          user:
            initialUserFilter?.id && !peopleIds.length
              ? [initialUserFilter.id]
              : peopleIds,
          rowsPerPage,
        }),
      }),
      [
        accountName,
        dateRange,
        filter,
        page,
        peopleIds,
        rowsPerPage,
        initialUserFilter,
      ],
    );

    const { data, isValidating } = useSWR(
      ['/manager/transactions', config],
      (url, config) =>
        arcadeApiClient(url, config).then(res => {
          setTotalCount(res.headers['x-total']);
          return {
            ...res.data,
            transactions: res.data.transactions.map(normalizeTransaction),
          };
        }),
      {
        revalidateOnFocus: false,
      },
    );

    function handleChangeFilter(nextFilters: string[]) {
      setFilter(nextFilters);
    }

    function handleDateRangeChange(dateFilter: DateFilter) {
      setDateRange(dateFilter);
    }

    function handleDeleteUserFilter() {
      setInitialUserFilter(undefined);
    }

    function handlePeopleChange(nextPeople) {
      setPeopleIds(nextPeople.map(({ id }) => id));
    }

    return (
      <ArcTransactionLog
        accountName={accountName}
        accounts={accounts}
        dateRange={dateRange}
        filter={filter}
        onChangeFilter={handleChangeFilter}
        onDateRangeChange={handleDateRangeChange}
        onDeleteUserFilter={handleDeleteUserFilter}
        onPeopleChange={handlePeopleChange}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        summary={data?.summary}
        transactions={data?.transactions || []}
        transferTypes={currentAccount?.transferTypes || []}
        isFetching={isValidating}
        page={page}
        rowsPerPage={rowsPerPage}
        totalCount={totalCount}
        user={initialUserFilter}
      />
    );
  };
