import axios from 'axios';

import Rollbar from 'arcade-frontend-ui/src/providers/RollbarProvider/client';

import getAxiosConfig from '../getAxiosConfig';
import getTransformedResponseFromResourceAction from '../getTransformedResponseFromResourceAction';
import ResourceError from '../ResourceError';


function getResource(resourceAction, payload, baseConfig) {
  if (!global.isProduction) {
    if (!resourceAction) {
      console.error([
        'getResource(resourceAction, payload, baseConfig)',
        '[resourceAction]',
        'is missing',
      ].join(' '));
    }
  }

  const axiosConfig = getAxiosConfig(resourceAction, payload, baseConfig);

  if (!axiosConfig.method) {
    axiosConfig.method = 'GET';
  }

  if (axiosConfig.method === 'GET') {
    delete axiosConfig.data;
  }

  const requestProperties = {
    axiosConfig,
    requestedAt: (new Date()).toISOString(),
    requestAction: resourceAction.idle(payload),
  };

  const handleSuccess = (resp = {}) => ({
    requestProperties: {
      ...requestProperties,
      statusCode: resp.status,
      succeededAt: (new Date()).toISOString(),
    },
    data: resp.isMock
      ? getTransformedResponseFromResourceAction(resourceAction, resp.data, payload)
      : resp.data,
  });

  const handleFailure = (error) => {
    const failurePayload = {
      requestProperties: {
        ...requestProperties,
        statusCode: error.response ? error.response.status : error.status,
        failedAt: (new Date()).toISOString(),
      },
      data: error,
    };

    if (!global.isProduction) {
      console.error(error);
    }

    Rollbar.error(error.toString(), {
      axiosConfig,
      status: error.status,
      statusText: error.statusText,
      data: error.data,
      headers: error?.config?.headers,
    });

    throw new ResourceError(failurePayload);
  };

  return axios(axiosConfig)
    .then(handleSuccess)
    .catch(handleFailure);
}

export default getResource;
