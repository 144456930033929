import React from 'react';
import PropTypes from 'prop-types';

import { ArcView, ArcText, ArcToken } from 'arcade-frontend-ui';
import ArcConfirmDialog from 'arcade-frontend-ui/src/components/ArcConfirmDialog';
import ArcEntity from 'arcade-frontend-ui/src/components/ArcEntity';
import ArcStackedEntities from 'arcade-frontend-ui/src/components/ArcStackedEntities';

function ArcClawbackTokensFormConfirmationDialog({
  maxPeople,
  open,
  onClose,
  onConfirm,
  people,
  reason,
  tokens,
  ...props
}) {
  const length = people ? people.length : 0;

  const shouldCollapsePeople = length > maxPeople;

  return (
    <ArcConfirmDialog
      {...props}
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      textContent={false}
      content={
        <ArcView>
          <ArcView marginBottom="24" align="center">
            <ArcView marginBottom="8">
              <ArcText color="disabled" size="12px" textTransform="uppercase">
                {'You will be '}
                <ArcText color="danger">{'removing'}</ArcText>
              </ArcText>
            </ArcView>
            <ArcView row align="center">
              <ArcToken />
              <ArcText variant="title" marginLeft="8">
                {tokens || '0'}
              </ArcText>
            </ArcView>
          </ArcView>

          <ArcView marginBottom="24">
            <ArcView marginBottom="8">
              <ArcText color="disabled" size="12px" textTransform="uppercase">
                {length === 1 ? 'From' : `From ${length} people`}
              </ArcText>
            </ArcView>

            {!length && (
              <ArcText color="disabled" size="12px">
                {'No one selected'}
              </ArcText>
            )}

            <ArcView
              fullWidth
              wrap="wrap"
              justify="center"
              marginBottom={reason ? '24' : undefined}
            >
              {shouldCollapsePeople ? (
                <ArcStackedEntities type="person" entities={people} />
              ) : (
                people.map(person => (
                  <ArcView key={person.id}>
                    <ArcEntity
                      type="person"
                      imageUrl={person.imageUrl}
                      name={person.name}
                      teamName={person.teamName}
                    />
                  </ArcView>
                ))
              )}
            </ArcView>

            {!!reason && (
              <React.Fragment>
                <ArcView marginBottom="8">
                  <ArcText
                    color="disabled"
                    size="12px"
                    textTransform="uppercase"
                  >
                    {'For'}
                  </ArcText>
                </ArcView>
                <ArcView>{reason}</ArcView>
              </React.Fragment>
            )}
          </ArcView>
        </ArcView>
      }
    />
  );
}

ArcClawbackTokensFormConfirmationDialog.displayName =
  'ArcClawbackTokensFormConfirmationDialog';

ArcClawbackTokensFormConfirmationDialog.propTypes = {
  maxPeople: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  reason: PropTypes.string,
  tokens: PropTypes.string,
};

ArcClawbackTokensFormConfirmationDialog.defaultProps = {
  maxPeople: 5,
  open: false,
  onClose: global.noop,
  onConfirm: global.noop,
  people: [],
  reason: '',
  tokens: '0',
};

export default ArcClawbackTokensFormConfirmationDialog;
