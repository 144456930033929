import React from 'react';

import PropTypes from 'prop-types';

import {
  ArcText,
  ArcView,

  createWithStyles,
} from 'arcade-frontend-ui';

import Portrait from './Portrait';


const styles = {
  Container: () => ({
    root: {
      cursor: 'pointer',
    },
  }),
  PortraitHolder: theme => ({
    root: {
      width: theme.spacing(2),
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);
const PortraitHolder = createWithStyles(styles.PortraitHolder)(ArcView);

const Participants = ({ people, onClick }) => {
  if (people.length === 0) {
    return (
      <ArcText color="disabled">
        {'None'}
      </ArcText>
    );
  }

  const range = people.slice(0, 3);
  return (
    <Container row align="center" onClick={onClick}>
      {range.map(person => (
        <PortraitHolder key={person.id}><Portrait person={person} /></PortraitHolder>
      ))}
      <ArcView row marginLeft={16} marginRight={16}>
        { people.length > 3 && `+${people.length - 3} more` }
      </ArcView>
    </Container>
  );
};

Participants.displayName = 'Participants';

Participants.propTypes = {
  people: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
  onClick: PropTypes.func,
};

Participants.defaultProps = {
  people: [],
  onClick: global.noop,
};

export default Participants;
