import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import * as REWARD_TYPES from 'arcade-frontend-core/src/types/rewards';

import ArcButton from '../../elements/ArcButton';
import ArcButtonGroup from '../../elements/ArcButtonGroup';
import ArcImage from '../../primitives/ArcImage';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';

import chestSvg from './chest.svg';

const CustomIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="491.634"
    height="491.634"
    viewBox="0 0 491.634 491.634"
    {...props}
  >
    <path
      fill="currentColor"
      d="M0 153.012v185.61h491.634v-185.61H0zm467.418 161.399H24.211V177.223h443.213v137.188h-.006zM87.997 292.949H50.78v-9.623h12.341v-75.01H50.78v-9.625h37.217v9.625H75.658v75.017h12.338v9.616z"
    />
  </svg>
);

const TITLE_BY_REWARD_TYPE = {
  [REWARD_TYPES.CHESTS]: 'Chests',
  [REWARD_TYPES.TOKENS]: 'Tokens',
  [REWARD_TYPES.CUSTOM]: 'Custom',
};

const IMAGE_BY_REWARD_TYPE = {
  [REWARD_TYPES.CHESTS]: (
    <ArcImage
      width="64px"
      height="64px"
      src={chestSvg}
    />
  ),
  [REWARD_TYPES.TOKENS]: (
    <ArcImage
      width="64px"
      height="64px"
      src="https://d17rkelr6jjme9.cloudfront.net/prize-images/ArcadeCoin.svg"
    />
  ),
  [REWARD_TYPES.CUSTOM]: (
    <CustomIcon
      width="64"
      height="64"
    />
  ),
};

const styleImage = {
  marginBottom: 8,
};

const styleMutedImage = {
  ...styleImage,
  opacity: 0.5,
};

const styleButton = {
  width: 116,
  height: 116,
  marginRight: 16,
  marginBottom: 32,
};

const REWARDS = [
  REWARD_TYPES.TOKENS,
  REWARD_TYPES.CHESTS,
];

function ArcRewardButtonGroup({
  value,
  onChange,
}) {
  return (
    <ArcButtonGroup wrap="wrap">
      {REWARDS.map(rewardType => (
        <ArcButton
          key={rewardType}
          className={cx([
            value === rewardType && 'isActive',
          ])}
          onClick={() => onChange(rewardType)}
          style={styleButton}
        >
          <ArcView align="center">
            <ArcView style={value === rewardType ? styleImage : styleMutedImage}>
              {IMAGE_BY_REWARD_TYPE[rewardType]}
            </ArcView>
            <ArcText>
              {TITLE_BY_REWARD_TYPE[rewardType]}
            </ArcText>
          </ArcView>
        </ArcButton>
      ))}
    </ArcButtonGroup>
  );
}

ArcRewardButtonGroup.displayName = 'ArcRewardButtonGroup';

ArcRewardButtonGroup.REWARD_TYPES = REWARD_TYPES;
ArcRewardButtonGroup.REWARDS = REWARDS;

ArcRewardButtonGroup.propTypes = {
  value: PropTypes.oneOf(REWARDS),
  onChange: PropTypes.func,
};

ArcRewardButtonGroup.defaultProps = {
  value: REWARDS[0],
  onChange: global.noop,
};

export default ArcRewardButtonGroup;
