import React from 'react';
import PropTypes from 'prop-types';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ArcButton from '../../elements/ArcButton';
import ArcDialog from '../../elements/ArcDialog';

const dialogStyle = {
  textAlign: 'center',
};

class ArcConfirmDialog extends React.PureComponent {
  static propTypes = {
    cancelLabel: PropTypes.node,
    confirmButtonColor: PropTypes.string,
    confirmLabel: PropTypes.node,
    content: PropTypes.node,
    hideCancel: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
    textContent: PropTypes.bool,
  };

  static defaultProps = {
    cancelLabel: 'Cancel',
    confirmButtonColor: 'secondary',
    confirmLabel: 'Confirm',
    content: 'Are you sure?',
    hideCancel: false,
    onClose: global.noop,
    onConfirm: global.noop,
    open: false,
    title: 'Confirm',
    textContent: true,
  };

  render() {
    const {
      cancelLabel,
      confirmButtonColor,
      confirmLabel,
      content,
      hideCancel,
      onConfirm,
      open,
      onClose,
      textContent,
      title,
      ...props
    } = this.props;

    return (
      <ArcDialog
        {...props}
        open={open}
        onClose={onClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        style={dialogStyle}
      >
        <DialogTitle id="confirm-dialog-title">
          {title}
        </DialogTitle>

        <DialogContent>
          { textContent ? (
            <DialogContentText id="confirm-dialog-description">
              {content}
            </DialogContentText>
          ) : content }
        </DialogContent>

        <DialogActions>
          {!hideCancel && (
            <ArcButton
              onClick={onClose}
            >
              {cancelLabel}
            </ArcButton>
          )}

          <ArcButton
            onClick={onConfirm}
            color={confirmButtonColor}
            variant="outlined"
          >
            {confirmLabel}
          </ArcButton>
        </DialogActions>
      </ArcDialog>
    );
  }
}

export default ArcConfirmDialog;
