import React from 'react';
import PropTypes from 'prop-types';

import { fade } from '@material-ui/core/styles/colorManipulator';

import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';

const TYPES = {
  ENTITY: 'entity',
  GROUP: 'group',
  PERSON: 'person',
  TEAM: 'team',
};

const SHAPES = {
  SQUARE: 'square',
  CIRCLE: 'circle',
};

class ArcEntityAvatar extends React.PureComponent {
  static propTypes = {
    color: PropTypes.string,
    imageUrl: PropTypes.string,
    letter: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.oneOf(Object.values(TYPES)),
    shape: PropTypes.oneOf(Object.values(SHAPES)),
  };

  static defaultProps = {
    color: '',
    imageUrl: '',
    letter: '',
    size: 24,
    type: TYPES.ENTITY,
    shape: SHAPES.SQUARE,
  };

  static TYPES = TYPES;

  get backgroundColor() {
    switch (this.props.type) {
      case TYPES.ENTITY:
        return this.props.color;
      case TYPES.GROUP:
        return this.props.color ? fade(this.props.color, 0.1) : 'rgba(0,0,0,0.12)';
      case TYPES.TEAM:
      default:
        return null;
    }
  }

  renderPerson() {
    const {
      size,
    } = this.props;

    return (
      <ArcView
        style={{
          backgroundColor: '#dedede',
          backgroundImage: `url(${this.props.imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          borderRadius: size,
          width: size,
          height: size,
        }}
      />
    );
  }

  renderLetter() {
    const {
      color,
      size,
      type,
      shape,
    } = this.props;

    const shouldInvertText = type === TYPES.ENTITY || shape === SHAPES.CIRCLE;

    return (
      <ArcView
        align="center"
        justify="center"
        userSelect="none"
        style={{
          color: shouldInvertText ? '#fff' : color,
          backgroundColor: shape === SHAPES.CIRCLE ? color : this.backgroundColor,
          border: `2px solid ${color}`,
          borderRadius: shape === SHAPES.CIRCLE ? 20 : 2,
          width: size,
          height: size,
          fontSize: Math.min(size * 0.45, 12),
        }}
      >
        <ArcText>{this.props.letter}</ArcText>
      </ArcView>
    );
  }

  render() {
    switch (this.props.type) {
      case TYPES.ENTITY:
      case TYPES.GROUP:
      case TYPES.TEAM:
        return this.renderLetter();
      case TYPES.PERSON:
        return this.renderPerson();
      default:
        return null;
    }
  }
}

export default ArcEntityAvatar;
