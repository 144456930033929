import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Platform } from 'react-primitives';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import filesize from 'filesize';
import parseFilePath from 'parse-filepath';
import { throttle } from 'throttle-debounce';
import { Formik, Form } from 'formik';

import {
  ArcImage,
  ArcText,
  ArcView,
  ArcTextField,
  ArcIconButton,
  ArcScroll,
  ArcButton,
  ArcLoaderButton,
  createWithStyles,
  download,
} from 'arcade-frontend-ui';
import ArcProgressButton from 'arcade-frontend-ui/src/components/ArcProgressButton';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';
import ArcResourceSpinner from 'arcade-frontend-ui/src/components/ArcResourceSpinner';
import ArcDeleteDialog from 'arcade-frontend-ui/src/components/ArcDeleteDialog';

import { videoObject, uploadObject } from '../../propTypes';
import ArcVideo from '../ArcVideo';

const CLOSE_DELAY = 1200;

const backToUploadsButtonStyle = {
  zIndex: 1,
  position: 'absolute',
  bottom: Platform.OS === 'ios' ? 12 : undefined,
  top: Platform.OS !== 'ios' ? 8 : undefined,
  left: 8,
  backgroundColor: 'rgba(82, 213, 255, 0.16)',

  borderColor: '#fff',
  color: '#fff',
};

const durationStyle = {
  bottom: 4,
  right: 4,
  backgroundColor: 'rgba(0,0,0,0.54)',
  borderRadius: 2,
  padding: '2px 4px',
};

const formStyle = {
  width: '100%',
};

const searchTabStyle = {
  width: 48,
  minWidth: 48,
  marginRight: 4,
};

const userImageStyle = {
  borderRadius: 2,
  verticalAlign: 'sub',
  marginRight: 4,
};

const noUploadMessage = {
  mine: 'No videos found. Upload and share your first video!',
  shared: 'No videos have been shared yet',
  my_files: "You haven't uploaded any files yet",
  shared_files: 'No files have been shared yet',
};

const processDuration = duration =>
  moment.duration(duration, 'seconds').format('h:mm:ss').padStart(4, '0:0');

const styles = {
  ArcDialogScroll: theme => ({
    root: {
      overflowX: 'hidden',
      borderWidth: 0,
      borderTopWidth: 2,
      borderColor: theme.palette.grey.tint,
      borderStyle: 'solid',

      '& .VideoTitleText': {
        display: 'flex',
        fontSize: '1rem',
      },

      '& .VideoNameText': {
        maxWidth: '100%',
        flexGrow: 1,
        flexShrink: 100,
        minWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },

      '& .VideoExtText': {
        flexShrink: 0,
        flexGrow: 100,
      },

      [theme.breakpoints.up('sm')]: {
        '& .VideoTitleText': {
          fontSize: '1.25rem',
        },
      },
    },
  }),

  PlaceholderText: () => ({
    root: {
      fontSize: 16,
    },
  }),

  SearchPlaceholderView: theme => ({
    root: {
      marginTop: theme.spacing(10),
      textAlign: 'center',
    },
  }),

  ThumbnailVideo: theme => ({
    root: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      backgroundColor: '#000',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',

      padding: theme.spacing(2),
    },
  }),

  ThumbnailFile: theme => ({
    root: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      backgroundColor: '#FFF',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',

      padding: theme.spacing(2),
    },
  }),

  UploadActions: () => ({
    root: {
      marginTop: 6,
    },
  }),
  UploadContent: theme => ({
    root: {
      display: 'flex',
      borderWidth: 0,
      borderBottomWidth: 2,
      borderColor: theme.palette.grey.tint,
      borderStyle: 'solid',
      padding: 0,
    },
  }),
  UploadTitle: theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      // paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(0.5),
      paddingBottom: 0,
      paddingLeft: 14,
    },
    isPlayingVideo: {
      paddingBottom: 16,
    },
  }),
  TitleText: theme => ({
    root: {
      marginLeft: theme.spacing(1),
    },
  }),
};

const ArcDialogScroll = createWithStyles(styles.ArcDialogScroll)(ArcScroll);
const PlaceholderText = createWithStyles(styles.PlaceholderText)(ArcText);
const SearchPlaceholderView = createWithStyles(styles.SearchPlaceholderView)(
  ArcView,
);
const ThumbnailVideo = createWithStyles(styles.ThumbnailVideo)(ArcView);
const ThumbnailFile = createWithStyles(styles.ThumbnailFile)(ArcView);
const UploadActions = createWithStyles(styles.UploadActions)(DialogActions);
const UploadContent = createWithStyles(styles.UploadContent)(DialogContent);
const UploadTitle = createWithStyles(styles.UploadTitle)(DialogTitle);
const TitleText = createWithStyles(styles.TitleText)(Typography);

class ArcFileList extends React.PureComponent {
  static displayName = 'ArcFileList';

  static propTypes = {
    filesById: PropTypes.objectOf(videoObject),
    filesMine: PropTypes.arrayOf(videoObject),
    filesShared: PropTypes.arrayOf(videoObject),
    uploads: PropTypes.objectOf(uploadObject),
    onAdd: PropTypes.func,
    onCancel: PropTypes.func,
    onSearchChange: PropTypes.func,
    onSearchReset: PropTypes.func,
    onTabChange: PropTypes.func,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func,
    onUpdate: PropTypes.func,
    onUpdateFile: PropTypes.func,
    onLoadMore: PropTypes.func,
    source: PropTypes.string,
    requestStatus: PropTypes.objectOf(PropTypes.string),
    searchResults: PropTypes.arrayOf(videoObject),
    searchResultsById: PropTypes.objectOf(videoObject),
    uploadStatus: PropTypes.string,
    videosById: PropTypes.objectOf(videoObject),
    videosMine: PropTypes.arrayOf(videoObject),
    videosShared: PropTypes.arrayOf(videoObject),
    videosHasMore: PropTypes.bool,
    sharedVideosHasMore: PropTypes.bool,
    filesHasMore: PropTypes.bool,
    sharedFilesHasMore: PropTypes.bool,
    videosIsLoading: PropTypes.bool,
    filesIsLoading: PropTypes.bool,
  };

  static defaultProps = {
    source: null,
    requestStatus: {},
    uploadStatus: 'DEFAULT',
    videosHasMore: false,
    sharedVideosHasMore: false,
    filesById: {},
    filesMine: [],
    filesShared: [],
    uploads: {},
    searchResults: [],
    searchResultsById: {},
    videosById: {},
    videosMine: [],
    videosShared: [],
    filesHasMore: false,
    sharedFilesHasMore: false,
    onAdd: global.noop,
    onCancel: global.noop,
    onSearchChange: global.noop,
    onSearchReset: global.noop,
    onTabChange: global.noop,
    onDelete: global.noop,
    onSelect: global.noop,
    onUpdate: global.noop,
    onUpdateFile: global.noop,
    onLoadMore: global.noop,
    videosIsLoading: false,
    filesIsLoading: false,
  };

  state = {
    currentSearch: '',
    deleteDialogOpen: false,
    editingFileId: null,
    playingVideo: null,
    selectedFileId: null,
    tab: 'search',
  };

  componentDidMount() {
    this.hasMounted = true;
  }

  componentWillUnmount() {
    this.hasMounted = false;
  }

  get addVideoLabel() {
    switch (this.props.uploadStatus) {
      case 'REQUEST':
        return 'Uploading';
      case 'SUCCESS':
        return 'Done';
      case 'FAILURE':
        return 'Error';
      case 'DEFAULT':
      default:
        return 'Add Video';
    }
  }

  get currentTabIsLoading() {
    switch (this.state.tab) {
      case 'mine':
      case 'shared':
        return this.props.videosIsLoading;
      case 'my_files':
      case 'shared_files':
        return this.props.filesIsLoading;
      default:
        return false;
    }
  }

  get currentTabStatus() {
    return this.currentTabIsLoading ? { pending: true } : { idle: true };
  }

  get currentTabHasMoreItems() {
    switch (this.state.tab) {
      case 'mine':
        return this.props.videosHasMore;
      case 'shared':
        return this.props.sharedVideosHasMore;
      case 'my_files':
        return this.props.filesHasMore;
      case 'shared_files':
        return this.props.sharedFilesHasMore;
      default:
        return false;
    }
  }

  get mediaById() {
    switch (this.state.tab) {
      case 'mine':
      case 'shared':
        return this.props.videosById;
      case 'my_files':
      case 'shared_files':
        return this.props.filesById;
      case 'search':
        return this.props.searchResultsById;
      default:
        return {};
    }
  }

  get media() {
    switch (this.state.tab) {
      case 'mine':
        return this.props.videosMine;
      case 'shared':
        return this.props.videosShared;
      case 'my_files':
        return this.props.filesMine;
      case 'shared_files':
        return this.props.filesShared;
      case 'search':
        return this.props.searchResults;
      default:
        return [];
    }
  }

  get isUploading() {
    return !!this.props.source;
  }

  setDeleteDialogOpen = deleteDialogOpen => this.setState({ deleteDialogOpen });

  setPlayingVideo = playingVideo => this.setState({ playingVideo });

  clearSelections = () =>
    this.setState({
      selectedFileId: null,
      editingFileId: null,
      playingVideo: null,
    });

  deleteFile = () => {
    const fileId = this.state.selectedFileId || this.state.editingFileId;

    if (!fileId) {
      return;
    }

    const file = this.mediaById[fileId];

    if (!file) {
      return;
    }

    this.props.onDelete(file);
    this.clearSelections();
  };

  runSearch = throttle(300, () => {
    if (this.state.currentSearch.length >= 3) {
      this.props.onSearchChange(this.state.currentSearch);
    }
  });

  searchInputRef = React.createRef();

  handlePlay = video => this.setPlayingVideo(video);

  handleSearchCancel = () => {
    this.props.onSearchReset();
    this.setState({ currentSearch: '' });
  };

  handleTabChange = (_, tab) => {
    if (tab === 'search') {
      this.runSearch();
    }
    this.setState({ tab });

    this.props.onTabChange(tab);
  };

  handleSelect = (selectedFileId, video) => {
    this.setState({
      selectedFileId,
      editingFileId: null,
      playingVideo: video,
    });
  };

  handleSelectFile = selectedFileId => {
    this.setState({
      selectedFileId,
      editingFileId: null,
    });
  };

  handleEdit = editingFileId => {
    const nextState = {
      editingFileId,
      selectedFileId: null,
      playingVideo: null,
    };

    if (this.state.editingFileId) {
      this.setState({ editingFileId: null });
      setTimeout(() => {
        if (this.hasMounted) {
          this.setState(nextState);
        }
      }, 200);
    } else {
      this.setState(nextState);
    }
  };

  handleCancel = () => this.clearSelections();

  handleDeleteOpen = () => this.setDeleteDialogOpen(true);

  handleDelete = () => {
    this.deleteFile();
    this.setDeleteDialogOpen(false);
  };

  handleDeleteDialogClose = () => this.setDeleteDialogOpen(false);

  handleEditSubmit = media => {
    const editedFile = this.mediaById[this.state.editingFileId];
    if (editedFile.media_type.toLowerCase() === 'video') {
      this.props.onUpdate({ id: this.state.editingFileId, media });
    } else {
      this.props.onUpdateFile({ id: this.state.editingFileId, media });
    }

    setTimeout(() => {
      this.setState({ editingFileId: null });
    }, CLOSE_DELAY);
  };

  handleSearchChange = e => {
    const { value } = e.target;
    this.setState({ currentSearch: value }, this.runSearch);
  };

  handleTabSearchClick = () => {
    if (this.searchInputRef.current) {
      this.searchInputRef.current.focus();
    }
  };

  handleDownload = fileUrl => {
    download.openFileForPlatform(fileUrl);
  };

  handleLoadMore = () => this.props.onLoadMore(this.state.tab);

  renderDeleteDialog() {
    return (
      <ArcDeleteDialog
        open={this.state.deleteDialogOpen}
        onClose={this.handleDeleteDialogClose}
        onDelete={this.handleDelete}
      />
    );
  }

  renderEditedFile() {
    const editedFile = this.mediaById[this.state.editingFileId];
    if (!editedFile) {
      return null;
    }

    const { title, tags } = editedFile || {};
    const initialValues = {
      title: title || '',
      tags: tags || [],
    };

    return (
      <UploadActions>
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleEditSubmit}
          isInitialValid
          render={this.renderEditedFileForm}
        />
      </UploadActions>
    );
  }

  renderEditedFileForm = formikProps => {
    const uploadStatusType =
      this.state.tabs === 'mine' || this.state.tabs === 'shared'
        ? this.props.requestStatus.UPLOAD_MANAGER_UPDATE_UPLOAD
        : this.props.requestStatus.UPLOAD_MANAGER_UPDATE_UPLOAD_FILE;

    return (
      <Form style={formStyle}>
        <ArcView padding="4">
          <ArcTextField
            name="title"
            variant="outlined"
            margin="normal"
            label="Title"
            value={formikProps.values.title}
            onChange={formikProps.handleChange}
            fullWidth
          />
          {/*
        <ArcTextField
          name="tags"
          variant="outlined"
          margin="normal"
          label="Tags"
          value={values.tags}
        />
        */}
        </ArcView>
        <ArcView row marginTop={8} marginLeft="4" marginRight="4">
          {['mine', 'my_files'].indexOf(this.state.tab) > -1 && (
            <ArcButton
              label="Delete"
              color="danger"
              variant="outlined"
              onClick={this.handleDeleteOpen}
            />
          )}

          <ArcView spacer />

          <ArcButton
            label="Cancel"
            type="button"
            isSpaced
            onClick={this.handleCancel}
          />
          <ArcLoaderButton
            loadingState={uploadStatusType}
            label="Save"
            color="blue"
            variant="contained"
            type="submit"
            disabled={!formikProps.dirty}
            onClick={formikProps.handleSubmit}
          />
        </ArcView>
      </Form>
    );
  };

  renderSearchField() {
    return (
      <ArcView
        height={this.state.tab === 'search' ? undefined : 0}
        overflow="hidden"
      >
        <ArcTextField
          value={this.state.currentSearch}
          variant="standard"
          onChange={this.handleSearchChange}
          placeholder="Search"
          inputRef={this.searchInputRef}
          InputProps={{
            style: {
              paddingLeft: 14,
            },
            inputProps: {
              style: {
                padding: 14,
                paddingLeft: 0,
              },
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <ArcIconButton onClick={this.handleSearchCancel}>
                  <CloseIcon />
                </ArcIconButton>
              </InputAdornment>
            ),
          }}
        />
      </ArcView>
    );
  }

  renderPlayingVideo() {
    return (
      <ArcView color="black" position="absolute" fillToParent>
        <ArcVideo
          fluid={false}
          fullHeight
          sourcesByEncoding={this.state.playingVideo}
        />

        <ArcButton
          size="small"
          variant="outlined"
          color="blue"
          style={backToUploadsButtonStyle}
          onClick={this.handleCancel}
        >
          <ArrowBackIcon fontSize="inherit" />
          <ArcView marginLeft="8">{'Back to Uploads'}</ArcView>
        </ArcButton>
      </ArcView>
    );
  }

  renderSelectedFile() {
    const selectedFile = this.mediaById[this.state.selectedFileId];
    const fileUrl = selectedFile && selectedFile.assets.link;

    if (!selectedFile) {
      return null;
    }

    return (
      <UploadActions>
        {this.state.tab === 'mine' && (
          <ArcButton
            label="Delete"
            color="danger"
            variant="outlined"
            onClick={this.handleDeleteOpen}
          />
        )}

        <ArcView spacer />

        <ArcButton onClick={this.handleCancel}>Cancel</ArcButton>

        {selectedFile.media_type.toLowerCase() === 'document' && (
          <ArcButton onClick={() => this.handleDownload(fileUrl)}>
            Download
          </ArcButton>
        )}

        {this.isUploading && (
          <ArcButton
            color="green"
            label="Select"
            variant="contained"
            onClick={() => this.props.onSelect(selectedFile)}
          />
        )}
      </UploadActions>
    );
  }

  renderVideoItem = video => (
    <ListItem
      key={video.id}
      alignItems="flex-start"
      button
      onClick={() => this.handleSelect(video.id, video.assets)}
      selected={video.id === this.state.selectedFileId}
    >
      <ArcView position="relative" marginRight="16">
        <ThumbnailVideo
          style={{
            backgroundImage: `url(${video.assets.thumbnail[0].url})`,
          }}
        />

        <ArcView position="absolute" style={durationStyle}>
          <ArcText color="white" isSmaller isLight>
            {processDuration(video.assets.video_webm[0].meta.duration)}
          </ArcText>
        </ArcView>
      </ArcView>
      <ListItemText
        primaryTypographyProps={{
          variant: 'h6',
          noWrap: true,
          className: 'VideoTitleText',
        }}
        secondaryTypographyProps={{
          noWrap: true,
        }}
        primary={
          <React.Fragment>
            <ArcText display="inline-block" className="VideoNameText">
              {video.title ? parseFilePath(video.title).name : 'Untitled'}
            </ArcText>
            <ArcText color="disabled" className="VideoExtText">
              {video.name ? parseFilePath(video.name).ext.toLowerCase() : ''}
            </ArcText>
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            <ArcImage
              height="16px"
              width="16px"
              src={video.user.imageUrl}
              style={userImageStyle}
            />
            <ArcText isSpaced>{video.user.name}</ArcText>
            <ArcText isSpaced>&bull;</ArcText>
            <ArcText isSpaced>{filesize(video.size)}</ArcText>
          </React.Fragment>
        }
      />
      {this.state.tab === 'mine' && (
        <ListItemSecondaryAction>
          <ArcIconButton
            aria-label="Edit"
            edge="end"
            onClick={() => this.handleEdit(video.id)}
          >
            <SettingsIcon />
          </ArcIconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );

  renderFileItem = file => (
    <ListItem
      key={file.id}
      alignItems="flex-start"
      button
      onClick={() => this.handleSelectFile(file.id, file.assets)}
      selected={file.id === this.state.selectedFileId}
    >
      <ArcView position="relative" marginTop="4" marginRight="12">
        <ThumbnailFile
          style={{
            backgroundImage: `url(${file.assets.icon})`,
          }}
        />
      </ArcView>
      <ListItemText
        primaryTypographyProps={{
          variant: 'h6',
          noWrap: true,
          className: 'VideoTitleText',
        }}
        secondaryTypographyProps={{
          noWrap: true,
        }}
        primary={
          <React.Fragment>
            <ArcText
              display="inline-block"
              className="VideoNameText"
              color={file?.title ? undefined : 'disabled'}
            >
              {file?.title ? parseFilePath(file.title).name : 'Untitled'}
            </ArcText>
            {!!file.type && (
              <ArcText color="disabled" className="VideoExtText">
                {`.${file.type}`}
              </ArcText>
            )}
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            <ArcImage
              height="16px"
              width="16px"
              src={file.user.imageUrl}
              style={userImageStyle}
            />
            <ArcText isSpaced>{file.user.name}</ArcText>
            <ArcText isSpaced>&bull;</ArcText>
            <ArcText isSpaced>{filesize(file.size)}</ArcText>
          </React.Fragment>
        }
      />
      {this.state.tab === 'my_files' && (
        <ListItemSecondaryAction>
          <ArcIconButton
            aria-label="Edit"
            edge="end"
            onClick={() => this.handleEdit(file.id)}
          >
            <SettingsIcon />
          </ArcIconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );

  renderArcProgressButton = () => {
    const { onAdd, uploadStatus, uploads } = this.props;

    const firstUpload = uploads ? Object.values(uploads)[0] : null;

    let progress = firstUpload ? firstUpload.progress.percentage : 0;

    if (uploadStatus === 'SUCCESS' || uploadStatus === 'FAILURE') {
      progress = 100;
    }

    const label =
      progress === 100 && uploadStatus === 'REQUEST'
        ? 'Processing'
        : this.addVideoLabel;

    return (
      <ArcProgressButton
        requestStatus={uploadStatus}
        progress={progress}
        onClick={onAdd}
        isSpaced
        label={label}
        style={{
          minWidth: 136,
        }}
      />
    );
  };

  renderSearchItems = () => {
    if (this.props.requestStatus.UPLOAD_MANAGER_SEARCH_UPLOADS === 'REQUEST') {
      return (
        <SearchPlaceholderView>
          <PlaceholderText>Searching...</PlaceholderText>
        </SearchPlaceholderView>
      );
    }

    if (this.props.requestStatus.UPLOAD_MANAGER_SEARCH_UPLOADS === 'FAILURE') {
      return (
        <SearchPlaceholderView>
          <PlaceholderText>
            Unable to Search. Please try again later
          </PlaceholderText>
        </SearchPlaceholderView>
      );
    }

    const { searchResults } = this.props;

    if (searchResults.length === 0) {
      return (
        <SearchPlaceholderView>
          <PlaceholderText>
            {this.state.currentSearch.length > 3
              ? 'No Results Found'
              : 'Enter a search term in the field below to find Media'}
          </PlaceholderText>
        </SearchPlaceholderView>
      );
    }

    return searchResults.map(item => {
      if (item.media_type.toLowerCase() === 'video') {
        return this.renderVideoItem(item);
      }
      return this.renderFileItem(item);
    });
  };

  renderContent = () => {
    const { tab } = this.state;
    const sortedVideos = this.media || [];

    return (
      <ArcView position="relative" flexShrink="1" flexGrow="1" fullWidth>
        <Tabs value={tab} onChange={this.handleTabChange} variant="scrollable">
          <Tab value="mine" label="My Videos" />
          <Tab value="shared" label="Shared With Me" />
          <Tab value="my_files" label="My Files" />
          <Tab value="shared_files" label="Files Shared With Me" />
          <Tab
            aria-label="Search"
            value="search"
            icon={<SearchIcon />}
            style={searchTabStyle}
            onClick={this.handleTabSearchClick}
          />
        </Tabs>

        <ArcView position="relative" flexShrink="1" flexGrow="1" fullWidth>
          <ArcDialogScroll>
            <List>
              {['mine', 'shared'].indexOf(this.state.tab) > -1 &&
                sortedVideos?.map(this.renderVideoItem)}
              {['my_files', 'shared_files'].indexOf(this.state.tab) > -1 &&
                sortedVideos?.map(this.renderFileItem)}
              {this.state.tab === 'search' && this.renderSearchItems()}

              {this.currentTabHasMoreItems && (
                <ArcView align="center" marginTop="16" marginBottom="16">
                  <ArcResourceButton
                    variant="outlined"
                    color="primary"
                    label="Load More"
                    onClick={this.handleLoadMore}
                    status={this.currentTabStatus}
                  />
                </ArcView>
              )}
            </List>
          </ArcDialogScroll>

          {((this.currentTabIsLoading && !this.currentTabHasMoreItems) ||
            !sortedVideos.length) && (
            <ArcView
              position="absolute"
              fillToParent
              align="center"
              justify="center"
            >
              <ArcResourceSpinner
                size={32}
                color="primary"
                status={this.currentTabStatus}
              />
              {!this.currentTabIsLoading && !sortedVideos.length && (
                <PlaceholderText align="center">
                  {noUploadMessage[this.state.tab]}
                </PlaceholderText>
              )}
            </ArcView>
          )}
        </ArcView>

        {this.state.playingVideo && this.renderPlayingVideo()}
      </ArcView>
    );
  };

  render() {
    const { onCancel } = this.props;

    return (
      <React.Fragment>
        <UploadTitle
          disableTypography
          isPlayingVideo={!!this.state.playingVideo}
        >
          <TitleText variant="h6">{'Uploads'}</TitleText>
          <ArcView spacer />

          {this.renderArcProgressButton()}

          <ArcIconButton onClick={onCancel}>
            <CloseIcon />
          </ArcIconButton>
        </UploadTitle>

        <UploadContent>{this.renderContent()}</UploadContent>

        {this.renderSearchField()}
        {this.renderSelectedFile()}
        {this.renderEditedFile()}
        {this.renderDeleteDialog()}
      </React.Fragment>
    );
  }
}

export default ArcFileList;
