import { makeApiActions } from 'arcade-frontend-ui';
import packageJson from '../../../../package.json';
import * as normalizers from './normalizers';

const login = params => ({
  method: 'POST',
  url: '/users/sign_in.json',
  data: normalizers.toLogin(params),
  normalizer: normalizers.fromLogin,
});

const logout = () => ({
  method: 'DELETE',
  url: '/users/sign_out.json',
});

const menu = () => ({
  method: 'GET',
  url: '/v2/menus?source=desktop',
  normalizer: normalizers.fromMenu,
});

const features = ({ source }) => ({
  method: 'GET',
  url: '/v4/features',
  params: {
    source,
    version: '4.8.7',
  },
  // normalizer: normalizers.fromFeatures,
  rootKey: 'features',
});

const updateFeatures = ({ name, enabled }) => ({
  method: 'PUT',
  url: '/v4/features',
  data: { feature: { name, enabled } },
  rootKey: 'features',
});

const permissions = ({ source }) => ({
  method: 'GET',
  url: '/user/permissions',
  params: {
    source,
    version: packageJson.version,
  },
  normalizer: normalizers.fromPermissions,
  rootKey: 'users',
});

const resetPassword = params => ({
  method: 'POST',
  url: `${params.endpoint}/v4/passwords/reset`,
  data: params,
});

const taggablePeople = ({ type }) => ({
  method: 'GET',
  url: '/v4/users/taggable',
  normalizer: normalizers.fromTaggablePeople,
  rootKey: 'users',
  data: {
    type,
  },
});

const invitePerson = form => ({
  method: 'POST',
  url: '/v2/users',
  data: {
    user: {
      email: form.email,
      firstname: form.firstName,
      lastname: form.lastName,
      team_id: form.teamId,
      company_identifier: form.companyIdentifier,
      should_be_geofenced: form.shouldBeGeofenced,
    },
  },
});

const people = taggablePeople;

// const people = () => ({
//   method: 'GET',
//   url: '/v2/search',
//   rootKey: 'users',
//   normalizer: normalizers.fromPeople,
// });

const taggableTeams = () => ({
  method: 'GET',
  url: '/v4/teams/taggable',
  rootKey: 'teams',
  normalizer: normalizers.fromTaggableTeams,
});

const taggableEntities = () => ({
  method: 'GET',
  url: '/v4/entities/taggable',
  rootKey: 'entities',
  normalizer: normalizers.fromTaggableEntities,
});

const hashTags = () => ({
  method: 'GET',
  url: '/v3/activities/list_tags',
  normalizer: normalizers.fromHashTags,
  rootKey: 'tags',
});

const checklist = () => ({
  method: 'GET',
  url: '/v3/quests/checklist',
  normalizer: normalizers.fromChecklist,
  rootKey: 'quests',
});

const checklistClaimReward = () => ({
  method: 'PUT',
  url: '/v3/quests/checklist',
});

const registerChatSession = () => ({
  method: 'POST',
  url: '/v2/chat_sessions',
});

const saveWebpushCredentials = data => ({
  method: 'POST',
  url: '/v4/webpush_credentials',
  data,
});

const getMute = () => ({
  method: 'GET',
  url: '/v2/notifications/mute',
  normalizer: data => data.muted,
});

const toggleMute = () => ({
  method: 'PUT',
  url: '/v2/notifications/mute',
  normalizer: data => data.muted,
});

const profileUpdate = payload => ({
  method: 'PUT',
  url: '/v4/users',
  data: { user: payload },
});

const endpoints = {
  login,
  logout,
  menu,
  permissions,
  features,
  updateFeatures,
  resetPassword,
  taggablePeople,
  taggableTeams,
  taggableEntities,
  hashTags,
  invitePerson,
  checklist,
  checklistClaimReward,
  registerChatSession,
  getMute,
  toggleMute,
  people,
  profileUpdate,
  saveWebpushCredentials,
};

const api = makeApiActions(endpoints);

export default api;
