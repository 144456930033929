import sagas from 'arcade-frontend-core/src/helpers/sagas';

import { api } from '../../resources/manage/sales';
import { types } from '../../actions/manage/sales';

const takeLatestSaga = [
  {
    type: types.MANAGE_SALES_INDEX,
    call: api.salesIndex,
  },
  {
    type: types.MANAGE_SALES_CREATE,
    call: api.salesCreate,
  },
  {
    type: types.MANAGE_SALES_DELETE,
    call: api.salesDelete,
  },
  {
    type: types.MANAGE_SALES_UPDATE,
    call: api.salesUpdate,
  },
  {
    type: types.MANAGE_SALE_METRICS_INDEX,
    call: api.saleMetricsIndex,
  },
  {
    type: types.MANAGE_SALE_TEAMS_INDEX,
    call: api.saleTeamsIndex,
  },
  {
    type: types.MANAGE_SALE_USERS_INDEX,
    call: api.saleUsersIndex,
  },
];

const sales = takeLatestSaga.map(sagas.makeTakeLatest);

export default sales;
