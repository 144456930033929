import React from 'react';
// import ArcPropTypes from '../../helpers/arc/propTypes';

const RaceArrow = props => (
  <svg
    width="26"
    height="11"
    viewBox="0 0 26 11"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path
      d="M23 4h2v1h1v1h-1v1h-2v1h-2v1h-2v1h-2v1h-2V8H0V3h15V0h2v1h2v1h2v1h2v1z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);

RaceArrow.propTypes = {
};

RaceArrow.defaultProps = {
};

export default RaceArrow;
