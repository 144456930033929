import { createIsFetchingReducer } from 'arcade-frontend-ui';
import { types } from '../../../actions/manage/sales';

const initialState = false;

const apiActionTypes = [
  types.MANAGE_SALES_INDEX,
  types.MANAGE_SALES_CREATE,
  types.MANAGE_SALES_UPDATE,
  types.MANAGE_SALES_DELETE,
];

const isRequesting = createIsFetchingReducer(initialState, apiActionTypes);

export default isRequesting;

export const getIsRequesting = state => state.manage.sales.isRequesting;
