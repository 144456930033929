import React from 'react';
import PropTypes from 'prop-types';

import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import usePermission from 'arcade-frontend-core/src/hooks/usePermission';

import GamesActiveListContainer from '../../containers/GamesActiveListContainer';
import GamesNewFromTemplateBox from '../GamesNewFromTemplateBox';

const GamesActiveTabPanel = ({ onCardClick }) => {
  const canManageGames = usePermission(PERMISSIONS.MANAGE_GAMES);

  return (
    <ArcBox className="animated fadeIn">
      <GamesActiveListContainer onCardClick={onCardClick} />
      {canManageGames && <GamesNewFromTemplateBox mb={4} />}
    </ArcBox>
  );
};

GamesActiveTabPanel.displayName = 'GamesActiveTabPanel';

GamesActiveTabPanel.propTypes = {
  onCardClick: PropTypes.func,
};

GamesActiveTabPanel.defaultProps = {
  onCardClick: global.noop,
};

export default GamesActiveTabPanel;
