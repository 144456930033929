import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../../actions/manage/metrics/types';

const handleTeamsLoadSuccess = (state, action) => {
  if (!action.payload) return state;

  const { data } = action.payload;

  const newState = { ...state };

  data.forEach((team) => {
    newState[team.id] = team;
  });

  return newState;
};

export const sorted = (teams, prop) => {
  const teamIds = Object.keys(teams);

  return teamIds.sort((a, b) => {
    if (teams[a][prop] < teams[b][prop]) {
      return -1;
    } if (teams[a][prop] > teams[b][prop]) {
      return 1;
    }

    return 0;
  });
};

const initialState = {};

const handlers = {
  [types.MANAGE_METRICS_TEAMS_INDEX.SUCCESS]: handleTeamsLoadSuccess,
};

const teamsById = createReducer(initialState, handlers);

export default teamsById;

export const getManageMetricsTeamsById = state => state.manage.metrics.teamsById;
