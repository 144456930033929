import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const Cap = props => (
  <Icon {...props}>
    <Path fill="transparent" d="M0.5 8.99219L3.5 13.4922L7 9.49219" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="transparent" d="M13 6.99805V12.498H18" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="transparent" d="M13 21.5C18.2467 21.5 22.5 17.2467 22.5 12C22.5 6.75329 18.2467 2.5 13 2.5C7.75329 2.5 3.5 6.75329 3.5 12V13.494" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

export default Cap;
