import React from 'react';
import PropTypes from 'prop-types';
import Emitter from 'emmett';
import Attachment from '@material-ui/icons/Attachment';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Video from '@material-ui/icons/Videocam';
import AddIcon from '@material-ui/icons/AddCircle';
import Popover from '@material-ui/core/Popover';

import { urlToFile } from 'arcade-frontend-core/src/helpers/files';
import {
  ArcView,
  ArcButton,
  ArcIconButton,
  ArcPaper,
  ArcText,
  createWithStyles,
} from 'arcade-frontend-ui';
import ArcUserInputContainer from 'arcade-frontend-core/src/containers/ArcUserInputContainer';

import FileDropper from './FileDropper';
import Previews from './Previews';
import { videoObject } from '../../propTypes';

const changeTextStyle = {
  position: 'absolute',
  bottom: 4,
  width: '100%',
  left: 0,
  textTransform: 'uppercase',
  fontSize: 8,
};

const styles = {
  ControlsWrapper: () => ({
    root: {
      overflow: 'hidden',
      position: 'absolute',
    },
  }),

  Controls: theme => ({
    root: {
      borderTopWidth: 3,
      borderColor: theme.palette.grey[200],
      borderStyle: 'solid',
      backgroundColor: theme.palette.grey[100],
      flexDirection: 'row',
      padding: theme.spacing(1),
      transition: theme.transitions.create('transform'),
      transform: 'translateY(-100%)',
    },
    isActive: {
      transform: 'translateY(0)',
    },
  }),
  LeftButtons: () => ({
    root: {
      flexGrow: 1,
    },
  }),
  RightButtons: () => ({
    root: {
      flexDirection: 'row',
      flexBasis: 'content',
    },
  }),
  IconButton: () => ({
    root: {
      width: 56,
    },
  }),
  ShareButton: () => ({
    root: {
      borderRadius: 0,
    },
  }),
  SavingWrapper: () => ({
    root: {
      position: 'relative',
    },
    horizontal: {
      flexDirection: 'row',
    },
  }),
  InputWrapper: theme => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(0.5),
      transition: theme.transitions.create('border-radius'),
    },
    isActive: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  }),
};

const ControlsWrapper = createWithStyles(styles.ControlsWrapper)(ArcView);
const Controls = createWithStyles(styles.Controls)(ArcView);
const LeftButtons = createWithStyles(styles.LeftButtons)(ArcView);
const RightButtons = createWithStyles(styles.RightButtons)(ArcView);
const IconButton = createWithStyles(styles.IconButton)(ArcIconButton);
const ShareButton = createWithStyles(styles.ShareButton)(ArcButton);
const SavingWrapper = createWithStyles(styles.SavingWrapper)(ArcView);
const InputWrapper = createWithStyles(styles.InputWrapper)(ArcPaper);

const initialState = {
  attachmentIsOpen: false,
  attachments: [],
  controlsAnchorEl: null,
  displayValue: '',
  hasChanged: false,
  hashTags: [],
  initialValue: '',
  inputFocused: false,
  markedActive: false,
  mentionIds: [],
  modelValue: '',
  files: [],
};

const MAXIMUM_ATTACHMENTS = {
  files: 2,
  videos: 1,
  images: 3,
};

class SlimEditor extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onAdd: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onInputFocused: PropTypes.func,
    onRemove: PropTypes.func,
    onRemoveMedia: PropTypes.func,
    multipleFiles: PropTypes.bool,
    attachmentSupport: PropTypes.bool,
    renderControls: PropTypes.func,
    placeholder: PropTypes.string,
    horizontal: PropTypes.bool,
    submitOnEnter: PropTypes.bool,
    taggingRules: PropTypes.shape({}),
    uploadManagerOpened: PropTypes.func,
    attachedVideos: PropTypes.arrayOf(videoObject),
    controlProps: PropTypes.shape({
      currentlyEditing: PropTypes.shape({}),
    }),
    attachedFiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    inputRef: PropTypes.shape({}),
    currentRequestStatus: PropTypes.string,
    canUploadFiles: PropTypes.bool,
    canUploadVideos: PropTypes.bool,
    canUploadImages: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    placeholder: 'What do you want to share?',
    onAdd: global.noop,
    onSubmit: global.noop,
    onCancel: global.noop,
    onChange: global.noop,
    onInputFocused: global.noop,
    onRemove: global.noop,
    uploadManagerOpened: global.noop,
    onRemoveMedia: global.noop,
    multipleFiles: false,
    attachmentSupport: true,
    renderControls: null,
    horizontal: false,
    submitOnEnter: true,
    taggingRules: {},
    attachedVideos: [],
    controlProps: {},
    inputRef: null,
    currentRequestStatus: '',
    canUploadFiles: false,
    canUploadVideos: false,
    canUploadImages: false,
  };

  constructor(props) {
    super(props);
    this.emitter = new Emitter();

    window.addEventListener('message', this.handleWindowMessage);
    document.addEventListener('message', this.handleWindowMessage);
  }

  state = {
    ...initialState,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.attachmentSupport !== nextProps.attachmentSupport) {
      this.clear();
    }

    if (this.props.value !== nextProps.value) {
      this.clear({
        initialValue: nextProps.value,
        modelValue: nextProps.value,
      });
    }

    const currentlyAttached = this.props.attachedFiles.map(f => f.id).sort();

    const newlyAttached = nextProps.attachedFiles.filter(
      f => currentlyAttached.indexOf(f.id) === -1,
    );

    if (newlyAttached.length > 0) {
      this.setState({
        attachments: [...this.state.attachments, ...newlyAttached],
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const didSubmit =
      prevProps.currentRequestStatus === 'SUCCESS' &&
      this.props.currentRequestStatus === 'DEFAULT';

    if (didSubmit) {
      this.resetState();
    }

    if (prevState.inputFocused !== this.state.inputFocused) {
      this.onInputFocused();
    }
  }

  componentWillUnmount() {
    this.cleanUpPreviews();
    window.removeEventListener('message', this.handleWindowMessage);
    document.removeEventListener('message', this.handleWindowMessage);
  }

  onAdd = () => {
    this.props.onAdd({
      files: this.state.attachments,
      videos: this.props.attachedVideos,
    });
  };

  onInputFocused = () => this.props.onInputFocused(this.state.inputFocused);

  onRemove = () => {
    this.props.onRemove({
      files: this.state.attachments,
      videos: this.props.attachedVideos,
    });
  };

  container = null;

  get hasSelectedImage() {
    return this.state.files.length
      ? this.state.files[0].type.indexOf('image/') === 0
      : false;
  }

  get canAddMoreImages() {
    return this.props.multipleFiles
      ? this.images.length < MAXIMUM_ATTACHMENTS.images
      : this.hasSelectedImage;
  }

  get hasSelectedFile() {
    return this.files.length ? !this.hasSelectedImage : false;
  }

  get canAddMoreFiles() {
    return this.props.multipleFiles
      ? this.files.length < MAXIMUM_ATTACHMENTS.files
      : this.hasSelectedFile;
  }

  get isDisabled() {
    return this.props.currentRequestStatus !== 'DEFAULT';
  }

  get attachments() {
    return [...this.state.attachments];
  }

  get files() {
    return this.attachments.filter(f => !this.isImage(f));
  }

  get images() {
    return this.attachments.filter(this.isImage);
  }

  setInputFocused = inputFocused => this.setState({ inputFocused });

  isImage = file => file.type.indexOf('image/') === 0;

  cleanUpPreviews(files = this.state.files) {
    if (files) {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    }
  }

  clear = newValues => {
    this.cleanUpPreviews();
    this.emitter.emit('clear');
    this.setState({ ...initialState, ...newValues });
  };

  filesSelected = ({ acceptedFiles }) => {
    const newAttachments = acceptedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );

    if (this.props.multipleFiles) {
      this.setState(
        { attachments: [...this.attachments, ...newAttachments] },
        this.onAdd,
      );
    } else {
      this.cleanUpPreviews();
      if (this.files.length > 0) {
        this.props.onRemoveMedia(this.files[0].name);
      }

      this.setState({ attachments: [newAttachments[0]] }, this.onAdd);
    }
  };

  openVideoManager = () => {
    this.handleCloseControls();
    this.props.uploadManagerOpened('activity-editor');
  };

  removeFile = name => {
    this.cleanUpPreviews(
      this.state.attachments.filter(attachment => attachment.name === name),
    );
    this.setState(
      {
        attachments: this.state.attachments.filter(
          attachment => attachment.name !== name,
        ),
      },
      this.onRemove,
    );
    this.props.onRemoveMedia(name);
  };

  resetState = () => this.setState({ ...initialState });

  handleCancel = () => {
    this.clear();
    this.props.onCancel();
  };

  handleChange = (event, modelValue, displayValue, mentionIds, hashTags) => {
    const nextState = {
      hasChanged: this.state.initialValue !== modelValue,
      modelValue,
      displayValue,
      mentionIds,
      hashTags,
    };

    this.setState(nextState);
    this.props.onChange(nextState);
  };

  handleClick = () => this.setInputFocused(true);

  handleCloseControls = () => {
    this.setState({ attachmentIsOpen: false });
  };

  handleFocus = () => this.setInputFocused(true);

  handleInputSubmit = (
    event,
    modelValue,
    displayValue,
    mentionIds,
    hashTags,
  ) => {
    this.setState(
      { modelValue, displayValue, mentionIds, hashTags },
      this.handleSubmit,
    );
  };

  handleOpenControls = e => {
    this.setState({
      controlsAnchorEl: e.currentTarget,
      inputFocused: true,
      attachmentIsOpen: true,
    });
  };

  handleRemoveMedia = video => {
    this.props.onRemoveMedia(video.name);
  };

  handleReset = () => this.resetState();

  handleSelect = () => this.setInputFocused(true);

  handleSubmit = () => {
    this.props.onSubmit(
      {
        ...this.state,
        videos: this.props.attachedVideos,
        files: this.attachments,
      },
      this.handleReset,
    );
  };

  handleWindowMessage = event => {
    let parsedEvent;

    try {
      parsedEvent = JSON.parse(event.data);
    } catch {
      parsedEvent = event.data;

      if (typeof parsedEvent !== 'string') {
        return;
      }

      if (parsedEvent.indexOf('ng.imageUpload:newsfeed') === -1) {
        return; // this is not a newsfeed event
      }

      parsedEvent = {
        type: 'ng.imageUpload:newsfeed',
        payload: event.data.replace('ng.imageUpload:newsfeed.', ''),
      };
    }

    if (
      ['ng.imageUpload:newsfeed', 'rtpos/IMAGE_UPLOAD'].indexOf(
        parsedEvent.type,
      ) === -1 ||
      parsedEvent.payload === 'cancel'
    ) {
      return;
    }

    const mimeType = parsedEvent.payload.split(';')[0].replace('data:', '');
    const name = `image.${mimeType.replace('image/', '')}`;

    urlToFile(parsedEvent.payload, name, mimeType).then(file => {
      const files = [file];
      files[0].preview = parsedEvent.payload;

      this.setState(
        { attachments: [...this.state.attachments, files[0]] },
        this.onAdd,
      );
    });
  };

  renderControls = params =>
    this.props.renderControls ? (
      this.props.renderControls(params)
    ) : (
      <ControlsWrapper>
        <Controls isActive={params.isActive}>
          <LeftButtons />
          <RightButtons>
            <ShareButton
              color="primary"
              variant="flat"
              onClick={params.onSubmit}
              label={params.currentlyEditing ? 'Save' : 'Share'}
            />
          </RightButtons>
        </Controls>
      </ControlsWrapper>
    );

  renderBody = open => {
    const {
      attachmentSupport,
      value,
      placeholder,
      horizontal,
      submitOnEnter,
      taggingRules,
      attachedVideos,
      controlProps,
      canUploadFiles,
      canUploadImages,
      canUploadVideos,
    } = this.props;

    const { inputFocused, attachments, hasChanged, markedActive } = this.state;
    const hasAttachments = attachedVideos.length > 0 || attachments.length > 0;

    const isActive =
      markedActive || hasChanged || inputFocused || hasAttachments;
    const isDirty = hasChanged || hasAttachments;

    const controlParams = {
      open,
      isActive,
      isDirty,
      attachmentSupport,
      handleCancel: this.handleCancel,
      handleSubmit: this.handleSubmit,
      handleFocus: this.handleFocus,
      controlProps,
    };

    const closeControlsAndOpen = () => {
      this.handleCloseControls();
      if (window.arcPostMessage) {
        window.arcPostMessage('native.imageUpload.newsfeed', '*');
      } else {
        open();
      }

      if (window.parent && window.parent.postMessage) {
        const date = new Date();
        const uid = date.getTime();
        window.parent.postMessage(`arcade.image-upload:${uid}`, '*');
      }
    };

    const renderOpenControlsButton = () => (
      <IconButton onClick={this.handleOpenControls} disabled={this.isDisabled}>
        <AddIcon />
      </IconButton>
    );

    const renderUploadFilesButton = () => (
      <IconButton
        onClick={closeControlsAndOpen}
        disabled={!this.canAddMoreFiles}
      >
        <ArcView>
          <Attachment />
          {!this.canAddMoreFiles && (
            <ArcText style={changeTextStyle}>
              {`Max ${MAXIMUM_ATTACHMENTS.files}`}
            </ArcText>
          )}
        </ArcView>
      </IconButton>
    );

    const renderUploadVideosButton = () => (
      <IconButton onClick={this.openVideoManager}>
        <ArcView>
          <Video />
          {!!this.props.attachedVideos.length && (
            <ArcText style={changeTextStyle}>{'Change'}</ArcText>
          )}
        </ArcView>
      </IconButton>
    );

    const renderUploadImagesButton = () => (
      <IconButton
        onClick={closeControlsAndOpen}
        disabled={!this.canAddMoreImages}
      >
        <ArcView>
          <PhotoCamera />
          {!this.canAddMoreImages && (
            <ArcText style={changeTextStyle}>
              {`Max ${MAXIMUM_ATTACHMENTS.images}`}
            </ArcText>
          )}
        </ArcView>
      </IconButton>
    );

    const renderUploadButtons = () => {
      const totalEnabled = [
        canUploadImages,
        canUploadFiles,
        canUploadVideos,
      ].filter(upload => upload).length;

      if (totalEnabled >= 2) {
        return renderOpenControlsButton();
      }
      if (canUploadImages) {
        return renderUploadImagesButton();
      }
      if (canUploadFiles) {
        return renderUploadFilesButton();
      }
      if (canUploadVideos) {
        return renderUploadVideosButton();
      }
      return null;
    };

    return (
      <SavingWrapper horizontal={horizontal}>
        <ArcView position="relative">
          <InputWrapper
            isActive={isActive || !!controlProps.currentlyEditing}
            square
          >
            <ArcView flexGrow={1} flexShrink={1}>
              <ArcUserInputContainer
                disabled={this.isDisabled}
                placeholder={placeholder}
                onChange={this.handleChange}
                onClick={this.handleClick}
                onSelect={this.handleSelect}
                onSubmit={this.handleSubmit}
                events={this.emitter}
                value={value}
                submitOnEnter={submitOnEnter}
                taggingRules={taggingRules}
                ref={this.props.inputRef}
              />
            </ArcView>
            {attachmentSupport && (
              <ArcView fullHeight>
                <Popover
                  open={this.state.attachmentIsOpen}
                  anchorEl={this.state.controlsAnchorEl}
                  container={this.container}
                  onClose={this.handleCloseControls}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                >
                  {canUploadVideos && renderUploadVideosButton()}
                  {canUploadImages && renderUploadImagesButton()}
                  {!global.isMobile &&
                    canUploadFiles &&
                    renderUploadFilesButton()}
                </Popover>

                <ArcView row>{renderUploadButtons()}</ArcView>
              </ArcView>
            )}
          </InputWrapper>

          {this.renderControls(controlParams)}
        </ArcView>

        {attachmentSupport && (
          <Previews
            isDisabled={this.isDisabled}
            files={this.files}
            images={this.images}
            videos={attachedVideos}
            remove={this.removeFile}
            removeVideo={this.handleRemoveMedia}
          />
        )}
      </SavingWrapper>
    );
  };

  render() {
    if (this.props.attachmentSupport) {
      return (
        <FileDropper
          filesSelected={this.filesSelected}
          withOpen={this.renderBody}
        />
      );
    }
    return this.renderBody();
  }
}

export default SlimEditor;
