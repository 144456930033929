export function usePersonActions() {
  const navigateAwardToken = (event, id) => {
    if (window.awardToken) {
      window.awardToken(event, id);
    }
  };

  const navigateCall = phoneNumber => {
    // if only calls in the US, use window.open(`tel:+1${phoneNumber}`); instead
    // https://stackoverflow.com/questions/17897213/how-to-call-a-phone-number-through-javascript-without-using-a-tag
    window.open(`tel:${phoneNumber}`);
  };

  const navigateChat = (event, id) => {
    if (window.sendMessage) {
      window.sendMessage(event, null, id);
    }
  };

  const navigateReward = (event, id) => {
    if (window.reward) {
      window.reward(event, id);
    }
  };

  const navigateProfile = (event, id) => {
    if (window.viewProfile) {
      window.viewProfile(event, id);
    }
  };

  const isPhoneNumberValid = phoneNumber => {
    // decent regex solution at https://stackoverflow.com/a/16702965
    if (phoneNumber) {
      phoneNumber.match(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      );
    }
  };

  return {
    navigateAwardToken,
    navigateCall,
    navigateChat,
    navigateReward,
    navigateProfile,
    isPhoneNumberValid,
  };
}

export default usePersonActions;
