import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getCurrentUser } from '../../reducers/user';

import apiCall from '../apiCall';

const RESET_DELAY = 2000;

const DEFAULT_OPTS = {
  shouldDispatch: true,
};

const STATUS_TYPES = {
  DEFAULT: 'DEFAULT',
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const withApiData = (apiAction, opts = DEFAULT_OPTS) => {
  const options = {
    ...DEFAULT_OPTS,
    ...opts,
  };

  const createComponent = (Component) => {
    class ComponentWithApiData extends React.PureComponent {
      static propTypes = {
        currentUser: PropTypes.shape({
          email: PropTypes.string,
          endpoint: PropTypes.string,
          token: PropTypes.string,
        }),
        data: PropTypes.oneOfType([
          PropTypes.array,
          PropTypes.number,
          PropTypes.object,
          PropTypes.string,
        ]),
        error: PropTypes.oneOfType([
          PropTypes.object,
          PropTypes.string,
        ]),
        dispatch: PropTypes.func,
        status: PropTypes.oneOf(Object.values(STATUS_TYPES)),
      };

      static defaultProps = {
        currentUser: {},
        data: undefined,
        dispatch: global.noop,
        error: null,
        status: STATUS_TYPES.DEFAULT,
      };

      state = {
        data: this.props.data,
        error: this.props.error,
        status: this.props.status,
      };

      get baseAxiosConfig() {
        return {
          headers: this.headers,
          baseURL: this.baseURL,
        };
      }

      get baseURL() {
        if (process.env.NODE_ENV === 'development') {
          return window.localStorage.getItem('endpoint');
        }

        if (this.isAuthorized) {
          return this.props.currentUser.endpoint;
        }

        // TODO how do we want to handle this fallback?
        return null;
      }

      get headers() {
        if (this.isAuthorized) {
          return {
            'X-User-Token': this.props.currentUser.token,
            'X-User-Email': this.props.currentUser.email,
          };
        }

        return {};
      }

      get hasData() {
        const { data } = this.state;

        if (!data) {
          return false;
        }

        if (data.constructor === Array) {
          return !!data.length;
        }

        if (typeof data === 'object' && data.toString() === '[object Object]') {
          return !!Object.keys(data).length;
        }

        return true;
      }

      get hasError() {
        return !!this.state.error;
      }

      get isAuthorized() {
        return !!this.props.currentUser && !!this.props.currentUser.token;
      }

      get isRequesting() {
        return this.state.status === STATUS_TYPES.REQUEST;
      }

      setData = data => this.setState({ data });

      setError = error => this.setState({ error });

      setStatus = status => this.setState({ status });

      requestData = (payload, dynamicAxiosConfig) => {
        this.setStatus(STATUS_TYPES.REQUEST);

        if (options.shouldDispatch) {
          this.props.dispatch(apiAction.request(payload));
        }

        const axiosConfig = {
          ...this.baseAxiosConfig,
          ...dynamicAxiosConfig,
        };

        return apiCall(apiAction, payload, axiosConfig)
          .then(this.handleSuccess)
          .catch(this.handleFailure);
      };


      delayedResetStatus = () => setTimeout(this.resetStatus, RESET_DELAY);

      resetStatus = () => this.setStatus(STATUS_TYPES.DEFAULT);

      handleFailure = (error) => {
        // console.warn(error);
        this.setError(error.data);
        this.setStatus(STATUS_TYPES.FAILURE, this.delayedResetStatus);

        if (options.shouldDispatch) {
          this.props.dispatch(apiAction.failure(error));
        }

        return error;
      };

      handleSuccess = (resp) => {
        // console.warn(resp.data);
        if (typeof resp.data === 'object' && resp.data.toString() === '[object Object]' && this.props.data) {
          this.setData({
            ...this.props.data,
            ...resp.data,
          });
        } else {
          this.setData(resp.data);
        }

        this.setStatus(STATUS_TYPES.SUCCESS, this.delayedResetStatus);

        if (options.shouldDispatch) {
          this.props.dispatch(apiAction.success(resp));
        }

        return resp;
      };

      render() {
        return (
          <Component
            {...this.props}
            {...this.state}
            hasData={this.hasData}
            hasError={this.hasError}
            isRequesting={this.isRequesting}
            requestData={this.requestData}
          />
        );
      }
    }

    const getState = (state, props) => ({
      currentUser: getCurrentUser(state),
      ...props,
    });

    if (process.env.NODE_ENV === 'test') {
      return ComponentWithApiData;
    }
    return connect(getState, null)(ComponentWithApiData);
  };

  return createComponent;
};

export default withApiData;
