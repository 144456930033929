import { ArcPropTypes } from 'arcade-frontend-ui';
import * as types from 'arcade-frontend-core/src/types/routes';
import getComponentFromDirective from 'arcade-frontend-core/src/helpers/getComponentFromDirective';


const ArcadeAngularProfileView = getComponentFromDirective('arcadeProfile');
const { PLATFORMS } = ArcPropTypes;

const base = {
  [types.ROUTE_PROFILE]: ArcadeAngularProfileView,
  [types.ROUTE_PROFILE_EDIT]: ArcadeAngularProfileView,
};

const benjo = base;
const development = base;
const launch = base;

export default {
  [PLATFORMS.BENJO]: benjo,
  [PLATFORMS.DEVELOPMENT]: development,
  [PLATFORMS.LAUNCH]: launch,
};
