import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  ArcButton,
  ArcIconButton,
  ArcMainView,
  ArcView,
  ArcViewTitleBar,
  createWithStyles,
  date,
} from 'arcade-frontend-ui';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import { ArcFormDateRangePickerContainer } from 'arcade-frontend-widgets/src/containers/ArcFormDateRangePickerContainer';

import CloseIcon from '@material-ui/icons/Close';
import { routeManage } from '../../../actions/manage/routes';
import {
  routes,
  actions as actionsManageSales,
} from '../../../actions/manage/sales';
import { getIsRequesting } from '../../../reducers/manage/sales/isRequesting';
import { getManageSaleMetrics } from '../../../reducers/manage/sales/metrics';
import { getRequestStatus } from '../../../reducers/manage/sales/requestStatus';
import { getManageSales } from '../../../reducers/manage/sales/salesById';
import { getManageSaleTeams } from '../../../reducers/manage/sales/teams';
import { getManageSalesTotal } from '../../../reducers/manage/sales/totalSales';
import { getManageSaleUsers } from '../../../reducers/manage/sales/users';
import {
  getLocationPayload,
  getLocationQuery,
} from '../../../reducers/location';
import SaleCreateForm from '../../../forms/sales/SaleCreateForm';
import SalesTable from '../../../components/manage/sales/SalesTable';
import MetricFilterContainer from './MetricFilterContainer';
import { PeopleFilterContainer } from '../PeopleFilterContainer';

const styles = {
  Header: theme => ({
    root: {
      backgroundColor: theme.palette.common.white,
      overflowX: 'auto',
      padding: theme.spacing(1),
    },
  }),

  ManageSalesView: theme => ({
    root: {
      backgroundColor: theme.palette.common.white,
    },
  }),

  Chip: theme => ({
    root: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      borderRadius: theme.spacing(1),
      borderWidth: '1px',
      backgroundColor: theme.palette.blue.tint,
      borderStyle: 'solid',
      borderColor: theme.palette.blue.light,
    },
  }),

  DateRangeWrapper: theme => ({
    root: {
      paddingRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  }),

  ButtonWrapper: () => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      maxHeight: '56px',
    },
  }),
};

const Header = createWithStyles(styles.Header)(ArcView);
const ManageSalesView = createWithStyles(styles.ManageSalesView)(ArcView);
const DateRangeWrapper = createWithStyles(styles.DateRangeWrapper)(ArcView);
const ButtonWrapper = createWithStyles(styles.ButtonWrapper)(ArcView);
const Chip = createWithStyles(styles.Chip)(ArcView);

class ManageSalesContainer extends React.PureComponent {
  static propTypes = {
    apiManageSalesCreateRequest: PropTypes.func.isRequired,
    apiManageSalesDeleteRequest: PropTypes.func.isRequired,
    apiManageSaleMetricsIndexRequest: PropTypes.func.isRequired,
    apiManageSaleTeamsIndexRequest: PropTypes.func.isRequired,
    apiManageSaleUsersIndexRequest: PropTypes.func.isRequired,
    apiManageSalesIndexRequest: PropTypes.func.isRequired,
    apiManageSalesUpdateRequest: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      id: PropTypes.string,
    }),
    routeManage: PropTypes.func.isRequired,
    routeManageActivities: PropTypes.func.isRequired,

    locationQuery: PropTypes.shape({
      integrationRunId: PropTypes.string,
    }).isRequired,
    locationPayload: PropTypes.shape({
      integrationRunName: PropTypes.string,
    }).isRequired,

    isRequesting: PropTypes.bool,
    totalSales: PropTypes.number,

    metrics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),

    requestStatus: PropTypes.objectOf(
      PropTypes.oneOf(['DEFAULT', 'REQUEST', 'SUCCESS', 'FAILURE']),
    ).isRequired,

    sales: PropTypes.arrayOf(
      PropTypes.shape({
        userName: PropTypes.string,
        teamName: PropTypes.string,
        metricName: PropTypes.string,
        quantity: PropTypes.number,
        createdDate: PropTypes.string,
      }),
    ),

    teams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),

    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        teamId: PropTypes.string,
        metrics: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
  };

  static defaultProps = {
    currentUser: {},
    isRequesting: false,
    totalSales: 0,
    metrics: [],
    sales: [],
    teams: [],
    users: [],
  };

  state = {
    editingSale: {},
    modalOpen: false,
    page: 0,
    rowsPerPage: 50,
    dateRange: {
      type: 'time period',
      timePeriod: 'last_30_days',
    },
    metricFilter: null,
    people: [],
  };

  componentDidMount() {
    this.getSales();
    this.requestAll();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.getSales();
      this.requestAll();
    }

    if (
      prevProps.locationQuery.integrationRunId !==
      this.props.locationQuery.integrationRunId
    ) {
      this.getSales();
    }
  }

  get integrationRunId() {
    return this.props.locationQuery.integrationRunId;
  }

  get integrationRunName() {
    return this.props.locationPayload.integrationRunName;
  }

  get isAuthorized() {
    return !!this.props.currentUser.id;
  }

  requestAll() {
    if (!this.isAuthorized) {
      return;
    }

    this.props.apiManageSaleMetricsIndexRequest();
    this.props.apiManageSaleTeamsIndexRequest();
    this.props.apiManageSaleUsersIndexRequest();
  }

  getSales(reIndex = false) {
    if (!this.isAuthorized) {
      return;
    }
    const { page, rowsPerPage, dateRange, metricFilter, people } = this.state;
    if (reIndex) this.setState({ page: 0 });

    const request = {
      page: reIndex ? 0 : page,
      rowsPerPage,
      integrationRunId: this.integrationRunId,
      user: people,
      ...dateRange,
    };
    if (metricFilter > -1) request.metricId = metricFilter;
    this.props.apiManageSalesIndexRequest(request);
  }

  setModalOpen = modalOpen => this.setState({ modalOpen });

  setEditingSale = (editingSale, cb) => this.setState({ editingSale }, cb);

  handleChangePage = page => {
    this.setState({ page }, this.getSales);
  };

  handleChangeRowsPerPage = rowsPerPage => {
    this.setState({ rowsPerPage }, this.getSales);
  };

  handleClickSale = sale => this.setEditingSale(sale, this.handleModalOpen);

  handleCreateSale = () => this.setEditingSale({}, this.handleModalOpen);

  handleDeleteSale = () => {
    this.props.apiManageSalesDeleteRequest(this.state.editingSale);
    this.setModalOpen(false);
  };

  handleModalClose = () => this.setModalOpen(false);

  handleModalOpen = () => this.setModalOpen(true);

  handleSaveSale = values => {
    if (this.state.editingSale && this.state.editingSale.id) {
      this.props.apiManageSalesUpdateRequest({
        ...this.state.editingSale,
        ...values,
      });
    } else {
      this.props.apiManageSalesCreateRequest(values);
    }
  };

  handleDateRangeChange = ({ type, timePeriod, fromDate, toDate }) => {
    if (type === 'time period') {
      this.setState(
        {
          dateRange: {
            type,
            timePeriod,
            fromDate: undefined,
            toDate: undefined,
          },
        },
        () => this.getSales(true),
      );
    } else {
      this.setState(
        {
          dateRange: {
            type,
            timePeriod: undefined,
            fromDate,
            toDate,
          },
        },
        () => this.getSales(true),
      );
    }
  };

  handleMetricChange = value => {
    this.setState(
      {
        metricFilter: value,
      },
      () => this.getSales(true),
    );
  };

  handlePeopleChange = people => {
    const peopleIds = people.map(person => person.id);

    this.setState(
      {
        people: peopleIds,
      },
      () => this.getSales(true),
    );
  };

  renderSaleModal() {
    const { editingSale } = this.state;
    const { requestStatus, metrics, teams, users } = this.props;

    const status =
      requestStatus.MANAGE_SALES_UPDATE !== 'DEFAULT'
        ? requestStatus.MANAGE_SALES_UPDATE
        : requestStatus.MANAGE_SALES_CREATE;

    return (
      <SaleCreateForm
        sale={editingSale}
        open={this.state.modalOpen}
        metrics={metrics}
        teams={teams}
        people={users}
        onClose={this.handleModalClose}
        onSubmit={this.handleSaveSale}
        onDelete={this.handleDeleteSale}
        requestStatus={status}
        initialValues={
          editingSale
            ? {
                createdAt:
                  editingSale.createdAt ||
                  date.toNextMinuteInterval(new Date(), 1),
                metricId: editingSale.metricId || '-1',
                teamId: editingSale.teamId || '-1',
                userId: editingSale.userId || '-1',
                quantity: editingSale.quantity
                  ? editingSale.quantity.toString()
                  : '1',
              }
            : undefined
        }
      />
    );
  }

  renderIntegrationTools() {
    if (!this.integrationRunId || !this.integrationRunName) return null;

    return (
      <ArcView padding={8} row>
        <Chip row align="center">
          Integration run {this.integrationRunName}
          <ArcIconButton onClick={this.props.routeManageActivities}>
            <CloseIcon />
          </ArcIconButton>
        </Chip>
      </ArcView>
    );
  }

  render() {
    const { sales, totalSales, isRequesting } = this.props;
    const { dateRange, page, rowsPerPage, metricFilter } = this.state;

    return (
      <ArcMainView position="relative" hasNoPaddingLeftLaunch isViewing>
        <ArcViewTitleBar
          onClickBack={this.props.routeManage}
          title="Manage Activities"
        />
        <Header row>
          <ArcView row flexGrow={1} />

          <PeopleFilterContainer onSelect={this.handlePeopleChange} />

          <ArcView>
            <MetricFilterContainer
              value={metricFilter}
              onChange={this.handleMetricChange}
            />
          </ArcView>

          <DateRangeWrapper>
            <ArcFormDateRangePickerContainer
              value={dateRange}
              onChange={this.handleDateRangeChange}
            />
          </DateRangeWrapper>

          <ButtonWrapper>
            <ArcButton
              color="primary"
              variant="outlined"
              onClick={this.handleCreateSale}
            >
              {'Create'}
            </ArcButton>
          </ButtonWrapper>
        </Header>
        <ManageSalesView scrollParent>
          {this.renderIntegrationTools()}

          <SalesTable
            sales={sales}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            onClickRow={this.handleClickSale}
            onRefresh={this.getSales}
            totalSales={totalSales}
            isRequesting={isRequesting}
          />
        </ManageSalesView>
        {this.renderSaleModal()}
      </ArcMainView>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentUser: getCurrentUser(state),
  isRequesting: getIsRequesting(state),
  metrics: getManageSaleMetrics(state),
  requestStatus: getRequestStatus(state),
  sales: getManageSales(state),
  teams: getManageSaleTeams(state),
  totalSales: getManageSalesTotal(state),
  users: getManageSaleUsers(state),
  locationPayload: getLocationPayload(state),
  locationQuery: getLocationQuery(state),
  ...props,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionsManageSales,
      ...routes,
      routeManage,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageSalesContainer);
