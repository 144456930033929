import React from 'react';

function LightCone(props) {
  return (
    <svg viewBox="0 0 85 124" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path opacity="0.4" d="M85 30L51 124L41 124L0.112507 0.734211L85 30Z" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient id="paint0_linear" x1="48.364" y1="146.341" x2="89.3348" y2="-1.1239" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.682292" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

LightCone.displayName = 'LightCone';

export default LightCone;
