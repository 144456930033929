import { createReducer } from 'arcade-frontend-ui';

import * as typesRoutes from 'arcade-frontend-core/src/types/routes';

const setGameDetailsType = (state, action) => {
  const { gameDetailsType } = action.payload;

  return gameDetailsType;
};

const handleRouteManageGamesShow = setGameDetailsType;

const initialState = null;

const handlers = {
  [typesRoutes.ROUTE_MANAGE_GAMES_SHOW]: handleRouteManageGamesShow,
};

const currentGameDetailsType = createReducer(initialState, handlers);

export default currentGameDetailsType;
