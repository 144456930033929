import React from 'react';
import PropTypes from 'prop-types';

import ArcBoxV2 from '../ArcBoxV2';

import { ArcViewBoxProps } from './ArcViewBox.d';

const ArcViewBox: React.FunctionComponent<ArcViewBoxProps> = props => (
  <ArcBoxV2
    display="flex"
    flexDirection="column"
    flexShrink={0}
    {...props}
  />
);

export default ArcViewBox;

ArcViewBox.propTypes = {
  children: PropTypes.node,
};

ArcViewBox.defaultProps = {
  children: null,
};
