import TableRow from '@material-ui/core/TableRow';
import { createWithStyles } from '../../styles';


const styles = {
  ArcDataTableRow: theme => ({
    root: {
      backgroundColor: theme.palette.common.white,
    },
    shade: {
      backgroundColor: theme.palette.grey[100],
    },
  }),
};

const ArcDataTableRow = createWithStyles(styles.ArcDataTableRow)(TableRow);

export default ArcDataTableRow;
