import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import { getLocationQuery } from 'arcade-frontend-features/src/reducers/location';
import usePermission from 'arcade-frontend-core/src/hooks/usePermission';
import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';

import GamesCompletedTable from '../../components/GamesCompletedTable';
import { resources } from '../../resources/games';
import {
  getCompletedGames,
  getCompletedGamesMeta,
} from '../../reducers/completed-games';

function CompletedGamesContainer({ dateRange }) {
  const dispatch = useDispatch();

  const completedGameMeta = useSelector(getCompletedGamesMeta);
  const totalGames = completedGameMeta ? completedGameMeta.totalGames : 0;

  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isManager = usePermission(PERMISSIONS.MANAGE_GAMES);

  const {
    requestData,
    hasFailed,
    isPending,
    onStatusReset,
  } = useResourceAction(resources.apiGetCompletedGamesIndex);
  const locationQuery = useSelector(getLocationQuery);
  const completedGames = useSelector(getCompletedGames);

  useEffect(() => {
    requestData({ meta: { page, rowsPerPage, ...dateRange } })
      .then(onStatusReset)
      .catch(onStatusReset);
  }, [dateRange, page, rowsPerPage, locationQuery.form]);

  function handleEventsClick(gameId) {
    const routeAction = {
      type: ROUTE_TYPES.ROUTE_GAMES_SHOW,
      payload: {
        id: gameId,
      },
      query: {
        ...locationQuery,
        tab: 'events',
      },
    };

    dispatch(routeAction);
  }

  function handleLeaderboardClick(gameId) {
    const routeAction = {
      type: ROUTE_TYPES.ROUTE_GAMES_SHOW,
      payload: {
        id: gameId,
      },
      query: {
        ...locationQuery,
        tab: 'leaderboard',
      },
    };

    dispatch(routeAction);
  }

  return (
    <GamesCompletedTable
      games={completedGames}
      totalGames={totalGames}
      hasFailed={hasFailed}
      isPending={isPending}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onLeaderboardClick={handleLeaderboardClick}
      onEventsClick={handleEventsClick}
      rowsPerPage={rowsPerPage}
      isManager={isManager}
    />
  );
}

CompletedGamesContainer.propTypes = {
  dateRange: PropTypes.shape({
    type: PropTypes.oneOf(['time period', 'date range']),
    timePeriod: PropTypes.string,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }),
};

CompletedGamesContainer.defaultProps = {
  dateRange: undefined,
};

export default CompletedGamesContainer;
