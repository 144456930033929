import { useMemo } from 'react';
import useSWR, { keyInterface } from 'swr';

import { arcadeApiClient } from '../../helpers/arcadeApiClient';
import { FetchMethods } from '../../types/http';

const createFetcher = (method: FetchMethods = 'GET') => (url: string) =>
  arcadeApiClient({ url, method }).then(
    res => res.data,
  );

export const useArcSWR = (
  key: keyInterface,
  method: FetchMethods = 'GET',
  options = {},
) => {
  const fetcher = useMemo(() => createFetcher(method), [method]);
  return useSWR(key, fetcher, options);
};
