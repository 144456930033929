import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { withTheme } from '@material-ui/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconClear from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import PeopleIcon from '@material-ui/icons/People';

import {
  ArcIconButton,
  ArcText,
  ArcTextField,
  ArcView,
  Grid,
  createWithStyles,
  ArcNoResultsMessage,
  ArcDataTableCell,
  ArcDataTableRow,
} from 'arcade-frontend-ui';
import ArcTabs from 'arcade-frontend-ui/src/components/ArcTabs';
import ArcTab from 'arcade-frontend-ui/src/components/ArcTab';

import PeopleTable from '../PeopleTable/PeopleTable';
import PeopleGrid from '../PeopleGrid/PeopleGrid';
import PeopleGridItem from '../PeopleGrid/PeopleGridItem/PeopleGridItem';
import PeopleCreateTeamModalContainer from '../../containers/PeopleCreateTeamModalContainer';
import PeopleDeleteTeamModalContainer from '../../containers/PeopleDeleteTeamModalContainer';
import PeopleInvitePersonFormContainer from '../../containers/PeopleInvitePersonFormContainer';
import PeopleTeamListContainer from '../../containers/PeopleTeamListContainer/PeopleTeamListContainer';
import PeopleListHeader from './PeopleListHeader';

const PLACEHOLDERS = [0, 1, 2, 3];
const CLOSE_DIALOG_ANIMATION_DELAY = 200;

const styles = {
  ContainerWrapper: theme => ({
    root: {
      backgroundColor: theme.palette.common.white,
      boxShadow:
        '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
      borderRadius: 4,
      flexDirection: 'row',
      justifyContent: 'center',

      [theme.breakpoints.up('sm')]: {
        margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      },

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        margin: 0,
        borderRadius: 0,
      },
    },
  }),

  SearchField: theme => ({
    root: {
      margin: theme.spacing(2),
      marginLeft: theme.spacing(3),
      width: theme.spacing(30),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '100%',
      },
    },
  }),

  AdornmentIconWrapper: theme => ({
    root: {
      color: theme.palette.grey[400],
    },

    startAdornment: {
      marginRight: theme.spacing(1),
      cursor: 'default',
    },
  }),

  SortToggle: theme => ({
    root: {
      margin: `${theme.spacing(1.5)}px 0`,
      [theme.breakpoints.down('xs')]: {
        margin: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
      },
    },
  }),

  TeamTitle: theme => ({
    root: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      fontSize: theme.font.getFontSize(1.5),

      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(3),
        fontSize: theme.font.getFontSize(1.25),
      },
    },

    isFirstChild: {
      marginTop: theme.spacing(2),
    },
    subheader: {
      marginLeft: theme.spacing(2.5),
      fontSize: theme.font.getFontSize(1),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(2),
        fontSize: theme.font.getFontSize(0.85),
      },
    },
  }),
  FilterSection: () => ({
    root: {
      flexDirection: 'row',
      marginLeft: 'auto',
    },
  }),
  FilterButton: theme => ({
    root: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 'auto',
        marginRight: theme.spacing(2),
      },
    },
  }),
  PeopleDisplayTypeIconButton: () => ({
    root: {
      marginLeft: 'auto',
      marginRight: 16,
    },
  }),
  FixedWidthDataTableCell: theme => ({
    root: {
      paddingRight: theme.spacing(2),

      [theme.breakpoints.up('lg')]: {
        width: '25%',
      },
      [theme.breakpoints.down('md')]: {
        width: '35%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '60%',
      },
    },
  }),
  PeopleGridPlaceholderContainer: theme => ({
    root: {
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'initial',
        marginLeft: 16,
      },
    },
  }),
};

const ContainerWrapper = createWithStyles(styles.ContainerWrapper)(ArcView);
const SearchField = createWithStyles(styles.SearchField)(ArcTextField);
const AdornmentIconWrapper = createWithStyles(styles.AdornmentIconWrapper)(
  ArcView,
);
const TeamTitle = createWithStyles(styles.TeamTitle)(ArcView);
const FilterButton = createWithStyles(styles.FilterButton)(ArcIconButton);
const FilterSection = createWithStyles(styles.FilterSection)(ArcView);
const PeopleDisplayTypeIconButton = createWithStyles(
  styles.PeopleDisplayTypeIconButton,
)(ArcIconButton);
const PeopleGridPlaceholderContainer = createWithStyles(
  styles.PeopleGridPlaceholderContainer,
)(Grid);

const PEOPLE_DISPLAY_TYPES = {
  GRID: 'GRID',
  TABLE: 'TABLE',
};

class PeopleList extends React.PureComponent {
  static propTypes = {
    canAwardChests: PropTypes.bool,
    canAwardTokens: PropTypes.bool,
    hasFeaturePeopleV2: PropTypes.bool,
    currentUser: PropTypes.shape({ id: PropTypes.string }),
    isFetching: PropTypes.bool,
    onUpdatePeopleDisplayTypes: PropTypes.func,
    peopleById: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
        image: PropTypes.string,
        name: PropTypes.string,
        phone: PropTypes.string,
        team: PropTypes.string,
      }),
    ),
    peopleDisplayType: PropTypes.string,
    peopleIds: PropTypes.arrayOf(PropTypes.string),
    teamPeople: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    onRefreshData: PropTypes.func,
    onGetLockedPeople: PropTypes.func,
  };

  static defaultProps = {
    canAwardChests: false,
    canAwardTokens: false,
    hasFeaturePeopleV2: false,
    currentUser: {},
    isFetching: false,
    onUpdatePeopleDisplayTypes: global.noop,
    peopleDisplayType: '',
    peopleById: null,
    peopleIds: null,
    teamPeople: null,
    onRefreshData: global.noop,
    onGetLockedPeople: global.noop,
  };

  state = {
    searchTerm: '',
    filteredPeople: this.props.peopleIds.map(id => this.props.peopleById[id]),
    filteredTeams: Object.keys(this.props.teamPeople),
    sortType: 'by-name',
    showFilters: false,
    windowWidth: window && window.innerWidth,
    anchorEl: null,
    selectedPerson: {},
    selectedTeam: null,
    activeTab: 'members',
    openedDialog: '',
  };

  componentDidUpdate(prevProps, prevState) {
    const availablePeopleChanged = prevProps.peopleIds !== this.props.peopleIds;
    const availableTeamsChanged =
      prevProps.teamPeople !== this.props.teamPeople;
    const searchChanged = prevState.searchTerm !== this.state.searchTerm;
    const hasSearchBeenCleared =
      searchChanged && this.state.searchTerm.length === 0;

    if (availablePeopleChanged || hasSearchBeenCleared) {
      const people = this.props.peopleIds.map(id => this.props.peopleById[id]);
      if (this.state.sortType === 'locked') {
        this.setFilteredPeople(people.filter(person => person.locked));
      } else {
        this.setFilteredPeople(people.filter(person => !person.locked));
      }
    }

    if (availableTeamsChanged || hasSearchBeenCleared) {
      this.setFilteredTeams(Object.keys(this.props.teamPeople));
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.setWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWindowWidth);
  }

  get hasPeople() {
    return this.state.sortType === 'by-team'
      ? this.state.filteredTeams.length >= 1
      : this.state.filteredPeople.length >= 1;
  }

  setActiveTab = activeTab => this.setState({ activeTab });

  setAnchorEl = anchorEl => this.setState({ anchorEl });

  setFilteredPeople = (filteredPeople, cb) =>
    this.setState({ filteredPeople }, cb);

  setFilteredTeams = filteredTeams => this.setState({ filteredTeams });

  setOpenedDialog = openedDialog => this.setState({ openedDialog });

  setSearchTerm = (searchTerm, cb) => this.setState({ searchTerm }, cb);

  setSelectedTeam = selectedTeam => this.setState({ selectedTeam });

  setOpenedDialog = openedDialog => this.setState({ openedDialog });

  setShowFilters = showFilters => {
    this.setState({ showFilters });
  };

  setSortType = sortType => this.setState({ sortType });

  setWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getPeopleByTeam = teamName => {
    const peopleIds = this.props.teamPeople[teamName] || [];
    const { filteredPeople, searchTerm } = this.state;
    if (teamName === 'no team') {
      return filteredPeople.filter(people => people.team === undefined);
    }

    return !!searchTerm && !!filteredPeople.length
      ? filteredPeople.filter(people => people.team === teamName)
      : peopleIds.map(id => this.props.peopleById[id]);
  };

  handleChangeSortBy = sortType => {
    if (this.state.sortType === 'locked') {
      this.setFilteredPeople([], () => this.props.onGetLockedPeople(false));
    }

    if (sortType === 'locked') {
      this.setFilteredPeople([], () => this.props.onGetLockedPeople(true));
    }

    this.setSortType(sortType);
    this.setSearchTerm('', this.updateListThrottled);
  };

  handleChangeSearchTerm = value => {
    if (value.length > 2) {
      this.setSearchTerm(value, this.updateListThrottled);
    } else {
      this.setSearchTerm('', this.updateListThrottled);
    }
  };

  handleChangePeopleDisplayType = () =>
    this.props.peopleDisplayType === PEOPLE_DISPLAY_TYPES.TABLE
      ? this.props.onUpdatePeopleDisplayTypes(PEOPLE_DISPLAY_TYPES.GRID)
      : this.props.onUpdatePeopleDisplayTypes(PEOPLE_DISPLAY_TYPES.TABLE);

  handleOnInvitePersonClick = () => {
    this.setOpenedDialog('invite-person');
  };

  handleCreateTeamClick = () => {
    this.setOpenedDialog('create-team');
  };

  handleClickResetSearch = () =>
    this.setSearchTerm('', this.updateListThrottled);

  handleClose = () => this.setAnchorEl(null);

  handleCloseDialog = () => {
    this.setSelectedTeam(null);
    this.setOpenedDialog('');
  };

  handleCloseCreateTeamDialog = () => {
    this.setOpenedDialog('');

    setTimeout(() => {
      this.setSelectedTeam(null);
    }, CLOSE_DIALOG_ANIMATION_DELAY);
  };

  handleDeleteTeam = team => {
    this.setState({ selectedTeam: team, openedDialog: 'delete-team' });
  };

  handleEditTeamDetails = team => {
    this.setState({ selectedTeam: team, openedDialog: 'create-team' });
  };

  handleRefreshData = () => {
    this.props.onRefreshData();
  };

  updateListThrottled = debounce(300, () => {
    if (this.state.searchTerm.length < 3) {
      return;
    }

    if (this.state.sortType === 'by-team') {
      const filteredPeople = this.filterPeopleBySearchTerm(
        this.state.searchTerm,
      );
      const filteredTeamNames = [
        ...new Set(
          filteredPeople.map(people => (people.team ? people.team : 'no team')),
        ),
      ];

      this.setFilteredPeople(filteredPeople);
      this.setFilteredTeams(filteredTeamNames);
    } else {
      this.setFilteredTeams([]);
      this.setFilteredPeople(
        this.filterPeopleBySearchTerm(this.state.searchTerm),
      );
    }
  });

  filterTeamsBySearchTerm = searchTerm => {
    const teamNames = Object.keys(this.props.teamPeople);
    return teamNames.filter(team => this.containsSearchTerm(team, searchTerm));
  };

  filterPeopleBySearchTerm = searchTerm => {
    if (this.state.sortType === 'locked') {
      return this.state.filteredPeople.filter(person =>
        this.containsSearchTerm(person, searchTerm),
      );
    }

    const peopleResults = this.props.peopleIds.map(
      id => this.props.peopleById[id],
    );
    return peopleResults.filter(person =>
      this.containsSearchTerm(person, searchTerm),
    );
  };

  containsSearchTerm = (object, searchTerm) => {
    const { name, team, email } = object;

    return [name, team, email]
      .map(
        value =>
          value &&
          value.toLowerCase().includes(searchTerm.toLowerCase().trim()),
      )
      .includes(true);
  };

  renderCreateTeamModal = () => (
    <PeopleCreateTeamModalContainer
      open={this.state.openedDialog === 'create-team'}
      onClose={this.handleCloseCreateTeamDialog}
      team={this.state.selectedTeam}
    />
  );

  renderDeleteTeamModal = () => (
    <PeopleDeleteTeamModalContainer
      open={this.state.openedDialog === 'delete-team'}
      onClose={this.handleCloseDialog}
      onDeleteTeamSuccess={this.handleRefreshData}
      team={this.state.selectedTeam}
    />
  );

  renderInvitePeopleForm = () => (
    <PeopleInvitePersonFormContainer
      open={this.state.openedDialog === 'invite-person'}
      onClose={this.handleCloseDialog}
      onInvitePersonSuccess={this.handleRefreshData}
    />
  );

  renderFilterButton = () => (
    <FilterButton onClick={() => this.setShowFilters(!this.state.showFilters)}>
      <FilterListIcon />
    </FilterButton>
  );

  renderNavigation() {
    return (
      <ContainerWrapper>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={4} sm={4} md={3} lg={3}>
            <ArcView row style={{ padding: 16 }}>
              <ArcTabs
                value={this.state.activeTab}
                onChange={tab => this.setActiveTab(tab)}
              >
                <ArcTab value="members" label="Members" />
                {this.props.hasFeaturePeopleV2 && (
                  <ArcTab value="teams" label="Teams" />
                )}
              </ArcTabs>
            </ArcView>
          </Grid>

          {this.state.activeTab === 'members' && (
            <FilterSection>
              <PeopleDisplayTypeIconButton
                data-testid="PeopleList-DisplayTypeIconButton"
                onClick={this.handleChangePeopleDisplayType}
              >
                {this.props.peopleDisplayType === PEOPLE_DISPLAY_TYPES.GRID ? (
                  <ViewListIcon />
                ) : (
                  <ViewModuleIcon />
                )}
              </PeopleDisplayTypeIconButton>
            </FilterSection>
          )}
        </Grid>
      </ContainerWrapper>
    );
  }

  renderNoResultsMessage = () => {
    const iconStyles = { transform: 'translate(-11%, -50%)' };
    const shapeStyles = { backgroundColor: '#efefef' };

    const { activeTab, searchTerm, sortType } = this.state;

    if (searchTerm) {
      return (
        <ArcView margin="32" padding="32">
          <ArcNoResultsMessage
            iconComponent={PeopleIcon}
            iconStyles={iconStyles}
            shapeStyles={shapeStyles}
            header={`No ${activeTab === 'members' ? 'People' : 'Teams'} Found`}
            subheader={`Couldn't find any results for '${searchTerm}'`}
          />
        </ArcView>
      );
    }

    if (sortType === 'locked' && !searchTerm) {
      return (
        <ArcView margin="32" padding="32">
          <ArcNoResultsMessage
            iconComponent={PeopleIcon}
            iconStyles={iconStyles}
            shapeStyles={shapeStyles}
            header="No Locked People"
            subheader="When you lock people in Arcade they will show up in this list"
          />
        </ArcView>
      );
    }

    return (
      <ArcView margin="32" padding="32">
        <ArcNoResultsMessage
          iconComponent={PeopleIcon}
          iconStyles={iconStyles}
          shapeStyles={shapeStyles}
          header={`No ${activeTab === 'members' ? 'People' : 'Teams'} Found`}
          subheader={
            activeTab === 'teams' ? 'Add a team using the button above' : null
          }
        />
      </ArcView>
    );
  };

  renderHeader = () => (
    <PeopleListHeader
      onCreateTeamClick={this.handleCreateTeamClick}
      onChangeSearch={this.handleChangeSearchTerm}
      onChangeSortBy={this.handleChangeSortBy}
      onInvitePersonClick={this.handleOnInvitePersonClick}
      type={this.state.activeTab}
      sortBy={this.state.sortType}
    />
  );

  renderSearchField() {
    const searchPlaceholder =
      this.state.sortType === 'by-name' ? 'Search People' : 'Search Teams';

    return (
      <SearchField
        placeholder={searchPlaceholder}
        value={this.state.searchTerm}
        onChange={this.handleChangeSearchTerm}
        variant="outlined"
        margin="dense"
        InputProps={{
          startAdornment: (
            <InputAdornment>
              <AdornmentIconWrapper startAdornment>
                <SearchIcon />
              </AdornmentIconWrapper>
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {this.state.searchTerm.length > 0 && (
                <AdornmentIconWrapper>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={this.handleClickResetSearch}
                  >
                    <IconClear />
                  </IconButton>
                </AdornmentIconWrapper>
              )}
            </>
          ),
        }}
      />
    );
  }

  renderSortByName() {
    const { filteredPeople } = this.state;
    const { peopleDisplayType } = this.props;

    return peopleDisplayType === PEOPLE_DISPLAY_TYPES.GRID
      ? this.renderPeopleGrid(filteredPeople)
      : this.renderPeopleTable(filteredPeople);
  }

  renderTeamItem = (teamName, index) => {
    const noTeam = 'No Team Assigned';

    return (
      <ArcView key={teamName}>
        <TeamTitle subheader isFirstChild={index === 0}>
          <ArcText isLarge>
            {teamName === 'no team' ? noTeam : teamName}
          </ArcText>
        </TeamTitle>

        {this.renderTeamPeople(teamName)}
      </ArcView>
    );
  };

  renderTeamPeople = teamName => {
    const { peopleDisplayType } = this.props;
    const peopleByTeam = this.getPeopleByTeam(teamName);

    return peopleDisplayType === PEOPLE_DISPLAY_TYPES.GRID
      ? this.renderPeopleGrid(peopleByTeam)
      : this.renderPeopleTable(peopleByTeam);
  };

  renderSortByTeam() {
    const teamNames = this.state.filteredTeams;

    return <ArcView>{teamNames.map(this.renderTeamItem)}</ArcView>;
  }

  renderPeopleGrid = people => (
    <PeopleGrid
      people={people}
      isFetching={this.props.isFetching}
      canAwardChests={this.props.canAwardChests}
      canAwardTokens={this.props.canAwardTokens}
      currentUser={this.props.currentUser}
      onUpdateProfileSuccess={this.handleRefreshData}
      onUpdateTeamSuccess={this.handleRefreshData}
    />
  );

  renderPeopleTable = people => (
    <PeopleTable
      people={people}
      currentUser={this.props.currentUser}
      isFetching={this.props.isFetching}
      canAwardChests={this.props.canAwardChests}
      canAwardTokens={this.props.canAwardTokens}
      onUpdateProfileSuccess={this.handleRefreshData}
      onUpdateTeamSuccess={this.handleRefreshData}
    />
  );

  renderPeopleMembers() {
    switch (this.state.sortType) {
      case 'by-team':
        return this.renderSortByTeam();
      default:
        return this.renderSortByName();
    }
  }

  renderPeople() {
    const { activeTab, searchTerm } = this.state;
    if (!this.hasPeople && activeTab === 'members') {
      return this.renderNoResultsMessage();
    }

    return activeTab === 'members' ? (
      this.renderPeopleMembers()
    ) : (
      <PeopleTeamListContainer
        onDeleteTeam={this.handleDeleteTeam}
        onEditTeamDetails={this.handleEditTeamDetails}
        searchTerm={searchTerm}
      />
    );
  }

  renderLoadingPlaceholder() {
    const placeholderTableRows = [];

    const renderPlaceholderTableRows = numRows => {
      for (let i = 0; i < numRows; i += 1) {
        placeholderTableRows.push(
          <ArcDataTableRow key={i}>
            <ArcDataTableCell>
              <ArcView className="shimmer" padding="12" />
            </ArcDataTableCell>
            <ArcDataTableCell>
              <ArcView className="shimmer" padding="12" />
            </ArcDataTableCell>
            <ArcDataTableCell style={{ width: 8 }}>
              <ArcView
                className="shimmer"
                padding="12"
                style={{ marginLeft: 'auto', width: 30 }}
              />
            </ArcDataTableCell>
          </ArcDataTableRow>,
        );
      }
      return placeholderTableRows;
    };

    const placeholderTableView = (
      <ContainerWrapper>
        <Table>
          <TableBody>{renderPlaceholderTableRows(4)}</TableBody>
        </Table>
      </ContainerWrapper>
    );

    const placeholderGridView = (
      <ArcView marginTop="24" margin="8">
        <PeopleGridPlaceholderContainer container spacing={2}>
          {PLACEHOLDERS.map(id => (
            <Grid item key={id}>
              <PeopleGridItem isFetching={this.props.isFetching} />
            </Grid>
          ))}
        </PeopleGridPlaceholderContainer>
      </ArcView>
    );

    return this.props.peopleDisplayType === PEOPLE_DISPLAY_TYPES.GRID
      ? placeholderGridView
      : placeholderTableView;
  }

  render() {
    return (
      <>
        {this.renderNavigation()}
        {this.renderHeader()}
        {this.props.isFetching && !this.hasPeople
          ? this.renderLoadingPlaceholder()
          : this.renderPeople()}
        {this.renderCreateTeamModal()}
        {this.renderDeleteTeamModal()}
        {this.renderInvitePeopleForm()}
      </>
    );
  }
}

export default withTheme(PeopleList);
