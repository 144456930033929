import { createSelector } from 'reselect';
import moment from 'moment';

import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/sales';

const handleManageSalesLoadSuccess = (_state, action) => {
  const { sales } = action.payload.data;
  if (!sales || !sales.length) return {};

  const newState = {};

  sales.forEach(sale => {
    newState[sale.id] = sale;
  });

  return newState;
};

const handleManageSalesCreateSuccess = (state, action) => {
  const newSale = action.payload.data;

  return { ...state, [newSale.id]: newSale };
};

const handleManageSalesUpdateSuccess = (state, action) => {
  const newSale = action.payload.data;

  return { ...state, [newSale.id]: newSale };
};

const handleManageSalesDeleteSuccess = (state, action) => {
  const deletedId = action.payload.data.sale_id;

  const nextState = { ...state };

  delete nextState[deletedId];

  return nextState;
};

const initialState = {};

const handlers = {
  [types.MANAGE_SALES_CREATE.SUCCESS]: handleManageSalesCreateSuccess,
  [types.MANAGE_SALES_DELETE.SUCCESS]: handleManageSalesDeleteSuccess,
  [types.MANAGE_SALES_INDEX.SUCCESS]: handleManageSalesLoadSuccess,
  [types.MANAGE_SALES_UPDATE.SUCCESS]: handleManageSalesUpdateSuccess,
};

const salesById = createReducer(initialState, handlers);

export default salesById;

export const getManageSalesById = state => state.manage.sales.salesById;

export const getManageSales = createSelector(getManageSalesById, byId =>
  Object.values(byId).sort(
    ({ createdAt: createdAtLeft }, { createdAt: createdAtRight }) =>
      moment(createdAtRight) - moment(createdAtLeft),
  ),
);
