import * as types from 'arcade-frontend-core/src/types/routes';

export default {
  [types.ROUTE_MANAGE]: '/arcade/manage',

  [types.ROUTE_MANAGE_ACCOUNT]: '/arcade/manage/account',

  [types.ROUTE_MANAGE_ACTIVITIES]: '/arcade/manage/activities',

  [types.ROUTE_MANAGE_BILLING]: '/arcade/manage/account',
  [types.ROUTE_MANAGE_BILLING2]: '/arcade/manage/billing',

  [types.ROUTE_MANAGE_CHECKLISTS]: '/arcade/manage/checklists',
  [types.ROUTE_MANAGE_CHECKLISTS_CREATE]: '/arcade/manage/checklists/create',
  [types.ROUTE_MANAGE_CHECKLISTS_EDIT]: '/arcade/manage/checklists/:id/edit',
  [types.ROUTE_MANAGE_CHECKLISTS_SHOW]: '/arcade/manage/checklists/:id',

  [types.ROUTE_MANAGE_ENGAGEMENT]: '/arcade/manage/engagement',

  [types.ROUTE_MANAGE_INTEGRATIONS]: '/arcade/manage/integrations',

  [types.ROUTE_MANAGE_METRICS]: '/arcade/manage/metrics',
  [types.ROUTE_MANAGE_METRICS_CREATE]: '/arcade/manage/metrics/create',
  [types.ROUTE_MANAGE_METRICS_EDIT]: '/arcade/manage/metrics/:id/edit',
  [types.ROUTE_MANAGE_METRICS_SHOW]:
    '/arcade/manage/metrics/:id/:metricDetailsType',

  [types.ROUTE_MANAGE_GAMES]: '/arcade/manage/games',
  [types.ROUTE_MANAGE_GAMES_CREATE]: '/arcade/manage/games/create',
  [types.ROUTE_MANAGE_GAMES_EDIT]: '/arcade/manage/games/:id/edit',
  [types.ROUTE_MANAGE_GAMES_CLONE]: '/arcade/manage/games/:id/clone',
  [types.ROUTE_MANAGE_GAMES_SHOW]: '/arcade/manage/games/:id/:gameDetailsType',

  // [types.ROUTE_MANAGE_INTEGRATION_HISTORY]: '/arcade/manage/integrations',

  [types.ROUTE_MANAGE_PEOPLE]: '/arcade/manage/users',

  [types.ROUTE_MANAGE_POSTS]: '/arcade/manage/posts',

  [types.ROUTE_MANAGE_REWARDS]: '/arcade/manage/rewards',
  [types.ROUTE_MANAGE_REWARDS_CREATE]: '/arcade/manage/rewards/create',
  [types.ROUTE_MANAGE_REWARDS_EDIT]: '/arcade/manage/rewards/:id/edit',
  [types.ROUTE_MANAGE_REWARDS_SHOW]: '/arcade/manage/rewards/:id',

  [types.ROUTE_MANAGE_TEAMS]: '/arcade/manage/teams',

  [types.ROUTE_MANAGE_TOKENS]: '/arcade/manage/tokens',

  [types.ROUTE_MANAGE_TOKEN_BUDGETS]: '/arcade/manage/token-budgets',

  [types.ROUTE_MANAGE_TRANSACTIONS]: '/arcade/manage/transactions',
};
