import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import track from 'arcade-frontend-core/src/helpers/track';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import {
  ArcView,
  ArcText,
  ArcDataTableCell,
  ArcDataTableRow,
  ArcEntity,
  ArcIconButton,
  ArcNoResultsMessage,
  ArcTextField,
  ArcLink,
} from 'arcade-frontend-ui';
import { getCurrentUserPermission } from 'arcade-frontend-core/src/reducers/user';
import ManageUserTokenBudgetContainer from '../../containers/ManageUserTokenBudgetContainer/ManageUserTokenBudgetContainer';
import TokenUserBudgetLimitFormContainer from '../../containers/TokenUserBudgetLimitFormContainer/TokenUserBudgetLimitFormContainer';

const useStyles = makeStyles(theme => ({
  tableStyles: {
    backgroundColor: theme.palette.background.paper,
  },
  tableWrapper: {
    display: 'block',
    overflowX: 'auto',
    overflowY: 'visible',
  },
  tableTitleWrapper: {
    flexDirection: 'row',
    backgroundColor: theme.palette.background.paper,
    padding: 16,
    borderBottom: `4px solid ${theme.palette.background.default}`,
  },
  tableTitle: {
    alignSelf: 'center',
    fontSize: 24,
    lineHeight: 1.35,
    fontWeight: '200',
    color: theme.palette.text.secondary,
  },
  tableRow: {
    height: 50,
  },
  searchInput: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 'auto',
    backgroundColor: theme.palette.grey[100],
    [theme.breakpoints.down('xs')]: {
      marginTop: 12,
      marginBottom: 12,
      borderRadius: 4,
    },
  },

  iconWrapper: {
    marginLeft: 'auto',
    backgroundColor: 'inherit',
  },
  ellipsisIcon: {
    color: theme.palette.grey.main,
  },
  settingsIcon: {
    height: 20,
    color: theme.palette.grey.light,
  },
  placeholderUserAvatar: {
    borderRadius: 50,
    height: 20,
    width: 20,
    marginRight: 8,
    padding: 12,
  },
  placeholderNameCell: {
    flexGrow: 2,
  },
  GamesNoResultsWrapper: {
    backgroundColor: theme.palette.background.paper,
    height: '50%',
    width: '100%',
    padding: theme.spacing(6),
  },
  noResultsSubheaderLink: {
    color: theme.palette.grey[500],
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.grey[500],
    },
  },
  textEmphasis: {
    color: theme.palette.grey[700],
    fontWeight: 600,
    fontSize: theme.font.getFontSize(0.8),
  },
  textSecondary: {
    color: theme.palette.grey[600],
    fontSize: theme.font.getFontSize(0.75),
  },
}));

function TokenUserBudgetList({
  data,
  userBudgetsRequestStatus,
  onBudgetLimitSubmit,
  onTokenBudgetCancel,
  onViewTokenHistory,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedMenu, setOpenedMenu] = useState(null);
  const [openedDialog, setOpenedDialog] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedPerson, setSelectedPerson] = useState({});
  const [searchString, setSearchString] = useState('');

  const canViewIntegrationHistory = useSelector(state =>
    getCurrentUserPermission(state, PERMISSIONS.MANAGE_TOKENS),
  );
  const canEditMonthlyLimits = useSelector(state =>
    getCurrentUserPermission(state, PERMISSIONS.SET_MONTHLY_LIMIT),
  );
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    track(`change page from ${page} to ${newPage}`, {
      payload: { event: 'token-budgets:change-page' },
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    track(`change rows per page from ${rowsPerPage} to ${event.target.value}`, {
      payload: { event: 'token-budgets:change-rows-per-page' },
    });

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDropdownMenu = (event, dropdownMenuName) => {
    setAnchorEl(event.currentTarget);
    setOpenedMenu(dropdownMenuName);
  };

  const handleCloseDropdownMenu = () => {
    setAnchorEl(null);
    setOpenedMenu(null);
  };

  const handleOpenDialog = dialogName => {
    track(`open dialog ${dialogName}`, {
      payload: { event: `token-budgets:open-dialog-${dialogName}` },
    });
    setOpenedDialog(dialogName);
  };

  const handleCloseDialog = () => {
    track(`close dialog ${openedDialog}`, {
      payload: { event: `token-budgets:close-dialog-${openedDialog}` },
    });
    setOpenedDialog(null);
  };

  const handleEditLimit = () => {
    setOpenedDialog('user-budget-limit-form');
  };

  const startSearch = debounce(500, value => {
    setSearchString(
      value
        .toLowerCase()
        .trim()
        .replace(/[^\w\s]/gi, ''),
    );
  });

  const handleOnChangeSearch = event => {
    startSearch(event.target.value);
  };

  const handleClickSettings = (event, person) => {
    setAnchorEl(event.currentTarget);
    setOpenedMenu('userSettings');
    setSelectedPerson(person);
  };

  const formatTokens = (tokenValue, noValueDisplay) =>
    tokenValue > 0
      ? window.Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(
          tokenValue,
        )
      : noValueDisplay;

  const renderPlaceholder = () => {
    const placeholderRows = [];

    const renderPlaceholderRows = numRows => {
      for (let i = 0; i < numRows; i += 1) {
        placeholderRows.push(
          <TableRow key={i}>
            <ArcDataTableCell>
              <ArcView row>
                <ArcView
                  className={`shimmer ${classes.placeholderUserAvatar}`}
                />
                <ArcView className={`shimmer ${classes.placeholderNameCell}`} />
              </ArcView>
            </ArcDataTableCell>
            <ArcDataTableCell>
              <ArcView className="shimmer" padding="12" />
            </ArcDataTableCell>
            <ArcDataTableCell>
              <ArcView className="shimmer" padding="12" />
            </ArcDataTableCell>
          </TableRow>,
        );
      }
      return placeholderRows;
    };

    return (
      <Table className={classes.tableStyles}>
        <TableHead>
          <TableRow>
            <ArcDataTableCell>
              <ArcView className="shimmer" padding="12" />
            </ArcDataTableCell>
            <ArcDataTableCell>
              <ArcView className="shimmer" padding="12" />
            </ArcDataTableCell>
            <ArcDataTableCell>
              <ArcView className="shimmer" padding="12" />
            </ArcDataTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderPlaceholderRows(4)}</TableBody>
        <TableFooter>
          <TableRow />
        </TableFooter>
      </Table>
    );
  };

  const renderTableBody = budgets =>
    budgets.map((userBudget, index) => {
      const { name, profileImage, remainingBalance, monthlyLimit } = userBudget;

      const remainingBalanceText =
        !monthlyLimit || monthlyLimit === 0 ? 'N/A' : 'None';

      const formattedMonthlyLimit = formatTokens(
        monthlyLimit,
        'Company Balance',
      );
      const formattedRemainingBalance = formatTokens(
        remainingBalance,
        remainingBalanceText,
      );

      return (
        <ArcDataTableRow key={name} shade={index % 2 === 0} color="default">
          <ArcDataTableCell slim>
            <ArcEntity type="person" name={name} imageUrl={profileImage} />
          </ArcDataTableCell>

          {formattedMonthlyLimit === 'Company Balance' ? (
            <ArcDataTableCell slim>
              <ArcText className={classes.textEmphasis}>
                {formattedMonthlyLimit}
              </ArcText>
            </ArcDataTableCell>
          ) : (
            <ArcDataTableCell slim>{formattedMonthlyLimit}</ArcDataTableCell>
          )}

          <ArcDataTableCell slim>{formattedRemainingBalance}</ArcDataTableCell>

          <ArcDataTableCell slim>
            <ArcView className={classes.iconWrapper}>
              <ArcIconButton
                className={classes.iconWrapper}
                onClick={event => handleClickSettings(event, userBudget)}
              >
                <SettingsIcon className={classes.settingsIcon} />
              </ArcIconButton>
            </ArcView>
          </ArcDataTableCell>
        </ArcDataTableRow>
      );
    });

  const renderNoResultsSubheader = () => (
    <ArcText>
      <ArcLink
        className={classes.noResultsSubheaderLink}
        onClick={() => handleOpenDialog('manage-user-token-budget')}
      >
        {'Click here'}
      </ArcLink>
      {' to give other people the permission'}
    </ArcText>
  );

  let userBudgets = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  if (searchString.length >= 3) {
    userBudgets = userBudgets.filter(userBudget =>
      userBudget.name.toLowerCase().includes(searchString),
    );
  }

  const isLoading = userBudgetsRequestStatus.pending;
  const hasNoResults = !isLoading && !userBudgets.length;

  return (
    <>
      <ArcView
        id="token-budget-user-list"
        className={classes.tableTitleWrapper}
      >
        <ArcText className={classes.tableTitle}>{'User Limits'}</ArcText>
        <ArcTextField
          className={classes.searchInput}
          label="Search users"
          type="search"
          margin="dense"
          variant="outlined"
          onChange={handleOnChangeSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ color: '#616264' }} />
              </InputAdornment>
            ),
          }}
        />
      </ArcView>
      <>
        {hasNoResults ? (
          <ArcView className={classes.GamesNoResultsWrapper}>
            <ArcNoResultsMessage
              header={
                searchString.length >= 3
                  ? `No results for '${searchString}'`
                  : 'No matching people found'
              }
              subheader={renderNoResultsSubheader()}
            />
          </ArcView>
        ) : (
          <>
            {isLoading ? (
              renderPlaceholder()
            ) : (
              <ArcView className={classes.tableWrapper}>
                <Table className={classes.tableStyles}>
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      <ArcDataTableCell headerCell strong slim>
                        Employee
                      </ArcDataTableCell>
                      <ArcDataTableCell headerCell strong slim>
                        Monthly Limit
                      </ArcDataTableCell>
                      <ArcDataTableCell headerCell strong slim>
                        Remaining Balance
                      </ArcDataTableCell>

                      <ArcDataTableCell headerCell slim>
                        <ArcView className={classes.iconWrapper}>
                          <ArcIconButton
                            className={classes.iconWrapper}
                            onClick={e =>
                              handleOpenDropdownMenu(e, 'tableSettings')
                            }
                          >
                            <MoreVertIcon className={classes.ellipsisIcon} />
                          </ArcIconButton>
                        </ArcView>
                      </ArcDataTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderTableBody(userBudgets)}</TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[25, 50, 100]}
                        page={page}
                        colSpan={5}
                        count={data.length}
                        backIconButtonProps={{
                          'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                          'aria-label': 'next page',
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </ArcView>
            )}
          </>
        )}
      </>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={openedMenu === 'tableSettings'}
        onClose={handleCloseDropdownMenu}
      >
        <MenuItem
          onClick={() => {
            handleOpenDialog('manage-user-token-budget');
            handleCloseDropdownMenu();
          }}
        >
          {'Add/Remove Users'}
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={openedMenu === 'userSettings'}
        onClose={handleCloseDropdownMenu}
      >
        {canViewIntegrationHistory && (
          <MenuItem
            onClick={() => {
              onViewTokenHistory(selectedPerson);
              handleCloseDropdownMenu();
            }}
          >
            {'View History'}
          </MenuItem>
        )}
        <MenuItem
          disabled={!canEditMonthlyLimits}
          onClick={() => {
            handleEditLimit();
            handleCloseDropdownMenu();
          }}
        >
          <ArcView>
            {'Edit Limit'}
            {!canEditMonthlyLimits && (
              <ArcText className={classes.textSecondary}>
                {'Permission required'}
              </ArcText>
            )}
          </ArcView>
        </MenuItem>
      </Menu>

      <TokenUserBudgetLimitFormContainer
        open={openedDialog === 'user-budget-limit-form'}
        onClose={handleCloseDialog}
        onSubmit={onBudgetLimitSubmit}
        person={selectedPerson}
      />

      <ManageUserTokenBudgetContainer
        open={openedDialog === 'manage-user-token-budget'}
        searchString={searchString}
        onClose={() => {
          handleCloseDialog();
          onTokenBudgetCancel();
        }}
      />
    </>
  );
}

TokenUserBudgetList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      profileImage: PropTypes.string,
      remainingBalance: PropTypes.number,
      totalBalance: PropTypes.number,
    }),
  ),
  userBudgetsRequestStatus: PropTypes.shape({
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    succeeded: PropTypes.bool,
    failed: PropTypes.bool,
  }),
  onBudgetLimitSubmit: PropTypes.func,
  onTokenBudgetCancel: PropTypes.func,
  onViewTokenHistory: PropTypes.func,
};

TokenUserBudgetList.defaultProps = {
  data: [],
  userBudgetsRequestStatus: {},
  onBudgetLimitSubmit: global.noop,
  onTokenBudgetCancel: global.noop,
  onViewTokenHistory: global.noop,
};

export default TokenUserBudgetList;
