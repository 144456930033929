import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconImage from '@material-ui/icons/ImageOutlined';
import IconVideocam from '@material-ui/icons/VideocamOutlined';
import IconAttachFile from '@material-ui/icons/AttachFile';
import IconPinDrop from '@material-ui/icons/PinDropOutlined';

import {
  ArcText,
  ArcView,
  ArcEasyMenu,
  createWithStyles,
} from 'arcade-frontend-ui';

const CONTENT_TRIM_LENGTH = 100;

const styles = {
  ActivityRow: theme => ({
    root: {
      height: theme.spacing(6),
      width: '100%',
    },

    shade: {
      backgroundColor: theme.palette.grey[100],
    },

    clickable: {
      cursor: 'pointer',
    },
  }),

  ActivityCell: () => ({
    root: {},
  }),

  AnalyticsCell: () => ({
    root: {
      fontSize: 16,
    },
  }),

  IconVideocamStyle: () => ({
    root: {},

    colorDisabled: {
      color: '#D4D4D4',
    },
  }),

  IconImageStyle: () => ({
    root: {},

    colorDisabled: {
      color: '#D4D4D4',
    },
  }),

  IconAttachFileStyle: () => ({
    root: {},

    colorDisabled: {
      color: '#D4D4D4',
    },
  }),

  IconPinDropStyle: () => ({
    root: {},

    colorDisabled: {
      color: '#D4D4D4',
    },
  }),
};

const ActivityRow = createWithStyles(styles.ActivityRow)(TableRow);
const AnalyticsCell = createWithStyles(styles.AnalyticsCell)(TableCell);
const ActivityCell = createWithStyles(styles.ActivityCell)(TableCell);
const IconVideocamStyle = createWithStyles(styles.IconVideocamStyle)(
  IconVideocam,
);
const IconImageStyle = createWithStyles(styles.IconImageStyle)(IconImage);
const IconAttachFileStyle = createWithStyles(styles.IconAttachFileStyle)(
  IconAttachFile,
);
const IconPinDropStyle = createWithStyles(styles.IconPinDropStyle)(IconPinDrop);

class ActivityHistoryListItem extends React.PureComponent {
  static propTypes = {
    acknowledgements: PropTypes.number,
    attachments: PropTypes.shape({
      file: PropTypes.bool,
      image: PropTypes.bool,
      video: PropTypes.bool,
    }),
    audience: PropTypes.number,
    comments: PropTypes.number,
    content: PropTypes.string,
    date: PropTypes.string,
    id: PropTypes.string,
    onClickActivity: PropTypes.func,
    onClickComments: PropTypes.func,
    onClickDeleteActivity: PropTypes.func,
    pinned: PropTypes.bool,
    reactions: PropTypes.number,
    shade: PropTypes.bool,
    views: PropTypes.number,
  };

  static defaultProps = {
    acknowledgements: null,
    attachments: null,
    audience: null,
    comments: null,
    content: null,
    date: null,
    id: null,
    onClickActivity: global.noop,
    onClickComments: global.noop,
    onClickDeleteActivity: global.noop,
    pinned: null,
    reactions: null,
    shade: null,
    views: 0,
  };

  handleClickComments = e => {
    this.props.onClickComments(e, this.props.id);
  };

  handleClickActivity = section => {
    this.props.onClickActivity(this.props.id, section);
  };

  handleClickDeleteActivity = () => {
    this.props.onClickDeleteActivity(this.props.id);
  };

  handleClickGetPost = () => {
    if (window.getPost) {
      window.getPost(this.props.id);
    }
  };

  percentage = type => (type / this.props.audience) * 100;

  renderCircularProgress = attributeCount => (
    <ArcView row align="center">
      <ArcView style={{ marginRight: 4, color: '#DCF8F4' }}>
        <CircularProgress
          variant="static"
          value={100}
          size={20}
          thickness={12}
          color="inherit"
        />
        <CircularProgress
          variant="static"
          value={this.percentage(attributeCount)}
          size={20}
          thickness={12}
          style={{ position: 'absolute' }}
          color="secondary"
        />
      </ArcView>
      {attributeCount}
    </ArcView>
  );

  render() {
    const {
      attachments,
      audience,
      acknowledgements,
      comments,
      content,
      date,
      pinned,
      shade,
      views,
      reactions,
    } = this.props;

    const menuOptions = [
      {
        children: 'Go to active post',
        onClick: () => this.handleClickGetPost(),
      },
      { children: 'Delete', onClick: () => this.handleClickDeleteActivity() },
    ];

    return (
      <ActivityRow shade={shade} clickable>
        <ActivityCell
          style={{ paddingRight: 0, minWidth: 200 }}
          onClick={() => this.handleClickActivity()}
        >
          <ArcView>
            <ArcText isSmall colorGrey>
              {date}
            </ArcText>
            <ArcText
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingTop: 8,
              }}
            >
              {content && content.slice(0, CONTENT_TRIM_LENGTH)}
              {content && content.length > CONTENT_TRIM_LENGTH ? '...' : ''}
            </ArcText>
            <ArcView row style={{ marginTop: 8, marginBottom: 8 }}>
              <IconImageStyle
                fontSize="small"
                style={{ marginRight: 4 }}
                color={
                  attachments && attachments.image ? 'inherit' : 'disabled'
                }
              />
              <IconVideocamStyle
                fontSize="small"
                style={{ marginRight: 4 }}
                color={
                  attachments && attachments.video ? 'inherit' : 'disabled'
                }
              />
              <IconAttachFileStyle
                fontSize="small"
                style={{ marginRight: 4 }}
                color={attachments && attachments.file ? 'inherit' : 'disabled'}
              />
              <IconPinDropStyle
                fontSize="small"
                color={pinned ? 'inherit' : 'disabled'}
              />
            </ArcView>
          </ArcView>
        </ActivityCell>
        <AnalyticsCell
          align="left"
          onClick={() => this.handleClickActivity('Audience')}
        >
          {audience}
        </AnalyticsCell>
        <AnalyticsCell
          align="left"
          onClick={() => this.handleClickActivity('Views')}
        >
          {this.renderCircularProgress(views)}
        </AnalyticsCell>
        <AnalyticsCell
          align="left"
          onClick={() => this.handleClickActivity('Post')}
        >
          {comments}
        </AnalyticsCell>
        <AnalyticsCell
          align="left"
          onClick={() => this.handleClickActivity('Reactions')}
        >
          {this.renderCircularProgress(reactions)}
        </AnalyticsCell>
        <AnalyticsCell
          align="left"
          onClick={() => pinned && this.handleClickActivity('Acknowledgements')}
        >
          {pinned ? this.renderCircularProgress(acknowledgements) : '-'}
        </AnalyticsCell>
        <TableCell>
          <ArcEasyMenu options={menuOptions} />
        </TableCell>
      </ActivityRow>
    );
  }
}

export default ActivityHistoryListItem;
