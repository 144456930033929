import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import * as REWARD_TYPES from 'arcade-frontend-core/src/types/rewards';
import navigateTo from 'arcade-frontend-core/src/helpers/navigateTo';
import ArcResourceErrorDialog from 'arcade-frontend-ui/src/components/ArcResourceErrorDialog';
import ArcTokenValue from 'arcade-frontend-ui/src/components/ArcTokenValue';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcView from 'arcade-frontend-ui/src/primitives/ArcView';

function RewardsAwardFormResourceErrorDialog({
  awardType,
  awardValue,
  error,
  hasError,
  onClose,
  onReduceTokens,
  onTopUp,
  peopleLength,
}) {
  const currentUser = useSelector(getCurrentUser);

  function handleTopUp() {
    navigateTo('/arcade/manage/tokens');
    onTopUp();
  }

  function getErrorChildrenByAwardType() {
    switch (awardType) {
      case REWARD_TYPES.TOKENS: {
        const { data } = error.data.response;

        const reducedValue =
          data && data.availableAmount
            ? Math.floor(data.availableAmount / (peopleLength || 1))
            : 0;

        const handleReduceTokens = () => onReduceTokens(reducedValue);

        return (
          <ArcView marginBottom="48">
            <ArcView display="block" marginBottom="16">
              <span>{'You need '}</span>
              <ArcTokenValue
                value={awardValue - data.availableAmount}
                verticalAlign="bottom"
              />
              <span>{' tokens but you only have '}</span>
              <ArcTokenValue
                value={data.availableAmount}
                verticalAlign="bottom"
              />
              <span>{' available.'}</span>
            </ArcView>
            <ArcView marginBottom="16">
              <ArcButton
                variant="outlined"
                color="green"
                label="Reduce Tokens"
                onClick={handleReduceTokens}
              />
            </ArcView>
            <ArcView marginBottom="16">
              <ArcButton
                variant="outlined"
                color="blue"
                label="Request a Top Up"
                onClick={handleTopUp}
              />
            </ArcView>
          </ArcView>
        );
      }
      default:
        return null;
    }
  }

  function getErrorChildren() {
    if (!hasError) {
      return null;
    }

    switch (error.data.response.status) {
      case 400:
        return getErrorChildrenByAwardType();
      default:
        return null;
    }
  }

  const errorChildren = getErrorChildren();

  return (
    <ArcResourceErrorDialog
      entityId={currentUser.entityId}
      error={error}
      onClose={onClose}
      open={hasError}
      userId={currentUser.id}
    >
      {errorChildren}
    </ArcResourceErrorDialog>
  );
}

RewardsAwardFormResourceErrorDialog.displayName =
  'RewardsAwardFormResourceErrorDialog';

RewardsAwardFormResourceErrorDialog.propTypes = {
  awardType: PropTypes.oneOf([REWARD_TYPES.CHESTS, REWARD_TYPES.TOKENS]),
  awardValue: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.number),
    PropTypes.number,
  ]),
  error: PropTypes.shape({
    data: PropTypes.shape({
      response: PropTypes.shape({
        status: PropTypes.number,
        data: PropTypes.shape({
          error: PropTypes.oneOf(
            Object.values(ArcResourceErrorDialog.ERROR_TYPES),
          ),
          availableAmount: PropTypes.number,
        }),
      }),
    }),
  }),
  hasError: PropTypes.bool,
  onClose: PropTypes.func,
  onReduceTokens: PropTypes.func,
  onTopUp: PropTypes.func,
  peopleLength: PropTypes.number,
};

RewardsAwardFormResourceErrorDialog.defaultProps = {
  awardType: null,
  awardValue: null,
  error: undefined,
  hasError: undefined,
  onClose: global.noop,
  onReduceTokens: global.noop,
  onTopUp: global.noop,
  peopleLength: 0,
};

export default RewardsAwardFormResourceErrorDialog;
