import createActionCreators from 'redux-resource-action-creators';

import mockAxios from '../../test-utils/mockAxios';
import getEndpointUrlFromAxiosConfig from '../../test-utils/getEndpointUrlFromAxiosConfig';


function createResourceAction(crudAction, options = {}) {
  if (global.isProduction) {
    const requiredKeys = [
      'api',
      'resourceType',
      'requestKey',
    ];

    const missingKeys = requiredKeys.filter(key => typeof options[key] === typeof undefined);

    if (missingKeys.length) {
      console.error(
        [
          'createResourceAction(crudAction, options)\n',
          '[options] Expected missing keys:',
          missingKeys.map(key => `"${key}"`).join(', '),
          '\n',
          '[options] Received:',
        ].join(' '),
        options,
      );
    }
  }

  const {
    api,
    getInitialData,
    ...reduxResourceConfig
  } = options;

  const resourceAction = createActionCreators(crudAction, reduxResourceConfig);

  resourceAction.api = api;
  resourceAction.getInitialData = getInitialData;
  resourceAction.resourceType = options.resourceType;
  resourceAction.requestKey = options.requestKey;

  if (!global.isProduction) {
    resourceAction.isMock = false;

    resourceAction.mock = (response, payload) => {
      resourceAction.isMock = true;

      const config = api(payload);
      const url = getEndpointUrlFromAxiosConfig(config);

      mockAxios.add(url, response);
    };

    resourceAction.unmock = (payload) => {
      const config = api(payload);
      const url = getEndpointUrlFromAxiosConfig(config);

      mockAxios.remove(url);

      resourceAction.isMock = false;
    };
  }

  return resourceAction;
}

export default createResourceAction;
