import { combineReducers } from 'redux';

import entitiesByUuid from './entitiesByUuid';
import open from './open';
import requestStatus from './requestStatus';

const customAudience = combineReducers({
  entitiesByUuid,
  open,
  requestStatus,
});

export default customAudience;
