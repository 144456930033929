import React from 'react';
// import ArcPropTypes from '../../helpers/arc/propTypes';
import { createWithStyles } from '../../styles';
import ArcView from '../../primitives/ArcView/ArcView';

import positions from './positions';

import FinishFlag from '../../assets/FinishFlag';
import Grass from '../../assets/Grass';
import RaceCircuit from '../../assets/RaceCircuit';
import Spectators from '../../assets/Spectators';
import RaceArrow from '../../assets/RaceArrow';
import ArcClouds from '../ArcClouds/ArcClouds';

import { displaysScoresType, PositionedPlayers } from '../PositionedPlayers';

const styles = {
  Container: () => ({
    root: {
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
    },
  }),

  Track: () => ({
    root: {
      color: '#F00',
      paddingTop: 0,
    },
  }),

  TrackWrapper: theme => ({
    root: {
      position: 'relative',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: 275,
      height: 244,
      margin: '0 auto',
    },
  }),

  Field: () => ({
    root: {
      position: 'relative',
      width: '100%',
      backgroundColor: '#AAE46C',
    },
  }),

  Flag: theme => ({
    root: {
      position: 'absolute',
      top: 0,
      left: '50%',
      marginLeft: -4,
      marginTop: theme.spacing(2),
      transform: 'translateY(-100%)',
      height: 48,
    },
  }),

  // Shadows: () => ({
  //   root: {
  //     zIndex: 1,
  //     position: 'absolute',
  //     bottom: 0,
  //     height: 150,
  //     left: '-10%',
  //     width: '120%',

  //     transform: 'skewX(-15deg) translateX(100px)',

  //     '& > .Cloud': {
  //       flexShrink: 0,
  //     },

  //     '& > .Cloud > path': {
  //       fill: 'rgba(0,0,0,0.06)',
  //     },
  //   },
  // }),

  Sky: () => ({
    root: {
      position: 'relative',
      backgroundColor: '#DAF7FF',
      height: 150,
      left: '-10%',
      width: '120%',

      '& > .Cloud': {
        flexShrink: 0,
      },
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);
const Field = createWithStyles(styles.Field)(ArcView);
const Flag = createWithStyles(styles.Flag)(ArcView);
const TrackWrapper = createWithStyles(styles.TrackWrapper)(ArcView);
// const Shadows = createWithStyles(styles.Shadows)(ArcView);
const Sky = createWithStyles(styles.Sky)(ArcView);

const ArcCircuit = ({ scores, goal }) => (
  <Container>
    <Sky row>
      <ArcClouds />
    </Sky>
    <Field>
      <TrackWrapper>
        <Flag
          style={{ position: 'absolute', top: -16, left: 120 }}
        >
          <FinishFlag
            width="56"
            height="56"
          />
        </Flag>
        <Spectators
          style={{ position: 'absolute', top: -36, left: 16 }}
        />
        <RaceCircuit />
        <RaceArrow
          style={{ position: 'absolute', transform: 'rotate(180deg)', top: 224, left: 124 }}
        />
        <RaceArrow
          style={{ position: 'absolute', transform: 'rotate(76deg)', top: 104, left: 228 }}
        />
        <RaceArrow
          style={{ position: 'absolute', transform: 'rotate(284deg)', top: 104, left: 22 }}
        />
        <Grass
          style={{ position: 'absolute', top: 8, left: 8 }}
          width="16"
          height="16"
        />
        <Grass
          style={{ position: 'absolute', top: -15, left: -15 }}
          width="16"
          height="16"
        />
        <Grass
          style={{ position: 'absolute', top: -6, left: 32 }}
          width="16"
          height="16"
        />
        <Grass
          style={{ position: 'absolute', top: 2, left: 250 }}
          width="16"
          height="16"
        />
        <Grass
          style={{ position: 'absolute', top: -12, left: 220 }}
          width="16"
          height="16"
        />
        <Grass
          style={{ position: 'absolute', top: -14, left: 270 }}
          width="16"
          height="16"
        />
        { !!scores && (
          <PositionedPlayers
            slots={positions}
            scores={scores}
            goal={goal}
          />
        )}

      </TrackWrapper>
    </Field>
  </Container>
);
ArcCircuit.propTypes = displaysScoresType;

export default ArcCircuit;
