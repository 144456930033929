import { createReducer } from 'arcade-frontend-ui';
import { getStateById } from 'arcade-frontend-ui/src/helpers/utils/reducer';
import { createSelector } from 'reselect';

import { types } from '../actions/uploadManager';

const initialState = {
  byId: {},
  results: [],
};

const handleSearchReset = () => initialState;

const handleSearchStartSuccess = (state, action) => {
  return {
    ...state,
    byId: getStateById(action.payload.data),
    results: action.payload.data?.map(result => result.id),
  };
};

const handlers = {
  [types.UPLOAD_MANAGER_SEARCH_UPLOADS.SUCCESS]: handleSearchStartSuccess,
  [types.UPLOAD_MANAGER_RESET_SEARCH]: handleSearchReset,
};

export default createReducer(initialState, handlers);

export const getSearchResultsById = state => state.search.byId;

export const getSearchResults = createSelector(
  getSearchResultsById,
  state => state.search.results,
  (byId, results) => results?.map(id => byId[id]),
);

export const searchResults = state => state.search.results;
export const searchResultsUpdating = state => state.search.loading;
