import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getCurrentUser, getCurrentUserEndpoint } from 'arcade-frontend-core/src/reducers/user';

import { setRollbar } from '../../helpers/utils/resource';

import Rollbar from './client';

setRollbar(Rollbar);

class RollbarProvider extends React.PureComponent {
  static propTypes = {
    currentUser: PropTypes.shape({
      id: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
    }),
    currentUserEndpoint: PropTypes.string,
  };

  static defaultProps = {
    currentUser: {},
    currentUserEndpoint: '',
  };

  static displayName = 'RollbarProvider';

  constructor(props) {
    super(props);
    this.setup();
  }

  componentDidUpdate(prevProps) {
    const shouldUpdate = prevProps.currentUser.id !== this.props.currentUser.id
      || prevProps.currentUser.email !== this.props.currentUser.email
      || prevProps.currentUser.firstname !== this.props.currentUser.firstname
      || prevProps.currentUser.lastname !== this.props.currentUser.lastname;

    if (shouldUpdate) {
      this.destroy();
      this.setup();
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  setup() {
    const { currentUserEndpoint } = this.props;
    const { id, firstname, lastname, email } = this.props.currentUser;

    if (id && firstname && lastname && email) {
      const fullName = `${firstname} ${lastname}`;

      const endpointName = currentUserEndpoint
        ? currentUserEndpoint.replace('https://', '').replace('.arcadehub.co', '')
        : 'NO_ENDPOINT';

      const uuid = `${endpointName}#${id}`;

      Rollbar.setPerson(uuid, fullName, email);
    }
  }

  destroy() { // eslint-disable-line class-methods-use-this
    Rollbar.clearPerson();
  }

  render() {
    return null;
  }
}

const getState = state => ({
  currentUser: getCurrentUser(state),
  currentUserEndpoint: getCurrentUserEndpoint(state),
});

export default compose(
  connect(getState),
)(RollbarProvider);
