import humps from 'humps';
import { ARCADE_LOGIN_PROXY_URL } from 'react-native-dotenv';
import packageJson from '../../../../../package.json';
import createResourceAction from '../../helpers/createResourceAction';
import getEndpointsByEmail from '../../helpers/getEndpointsByEmail';
import { fromPermissionsAsArray, fromUserProfile } from '../normalizers';

const LOGIN_PROXY_URL =
  ARCADE_LOGIN_PROXY_URL ||
  process.env.ARCADE_LOGIN_PROXY_URL ||
  'https://login-proxy.arcade.co';

export const apiPostUserChests = createResourceAction('update', {
  resourceType: 'userChests',
  requestKey: 'apiPostUserChests',

  api: payload => ({
    method: 'POST',
    url: '/v4/chests',
    data: payload.resources[0],
  }),
});

export const apiPostUserPasswordReset = createResourceAction('create', {
  resourceType: 'user',
  requestKey: 'apiPostUserPasswordReset',

  api: payload => {
    const data = payload.resources[0];

    return {
      method: 'POST',
      baseURL: LOGIN_PROXY_URL,
      url: '/v4/passwords/reset',
      data,
      transformResponse: resp => ({
        resources: [resp],
      }),
    };
  },
});

export const apiPostUserSignIn = createResourceAction('create', {
  resourceType: 'user',
  requestKey: 'apiPostUserSignIn',

  api: (payload, axiosConfig = {}) => ({
    method: 'POST',
    url: '/users/sign_in.json',
    baseURL: axiosConfig.baseURL || LOGIN_PROXY_URL,
    data: payload.resources[0],
    transformResponse: data => {
      if (data.error) {
        return data;
      }

      const normalizedUser = humps.camelizeKeys(data);

      return {
        resources: [
          {
            ...normalizedUser,
            id: normalizedUser.id ? normalizedUser.id.toString() : '',
            entityId: normalizedUser.entityId
              ? normalizedUser.entityId.toString()
              : '',
            teamId: normalizedUser.teamId
              ? normalizedUser.teamId.toString()
              : '',
            token: normalizedUser.authenticationToken,
            features: data.features,
          },
        ],
      };
    },
  }),
});

export const apiDeleteUserSignOut = createResourceAction('delete', {
  resourceType: 'user',
  requestKey: 'apiDeleteUserSignOut',

  api: () => ({
    method: 'DELETE',
    url: '/users/sign_out.json',
  }),
});

export const apiPostUserTokens = createResourceAction('update', {
  resourceType: 'userTokens',
  requestKey: 'apiPostUserTokens',

  api: payload => ({
    method: 'POST',
    url: '/manager/v4/credits/award',
    data: payload.resources[0],
  }),
});

export const apiGetEscrowableFunds = createResourceAction('read', {
  resourceType: 'userEscrowableFunds',
  requestKey: 'apiGetEscrowableFunds',

  api: () => ({
    method: 'GET',
    url: '/manager/v4/campaigns/escrowable_funds',
    transformResponse: data => ({
      resources: [
        {
          ...data,
          balance: data.balance || 0,
        },
      ],
    }),
  }),
});

export const apiGetCurrentUserPermissions = createResourceAction('read', {
  resourceType: 'currentUserPermissions',
  requestKey: 'apiGetCurrentUserPermissions',

  api: payload => {
    const { source } = payload.resources[0];

    return {
      method: 'GET',
      url: '/user/permissions',
      params: {
        source,
        version: packageJson.version,
      },
      transformResponse: data => (data ? Object.values(data.users) : {}),
    };
  },
});

export const apiGetUserFeatures = createResourceAction('read', {
  resourceType: 'userPermissions',
  requestKey: 'apiGetUserFeatures',

  api: payload => {
    const { source } = payload.resources[0];

    return {
      method: 'GET',
      url: '/v4/features',
      params: {
        source,
        version: '4.8.7',
      },
      transformResponse: [data => data],
    };
  },
});

export const apiGetUserPermissions = createResourceAction('read', {
  resourceType: 'userPermissions',
  requestKey: 'apiGetUserPermissions',

  api: payload => {
    const { id } = payload.resources[0];

    return {
      method: 'GET',
      url: `/manager/v4/users/${id}/permissions/as_array`,
      transformResponse: data => ({
        resources: [fromPermissionsAsArray(data)],
      }),
    };
  },
});

export const apiPutUserPermissions = createResourceAction('update', {
  resourceType: 'userPermissions',
  requestKey: 'apiPutUserPermissions',

  api: payload => {
    const { id, permissions } = payload.resources[0];
    return {
      method: 'PUT',
      url: `/manager/v4/users/${id}/permissions`,
      data: { simple_permissions: permissions },
    };
  },
});

export const apiGetUserProfile = createResourceAction('read', {
  resourceType: 'userProfile',
  requestKey: 'apiGetUserProfile',
  api: payload => {
    const { id } = payload.resources[0];

    return {
      method: 'GET',
      url: `/manager/v4/users/${id}`,
      transformResponse: data => ({
        resources: [fromUserProfile(data)],
      }),
    };
  },
});

export const apiPutUserProfile = createResourceAction('update', {
  resourceType: 'userProfile',
  requestKey: 'apiPutUserProfile',

  api: payload => {
    const { id, user } = payload.resources[0];
    return {
      method: 'PUT',
      url: `/manager/v4/users/${id}`,
      data: {
        user: {
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          weekly_token_allowance: user.recognition_star_count,
          company_identifier: user.company_identifier
            ? user.company_identifier.id
            : '',
        },
      },
    };
  },
});

export const apiPutUserResetPassword = createResourceAction('update', {
  resourceType: 'userResetPassword',
  requestKey: 'apiPutUserResetPassword',

  api: payload => {
    const { id } = payload.resources[0];
    return {
      method: 'PUT',
      url: `/manager/users/${id}/resend_welcome_email`,
    };
  },
});

export const apiPutUserLockAccount = createResourceAction('update', {
  resourceType: 'userLockAccount',
  requestKey: 'apiPutUserLockAccount',

  api: payload => {
    const { id } = payload.resources[0];
    return {
      method: 'PUT',
      url: `/manager/v4/users/${id}/lock`,
    };
  },
});

export const apiPutUserUnlockAccount = createResourceAction('update', {
  resourceType: 'userUnlockAccount',
  requestKey: 'apiPutUserUnlockAccount',

  api: payload => {
    const { id } = payload.resources[0];
    return {
      method: 'PUT',
      url: `/manager/v4/users/${id}/unlock`,
    };
  },
});

export const apiPutUpdateUserTeam = createResourceAction('update', {
  resourceType: 'userTeam',
  requestKey: 'apiPutUpdateUserTeam',

  api: payload => {
    const { primaryId, secondaryIds, userId } = payload.resources[0];
    return {
      method: 'PUT',
      url: `manager/v4/users/${userId}/teams/update_assignments`,
      data: {
        team: {
          primary_id: primaryId,
          secondary_ids: secondaryIds,
        },
      },
    };
  },
});

export const apiPostUserInvite = createResourceAction('create', {
  resourceType: 'userInvite',
  requestKey: 'apiPostUserInvite',

  api: payload => {
    const { user } = payload.resources[0];

    return {
      method: 'POST',
      url: '/v2/users',
      // url: '/manager/v5/users',
      data: {
        user: {
          firstname: user.firstName,
          lastname: user.lastName,
          email: user.email,
          team_id: user.teamId,
          company_identifier: user.companyIdentifier,
        },
      },
    };
  },
});

export const apiGetCompanyIdentifierExplainer = createResourceAction('read', {
  resourceType: 'explainers',
  requestKey: 'apiGetCompanyIdentifierExplainer',

  api: () => ({
    method: 'GET',
    url: 'manager/v4/users/company_identifier_information/',
    transformResponse: data => ({
      resources: data,
    }),
  }),
});
