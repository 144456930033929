import { createRequestingStatusReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/sales';

const initialState = {
  MANAGE_SALES_CREATE: 'DEFAULT',
  MANAGE_SALES_UPDATE: 'DEFAULT',
};

const actions = [
  types.MANAGE_SALES_CREATE,
  types.MANAGE_SALES_UPDATE,
];

const requestStatus = createRequestingStatusReducer(initialState, actions);

export default requestStatus;

export const getRequestStatus = state => state.manage.sales.requestStatus;
