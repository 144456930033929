import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import CalendarToday from '@material-ui/icons/CalendarToday';

import { ArcButton, ArcFormField, ArcView, ArcText } from 'arcade-frontend-ui';

const subtitleStyle = {
  fontSize: '1rem',
  fontWeight: '400',
};

const getTodayYearsAgo = years => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - years);

  return date;
};

class ArcProfileFormStepAbout extends React.PureComponent {
  static propTypes = {
    touched: PropTypes.shape({
      birthday: PropTypes.bool,
    }).isRequired,
    values: PropTypes.shape({
      birthday: PropTypes.string,
    }).isRequired,
  };

  state = {
    openCalendar: false,
  };

  get hasDate() {
    return !!this.props.values.birthday;
  }

  setOpenCalendar = openCalendar => this.setState({ openCalendar });

  maxDate = getTodayYearsAgo(13);

  handleOpenCalendar = () => this.setOpenCalendar(true);

  render() {
    return (
      <ArcView>
        <ArcView color="blue" padding="24">
          <ArcView marginTop="16">
            <Typography variant="h4">
              <ArcText color="white">{'About You'}</ArcText>
            </Typography>
          </ArcView>
        </ArcView>
        <ArcView padding="24">
          <ArcView marginBottom="16">
            <ArcView marginBottom="16">
              <Typography variant="h5">{'Add your birthday'}</Typography>
              <Typography variant="caption" style={subtitleStyle}>
                {'Arcade will help celebrate you on your day.'}
              </Typography>
            </ArcView>

            {this.hasDate || this.state.openCalendar ? (
              <ArcView className="animated fadeIn">
                <ArcFormField
                  name="birthday"
                  type="datetime"
                  label="Birthday"
                  validations={{
                    isRequired: true,
                  }}
                  initialDate={this.maxDate}
                  maxDate={this.maxDate}
                  mode="permanent"
                  hideTime
                />
              </ArcView>
            ) : (
              <ArcView>
                <ArcView marginBottom="16" align="flex-start">
                  <ArcButton
                    variant="outlined"
                    color="secondary"
                    onClick={this.handleOpenCalendar}
                    label="Select Date"
                  >
                    <CalendarToday />
                    <ArcView marginLeft="8">{'Select Date'}</ArcView>
                  </ArcButton>
                </ArcView>

                {this.props.touched.birthday && (
                  <ArcView marginTop="16">
                    <ArcText color="danger">{'Birthday is required'}</ArcText>
                  </ArcView>
                )}
              </ArcView>
            )}
          </ArcView>
        </ArcView>
      </ArcView>
    );
  }
}

export default ArcProfileFormStepAbout;
