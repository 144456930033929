import React from 'react';
import PropTypes from 'prop-types';

// import Url from 'url-parse';

import createWithStyles from '../../styles/createWithStyles';
import ArcView from '../../primitives/ArcView';
import ArcLinkPreview from './ArcLinkPreview';
import { videoLinkedText } from '../../helpers/utils/parsers';
import { unfurling } from '../../helpers/utils/unfurling';

// const DOMAIN_WHITELIST = [
//   'vimeo.com',
//   'www.vimeo.com',
//   'youtube.com',
//   'www.youtube.com',
//   'youtu.be',
// ];

// const whitelistURLS = urls => urls.filter((url) => {
//   const { hostname } = new Url(url);
//   return DOMAIN_WHITELIST.filter(test => hostname === test).length > 0;
// });


const styles = {
  PreviewList: theme => ({
    root: {
      '& > div:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
  }),
};

const PreviewList = createWithStyles(styles.PreviewList)(ArcView);

const previews = { };

const promiseForLink = (link) => {
  if (previews[link]) {
    return previews[link].promise;
  }
  const promise = unfurling(link)
    .then((response) => {
      previews[link].data = response.data;
      return link;
    })
    .catch(global.noop);

  previews[link] = { promise };
  return promise;
};


class ArcLinkPreviewList extends React.Component {
  state = { previewLinks: null }

  componentDidMount() {
    const { text } = this.props;

    if (text) {
      this.textToLinks(text);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.text !== prevProps.text) {
      this.textToLinks(this.props.text);
    }
  }

  textToLinks = (text) => {
    const urls = videoLinkedText(text);

    if (!urls) {
      return;
    }

    // const links = whitelistURLS(urls);
    const links = urls;

    const promises = links.map(promiseForLink);

    Promise.all(promises).then((responses) => {
      const previewLinks = responses && responses.filter(r => r);
      this.setState({ previewLinks });
    });
  }

  render() {
    const { previewLinks } = this.state;

    if (previewLinks && previewLinks.length > 0) {
      return (
        <PreviewList style={{ width: '100%' }}>
          {previewLinks.map(link => (
            <ArcLinkPreview
              key={link}
              preview={previews[link].data}
              elevation={this.props.elevation}
            />
          ))}
        </PreviewList>
      );
    }

    return null;
  }
}

ArcLinkPreviewList.propTypes = {
  text: PropTypes.string,
  elevation: PropTypes.number,
};

ArcLinkPreviewList.defaultProps = {
  text: '',
  elevation: 1,
};

export default ArcLinkPreviewList;
