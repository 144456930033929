import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathToAction } from 'redux-first-router';
import queryString from 'query-string';
import humps from 'humps';
import axios from 'axios';

import { useArcUser } from 'arcade-frontend-auth';
import * as auth from 'arcade-frontend-core/src/helpers/auth';
import getResource from 'arcade-frontend-core/src/helpers/getResource';
import { removeBootLogo } from 'arcade-frontend-core/src/helpers/removeBootLogo';

import { resources } from 'arcade-frontend-core/src/resources/users';
import { setResource } from 'arcade-frontend-ui/src/helpers/utils/resource';
import {
  getLocation,
  getLocationPayload,
} from 'arcade-frontend-features/src/reducers/location';
import navigateToEntryRoute from 'arcade-frontend-core/src/helpers/navigateToEntryRoute';
import arcPostMessage from 'arcade-frontend-core/src/helpers/arcPostMessage';
import { actions } from 'arcade-frontend-core/src/actions';

const delay = (timeout, error) =>
  new Promise((resolve, reject) => setTimeout(() => reject(error), timeout));

export const useLogin = () => {
  const { encodedObject } = useSelector(getLocationPayload) as any;
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { login } = useArcUser();
  const dispatch = useDispatch();
  const location = useSelector(getLocation) as any;
  const tsHappyWindow = window as any;

  const handleLoginSuccess = data => {
    const rawFeatures = { ...data.features };
    const nextUser = humps.camelizeKeys(data) as any;
    nextUser.features = rawFeatures;
    nextUser.token = nextUser.authenticationToken;

    setResource(nextUser.endpoint);

    const action = actions.setCurrentUser({
      currentUser: nextUser,
      endpoint: nextUser.endpoint,
      chatEndpoint: nextUser.endpointChat,
    });

    dispatch(action);
    arcPostMessage(`rw.dispatch.${JSON.stringify(action)}`);

    return auth.signIn(nextUser).then(() => {
      login(nextUser);
      const source = global.Platform.select({
        web: 'launch',
        default: 'app',
      });

      const featuresRequest = getResource(
        resources.apiGetUserFeatures,
        { resources: [{ source }] },
        {
          baseURL: nextUser.endpoint,
          transformResponse: [],
        },
      ).then(response => {
        if (tsHappyWindow?.setArcadeFeatures) {
          tsHappyWindow.setArcadeFeatures(response.data.features);
        }

        dispatch(actions.appFeaturesSuccess(response));
      });
      const permissionsRequest = getResource(
        resources.apiGetCurrentUserPermissions,
        { resources: [{ source }] },
        {
          baseURL: nextUser.endpoint,
          transformResponse: [],
        },
      ).then(response => {
        dispatch(actions.appPermissionsSuccess(response));
      });

      return Promise.all([featuresRequest, permissionsRequest])
        .then(() => {
          if (location?.query?.redirectTo) {
            const nextRoute = pathToAction(
              location.query.redirectTo,
              location.routesMap,
              queryString,
            );
            dispatch(nextRoute);
          } else {
            navigateToEntryRoute(location, dispatch);
          }
        })
        .catch(error => {
          console.error(error);
          if (tsHappyWindow?.arcPostMessage) {
            tsHappyWindow.arcPostMessage('native.appReady');
          }
        });
    });
  };

  const loginViaLink = async () => {
    if (isLoggingIn) {
      return;
    }

    try {
      setIsLoggingIn(true);

      const { email, token, endpoint } = JSON.parse(atob(encodedObject));
      const baseURL =
        endpoint ||
        process.env.ARCADE_LOGIN_PROXY_URL ||
        'https://login-proxy.arcade.co';

      await Promise.race([
        axios({
          method: 'POST',
          baseURL,
          url: '/users/sign_in.json',
          data: {
            user: {},
          },
          headers: {
            'X-User-Token': token,
            'X-User-Email': email,
          },
        }).then(resp => handleLoginSuccess(resp.data)),
        delay(30 * 1000, new Error('Timeout Error')),
      ]);
    } catch (error) {
      throw error;
    } finally {
      setIsLoggingIn(false);
      removeBootLogo();
    }
  };

  return {
    encodedObject,
    handleLoginSuccess,
    loginViaLink,
  };
};
