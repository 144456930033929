import React from 'react';
import PropTypes from 'prop-types';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import ArcResourceErrorDialog from 'arcade-frontend-ui/src/components/ArcResourceErrorDialog';
import ArcResourceSuccessDialog from 'arcade-frontend-ui/src/components/ArcResourceSuccessDialog';
import { resources } from 'arcade-frontend-core/src/resources/profile';
import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';

import ProfileEditForm from '../../components/ProfileEditForm';

function ProfileEditFormContainer({ onClose, open, ...props }) {
  const apiGetProfile = useResourceAction(resources.apiGetProfile);
  const apiPutProfile = useResourceAction(resources.apiPutProfile);

  React.useEffect(() => {
    if (!props.userId) {
      return;
    }

    // We only want to make this request if the current route is the profile
    if (
      [ROUTE_TYPES.ROUTE_PROFILE, ROUTE_TYPES.ROUTE_PROFILE_EDIT].indexOf(
        props.currentLocation,
      ) === -1
    ) {
      return;
    }

    apiGetProfile
      .requestData({
        resources: [props.userId],
      })
      .then(apiGetProfile.onStatusReset);
  }, [props.userId, props.currentLocation]);

  function handleSuccessClose() {
    apiPutProfile.onStatusReset();
    onClose();
  }

  function handleSubmit(values, formikBag) {
    apiPutProfile
      .requestData({ resources: [values] })
      .then(() => {
        formikBag.resetForm();
      })
      .catch(global.noop)
      .finally(() => {
        formikBag.setSubmitting(false);
      });
  }

  if (!apiGetProfile.hasData) {
    return <ProfileEditForm.Placeholder onClose={onClose} open={open} />;
  }

  return (
    <>
      <ProfileEditForm
        initialValues={apiGetProfile.data.resources[0]}
        isPending={apiPutProfile.isPending}
        onSubmit={handleSubmit}
        onClose={onClose}
        open={open}
        status={apiPutProfile.status}
        {...props}
      />
      <ArcResourceErrorDialog
        open={apiPutProfile.hasFailed}
        error={apiPutProfile.error}
        onClose={apiPutProfile.onStatusReset}
      />
      <ArcResourceSuccessDialog
        open={apiPutProfile.hasSucceeded}
        onClose={handleSuccessClose}
      >
        {'Your profile has been updated.'}
      </ArcResourceSuccessDialog>
    </>
  );
}

ProfileEditFormContainer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  userId: PropTypes.string,
  currentLocation: PropTypes.string,
};

ProfileEditFormContainer.defaultProps = {
  onClose: global.noop,
  open: false,
  userId: '',
  currentLocation: '',
};

export default ProfileEditFormContainer;
