import React from 'react';
import PropTypes from 'prop-types';
import { Platform } from 'react-primitives';

import ArcButton from '../../elements/ArcButton';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';

import createWithStyles from '../../styles/createWithStyles';

import ArcEntityAvatar from '../ArcEntityAvatar';

const nameTextStyle = {
  overflow: 'hidden',
  flexGrow: 1,
  maxWidth: '100%',
  minWidth: 0,
  flexShrink: 100,
  whiteSpace: 'nowrap',
  textAlign: 'left',
  textOverflow: 'ellipsis',
  display: 'block',
  lineHeight: 1.15,
};

const teamNameTextStyle = {
  fontSize: 9,
};

const styles = {
  ArcEntityView: theme => ({
    root: {
      overflow: 'hidden',
      ...theme.typography.button,
      textTransform: Platform.OS === 'web' ? 'initial' : undefined,
      fontWeight: Platform.OS === 'web' ? 'normal' : undefined,
      color: theme.palette.text.primary,
      letterSpacing: 0.25,
      userSelect: 'none',
      flexShrink: 1,
    },
  }),
  ArcEntityButton: theme => ({
    root: {
      overflow: 'hidden',
      padding: 8,
      textTransform: Platform.OS === 'web' ? 'initial' : undefined,
      fontWeight: Platform.OS === 'web' ? 'normal' : undefined,
      color: theme.palette.text.primary,
      userSelect: 'none',
    },

    padding0: {
      padding: 0,
    },
  }),
};

const ArcEntityView = createWithStyles(styles.ArcEntityView)(ArcView);
const ArcEntityButton = createWithStyles(styles.ArcEntityButton)(ArcButton);

class ArcEntity extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.func,
    padding: PropTypes.string,
    teamName: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    children: null,
    disabled: false,
    name: '',
    onClick: null,
    padding: '8',
    teamName: '',
    style: {},
  };

  static TYPES = ArcEntityAvatar.TYPES;

  render() {
    const {
      disabled,
      name,
      onClick,
      padding,
      teamName,
      style,
      ...rest
    } = this.props;

    const nameText = teamName ? (
      <ArcView marginLeft="8" style={nameTextStyle}>
        <ArcView>
          {name}
          <ArcText style={teamNameTextStyle}>{teamName}</ArcText>
        </ArcView>
      </ArcView>
    ) : (
      <ArcView marginLeft="8" style={nameTextStyle}>
        {name}
      </ArcView>
    );

    const children = (
      <React.Fragment>
        <ArcEntityAvatar
          {...rest}
        />
        {(!!name || !!teamName) && nameText}
        {!!this.props.children && (
          <React.Fragment>
            <ArcView spacer />
            <ArcView marginLeft="8">
              {this.props.children}
            </ArcView>
          </React.Fragment>
        )}
      </React.Fragment>
    );

    if (onClick) {
      return (
        <ArcEntityButton
          fullWidth
          onClick={disabled ? undefined : onClick}
          isDisabled={disabled}
          padding={padding}
          style={style}
        >
          {children}
        </ArcEntityButton>
      );
    }

    return (
      <ArcEntityView
        align="center"
        row
        padding={padding}
      >
        {children}
      </ArcEntityView>
    );
  }
}

export default ArcEntity;
