import * as types from './types';

export const apiManageTokensIndexRequest = () => ({
  type: types.MANAGE_TOKENS_INDEX.REQUEST,
  payload: {},
});

export const apiManageTokensTransferRequest = payload => ({
  type: types.MANAGE_TOKENS_TRANSFER.REQUEST,
  payload,
});

export const apiManageTokensTopUpRequest = payload => ({
  type: types.MANAGE_TOKENS_TOP_UP.REQUEST,
  payload,
});

export const manageTokensSetTopUpUrl = payload => ({
  type: types.MANAGE_TOKENS_SET_TOP_UP_URL,
  payload,
});

export const manageTokensSetHasToppedUp = payload => ({
  type: types.MANAGE_TOKENS_SET_HAS_TOPPED_UP,
  payload,
});

export const manageTokensSetTopUpResponse = payload => ({
  type: types.MANAGE_TOKENS_SET_TOP_UP_RESPONSE,
  payload,
});

export const manageTokensSetTopUpError = payload => ({
  type: types.MANAGE_TOKENS_SET_TOP_UP_ERROR,
  payload,
});
