import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const initialState = [];

const handleRewardsShopIndexSuccess = (state, action) => action.payload.data;

const handlers = {
  [types.REWARDS_SHOP_INDEX.SUCCESS]: handleRewardsShopIndexSuccess,
};

const products = createReducer(initialState, handlers);

export const getProducts = state => state.rewards.products.rewards;
export const getDisclaimer = state => state.rewards.products.disclaimer;

export default products;
