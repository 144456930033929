import { createSelector } from 'reselect';

import { createReducer } from 'arcade-frontend-ui';
import { types as uploadTypes } from 'arcade-frontend-widgets/src/actions/uploadManager';

import { selectors as activitySelectors } from './activities';
import { types } from '../actions';

const initialState = {
  currentlyEditingId: null,
  attachedVideos: [],
  attachedFiles: [],
};

const handleEditActivity = (state, action) => ({
  ...state,
  currentlyEditingId: action.payload.id,
  attachedVideos: action.payload.video
    ? [action.payload.video]
    : state.attachedVideos,
});

const handleActivityDone = state => ({
  ...state,
  currentlyEditingId: null,
  attachedVideos: [],
  attachedFiles: [],
});

const handleAttachingVideo = (state, action) => {
  const { payload } = action;
  const { selectedFile, source } = payload;
  if (source !== 'activity-editor') {
    return state;
  }

  if (selectedFile.media_type.toLowerCase() === 'document') {
    return {
      ...state,
      attachedFiles: [...state.attachedFiles, selectedFile],
    };
  }

  return {
    ...state,
    attachedVideos: [selectedFile],
  };
};

const handleClearingAttachedVideos = (state, action) => ({
  ...state,
  attachedFiles: state.attachedFiles.filter(
    item => item.name !== action.payload.name,
  ),
  attachedVideos: state.attachedVideos.filter(
    item => item.name !== action.payload.name,
  ),
});

const handlers = {
  [types.NEWSFEED_EDIT_ACTIVITY]: handleEditActivity,
  [types.NEWSFEED_EDIT_ACTIVITY_CANCEL]: handleActivityDone,
  [types.NEWSFEED_CREATE_ACTIVITY.SUCCESS]: handleActivityDone,
  [types.NEWSFEED_UPDATE_ACTIVITY.RESET]: handleActivityDone,
  [uploadTypes.UPLOAD_MANAGER_FILE_SELECTED]: handleAttachingVideo,
  [types.NEWSFEED_CLEAR_ATTACHED_VIDEOS]: handleClearingAttachedVideos,
};

export default createReducer(initialState, handlers);

const currentlyEditingId = state => state.newsfeed.editing.currentlyEditingId;

const currentlyEditing = createSelector(
  activitySelectors.activitiesById,
  currentlyEditingId,
  (activitiesById, id) => activitiesById[id],
);

export const selectors = {
  currentlyEditing,
  currentlyEditingId,
  attachedFiles: state => state.newsfeed.editing.attachedFiles,
  attachedVideos: state => state.newsfeed.editing.attachedVideos,
};
