import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const GameController = props => (
  <Icon {...props}>
    <Path
      d="M16.7 7.31v0h.003a5.85 5.85 0 015.023 2.843 5.496 5.496 0 010 5.623c-1.032 1.742-2.946 2.822-5.023 2.822H16.7a5.853 5.853 0 01-3.53-1.168l-.002-.002a1.984 1.984 0 00-2.363 0l.299.401-.297-.402a5.934 5.934 0 01-6.848.122c-2.063-1.412-2.945-3.954-2.196-6.285.75-2.334 2.97-3.94 5.5-3.954h.001a5.798 5.798 0 013.538 1.167h0l.004.003c.195.145.419.249.656.305a.5.5 0 00.525-.199.501.501 0 00.524.2c.237-.057.46-.16.656-.306h0l.004-.003A5.786 5.786 0 0116.7 7.31z"
      fill="transparent"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <Path
      d="M14.751 12.164a2.103 2.103 0 011.95-1.254c1.169.005 2.092.926 2.092 2.033 0 .816-.505 1.56-1.295 1.878-.791.318-1.7.139-2.3-.447a1.985 1.985 0 01-.447-2.21z"
      fill="transparent"
      stroke="currentColor"
      strokeWidth=".987"
    />
    <mask id="a" fill="transparent">
      <Path
        d="M9.576 12.587H7.542v-2.024a.404.404 0 00-.41-.398.404.404 0 00-.409.398v2.024H4.69a.404.404 0 00-.41.398c0 .22.184.399.41.399h2.034v1.981c0 .22.184.399.41.399.225 0 .409-.179.409-.399v-2.023h2.034c.226 0 .41-.179.41-.399 0-.22-.184-.356-.41-.356z"
      />
    </mask>
    <Path
      d="M9.576 12.587H7.542v-2.024a.404.404 0 00-.41-.398.404.404 0 00-.409.398v2.024H4.69a.404.404 0 00-.41.398c0 .22.184.399.41.399h2.034v1.981c0 .22.184.399.41.399.225 0 .409-.179.409-.399v-2.023h2.034c.226 0 .41-.179.41-.399 0-.22-.184-.356-.41-.356z"
      stroke="currentColor"
      fill="transparent"
    />
    <Path
      d="M7.542 12.587h-.987v.987h.987v-.987zm0-2.024h-.987.987zm-.819 2.024v.987h.987v-.987h-.987zm-2.034 0V11.6v.987zm0 .797v.986-.986zm2.034 0h.987v-.987h-.987v.987zm0 1.981h-.986.986zm.819-2.023v-.987h-.987v.987h.987zM9.576 11.6H7.542v1.974h2.034V11.6zm-1.048.987v-2.024H6.555v2.024h1.973zm0-2.024c0-.79-.65-1.385-1.395-1.385v1.974a.583.583 0 01-.578-.589h1.973zM7.133 9.178a1.39 1.39 0 00-1.396 1.385H7.71c0 .35-.283.589-.577.589V9.178zm-1.396 1.385v2.024H7.71v-2.024H5.737zm.986 1.037H4.69v1.974h2.034V11.6zm-2.034 0a1.39 1.39 0 00-1.396 1.385h1.974c0 .35-.284.589-.578.589V11.6zm-1.396 1.385c0 .79.65 1.386 1.396 1.386v-1.974c.294 0 .578.239.578.588H3.293zm1.396 1.386h2.034v-1.974H4.69v1.973zm1.048-.987v1.981H7.71v-1.981H5.737zm0 1.981c0 .79.65 1.385 1.396 1.385v-1.973c.294 0 .577.239.577.588H5.737zm1.396 1.385a1.39 1.39 0 001.395-1.385H6.555c0-.35.283-.588.578-.588v1.973zm1.395-1.385v-2.023H6.555v2.023h1.973zm-.986-1.037h2.034v-1.973H7.542v1.973zm2.034 0a1.39 1.39 0 001.396-1.385H8.998c0-.349.284-.588.578-.588v1.973zm1.396-1.385c0-.408-.18-.773-.475-1.019a1.435 1.435 0 00-.92-.324v1.974a.54.54 0 01-.343-.133.657.657 0 01-.236-.498h1.974z"
      fill="currentColor"
      stroke="currentColor"
      mask="url(#a)"
    />
  </Icon>
);

export default GameController;
