import React from 'react';
import { Platform } from 'react-native';
import { Card } from 'react-native-paper';

import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcMarkdownText from 'arcade-frontend-ui/src/components/ArcMarkdownText';
// @ts-ignore
import ArcTextV2 from 'arcade-frontend-ui/src/components/ArcTextV2';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
// @ts-ignore
import ArcIconButton from 'arcade-frontend-ui/src/elements/ArcIconButton';
import Close from 'arcade-frontend-ui/src/icons/Close';

import { NotificationsPanelCardProps } from './NotificationsPanelCard.d';
import NotificationsPanelCardPlaceholder from './NotificationsPanelCardPlaceholder/NotificationsPanelCardPlaceholder';

const PlaceholderLeftComponent = () => (
  <ArcViewBox width={48} height={48} backgroundColor="#ccc" borderRadius={48} />
);

const styles = {
  titleContainer: {
    minHeight: 66,
  },

  title: {
    fontSize: 16,
    lineHeight: 24,
    minHeight: 24,
  },

  subtitle: {
    color: '#B4B4B4',
    letterSpacing: 0,
  },

  left: {
    marginRight: 20,
  },

  content: {
    maxWidth: 320,
  },
};

const defaultProps: NotificationsPanelCardProps = {
  content: '',
  elevation: 4,
  hasBorder: false,
  hasDismiss: false,
  header: '',
  isPending: false,
  leftComponent: PlaceholderLeftComponent,
  onLinkPress: undefined,
  onPrimaryAction: undefined,
  onSecondaryAction: undefined,
  roundedTop: true,
  roundedBottom: true,
  subtitle: '',
  title: '',
};

const NotificationsPanelCard: React.FunctionComponent<NotificationsPanelCardProps> = ({
  content,
  elevation = 4,
  hasBorder,
  hasDismiss,
  header,
  isPending,
  leftComponent: LeftComponent,
  onDismiss,
  onLinkPress,
  onPrimaryAction,
  onSecondaryAction,
  roundedTop,
  roundedBottom,
  subtitle,
  title,
  ...props
}) => {
  if (isPending) {
    return (
      <NotificationsPanelCardPlaceholder
        header={!!header}
        elevation={elevation}
        roundedTop={roundedTop}
        roundedBottom={roundedBottom}
      />
    );
  }

  return (
    <ArcViewBox {...props}>
      <Card
        elevation={elevation}
        style={{
          borderTopLeftRadius: roundedTop ? 8 : 0,
          borderTopRightRadius: roundedTop ? 8 : 0,
          borderBottomLeftRadius: roundedBottom ? 8 : 0,
          borderBottomRightRadius: roundedBottom ? 8 : 0,
          borderColor: hasBorder ? '#D8D8D8' : undefined,
          borderStyle: hasBorder ? 'solid' : undefined,
          borderWidth: hasBorder ? 1 : undefined,
        }}
      >
        {!!header && (
          <ArcViewBox px={3} py="12px">
            <ArcTextV2 fontSize={14} fontWeight={500}>
              {header}
            </ArcTextV2>
          </ArcViewBox>
        )}

        <ArcViewBox>
          {!!header && <ArcViewBox height={1} backgroundColor="#EBEBEB" />}
          <ArcViewBox flexDirection="row">
            <Card.Title
              left={LeftComponent || PlaceholderLeftComponent}
              leftStyle={styles.left}
              title={title}
              titleStyle={styles.title}
              subtitle={subtitle}
              subtitleStyle={styles.subtitle}
              style={styles.titleContainer}
            />
            <ArcViewBox flex={1000} />
            {hasDismiss && (
              <ArcViewBox ml={1} p={1}>
                <ArcIconButton
                  size={Platform.select({ web: 'small', default: 'large' })}
                  style={{ padding: 6 }}
                  onClick={onDismiss}
                >
                  <Close width="24px" height="24px" color="#b4b4b4" />
                </ArcIconButton>
              </ArcViewBox>
            )}
          </ArcViewBox>
          <Card.Content style={styles.content}>
            <ArcMarkdownText
              onLinkPress={onLinkPress}
              style={{ paragraph: { marginTop: 0 } }}
            >
              {content}
            </ArcMarkdownText>
          </Card.Content>

          <ArcViewBox
            flexDirection="row"
            flexGrow={1}
            justifyContent="flex-end"
            pb={3}
            pr={3}
          >
            <ArcButton
              color="primary"
              onClick={onSecondaryAction}
              label="View Events"
              style={{
                textTransform: 'capitalize',
                letterSpacing: 'normal',
                fontWeight: 400,
              }}
            />
            <ArcViewBox mr={2} />
            <ArcButton
              color="primary"
              variant="flat"
              onClick={onPrimaryAction}
              label="Verify Now"
              style={{
                textTransform: 'capitalize',
                letterSpacing: 'normal',
                fontWeight: 400,
              }}
            />
          </ArcViewBox>
        </ArcViewBox>
      </Card>
    </ArcViewBox>
  );
};

NotificationsPanelCard.defaultProps = defaultProps;

export default NotificationsPanelCard;
