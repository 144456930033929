import * as types from './types';

export const apiManageRewardsArchiveRequest = id => ({
  type: types.MANAGE_REWARDS_ARCHIVE.REQUEST,
  payload: {
    id,
  },
});

export const apiManageRewardsRestoreRequest = id => ({
  type: types.MANAGE_REWARDS_RESTORE.REQUEST,
  payload: {
    id,
  },
});

export const apiManageRewardsCustomArchiveRequest = id => ({
  type: types.MANAGE_REWARDS_CUSTOM_ARCHIVE.REQUEST,
  payload: {
    id,
  },
});

export const apiManageRewardsCustomRestoreRequest = id => ({
  type: types.MANAGE_REWARDS_CUSTOM_RESTORE.REQUEST,
  payload: {
    id,
  },
});

export const apiManageRewardsCreateRequest = reward => ({
  type: types.MANAGE_REWARDS_CREATE.REQUEST,
  payload: {
    reward,
  },
});

export const apiManageRewardsEditRequest = reward => ({
  type: types.MANAGE_REWARDS_EDIT.REQUEST,
  payload: {
    reward,
  },
});

export const apiManageRewardsIndexRequest = () => ({
  type: types.MANAGE_REWARDS_INDEX.REQUEST,
  payload: {
  },
});

export const apiManageRewardsCustomIndexRequest = () => ({
  type: types.MANAGE_REWARDS_CUSTOM_INDEX.REQUEST,
  payload: {
  },
});
