import React from 'react';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import { createWithStyles } from '../../styles';

const styles = {
  NumberContainer: () => ({
    root: {
      alignSelf: 'flex-end',
      flexShrink: 1,

      '& > svg path': {
        transition: 'fill-opacity 300ms cubic-bezier(0.42, 0, 0.65, 1.22)',
      },

      '& > svg': {
        width: '100%',
        // maxWidth: '40px',
      },
    },
  }),
};

const NumberContainer = createWithStyles(styles.NumberContainer)(ArcView);

const ArcScoreboardDecimal = props => (
  <NumberContainer style={{ width: props.width }} className="ArcScoreboardDecimal">
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill="#FBBB38" fillRule="nonzero" d="M6.937 11.127h5.873V17H6.937z" />
    </svg>
  </NumberContainer>
);

export default ArcScoreboardDecimal;

ArcScoreboardDecimal.propTypes = {
  width: ArcPropTypes.number,
};

ArcScoreboardDecimal.defaultProps = {
  width: 40,
};
