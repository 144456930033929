import React from 'react';
import PropTypes from 'prop-types';
import IconDateRange from '@material-ui/icons/DateRange';
import Dialog from '@material-ui/core/Dialog';

import ArcDatePicker from '../../elements/ArcDatePicker';
import ArcDateRange from '../../elements/ArcDateRange';
import ArcSelect from '../../elements/ArcSelect';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';
import * as date from '../../helpers/utils/date';

const dateTypeItems = {
  today: {
    value: 'today',
    label: 'Today',
  },
  yesterday: {
    value: 'yesterday',
    label: 'Yesterday',
  },
  pastWeek: {
    value: 'pastWeek',
    label: 'Past 7 Days',
  },

  currentWeek: {
    value: 'currentWeek',
    label: 'Current Week',
  },

  currentMonth: {
    value: 'currentMonth',
    label: 'Current Month',
  },

  dateRange: {
    value: 'dateRange',
    label: 'Date Range',
  },

  everything: {
    value: 'everything',
    label: 'Everything',
  },

  // selectedDay: {
  //   value: 'selectedDay',
  //   label: 'Selected Day',
  // },
};

const items = [
  dateTypeItems.today,
  dateTypeItems.yesterday,
  dateTypeItems.pastWeek,
  dateTypeItems.currentWeek,
  dateTypeItems.currentMonth,
  dateTypeItems.dateRange,
  // dateTypeItems.selectedDay,
];

const dateRangeSelectors = {
  currentMonth: date.beginningOfMonth,
  currentWeek: date.beginningOfWeek,
  pastWeek: date.oneWeekAgo,
  yesterday: date.removeOneDay,
  today: date.startOfDay,
  // selectedDay: date.startOfDay,
};

const getDefaultDateRange = (range, fromDate) => dateRangeSelectors[range](fromDate);

class ArcDateSelector extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    selectDisplayProps: PropTypes.shape({}),
    includeEverythingOption: PropTypes.bool,
    initialRange: PropTypes.string,
  };

  static defaultProps = {
    onChange: global.noop,
    selectDisplayProps: null,
    includeEverythingOption: false,
    initialRange: 'currentMonth',
  };

  constructor(props) {
    super(props);

    if (
      props.includeEverythingOption
      && !items.find(i => i.value === 'everything')
    ) {
      items.unshift(dateTypeItems.everything);
    }

    const dateRange = this.getDateRange(props.initialRange);

    this.state = {
      viewingType: null,
      dateType: props.initialRange,
      dateRange,
    };

    this.props.onChange(dateRange);
  }

  // getValueAsText = value => dateTypeItems[value].label

  getDateRange = (dateType) => {
    let from = date.startOfDay(this.todayDate);
    let to = date.endOfDay(this.todayDate);

    switch (dateType) {
      case 'dateRange':
        from = date.startOfDay(this.state.dateRange.from);
        to = date.endOfDay(this.state.dateRange.to);
        break;
      case 'selectedDay':
        from = date.startOfDay(this.state.selectedDay);
        to = date.endOfDay(this.state.selectedDay);
        break;
      case 'everything':
        from = null;
        to = null;
        break;
      case 'yesterday':
        from = date.startOfDay(this.yesterdayDate);
        to = date.endOfDay(this.yesterdayDate);
        break;
      default:
        from = getDefaultDateRange(dateType, this.todayDate);
        from = date.startOfDay(from);
        break;
    }

    return { from, to, dateType };
  };

  getValueAsText(value) {
    const { dateType, dateRange, selectedDay } = this.state;

    const todayReadable = date.formatDate(this.todayDate);
    const yesterdayReadable = date.formatDate(this.yesterdayDate);

    switch (dateType) {
      case 'today':
        return `${dateTypeItems[value].label} — ${todayReadable}`;
      case 'yesterday':
        return `${dateTypeItems[value].label} — ${yesterdayReadable}`;
      case 'dateRange':
        if (dateRange.from && dateRange.to) {
          return `${date.formatDate(dateRange.from)} to ${date.formatDate(
            dateRange.to,
          )}`;
        }

        return dateTypeItems[value].label;
      case 'currentMonth':
        return `${date.formatDate(
          date.beginningOfMonth(this.todayDate),
        )} to ${todayReadable}`;
      case 'currentWeek':
        return `${date.formatDate(
          date.beginningOfWeek(this.todayDate),
        )} to ${todayReadable}`;
      case 'pastWeek':
        return `${date.formatDate(
          date.oneWeekAgo(this.todayDate),
        )} to ${todayReadable}`;
      case 'selectedDay':
        if (selectedDay) {
          return `Selected — ${date.formatDate(selectedDay)}`;
        }

        return dateTypeItems[value].label;
      case 'everything':
        return 'Everything';
      default:
        return dateTypeItems[value].label;
    }
  }

  setDateType = dateType => this.setState({ dateType });

  setDateRange = dateRange => this.setState({ dateRange });

  setViewingType = (viewingType) => {
    // const shouldSubmit = viewingType !== 'dateRange' && viewingType !== 'selectedDay';
    // this.setState({ viewingType }, shouldSubmit ? this.props.onSubmit : null);
    this.setState({ viewingType });
  };

  todayDate = new Date();

  yesterdayDate = date.removeOneDay(this.todayDate);

  handleChangeType = (event) => {
    const { value } = event.target;

    this.setDateType(value);
    this.setViewingType(value);

    if (value === 'dateRange') {
      return;
    }

    this.props.onChange(this.getDateRange(value));
  };

  handleChangeRange = (value) => {
    this.setDateRange(value);
    this.props.onChange({ ...value, dateType: 'dateRange' });
  };

  handleClose = () => this.setViewingType(null);

  handleOk = () => this.setViewingType(null);

  renderValue = value => (
    <ArcView row align="center">
      <ArcView marginRight="8">
        <IconDateRange color="action" fontSize="small" />
      </ArcView>
      <ArcText>{this.getValueAsText(value)}</ArcText>
    </ArcView>
  );

  renderDateRange() {
    const { from, to } = this.getDateRange(this.state.dateType);

    return (
      <Dialog
        open={this.state.viewingType === 'dateRange'}
        onClose={this.handleClose}
      >
        <ArcView>
          <ArcDateRange
            from={from}
            to={to}
            onOk={this.handleClose}
            onCancel={this.handleClose}
            onChange={this.handleChangeRange}
          />
        </ArcView>
      </Dialog>
    );
  }

  renderSelectedDay() {
    return (
      <Dialog
        open={this.state.viewingType === 'selectedDay'}
        onClose={this.handleClose}
        aria-labelledby="date-range-title"
      >
        <ArcView>
          <ArcDatePicker />
        </ArcView>
      </Dialog>
    );
  }

  renderDateSelector() {
    const { dateType } = this.state;

    switch (dateType) {
      case 'dateRange':
        return this.renderDateRange();
      case 'selectedDay':
        return this.renderSelectedDay();
      default:
        return null;
    }
  }

  render() {
    const { selectDisplayProps } = this.props;

    return (
      <ArcView>
        {this.renderDateSelector()}
        <ArcSelect
          items={items}
          onChange={this.handleChangeType}
          renderValue={this.renderValue}
          value={this.state.dateType}
          variant="outlined"
          SelectDisplayProps={
            selectDisplayProps || {
              style: {
                padding: 8,
                paddingRight: 32,
                fontSize: 12,
                height: 'auto',
              },
            }
          }
        />
      </ArcView>
    );
  }
}

export default ArcDateSelector;
