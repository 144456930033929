import getTeamColor from '../getTeamColor';
import getTeamLetter from '../getTeamLetter';

function getNormalizedEntities(data) {
  const entitiesByUuid = {};

  function getNormalizedUser(user, team) {
    const nextUser = {
      ...user,
      type: 'person',
      id: user.id.toString(),
      uuid: `person-${user.id}`,
      imageUrl: user.image,
      level: 'bottom',
      teamName: team.name,
      teamId: team.id.toString(),
      parentUuid: `team-${team.id}`,
    };

    entitiesByUuid[nextUser.uuid] = nextUser;

    return nextUser;
  }

  function getNormalizedTeam(team, parentUuid) {
    const uuid = `team-${team.id}`;

    const nextTeam = {
      ...team,
      level: 'bottom',
      id: team.id.toString(),
      uuid,
      type: 'team',
      color: getTeamColor(team.id),
      letter: getTeamLetter(team.name),
      parentUuid,
      children: team.users
        .map(user => getNormalizedUser(user, team))
        .map(user => user.uuid),
    };

    entitiesByUuid[nextTeam.uuid] = nextTeam;

    return nextTeam;
  }

  function getNormalizedEntity(entity, parentUuid) {
    const id = entity.root.id ? entity.root.id.toString() : '';
    const type = entity.root.type ? entity.root.type.toLowerCase() : '';

    const uuid = `${type}-${id}`;

    const children = [
      ...entity.children.map(child => getNormalizedEntity(child, uuid)).map(child => child.uuid),
      ...entity.teams.map(team => getNormalizedTeam(team, parentUuid)).map(team => team.uuid),
    ];

    if (entity.users.length) {
      const teamUnassigned = {
        id: `unassigned-${id}`,
        type: 'team',
        level: 'bottom',
        name: 'Unassigned',
        uuid: `team-unassigned-${id}`,
        parentUuid,
        letter: 'UA',
      };

      teamUnassigned.children = entity.users
        .map(user => getNormalizedUser(user, teamUnassigned))
        .map(user => user.uuid);

      entitiesByUuid[teamUnassigned.uuid] = teamUnassigned;
      children.push(teamUnassigned.uuid);
    }

    const normalizedEntity = {
      id,
      type,
      uuid,
      parentUuid: parentUuid === uuid ? null : parentUuid,
      level: entity.root.level,
      name: entity.root.name,
      color: type !== 'person' ? getTeamColor(id) : undefined,
      letter: type !== 'person' ? getTeamLetter(entity.root.name) : undefined,
      children,
    };

    if (!entitiesByUuid[normalizedEntity.uuid]) {
      entitiesByUuid[normalizedEntity.uuid] = normalizedEntity;
    }

    return normalizedEntity;
  }

  function normalizeEntities(entities) {
    entities.forEach((entity) => {
      const parentUuid = `${entity.root.type.toLowerCase()}-${entity.root.id}`;

      getNormalizedEntity(entity, parentUuid);

      if (entity.children) {
        normalizeEntities(entity.children);
      }
    });
  }

  normalizeEntities([data]);

  entitiesByUuid.entity = entitiesByUuid[`entity-${data.root.id}`];
  entitiesByUuid.entity.isRoot = true;

  return entitiesByUuid;
}

export default getNormalizedEntities;
