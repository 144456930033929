import { react2angular } from 'react2angular';


import ChatCreateFormContainer from 'arcade-frontend-chat/src/containers/ChatCreateFormContainer';

import { createProvidedContainer } from './helpers';

const ProvidedChatCreateFormContainer = createProvidedContainer(ChatCreateFormContainer);

export default react2angular(ProvidedChatCreateFormContainer, [
  'open',
  'onClose',
]);
