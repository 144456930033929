import React from 'react';
import CardGiftcard from '@material-ui/icons/CardGiftcard';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { resources } from 'arcade-frontend-core/src/resources/users';
import { resources as chatAudienceResources } from 'arcade-frontend-core/src/resources/chatAudiences';
import * as REWARD_TYPES from 'arcade-frontend-core/src/types/rewards';
import ArcResourceErrorDialog from 'arcade-frontend-ui/src/components/ArcResourceErrorDialog';
import ArcInternalLink from 'arcade-frontend-ui/src/components/ArcInternalLink';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcView from 'arcade-frontend-ui/src/primitives/ArcView';

import RewardsAwardForm from '../../components/RewardsAwardForm';

function RewardsAwardFormContainer(props) {
  const apiGetEscrowableFunds = useResourceAction(
    resources.apiGetEscrowableFunds,
  );

  const apiGetChatEntities = useResourceAction(
    chatAudienceResources.apiGetChatEntities,
  );

  const apiPostUserChests = useResourceAction(resources.apiPostUserChests);
  const apiPostUserTokens = useResourceAction(resources.apiPostUserTokens);

  const [currentRequest, setCurrentRequest] = React.useState(
    REWARD_TYPES.TOKENS,
  );

  function getCurrentRequestAction() {
    switch (currentRequest) {
      case REWARD_TYPES.CHESTS:
        return apiPostUserChests;
      case REWARD_TYPES.TOKENS:
        return apiPostUserTokens;
      default:
        return {};
    }
  }

  function setup() {
    apiGetChatEntities
      .requestData()
      .then(apiGetChatEntities.onStatusReset)
      .catch(global.noop);

    apiGetEscrowableFunds
      .requestData()
      .then(apiGetEscrowableFunds.onStatusReset)
      .catch(global.noop);
  }

  React.useEffect(setup, []);

  const handleSubmit = values => {
    setCurrentRequest(values.award.type);

    switch (values.award.type) {
      case REWARD_TYPES.CHESTS: {
        const payload = {
          award: {
            chests: values.award.value,
            user_ids: values.people,
            is_public: values.isPublic,
            message: values.message,
          },
        };

        return apiPostUserChests
          .requestData({
            resources: [payload],
          })
          .catch(global.noop);
      }
      case REWARD_TYPES.TOKENS: {
        const payload = {
          tokens: {
            amount: values.award.value,
            user_ids: values.people,
            is_public: values.isPublic,
            message: values.message,
          },
        };

        return apiPostUserTokens
          .requestData({
            resources: [payload],
          })
          .catch(global.noop);
      }
      default:
        return null;
    }
  };

  const escrowableFunds = apiGetEscrowableFunds.hasData
    ? apiGetEscrowableFunds.data.resources[0]
    : {};

  const currentRequestAction = getCurrentRequestAction();

  return (
    <>
      <RewardsAwardForm
        error={currentRequestAction.error}
        escrowableFundsBalance={escrowableFunds.balance}
        escrowableFundsType={escrowableFunds.type}
        hasError={currentRequestAction.hasError}
        hasSucceeded={currentRequestAction.hasSucceeded}
        isIdle={
          currentRequestAction.isIdle &&
          apiGetEscrowableFunds.isIdle &&
          apiGetChatEntities.isIdle
        }
        onSubmit={handleSubmit}
        onErrorReset={currentRequestAction.onErrorReset}
        onStatusReset={currentRequestAction.onStatusReset}
        status={currentRequestAction.status}
        entitiesByUuid={
          apiGetChatEntities.hasData
            ? apiGetChatEntities.data.resources
            : undefined
        }
        {...props}
      />

      <ArcResourceErrorDialog
        open={apiGetEscrowableFunds.hasError}
        error={apiGetEscrowableFunds.error}
        onClose={apiGetEscrowableFunds.onErrorReset}
      >
        <ArcView marginBottom="16">
          <ArcInternalLink href="/arcade/prize" title="Back to rewards">
            <ArcText>{'Back to '}</ArcText>
            <ArcText verticalAlign="middle" marginRight="4">
              <CardGiftcard fontSize="inherit" />
            </ArcText>
            <ArcText>{'Rewards'}</ArcText>
          </ArcInternalLink>
        </ArcView>
      </ArcResourceErrorDialog>
    </>
  );
}

RewardsAwardFormContainer.displayName = 'RewardsAwardFormContainer';

export default RewardsAwardFormContainer;
