import * as types from 'arcade-frontend-core/src/types/routes';

export const routeManageChecklists = () => ({
  type: types.ROUTE_MANAGE_CHECKLISTS,
  payload: {},
});

export const routeManageChecklistsCreate = (step = 1) => ({
  type: types.ROUTE_MANAGE_CHECKLISTS_CREATE,
  payload: {
    step,
  },
});

export const routeManageChecklistsShow = id => ({
  type: types.ROUTE_MANAGE_CHECKLISTS_SHOW,
  payload: {
    id,
  },
});


export const routeManageChecklistsEdit = id => ({
  type: types.ROUTE_MANAGE_CHECKLISTS_EDIT,
  payload: {
    id,
  },
});
