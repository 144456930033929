import React from 'react';
import PropTypes from 'prop-types';

import ArcView from '../../primitives/ArcView';

import ArcEntity from '../ArcEntity';
import ArcMultiSelect from '../ArcMultiSelect';

function ArcPeopleMultiSelect(props) {
  return (
    <ArcMultiSelect
      {...props}
      label="People"
      renderItem={item => (
        <ArcView
          paddingLeft="4"
          paddingRight="8"
        >
          <ArcEntity
            type="person"
            padding="0"
            {...item}
          />
        </ArcView>
      )}
      renderValue={value => (
        <ArcView
          paddingLeft="4"
          paddingRight="8"
        >
          <ArcEntity
            type="person"
            padding="0"
            {...value}
          />
        </ArcView>
      )}
    />
  );
}

ArcPeopleMultiSelect.displayName = 'ArcPeopleMultiSelect';

ArcPeopleMultiSelect.propTypes = {
  placeholder: PropTypes.string,
};

ArcPeopleMultiSelect.defaultProps = {
  placeholder: 'Select People',
};

export default ArcPeopleMultiSelect;
