import React from 'react';
import { SnackbarProvider } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  containerRoot: {
    zIndex: 0,
  },
});

export interface ArcNotistackProviderProps {
  children: React.ReactNode;
}

function ArcNotistackProvider({
  children,
}: ArcNotistackProviderProps) {
  const classes = useStyles();

  return (
    <SnackbarProvider
      content={(key, message) => (<div key={key}>{message}</div>)}
      classes={classes}
    >
      {children}
    </SnackbarProvider>
  );
}

ArcNotistackProvider.displayName = 'ArcNotistackProvider';

export default ArcNotistackProvider;
