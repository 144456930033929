import { ArcPropTypes } from 'arcade-frontend-ui';
import { NOT_FOUND } from 'redux-first-router';
import * as types from 'arcade-frontend-core/src/types/routes';

import CbLoginContainer from '../../containers/app/LoginContainer/CbLogin/CbLoginContainer';
import RouteNotFound from '../RouteNotFound';

const { PLATFORMS } = ArcPropTypes;

const base = {
  [types.ROUTE_BASE]: CbLoginContainer,
  [types.ROUTE_LOGIN]: CbLoginContainer,
  [types.ROUTE_LOGIN_VIA_LINK]: CbLoginContainer,
  [NOT_FOUND]: RouteNotFound,
};

const benjo = base;

const development = base;

const launch = base;

export default {
  [PLATFORMS.BENJO]: benjo,
  [PLATFORMS.DEVELOPMENT]: development,
  [PLATFORMS.LAUNCH]: launch,
};
