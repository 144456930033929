import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Platform } from 'react-native';
import ArcView from 'arcade-frontend-ui/src/primitives/ArcView';

const FormComponent = Platform.select({
  web: Form,
  default: ArcView,
});

const NG_MANAGE_TITLE_BAR_HEIGHT = 50;

const formStyle = Platform.select({
  web: {
    height: `calc(100% - ${NG_MANAGE_TITLE_BAR_HEIGHT}px)`,
  },
  default: {
    height: '100%',
  },
});

const inDialogFormStyle = {
  height: '100%',
};

function ArcForm({ children, component, id, inDialog, formProps, ...props }) {
  const Component = component || FormComponent;

  return (
    <Formik {...props}>
      {formikProps => (
        <Component
          data-testid={id ? `ArcForm-${id}` : 'ArcForm'}
          style={inDialog ? inDialogFormStyle : formStyle}
          id={id}
          role={Component !== Form || Component !== 'form' ? 'form' : undefined}
          {...formProps}
        >
          {children(formikProps)}
        </Component>
      )}
    </Formik>
  );
}

ArcForm.propTypes = {
  children: PropTypes.func,
  component: PropTypes.elementType,
  formProps: PropTypes.objectOf(PropTypes.any),
  id: PropTypes.string,
  inDialog: PropTypes.bool,
};

ArcForm.defaultProps = {
  children: global.noop,
  component: undefined,
  formProps: {},
  id: '',
  inDialog: false,
};

export default ArcForm;
