import { createReducer } from 'arcade-frontend-ui';
import { createSelector } from 'reselect';

import moment from 'moment';

import { types } from '../../../actions/manage/games';
import { getManageGamesById } from './manageGamesById';
import { getExtraGameDataById } from './extraGameDataById';

import { gameSectionsWithDateSelector } from '../../../data/manage/games';

const initialState = {
  type: 'overview',
  from: moment(0).toISOString(),
  to: moment(0).toISOString(),
  page: 0,
};

const handleTypeChange = (state, action) => {
  const { payload } = action;
  const { type } = payload;
  return { ...state, type };
};
const handleDateChange = (state, action) => {
  const { payload } = action;
  const { from, to } = payload;
  return { ...state, from: from.toISOString(), to: to.toISOString() };
};
const handlePageChange = (state, action) => {
  const { payload } = action;
  const { page } = payload;
  return { ...state, page };
};

const handlers = {
  [types.MANAGE_GAMES_SET_TYPE_FILTER]: handleTypeChange,
  [types.MANAGE_GAMES_SET_DATE_FILTER]: handleDateChange,
  [types.MANAGE_GAMES_SET_PAGE_FILTER]: handlePageChange,
};

const filteredGameIds = createReducer(initialState, handlers);

export default filteredGameIds;

export const gameFilters = state => state.manage.games.filteredGameIds;

const typeForTab = {
  active: ['active'],
  upcoming: ['prelaunch'],
  needs_verification: ['needs_verification'],
  previous: ['completed', 'cancelled'],
};

const gamesByType = (games, type) => {
  const gameTypes = typeForTab[type];

  return Object.values(games).filter(game => gameTypes.includes(game.status));
};

const filterGames = (games, filters) =>
  Object.values(games).filter(game => {
    const { type } = filters;
    const gameTypes = typeForTab[type];
    if (!gameTypes) return [];

    if (!gameTypes.includes(game.status)) {
      return false;
    }

    return true;
  });

export const getGameStats = createSelector(
  getManageGamesById,
  gameFilters,
  games =>
    Object.keys(typeForTab).map(type => {
      const matchingGames = gamesByType(games, type);
      const total = matchingGames.length;
      return { type, total };
    }),
);

export const getTotalLoot = createSelector(
  getManageGamesById,
  gameFilters,
  getExtraGameDataById,
  (games, filters, lootData) => {
    const matchingGames = filterGames(games, filters);
    return matchingGames.reduce((total, game) => {
      const data = lootData[game.id];
      const loot = data ? data.loot : 0;
      return total + loot;
    }, 0);
  },
);

export const getFilteredGameIds = createSelector(
  getManageGamesById,
  gamesById => {
    return Object.keys(gamesById);
  },
);
