import React from 'react';
import PropTypes from 'prop-types';
import * as GAME_SCENE_SIZES from 'arcade-frontend-core/src/types/game-scene-sizes';
import { createWithStyles } from '../../styles';
import ArcView from '../../primitives/ArcView/ArcView';
import ArcBox from '../ArcBox';
import { getStyleByGameSceneSize } from '../../helpers/utils/game-scene';

import PositionedPlayers from '../PositionedPlayers/PositionedPlayersV2';
import positions from './positions';

import BountyMountain from '../../assets/BountyMountain';
import ColdSpectators from '../../assets/ColdSpectators';
import SummitFlag from '../../assets/SummitFlag';
import Grass from '../../assets/Grass';
import Snowfield from './Snowfield';
import { PositionedPlayersDemo } from '../PositionedPlayers';


const styles = {
  MountainWrapper: () => ({
    root: {
      width: 273,
      height: 255,
      marginLeft: 'auto',
      marginTop: 85,
      position: 'relative',
    },
  }),
  Sky: () => ({
    root: {
      backgroundColor: '#F0FBFE',
      width: '100%',
      position: 'relative',
    },
  }),
  Field: () => ({
    root: {
      backgroundColor: '#D0F9E7',
      height: 70,
      width: '100%',
    },
  }),
};

const MountainWrapper = createWithStyles(styles.MountainWrapper)(ArcView);
const Sky = createWithStyles(styles.Sky)(ArcView);
const Field = createWithStyles(styles.Field)(ArcView);

const ArcMountain = ({ scores, goal, isDemo, size }) => {
  const renderPlayerPositions = () => {
    if (isDemo) {
      return (
        <PositionedPlayersDemo
          slots={positions}
          numPlayers={2}
          goal={4}
          offsetLeft={getStyleByGameSceneSize(size, [25, 25])}
          offsetTop={getStyleByGameSceneSize(size, [25, 25])}
        />
      );
    }

    if (scores) {
      return (
        <PositionedPlayers
          slots={positions}
          scores={scores}
          goal={goal}
          type="bounty"
        />
      );
    }

    return null;
  };

  return (
    <ArcBox
      overflow="hidden"
      width="100%"
    >
      <Sky>
        <MountainWrapper style={{
          top: getStyleByGameSceneSize(size, [62, 0]),
          width: getStyleByGameSceneSize(size, [260, 273]),
          height: getStyleByGameSceneSize(size, [265, 255]),
          marginTop: getStyleByGameSceneSize(size, [0, 85]),
        }}
        >
          <BountyMountain />
          <SummitFlag
            style={{ position: 'absolute', top: -53, left: 189, height: 53 }}
          />
          <ColdSpectators
            style={{ position: 'absolute', top: -32, left: 180 }}
          />
          {renderPlayerPositions()}
        </MountainWrapper>
        <Field />
        <Grass
          style={{
            position: 'absolute',
            top: getStyleByGameSceneSize(size, [275, 340]),
            left: 8,
          }}
          width="16"
          height="16"
        />
        {/* <Grass
      style={{ position: 'absolute', top: 350, left: 90 }}
      width="16"
      height="16"
    /> */}
        <Grass
          style={{
            position: 'absolute',
            top: getStyleByGameSceneSize(size, [257, 360]),
            left: getStyleByGameSceneSize(size, [2, 32]),
          }}
          width="16"
          height="16"
        />
        {/* <Grass
      style={{ position: 'absolute', top: 355, left: 250 }}
      width="16"
      height="16"
    />
    <Grass
      style={{ position: 'absolute', top: 345, left: 220 }}
      width="16"
      height="16"
    />
    <Grass
      style={{ position: 'absolute', top: 340, left: 280 }}
      width="16"
      height="16"
    /> */}
        <Snowfield
          total={50}
          maxSize={3}
          duration={15}
        />
      </Sky>
    </ArcBox>
  );
};

ArcMountain.propTypes = {
  isDemo: PropTypes.bool,
  size: PropTypes.string,
  goal: PropTypes.number,
  scores: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
    catch_up: PropTypes.string,
    is_self: PropTypes.bool.isRequired,
  })),

};

ArcMountain.defaultProps = {
  isDemo: false,
  size: GAME_SCENE_SIZES.SMALL,
  goal: 0,
  scores: [],
};

export default ArcMountain;
