import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions';

const initialState = {
  activities: [],
  currentTag: null,
};

const handleTaggedActivitiesRequest = state => ({ ...state, activities: [] });

const handleTaggedActivitiesSuccess = (state, action) => {
  const { data } = action.payload;

  return { ...state, activities: data };
};

const handleSetTag = (state, action) => ({
  ...state,
  currentTag: action.payload,
});
const handleClearTag = state => ({ ...state, currentTag: null });

const handlers = {
  [types.NEWSFEED_TAGGED_ACTIVITIES.REQUEST]: handleTaggedActivitiesRequest,
  [types.NEWSFEED_TAGGED_ACTIVITIES.SUCCESS]: handleTaggedActivitiesSuccess,
  [types.NEWSFEED_OPEN_TAGGED_ACTIVITIES]: handleSetTag,
  [types.NEWSFEED_CLOSE_TAGGED_ACTIVITIES]: handleClearTag,
};

export default createReducer(initialState, handlers);

const getCurrentTag = state => state.newsfeed.taggedActivities.currentTag;
const getTaggedActivities = state =>
  state.newsfeed.taggedActivities.activities || [];

export const selectors = { getTaggedActivities, getCurrentTag };
