import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import IconSwapHoriz from '@material-ui/icons/SwapHoriz';
import IconAdd from '@material-ui/icons/Add';

import ArcTokenSvg from '../../assets/ArcToken/arc-token.svg';
import ArcButton from '../../elements/ArcButton';
import ArcImage from '../../primitives/ArcImage';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';
import { createWithStyles } from '../../styles';

const styles = {
  AccountNameText: theme => ({
    root: {
      color: theme.palette.text.secondary,
      flexGrow: 10,
      fontSize: 24,
      fontWeight: '200',
      lineHeight: 1.35,
      marginBottom: theme.spacing(1),
    },
  }),

  BalanceText: theme => ({
    root: {
      fontSize: 48,
      fontWeight: '400',
      lineHeight: 1.18,
      marginLeft: theme.spacing(1),
    },
  }),

  CardButtonRow: theme => ({
    hidden: {
      visibility: 'hidden',
    },

    root: {
      padding: theme.spacing(1),
    },
  }),

  Container: theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,

      [theme.breakpoints.up('md')]: {
        height: '100%',
        width: 336,
      },
    },
  }),

  Divider: theme => ({
    hidden: {
      visibility: 'hidden',
    },

    root: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  }),

  TokenLabel: theme => ({
    root: {
      fontSize: 16,
      fontWeight: '200',
      marginLeft: theme.spacing(1),
      opacity: 0.5,
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);
const BalanceText = createWithStyles(styles.BalanceText)(ArcView);
const TokenLabel = createWithStyles(styles.TokenLabel)(ArcView);
const AccountNameText = createWithStyles(styles.AccountNameText)(ArcView);
const CardButtonRow = createWithStyles(styles.CardButtonRow)(ArcView);
const Divider = createWithStyles(styles.Divider)(ArcView);

class ArcTokenBalanceCard extends React.PureComponent {
  static propTypes = {
    account: PropTypes.string,
    accountName: PropTypes.string,
    balance: PropTypes.number,
    canBeToppedUp: PropTypes.bool,
    canRequestTokenTopup: PropTypes.bool,
    canTransferTokens: PropTypes.bool,
    color: PropTypes.oneOf([
      'default',
      'primary',
      'secondary',
      'danger',
      'none',
    ]),
    isFetching: PropTypes.bool,
    isTransferable: PropTypes.bool,
    onClick: PropTypes.func,
    onTopUp: PropTypes.func,
    onTransfer: PropTypes.func,
  };

  static defaultProps = {
    account: '',
    accountName: '',
    balance: 0,
    canBeToppedUp: false,
    canRequestTokenTopup: false,
    canTransferTokens: false,
    color: 'default',
    isFetching: false,
    isTransferable: false,
    onClick: undefined,
    onTopUp: undefined,
    onTransfer: undefined,
  };

  static displayName = 'ArcTokenBalanceCard'

  render() {
    const {
      color,
      isFetching,
      isTransferable,
      canRequestTokenTopup,
      canTransferTokens,
      canBeToppedUp,
    } = this.props;

    const showDivider = canTransferTokens || canRequestTokenTopup;
    const isMobile = !!window.arcPostMessage;

    return (
      <Container border={color}>
        <ArcView
          onClick={this.props.onClick}
          padding="16"
          cursor="pointer"
          style={{ flexGrow: 100 }}
        >
          <AccountNameText>
            <ArcText className={cx([isFetching && 'shimmer'])}>
              {this.props.accountName}
            </ArcText>
          </AccountNameText>
          <ArcView
            align="center"
            row
          >
            <ArcImage
              src={ArcTokenSvg}
              width="24px"
              height="24px"
            />
            <BalanceText>
              <ArcText className={cx([isFetching && 'shimmer'])}>
                {this.props.balance.toLocaleString()}
              </ArcText>
            </BalanceText>
            <TokenLabel>
              {'tokens'}
            </TokenLabel>
          </ArcView>
        </ArcView>

        {showDivider && (
          <Divider
            borderBottom="default"
            hidden={this.props.account === 'rewards'}
          />
        )}

        <CardButtonRow
          row
          justify="space-between"
          hidden={this.props.account === 'rewards'}
        >

          {canTransferTokens && (
            <ArcButton
              disabled={isFetching || !isTransferable}
              onClick={this.props.onTransfer}
              size="small"
            >
              <IconSwapHoriz />
              <ArcView marginLeft="8">{'Transfer'}</ArcView>
            </ArcButton>
          )}

          {!isMobile && canRequestTokenTopup && canBeToppedUp && (
            <ArcButton
              disabled={isFetching || !isTransferable}
              onClick={this.props.onTopUp}
              size="small"
            >
              <IconAdd />
              <ArcView marginLeft="8">{'Add Tokens'}</ArcView>
            </ArcButton>
          )}

        </CardButtonRow>
      </Container>
    );
  }
}

export default ArcTokenBalanceCard;
