import sagas from 'arcade-frontend-core/src/helpers/sagas';

import { actions, types } from '../actions/metrics';

import { api } from '../resources/metrics';
import { getMetricsDateRange } from '../reducers/forms/metrics';

const onActivityCreate = (_, state) =>
  actions.apiMetricsIndexRequest(getMetricsDateRange(state));

const takeLatestSaga = [
  {
    type: types.METRICS_ACTIVITIES_CREATE,
    call: api.create,
    onSuccess: onActivityCreate,
  },
  {
    type: types.METRICS_RANKINGS_INDEX,
    call: api.rankingsIndex,
  },
  {
    type: types.METRICS_ACTIVITIES_INDEX,
    call: api.activitiesIndex,
  },
  {
    type: types.METRICS_INDEX,
    call: api.load,
  },
];

const metrics = takeLatestSaga.map(sagas.makeTakeLatest);

export default metrics;
