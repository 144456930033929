import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/tokens';

const handleManageTokensIndexSuccess = (state, action) => {
  const { data } = action.payload;
  return data;
};

const initialState = [];

const handlers = {
  [types.MANAGE_TOKENS_INDEX.SUCCESS]: handleManageTokensIndexSuccess,
};

const accounts = createReducer(initialState, handlers);

export default accounts;

export const getAccounts = state => state.manage.tokens.accounts;
