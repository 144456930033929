import * as theme from '../variables';

const baseColors = ['red', 'yellow', 'blue', 'green'];
const colors = ['grey5'];
const steps = 5;

baseColors.forEach((color) => {
  for (let ii = 1; ii <= steps; ii += 1) {
    colors.push(`${color}${ii}`);
  }
});

const mapPercentToColor = (percent) => {
  const step = parseInt((percent / 5).toFixed(0), 10);
  const color = colors[step];

  return theme[color];
};

const percentToArcColor = (percent) => {
  if (!percent) {
    return theme.grey5;
  }

  if (percent > 100) {
    return theme.green5;
  }

  if (percent > 0 && percent <= 5) {
    return theme.red1;
  }

  return mapPercentToColor(percent);
};

export default percentToArcColor;
