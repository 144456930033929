import React from 'react';
import PropTypes from 'prop-types';

import * as REWARD_TYPES from 'arcade-frontend-core/src/types/rewards';

import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcView from 'arcade-frontend-ui/src/primitives/ArcView';
import ArcChestImage from 'arcade-frontend-ui/src/components/ArcChestImage';
import ArcToken from '../../assets/ArcToken';

function ArcAwardSelections({
  type,
  value,
  ...props
}) {
  if (!value) {
    return null;
  }

  switch (type) {
    case REWARD_TYPES.CHESTS: {
      const chests = Object.entries(value).filter(([, qty]) => !!qty);
      const { length } = chests;

      return (
        <ArcView {...props}>
          {chests.map(([rarity, quantity], index) => (
            <ArcView
              key={rarity}
              row
              align="center"
              marginBottom={index === length - 1 ? undefined : '8'}
            >
              <ArcChestImage
                label=""
                size="24px"
                variant={rarity}
              />
              <ArcText marginLeft="4" size="12px">x{quantity}</ArcText>
              <ArcText marginLeft="4" size="12px" isStrong>{rarity}</ArcText>
            </ArcView>
          ))}
        </ArcView>
      );
    }
    case REWARD_TYPES.TOKENS: {
      const formattedValue = value.toLocaleString ? value.toLocaleString() : '';

      return (
        <ArcView
          row
          justify="flex-start"
          align="center"
          {...props}
        >
          <ArcToken
            width="14"
            height="14"
          />
          <ArcView marginLeft="4" row>
            {formattedValue}
            <ArcText marginLeft="4" isStrong>tokens</ArcText>
          </ArcView>
        </ArcView>
      );
    }
    case REWARD_TYPES.CUSTOM: {
      return (
        <ArcView
          row
          justify="flex-start"
          {...props}
        >
          <ArcText size="14px" isStrong>{value}</ArcText>
        </ArcView>
      );
    }
    default:
      return null;
  }
}

ArcAwardSelections.displayName = 'ArcAwardSelections';

ArcAwardSelections.propTypes = {
  type: PropTypes.oneOf(Object.values(REWARD_TYPES)),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

ArcAwardSelections.defaultProps = {
  type: undefined,
  value: undefined,
};

export default ArcAwardSelections;
