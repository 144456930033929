import { createRequestingStatusReducer } from 'arcade-frontend-ui';

import { types } from '../../actions/customAudience';

export default createRequestingStatusReducer(
  {
    CUSTOM_AUDIENCE_CREATE: 'DEFAULT',
    CUSTOM_AUDIENCE_ENTITIES: 'DEFAULT',
  },
  [types.CUSTOM_AUDIENCE_CREATE, types.CUSTOM_AUDIENCE_ENTITIES],
);

export const getRequestStatus = state => state.customAudience.requestStatus;
