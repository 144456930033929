import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import {
  ArcButton,
  ArcView,
  ArcToken,
  ArcResponsiveDataTable,
  ArcCellContent,
  createWithStyles,
} from 'arcade-frontend-ui';

import MoreVert from '@material-ui/icons/MoreVert';

import moment from 'moment';

import { getVerifiedGameIds } from 'arcade-frontend-features/src/reducers/manage/games/verifiedGameIds';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import usePermission from 'arcade-frontend-core/src/hooks/usePermission';

import useMenuControls from 'arcade-frontend-core/src/hooks/useMenuControls/useMenuControls';
import Participants from './Participants';
import ParticipantDialog from './ParticipantDialog';
import formatNumberToPrecision from '../../helpers/formatNumberToPrecision';

const styles = {
  TokenHolder: theme => ({
    root: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  }),
  TableContainer: theme => ({
    root: {
      borderWidth: 1,
      borderColor: theme.palette.grey[300],
      borderStyle: 'solid',
      backgroundColor: theme.palette.common.white,
    },
  }),
};

const TokenHolder = createWithStyles(styles.TokenHolder)(ArcView);
const TableContainer = createWithStyles(styles.TableContainer)(ArcView);

const gameListColumns = (
  column,
  {
    name,
    winners,
    participants,
    creator,
    loot,
    escrowedValue,
    tokensAwarded,
    id,
    verifiedGameIds,
    handleShowDetails,
    handleShowVerify,
    handleShowEvents,
    handleShowLeaderboard,
    handleShowParticipants,
    handleShowWinners,
    handleOpenActionsMenu,
  },
) => {
  switch (column) {
    case 'leaderboard':
      return (
        <ArcCellContent action onClick={handleShowLeaderboard}>
          Leaderboard
        </ArcCellContent>
      );
    case 'events':
      return (
        <ArcCellContent action onClick={handleShowEvents}>
          Events
        </ArcCellContent>
      );

    case 'name':
      return <ArcCellContent subheader>{name}</ArcCellContent>;

    case 'verify':
      return verifiedGameIds.includes(id) ? (
        <ArcCellContent subheader secondary>
          Verified
        </ArcCellContent>
      ) : (
        <ArcCellContent action onClick={handleShowVerify}>
          Verify
        </ArcCellContent>
      );
    case 'details':
      return (
        <ArcCellContent action onClick={handleShowDetails}>
          Show details
        </ArcCellContent>
      );
    case 'winners':
      return <Participants people={winners} onClick={handleShowWinners} />;
    case 'participants':
      return (
        <Participants people={participants} onClick={handleShowParticipants} />
      );
    case 'creator':
      return <ArcCellContent hint>{creator}</ArcCellContent>;
    case 'loot':
      return (
        <ArcCellContent row align="center">
          <TokenHolder row align="center">
            <ArcToken />
          </TokenHolder>{' '}
          {loot.toLocaleString()}
        </ArcCellContent>
      );
    case 'escrowedValue':
      return (
        <ArcCellContent row align="center">
          <TokenHolder row align="center">
            <ArcToken />
          </TokenHolder>{' '}
          {escrowedValue ? escrowedValue.toLocaleString() : 0}
        </ArcCellContent>
      );
    case 'tokensAwarded':
      return (
        <ArcCellContent row align="center">
          <TokenHolder row align="center">
            <ArcToken />
          </TokenHolder>{' '}
          {tokensAwarded.toLocaleString()}
        </ArcCellContent>
      );
    case 'actions':
      return (
        <ArcCellContent>
          <ArcView style={{ marginLeft: 'auto' }}>
            <ArcButton
              color="primary"
              onClick={handleOpenActionsMenu}
              size="small"
              label="Edit Settings"
            >
              <MoreVert fontSize="small" />
            </ArcButton>
          </ArcView>
        </ArcCellContent>
      );
    default:
      return null;
  }
};

const ManageGamesGameList = ({
  view,
  gamesById,
  extraGameDataById,
  gameIds,
  onEditGame,
  onCloneGame,
  onShowLeaderboard,
  onShowDetails,
  onShowEvents,
  onShowVerify,
  onCancelGame,
  hasEscrowFeature,
  isFetching,
}) => {
  const [dialogParticipants, setDialogParticipants] = useState(null);
  const [participantTitle, setParticipantTitle] = useState('Winners');
  const [selectedGameId, setSelectedGameId] = useState(null);
  const verifiedGameIds = useSelector(getVerifiedGameIds);

  const [anchorEl, handleClick, handleClose] = useMenuControls();

  const hasCloneFeature = useFeatureFlag(FEATURE_FLAGS.GAME_CLONING);
  const canManageGames = usePermission(PERMISSIONS.MANAGE_GAMES);
  const selectedGame = gamesById[selectedGameId];
  const recurring = !!selectedGame && selectedGame.recurring;
  const canClone = hasCloneFeature && canManageGames && !recurring;

  const editable = view === 'active' || view === 'upcoming';

  const showParticipants = id => {
    setDialogParticipants(extraGameDataById[id].participants);
    setParticipantTitle('Participants');
  };
  const hideParticipants = () => setDialogParticipants(null);

  const showWinners = id => {
    setDialogParticipants(extraGameDataById[id].winners);
    setParticipantTitle('Winners');
  };

  const safeDateTime = date => {
    if (new window.Intl.DateTimeFormat().resolvedOptions().locale === 'en-US') {
      return date ? moment(date).format('MMMM D, h:mma') : '';
    }
    return date ? moment(date).format('D MMMM, h:mma') : '';
  };

  const safeDate = date => {
    if (new window.Intl.DateTimeFormat().resolvedOptions().locale === 'en-US') {
      return date ? moment(date).format('D MMMM') : '';
    }
    return date ? moment(date).format('MMMM D') : '';
  };

  const data = useMemo(() => {
    const nextData = {};

    if (!gamesById) {
      return nextData;
    }

    Object.values(gamesById).forEach(game => {
      const {
        id,
        displayableFormat,
        name,
        goal,
        type,
        startsAt,
        expiresAt,
        metric,
      } = game;

      const extraData = extraGameDataById[id] || {
        participants: [],
        creator: 'none',
        loot: 0,
        tokensAwarded: 0,
        escrowedFunds: 0,
      };

      nextData[id] = {
        id,
        type,
        name,
        displayableFormat,
        verifiedGameIds,
        creator: extraData.created_by || 'None',
        escrowedValue: extraData.escrowedFunds,
        goal: goal ? formatNumberToPrecision(goal) : 'N/A',
        metric: metric?.name,
        winners: extraData.winners,
        participants: extraData.participants,
        loot: extraData.loot,
        totalScore: extraData.totalScore,
        tokensAwarded: extraData.tokensAwarded,
        start: safeDateTime(startsAt),
        end: safeDateTime(expiresAt),
        dates: `${safeDate(startsAt)} - ${safeDate(expiresAt)}`,
        handleShowLeaderboard: () => onShowLeaderboard(id),
        handleShowDetails: () => onShowDetails(id),
        handleShowEvents: () => onShowEvents(id),
        handleShowVerify: () => onShowVerify(id),
        handleShowWinners: () => showWinners(id),
        handleShowParticipants: () => showParticipants(id, 'Participants'),
        handleOpenActionsMenu: event => {
          handleClick(event);
          setSelectedGameId(id);
        },
      };
    });

    return nextData;
  }, [gamesById, extraGameDataById, verifiedGameIds]);

  const allColumns = {
    active: [
      { label: 'Name', key: 'name', render: gameListColumns },
      { label: 'Type', key: 'displayableFormat' },
      { label: 'Participant Target', key: 'goal' },
      { label: 'Metric', key: 'metric' },
      { label: "Who's Playing", key: 'participants', render: gameListColumns },
      hasEscrowFeature
        ? { label: 'Escrow', key: 'escrowedValue', render: gameListColumns }
        : null,
      { label: 'Dates', key: 'dates' },
      { key: 'details', render: gameListColumns },
      { key: 'actions', render: gameListColumns },
    ],
    upcoming: [
      { label: 'Name', key: 'name', render: gameListColumns },
      { label: 'Type', key: 'displayableFormat' },
      { label: 'Participant Target', key: 'goal' },
      { label: 'Metric', key: 'metric' },
      { label: "Who's Playing", key: 'participants', render: gameListColumns },
      hasEscrowFeature
        ? { label: 'Escrow', key: 'escrowedValue', render: gameListColumns }
        : null,
      { label: 'Dates', key: 'dates' },
      { key: 'details', render: gameListColumns },
      { key: 'actions', render: gameListColumns },
    ],
    needs_verification: [
      { label: 'Name', key: 'name', render: gameListColumns },
      { label: 'Type', key: 'displayableFormat' },
      { label: 'Participant Target', key: 'goal' },
      { label: 'Metric', key: 'metric' },
      { label: 'Winners', key: 'winners', render: gameListColumns },
      { key: 'verify', render: gameListColumns },
      { key: 'details', render: gameListColumns },
      { key: 'actions', render: gameListColumns },
    ],
    previous: [
      { label: 'Name', key: 'name', render: gameListColumns },
      { label: 'Type', key: 'displayableFormat' },
      { label: 'Participant Target', key: 'goal' },
      { label: 'Metric', key: 'metric' },
      { label: 'Dates', key: 'dates' },
      { label: 'Participants', key: 'participants', render: gameListColumns },
      { key: 'details', render: gameListColumns },
      { key: 'actions', render: gameListColumns },
    ],
  };

  const noResultsMessages = {
    active: {
      header: 'No Active Games Found',
      subheader: 'Create a game with the New Game button above.',
    },
    needs_verification: {
      header: 'No games currently need verification',
    },
    upcoming: {
      header: 'No Upcoming Games Found',
    },
    previous: {
      header: 'No Previous Games Found',
      subheader:
        'Try adjusting your selected dates using the date selector in the top bar.',
    },
  };

  const columns = allColumns[view]?.filter(c => c !== null) ?? [];

  const handleMenuItemClick = menuItem => {
    if (!selectedGameId) {
      return null;
    }

    handleClose();

    switch (menuItem) {
      case 'leaderboard':
        return onShowLeaderboard(selectedGameId);
      case 'details':
        return onShowDetails(selectedGameId);
      case 'events':
        return onShowEvents(selectedGameId);
      case 'edit-game':
        return onEditGame(selectedGameId);
      case 'clone-game':
        return onCloneGame(selectedGameId);
      case 'cancel-game':
        return onCancelGame(selectedGameId);
      default:
        return null;
    }
  };

  return (
    <ArcView fullWidth fullHeight padding={16} overflow="hidden">
      <ParticipantDialog
        participants={dialogParticipants}
        onClose={hideParticipants}
        title={participantTitle}
      />

      <TableContainer fullWidth fullHeight overflow="auto">
        <ArcResponsiveDataTable
          columns={columns}
          rowCount={gameIds?.length ?? 0}
          getRecord={(column, row) => data[gameIds[row]]}
          noResultsMessage={noResultsMessages[view]}
          isFetching={isFetching}
        />
      </TableContainer>
      {/* TODO - add pagination? <ArcView>footer</ArcView> */}

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleMenuItemClick('leaderboard')}>
          {'View Leaderboard'}
        </MenuItem>

        <MenuItem onClick={() => handleMenuItemClick('events')}>
          {'View Events'}
        </MenuItem>

        {editable && (
          <MenuItem onClick={() => handleMenuItemClick('edit-game')}>
            {'Edit Game'}
          </MenuItem>
        )}

        {canClone && (
          <MenuItem onClick={() => handleMenuItemClick('clone-game')}>
            {'Clone Game'}
          </MenuItem>
        )}

        {editable && (
          <MenuItem onClick={() => handleMenuItemClick('cancel-game')}>
            {'Cancel Game'}
          </MenuItem>
        )}
      </Menu>
    </ArcView>
  );
};

ManageGamesGameList.displayName = 'ManageGamesGameList';

ManageGamesGameList.propTypes = {
  view: PropTypes.string.isRequired,
  gamesById: PropTypes.shape({}).isRequired,
  extraGameDataById: PropTypes.objectOf(
    PropTypes.shape({
      tokensAwarded: PropTypes.number.isRequired,
      totalScore: PropTypes.number.isRequired,
      loot: PropTypes.number.isRequired,
      participants: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          image: PropTypes.string.isRequired,
        }),
      ),
      winners: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          image: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  gameIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onEditGame: PropTypes.func.isRequired,
  onCloneGame: PropTypes.func.isRequired,
  onCancelGame: PropTypes.func.isRequired,
  onShowLeaderboard: PropTypes.func.isRequired,
  onShowDetails: PropTypes.func.isRequired,
  onShowEvents: PropTypes.func.isRequired,
  onShowVerify: PropTypes.func.isRequired,
  hasEscrowFeature: PropTypes.bool,
  isFetching: PropTypes.bool,
};
ManageGamesGameList.defaultProps = {
  hasEscrowFeature: false,
  isFetching: false,
};

export default ManageGamesGameList;
