import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const Attachment = props => (
  <Icon {...props}>
    <Path
      fill="currentColor"
      d="M7.5 24.002a7.448 7.448 0 01-5.303-2.197A7.452 7.452 0 010 16.502c0-2.004.78-3.887 2.197-5.303l9.016-9.015A5.208 5.208 0 0114.889.59l.12-.001a5.19 5.19 0 013.618 1.474 5.218 5.218 0 011.6 3.685 5.214 5.214 0 01-1.471 3.738 5.312 5.312 0 01-.13.13l-9.004 9.01a3 3 0 01-2.097.852h-.047a2.984 2.984 0 01-2.107-.915 2.989 2.989 0 01-.001-4.168l9.025-9.028a.748.748 0 011.06-.001.75.75 0 010 1.06L6.44 15.447a1.49 1.49 0 00.009 2.075c.278.288.652.45 1.053.457h.032c.387 0 .754-.15 1.036-.422l9.006-9.011.1-.1a3.755 3.755 0 00-.091-5.303 3.73 3.73 0 00-2.604-1.053h-.066a3.726 3.726 0 00-2.632 1.145l-9.025 9.024A5.964 5.964 0 001.5 16.502c0 1.603.624 3.109 1.757 4.243A5.96 5.96 0 007.5 22.499a5.962 5.962 0 004.243-1.754L22.72 9.767a.744.744 0 011.06 0c.142.14.22.33.22.53s-.078.389-.22.53L12.803 21.805A7.448 7.448 0 017.5 24.002z"
    />
  </Icon>
);

export default Attachment;
