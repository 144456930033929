import humps from 'humps';
import { makeApiActions } from 'arcade-frontend-ui';
// import normalizers from './normalizers';

const index = () => ({
  method: 'GET',
  url: '/manager/v4/accounts',
  rootKey: 'accounts',
  normalizer: account => ({ ...account, ...humps.camelizeKeys(account) }),
});

const transfer = ({ amount, fromAccountKey, toAccountKey }) => ({
  method: 'POST',
  url: '/manager/v4/accounts/transfer',
  data: {
    transfer: {
      amount,
      from_type: fromAccountKey,
      to_type: toAccountKey,
    },
  },
});

const topUp = ({ amount }) => ({
  method: 'POST',
  url: '/manager/v4/accounts/top_up',
  data: {
    top_up: {
      amount,
    },
  },
});

const endpoints = {
  index,
  transfer,
  topUp,
};

const api = makeApiActions(endpoints);

export default api;
