import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Platform } from 'react-primitives';
import Video from '@material-ui/icons/Videocam';

import { ArcText, ArcView, createWithStyles } from 'arcade-frontend-ui';

const styles = {
  AnchorBody: theme => ({
    root: {
      display: 'none',
      position: 'relative',
      minWidth: theme.spacing(6),
      width: 'auto',
    },
    isVisible: {
      display: 'flex',
    },

    fullWidth: {
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },

    onClick: {
      ...Platform.select({
        web: {
          cursor: 'pointer',
        },
      }),
    },
  }),
  VideoIcon: theme => ({
    root: {
      margin: '12px 8px',
      transition: theme.transitions.create('color'),
    },

    fullWidth: {
      left: 4,
    },

    uploadStatusDefault: {
      color: theme.palette.primary.main,
    },

    uploadStatusRequest: {
      color: theme.palette.yellow.main,
      animationIterationCount: 'infinite',
    },

    uploadStatusSuccess: {
      color: theme.palette.success.main,
    },

    uploadStatusFailure: {
      color: theme.palette.danger.main,
    },
  }),

  ArcUploadAnchorLabel: () => ({
    root: {
      color: 'inherit',
    },
  }),
};

const AnchorBody = createWithStyles(styles.AnchorBody)(ArcView);
const VideoIcon = createWithStyles(styles.VideoIcon)(Video);
const ArcUploadAnchorLabel = createWithStyles(styles.ArcUploadAnchorLabel)(
  ArcText,
);

class ArcUploadAnchor extends React.PureComponent {
  static propTypes = {
    fullWidth: PropTypes.bool,
    isUploading: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    uploadStatus: PropTypes.oneOf(['DEFAULT', 'REQUEST', 'SUCCESS', 'FAILURE']),
    source: PropTypes.string,
  };

  static defaultProps = {
    fullWidth: false,
    isUploading: false,
    label: '',
    onClick: global.noop,
    uploadStatus: 'DEFAULT',
    source: null,
  };

  handleClick = () => this.props.onClick(this.props.source);

  render() {
    const {
      fullWidth,
      isUploading,
      label,
      source,
      uploadStatus,
      ...rest
    } = this.props;

    return (
      <AnchorBody
        row
        align="center"
        fullWidth={fullWidth}
        isVisible={!!label || isUploading || !!source}
        {...rest}
        onClick={this.handleClick}
      >
        {!!source && (
          <VideoIcon
            className={cx([
              'ArcUploadAnchorIcon',
              isUploading && 'animated pulse',
              uploadStatus === 'SUCCESS' && 'animated tada',
              uploadStatus === 'FAILURE' && 'animated flash',
            ])}
            fullWidth={fullWidth}
            uploadStatus={uploadStatus.toLowerCase()}
          />
        )}

        {!!label && (
          <ArcUploadAnchorLabel className="ArcUploadAnchorLabel">
            {label}
          </ArcUploadAnchorLabel>
        )}
      </AnchorBody>
    );
  }
}

export default ArcUploadAnchor;
