import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';

import { resources, data } from '../../resources/notifications';
import NotificationsPanelHistory from '../../components/NotificationsPanelHistory';

const NotificationsPanelHistoryContainer = () => {
  const currentUser = useSelector(getCurrentUser);
  const [notifications, setNotifications] = useState([]);
  const [dateRange, setDateRange] = useState(null);

  const apiGetNotificationsHistory = useResourceAction(
    resources.apiGetNotificationsHistory,
  );

  const {
    notifications: notificationsRaw,
  } = data.apiGetNotificationsHistoryRaw;

  useEffect(() => {
    if (currentUser.id) {
      apiGetNotificationsHistory.requestData({ dateRange }).then(() => {
        setNotifications(notificationsRaw);
      });
    }
  }, [currentUser.id, dateRange]);

  const handleChangeDateRange = selectedDateRange => {
    setDateRange(selectedDateRange);
  };

  return (
    <NotificationsPanelHistory
      notifications={notifications}
      onChangeDateRange={handleChangeDateRange}
      statusGetNotifications={apiGetNotificationsHistory.status}
    />
  );
};

NotificationsPanelHistoryContainer.displayName =
  'NotificationsPanelHistoryContainer';

export default NotificationsPanelHistoryContainer;
