import React from 'react';
import Dropzone from 'react-dropzone';
import Clear from '@material-ui/icons/Clear';

import {
  ArcView,
  createWithStyles,
  ArcPropTypes,
  ArcIconButton,
} from 'arcade-frontend-ui';

import DropTarget from '../DropTarget';

const styles = {
  Wrapper: () => ({
    root: {
      position: 'relative',
    },
  }),
  UploadError: () => ({
    root: {
      backgroundColor: 'pink',
    },
  }),
  ClearErrors: () => ({
    root: {
      position: 'absolute',
      right: 10,
      top: 10,
    },
  }),
};

const Wrapper = createWithStyles(styles.Wrapper)(ArcView);
const UploadError = createWithStyles(styles.UploadError)(ArcView);
const ClearErrors = createWithStyles(styles.ClearErrors)(ArcIconButton);

class FileDropper extends React.Component {
  static propTypes = {
    filesSelected: ArcPropTypes.func,
    children: ArcPropTypes.children,
    withOpen: ArcPropTypes.func,
    acceptedExtensions: ArcPropTypes.arrayOf(ArcPropTypes.string),
    acceptedMimetypes: ArcPropTypes.arrayOf(ArcPropTypes.string),
    maxSize: ArcPropTypes.number,
  };

  static defaultProps = {
    filesSelected: ArcPropTypes.noop,
    children: null,
    withOpen: ArcPropTypes.noop,
    acceptedExtensions: [
      'key',
      'pdf',
      'docx',
      'xls',
      'xlsx',
      'numbers',
      'pages',
      'md',
    ],
    acceptedMimetypes: ['image/*', 'video/*', 'text/plain'],
    maxSize: 20000000,
  };

  state = {
    invalidFiles: null,
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      this.setState({ error: rejectedFiles.map(file => file.name) });
    }

    if (this.props.filesSelected && acceptedFiles.length > 0) {
      this.props.filesSelected({ acceptedFiles });
    }
  };

  get accept() {
    const { acceptedExtensions, acceptedMimetypes } = this.props;

    return [...acceptedExtensions.map(e => `.${e}`), ...acceptedMimetypes].join(
      ', ',
    );
  }

  clearErrors = () => {
    this.setState({ error: null });
  };

  render() {
    const { error } = this.state;
    const { maxSize } = this.props;

    return (
      <Wrapper>
        {error && (
          <UploadError>
            <ClearErrors onClick={this.clearErrors}>
              <Clear />
            </ClearErrors>
            <ArcView>
              The following files can&apos;t be uploaded: {error.join(', ')}
            </ArcView>
            <ArcView>
              We accept all common picture, video and business file types, up to{' '}
              {maxSize} bytes in size.
            </ArcView>
          </UploadError>
        )}
        <Dropzone
          onDrop={this.onDrop}
          onClick={evt => evt.preventDefault()}
          accept={this.accept}
          maxSize={this.props.maxSize}
        >
          {targetProps => (
            <DropTarget {...targetProps}>
              {this.props.withOpen(targetProps.open)}
            </DropTarget>
          )}
        </Dropzone>
      </Wrapper>
    );
  }
}

export default FileDropper;
