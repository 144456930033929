import WarningIcon from '@material-ui/icons/Warning';

import {
  ArcView,
  createWithStyles,
  ArcIconButton,
  ArcScroll,
} from 'arcade-frontend-ui';

const PAPER_PROPS = {
  style: {
    width: '100%',
    height: '100%',
  },
};

const styles = {
  Count: theme => ({
    root: {
      margin: theme.spacing(3),
      marginLeft: 0,
    },
  }),
  LoadingUsers: () => ({
    root: {},
  }),
  UserRow: theme => ({
    root: {
      flexDirection: 'row',
      cursor: 'pointer',
      flexGrow: 1,
      '&:not(:last-child)': {
        marginBottom: theme.spacing(4),
      },
    },
  }),
  PortraitLoading: () => ({
    root: {
      width: 34,
      height: 34,
    },
  }),
  Details: () => ({
    root: {
      flexDirection: 'column',
      marginLeft: 16,
      flexGrow: 1,
    },
  }),
  Username: () => ({
    root: {
      height: 16,
      flexGrow: 1,
      marginBottom: 4,
    },
  }),
  CreatedAt: theme => ({
    root: {
      height: 16,
      flexGrow: 1,
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
  }),
  CloseButton: theme => ({
    root: {
      color: theme.palette.primary.main,
    },
  }),
  Dialog: () => ({
    root: {
      width: 400,
      margin: 'auto',
    },
  }),
  Title: theme => ({
    root: {
      borderBottomWidth: 2,
      borderBottomStyle: 'solid',
      borderColor: theme.palette.grey[200],
      backgroundColor: theme.palette.grey.tint,
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }),
  TitleLabel: theme => ({
    root: {
      ...theme.typography.h6,
      flexGrow: 1,
      fontWeight: 400,
      paddingLeft: theme.spacing(2),
    },
  }),
  Content: theme => ({
    root: {
      minWidth: 300,
      padding: `0 ${theme.spacing(4)}px ${theme.spacing(4)}px`,
    },
  }),
  DialogActions: theme => ({
    root: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      padding: theme.spacing(1),
    },
  }),
  Warning: theme => ({
    root: {
      alignItems: 'center',
      paddingTop: theme.spacing(4),
    },
  }),
  WarningMessage: theme => ({
    root: {
      color: theme.palette.default.main,
      margin: `${theme.spacing(4)}px 0`,
      ...theme.typography.h3,
    },
  }),
  WarningGraphic: theme => ({
    root: {
      width: 120,
      height: 120,
      color: theme.palette.orange.main,
    },
  }),
  PinPostContent: theme => ({
    root: {
      margin: theme.spacing(1),
      marginTop: 0,
      textAlign: 'left',
    },
  }),
};

const CloseButton = createWithStyles(styles.CloseButton)(ArcIconButton);
const Title = createWithStyles(styles.Title)(ArcView);
const TitleLabel = createWithStyles(styles.TitleLabel)(ArcView);
const Content = createWithStyles(styles.Content)(ArcScroll);
const Warning = createWithStyles(styles.Warning)(ArcView);
const WarningMessage = createWithStyles(styles.WarningMessage)(ArcView);
const WarningGraphic = createWithStyles(styles.WarningGraphic)(WarningIcon);
const DialogActions = createWithStyles(styles.DialogActions)(ArcView);
const Count = createWithStyles(styles.Count)(ArcView);
const UserRow = createWithStyles(styles.UserRow)(ArcView);
const PortraitLoading = createWithStyles(styles.PortraitLoading)(ArcView);
const Details = createWithStyles(styles.Details)(ArcView);
const Username = createWithStyles(styles.Username)(ArcView);
const CreatedAt = createWithStyles(styles.CreatedAt)(ArcView);
const LoadingCount = createWithStyles(styles.Count)(ArcView);
const PinPostContent = createWithStyles(styles.PinPostContent)(ArcView);

export {
  PAPER_PROPS,
  CloseButton,
  Title,
  TitleLabel,
  Content,
  Warning,
  WarningMessage,
  WarningGraphic,
  DialogActions,
  Count,
  UserRow,
  PortraitLoading,
  Details,
  Username,
  CreatedAt,
  LoadingCount,
  PinPostContent,
};
