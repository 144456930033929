import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import IconClear from '@material-ui/icons/Clear';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import Portrait from 'arcade-frontend-games/src/components/ManageGamesGameList/Portrait';
import {
  ArcView,
  ArcText,
  ArcScroll,
  ArcIconButton,
  ArcEmoji,
  ArcResponsiveDialog,
  ArcDataTableRow,
  ArcDataTableCell,
  createWithStyles,
} from 'arcade-frontend-ui';

const paperProps = { style: { height: '100%' } };

const styles = {
  DialogHeader: theme => ({
    root: {
      backgroundColor: theme.palette.grey.tint,
    },
  }),
};

const DialogHeader = createWithStyles(styles.DialogHeader)(ArcView);

function CommentReactionDialog({
  commentId,
  getCommentReactions,
  commentReactions,
  onClose,
  open,
  onQuicklinkUser,
}) {
  useEffect(() => {
    if (commentId) {
      getCommentReactions(commentId);
    }
  }, [commentId]);

  return (
    <ArcResponsiveDialog open={open} onClose={onClose} PaperProps={paperProps}>
      <DialogHeader row align="center">
        <ArcView row flexGrow={1} paddingLeft={16}>
          <ArcText isLarger>Reactions</ArcText>
        </ArcView>
        <ArcIconButton onClick={onClose}>
          <IconClear />
        </ArcIconButton>
      </DialogHeader>
      <ArcScroll>
        <Table style={{ tableLayout: 'fixed' }}>
          <TableBody>
            {commentReactions.map(({ id, reaction, user }, i) => (
              <ArcDataTableRow
                key={id}
                shade={i % 2 === 1}
                onClick={() => onQuicklinkUser(user.id, 'person')}
              >
                <ArcDataTableCell style={{ width: 50 }}>
                  <Portrait person={user} />
                </ArcDataTableCell>
                <TableCell>{user.name}</TableCell>
                <ArcDataTableCell style={{ width: 50 }}>
                  <ArcText isLarger>
                    <ArcEmoji label={reaction} emoji={reaction} />
                  </ArcText>
                </ArcDataTableCell>
              </ArcDataTableRow>
            ))}
          </TableBody>
        </Table>
      </ArcScroll>
    </ArcResponsiveDialog>
  );
}

CommentReactionDialog.propTypes = {
  commentId: PropTypes.string,
  getCommentReactions: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onQuicklinkUser: PropTypes.func,
  commentReactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
      }).isRequired,
      reaction: PropTypes.string.isRequired,
    }),
  ),
};

CommentReactionDialog.defaultProps = {
  commentId: '',
  commentReactions: [],
  open: false,
  onQuicklinkUser: global.noop,
};

export default CommentReactionDialog;
