import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import HelpIcon from '@material-ui/icons/Help';
import RefreshIcon from '@material-ui/icons/Refresh';
import TablePagination from '@material-ui/core/TablePagination';

import {
  date,
  ArcView,
  ArcButton,
  ArcMainView,
  ArcViewTitleBar,
  createWithStyles,
} from 'arcade-frontend-ui';
import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { actions } from 'arcade-frontend-core/src/actions';
import ArcDateSelector from 'arcade-frontend-ui/src/components/ArcDateSelector/ArcDateSelector';
import { routes as manageRoutes } from 'arcade-frontend-features/src/actions/manage';
import { routes as salesRoutes } from 'arcade-frontend-features/src/actions/manage/sales';

import { resources } from '../../resources/integrationRuns';
import IntegrationHistoryWithdrawDialog from '../../components/IntegrationHistoryWithdrawDialog';
import IntegrationHistoryMessageDialog from '../../components/IntegrationHistoryMessageDialog';
import IntegrationHistoryHelpDialog from '../../components/IntegrationHistoryHelpDialog';
import IntegrationHistoryList from '../../components/IntegrationHistoryList';

const styles = {
  Header: theme => ({
    root: {
      backgroundColor: theme.palette.common.white,
      overflowX: 'auto',
      padding: theme.spacing(1),
    },
  }),
  DateSelectorWrapper: theme => ({
    root: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }),
};

const Header = createWithStyles(styles.Header)(ArcView);
const DateSelectorWrapper = createWithStyles(styles.DateSelectorWrapper)(
  ArcView,
);

const IntegrationHistoryView = ({
  goBack,
  goToActivities,
  showNotification,
}) => {
  const {
    requestData: getRunData,
    data: runData,
    hasFailed: runsHasFailed,
    isPending: runsIsPending,
  } = useResourceAction(resources.apiGetIntegrationRuns);
  const {
    requestData: withdrawRun,
    status: withdrawStatus,
  } = useResourceAction(resources.apiWithdrawIntegrationRun);
  const { requestData: requestHelp, status: helpStatus } = useResourceAction(
    resources.apiIntegrationHelp,
  );

  const {
    requestData: requestMessages,
    data: messagesData,
    isPending: messagesIsPending,
  } = useResourceAction(resources.apiIntegrationMessages);

  const { runs, totalRuns } = runData || { runs: null, totalRuns: 0 };

  const [dateRange, setDateRange] = useState(null);
  const [page, setPage] = useState(0);
  const handleChangePage = (_, p) => setPage(p);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangeRowsPerPage = event =>
    setPage(0) || setRowsPerPage(event.target.value);

  const handleChangeRange = change => setDateRange(change);

  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const handleRequestHelp = params => {
    requestHelp(params).then(() => {
      showNotification('Your support request has been submitted.');
      setTimeout(() => {
        setHelpDialogOpen(false);
      }, 3000);
    });
  };

  const handleOpenHelpDialog = () => setHelpDialogOpen(true);
  const handlecloseHelpDialog = () => setHelpDialogOpen(false);

  const handleShowSalesRequest = integrationRunId => {
    const activityRun = runs.find(run => run.id === integrationRunId) || {
      sheetName: '[unknown file]',
      createdAt: new Date().toString(),
    };

    const { sheetName, createdAt } = activityRun;
    const integrationRunName = `${sheetName} (${date.simpleLocaleDateTime({
      datetime: createdAt,
    })})`;

    goToActivities({ integrationRunName, integrationRunId });
  };

  const [showMessagesDialog, setShowMessagesDialog] = useState(false);
  const [messagesDialogType, setMessagesDialogType] = useState('error');
  const [messagesDialogRun, setMessagesDialogRun] = useState(null);

  const handleOpenMessagesDialog = ({ type, run }) => {
    setMessagesDialogType(type);
    setMessagesDialogRun(run);
    setShowMessagesDialog(true);
    requestMessages({ resources: [{ type, run }] });
  };
  const handleCloseMessagesDialog = () => setShowMessagesDialog(false);

  const loadRuns = () => {
    if (dateRange) getRunData({ dateRange, page, rowsPerPage });
  };

  const [withdrawDialogOpen, setWithdrawDialogOpen] = useState(false);
  const [withdrawRunId, setWithdrawRunId] = useState(null);

  const handleWithdrawRequest = id =>
    setWithdrawRunId(id) || setWithdrawDialogOpen(true);
  const handleCloseWithdrawDialog = () => setWithdrawDialogOpen(false);

  const handleWithdrawConfirm = () => {
    withdrawRun({ withdrawRunId }).then(() => {
      showNotification('Integration run withdrawn.');
      loadRuns();
      setTimeout(() => {
        setWithdrawDialogOpen(false);
      }, 3000);
    });
  };

  const handleRefresh = loadRuns;

  useEffect(loadRuns, [dateRange, page, rowsPerPage]);

  const warningsOrMessages = messagesData
    ? messagesData.integrationWarnings || messagesData.integrationErrors
    : [];

  return (
    <ArcMainView isViewing fullHeight hasScroll={false} hasNoPaddingLeftLaunch>
      <ArcViewTitleBar onClickBack={goBack} title="Integration History" />

      {!!runs && (
        <IntegrationHistoryHelpDialog
          runs={runs}
          open={helpDialogOpen}
          onConfirm={handleRequestHelp}
          onClose={handlecloseHelpDialog}
          status={helpStatus}
        />
      )}

      <IntegrationHistoryMessageDialog
        open={showMessagesDialog}
        type={messagesDialogType}
        messages={warningsOrMessages}
        run={messagesDialogRun}
        isPending={messagesIsPending}
        onClose={handleCloseMessagesDialog}
      />

      <IntegrationHistoryWithdrawDialog
        open={withdrawDialogOpen}
        onConfirm={handleWithdrawConfirm}
        onClose={handleCloseWithdrawDialog}
        status={withdrawStatus}
      />

      <Header row>
        <ArcView flexGrow={1} />

        <ArcButton label={'Help'} color={'grey'} onClick={handleRefresh}>
          <RefreshIcon />
        </ArcButton>

        <DateSelectorWrapper>
          <ArcDateSelector
            onChange={handleChangeRange}
            selectDisplayProps={{
              style: {
                padding: 12,
                paddingRight: 32,
              },
            }}
          />
        </DateSelectorWrapper>

        <ArcButton label={'Help'} color={'grey'} onClick={handleOpenHelpDialog}>
          <HelpIcon />
        </ArcButton>
      </Header>

      <ArcView scrollParent>
        <IntegrationHistoryList
          runs={runs}
          hasFailed={runsHasFailed}
          isPending={runsIsPending}
          onWithdrawRequest={handleWithdrawRequest}
          onShowSalesRequest={handleShowSalesRequest}
          onShowMessages={handleOpenMessagesDialog}
        />
      </ArcView>
      <TablePagination
        component="div"
        count={totalRuns}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </ArcMainView>
  );
};

IntegrationHistoryView.propTypes = {
  goBack: PropTypes.func,
  goToActivities: PropTypes.func,
  showNotification: PropTypes.func,
};

IntegrationHistoryView.defaultProps = {
  goBack: global.noop,
  goToActivities: global.noop,
  showNotification: global.noop,
};

const getState = (state, props) => ({
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      goBack: manageRoutes.routeManage,
      goToActivities: salesRoutes.routeManageActivities,
      showNotification: actions.appShortNotification,
    },
    dispatch,
  );

export default connect(getState, getActions)(IntegrationHistoryView);
