import * as types from 'arcade-frontend-core/src/types/routes';

export const routeManageRewards = () => ({
  type: types.ROUTE_MANAGE_REWARDS,
  payload: {},
});

export const routeManageRewardsCreate = () => ({
  type: types.ROUTE_MANAGE_REWARDS_CREATE,
  payload: {},
});

export const routeManageRewardsEdit = id => ({
  type: types.ROUTE_MANAGE_REWARDS_EDIT,
  payload: {
    id,
  },
});

export const routeManageRewardsShow = id => ({
  type: types.ROUTE_MANAGE_REWARDS_SHOW,
  payload: {
    id,
  },
});
