import { react2angular } from 'react2angular';


import ProfileView from 'arcade-frontend-profile/src/views/ProfileView';

import { createProvidedContainer } from './helpers';

const ProvidedProfileView = createProvidedContainer(ProfileView);

export default react2angular(ProvidedProfileView, [
  'open',
  'onClose',
]);
