import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';

import {
  ArcButton,
  ArcImage,
  ArcText,
  ArcView,
  ArcLink,
  createWithStyles,
} from 'arcade-frontend-ui';

import PeopleManageMenu from '../PeopleManageMenu';
import PersonDetailsActionButtons from '../PersonDetailsActionButtons';

const styles = {
  UserDescription: () => ({
    root: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  }),

  UserImageLink: theme => ({
    root: {
      overflow: 'hidden',
      position: 'relative',
      display: 'block',
      margin: theme.spacing(1),
      userSelect: 'none',
      borderRadius: 4,

      '& .UserImageText': {
        backgroundColor: 'rgba(0,0,0,0.54)',
        color: '#fff',
        fontSize: 12,
        lineHeight: '16px',
        opacity: 0,
        transition: theme.transitions.create('opacity'),
      },

      '&:hover .UserImageText': {
        opacity: 1,
      },
    },
  }),

  UserImage: theme => ({
    root: {
      display: 'block',
      height: theme.spacing(9),
      width: 'auto',
    },
  }),

  ButtonGroup: theme => ({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(-2),
    },
  }),
};

const UserDescription = createWithStyles(styles.UserDescription)(ArcView);
const UserImage = createWithStyles(styles.UserImage)(ArcImage);
const UserImageLink = createWithStyles(styles.UserImageLink)(ArcLink);
const ButtonGroup = createWithStyles(styles.ButtonGroup)(ArcView);
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const dialogContentStyle = {
  overflowY: 'visible',
};

const manageMenuStyle = {
  right: 8,
  top: 8,
};

class PersonDetailsDialog extends React.PureComponent {
  static displayName = 'PersonDetailsDialog';

  static propTypes = {
    canAwardChests: PropTypes.bool,
    canAwardTokens: PropTypes.bool,
    canClawbackTokens: PropTypes.bool,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    person: PropTypes.shape({
      email: PropTypes.string,
      id: PropTypes.string,
      image: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      team: PropTypes.string,
      location: PropTypes.string,
      level: PropTypes.number,
      is_self: PropTypes.bool,
    }),
  };

  static defaultProps = {
    canAwardChests: false,
    canAwardTokens: false,
    canClawbackTokens: false,
    open: false,
    onClose: global.noop,
    person: {},
    team: null,
    location: null,
    level: 1,
    is_self: false,
  };

  state = {
    clawbackTokensOpen: false,
  };

  get canManage() {
    return this.props.canClawbackTokens;
  }

  setClawbackTokensOpen = clawbackTokensOpen =>
    this.setState({ clawbackTokensOpen });

  handleClawbackTokensClick = () => this.setClawbackTokensOpen(true);

  handleClawbackTokensCancel = () => this.setClawbackTokensOpen(false);

  handleClickProfile = event => {
    if (window.viewProfile) {
      window.viewProfile(event, this.props.person.id);
    }
    this.props.onClose();
  };

  handleCloseProfile = () => {
    this.props.onClose();
  };

  renderLevel = person => person.level && `Level ${person.level}`;

  render() {
    const { person } = this.props;
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        onClose={this.handleCloseProfile}
        maxWidth="xs"
        fullWidth
        scroll="body"
      >
        <DialogContent style={dialogContentStyle}>
          <ArcView align="center">
            <UserImageLink onClick={this.handleClickProfile}>
              <UserImage
                alt={`${person.name} profile image`}
                src={person.image}
              />

              <ArcView
                className="UserImageText"
                position="absolute"
                fillToParent
                align="center"
                justify="center"
                textAlign="center"
              >
                <ArcText>{'View'}</ArcText>
                <ArcText>{'Profile'}</ArcText>
              </ArcView>
            </UserImageLink>
            {this.canManage && (
              <ArcView position="absolute" style={manageMenuStyle}>
                <PeopleManageMenu
                  canClawbackTokens={this.props.canClawbackTokens}
                  person={{
                    ...person,
                    imageUrl: person.image,
                  }}
                />
              </ArcView>
            )}
            <ArcView>
              <ArcText
                position="relative"
                isStrong
                isLarge
                textAlign="center"
                lineHeight="24px"
              >
                {person.name}
              </ArcText>
              <ArcText
                isLarge
                isLighter
                textAlign="center"
                style={{ marginTop: 8, marginBottom: 8 }}
              >
                {person.team}
              </ArcText>
            </ArcView>
          </ArcView>

          <UserDescription>
            {person.location ? (
              <ArcText color="disabled">
                {`${person.location} | ${this.renderLevel(person)}`}
              </ArcText>
            ) : (
              <ArcText color="disabled">{this.renderLevel(person)}</ArcText>
            )}
          </UserDescription>

          {!person.is_self && (
            <ButtonGroup row justify="center">
              <PersonDetailsActionButtons
                canAwardChests={this.props.canAwardChests}
                canAwardTokens={this.props.canAwardTokens}
                phoneNumber={person.phone}
                onClose={this.handleCloseProfile}
                {...person}
              />
            </ButtonGroup>
          )}
        </DialogContent>

        <DialogActions>
          <ArcButton onClick={this.handleCloseProfile}>{'Close'}</ArcButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PersonDetailsDialog;
