import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { getLeftMenuStatus } from 'arcade-frontend-core/src/reducers/leftMenuStatus';

import {
  ArcView,
  ArcListItem,
  ArcText,
  Platform,
  createWithStyles,
} from 'arcade-frontend-ui';

import QuestionCircle from 'arcade-frontend-ui/src/icons/QuestionCircle';

const styles = {
  ArcLinkItem: () => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'relative',
      padding: 16,
      color: '#21afdb',

      ...Platform.select({
        web: {
          '&:hover': {
            backgroundColor: '#F8F8F8',
          },
        },
      }),
    },

    disabled: {
      ...Platform.select({
        web: {
          '&:hover': {
            backgroundColor: 'inherit',
          },
        },
      }),
    },

    isSelected: {
      backgroundColor: '#F0FBFE',

      ...Platform.select({
        web: {
          '&:hover': {
            backgroundColor: '#dcf3f9',
          },
        },
      }),
    },
  }),
};

const ArcLinkItem = createWithStyles(styles.ArcLinkItem)(ArcListItem);

const ArcSupportWidgetLinkContainer = ({ onClick }) => {
  const { supportWidgetLoaded } = useSelector(getLeftMenuStatus);

  return (
    <ArcLinkItem
      leftIcon={QuestionCircle}
      onPress={onClick}
      isDisabled={!supportWidgetLoaded}
      label="Get Help"
    />
  );
};

ArcSupportWidgetLinkContainer.propTypes = {
  onClick: PropTypes.func,
};

ArcSupportWidgetLinkContainer.defaultProps = {
  onClick: global.noop,
};

ArcSupportWidgetLinkContainer.displayName = 'ArcSupportWidgetLinkContainer';

export default ArcSupportWidgetLinkContainer;
