import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const ManageBudgets = props => (
  <Icon {...props}>
    <Path d="M15.231 23.713C14.962 23.713 14.695 23.674 14.436 23.596L3.309 20.98C1.402 20.455 0.076 18.786 0 16.825V3.805C0.002 2.962 0.309 2.149 0.865 1.512C0.904 1.444 0.958 1.385 1.024 1.342C1.686 0.676 2.564 0.308 3.499 0.306H20.5C22.425 0.31 23.995 1.88 24 3.804V14.805C23.995 16.73 22.426 18.3 20.501 18.305H18V20.805C18.043 21.522 17.797 22.229 17.304 22.782C16.811 23.335 16.134 23.664 15.397 23.708C15.343 23.711 15.287 23.713 15.231 23.713ZM1.495 2.322C1.176 2.751 1.002 3.273 1 3.807V16.805C1.059 18.314 2.086 19.606 3.557 20.011L14.695 22.63C14.887 22.687 15.058 22.713 15.232 22.713C15.268 22.713 15.303 22.712 15.339 22.71C15.811 22.682 16.244 22.471 16.558 22.116C16.873 21.762 17.03 21.306 17.002 20.835L17 8.805C16.94 7.296 15.917 5.987 14.453 5.557L1.495 2.322ZM20.5 17.305C21.875 17.301 22.996 16.179 23 14.804V3.805C22.996 2.43 21.874 1.309 20.499 1.305H3.5C3.137 1.306 2.786 1.384 2.464 1.534L9.561 3.306H19.504C19.78 3.306 20.004 3.53 20.004 3.806C20.004 4.082 19.78 4.306 19.504 4.306H13.566L14.715 4.593C16.451 5.103 17.697 6.545 17.952 8.306H19.504C19.78 8.306 20.004 8.53 20.004 8.806C20.004 9.082 19.78 9.306 19.504 9.306H18V17.306H20.5V17.305Z" fill="currentColor" />
    <Path d="M13.004 16.305C11.626 16.305 10.504 15.183 10.504 13.805C10.504 12.427 11.625 11.305 13.004 11.305C14.383 11.305 15.504 12.426 15.504 13.805C15.504 15.184 14.382 16.305 13.004 16.305ZM13.004 12.305C12.177 12.305 11.504 12.978 11.504 13.805C11.504 14.632 12.177 15.305 13.004 15.305C13.831 15.305 14.504 14.632 14.504 13.805C14.504 12.978 13.831 12.305 13.004 12.305Z" fill="black" />
  </Icon>
);

export default ManageBudgets;
