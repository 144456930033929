import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Carousel from 'nuka-carousel';
import { debounce } from 'throttle-debounce';

import Clear from '@material-ui/icons/Clear';

import withStyles from '@material-ui/core/styles/withStyles';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import ArcView from '../../primitives/ArcView';
import ArcIconButton from '../../elements/ArcIconButton';

const THUMBNAIL_WIDTH = 80;
const THUMBNAIL_HEIGHT = 46;
const THUMBNAIL_BORDER_WIDTH = 1;

const thumbnailStyle = {
  width: THUMBNAIL_WIDTH,
  height: THUMBNAIL_HEIGHT,
  // borderLeft: `${THUMBNAIL_BORDER_WIDTH}px solid #f3f3f3`,
  // borderRight: `${THUMBNAIL_BORDER_WIDTH}px solid #f3f3f3`,
  // marginLeft: -THUMBNAIL_BORDER_WIDTH,
};

const iconButtonStyle = {
  width: THUMBNAIL_HEIGHT,
  height: THUMBNAIL_HEIGHT,
  backgroundColor: 'rgba(255,255,255,0.85)',
};

const styles = theme => ({
  root: {
    '& ul.slider-list': {
      marginLeft: THUMBNAIL_BORDER_WIDTH,
    },
    '& ul.slider-list li.slider-slide': {
      display: 'inline-flex !important',
      marginLeft: -THUMBNAIL_BORDER_WIDTH,
    },
  },

  isSelected: {
    border: `solid 2px ${theme.palette.blue['500']}`,
  },

  previewRemove: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: theme.palette.common.white, // 'rgba(255,255,255,0.5)',
    // padding: theme.spacing(1),
    // borderColor: theme.palette.divider,
    // borderStyle: 'solid',
    // borderWidth: '0 0 1px 1px',
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[2],
  },

  scrim: {
    backgroundColor: 'rgba(0,0,0,0.25)',
    transition: theme.transitions.create('opacity'),
  },

  scrimHidden: {
    opacity: 0,
  },
});

class ArcGallery extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
    })),

    onRemove: PropTypes.func,

    renderAnnounceSlideMessage: PropTypes.func,
    renderItem: PropTypes.func,
    renderThumbnail: PropTypes.func,
  };

  static defaultProps = {
    items: [],
    onRemove: global.noop,
    renderAnnounceSlideMessage: global.noop,
    renderItem: props => props.children,
    renderThumbnail: props => props.children,
  };

  state = {
    hasOverflow: false,
    viewingIndex: 0,
  };

  componentDidMount() {
    this.setHasOverflow(this.props.items.length > this.slidesToShow);

    if (global.canUseDOM) {
      window.addEventListener('resize', this.handleWindowResize);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items.length !== this.props.items.length) {
      this.setHasOverflow(this.props.items.length > this.slidesToShow);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  get slidesToShow() {
    if (this.thumbnailContainerRef.current) {
      const { clientWidth } = this.thumbnailContainerRef.current;

      return Math.floor(clientWidth / THUMBNAIL_WIDTH);
    }

    return 1;
  }

  setHasOverflow = hasOverflow => this.setState({ hasOverflow });

  setViewingIndex = viewingIndex => this.setState({ viewingIndex });

  thumbnailContainerRef = React.createRef();

  thumbnailClickHandlers = {};

  createHandleThumbnailClick = (key, index) => {
    const handlerKey = typeof key !== 'undefined' ? key : index;

    this.thumbnailClickHandlers[handlerKey] = this.thumbnailClickHandlers[handlerKey] || (() => {
      this.setViewingIndex(index);
    });

    return this.thumbnailClickHandlers[handlerKey];
  };

  slideHandlers = {
    previous: null,
    next: null,
  };

  createHandleSlide = (direction, carouselStep) => {
    const step = direction === 'next' ? 1 : -1;

    this.slideHandlers[direction] = this.slideHandlers[direction] || (() => {
      carouselStep();
      this.stepViewingIndex(step);
    });

    return this.slideHandlers[direction];
  };

  stepViewingIndex = (step = 1) => {
    let nextViewingIndex = this.state.viewingIndex + step;

    const maxIndex = this.props.items.length - 1;

    if (nextViewingIndex > maxIndex) {
      nextViewingIndex = maxIndex;
    } else if (nextViewingIndex < 0) {
      nextViewingIndex = 0;
    }

    this.setViewingIndex(nextViewingIndex);
  };

  handleWindowResize = debounce(800, () => {
    this.setHasOverflow(this.props.items.length > this.slidesToShow);
  });

  handleRemoveItem = (item) => {
    this.props.onRemove(item.name);
    this.setViewingIndex(0);
  }

  renderCenterLeftControls = ({ previousSlide }) => this.state.hasOverflow && (
    <ArcIconButton
      onClick={this.createHandleSlide('previous', previousSlide)}
      style={iconButtonStyle}
    >
      <ChevronLeft />
    </ArcIconButton>
  );

  renderCenterRightControls = ({ nextSlide }) => this.state.hasOverflow && (
    <ArcIconButton
      onClick={this.createHandleSlide('next', nextSlide)}
      style={iconButtonStyle}
    >
      <ChevronRight />
    </ArcIconButton>
  );

  renderItem() {
    const item = this.props.items[this.state.viewingIndex];

    return (
      <ArcView
        border="default"
        borderWidth="1"
        align="center"
        justify="center"
        fullWidth
        padding="8"
        position="relative"
      >
        {this.props.renderItem(item)}
        <ArcView className={this.props.classes.previewRemove}>
          {this.props.onRemove !== global.noop && (
            <ArcIconButton onClick={() => this.handleRemoveItem(item)}>
              <Clear />
            </ArcIconButton>
          )}
        </ArcView>
      </ArcView>
    );
  }

  renderThumbnail = (thumbnail, index) => (
    <ArcView
      key={'key' in thumbnail ? thumbnail.key : index}
      overflow="hidden"
      position="relative"
      onClick={this.createHandleThumbnailClick(thumbnail.key, index)}
      borderColor={index !== this.state.viewingIndex ? 'transparent' : undefined}
      style={thumbnailStyle}
      align="center"
      justify="center"
      padding="4"
      className={cx([
        index === this.state.viewingIndex && this.props.classes.isSelected,
      ])}
    >
      {this.props.renderThumbnail(thumbnail, index, this.state.viewingIndex)}

      <ArcView
        position="absolute"
        fillToParent
        className={cx([
          this.props.classes.scrim,
          index === this.state.viewingIndex && this.props.classes.scrimHidden,
        ])}
      />
    </ArcView>
  );

  renderThumbnails() {
    return (
      <Carousel
        enableKeyboardControls={false}
        framePadding={this.state.hasOverflow ? `0 0 0 ${THUMBNAIL_HEIGHT}px` : undefined}
        initialSlideHeight={THUMBNAIL_HEIGHT}
        initialSlideWidth={THUMBNAIL_WIDTH}
        renderAnnounceSlideMessage={this.props.renderAnnounceSlideMessage}
        renderBottomCenterControls={null}
        renderCenterLeftControls={this.renderCenterLeftControls}
        renderCenterRightControls={this.renderCenterRightControls}
        slideWidth={`${THUMBNAIL_WIDTH}px`}
        slidesToShow={this.slidesToShow}
        slidesToScroll={1}
        wrapAround={false}
      >
        {this.props.items.map(this.renderThumbnail)}
      </Carousel>
    );
  }

  render() {
    const {
      classes,
      items,
    } = this.props;

    return (
      <ArcView
        fullWidth
        padding="8"
        className={classes.root}
      >
        {items.length > 1 && (
          <ArcView
            internalRef={this.thumbnailContainerRef}
            border="default"
            borderWidth="1"
            marginBottom="8"
          >
            {this.renderThumbnails()}
          </ArcView>
        )}

        {this.renderItem()}
      </ArcView>
    );
  }
}

export default withStyles(styles)(ArcGallery);
