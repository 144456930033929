import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const handleUpdatePeopleDisplayType = (state, action) =>
  action.payload.displayType ? action.payload.displayType : state;

const initialState = 'GRID';

const handlers = {
  [types.UPDATE_PEOPLE_DISPLAY_TYPE]: handleUpdatePeopleDisplayType,
};

const peopleDisplayType = createReducer(initialState, handlers);

export default peopleDisplayType;

export const getPeopleDisplayType = state => state.people.peopleDisplayType;
