import React from 'react';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';

const styleSuggestion = {
  padding: '8px 16px',
  minWidth: 200,
};

const ArcSuggestionCommand = (props) => {
  const {
    name,
  } = props;

  return (
    <ArcView style={styleSuggestion}>
      Command {name}
    </ArcView>
  );
};

ArcSuggestionCommand.propTypes = {
  name: ArcPropTypes.string.isRequired,
};

export default ArcSuggestionCommand;
