import React from 'react';
import Typography from '@material-ui/core/Typography';
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import ArcButton from '../ArcButton';
// import ArcTextField from '../ArcTextField';
import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';

import { createWithStyles } from '../../styles';

const styles = {
  DateInput: theme => ({
    root: {
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #f3f3f3',
    },
  }),
};

const styleContainer = {
  // position: 'relative',
  // zIndex: 10,
  border: '1px solid #b4b4b4',
};

const styleContainerFullWidth = {
  ...styleContainer,
  width: '100%',
};

const styleButton = {
  flex: 1,
};

const DateInputView = createWithStyles(styles.DateInput)(ArcView);

class ArcDatePicker extends React.PureComponent {
  static propTypes = {
    date: ArcPropTypes.date,
    defaultDate: ArcPropTypes.date,
    fullWidth: ArcPropTypes.bool,
    isCalendar: ArcPropTypes.bool,
    label: ArcPropTypes.string,
    maxDate: ArcPropTypes.date,
    minDate: ArcPropTypes.date,
    onChange: ArcPropTypes.func,
    onCancel: ArcPropTypes.func,
    onOk: ArcPropTypes.func,
    style: ArcPropTypes.style,
    value: ArcPropTypes.date,
  };

  static defaultProps = {
    date: undefined,
    defaultDate: undefined,
    fullWidth: false,
    isCalendar: false,
    label: undefined,
    maxDate: undefined,
    minDate: undefined,
    onChange: ArcPropTypes.noop,
    onCancel: ArcPropTypes.noop,
    onOk: ArcPropTypes.noop,
    style: undefined,
    value: undefined,
  };

  getDisabledDays = (day) => {
    const { maxDate, minDate } = this.props;

    if (maxDate && minDate) {
      return (
        DateUtils.isDayAfter(day, maxDate)
        && DateUtils.isDayBefore(day, minDate)
      );
    }

    if (maxDate) {
      return DateUtils.isDayAfter(day, maxDate);
    }

    if (minDate) {
      return DateUtils.isDayBefore(day, minDate);
    }

    return false;
  };

  formatDate = date => `${date.getMonth() + 1} / ${date.getDate()} / ${date.getFullYear()}`;

  handleClickCancel = () => {
    this.props.onCancel();
  };

  handleClickReset = () => {
    this.props.onChange(null);
  };

  handleClickOk = () => {
    this.props.onChange(this.value);
    this.props.onOk();
  };

  handleDayChange = (value) => {
    this.value = value;
    this.props.onChange(value);
  };

  render() {
    const {
      date,
      defaultDate,
      fullWidth,
      isCalendar,
      label,
      minDate,
      maxDate,
      onChange,
      style,
      value,
      ...rest
    } = this.props;

    if (isCalendar) {
      return (
        <ArcView style={fullWidth ? styleContainerFullWidth : styleContainer}>
          <DayPicker
            className="Selectable"
            formatDate={this.formatDate}
            inputProps={{
              label,
            }}
            value={value || defaultDate}
            onDayClick={this.handleDayChange}
            disabledDays={this.getDisabledDays}
            numberOfMonths={1}
            showOutsideDays
            fixedWeeks
            selectedDays={value}
            {...rest}
          />

          <DateInputView row justify="center">
            <Typography variant="caption">
              {value ? value.toLocaleDateString() : 'Select a date'}
            </Typography>
          </DateInputView>

          <ArcView row>
            <ArcButton
              onClick={value ? this.handleClickReset : this.handleClickCancel}
              style={styleButton}
            >
              {value ? 'Reset' : 'Cancel'}
            </ArcButton>

            <ArcButton
              color="secondary"
              disabled={!value}
              onClick={this.handleClickOk}
              style={styleButton}
            >
              Ok
            </ArcButton>
          </ArcView>
        </ArcView>
      );
    }

    return (
      <ArcView style={fullWidth ? styleContainerFullWidth : styleContainer}>
        <DayPickerInput
          hideOnDayClick
          formatDate={this.formatDate}
          inputProps={{
            label,
            readOnly: true,
            style: {
              width: '100%',
            },
          }}
          onDayChange={this.handleDayChange}
          modifiers={{
            before: minDate,
            after: maxDate,
          }}
          dayPickerProps={{
            selectedDays: value,
            disabledDays: this.getDisabledDays,
          }}
          disabledDays={this.getDisabledDays}
          value={value || defaultDate}
          {...rest}
        />
      </ArcView>
    );
  }
}

export default ArcDatePicker;
