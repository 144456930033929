import React from 'react';
import { Portal } from 'react-native-paper';

import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import { NOTIFICATIONS_PANEL } from 'arcade-frontend-core/src/types/feature-flags';
import DeviceInfo from 'arcade-frontend-core/src/helpers/device-info';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';

import NotificationsTypeOneContainer from '../NotificationsTypeOneContainer';
import NotificationsTypeThreeContainer from '../NotificationsTypeThreeContainer';

function NotificationsPortalContainer() {
  const hasNotificationsPanel = useFeatureFlag(NOTIFICATIONS_PANEL);

  if (!hasNotificationsPanel) {
    return null;
  }

  const hasNotch = DeviceInfo.hasNotch();
  const bottom = hasNotch ? 32 : 0;

  return (
    <Portal>
      <NotificationsTypeThreeContainer />
      <ArcViewBox
        position="absolute"
        bottom={bottom}
        width={['100%', '340px']}
        p={0}
      >
        <NotificationsTypeOneContainer />
      </ArcViewBox>
    </Portal>
  );
}

NotificationsPortalContainer.displayName = 'NotificationsPortalContainer';

export default NotificationsPortalContainer;
