import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import { getOrdinal } from 'arcade-frontend-ui/src/helpers/utils/date';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcResourceEmptyPending from 'arcade-frontend-ui/src/components/ArcResourceEmptyPending';
import { DISPLAYABLE_TITLE_BY_TYPE } from 'arcade-frontend-core/src/types/game-formats';
import * as REWARD_TYPES from 'arcade-frontend-core/src/types/rewards';
import Participants from 'arcade-frontend-games/src/components/ManageGamesGameList/Participants';

import CompleteGamesPending from '../CompleteGamesPending';
import GamesCompletedEmpty from '../GamesCompletedEmpty';

import GamesRewardTag from '../GamesRewardTag';

const rowHeight = 68;

const tableStyles = makeStyles(theme => ({
  rowHeight: {
    '& th, & td': {
      whiteSpace: 'nowrap',
      lineHeight: 1,
      height: rowHeight,
    },
  },
  tableHeader: { backgroundColor: theme.palette.common.white },
  rowStripe0: {},
  rowStripe1: { backgroundColor: theme.palette.common.white },
  fullWidthScrollable: {
    width: '100%',
    overflowX: 'auto',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
}));

const mapParticipants = people =>
  people.map(person => ({ ...person, image: person.profileImage }));
const formatDateTime = date =>
  moment(date).format(
    new window.Intl.DateTimeFormat().resolvedOptions().locale === 'en-US'
      ? 'MMM D, h:mma'
      : 'D MMM, h:mma',
  );

function rewardTextForSummary(userReward) {
  if (!userReward) return 'Nothing';
  if (userReward.type === REWARD_TYPES.CHESTS) {
    return Object.entries(userReward.value)
      .map(([size, number]) => `${number} ${size}`)
      .join(', ');
  }
  return userReward.value;
}

function GamesRewardSummary({ rewards, userReward }) {
  return (
    <GamesRewardTag
      variant="text-dark"
      text={rewardTextForSummary(userReward)}
      rewards={rewards}
      rewardType={userReward && userReward.type}
    />
  );
}
GamesRewardSummary.propTypes = {
  rewards: PropTypes.arrayOf(PropTypes.object),
  userReward: PropTypes.shape({
    type: PropTypes.string,
  }),
};
GamesRewardSummary.defaultProps = {
  rewards: [],
  userReward: null,
};

function GamesCompletedTable({
  games,
  totalGames,
  hasFailed,
  isPending,
  page,
  onChangePage,
  onEventsClick,
  onLeaderboardClick,
  rowsPerPage,
  onChangeRowsPerPage,
}) {
  const classes = tableStyles();

  function handleEventsClick(gameId) {
    onEventsClick(gameId);
  }

  function handleLeaderboardClick(gameId) {
    onLeaderboardClick(gameId);
  }

  const maxRowsToShow = Math.min(rowsPerPage, totalGames);
  const tableHeight = rowHeight * (maxRowsToShow + 1) + 1;
  const completedGamesToDisplay = games.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <ArcBox>
      <ArcResourceEmptyPending
        hasData={!!games.length}
        hasFailed={hasFailed}
        isPending={isPending}
        pendingNode={<CompleteGamesPending />}
        failedNode={null}
        emptyNode={<GamesCompletedEmpty />}
      >
        {() => (
          <>
            <ArcBox className={classes.fullWidthScrollable}>
              <ArcBox style={{ height: tableHeight }}>
                <Table>
                  <TableHead>
                    <TableRow
                      className={cx(classes.tableHeader, classes.rowHeight)}
                    >
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Goal</TableCell>
                      <TableCell>Your Score</TableCell>
                      <TableCell>Result</TableCell>
                      <TableCell>Reward</TableCell>
                      <TableCell>Started</TableCell>
                      <TableCell>Ended</TableCell>
                      <TableCell>Participants</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {completedGamesToDisplay.map((game, index) => (
                      <TableRow
                        key={game.id}
                        className={cx(
                          classes[`rowStripe${index % 2}`],
                          classes.rowHeight,
                        )}
                      >
                        <TableCell>{game.name}</TableCell>
                        <TableCell className={classes.capitalize}>
                          {DISPLAYABLE_TITLE_BY_TYPE[game.type]}
                        </TableCell>
                        <TableCell>{game.goal}</TableCell>
                        <TableCell>{game.userScore}</TableCell>
                        <TableCell>
                          {game.finalRank && getOrdinal(game.finalRank)}
                        </TableCell>
                        <TableCell>
                          <GamesRewardSummary
                            rewards={game.rewards}
                            userReward={game.userReward}
                          />
                        </TableCell>
                        <TableCell>{formatDateTime(game.startsAt)}</TableCell>
                        <TableCell>{formatDateTime(game.expiresAt)}</TableCell>
                        <TableCell>
                          <Participants
                            people={mapParticipants(game.participants)}
                            onClick={() => handleLeaderboardClick(game.id)}
                          />
                        </TableCell>
                        <TableCell>
                          <ArcButton
                            color="primary"
                            onClick={() => handleLeaderboardClick(game.id)}
                          >
                            Leaderboard
                          </ArcButton>
                        </TableCell>
                        <TableCell>
                          <ArcButton
                            color="primary"
                            onClick={() => handleEventsClick(game.id)}
                          >
                            Events
                          </ArcButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ArcBox>
            </ArcBox>
            <ArcBox
              bgcolor="background.paper"
              className={classes.fullWidthScrollable}
            >
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalGames}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                labelRowsPerPage=""
              />
            </ArcBox>
          </>
        )}
      </ArcResourceEmptyPending>
    </ArcBox>
  );
}

GamesCompletedTable.propTypes = {
  games: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      participants: PropTypes.arrayOf(PropTypes.object).isRequired,
      finalRank: PropTypes.number,
      goal: PropTypes.number.isRequired,
      userScore: PropTypes.number.isRequired,
      userReward: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.objectOf(PropTypes.any),
        receivingRank: PropTypes.number.isRequired,
      }),
      rewards: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          receivingRank: PropTypes.number.isRequired,
        }),
      ),
    }),
  ),
  totalGames: PropTypes.number,
  hasFailed: PropTypes.bool,
  isPending: PropTypes.bool,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onEventsClick: PropTypes.func,
  onLeaderboardClick: PropTypes.func,
  rowsPerPage: PropTypes.number,
};

GamesCompletedTable.defaultProps = {
  games: null,
  hasFailed: false,
  isPending: false,
  page: 0,
  totalGames: 0,
  onChangePage: global.noop,
  onChangeRowsPerPage: global.noop,
  onEventsClick: global.noop,
  onLeaderboardClick: global.noop,
  rowsPerPage: 5,
};

export default GamesCompletedTable;
