import { createSelector } from 'reselect';
import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/people';

const toggleLocked = locked => (state, action) => {
  const { id } = action.meta.requestAction.payload;

  const updatedObject = { ...state[id], locked };

  return { ...state, [id]: updatedObject };
};

const handleLockAccountSuccess = toggleLocked(true);
const handleUnlockAccountSuccess = toggleLocked(false);

const handleManagePeopleIndexSuccess = (state, action) => {
  const { data } = action.payload;
  const newState = {};

  data.forEach((person) => {
    newState[person.id] = person;
  });

  return newState;
};

const handleManagePeopleProfileUpdateSuccess = (state, action) => {
  const { data } = action.payload;

  return { ...state, [data.id]: data };
};

const initialState = {};

const handlers = {
  [types.MANAGE_PEOPLE_INDEX.SUCCESS]: handleManagePeopleIndexSuccess,
  [types.MANAGE_PEOPLE_PROFILE_UPDATE.SUCCESS]: handleManagePeopleProfileUpdateSuccess,
  [types.LOCK_ACCOUNT.SUCCESS]: handleLockAccountSuccess,
  [types.UNLOCK_ACCOUNT.SUCCESS]: handleUnlockAccountSuccess,
};

const peopleById = createReducer(initialState, handlers);

export default peopleById;

export const getManagePeopleById = state => state.manage.people.peopleById;

export const getManagePeople = createSelector(
  getManagePeopleById,
  byId => Object.values(byId),
);

const sortByName = (byId) => {
  const ids = Object.keys(byId);

  return ids.sort((a, b) => {
    if (byId[a].firstName < byId[b].firstName) {
      return -1;
    } if (byId[a].firstName > byId[b].firstName) {
      return 1;
    }

    return 0;
  });
};

export const getSortedManagePeople = createSelector(
  getManagePeopleById,
  byId => sortByName(byId).map(id => byId[id]),
);

export const getActiveSortedManagePeopleIds = createSelector(
  getSortedManagePeople,
  sortedPeople => sortedPeople.map(person => person.id),
);
