import ArcView from '../../primitives/ArcView';
import { createWithStyles } from '../../styles';

const styles = {
  ArcMain: theme => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
  }),
};

const ArcMain = createWithStyles(styles.ArcMain)(ArcView);

export default ArcMain;
