import React from 'react';

import Icon from '../Icon';


const ChestOutline = props => (
  <Icon {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.416 4.134H7.584c-3.925.032-7.1 3.24-7.14 7.212v8.986a.543.543 0 00.54.534h22.032a.543.543 0 00.54-.534v-8.986c-.04-3.971-3.215-7.18-7.14-7.212zm6 6.678H10.38c.244-3.194 2.876-5.66 6.042-5.66s5.798 2.466 6.042 5.66h-.048zm-9.72-5.598H7.584c-3.146.007-5.769 2.44-6.048 5.61h7.776a7.298 7.298 0 013.384-5.61zM1.512 11.893h3.36v3.958a.531.531 0 00.905.382c.1-.101.154-.239.151-.382v-3.97h3.36v7.905H1.512v-7.893zm8.844-.012v7.905h12.132V11.88H10.356z" fill="currentColor" />
  </Icon>
);

export default ChestOutline;
