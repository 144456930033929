import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getLocation } from 'arcade-frontend-features/src/reducers/location';

import { getGame } from '../../reducers/games';
import GamesVerificationDialog from '../../components/GamesVerificationDialog/GamesVerificationDialog';

function GamesVerificationContainer() {
  const dispatch = useDispatch();
  const location = useSelector(getLocation);

  const handleClose = () => {
    const routeAction = {
      ...location,
      query: {
        ...location.query,
        form: undefined,
        formGameId: undefined,
      },
    };

    dispatch(routeAction);
  };

  const gameId = location.query ? location.query.formGameId : '';
  const game = useSelector(getGame(gameId));
  const shouldOpen = gameId && location.query.form === 'verify-game';

  return (
    <GamesVerificationDialog
      open={shouldOpen}
      onClose={handleClose}
      game={game}
    />
  );
}

GamesVerificationContainer.displayName = 'GamesVerificationContainer';

GamesVerificationContainer.propTypes = {
  locationPayload: PropTypes.shape({
    id: PropTypes.string,
  }),
  locationQuery: PropTypes.shape({
    tab: PropTypes.string,
    form: PropTypes.string,
  }),
};

GamesVerificationContainer.defaultProps = {
  locationPayload: undefined,
  locationQuery: undefined,
};

export default GamesVerificationContainer;
