import React from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'throttle-debounce';

const rootStyle = {
  position: 'relative',
  overflow: 'auto',
  overflowX: 'hidden',
  display: 'flex',
  height: '100%',
  width: '100%',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  transform: 'translateZ(0)',
  WebkitOverflowScrolling: 'touch',
};

class ArcFlatList extends React.Component {
  static displayName = 'ArcFlatList';

  static propTypes = {
    contentContainerStyle: PropTypes.objectOf(PropTypes.any),
    data: PropTypes.arrayOf(PropTypes.object),
    extraData: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.arrayOf(PropTypes.any),
    ]),
    onEndReached: PropTypes.func,
    onEndReachedThreshold: PropTypes.number,
    onScroll: PropTypes.func,
    renderItem: PropTypes.func,
    // stickyHeaderIndices: PropTypes.arrayOf(PropTypes.number),
  };

  static defaultProps = {
    contentContainerStyle: null,
    data: [],
    extraData: null,
    onEndReached: global.noop,
    onEndReachedThreshold: 1,
    onScroll: global.noop,
    renderItem: global.noop,
    // stickyHeaderIndices: [],
  };

  componentDidMount() {
    if (global.canUseDOM) {
      this.scrollRef.current.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.dataHasChanged = true;
    }
  }

  shouldComponentUpdate(nextProps) {
    let extraDataChanged = nextProps.extraData !== this.props.extraData;

    if (extraDataChanged && this.props.extraData.constructor === Array) {
      const lengthChanged = this.props.extraData.length !== nextProps.extraData.length;

      if (!lengthChanged) {
        extraDataChanged = false;

        for (let idx = 0, { length } = nextProps.extraData; idx < length; idx += 1) {
          if (nextProps.extraData[idx] !== this.props.extraData[idx]) {
            extraDataChanged = true;
            break;
          }
        }
      }
    }

    return nextProps.data !== this.props.data
      || extraDataChanged;
  }

  componentWillUnmount() {
    if (global.canUseDOM) {
      this.scrollRef.current.removeEventListener('scroll', this.handleScroll);
    }
  }

  dataHasChanged = false;

  scrollRef = React.createRef();

  scrollTo = (position) => {
    this.scrollRef.current.scrollTo(position);
  };

  maybeCallOnEndReached = throttle(16, (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    const threshold = scrollHeight - (this.props.onEndReachedThreshold * clientHeight);

    if ((scrollTop + clientHeight) >= threshold) {
      this.props.onEndReached();
    }
  });

  handleScroll = (event) => {
    this.props.onScroll(event);
    this.maybeCallOnEndReached(event);
  };

  renderItem = (item, index) => this.props.renderItem({ item, index });

  render() {
    return (
      <div
        ref={this.scrollRef}
        style={rootStyle}
      >
        <div style={this.props.contentContainerStyle}>
          {this.props.data.map(this.renderItem)}
        </div>
      </div>
    );
  }
}

export default ArcFlatList;
