import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import {
  ArcPropTypes,
  ArcButton,
  ArcButtonGroup,
  ArcFormField,
  ArcFormSummary,
  ArcGrid,
  ArcPeopleList,
  ArcView,
  ArcText,
  Typography,
  date,
} from 'arcade-frontend-ui';

import CalendarRecurring from 'arcade-frontend-ui/src/assets/CalendarRecurring';

import { getManageGamesTeamsById } from '../../../reducers/manage/games/teamsById';
import { getManageGamesPeopleById } from '../../../reducers/manage/games/peopleById';

export const checkAutoVerifyType = format => {
  return ['rpa_every_time', 'rpa_one_time'].includes(format);
};

const styleMarginBottom = {
  marginBottom: 32,
};

const styleImage = {
  marginBottom: 8,
};

const styleMutedImage = {
  ...styleImage,
  opacity: 0.5,
};

const styleButton = {
  width: 116,
  height: 116,
  marginRight: 16,
  marginBottom: 32,
  paddingLeft: 16,
  paddingRight: 16,
};

const getRemainingDays = recurringTimePeriod => {
  switch (recurringTimePeriod) {
    case 'daily':
      return 'The game will run until the end of day and run daily thereafter';
    case 'weekly':
      return 'The game will run until the end of the week and run weekly thereafter';
    case 'monthly':
      return 'The game will run until the end of the month and run monthly thereafter';
    default:
      return null;
  }
};

const FIELDS = [
  'recurringTimePeriod',
  'recurringNumberOfDays',
  'startDateTime',
  'endDateTime',
];

class GamesCreateConfirmationForm extends React.PureComponent {
  static displayName = 'GamesCreateConfirmationForm';

  static FIELDS = FIELDS;

  static propTypes = {
    setFieldTouched: ArcPropTypes.func.isRequired,
    values: ArcPropTypes.objectOf(ArcPropTypes.any),
    summaryFields: ArcPropTypes.arrayOf(ArcPropTypes.string),
    peopleById: ArcPropTypes.shape({}),
    teamsById: ArcPropTypes.shape({}),
    setFieldValue: ArcPropTypes.func,
    validMetrics: ArcPropTypes.arrayOf(
      ArcPropTypes.shape({
        label: ArcPropTypes.string.isRequired,
      }),
    ),
    validFormats: ArcPropTypes.arrayOf(
      ArcPropTypes.shape({
        label: ArcPropTypes.string.isRequired,
      }),
    ),
    hasAutoVerifyFeature: ArcPropTypes.bool,
  };

  static defaultProps = {
    summaryFields: [],
    peopleById: {},
    teamsById: {},
    setFieldValue: ArcPropTypes.noop,
    validMetrics: [],
    validFormats: [],
    values: {},
    hasAutoVerifyFeature: false,
  };

  componentWillUnmount() {
    FIELDS.forEach(field => {
      this.props.setFieldTouched(field, true, false);
    });
  }

  get hasSelectedRepeat() {
    return (
      !!this.props.values.recurringTimePeriod &&
      this.props.values.recurringTimePeriod !== 'none'
    );
  }

  renderConfirmationPeople = () => {
    const { values } = this.props;

    if (!values) {
      return (
        <Typography variant="body1">{'No participants selected'}</Typography>
      );
    }

    const { assignType, peopleSelector, selectType } = values;

    const teams = peopleSelector.teamIds
      ? peopleSelector.teamIds.map(id => this.props.teamsById[id])
      : [];
    const people = peopleSelector.peopleIds
      ? peopleSelector.peopleIds.map(id => this.props.peopleById[id])
      : [];

    const peopleListProps = {
      people,
      teams,
      showSelectAll: false,
    };

    if (selectType === 'all') {
      return assignType === 'teams' ? (
        <Typography variant="body1">Assigned to all teams</Typography>
      ) : (
        <Typography variant="body1">Assigned to everyone</Typography>
      );
    }

    if (assignType === 'teams' && !teams.length) {
      return <Typography variant="caption">{'No teams selected'}</Typography>;
    }

    if (assignType === 'users' && !people.length) {
      return (
        <Typography variant="caption">{'No individuals selected'}</Typography>
      );
    }

    switch (assignType) {
      case 'teams':
        return <ArcPeopleList type="teams" {...peopleListProps} />;
      case 'users':
      default:
        return <ArcPeopleList type="people" {...peopleListProps} />;
    }
  };

  renderAutoVerify() {
    const {
      format,
      autoVerify,
      autoVerifyAt,
      endDateTime,
      recurringTimePeriod,
    } = this.props.values;
    const autoVerifyType = checkAutoVerifyType(format);

    if (!recurringTimePeriod || autoVerifyType) {
      return null;
    }

    const maxDaysFromEndDate = new Date(endDateTime);
    maxDaysFromEndDate.setDate(maxDaysFromEndDate.getDate() + 7);
    const autoVerifyDefaultDate = new Date(endDateTime);
    autoVerifyDefaultDate.setDate(autoVerifyDefaultDate.getDate() + 1);

    let formField;

    if (this.hasSelectedRepeat) {
      formField = (
        <ArcFormField
          name="autoVerifyDaysAfterFinish"
          type="number"
          label="Days after finish"
          mode="permanent"
          validations={{
            minNumber: 1,
            maxNumber: 7,
          }}
        />
      );
    } else {
      formField = (
        <ArcFormField
          name="autoVerifyAt"
          type="datetime"
          label="Auto Verification Date"
          mode="permanent"
          minDate={new Date(endDateTime)}
          initialDate={autoVerifyAt || autoVerifyDefaultDate}
        />
      );
    }

    return (
      <React.Fragment>
        <ArcGrid item xs={12}>
          <Typography variant="h5">{'Auto-verification'}</Typography>
        </ArcGrid>
        <ArcGrid
          item
          xs={12}
          style={autoVerify ? undefined : styleMarginBottom}
        >
          <ArcView marginBottom="8">
            <Typography variant="caption">
              {
                'When the auto-verification date is reached the game will be verified with whatever the final scores were.'
              }
            </Typography>
          </ArcView>
          <ArcView marginBottom="8">
            <Typography variant="caption">
              {
                'You can still manually verify this game if scores need to be updated.'
              }
            </Typography>
          </ArcView>
          <Typography variant="h5">
            <ArcFormField
              name="autoVerify"
              type="checkbox"
              label="Enable auto-verification"
              hasNoMarginBottom
            />
          </Typography>
        </ArcGrid>
        {autoVerify && (
          <React.Fragment>
            <ArcGrid item xs={12}>
              <Typography variant="subtitle2">
                {'When should auto-verification happen?'}
              </Typography>
            </ArcGrid>
            <ArcGrid item xs={12} style={styleMarginBottom}>
              {formField}
              {!this.hasSelectedRepeat &&
                date.isAfter(autoVerifyAt, maxDaysFromEndDate) && (
                  <ArcText color="warning" variant="caption">
                    {
                      "We don't recommend setting this for more than 7 days after the game end date"
                    }
                  </ArcText>
                )}
            </ArcGrid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { startDateTime, endDateTime } = this.props.values;

    const endDateTimeHasError =
      date.isAfter(startDateTime, endDateTime) ||
      date.isEqual(startDateTime, endDateTime);

    const maxDate = new Date();
    const minDate = new Date();

    maxDate.setFullYear(maxDate.getFullYear() + 1);
    minDate.setFullYear(minDate.getFullYear() - 1);

    const minEndDate = new Date(startDateTime);
    minEndDate.setMinutes(minEndDate.getMinutes() + 15);

    return (
      <ArcGrid container spacing={2} wrap="wrap">
        <ArcGrid item xs={12}>
          <Typography variant="h5">
            {'When does it '}
            <i>{'start'}</i>
            {'?'}
          </Typography>
        </ArcGrid>

        <ArcGrid item xs={12} style={styleMarginBottom}>
          <ArcFormField
            name="startDateTime"
            type="datetime"
            label="Starts at"
            initialDate={startDateTime}
            maxDate={maxDate}
            minDate={minDate}
            mode="permanent"
          />
        </ArcGrid>

        <ArcGrid item xs={12}>
          <Typography variant="h5">
            {'Play '}
            <i>{'once'}</i>
            {' or '}
            <i>{'repeat'}</i>
            {'?'}
          </Typography>
        </ArcGrid>

        <ArcGrid item xs={12} style={styleMarginBottom}>
          <ArcButtonGroup>
            <ArcButton
              aria-checked={
                this.props.values.recurringTimePeriod === 'none'
                  ? 'true'
                  : 'false'
              }
              label="Once"
              className={cx([
                this.props.values.recurringTimePeriod === 'none' && 'isActive',
              ])}
              onClick={() => {
                this.props.setFieldValue('recurringTimePeriod', 'none');
              }}
              style={styleButton}
            >
              <ArcView align="center">
                <CalendarRecurring
                  width="64px"
                  height="64px"
                  isRecurring={false}
                  style={this.hasSelectedRepeat ? styleMutedImage : styleImage}
                />
                <ArcText>{'Once'}</ArcText>
              </ArcView>
            </ArcButton>

            <ArcButton
              aria-checked={this.hasSelectedRepeat ? 'true' : 'false'}
              label="Repeat"
              className={cx([this.hasSelectedRepeat && 'isActive'])}
              onClick={() => {
                this.props.setFieldValue('recurringTimePeriod', 'daily');
              }}
              style={styleButton}
            >
              <ArcView align="center">
                <CalendarRecurring
                  width="64px"
                  height="64px"
                  style={
                    this.props.values.recurringTimePeriod === 'none'
                      ? styleMutedImage
                      : styleImage
                  }
                />
                <ArcText>Repeat</ArcText>
              </ArcView>
            </ArcButton>
          </ArcButtonGroup>
        </ArcGrid>

        {this.hasSelectedRepeat && (
          <React.Fragment>
            <ArcGrid item xs={12}>
              <Typography variant="h5">
                {'How often does it '}
                <i>{'repeat'}</i>
                {'?'}
              </Typography>
            </ArcGrid>

            <ArcGrid item xs={12}>
              <ArcButtonGroup>
                {['daily', 'weekly', 'monthly', 'custom'].map(timePeriod => (
                  <ArcButton
                    aria-checked={
                      this.props.values.recurringTimePeriod === timePeriod
                        ? 'true'
                        : 'false'
                    }
                    key={timePeriod}
                    className={cx([
                      this.props.values.recurringTimePeriod === timePeriod &&
                        'isActive',
                    ])}
                    disabled={false}
                    onClick={() => {
                      this.props.setFieldValue(
                        'recurringTimePeriod',
                        timePeriod,
                      );
                    }}
                    label={timePeriod}
                  />
                ))}
              </ArcButtonGroup>
            </ArcGrid>
            <ArcGrid item xs={12} style={styleMarginBottom}>
              {this.props.values.recurringTimePeriod === 'custom' ? (
                <ArcFormField
                  name="recurringNumberOfDays"
                  type="number"
                  label="Number of days"
                  validations={{
                    isRequired: true,
                    isInteger: true,
                    minNumber: 1,
                    maxNumber: 365,
                  }}
                />
              ) : (
                <Typography variant="body1">
                  {getRemainingDays(this.props.values.recurringTimePeriod)}
                </Typography>
              )}
            </ArcGrid>
          </React.Fragment>
        )}

        {this.props.values.recurringTimePeriod === 'none' && (
          <React.Fragment>
            <ArcGrid item xs={12}>
              <Typography variant="h5">
                {'When does it '}
                <i>{'finish'}</i>
                {'?'}
              </Typography>
            </ArcGrid>

            <ArcGrid item xs={12} style={styleMarginBottom}>
              <ArcFormField
                name="endDateTime"
                type="datetime"
                label="Ends at"
                initialDate={endDateTime}
                hasError={endDateTimeHasError}
                minDate={minEndDate}
                maxDate={maxDate}
                mode="permanent"
              />
              {endDateTimeHasError && (
                <ArcText color="danger">
                  <Typography color="inherit" variant="caption">
                    {'End time should be later than start time'}
                  </Typography>
                </ArcText>
              )}
            </ArcGrid>
          </React.Fragment>
        )}

        {this.props.hasAutoVerifyFeature && this.renderAutoVerify()}

        <ArcGrid item xs={12}>
          <Typography variant="h5">Details</Typography>
        </ArcGrid>

        <ArcGrid item xs={12} style={styleMarginBottom}>
          <ArcFormSummary
            itemsByField={{
              metric: this.props.validMetrics,
              format: this.props.validFormats,
            }}
            labelsByField={{
              format: 'Game Type',
              metric: 'Metric / Score',
              name: 'Game Name',
              target: 'Target',
            }}
            fields={this.props.summaryFields}
            values={this.props.values}
          />
        </ArcGrid>

        <ArcGrid item xs={12}>
          <Typography variant="h5">Participants</Typography>
        </ArcGrid>

        <ArcGrid item xs={12} style={styleMarginBottom}>
          {this.renderConfirmationPeople()}
        </ArcGrid>
      </ArcGrid>
    );
  }
}

const getState = state => ({
  peopleById: getManageGamesPeopleById(state),
  teamsById: getManageGamesTeamsById(state),
});

export default connect(getState)(GamesCreateConfirmationForm);
