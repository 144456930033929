import sagas from 'arcade-frontend-core/src/helpers/sagas';
import { forms } from 'arcade-frontend-ui';
import { actions as actionsApp } from 'arcade-frontend-core/src/actions';
import { actions, types } from '../../actions/manage/metrics';
import { api } from '../../resources/manage/metrics';

// import store from '../../store';
import { getForm } from '../../forms/metrics/dateRange';

const activeIndexRequest = (_, store) => {
  const { from, to } = getForm(store).values.dateRange;

  return actions.apiManageMetricsActiveIndexRequest({
    fromDate: from,
    toDate: to,
  });
};

const archivedIndexRequest = (_, store) => {
  const { from, to } = getForm(store).values.dateRange;

  return actions.apiManageMetricsArchivedIndexRequest({
    fromDate: from,
    toDate: to,
  });
};

const onSuccessEdit = [
  ({ data }) => forms.actions.change('peopleSelector', { values: data }),
  ({ data }) => forms.actions.change('metrics.create', { values: data }),
];

const onSuccessCreate = [activeIndexRequest];

const onSuccessUpdate = [activeIndexRequest, archivedIndexRequest];

const onFailureAlert = () =>
  actionsApp.setAlert({
    type: 'error',
    title: 'Oops!',
    content: 'Something went wrong...',
  });

const onSuccessArchiveAlert = () =>
  actionsApp.setAlert({
    type: 'success',
    title: 'Success',
    content: 'Metric Archived!',
  });

const onSuccessArchive = [
  onSuccessArchiveAlert,
  activeIndexRequest,
  archivedIndexRequest,
];

const onSuccessRestoreAlert = () =>
  actionsApp.setAlert({
    type: 'success',
    title: 'Success',
    content: 'Metric Restored',
  });

const onSuccessRestore = [
  onSuccessRestoreAlert,
  activeIndexRequest,
  archivedIndexRequest,
];

const takeLatest = [
  {
    type: types.MANAGE_METRICS_INDEX,
    call: api.index,
  },
  {
    type: types.MANAGE_ACTIVE_METRICS_INDEX,
    call: api.activeIndex,
  },
  {
    type: types.MANAGE_ARCHIVED_METRICS_INDEX,
    call: api.archivedIndex,
  },
  {
    type: types.MANAGE_METRICS_CREATE,
    call: api.create,
    onSuccess: onSuccessCreate,
    onFailure: onFailureAlert,
  },
  {
    type: types.MANAGE_METRICS_UPDATE,
    call: api.update,
    onSuccess: onSuccessUpdate,
    onFailure: onFailureAlert,
  },
  {
    type: types.MANAGE_METRICS_TEAMS_INDEX,
    call: api.teamsIndex,
  },
  {
    type: types.MANAGE_METRICS_EDIT,
    call: api.edit,
    onSuccess: onSuccessEdit,
  },
  {
    type: types.MANAGE_METRICS_ARCHIVE,
    call: api.archive,
    onSuccess: onSuccessArchive,
  },
  {
    type: types.MANAGE_METRICS_RESTORE,
    call: api.restore,
    onSuccess: onSuccessRestore,
  },
  {
    type: types.MANAGE_METRICS_RANKINGS_INDEX,
    call: api.rankingsIndex,
  },
  {
    type: types.MANAGE_METRICS_ACTIVITIES_INDEX,
    call: api.activitiesIndex,
  },
];

const metrics = takeLatest.map(sagas.makeTakeLatest);

export default metrics;
