import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import MoreVert from '@material-ui/icons/MoreVert';
import cx from 'classnames';

import {
  ArcButton,
  ArcEmojiButton,
  ArcGrid,
  ArcView,
  ArcText,
  createWithStyles,
} from 'arcade-frontend-ui';

import { reactionOptions } from '../../reactionData';
import NewsfeedActivityCommentsDeleteContainer from '../../containers/NewsfeedActivityCommentsDeleteContainer/NewsfeedActivityCommentsDeleteContainer';

const rootStyle = {
  maxWidth: 258,
};

const deleteGridItemStyle = {
  display: 'flex',
  flexGrow: 100,
  justifyContent: 'flex-end',
};

const STRINGS = {
  'NEWSFEED/ACTIVITY_COMMENT_DELETE_FAILED_LABEL': 'Could not delete comment',
};

const styles = {
  DeleteCommentFailedWrapper: theme => ({
    root: {
      marginLeft: 'auto',
      padding: theme.spacing(0.5),
    },
  }),
  DeleteCommentFailedText: theme => ({
    root: {
      color: theme.palette.red[400],
    },
  }),
};

const DeleteCommentFailedWrapper = createWithStyles(
  styles.DeleteCommentFailedWrapper,
)(ArcView);
const DeleteCommentFailedText = createWithStyles(
  styles.DeleteCommentFailedText,
)(ArcText);

function NewsfeedCommentMenu({
  activityId,
  canDeleteComments,
  commentId,
  reactions,
  currentUserId,
  onRequest,
  onSelect,
  deleteStatus,
}) {
  const [anchorEl, setMenuState] = useState(null);

  useEffect(() => {
    onRequest(commentId);
  }, []);

  const handleOpen = event => {
    setMenuState(event.currentTarget);
  };

  const handleClose = () => {
    setMenuState(null);
  };

  const handleReactionSelection = reaction => {
    onSelect(commentId, reaction);
    handleClose();
  };

  return (
    <ArcView row>
      <ArcButton onClick={handleOpen} size="small">
        <MoreVert color="disabled" fontSize="small" />
      </ArcButton>

      <Popover anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        <ArcView padding="8" style={rootStyle}>
          <ArcGrid container spacing={1}>
            {reactionOptions.map(emoji => (
              <ArcGrid key={emoji} item>
                <ArcEmojiButton
                  onClick={() => handleReactionSelection(emoji)}
                  isSelected={
                    reactions.filter(
                      r => r.userId === currentUserId && r.reaction === emoji,
                    ).length > 0
                  }
                  label={emoji}
                  emoji={emoji}
                />
              </ArcGrid>
            ))}
            {canDeleteComments && (
              <ArcGrid item style={deleteGridItemStyle}>
                <NewsfeedActivityCommentsDeleteContainer
                  commentId={commentId}
                  activityId={activityId}
                />
              </ArcGrid>
            )}
            {deleteStatus.failed && (
              <DeleteCommentFailedWrapper className={cx(['animated fadeIn'])}>
                <DeleteCommentFailedText isSmall>
                  {STRINGS['NEWSFEED/ACTIVITY_COMMENT_DELETE_FAILED_LABEL']}
                </DeleteCommentFailedText>
              </DeleteCommentFailedWrapper>
            )}
          </ArcGrid>
        </ArcView>
      </Popover>
    </ArcView>
  );
}

NewsfeedCommentMenu.propTypes = {
  activityId: PropTypes.string,
  canDeleteComments: PropTypes.bool,
  commentId: PropTypes.string,
  reactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      reaction: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      userName: PropTypes.string,
      userImage: PropTypes.string,
    }),
  ),
  currentUserId: PropTypes.string,
  deleteStatus: PropTypes.shape({
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    failed: PropTypes.bool,
    succeeded: PropTypes.bool,
  }),
  onRequest: PropTypes.func,
  onSelect: PropTypes.func,
};

NewsfeedCommentMenu.defaultProps = {
  activityId: null,
  canDeleteComments: false,
  commentId: null,
  reactions: [],
  currentUserId: null,
  deleteStatus: {},
  onRequest: global.noop,
  onSelect: global.noop,
};

NewsfeedCommentMenu.displayName = 'NewsfeedCommentMenu';

export default NewsfeedCommentMenu;
