import React from 'react';
import PropTypes from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withResourceAction from 'arcade-frontend-core/src/helpers/withResourceAction';
import { resources } from 'arcade-frontend-core/src/resources/tokens';

import ArcClawbackTokensForm from '../../components/ArcClawbackTokensForm';

class ArcClawbackTokensFormContainer extends React.PureComponent {
  static displayName = 'ArcClawbackTokensFormContainer';

  static propTypes = {
    onCancel: PropTypes.func,
    onStatusReset: PropTypes.func,
    peopleById: PropTypes.objectOf(PropTypes.object),
    requestData: PropTypes.func,
  };

  static defaultProps = {
    onCancel: global.noop,
    onStatusReset: global.noop,
    peopleById: {},
    requestData: global.noop,
  };

  handleSubmit = values => {
    const data = {
      clawback: {
        tokens: values.tokens,
        user_ids: values.people.map(person => person.id),
        is_public: values.isPublic,
        reason: values.reason,
      },
    };

    return this.props
      .requestData({
        resources: [data],
      })
      .then(this.props.onStatusReset)
      .then(this.props.onCancel);
  };

  render() {
    const { ...props } = this.props;

    return <ArcClawbackTokensForm {...props} onSubmit={this.handleSubmit} />;
  }
}

const getState = (state, props) => ({
  ...props,
});

const getActions = dispatch => bindActionCreators({}, dispatch);

export default compose(
  connect(getState, getActions),
  withResourceAction(resources.apiPutTokensClawback),
)(ArcClawbackTokensFormContainer);
