import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { actionTypes } from 'redux-resource';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';

import { resources } from '../../resources/comments';
import { getActivityComments } from '../../reducers/rrComments';
import NewsfeedActivityComments from '../../components/NewsfeedActivityComments';

function NewsfeedActivityCommentsContainer({ comments, ...props }) {
  const dispatch = useDispatch();
  const apiGetActivityComments = useResourceAction(
    resources.apiGetActivityComments,
  );
  const data = useSelector(state => getActivityComments(state, props));

  const requestData = () =>
    apiGetActivityComments
      .requestData({
        resources: [props.activityId],
      })
      .then(resp => {
        dispatch({
          type: actionTypes.UPDATE_RESOURCES,
          lists: {
            rrComments: {
              [props.activityId]:
                resp.data && resp.data.resources
                  ? resp.data.resources.map(comment => comment.id)
                  : [],
            },
          },
        });

        apiGetActivityComments.onStatusReset();
      });

  return (
    <NewsfeedActivityComments
      comments={data.length ? data : comments}
      commentCount={props.commentCount}
      onGetActivityComments={requestData}
      {...props}
    />
  );
}

NewsfeedActivityCommentsContainer.displayName =
  'NewsfeedActivityCommentsContainer';

NewsfeedActivityCommentsContainer.propTypes = {
  activityId: PropTypes.string,
  comments: PropTypes.arrayOf(PropTypes.object),
  commentCount: PropTypes.number,
  onStatusReset: PropTypes.func,
  requestData: PropTypes.func,
};

NewsfeedActivityCommentsContainer.defaultProps = {
  activityId: '',
  comments: [],
  commentCount: 0,
  onStatusReset: global.noop,
  requestData: global.noop,
};

export default NewsfeedActivityCommentsContainer;
