// Middleware that allows remote machinery (like our dev extension) to request changes

export default ({ getState }) => {
  let subscribed = false;

  function publish() {
    window.postMessage(JSON.stringify({ arcadeRemote: true, state: getState() }));
  }

  if (window && typeof window.addEventListener === 'function') {
    window.addEventListener('message', (message) => {
      const { data } = message;

      let object;

      try {
        object = JSON.parse(data);
      } catch (e) {
        return;
      }

      const { remoteSubscribed } = object;
      if (remoteSubscribed === undefined) return;
      subscribed = remoteSubscribed;

      if (subscribed) publish();
    }, false);
  }

  return next => (action) => {
    if (subscribed) {
      publish();
    }

    return next(action);
  };
};
