import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import {
  ArcPropTypes,
  ArcButton,
  ArcButtonGroup,
  ArcGrid,
  Typography,
  ArcFormField,
  ArcView,
  ArcText,
} from 'arcade-frontend-ui';

import IconMultipleTargets from '../../../assets/IconMultipleTargets';
import IconTarget from '../../../assets/IconTarget';

const TARGET_TYPES = {
  EVERYDAY: 'everyday',
  DAILY: 'daily',
  NONE: 'none',
};

const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const styleButton = {
  width: 72,
  height: 72,
  marginRight: 16,
  marginBottom: 32,
};

const styleImage = {
  marginBottom: 8,
};

const styleMuted = {
  opacity: 0.5,
};

const styleMutedImage = {
  ...styleImage,
  ...styleMuted,
};

const styleImageButton = {
  width: 116,
  height: 116,
  marginRight: 16,
  marginBottom: 32,
};

const FIELDS = ['targetType', 'target', ...DAYS.map(day => day.toLowerCase())];

class MetricsCreateStepThreeForm extends React.PureComponent {
  static FIELDS = FIELDS;

  static propTypes = {
    setFieldTouched: ArcPropTypes.func.isRequired,
    setFieldValue: ArcPropTypes.func.isRequired,
    touched: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
    values: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
  };

  componentWillUnmount() {
    FIELDS.forEach(field => {
      this.props.setFieldTouched(field, true, false);
    });
  }

  renderDay = type => (
    <ArcGrid key={type} item xs={12}>
      <ArcFormField
        type="number"
        name={type.toLowerCase()}
        fullWidth
        label={`Target for ${type}`}
        autoComplete="off"
      />
    </ArcGrid>
  );

  renderDailyError() {
    let hasError = true;
    let hasTouched = false;

    DAYS.forEach(day => {
      const key = day.toLowerCase();
      if (this.props.touched[key]) {
        hasTouched = true;
      }

      if (this.props.values[key]) {
        hasError = false;
      }
    });

    return (
      <ArcGrid item xs={12}>
        <ArcText color={hasTouched && hasError ? 'danger' : undefined}>
          <Typography variant="caption" color="inherit">
            {'At least one target is required.'}
          </Typography>
        </ArcText>
      </ArcGrid>
    );
  }

  renderTargets() {
    return (
      <ArcGrid container className="animated fadeIn" spacing={2}>
        <ArcGrid item xs={12}>
          <Typography variant="h5">
            Does this metric have <i>one</i> or <i>multiple</i> targets?
          </Typography>
        </ArcGrid>

        <ArcGrid item xs={12}>
          <ArcButtonGroup wrap="wrap">
            <ArcButton
              className={cx([
                this.props.values.targetType === TARGET_TYPES.EVERYDAY &&
                  'isActive',
              ])}
              onClick={() => {
                this.props.setFieldValue('targetType', TARGET_TYPES.EVERYDAY);
              }}
              style={styleImageButton}
            >
              <ArcView align="center" justify="center">
                <IconTarget
                  width="64px"
                  height="64px"
                  style={
                    this.props.values.targetType === TARGET_TYPES.EVERYDAY
                      ? styleImage
                      : styleMutedImage
                  }
                />
                <ArcText>{'One'}</ArcText>
              </ArcView>
            </ArcButton>
            <ArcButton
              className={cx([
                this.props.values.targetType === TARGET_TYPES.DAILY &&
                  'isActive',
              ])}
              onClick={() => {
                this.props.setFieldValue('targetType', TARGET_TYPES.DAILY);
              }}
              style={styleImageButton}
            >
              <ArcView align="center" justify="center">
                <IconMultipleTargets
                  width="64px"
                  height="64px"
                  style={
                    this.props.values.targetType === TARGET_TYPES.DAILY
                      ? styleImage
                      : styleMutedImage
                  }
                />
                <ArcText>{'Multiple'}</ArcText>
              </ArcView>
            </ArcButton>
          </ArcButtonGroup>
        </ArcGrid>

        {this.props.values.targetType === TARGET_TYPES.DAILY &&
          this.renderDailyError()}

        {this.props.values.targetType === TARGET_TYPES.EVERYDAY ? (
          <ArcGrid item xs={12}>
            <ArcFormField
              type="number"
              name="target"
              label="Target for everyday"
              validations={{
                isRequired: true,
              }}
            />
          </ArcGrid>
        ) : (
          DAYS.map(this.renderDay)
        )}
      </ArcGrid>
    );
  }

  render() {
    return (
      <ArcGrid
        className="animated fadeIn"
        container
        position="relative"
        spacing={2}
        style={{ maxWidth: 600 }}
      >
        <ArcGrid item xs={12}>
          <Typography variant="h5">
            Does this metric have <i>targets</i>?
          </Typography>
        </ArcGrid>

        <ArcGrid item xs={12}>
          <ArcButtonGroup wrap="wrap">
            <ArcButton
              className={cx([
                this.props.values.targetType !== TARGET_TYPES.NONE &&
                  'isActive',
              ])}
              onClick={() => {
                this.props.setFieldValue('targetType', TARGET_TYPES.EVERYDAY);
              }}
              style={styleButton}
            >
              {'Yes'}
            </ArcButton>
            <ArcButton
              className={cx([
                this.props.values.targetType === TARGET_TYPES.NONE &&
                  'isActive',
              ])}
              onClick={() => {
                this.props.setFieldValue('targetType', TARGET_TYPES.NONE);
              }}
              style={styleButton}
            >
              {'No'}
            </ArcButton>
          </ArcButtonGroup>

          <ArcView marginBottom={16}>
            <Typography variant="caption">
              {`Targets are what you expect each participant to hit for this metric.`}
            </Typography>
            <Typography variant="caption">
              {
                'You can choose to have the same value for each day of the week or to set a different value for selected days.'
              }
            </Typography>
          </ArcView>
        </ArcGrid>

        {this.props.values.targetType !== TARGET_TYPES.NONE &&
          this.renderTargets()}
      </ArcGrid>
    );
  }
}

const getState = (state, props) => ({
  ...props,
});

export default connect(getState)(MetricsCreateStepThreeForm);
