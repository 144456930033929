import React from 'react';
import PropTypes from 'prop-types';

import { ArcView, ArcText } from 'arcade-frontend-ui';

const containerStyle = {
  backgroundColor: '#fff',
  fontSize: 12,
  color: '#b4b4b4',
};

const textStyle = {
  display: 'inline-flex',
  width: 24,
  textAlign: 'center',
};

const ArcAudienceListLetter = props => (
  <ArcView
    row
    paddingTop="8"
    paddingRight="16"
    paddingBottom="8"
    paddingLeft="16"
    marginBottom="8"
    style={containerStyle}
    borderBottom="default"
    borderBottomWidth="1"
    className="animated fadeIn"
  >
    <ArcText style={textStyle}>{props.children}</ArcText>
  </ArcView>
);

ArcAudienceListLetter.displayName = 'ArcAudienceListLetter';

ArcAudienceListLetter.propTypes = {
  children: PropTypes.node,
};

ArcAudienceListLetter.defaultProps = {
  children: null,
};

export default ArcAudienceListLetter;
