/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Control } from 'react-redux-form';

import ArcPropTypes from '../helpers/arc/propTypes';
import { makeValidators } from '../helpers/utils/validators';

import ArcFormError from './ArcFormError';
import ArcFormControl from './ArcFormControl';

const isRequired = fieldValue => fieldValue.touched && fieldValue.errors && fieldValue.errors.isRequired;

const createFormField = (controlType, baseProps = {}) => (Component) => {
  class ArcFormFieldControl extends React.PureComponent {
    static propTypes = {
      label: ArcPropTypes.string.isRequired,
      model: ArcPropTypes.string.isRequired,
      description: ArcPropTypes.string,
      persist: ArcPropTypes.bool,
      validations: ArcPropTypes.objectOf(ArcPropTypes.oneOfType([
        ArcPropTypes.bool,
        ArcPropTypes.number,
      ])),
    };

    static defaultProps = {
      description: undefined,
      validations: undefined,
      persist: true,
    };

    constructor(props) {
      super(props);

      this.setValidators(props);
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.validations !== this.props.validations) {
        this.setValidators(nextProps);
      }
    }

    setValidators(props) {
      const {
        validations,
        label,
        persist,
        model,
        ...rest
      } = props || this.props;

      const {
        validations: basePropValidations,
        ...restBaseProps
      } = baseProps;

      let labelWithErrorText;

      this.validators = undefined;

      if (validations || basePropValidations) {
        let allValidations = basePropValidations || {};
        allValidations = validations ? { ...allValidations, ...validations } : allValidations;

        const {
          messages,
          validators,
        } = makeValidators(label, allValidations);

        labelWithErrorText = ({ fieldValue, model: fieldModel }) => ArcFormError(fieldValue, fieldModel, messages, label);

        this.validators = validators;
      }

      const hasError = ({ fieldValue }) => !!isRequired(fieldValue);

      this.mapProps = {
        label: labelWithErrorText,
        error: hasError,
        ...rest,
        ...restBaseProps,
      };
    }

    render() {
      const ControlComponent = Control[controlType];

      return (
        <ArcFormControl description={this.props.description}>
          <ControlComponent
            model={this.props.model}
            persist={this.props.persist}
            validators={this.validators}
            component={Component}
            mapProps={this.mapProps}
          />
        </ArcFormControl>
      );
    }
  }

  return ArcFormFieldControl;
};

export default createFormField;
