import { createSelector } from 'reselect';
import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const handlePeopleIndexSuccess = (state, action) => {
  const { data } = action.payload;
  const newState = {};

  data.forEach((person) => {
    newState[person.id] = person;
  });

  return newState;
};

const initialState = {};

const handlers = {
  [types.APP_TAGGABLE_PEOPLE_INDEX.SUCCESS]: handlePeopleIndexSuccess,
  [types.APP_PEOPLE_INDEX.SUCCESS]: handlePeopleIndexSuccess,
};

const peopleById = createReducer(initialState, handlers);

export default peopleById;

export const getAppPeopleById = state => state.app.peopleById;

export const getAppPeople = createSelector(
  getAppPeopleById,
  byId => Object.values(byId),
);

const sortByName = (byId) => {
  const ids = Object.keys(byId);

  return ids.sort((a, b) => {
    if (byId[a].name < byId[b].name) {
      return -1;
    }

    if (byId[a].name > byId[b].name) {
      return 1;
    }

    return 0;
  });
};

export const getSortedAppPeople = createSelector(
  getAppPeopleById,
  byId => sortByName(byId).map(id => byId[id]),
);

export const getActiveSortedAppPeopleIds = createSelector(
  getSortedAppPeople,
  sortedPeople => sortedPeople.map(person => person.id),
);
