import { ArcPropTypes } from 'arcade-frontend-ui';

export const gameReward = ArcPropTypes.shape({
  receiving_rank: ArcPropTypes.number.isRequired,
  receivingRank: ArcPropTypes.number.isRequired,
  type: ArcPropTypes.string.isRequired,
  value: ArcPropTypes.oneOfType([
    ArcPropTypes.string,
    ArcPropTypes.number,
  ]).isRequired,
});

export const gameVerificationReward = ArcPropTypes.shape({
  displayName: ArcPropTypes.string.isRequired,
  receivingRank: ArcPropTypes.number.isRequired,
  type: ArcPropTypes.string.isRequired,
});

export const manageGame = ArcPropTypes.shape({
  id: ArcPropTypes.string.isRequired,
  name: ArcPropTypes.string,
  status: ArcPropTypes.string,
  goal: ArcPropTypes.number,
  expiresAt: ArcPropTypes.date,
  timeRemaining: ArcPropTypes.number,
  rewards: ArcPropTypes.oneOfType([
    // Server returns empty object for no rewards
    ArcPropTypes.shape({}),
    ArcPropTypes.arrayOf(gameReward),
  ]),
  winnerId: ArcPropTypes.string,
});

export const dailyBenchmarks = ArcPropTypes.shape({
  monday: ArcPropTypes.string,
  tuesday: ArcPropTypes.string,
  wednesday: ArcPropTypes.string,
  thursday: ArcPropTypes.string,
  friday: ArcPropTypes.string,
  saturday: ArcPropTypes.string,
  sunday: ArcPropTypes.string,
});

export const manageGames = ArcPropTypes.arrayOf(manageGame);
export const manageGamesById = ArcPropTypes.objectOf(manageGame);

export const manageGameTemplate = ArcPropTypes.shape({
  name: ArcPropTypes.string.isRequired,
  imageUrl: ArcPropTypes.string.isRequired,
  endpoint: ArcPropTypes.string.isRequired,
  explainer: ArcPropTypes.string.isRequired,
});

export const gameResult = ArcPropTypes.shape({
  id: ArcPropTypes.string.isRequired,
  name: ArcPropTypes.string.isRequired,
  score: ArcPropTypes.number.isRequired,
  rank: ArcPropTypes.number.isRequired,
  imageUrl: ArcPropTypes.string.isRequired,
  reward: ArcPropTypes.string.isRequired,
});

export const gameVerificationResult = ArcPropTypes.shape({
  id: ArcPropTypes.string.isRequired,
  name: ArcPropTypes.string.isRequired,
  score: ArcPropTypes.number.isRequired,
  rank: ArcPropTypes.number.isRequired,
  imageUrl: ArcPropTypes.string.isRequired,
  userId: ArcPropTypes.string.isRequired,
});

export const manageGameTemplates = ArcPropTypes.arrayOf(manageGameTemplate);
export const gameResults = ArcPropTypes.arrayOf(gameResult);
export const gameVerificationResults = ArcPropTypes.arrayOf(gameVerificationResult);
export const gameVerificationRewards = ArcPropTypes.arrayOf(gameVerificationReward);

ArcPropTypes.extendWith({
  gameReward,
  gameResults,
  gameVerificationResult,
  gameVerificationResults,
  gameVerificationRewards,
  gameVerificationReward,
  manageGame,
  manageGames,
  manageGamesById,

  manageGameTemplate,
  manageGameTemplates,

  dailyBenchmarks,
});
