import React from 'react';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import ArcText from '../../primitives/ArcText';
import { createWithStyles } from '../../styles';

const styles = {
  ArcCardHeaderView: () => ({
    root: {
      flexShrink: 1,
      flexGrow: 1,
      padding: 8,
      paddingBottom: 0,
    },
  }),
};

const ArcCardHeaderView = createWithStyles(styles.ArcCardHeaderView)(ArcView);

const styleLineOne = {
  fontSize: 48,
  lineHeight: '56px',
  fontWeight: '500',
  color: '#666666',
  marginTop: 0,
  marginBottom: 0,
  marginRight: 8,
};

const styleLineTwo = {
  fontSize: 20,
  lineHeight: '24px',
  fontWeight: '100',
  color: '#666666',
};

const styleLineOneAlt = {
  fontSize: 26,
  lineHeight: '32px',
  fontWeight: '500',
  color: '#666666',
  marginTop: 0,
  marginBottom: 0,
};

const ArcCardHeaderLineOne = ({ children }) => (
  <ArcView row align="center">
    {typeof children === 'function'
      ? children(styleLineOne)
      : (
        <ArcText style={styleLineOne}>{children}</ArcText>
      )}
  </ArcView>
);

ArcCardHeaderLineOne.propTypes = {
  children: ArcPropTypes.children.isRequired,
};


const ArcCardHeaderLineOneAlt = ({ children }) => (
  <ArcView row align="center">
    {typeof children === 'function'
      ? children(styleLineOneAlt)
      : (
        <ArcText style={styleLineOne}>{children}</ArcText>
      )}
  </ArcView>
);

ArcCardHeaderLineOneAlt.propTypes = {
  children: ArcPropTypes.children.isRequired,
};

const ArcCardHeaderLineTwo = ({ children }) => (
  <ArcView>
    {typeof children === 'function'
      ? children(styleLineTwo)
      : (
        <ArcText style={styleLineTwo}>{children}</ArcText>
      )}
  </ArcView>
);

ArcCardHeaderLineTwo.propTypes = {
  children: ArcPropTypes.children.isRequired,
};

class ArcCardHeader extends React.PureComponent {
  static propTypes = {
    children: ArcPropTypes.children,
    isColumn: ArcPropTypes.bool,
    lineOne: ArcPropTypes.children,
    lineOneAlt: ArcPropTypes.children,
    lineTwo: ArcPropTypes.children,
    lineThree: ArcPropTypes.children,
    lineOneHasBorder: ArcPropTypes.bool,
  };

  static defaultProps = {
    children: null,
    isColumn: true,
    lineOne: null,
    lineTwo: null,
    lineOneAlt: null,
    lineThree: null,
    lineOneHasBorder: true,
  };

  state = {
    maxWidth: null,
  };

  componentDidMount() {
    this.updateMaxWidth();
  }

  setInnerRef = (c) => {
    this.container = c;
  };

  updateMaxWidth = () => {
    if (!this.container) {
      return;
    }

    const containerWidth = this.container.offsetWidth;

    if (containerWidth > 220) {
      this.setState({
        maxWidth: '100%',
      });
    } else {
      this.setState({
        maxWidth: 220,
      });
    }
  };

  render() {
    const {
      children,
      isColumn,
      lineOne,
      lineOneAlt,
      lineOneHasBorder,
      lineTwo,
      lineThree,
      ...rest
    } = this.props;

    return (
      <ArcCardHeaderView
        row
        wrap="wrap"
        align="center"
        internalRef={this.setInnerRef}
        {...rest}
      >
        <ArcView
          row={isColumn}
          wrap="wrap"
          align={isColumn ? 'center' : 'stretch'}
          style={{
            borderBottomStyle: lineOneHasBorder ? 'solid' : 'none',
            maxWidth: this.state.maxWidth,
            flexShrink: 1,
            flexGrow: 1,

            paddingBottom: 16,

            borderBottomWidth: 4,
            borderBottomColor: '#f3f3f3',
          }}
        >
          {lineOne && (<ArcCardHeaderLineOne>{lineOne}</ArcCardHeaderLineOne>)}
          {lineOneAlt && (<ArcCardHeaderLineOneAlt>{lineOneAlt}</ArcCardHeaderLineOneAlt>)}
          {lineTwo && (<ArcCardHeaderLineTwo>{lineTwo}</ArcCardHeaderLineTwo>)}
          {lineThree && (typeof lineThree === 'string' ? (
            <ArcView><ArcText>{lineThree}</ArcText></ArcView>
          ) : lineThree
          )}
        </ArcView>
        {children}
      </ArcCardHeaderView>
    );
  }
}


export default ArcCardHeader;
