import { makeApiActions } from 'arcade-frontend-ui';
import normalizers from './normalizers';

const index = () => ({
  method: 'GET',
  url: '/manager/v4/users',
  rootKey: 'users',
  normalizer: normalizers.fromManagePeople,
});

const showPermissions = ({ id }) => ({
  method: 'GET',
  url: `/manager/v4/users/${id}/permissions`,
  rootKey: 'user_permissions',
});

const updatePermissions = ({ id, permissions }) => ({
  method: 'PUT',
  url: `/manager/v4/users/${id}/permissions`,
  data: {
    permissions,
  },
});

const profile = ({ id }) => ({
  method: 'GET',
  url: `/manager/v4/users/${id}`,
  normalizer: normalizers.fromUserProfile,
});

const profileUpdate = ({ person }) => ({
  method: 'PUT',
  url: `/manager/v4/users/${person.id}`,
  normalizer: normalizers.fromUserEdit,
  rootKey: 'user',
  data: {
    user: normalizers.toUserEdit(person),
  },
});

const resetPassword = ({ id }) => ({
  method: 'PUT',
  url: `/manager/users/${id}/resend_welcome_email`,
});

const lockAccount = ({ id }) => ({
  method: 'PUT',
  url: `/manager/v4/users/${id}/lock`,
});

const unlockAccount = ({ id }) => ({
  method: 'PUT',
  url: `/manager/v4/users/${id}/unlock`,
});

const endpoints = {
  index,
  showPermissions,
  updatePermissions,
  profile,
  profileUpdate,
  resetPassword,
  lockAccount,
  unlockAccount,
};

const api = makeApiActions(endpoints);

export default api;
