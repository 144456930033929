import { getResources, getStatus, resourceReducer } from 'redux-resource';

const rrComments = resourceReducer('rrComments');

export default rrComments;

export const getComments = state => state.newsfeed.rrComments;

export const getCommentsById = state => getComments(state).resources;

export const getDeleteStatus = state =>
  getStatus(state, 'newsfeed.rrComments.requests.apiDeleteComment.status');

export const getMetaStatus = (id, type = 'read') =>
  `newsfeed.rrComments.meta[${id}].${type}Status`;
export const getCommentMeta = (state, props) =>
  state.newsfeed.rrComments.meta[props.commentId];

export const getCommentCreateActivityId = state => {
  const actionStatus = getComments(state).requests.apiCreateComment;
  if (actionStatus && actionStatus.status === 'PENDING') {
    return actionStatus.activityId;
  }
  return null;
};

export const getActivityComments = (state, props) =>
  getResources(getComments(state), props.activityId);
