import React from 'react';
import PropTypes from 'prop-types';


function ArcMultiSelectInputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

ArcMultiSelectInputComponent.displayName = 'ArcMultiSelectInputComponent';

ArcMultiSelectInputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
};

ArcMultiSelectInputComponent.defaultProps = {
  inputRef: undefined,
};

export default ArcMultiSelectInputComponent;
