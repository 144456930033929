import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles, withStyles } from '@material-ui/styles';

import * as REWARD_TYPES from 'arcade-frontend-core/src/types/rewards';
import {
  ArcChestImage,
  ArcCustomReward,
  ArcText,
  ArcToken,
  Tooltip,
  ArcHidden,
  ArcIconButton,
  Dialog,
  ArcButton,
  date,
} from 'arcade-frontend-ui';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcAwardSelections from 'arcade-frontend-ui/src/components/ArcAwardSelections';

const RewardTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: theme.font.getFontSize(1),
    boxShadow:
      '0px 4px 6px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.06)',
    overflowY: 'auto',
    maxHeight: 420,
    padding: 0,
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  toolTipTitle: {
    fontWeight: 600,
    fontSize: 18,
    color: '#616264',
    marginBottom: theme.spacing(1),
  },

  infoIcon: {
    height: 16,
    width: 16,
    alignSelf: 'center',
  },

  rewardIcon: {
    height: 24,
    width: 24,
  },

  rewardTextWrapper: {
    flexDirection: 'row',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
  },

  'text-light': {
    color: theme.palette.common.white,
  },

  'text-dark': {
    color: theme.palette.text.primary,
  },

  noPadding: {
    padding: 0,
  },
}));

function GamesRewardTag({ text, rewardType, rewards, variant }) {
  const classes = useStyles();
  const [openedDialog, setOpenedDialog] = useState('');

  const handleOpenDialog = dialogName => {
    setOpenedDialog(dialogName);
  };

  const renderRewardIcon = () => {
    switch (rewardType) {
      case REWARD_TYPES.CHESTS:
        return (
          <ArcChestImage
            className={classes.rewardIcon}
            label={null}
            variant="epic"
          />
        );
      case REWARD_TYPES.TOKENS:
        return <ArcToken className={classes.rewardIcon} />;
      case REWARD_TYPES.CUSTOM:
        return <ArcCustomReward className={classes.rewardIcon} />;
      default:
        return null;
    }
  };

  const renderRewards = () => (
    <ArcBox m={1} mb={0} p={2} overflow="auto" maxHeight="325px">
      <ArcText className={classes.toolTipTitle}>{'Prizes'}</ArcText>

      {(!rewards || rewards.length === 0) && 'No Rewards'}
      {rewards &&
        rewards.map((reward, i) => (
          <ArcBox key={i.toString()} marginTop="8px" marginBottom="16px">
            {rewards.length > 1 && (
              <ArcText size="14px" marginBottom8>
                {date.getOrdinal(reward.receivingRank + 1)}
              </ArcText>
            )}

            <ArcText size="14px">
              <ArcAwardSelections
                type={reward.type || rewardType.toLowerCase()}
                value={reward.value}
              />
            </ArcText>
          </ArcBox>
        ))}
    </ArcBox>
  );

  const renderDialog = () => (
    <>
      <ArcIconButton
        className={classes.noPadding}
        onClick={() => handleOpenDialog('rewards-dialog')}
      >
        <InfoOutlinedIcon
          className={cx([classes.infoIcon, classes[variant]])}
        />
      </ArcIconButton>

      <Dialog
        fullWidth
        open={openedDialog === 'rewards-dialog'}
        onClose={() => handleOpenDialog('')}
      >
        {renderRewards()}

        <ArcBox
          display="flex"
          flexDirection="row-reverse"
          mt={1}
          py={1.5}
          px={1}
          borderTop="1px solid #EBEBEB"
        >
          <ArcButton
            color="primary"
            variant="contained"
            onClick={() => handleOpenDialog('')}
          >
            {'Close'}
          </ArcButton>
        </ArcBox>
      </Dialog>
    </>
  );

  const renderTooltip = () => (
    <RewardTooltip interactive title={renderRewards()}>
      <InfoOutlinedIcon className={cx([classes.infoIcon, classes[variant]])} />
    </RewardTooltip>
  );

  return (
    <ArcBox display="flex" flexDirection="row">
      {renderRewardIcon()}
      <ArcBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        className={classes.rewardTextWrapper}
        mx={0.5}
      >
        <ArcText size="16px" className={classes[variant]}>
          {text || (
            <>
              <ArcText>{'Win '}</ArcText>
              <ArcText className={classes[variant]} isStrong>
                {rewardType === REWARD_TYPES.CUSTOM
                  ? 'custom reward'
                  : rewardType}
              </ArcText>
            </>
          )}
        </ArcText>
      </ArcBox>

      <ArcHidden smUp>{renderDialog()}</ArcHidden>

      <ArcHidden xsDown>{renderTooltip()}</ArcHidden>
    </ArcBox>
  );
}

GamesRewardTag.displayName = 'GamesRewardTag';

GamesRewardTag.propTypes = {
  text: PropTypes.string,
  rewardType: PropTypes.string,
  rewards: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.oneOf(['text-light', 'text-dark']),
};

GamesRewardTag.defaultProps = {
  text: null,
  rewardType: REWARD_TYPES.CHESTS,
  rewards: [],
  variant: 'text-light',
};

export default GamesRewardTag;
