import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/tokens';

const initialState = '';

const handlers = {
  [types.MANAGE_TOKENS_SET_TOP_UP_RESPONSE]: (state, action) => action.payload,
};

const topUpResponse = createReducer(initialState, handlers);

export default topUpResponse;

export const getTopUpResponse = state => state.manage.tokens.topUpResponse;
