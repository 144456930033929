import React from 'react';
import PropTypes from 'prop-types';
import { Platform } from 'react-native';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Clear from '@material-ui/icons/Clear';

import track from 'arcade-frontend-core/src/helpers/track';

import ArcView from '../../primitives/ArcView';
import ArcButton from '../../elements/ArcButton';
import ArcIconButton from '../../elements/ArcIconButton';
import ArcDialog from '../../elements/ArcDialog';

import Rollbar from '../../providers/RollbarProvider/client';


class ArcErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    initialDialogOpen: PropTypes.bool,
    initialError: PropTypes.string,
    initialErrorInfo: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    initialDialogOpen: false,
    initialError: '',
    initialErrorInfo: '',
  };

  static displayName = 'ArcErrorBoundary';

  state = {
    dialogOpen: this.props.initialDialogOpen,
    error: this.props.initialError,
    errorInfo: this.props.initialErrorInfo,
  };

  componentDidCatch(error, errorInfo) {
    track('app crash');
    Rollbar.error(error, errorInfo);

    this.setState({
      error,
      errorInfo,
      dialogOpen: true,
    });

    console.log(errorInfo);
    console.error(error);
  }

  closeDialog = () => this.setState({ dialogOpen: false });

  handleClose = () => this.closeDialog();

  handleReload = () => {
    track('clicked app reload');

    if (window) {
      setTimeout(() => {
        // wait for analytics event to submit before reloading
        window.location.reload();
      }, 400);
    }
  };

  renderDialog() {
    return (
      <ArcDialog
        open={this.state.dialogOpen}
        onClose={this.handleClose}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">
          <ArcView row align="center">
            <ArcView>{'Error'}</ArcView>
            <ArcView spacer />
            <ArcIconButton onClick={this.handleClose}><Clear /></ArcIconButton>
          </ArcView>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="error-dialog-description">
            {'Unexpected error - please refresh.'}
          </DialogContentText>
          {process.env.NODE_ENV !== 'production' && (
            <div>
              <div>
                <pre>
                  <code>
                    {this.state.error ? this.state.error.toString() : null}
                  </code>
                </pre>
              </div>
              <div>
                <pre>
                  <code>
                    {this.state.errorInfo?.componentStack
                      ? this.state.errorInfo?.componentStack
                      : JSON.stringify(this.state.errorInfo, null, 2)
                    }
                  </code>
                </pre>
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <ArcButton
            onClick={this.handleReload}
            variant="outlined"
          >
            {'Reload'}
          </ArcButton>
        </DialogActions>
      </ArcDialog>
    );
  }

  render() {
    if (Platform.OS !== 'web') {
      return this.props.children;
    }

    return this.state.error ? this.renderDialog() : this.props.children;
  }
}

export default ArcErrorBoundary;
