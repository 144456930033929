import getTransformedResponseFromResourceAction from 'arcade-frontend-core/src/helpers/getTransformedResponseFromResourceAction';

import * as resources from './activityFilters.resources';
import apiGetActivityFiltersRaw from './raw/apiGetActivityFiltersRaw.json';

export { apiGetActivityFiltersRaw };

export const apiGetActivityFilters = getTransformedResponseFromResourceAction(
  resources.apiGetActivityFilters,
  apiGetActivityFiltersRaw,
);
