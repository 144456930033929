import humps from 'humps';
import Resource from 'arcade-frontend-ui/src/helpers/utils/resource';
import { setArcUserHeaders } from '../axios';

export async function setup(user) {
  Resource.setEndpoint(user.endpoint);
  setArcUserHeaders(user.authenticationToken, user.email);

  if (window.ArcadeAuthentication) {
    window.ArcadeAuthentication.setEndpoint(user.endpoint);
    window.ArcadeAuthentication.setChatEndpoint(user.chatEndpoint);
    window.ArcadeAuthentication.setUser(humps.decamelizeKeys(user));
  }

  return user;
}

export async function destroy() {
  Resource.setEndpoint(null);
  setArcUserHeaders(null, null);

  if (window.ArcadeAuthentication && window.ArcadeAuthentication.logout) {
    window.ArcadeAuthentication.logout();
  }
}
