import { createRequestingStatusReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/tokens';

const initialState = {
  MANAGE_TOKENS_TRANSFER: 'DEFAULT',
  MANAGE_TOKENS_TOP_UP: 'DEFAULT',
};

const actions = [
  types.MANAGE_TOKENS_TRANSFER,
  types.MANAGE_TOKENS_TOP_UP,
];

const requestStatus = createRequestingStatusReducer(initialState, actions);

export default requestStatus;

export const getRequestStatus = state => state.manage.tokens.requestStatus;
