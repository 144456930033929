import { types } from 'arcade-frontend-core/src/actions';

export default () => next => action => {
  if (
    action.type.indexOf('route/ROUTE_') === 0 ||
    action.type === types.SET_SUPPORT_WIDGET_LOADED
  ) {
    if (window.arcPostMessage) {
      window.arcPostMessage(`native.dispatch.${JSON.stringify(action)}`);
    }
  }

  return next(action);
};
