import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ArcPropTypes, ArcView } from 'arcade-frontend-ui';

import { getMetricsIndexActions } from '../../actions/metrics';
import { getMetricsIndexState } from '../../reducers/metrics';

import MetricViewHeader from '../../components/metrics/MetricViewHeader';
import MetricCardList from '../../components/metrics/MetricCardList';

class MetricsIndexContainer extends React.PureComponent {
  state = {
    dateRange: {
      type: 'time period',
      timePeriod: 'this_month',
      fromDate: undefined,
      toDate: undefined,
    },
  };

  static propTypes = {
    apiMetricsIndexRequest: PropTypes.func.isRequired,
    routeMetricsShow: PropTypes.func.isRequired,

    metricsById: ArcPropTypes.metricsById,
    sortedMetrics: ArcPropTypes.ids,

    type: PropTypes.string,

    onDateRangeChange: PropTypes.func,
    dateRange: PropTypes.shape({
      type: PropTypes.oneOf(['time period', 'date range']),
      timePeriod: PropTypes.string,
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
    }),
  };

  static defaultProps = {
    metricsById: {},
    sortedMetrics: [],
    type: null,
    onDateRangeChange: global.noop,
    dateRange: undefined,
  };

  static displayName = 'MetricsIndexContainer';

  componentWillReceiveProps(nextProps) {
    const { dateRange } = nextProps;

    const isChangingDateRange = dateRange !== this.props.dateRange;

    if (isChangingDateRange) {
      this.props.apiMetricsIndexRequest(dateRange);
    }
  }

  componentWillMount() {
    this.props.apiMetricsIndexRequest(this.props.dateRange);
  }

  handleClick = (e, id) => this.props.routeMetricsShow(id, 'create');

  handleClickNav = (e, id, type) => {
    e.stopPropagation();
    this.props.routeMetricsShow(id, type);
  };

  renderCardList() {
    const metrics = this.props.sortedMetrics.map(
      id => this.props.metricsById[id],
    );
    return (
      <MetricCardList
        metrics={metrics}
        onClick={this.handleClick}
        onClickNav={this.handleClickNav}
      />
    );
  }

  render() {
    const { type, dateRange, onDateRangeChange } = this.props;

    return (
      <ArcView>
        <MetricViewHeader
          dateType={type}
          value={dateRange}
          onChange={onDateRangeChange}
        />
        {this.renderCardList()}
      </ArcView>
    );
  }
}

export default connect(
  getMetricsIndexState,
  getMetricsIndexActions,
)(MetricsIndexContainer);
