import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import IconContentFilterList from '@material-ui/icons/FilterList';

import {
  ArcPropTypes,
} from 'arcade-frontend-ui';

const styleSelect = {
  width: 150,
  flexShrink: 0,
  flexGrow: 0,
};

const styleOverlay = {
  maxWidth: 320,
};

const styleViewingObject = {
  paddingTop: 8,
};

const teamsNorthCarolina = [
  {
    value: 'gaffney',
    primaryText: 'Gaffney',
  },
  {
    value: 'york',
    primaryText: 'York',
  },
];

const teamsSouthCarolina = [
  {
    value: 'cary_boulder',
    primaryText: 'Cary Boulder',
  },
  {
    value: 'garner',
    primaryText: 'Garner',
  },
  {
    value: 'holly_springs',
    primaryText: 'Holly Springs',
  },
];

const entitiesLevel1 = [
  {
    value: 'north_carolina',
    primaryText: 'North Carolina',
    teams: teamsNorthCarolina,
  },
  {
    value: 'south_carolina',
    primaryText: 'South Carolina',
    teams: teamsSouthCarolina,
  },
];

const entitiesLevel0 = [
  {
    value: 'organisation',
    primaryText: 'Victra Corporate',
    children: entitiesLevel1,
  },
];

class EntitySelector extends React.PureComponent {
  static propTypes = {
    onChange: ArcPropTypes.func.isRequired,
    viewingType: ArcPropTypes.string.isRequired,
  };

  state = {
    open: false,
    viewingType: 'organisation',
    viewingSubType: entitiesLevel0[0],
    viewingTeam: teamsNorthCarolina[0],
  };

  handleOpen = () => this.setState({ open: true });

  handleClose = () => {
    this.setState({ open: false });
    this.props.onChange(this.state.viewingType);
  };

  selectFirstTypeItem = (type) => {
    if (type === 'region') {
      this.setState({ viewingSubType: entitiesLevel1[0] });
    }

    if (type === 'team') {
      if (this.state.viewingSubType.value === 'organisation') {
        const child = this.state.viewingSubType.children[0];

        this.setState({ viewingSubType: child, viewingTeam: child.teams[0] });
      } else {
        this.setState({ viewingTeam: this.state.viewingSubType.teams[0] });
      }
    }
  };

  createHandleClickType = value => () => {
    this.setState({ viewingType: value });
    this.selectFirstTypeItem(value);
  };

  createHandleClickSubType = value => () => {
    this.setState({ viewingSubType: value });
    this.selectFirstTypeItem(value);
  }

  createHandleClickTeam = value => () => {
    this.setState({ viewingTeam: value });
    this.selectFirstTypeItem(value);
  };

  handleChangeViewingType = (event) => {
    this.setState({ viewingType: event.target.value });
  }

  handleChangeRegion = (event) => {
    const viewingSubType = entitiesLevel1.filter(team => team.value === event.target.value)[0];

    this.setState({ viewingSubType });
  }

  handleChangeTeam = (event) => {
    const viewingTeam = this.state.viewingSubType.teams.filter(t => t.value === event.target.value)[0];

    this.setState({ viewingTeam });
  }

  viewingObject = (type) => {
    switch (type) {
      case 'organisation':
        return 'Victra Corporate';
      case 'region':
        return this.state.viewingSubType.primaryText;
      case 'team':
      default:
        return this.state.viewingTeam.primaryText;
    }
  }

  regionChange = (event) => {
    const viewingSubType = entitiesLevel1.filter(team => team.value === event.target.value)[0];

    this.setState({ viewingSubType });
  }

  renderMenuItemSubType = item => (
    <MenuItem value={item.value} onClick={this.createHandleClickSubType(item)}>
      {item.primaryText}
    </MenuItem>
  );

  renderMenuItemTeam = item => (
    <MenuItem value={item.value} onClick={this.createHandleClickTeam(item)}>
      {item.primaryText}
    </MenuItem>
  );

  renderTeams() {
    const { viewingSubType } = this.state;

    return (
      <div>
        <Select style={styleSelect} value={this.state.viewingSubType.value} onChange={this.regionChange}>
          {entitiesLevel1.map(this.renderMenuItemSubType)}
        </Select>

        {viewingSubType.teams && (
          <Select style={styleSelect} value={this.state.viewingTeam.value} onChange={this.handleChangeTeam}>
            {viewingSubType.teams.map(this.renderMenuItemTeam)}
          </Select>
        )}
      </div>
    );
  }

  renderSubType() {
    const { viewingType } = this.state;

    switch (viewingType) {
      case 'organisation':
        return entitiesLevel0[0].primaryText;
      case 'region':
        return (
          <Select style={styleSelect} value={this.state.viewingSubType.value} onChange={this.handleChangeRegion}>
            {entitiesLevel1.map(this.renderMenuItemSubType)}
          </Select>
        );
      case 'teams':
      default:
        return this.renderTeams();
    }
  }

  render() {
    return (
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={this.handleOpen}
          icon={<IconContentFilterList />}
        >
          {this.props.viewingType}
        </Button>
        <Dialog
          title={`Filter stats by ${this.state.viewingType}`}
          modal={false}
          open={this.state.open}
          onClose={this.handleClose}
          contentStyle={styleOverlay}
        >
          <DialogTitle>
            Select your viewing type
          </DialogTitle>
          <DialogContent>
            <form>
              <FormControl>
                <Select
                  style={styleSelect}
                  value={this.state.viewingType}
                  onChange={this.handleChangeViewingType}
                >
                  <MenuItem
                    value="organisation"
                  >
            Organisation
                  </MenuItem>
                  <MenuItem
                    value="region"
                  >
            Region
                  </MenuItem>
                  <MenuItem
                    value="team"
                  >
            Team
                  </MenuItem>
                </Select>

                {this.renderSubType()}
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              variant="flat"
              color="primary"
              onClick={this.handleClose}
            >
      Ok
            </Button>
          </DialogActions>
        </Dialog>
        <div style={styleViewingObject}>
          {this.viewingObject(this.state.viewingType)}
        </div>
      </div>
    );
  }
}

export default EntitySelector;
