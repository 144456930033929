import getTransformedResponseFromResourceAction from 'arcade-frontend-core/src/helpers/getTransformedResponseFromResourceAction';

import * as resources from './game-events.resources';

import apiGetGameEventsRaw1 from './raw/apiGetGameEventsRaw-1.json';
import apiGetGameEventPaginatedRaw1 from './raw/apiGetGameEventsPaginatedRaw-1.json';

export { default as apiGetGameEventsRawStatus } from './raw/apiGetGameEventsRaw-status.json';
export { default as apiApproveGameEventRaw } from './raw/apiApproveGameEventRaw-1.json';
export { default as apiRejectGameEventRaw } from './raw/apiRejectGameEventRaw-1.json';
export { default as apiRevertGameEventRaw } from './raw/apiRevertGameEventRaw-1.json';

export const apiGetGameEventsRaw = apiGetGameEventsRaw1;
export const apiGetGameEventPaginatedRaw = apiGetGameEventPaginatedRaw1;

const META_PAYLOAD = {
  meta: {
    gameId: 0,
  },
};

export const apiGetGameEvents = getTransformedResponseFromResourceAction(
  resources.apiGetGameEvents,
  apiGetGameEventsRaw,
  META_PAYLOAD,
);

const META_PAYLOAD_PAGINATED = {
  meta: {
    gameId: 0,
    page: 0,
    rowsPerPage: 0,
  },
};

export const apiGetGameEventsPaginated = getTransformedResponseFromResourceAction(
  resources.apiGetGameEventsPaginated,
  apiGetGameEventPaginatedRaw,
  META_PAYLOAD_PAGINATED,
);
