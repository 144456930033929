import React from 'react';
import PropTypes from 'prop-types';

import {
  ArcLoaderButton,
  ArcText,
  ArcView,
  createWithStyles,
} from 'arcade-frontend-ui';
import track from 'arcade-frontend-core/src/helpers/track';
import { Editor } from 'arcade-frontend-widgets';

import { buildCommentJSonDataFromEditorFields } from '../../utils';

const styles = {
  Wrapper: () => ({
    root: {
      minWidth: 260,
    },
  }),
  SaveIconButton: () => ({
    root: {
      position: 'absolute',
      right: 8,
    },
  }),
};

const Wrapper = createWithStyles(styles.Wrapper)(ArcView);
const SaveIconButton = createWithStyles(styles.SaveIconButton)(ArcLoaderButton);

const SaveButton = ({ hasChanged, onSubmit, isSaving }) => (
  <SaveIconButton
    color={hasChanged ? 'blue' : 'default'}
    size="small"
    loadingState={isSaving ? 'REQUEST' : 'DEFAULT'}
    label={
      <ArcText size="10px" color={hasChanged ? undefined : 'disabled'}>
        {'Comment'}
      </ArcText>
    }
    disabled={!hasChanged}
    onClick={onSubmit}
  />
);

SaveButton.propTypes = {
  hasChanged: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

function NewsfeedCommentForm({ activityId, onSave, isSaving, participants }) {
  const onSubmit = editorFields => {
    track('new comment saved', {
      payload: { event: 'newsfeed:create-comment' },
    });
    onSave(
      activityId,
      buildCommentJSonDataFromEditorFields({ id: activityId }, editorFields),
    );
  };

  return (
    <Wrapper>
      <Editor
        attachmentSupport={false}
        placeholder="Comment on this activity..."
        isSaving={isSaving}
        onSubmit={onSubmit}
        controls={SaveButton}
        horizontal
        taggingRules={{ prioritiseUsers: participants }}
      />
    </Wrapper>
  );
}

NewsfeedCommentForm.propTypes = {
  activityId: PropTypes.string,
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  participants: PropTypes.arrayOf(PropTypes.string),
};

NewsfeedCommentForm.defaultProps = {
  activityId: '',
  isSaving: false,
  onSave: global.noop,
  participants: [],
};

NewsfeedCommentForm.displayName = 'NewsfeedCommentForm';

export default NewsfeedCommentForm;
