import sagas from 'arcade-frontend-core/src/helpers/sagas';

import { api } from '../../resources/manage/people';
import { types } from '../../actions/manage/people';

const takeLatest = [
  {
    type: types.MANAGE_PEOPLE_INDEX,
    call: api.index,
  },
  {
    type: types.MANAGE_PEOPLE_PERMISSIONS_INDEX,
    call: api.showPermissions,
  },
  {
    type: types.MANAGE_PEOPLE_PERMISSIONS_UPDATE,
    call: api.updatePermissions,
  },
  {
    type: types.MANAGE_PEOPLE_PROFILE,
    call: api.profile,
  },
  {
    type: types.MANAGE_PEOPLE_PROFILE_UPDATE,
    call: api.profileUpdate,
  },
  {
    type: types.RESET_PASSWORD,
    call: api.resetPassword,
  },
  {
    type: types.LOCK_ACCOUNT,
    call: api.lockAccount,
  },
  {
    type: types.UNLOCK_ACCOUNT,
    call: api.unlockAccount,
  },
];

const people = takeLatest.map(sagas.makeTakeLatest);

export default people;
