import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { throttle } from 'throttle-debounce';


import ArcCheckbox from '../../elements/ArcCheckbox';
import ArcCurrencyField from '../../elements/ArcCurrencyField';
import ArcDateRange from '../../elements/ArcDateRange';
import ArcDateTime from '../../elements/ArcDateTime';
import ArcMultiAddDemo from '../../elements/ArcMultiAddDemo';
import ArcImageSelect from '../../elements/ArcImageSelect';
import ArcMultiAdd from '../../elements/ArcMultiAdd';
import ArcNumberField from '../../elements/ArcNumberField';
import ArcRadioButtonGroup from '../../elements/ArcRadioButtonGroup';
import ArcSelect from '../../elements/ArcSelect';
import ArcTextField from '../../elements/ArcTextField';
import ArcTimePicker from '../../elements/ArcTimePicker';
import ArcUserInput from '../../components/ArcUserInput/ArcUserInput';

import { makeValidators } from '../../helpers/utils/validators';

import ArcFormControl from '../ArcFormControl';

import * as actions from './actions';
import * as types from './types';


const noop = () => {};

const fieldsByType = {
  checkbox: ArcCheckbox,
  currency: ArcCurrencyField,
  date: ArcDateTime,
  dateRange: ArcDateRange,
  multiAddDemo: ArcMultiAddDemo,
  image: ArcImageSelect,
  multiAdd: ArcMultiAdd,
  number: ArcNumberField,
  password: ArcTextField,
  radio: ArcRadioButtonGroup,
  select: ArcSelect,
  text: ArcTextField,
  time: ArcTimePicker,
  user_input: ArcUserInput,
};

const handleChangeTargetValue = (e) => {
  if (typeof e.target !== 'undefined') {
    return e.target.value;
  }

  return e;
};
const handleChangeValue = value => value;

let handleChangeSelectors = {};

Object.keys(fieldsByType).forEach((key) => {
  handleChangeSelectors[key] = handleChangeTargetValue;
});

handleChangeSelectors = {
  ...handleChangeSelectors,
  checkbox: e => e.target.checked,
  date: handleChangeValue,
  dateRange: handleChangeValue,
  multiAddDemo: rows => rows,
  image: handleChangeValue,
  multiAdd: rows => rows,
  peopleSelector: (e, selected) => selected,
  time: handleChangeValue,
};

const getDefaultValidations = (type) => {
  switch (type) {
    case 'currency':
    case 'number':
      return {
        isNumber: true,
      };
    default:
      return {};
  }
};

const getDefaultValue = (type) => {
  switch (type) {
    case 'checkbox':
      return false;
    case 'time':
      return null;
    case 'dateRange':
      return {
        from: null,
        to: null,
      };
    default:
      return '';
  }
};

const getErrors = (field, value, props = {}) => {
  const errors = [];

  if (props.isDisabled) {
    return errors;
  }

  const defaultValidations = getDefaultValidations(field.type);

  if (field.validations || defaultValidations) {
    const validations = {
      ...defaultValidations,
      ...(field.validations || {}),
      ...props.validations,
    };

    const { messages, validators } = makeValidators(field.label, validations);

    Object.keys(validators).forEach((key) => {
      const isValid = validators[key](value);

      if (!isValid) {
        errors.push(messages[key]);
      }
    });
  }

  return errors;
};

const createForm = (model, formProps) => (Component) => {
  const { fields, onError, onSubmit, selector } = formProps;
  const renderFields = {};

  const createField = (key) => {
    const {
      type,
      hasNoMarginBottom,
      ...rest
    } = fields[key];

    const FieldComponent = fieldsByType[type];

    renderFields[key] = props => (
      <FieldComponent
        {...rest}
        {...props}
      />
    );

    renderFields[key].displayName = FieldComponent.name;
  };

  const fieldKeys = Object.keys(fields);
  const fieldKeysLength = fieldKeys.length;

  fieldKeys.forEach(createField);

  class ArcForm extends React.PureComponent {
    static displayName = Component.name;

    static propTypes = types.propTypes;

    static defaultProps = types.defaultProps;

    constructor(props) {
      super(props);

      this.initialState = {
        errors: props.errors,
        hasSubmitted: false,
        touched: props.touched,
        values: props.values,
      };

      this.state = this.initialState;

      const createSetField = type => (fieldKey, value, callback = noop) => this.setState(prevState => ({
        [type]: {
          ...prevState[type],
          [fieldKey]: value,
        },
      }), () => callback(this.state));

      this.setFieldError = createSetField('errors');
      this.setFieldTouched = createSetField('touched');
      this.setFieldValue = createSetField('values');

      // Convenient object to set the entire form as touched without creating a new object every time
      this.allTouchedState = {};

      // Objects to initialize refs and field components / props once
      this.inputRefs = {};
      this.renderFields = {};
      this.renderedFieldProps = {};


      if (typeof this.state.values === 'undefined') {
        throw new TypeError([
          'Values could not be selected for',
          `\`${Component.name}\`.`,
          'Did you add the',
          `\`${model}\``,
          'reducer to the store?',
        ].join(' '));
      }

      fieldKeys.forEach((key) => {
        this.allTouchedState[key] = true;

        const { description, hasNoMarginBottom, label, type } = fields[key];

        this.state.values[key] = typeof this.state.values[key] === 'undefined'
          ? getDefaultValue(key)
          : this.state.values[key];

        if (type === 'time') {
          // set the initial time value to a moment object because it is impure to call it in the reducer
          const val = this.state.values[key];

          if (val && typeof val === 'string' && val.length) {
            this.state.values[key] = moment(val);
          } else {
            this.state.values[key] = null;
          }
        }

        this.state.touched[key] = this.state.touched[key] || false;
        this.state.errors[key] = this.state.errors[key] || getErrors(fields[key], this.state.values[key]);

        const setRef = (ref) => {
          this.inputRefs[key] = ref;
        };

        let FieldComponent = renderFields[key];

        this.renderFields[key] = (fieldProps = {}) => {
          this.renderedFieldProps[key] = fieldProps;

          const handleBlur = (...args) => {
            this.setFieldTouched(key, true);

            if (fieldProps.onBlur) {
              fieldProps.onBlur(...args);
            }
          };

          const handleChange = (...args) => {
            const value = handleChangeSelectors[type](...args);
            this.setFieldValue(key, value);

            const errors = getErrors({ ...fields[key], ...fieldProps }, value, fieldProps);
            this.setFieldError(key, errors);

            if (fieldProps.onChange) {
              fieldProps.onChange(...args);
            }
          };

          const handleMount = () => {
            const value = this.state.values[key];
            const errors = getErrors({ ...fields[key], ...fieldProps }, value, fieldProps);
            this.setFieldError(key, errors);
          };

          const handleUnmount = () => {
            if (!this.hasMounted) {
              // The entire form has unmounted so we don't want individual components dispatching this
              // event handler

            }
            // const value = this.state.values[key];
            // const errors = getErrors({ ...fields[key], ...fieldProps }, value, fieldProps.validations);
            // if (key === 'target') {
            //   console.log(fieldProps.validations);
            // }
            // this.setFieldError(key, []);

            // Commenting this out for VICTRA
            // this.throttledUpdateStore();
          };

          const hasError = this.state.touched[key] && !!this.state.errors[key].length;
          const errorLabel = hasError ? this.state.errors[key][0] : undefined;

          const formControlProps = {
            description,
            hasNoMarginBottom: (typeof fieldProps.hasNoMarginBottom === 'undefined')
              ? hasNoMarginBottom
              : fieldProps.hasNoMarginBottom,
          };

          const value = typeof fieldProps.value === 'undefined' ? this.state.values[key] : fieldProps.value;

          const fieldComponentProps = { ...fieldProps };

          delete fieldComponentProps.hasNoMarginBottom;

          if (fieldProps.type) {
            FieldComponent = fieldsByType[fieldProps.type];
          }

          const fieldComponentId = fieldProps.id || key;

          let { variant } = fieldComponentProps;

          if (type === 'text' || type === 'currency' || type === 'number' || type === 'password') {
            variant = 'outlined';
          }

          return (
            <ArcFormControl
              {...formControlProps}
              onMount={handleMount}
              onUnmount={handleUnmount}
            >
              <FieldComponent
                {...fieldComponentProps}
                id={fieldComponentId}
                label={(errorLabel || label)}
                onBlur={handleBlur}
                onChange={handleChange}
                error={type === 'checkbox' ? undefined : hasError}
                value={type === 'checkbox' ? fieldComponentId : value}
                checked={type === 'checkbox' ? !!this.state.values[key] : undefined}
                inputRef={setRef}
                type={type === 'password' ? type : undefined}
                variant={variant}
              />
            </ArcFormControl>
          );
        };

        this.renderFields[key].displayName = FieldComponent.name;
      });
    }

    componentDidMount() {
      this.hasMounted = true;
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.values !== this.props.values) {
        this.updateState(nextProps.values);
      }
    }

    componentWillUnmount() {
      this.hasMounted = false;
      this.updateStore();
      if (this.props.shouldResetOnUnmount) {
        this.handleReset();
      }
    }

    get isValid() {
      let errors;
      let index;
      let key;

      for (index = 0; index < fieldKeysLength; index += 1) {
        key = fieldKeys[index];
        errors = this.state.errors[key];

        if (errors && errors.length) {
          this.lastInvalidKey = key;
          return false;
        }
      }

      return true;
    }

    get onError() {
      return this.props.onError || onError;
    }

    get onSubmit() {
      return this.props.onSubmit || onSubmit;
    }

    setAllTouched = callback => this.setState({
      touched: this.allTouchedState,
    }, callback);

    setFocus = (key) => {
      const inputRef = this.inputRefs[key];

      if (!inputRef) {
        return;
      }

      if (inputRef.node && inputRef.node.focus) {
        inputRef.node.focus();
      } else if (inputRef.focus) {
        inputRef.focus();
      }
    };

    setHasSubmitted = hasSubmitted => this.setState({ hasSubmitted });

    updateState = (values = {}, skipValues = false) => {
      const keysToUpdate = Object.keys(values);
      const nextErrors = {};

      keysToUpdate.forEach((key) => {
        const value = values[key];
        const props = this.renderedFieldProps[key] ? this.renderedFieldProps[key] : {};
        nextErrors[key] = getErrors({ ...fields[key], ...props }, value, props);
      });

      if (!skipValues) {
        this.setState(prevState => ({
          values: {
            ...prevState.values,
            ...values,
          },
        }));
      }

      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          ...nextErrors,
        },
      }));
    };

    updateStore = () => {
      if (this.isResetting) {
        return;
      }

      const action = actions.change(model, this.state);
      this.props.dispatch(action);
    };

    handleReset = (state) => {
      this.isResetting = true;
      const nextState = state || this.initialState;

      const action = actions.change(model, nextState);
      this.props.dispatch(action);
      this.isResetting = false;
      this.setState(nextState);
    };

    handleSubmit = (e, sharedValues) => {
      this.setAllTouched(this.updateStore);

      if (!this.isValid) {
        if (this.lastInvalidKey) {
          this.setFocus(this.lastInvalidKey);
          this.lastInvalidKey = null;
        }
        return;
      }

      let action;

      if (this.onSubmit) {
        action = this.onSubmit(e, { ...this.state.values, ...sharedValues }, this.props.dispatch);
        this.setHasSubmitted(true);
      } else if (this.onError) {
        action = this.onError(e, this.state.errors, this.props.dispatch);
        this.setHasSubmitted(false);
      }

      if (action && action.type) {
        // only dispatch an action if onSubmit or onError returned an action object
        this.props.dispatch(action);
      }
    };

    throttledUpdateStore = throttle(300, this.updateStore);

    render() {
      return (
        <Component
          {...this.props}
          {...this.state}
          hasSubmitted={this.state.hasSubmitted}
          isValid={this.isValid}
          onReset={this.handleReset}
          onSubmit={this.handleSubmit}
          renderFields={this.renderFields}
          setFieldError={this.setFieldError}
          setFieldTouched={this.setFieldTouched}
          setFieldValue={this.setFieldValue}
        />
      );
    }
  }

  const getState = (state, ownProps) => ({
    ...selector(state),
    ...ownProps,
  });

  return connect(getState)(ArcForm);
};

export default createForm;
