// import React, { useEffect, useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

import { formatByGameUnit } from 'arcade-frontend-core/src/helpers/numbers/numbers';
import {
  ArcText,
  ArcIconButton,
  ArcFormField,
  ArcForm,
  ArcResponsiveDialog,
} from 'arcade-frontend-ui';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';

import { getGame } from '../../reducers/games';

const VALIDATION_SCHEMA = Yup.object().shape({
  score: Yup.number().required().positive(),
});

function GamesLogActionForm({
  gameId,
  status,
  onClose,
  onSubmit,
  open,
  hasError,
}) {
  const game = useSelector(getGame(gameId));

  const renderForm = formProps => {
    const { rules } = game || {};
    const { metric, unit } = rules || '';

    const { values } = formProps;
    const { score } = values;

    let formattedScore = formatByGameUnit(unit, score);

    if (formattedScore === 'NaN') {
      formattedScore = '_';
    }

    return (
      <ArcResponsiveDialog open={open} onClose={onClose} maxWidth={475}>
        <DialogTitle>
          <ArcBox display="flex" flexDirection="row">
            <ArcBox
              component={ArcText}
              fontWeight={600}
              fontSize={20}
              lineHeight="150%"
            >
              {'Add Score'}
            </ArcBox>
            <ArcBox ml="auto">
              <ArcIconButton onClick={onClose} size="small">
                <CloseIcon />
              </ArcIconButton>
            </ArcBox>
          </ArcBox>
        </DialogTitle>

        <DialogContent>
          <ArcBox component={ArcText}>
            {'Adding'}
            <ArcText isStrong marginLeft="4">
              {formattedScore || 0}
            </ArcText>
            {' to your score for'}
            <ArcText isStrong marginLeft="4">
              {metric || 'this game'}
            </ArcText>
          </ArcBox>

          <ArcBox display="flex" flexDirection="column" mt={3}>
            <ArcFormField
              autoComplete="off"
              id="score"
              type="number"
              name="score"
              fullWidth
              label="Amount"
              placeholder="Ex: $50.00"
              validations={{
                isRequired: true,
                minNumber: 1,
                maxNumber: 1000000,
              }}
            />
          </ArcBox>
        </DialogContent>

        <DialogActions>
          <ArcBox
            display="flex"
            alignSelf="flex-end"
            alignItems="center"
            mr={2}
            mb={1}
          >
            {hasError && (
              <ArcBox
                component={ArcText}
                fontSize={12}
                fontWeight="bold"
                color="rgb(243, 96, 79)"
                mr={1}
                className="animated fadeIn"
              >
                {'Add score failed - please try again'}
              </ArcBox>
            )}
            <ArcResourceButton
              type="submit"
              label="Submit"
              color="primary"
              variant="contained"
              status={status}
              onClick={formProps.handleSubmit}
            />
          </ArcBox>
        </DialogActions>
      </ArcResponsiveDialog>
    );
  };

  return (
    <>
      {open && (
        <ArcForm
          initialValues={{ score: 1 }}
          validateOnBlur
          validateOnChange
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={onSubmit}
        >
          {renderForm}
        </ArcForm>
      )}
    </>
  );
}

GamesLogActionForm.displayName = 'GamesLogActionForm';

GamesLogActionForm.propTypes = {
  open: PropTypes.bool,
  gameId: PropTypes.string,
  status: PropTypes.shape({
    failed: PropTypes.bool,
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    succeeded: PropTypes.bool,
  }),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  hasError: PropTypes.bool,
};

GamesLogActionForm.defaultProps = {
  open: false,
  gameId: '',
  status: {},
  onClose: global.noop,
  onSubmit: global.noop,
  hasError: false,
};

export default GamesLogActionForm;
