import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import {
  ArcIconButton,
  ArcResponsiveDialog,
  ArcText,
  ArcView,
  ArcMarkdownContent,
  ArcToken,
  createWithStyles,
} from 'arcade-frontend-ui';

const styles = {
  DialogContent: () => ({
    root: {
      padding: '16px 24px',
    },
  }),
  TokenHolder: theme => ({
    root: {
      height: 20,
      width: 20,
      marginTop: 1,
      marginRight: theme.spacing(0.85),
    },
  }),
  TokenText: theme => ({
    root: {
      color: theme.palette.grey[600],
      fontSize: theme.font.getFontSize(1),
    },
  }),
};

const STRINGS = {
  REDEMPTION_INFO_PLACEHOLDER: 'No redemption information available right now.',
};

const DialogContent = createWithStyles(styles.DialogContent)(ArcView);
const TokenHolder = createWithStyles(styles.TokenHolder)(ArcView);
const TokenText = createWithStyles(styles.TokenText)(ArcText);

const RewardHowToRedeemDialog = ({ onClose, open, reward }) => {
  const { name, redemptionInformation, imageUrl, tokenCost } = reward;

  return (
    <ArcResponsiveDialog onClose={onClose} open={open}>
      <DialogTitle>
        <ArcView row align="center">
          <ArcView
            style={{
              backgroundColor: '#f3f3f3',
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: 6,
              width: 85,
              height: 60,
              marginRight: 12,
            }}
          />
          <ArcView style={{ flexDirection: 'column' }}>
            <ArcText>{name}</ArcText>
            <ArcText>
              <ArcView row>
                <TokenHolder>
                  <ArcToken />
                </TokenHolder>
                <TokenText>
                  {window.Intl.NumberFormat('en-US', {
                    maximumFractionDigits: 2,
                  }).format(tokenCost)}
                </TokenText>
              </ArcView>
            </ArcText>
          </ArcView>
          <ArcView spacer />
          <ArcIconButton onClick={onClose} size="small">
            <CloseIcon />
          </ArcIconButton>
        </ArcView>
      </DialogTitle>

      <DialogContent>
        <ArcMarkdownContent
          content={redemptionInformation || STRINGS.REDEMPTION_INFO_PLACEHOLDER}
        />
      </DialogContent>
    </ArcResponsiveDialog>
  );
};

RewardHowToRedeemDialog.displayName = 'RewardHowToRedeemDialog';

RewardHowToRedeemDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  reward: PropTypes.shape({
    id: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    amount: PropTypes.number,
    createdAt: PropTypes.string,
    expectedFulfillmentDate: PropTypes.string,
    quantity: PropTypes.number,
    denomination: PropTypes.string,
    status: PropTypes.string,
    redemptionLink: PropTypes.string,
    redemptionInformation: PropTypes.string,
    tokenCost: PropTypes.number,
  }),
};

RewardHowToRedeemDialog.defaultProps = {
  onClose: global.noop,
  title: 'How to Redeem',
  open: false,
  reward: {},
};

export default RewardHowToRedeemDialog;
