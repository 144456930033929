import { createReducer } from 'arcade-frontend-ui';

import * as routeTypes from 'arcade-frontend-core/src/types/routes';

const initialState = null;

const creatingType = (state, action) => action.payload.type || state;

const handlers = {
  [routeTypes.ROUTE_MANAGE_GAMES_CREATE]: creatingType,
};

const gameCreateType = createReducer(initialState, handlers);

export default gameCreateType;

export const getManageGamesGameCreateType = state => state.manage.games.gameCreateType;
