import React from 'react';
/* eslint-disable import/no-unresolved */
import { ICON_BY_NAME as IconByName } from '@workplacearcade/ui/components/Icons/Icon';
/* eslint-disable import/no-unresolved */

import Cap from 'arcade-frontend-ui/src/icons/Cap';
import QuestionCircle from 'arcade-frontend-ui/src/icons/QuestionCircle';
import StatsLine from 'arcade-frontend-ui/src/icons/StatsLine';
import Group from 'arcade-frontend-ui/src/icons/Group';
import FlagOutlined from 'arcade-frontend-ui/src/assets/FlagOutlined';

const iconSize = {
  height: '18px',
  width: '18px',
};

export const ARCADE_ICON_BY_NAME = {
  newsfeed: <IconByName.homeoutlinetwo {...iconSize} />,
  chat: <IconByName.comment {...iconSize} />,
  metrics: <FlagOutlined {...iconSize} stroke="white" />,
  games: <IconByName.gamecontroller {...iconSize} />,
  rewards: <IconByName.giftthree {...iconSize} />,
  academy: <Cap {...iconSize} />,
  people: <IconByName.people {...iconSize} />,
  get_help: <QuestionCircle {...iconSize} />,
  manage: <IconByName.wrench {...iconSize} />,
  hub: <StatsLine {...iconSize} />,
  insights: <StatsLine {...iconSize} />,
  huddle: <Group {...iconSize} />,
};
