import { createReducer } from 'arcade-frontend-ui';
import * as types from '../../../actions/manage/metrics/types';

const setTrue = () => true;
const setFalse = () => false;

const createIsFetchingReducer = (initialState, apiActionTypes) => {
  const handlers = {};

  apiActionTypes.forEach((type) => {
    handlers[type.REQUEST] = setTrue;
    handlers[type.SUCCESS] = setFalse;
    handlers[type.FAILURE] = setFalse;
  });

  return createReducer(initialState, handlers);
};

const initialState = false;

const apiActionTypes = [
  types.MANAGE_METRICS_INDEX,
  types.MANAGE_METRICS_EDIT,
];

const isFetching = createIsFetchingReducer(initialState, apiActionTypes);

export default isFetching;

export const getIsFetching = state => state.manage.metrics.isFetching;
