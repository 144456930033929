import React from 'react';
import PropTypes from 'prop-types';

import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
import ArcCheckbox from 'arcade-frontend-ui/src/elements/ArcCheckbox';

import Skeleton from '@material-ui/lab/Skeleton';

import { FieldArray } from 'formik';

const renderPlaceholder = (
  <ArcViewBox mt={2.5}>
    {[...Array(3).keys()].map(index => (
      <ArcViewBox key={index}>
        <ArcViewBox my={2} flexDirection="row">
          <ArcViewBox mr={2}>
            <Skeleton variant="rect" height={25} width={25} />
          </ArcViewBox>
          <Skeleton variant="rect" height={25} width={150} />
        </ArcViewBox>
        <ArcViewBox my={2} flexDirection="row">
          <ArcViewBox mr={2}>
            <Skeleton variant="rect" height={25} width={25} />
          </ArcViewBox>
          <Skeleton variant="rect" height={25} width={170} />
        </ArcViewBox>
        <ArcViewBox my={2} flexDirection="row">
          <ArcViewBox mr={2}>
            <Skeleton variant="rect" height={25} width={25} />
          </ArcViewBox>
          <Skeleton variant="rect" height={25} width={140} />
        </ArcViewBox>
      </ArcViewBox>
    ))}
  </ArcViewBox>
);

function ArcSecondaryTeamsFormField({ teams, formValues }) {
  if (!teams.length) {
    return renderPlaceholder;
  }

  return (
    <FieldArray
      name="secondaryTeamIds"
      render={arrayHelpers => (
        <ArcViewBox>
          {teams.map(team => (
            <ArcViewBox key={team.id}>
              <ArcCheckbox
                name="secondaryTeamIds"
                type="checkbox"
                value={team.id}
                label={team.name}
                checked={formValues.secondaryTeamIds.includes(team.id)}
                onChange={e => {
                  if (e.target.checked) arrayHelpers.push(team.id);
                  else {
                    const idx = formValues.secondaryTeamIds.indexOf(team.id);
                    arrayHelpers.remove(idx);
                  }
                }}
              />
            </ArcViewBox>
          ))}
        </ArcViewBox>
      )}
    />
  );
}

ArcSecondaryTeamsFormField.displayName = 'ArcSecondaryTeamsFormField';

ArcSecondaryTeamsFormField.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  formValues: PropTypes.shape({
    secondaryTeamIds: PropTypes.arrayOf(PropTypes.string),
  }),
};

ArcSecondaryTeamsFormField.defaultProps = {
  teams: [],
  formValues: {},
};

export default ArcSecondaryTeamsFormField;
