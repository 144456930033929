import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  ArcPropTypes,
  ArcViewTitleBar,
  ArcMainView,
  ArcView,
  loaders,
} from 'arcade-frontend-ui';

import {
  actions,
  routes,
} from '../../../actions/manage/metrics';


// import FormMetricCreate from '../../../forms/metrics/create';
import MetricsCreateForm from '../../../forms/metrics/MetricsCreateForm';

import { getCurrentMetricId } from '../../../reducers/manage/metrics/currentMetricId';
import { getManageMetricsById } from '../../../reducers/manage/metrics/manageMetricsById';
import { getIsFetching } from '../../../reducers/manage/metrics/isFetching';
import { getIsCreating } from '../../../reducers/manage/metrics/isCreating';

class ManageMetricsEditContainer extends React.PureComponent {
  static propTypes = {
    routeManageMetrics: ArcPropTypes.func.isRequired,
    apiManageMetricsEditRequest: ArcPropTypes.func.isRequired,
    manageMetricsById: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
    isCreating: ArcPropTypes.bool.isRequired,
    isFetching: ArcPropTypes.bool.isRequired,

    currentMetricId: ArcPropTypes.string,
  };

  static defaultProps = {
    currentMetricId: null,
  }

  constructor(props) {
    super(props);

    if (!props.isCreating && props.currentMetricId) {
      props.apiManageMetricsEditRequest(props.currentMetricId);
    }
  }

  renderCreate() {
    return (
      <MetricsCreateForm
        onCancel={this.props.routeManageMetrics}
        isCreating={this.props.isCreating}
      />
    );
  }

  renderEdit() {
    const currentMetric = this.props.manageMetricsById[this.props.currentMetricId];

    if (!currentMetric && this.props.isFetching) {
      return (
        <ArcView
          padding="4"
          flexGrow="1"
        >
          <ArcView
            flexGrow="1"
            align="center"
            justify="center"
            color="paper"
          >
            <loaders.LoaderBars />
          </ArcView>
        </ArcView>
      );
    }

    return (
      <MetricsCreateForm
        currentMetric={currentMetric}
        onCancel={this.props.routeManageMetrics}
        isCreating={this.props.isCreating}
        initialValues={MetricsCreateForm.getInitialValues(currentMetric)}
      />
    );
  }

  render() {
    const {
      currentMetricId,
      manageMetricsById,
    } = this.props;

    const currentMetric = manageMetricsById[currentMetricId];

    const metricTitle = currentMetric && currentMetric.name !== null && currentMetric.verb !== null
      ? `${currentMetric.name} ${currentMetric.verb}`
      : 'Metric';

    const title = this.props.isCreating ? 'New Metric' : `Edit ${metricTitle}`;

    return (
      <ArcMainView
        isViewing
        hasNoPaddingLeftLaunch
      >
        <ArcViewTitleBar
          onClickBack={this.props.routeManageMetrics}
          showIconBack
          title={title}
        />
        {this.props.currentMetricId ? this.renderEdit() : this.renderCreate()}
      </ArcMainView>
    );
  }
}

const getState = state => ({
  location: state.location,
  currentMetricId: getCurrentMetricId(state),
  isCreating: getIsCreating(state),
  isFetching: getIsFetching(state),
  manageMetricsById: getManageMetricsById(state),
});

const getActions = dispatch => bindActionCreators({
  apiManageMetricsEditRequest: actions.apiManageMetricsEditRequest,
  routeManageMetrics: routes.routeManageMetrics,
}, dispatch);

export default connect(getState, getActions)(ManageMetricsEditContainer);
