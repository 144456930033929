import React from 'react';
import IconCheck from '@material-ui/icons/Check';

import ArcProgress from '../ArcProgress';
import ArcPropTypes from '../../helpers/arc/propTypes';
import { formatDateTime } from '../../helpers/utils/date';
import ArcView from '../../primitives/ArcView';
import { createWithStyles } from '../../styles';

const styles = {
  Container: () => ({
    root: {
      position: 'relative',
      textAlign: 'left',
      backgroundColor: '#fff',
      fontSize: 14,
      lineHeight: '24px',
      paddingTop: 20,
      paddingBottom: 12,
      paddingLeft: 8,
      paddingRight: 8,
    },

    isUser: {
      marginLeft: 8,
      marginRight: 8,
      borderBottom: '1px solid #f3f3f3',
      fontSize: 14,
      paddingTop: 12,
    },
  }),
  Title: () => ({
    root: {
      fontSize: 16,
      paddingLeft: 8,
    },
  }),
  Percent: () => ({
    root: {
      fontSize: 16,
      paddingRight: 8,
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);
const Title = createWithStyles(styles.Title)(ArcView);
const Percent = createWithStyles(styles.Percent)(ArcView);

const styleArcView = {
  position: 'relative',
};

const styleArcViewUsers = {
  ...styleArcView,
  marginBottom: 64,
  border: '2px solid #f3f3f3',
};

const styleArcProgress = {
  position: 'absolute',
  padding: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const ProgressWithUsers = {
  ...styleArcProgress,
  marginRight: 16,
  marginLeft: 8,
  marginBottom: 8,
};

const TitleWithUsers = {
  paddingLeft: 0,
  marginLeft: 0,
  marginBottom: 12,
};

const styleIcon = {
  marginLeft: 8,
};

const renderUser = user => (
  <Container
    key={user.id}
    isUser
    row
    justify="space-between"
  >
    <ArcView>{user.name}</ArcView>
    <ArcView row>
      {user.completedAt ? formatDateTime(user.completedAt) : null}
      {user.completed ? (<IconCheck color="primary" style={styleIcon} />) : '–'}
    </ArcView>
  </Container>
);

class ArcManageChecklistItem extends React.PureComponent {
  static propTypes = {
    // checked: ArcPropTypes.bool,
    // disabled: ArcPropTypes.bool,

    // value: ArcPropTypes.string,
    label: ArcPropTypes.string,
    percent: ArcPropTypes.number,
    users: ArcPropTypes.arrayOf(ArcPropTypes.shape({
      id: ArcPropTypes.string.isRequired,
      name: ArcPropTypes.string.isRequired,
      completed: ArcPropTypes.bool.isRequired,
      completedAt: ArcPropTypes.date,
    })),
    // labelInfo: ArcPropTypes.string,
  };

  static defaultProps = {
    // checked: false,
    // disabled: false,

    // value: undefined,
    label: undefined,
    percent: 0,
    users: [],
    // labelInfo: undefined,
  };

  render() {
    const {
      label,
      percent,
      users,
    } = this.props;

    return (
      <ArcView style={users.length ? styleArcViewUsers : styleArcView}>
        <Container row justify="space-between">
          <Title style={users.length ? null : TitleWithUsers}>{label}</Title>
          <Percent>{percent}{'%'}</Percent>
          <ArcProgress
            style={users.length ? styleArcProgress : ProgressWithUsers}
            height={8}
            percent={percent}
          />
        </Container>

        {users.map(renderUser)}
      </ArcView>
    );
  }
}

export default ArcManageChecklistItem;
