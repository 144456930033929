import getTransformedResponseFromResourceAction from 'arcade-frontend-core/src/helpers/getTransformedResponseFromResourceAction';

import * as resources from './comments.resources';
import apiGetActivityCommentsRaw from './raw/apiGetActivityCommentsRaw.json';

export { apiGetActivityCommentsRaw };

export const apiGetActivityComments = getTransformedResponseFromResourceAction(
  resources.apiGetActivityComments,
  apiGetActivityCommentsRaw,
  {
    resources: ['0'],
  },
);
