import { combineReducers } from 'redux';

import isFetching from './isFetching';
import products from './products';
import transactionsById from './transactionsById';
import transactions from './transactions';
import summary from './summary';
import accounts from './accounts';
import rewardPurchases from './rewardPurchases';

const rewards = combineReducers({
  isFetching,
  products,
  transactionsById,
  transactions,
  summary,
  accounts,
  rewardPurchases,
});

export default rewards;
