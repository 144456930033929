import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAlertState } from 'arcade-frontend-core/src/reducers/alert';

import { actions } from 'arcade-frontend-core/src/actions';

import {
  ArcConfirmDialog,
} from 'arcade-frontend-ui';

class ArcAlertContainer extends React.Component {
  static propTypes = {
    setIsViewingAlert: PropTypes.func,
    type: PropTypes.oneOf([
      '',
      'error',
      'info',
      'success',
    ]),
  };

  static defaultProps = {
    setIsViewingAlert: global.noop,
    type: '',
  };

  handleOnConfirm = (response) => {
    this.props.setIsViewingAlert(false, response);
    // ArcConsole.log('confirmed', response);
  };

  handleOnDismiss = (reason) => {
    this.props.setIsViewingAlert(false, reason);
    // ArcConsole.log('canceled', reason);
  };

  render() {
    const {
      type,
      ...props
    } = this.props;

    return (
      <ArcConfirmDialog
        hideCancel
        confirmButtonColor={type === 'error' ? 'danger' : 'default'}
        confirmLabel="Okay"
        onConfirm={this.handleOnConfirm}
        onClose={this.handleOnDismiss}
        {...props}
      />
    );
  }
}

const mapStateToProps = getAlertState;
const mapDispatchToProps = dispatch => bindActionCreators({
  setIsViewingAlert: actions.setIsViewingAlert,
  setAlert: actions.setAlert,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ArcAlertContainer);
