import React from 'react';
import PropTypes from 'prop-types';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import EditOutlined from '@material-ui/icons/EditOutlined';
import Close from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';

import * as ESCROWABLE_FUNDS_TYPES from 'arcade-frontend-core/src/types/escrowable-funds';
import * as REWARD_TYPES from 'arcade-frontend-core/src/types/rewards';
import ArcUserInputContainer from 'arcade-frontend-core/src/containers/ArcUserInputContainer';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';
import ArcResourceErrorDialog from 'arcade-frontend-ui/src/components/ArcResourceErrorDialog';
import ArcResourceSuccessDialog from 'arcade-frontend-ui/src/components/ArcResourceSuccessDialog';
import ArcResponsiveDialog from 'arcade-frontend-ui/src/components/ArcResponsiveDialog';
import ArcTokenValue from 'arcade-frontend-ui/src/components/ArcTokenValue';
import ArcTokenOutline from 'arcade-frontend-ui/src/icons/ArcTokenOutline';
import PeopleOutline from 'arcade-frontend-ui/src/icons/PeopleOutline';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcCheckbox from 'arcade-frontend-ui/src/elements/ArcCheckbox';
import ArcInternalLink from 'arcade-frontend-ui/src/components/ArcInternalLink';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcView from 'arcade-frontend-ui/src/primitives/ArcView';
import ArcAwardForm from 'arcade-frontend-widgets/src/components/ArcAwardForm';
import ArcAudienceCreateForm from 'arcade-frontend-widgets/src/components/ArcAudienceCreateForm';
import ArcAwardSelections from 'arcade-frontend-ui/src/components/ArcAwardSelections/ArcAwardSelections';
import ArcEntitySelections from 'arcade-frontend-ui/src/components/ArcEntitySelections/ArcEntitySelections';

import RewardsAwardFormResourceErrorDialog from './RewardsAwardFormResourceErrorDialog';
import RewardsAwardFormConfirmationDialog from './RewardsAwardFormConfirmationDialog';

const STRINGS = {
  'REWARDS/AWARD_FORM_TITLE': 'Give Tokens or Chests!',
  'REWARDS/AWARD_FORM_MESSAGE_LABEL': 'I am awarding this for',
  'REWARDS/AWARD_FORM_PEOPLE_TITLE': 'People',
  'REWARDS/AWARD_FORM_PEOPLE_EDIT': 'Edit People',
  'REWARDS/AWARD_FORM_PEOPLE_EMPTY': 'No people selected',
  'REWARDS/AWARD_FORM_PEOPLE_ERROR': 'People Required',
  'REWARDS/AWARD_FORM_PEOPLE_SELECT': 'Select People',
  'REWARDS/AWARD_FORM_PRIZES_TITLE': 'Choose Prize',
  'REWARDS/AWARD_FORM_PRIZES_EDIT': 'Edit Prizes',
  'REWARDS/AWARD_FORM_PRIZES_ERROR': 'Prizes Required',
  'REWARDS/AWARD_FORM_PRIZES_EMPTY': 'No prize selected',
  'REWARDS/AWARD_FORM_PRIZES_SELECT': 'Select Prizes',
};

const LABELS_BY_ESCROWABLE_FUNDS_TYPES = {
  [ESCROWABLE_FUNDS_TYPES.COMPANY_BUDGET]:
    'Award tokens or chests to users in Arcade.',
  [ESCROWABLE_FUNDS_TYPES.USER_BUDGET]:
    'Award tokens or chests to users in Arcade from your own budget.',
};

const MESSAGE_TYPES = {
  OUTSTANDING: 'OUTSTANDING',
  BEYOND: 'BEYOND',
  PROGRESS: 'PROGRESS',
  CUSTOM: 'CUSTOM',
};

const LABELS_BY_MESSAGE_TYPE = {
  OUTSTANDING: 'Outstanding achievement',
  BEYOND: 'Going above and beyond',
  PROGRESS: 'Making progress',
  CUSTOM: 'Custom message',
};

const MESSAGES = [
  MESSAGE_TYPES.OUTSTANDING,
  MESSAGE_TYPES.BEYOND,
  MESSAGE_TYPES.PROGRESS,
];

function getInitialValuesFromAward(award) {
  if (!award) {
    return undefined;
  }

  const { type, value } = award;

  switch (type) {
    case REWARD_TYPES.CHESTS:
      return {
        type,
        chests: value,
      };
    case REWARD_TYPES.TOKENS:
      return {
        type,
        tokens: value,
      };
    default:
      return undefined;
  }
}

const addButtonStyle = {
  width: 120,
};

const customMessageRadioStyle = {
  marginLeft: -11,
};

const subheadingStyle = {
  color: '#616264',
};

function RewardsAwardForm({
  entitiesByUuid,
  error,
  escrowableFundsBalance,
  escrowableFundsType,
  hasError,
  hasFailed,
  hasSucceeded,
  initialValues,
  isIdle,
  isPending,
  onErrorReset,
  onStatusReset,
  onSubmit,
  status,
}) {
  const [people, setPeople] = React.useState(initialValues.people);

  const [peopleHasError, setPeopleHasError] = React.useState(
    !initialValues.people || !initialValues.people.length,
  );

  const [peopleOpen, setPeopleOpen] = React.useState(false);

  const [peopleTouched, setPeopleTouched] = React.useState(false);

  const [award, setAward] = React.useState(initialValues.award);

  const [awardOpen, setAwardOpen] = React.useState(false);

  const [awardTouched, setAwardTouched] = React.useState(false);

  const [isConfirmingSubmission, setIsConfirmingSubmission] = React.useState(
    false,
  );

  const [isPublic, setIsPublic] = React.useState(false);

  const [message, setMessage] = React.useState('');

  const [messageType, setMessageType] = React.useState(
    MESSAGE_TYPES.OUTSTANDING,
  );

  const [submitCount, setSubmitCount] = React.useState(0);

  function getAwardHasError(nextAward) {
    if (!nextAward || !nextAward.value || !nextAward.type) {
      return true;
    }

    switch (nextAward.type) {
      case REWARD_TYPES.TOKENS:
        return people && people.length
          ? nextAward.value / people.length > escrowableFundsBalance
          : nextAward.value > escrowableFundsBalance;
      case REWARD_TYPES.CHESTS:
        return (
          !nextAward.value ||
          (!nextAward.value.Common &&
            !nextAward.value.Rare &&
            !nextAward.value.Epic &&
            !nextAward.value.Legendary)
        );
      default:
        return false;
    }
  }

  const [awardHasError, setAwardHasError] = React.useState(() =>
    getAwardHasError(initialValues.award),
  );

  function getPeopleSelectionsFromFormValue() {
    const nextPeople = [];

    if (!people || !people.length) {
      return nextPeople;
    }

    people.forEach(id => {
      const uuid = `person-${id}`;
      nextPeople.push(entitiesByUuid[uuid]);
    });

    return nextPeople.filter(Boolean);
  }

  const selectedPeople = getPeopleSelectionsFromFormValue();
  const isValid = !awardHasError && !peopleHasError;

  function resetForm() {
    setSubmitCount(0);
    setPeople([]);
    setPeopleHasError(true);
    setPeopleTouched(false);
    setAward({});
    setAwardHasError(true);
    setAwardTouched(false);
    setIsPublic(false);
    setMessage('');
  }

  function handleAwardClose() {
    setAwardHasError(getAwardHasError(award));
    setAwardOpen(false);
    setAwardTouched(true);
  }

  function handleAwardOpen() {
    setAwardOpen(true);
  }

  function handleAwardSubmit(values) {
    setAward(values);
    setAwardHasError(getAwardHasError(values));
    setAwardOpen(false);
  }

  function handleConfirmingSubmissionClose() {
    setIsConfirmingSubmission(false);
  }

  function handleConfirmSubmission() {
    setSubmitCount(submitCount + 1);

    setAwardTouched(true);
    setPeopleTouched(true);

    if (!isValid) {
      return;
    }

    setIsConfirmingSubmission(true);
  }

  function handleErrorDialogClose() {
    onErrorReset();
    onStatusReset();
  }

  function handleIsPublicChange(evt) {
    setIsPublic(evt.target.checked);
  }

  function handleMessageChange(evt) {
    setMessage(evt.target.value);
  }

  function handleMessageFocus() {
    setMessageType(MESSAGE_TYPES.CUSTOM);
  }

  function handleMessageTypeChange(evt) {
    setMessageType(evt.target.value);
  }

  function handlePeopleClose() {
    setPeopleHasError(!people || !people.length);
    setPeopleOpen(false);
    setPeopleTouched(true);
  }

  function handlePeopleOpen() {
    setPeopleOpen(true);
  }

  function handlePeopleSubmit(values) {
    setPeople(values.person);
    setPeopleHasError(!values.person || !values.person.length);
    setPeopleOpen(false);
    setPeopleTouched(true);
  }

  function handleReduceTokens(value) {
    onErrorReset();
    setAward({
      type: REWARD_TYPES.TOKENS,
      value,
    });
  }

  function handleSubmit() {
    setIsConfirmingSubmission(false);

    let messageValue =
      messageType === MESSAGE_TYPES.CUSTOM
        ? message
        : LABELS_BY_MESSAGE_TYPE[messageType];

    if (!isPublic) {
      messageValue = '';
    }

    const values = {
      award,
      people,
      isPublic,
      message: messageValue,
    };

    onSubmit(values);
  }

  function handleSuccessDialogClose() {
    onStatusReset();
    resetForm();
  }

  const hasAward = !!award && !!award.type;

  const awardTitle = hasAward
    ? STRINGS['REWARDS/AWARD_FORM_PRIZES_EDIT']
    : STRINGS['REWARDS/AWARD_FORM_PRIZES_SELECT'];

  const awardButtonLabel = hasAward ? 'Change' : 'Select';

  let awardSelectionLabel = STRINGS['REWARDS/AWARD_FORM_PRIZES_EMPTY'];

  if (awardHasError && awardTouched) {
    awardSelectionLabel = STRINGS['REWARDS/AWARD_FORM_PRIZES_ERROR'];
  }

  const hasPeople = !!people && !!people.length;

  const peopleTitle = hasPeople
    ? STRINGS['REWARDS/AWARD_FORM_PEOPLE_EDIT']
    : STRINGS['REWARDS/AWARD_FORM_PEOPLE_SELECT'];

  const peopleButtonLabel = hasPeople ? 'Change' : 'Select';

  let peopleSelectionLabel = STRINGS['REWARDS/AWARD_FORM_PEOPLE_EMPTY'];

  if (peopleHasError && peopleTouched) {
    peopleSelectionLabel = STRINGS['REWARDS/AWARD_FORM_PEOPLE_ERROR'];
  }

  const submitDisabled = (submitCount > 0 && !isValid) || !isIdle;

  return (
    <ArcView color="paper">
      <ArcView
        position="relative"
        row
        padding="16"
        paddingLeft="24"
        align="flex-end"
      >
        <Typography variant="h5">
          {STRINGS['REWARDS/AWARD_FORM_TITLE']}
        </Typography>
      </ArcView>

      <ArcView
        paddingLeft="24"
        paddingRight="24"
        paddingTop="16"
        paddingBottom="8"
      >
        <ArcView marginBottom="32" style={subheadingStyle}>
          <ArcView marginBottom="8">
            {LABELS_BY_ESCROWABLE_FUNDS_TYPES[escrowableFundsType]}
          </ArcView>
          <ArcView display="block">
            <ArcText>{'You have '}</ArcText>
            <ArcTokenValue
              value={escrowableFundsBalance}
              verticalAlign="bottom"
            />
            <ArcText>{' available to spend.'}</ArcText>

            {!escrowableFundsBalance && (
              <ArcView display="block" marginTop="16">
                <ArcText>
                  {'You can still award chests, visit '}
                  <ArcInternalLink
                    title="manage tokens"
                    href="/arcade/manage/tokens"
                    size="inherit"
                  >
                    {'manage tokens'}
                  </ArcInternalLink>
                  {' to top up your token balance.'}
                </ArcText>
              </ArcView>
            )}
          </ArcView>
        </ArcView>

        <ArcView row align="center" marginBottom="16">
          <PeopleOutline />
          <ArcView marginLeft="8">
            <ArcText size="1.25">
              {STRINGS['REWARDS/AWARD_FORM_PEOPLE_TITLE']}
            </ArcText>
          </ArcView>
          <ArcView spacer />
          <ArcButton
            color="blue"
            disabled={!isIdle}
            label={peopleButtonLabel}
            onClick={handlePeopleOpen}
            style={addButtonStyle}
          >
            {hasPeople ? <EditOutlined /> : <AddCircleOutline />}
            <ArcView marginLeft="8">{peopleButtonLabel}</ArcView>
          </ArcButton>
        </ArcView>

        <ArcView marginBottom="32">
          <ArcView
            padding="16"
            color="grey-tint"
            onClick={hasPeople ? undefined : handlePeopleOpen}
            cursor={hasPeople ? undefined : 'pointer'}
          >
            <ArcText
              color={peopleTouched && peopleHasError ? 'danger' : 'grey'}
            >
              {selectedPeople && selectedPeople.length ? (
                <ArcEntitySelections
                  fullWidth={false}
                  align="flex-start"
                  people={selectedPeople}
                />
              ) : (
                peopleSelectionLabel
              )}
            </ArcText>
          </ArcView>

          {peopleOpen && (
            <ArcAudienceCreateForm
              title={peopleTitle}
              confirmDialogContent="You will lose any unsaved selections"
              initialValues={{
                person: people,
              }}
              entitiesByUuid={entitiesByUuid}
              hasFieldName={false}
              open={peopleOpen}
              onClose={handlePeopleClose}
              onSubmit={handlePeopleSubmit}
              peopleOnly
              submitButtonLabel="Ok"
              submitButtonColor="blue"
            />
          )}
        </ArcView>

        <ArcView row align="center" marginBottom="16">
          <ArcTokenOutline />
          <ArcView marginLeft="8">
            <ArcText size="1.25">
              {STRINGS['REWARDS/AWARD_FORM_PRIZES_TITLE']}
            </ArcText>
          </ArcView>
          <ArcView spacer />
          <ArcButton
            data-testid="RewardsAwardForm-SelectPrize"
            color="blue"
            disabled={!isIdle}
            label={awardButtonLabel}
            onClick={handleAwardOpen}
            style={addButtonStyle}
          >
            {hasAward ? <EditOutlined /> : <AddCircleOutline />}
            <ArcView marginLeft="8">{awardButtonLabel}</ArcView>
          </ArcButton>
        </ArcView>

        <ArcView marginBottom="16">
          <ArcView
            padding="16"
            color="grey-tint"
            onClick={hasAward ? undefined : handleAwardOpen}
            cursor={hasAward ? undefined : 'pointer'}
          >
            <ArcText color={awardTouched && awardHasError ? 'danger' : 'grey'}>
              {hasAward ? (
                <ArcAwardSelections justify="flex-start" {...award} />
              ) : (
                awardSelectionLabel
              )}
            </ArcText>
          </ArcView>

          <ArcResponsiveDialog
            aria-labelledby="rewards-select-award-title"
            onClose={handleAwardClose}
            open={awardOpen}
          >
            <DialogTitle id="rewards-select-award-title">
              <ArcView row>
                <ArcView>{awardTitle}</ArcView>
                <ArcView spacer />
                <ArcButton size="small" onClick={handleAwardClose}>
                  <Close color="action" fontSize="small" />
                </ArcButton>
              </ArcView>
            </DialogTitle>
            <ArcAwardForm
              initialValues={getInitialValuesFromAward(award)}
              onCancel={handleAwardClose}
              onSubmit={handleAwardSubmit}
              tokensMax={escrowableFundsBalance}
              escrowableFundsType={escrowableFundsType}
              escrowableFundsBalance={escrowableFundsBalance}
              isIdle={isIdle}
              isPending={isPending}
              hasError={hasError}
              hasFailed={hasFailed}
            />
          </ArcResponsiveDialog>
        </ArcView>

        <ArcView marginBottom={isPublic ? '16' : undefined}>
          <ArcCheckbox
            checked={isPublic}
            disabled={!isIdle}
            label="Announce this to the newsfeed"
            onChange={handleIsPublicChange}
          />
        </ArcView>

        {isPublic && (
          <ArcView>
            <FormLabel component="legend">
              {STRINGS['REWARDS/AWARD_FORM_MESSAGE_LABEL']}&hellip;
            </FormLabel>
            <RadioGroup
              aria-label="Message Type"
              name="messageType"
              value={messageType}
              onChange={handleMessageTypeChange}
            >
              {MESSAGES.map(type => (
                <FormControlLabel
                  key={type}
                  value={type}
                  control={<Radio />}
                  label={LABELS_BY_MESSAGE_TYPE[type]}
                />
              ))}
              <ArcView row align="center">
                <Radio
                  value={MESSAGE_TYPES.CUSTOM}
                  inputProps={{ 'aria-label': LABELS_BY_MESSAGE_TYPE.CUSTOM }}
                  style={customMessageRadioStyle}
                />
                <ArcView
                  border="default"
                  borderWidth="1"
                  flexGrow="100"
                  rounded
                  style={{ borderColor: '#666' }}
                >
                  <ArcUserInputContainer
                    placeholder={LABELS_BY_MESSAGE_TYPE.CUSTOM}
                    onChange={handleMessageChange}
                    onFocus={handleMessageFocus}
                    value={message}
                  />
                </ArcView>
              </ArcView>
            </RadioGroup>
          </ArcView>
        )}
      </ArcView>

      <ArcView row justify="flex-end" padding="8">
        <ArcResourceButton
          variant="contained"
          color="secondary"
          disabled={submitDisabled}
          type="submit"
          label="Submit"
          onClick={handleConfirmSubmission}
          status={status}
        />
      </ArcView>

      <RewardsAwardFormConfirmationDialog
        awardType={award.type}
        awardValue={award.value}
        open={isConfirmingSubmission}
        onConfirm={handleSubmit}
        onClose={handleConfirmingSubmissionClose}
        people={selectedPeople}
      />

      <RewardsAwardFormResourceErrorDialog
        awardType={award.type}
        awardValue={award.value}
        error={error}
        hasError={hasError}
        onClose={handleErrorDialogClose}
        onTopUp={onErrorReset}
        onReduceTokens={handleReduceTokens}
        peopleLength={people.length}
      />

      <ArcResourceSuccessDialog
        open={hasSucceeded}
        onClose={handleSuccessDialogClose}
      >
        {'Awards will be sent out shortly!'}
      </ArcResourceSuccessDialog>
    </ArcView>
  );
}

RewardsAwardForm.displayName = 'RewardsAwardForm';

RewardsAwardForm.propTypes = {
  entitiesByUuid: PropTypes.shape({
    person: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  error: PropTypes.shape({
    data: PropTypes.shape({
      response: PropTypes.shape({
        data: PropTypes.shape({
          error: PropTypes.oneOf(
            Object.values(ArcResourceErrorDialog.ERROR_TYPES),
          ),
          availableAmount: PropTypes.number,
        }),
      }),
    }),
  }),
  escrowableFundsBalance: PropTypes.number,
  escrowableFundsType: PropTypes.oneOf(Object.values(ESCROWABLE_FUNDS_TYPES)),
  hasError: PropTypes.bool,
  hasFailed: PropTypes.bool,
  hasSucceeded: PropTypes.bool,
  initialValues: PropTypes.shape({
    award: PropTypes.shape({
      type: PropTypes.oneOf([REWARD_TYPES.CHESTS, REWARD_TYPES.TOKENS]),
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.objectOf(PropTypes.number),
      ]),
    }),
    people: PropTypes.arrayOf(PropTypes.string),
  }),
  isIdle: PropTypes.bool,
  isPending: PropTypes.bool,
  onErrorReset: PropTypes.func,
  onStatusReset: PropTypes.func,
  onSubmit: PropTypes.func,
  status: PropTypes.objectOf(PropTypes.bool),
};

RewardsAwardForm.defaultProps = {
  entitiesByUuid: {},
  error: undefined,
  escrowableFundsBalance: 0,
  escrowableFundsType: ESCROWABLE_FUNDS_TYPES.COMPANY_BUDGET,
  hasError: undefined,
  hasFailed: undefined,
  hasSucceeded: undefined,
  initialValues: {
    award: {},
    people: [],
  },
  isIdle: true,
  isPending: false,
  onErrorReset: global.noop,
  onStatusReset: global.noop,
  onSubmit: global.noop,
  status: undefined,
};

export default RewardsAwardForm;
