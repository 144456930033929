import 'normalize.css';
import 'font-awesome/css/font-awesome.css';
import 'animate.css/animate.min.css';
import 'rc-time-picker/assets/index.css';
import 'tiny-date-picker/tiny-date-picker.min.css';
import 'video.js/dist/video-js.css';

import './react-day-picker.css';
import './react-table.css';

import './angular-defaults.css';
import './button-reset.css';

import './animations.css';
import './app.css';
import './arc-progress.css';
import './arc-table.css';
import './checkmark-loader.css';
import './fireworks.css';
import './leaderboard.css';
import './rc-time-picker.css';
import './sizzle.css';
import './sweetalert.css';
import './warning-loader.css';

/* eslint-disable import/no-unresolved */
import '@workplacearcade/styles/typography.css';
/* eslint-enable import/no-unresolved */
