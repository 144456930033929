import React from 'react';
import { connect } from 'react-redux';
import { actions, Control } from 'react-redux-form';
import RadioButtonGroup from '@material-ui/core/RadioGroup';
// import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
// import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';

import { makeValidators } from '../helpers/utils/validators';
import ArcPropTypes from '../helpers/arc/propTypes';
// import ArcStyles from '../styles';
import ArcFormRadioButton from './ArcFormRadioButton';

// const styleCheckbox = {
//   marginBottom: 16,
// };

// const styleUnchecked = {
//   fill: ArcStyles.theme.grey3,
// };

// const styleChecked = {
//   fill: ArcStyles.theme.brandPrimary,
// };

// const styleLabel = {
//   color: ArcStyles.theme.grey2,
// };

class ArcFormRadioButtonGroup extends React.PureComponent {
  static propTypes = {
    model: ArcPropTypes.string.isRequired,
    label: ArcPropTypes.string,
    validations: ArcPropTypes.objectOf(ArcPropTypes.oneOfType([
      ArcPropTypes.bool,
      ArcPropTypes.number,
    ])),

    items: ArcPropTypes.formRadioItems.isRequired,
    onChange: ArcPropTypes.func,
    value: ArcPropTypes.string,
    valueSelected: ArcPropTypes.string,
  };

  static defaultProps = {
    label: '',
    onChange: ArcPropTypes.noop,
    validations: null,
    value: undefined,
    valueSelected: undefined,
  };


  constructor(props) {
    super(props);

    const {
      label,
      validations,
      ...rest
    } = props;

    this.validators = undefined;

    if (validations) {
      const {
        validators,
      } = makeValidators(label, validations);

      this.validators = validators;
    }

    this.mapProps = {
      name: ({ model }) => model,
      valueSelected: ({ modelValue }) => modelValue,
      onChange: ({ dispatch, model }) => (e, value) => {
        this.props.onChange(e, value);
        dispatch(actions.change(model, value));
      },
    };

    this.restProps = rest;
  }

  renderItems(props) {
    const { items } = this.props;
    // const length = items.length - 1;


    return items.map(item => (
      <ArcFormRadioButton
        key={item.value}
        value={item.value}
        label={item.label}
        checked={item.value === props.valueSelected}
      />
    ));

    // return items.map((item, index) => (
    //   <ArcFormRadioButton
    //     key={item.value}
    //     style={length !== index ? styleCheckbox : null}
    //     checkedIcon={<RadioButtonChecked style={styleChecked} />}
    //     uncheckedIcon={<RadioButtonUnchecked style={styleUnchecked} />}
    //     labelStyle={styleLabel}
    //     {...item}
    //     checked={item.value === props.valueSelected}
    //   />
    // ));
  }

  renderRadioButtonGroup = (props) => {
    const {
      value,
      valueSelected,
      ...rest
    } = props;

    return (
      <RadioButtonGroup value={valueSelected} {...rest}>
        {this.renderItems(props)}
      </RadioButtonGroup>
    );
  };

  render() {
    return (
      <Control.custom
        persist
        model={this.props.model}
        component={this.renderRadioButtonGroup}
        mapProps={this.mapProps}
        validators={this.validators}
        {...this.restProps}
      />
    );
  }
}


export default connect()(ArcFormRadioButtonGroup);
