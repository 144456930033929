import {
  makeParams,
  makePaginationParams,
  makeDateFilterParams,
} from 'arcade-frontend-core/src/actions/helpers';
import { makeApiActions } from 'arcade-frontend-ui';

import normalizers from './normalizers';

const salesIndex = params => {
  return {
    method: 'GET',
    url: '/manager/v4/sales.json',
    normalizer: normalizers.fromSalesIndex,
    params: {
      ...makeParams(params, [
        'integrationRunId',
        'metricId',
        'metric_id',
        'user',
      ]),
      ...makePaginationParams(params),
      ...makeDateFilterParams(params),
    },
  };
};

const saleMetricsIndex = () => ({
  method: 'GET',
  url: '/manager/v4/sales/metrics.json',
  normalizer: normalizers.fromSaleMetricsIndex,
  rootKey: 'metrics',
});

const saleTeamsIndex = () => ({
  method: 'GET',
  url: '/manager/v4/sales/teams.json',
  normalizer: normalizers.fromSaleTeamsIndex,
  rootKey: 'teams',
});

const saleUsersIndex = () => ({
  method: 'GET',
  url: '/manager/v4/sales/users.json',
  normalizer: normalizers.fromSaleUsersIndex,
  rootKey: 'users',
});

const salesCreate = ({ sale }) => ({
  method: 'POST',
  url: '/manager/v4/sales.json',
  data: {
    sale: normalizers.toSaleCreate(sale),
  },
  rootKey: 'sale',
  normalizer: normalizers.normalizeSale,
});

const salesDelete = ({ sale }) => ({
  method: 'DELETE',
  url: `/manager/v4/sales/${sale.id}`,
});

const salesUpdate = ({ sale }) => ({
  method: 'PUT',
  url: `/manager/v4/sales/${sale.id}`,
  data: {
    sale: normalizers.toSaleEdit(sale),
  },
  rootKey: 'sale',
  normalizer: normalizers.normalizeSale,
});

const api = makeApiActions({
  salesIndex,
  saleMetricsIndex,
  saleTeamsIndex,
  saleUsersIndex,
  salesCreate,
  salesDelete,
  salesUpdate,
});

export default api;
