import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ArcPropTypes, ArcView, loaders } from 'arcade-frontend-ui';

import { actions } from 'arcade-frontend-features/src/actions/manage/games';

import GamesCreateForm from 'arcade-frontend-features/src/forms/games/GamesCreateForm';
import { getManageGamesById } from 'arcade-frontend-features/src/reducers/manage/games/manageGamesById';
import { getManageGamesIsFetching } from 'arcade-frontend-features/src/reducers/manage/games/isFetching';
import { getManageGamesIsCreating } from 'arcade-frontend-features/src/reducers/manage/games/isCreating';

class ManageGamesFormContainer extends React.PureComponent {
  static displayName = 'ManageGamesFormContainer';

  static propTypes = {
    apiManageGamesShowRequest: ArcPropTypes.func.isRequired,
    currentGameId: ArcPropTypes.string,
    isCreating: ArcPropTypes.bool.isRequired,
    isFetching: ArcPropTypes.bool.isRequired,
    manageGamesById: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
    onCancel: ArcPropTypes.func.isRequired,
  };

  static defaultProps = {
    currentGameId: null,
  };

  constructor(props) {
    super(props);

    if (props.currentGameId) {
      props.apiManageGamesShowRequest(props.currentGameId);
    }
  }

  renderCreate() {
    return (
      <GamesCreateForm
        onCancel={this.props.onCancel}
        isCreating={this.props.isCreating}
        inDialog
      />
    );
  }

  renderEdit() {
    const currentGame = this.props.manageGamesById[this.props.currentGameId];

    if (this.props.isFetching) {
      return (
        <ArcView padding="4" flexGrow="1">
          <ArcView flexGrow="1" align="center" justify="center" color="paper">
            <loaders.LoaderBars />
          </ArcView>
        </ArcView>
      );
    }

    return (
      <GamesCreateForm
        currentGame={currentGame}
        onCancel={this.props.onCancel}
        isCreating={this.props.isCreating}
        initialValues={GamesCreateForm.getInitialValues(currentGame)}
        inDialog
      />
    );
  }

  render() {
    return this.props.currentGameId ? this.renderEdit() : this.renderCreate();
  }
}

const mapStateToProps = state => ({
  isCreating: getManageGamesIsCreating(state),
  isFetching: getManageGamesIsFetching(state),
  manageGamesById: getManageGamesById(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      apiManageGamesShowRequest: actions.apiManageGamesShowRequest,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageGamesFormContainer);
