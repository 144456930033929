import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import People from '@material-ui/icons/People';

function NewsfeedPostedToBadge({ id, name, onClick }) {
  return (
    <Box
      display="inline-flex"
      alignItems="center"
      color="text.secondary"
      fontSize="0.8em"
      onClick={evt => onClick(evt, id)}
    >
      <Box display="inline">{'Posted to'}</Box>
      <Box
        alignItems="center"
        display="inline-flex"
        bgcolor="#f2f2f2"
        px={1}
        py={0.5}
        borderRadius={4}
        ml={1}
      >
        <People color="disabled" fontSize="inherit" />
        <Box display="inline" ml={0.5}>
          {name}
        </Box>
      </Box>
    </Box>
  );
}

NewsfeedPostedToBadge.displayName = 'NewsfeedPostedToBadge';

NewsfeedPostedToBadge.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

NewsfeedPostedToBadge.defaultProps = {
  id: '',
  name: '',
  onClick: global.noop,
};

export default NewsfeedPostedToBadge;
