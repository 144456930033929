import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';

import { data, resources } from '../../resources/notifications';
import NotificationsPanelActivity from '../../components/NotificationsPanelActivity';

const ANIMATION_DELAY = 500;

const NotificationsPanelActivityContainer = () => {
  const currentUser = useSelector(getCurrentUser);
  const [notifications, setNotifications] = useState([]);

  const apiGetNotificationsActivity = useResourceAction(
    resources.apiGetNotificationsActivity,
  );
  const apiPutNotificationsMarkAsRead = useResourceAction(
    resources.apiPutNotificationsMarkAsRead,
  );

  const {
    notifications: notificationsRaw,
  } = data.apiGetNotificationsActivityRaw;

  useEffect(() => {
    if (currentUser.id) {
      apiGetNotificationsActivity.requestData().then(() => {
        setNotifications(notificationsRaw);
      });
    }
  }, [currentUser.id]);

  const handleMarkAsRead = () => {
    apiPutNotificationsMarkAsRead.requestData().finally(() => {
      setTimeout(() => {
        apiPutNotificationsMarkAsRead.onStatusReset();
      }, ANIMATION_DELAY);
    });
  };

  return (
    <NotificationsPanelActivity
      notifications={notifications}
      onMarkAsRead={handleMarkAsRead}
      statusGetNotificationsActivity={apiGetNotificationsActivity.status}
      statusPutNotificationsMarkAsRead={apiPutNotificationsMarkAsRead.status}
    />
  );
};

NotificationsPanelActivityContainer.displayName =
  'NotificationsPanelActivityContainer';

export default NotificationsPanelActivityContainer;
