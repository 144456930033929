// https://flatuicolors.com/palette/defo

// Teal
export const turquoise = '#1abc9c';

// Green
export const emerald = '#2ecc71';

// Blue
export const river = '#3498db';

// Purple
export const amethyst = '#9b59b6';

// Yellow
export const sunflower = '#f1c40f';

// Orange
export const carrot = '#e67e22';

// Red
export const alizarin = '#e74c3c';

// Grey
// export const concrete = '#95a5a6';
export const concrete = '#8c8c8c';

// Black
export const asphalt = '#34495e';

// White
export const clouds = '#ecf0f1';

export default {
  black: asphalt,
  blue: river,
  green: emerald,
  grey: concrete,
  orange: carrot,
  purple: amethyst,
  red: alizarin,
  teal: turquoise,
  white: clouds,
  yellow: sunflower,
};
