import {
  ArcPropTypes,
} from 'arcade-frontend-ui';

import getComponentFromDirective from 'arcade-frontend-core/src/helpers/getComponentFromDirective';
import * as types from 'arcade-frontend-core/src/types/routes';

const { PLATFORMS } = ArcPropTypes;

const ArcadeAngularHomeView = getComponentFromDirective('arcadeHome');

const base = {
  [types.ROUTE_HOME]: ArcadeAngularHomeView,
};

const benjo = base;
const development = base;
const launch = base;

export default {
  [PLATFORMS.BENJO]: benjo,
  [PLATFORMS.DEVELOPMENT]: development,
  [PLATFORMS.LAUNCH]: launch,
};
