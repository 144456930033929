import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../actions';

const handleEntitiesIndexSuccess = (state, action) => {
  const { data } = action.payload;

  return data.map(team => team.id);
};

const initialState = [];

const handlers = {
  [types.APP_TAGGABLE_ENTITIES_INDEX.SUCCESS]: handleEntitiesIndexSuccess,
};

const list = createReducer(initialState, handlers);

export default list;

export const getList = state => state.app.entities.list;
