import React from 'react';
import { Formik } from 'formik';
import { throttle } from 'throttle-debounce';
import PropTypes from 'prop-types';
import IconClear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
  BOUNTY,
  RACE,
  TOURNAMENT,
  KEEP_ABOVE,
  isRPAType,
} from 'arcade-frontend-core/src/types/game-formats';
import {
  ArcButton,
  ArcText,
  ArcTextField,
  ArcView,
  createWithStyles,
} from 'arcade-frontend-ui';

import GameVerificationListItem from '../GameVerificationListItem';

const validate = values => {
  const errors = {};

  Object.entries(values).forEach(item => {
    const [key, value] = item;
    if (!value) {
      errors[key] = 'A score is required';
    } else if (/[^0-9.]/g.test(value)) {
      errors[key] = 'Score must be a number';
    }
    return null;
  });

  return errors;
};

const PLAYERS_TO_SHOW = 12;
const PLAYERS_TO_TRUNCATE = 10;

const styles = {
  ButtonRow: theme => ({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),

  ListHeader: theme => ({
    root: {
      margin: theme.spacing(1),
    },
  }),

  PlaceholderText: theme => ({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  }),
};

const ButtonRow = createWithStyles(styles.ButtonRow)(ArcView);
const ListHeader = createWithStyles(styles.ListHeader)(ArcView);
const PlaceholderText = createWithStyles(styles.PlaceholderText)(ArcView);

class GameVerificationList extends React.PureComponent {
  static displayName = 'GameVerificationList';

  static propTypes = {
    onClickEdit: PropTypes.func,
    onClickVerify: PropTypes.func,
    goal: PropTypes.number,
    isEditing: PropTypes.bool,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        score: PropTypes.number,
      }),
    ),
    rewards: PropTypes.arrayOf(
      PropTypes.shape({
        receivingRank: PropTypes.number,
      }),
    ),
    type: PropTypes.string,
  };

  static defaultProps = {
    onClickEdit: global.noop,
    onClickVerify: global.noop,
    goal: null,
    isEditing: false,
    results: [],
    rewards: [],
    type: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      editableResults: this.props.results || [],
      updatedScores: {},
      filteredResultIds: [],
      searchTerm: '',
      truncateResults: this.props.results.length > PLAYERS_TO_SHOW,
    };

    this.resultsById = {};

    this.props.results.forEach(result => {
      this.resultsById[result.id] = result;
    });
  }

  get initialValues() {
    const initialScores = {};
    const { results } = this.props;

    results.forEach(result => {
      initialScores[result.id] = result.score.toString();
    });

    return initialScores;
  }

  get hasNoUpdates() {
    const { updatedScores } = this.state;

    return (
      Object.values(updatedScores).indexOf(true) < 0 ||
      Object.keys(updatedScores).length === 0
    );
  }

  get numberOfRewards() {
    return this.props.rewards.length;
  }

  get hasWinners() {
    if (this.props.type === TOURNAMENT) {
      return !!this.state.editableResults.filter(result => result.score > 0)
        .length;
    }
    return !!this.state.editableResults.filter(
      result => result.score >= this.props.goal,
    ).length;
  }

  get prizeWinningResults() {
    const { type, goal } = this.props;
    const { editableResults } = this.state;

    if (type === TOURNAMENT) {
      return editableResults
        .filter(result => result.score > 0)
        .slice(0, this.numberOfRewards);
    }

    const isBountyOrRPA = type === BOUNTY || isRPAType(type);
    const filteredResultsByGoal = editableResults.filter(
      result => result.score >= goal,
    );

    return isBountyOrRPA
      ? filteredResultsByGoal
      : filteredResultsByGoal.slice(0, this.numberOfRewards);
  }

  get editedResults() {
    let results = this.state.editableResults;

    const editedResultsById = {};

    this.state.editableResults.forEach(result => {
      editedResultsById[result.id] = result;
    });

    if (this.state.filteredResultIds.length || this.state.searchTerm) {
      results = this.state.filteredResultIds.map(id => editedResultsById[id]);
    }

    results.sort((a, b) => {
      if (a.score > b.score) {
        return -1;
      }
      if (a.score < b.score) {
        return 1;
      }
      return 0;
    });

    if (this.state.truncateResults) {
      return results.slice(0, PLAYERS_TO_TRUNCATE);
    }

    return results;
  }

  setFilteredResultIds = filteredResultIds =>
    this.setState({ filteredResultIds });

  setSearchTerm = (searchTerm, cb) => this.setState({ searchTerm }, cb);

  setEditableResults = editableResults => this.setState({ editableResults });

  setTruncateResults = truncateResults => this.setState({ truncateResults });

  handleClickSeeMoreButton = () => this.setTruncateResults(false);

  handleClickResetSearch = () =>
    this.setSearchTerm('', this.updateListThrottled);

  handleChangeSearchTerm = event => {
    this.setSearchTerm(event.target.value, this.updateListThrottled);
  };

  updateListThrottled = throttle(300, () => {
    const updatedResultsList = this.filterResultsBySearchTerm(
      this.state.searchTerm,
    );

    this.setFilteredResultIds(updatedResultsList.map(result => result.id));
  });

  filterResultsBySearchTerm = searchTerm =>
    this.props.results.filter(person =>
      this.containsSearchTerm(person, searchTerm),
    );

  containsSearchTerm = (person, searchTerm) =>
    person.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;

  determineReward = result => {
    const { goal, type } = this.props;

    // Reference made to KEEPABOVE game type for when this functionality is introduced
    const ranking = this.determineRanking(result.id);
    if ((type === BOUNTY || type === KEEP_ABOVE) && result.score >= goal) {
      return this.props.rewards[0];
    }
    if (type === RACE && result.score >= goal) {
      return this.props.rewards.find(
        reward => reward.receivingRank === ranking,
      );
    }
    if (type === TOURNAMENT && result.score > 0) {
      return this.props.rewards.find(
        reward => reward.receivingRank === ranking,
      );
    }
    return null;
  };

  determineRanking = id => {
    const person = this.state.editableResults.find(result => result.id === id);
    return this.state.editableResults.indexOf(person);
  };

  renderVerificationButtons = formikProps => {
    const handleResetAll = () => {
      formikProps.resetForm();
      this.setState({
        editableResults: this.props.results,
        updatedScores: {},
      });
    };

    const handleClickVerify = () => {
      const scoresArray = formikProps.dirty ? formikProps.values : [];

      this.props.onClickVerify(scoresArray);
    };

    return (
      <ButtonRow row justify="space-between">
        <ArcView row>
          <ArcButton
            onClick={handleResetAll}
            variant="outlined"
            color="secondary"
            label="Revert"
            hidden={this.hasNoUpdates}
            isSpaced
          />
        </ArcView>
        <ArcView row>
          <ArcButton
            onClick={this.props.onClickEdit}
            variant="contained"
            color="primary"
            label="Edit"
            isSpaced
          />
          <ArcButton
            color="secondary"
            variant="contained"
            label="Verify"
            onClick={handleClickVerify}
          />
        </ArcView>
      </ButtonRow>
    );
  };

  renderEditingButtons = formikProps => {
    const handleCancel = () => {
      this.props.onClickEdit();
      formikProps.resetForm();
      this.setSearchTerm('', this.updateListThrottled);
      this.setState({
        editableResults: this.props.results,
        updatedScores: {},
      });
    };

    const handleSave = () => {
      this.props.onClickEdit();
    };

    const handleResetAll = () => {
      formikProps.resetForm();
      this.setState({
        editableResults: this.props.results,
        updatedScores: {},
      });
    };

    return (
      <ButtonRow row justify="space-between">
        <ArcView>
          <ArcButton
            onClick={handleResetAll}
            variant="outlined"
            color="secondary"
            label="Reset All"
            hidden={this.hasNoUpdates}
            isSpaced
          />
        </ArcView>
        <ArcView row>
          <ArcButton
            onClick={handleCancel}
            variant="text"
            label="Cancel"
            isSpaced
          />
          <ArcButton
            color="secondary"
            variant="contained"
            label="Save"
            disabled={!formikProps.dirty || this.hasNoUpdates}
            onClick={handleSave}
          />
        </ArcView>
      </ButtonRow>
    );
  };

  renderHeader = () => (
    <ListHeader row justify="space-between">
      <ArcText style={{ width: 76 }}>Rank</ArcText>
      <ArcText style={{ width: 200 }}>Name</ArcText>
      <ArcText style={{ width: 200 }}>Prize</ArcText>
      <ArcText>Score</ArcText>
    </ListHeader>
  );

  renderForm = formikProps => {
    const { isEditing, ...rest } = this.props;

    const { editableResults, updatedScores } = this.state;

    const handleScoresDirty = (resultId, action) => {
      let addUpdatedScores = {};

      if (action && action === 'reset') {
        addUpdatedScores = { ...updatedScores, [resultId]: false };
      } else {
        const didUpdate =
          formikProps.values[resultId] !== this.initialValues[resultId];

        addUpdatedScores = { ...updatedScores, [resultId]: didUpdate };
      }
      this.setState({ updatedScores: addUpdatedScores });
    };

    const handleUpdate = (resultId, value, action) => {
      if (formikProps.errors[resultId]) return;

      let editedResults = [];

      editedResults = editableResults.map(result => {
        if (resultId === result.id) {
          let newResult = {};

          newResult = { ...result, score: parseFloat(value) };

          return newResult;
        }
        return result;
      });

      editedResults.sort((a, b) => {
        if (a.score > b.score) {
          return -1;
        }
        if (a.score < b.score) {
          return 1;
        }
        return 0;
      });

      handleScoresDirty(resultId, action);

      this.setEditableResults(editedResults);
    };

    const displayedResults = isEditing
      ? this.editedResults
      : this.prizeWinningResults;

    return (
      <ArcView marginTop="16">
        {this.hasWinners || isEditing ? (
          displayedResults.map(result => (
            <GameVerificationListItem
              className="animated fadeIn"
              key={result.id}
              rankingDisplay={this.determineRanking(result.id) + 1}
              person={result}
              isEditing={isEditing}
              formikProps={formikProps}
              onBlur={formikProps.onBlur}
              touched={formikProps.touched[result.id]}
              error={formikProps.errors[result.id]}
              onChange={formikProps.handleChange}
              onUpdate={handleUpdate}
              setFieldValue={formikProps.setFieldValue}
              value={formikProps.values[result.id]}
              hasUpdated={updatedScores[result.id]}
              reward={this.determineReward(result)}
              {...rest}
            >
              {result.name}
            </GameVerificationListItem>
          ))
        ) : (
          <PlaceholderText>
            <ArcText>
              {
                'There were no eligible winners for this game - no prizes will be awarded'
              }
            </ArcText>
          </PlaceholderText>
        )}
        {!this.state.filteredResultIds.length && !!this.state.searchTerm && (
          <PlaceholderText>
            <ArcText>{'No results found'}</ArcText>
          </PlaceholderText>
        )}
        {isEditing &&
          this.state.truncateResults &&
          !this.state.searchTerm &&
          this.renderSeeMoreButton()}
        {isEditing
          ? this.renderEditingButtons(formikProps)
          : this.renderVerificationButtons(formikProps)}
      </ArcView>
    );
  };

  renderSearchField() {
    const totalPlayers = this.props.results.length;
    const searchPlaceholderText = `Search ${totalPlayers} Players`;
    return (
      <ArcTextField
        placeholder={searchPlaceholderText}
        value={this.state.searchTerm}
        onChange={this.handleChangeSearchTerm}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton onClick={this.handleClickResetSearch}>
                <IconClear />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }

  renderPlayerCount = () => {
    const { type, results, rewards } = this.props;
    const { editableResults } = this.state;
    const playerCount = results.length;

    return (
      <ArcView style={{ padding: '16px 0' }}>
        <ArcText>
          {this.prizeWinningResults.length > 0
            ? `${this.prizeWinningResults.length} out of ${playerCount} players won rewards`
            : `${playerCount} players participated in this game`}
        </ArcText>
      </ArcView>
    );
  };

  renderSeeMoreButton = () => (
    <ArcButton
      onClick={this.handleClickSeeMoreButton}
      variant="text"
      label="Show remaining players"
      isSpaced
      style={{ margin: 8 }}
    />
  );

  render() {
    return (
      <React.Fragment>
        {this.props.isEditing
          ? this.renderSearchField()
          : this.renderPlayerCount()}
        <Formik initialValues={this.initialValues} validate={validate}>
          {this.renderForm}
        </Formik>
      </React.Fragment>
    );
  }
}

export default GameVerificationList;
