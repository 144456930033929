import humps from 'humps';
import { compose } from 'redux';
import createResourceAction from 'arcade-frontend-core/src/helpers/createResourceAction';
import transformIdToString from 'arcade-frontend-core/src/helpers/transformIdToString';

const transformResponseProfile = compose(
  data => ({ resources: [data] }),
  transformIdToString,
  data => data.user,
);

export const apiGetProfile = createResourceAction('read', {
  resourceType: 'profile',
  requestKey: 'apiGetProfile',

  api: (payload) => {
    const id = payload.resources[0];

    return {
      method: 'GET',
      url: `/v4/users/${id}`,
      transformResponse: [
        data => data.user,
        transformIdToString,
        data => ({
          ...data,
          contactNumber: data.contactNumber || '',
          password: data.password || '',
          passwordConfirmation: data.passwordConfirmation || '',
        }),
        data => ({ resources: [data] }),
      ],
    };
  },
});

export const apiPutProfile = createResourceAction('update', {
  resourceType: 'profile',
  requestKey: 'apiPutProfile',

  api: (payload) => {
    const formValues = payload.resources[0];

    const user = {
      anniversary: formValues.anniversary,
      birthday: formValues.birthday,
      contactNumber: formValues.contactNumber,
      currentPassword: formValues.currentPassword,
      email: formValues.email,
      firstname: formValues.firstname,
      lastname: formValues.lastname,
      password: formValues.newPassword,
    };

    Object.entries(user).forEach(([key, value]) => {
      if (!value) {
        delete user[key];
      }
    });

    return {
      method: 'PUT',
      url: '/v4/users',
      data: {
        user: humps.decamelizeKeys(user),
      },
      transformResponse: data => (data.user ? transformResponseProfile(data) : data),
    };
  },
});
