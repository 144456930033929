import { bindActionCreators } from 'redux';

import * as actions from './actions';
import * as routes from './routes';
import * as types from './types';

export {
  actions,
  routes,
  types,
};

export const getMetricsIndexActions = dispatch => bindActionCreators({
  apiMetricsIndexRequest: actions.apiMetricsIndexRequest,
  routeMetricsShow: routes.routeMetricsShow,
}, dispatch);
