import arcConsole from '../arc/console';
import { getResource } from './resource';
import { isArray } from './array';

const handleNormalize = (rootKey, normalizer, rootData = {}) => response => {
  const responseData = response && response.data ? response.data : response;
  const data = rootKey ? responseData[rootKey] : responseData;

  let normalizedData = null;

  if (!data) {
    arcConsole.error(
      'handleNormalize',
      `data is \`${data}\`. Check normalizer \`${normalizer.name}\``,
    );
  }

  if (isArray(data)) {
    normalizedData = data.map(normalizer);
  } else {
    normalizedData = normalizer(data);
  }

  return {
    ...rootData,
    data: normalizedData,
    raw: response,
  };
};

const makeAction = endpoint => params => {
  const { rootKey, rootData, normalizer, ...config } = endpoint(params);

  if (normalizer) {
    return getResource()
      .request(config)
      .then(handleNormalize(rootKey, normalizer, rootData));
  }

  return getResource().request(config);
};

export const makeApiActions = endpoints => {
  const endpointNames = Object.keys(endpoints);
  const actions = {};

  endpointNames.forEach(name => {
    actions[name] = makeAction(endpoints[name]);
  });

  return actions;
};

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const RESET = 'RESET';

const toApiFormat = (type, action) => `api/${type}_${action}`;

export const makeApiActionTypes = type => ({
  key: type,
  [REQUEST]: toApiFormat(type, REQUEST),
  [SUCCESS]: toApiFormat(type, SUCCESS),
  [FAILURE]: toApiFormat(type, FAILURE),
  [RESET]: toApiFormat(type, RESET),
});
