import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const TakeMyMoney = props => (
  <Icon {...props}>
    <Path d="M19.7939 22.5H12.3719C11.9899 22.5 11.6119 22.573 11.2579 22.715L9.29395 23.5" fill="black" />
    <Path fill="transparent" d="M19.7939 22.5H12.3719C11.9899 22.5 11.6119 22.573 11.2579 22.715L9.29395 23.5" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="transparent" d="M12.2939 12.5H20.7939L21.4239 13.445C21.6479 13.781 21.6479 14.219 21.4239 14.555L21.1159 15.017C20.9179 15.315 20.8939 15.697 21.0539 16.017L21.5709 17.051C21.7119 17.332 21.7119 17.664 21.5709 17.945L20.9789 19.128C20.8609 19.365 20.8409 19.64 20.9249 19.891L21.0989 20.412C21.2189 20.771 21.1249 21.167 20.8569 21.435L19.7939 22.5" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="transparent" d="M12.2939 10.5L10.8799 1.074C10.8389 0.801 11.0269 0.546 11.2999 0.506C11.3249 0.502 11.3499 0.5 11.3749 0.5H18.2939" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="transparent" d="M2.29395 17.4999L5.65195 16.2409C6.06795 16.0849 6.41995 15.7949 6.65195 15.4159L9.95195 10.0589C10.1789 9.69091 10.4829 9.37591 10.8429 9.13591L11.9779 8.37891" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="transparent" d="M14.794 12.5L13.849 3.05C13.821 2.775 14.022 2.53 14.296 2.503C14.314 2.501 14.331 2.5 14.349 2.5H21.216C21.492 2.5 21.716 2.724 21.716 3C21.716 3.025 21.714 3.049 21.71 3.074L20.294 12.5" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="transparent" fillRule="evenodd" clipRule="evenodd" d="M17.5439 6.5C18.2339 6.5 18.7939 7.06 18.7939 7.75C18.7939 8.44 18.2339 9 17.5439 9C16.8539 9 16.2939 8.44 16.2939 7.75C16.2939 7.06 16.8539 6.5 17.5439 6.5Z" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

export default TakeMyMoney;
