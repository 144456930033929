import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../../actions/manage/games/types';

const handleGameEventsLoadSuccess = (state, action) => {
  const { id, data } = action.payload;

  const newState = { ...state };

  newState[id] = data;

  return newState;
};

const initialState = {};

const handlers = {
  [types.MANAGE_GAMES_EVENTS_INDEX.SUCCESS]: handleGameEventsLoadSuccess,
};

export default createReducer(initialState, handlers);

export const eventsByGameId = state => state.manage.games.eventsByGameId;
