import pluralize from 'pluralize';

const isCurrency = value => {
  if (!value) return true;
  const modelValue = value.replace(/(,|\s)/g, '');

  if (/[^0-9.]/g.test(modelValue)) return false;
  if (
    /[.]/g.test(modelValue) &&
    modelValue.toString().match(/[.]/g).length >= 2
  )
    return false;
  return true;
};

export const isNumber = value => {
  if (!value) return true;
  if (/[^0-9.]/g.test(value)) return false;
  if (/[.]/g.test(value) && value.toString().match(/[.]/g).length >= 2)
    return false;
  return true;
};

export const isInteger = value => {
  if (!value) return true;
  if (/[^0-9]/g.test(value)) return false;
  return true;
};

export const isRequired = model => {
  if (typeof model === typeof undefined || model === null) return false;

  if (typeof model.length !== typeof undefined) {
    return model.length > 0;
  }

  return true;
};

export const isEmail = email => {
  if (!email) {
    return false;
  }

  const regExpEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regExpEmail.test(String(email).toLowerCase());
};

export const validators = {
  isRequired: bool => model => (bool ? isRequired(model) : true),
  isCurrency: bool => model => (bool ? isCurrency(model) : true),
  isNumber: bool => model => (bool ? isNumber(model) : true),
  isInteger: bool => model => (bool ? isInteger(model) : true),
  isEmail: bool => model => (bool ? isEmail(model) : true),

  minLength: min => model => !model || !min || model.length >= min,
  maxLength: max => model => !model || !max || model.length <= max,

  minNumber: min => model => !model || model >= min,
  maxNumber: max => model => !model || model <= max,

  greaterThan: min => model => model > min,
  lessThan: max => model => model < max,

  minSelect: min => model => !model || model.length >= min,
  maxSelect: max => model => !model || model.length <= max,
};

export const messages = {
  isRequired: label => `${label} is required.`,
  isCurrency: label => `${label} must be valid currency.`,
  isNumber: label => `${label} must be a number.`,
  isInteger: label => `${label} must be a whole number.`,
  isEmail: label => `${label} must be a valid email`,

  minLength: (label, min) => `${label} needs to be at least ${min} characters.`,
  maxLength: (label, max) =>
    `${label} needs to be less than ${max} characters.`,

  minNumber: (label, min) => `${label} needs to be at least ${min}.`,
  maxNumber: (label, max) => `${label} cannot be more than ${max}.`,

  greaterThan: (label, min) => `${label} must be greater than ${min}.`,
  lessThan: (label, max) => `${label} must be less than ${max}.`,

  minSelect: (label, min) => `${min} ${pluralize(label, min)} required.`,
  maxSelect: (label, max) => `${max} ${pluralize(label, max)} maximum.`,
};

export const makeValidators = (label, validations) => {
  const newMessages = {};
  const newValidators = {};

  Object.keys(validations).forEach(validation => {
    const value = validations[validation];

    newMessages[validation] = messages[validation](label, value);
    newValidators[validation] = validators[validation](value);
  });

  return {
    messages: newMessages,
    validators: newValidators,
  };
};

// window.pluralize = pluralize;
