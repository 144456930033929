import { Platform } from 'react-native';

export const DEVELOPMENT_ENDPOINTS = {
  endpoint:
    process.env.ARCADE_API_ENDPOINT || 'https://stagingapi.arcadehub.co',
  chatEndpoint:
    process.env.ARCADE_CHAT_ENDPOINT || 'https://staging-chat.arcadehub.co',
};

export const DEFAULT_ENDPOINTS = {
  endpoint: 'https://metropcsapi.arcadehub.co',
  chatEndpoint: 'https://metro-chat.arcadehub.co',
};

export const RTPOS_ENDPOINTS = {
  endpoint: 'https://rtposapi.arcadehub.co',
  chatEndpoint: 'https://rtpos-chat.arcadehub.co',
};

export const STAGING_ENDPOINTS = {
  endpoint: 'https://stagingapi.arcadehub.co',
  chatEndpoint: 'https://staging-chat.arcadehub.co',
};

export const AUS_ENDPOINTS = {
  endpoint: 'https://api.arcadehub.co',
  chatEndpoint: 'https://chat.arcadehub.co',
};

export const VICTRA_ENDPOINTS = {
  endpoint: 'https://victraapi.arcadehub.co',
  chatEndpoint: 'https://victra-chat.arcadehub.co',
};

const ARCADE_EMAIL_DOMAINS = ['workplacearcade.com', 'arcade.co'];

export default function getEndpointsByEmail(email = '') {
  if (process.env.NODE_ENV === 'development') {
    return DEFAULT_ENDPOINTS;
  }

  if (process.env.ARCADE_WHITELABEL_ID === 'rtpos') {
    return RTPOS_ENDPOINTS;
  }

  const [username, domain] = email.toLowerCase().split('@');

  const isArcadeUser = ARCADE_EMAIL_DOMAINS.indexOf(domain) > -1;
  const isArcadeTestUser = isArcadeUser && username === 'arcade_test';
  const isAppleDemoUser = isArcadeUser && username === 'appledemo';
  const isArcadeVictraUser =
    isArcadeUser && username && username.endsWith('+victra');

  if (isArcadeVictraUser || domain === 'victra.com') {
    return VICTRA_ENDPOINTS;
  }

  if ((isArcadeUser || isArcadeTestUser) && !isAppleDemoUser) {
    const isStaging =
      Platform.OS === 'web' &&
      (window.location.href.startsWith('https://staging.arcadehub.co') ||
        window.location.href.startsWith(
          'https://s3-ap-southeast-2.amazonaws.com',
        ) ||
        window.location.href.startsWith('http://localhost'));

    return isStaging ? STAGING_ENDPOINTS : AUS_ENDPOINTS;
  }

  return DEFAULT_ENDPOINTS;
}
