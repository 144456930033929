import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import Buildings from './Buildings';
import Land from './Land';
import Light from './Light';

const styles = makeStyles({
  wrapper: {
    width: '100%',
    overflow: 'hidden',
  },
  buildings: {
    width: '100%',
    transform: 'translate(0, 20px)',
  },
  land: {
    color: '#564490',
    width: '100%',
    transform: 'translate(0, -10%)',
  },
  lights: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  lightLeft: {
    position: 'absolute',
    left: '10%',
    width: '5%',
    bottom: 0,
  },
  lightRight: {
    transform: 'scaleX(-1)',
    position: 'absolute',
    right: '10%',
    width: '5%',
    bottom: 0,
  },
});


function ArcSkyline() {
  const classes = styles();

  return (
    <ArcBox className={classes.wrapper}>
      <Buildings className={classes.buildings} />
      <Land className={classes.land} />
      <ArcBox className={classes.lights}>
        <ArcBox className={classes.lightLeft}><Light /></ArcBox>
        <ArcBox className={classes.lightRight}><Light /></ArcBox>
      </ArcBox>
    </ArcBox>
  );
}

ArcSkyline.displayName = 'ArcSkyline';

ArcSkyline.propTypes = {

};

ArcSkyline.defaultProps = {

};

export default ArcSkyline;
