import arrayToSentence from './arrayToSentence';
import { pluralize } from './pluralize';

export default function getRewardsAsText(rewards) {
  const items = rewards.map(({ type, value }) => {
    if (type === 'chests') {
      const chestsWithCounts = Object.entries(value).map(
        ([rarity, count]) => `${count} ${rarity.toLowerCase()}`,
      );
      return `${arrayToSentence(chestsWithCounts)} ${pluralize(
        chestsWithCounts.length,
        'chest',
      )}`;
    }
    return `${value} ${type}`;
  });
  return arrayToSentence(items);
}
