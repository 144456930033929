import { combineReducers } from 'redux';

import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';
import { reactionOptions } from '../reactionData';

const handleActivityReactions = (state, action) => {
  const { data } = action.payload;
  const { id } = action.meta.requestAction.payload;
  return { ...state, [id]: data };
};

const handleActivityReactionsUpdating = (state, action) => {
  const { payload } = action;
  const { id } = payload;
  return { ...state, [id]: true };
};
const handleActivityReactionsUpdated = (state, action) => {
  const { id } = action.meta.requestAction.payload;
  return { ...state, [id]: undefined };
};

const handleNewsfeedIndexSuccess = (state, action) => {
  const nextState = { ...state };

  Object.values(action.payload.data.activities).forEach(activity => {
    nextState[activity.id] = activity.reactions;
  });

  return nextState;
};

const handleNewsfeedActivitySuccess = (state, action) => {
  const nextState = { ...state };

  nextState[action.payload.data.id] = action.payload.data.reactions;

  return nextState;
};

const handleNewsfeedSummarySuccess = (state, action) => {
  const nextState = { ...state };

  Object.values(action.payload.data).forEach(activity => {
    nextState[activity.id] = activity.reactions;
  });

  return nextState;
};

const reactionsInitialState = {};

const updatingInitialState = {};

const reactionsHandlers = {
  [types.NEWSFEED_GET_ACTIVITY_REACTIONS.SUCCESS]: handleActivityReactions,
  [types.NEWSFEED_INDEX.SUCCESS]: handleNewsfeedIndexSuccess,
  [types.NEWSFEED_GET_SUMMARY_ACTIVITIES.SUCCESS]: handleNewsfeedSummarySuccess,
  [types.NEWSFEED_ACTIVITY.SUCCESS]: handleNewsfeedActivitySuccess,
};
const updatingHandlers = {
  [types.NEWSFEED_SAVE_ACTIVITY_REACTION]: handleActivityReactionsUpdating,
  [types.NEWSFEED_GET_ACTIVITY_REACTIONS
    .REQUEST]: handleActivityReactionsUpdating,
  [types.NEWSFEED_GET_ACTIVITY_REACTIONS
    .SUCCESS]: handleActivityReactionsUpdated,
  [types.NEWSFEED_GET_ACTIVITY_REACTIONS
    .FAILURE]: handleActivityReactionsUpdated,
};

const reactionsReducer = createReducer(
  reactionsInitialState,
  reactionsHandlers,
);
const updatingReducer = createReducer(updatingInitialState, updatingHandlers);

export default combineReducers({
  reactions: reactionsReducer,
  updating: updatingReducer,
});

export const activityReactionsById = state =>
  state.newsfeed.activityReactions.reactions;

export const getReactionsByActivityId = activityReactionsById;

export const getReactionsIsRequestingByActivityId = state =>
  state.newsfeed.activityReactions.updating;

export const activityReactionsUpdatingById = state =>
  state.newsfeed.activityReactions.updating;

export const activityReactions = (state, props) =>
  activityReactionsById(state)[props.activity.id] || [];

export const activityReactionUpdating = (state, props) =>
  activityReactionsUpdatingById(state)[props.activity.id];

export const activityReactionTotals = (state, props) => {
  const reactions = activityReactions(state, props);
  return reactionOptions.map(reaction => ({
    reaction,
    total: reactions.filter(r => r.reaction === reaction).length,
  }));
};
