import moment from 'moment';

const SERVER_DATE_FORMAT = 'D MMM YYYY hh:mm a';

const sortComments = comments => {
  comments.sort((a, b) => {
    if (a.created_at < b.created_at) {
      return -1;
    }

    if (a.created_at > b.created_at) {
      return 1;
    }

    return 0;
  });

  return comments;
};

const likeNormalizer = l => ({
  username: l.username,
  userId: l.user_id.toString(),
  userImage: l.user_image,
  createdAt: l.created_at,
});

const viewNormalizer = v => ({
  username: v.username,
  userId: v.user_id.toString(),
  userImage: v.user_image,
  createdAt: v.date_data,
});

const ackNormalizer = viewNormalizer;

export const reactionNormalizer = props => ({
  ...props,
  user: props.user
    ? {
        ...props.user,
        id: props.user.id ? props.user.id.toString() : '',
      }
    : {},
  id: props.id ? props.id.toString() : '',
  userId: props.user.id ? props.user.id.toString() : '',
  userImage: props.user.image,
  userName: props.user.name,
});

const commentNormalizer = comment => ({
  ...comment,
  id: comment.id ? comment.id.toString() : '',
  readableDate: comment.readable_date_data,

  userBadge: comment.user_badge,
  userCreated: comment.user_created,
  userId: comment.user_id ? comment.user_id.toString() : '',
  userImage: comment.user_image,
  userName: comment.username,

  user: comment.user
    ? {
        ...comment.user,
        id: comment.user.id ? comment.user.id.toString() : '',
      }
    : {
        badge: comment.user_badge,
        id: comment.user_id ? comment.user_id.toString() : '',
        image: comment.user_image,
        name: comment.username,
      },

  reactions: comment.reactions ? comment.reactions.map(reactionNormalizer) : [],
});

const activityHistoryCommentNormalizer = c => ({
  id: c.id ? c.id.toString() : '',
  content: c.content,
  createdAt: c.created_at,
  displayContent: c.display_content,
  user: c.user,
});

const normalizeImage = image => ({
  ...image,
  preview: image.newsfeed,
  id: image.id ? image.id.toString() : '',
});

const idNormalizer = record => ({
  ...record,
  id: record.id ? record.id.toString() : '',
});

const nullableArrayIdNormalizer = records => {
  if (!records) return records;

  return records.map(idNormalizer);
};

export const activityNormalizer = activity => {
  const pinned = activity.pinned_information;
  const { user } = activity;
  const additional = activity.additional_information;

  const reactions = activity.reactions
    ? activity.reactions.map(reactionNormalizer)
    : [];

  return {
    ...activity,

    additionalInformation: {
      ...additional,
      id: additional.id ? additional.id.toString() : null,
      receivingUser: additional.receiving_user,
      nominatingUser: additional.nominating_user,
      tokenType: additional.token_type,
      tokenImage: additional.token_image,
      rewardedUsers: nullableArrayIdNormalizer(additional.rewarded_users),
    },
    audienceId: activity.audience_id ? activity.audience_id.toString() : '',
    audienceName: activity.audience_name,
    comments: activity.comments.map(commentNormalizer),
    createdAt: moment(activity.created_at, SERVER_DATE_FORMAT),
    commentCount: activity.comments_count,
    content: activity.content,
    currentUserCreated: activity.current_user_created,
    edited: activity.edited,
    file: activity.file,
    id: activity.id ? activity.id.toString() : '',
    images:
      activity.images && activity.images.length > 0
        ? activity.images.map(normalizeImage)
        : null,
    imageDownload: activity.image_download,
    interestingAt: moment(activity.interesting_at, SERVER_DATE_FORMAT),
    pinned: pinned.pinned,
    pinnedByName: pinned.pinned_by_name,
    pinnedByCurrentUser: pinned.pinned_by_current_user,
    participants: activity.participants
      ? activity.participants.map(id => id.toString())
      : [],
    reactions,
    respectCount: activity.likes,
    type: activity.type,
    userCreated: activity.user_created,
    user: {
      ...user,
      id: user.id ? user.id.toString() : '',
    },
    userId: user.id ? user.id.toString() : '',
    userName: user.name,
    userImage: user.image,
    userBadge: user.badge,
    userRespected: activity.user_liked,
    video: activity.video,
    views: activity.views,
    viewsCount: activity.views_count,
  };
};

const normalizeHistoryActivity = activity => ({
  id: activity.id ? activity.id.toString() : '',
  acknowledgements: activity.acknowledgements,
  attachments: activity.attachments,
  audience: activity.audience,
  comments: activity.comments,
  content: activity.display_content,
  date: moment(activity.created_at).format('ddd MMM DD'),
  pinned: activity.pinned,

  // these are numbers not objects
  respects: activity.respects,
  reactions: activity.reactions,
  views: activity.views,
});

export const activityHistoryNormalizer = payload => ({
  totalActivities: payload.total_activities,
  activities: payload.activities.map(normalizeHistoryActivity),
});

export const activityHistoryDetailsNormalizer = activity => ({
  acknowledgements: activity.acknowledgements,
  attachments: activity.attachments,
  audience: activity.audience,
  comments: sortComments(activity.comments).map(
    activityHistoryCommentNormalizer,
  ),
  content: activity.content,
  createdAt: moment(activity.created_at),
  currentUserCreated: activity.current_user_created,
  displayContent: activity.display_content,
  edit: activity.edited,
  file: activity.file,
  files: activity.files,
  id: activity.id.toString(),
  image: activity.image,
  images:
    activity.images && activity.images.length > 0
      ? activity.images.map(normalizeImage)
      : null,
  imageDownload: activity.image_download,
  interestingAt: moment(activity.interesting_at),
  pinned: activity.pinned,
  pinnedInformation: activity.pinned_information,
  user: activity.user,
  userLiked: activity.user_liked,
  video: activity.video,

  // these are numbers not objects
  respects: activity.respects,
  reactions: activity.reactions,
  views: activity.views,
});

export const normalizeActivity = activity => activityNormalizer(activity);

export const normalizeLikes = ({ likes }) => likes.map(likeNormalizer);
export const normalizeViews = ({ views }) => views.map(viewNormalizer);
export const normalizeAcks = ({ acknowledged }) =>
  acknowledged.map(ackNormalizer);
export const normalizeComments = ({ comments }) =>
  comments.map(commentNormalizer);

export function normalizeActivities({ activities }) {
  return activities.map(activityNormalizer);
}

function normalizeSummaries(summaryList) {
  if (!summaryList) {
    return [];
  }

  return summaryList.map(summary => ({
    counts: summary.counts,
    oldest: moment(summary.oldest, SERVER_DATE_FORMAT),
    newest: moment(summary.newest, SERVER_DATE_FORMAT),
    summaryUsers: summary.summary_users,
  }));
}

export const activityDataNormalizer = props => ({
  ...props,
  activities: normalizeActivities(props),
  pinned:
    props.pinned_activities &&
    normalizeActivities({ activities: props.pinned_activities }),
  summaries: normalizeSummaries(props.summaries),
});
