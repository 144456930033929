import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcFormField from 'arcade-frontend-forms/src/components/ArcFormField';

function ProfileEditFormContact() {
  return (
    <ArcBox className="animated fadeIn">
      <ArcBox display="flex" flexDirection={['column', 'row']}>
        <ArcBox display="flex" flex="1" mr={[0, 1]}>
          <ArcFormField
            name="firstname"
            type="text"
            label="First Name"
            fullWidth
          />
        </ArcBox>

        <ArcBox display="flex" flex="1" ml={[0, 1]}>
          <ArcFormField
            name="lastname"
            type="text"
            label="Last Name"
            fullWidth
          />
        </ArcBox>
      </ArcBox>

      <ArcFormField
        autoComplete="username"
        name="email"
        type="email"
        label="Email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Email color="action" fontSize="small" />
            </InputAdornment>
          ),
        }}
      />

      <ArcFormField
        name="contactNumber"
        type="tel"
        label="Phone"
        placeholder="+1 (888) 555-5555"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Phone color="action" fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
    </ArcBox>
  );
}

ProfileEditFormContact.displayName = 'ProfileEditFormContact';

export default ProfileEditFormContact;
