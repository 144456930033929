import React from 'react';
import PropTypes from 'prop-types';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';

import PeopleDeleteTeamModal from '../../components/PeopleDeleteTeamModal/PeopleDeleteTeamModal';
import { resources } from '../../resources/teams';

const ANIMATION_DELAY = 500;

function PeopleDeleteTeamModalContainer({
  open,
  onClose,
  onDeleteTeamSuccess,
  team,
}) {
  const apiDeleteTeam = useResourceAction(resources.apiDeleteTeam);
  const apiGetTeams = useResourceAction(resources.apiGetTeams);

  const handleClose = () => {
    apiDeleteTeam.onErrorReset();
    onClose();
  };

  const handleDeleteTeamConfirmation = () => {
    apiDeleteTeam.onStatusReset();
    apiDeleteTeam.onErrorReset();

    apiDeleteTeam
      .requestData({
        resources: [{ id: team.id }],
      })
      .then(() => {
        setTimeout(() => {
          apiGetTeams.requestData();
          apiDeleteTeam.onStatusReset();
          onDeleteTeamSuccess();
          handleClose();
        }, ANIMATION_DELAY);
      })
      .catch(() => {
        setTimeout(() => {
          apiGetTeams.requestData();
          apiDeleteTeam.onStatusReset();
        }, ANIMATION_DELAY);
      });
  };

  return (
    <PeopleDeleteTeamModal
      open={open}
      onClose={handleClose}
      onDeleteTeamConfirmation={handleDeleteTeamConfirmation}
      team={team}
      deleteTeamStatus={apiDeleteTeam.status}
      deleteTeamHasError={apiDeleteTeam.hasError}
    />
  );
}

PeopleDeleteTeamModalContainer.displayName = 'PeopleDeleteTeamModalContainer';

PeopleDeleteTeamModalContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onDeleteTeamSuccess: PropTypes.func,
  team: PropTypes.shape({
    id: PropTypes.string,
  }),
};

PeopleDeleteTeamModalContainer.defaultProps = {
  open: false,
  onClose: global.noop,
  onDeleteTeamSuccess: global.noop,
  team: null,
};

export default PeopleDeleteTeamModalContainer;
