import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from '@material-ui/styles';
import ArcBox from '../ArcBox';
import ArcText from '../../primitives/ArcText';


const ArcBadge = ({ children, variant, color, theme }) => {
  const getBackgroundColor = (colorProp) => {
    switch (colorProp) {
      case 'primary':
        return theme.palette.blue[200];
      case 'secondary':
        return '#DAF3ED';
      case 'warning':
        return theme.palette.yellow[200];
      case 'danger':
        return theme.palette.red[200];
      default:
        return '#E5E5E5';
    }
  };

  const getTextColor = (colorProp) => {
    switch (colorProp) {
      case 'primary':
        return theme.palette.blue.main;
      case 'secondary':
        return theme.palette.green.main;
      case 'warning':
        return theme.palette.yellow[700];
      case 'danger':
        return theme.palette.red.main;
      default:
        return theme.palette.grey[600];
    }
  };

  const getPadding = () => {
    switch (variant) {
      case 'contained':
        return '3px 6px';
      case 'text':
        return '3px 0';
      case 'round':
        return '0 4px';
      default:
        return '3px 6px';
    }
  };

  return (
    <ArcBox
      component={ArcText}
      bgcolor={variant === 'text' ? 'inherit' : getBackgroundColor(color)}
      p={getPadding()}
      borderRadius={variant === 'round' ? 50 : 4}
      color={getTextColor(color)}
      fontSize={12}
      fontWeight={600}
      textTransform="capitalize"
      textAlign="center"
      style={variant === 'round' && {
        display: 'inline-block',
        lineHeight: 1.7,
        height: 22,
        minWidth: 22,
        float: 'right',
        border: '2px solid white',
      }}
    >
      {children}
    </ArcBox>
  );
};


ArcBadge.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  variant: PropTypes.string,
  theme: PropTypes.shape({
    palette: PropTypes.any,
  }),
};

ArcBadge.defaultProps = {
  children: undefined,
  color: '',
  variant: 'contained',
  theme: {},
};

export default withTheme(ArcBadge);
