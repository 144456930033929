import {
  call as rsCall,
  put as rsPut,
  all as rsAll,
  takeEvery,
  takeLatest,
  select,
} from '@redux-saga/core/effects';

const createSuccessAction = (type, payload, requestAction) => ({
  type,
  payload,
  meta: {
    requestAction,
  },
});

const createErrorAction = (type, error, requestAction, status) => ({
  type,
  payload: { error, status, message: error ? error.message : '' },
  meta: {
    requestAction,
  },
});

export const makeSagaHandler = ({ type, call, onSuccess, onFailure }) =>
  function* (action = {}) {
    const state = yield select(storeState => storeState);

    try {
      if (typeof call !== 'function') {
        throw Error("makeSagaHandler requires a function for the 'call' prop");
      }

      const { payload } = action || {};

      const response = yield rsCall(call, payload);

      const puts = [rsPut(createSuccessAction(type.SUCCESS, response, action))];

      if (onSuccess) {
        if (onSuccess.constructor === Array) {
          onSuccess.forEach(fn => {
            const actionToPut = fn(response, state, action);
            if (actionToPut) {
              puts.push(rsPut(actionToPut));
            }
          });
        } else {
          const result = onSuccess(response, state, action);
          if (result) {
            puts.push(rsPut(result));
          }
        }
      }
      yield rsAll(puts);
    } catch (e) {
      console.error(e);
      const puts = [
        rsPut(createErrorAction(type.FAILURE, e.message, action, e.status)),
      ];

      if (onFailure) {
        if (onFailure.constructor === Array) {
          onFailure.forEach(fn => puts.push(rsPut(fn(e.message, state))));
        } else {
          const result = onFailure(e.message, state);
          if (result) {
            puts.push(rsPut(result));
          }
        }
      }

      yield rsAll(puts);
    }
  };

// USAGE: rename the following method to makeSagaHandler and comment out
// the helper above to set the entire app in an evergreen state

export const makeSagaHandlerDebug = ({ type, call, action }) =>
  function* () {
    let response = { data: [] };
    const { payload } = action || {};

    if (type.REQUEST.indexOf('INDEX') === -1) {
      response = yield rsCall(call, payload);
    }

    yield rsPut(createSuccessAction(type.SUCCESS, response));
  };

export const makeTakeEvery = saga =>
  takeEvery(saga.type.REQUEST, makeSagaHandler(saga));
export const makeTakeLatest = saga =>
  takeLatest(saga.type.REQUEST, makeSagaHandler(saga));

export default {
  makeSagaHandlerDebug,
  makeTakeEvery,
  makeTakeLatest,
};
