import { ArcPropTypes } from 'arcade-frontend-ui';

import { createRoutes } from './helpers';
import app from './views/app';
import academy from './views/academy';
import chat from './views/chat';
import games from './views/games';
import home from './views/home';
import hub from './views/hub';
import huddle from './views/huddle';
import insights from './views/insights';
import league from './views/league';
import manage from './views/manage';
import metrics from './views/metrics';
import newsfeed from './views/newsfeed';
import people from './views/people';
import profile from './views/profile';
import rewards from './views/rewards';

const { PLATFORMS } = ArcPropTypes;


const benjo = {
  ...app[PLATFORMS.BENJO],
  ...academy[PLATFORMS.BENJO],
  ...chat[PLATFORMS.BENJO],
  ...games[PLATFORMS.BENJO],
  ...home[PLATFORMS.BENJO],
  ...hub[PLATFORMS.BENJO],
  ...huddle[PLATFORMS.BENJO],
  ...insights[PLATFORMS.BENJO],
  ...league[PLATFORMS.BENJO],
  ...manage[PLATFORMS.BENJO],
  ...metrics[PLATFORMS.BENJO],
  ...rewards[PLATFORMS.BENJO],
  ...people[PLATFORMS.BENJO],
  ...profile[PLATFORMS.BENJO],
};

const development = {
  ...app[PLATFORMS.DEVELOPMENT],
  ...chat[PLATFORMS.DEVELOPMENT],
  ...academy[PLATFORMS.DEVELOPMENT],
  ...games[PLATFORMS.DEVELOPMENT],
  ...home[PLATFORMS.DEVELOPMENT],
  ...hub[PLATFORMS.DEVELOPMENT],
  ...huddle[PLATFORMS.DEVELOPMENT],
  ...insights[PLATFORMS.DEVELOPMENT],
  ...league[PLATFORMS.DEVELOPMENT],
  ...newsfeed[PLATFORMS.DEVELOPMENT],
  ...manage[PLATFORMS.DEVELOPMENT],
  ...metrics[PLATFORMS.DEVELOPMENT],
  ...rewards[PLATFORMS.DEVELOPMENT],
  ...people[PLATFORMS.DEVELOPMENT],
  ...profile[PLATFORMS.DEVELOPMENT],
};

const launch = {
  ...app[PLATFORMS.LAUNCH],
  ...chat[PLATFORMS.LAUNCH],
  ...academy[PLATFORMS.LAUNCH],
  ...games[PLATFORMS.LAUNCH],
  ...home[PLATFORMS.LAUNCH],
  ...hub[PLATFORMS.LAUNCH],
  ...huddle[PLATFORMS.LAUNCH],
  ...insights[PLATFORMS.LAUNCH],
  ...league[PLATFORMS.LAUNCH],
  ...newsfeed[PLATFORMS.LAUNCH],
  ...manage[PLATFORMS.LAUNCH],
  ...metrics[PLATFORMS.LAUNCH],
  ...rewards[PLATFORMS.LAUNCH],
  ...people[PLATFORMS.LAUNCH],
  ...profile[PLATFORMS.LAUNCH],
};

export const routes = {
  [PLATFORMS.BENJO]: benjo,
  [PLATFORMS.DEVELOPMENT]: development,
  [PLATFORMS.LAUNCH]: launch,
};


const RoutesSecured = createRoutes(routes);

export default RoutesSecured;
