import React from 'react';
import PropTypes from 'prop-types';

import * as GAME_FORMATS from 'arcade-frontend-core/src/types/game-formats';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';

function GamesGameCardTitle({ unit, goal, metric, rewards, type }) {
  const displayableUnit = unit === '$' ? unit : null;
  const rewardsCount = (rewards && rewards.length) || '';
  const placementText =
    rewardsCount === 1
      ? 'Be the first person'
      : `Be one of the first ${rewardsCount} people`;

  const getRewardsString = () => {
    if (!rewards[0]) {
      return 'Win Rewards';
    }

    switch (rewards[0].type) {
      case 'tokens':
        return 'Win Tokens';
      case 'chests':
        return 'Win Chests';
      default:
        return 'Win Rewards';
    }
  };

  const rewardsString = getRewardsString();

  switch (type) {
    case GAME_FORMATS.BOUNTY:
      return (
        <ArcText>
          Achieve{' '}
          <ArcText isStrong>
            {goal} {metric}
          </ArcText>
        </ArcText>
      );
    case GAME_FORMATS.RACE:
      return (
        <ArcText>
          {placementText} to hit {goal} <ArcText isStrong>{metric}</ArcText>
        </ArcText>
      );
    case GAME_FORMATS.TOURNAMENT:
      return (
        <ArcText>
          Top {rewardsCount} for most <ArcText isStrong>{metric}</ArcText>
        </ArcText>
      );
    case GAME_FORMATS.RPA_ONE_TIME:
      return (
        <ArcText>
          Unlock with {displayableUnit}
          {goal} <ArcText isStrong>{metric}</ArcText>
        </ArcText>
      );
    case GAME_FORMATS.RPA_EVERY_TIME:
      return (
        <ArcText>
          {rewardsString} for every {displayableUnit}
          {goal} <ArcText isStrong>{metric}</ArcText>
        </ArcText>
      );
    default:
      return null;
  }
}

GamesGameCardTitle.displayName = 'GamesGameCardTitle';

GamesGameCardTitle.propTypes = {
  unit: PropTypes.string,
  metric: PropTypes.string,
  goal: PropTypes.number,
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ),
  type: PropTypes.oneOf(GAME_FORMATS.GAME_TYPES),
};

GamesGameCardTitle.defaultProps = {
  unit: '',
  metric: '',
  goal: null,
  rewards: [],
  type: null,
};

export default GamesGameCardTitle;
