import React from 'react';

import {
  ArcView,
  ArcButton,
  ArcText,
  ArcTextField,
  createWithStyles,
  ArcPropTypes,
} from 'arcade-frontend-ui';

import ManageRewardsListItem from './ManageRewardsListItem';

const styles = {
  FilterField: theme => ({
    root: {
      marginRight: theme.spacing(1),
    },
  }),

  ManageRewardsListContainer: theme => ({
    root: {
      [theme.breakpoints.up('sm')]: {
        width: 480,
        margin: '0 auto',
      },
    },
  }),

  RewardsColumnHeadingRow: theme => ({
    root: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  }),

  RewardsSectionHeading: theme => ({
    root: {
      margin: theme.spacing(2),
      marginTop: theme.spacing(6),
      fontSize: '1.2em',
    },
  }),
};

const FilterField = createWithStyles(styles.FilterField)(ArcTextField);
const ManageRewardsListContainer = createWithStyles(styles.ManageRewardsListContainer)(ArcView);
const RewardsColumnHeadingRow = createWithStyles(styles.RewardsColumnHeadingRow)(ArcView);
const RewardsSectionHeading = createWithStyles(styles.RewardsSectionHeading)(ArcView);

const stylePlaceholder = {
  textAlign: 'center',
  marginTop: 16,
  padding: 16,
};

const REWARD_TYPES = {
  id: ArcPropTypes.string,
  name: ArcPropTypes.string,
  imageUrl: ArcPropTypes.string,
  variants: ArcPropTypes.arrayOf(ArcPropTypes.shape({
    label: ArcPropTypes.oneOfType([
      ArcPropTypes.string,
      ArcPropTypes.number,
    ]),
    value: ArcPropTypes.number,
  })),
  tokens: ArcPropTypes.number,
  description: ArcPropTypes.string,
  isRequesting: ArcPropTypes.shape({ id: ArcPropTypes.string }),
};

const isRTPOS = process.env.ARCADE_WHITELABEL_ID === 'rtpos';

class ManageRewardsList extends React.Component {
  static propTypes = {
    arcade: ArcPropTypes.arrayOf(ArcPropTypes.shape(REWARD_TYPES)),
    custom: ArcPropTypes.arrayOf(ArcPropTypes.shape(REWARD_TYPES)),
    onClickAdd: ArcPropTypes.func,
    onArchive: ArcPropTypes.func,
    onRestore: ArcPropTypes.func,
    onClickEdit: ArcPropTypes.func,
    onClickShow: ArcPropTypes.func,
    placeholderText: ArcPropTypes.string,
    isRequesting: ArcPropTypes.shape({}),
  };

  static defaultProps = {
    arcade: [],
    custom: [],
    onClickAdd: ArcPropTypes.noop,
    onArchive: ArcPropTypes.noop,
    onRestore: ArcPropTypes.noop,
    onClickEdit: ArcPropTypes.noop,
    onClickShow: ArcPropTypes.noop,
    placeholderText: '',
    isRequesting: {},
  };

  state = {
    arcade: this.props.arcade,
    custom: this.props.custom,
    searchTerm: '',
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.arcade !== nextProps.arcade) {
      this.setState({ arcade: nextProps.arcade });
    }

    if (this.props.custom !== nextProps.custom) {
      this.setState({ custom: nextProps.custom });
    }
  }

  get hasArcade() {
    return !!this.props.arcade.length;
  }

  get hasCustom() {
    return !!this.props.custom.length;
  }

  filterRewardsByName = (arcade, searchTerm) => {
    const searchTermLowerCase = searchTerm.toLowerCase();

    return arcade.filter(reward => (reward.name.toLowerCase().indexOf(searchTermLowerCase) > -1));
  };

  handleSearchTermChange = (event) => {
    const searchTerm = event.target.value;
    const arcade = this.filterRewardsByName(this.props.arcade, searchTerm);
    const custom = this.filterRewardsByName(this.props.custom, searchTerm);

    this.setState({ arcade, custom, searchTerm });
  };

  renderCustomRewards = () => this.state.custom.map(reward => (
    <ManageRewardsListItem
      key={reward.id}
      {...reward}
      canEdit
      onClick={this.props.onClickEdit}
      onArchive={this.props.onArchive}
      onRestore={this.props.onRestore}
      isRequesting={!!this.props.isRequesting[reward.id]}
    />
  ));

  renderArcadeRewards = () => this.state.arcade.map(reward => (
    <ManageRewardsListItem
      key={reward.id}
      {...reward}
      onClick={this.props.onClickShow}
      onArchive={this.props.onArchive}
      onRestore={this.props.onRestore}
      isRequesting={!!this.props.isRequesting[reward.id]}
    />
  ));

  renderCustom() {
    return (
      <ArcView>
        <RewardsSectionHeading align="center" justify="space-between" row>
          <ArcView>
            Custom Rewards
          </ArcView>
        </RewardsSectionHeading>
        {this.renderCustomRewards()}
      </ArcView>
    );
  }

  renderArcade() {
    return (
      <ArcView>
        <RewardsSectionHeading>
          { isRTPOS ? 'RTMe Rewards' : 'Arcade Rewards'}
        </RewardsSectionHeading>
        {this.renderArcadeRewards()}
      </ArcView>
    );
  }

  renderPlaceholder() {
    if (!this.props.placeholderText) {
      return null;
    }

    return (
      <ArcView className="animated fadeIn" style={stylePlaceholder}>
        <ArcText
          color="action"
          variant="title"
          weight="300"
        >
          {this.props.placeholderText}
        </ArcText>
      </ArcView>
    );
  }

  render() {
    return (
      <ManageRewardsListContainer>
        <ArcView justify="space-between" align="stretch">
          <RewardsColumnHeadingRow justify="space-between" row>
            <ArcView row>
              <FilterField
                placeholder="Search Rewards"
                value={this.state.searchTerm}
                onChange={this.handleSearchTermChange}
                variant="outlined"
              />
            </ArcView>

            <ArcButton
              color="primary"
              variant="contained"
              onClick={this.props.onClickAdd}
            >
              Add Reward
            </ArcButton>

          </RewardsColumnHeadingRow>
        </ArcView>

        {this.hasCustom && this.renderCustom()}
        {this.hasArcade && this.renderArcade()}
        {!this.hasCustom && !this.hasArcade && this.renderPlaceholder()}
      </ManageRewardsListContainer>
    );
  }
}

export default ManageRewardsList;
