const normalizeSale = sale => ({
  id: sale.id.toString(),
  userId: sale.user_id && sale.user_id.toString(),
  userName: sale.user_name,
  userImage: sale.user_image,
  teamId: sale.team_id && sale.team_id.toString(),
  teamName: sale.team_name,
  metricId: sale.metric_id && sale.metric_id.toString(),
  metricName: sale.metric_name,
  quantity: sale.quantity,
  createdAt: sale.created_at,
});

const fromSalesIndex = payload => ({
  sales: payload.sales.map(s => normalizeSale(s)),
  totalSales: payload.total_sales,
});

const fromSaleMetricsIndex = metric => ({
  id: metric.id.toString(),
  name: metric.name,
  unit: metric.unit,
});

const fromSaleTeamsIndex = team => ({
  id: team.id.toString(),
  name: team.name,
});

const fromSaleUsersIndex = user => ({
  id: user.id.toString(),
  name: user.name,
  imageUrl: user.profile_image,
  teamId: user.team_id ? user.team_id.toString() : null,
  metrics: user.metrics.map(m => m.id.toString()),
});

const toSaleCreate = sale => ({
  user_id: sale.userId,
  team_id: sale.teamId,
  metric_id: sale.metricId,
  quantity: sale.quantity,
  created_at: sale.createdAt,
});

const toSaleEdit = toSaleCreate;

const normalizers = {
  fromSalesIndex,
  fromSaleMetricsIndex,
  fromSaleTeamsIndex,
  fromSaleUsersIndex,
  toSaleCreate,
  toSaleEdit,

  normalizeSale,
};

export default normalizers;
