import React from 'react';

import { Path, G } from 'react-primitives-svg';
import Icon from '../Icon';

const HashTag = props => (
  <Icon {...props}>
   <Path
     fill="currentColor"
     d="M17.182 4.91h-2.486l.82-3.062a.818.818 0 10-1.58-.423l-.933 3.484H7.192l.82-3.06a.818.818 0 10-1.58-.424l-.934 3.484H2.455a.818.818 0 100 1.637h2.604l-1.317 4.909H.818a.818.818 0 100 1.636h2.486l-.82 3.06a.818.818 0 001.578.425l.935-3.485h5.811l-.82 3.06a.818.818 0 001.58.425l.934-3.485h3.043a.818.818 0 100-1.636h-2.604l1.317-4.91h2.924a.818.818 0 100-1.636zm-5.935 6.545H5.436l1.316-4.91h5.811l-1.316 4.91z"
   />
  </Icon>
);

export default HashTag;
