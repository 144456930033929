import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { getAppPeopleById } from 'arcade-frontend-core/src/reducers/peopleById';

import {
  ArcButton,
  ArcFormConfirmation,
  ArcPeopleList,
  ArcStepperNew as ArcStepper,
  ArcView,
  forms,
  Typography,
} from 'arcade-frontend-ui';

import FormPeopleSelector, {
  getForm as getFormPeopleSelector,
  selectorTypeItems,
} from '../peopleSelector';

import { routes } from '../../actions/manage/checklists';

const model = 'checklists.create';

const initialState = {
  id: null,
  name: '',
  peopleIds: [],
};

export const reducer = forms.createFormReducer(model, initialState);
export const getForm = state => state.newForms.checklists.create;

const selector = getForm;

const fields = {
  name: {
    type: 'text',
    label: 'Checklist Name',
    validations: {
      isRequired: true,
      maxLength: 30,
    },
  },

  task0: {
    type: 'text',
    label: 'Task One',
    validations: {
      isRequired: true,
      maxLength: 100,
    },
  },

  task1: {
    type: 'text',
    label: 'Task Two',
    validations: {
      maxLength: 100,
    },
  },

  task2: {
    type: 'text',
    label: 'Task Three',
    validations: {
      maxLength: 100,
    },
  },

  task3: {
    type: 'text',
    label: 'Task Four',
    validations: {
      maxLength: 100,
    },
  },

  task4: {
    type: 'text',
    label: 'Task Five',
    validations: {
      maxLength: 100,
    },
  },

  dueDate: {
    type: 'date',
    label: 'Date',
    placeholder: 'Date',
    validations: {
      isRequired: true,
    },
  },

  dueTime: {
    type: 'time',
    label: 'Time',
    placeholder: 'Time',
    validations: {
      isRequired: true,
    },
  },
};

const onSubmit = (e, values, dispatch) => {
  dispatch(routes.routeManageChecklists());
};

const formProps = {
  fields,
  selector,
  onSubmit,
};

const styleContent = {
  maxWidth: 600,
};

const styleRelative = {
  position: 'relative',
};

const styleTitle = {
  fontWeight: '300',
  marginBottom: 24,
};

const styleSpacer = {
  marginBottom: 48,
};

class FormChecklistCreate extends React.PureComponent {
  static propTypes = forms.propTypes;

  state = {
    numOfTasks: 1,
  };

  componentWillUnmount() {
    this.props.onReset({ values: initialState });
  }

  getFieldsByStep() {
    return {
      0: this.getStepOneFields(),
    };
  }

  getStepOneFields(except = []) { // eslint-disable-line
    const stepOneFields = ([
      { key: 'name' },
    ]);

    for (let idx = 0; idx < this.state.numOfTasks; idx += 1) {
      stepOneFields.push({
        key: `task${idx}`,
      });
    }

    if (except.length) {
      return stepOneFields.filter(field => except.indexOf(field.key) === -1);
    }

    return stepOneFields;
  }

  getStepOne() {
    const isValid = this.getStepIsValid(this.getStepOneFields());

    return {
      label: 'Details',
      children: (
        <div>
          {this.renderStepFields(this.getStepOneFields())}
          {this.state.numOfTasks < 5 && (
            <ArcButton
              color="primary"
              variant="contained"
              onClick={this.handleClickAddTask}
            >
              Add task
            </ArcButton>
          )}
        </div>
      ),
      isValid,
    };
  }

  getStepTwo() {
    const { peopleSelectorValues } = this.props;
    const { peopleIds } = peopleSelectorValues;

    return {
      label: 'People',
      children: (
        <ArcView>
          <ArcView style={styleRelative}>
            <FormPeopleSelector
              minSelect={1}
              peopleById={this.props.peopleById}
              selectablePeople={Object.keys(this.props.peopleById)}
            />
          </ArcView>
        </ArcView>
      ),
      isValid: peopleIds.length > 0,
    };
  }

  getStepIsValid(stepFields) {
    const { length } = stepFields;
    let errors;
    let fieldKey;
    let touched;

    for (let index = 0; index < length; index += 1) {
      fieldKey = stepFields[index].key;
      errors = this.props.errors[fieldKey];
      touched = this.props.touched[fieldKey];

      if (errors.length && touched) {
        return false;
      }
    }

    return true;
  }

  setIsViewingDetails = isViewingDetails => this.setState({ isViewingDetails });

  setStepTouched(stepIndex) {
    const fieldsByStep = this.getFieldsByStep();

    if (fieldsByStep[stepIndex]) {
      fieldsByStep[stepIndex].forEach(field => this.props.setFieldTouched(field.key, true));
    }
  }


  handleBack = (toStep, prevStep) => this.setStepTouched(prevStep);

  handleClickAddTask = () => {
    this.setState(prevState => ({ numOfTasks: prevState.numOfTasks + 1 }));
  };

  handleNext = (toStep, prevStep) => this.setStepTouched(prevStep);

  handleSubmit = (e) => {
    const { peopleIds, teamIds, selectorType } = this.props.peopleSelectorValues;
    const { isCreating } = this.props;

    const sharedValues = {
      peopleIds: selectorType === selectorTypeItems.all.value ? this.props.eligiblePeople : peopleIds,
      teamIds: selectorType === selectorTypeItems.all_teams.value ? this.props.eligibleTeams : teamIds,
      assign: selectorType,
      isCreating,
    };

    this.props.onSubmit(e, sharedValues);
  };

  renderConfirmation = () => {
    const fieldsByStep = this.getFieldsByStep();
    const isValid = true;
    return {
      children: (
        <ArcView style={styleContent}>
          <ArcView style={styleSpacer}>
            <Typography
              style={styleTitle}
              variant="headline"
            >
            Due When?
            </Typography>
            <ArcView row>
              {this.props.renderFields.dueDate()}
              {this.props.renderFields.dueTime()}
            </ArcView>
          </ArcView>
          <ArcView style={styleSpacer}>
            <Typography
              style={styleTitle}
              variant="headline"
            >
            Details
            </Typography>
            <ArcFormConfirmation
              fields={fields}
              fieldsToRender={fieldsByStep[0]}
              values={this.props.values}
            />
          </ArcView>

          <ArcView style={styleSpacer}>
            <Typography
              style={styleTitle}
              variant="headline"
            >
            Participants
            </Typography>
            {this.renderConfirmationPeople()}
          </ArcView>
        </ArcView>
      ),
      isValid,
    };
  };

  renderConfirmationPeople = () => {
    const teams = this.props.peopleSelectorValues.teamIds.map(id => this.props.teamsById[id]);
    const people = this.props.peopleSelectorValues.peopleIds.map(id => this.props.peopleById[id]);

    const props = {
      people,
      teams,
      showSelectAll: false,
    };

    switch (this.props.peopleSelectorValues.selectorType) {
      case selectorTypeItems.all.value:
        return <Typography variant="body1">Assigned to everyone</Typography>;
      case selectorTypeItems.all_teams.value:
        return <Typography variant="body1">Assigned to all teams</Typography>;
      case selectorTypeItems.teams.value:
        return (
          <ArcPeopleList
            type="teams"
            {...props}
          />
        );
      case selectorTypeItems.users.value:
      default:
        return (
          <ArcPeopleList
            type="people"
            {...props}
          />
        );
    }
  };

  renderStepField = ({ key, isDisabled, ...props }) => (isDisabled ? null : (
    <ArcView key={key} style={styleContent}>
      {this.props.renderFields[key](props)}
    </ArcView>
  ));

  renderStepFields(stepFields) {
    return stepFields.map(this.renderStepField);
  }

  renderSteps = () => [
    this.getStepOne(),
    this.getStepTwo(),
  ];

  render() {
    return (
      <ArcStepper
        onBack={this.handleBack}
        onCancel={this.props.onCancel}
        onFinish={this.handleSubmit}
        onNext={this.handleNext}
        renderConfirmation={this.renderConfirmation}
        renderSteps={this.renderSteps}
      />
    );
  }
}

const getState = (state, props) => ({
  peopleSelectorValues: getFormPeopleSelector(state).values,
  peopleById: getAppPeopleById(state),
  ...props,
});

const getActions = dispatch => bindActionCreators({
  changeFormValue: forms.actions.change,
}, dispatch);

export default compose(
  connect(getState, getActions),
  forms.createForm(model, formProps),
)(FormChecklistCreate);
