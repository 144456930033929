import React from 'react';
import ArcPropTypes from '../../helpers/arc/propTypes';

const Cloud = ({ color, ...props }) => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path
      d="M9 2h1v1h1v1h1v2h-1v1H1V6H0V4h1V3h1V1h1V0h2v1h1v1h1V1h2v1z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

Cloud.propTypes = {
  color: ArcPropTypes.string,
};

Cloud.defaultProps = {
  color: '#fff',
};

export default Cloud;
