import React from 'react';

import { createWithStyles } from '../../styles';

import ArcView from '../../primitives/ArcView';
import ArcPropTypes from '../../helpers/arc/propTypes';

const styles = {
  Shimmer: () => ({
    root: {
      color: 'transparent',
    },
  }),
};

const Shimmer = createWithStyles(styles.Shimmer)(ArcView);

const ArcPlaceholder = ({ type, ...props }) => {
  switch (type) {
    case 'image':
    case 'text':
    default:
      return (
        <Shimmer className="shimmer" {...props}>
          {props.children}
        </Shimmer>
      );
  }
};

ArcPlaceholder.propTypes = {
  type: ArcPropTypes.string,
  children: ArcPropTypes.children,
};

ArcPlaceholder.defaultProps = {
  type: undefined,
  children: null,
};

export default ArcPlaceholder;
