import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'arcade-frontend-features/src/actions/manage/tokens';
import { routeManageTokens } from 'arcade-frontend-features/src/actions/manage/tokens/routes';
import {
  getCurrentUser,
  getCurrentUserPermission,
} from 'arcade-frontend-core/src/reducers/user';
import { REQUEST_TOPUP } from 'arcade-frontend-core/src/types/permissions';
import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import RollbarClient from 'arcade-frontend-ui/src/providers/RollbarProvider/client';

import { resources } from '../../resources/accounts';

import TokenBudgetFundsTile from '../../components/TokenBudgetFundsTile';

const TokenBudgetFundsTileContainer = props => {
  const dispatch = useDispatch();
  const apiGetAccounts = useResourceAction(resources.apiGetAccounts);
  const apiPostAccountTopUp = useResourceAction(resources.apiPostAccountTopUp);

  const canRequestTokenTopup = useSelector(state =>
    getCurrentUserPermission(state, REQUEST_TOPUP),
  );

  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    apiGetAccounts.requestData().catch(global.noop);
  }, []);

  const handleRouteViewTokenHistory = () => {
    dispatch(routeManageTokens());
  };

  const handleSubmitRequestTopup = payload => {
    apiPostAccountTopUp
      .requestData({
        resources: [
          {
            top_up: {
              amount: payload.amount,
            },
          },
        ],
      })
      .then(resp => {
        console.log(resp);
        const data = resp.data.resources[0];

        setTimeout(() => {
          dispatch(actions.manageTokensSetHasToppedUp(true));
          dispatch(actions.manageTokensSetTopUpUrl(data.redirectLink));
          dispatch(actions.manageTokensSetTopUpResponse(data.message));

          apiPostAccountTopUp.onStatusReset();
        }, 1000);
      })
      .catch(error => {
        console.error(error);

        RollbarClient.critical('TOKEN TOP UP FAILED', {
          error,
          currentUser,
        });

        setTimeout(() => {
          dispatch(actions.manageTokensSetHasToppedUp(true));
          dispatch(actions.manageTokensSetTopUpError(error));
          apiPostAccountTopUp.onStatusReset();
        }, 1000);
      });
  };

  return (
    <TokenBudgetFundsTile
      data={apiGetAccounts.hasData ? apiGetAccounts.data.resources : undefined}
      onViewTokenHistory={handleRouteViewTokenHistory}
      getAccountRequestStatus={apiGetAccounts.status}
      postAccountTopUpStatus={apiPostAccountTopUp.status}
      onSubmitRequestTopUp={handleSubmitRequestTopup}
      canRequestTokenTopup={canRequestTokenTopup}
      {...props}
    />
  );
};

export default TokenBudgetFundsTileContainer;
