import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';

import ManageTokenUserBudgetPermissionDialog from '../../components/ManageTokenUserBudgetPermissionDialog';
import { resources } from '../../resources/users';

const ManageTokenUserBudgetPermissionContainer = ({
  open,
  onClose,
  searchString,
}) => {
  const apiGetEligibleTokenBudgetUser = useResourceAction(
    resources.apiGetEligibleTokenBudgetUser,
  );

  useEffect(() => {
    apiGetEligibleTokenBudgetUser
      .requestData()
      .then(apiGetEligibleTokenBudgetUser.onStatusReset)
      .catch(apiGetEligibleTokenBudgetUser.onStatusReset);
  }, []);

  if (!apiGetEligibleTokenBudgetUser.hasData) {
    return null;
  }

  return (
    <ManageTokenUserBudgetPermissionDialog
      open={open}
      data={apiGetEligibleTokenBudgetUser.data.resources}
      onClose={onClose}
      searchString={searchString}
    />
  );
};

ManageTokenUserBudgetPermissionContainer.displayName =
  'ManageTokenUserBudgetPermissionContainer';

ManageTokenUserBudgetPermissionContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  searchString: PropTypes.string,
};

ManageTokenUserBudgetPermissionContainer.defaultProps = {
  open: false,
  onClose: global.noop,
  searchString: '',
};

export default ManageTokenUserBudgetPermissionContainer;
