import React from 'react';

import {
  ArcPropTypes,
} from 'arcade-frontend-ui';

import MetricCard from '../../metrics/MetricCard';

class ManageMetricCard extends React.PureComponent {
  static propTypes = {
    archived: ArcPropTypes.bool,
    hasEdit: ArcPropTypes.bool,
  };

  static defaultProps = {
    archived: false,
    hasEdit: true,
  };

  render() {
    const {
      archived,
      hasEdit,
      ...rest
    } = this.props;

    return (
      <MetricCard
        hasArchive={!archived}
        hasRestore={archived}
        hasEdit={hasEdit}
        hasVerify
        {...rest}
      />
    );
  }
}

export default ManageMetricCard;
