import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Close from '@material-ui/icons/Close';

import {
  ArcButton,
  ArcButtonGroup,
  ArcFormField,
  ArcIconButton,
  ArcImage,
  ArcGrid,
  ArcParagraph,
  ArcScreenBlocker,
  ArcText,
  ArcView,
  date,
} from 'arcade-frontend-ui';

import * as REWARD_TYPES from 'arcade-frontend-core/src/types/rewards';
import * as FORMAT_TYPES from 'arcade-frontend-core/src/types/game-formats';

import { getManageGamesEligiblePeople } from '../../../reducers/manage/games/eligiblePeople';
import { getManageGamesEligibleTeams } from '../../../reducers/manage/games/eligibleTeams';

import chestSvg from './chest.svg';

const REWARD_MESSAGE_BY_TYPE = {
  [REWARD_TYPES.CHESTS]:
    'Chest rewards may award tokens which will be deducted from the company rewards balance.',
  [REWARD_TYPES.TOKENS]:
    'Token rewards will come from your monthly token budget and may incur additional charges.',
  [REWARD_TYPES.CUSTOM]:
    'Custom rewards are rewards you will distribute yourself.',
};

const REWARD_MESSAGE_BY_RPA_TYPE = {
  [FORMAT_TYPES.RPA_ONE_TIME]:
    'Each player who achieves the target will be eligible for this reward. You will be required to verify their progress before they receive the reward but the reward will be delivered instantly after verification',
  [FORMAT_TYPES.RPA_EVERY_TIME]:
    'Every time a player achieves the target they will be eligible for this reward. You will be required to verify their progress before they receive the reward but the reward will be delivered instantly after verification',
};

const CustomIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="491.634"
    height="491.634"
    viewBox="0 0 491.634 491.634"
    {...props}
  >
    <path
      fill="currentColor"
      d="M0 153.012v185.61h491.634v-185.61H0zm467.418 161.399H24.211V177.223h443.213v137.188h-.006zM87.997 292.949H50.78v-9.623h12.341v-75.01H50.78v-9.625h37.217v9.625H75.658v75.017h12.338v9.616z"
    />
  </svg>
);

const styleTitle = {
  marginBottom: 32,
};

const styleImage = {
  marginBottom: 8,
};

const styleMutedImage = {
  ...styleImage,
  opacity: 0.5,
};

const styleButton = {
  width: 116,
  height: 116,
  marginRight: 16,
  marginBottom: 32,
};

const FIELDS = ['rewardType', 'rewards'];

class GamesCreateStepThreeForm extends React.PureComponent {
  static propTypes = {
    eligiblePeople: PropTypes.arrayOf(PropTypes.string),
    eligibleTeams: PropTypes.arrayOf(PropTypes.string),
    hasEscrowFeature: PropTypes.bool,
    escrowAvailable: PropTypes.number,
    currentEscrowValue: PropTypes.number,
    errors: PropTypes.objectOf(PropTypes.string),
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    touched: PropTypes.shape({
      rewards: PropTypes.bool,
    }),
    values: PropTypes.shape({
      limitWinners: PropTypes.string,
      assignType: PropTypes.oneOf(['users', 'teams']),
      format: PropTypes.oneOf(['threshold', 'first', 'ranking']),
      metric: PropTypes.string,
      peopleSelector: PropTypes.shape({
        peopleIds: PropTypes.arrayOf(PropTypes.string),
        teamIds: PropTypes.arrayOf(PropTypes.string),
      }),
      rewards: PropTypes.objectOf(
        PropTypes.shape({
          type: PropTypes.oneOf(['chests', 'tokens', 'custom']),
          value: PropTypes.oneOfType([
            PropTypes.shape({
              Common: PropTypes.number,
              Rare: PropTypes.number,
              Epic: PropTypes.number,
              Legendary: PropTypes.number,
            }),
            PropTypes.number,
            PropTypes.string,
          ]),
        }),
      ),
      rewardType: PropTypes.oneOf(Object.values(REWARD_TYPES)),
      selectType: PropTypes.oneOf(['all', 'selected']),
    }),
    currentUserCreated: PropTypes.bool,
    isEditing: PropTypes.bool,
  };

  static defaultProps = {
    eligiblePeople: [],
    eligibleTeams: [],
    escrowAvailable: 0,
    currentEscrowValue: 0,
    hasEscrowFeature: false,
    errors: {},
    touched: {},
    values: {},
    currentUserCreated: false,
    isEditing: false,
  };

  static FIELDS = FIELDS;

  static REWARD_TYPES = REWARD_TYPES;

  rewardValuesByType = {};

  rewardTypeClickHandlers = {};

  constructor(props) {
    super(props);

    const hasChestByRank = {
      0: true,
    };

    const { rewardType, rewards } = this.props.values;

    if (rewardType === 'chests') {
      Object.values(rewards).forEach(reward => {
        hasChestByRank[reward.receivingRank] = true;
      });
    }

    this.state = {
      hasChestByRank,
    };
  }

  componentWillUnmount() {
    FIELDS.forEach(field => {
      this.props.setFieldTouched(field, true, false);
    });
  }

  get hasError() {
    const { rewards, rewardType } = this.props.values;
    const missingFirstReward = rewards && rewards[0] ? !rewards[0].value : true;

    let chestError;
    if (rewardType === 'chests') {
      chestError =
        rewards && rewards[0] && rewards[0].value
          ? Object.values(rewards[0].value).filter(qty => qty > 0).length === 0
          : true;
    }

    let tokenError;
    if (rewardType === REWARD_TYPES.TOKENS && this.props.hasEscrowFeature) {
      let totalRewardTokens = 0;
      Object.values(rewards).forEach(reward => {
        totalRewardTokens += parseInt(reward.value, 10);
      });
      const requiredEscrowValue =
        totalRewardTokens - this.props.currentEscrowValue;
      tokenError = this.props.escrowAvailable < requiredEscrowValue;
    }

    return missingFirstReward || chestError || tokenError;
  }

  get hasParticipants() {
    const { values } = this.props;

    if (!values) {
      return false;
    }

    if (typeof values.metric === 'undefined' || values.metric === '') {
      return false;
    }

    if (values.selectType === 'all') {
      return true;
    }

    const { assignType, peopleSelector } = values;

    return assignType === 'users'
      ? !!peopleSelector.peopleIds.length
      : !!peopleSelector.teamIds.length;
  }

  getRewardFields = () => {
    const defaultRewards = [0, 1, 2, 3, 4];
    const { format } = this.props.values;
    if (format === FORMAT_TYPES.BOUNTY || FORMAT_TYPES.isRPAType(format)) {
      return [0];
    }

    if (!this.props.values) {
      return defaultRewards;
    }

    const { assignType, selectType, peopleSelector } = this.props.values;

    const peopleSelectorPeopleIds = peopleSelector
      ? peopleSelector.peopleIds
      : [];
    const peopleSelectorTeamIds = peopleSelector ? peopleSelector.teamIds : [];

    const peopleIds =
      selectType === 'all'
        ? this.props.eligiblePeople
        : peopleSelectorPeopleIds;
    const teamIds =
      selectType === 'all' ? this.props.eligibleTeams : peopleSelectorTeamIds;

    const maxWithPeople = peopleIds.map((_, idx) => idx);

    const maxWithTeams = teamIds.map((_, idx) => idx);

    switch (assignType) {
      case 'users':
        return maxWithPeople;
      case 'teams':
        return maxWithTeams;
      default:
        return defaultRewards;
    }
  };

  setHasChestByRank = hasChestByRank => this.setState({ hasChestByRank });

  createHandleClickRewardType = rewardType => {
    if (!this.rewardTypeClickHandlers[rewardType]) {
      this.rewardTypeClickHandlers[rewardType] = () => {
        this.rewardValuesByType[
          this.props.values.rewardType
        ] = this.props.values.rewards;

        this.props.setFieldValue(
          'rewards',
          this.rewardValuesByType[rewardType] || {},
        );
        this.props.setFieldValue('rewardType', rewardType);
      };
    }

    return this.rewardTypeClickHandlers[rewardType];
  };

  renderRewardField = (id, index) => {
    const { hasChestByRank } = this.state;
    const { rewardType, rewards } = this.props.values;

    const isRewardChests = rewardType === REWARD_TYPES.CHESTS;
    const isRewardCustom = rewardType === REWARD_TYPES.CUSTOM;
    const isRewardTokens = rewardType === REWARD_TYPES.TOKENS;

    const isLast = index === this.rewardFields.length - 1;

    const ordinalIndex = date.getOrdinal(index + 1);
    let addChestBlock = null;

    const hasError = index === 0 && this.hasError && this.props.touched.rewards;

    if (isRewardChests) {
      if (!isLast && !hasChestByRank[index + 1]) {
        const nextOrdinalIndex = date.getOrdinal(index + 2);

        const handleAddChest = () => {
          this.setHasChestByRank({
            ...hasChestByRank,
            [index + 1]: true,
          });

          this.props.setFieldValue('rewards', {
            ...rewards,
            [index + 1]: {
              receivingRank: index + 1,
              rewardType: 'chests',
              value: {},
            },
          });
        };

        addChestBlock = (
          <ArcGrid item xs={12}>
            <ArcButton
              color="blue"
              variant="outlined"
              size="small"
              onClick={handleAddChest}
            >
              <Add fontSize="small" color="inherit" />
              <ArcView marginLeft="4">{`${nextOrdinalIndex} reward`}</ArcView>
            </ArcButton>
          </ArcGrid>
        );
      }

      if (index > 0 && !hasChestByRank[index]) {
        return null;
      }

      const handleRemoveChest = () => {
        this.setHasChestByRank({
          ...hasChestByRank,
          [index]: false,
        });

        const nextRewards = { ...rewards };

        delete nextRewards[index];

        this.props.setFieldValue('rewards', nextRewards);
      };

      return (
        <React.Fragment key={id}>
          <ArcGrid item xs={12}>
            <ArcView align="flex-start">
              <ArcView>
                <ArcView
                  row
                  align="center"
                  marginBottom="16"
                  borderBottom="default"
                  borderBottomWidth="1"
                >
                  <ArcView paddingTop="16" paddingBottom="16">
                    <ArcText color={hasError ? 'danger' : undefined}>
                      {hasError
                        ? `${ordinalIndex} reward is required`
                        : `${ordinalIndex} reward`}
                    </ArcText>
                  </ArcView>
                  <ArcView spacer />

                  {index > 0 &&
                    hasChestByRank[index - 1] &&
                    !hasChestByRank[index + 1] && (
                      <ArcIconButton onClick={handleRemoveChest}>
                        <Close color="disabled" fontSize="small" />
                      </ArcIconButton>
                    )}
                </ArcView>
                <ArcFormField
                  autoComplete="off"
                  id={`chest-select-${id}`}
                  type="chest-select"
                  name="rewards"
                  fullWidth
                  hasLabel={false}
                  label={'Chest'}
                  onChange={chests => {
                    this.props.setFieldValue('rewards', {
                      ...rewards,
                      [index]: {
                        receivingRank: index,
                        rewardType: 'chests',
                        value: chests,
                      },
                    });
                  }}
                  error={this.props.errors.rewards}
                  value={
                    rewards[index] && rewards[index].value
                      ? rewards[index].value
                      : undefined
                  }
                  isTouched={this.props.touched.rewards}
                  min={0}
                  max={99}
                />
              </ArcView>
            </ArcView>
          </ArcGrid>

          {addChestBlock}
        </React.Fragment>
      );
    }

    const tokenIcon = isRewardTokens ? (
      <ArcImage
        width="16px"
        height="16px"
        src="https://d17rkelr6jjme9.cloudfront.net/prize-images/ArcadeCoin.svg"
      />
    ) : (
      <CustomIcon width="16" height="16" />
    );

    const rewardTextFieldPlaceholder = isRewardTokens
      ? 'Number of tokens'
      : 'Reward';

    return (
      <ArcGrid item xs={12} key={id}>
        {hasError && this.renderError(rewardType)}

        <ArcFormField
          autoComplete={isRewardCustom ? undefined : 'off'}
          description=""
          disabled={!isRewardCustom && this.props.escrowAvailable <= 0}
          name={`rewards-${id}`}
          fullWidth
          hasLabel={false}
          label={isRewardTokens ? 'Tokens' : 'Reward'}
          type={isRewardTokens ? 'number' : 'text'}
          isTouched={this.props.touched.rewards}
          onChange={event => {
            this.props.setFieldValue('rewards', {
              ...rewards,
              [index]: {
                receivingRank: index,
                type: rewardType,
                value: event.target.value,
              },
            });
          }}
          InputProps={{
            startAdornment: isRewardTokens && (
              <InputAdornment position="start">{tokenIcon}</InputAdornment>
            ),
            endAdornment: (
              <>
                {this.props.values.format !== 'threshold' && (
                  <InputAdornment position="end">{ordinalIndex}</InputAdornment>
                )}
              </>
            ),
          }}
          placeholder={rewardTextFieldPlaceholder}
          value={rewards && rewards[index] ? rewards[index].value : ''}
          validations={{
            isRequired: index === 0,
            isNumber: rewardType === REWARD_TYPES.TOKENS,
            ...(rewardType === REWARD_TYPES.TOKENS ? { minNumber: 1 } : {}),
          }}
        />
      </ArcGrid>
    );
  };

  renderError = rewardType => {
    switch (rewardType) {
      case REWARD_TYPES.TOKENS:
        return (
          <ArcText color="danger">
            Rewards require valid token amounts
            {this.props.hasEscrowFeature
              ? " and token value can't be greater than available escrow"
              : ''}
            .
          </ArcText>
        );

      case REWARD_TYPES.CUSTOM:
        return (
          <>
            <ArcText color="danger">Custom reward is required.</ArcText>
            <ArcView spacer />
          </>
        );

      default:
        return null;
    }
  };

  renderWinnerLimitField = () => {
    const maxWinners = 10000;
    const minWinners = 0;

    const { rewardType, limitWinners } = this.props.values;

    const winnerLimitsDisabled = rewardType !== REWARD_TYPES.TOKENS;
    const addButtonDisabled = limitWinners >= maxWinners;
    const removeButtonDisabled = limitWinners <= minWinners;

    const addButton = (
      <ArcIconButton
        size="small"
        disabled={winnerLimitsDisabled || addButtonDisabled}
        onClick={() => {
          if (limitWinners + 1 > maxWinners) {
            return;
          }

          this.props.setFieldValue(
            'limitWinners',
            parseInt(limitWinners || 0, 10) + 1,
          );
          this.props.setFieldTouched('limitWinners', true);
        }}
      >
        <Add color="inherit" size="small" />
      </ArcIconButton>
    );

    const removeButton = (
      <ArcIconButton
        size="small"
        disabled={winnerLimitsDisabled || removeButtonDisabled}
        onClick={() => {
          if (limitWinners - 1 < minWinners) {
            return;
          }

          this.props.setFieldValue(
            'limitWinners',
            parseInt(limitWinners || 0, 10) - 1,
          );
          this.props.setFieldTouched('limitWinners', true);
        }}
      >
        <Remove color="inherit" size="small" />
      </ArcIconButton>
    );

    const removeButtonAdornment =
      removeButtonDisabled && minWinners > 0 ? (
        <Tooltip title={`Min winners is ${minWinners}`}>
          <ArcView>{removeButton}</ArcView>
        </Tooltip>
      ) : (
        removeButton
      );

    const addButtonAdornment = addButtonDisabled ? (
      <Tooltip title={`Max winners is ${maxWinners}`}>
        <ArcView>{addButton}</ArcView>
      </Tooltip>
    ) : (
      addButton
    );

    const endAdornment = (
      <React.Fragment>
        {removeButtonAdornment}
        {addButtonAdornment}
      </React.Fragment>
    );

    return (
      <ArcGrid item xs={12}>
        <ArcFormField
          autoComplete="off"
          type="number"
          name="limitWinners"
          fullWidth
          label="Number of winners"
          disabled={winnerLimitsDisabled || this.props.escrowAvailable <= 0}
          validations={{
            minNumber: minWinners,
            maxNumber: maxWinners,
          }}
          InputProps={{
            endAdornment,
          }}
        />
      </ArcGrid>
    );
  };

  render() {
    const { rewardType, format } = this.props.values;

    const isRewardChests = rewardType === REWARD_TYPES.CHESTS;
    const isRewardTokens = rewardType === REWARD_TYPES.TOKENS;
    const isRewardCustom = rewardType === REWARD_TYPES.CUSTOM;

    const isBountyGame = format === FORMAT_TYPES.BOUNTY;
    const isRPAGame =
      [FORMAT_TYPES.RPA_EVERY_TIME, FORMAT_TYPES.RPA_ONE_TIME].indexOf(format) >
      -1;

    const shouldLimitWinnerNumbers =
      (isBountyGame || isRPAGame) && isRewardTokens;

    const { currentEscrowValue, escrowAvailable } = this.props;

    const formattedEscrowAvailable = escrowAvailable || ' None';

    this.rewardFields = this.getRewardFields();

    return (
      <ArcGrid
        component={ArcView}
        container
        position="relative"
        spacing={2}
        style={{ maxWidth: 600 }}
        wrap="nowrap"
      >
        <ArcGrid item xs={12}>
          <Typography style={styleTitle} variant="h5">
            {'Choose a reward type'}
          </Typography>

          <ArcButtonGroup wrap="wrap">
            <ArcButton
              aria-checked={isRewardChests ? 'true' : 'false'}
              label="Chests"
              className={cx([isRewardChests && 'isActive'])}
              onClick={this.createHandleClickRewardType(REWARD_TYPES.CHESTS)}
              style={styleButton}
            >
              <ArcView align="center">
                <ArcImage
                  width="64px"
                  height="64px"
                  src={chestSvg}
                  style={!isRewardChests ? styleMutedImage : styleImage}
                />
                <ArcText>{'Chests'}</ArcText>
              </ArcView>
            </ArcButton>

            <ArcButton
              aria-checked={isRewardTokens ? 'true' : 'false'}
              label="Tokens"
              className={cx([isRewardTokens && 'isActive'])}
              onClick={this.createHandleClickRewardType(REWARD_TYPES.TOKENS)}
              style={styleButton}
            >
              <ArcView align="center">
                <ArcImage
                  width="64px"
                  height="64px"
                  src="https://d17rkelr6jjme9.cloudfront.net/prize-images/ArcadeCoin.svg"
                  style={!isRewardTokens ? styleMutedImage : styleImage}
                />
                <ArcText>{'Tokens'}</ArcText>
              </ArcView>
            </ArcButton>

            <ArcButton
              aria-checked={isRewardCustom ? 'true' : 'false'}
              label="Custom"
              className={cx([isRewardCustom && 'isActive'])}
              onClick={this.createHandleClickRewardType(REWARD_TYPES.CUSTOM)}
              style={styleButton}
            >
              <ArcView align="center">
                <CustomIcon
                  width="64"
                  height="64"
                  style={!isRewardCustom ? styleMutedImage : styleImage}
                />
                <ArcText>{'Custom'}</ArcText>
              </ArcView>
            </ArcButton>
          </ArcButtonGroup>
        </ArcGrid>

        <ArcGrid item xs={12}>
          {isRewardTokens && (
            <ArcView marginBottom={8}>
              {escrowAvailable > 0 ? (
                <Typography variant="h6">
                  {'Your available tokens: '}
                  <ArcImage
                    width="16px"
                    height="16px"
                    style={{ verticalAlign: 'baseline' }}
                    src="https://d17rkelr6jjme9.cloudfront.net/prize-images/ArcadeCoin.svg"
                  />
                  {formattedEscrowAvailable.toLocaleString()}
                </Typography>
              ) : (
                <ArcGrid item xs={12}>
                  <ArcView marginBottom="8">
                    <Typography variant="h6" color="error">
                      {
                        'You cannot have a token prize as you have no remaining tokens in your monthly balance.'
                      }
                    </Typography>
                  </ArcView>
                </ArcGrid>
              )}

              {this.props.hasEscrowFeature && (
                <ArcView>
                  {currentEscrowValue > 0 && (
                    <Typography variant="body1">
                      &nbsp;(
                      <ArcImage
                        width="16px"
                        height="16px"
                        style={{ verticalAlign: 'text-top' }}
                        src="https://d17rkelr6jjme9.cloudfront.net/prize-images/ArcadeCoin.svg"
                      />
                      {formattedEscrowAvailable.toLocaleString()} already in
                      escrow for this game)
                    </Typography>
                  )}
                </ArcView>
              )}
            </ArcView>
          )}

          <ArcParagraph style={{ marginBottom: 16 }}>
            {REWARD_MESSAGE_BY_TYPE[rewardType]}
          </ArcParagraph>

          {FORMAT_TYPES.isRPAType(this.props.values.format) && (
            <Typography variant="body1" style={{ marginBottom: 8 }}>
              {REWARD_MESSAGE_BY_RPA_TYPE[this.props.values.format]}
            </Typography>
          )}

          {this.rewardFields.map(this.renderRewardField)}
          {isRewardTokens && this.props.hasEscrowFeature && (
            <Typography variant="caption">
              Your available tokens are the maximum possible prize for this game
              - they must be available before game creation, and will be held in
              escrow until game is complete or cancelled.
            </Typography>
          )}
        </ArcGrid>

        {shouldLimitWinnerNumbers && (
          <ArcGrid item xs={12}>
            {this.renderWinnerLimitField()}
            <Typography variant="caption">
              {isRPAGame
                ? 'Because people can win multiple times you may want to limit the maximum number of times prizes can be awarded.'
                : 'If you are assigning a large number of people to this game you may want to limit how many people can win.'}
              {
                ' This is optional but will reduce the amount of tokens placed in escrow.'
              }
            </Typography>
          </ArcGrid>
        )}

        {this.props.hasEscrowFeature &&
          !this.props.currentUserCreated &&
          this.props.isEditing && (
            <ArcScreenBlocker
              align="center"
              justify="center"
              textAlign="center"
              color="danger"
            >
              <Typography color="inherit" variant="h5">
                {'Only the creator of a game can change the rewards'}
              </Typography>
            </ArcScreenBlocker>
          )}

        {!this.hasParticipants && (
          <ArcScreenBlocker
            align="center"
            justify="center"
            textAlign="center"
            color="danger"
          >
            <Typography color="inherit" variant="h5">
              {'You need to select participants in step 2'}
            </Typography>
          </ArcScreenBlocker>
        )}
      </ArcGrid>
    );
  }
}

const getState = (state, props) => ({
  eligiblePeople: getManageGamesEligiblePeople(state),
  eligibleTeams: getManageGamesEligibleTeams(state),
  ...props,
});

export default connect(getState)(GamesCreateStepThreeForm);
