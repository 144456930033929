import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { ArcMainView, ArcViewTitleBar } from 'arcade-frontend-ui';

import { routeRewards } from '../actions/routes';
import RewardHistoryListContainer from '../containers/RewardHistoryListContainer';

const RewardHistoryView = ({ goBack }) => (
  <ArcMainView isViewing fullHeight hasScroll={false}>
    <ArcViewTitleBar onClickBack={goBack} title="Reward Purchases" />
    <RewardHistoryListContainer />
  </ArcMainView>
);

RewardHistoryView.propTypes = {
  goBack: PropTypes.func.isRequired,
};

const getActions = dispatch =>
  bindActionCreators(
    {
      goBack: routeRewards,
    },
    dispatch,
  );

export default connect(null, getActions)(RewardHistoryView);
