import { Platform, StyleSheet } from 'react-primitives';

export const flatten = (styles) => {
  switch (Platform.OS) {
    case 'web':
      return styles;
    default:
      return StyleSheet.flatten(styles);
  }
};
