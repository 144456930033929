import React from 'react';
import PropTypes from 'prop-types';
import ArcScoreboard from '../ArcScoreboard/ArcScoreboard';
import ArcBox from '../ArcBox';
import theme from '../../styles/theme';

function ArcScoreboardBadge({ backgroundColor, borderRadius, ...props }) {
  return (
    <ArcBox
      bgcolor={theme.palette[backgroundColor] ? theme.palette[backgroundColor].main : backgroundColor}
      padding={0.5}
      borderRadius={borderRadius}
    >
      <ArcScoreboard {...props} />
    </ArcBox>
  );
}

ArcScoreboardBadge.displayName = 'ArcScoreboardBadge';

ArcScoreboardBadge.propTypes = {
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.number,
};

ArcScoreboardBadge.defaultProps = {
  backgroundColor: theme.palette.common.white,
  borderRadius: 8,
};

export default ArcScoreboardBadge;
