import { react2angular } from 'react2angular';

import NotificationsPortal from 'arcade-frontend-notifications/src/components/NotificationsPortal';

import { createProvidedContainer } from './helpers';

const ProvidedNotificationsPortal = createProvidedContainer(
  NotificationsPortal,
);

export default react2angular(ProvidedNotificationsPortal, []);
