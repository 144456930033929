import createResourceAction from '../../helpers/createResourceAction';

export const apiPutTokensClawback = createResourceAction('update', {
  resourceType: 'tokens',
  requestKey: 'apiPutTokensClawback',

  api: payload => ({
    method: 'PUT',
    url: '/manager/v4/credits/clawback',
    data: payload.resources[0],
  }),
});

export const apiPostTokensAward = createResourceAction('update', {
  resourceType: 'tokens',
  requestKey: 'apiPostTokensAward',

  api: payload => ({
    method: 'POST',
    url: '/manager/v4/credits/award',
    data: payload.resources[0],
  }),
});
