import React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createGenerateClassName, jssPreset } from '@material-ui/styles';
import { create as jssCreate } from 'jss';
import JssProvider from 'react-jss/lib/JssProvider';

import ArcPropTypes from '../helpers/arc/propTypes';
import TinyDatePickerJss from '../jss/TinyDatePickerJss';
import arcTheme from '../styles/theme';

export const theme = arcTheme;

const generateClassName = createGenerateClassName();
const jss = jssCreate(jssPreset());

class ArcBenjoThemeProvider extends React.PureComponent {
  static propTypes = {
    children: ArcPropTypes.children.isRequired,
  };

  static theme = theme;

  render() {
    return (
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <TinyDatePickerJss />
          {this.props.children}
        </ThemeProvider>
      </JssProvider>
    );
  }
}

export default ArcBenjoThemeProvider;
