import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ChevronRight from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';

import track from 'arcade-frontend-core/src/helpers/track';
import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import {
  ArcResponsiveDialog,
  ArcView,
  ArcButton,
  ArcTextField,
  ArcEntity,
  ArcText,
  ArcNoResultsMessage,
} from 'arcade-frontend-ui';

import { resources } from '../../resources/users';

const SwitchColorPrimary = withStyles(theme => ({
  switchBase: {
    color: theme.palette.grey[100],
    '&$checked': {
      color: theme.palette.blue[500],
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.blue[500],
    },
  },
}))(Switch);

const useStyles = makeStyles(theme => ({
  searchHeader: {
    flexDirection: 'row',
    padding: '8px 16px',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  searchRow: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  pullRight: {
    marginLeft: 'auto',
  },
  textInputStyles: {
    marginTop: 0,
    width: 180,
  },
  textInputDisabled: {
    backgroundColor: theme.palette.grey[100],
  },
  allowTokenBudgetLabel: {
    alignSelf: 'center',
    color: theme.palette.grey[700],
    fontSize: theme.font.getFontSize(0.85),
  },
  searchResultsWrapper: {
    padding: '0 16px',
    height: 500,
  },
  searchGamesNoResultsWrapper: {
    margin: '20px 10px',
  },
  explainerText: {
    fontSize: theme.font.getFontSize(0.85),
    color: theme.palette.grey[600],
    margin: theme.spacing(0.5),
    lineHeight: 1.5,
  },
  explainerTextHeading: {
    fontSize: theme.font.getFontSize(1),
  },
}));

const STRINGS = {
  'UI/MANAGE_USER_TOKEN_BUDGET_DIALOG_TITLE': 'Add/Remove Users',
  'UI/MANAGE_USER_TOKEN_BUDGET_DIALOG_SEARCH_LABEL': 'Search by users',
  'UI/MANAGE_USER_TOKEN_BUDGET_DIALOG_EXPLAINER_P1': `Turning this functionality on for a user will allow yourself and anyone with the Set Token Limits permission to assign a token budget to them. Having a token budget will allow a user to transfer the set amount of funds from the company account to a game or to another user.`,
  'UI/MANAGE_USER_TOKEN_BUDGET_DIALOG_EXPLAINER_P2': `Removing this functionality from a user will not undo any transfers they have already conducted but will prevent them from making future transfers.`,
};

const ManageTokenUserBudgetPermissionDialog = ({
  open,
  onClose,
  data,
  searchString,
}) => {
  const classes = useStyles();

  const apiPutPermissions = useResourceAction(resources.apiPutPermissions);

  const getInitialSwitchState = () => {
    const selectedIds = {};

    if (!data) {
      return selectedIds;
    }

    data.forEach(user => {
      selectedIds[user.id] = user.canDistributeTokens;
    });

    return selectedIds;
  };

  const [searchStr, setSearchStr] = useState(searchString);
  const [toggledSwitches, setToggledSwitches] = useState(getInitialSwitchState);
  const [showExplainer, setShowExplainer] = useState(true);

  const startSearch = debounce(500, value => {
    setSearchStr(
      value
        .toLowerCase()
        .trim()
        .replace(/[^\w\s]/gi, ''),
    );
  });

  const handleOnChangeSearch = event => {
    track(`searched add/remove dialog for ${event.target.value}`, {
      payload: { event: 'token-budgets:search' },
    });
    startSearch(event.target.value);
  };

  const updateUserPermission = (id, permissionValue) => {
    apiPutPermissions
      .requestData({
        resources: [
          {
            id,
            permissions: {
              'Token Management': {
                'Award Tokens': permissionValue,
              },
            },
          },
        ],
      })
      .then(() => apiPutPermissions.onStatusReset())
      .catch(apiPutPermissions.onStatusReset);
  };

  const handleOnChangeSwitch = (id, isToggled) => {
    track(
      `update permission for 'Award Tokens' from ${!isToggled} to ${isToggled}`,
      { payload: { event: 'token-budgets:update-permission' } },
    );

    setToggledSwitches({ ...toggledSwitches, [id]: isToggled });
    updateUserPermission(id, isToggled);
  };

  const handleShowExplainer = () => {
    setShowExplainer(!showExplainer);
  };

  const renderSearchResults = () => {
    let resultsToDisplay = data || [];

    if (searchStr.length >= 3) {
      resultsToDisplay = resultsToDisplay.filter(result =>
        result.name.toLowerCase().includes(searchStr),
      );
    }

    return (
      <>
        {resultsToDisplay.length === 0 ? (
          <ArcView className={classes.searchResultsWrapper}>
            <ArcView className={classes.searchGamesNoResultsWrapper}>
              <ArcNoResultsMessage
                subheader={
                  searchStr.length > 0
                    ? `Could not find any results for '${searchStr}'`
                    : null
                }
              />
            </ArcView>
          </ArcView>
        ) : (
          <ArcView className={classes.searchResultsWrapper}>
            <ArcView className={classes.searchHeader}>
              <ArcText isStrong>Allow Monthly Token Budget</ArcText>
            </ArcView>
            {resultsToDisplay.map(result => (
              <ArcView
                key={result.id}
                className={classes.searchRow}
                padding="8"
                row
              >
                <ArcEntity
                  type="person"
                  imageUrl={result.profileImage}
                  name={result.name}
                />
                <ArcView row className={classes.pullRight}>
                  <SwitchColorPrimary
                    data-testid={`add-remove-users-dialog-switch-${result.id}`}
                    checked={toggledSwitches[result.id]}
                    onClick={() =>
                      handleOnChangeSwitch(
                        result.id,
                        !toggledSwitches[result.id],
                      )
                    }
                  />
                </ArcView>
              </ArcView>
            ))}
          </ArcView>
        )}
      </>
    );
  };

  return (
    <ArcResponsiveDialog
      id="manage-user-token-budget-dialog"
      open={open}
      onClose={onClose}
    >
      <ArcView flexGrow="10" flexShrink="10" fullHeight>
        <ArcView
          position="relative"
          row
          padding="8"
          paddingLeft="16"
          align="flex-end"
          color="grey-tint"
          borderBottom="default"
          borderBottomWidth="1"
        >
          <Typography variant="h5">
            {STRINGS['UI/MANAGE_USER_TOKEN_BUDGET_DIALOG_TITLE']}
          </Typography>

          <ArcView spacer />

          <ArcButton size="small" onClick={onClose}>
            <Close color="action" fontSize="small" />
          </ArcButton>
        </ArcView>
      </ArcView>

      <ArcView padding="16">
        <Box display="flex" flexDirection="column" color="text.secondary">
          <Box
            onClick={handleShowExplainer}
            display="flex"
            alignItems="center"
            fontSize={12}
          >
            {showExplainer ? (
              <KeyboardArrowDownIcon color="inherit" />
            ) : (
              <ChevronRight color="inherit" />
            )}

            <Box py={1}>
              <ArcText className={classes.explainerTextHeading}>
                {'Explainer'}
              </ArcText>
            </Box>
          </Box>
          <Collapse in={showExplainer}>
            <ArcView>
              <ArcText className={classes.explainerText}>
                {STRINGS['UI/MANAGE_USER_TOKEN_BUDGET_DIALOG_EXPLAINER_P1']}
              </ArcText>
            </ArcView>
            <ArcView>
              <ArcText className={classes.explainerText}>
                {STRINGS['UI/MANAGE_USER_TOKEN_BUDGET_DIALOG_EXPLAINER_P2']}
              </ArcText>
            </ArcView>
          </Collapse>
        </Box>

        <ArcTextField
          label={STRINGS['UI/MANAGE_USER_TOKEN_BUDGET_DIALOG_SEARCH_LABEL']}
          defaultValue={searchStr.length > 1 ? searchStr : null}
          type="search"
          margin="normal"
          variant="outlined"
          onChange={handleOnChangeSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ color: '#616264' }} />
              </InputAdornment>
            ),
          }}
        />
      </ArcView>
      {renderSearchResults()}
    </ArcResponsiveDialog>
  );
};

ManageTokenUserBudgetPermissionDialog.displayName =
  'ManageTokenUserBudgetPermissionDialog';

ManageTokenUserBudgetPermissionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.any),
  searchString: PropTypes.string,
};

ManageTokenUserBudgetPermissionDialog.defaultProps = {
  open: false,
  onClose: global.noop,
  data: {},
  searchString: '',
};

export default ManageTokenUserBudgetPermissionDialog;
