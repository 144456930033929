import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  ArcButton,
  ArcFormStepper,
  ArcDialog,
  ArcForm,
  ArcView,
  ArcLoader,
} from 'arcade-frontend-ui';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { actions } from '../../../actions/manage/metrics';

import { getRequestStatus } from '../../../reducers/manage/metrics/requestStatus';

import MetricsCreateConfirmationForm from './MetricsCreateConfirmationForm';
import MetricsCreateStepOneForm from './MetricsCreateStepOneForm';
import MetricsCreateStepTwoForm from './MetricsCreateStepTwoForm';
import MetricsCreateStepThreeForm from './MetricsCreateStepThreeForm';

const ASSIGN_TYPES = {
  USERS: 'users',
  TEAMS: 'teams',
};

const SELECT_TYPES = {
  ALL: 'all',
  SELECTED: 'selected',
};

const initialValues = {
  name: '',
  verb: '',
  unit: '#',
  average: false,
  canAddSales: true,
  includesIntegrationIdentifier: false,
  integrationIdentifier: '',

  assignType: ASSIGN_TYPES.USERS,
  selectType: SELECT_TYPES.ALL,
  peopleSelector: {
    teamIds: [],
    peopleIds: [],
  },

  targetType: 'everyday',
  target: '',
  monday: '',
  tuesday: '',
  wednesday: '',
  thursday: '',
  friday: '',
  saturday: '',
  sunday: '',
};

const getInitialValues = (currentMetric) => {
  if (currentMetric && currentMetric.id) {
    const { assign } = currentMetric;
    const selectType = ((assign && assign.indexOf('all') === 0) || assign === 'everyone' ? 'all' : 'selected');
    let assignType = assign;

    if (assign === 'all_teams') {
      assignType = 'teams';
    } else if (assign === 'everyone' || assign === 'people') {
      assignType = 'users';
    }

    let target = { target: currentMetric.benchmark };
    let targetType = currentMetric.benchmark === null ? 'none' : 'everyday';

    if (currentMetric.benchmark && typeof currentMetric.benchmark.monday !== 'undefined') {
      target = currentMetric.benchmark;
      targetType = 'daily';
    }

    const includesIntegrationIdentifier = !!(currentMetric && currentMetric.integrationIdentifier);

    return {
      ...currentMetric,
      includesIntegrationIdentifier,
      assignType,
      selectType,
      peopleSelector: {
        teamIds: currentMetric.teamIds,
        peopleIds: currentMetric.peopleIds,
      },

      average: currentMetric.isAverage,

      targetType,
      ...target,
    };
  }

  return undefined;
};

class MetricsCreateForm extends React.Component {
  static getInitialValues = getInitialValues;

  static propTypes = {
    activeStep: PropTypes.number,
    apiManageMetricsCreateRequest: PropTypes.func.isRequired,
    apiManageMetricsUpdateRequest: PropTypes.func.isRequired,
    currentMetric: PropTypes.shape({
      id: PropTypes.string,
      canAddSales: PropTypes.bool,
    }),
    initialValues: PropTypes.objectOf(PropTypes.any),
    onCancel: PropTypes.func,
    requestStatus: PropTypes.shape({
      MANAGE_METRICS_CREATE: PropTypes.string,
      MANAGE_METRICS_UPDATE: PropTypes.string,
      MANAGE_METRICS_EDIT: PropTypes.string,
    }),
  };

  static defaultProps = {
    activeStep: 0,
    currentMetric: {},
    initialValues,
    onCancel: global.noop,
    requestStatus: {},
  };

  static displayName = 'MetricsCreateForm';

  state = {
    activeStep: this.props.activeStep,
    isCancelling: false,
    isLoading: true,
  };

  get currentRequestStatus() {
    return this.props.requestStatus[this.requestStatus];
  }

  get requestStatus() {
    if (this.props.currentMetric.id) {
      return 'MANAGE_METRICS_UPDATE';
    }

    return 'MANAGE_METRICS_CREATE';
  }

  setActiveStep = activeStep => this.setState({ activeStep });

  setIsLoading = isLoading => this.setState({ isLoading });

  setIsCancelling = isCancelling => this.setState({ isCancelling });

  componentDidUpdate(prevProps) {
    const didSubmit = prevProps.requestStatus[this.requestStatus] === 'SUCCESS'
      && this.currentRequestStatus === 'DEFAULT';

    if (didSubmit) {
      this.props.onCancel();
    }

    if (prevProps.requestStatus.MANAGE_METRICS_EDIT === 'SUCCESS'
    && this.props.requestStatus.MANAGE_METRICS_EDIT === 'DEFAULT') {
      this.setIsLoading(false);
    }
  }

  incrementActiveStep = (steps = 1) => this.setState(prevState => ({
    activeStep: prevState.activeStep + steps,
  }));

  handleBack = () => this.incrementActiveStep(-1);

  handleCancel = () => this.setIsCancelling(true);

  handleCloseCancellingDialog = () => this.setIsCancelling(false);

  handleNext = () => this.incrementActiveStep(1);

  handleStep = step => this.setActiveStep(step);

  handleSubmit = (values) => {
    if (this.props.currentMetric.id) {
      this.props.apiManageMetricsUpdateRequest(this.props.currentMetric.id, values);
    } else {
      this.props.apiManageMetricsCreateRequest(values);
    }
  };

  renderActiveStep(formikProps) {
    return this.renderStep(this.state.activeStep, formikProps);
  }

  renderStep = (step, formikProps = {}) => {
    switch (step) {
      case 0:
        return (
          <MetricsCreateStepOneForm
            key={step}
            values={formikProps.values}
            setFieldValue={formikProps.setFieldValue}
            setFieldTouched={formikProps.setFieldTouched}
          />
        );
      case 1:
        return (
          <MetricsCreateStepTwoForm
            key={step}
            values={formikProps.values}
            setFieldValue={formikProps.setFieldValue}
            setFieldTouched={formikProps.setFieldTouched}
          />
        );
      case 2:
        return (
          <MetricsCreateStepThreeForm
            key={step}
            values={formikProps.values}
            touched={formikProps.touched}
            setFieldValue={formikProps.setFieldValue}
            setFieldTouched={formikProps.setFieldTouched}
          />
        );
      case 3:
        return (
          <MetricsCreateConfirmationForm
            key={step}
            values={formikProps.values}
            setFieldValue={formikProps.setFieldValue}
            setFieldTouched={formikProps.setFieldTouched}
            summaryFields={MetricsCreateStepOneForm.FIELDS}
          />
        );
      default:
        return null;
    }
  };

  renderCancelDialog() {
    return (
      <ArcDialog
        open={this.state.isCancelling}
        onClose={this.handleCloseCancellingDialog}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description"
      >
        <DialogTitle id="cancel-dialog-title">
          {'Go back?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="cancel-dialog-description">
            {'You will lose all unsaved information.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ArcButton onClick={this.handleCloseCancellingDialog}>
            {'No'}
          </ArcButton>
          <ArcButton onClick={this.props.onCancel} color="danger">
            {'Yes'}
          </ArcButton>
        </DialogActions>
      </ArcDialog>
    );
  }

  renderForm = (formikProps) => {
    const getIsValid = (fields, errors) => {
      let isValid = true;

      fields.forEach((field) => {
        if (errors[field]) {
          isValid = false;
        }
      });

      return isValid;
    };

    const { peopleIds, teamIds } = formikProps.values.peopleSelector;

    let isValidStepTwo = false;

    if (formikProps.values.selectType === SELECT_TYPES.ALL) {
      isValidStepTwo = true;
    } else {
      isValidStepTwo = formikProps.values.assignType === ASSIGN_TYPES.USERS
        ? (!!peopleIds && !!peopleIds.length)
        : (!!teamIds && !!teamIds.length);
    }

    let stepThreeFields = [];
    let multipleTargetsError = true;

    if (formikProps.values.targetType === 'everyday') {
      stepThreeFields = ['target'];
      multipleTargetsError = false;
    } else if (formikProps.values.targetType === 'daily') {
      stepThreeFields = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

      stepThreeFields.forEach((key) => {
        if (formikProps.values[key]) {
          multipleTargetsError = false;
        }
      });
    } else {
      multipleTargetsError = false;
    }

    const isValidStepThree = getIsValid(stepThreeFields, formikProps.errors);

    const isValidByIndex = {
      0: getIsValid(MetricsCreateStepOneForm.FIELDS, formikProps.errors),
      1: isValidStepTwo,
      2: isValidStepThree && !multipleTargetsError,
    };

    const isTouchedByIndex = {
      0: getIsValid(MetricsCreateStepOneForm.FIELDS, formikProps.touched),
      1: getIsValid(MetricsCreateStepTwoForm.FIELDS, formikProps.touched),
      2: getIsValid(MetricsCreateStepThreeForm.FIELDS, formikProps.touched),
    };

    const formIsValid = Object.values(isValidByIndex).indexOf(false) === -1;

    return (
      <ArcFormStepper
        activeStep={this.state.activeStep}
        hasConfirm
        isValid={formIsValid}
        onBack={this.handleBack}
        onCancel={this.handleCancel}
        onConfirm={this.handleStep}
        onFinish={formikProps.handleSubmit}
        onNext={this.handleNext}
        onStep={this.handleStep}
        requestStatus={this.currentRequestStatus}
        steps={[
          {
            label: 'Details',
            isValid: isValidByIndex[0],
            isTouched: isTouchedByIndex[0],
          },
          {
            label: 'People',
            isValid: isValidByIndex[1],
            isTouched: isTouchedByIndex[1],
          },
          {
            label: 'Targets',
            isValid: isValidByIndex[2],
            isTouched: isTouchedByIndex[2],
          },
        ]}
      >
        {this.renderActiveStep(formikProps)}
        {this.renderCancelDialog()}
      </ArcFormStepper>
    );
  };

  render() {
    return (
      <>
        {this.props.currentMetric.id && this.state.isLoading ? (
          <ArcView
            data-testid="ArcView-IsLoading"
          >
            <ArcLoader
              in={this.state.isLoading}
            />
          </ArcView>
        ) : (
          <ArcForm
            initialValues={{
              ...initialValues,
              ...this.props.initialValues,
            }}
            onSubmit={this.handleSubmit}
          >
            {this.renderForm}
          </ArcForm>
        )}
      </>
    );
  }
}

const getState = (state, props) => ({
  requestStatus: getRequestStatus(state),
  ...props,
});

const getActions = dispatch => bindActionCreators({
  apiManageMetricsCreateRequest: actions.apiManageMetricsCreateRequest,
  apiManageMetricsUpdateRequest: actions.apiManageMetricsUpdateRequest,
}, dispatch);

export default connect(getState, getActions)(MetricsCreateForm);
