import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../../actions/manage/metrics/types';

const handleTeamsLoadSuccess = (state, action) => {
  if (!action.payload) return state;

  const { data } = action.payload;

  const userIdsByTeamId = {};

  data.forEach((team) => {
    userIdsByTeamId[team.id] = team.peopleIds;
  });

  return userIdsByTeamId;
};

const initialState = {};

const handlers = {
  [types.MANAGE_METRICS_TEAMS_INDEX.SUCCESS]: handleTeamsLoadSuccess,
};

const peopleByTeam = createReducer(initialState, handlers);

export default peopleByTeam;

export const getManageMetricsPeopleByTeam = state => state.manage.metrics.peopleByTeam;
