import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const SearchOutline = props => (
  <Icon {...props}>
    <Path
      stroke="currentColor"
      fill="transparent"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.834 16.825L22.01 22M10.68 19.38a8.69 8.69 0 100-17.38 8.69 8.69 0 000 17.38z"
    />
  </Icon>
);


export default SearchOutline;
