import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { routes } from 'arcade-frontend-core/src/actions';
import WeeklySuperstarBanner from 'arcade-frontend-ui/src/assets/WeeklySuperstarBanner';
import ArcImage from 'arcade-frontend-ui/src/primitives/ArcImage';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
import ArcTextV2 from 'arcade-frontend-ui/src/components/ArcTextV2';
import ArcChestImage from 'arcade-frontend-ui/src/components/ArcChestImage';
import ArcLink from 'arcade-frontend-ui/src/elements/ArcLink';
import ArcCoverAlignedImage from 'arcade-frontend-ui/src/components/ArcCoverAlignedImage';

import WeeklySuperstarActivityStars from './WeeklySuperstarActivityStars';

const RECEIVING_USER_IMAGE_OFFSET = 20;

export default function WeeklySuperstarActivity({ activity }) {
  const dispatch = useDispatch();

  const {
    receivingUser = {},
    nominatingUser = {},
    reason,
  } = activity.additionalInformation;

  function handleClickNominatingUser(evt) {
    evt.preventDefault();
    dispatch(routes.routeProfile({ id: nominatingUser?.id }));
  }

  const profileLinkOrPersonName = window.arcPostMessage ? (
    nominatingUser?.name
  ) : (
    <ArcLink
      size="inherit"
      onClick={handleClickNominatingUser}
      href={`/#/arcade/profile/${nominatingUser?.id}`}
    >
      {nominatingUser?.name}
    </ArcLink>
  );

  return (
    <ArcViewBox borderRadius={8} border="1px solid #EBEBEB" overflow="hidden">
      <ArcViewBox position="relative" backgroundColor="#003552">
        <WeeklySuperstarActivityStars />

        <ArcViewBox position="relative" alignItems="center">
          <ArcViewBox margin={3}>
            <WeeklySuperstarBanner />
          </ArcViewBox>
          <ArcViewBox
            border="2px solid #fff"
            borderRadius={8}
            overflow="hidden"
            marginBottom={`-${RECEIVING_USER_IMAGE_OFFSET}px`}
          >
            <ArcCoverAlignedImage
              alt={`${receivingUser?.name} profile image`}
              src={receivingUser.image}
              width="72px"
              height="72px"
            />
          </ArcViewBox>
        </ArcViewBox>
      </ArcViewBox>
      <ArcViewBox padding={1}>
        <ArcViewBox
          paddingTop={`${RECEIVING_USER_IMAGE_OFFSET + 8}px`}
          alignItems="center"
          marginBottom={1}
        >
          <ArcTextV2 textAlign="center" fontSize="14px" fontWeight="bold">
            {receivingUser?.name}
          </ArcTextV2>
        </ArcViewBox>
        <ArcViewBox marginBottom={3}>
          <ArcTextV2 color="#616264" textAlign="center" fontSize="12px">
            {reason}
          </ArcTextV2>
        </ArcViewBox>
      </ArcViewBox>
      <ArcViewBox
        flexDirection={['column', 'row']}
        alignItems="center"
        paddingX={3}
        paddingY="12px"
        borderTop="1px solid #EBEBEB"
      >
        <ArcViewBox flexDirection="row" alignItems="center">
          <ArcImage
            alt={`${nominatingUser?.name} profile image`}
            src={nominatingUser.image}
            width="32px"
            height="32px"
            style={{
              borderRadius: 32,
            }}
          />
          <ArcTextV2 fontSize="12px" color="#B4B4B4" marginLeft={2}>
            {'Nominated by '}
            {profileLinkOrPersonName}
          </ArcTextV2>
        </ArcViewBox>

        <ArcViewBox flexGrow="1000" />

        <ArcViewBox
          marginTop={[3, 0]}
          marginLeft={[0, 3]}
          backgroundColor="#F9F9F9"
          borderRadius={8}
          paddingX={2}
          paddingY={1}
          flexDirection="row"
          alignItems="center"
        >
          <ArcChestImage variant="base" label="" size="24px" />
          <ArcTextV2
            fontSize="10px"
            fontWeight="bold"
            color="#616264"
            marginLeft={2}
          >
            {'Prize: 5 Epic Chests'}
          </ArcTextV2>
        </ArcViewBox>
      </ArcViewBox>
    </ArcViewBox>
  );
}

WeeklySuperstarActivity.propTypes = {
  activity: PropTypes.shape({
    additionalInformation: PropTypes.shape({
      receivingUser: PropTypes.shape({
        id: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      nominatingUser: PropTypes.shape({
        id: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      reason: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
