import {
  ArcStyles,
} from 'arcade-frontend-ui';

const colors = ['blue', 'green', 'red', 'yellow'];
const teamColorPalette = [];

for (let idx = 1; idx < 5; idx += 1) {
  colors.forEach(color => teamColorPalette.push(ArcStyles.theme[`${color}${idx}`]));
}

function getTeamColor(id) {
  const intId = parseInt(id, 10);

  return teamColorPalette[intId % teamColorPalette.length];
}


export default getTeamColor;
