import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import newsfeedFilter from 'arcade-frontend-core/src/helpers/newsfeedFilter';

import NewsfeedActivitiesFilter from '../../components/NewsfeedActivitiesFilter';
import { actions } from '../../actions';
import { resources } from '../../resources/activityFilters';

const NewsfeedActivitiesFilterContainer = ({ onSubmit, ...props }) => {
  const dispatch = useDispatch();

  const apiGetActivityFilters = useResourceAction(
    resources.apiGetActivityFilters,
  );
  const apiPostActivityFilters = useResourceAction(
    resources.apiPostActivityFilters,
  );

  const data = apiGetActivityFilters.hasData
    ? apiGetActivityFilters.data.resources[0]
    : {};

  const { activityTypes, audiences } = data;

  function requestData() {
    return apiGetActivityFilters.requestData().then(resp => {
      apiGetActivityFilters.onStatusReset();
      return resp;
    });
  }

  function handleSubmit(values) {
    dispatch(actions.clearActivities());

    newsfeedFilter.set(values);

    dispatch(
      actions.activityIndexRequest({
        page: 1,
        numberOfComments: 5,
        newsfeedFilter: newsfeedFilter.get(),
      }),
    );
    onSubmit(values);
  }

  return (
    <NewsfeedActivitiesFilter
      activityTypes={activityTypes}
      audiences={audiences}
      hasData={apiGetActivityFilters.hasData}
      isPending={
        apiGetActivityFilters.isPending || apiPostActivityFilters.isPending
      }
      onSubmit={handleSubmit}
      requestData={requestData}
      status={
        apiGetActivityFilters.isPending
          ? apiGetActivityFilters.status
          : apiPostActivityFilters.status
      }
      {...props}
    />
  );
};

NewsfeedActivitiesFilterContainer.displayName =
  'NewsfeedActivitiesFilterContainer';

NewsfeedActivitiesFilterContainer.propTypes = {
  onSubmit: PropTypes.func,
};

NewsfeedActivitiesFilterContainer.defaultProps = {
  onSubmit: global.noop,
};

export default NewsfeedActivitiesFilterContainer;
