import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ArrowForward from '@material-ui/icons/ArrowForward';

import {
  ArcButton,
  ArcPaper,
  ArcPlaceholder,
  ArcResponsiveDialog,
  ArcView,
  createWithStyles,
} from 'arcade-frontend-ui';

import NewsfeedActivityCard from '../NewsfeedActivityCard';

const PAPER_PROPS = {
  style: {
    width: '100%',
    height: '100%',
  },
};

const headings = ['People', 'Time'];

const audienceHeadings = ['People', 'Audience'];

const headingToIndex = {
  Post: 0,
  Audience: 1,
  Views: 2,
  Respects: 3,
  Acknowledgements: 4,
  Reactions: 3,
};

const PLACEHOLDERS = [0, 1, 2, 3, 4];

const placeholders = PLACEHOLDERS.map(id => (
  <TableRow key={id}>
    <TableCell>
      <ArcView className="shimmer" padding="16" />
    </TableCell>
    <TableCell>
      <ArcView className="shimmer" padding="16" />
    </TableCell>
  </TableRow>
));

const renderPostPlaceholder = () => (
  <ArcPaper>
    <ArcView fullHeight>
      <ArcView row>
        <ArcPlaceholder
          style={{ height: 56, width: 56, margin: 16, marginBottom: 0 }}
        />
        <ArcPlaceholder
          style={{ height: 56, flexGrow: 1, margin: 16, marginBottom: 0 }}
        />
      </ArcView>

      <ArcPlaceholder style={{ height: 100, margin: 16 }} />
    </ArcView>
  </ArcPaper>
);

const styles = {
  ActivityDialogActions: theme => ({
    root: {
      marginTop: theme.spacing(2),
    },
  }),

  PersonImage: theme => ({
    root: {
      height: theme.spacing(3),
      width: theme.spacing(3),
      backgroundSize: 'cover',
      borderRadius: '50%',
      backgroundPosition: 'center center',
      marginRight: theme.spacing(1),
    },
  }),

  PersonRow: theme => ({
    root: {},

    shade: {
      backgroundColor: theme.palette.grey[100],
    },
  }),

  DialogTabs: theme => ({
    root: {
      marginLeft: theme.spacing(-3.5),
      marginRight: theme.spacing(-2),
    },
  }),

  DialogTab: () => ({
    root: {
      minWidth: 100,
    },
  }),
};

const ActivityDialogActions = createWithStyles(styles.ActivityDialogActions)(
  DialogActions,
);
const PersonImage = createWithStyles(styles.PersonImage)(ArcView);
const PersonRow = createWithStyles(styles.PersonRow)(TableRow);
const DialogTabs = createWithStyles(styles.DialogTabs)(Tabs);
const DialogTab = createWithStyles(styles.DialogTab)(Tab);

class ActivityDetailsDialog extends React.PureComponent {
  static propTypes = {
    acknowledgements: PropTypes.arrayOf(
      PropTypes.shape({
        created_at: PropTypes.date,
        user: PropTypes.shape({
          image: PropTypes.image,
          name: PropTypes.string,
        }),
      }),
    ),
    audience: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string,
        audience_name: PropTypes.string,
      }),
    ),
    content: PropTypes.string,
    createdAt: PropTypes.instanceOf(moment),
    currentUserId: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.shape({})),
    isRequesting: PropTypes.bool,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    pinned: PropTypes.bool,
    reactions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        user: PropTypes.shape({
          name: PropTypes.string.isRequired,
          image: PropTypes.string.isRequired,
        }).isRequired,
        reaction: PropTypes.string.isRequired,
      }),
    ),
    startingTab: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.string,
      id: PropTypes.string,
      badge: PropTypes.string,
    }),
    userRespected: PropTypes.bool,
    views: PropTypes.arrayOf(
      PropTypes.shape({
        created_at: PropTypes.date,
        user: PropTypes.shape({
          image: PropTypes.image,
          name: PropTypes.string,
        }),
      }),
    ),
    video: PropTypes.shape({}),
  };

  static defaultProps = {
    acknowledgements: null,
    audience: null,
    content: null,
    currentUserId: null,
    files: [],
    images: [],
    createdAt: null,
    isRequesting: false,
    id: null,
    onClose: global.noop,
    open: false,
    pinned: false,
    user: {},
    userRespected: false,
    reactions: [],
    startingTab: 'Post',
    video: {},
    views: null,
  };

  state = {
    tabValue: 0,
  };

  componentDidUpdate(prevProps) {
    if (this.props.startingTab !== prevProps.startingTab) {
      this.updateTab(null, headingToIndex[this.props.startingTab]);
    }
  }

  updateTab = (event, tabValue) => {
    this.setState({ tabValue });
  };

  handleClickShowPost = () => {
    if (window.getPost) {
      window.getPost(this.props.id);
    }

    this.props.onClose();
  };

  renderColumnHeadings = heading => (
    <TableCell key={heading}>{heading}</TableCell>
  );

  renderPersonRow = (row, index) => (
    <PersonRow key={row.id} shade={index % 2 === 0}>
      <TableCell>
        <ArcView row align="center">
          <PersonImage
            style={{
              backgroundImage: `url('${row.user.image}')`,
            }}
          />
          {row.user.name}
        </ArcView>
      </TableCell>
      <TableCell>{moment(row.created_at).format('ddd MMM DD')}</TableCell>
    </PersonRow>
  );

  renderReactionRow = (row, index) => (
    <PersonRow key={row.id} shade={index % 2 === 0}>
      <TableCell>
        <ArcView row align="center">
          <PersonImage
            style={{
              backgroundImage: `url('${row.user.image}')`,
            }}
          />
          {row.user.name}
        </ArcView>
      </TableCell>
      <TableCell>{row.reaction}</TableCell>
    </PersonRow>
  );

  renderAudienceRow = (row, index) => (
    <PersonRow key={row.id} shade={index % 2 === 0}>
      <TableCell>
        <ArcView row align="center">
          <PersonImage
            style={{
              backgroundImage: `url('${row.image}')`,
            }}
          />
          {row.name}
        </ArcView>
      </TableCell>
      <TableCell>{row.audience_name}</TableCell>
    </PersonRow>
  );

  renderNoResultsPlaceholder = verb => (
    <TableRow>
      <TableCell colSpan={2}>{`No one ${verb} this post.`}</TableCell>
    </TableRow>
  );

  renderActivityPost() {
    const {
      content,
      currentUserId,
      files,
      id,
      images,
      createdAt,
      user,
      userRespected,
      video,
    } = this.props;

    if (!id) {
      return null;
    }

    return (
      <ArcView>
        {this.props.isRequesting ? (
          <ArcView marginTop="32">{renderPostPlaceholder()}</ArcView>
        ) : (
          <ArcView marginTop="24">
            <NewsfeedActivityCard
              acknowledgedState={{ 123: 'unpinned' }}
              activityRespects={{}}
              activityViews={{}}
              activityAcknowledged={{}}
              content={content}
              currentUserId={currentUserId}
              files={files}
              id={id}
              images={images}
              createdAt={createdAt}
              userBadge={user.badge}
              userId={user.id}
              userImage={user.image}
              userName={user.name}
              userRespected={userRespected}
              video={video}
              showRespects={false}
              activityHistoryView
            />
          </ArcView>
        )}
      </ArcView>
    );
  }

  renderViewsResults() {
    const { views } = this.props;
    const hasResults = views.length >= 1;

    return hasResults
      ? views.map(this.renderPersonRow)
      : this.renderNoResultsPlaceholder('viewed');
  }

  renderViewsTable() {
    return (
      <Table>
        <TableHead>
          <TableRow>{headings.map(this.renderColumnHeadings)}</TableRow>
        </TableHead>
        <TableBody>
          {this.props.isRequesting ? placeholders : this.renderViewsResults()}
        </TableBody>
      </Table>
    );
  }

  renderAudienceResults() {
    const { audience } = this.props;
    const hasResults = audience.length >= 1;

    return hasResults
      ? audience.map(this.renderAudienceRow)
      : this.renderNoResultsPlaceholder('was in the audience selected for');
  }

  renderAudienceTable() {
    return (
      <Table>
        <TableHead>
          <TableRow>{audienceHeadings.map(this.renderColumnHeadings)}</TableRow>
        </TableHead>
        <TableBody>
          {this.props.isRequesting
            ? placeholders
            : this.renderAudienceResults()}
        </TableBody>
      </Table>
    );
  }

  renderReactionResults() {
    const { reactions } = this.props;
    const hasResults = reactions.length >= 1;

    return hasResults
      ? reactions.map(this.renderReactionRow)
      : this.renderNoResultsPlaceholder('reacted to');
  }

  renderReactionsTable() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>People</TableCell>
            <TableCell>Reaction</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.isRequesting
            ? placeholders
            : this.renderReactionResults()}
        </TableBody>
      </Table>
    );
  }

  renderAcknowledgementsResults() {
    const { acknowledgements } = this.props;
    const hasResults = acknowledgements.length >= 1;

    return hasResults
      ? acknowledgements.map(this.renderPersonRow)
      : this.renderNoResultsPlaceholder('acknowledged');
  }

  renderAcknowledgementsTable() {
    return (
      <Table>
        <TableHead>
          <TableRow>{headings.map(this.renderColumnHeadings)}</TableRow>
        </TableHead>
        <TableBody>
          {this.props.isRequesting
            ? placeholders
            : this.renderAcknowledgementsResults()}
        </TableBody>
      </Table>
    );
  }

  renderCounter = (label, type) => (
    <ArcView row align="center">
      {label}
      {!!type && !!type.length && ` (${type.length})`}
    </ArcView>
  );

  renderContent() {
    const { tabValue } = this.state;

    if (this.props.pinned && tabValue === 4) {
      return this.renderAcknowledgementsTable();
    }

    switch (this.state.tabValue) {
      case 0:
        return this.renderActivityPost();
      case 1:
        return this.renderAudienceTable();
      case 2:
        return this.renderViewsTable();
      case 3:
        return this.renderReactionsTable();
      default:
        return null;
    }
  }

  render() {
    const { acknowledgements, audience, pinned, reactions, views } = this.props;

    const { tabValue } = this.state;

    return (
      <ArcResponsiveDialog
        open={this.props.open}
        onClose={this.props.onClose}
        fullWidth
        PaperProps={PAPER_PROPS}
      >
        <DialogTitle>
          <ArcView row align="center">
            <ArcView>{'Post Details'}</ArcView>

            <ArcView spacer />

            <ArcButton
              variant="outlined"
              color="primary"
              size="small"
              onClick={this.handleClickShowPost}
            >
              <ArcView marginRight="4">{'Go to post'}</ArcView>
              <ArrowForward fontSize="small" />
            </ArcButton>
          </ArcView>
        </DialogTitle>
        <DialogContent>
          <ArcView>
            <DialogTabs
              value={tabValue}
              onChange={this.updateTab}
              variant="scrollable"
              scrollButtons="on"
            >
              <DialogTab label={'Post'} />
              <DialogTab label={this.renderCounter('Audience', audience)} />
              <DialogTab label={this.renderCounter('Views', views)} />
              <DialogTab label={this.renderCounter('Reactions', reactions)} />
              {pinned && (
                <DialogTab
                  label={this.renderCounter(
                    'Acknowledgements',
                    acknowledgements,
                  )}
                />
              )}
            </DialogTabs>

            <ArcView>{this.renderContent()}</ArcView>
          </ArcView>
        </DialogContent>
        <ActivityDialogActions>
          <ArcButton onClick={this.props.onClose}>{'Close'}</ArcButton>
        </ActivityDialogActions>
      </ArcResponsiveDialog>
    );
  }
}

export default ActivityDetailsDialog;
