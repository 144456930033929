import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/tokens';

const initialState = '';

const handlers = {
  [types.MANAGE_TOKENS_SET_TOP_UP_URL]: (state, action) => action.payload || '',
};

const topUpUrl = createReducer(initialState, handlers);

export default topUpUrl;

export const getTopUpUrl = state => state.manage.tokens.topUpUrl;
