export const TAB_TYPES = {
  CONTACT: 'contact',
  PERSONAL: 'personal',
  SECURITY: 'security',
};

export const LABELS_BY_TAB_TYPE = {
  [TAB_TYPES.CONTACT]: 'Contact',
  [TAB_TYPES.PERSONAL]: 'Personal',
  [TAB_TYPES.SECURITY]: 'Security',
};

export const TABS = [TAB_TYPES.CONTACT, TAB_TYPES.PERSONAL, TAB_TYPES.SECURITY];
