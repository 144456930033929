import { getAsyncStorageRoute } from 'arcade-frontend-core/src/helpers/asyncStorage';
import actionIsValidRoute from 'arcade-frontend-core/src/helpers/actionIsValidRoute';
import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';


export default async function navigateToEntryRoute(location, dispatch) {
  const currentRoute = await getAsyncStorageRoute();

  const isEntryRoute = location.type === ROUTE_TYPES.ROUTE_BASE
      || location.type === ROUTE_TYPES.ROUTE_LOGIN
      || location.type === ROUTE_TYPES.ROUTE_LOGIN_VIA_LINK
      || location.type === ROUTE_TYPES.NOT_FOUND;

  let nextRoute = isEntryRoute
    ? { type: ROUTE_TYPES.ROUTE_NEWSFEED }
    : location;

  if (isEntryRoute && actionIsValidRoute(currentRoute)) {
    nextRoute = currentRoute;
  }

  // This check is to allow for a push notification to override our next route
  // It is set in `angular/arcade.material/app.run`

  if (!window.openedFromPush) {
    const { type, payload, query } = nextRoute;
    dispatch({ type, payload, query });
  }

  if (window.componentHandler) {
    window.componentHandler.upgradeAllRegistered();
  }

  if (window.ngRootScope) {
    window.ngRootScope.$broadcast('login');
  }

  if (window.arcPostMessage) {
    window.arcPostMessage('native.appReady');
  }
}
