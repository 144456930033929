import * as types from './types';

// export const apiManagePermissionsIndexRequest = () => ({
//   type: types.MANAGE_PERMISSIONS_INDEX.REQUEST,
//   payload: {},
// });

export const apiManagePeopleIndexRequest = () => ({
  type: types.MANAGE_PEOPLE_INDEX.REQUEST,
  payload: {},
});

export const apiManagePeoplePermissionsIndexRequest = id => ({
  type: types.MANAGE_PEOPLE_PERMISSIONS_INDEX.REQUEST,
  payload: {
    id,
  },
});

export const apiManagePeoplePermissionsUpdateRequest = (id, permissions) => ({
  type: types.MANAGE_PEOPLE_PERMISSIONS_UPDATE.REQUEST,
  payload: {
    id,
    permissions,
  },
});

export const apiManagePeopleProfileRequest = id => ({
  type: types.MANAGE_PEOPLE_PROFILE.REQUEST,
  payload: {
    id,
  },
});

export const apiManagePeopleProfileUpdateRequest = person => ({
  type: types.MANAGE_PEOPLE_PROFILE_UPDATE.REQUEST,
  payload: {
    person,
  },
});

export const resetPassword = id => ({
  type: types.RESET_PASSWORD.REQUEST,
  payload: {
    id,
  },
});

export const lockAccount = id => ({
  type: types.LOCK_ACCOUNT.REQUEST,
  payload: {
    id,
  },
});

export const unlockAccount = id => ({
  type: types.UNLOCK_ACCOUNT.REQUEST,
  payload: {
    id,
  },
});

export const resetRequestState = () => ({
  type: types.MANAGE_PEOPLE_PROFILE_UPDATE.RESET,
  payload: {},
});
