import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { resources } from 'arcade-frontend-core/src/resources/users/';
import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import ManagePeopleProfileModal from 'arcade-frontend-features/src/components/manage/people/ManagePeopleProfileModal';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import {
  getCurrentUser,
  getCurrentUserPermission,
} from 'arcade-frontend-core/src/reducers/user';
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';

import { resources as teamResources } from '../../resources/teams';

const ANIMATION_DELAY = 500;
const ERROR_ANIMATION_DELAY = 3000;

function PeopleEditProfileModalContainer({
  id,
  open,
  onClose,
  onUpdateProfileSuccess,
}) {
  const currentUser = useSelector(getCurrentUser);
  const apiGetUserProfile = useResourceAction(resources.apiGetUserProfile);
  const apiPutUserProfile = useResourceAction(resources.apiPutUserProfile);
  const apiPutUserResetPassword = useResourceAction(
    resources.apiPutUserResetPassword,
  );
  const apiPutUserLockAccount = useResourceAction(
    resources.apiPutUserLockAccount,
  );
  const apiPutUserUnlockAccount = useResourceAction(
    resources.apiPutUserUnlockAccount,
  );
  const apiGetCompanyIdentifierExplainer = useResourceAction(
    resources.apiGetCompanyIdentifierExplainer,
  );
  const apiGetTeams = useResourceAction(teamResources.apiGetTeams);
  const canSetRecognitionStarCount = useSelector(state =>
    getCurrentUserPermission(state, PERMISSIONS.SET_RECOGNITION_STAR_COUNT),
  );
  const hasRecognitionStarCountFeature = useFeatureFlag(
    FEATURE_FLAGS.SET_TOKEN_COUNT,
  );
  const hasFeatureRemainingSeats = useFeatureFlag(
    FEATURE_FLAGS.USER_COUNT_LIMIT,
  );

  const [remainingSeats, setRemainingSeats] = useState();
  const [refreshOnClose, setRefreshOnClose] = useState(false);

  useEffect(() => {
    if (open && !!id) {
      apiGetUserProfile
        .requestData({
          resources: [{ id }],
        })
        .catch(global.noop);
    }
  }, [id, open]);
  useEffect(() => {
    if (!currentUser.endpoint) {
      return;
    }

    apiGetCompanyIdentifierExplainer.requestData().catch(() => {
      apiGetCompanyIdentifierExplainer.onStatusReset();
    });

    apiGetTeams.requestData().catch(() => {
      apiGetTeams.onStatusReset();
    });

    fetch(`${currentUser.endpoint}/v2/entities/remaining_seats`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-User-Token': currentUser.token,
        'X-User-Email': currentUser.email,
      },
    })
      .then(response => response.json())
      .then(response => setRemainingSeats(response.remaining_seats));
  }, [currentUser]);

  const handleClickEditProfile = user => {
    apiPutUserProfile
      .requestData({
        resources: [
          {
            id: user.id,
            user: {
              firstname: user.firstName,
              lastname: user.lastName,
              company_identifier: user.companyIdentifier,
              email: user.email,
              recognition_star_count: user.recognitionStarCount,
            },
          },
        ],
      })
      .then(() => {
        setTimeout(() => {
          onClose();
          onUpdateProfileSuccess();
          apiPutUserProfile.onStatusReset();
        }, ANIMATION_DELAY);
      })
      .catch(global.noop);
  };

  const handleClickLockAccount = () => {
    apiPutUserLockAccount.onErrorReset();
    apiPutUserUnlockAccount.onErrorReset();

    apiPutUserLockAccount
      .requestData({ resources: [{ id }] })
      .then(() => {
        setTimeout(() => {
          setRefreshOnClose(true);
          apiPutUserLockAccount.onStatusReset();
          apiGetUserProfile
            .requestData({
              resources: [{ id }],
            })
            .catch(global.noop);
        }, ANIMATION_DELAY);
      })
      .catch(() => {
        setTimeout(() => {
          apiPutUserLockAccount.onStatusReset();
          apiPutUserLockAccount.onErrorReset();
        }, ERROR_ANIMATION_DELAY);
      });
  };

  const handleClickUnlockAccount = () => {
    apiPutUserLockAccount.onErrorReset();
    apiPutUserUnlockAccount.onErrorReset();

    apiPutUserUnlockAccount
      .requestData({ resources: [{ id }] })
      .then(() => {
        setTimeout(() => {
          setRefreshOnClose(true);
          apiPutUserUnlockAccount.onStatusReset();
          apiGetUserProfile.requestData({
            resources: [{ id }],
          });
        }, ANIMATION_DELAY);
      })
      .catch(() => {
        setTimeout(() => {
          apiPutUserUnlockAccount.onStatusReset();
          apiPutUserUnlockAccount.onErrorReset();
        }, ERROR_ANIMATION_DELAY);
      });
  };

  const handleClickReset = () => {
    apiPutUserResetPassword
      .requestData({ resources: [{ id }] })
      .then(() => {
        setTimeout(() => {
          apiPutUserResetPassword.onStatusReset();
        }, ERROR_ANIMATION_DELAY);
      })
      .catch(global.noop);
  };

  const handleClickGetUserProfile = () => {
    apiGetUserProfile.onErrorReset();
    apiGetUserProfile
      .requestData({
        resources: [{ id }],
      })
      .catch(global.noop);
  };

  const handleClose = () => {
    onClose();
    apiPutUserLockAccount.onErrorReset();
    apiPutUserUnlockAccount.onErrorReset();

    if (refreshOnClose) {
      onUpdateProfileSuccess();
    }
  };

  return (
    <ManagePeopleProfileModal
      open={open}
      onClose={handleClose}
      person={
        apiGetUserProfile.hasData ? apiGetUserProfile.data.resources[0] : {}
      }
      onResetPassword={handleClickReset}
      onLockAccount={handleClickLockAccount}
      onUnlockAccount={handleClickUnlockAccount}
      onClickEdit={handleClickEditProfile}
      onGetUserProfile={handleClickGetUserProfile}
      canSetRecognitionStarCount={
        canSetRecognitionStarCount && hasRecognitionStarCountFeature
      }
      statusGetUserProfile={apiGetUserProfile.status}
      statusLockUserProfile={apiPutUserLockAccount.status}
      statusUnlockUserProfile={apiPutUserUnlockAccount.status}
      statusResetUserPassword={apiPutUserResetPassword.status}
      statusUpdateUserProfile={apiPutUserProfile.status}
      hasDataGetUserProfile={apiGetUserProfile.hasData}
      hasErrorLockUnlockAccount={
        apiPutUserLockAccount.hasError || apiPutUserUnlockAccount.hasError
      }
      hasErrorGetUserProfile={apiGetUserProfile.hasError}
      hasFeatureRemainingSeats={hasFeatureRemainingSeats}
      remainingSeats={remainingSeats}
    />
  );
}

PeopleEditProfileModalContainer.displayName = 'PeopleEditProfileModalContainer';

PeopleEditProfileModalContainer.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdateProfileSuccess: PropTypes.func,
};

PeopleEditProfileModalContainer.defaultProps = {
  id: '',
  open: false,
  onClose: global.noop,
  onUpdateProfileSuccess: global.noop,
};

export default PeopleEditProfileModalContainer;
