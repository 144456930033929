import React from 'react';
import Close from '@material-ui/icons/Close';

import ArcIconButton from '../../elements/ArcIconButton';
import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import ArcText from '../../primitives/ArcText';
import { createWithStyles } from '../../styles';

const styles = {
  Container: () => ({
    root: {
      paddingLeft: 16,
    },
  }),

  TitleText: theme => ({
    root: {
      ...theme.typography.subheading,
    },
  }),

  PercentText: theme => ({
    root: {
      ...theme.typography.caption,
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);
const TitleText = createWithStyles(styles.TitleText)(ArcText);
const PercentText = createWithStyles(styles.PercentText)(ArcText);

const styleIconButton = {
  height: 72,
};

const styleTextContainer = {
  marginTop: 16,
};

/** **Description**
  *
  * Renders a title for `<ArcChecklist />`
  *
*/

class ArcChecklistTitle extends React.PureComponent {
  static displayName = 'ArcChecklistTitle';

  static propTypes = {
    title: ArcPropTypes.string.isRequired,
    percent: ArcPropTypes.number.isRequired,

    onClose: ArcPropTypes.func,
    hasClose: ArcPropTypes.bool,
  };

  static defaultProps = {
    onClose: ArcPropTypes.noop,
    hasClose: false,
  };

  render() {
    return (
      <Container
        row
        align="center"
        justify="space-between"
      >
        <ArcView style={styleTextContainer}>
          <TitleText>{this.props.title}</TitleText>
          <PercentText>{this.props.percent.toFixed(0)}% complete</PercentText>
        </ArcView>

        {this.props.hasClose && (
          <ArcIconButton onClick={this.props.onClose} style={styleIconButton}>
            <Close />
          </ArcIconButton>
        )}
      </Container>
    );
  }
}

export default ArcChecklistTitle;
