import { gql } from 'arcade-frontend-core/src/helpers/apollo';

const NOTIFICATIONS_MUTATION = gql`
  mutation addNotification(
    $videoType: VideoType
    $title: String!
    $content: String!
    $dialogType: DialogType!
    $imageUrl: String!
  ) {
    addNotification(
      videoType: $videoType
      title: $title
      content: $content
      dialogType: $dialogType
      imageUrl: $imageUrl
    ) {
      id
      videoType
      title
      content
      dialogType
      imageUrl
    }
  }
`;

export default NOTIFICATIONS_MUTATION;
