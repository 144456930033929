import React from 'react';
import PropTypes from 'prop-types';

import ArcImage from '../../primitives/ArcImage';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';

import baseSvg from './assets/base.svg';
import baseOpenSvg from './assets/base-open.svg';

import commonSvg from './assets/common.svg';
import commonOpenSvg from './assets/common-open.svg';

import epicSvg from './assets/epic.svg';
import epicOpenSvg from './assets/epic-open.svg';

import rareSvg from './assets/rare.svg';
import rareOpenSvg from './assets/rare-open.svg';

import legendarySvg from './assets/legendary.svg';
import legendaryOpenSvg from './assets/legendary-open.svg';


const VARIANTS = {
  BASE: 'base',
  COMMON: 'common',
  RARE: 'rare',
  EPIC: 'epic',
  LEGENDARY: 'legendary',
};

const SRC_BY_VARIANT = {
  [VARIANTS.BASE]: baseSvg,
  [VARIANTS.COMMON]: commonSvg,
  [VARIANTS.RARE]: rareSvg,
  [VARIANTS.EPIC]: epicSvg,
  [VARIANTS.LEGENDARY]: legendarySvg,
};

const OPEN_SRC_BY_VARIANT = {
  [VARIANTS.BASE]: baseOpenSvg,
  [VARIANTS.COMMON]: commonOpenSvg,
  [VARIANTS.RARE]: rareOpenSvg,
  [VARIANTS.EPIC]: epicOpenSvg,
  [VARIANTS.LEGENDARY]: legendaryOpenSvg,
};

const LABEL_BY_VARIANT = {
  [VARIANTS.BASE]: 'Base',
  [VARIANTS.COMMON]: 'Common',
  [VARIANTS.RARE]: 'Rare',
  [VARIANTS.EPIC]: 'Epic',
  [VARIANTS.LEGENDARY]: 'Legendary',
};

function ArcChestImage({
  label,
  labelTextSize,
  open,
  size,
  variant,
  ...props
}) {
  const lowercaseVariant = variant.toLowerCase();

  return (
    <ArcView
      align="center"
      {...props}
    >
      <ArcText
        size={labelTextSize}
      >
        {typeof label === typeof undefined ? LABEL_BY_VARIANT[lowercaseVariant] : label}
      </ArcText>

      <ArcImage
        src={open ? OPEN_SRC_BY_VARIANT[lowercaseVariant] : SRC_BY_VARIANT[lowercaseVariant]}
        height={size}
        width={size}
      />
    </ArcView>
  );
}

ArcChestImage.propTypes = {
  label: PropTypes.string,
  labelTextSize: PropTypes.string,
  open: PropTypes.bool,
  size: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

ArcChestImage.defaultProps = {
  label: undefined,
  labelTextSize: '12px',
  open: false,
  size: '56px',
  variant: VARIANTS.COMMON,
};

ArcChestImage.displayName = 'ArcChestImage';

ArcChestImage.VARIANTS = VARIANTS;

export default ArcChestImage;
