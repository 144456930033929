import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CardGiftcardTwoToneIcon from '@material-ui/icons/CardGiftcardTwoTone';

import withResourceAction from 'arcade-frontend-core/src/helpers/withResourceAction';
import {
  ArcNoResultsMessage,
  ArcView,
  createWithStyles,
} from 'arcade-frontend-ui';
import { ArcFormDateRangePickerContainer } from 'arcade-frontend-widgets/src/containers/ArcFormDateRangePickerContainer';

import { resources } from '../resources/rewardPurchases';
import RewardHistoryList from '../components/RewardHistoryList';
import {
  getRewardPurchaseStatus,
  getRewardPurchases,
  getMeta,
} from '../reducers/rewardPurchases';

const styles = {
  NoResultsContainer: theme => ({
    root: {
      borderWidth: 1,
      borderColor: theme.palette.grey[300],
      borderStyle: 'solid',
      backgroundColor: theme.palette.common.white,
    },
  }),

  Header: theme => ({
    root: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1),
    },
  }),

  DateRangeWrapper: theme => ({
    root: {
      paddingRight: theme.spacing(1),
    },
  }),
};

const NoResultsContainer = createWithStyles(styles.NoResultsContainer)(ArcView);
const Header = createWithStyles(styles.Header)(ArcView);
const DateRangeWrapper = createWithStyles(styles.DateRangeWrapper)(ArcView);

class RewardHistoryListContainer extends React.PureComponent {
  static displayName = 'RewardHistoryListContainer';

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    onStatusReset: PropTypes.func,
    requestData: PropTypes.func,
    dispatch: PropTypes.func,
    meta: PropTypes.objectOf(PropTypes.any),
    status: PropTypes.shape({
      idle: PropTypes.bool,
      pending: PropTypes.bool,
      failed: PropTypes.bool,
      succeeded: PropTypes.bool,
    }),
  };

  static defaultProps = {
    data: [],
    onStatusReset: global.noop,
    requestData: global.noop,
    dispatch: global.noop,
    meta: {},
    status: {},
  };

  state = {
    page: 0,
    rowsPerPage: 50,
    dateRange: {
      type: 'time period',
      timePeriod: 'last_30_days',
    },
  };

  componentDidMount() {
    this.requestRewardData();
  }

  requestRewardData = () => {
    const { page, rowsPerPage, dateRange } = this.state;

    return this.props
      .requestData({
        resources: [
          {
            page,
            rowsPerPage,
            ...dateRange,
          },
        ],
      })
      .then(resp => {
        this.props.dispatch({
          type: 'UPDATE_RESOURCES',
          resourceType: 'rewardPurchases',
          meta: {
            rewardPurchases: {
              totalPurchases: resp.data
                ? resp.data.meta.index.totalPurchases
                : null,
            },
          },
        });
        this.props.onStatusReset();
      });
  };

  handleDateRangeChange = ({ type, timePeriod, fromDate, toDate }) => {
    if (type === 'time period') {
      this.setState(
        {
          dateRange: {
            type,
            timePeriod,
            fromDate: undefined,
            toDate: undefined,
          },
        },
        this.requestRewardData,
      );
    } else {
      this.setState(
        {
          dateRange: {
            type,
            timePeriod: undefined,
            fromDate,
            toDate,
          },
        },
        this.requestRewardData,
      );
    }
  };

  handleOnChangePage = (_e, page) => {
    this.setState({ page }, this.requestRewardData);
  };

  handleOnChangeRowsPerPage = rowsPerPage => {
    this.setState({ rowsPerPage }, this.requestRewardData);
  };

  render() {
    const { data, status, meta } = this.props;
    const { dateRange } = this.state;
    const rewardPurchaseCount =
      meta.totalPurchases && meta.totalPurchases > 0
        ? meta.totalPurchases
        : data.length;
    const hasNoResults = data.length === 0;

    return (
      <>
        <Header row>
          <ArcView row flexGrow={1} />

          <DateRangeWrapper>
            <ArcFormDateRangePickerContainer
              value={dateRange}
              onChange={this.handleDateRangeChange}
            />
          </DateRangeWrapper>
        </Header>

        {hasNoResults ? (
          <NoResultsContainer
            fullWidth
            fullHeight
            padding={16}
            overflow="hidden"
          >
            <ArcNoResultsMessage
              iconComponent={CardGiftcardTwoToneIcon}
              iconStyles={{
                fontSize: 192,
                transform: 'translate(-4.5%, -54%)',
              }}
              header="No reward purchases"
              subheader="Head to the shop to redeem some goodies"
            />
          </NoResultsContainer>
        ) : (
          <RewardHistoryList
            rewardPurchaseCount={rewardPurchaseCount}
            rewardPurchases={data}
            status={status}
            onChangeRowsPerPage={this.handleOnChangeRowsPerPage}
            onChangePage={this.handleOnChangePage}
          />
        )}
      </>
    );
  }
}

const getState = (state, props) => ({
  data: getRewardPurchases(state),
  meta: getMeta(state),
  status: getRewardPurchaseStatus(state, props),
  ...props,
});

export default compose(
  connect(getState),
  withResourceAction(resources.apiGetRewardPurchases),
)(RewardHistoryListContainer);
