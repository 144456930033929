import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../actions/audiences';

const initialState = [];

const handleAudienceLoadSuccess = (state, action) => {
  if (action.payload.data) {
    return action.payload.data.map(item => item.id);
  }

  return state;
};

const handlers = {
  [types.AUDIENCES_GET_AUDIENCES.SUCCESS]: handleAudienceLoadSuccess,
};

export default createReducer(initialState, handlers);

export const getIds = state => state.audiences.ids;
