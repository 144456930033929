import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  ArcManageChecklist,
  ArcSubheading,
  ArcPaper,
  ArcPropTypes,
  ArcScroll,
  ArcView,
  ArcViewHeader,

  Grid,
} from 'arcade-frontend-ui';

import { routes } from '../../../actions/manage/checklists';

const styleContainer = {
  padding: 16,
};

const checklists = [
  {
    id: '0',
    title: 'Onboarding',
    items: [
      {
        value: 'send_message',
        label: 'Send a message',
        percent: 100,
      },
      {
        value: 'equip_badge',
        label: 'Equip a badge',
        percent: 36,
      },
      {
        value: 'post_newsfeed',
        label: 'Post on the newsfeed',
        percent: 24,
      },
      {
        value: 'open_chest',
        label: 'Open a chest',
        percent: 55,
      },
      {
        value: 'add_picture',
        label: 'Update your profile picture',
        percent: 76,
      },
    ],
  },
];

class ManageChecklistsIndexContainer extends React.PureComponent {
  static propTypes = {
    routeManageChecklistsCreate: ArcPropTypes.func.isRequired,
    routeManageChecklistsShow: ArcPropTypes.func.isRequired,
    // templates: ArcPropTypes.manageGameTemplates.isRequired,
    // createdGames: ArcPropTypes.ids.isRequired,
  };

  handleClickCreate = () => {
    this.props.routeManageChecklistsCreate();
  };

  renderHeader() {
    return (
      <ArcViewHeader
        heading="1 Checklist"
        labelAction="Create Checklist"
        onClickAction={this.handleClickCreate}
      />
    );
  }

  render() {
    return (
      <ArcScroll>
        {this.renderHeader()}
        <ArcView style={styleContainer} className="animated fadeIn">
          <Grid container spacing={1}>
            {checklists.map(checklist => (
              <Grid key={checklist.id} item xs={12} sm={6} md={4}>
                <ArcSubheading>{checklist.title}</ArcSubheading>
                <ArcPaper square>
                  <ArcManageChecklist
                    {...checklist}
                    onClick={this.props.routeManageChecklistsShow}
                  />
                </ArcPaper>
              </Grid>
            ))}
          </Grid>
        </ArcView>
      </ArcScroll>
    );
  }
}

const mapStateToProps = state => ({
  templates: state.manage.games.templates,
  // createdGames: fromManageGamesById.getManageGamesSortedNameState(state),
  // manageGamesById: state.manage.games.manageGamesById,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  routeManageChecklistsShow: routes.routeManageChecklistsShow,
  routeManageChecklistsCreate: routes.routeManageChecklistsCreate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManageChecklistsIndexContainer);
