export const formatCurrency = currency =>
  window.Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(currency);

export const formatNumber = number =>
  window.Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);

export const formatByGameUnit = (unit, number) =>
  unit === '$' ? formatCurrency(number) : formatNumber(number);

export function toFixed(number, fixed = 2) {
  const regexp = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);

  return number.toString().match(regexp)[0];
}
