import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { throttle } from 'throttle-debounce';

import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import {
  ArcConfirmDialog,
  ArcView,
  ArcButton,
  ArcResponsiveDialog,
  ArcTextField,
  createWithStyles,
} from 'arcade-frontend-ui';

import ArcAudienceBrowser from '../ArcAudienceBrowser';
import ArcAudienceConfirmation from '../ArcAudienceConfirmation';
import ArcAudienceMember from '../ArcAudienceMember';
import ArcAudiencePreSelection from '../ArcAudiencePreSelection';

const SEARCH_ANIMATION_DELAY = 500;

const PAPER_PROPS = {
  style: {
    width: '100%',
    height: '100%',
  },
};

const textfieldInputProps = {
  style: {
    padding: '9px 14px 8px 8px',
  },
};

const styles = {
  SearchContainer: theme => ({
    root: {
      width: '100%',
      left: 0,

      [theme.breakpoints.up('sm')]: {
        width: '50%',
        left: 'initial',
        right: 0,
      },
    },
  }),
};

const SearchContainer = createWithStyles(styles.SearchContainer)(ArcView);

class ArcAudienceDialog extends React.PureComponent {
  static displayName = 'ArcAudienceDialog';

  static propTypes = {
    breadcrumbItems: PropTypes.arrayOf(PropTypes.shape({})),
    browserData: PropTypes.arrayOf(PropTypes.shape({})),
    browserRequestStatus: PropTypes.string,
    confirmDialogContent: PropTypes.node,
    confirmationRequestStatus: PropTypes.string,
    confirmationTitle: PropTypes.string,
    confirmationData: PropTypes.arrayOf(PropTypes.shape({})),
    currentEntity: PropTypes.shape({}),
    currentLevel: PropTypes.string,
    currentUserId: PropTypes.string,
    disableSelf: PropTypes.bool,
    entitiesByUuid: PropTypes.objectOf(
      PropTypes.shape({
        children: PropTypes.arrayOf(PropTypes.shape),
        uuid: PropTypes.string.isRequired,
      }),
    ),
    excludedEntitiesByParentUuid: PropTypes.objectOf(
      PropTypes.objectOf(PropTypes.bool),
    ),
    fadeDirection: PropTypes.string,
    fadeOut: PropTypes.bool,
    hasFieldName: PropTypes.bool,
    namePlaceholder: PropTypes.string,
    nameValue: PropTypes.string,
    onAdd: PropTypes.func,
    onAddGroup: PropTypes.func,
    onBack: PropTypes.func,
    onBreadcrumbsClick: PropTypes.func,
    onBrowserItemChange: PropTypes.func,
    onBrowserItemClick: PropTypes.func,
    onClose: PropTypes.func,
    onNameChange: PropTypes.func,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    onRemoveByEntities: PropTypes.func,
    onSearch: PropTypes.func,
    onSearchToggle: PropTypes.func,
    onSubmit: PropTypes.func,
    open: PropTypes.bool,
    partiallySelectedEntitiesByUuid: PropTypes.objectOf(PropTypes.bool),
    placeholderText: PropTypes.node,
    peopleOnly: PropTypes.bool,
    selectedEntitiesByUuid: PropTypes.objectOf(PropTypes.bool),
    submitButtonColor: PropTypes.string,
    submitButtonLabel: PropTypes.string,
    title: PropTypes.string,
  };

  static defaultProps = {
    breadcrumbItems: [],
    browserData: [],
    browserRequestStatus: '',
    confirmationRequestStatus: '',
    confirmationTitle: '',
    confirmationData: [],
    currentEntity: {},
    currentLevel: ArcAudienceBrowser.LEVELS.TOP,
    currentUserId: '',
    confirmDialogContent: 'You will lose this unsaved audience',
    disableSelf: false,
    entitiesByUuid: {},
    excludedEntitiesByParentUuid: {},
    fadeDirection: '',
    fadeOut: false,
    hasFieldName: true,
    namePlaceholder: '',
    nameValue: '',
    onAdd: global.noop,
    onAddGroup: global.noop,
    onBack: global.noop,
    onBreadcrumbsClick: global.noop,
    onBrowserItemChange: global.noop,
    onBrowserItemClick: global.noop,
    onClose: global.noop,
    onNameChange: global.noop,
    onRemove: global.noop,
    onRemoveAll: global.noop,
    onRemoveByEntities: global.noop,
    onSearch: global.noop,
    onSearchToggle: global.noop,
    onSubmit: global.noop,
    open: false,
    partiallySelectedEntitiesByUuid: {},
    peopleOnly: false,
    placeholderText: '',
    selectedEntitiesByUuid: {},
    submitButtonColor: undefined,
    submitButtonLabel: undefined,
    title: 'Custom Audience',
  };

  state = this.initialState;

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedEntitiesByUuid !== this.props.selectedEntitiesByUuid
    ) {
      this.setSelectedEntitiesByUuid(this.props.selectedEntitiesByUuid);
    }

    if (prevProps.open !== this.props.open) {
      if (!this.props.open) {
        this.resetState();
      }
    }
  }

  onSearch = throttle(300, () => {
    this.props.onSearch(this.state.searchValue);
  });

  onSearchToggle = () => this.props.onSearchToggle(this.state.isSearching);

  get initialState() {
    return {
      isClosing: false,
      isPreSelecting: false,
      isSearching: false,
      isViewingPicks: false,
      preSelectingEntitiesByUuid: {},
      searchValue: '',
      searchVisible: false,
      selectedEntitiesByUuid: this.props.selectedEntitiesByUuid,
    };
  }

  setIsClosing = isClosing => this.setState({ isClosing });

  setIsPreselecting = isPreSelecting => this.setState({ isPreSelecting });

  setIsSearching = isSearching =>
    this.setState({ isSearching }, this.onSearchToggle);

  setIsViewingPicks = isViewingPicks => this.setState({ isViewingPicks });

  setPreselectingEntity = entity => {
    const uuid = `${entity.type}-${entity.id}`;

    const preSelectingEntitiesByUuid = {
      ...this.state.preSelectingEntitiesByUuid,
      [uuid]: !this.state.preSelectingEntitiesByUuid[uuid],
    };

    this.setState(
      {
        preSelectingEntitiesByUuid,
      },
      this.updateIsPreSelecting,
    );
  };

  setSearchValue = searchValue => this.setState({ searchValue }, this.onSearch);

  setSearchVisible = searchVisible => this.setState({ searchVisible });

  setSelectedEntity = entity => {
    const uuid = `${entity.type}-${entity.id}`;

    this.setState({
      selectedEntitiesByUuid: {
        ...this.props.selectedEntitiesByUuid,
        ...this.state.selectedEntitiesByUuid,
        [uuid]: !this.state.selectedEntitiesByUuid[uuid],
      },
    });
  };

  setSelectedEntitiesByUuid = selectedEntitiesByUuid =>
    this.setState({ selectedEntitiesByUuid });

  clearPreSelections = () => {
    this.setIsPreselecting(false);
    this.setState({
      preSelectingEntitiesByUuid: {},
    });
  };

  resetState = () => this.setState({ ...this.initialState });

  toggleSelectAllGroups = (toggle = true) => {
    if (toggle || this.state.isPreSelecting) {
      const preSelectingEntitiesByUuid = {};

      this.props.browserData.forEach(item => {
        preSelectingEntitiesByUuid[item.uuid] = toggle;
      });

      this.setState({
        preSelectingEntitiesByUuid,
        isPreSelecting: toggle,
      });
    } else {
      this.props.onRemoveByEntities(this.props.browserData);
    }
  };

  toggleSelectAllPeople = (toggle = true) => {
    const nextSelectedEntitiesByUuid = {};

    this.props.browserData.forEach(item => {
      if (this.props.peopleOnly) {
        let nextEntity = this.props.entitiesByUuid[item.uuid];

        const getRecursivelyNestedPeople = () => {
          if (nextEntity.type === ArcAudienceMember.TYPES.PERSON) {
            nextSelectedEntitiesByUuid[item.uuid] = toggle;
          }

          if (nextEntity.users) {
            nextEntity.users.forEach(user => {
              nextSelectedEntitiesByUuid[`person-${user.id}`] = toggle;
            });
          }

          if (!nextEntity.children) {
            return;
          }

          nextEntity.children.forEach(childUuid => {
            nextEntity = this.props.entitiesByUuid[childUuid];

            getRecursivelyNestedPeople();
          });
        };

        getRecursivelyNestedPeople();
      } else {
        nextSelectedEntitiesByUuid[item.uuid] = toggle;
      }
    });

    this.props.onAdd(nextSelectedEntitiesByUuid);
  };

  updateIsPreSelecting = () => {
    const isPreSelecting = !!Object.values(
      this.state.preSelectingEntitiesByUuid,
    ).filter(Boolean).length;

    this.setIsPreselecting(isPreSelecting);
  };

  handleBrowserItemChange = item => {
    if (item.type === ArcAudienceMember.TYPES.PERSON) {
      this.props.onBrowserItemChange(item);
      this.setSelectedEntity(item);
    } else if (this.props.peopleOnly) {
      this.props.onBrowserItemClick(item);
    } else {
      this.setPreselectingEntity(item);
    }
  };

  handleCancel = () => this.setIsClosing(true);

  handleCancelClose = () => this.setIsClosing(false);

  handleConfirmationBack = () => this.setIsViewingPicks(false);

  handlePreSelectionBack = () => this.clearPreSelections();

  handlePreSelectionItemChange = item => {
    if (item.type === ArcAudienceMember.TYPES.PERSON) {
      this.setSelectedEntity(item);
    }
  };

  handlePreSelectionSelection = (
    selectedEntitiesByUuid,
    partiallySelectedEntitiesByUuid,
    entitiesByPreSelectedUuid,
  ) => {
    this.setIsPreselecting(false);

    let nextSelectedEntitiesByUuid = {
      ...this.state.selectedEntitiesByUuid,
      ...selectedEntitiesByUuid,
    };

    let nextPartiallySelectedEntitiesByUuid = {
      ...this.props.partiallySelectedEntitiesByUuid,
      ...partiallySelectedEntitiesByUuid,
    };

    const removeChildren = children => {
      if (!children || !children.forEach) {
        return;
      }

      children.forEach(uuid => {
        const child = this.props.entitiesByUuid[uuid];

        if (this.state.selectedEntitiesByUuid[child.uuid]) {
          nextSelectedEntitiesByUuid[child.uuid] = false;
        }

        if (child.children) {
          removeChildren(child.children);
        }
      });
    };

    Object.keys(selectedEntitiesByUuid).forEach(uuid => {
      const { children } = this.props.entitiesByUuid[uuid];
      removeChildren(children);
    });

    nextSelectedEntitiesByUuid = {
      ...nextSelectedEntitiesByUuid,
      ...selectedEntitiesByUuid,
    };

    nextPartiallySelectedEntitiesByUuid = {
      ...nextPartiallySelectedEntitiesByUuid,
      ...partiallySelectedEntitiesByUuid,
    };

    this.setState({
      preSelectingEntitiesByUuid: {},
      selectedEntitiesByUuid: nextSelectedEntitiesByUuid,
    });

    this.props.onAddGroup(
      nextSelectedEntitiesByUuid,
      nextPartiallySelectedEntitiesByUuid,
      entitiesByPreSelectedUuid,
    );
  };

  handleSearchCancel = () => {
    this.setSearchVisible(false);

    setTimeout(() => {
      this.setIsSearching(false);
      this.setSearchValue('');
    }, SEARCH_ANIMATION_DELAY);
  };

  handleSearchClick = () => {
    this.setSearchVisible(true);
    this.setIsSearching(true);
  };

  handleSearchValueChange = e => {
    const { value } = e.target;
    this.setSearchValue(value);

    if (value && this.state.isPreSelecting) {
      this.clearPreSelections();
      this.setIsViewingPicks(false);
    }
  };

  handleSelectAll = (event, checked) => {
    const nextChecked = !checked;

    if (
      this.props.currentLevel === 'bottom' ||
      this.state.isSearching ||
      this.props.peopleOnly
    ) {
      this.toggleSelectAllPeople(nextChecked);
    } else {
      this.toggleSelectAllGroups(nextChecked);
    }
  };

  handleViewPicks = () => this.setIsViewingPicks(true);

  renderConfirmation() {
    return (
      <ArcAudienceConfirmation
        currentUserId={this.props.currentUserId}
        data={this.props.confirmationData}
        disableSelf={this.props.disableSelf}
        hasFieldName={this.props.hasFieldName}
        namePlaceholder={this.props.namePlaceholder}
        nameValue={this.props.nameValue}
        onBack={this.handleConfirmationBack}
        onCancel={this.handleCancel}
        onNameChange={this.props.onNameChange}
        onRemove={this.props.onRemove}
        onRemoveAll={this.props.onRemoveAll}
        onSubmit={this.props.onSubmit}
        peopleOnly={this.props.peopleOnly}
        placeholderText={this.props.placeholderText}
        requestStatus={this.props.confirmationRequestStatus}
        submitButtonColor={this.props.submitButtonColor}
        submitButtonLabel={this.props.submitButtonLabel}
        title={this.props.confirmationTitle}
      />
    );
  }

  renderPreSelection() {
    return (
      <ArcAudiencePreSelection
        className={cx([
          'animated',
          this.state.isViewingPicks ? 'fadeInRight' : 'fadeIn',
        ])}
        entitiesByUuid={this.props.entitiesByUuid}
        excludedEntitiesByParentUuid={this.props.excludedEntitiesByParentUuid}
        onBack={this.handlePreSelectionBack}
        onChange={this.handlePreSelectionItemChange}
        onSelection={this.handlePreSelectionSelection}
        preSelectingEntitiesByUuid={this.state.preSelectingEntitiesByUuid}
        selectedEntitiesByUuid={this.state.selectedEntitiesByUuid}
      />
    );
  }

  renderRightScreen() {
    return (
      <React.Fragment>
        <Hidden smUp>
          {this.state.isViewingPicks || this.state.isPreSelecting ? (
            <ArcView
              zIndex="10"
              color="paper"
              position="absolute"
              fillToParent
              overflow="hidden"
              flexGrow="1"
              flexShrink="1"
              flexBasis="50%"
              padding="8"
            >
              {this.state.isPreSelecting
                ? this.renderPreSelection()
                : this.renderConfirmation()}
            </ArcView>
          ) : (
            <ArcView />
          )}
        </Hidden>

        <Hidden xsDown>
          <ArcView
            overflow="hidden"
            flexGrow="1"
            flexShrink="1"
            flexBasis="50%"
            padding="8"
          >
            {this.state.isPreSelecting
              ? this.renderPreSelection()
              : this.renderConfirmation()}
          </ArcView>
        </Hidden>
      </React.Fragment>
    );
  }

  renderSearch() {
    return (
      <React.Fragment>
        <ArcButton
          isSpaced
          size="small"
          disabled={!this.props.browserData.length}
          onClick={this.handleSearchClick}
        >
          <SearchIcon color="action" fontSize="small" />
        </ArcButton>

        {this.state.isSearching && (
          <SearchContainer
            zIndex="10"
            className={cx([
              'animated',
              this.state.searchVisible && 'fadeInDown',
              !this.state.searchVisible && 'fadeOutUp',
            ])}
            position="absolute"
            fillToParent
            padding="8"
            color="grey-tint"
          >
            <ArcTextField
              autoComplete="off"
              id="audienceSearch"
              variant="outlined"
              placeholder="Search"
              autoFocus
              onChange={this.handleSearchValueChange}
              value={this.state.searchValue}
              InputProps={{
                inputProps: textfieldInputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.handleSearchCancel}>
                      <CloseIcon color="action" fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </SearchContainer>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <ArcResponsiveDialog
        PaperProps={PAPER_PROPS}
        open={this.props.open}
        onClose={this.handleCancel}
      >
        <ArcView flexGrow="10" flexShrink="10" fullHeight>
          <ArcView
            position="relative"
            row
            padding="8"
            paddingLeft="16"
            align="flex-end"
            color="grey-tint"
            borderBottom="default"
            borderBottomWidth="1"
          >
            <Typography variant="h5">{this.props.title}</Typography>

            <ArcView spacer />

            {this.renderSearch()}

            <ArcButton size="small" onClick={this.handleCancel}>
              <CloseIcon color="action" fontSize="small" />
            </ArcButton>
          </ArcView>

          <ArcView position="relative" overflow="hidden" row fullWidth flex="1">
            <ArcView
              overflow="hidden"
              flexGrow="1"
              flexShrink="1"
              flexBasis="50%"
              padding="8"
            >
              <ArcAudienceBrowser
                breadcrumbItems={this.props.breadcrumbItems}
                currentEntity={this.props.currentEntity}
                currentUserId={this.props.currentUserId}
                data={this.props.browserData}
                disableSelf={this.props.disableSelf}
                fadeDirection={this.props.fadeDirection}
                fadeOut={this.props.fadeOut}
                hasChange={
                  this.props.peopleOnly
                    ? this.props.currentLevel ===
                      ArcAudienceBrowser.LEVELS.BOTTOM
                    : true
                }
                isPreSelecting={this.state.isPreSelecting}
                isSearching={this.state.isSearching}
                level={this.props.currentLevel}
                onChange={this.handleBrowserItemChange}
                onBack={this.props.onBack}
                onBreadcrumbsClick={this.props.onBreadcrumbsClick}
                onClick={this.props.onBrowserItemClick}
                onSelectAll={this.handleSelectAll}
                onViewPicks={this.handleViewPicks}
                partiallySelectedEntitiesByUuid={
                  this.props.partiallySelectedEntitiesByUuid
                }
                preSelectingEntitiesByUuid={
                  this.state.preSelectingEntitiesByUuid
                }
                requestStatus={this.props.browserRequestStatus}
                selectAllDisabled={
                  this.props.peopleOnly
                    ? this.props.currentLevel !==
                      ArcAudienceBrowser.LEVELS.BOTTOM
                    : false
                }
                selectAllName={
                  this.state.searchValue
                    ? `${this.props.browserData.length} ${
                        this.props.browserData.length === 1
                          ? 'Result'
                          : 'Results'
                      }`
                    : undefined
                }
                selectedEntitiesByUuid={this.state.selectedEntitiesByUuid}
              />
            </ArcView>

            {this.renderRightScreen()}
          </ArcView>
        </ArcView>

        <ArcConfirmDialog
          title="Are you sure?"
          content={this.props.confirmDialogContent}
          open={this.state.isClosing}
          onClose={this.handleCancelClose}
          onConfirm={this.props.onClose}
        />
      </ArcResponsiveDialog>
    );
  }
}

export default ArcAudienceDialog;
