import React from 'react';
import { PortalWithState } from 'react-portal';
import Check from '@material-ui/icons/Check';

import {
  ArcPropTypes,
  ArcView,
  ArcListItem,
} from 'arcade-frontend-ui';

import ArcChecklist from './ArcChecklist';

const styleBackdrop = {
  zIndex: 99999,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0,0,0,0.77)',
};

class ArcChecklistPortal extends React.PureComponent {
  static propTypes = {
    checklist: ArcPropTypes.shape({
      objectives: ArcPropTypes.arrayOf(ArcPropTypes.shape({})),
      reward: ArcPropTypes.shape({}),
    }),
    onOpen: ArcPropTypes.func.isRequired,
    onClaimReward: ArcPropTypes.func.isRequired,
    onClickItem: ArcPropTypes.func,
  };

  static defaultProps = {
    checklist: {
      objectives: [],
    },

    onClickItem: ArcPropTypes.noop,
  };

  renderTodoList = closePortal => (
    <ArcView
      style={styleBackdrop}
      onClick={closePortal}
      align="center"
    >
      <ArcChecklist
        isPopover
        onClose={closePortal}
        items={this.props.checklist.objectives}
        reward={this.props.checklist.reward}
        onClickItem={this.props.onClickItem}
        {...this.props}
      />
    </ArcView>
  );

  renderPortal = ({
    openPortal, closePortal, isOpen, portal,
  }) => (
    <React.Fragment>
      {!!this.props.checklist.objectives.length && (
        <ArcListItem
          onClick={openPortal}
          isSelected={isOpen}
          leftIcon={Check}
          position="relative"
          className="animated fadeIn"
        >
          {this.props.checklist.objectives.length}
          {' '}
TO DOs
        </ArcListItem>
      )}
      {portal(this.renderTodoList(closePortal))}
    </React.Fragment>
  );

  render() {
    return (
      <PortalWithState
        closeOnOutsideClick
        closeOnEsc
        node={global.window && document && document.body}
        onOpen={this.props.onOpen}
      >
        {this.renderPortal}
      </PortalWithState>
    );
  }
}

export default ArcChecklistPortal;
