import { setAsyncStorageRoute, clearAsyncStorage } from 'arcade-frontend-core/src/helpers/asyncStorage';
import { types } from 'arcade-frontend-core/src/actions';
import { getAppReady } from 'arcade-frontend-core/src/reducers/appReady';
import * as ROUTES from 'arcade-frontend-core/src/types/routes';

export default store => next => (action) => {
  const state = store.getState();
  const appReady = getAppReady(state);
  const nextAction = next(action);

  if (!appReady) {
    return nextAction;
  }

  const shouldSaveRoute = action.type.indexOf('route/') === 0
    && action.type !== ROUTES.ROUTE_HOME
    && action.type !== ROUTES.ROUTE_LOGIN
    && action.type !== ROUTES.ROUTE_LOGIN_VIA_LINK
    && action.type !== ROUTES.NOT_FOUND;

  if (shouldSaveRoute) {
    setAsyncStorageRoute(action);
  }

  if (action.type === types.LOGOUT) {
    clearAsyncStorage();
  }
  return nextAction;
};
