import { connectRoutes } from 'redux-first-router';
import { createHashHistory, createMemoryHistory } from 'history';
import queryString from 'query-string';

import routePaths from '../routes/paths';

const createHistory = typeof window !== 'undefined' && window.document ? createHashHistory : createMemoryHistory;

const {
  reducer,
  middleware,
  enhancer,
} = connectRoutes(routePaths, {
  createHistory,
  querySerializer: queryString,
});

// @HACK Exporting when assigning the variables above works when the application compiles to run,
// however Jest chokes on the reducer when running our snapshot tests.
export {
  reducer,
  middleware,
  enhancer,
};
