// import React from 'react';
import {
  Platform,
  // Text,
  View,
} from 'react-primitives';

import ArcPropTypes from '../../helpers/arc/propTypes';
import { createWithStyles } from '../../styles';

import * as styles from './styles';

const NativeView = Platform.OS === 'web' ? 'div' : View;

const ArcView = createWithStyles(styles.ArcView)(NativeView);

const flexProperties = [
  null,
  'center',
  'flex-end',
  'flex-start',
  'space-around',
  'space-between',
  'space-evenly',
  'stretch',
  'baseline',
];

ArcView.propTypes = {
  children: ArcPropTypes.children,
  row: ArcPropTypes.bool,
  align: ArcPropTypes.oneOf(flexProperties),
  justify: ArcPropTypes.oneOf(flexProperties),
  wrap: ArcPropTypes.oneOf([
    null,
    'wrap',
    'nowrap',
    'wrap-reverse',
  ]),
};

ArcView.defaultProps = {
  children: undefined,
  row: false,
  align: null,
  justify: null,
  wrap: null,
};

ArcView.displayName = 'ArcView';

export default ArcView;


// export default (props) => {
//   function renderDefault() {
//     return (
//       <ArcView {...props}>
//         {props.children}
//       </ArcView>
//     );
//   }

//   function renderWithText() {
//     return (
//       <ArcView {...props}>
//         <Text>{props.children}</Text>
//       </ArcView>
//     );
//   }

//   if (Platform.OS === 'web') {
//     return renderDefault();
//   }

//   switch (typeof children) {
//     case 'string':
//       return renderWithText();
//     default:
//       return renderDefault();
//   }
// };
