import React from 'react';
import PropTypes from 'prop-types';

import {
  ArcView,
  ArcMarkdownContent,
  createWithStyles,
  ArcText,
} from 'arcade-frontend-ui';
import ArcChestImage from 'arcade-frontend-ui/src/components/ArcChestImage';
import ArcEntity from 'arcade-frontend-ui/src/components/ArcEntity';
import ArcStackedEntities from 'arcade-frontend-ui/src/components/ArcStackedEntities';

const styles = {
  Title: () => ({
    root: {
      fontSize: 24,
      textAlign: 'center',
    },
  }),
  Body: () => ({
    root: {
      textAlign: 'center',
    },
  }),
};

const Title = createWithStyles(styles.Title)(ArcView);
const Body = createWithStyles(styles.Body)(ArcView);

const CHEST_VARIANTS = ['Common', 'Rare', 'Epic', 'Legendary'];

const MAX_PEOPLE = 3;
const MIN_CHEST_SIZE = 48;

const ChestActivity = ({ activity, onRecipientsClick, onQuicklinkUser }) => {
  const { additionalInformation, content } = activity;
  const { chests, rewardedUsers } = additionalInformation;

  const rewardedUsersLength = rewardedUsers ? rewardedUsers.length : 0;
  const shouldCollapsePeople = rewardedUsersLength > MAX_PEOPLE;

  const getHasChest = rarity =>
    !!chests && !!chests[rarity] && chests[rarity] > 0;

  const handleRecipientClick = () => {
    if (rewardedUsersLength === 1) {
      onQuicklinkUser(rewardedUsers[0].id, 'person');
    } else {
      onRecipientsClick();
    }
  };

  const chestVariants = CHEST_VARIANTS.filter(getHasChest);
  const chestVariantsLength = chestVariants.length;

  const chestSize = Math.max(128 / chestVariantsLength, MIN_CHEST_SIZE);

  let title = 'Chests Awarded!';

  if (chestVariantsLength === 1) {
    const qty = chests[chestVariants[0]];
    title = `${chestVariants[0]} ${qty > 1 ? 'Chests' : 'Chest'} Awarded!`;
  }

  return (
    <ArcView align="center">
      <Title marginBottom="16">{title}</Title>

      <ArcView row justify="center" marginBottom="24">
        {chestVariants.map(rarity => (
          <ArcView key={rarity} align="center" margin="4">
            <ArcChestImage
              size={`${chestSize}px`}
              variant={rarity}
              label={`x${chests[rarity]}`}
              labelTextSize={chestVariantsLength > 1 ? '12px' : 'inherit'}
            />
            {chestVariantsLength > 1 && <ArcText size="12px">{rarity}</ArcText>}
          </ArcView>
        ))}
      </ArcView>

      <ArcView onClick={handleRecipientClick} cursor="pointer" align="center">
        <ArcText
          color="disabled"
          size="12px"
          marginBottom="8"
          textTransform="uppercase"
        >
          {shouldCollapsePeople ? `To ${rewardedUsersLength} people` : 'To'}
        </ArcText>

        <ArcView marginBottom="24">
          {shouldCollapsePeople ? (
            <ArcStackedEntities
              entities={rewardedUsers.map(person => ({
                id: person.id,
                type: 'person',
                imageUrl: person.image,
                name: person.name,
              }))}
            />
          ) : (
            rewardedUsers.map(person => (
              <ArcView key={person.id} align="center">
                <ArcEntity
                  type="person"
                  imageUrl={person.image}
                  name={person.name}
                  teamName={person.teamName}
                />
              </ArcView>
            ))
          )}
        </ArcView>
      </ArcView>

      <ArcText
        color="disabled"
        size="12px"
        marginBottom="8"
        textTransform="uppercase"
      >
        {'For'}
      </ArcText>

      <Body>
        <ArcMarkdownContent content={content} />
      </Body>
    </ArcView>
  );
};

ChestActivity.displayName = 'ChestActivity';

ChestActivity.propTypes = {
  activity: PropTypes.shape({
    additionalInformation: PropTypes.shape({
      chests: PropTypes.shape({
        Common: PropTypes.number,
        Rare: PropTypes.number,
        Epic: PropTypes.number,
        Legendary: PropTypes.number,
      }),
      rewardedUsers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          image: PropTypes.string,
          badge: PropTypes.string,
        }),
      ),
    }),
    content: PropTypes.string,
  }),
  onQuicklinkUser: PropTypes.func,
  onRecipientsClick: PropTypes.func,
};

ChestActivity.defaultProps = {
  activity: {},
  onQuicklinkUser: global.noop,
  onRecipientsClick: global.noop,
};

export default ChestActivity;
