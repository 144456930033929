import React from 'react';
import PropTypes from 'prop-types';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import { ArcText } from 'arcade-frontend-ui';
import AlertDiamond from 'arcade-frontend-ui/src/icons/AlertDiamond';

function GamesVerificationBadge({
  isManager,
  backgroundOpacity,
  pendingCount,
}) {
  const showPendingCount = isManager && pendingCount > 1;
  return (
    <ArcBox
      display="flex"
      flexDirection="row"
      alignItems="center"
      py={0.75}
      px={1}
      bgcolor={
        isManager || showPendingCount
          ? `rgba(233, 151, 38, ${backgroundOpacity})`
          : `rgba(11, 11, 11, ${backgroundOpacity})`
      }
      borderRadius={8}
    >
      <AlertDiamond stroke="#FFD64F" />
      <ArcBox
        component={ArcText}
        ml={0.5}
        mr={1}
        fontWeight={isManager ? 'bold' : 'normal'}
        fontSize={14}
        lineHeight="150%"
        color="white"
      >
        {showPendingCount ? (
          `${pendingCount} Events Pending`
        ) : (
          <>{isManager ? 'Verification Needed' : 'Awaiting Verification'}</>
        )}
      </ArcBox>
    </ArcBox>
  );
}

GamesVerificationBadge.displayName = 'GamesVerificationBadge';

GamesVerificationBadge.propTypes = {
  isManager: PropTypes.bool,
  backgroundOpacity: PropTypes.number,
  pendingCount: PropTypes.number,
};

GamesVerificationBadge.defaultProps = {
  isManager: false,
  backgroundOpacity: 0.4,
  pendingCount: 0,
};

export default GamesVerificationBadge;
