import React from 'react';
import PropTypes from 'prop-types';

const RaceCircuit = ({
  height,
  width,
  trackFillColor,
  trackStrokeColor,
  innerFieldFillColor,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 275 243"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M104.931 24h64.954c20.498 0 41.777 22.386 47.733 50l23.486 100c7.304 33-9.696 42-49.299 42H83.011c-40.603 0-56.603-9-49.3-42L57.199 74c5.957-27.614 27.235-50 47.733-50z" fill={innerFieldFillColor} /><path
        d="M94.971 1c-26.29 0-53.819 26.79-61.538 59.995L2.672 182.515C-6.324 215.37 21.938 242 66.266 242h142.468c44.328 0 72.59-26.63 63.589-59.505l-30.76-121.519C233.848 27.791 206.319 1 180.029 1H94.971zm10.045 22h65.028c21.024 0 42.703 22.713 48.762 50.771l23.516 100.013c7.096 32.02-7.476 43.216-50.332 43.216H83.07c-43.447 0-57.482-10.95-50.33-43.229L56.25 73.79C62.313 45.713 83.992 23 105.016 23z"
        stroke={trackStrokeColor}
        strokeWidth="2"
        fill={trackFillColor}
      />
      <path d="M130 12h47c7.683 0 20.475 6.452 23.475 8.846 6.06 4.835 11.845 10.593 17.358 17.274l7.667 13.06 5.5 15.069 28 111.511c.667 7.412.667 12.77 0 16.074-.667 3.304-2.667 7.859-6 13.663-2.97 4.275-5.803 7.423-8.5 9.443-2.697 2.02-7.197 4.7-13.5 8.037-4.179 1.249-7.845 2.253-11 3.014-3.155.76-7.821 1.43-14 2.009H68c-7.195-.343-12.529-1.013-16-2.01-3.471-.996-7.805-2.67-13-5.022-2.811-1.992-5.478-4-8-6.028-2.522-2.027-5.522-5.175-9-9.443-3.296-4.634-5.296-8.518-6-11.654-.704-3.135-.704-8.158 0-15.069L47 57.207c2.488-5.35 4.488-9.368 6-12.055 1.512-2.687 3.845-6.37 7-11.05 3.63-4.77 6.63-8.12 9-10.047 2.37-1.927 6.37-4.271 12-7.032 5.315-2.098 9.648-3.438 13-4.018 3.352-.581 14.685-.916 34-1.005" />
    </g>
  </svg>
);

RaceCircuit.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  innerFieldFillColor: PropTypes.string,
  trackFillColor: PropTypes.string,
  trackStrokeColor: PropTypes.string,
};

RaceCircuit.defaultProps = {
  height: 275,
  width: 243,
  innerFieldFillColor: '#F5FFEB',
  trackFillColor: '#B4B4B4',
  trackStrokeColor: '#797979',
};

export default RaceCircuit;
