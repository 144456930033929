import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import ArcStyles, { createWithStyles } from '../../styles';

const styles = {
  ArcMainNav: theme => ({
    root: {
      ...ArcStyles.rules.scroll,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
      width: '100%',
      height: '100%',

      borderRightWidth: 1,
      borderRightStyle: 'solid',
      borderRightColor: 'rgb(224, 224, 224)',

      [theme.breakpoints.up('xs')]: {
        flexBasis: '100%',
        height: '100%',
        width: '100%',
      },

      [theme.breakpoints.up('sm')]: {
        ...ArcStyles.rules.scroll,
        top: 0,
        bottom: 0,
        left: 0,
        height: 'auto',
        width: ArcStyles.NAV_WIDTH,
        flexBasis: ArcStyles.NAV_WIDTH,
      },
    },

    isViewing: {
      display: 'none',

      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexBasis: ArcStyles.NAV_WIDTH,
        width: ArcStyles.NAV_WIDTH,
      },
    },
  }),
};


const ArcMainNav = createWithStyles(styles.ArcMainNav)(ArcView);

ArcMainNav.propTypes = {
  isViewing: ArcPropTypes.bool,
};

ArcMainNav.defaultProps = {
  isViewing: false,
};

export default ArcMainNav;
