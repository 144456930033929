import React from 'react';
import List from '@material-ui/core/List';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcStyles from '../../styles';


const styleList = {
  display: 'flex',
  flexDirection: 'column',
  fontSize: ArcStyles.ptSizeInPx(1),
  lineHeight: '24px',
  listStyle: 'none',
  margin: 0,
  padding: 0,
};


class ArcList extends React.PureComponent {
  static displayName = 'ArcList';

  static propTypes = {
    children: ArcPropTypes.children,
    style: ArcPropTypes.style,
  };

  static defaultProps = {
    children: null,
    style: null,
  };

  render() {
    return (
      <List
        {...this.props}
        style={styleList}
      >
        {this.props.children}
      </List>
    );
  }
}

export default ArcList;
