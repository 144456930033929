import { createIsRequestingReducer } from 'arcade-frontend-ui';
import { types } from '../../../actions/manage/rewards';

const initialState = {};

const apiActionTypes = [
  types.MANAGE_REWARDS_CUSTOM_ARCHIVE,
  types.MANAGE_REWARDS_CUSTOM_RESTORE,
  types.MANAGE_REWARDS_ARCHIVE,
  types.MANAGE_REWARDS_RESTORE,
];

const isRequesting = createIsRequestingReducer(initialState, apiActionTypes);

export default isRequesting;

export const getIsRequesting = state => state.manage.rewards.isRequesting;
