import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/tokens';

const initialState = '';

const handlers = {
  [types.MANAGE_TOKENS_SET_TOP_UP_ERROR]: (state, action) => action.payload,
};

const topUpError = createReducer(initialState, handlers);

export default topUpError;

export const getTopUpError = state => state.manage.tokens.topUpError;
