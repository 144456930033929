import React from 'react';

import ArcLink from '../../elements/ArcLink';
import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import ArcStyles from '../../styles';
import ArcHeading from '../../typography/ArcHeading';

const styleHeader = props => ({
  flexDirection: 'row',
  alignItems: 'center',
  margin: '0px 8px 16px',
  padding: '8px 8px 8px',
  borderBottom: `solid 4px ${ArcStyles.theme.grey7}`,

  ...props.style,
});

const styleHeaderClickable = props => ({
  ...styleHeader(props),
  cursor: 'pointer',
  ...props.style,
});

const styleHeading = {
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: '300',
  color: '#666666',
  marginBottom: '8px',
};

const ArcViewHeader = props => (
  <ArcView
    row
    justify="space-between"
    style={props.onClick ? styleHeaderClickable(props) : styleHeader(props)}
    onClick={props.onClick}
  >
    <ArcHeading style={styleHeading}>
      {props.heading}
    </ArcHeading>

    {props.children}

    {props.onClickAction && (
      <ArcLink onPress={props.onClickAction}>
        {props.labelAction}
      </ArcLink>
    )}
  </ArcView>
);

ArcViewHeader.propTypes = {
  heading: ArcPropTypes.children.isRequired,
  children: ArcPropTypes.children,
  onClick: ArcPropTypes.func,
  onClickAction: ArcPropTypes.func,
  labelAction: ArcPropTypes.children,
};

ArcViewHeader.defaultProps = {
  children: undefined,
  onClick: null,
  onClickAction: null,
  labelAction: '',
};

export default ArcViewHeader;
