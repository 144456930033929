import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import withResourceAction from 'arcade-frontend-core/src/helpers/withResourceAction';
import { ArcCellContent, ArcConfirmDialog, ArcView } from 'arcade-frontend-ui';
import track from 'arcade-frontend-core/src/helpers/track';
import { resources } from '../resources/rewardPurchases';

import { getRewardMetaStatus } from '../reducers/rewardPurchases';
import RewardCancelPurchaseMessageDialog from '../components/RewardCancelPurchaseMessageDialog';

const CODES = {
  UNABLE_TO_CANCEL: 'unable_to_cancel',
  PURCHASE_CANCELLED: 'purchase_cancelled',
};

const STRINGS = {
  [CODES.UNABLE_TO_CANCEL]:
    'We were unable to cancel your purchase. Please contact the support team for further assistance or try again later.',
  [CODES.PURCHASE_CANCELLED]:
    'Your purchase has been successfully cancelled. The tokens have been refunded to your account.',
  CANCELLATION_CONFIRMATION:
    'Are you sure you want to cancel your purchase? Once this has been approved the tokens will be refunded to your account',
};

class RewardHistoryCancelContainer extends React.PureComponent {
  static displayName = 'RewardHistoryCancelContainer';

  static propTypes = {
    reward: PropTypes.shape({
      id: PropTypes.string,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      amount: PropTypes.number,
      denomination: PropTypes.string,
      quantity: PropTypes.number,
      createdAt: PropTypes.string,
      expectedFulfillmentDate: PropTypes.string,
      status: PropTypes.string,
      redemptionLink: PropTypes.string,
      redemptionInformation: PropTypes.string,
      tokenCost: PropTypes.number,
      code: PropTypes.string,
    }),
    onStatusReset: PropTypes.func,
    requestData: PropTypes.func,
    status: PropTypes.shape({
      idle: PropTypes.bool,
      pending: PropTypes.bool,
      failed: PropTypes.bool,
      succeeded: PropTypes.bool,
    }),
  };

  static defaultProps = {
    reward: {},
    onStatusReset: global.noop,
    requestData: global.noop,
    status: {},
  };

  state = {
    openedDialog: '',
    cancellationCode: null,
  };

  setCancellationCode = code => {
    this.setState({
      cancellationCode: code,
    });
  };

  onCancelFailed = error => {
    track('error: cancel reward purchase failed', {
      payload: {
        event: 'reward:error-cancel-reward-purchase',
        reward: this.props.reward,
        error: error || null,
      },
    });
    this.setState({
      openedDialog: 'cancel-reward-response-message',
      cancellationCode: 'unable_to_cancel',
    });
  };

  cancelRewardPurchase = id =>
    this.props
      .requestData({
        resources: [
          {
            id,
          },
        ],
        requestKey: id,
      })
      .then(resp => {
        if (resp.data) this.setCancellationCode(resp.data.resources[0].code);
        this.handleOpenDialog('cancel-reward-response-message');
        this.props.onStatusReset();
      })
      .catch(error => {
        this.onCancelFailed(error);
        this.props.onStatusReset();
      });

  handleOpenDialog = openedDialog => {
    track(`open dialog ${openedDialog}`, {
      payload: { event: `reward:open-dialog-${openedDialog}` },
    });
    this.setState({ openedDialog });
  };

  handleCloseDialog = () => {
    track(`close dialog ${this.state.openedDialog}`, {
      payload: { event: `reward:close-dialog-${this.state.openedDialog}` },
    });
    this.setState({ openedDialog: '' });
  };

  handleCancelRewardPurchase = reward => {
    track('cancel reward purchase', {
      payload: { event: 'reward:cancel-reward-purchase' },
    });
    this.handleCloseDialog();
    this.cancelRewardPurchase(reward.id);
  };

  render() {
    const { openedDialog, cancellationCode } = this.state;
    const { reward, status } = this.props;

    const message =
      cancellationCode === 'purchase_cancelled'
        ? STRINGS[CODES.PURCHASE_CANCELLED]
        : STRINGS[CODES.UNABLE_TO_CANCEL];

    return (
      <>
        {reward.status === 'received' && (
          <>
            {status.pending ? (
              <ArcView className="circle-loader danger" />
            ) : (
              <ArcCellContent
                action
                danger
                onClick={() =>
                  this.handleOpenDialog('confirm-cancel-reward-dialog')
                }
              >
                {'Cancel Reward'}
              </ArcCellContent>
            )}
          </>
        )}
        <ArcConfirmDialog
          open={openedDialog === 'confirm-cancel-reward-dialog'}
          title={'Cancel Reward Purchase'}
          content={STRINGS.CANCELLATION_CONFIRMATION}
          confirmButtonColor="danger"
          cancelLabel="Close"
          confirmLabel="Cancel Reward"
          onClose={this.handleCloseDialog}
          onConfirm={() => this.handleCancelRewardPurchase(reward)}
        />

        <RewardCancelPurchaseMessageDialog
          open={openedDialog === 'cancel-reward-response-message'}
          message={message}
          cancellationCode={cancellationCode}
          onClose={this.handleCloseDialog}
          onTryAgain={() => this.handleCancelRewardPurchase(reward)}
        />
      </>
    );
  }
}

const getState = (state, props) => ({
  status: getRewardMetaStatus(state, props.reward.id),
  ...props,
});

export default compose(
  connect(getState),
  withResourceAction(resources.apiCancelRewardPurchase),
)(RewardHistoryCancelContainer);
