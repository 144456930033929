import React from 'react';

import PropTypes from 'prop-types';

import { ArcScroll, ArcSimpleDialog } from 'arcade-frontend-ui';

import ParticipantList from './ParticipantList';


const ParticipantDialog = ({ participants, onClose, title }) => {
  if (!participants) return null;

  return (
    <ArcSimpleDialog open onClose={onClose} title={title}>
      <ArcScroll>
        {participants.length === 0 && `No ${title} for this game`}
        <ParticipantList participants={participants} />
      </ArcScroll>
    </ArcSimpleDialog>
  );
};

ParticipantDialog.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  })),
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

ParticipantDialog.defaultProps = {
  participants: null,
};

export default ParticipantDialog;
