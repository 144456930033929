import React from 'react';

import {
  Circle,
  G,
  Path,
} from 'react-primitives-svg';

import Icon from '../Icon';

const RemoveAllPeople = props => (
  <Icon {...props}>
    <G
      fill="none"
      fillRule="evenodd"
    >
      <Path d="M0 0h24v24H0z" />
      <G stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <Circle cx="18.5" cy="18.5" r="5" />
        <Path d="M16.5 18.5h4M.5 13.5c.01-.928.202-1.846.565-2.7.4-.8 2.063-1.35 4.153-2.124.565-.209.472-1.684.222-1.96a4.167 4.167 0 0 1-1.085-3.231A2.725 2.725 0 0 1 7 .5a2.725 2.725 0 0 1 2.645 2.985A4.167 4.167 0 0 1 8.56 6.717c-.25.276-.343 1.751.222 1.96 2.09.774 3.754 1.327 4.153 2.124.363.854.555 1.772.565 2.7L.5 13.5zM10.912.737A2.573 2.573 0 0 1 12 .5a2.725 2.725 0 0 1 2.645 2.985 4.167 4.167 0 0 1-1.085 3.232c-.25.276-.343 1.751.222 1.96 2.09.774 3.754 1.327 4.153 2.124.024.049.048.1.07.15" />
      </G>
    </G>
  </Icon>
);

export default RemoveAllPeople;
