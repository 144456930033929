import { react2angular } from 'react2angular';

import ArcUploadAnchorContainer from 'arcade-frontend-widgets/src/containers/ArcUploadAnchorContainer';

import { createProvidedContainer } from './helpers';

const ProvidedArcUploadAnchorContainer = createProvidedContainer(ArcUploadAnchorContainer);

export default react2angular(ProvidedArcUploadAnchorContainer, [
  'animate',
  'fullWidth',
  'label',
  'source',
]);
