import React from 'react';

import PropTypes from 'prop-types';

const styles = {
  default: {
    backgroundCircleColor: '#FFCA13',
    innerCircleColor: '#FBE140',
    logoColor: '#FFF',
    toothColor: '#FFE215',
  },
  grey: {
    backgroundCircleColor: '#F8F8F8',
    innerCircleColor: '#E7E7E7',
    logoColor: '#FFF',
    toothColor: '#F3F3F3',
  },
};

const ArcToken = ({
  variant,
  ...rest
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g fillRule="nonzero" fill="none"><g transform="translate(.044 .044)"><circle fill={styles[variant].backgroundCircleColor} cx="11.956" cy="11.956" r="11.956" /><path d="M11.348 21.246l-1.382 2.489a11.858 11.858 0 0 1-2.176-.582l.047-2.852a9.244 9.244 0 0 0 3.511.945zm1.216 0a9.245 9.245 0 0 0 3.51-.935l.048 2.842c-.697.26-1.425.456-2.176.582l-1.382-2.488zm4.568-1.546a9.35 9.35 0 0 0 2.568-2.568l1.46 2.436c-.48.58-1.013 1.113-1.592 1.593l-2.436-1.46zm3.18-3.625a9.245 9.245 0 0 0 .935-3.51l2.488 1.381a11.858 11.858 0 0 1-.582 2.176l-2.842-.047zm.934-4.727a9.244 9.244 0 0 0-.945-3.51l2.852-.048c.26.697.456 1.424.582 2.176l-2.49 1.382zm-1.553-4.557a9.35 9.35 0 0 0-2.578-2.57l2.453-1.47c.58.48 1.113 1.013 1.593 1.593l-1.468 2.447zm-3.619-3.17a9.245 9.245 0 0 0-3.522-.935l1.394-2.51c.751.127 1.479.323 2.176.582l-.048 2.864zm-4.714-.934a9.244 9.244 0 0 0-3.523.945L7.79.758C8.487.5 9.214.303 9.966.177l1.394 2.51zM6.808 4.228a9.35 9.35 0 0 0-2.58 2.58L2.751 4.344c.48-.58 1.013-1.113 1.593-1.593l2.464 1.477zm-3.176 3.61a9.244 9.244 0 0 0-.945 3.522L.177 9.966c.126-.752.322-1.48.581-2.176l2.874.048zm-.946 4.714a9.245 9.245 0 0 0 .936 3.522l-2.864.048a11.858 11.858 0 0 1-.581-2.176l2.509-1.394zm1.536 4.563a9.35 9.35 0 0 0 2.57 2.579L4.343 21.16a12.017 12.017 0 0 1-1.593-1.593l1.47-2.453z" fill={styles[variant].toothColor} />
      <circle fill={styles[variant].innerCircleColor} cx="11.956" cy="11.956" r="9.299" /></g><g fill={styles[variant].logoColor}><path d="M7.19 14.646V9.338l4.756-2.774 4.756 2.775v7.978l-2.378-1.302-2.378 1.37-4.756-2.74zm4.818-5.305L9.73 10.667v2.651l2.278 1.326 2.279-1.326v-2.651L12.008 9.34z" /><path d="M11.902 14.79l-2.048 1.192 2.04 1.178zM9.547 13.419l-2.049 1.192 2.04 1.178zM9.694 15.885l2.048-1.192-2.04-1.178zM7.338 14.514l2.048-1.191-2.04-1.178zM11.902 9.216L9.854 8.024l2.04-1.178zM9.547 10.587L7.498 9.395l2.04-1.178zM9.694 8.12l2.048 1.192-2.04 1.178zM7.338 9.491l2.048 1.192-2.04 1.178zM9.54 10.82l-2.048 1.192 2.04 1.178zM12.057 9.216l2.049-1.192-2.04-1.178zM14.413 10.587l2.049-1.192-2.04-1.178zM14.266 8.12l-2.048 1.192 2.04 1.178zM16.554 9.491l-2.048 1.192 2.04 1.178zM16.554 14.721l-2.048 1.192 2.04 1.178zM12.057 14.79l2.049 1.192-2.04 1.178zM14.413 13.419l2.049 1.192-2.04 1.178zM14.266 15.885l-2.048-1.192 2.04-1.178zM16.554 14.514l-2.048-1.191 2.04-1.178zM14.465 13.186l2.049-1.192-2.04-1.178z" /></g></g>
  </svg>
);

ArcToken.propTypes = {
  variant: PropTypes.string,
};

ArcToken.defaultProps = {
  variant: 'default',
};

export default ArcToken;
