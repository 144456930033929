import React from 'react';
import PropTypes from 'prop-types';

import { ArcView, ArcText, createWithStyles } from 'arcade-frontend-ui';

const styles = {
  MetricRow: theme => ({
    root: {
      height: theme.spacing(4),
      width: '100%',
      padding: theme.spacing(1),
    },

    shade: {
      backgroundColor: '#F5F5F5',
    },
  }),

  MetricTitle: theme => ({
    root: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),

  MetricAmount: theme => ({
    root: {
      marginRight: theme.spacing(1),
    },
  }),
};

const MetricRow = createWithStyles(styles.MetricRow)(ArcView);
const MetricTitle = createWithStyles(styles.MetricTitle)(ArcView);
const MetricAmount = createWithStyles(styles.MetricAmount)(ArcView);

class ManageMetricEntityCardListItem extends React.PureComponent {
  static propTypes = {
    metric: PropTypes.shape({
      title: PropTypes.string,
      quantity: PropTypes.number,
    }),
    shade: PropTypes.bool,
  };

  static defaultProps = {
    metric: null,
    shade: null,
  };

  render() {
    const { metric, shade } = this.props;

    return (
      <MetricRow row justify="space-between" align="center" shade={shade}>
        <MetricTitle flexGrow="1">
          <ArcText>{metric.title}</ArcText>
        </MetricTitle>
        <MetricAmount>
          <ArcText>{metric.quantity}</ArcText>
        </MetricAmount>
      </MetricRow>
    );
  }
}

export default ManageMetricEntityCardListItem;
