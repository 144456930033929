import { createReducer } from 'arcade-frontend-ui';

import { types as typesCore } from 'arcade-frontend-core/src/actions';

import { types } from '../../../actions/manage/people';

const handleManagePeopleProfileUpdateFailure = () => 'An error occurred';
const handleReset = () => null;

const handleAppInvitePeopleFailure = (state, action) => {
  if (action.payload && action.payload.error) {
    return action.payload.error;
  }

  return 'An error occured';
};

const initialState = null;

const actions = {
  [types.MANAGE_PEOPLE_PROFILE_UPDATE.FAILURE]: handleManagePeopleProfileUpdateFailure,
  [types.MANAGE_PEOPLE_PROFILE_UPDATE.RESET]: handleReset,

  [typesCore.APP_INVITE_PERSON.FAILURE]: handleAppInvitePeopleFailure,
  [typesCore.APP_INVITE_PERSON.REQUEST]: handleReset,
};

const requestErrorMessage = createReducer(initialState, actions);

export default requestErrorMessage;

export const getRequestErrorMessage = state => state.manage.people.requestErrorMessage;
