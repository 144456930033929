import { createRequestingStatusReducer } from 'arcade-frontend-ui';

import { types } from '../../actions/uploadManager';

export default createRequestingStatusReducer(
  {
    UPLOAD_MANAGER_UPDATE_UPLOAD: 'DEFAULT',
    UPLOAD_MANAGER_UPDATE_UPLOAD_FILE: 'DEFAULT',
    UPLOAD_MANAGER_SEARCH_UPLOADS: 'DEFAULT',
  },
  [
    types.UPLOAD_MANAGER_UPDATE_UPLOAD,
    types.UPLOAD_MANAGER_UPDATE_UPLOAD_FILE,
    types.UPLOAD_MANAGER_SEARCH_UPLOADS,
  ],
);

export const getRequestStatus = state => state.uploadManager.requestStatus;
