import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Platform } from 'react-native';

import {
  STATUS_TYPES,
  EMPTY_STATUS,
  INITIAL_STATUS,
} from 'arcade-frontend-core/src/hooks/useResourceAction';

import ArcResourceStatusIcon from '../ArcResourceStatusIcon';
import ArcButton from '../../elements/ArcButton';
import ArcText from '../../primitives/ArcText';


function ArcResourceButton({
  children,
  label,
  status,
  ...props
}) {
  const nativeProps = Platform.OS === 'web' ? {} : {
    loading: status.pending,
  };

  return (
    <ArcButton
      disabled={!status[STATUS_TYPES.idle]}
      {...props}
      {...nativeProps}
      label={label}
    >
      {Platform.OS === 'web' && (
        <ArcResourceStatusIcon
          status={status}
        />
      )}
      <ArcText
        className={cx([
          status[STATUS_TYPES.idle] && 'animated animated--fast fadeIn',
          !status[STATUS_TYPES.idle] && 'animated animated--fast fadeOut',
        ])}
      >
        {children || label}
      </ArcText>
    </ArcButton>
  );
}


ArcResourceButton.displayName = 'ArcResourceButton';

ArcResourceButton.STATUS_TYPES = STATUS_TYPES;

ArcResourceButton.EMPTY_STATUS = EMPTY_STATUS;

ArcResourceButton.INITIAL_STATUS = INITIAL_STATUS;

ArcResourceButton.VARIANTS = ArcButton.VARIANTS;

ArcResourceButton.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node,
  status: PropTypes.shape({
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    succeeded: PropTypes.bool,
    failed: PropTypes.bool,
  }),
};

ArcResourceButton.defaultProps = {
  children: undefined,
  label: undefined,
  status: INITIAL_STATUS,
};


export default ArcResourceButton;
