import { types } from '../actions';

const handleWebviewSetCanGoBack = (state, action) => {
  const { payload } = action;

  return payload;
};

const initialState = false;

const canGoBack = (state = initialState, action) => {
  switch (action.type) {
    case types.WEBVIEW_SET_CAN_GO_BACK:
      return handleWebviewSetCanGoBack(state, action);
    default:
      return state;
  }
};

export const getCanGoBack = state => state.app.canGoBack;

export default canGoBack;
