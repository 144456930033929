import React from 'react';

import PropTypes from 'prop-types';

import { propTypes as gamePropTypes } from 'arcade-frontend-features/src/resources/games';

import ManageGamesLeaderboardContainer from '../../containers/ManageGamesLeaderboardContainer';
import ManageGamesEventsContainer from '../../containers/ManageGamesEventsContainer';
import ManageGamesVerifyContainer from '../../containers/ManageGamesVerifyContainer';
import { ManageGamesSeeDetails } from '../ManageGamesSeeDetails/ManageGamesSeeDetails';

function ManageGameDetailsView({ game, mode, finishedEditing, requestStatus }) {
  switch (mode) {
    case 'verify':
      return (
        <ManageGamesVerifyContainer
          game={game}
          finishedEditing={finishedEditing}
          requestStatus={requestStatus.MANAGE_GAMES_VERIFY}
        />
      );
    case 'leaderboard':
      return <ManageGamesLeaderboardContainer game={game} />;
    case 'details':
      return <ManageGamesSeeDetails game={game} />;
    case 'events':
      return <ManageGamesEventsContainer game={game} />;
    default:
      return null;
  }
}

ManageGameDetailsView.displayName = 'ManageGameDetailsView';

ManageGameDetailsView.propTypes = {
  game: gamePropTypes.game,
  mode: PropTypes.string,
  finishedEditing: PropTypes.func,
  requestStatus: PropTypes.objectOf(PropTypes.string),
};

ManageGameDetailsView.defaultProps = {
  game: {},
  mode: '',
  finishedEditing: global.noop,
  requestStatus: {},
};

export default ManageGameDetailsView;
