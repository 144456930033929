import humps from 'humps';

import createResourceAction from 'arcade-frontend-core/src/helpers/createResourceAction';

export const apiPutUserBudgetLimit = createResourceAction('update', {
  resourceType: 'users',
  requestKey: 'apiPutUserBudgetLimit',

  api: payload => {
    const { monthlyLimit, id } = payload.resources[0];

    const data = humps.decamelizeKeys({
      user: {
        monthlyLimit,
      },
    });

    return {
      method: 'PUT',
      url: `manager/v4/users/${id}/set_monthly_limit`,
      data,
    };
  },
});

export const apiPutPermissions = createResourceAction('update', {
  resourceType: 'users',
  requestKey: 'apiPutPermissions',

  api: payload => ({
    method: 'PUT',
    url: `manager/v4/users/${payload.resources[0].id}/permissions`,
    data: payload.resources[0],
  }),
});

export const apiGetUsersWithDistributePermission = createResourceAction(
  'read',
  {
    resourceType: 'users',
    requestKey: 'apiGetUsersWithDistributePermission',

    api: () => ({
      method: 'GET',
      url: 'manager/v4/users/can_distribute_tokens',
      transformResponse: data => ({
        resources: data.users,
      }),
    }),
  },
);

export const apiGetUsersBySearch = createResourceAction('read', {
  resourceType: 'users',
  requestKey: 'apiGetUsersBySearch',

  api: payload => ({
    method: 'GET',
    url: 'manager/v4/users/search',
    data: payload.resources[0],
    transformResponse: data => ({
      resources: data.users,
    }),
  }),
});

export const apiGetEligibleTokenBudgetUser = createResourceAction('read', {
  resourceType: 'users',
  requestKey: 'apiGetEligibleTokenBudgetUser',

  api: () => ({
    method: 'GET',
    url: 'manager/v4/users/token_budgets',
    transformResponse: data => ({
      resources: data.users,
    }),
  }),
});
