import React from 'react';

import {
  Circle,
  G,
  Path,
  Rect,
} from 'react-primitives-svg';

import Icon from '../Icon';

const RemoveAll = props => (
  <Icon {...props}>
    <G
      fill="none"
      fillRule="evenodd"
    >
      <Path d="M0 0h24v24H0z" />
      <G stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <Path d="M23.5 5.5c0 .552-.686 1-1.533 1H2.033C1.186 6.5.5 6.052.5 5.5v-3c0-.552.686-1 1.533-1h19.934c.847 0 1.533.448 1.533 1v3zM9.887 14.5H2.033c-.847 0-1.533-.448-1.533-1v-3c0-.552.686-1 1.533-1h19.934c.847 0 1.533.448 1.533 1v2.074M11.481 22.5H2.033c-.847 0-1.533-.448-1.533-1v-3c0-.552.686-1 1.533-1h7.4" />
      </G>
      <G transform="translate(11 11)" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <Circle cx="6" cy="6" r="6" />
        <Rect x="2.182" y="4.909" width="7.636" height="2.182" rx="1" />
      </G>
    </G>
  </Icon>
);

export default RemoveAll;
