import React from 'react';

import { gql, useSubscription } from 'arcade-frontend-core/src/helpers/apollo';
import { DIALOG_TYPES } from 'arcade-frontend-core/src/types/notifications.d';

import NotificationsDialogStack from '../../components/NotificationsDialogStack';

const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription {
    notificationAdded {
      id
      title
      content
      dialogType
      imageUrl
      videoType
    }
  }
`;

const NotificationsTypeThreeContainer = () => {
  const [notifications, setNotifications] = React.useState([]);

  const { data, loading } = useSubscription(NOTIFICATIONS_SUBSCRIPTION);

  React.useEffect(() => {
    if (data && !loading) {
      const isNewNotification = !notifications.filter(
        item => item.id === data.notificationAdded.id,
      ).length;

      if (isNewNotification) {
        setNotifications(
          [...notifications, data.notificationAdded].filter(
            notification => notification.dialogType === DIALOG_TYPES.TYPE_THREE,
          ),
        );
      }
    }
  }, [data, loading]);

  const handleHide = id => {
    const nextNotifications = notifications.filter(item => item.id !== id);
    setNotifications(nextNotifications);
  };

  return (
    <NotificationsDialogStack
      notifications={notifications}
      onClose={handleHide}
      onPrimaryAction={handleHide}
      onSecondaryAction={handleHide}
    />
  );
};

NotificationsTypeThreeContainer.displayName = 'NotificationsTypeThreeContainer';

export default NotificationsTypeThreeContainer;
