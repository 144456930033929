import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

import * as GAME_STATUSES from 'arcade-frontend-core/src/types/game-statuses';
import * as GAME_PLAYER_STATUSES from 'arcade-frontend-core/src/types/game-player-statuses';
import { i18nFormatDateTime } from 'arcade-frontend-ui/src/helpers/utils/date';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';

import GamesCardMenu from '../GamesCardMenu';
import GamesRewardTag from '../GamesRewardTag';
import GamesVerificationBadge from '../GamesVerificationBadge';
import GamesFinalScore from '../GamesFinalScore';
import GamesWinnerBadge from '../GamesWinnerBadge';
import GamesGameCardTitle from './GamesGameCardTitle';

const styles = makeStyles(() => ({
  ETALabel: {
    borderRadius: 8,
    backgroundColor: '#676f80',
    fontSize: '16px',
    fontWeight: '400',
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 8,
    paddingLeft: 16,
  },
}));

const StatusRow = ({ children }) => (
  <ArcBox
    display="flex"
    flexDirection="row"
    alignItems="center"
    justifyContent="space-between"
    height={72}
    px={3}
  >
    {children}
  </ArcBox>
);

function GamesGameCardHeader({
  onMenuSelect,
  completedAt,
  expiresAt,
  isManager,
  onClick,
  pendingCount,
  playerStatus,
  rewards,
  rules,
  finalScore,
  showVerify,
  status,
  startsAt,
  type,
  variant,
  verifiedAt,
  winners,
  name,
  hideMenu,
}) {
  const { metric, goal, unit } = rules;

  const classes = styles();

  const getEndTimeByStatus = () => {
    switch (status) {
      case GAME_STATUSES.NEEDS_VERIFICATION:
        return completedAt;
      case GAME_STATUSES.COMPLETED:
        return verifiedAt || completedAt;
      default:
        return expiresAt;
    }
  };

  const menu = hideMenu ? null : (
    <GamesCardMenu onSelect={onMenuSelect} showVerify={showVerify} />
  );

  const headerTitle = (
    <ArcBox
      fontSize="24px"
      lineHeight="150%"
      mb={1}
      onClick={onClick}
      style={onClick ? { cursor: 'pointer' } : undefined}
    >
      <GamesGameCardTitle
        unit={unit}
        goal={goal}
        metric={metric}
        rewards={rewards}
        type={type}
      />
    </ArcBox>
  );

  const endDateTime = getEndTimeByStatus();

  switch (status) {
    case GAME_STATUSES.ACTIVE:
      return (
        <ArcBox mb={1}>
          <StatusRow>
            <GamesRewardTag
              rewards={rewards}
              rewardType={rewards[0] ? rewards[0].type : undefined}
              variant={variant}
            />
            {menu}
          </StatusRow>
          <ArcBox display="flex" flexDirection="column" px={3}>
            {headerTitle}

            <ArcBox pt={1} fontStyle="italic">
              {name}
            </ArcBox>
          </ArcBox>
        </ArcBox>
      );
    case GAME_STATUSES.UPCOMING:
      return (
        <ArcBox mb={3}>
          <StatusRow>
            <ArcBox className={classes.ETALabel}>
              <ArcText size14px textTransform="uppercase">
                Starts In{' '}
                <ArcText size14px isStrong>
                  {moment(startsAt).toNow(true)}
                </ArcText>
              </ArcText>
            </ArcBox>
            {menu}
          </StatusRow>
          <ArcBox display="flex" flexDirection="column" px={3}>
            {headerTitle}
            <GamesRewardTag
              rewards={rewards}
              rewardType={rewards[0] ? rewards[0].type : undefined}
              variant={variant}
            />
          </ArcBox>
        </ArcBox>
      );
    case GAME_STATUSES.COMPLETED:
    case GAME_STATUSES.NEEDS_VERIFICATION:
      return (
        <ArcBox mb={2}>
          <StatusRow>
            {status === GAME_STATUSES.NEEDS_VERIFICATION ? (
              <GamesVerificationBadge
                isManager={isManager}
                pendingCount={pendingCount}
              />
            ) : (
              <ArcBox
                lineHeight="165%"
                fontWeight="500"
                bgcolor="#00A77C"
                color="common.white"
                fontSize={14}
                px={1.75}
                py={0.5}
                borderRadius={7}
              >
                <ArcText>{'Completed'}</ArcText>
              </ArcBox>
            )}
            {menu}
          </StatusRow>

          <ArcBox display="flex" flexDirection="column" px={3}>
            {headerTitle}
            <ArcBox pb={1} fontStyle="italic">
              {name}
            </ArcBox>
            <ArcText>
              {endDateTime
                ? `${i18nFormatDateTime(startsAt)} - ${i18nFormatDateTime(
                    endDateTime,
                  )}`
                : `Started ${i18nFormatDateTime(startsAt)}`}
            </ArcText>

            <ArcBox
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              justifyContent="space-between"
              mt={1.5}
            >
              <GamesWinnerBadge playerStatus={playerStatus} winners={winners} />

              <GamesFinalScore
                playerStatus={playerStatus}
                score={finalScore}
                unit={rules.unit}
              />
            </ArcBox>
          </ArcBox>
        </ArcBox>
      );
    default:
      return null;
  }
}

GamesGameCardHeader.displayName = 'GamesGameCardHeader';

GamesGameCardHeader.propTypes = {
  completedAt: PropTypes.string,
  expiresAt: PropTypes.string,
  type: PropTypes.string,
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ),
  rules: PropTypes.shape({
    metric: PropTypes.string,
    goal: PropTypes.number,
    unit: PropTypes.string,
  }),
  name: PropTypes.string,
  onClick: PropTypes.func,
  onMenuSelect: PropTypes.func,
  finalScore: PropTypes.number,
  startsAt: PropTypes.string,
  status: PropTypes.string,
  showVerify: PropTypes.bool,
  variant: PropTypes.oneOf(['text-dark', 'text-light']),
  verifiedAt: PropTypes.string,
  winners: PropTypes.arrayOf(PropTypes.any),
  isManager: PropTypes.bool,
  pendingCount: PropTypes.number,
  playerStatus: PropTypes.oneOf(GAME_PLAYER_STATUSES.ALL_PARTICIPANT_TYPES),
  hideMenu: PropTypes.bool,
};

GamesGameCardHeader.defaultProps = {
  type: '',
  completedAt: '',
  expiresAt: '',
  rewards: [],
  rules: {
    metric: 0,
    goal: '',
    unit: '$',
  },
  name: '',
  onClick: null,
  onMenuSelect: global.noop,
  finalScore: 0,
  startsAt: '',
  status: '',
  showVerify: false,
  variant: undefined,
  verifiedAt: '',
  winners: [],
  isManager: false,
  pendingCount: 0,
  playerStatus: null,
  hideMenu: false,
};

StatusRow.propTypes = {
  children: PropTypes.node,
};

StatusRow.defaultProps = {
  children: null,
};

export default GamesGameCardHeader;
