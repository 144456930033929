import React from 'react';
import PropTypes from 'prop-types';
// import * as GAME_SCENE_SIZES from 'arcade-frontend-core/src/types/game-scene-sizes';
import ArcView from '../../primitives/ArcView/ArcView';
import { distribute } from '../../helpers/utils/distribution';
import PlayerPortrait from '../PlayerPortrait';


const offset = (p, offsetLeft, offsetTop) => ({
  position: 'absolute',
  left: p.x - offsetLeft,
  top: p.y - offsetTop,
});

const UserProgress = ({
  index,
  player,
  slots,
  // size,
  offsetLeft,
  offsetTop,
}) => {
  const slot = slots[index % (slots.length)];
  return (
    <ArcView style={offset(slot, offsetLeft, offsetTop)}>
      <PlayerPortrait
        title={player.catch_up || 'You\'re doing great!'}
        isSelf={player.is_self}
        image={player.image}
      />
    </ArcView>
  );
};

const playerType = PropTypes.shape({
  image: PropTypes.string.isRequired,
  catch_up: PropTypes.string,
  is_self: PropTypes.bool.isRequired,
});

UserProgress.propTypes = {
  slots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  index: PropTypes.number.isRequired,
  player: playerType.isRequired,
  // size: PropTypes.string.isRequired,
  offsetLeft: PropTypes.number.isRequired,
  offsetTop: PropTypes.number.isRequired,
};


export const displaysScoresType = {
  scores: PropTypes.arrayOf(playerType),
  goal: PropTypes.number,
};

const PositionedPlayers = ({
  slots,
  scores,
  goal,
  // size,
  offsetLeft,
  offsetTop,
}) => {
  const target = goal;
  const reversed = [...scores].reverse().slice(0, slots.length);
  const distribution = distribute(slots.length, reversed, target);

  return (
    <React.Fragment>
      {distribution.map((player, index) => player && (
        <UserProgress
          key={player.id}
          slots={slots}
          index={index}
          player={player}
          // size={size}
          offsetTop={offsetTop}
          offsetLeft={offsetLeft}
        />
      ))}
    </React.Fragment>
  );
};

export default PositionedPlayers;

PositionedPlayers.propTypes = {
  slots: PropTypes.arrayOf(PropTypes.shape({})),
  // size: PropTypes.string,
  goal: PropTypes.number,
  scores: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
    catch_up: PropTypes.string,
    is_self: PropTypes.bool.isRequired,
  })),
  offsetLeft: PropTypes.number,
  offsetTop: PropTypes.number,
};

PositionedPlayers.defaultProps = {
  slots: [],
  // size: GAME_SCENE_SIZES.SMALL,
  goal: 0,
  scores: [],
  offsetLeft: 0,
  offsetTop: 0,
};
