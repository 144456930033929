import React from 'react';

import ArcPropTypes from '../arc/propTypes';

// https://medium.com/@tomaszferens/delay-unmounting-of-the-component-in-react-8d6f6e73cdc
function withDelayedUnmount(Component) {
  return class extends React.Component {
    static propTypes = {
      delayTime: ArcPropTypes.number,
      isMounted: ArcPropTypes.bool.isRequired,
    };

    static defaultProps = {
      delayTime: 50,
    };

    state = {
      shouldRender: this.props.isMounted,
    };

    componentWillReceiveProps(nextProps) {
      if (!nextProps.isMounted && this.props.isMounted) {
        setTimeout(
          () => this.setState({ shouldRender: false }),
          this.props.delayTime,
        );
      } else if (nextProps.isMounted && !this.props.isMounted) {
        this.setState({ shouldRender: true });
      }
    }

    render() {
      return this.state.shouldRender ? <Component {...this.props} /> : null;
    }
  };
}

export default withDelayedUnmount;
