import React from 'react';
import PropTypes from 'prop-types';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcNoResultsMessage from 'arcade-frontend-ui/src/components/ArcNoResultsMessage';
import Loot from 'arcade-frontend-ui/src/assets/Loot';
import Cloud from 'arcade-frontend-ui/src/assets/Cloud';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcLink from 'arcade-frontend-ui/src/elements/ArcLink';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';

const STRINGS = {
  MANAGER_SUBHEADER:
    'You have no games scheduled for your employees. Easily launch over six exciting game types designed to keep everyone engaged and productive.',
};

const UserSubHeader = () => (
  <ArcText>Your manager has no games scheduled at this time.</ArcText>
);

const LootIcon = () => (
  <ArcBox
    m="auto"
    width={175}
    display="flex"
    flexDirection="column"
    alignItems="center"
  >
    <Cloud height={175} width={175} />
    <ArcBox mt="-90px" mb={2}>
      <Loot height={100} width={135} />
    </ArcBox>
  </ArcBox>
);

const GamesUpcomingEmpty = ({ isManager }) => (
  <ArcBox maxWidth={400}>
    <ArcNoResultsMessage
      showShape={false}
      iconComponent={LootIcon}
      header="No Upcoming Games"
      subheader={isManager ? STRINGS.MANAGER_SUBHEADER : <UserSubHeader />}
    />
    {isManager && (
      <ArcBox display="flex" flexDirection="row" justifyContent="center" mt={4}>
        <ArcBox display="flex" flexDirection="row" alignItems="center" mr={2}>
          <ArcLink size="inherit" href="https://help.arcade.co/games">
            Learn More
          </ArcLink>
        </ArcBox>
        <ArcButton variant="contained" color="primary">
          Create a game
        </ArcButton>
      </ArcBox>
    )}
  </ArcBox>
);

GamesUpcomingEmpty.propTypes = {
  isManager: PropTypes.bool,
};

GamesUpcomingEmpty.defaultProps = {
  isManager: false,
};

export default GamesUpcomingEmpty;
