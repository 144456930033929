import React from 'react';
import PropTypes from 'prop-types';

function BillingChargebeePortal({ onClose }) {
  React.useEffect(() => {
    const shouldInitChargebee = window.Chargebee && !window.Chargebee.inited;

    if (shouldInitChargebee) {
      window.Chargebee.init({
        site: 'arcade',
      });
    }

    if (window.Chargebee && window.Chargebee.inited) {
      const instance = window.Chargebee.getInstance();
      instance.createChargebeePortal().open({
        close: onClose,
      });
    }

    return () => {
      if (window.Chargebee && window.Chargebee.inited) {
        const instance = window.Chargebee.getInstance();
        instance.closeAll();
      }
    };
  }, []);

  return null;
}

BillingChargebeePortal.displayName = 'BillingChargebeePortal';

BillingChargebeePortal.propTypes = {
  onClose: PropTypes.func,
};

BillingChargebeePortal.defaultProps = {
  onClose: global.noop,
};

export default BillingChargebeePortal;
