import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/styles';

import Collapse from '@material-ui/core/Collapse';
import ChevronRight from '@material-ui/icons/ChevronRight';

import ArcBox from '../ArcBox';
import ArcText from '../../primitives/ArcText';


function ArcCollapsibleExplainer({
  color,
  body,
  inlineHint,
  title,
  maxWidth,
  ...props
}) {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <ArcBox
      display="flex"
      flexDirection="column"
      {...props}
    >
      <ArcBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={handleClick}
        color={`${color}.700`}
        mb={1}
      >
        <ChevronRight
          color="inherit"
          style={{
            transition: theme.transitions.create('transform'),
            transform: isExpanded ? 'rotate(90deg)' : 'rotate(0)',
          }}
        />
        <ArcBox
          component={ArcText}
          fontSize={12}
          lineHeight="150%"
        >
          {inlineHint}
        </ArcBox>
      </ArcBox>

      <Collapse in={isExpanded}>
        <ArcBox
          display="flex"
          flexDirection="column"
          bgcolor={`${color}.200`}
          color={`${color}.700`}
          borderRadius={8}
          padding={2}
          justifyContent="left"
          fontSize={12}
          lineHeight="150%"
          maxWidth={maxWidth}
        >
          {!!title
            && <ArcText isStrong>{title}</ArcText>
            }
          <ArcText>{body}</ArcText>
        </ArcBox>
      </Collapse>

    </ArcBox>
  );
}

ArcCollapsibleExplainer.displayName = 'ArcCollapsibleExplainer';

ArcCollapsibleExplainer.propTypes = {
  color: PropTypes.string,
  body: PropTypes.string,
  inlineHint: PropTypes.string,
  maxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  title: PropTypes.string,
};

ArcCollapsibleExplainer.defaultProps = {
  color: 'purple',
  body: '',
  inlineHint: 'What is this?',
  maxWidth: 288,
  title: '',
};

export default ArcCollapsibleExplainer;
