export const baseFontSize = 16;

export const getFontSize = relative => relative * baseFontSize;

export const getLineHeight = relative => `${getFontSize(relative)}px`;

export default {
  baseFontSize,
  getFontSize,
  getLineHeight,
};
