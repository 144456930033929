import React from 'react';
import Dropzone from 'react-dropzone';
import Clear from '@material-ui/icons/Clear';

import {
  ArcView,
  ArcText,
  createWithStyles,
  ArcPropTypes,
  ArcIconButton,
} from 'arcade-frontend-ui';

import DropTarget from '../DropTarget';

const styles = {
  Wrapper: () => ({
    root: {
      position: 'relative',
    },
  }),
  UploadError: theme => ({
    root: {
      backgroundColor: theme.palette.red[100],
      color: theme.palette.red[700],
      border: `solid 2px ${theme.palette.red[200]}`,
      borderRadius: 4,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  }),
  ClearErrors: theme => ({
    root: {
      position: 'absolute',
      right: 10,
      top: 10,
      color: theme.palette.red[700],
    },
  }),
};

const Wrapper = createWithStyles(styles.Wrapper)(ArcView);
const UploadError = createWithStyles(styles.UploadError)(ArcView);
const ClearErrors = createWithStyles(styles.ClearErrors)(ArcIconButton);

class FileDropper extends React.Component {
  static propTypes = {
    filesSelected: ArcPropTypes.func,
    children: ArcPropTypes.children,
    withOpen: ArcPropTypes.func,
    acceptedExtensions: ArcPropTypes.arrayOf(ArcPropTypes.string),
    acceptedMimetypes: ArcPropTypes.arrayOf(ArcPropTypes.string),
    maxSize: ArcPropTypes.number,
  };

  static defaultProps = {
    filesSelected: ArcPropTypes.noop,
    children: null,
    withOpen: ArcPropTypes.noop,
    acceptedExtensions: [
      'key',
      'pdf',
      'docx',
      'xls',
      'xlsx',
      'numbers',
      'pages',
      'md',
      'csv',
      'doc',
      'odt',
      'html',
      'ods',
      'txt',
    ],
    acceptedMimetypes: ['image/*', 'video/*', 'text/plain'],
    maxSize: 20000000,
  };

  state = {
    invalidFiles: null,
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      this.setState({ error: rejectedFiles.map(file => file.name) });
    }

    if (this.props.filesSelected && acceptedFiles.length > 0) {
      this.props.filesSelected({ acceptedFiles });
    }
  };

  get accept() {
    const { acceptedExtensions, acceptedMimetypes } = this.props;

    return [...acceptedExtensions.map(e => `.${e}`), ...acceptedMimetypes].join(
      ', ',
    );
  }

  clearErrors = () => {
    this.setState({ error: null });
  };

  render() {
    const { error } = this.state;
    const { maxSize } = this.props;

    return (
      <Wrapper>
        {error && (
          <UploadError>
            <ClearErrors onClick={this.clearErrors}>
              <Clear />
            </ClearErrors>
            <ArcView>
              <ArcText>{"Sorry! We couldn't process that file"}</ArcText>
            </ArcView>
            <ArcView paddingRight32>
              <ArcText isSmall>
                We accept all common picture, video and business file types, up
                to {Math.round(maxSize * 0.000001, 1)} mb in size.
              </ArcText>
            </ArcView>
          </UploadError>
        )}
        <Dropzone
          onDrop={this.onDrop}
          onClick={evt => evt.preventDefault()}
          accept={this.accept}
          maxSize={this.props.maxSize}
          multiple={false}
        >
          {targetProps => (
            <DropTarget {...targetProps}>
              {this.props.withOpen(targetProps.open)}
            </DropTarget>
          )}
        </Dropzone>
      </Wrapper>
    );
  }
}

export default FileDropper;
