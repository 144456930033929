/* eslint-disable */
import {
  axios,
  Resource,
} from 'arcade-frontend-ui';

import { types } from '../actions';

const handleWebviewSetIsMuted = (state, action) => {
  const { payload } = action;

  return payload;
};

const handleAppGetMuteSuccess = (state, action) => action.payload.data;

const initialState = false;

const isMuted = (state = initialState, action) => {
  switch (action.type) {
    case types.WEBVIEW_SET_IS_MUTED:
      return handleWebviewSetIsMuted(state, action);
    case types.APP_GET_MUTE.SUCCESS:
      return handleAppGetMuteSuccess(state, action);
    case types.APP_TOGGLE_MUTE.SUCCESS:
      return handleAppGetMuteSuccess(state, action);
    default:
      return state;
  }
};

export const getIsMuted = state => state.app.isMuted;

export default isMuted;