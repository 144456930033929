/* eslint-disable */
// <TODO> Use helpers to generate the palette

export const white     = "#ffffff";
export const black     = "#000000";
export const black1   = black;
export const black2   = "#23282c";
export const black3   = "#3b3b3b";
export const blue1    = "#0D74AD";
export const blue2    = "#0290C3";
export const blue3    = "#00A3D6";
export const blue4    = "#01C0E6";
export const blue5    = "#02CDEB";
export const green1   = "#00967E";
export const green2   = "#00AB8B";
export const green3   = "#03C8A2";
export const green4   = "#07D2B6";
export const green5   = "#03DAC9";
export const red1     = "#CF1D1A";
export const red2     = "#EB2219";
export const red3     = "#F92514";
export const red4     = "#FF261C";
export const red5     = "#FF4E32";
export const yellow1  = "#FF8F14";
export const yellow2  = "#FFAE14";
export const yellow3  = "#FFCA13";
export const yellow4  = "#FFE215";
export const yellow5  = "#FFEA54";
export const grey1    = "#353535";
export const grey2    = "#666666";
export const grey3    = "#8C8C8C";
export const grey4    = "#B4B4B4";
export const grey5    = "#C4C4C4";
export const grey6    = "#D8D8D8";
export const grey7    = "#F3F3F3";
export const grey8    = "#F8F8F8";
export const gray1    = grey1;
export const gray2    = grey2;
export const gray3    = grey3;
export const gray4    = grey4;
export const gray5    = grey5;
export const gray6    = grey6;
export const gray7    = grey7;
export const gray8    = grey8;


// Core color palette
export const blue    = blue2;
export const green   = green1;
export const red     = red1;
export const yellow  = yellow1;


export const brand            = blue;
export const brandPrimary     = brand;
export const brandSecondary   = green;
export const grayDark         = black2;

// components
export const borderColorLight1    = grey7;
export const lightBgColor1        = white;
export const lightBgColor2        = grey8;
export const headerTextColor      = black2;
export const defaultTextColor     = grey2;
export const defaultHeadingColor  = black3;

export const arcTextColor  = 'rgba(0,0,0,0.87)';
export const arcSubheaderColor  = 'rgba(0,0,0,0.54)';

export const palette = [
  blue,
  green,
  red,
  yellow,
];
