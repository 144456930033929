const GENERATED_AMOUNT_OFFSET = 4;

const getSum = (total, num) => total + num;

const generateCreated = (amount, scale = 1) => {
  let idx;
  const created = [];

  for (idx = GENERATED_AMOUNT_OFFSET; idx < (amount + GENERATED_AMOUNT_OFFSET); idx += 1) {
    const isNegative = Math.random() > 0.5;

    let value = (idx + (Math.random() * 8) + 4) - (idx / 2);

    if (isNegative) {
      value = (value - (Math.random() * 8)) + 4;
    }

    const scaledValue = Math.floor(value) * scale * 2;

    created.push(scaledValue);
  }

  return {
    data: created,
    total: created.reduce(getSum),
  };
};

const generateCreatedDates = (scale = 1) => ({
  today: generateCreated(5, scale),
  yesterday: generateCreated(5, scale),
  selectedDay: generateCreated(4, scale),
  pastWeek: generateCreated(7, scale),
  currentWeek: generateCreated(6, scale),
  currentMonth: generateCreated(30, scale),
  dateRange: generateCreated(45, scale),
});

const generateViewingTypesWithCreatedDates = (scale = 1) => ({
  organisation: generateCreatedDates(scale * 10),
  region: generateCreatedDates(scale * 4),
  team: generateCreatedDates(scale),
});

const sampleData = {
  activities: generateViewingTypesWithCreatedDates(1),
  chests: generateViewingTypesWithCreatedDates(0.8),
  comments: generateViewingTypesWithCreatedDates(1.5),
  liked: generateViewingTypesWithCreatedDates(3.5),
  messages: generateViewingTypesWithCreatedDates(2.5),
};

export default sampleData;
