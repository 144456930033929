import sagas from 'arcade-frontend-core/src/helpers/sagas';
import { types } from '../actions';

import { api } from '../resources';

const takeLatest = [
  {
    type: types.MANAGE_METRICS_ENTITY_INDEX,
    call: api.manageMetricsEntityIndex,
  },
];

const metrics = takeLatest.map(sagas.makeTakeLatest);

export default metrics;
