import React from 'react';
import PropTypes from 'prop-types';

import {
  ArcButton,
  ArcImage,
  ArcText,
  ArcView,
  createWithStyles,
} from 'arcade-frontend-ui';

import IconLock from '@material-ui/icons/Lock';

const styles = {
  ListItem: theme => ({
    root: {
      borderBottom: '1px solid #f3f3f3',

      padding: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        padding: 0,
      },
    },
  }),

  UserImage: theme => ({
    root: {
      margin: theme.spacing(1),
      height: theme.spacing(5),
      width: theme.spacing(5),
    },
  }),

  SubTitleText: () => ({
    root: {
      color: '#b4b4b4',
    },
  }),
};

const ListItem = createWithStyles(styles.ListItem)(ArcView);
const UserImage = createWithStyles(styles.UserImage)(ArcImage);
const SubTitleText = createWithStyles(styles.SubTitleText)(ArcText);

class ManagePeopleListItem extends React.PureComponent {
  static propTypes = {
    onClickPermissions: PropTypes.func,
    onClickProfile: PropTypes.func,
    person: PropTypes.shape({
      companyIdentifier: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
      currentBalance: PropTypes.number,
      email: PropTypes.string,
      firstName: PropTypes.string,
      id: PropTypes.string,
      imageUrl: PropTypes.string,
      lastName: PropTypes.string,
      locked: PropTypes.bool,
      teamName: PropTypes.string,
    }),
  };

  static defaultProps = {
    onClickPermissions: global.noop,
    onClickProfile: global.noop,
    person: null,
  };

  handleClick = e => this.props.onClickProfile(e, this.props.person);

  handleClickPermissions = e => this.props.onClickPermissions(e, this.props.person);

  handlePersonClick = () => {
    if (window.ngRootScope) {
      window.ngRootScope.$broadcast('arcade:react:quicklinks', this.props.person.id);
    }
  };

  render() {
    const {
      person,
    } = this.props;

    const fullName = `${person.firstName} ${person.lastName}`;

    return (
      <ListItem>
        <ArcView
          aria-label={`${fullName} quicklink`}
          role="button"
          row
          align="center"
          marginRight="8"
          onClick={this.handlePersonClick}
          cursor="pointer"
        >
          <ArcView>
            <UserImage
              src={person.imageUrl}
            />
          </ArcView>
          <ArcView flexGrow="1" marginRight="4">
            <ArcText>
              {person.firstName}
              {' '}
              {person.lastName}
            </ArcText>
            <SubTitleText isSmall>
              {person.teamName}
            </SubTitleText>
          </ArcView>
          {person.locked && (
            <IconLock />
          )}
        </ArcView>

        <ArcView flexGrow="100" />

        <ArcView row align="center">
          <ArcButton
            size="small"
            onClick={this.handleClick}
          >
            Profile
          </ArcButton>
          <ArcButton
            size="small"
            onClick={this.handleClickPermissions}
          >
            Permissions
          </ArcButton>
        </ArcView>
      </ListItem>
    );
  }
}

export default ManagePeopleListItem;
