import React, { useState, useEffect } from 'react';
import { Animated } from 'react-native';

import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';

import NotificationsDialogStackCard from './NotificationsDialogStackCard';

export interface Notification {
  id: string;
  type?: string;
  title?: string;
  content?: string;
  imageUrl?: string;
}

export interface NotificationsDialogStackProps {
  notifications?: Notification[];
  onClose?: (id: string) => void;
  onPrimaryAction?: (id: string) => void;
  onSecondaryAction?: (id: string) => void;
}

const NotificationsDialogStack: React.FC<NotificationsDialogStackProps> = ({
  notifications = [],
  onClose = global.noop,
  onPrimaryAction = global.noop,
  onSecondaryAction = global.noop,
}) => {
  const [shouldShowBackground, setShouldShowBackground] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [backgroundOpacity] = useState(new Animated.Value(0));

  const boxInterpolation = backgroundOpacity.interpolate({
    inputRange: [0, 1],
    outputRange: ['rgba(0, 0, 0, 0.0)', 'rgba(0, 0, 0, 0.6)'],
  });

  useEffect(() => {
    if (notifications.length !== notificationCount) {
      if (notifications.length === 1) {
        setShouldShowBackground(true);
      }
    }
    setNotificationCount(notifications.length);
  }, [notifications]);

  useEffect(() => {
    Animated.timing(backgroundOpacity, {
      toValue: shouldShowBackground ? 1 : 0,
      duration: shouldShowBackground ? 300 : 150,
      useNativeDriver: false,
    }).start();
  }, [shouldShowBackground]);

  const handleDismissCard = (id: string, callback: (id: string) => void) => {
    if (notifications.length === 1) {
      setShouldShowBackground(false);
      setTimeout(() => {
        callback(id);
      }, 320);
    } else {
      callback(id);
    }
  };

  const handleClose = (id: string) => handleDismissCard(id, onClose);

  const handlePrimaryAction = (id: string) =>
    handleDismissCard(id, onPrimaryAction);

  const handleSecondaryAction = (id: string) =>
    handleDismissCard(id, onSecondaryAction);

  if (!notifications.length || window.arcPostMessage) {
    return null;
  }

  return (
    <ArcViewBox
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <Animated.View
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: boxInterpolation,
        }}
      >
        {notifications.map(notification => (
          <NotificationsDialogStackCard
            key={notification.id}
            notification={notification}
            onClose={handleClose}
            onPrimaryAction={handlePrimaryAction}
            onSecondaryAction={handleSecondaryAction}
          />
        ))}
      </Animated.View>
    </ArcViewBox>
  );
};

export default NotificationsDialogStack;
