import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import track from 'arcade-frontend-core/src/helpers/track';
import {
  ArcTokenBalanceCard,
  ArcView,
  ArcIconButton,
  ArcTokenTopUpDialog,
} from 'arcade-frontend-ui';
import getRequestStatusFromResourceStatus from 'arcade-frontend-core/src/helpers/getRequestStatusFromResourceStatus';
import { getCurrentUserPermission } from 'arcade-frontend-core/src/reducers/user';
import { getHasToppedUp } from 'arcade-frontend-features/src/reducers/manage/tokens/hasToppedUp';
import { getTopUpError } from 'arcade-frontend-features/src/reducers/manage/tokens/topUpError';
import { getTopUpResponse } from 'arcade-frontend-features/src/reducers/manage/tokens/topUpResponse';
import { getTopUpUrl } from 'arcade-frontend-features/src/reducers/manage/tokens/topUpUrl';

const useStyles = makeStyles(theme => ({
  balanceWrapper: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    marginBottom: theme.spacing(1),
    flexDirection: 'row',
  },
  dropdownWrapper: {
    marginLeft: 'auto',
  },
  ellipsisIcon: {
    color: theme.palette.grey.main,
  },
}));

const TokenBudgetFundsTile = ({
  canRequestTokenTopup,
  data,
  onViewTokenHistory,
  onSubmitRequestTopUp,
  getAccountRequestStatus,
  postAccountTopUpStatus,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedDialog, setOpenedDialog] = useState(null);
  const classes = useStyles();

  const canManageTokens = useSelector(state =>
    getCurrentUserPermission(state, 'manage_tokens'),
  );

  const hasToppedUp = useSelector(getHasToppedUp);
  const topUpError = useSelector(getTopUpError);
  const topUpResponse = useSelector(getTopUpResponse);
  const topUpUrl = useSelector(getTopUpUrl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    track(`close dialog ${openedDialog}`, {
      payload: { event: `token-budgets:close-dialog-${openedDialog}` },
    });
    setOpenedDialog(null);
  };

  const handleOpenDialog = dialogName => {
    track(`open dialog ${dialogName}`, {
      payload: { event: `token-budgets:open-dialog-${dialogName}` },
    });
    setOpenedDialog(dialogName);
  };

  const handleTopUpContinue = () => {
    if (global?.window) {
      window.open(topUpUrl);
    }

    handleCloseDialog();
  };

  const companyAccount =
    data && data.find(account => account.type === 'holding');

  const isNative = !!window.arcPostMessage;

  const shouldRenderAddMoreTokens =
    !isNative && companyAccount?.canBeToppedUp && canRequestTokenTopup;

  return (
    <ArcView
      data-testid="token-budget-funds-tile"
      className={classes.balanceWrapper}
    >
      <ArcTokenBalanceCard
        color="none"
        accountName="Current Balance"
        balance={companyAccount ? companyAccount.balance : 0}
        isFetching={getAccountRequestStatus.pending}
      />

      <ArcView className={classes.dropdownWrapper}>
        <ArcIconButton
          data-testid="token-budget-funds-menu-icon"
          label="Manage token budgets menu"
          onClick={handleClick}
        >
          {canManageTokens && <MoreVertIcon className={classes.ellipsisIcon} />}
        </ArcIconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {shouldRenderAddMoreTokens && (
            <MenuItem
              data-testid="token-budget-funds-add-tokens-button"
              onClick={() => handleOpenDialog('token-top-up-dialog')}
            >
              Add More Tokens
            </MenuItem>
          )}
          <MenuItem onClick={onViewTokenHistory}>View History</MenuItem>
        </Menu>
      </ArcView>
      <ArcTokenTopUpDialog
        open={openedDialog === 'token-top-up-dialog'}
        hasToppedUp={hasToppedUp}
        onClose={handleCloseDialog}
        onSubmit={onSubmitRequestTopUp}
        isFetching={postAccountTopUpStatus.pending}
        requestStatus={getRequestStatusFromResourceStatus(
          postAccountTopUpStatus,
        )}
        topUpError={topUpError}
        topUpResponse={topUpResponse}
        topUpUrl={topUpUrl}
        onTopUpContinue={handleTopUpContinue}
      />
    </ArcView>
  );
};

TokenBudgetFundsTile.displayName = 'TokenBudgetFundsTile';

TokenBudgetFundsTile.propTypes = {
  canRequestTokenTopup: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.any),
  getAccountRequestStatus: PropTypes.shape({
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    failed: PropTypes.bool,
    succeeded: PropTypes.bool,
  }),
  postAccountTopUpStatus: PropTypes.shape({
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    failed: PropTypes.bool,
    succeeded: PropTypes.bool,
  }),
  onViewTokenHistory: PropTypes.func,
  onSubmitRequestTopUp: PropTypes.func,
};

TokenBudgetFundsTile.defaultProps = {
  canRequestTokenTopup: false,
  data: [],
  getAccountRequestStatus: {},
  postAccountTopUpStatus: {},
  onViewTokenHistory: global.noop,
  onSubmitRequestTopUp: global.noop,
};

export default TokenBudgetFundsTile;
