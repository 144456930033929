import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import InvitePersonForm from 'arcade-frontend-features/src/forms/people/InvitePersonForm';
import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import { resources as userResources } from 'arcade-frontend-core/src/resources/users';

import { resources as teamResources } from '../../resources/teams';

const ANIMATION_DELAY = 500;

function PeopleInvitePersonFormContainer({
  open,
  onClose,
  onInvitePersonSuccess,
  identifierLabel,
  canGeofence,
}) {
  const currentUser = useSelector(getCurrentUser);
  const hasFeatureRemainingSeats = useFeatureFlag(
    FEATURE_FLAGS.USER_COUNT_LIMIT,
  );
  const apiGetTeams = useResourceAction(teamResources.apiGetTeams);
  const apiPostUserInvite = useResourceAction(userResources.apiPostUserInvite);
  const apiGetCompanyIdentifierExplainer = useResourceAction(
    userResources.apiGetCompanyIdentifierExplainer,
  );

  const [remainingSeats, setRemainingSeats] = useState();
  const [requestErrorMessage, setRequestErrorMessage] = useState('');

  useEffect(() => {
    if (!currentUser.endpoint) {
      return;
    }

    apiGetCompanyIdentifierExplainer.requestData().catch(() => {
      apiGetCompanyIdentifierExplainer.onStatusReset();
    });

    apiGetTeams.requestData().catch(() => {
      apiGetTeams.onStatusReset();
    });

    fetch(`${currentUser.endpoint}/v2/entities/remaining_seats`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-User-Token': currentUser.token,
        'X-User-Email': currentUser.email,
      },
    })
      .then(response => response.json())
      .then(response => setRemainingSeats(response.remaining_seats));
  }, [currentUser]);

  const resetErrorState = resourceAction => {
    setRequestErrorMessage('');
    resourceAction.onStatusReset();
    resourceAction.onErrorReset();
  };

  const requestMoreSeats = () =>
    fetch(
      `${currentUser.endpoint}/v2/support_requests/account_limit_questions`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-User-Token': currentUser.token,
          'X-User-Email': currentUser.email,
        },
      },
    );

  const handleClose = () => {
    setRequestErrorMessage('');
    onClose();
  };
  const handleSubmit = (values, formProps) => {
    resetErrorState(apiPostUserInvite);
    apiPostUserInvite
      .requestData(
        { resources: [{ user: values }] },
        {
          validateStatus: status => {
            return status === 400 || (status >= 200 && status < 300); // default
          },
        },
      )
      .then(res => {
        if (res.requestProperties.statusCode === 400) {
          setRequestErrorMessage(res.data.message);
          formProps.setFieldValue('email', '');
          apiPostUserInvite.onStatusReset();
          apiPostUserInvite.onErrorReset();
        } else {
          onInvitePersonSuccess();
          resetErrorState(apiPostUserInvite);
          setTimeout(() => {
            onClose();
            formProps.resetForm();
          }, ANIMATION_DELAY);
        }
      })
      .catch(err => {
        setRequestErrorMessage(
          'Unable to invite user. Please try again later.',
        );
        setTimeout(() => {
          resetErrorState(apiPostUserInvite);
        }, ANIMATION_DELAY * 2);
      });
  };

  const teams = apiGetTeams.hasData ? apiGetTeams.data.resources : [];

  const companyIdentifierExplainer = apiGetCompanyIdentifierExplainer.hasData
    ? apiGetCompanyIdentifierExplainer.data.resources
    : {};

  return (
    <InvitePersonForm
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      companyIdentifierExplainer={companyIdentifierExplainer}
      teams={teams}
      statusGetTeams={apiGetTeams.status}
      statusInviteUser={apiPostUserInvite.status}
      hasGeofenceField={canGeofence}
      identifierLabel={identifierLabel}
      hasFeatureRemainingSeats={hasFeatureRemainingSeats}
      remainingSeats={remainingSeats}
      requestMoreSeats={requestMoreSeats}
      requestErrorMessage={requestErrorMessage}
      setRequestErrorMessage={setRequestErrorMessage}
    />
  );
}

PeopleInvitePersonFormContainer.displayName = 'PeopleInvitePersonFormContainer';

PeopleInvitePersonFormContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onInvitePersonSuccess: PropTypes.func,
  identifierLabel: PropTypes.string,
  canGeofence: PropTypes.bool,
};

PeopleInvitePersonFormContainer.defaultProps = {
  open: false,
  onClose: global.noop,
  onInvitePersonSuccess: global.noop,
  identifierLabel: 'Company Identifier',
  canGeofence: false,
};

export default PeopleInvitePersonFormContainer;
