import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const StatsLine = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.25 18.25L8.586 10a1.042 1.042 0 011.432-.107l4.464 3.72c.43.351 1.06.303 1.43-.11L22.75 5.75"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.812 5.75h6.938v5.979"
    />
  </Icon>
);

export default StatsLine;
