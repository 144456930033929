import ArcPropTypes from '../helpers/arc/propTypes';
import ArcConsole from '../helpers/arc/console';

export const { PLATFORMS } = ArcPropTypes;
export const allPlatforms = Object.keys(PLATFORMS).map(platform => PLATFORMS[platform]);

let currentArcPlatform = PLATFORMS.DEVELOPMENT;

export const getArcPlatform = () => currentArcPlatform;

export const setArcPlatform = (arcPlatform) => {
  if (allPlatforms.indexOf(arcPlatform) > -1) {
    currentArcPlatform = arcPlatform;
  } else {
    ArcConsole.error('ArcStyles.setArcPlatform invalid platform', arcPlatform);
  }
};
