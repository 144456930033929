import React from 'react';
import { connect } from 'react-redux';

import {
  ArcPropTypes,
  ArcMain,
  ArcMainView,
  ArcScroll,
} from 'arcade-frontend-ui';

import RewardsShopContainer from '../containers/RewardsShopContainer';
import { getIsFetching } from '../reducers/isFetching';
import { getProducts } from '../reducers/products';

class RewardView extends React.PureComponent {
  static propTypes = {
    products: ArcPropTypes.arrayOf(ArcPropTypes.object),
    isFetching: ArcPropTypes.bool,
    userBalance: ArcPropTypes.number,
  };

  static defaultProps = {
    products: [],
    isFetching: false,
    userBalance: 0,
  };

  state = {
    hasLoader: !this.props.products.length,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isFetching && !this.props.products.length) {
      this.showLoader();
    } else if (this.state.hasLoader && !nextProps.isFetching) {
      this.hideLoader();
    }
  }

  showLoader() {
    this.setState({ hasLoader: true });
  }

  hideLoader() {
    setTimeout(() => this.setState({ hasLoader: false }), 250);
  }

  render() {
    return (
      <ArcMain>
        <ArcMainView isViewing hasNav={false}>
          <ArcScroll>
            <RewardsShopContainer
              userBalance={this.props.userBalance}
              updateUserBalance={window.updateUserBalance}
            />
          </ArcScroll>
        </ArcMainView>
      </ArcMain>
    );
  }
}

const getState = state => ({
  isFetching: getIsFetching(state),
  products: getProducts(state),
});

export default connect(getState)(RewardView);
