const engagement = {
  dateRange: {
    type: 'currentMonth',
    fromDate: undefined,
    toDate: undefined,
  },
};

export default engagement;

export const getDateRange = state => state.newForms.engagement.dateRange;
