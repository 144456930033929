import React from 'react';

import { Platform } from 'react-primitives';

import ArcPropTypes from '../helpers/arc/propTypes';
import ArcView from '../primitives/ArcView/ArcView';
import { createWithStyles } from '../styles';

const styles = {
  ArcFormFieldView: theme => ({
    root: {
      marginBottom: 16,

      ...(Platform.select({
        web: {
          [theme.breakpoints.up('sm')]: {
            maxWidth: 432,
          },
        },
      })),
    },

    hasNoMarginBottom: {
      marginBottom: 0,
    },
  }),

  ArcFormFieldDescription: theme => ({
    root: {
      ...theme.typography.caption,
      marginTop: 16,
    },
  }),
};

const ArcFormFieldView = createWithStyles(styles.ArcFormFieldView)(ArcView);
const ArcFormFieldDescription = createWithStyles(styles.ArcFormFieldDescription)(ArcView);

class ArcFormControl extends React.PureComponent {
  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount();
    }
  }

  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    const {
      type,
      description,
      hasNoMarginBottom,
      style,
      children,
      onMount, // eslint-disable-line
      onUnmount, // eslint-disable-line
      ...props
    } = this.props;

    return (
      <ArcFormFieldView style={style} {...props} hasNoMarginBottom={hasNoMarginBottom}>
        {children}
        {description && (
          <ArcFormFieldDescription type={type}>
            {description}
          </ArcFormFieldDescription>
        )}
      </ArcFormFieldView>
    );
  }
}

ArcFormControl.propTypes = {
  children: ArcPropTypes.children.isRequired,
  type: ArcPropTypes.string,
  // type: ArcPropTypes.oneOf(Object.keys(types)).isRequired,
  description: ArcPropTypes.string,
  hasNoMarginBottom: ArcPropTypes.bool,
  onMount: ArcPropTypes.func,
  onUnmount: ArcPropTypes.func,
  style: ArcPropTypes.style,
};

ArcFormControl.defaultProps = {
  description: undefined,
  style: {},
  hasNoMarginBottom: undefined,
  onMount: undefined,
  onUnmount: undefined,
  type: undefined,
};

export default ArcFormControl;
