import React from 'react';

function Buildings(props) {
  return (
    <svg viewBox="0 0 287 127" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 44.5002L18.5002 44.5V69H25.5002V41H40.0002V53.5H50.5002V49.5H68.0002V23H84.0002V27.5H118.5V35.5H129V31.5H143.5V35.5H149V58.5H178.5V49.5H188.5V41H199V35.5H214V58.5H223V28H227V24H246V49.5H257.5V53.5H262V35.5H287V86.5002H0V44.5002Z" fill="#9D8BDB" />
      <path d="M0 126.5H20H150.5H183.125H215.75H248.375H281H287V49.5H277.5V85H252.5V14H228V85H217V40.5H203.5V59H183V53.75H158V49.5H129V85H124V22.5H109.5V36.5H100V40.5H90V85H79.5V14H75V0H70V9.5H60V14H55.5V85H31.5L31 49.5H20H0V126.5Z" fill="#7B66C6" />
      <rect x="20" y="55" width="5" height="5" fill="#9886D9" />
      <rect x="60" y="23" width="5" height="5" fill="#9886D9" />
      <rect x="70" y="23" width="5" height="5" fill="#9886D9" />
      <rect x="113" y="43" width="5" height="17" fill="#9886D9" />
      <rect x="113" y="64" width="5" height="17" fill="#9886D9" />
      <rect x="20" y="65" width="5" height="5" fill="#9886D9" />
      <rect x="60" y="33" width="5" height="5" fill="#9886D9" />
      <rect x="70" y="33" width="5" height="5" fill="#9886D9" />
      <rect x="20" y="75" width="5" height="5" fill="#9886D9" />
      <rect x="60" y="43" width="5" height="5" fill="#9886D9" />
      <rect x="70" y="43" width="5" height="5" fill="#9886D9" />
      <rect x="234" y="23" width="5" height="5" fill="#9886D9" />
      <rect x="144" y="57" width="5" height="5" fill="#9886D9" />
      <rect x="243" y="23" width="5" height="5" fill="#9886D9" />
      <rect x="234" y="32" width="5" height="5" fill="#9886D9" />
      <rect x="144" y="66" width="5" height="5" fill="#9886D9" />
      <rect x="160" y="66" width="18" height="5" fill="#9886D9" />
      <rect x="243" y="32" width="5" height="5" fill="#9886D9" />
      <rect x="234" y="42" width="5" height="5" fill="#9886D9" />
      <rect x="144" y="76" width="5" height="5" fill="#9886D9" />
      <rect x="160" y="76" width="18" height="5" fill="#9886D9" />
      <rect x="243" y="42" width="5" height="5" fill="#9886D9" />
    </svg>
  );
}

Buildings.displayName = 'Buildings';

export default Buildings;
