import React from 'react';
import { Image } from 'react-primitives';

import ArcPropTypes from '../../helpers/arc/propTypes';

class ArcScaledImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      source: {
        uri: this.props.uri,
      },
      width: this.props.width,
      height: this.props.height,
    };
  }

  componentDidMount() {
    if (this.props.width && this.props.height) {
      return;
    }

    Image.getSize(this.props.uri, (width, height) => {
      if (this.props.width && !this.props.height) {
        this.setState({ width: this.props.width, height: height * (this.props.width / width) });
      } else if (!this.props.width && this.props.height) {
        this.setState({ width: width * (this.props.height / height), height: this.props.height });
      } else {
        this.setState({ width, height });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.uri !== this.props.uri) {
      this.setState({
        source: {
          uri: nextProps.uri,
        },
      });
    }
  }

  render() {
    const { style, ...rest } = this.props;
    return (
      <Image
        {...rest}
        source={this.state.source}
        style={{ height: this.state.height, width: this.state.width, ...style }}
      />
    );
  }
}

ArcScaledImage.propTypes = {
  uri: ArcPropTypes.string.isRequired,
  width: ArcPropTypes.number.isRequired,
  height: ArcPropTypes.number,
  style: ArcPropTypes.style,
};

ArcScaledImage.defaultProps = {
  height: undefined,
  style: undefined,
};

export default ArcScaledImage;
