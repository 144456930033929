import moment, { MomentInput } from 'moment';

const NOW = moment();

export const isSameDay = (date1: MomentInput, date2: MomentInput): boolean =>
  moment(date1).isSame(date2, 'day');

export const endOfToday = NOW.endOf('day');

export const getOrdinalIndicator = (n: number): string => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;

  return s[(v - 20) % 10] || s[v] || s[0];
};

export const getOrdinal = (n: number): string =>
  `${n}${getOrdinalIndicator(n)}`;

export const getMonth = (date: MomentInput): string =>
  moment(date).format('MMMM');

export const getMonthShort = (date: MomentInput): string =>
  moment(date).format('MMM');

export const formatBirthday = (date: MomentInput): string =>
  moment(date).format('MMM D Y');

export const formatDate = (date: MomentInput): string =>
  moment(date).format('MMM D');
export const formatDateTime = (date: MomentInput): string =>
  moment(date).format('MMM D, h:mma');
export const formatFullDateTime = (date: MomentInput): string =>
  moment(date).format('MMM D');

const intlFormat =
  window && window.Intl &&
  new window.Intl.DateTimeFormat().resolvedOptions().locale === 'en-US'
    ? 'MMM D, h:mma'
    : 'D MMM, h:mma';

export const i18nFormatDateTime = (date: MomentInput): string =>
  moment(date).format(intlFormat);

const localeFormat =
  window && window.Intl &&
  new window.Intl.DateTimeFormat().resolvedOptions().locale === 'en-US'
    ? 'MMMM Do YYYY, h:mma'
    : 'Do MMMM YYYY, h:mma';

export const formatDateTimeByLocale = (date: MomentInput): string =>
  moment(date).format(localeFormat);

export const startOfDay = (date: MomentInput): Date =>
  moment(date).startOf('day').toDate();

export const endOfDay = (date: MomentInput): Date =>
  moment(date).endOf('day').toDate();

export const addOneDay = (date: MomentInput): Date =>
  moment(date).add(1, 'day').toDate();

export const removeOneDay = (date: MomentInput): Date =>
  moment(date).subtract(1, 'day').toDate();

export const oneWeekAgo = (date: MomentInput): Date =>
  moment(date).subtract(1, 'week').toDate();

export const beginningOfWeek = (date: MomentInput): Date =>
  moment(date).startOf('week').toDate();

export const beginningOfMonth = (date: MomentInput): Date =>
  moment(date).startOf('month').toDate();

export const beginningOfYear = (date: MomentInput): Date =>
  moment(date).startOf('year').toDate();

export const toInputDate = (date: MomentInput): string =>
  moment(date).format('YYYY-MM-DD');

export const toShortDate = (date: MomentInput): string =>
  moment(date).format('DD-MMM');

export const toNextMinuteInterval = (
  date: MomentInput,
  interval = 15,
): Date => {
  const normalizedInput = moment(date).startOf('minute');
  const minute =
    (Math.floor(normalizedInput.get('minute') / interval) + 1) * interval;

  if (minute >= 60)
    return normalizedInput
      .add(1, 'hour')
      .minute(minute - 60)
      .toDate();
  return normalizedInput.minute(minute).toDate();
};

export const simpleLocaleDateTime = ({ datetime }) =>
  new Date(datetime).toLocaleDateString(undefined, {
    hour12: true,
    dateStyle: 'short',
    timeStyle: 'short',
  });

export const isAfter = (date1: MomentInput, date2: MomentInput): boolean =>
  moment(date1).isAfter(date2);
export const isEqual = (date1: MomentInput, date2: MomentInput): boolean =>
  moment(date1).isSame(date2);
export const isBefore = (date1: MomentInput, date2: MomentInput): boolean =>
  moment(date1).isBefore(date2);

export const nowInUTC = (date: MomentInput): Date => moment.utc(date).toDate();
