import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Emitter from 'emmett';
import { throttle } from 'throttle-debounce';

import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';

import {
  ArcConfirmDialog,
  ArcHidden,
  ArcImage,
  ArcLoader,
  ArcSelect,
  ArcSpacer,
  ArcTextField,
  ArcView,
  theme as baseTheme,
  createWithStyles,
  Grid,
} from 'arcade-frontend-ui';

import {
  getCurrentUser,
  getCurrentUserFeatures,
} from 'arcade-frontend-core/src/reducers/user';

/* eslint-disable import/no-unresolved */
import RewardsPage from '@workplacearcade/web/components/RewardsPage';
import arcadeWebTheme from '@workplacearcade/web/styles/theme';
import { ChakraProvider } from '@chakra-ui/core';
/* eslint-enable import/no-unresolved */

import DialogContentText from '@material-ui/core/DialogContentText';

import { actions } from '../actions';
import {
  GiftCard,
  Reward,
  propTypes as rewardProps,
} from '../components/RewardItem';
import { getProducts, getDisclaimer } from '../reducers/products';
import { getIsFetching } from '../reducers/isFetching';

const ranges = [
  { label: 'All Rewards', value: 20000 },
  { label: '< 2500', value: 2500 },
  { label: '< 5000', value: 5000 },
  { label: '< 10000', value: 10000 },
];

const styles = {
  UserBalanceView: () => ({
    root: {},
  }),

  PriceImage: theme => ({
    root: {
      height: 24,
      width: 24,
      marginLeft: theme.spacing(1),
    },
  }),

  SearchTextField: theme => ({
    root: {
      marginRight: theme.spacing(1),
    },
  }),

  RewardsHeader: {
    root: {
      margin: '24px 24px 16px',
    },
  },

  RewardItemDisclaimer: {
    root: {
      fontSize: '0.75em',
      margin: '0 24px 16px',
    },
  },
};

const PriceImage = createWithStyles(styles.PriceImage)(ArcImage);
const SearchTextField = createWithStyles(styles.SearchTextField)(ArcTextField);
const UserBalanceView = createWithStyles(styles.UserBalanceView)(ArcView);
const RewardsHeader = createWithStyles(styles.RewardsHeader)(ArcView);
const RewardItemDisclaimer = createWithStyles(styles.RewardItemDisclaimer)(
  ArcView,
);

class RewardsShopContainer extends React.PureComponent {
  static propTypes = {
    apiRewardsShopIndexRequest: PropTypes.func.isRequired,
    apiRewardsShopPurchaseRequest: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      id: PropTypes.string,
    }),
    hasFeatureRewardsV2: PropTypes.bool,
    products: PropTypes.arrayOf(PropTypes.shape(rewardProps)),
    userBalance: PropTypes.number,
    updateUserBalance: PropTypes.func,
    disclaimer: PropTypes.string,
    isFetching: PropTypes.bool,
    hasInstantRewardsFeature: PropTypes.bool,
  };

  static defaultProps = {
    currentUser: {},
    hasFeatureRewardsV2: false,
    products: [],
    userBalance: 0,
    updateUserBalance: global.noop,
    disclaimer: '',
    isFetching: false,
    hasInstantRewardsFeature: false,
  };

  constructor(props) {
    super(props);

    this.purchaseEvent = new Emitter();
  }

  state = {
    searchTerm: '',
    selectedRange: 20000,
    products: this.props.products,
    rewardItemID: null,
    rewardItemName: null,
    selectedDenomination: {},
  };

  componentDidMount() {
    if (!this.props.products.length && this.props.currentUser.id) {
      this.props.apiRewardsShopIndexRequest();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.products !== this.props.products) {
      this.setProducts(nextProps.products);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      if (this.props.currentUser.id) {
        this.props.apiRewardsShopIndexRequest();
      }
    }
  }

  setProducts = products => this.setState({ products });

  setSearchTerm = (searchTerm, cb) => this.setState({ searchTerm }, cb);

  setSelectedRange = selectedRange => this.setState({ selectedRange });

  setRewardItemPurchase = (
    rewardItemID,
    rewardItemName,
    selectedDenomination,
  ) =>
    this.setState({
      rewardItemID,
      rewardItemName,
      selectedDenomination,
    });

  resetRewardItemPurchase() {
    this.setState({
      rewardItemID: null,
      rewardItemName: null,
      selectedDenomination: {},
    });
  }

  containsSearchTerm = (product, searchTerm) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const nameContainsTerm =
      product.name &&
      product.name.toLowerCase().indexOf(lowerCaseSearchTerm) > -1;
    const descriptionContainsTerm =
      product.description &&
      product.description.toLowerCase().indexOf(lowerCaseSearchTerm) > -1;

    return nameContainsTerm || descriptionContainsTerm;
  };

  hasValidTokenCost = (product, filterRange) =>
    (product.tokens && product.tokens <= filterRange) ||
    product.variants.filter(variant => variant.cost <= filterRange).length >= 1;

  filterProductsByRangeAndSearchTerm = (searchTerm, filterRange) => {
    if (searchTerm.length < 2) {
      return this.props.products.filter(product =>
        this.hasValidTokenCost(product, filterRange),
      );
    }

    return this.props.products.filter(
      product =>
        this.containsSearchTerm(product, searchTerm) &&
        this.hasValidTokenCost(product, filterRange),
    );
  };

  handleSearchTextChange = event => {
    this.setSearchTerm(event.target.value, this.updateProductsThrottled);
  };

  updateProductsThrottled = throttle(300, () => {
    const products = this.filterProductsByRangeAndSearchTerm(
      this.state.searchTerm,
      this.state.selectedRange,
    );
    this.setProducts(products);
  });

  handlePriceRangeChange = event => {
    this.setSelectedRange(event.target.value);
    this.setProducts(
      this.filterProductsByRangeAndSearchTerm(
        this.state.searchTerm,
        event.target.value,
      ),
    );
  };

  handleClickPurchase = (
    rewardItemID,
    rewardItemName,
    selectedDenomination,
  ) => {
    this.setRewardItemPurchase(
      rewardItemID,
      rewardItemName,
      selectedDenomination,
    );
  };

  handleClickPurchaseConfirm = () => {
    const { rewardItemID, selectedDenomination } = this.state;

    this.props.apiRewardsShopPurchaseRequest(
      rewardItemID,
      selectedDenomination,
    );

    this.purchaseEvent.emit('purchaseComplete', { rewardItemID });

    this.resetRewardItemPurchase();
  };

  handleClickPurchaseCancel = () => this.setRewardItemPurchase(null, null, {});

  renderProduct = product => {
    const RewardItemType = product.tokens ? Reward : GiftCard;
    const rewardTokenCost = product.tokens;

    return (
      <Grid key={product.id} item xl={4} sm={6} xs={12}>
        <RewardItemType
          key={product.id}
          onClickPurchase={this.handleClickPurchase}
          userBalance={this.props.userBalance}
          {...product}
          purchaseEvent={this.purchaseEvent}
          rewardTokenCost={rewardTokenCost}
        />
      </Grid>
    );
  };

  renderProducts() {
    return this.state.products.map(this.renderProduct);
  }

  render() {
    const purchaseDenomination = this.state.selectedDenomination
      ? this.state.selectedDenomination.label
      : '';
    const purchaseName = this.state.rewardItemName;

    if (this.props.hasFeatureRewardsV2) {
      return (
        <ChakraProvider theme={arcadeWebTheme}>
          <RewardsPage />
        </ChakraProvider>
      );
    }
    const { hasInstantRewardsFeature } = this.props;

    return (
      <ArcView>
        <ArcLoader in={this.props.isFetching} />
        <RewardsHeader row justify="center">
          <SearchTextField
            placeholder="Filter Rewards"
            value={this.state.searchTerm}
            onChange={this.handleSearchTextChange}
          />
          <ArcSelect
            items={ranges}
            onChange={this.handlePriceRangeChange}
            value={this.state.selectedRange}
          />
          <ArcSpacer />
          <ArcHidden xsDown>
            <UserBalanceView>
              <PriceImage src={baseTheme.images.coin} />
              {this.props.userBalance.toLocaleString()}
            </UserBalanceView>
          </ArcHidden>
        </RewardsHeader>
        <RewardItemDisclaimer>{this.props.disclaimer}</RewardItemDisclaimer>
        <Grid container spacing={1}>
          {this.renderProducts()}
        </Grid>
        <ArcConfirmDialog
          open={!!this.state.rewardItemID}
          title="Are you sure?"
          textContent={false}
          content={
            <>
              <DialogContentText>
                {`Purchasing ${purchaseDenomination} ${purchaseName}`}
              </DialogContentText>
              {hasInstantRewardsFeature && (
                <DialogContentText>
                  {'All purchases are final and non-refundable.'}
                </DialogContentText>
              )}
            </>
          }
          onClose={this.handleClickPurchaseCancel}
          onConfirm={this.handleClickPurchaseConfirm}
          confirmLabel="Buy Now"
        />
      </ArcView>
    );
  }
}

const getState = (state, ownProps) => ({
  products: getProducts(state),
  disclaimer: getDisclaimer(state),
  isFetching: getIsFetching(state),
  currentUser: getCurrentUser(state),
  hasFeatureRewardsV2: getCurrentUserFeatures(state, FEATURE_FLAGS.REWARDS_V2),
  hasInstantRewardsFeature: getCurrentUserFeatures(
    state,
    FEATURE_FLAGS.INSTANT_REWARDS,
  ),
  ...ownProps,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      apiRewardsShopIndexRequest: actions.apiRewardsShopIndexRequest,
      apiRewardsShopPurchaseRequest: actions.apiRewardsShopPurchaseRequest,
    },
    dispatch,
  );

export default connect(getState, getActions)(RewardsShopContainer);
