export default {
  Header: () => ({
    root: {
      textAlign: 'left',
    },
  }),
  DataTableBody: () => ({
    root: {
    },
  }),
  DataTable: () => ({
    root: {
    },
  }),
  EmptyMessage: () => ({
    root: {
      margin: 'auto',

    },
  }),
  EmptyMessageIcon: theme => ({
    root: {
      color: theme.palette.grey[300],
      fontSize: theme.font.getFontSize(14),
      alignSelf: 'center',
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  }),
  EmptyMessageIconBackground: theme => ({
    root: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: '50%',
      height: 175,
      width: 175,
      alignSelf: 'center',
      margin: theme.spacing(2),
    },
  }),
  EmptyMessageHeader: theme => ({
    root: {
      color: theme.palette.grey[600],
      ...theme.typography.h5,
      fontWeight: 400,
      margin: theme.spacing(0.5),
      textAlign: 'center',
    },
  }),
  EmptyMessageSubheader: theme => ({
    root: {
      color: theme.palette.grey[500],
      ...theme.typography.body,
      textAlign: 'center',
    },
  }),
};
