import React from 'react';
import PropTypes from 'prop-types';
import Home from '@material-ui/icons/Home';
import Undo from '@material-ui/icons/Undo';

import {
  ArcEntityAvatar,
  ArcButton,
  ArcText,
  ArcView,
} from 'arcade-frontend-ui';

import ArcAudienceDialog from '../ArcAudienceDialog';
import ArcAudienceList from '../ArcAudienceList';

const DEFAULT_BREADCRUMB_ITEMS = [
  {
    key: 'entity',
    label: '',
    icon: <Home fontSize="small" color="action" />,
  },
];

const DEFAULT_PLACEHOLDER_TEXT = 'Browse and add to your selection.';

class ArcAudienceCreateForm extends React.PureComponent {
  static displayName = 'ArcAudienceCreateForm';

  static propTypes = {
    browserRequestStatus: PropTypes.string,
    confirmDialogContent: PropTypes.node,
    confirmationRequestStatus: PropTypes.string,
    currentUserId: PropTypes.string,
    defaultPlaceholderText: PropTypes.string,
    disableSelf: PropTypes.bool,
    entitiesByUuid: PropTypes.objectOf(
      PropTypes.shape({
        children: PropTypes.array,
        isRoot: PropTypes.bool,
        level: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        parentUuid: PropTypes.string,
        uuid: PropTypes.string.isRequired,
      }),
    ),
    hasFieldName: PropTypes.bool,
    initialNamePlaceholder: PropTypes.string,
    initialValues: PropTypes.shape({
      excluded: PropTypes.arrayOf(PropTypes.string),
      person: PropTypes.arrayOf(PropTypes.string),
      entity: PropTypes.arrayOf(PropTypes.string),
      team: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      excludedEntitiesByParentUuid: PropTypes.objectOf(
        PropTypes.objectOf(PropTypes.bool),
      ),
      parentEntityUuidsBySelectedEntityUuid: PropTypes.objectOf(
        PropTypes.string,
      ),
    }),
    nameRequired: PropTypes.bool,
    onClose: PropTypes.func,
    onEntitiesRequest: PropTypes.func,
    onSubmit: PropTypes.func,
    open: PropTypes.bool,
    peopleOnly: PropTypes.bool,
  };

  static defaultProps = {
    browserRequestStatus: 'DEFAULT',
    confirmDialogContent: undefined,
    confirmationRequestStatus: 'DEFAULT',
    currentUserId: '',
    defaultPlaceholderText: DEFAULT_PLACEHOLDER_TEXT,
    disableSelf: false,
    entitiesByUuid: {},
    hasFieldName: true,
    initialNamePlaceholder: 'Audience Name',
    initialValues: {},
    nameRequired: false,
    onClose: global.noop,
    onEntitiesRequest: global.noop,
    onSubmit: global.noop,
    open: false,
    peopleOnly: false,
  };

  state = this.initialState;

  componentDidMount() {
    if (this.props.open) {
      this.props.onEntitiesRequest();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entitiesByUuid !== this.props.entitiesByUuid) {
      this.updateBreadcrumbItems();
    }

    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.props.onEntitiesRequest();
      } else {
        this.resetState();
      }
    }
  }

  get currentEntity() {
    if (this.state.isSearching && this.searchValue) {
      return {};
    }

    return this.props.entitiesByUuid[this.state.currentEntityUuid] || {};
  }

  get currentEntityChildren() {
    if (this.state.isSearching && this.searchValue) {
      return this.state.searchResults;
    }

    return this.currentEntity.children
      ? this.currentEntity.children.map(uuid => this.props.entitiesByUuid[uuid])
      : [];
  }

  get currentLevel() {
    if (this.currentEntity.level) {
      return this.currentEntity.level;
    }

    if (this.prevEntityUuid && this.props.entitiesByUuid[this.prevEntityUuid]) {
      return this.props.entitiesByUuid[this.prevEntityUuid].level;
    }

    return 'top';
  }

  get emptyPlaceholder() {
    return (
      <React.Fragment>
        <ArcView marginBottom="8">{this.props.defaultPlaceholderText}</ArcView>
        {!this.props.peopleOnly && (
          <ArcView marginBottom="16">
            {
              'Adding a team will automatically add any future members of that team.'
            }
          </ArcView>
        )}
      </React.Fragment>
    );
  }

  get initialState() {
    const { entitiesByUuid, initialValues } = this.props;

    const excludedEntitiesByParentUuid =
      initialValues.excludedEntitiesByParentUuid || {};
    const parentEntityUuidsBySelectedEntityUuid =
      initialValues.parentEntityUuidsBySelectedEntityUuid || {};
    const partiallySelectedEntitiesByUuid = {};
    const selectedEntitiesByUuid = {};

    ['person', 'team', 'entity'].forEach(type => {
      const values = initialValues[type] || [];

      values.forEach(id => {
        const uuid = `${type}-${id}`;

        selectedEntitiesByUuid[uuid] = true;

        // TODO we need to sort out this logic for partially selecting entities
        // const entity = entitiesByUuid[uuid];
        // if (!entity.isRoot && !entitiesByUuid[entity.parentUuid].isRoot) {
        //   partiallySelectedEntitiesByUuid[entity.parentUuid] = true;
        // }
      });
    });

    const confirmationData = this.getConfirmationData({
      excludedEntitiesByParentUuid,
      parentEntityUuidsBySelectedEntityUuid,
      selectedEntitiesByUuid,
    });

    Object.keys(selectedEntitiesByUuid).forEach(selectedUuid => {
      const entity = entitiesByUuid[selectedUuid];

      if (entity.children) {
        let hasSelectedAll = true;
        let hasPartiallySelected = false;

        entity.children.forEach(uuid => {
          if (selectedEntitiesByUuid[uuid]) {
            hasPartiallySelected = true;
          } else {
            hasSelectedAll = false;
          }
        });

        if (hasSelectedAll) {
          selectedEntitiesByUuid[selectedUuid] = true;
        } else if (hasPartiallySelected) {
          partiallySelectedEntitiesByUuid[selectedUuid] = true;
          selectedEntitiesByUuid[selectedUuid] = false;
        } else {
          partiallySelectedEntitiesByUuid[selectedUuid] = true;
          if (entity.children.length) {
            selectedEntitiesByUuid[selectedUuid] = false;
          } else {
            selectedEntitiesByUuid[selectedUuid] = true;
          }
        }
      }
    });

    return {
      breadcrumbItems: DEFAULT_BREADCRUMB_ITEMS,
      currentEntityUuid: 'entity',
      confirmationData,
      excludedEntitiesByParentUuid,
      fadeDirection: ArcAudienceList.FADE_DIRECTIONS.NONE,
      fadeOut: false,
      isSearching: false,
      namePlaceholder: this.props.initialNamePlaceholder,
      nameValue: initialValues.name || '',
      placeholderText: this.emptyPlaceholder,
      parentEntityUuidsBySelectedEntityUuid,
      partiallySelectedEntitiesByUuid,
      searchResults: [],
      selectedEntitiesByUuid,
    };
  }

  getConfirmationData(state) {
    const data = [];

    const allSelectedEntitesByUuid = {
      ...state.selectedEntitiesByUuid,
      ...state.partiallySelectedEntitiesByUuid,
    };

    const allSelectedEntitiesByParentUuid = {};

    const allSelectedEntities = Object.entries(allSelectedEntitesByUuid);

    allSelectedEntities.forEach(([key, selected]) => {
      if (selected) {
        const entity = { ...this.props.entitiesByUuid[key] };

        if (entity.type === 'person') {
          const addedFromParentEntityUuid =
            state.parentEntityUuidsBySelectedEntityUuid[key];

          if (addedFromParentEntityUuid) {
            const addedFromParentEntity = this.props.entitiesByUuid[
              addedFromParentEntityUuid
            ];

            allSelectedEntitiesByParentUuid[
              addedFromParentEntityUuid
            ] = allSelectedEntitiesByParentUuid[addedFromParentEntityUuid] || [
              addedFromParentEntity,
            ];

            entity.addedFromParentEntity = addedFromParentEntity;

            if (addedFromParentEntity.name === entity.teamName) {
              entity.teamName = '';
            }

            allSelectedEntitiesByParentUuid[addedFromParentEntityUuid].push(
              entity,
            );
          } else {
            allSelectedEntitiesByParentUuid[
              entity.uuid
            ] = allSelectedEntitiesByParentUuid[entity.uuid] || [entity];
          }
        } else {
          entity.teamName = 'and all future members';

          const excludedPeople = state.excludedEntitiesByParentUuid[entity.uuid]
            ? Object.entries(
                state.excludedEntitiesByParentUuid[entity.uuid],
              ).filter(([, excluded]) => excluded)
            : [];

          if (excludedPeople.length) {
            entity.teamName += ` (excl. ${excludedPeople.length})`;
          }

          if (allSelectedEntitiesByParentUuid[entity.uuid]) {
            allSelectedEntitiesByParentUuid[entity.uuid][0] = entity;
          } else {
            allSelectedEntitiesByParentUuid[entity.uuid] = [entity];
          }
        }
      }
    });

    Object.values(allSelectedEntitiesByParentUuid).forEach(entities => {
      entities.forEach(item => {
        data.push(item);
      });
    });

    return data;
  }

  setBreadcrumbItems = breadcrumbItems => this.setState({ breadcrumbItems });

  setConfirmationData = confirmationData => this.setState({ confirmationData });

  setCurrentEntityUuid = currentEntityUuid => {
    this.prevEntityUuid = this.state.currentEntityUuid;

    this.setState(
      {
        currentEntityUuid,
      },
      this.updateBreadcrumbItems,
    );
  };

  setExcludedEntitiesByParentUuid = excludedEntitiesByParentUuid =>
    this.setState({ excludedEntitiesByParentUuid });

  setFadeDirection = fadeDirection => this.setState({ fadeDirection });

  setFadeOut = fadeOut => this.setState({ fadeOut });

  setIsSearching = isSearching => this.setState({ isSearching });

  setNamePlaceholder = namePlaceholder => this.setState({ namePlaceholder });

  setNameValue = nameValue => this.setState({ nameValue });

  setPlaceholderText = placeholderText => this.setState({ placeholderText });

  setSelectedEntitiesByUuid = selectedEntitiesByUuid =>
    this.setState(
      {
        selectedEntitiesByUuid,
      },
      this.updateConfirmationData,
    );

  setPartiallySelectedEntitiesByUuid = partiallySelectedEntitiesByUuid =>
    this.setState(
      {
        partiallySelectedEntitiesByUuid,
      },
      this.updateConfirmationData,
    );

  setSearchResults = searchResults => this.setState({ searchResults });

  requestData(uuid) {
    if (!this.props.entitiesByUuid[uuid]) {
      this.props.onEntitiesRequest(uuid);
    } else {
      this.setCurrentEntityUuid(uuid);
    }
  }

  resetState() {
    this.setState({ ...this.initialState });
  }

  updateBreadcrumbItems = () => {
    const breadcrumbItems = [];

    let nextEntity = this.currentEntity;

    while (nextEntity && nextEntity.uuid) {
      const Icon = nextEntity.isRoot ? Home : ArcEntityAvatar;

      const iconProps =
        Icon === Home
          ? {
              fontSize: 'small',
              color: 'action',
            }
          : {
              ...nextEntity,
              size: 20,
            };

      breadcrumbItems.push({
        key: nextEntity.uuid,
        label: nextEntity.name,
        icon: <Icon {...iconProps} />,
      });

      nextEntity = this.props.entitiesByUuid[nextEntity.parentUuid];
    }

    this.setBreadcrumbItems(breadcrumbItems.reverse());
  };

  updateConfirmationData = () => {
    const confirmationData = this.getConfirmationData(this.state);

    this.setConfirmationData(confirmationData);
  };

  handleAdd = selectedEntitiesByUuid => {
    const nextSelectedEntitiesByUuid = {
      ...this.state.selectedEntitiesByUuid,
      ...selectedEntitiesByUuid,
    };

    this.setSelectedEntitiesByUuid(nextSelectedEntitiesByUuid);

    if (this.state.placeholderText !== this.props.defaultPlaceholderText) {
      this.setPlaceholderText(this.props.defaultPlaceholderText);
    }
  };

  handleAddGroup = (
    selectedEntitiesByUuid,
    partiallySelectedEntitiesByUuid,
    entitiesByPreSelectedUuid,
  ) => {
    const parentEntityUuidsBySelectedEntityUuid = {
      ...this.state.parentEntityUuidsBySelectedEntityUuid,
    };

    Object.entries(entitiesByPreSelectedUuid).forEach(([uuid, entities]) => {
      entities.forEach(item => {
        parentEntityUuidsBySelectedEntityUuid[item.uuid] = uuid;
      });
    });

    const excludedEntitiesByParentUuid = {
      ...this.state.excludedEntitiesByParentUuid,
    };

    Object.entries(selectedEntitiesByUuid).forEach(([uuid, selected]) => {
      const parentUuid = parentEntityUuidsBySelectedEntityUuid[uuid];

      if (parentUuid) {
        excludedEntitiesByParentUuid[parentUuid] =
          excludedEntitiesByParentUuid[parentUuid] || {};
        excludedEntitiesByParentUuid[parentUuid][uuid] = !selected;
      }
    });

    const nextState = {
      excludedEntitiesByParentUuid,
      selectedEntitiesByUuid,
      partiallySelectedEntitiesByUuid,
      parentEntityUuidsBySelectedEntityUuid,
      placeholderText: this.emptyPlaceholder,
    };

    this.setState(nextState, this.updateConfirmationData);
  };

  handleBack = () => {
    const { parentUuid } = this.currentEntity;

    if (parentUuid) {
      this.setFadeOut(true);
      this.setFadeDirection(ArcAudienceList.FADE_DIRECTIONS.RIGHT);

      setTimeout(() => {
        this.setFadeOut(false);
        this.setFadeDirection(ArcAudienceList.FADE_DIRECTIONS.LEFT);

        // TODO fix this issue where going to top will still allow one more animation
        this.setCurrentEntityUuid(parentUuid);
      }, ArcAudienceList.ANIMATION_DURATION);
    }
  };

  handleBreadcrumbsClick = key => {
    if (this.state.isSearching || key === this.state.currentEntityUuid) {
      return;
    }

    this.setFadeOut(true);
    this.setFadeDirection(ArcAudienceList.FADE_DIRECTIONS.RIGHT);

    setTimeout(() => {
      this.setFadeOut(false);
      this.setFadeDirection(ArcAudienceList.FADE_DIRECTIONS.LEFT);

      this.setCurrentEntityUuid(key);
    }, ArcAudienceList.ANIMATION_DURATION);
  };

  handleBrowserItemChange = item => {
    const uuid = `${item.type}-${item.id}`;

    if (this.state.placeholderText !== this.props.defaultPlaceholderText) {
      this.setPlaceholderText(this.props.defaultPlaceholderText);
    }

    this.setSelectedEntitiesByUuid({
      ...this.state.selectedEntitiesByUuid,
      [uuid]: !this.state.selectedEntitiesByUuid[uuid],
    });
  };

  handleBrowserItemClick = item => {
    if (item.type !== 'person') {
      this.setFadeOut(true);
      this.setFadeDirection(ArcAudienceList.FADE_DIRECTIONS.LEFT);

      setTimeout(() => {
        this.setFadeOut(false);
        this.setFadeDirection(ArcAudienceList.FADE_DIRECTIONS.RIGHT);

        const uuid = `${item.type}-${item.id}`;
        this.requestData(uuid);
      }, ArcAudienceList.ANIMATION_DURATION);
    } else {
      this.handleBrowserItemChange(item);
    }
  };

  handleClose = () => {
    this.resetState();
    this.props.onClose();
  };

  handleNameChange = e => this.setNameValue(e.target.value);

  handleRemove = item => {
    const uuid = `${item.type}-${item.id}`;
    const nextExcludedEntitiesByParentUuid = {
      ...this.state.excludedEntitiesByParentUuid,
    };
    const nextParentEntityUuidsBySelectedEntityUuid = {
      ...this.state.parentEntityUuidsBySelectedEntityUuid,
    };

    const nextPartiallySelectedEntitiesByUuid = {
      ...this.state.partiallySelectedEntitiesByUuid,
    };
    delete nextPartiallySelectedEntitiesByUuid[uuid];

    const nextSelectedEntitiesByUuid = { ...this.state.selectedEntitiesByUuid };
    delete nextSelectedEntitiesByUuid[uuid];

    if (item.type === 'person') {
      const parentUuid = nextParentEntityUuidsBySelectedEntityUuid[uuid];

      // This seems to occur when searching
      if (typeof parentUuid !== 'undefined') {
        nextExcludedEntitiesByParentUuid[parentUuid] =
          nextExcludedEntitiesByParentUuid[parentUuid] || {};
        nextExcludedEntitiesByParentUuid[parentUuid][uuid] = true;

        delete nextSelectedEntitiesByUuid[parentUuid];
        nextPartiallySelectedEntitiesByUuid[parentUuid] = true;
      }

      delete nextParentEntityUuidsBySelectedEntityUuid[uuid];

      return this.setState(
        {
          excludedEntitiesByParentUuid: nextExcludedEntitiesByParentUuid,
          selectedEntitiesByUuid: nextSelectedEntitiesByUuid,
          parentEntityUuidsBySelectedEntityUuid: nextParentEntityUuidsBySelectedEntityUuid,
          partiallySelectedEntitiesByUuid: nextPartiallySelectedEntitiesByUuid,
        },
        this.updateConfirmationData,
      );
    }

    const removeChildren = children => {
      children.forEach(childUuid => {
        const child = this.props.entitiesByUuid[childUuid];

        if (this.state.selectedEntitiesByUuid[childUuid]) {
          delete nextParentEntityUuidsBySelectedEntityUuid[childUuid];
          delete nextSelectedEntitiesByUuid[childUuid];
        }

        if (this.state.partiallySelectedEntitiesByUuid[childUuid]) {
          delete nextPartiallySelectedEntitiesByUuid[childUuid];
        }

        if (child.children) {
          removeChildren(child.children);
        }
      });
    };

    removeChildren(this.props.entitiesByUuid[uuid].children);

    return this.setState(
      {
        parentEntityUuidsBySelectedEntityUuid: nextParentEntityUuidsBySelectedEntityUuid,
        partiallySelectedEntitiesByUuid: nextPartiallySelectedEntitiesByUuid,
        selectedEntitiesByUuid: nextSelectedEntitiesByUuid,
      },
      this.updateConfirmationData,
    );
  };

  handleRemoveAll = () => {
    this.prevState = {
      currentEntityUuid: 'entity',
      parentEntityUuidsBySelectedEntityUuid: this.state
        .parentEntityUuidsBySelectedEntityUuid,
      partiallySelectedEntitiesByUuid: this.state
        .partiallySelectedEntitiesByUuid,
      selectedEntitiesByUuid: this.state.selectedEntitiesByUuid,
      placeholderText: this.emptyPlaceholder,
    };

    const removedLength = Object.keys(this.prevState.selectedEntitiesByUuid)
      .length;

    const placeholderText =
      removedLength > 1 ? (
        <ArcView className="animated fadeIn">
          {this.emptyPlaceholder}
          <ArcView marginBottom="8">
            {`Recently removed ${removedLength} individuals and groups from your picks.`}
          </ArcView>
          <ArcView align="flex-start">
            <ArcButton
              color="blue"
              variant="outlined"
              onClick={this.handleRemoveAllUndo}
            >
              <ArcText isSmall>
                <Undo fontSize="inherit" />
              </ArcText>
              <ArcView marginLeft="8">
                <ArcText isSmall>{'Undo'}</ArcText>
              </ArcView>
            </ArcButton>
          </ArcView>
        </ArcView>
      ) : (
        this.emptyPlaceholder
      );

    this.setState(
      {
        parentEntityUuidsBySelectedEntityUuid: {},
        partiallySelectedEntitiesByUuid: {},
        selectedEntitiesByUuid: {},
        placeholderText,
      },
      this.updateConfirmationData,
    );
  };

  handleRemoveByEntities = entities => {
    const nextParentEntityUuidsBySelectedEntityUuid = {
      ...this.state.parentEntityUuidsBySelectedEntityUuid,
    };
    const nextPartiallySelectedEntitiesByUuid = {
      ...this.state.partiallySelectedEntitiesByUuid,
    };
    const nextSelectedEntitiesByUuid = { ...this.state.selectedEntitiesByUuid };

    const removeChildren = children => {
      children.forEach(child => {
        if (this.state.selectedEntitiesByUuid[child.uuid]) {
          delete nextParentEntityUuidsBySelectedEntityUuid[child.uuid];
          nextSelectedEntitiesByUuid[child.uuid] = false;
        }

        if (this.state.partiallySelectedEntitiesByUuid[child.uuid]) {
          nextPartiallySelectedEntitiesByUuid[child.uuid] = false;
        }

        if (child.children) {
          removeChildren(child.children);
        }
      });
    };

    removeChildren(entities);

    this.setState(
      {
        parentEntityUuidsBySelectedEntityUuid: nextParentEntityUuidsBySelectedEntityUuid,
        partiallySelectedEntitiesByUuid: nextPartiallySelectedEntitiesByUuid,
        selectedEntitiesByUuid: nextSelectedEntitiesByUuid,
      },
      this.updateConfirmationData,
    );
  };

  handleRemoveAllUndo = () => {
    if (this.prevState) {
      this.setState(this.prevState, this.updateConfirmationData);
      this.prevState = null;
    }
  };

  handleSearch = value => {
    const nextSearchResults = Object.values(this.props.entitiesByUuid).filter(
      entity =>
        entity.type === 'person' &&
        entity.name.toLowerCase().indexOf(value.toLowerCase()) > -1,
    );

    this.searchValue = value;
    this.setSearchResults(nextSearchResults);
  };

  handleSearchToggle = isSearching => {
    this.setIsSearching(isSearching);

    if (!isSearching) {
      this.searchValue = '';
    }
  };

  handleSubmit = () => {
    if (
      this.props.hasFieldName &&
      !this.state.nameValue &&
      this.props.nameRequired
    ) {
      this.setNamePlaceholder('Name is required');
      const input = document.getElementById('audienceName');

      if (input) {
        input.focus();
      }

      return;
    }

    const idsByType = {
      excluded: [],
      person: [],
      entity: [],
      team: [],
    };

    this.state.confirmationData.forEach(item => {
      idsByType[item.type].push(item.id);
    });

    const {
      excludedEntitiesByParentUuid,
      parentEntityUuidsBySelectedEntityUuid,
    } = this.state;

    Object.values(excludedEntitiesByParentUuid).forEach(exclusionsByUuid => {
      Object.entries(exclusionsByUuid).forEach(([uuid, excluded]) => {
        if (excluded) {
          const id = uuid.split('-')[1];
          idsByType.excluded.push(id);
        }
      });
    });

    this.props.onSubmit({
      ...idsByType,
      excludedEntitiesByParentUuid,
      parentEntityUuidsBySelectedEntityUuid,
      name: this.state.nameValue,
    });
  };

  render() {
    return (
      <ArcAudienceDialog
        {...this.props}
        breadcrumbItems={this.state.breadcrumbItems}
        browserData={this.currentEntityChildren}
        browserRequestStatus={this.props.browserRequestStatus}
        confirmDialogContent={this.props.confirmDialogContent}
        confirmationData={this.state.confirmationData}
        confirmationTitle="Your picks"
        confirmationRequestStatus={this.props.confirmationRequestStatus}
        currentEntity={this.currentEntity}
        currentLevel={this.currentLevel}
        currentUserId={this.props.currentUserId}
        disableSelf={this.props.disableSelf}
        excludedEntitiesByParentUuid={this.state.excludedEntitiesByParentUuid}
        fadeDirection={this.state.fadeDirection}
        fadeOut={this.state.fadeOut}
        entitiesByUuid={this.props.entitiesByUuid}
        namePlaceholder={this.state.namePlaceholder}
        nameValue={this.state.nameValue}
        onAdd={this.handleAdd}
        onAddGroup={this.handleAddGroup}
        onBack={this.handleBack}
        onBreadcrumbsClick={this.handleBreadcrumbsClick}
        onBrowserItemChange={this.handleBrowserItemChange}
        onBrowserItemClick={this.handleBrowserItemClick}
        onClose={this.handleClose}
        onNameChange={this.handleNameChange}
        onRemove={this.handleRemove}
        onRemoveAll={this.handleRemoveAll}
        onRemoveAllUndo={this.handleRemoveAllUndo}
        onRemoveByEntities={this.handleRemoveByEntities}
        onSearch={this.handleSearch}
        onSearchToggle={this.handleSearchToggle}
        onSubmit={this.handleSubmit}
        placeholderText={this.state.placeholderText}
        selectedEntitiesByUuid={this.state.selectedEntitiesByUuid}
        partiallySelectedEntitiesByUuid={
          this.state.partiallySelectedEntitiesByUuid
        }
      />
    );
  }
}

export default ArcAudienceCreateForm;
