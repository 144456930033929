import React from 'react';
import { Platform, ScrollView } from 'react-native';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';

import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
import ArcTabs from 'arcade-frontend-ui/src/components/ArcTabs';
import ArcTab from 'arcade-frontend-ui/src/components/ArcTab';

import NotificationsPanelHeader from '../../components/NotificationsPanelHeader';
import NotificationsPanelMyInboxContainer from '../NotificationsPanelMyInboxContainer';
import NotificationsPanelActivityContainer from '../NotificationsPanelActivityContainer';
import NotificationsPanelHistoryContainer from '../NotificationsPanelHistoryContainer';

const MODAL_PROPS = {
  hideBackdrop: true,
};

type Props = DrawerProps;

const PANELS_BY_VALUE: { [key: string]: React.ComponentType } = {
  'my-inbox': NotificationsPanelMyInboxContainer,
  activity: NotificationsPanelActivityContainer,
  history: NotificationsPanelHistoryContainer,
};

export default function NotificationsPanelContainer({
  onClose,
  ...props
}: Props): React.ReactNode {
  const [currentTab, setCurrentTab] = React.useState('my-inbox');
  const CurrentTab = PANELS_BY_VALUE[currentTab];

  const list = (
    <ArcViewBox
      width={Platform.OS === 'web' ? 375 : '100%'}
      backgroundColor="#f8f8f8"
      flex={1}
    >
      <ArcViewBox py={3} px={4}>
        <ArcTabs value={currentTab} onChange={setCurrentTab}>
          <ArcTab variant="dark" value="my-inbox">
            {'My Inbox (3)'}
          </ArcTab>
          <ArcTab variant="dark" value="activity">
            <ArcViewBox flexDirection="row" alignItems="center">
              {'Activity'}
              <ArcViewBox
                width={8}
                height={8}
                backgroundColor="#14B4E5"
                ml={3}
                borderRadius={8}
              />
            </ArcViewBox>
          </ArcTab>
          <ArcTab variant="dark" value="history">
            {'History'}
          </ArcTab>
        </ArcTabs>
      </ArcViewBox>
      <ArcViewBox px={2}>
        <CurrentTab />
      </ArcViewBox>
    </ArcViewBox>
  );

  if (Platform.OS !== 'web') {
    return list;
  }

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        elevation: 4,
        style: {
          backgroundColor: '#f8f8f8',
          borderLeft: 0,
        },
      }}
      ModalProps={MODAL_PROPS}
      variant="persistent"
      onClose={onClose}
      {...props}
    >
      <NotificationsPanelHeader onClose={onClose} />
      <ScrollView>{list}</ScrollView>
    </Drawer>
  );
}
