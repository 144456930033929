import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcTab from 'arcade-frontend-ui/src/components/ArcTab';
import ArcTabs from 'arcade-frontend-ui/src/components/ArcTabs';
import ArcConfirmDialog from 'arcade-frontend-ui/src/components/ArcConfirmDialog';
import ArcResponsiveDialog from 'arcade-frontend-ui/src/components/ArcResponsiveDialog';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcPasswordForm from 'arcade-frontend-forms/src/components/ArcPasswordForm';
import * as PASSWORD_FIELDS from 'arcade-frontend-forms/src/types/password-fields';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';

import ProfileEditFormContact from './ProfileEditFormContact';
import ProfileEditFormDisclaimer from './ProfileEditFormDisclaimer';
import ProfileEditFormPersonal from './ProfileEditFormPersonal';
import ProfileEditFormPlaceholder from './ProfileEditFormPlaceholder';
import ProfileEditFormSecurity from './ProfileEditFormSecurity';
import { TABS, TAB_TYPES, LABELS_BY_TAB_TYPE } from './ProfileEditForm.types';

const INITIAL_VALUES = {
  firstname: '',
  lastname: '',
  contactNumber: '',
  birthday: '',
  anniversary: '',
  password: '',
  passwordConfirmation: '',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().label('Email').email().required(),
  firstname: Yup.string().label('First Name').required(),
  lastname: Yup.string().label('Last Name').required(),
  newPassword: Yup.string()
    .label('Password')
    .min(PASSWORD_FIELDS.PASSWORD.min)
    .max(PASSWORD_FIELDS.PASSWORD.max),
  passwordConfirmation: Yup.string()
    .label('Password Confirmation')
    .min(PASSWORD_FIELDS.PASSWORD.min)
    .max(PASSWORD_FIELDS.PASSWORD.max)
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

const FIELDS_BY_TAB = {
  [TAB_TYPES.CONTACT]: ['contactNumber', 'email', 'firstname', 'lastname'],
  [TAB_TYPES.PERSONAL]: ['birthday', 'anniversary'],
  [TAB_TYPES.SECURITY]: ['newPassword', 'passwordConfirmation'],
};

const dialogContentStyle = {
  height: 478,
};

function ProfileEditForm({ currentTab, onTabChange, ...props }) {
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [passwordFormOpen, setPasswordFormOpen] = React.useState(false);
  const [showAnniversaryCalendar, setShowAnniversaryCalendar] = React.useState(
    false,
  );
  const [showBirthdayCalendar, setShowBirthdayCalendar] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [submitCount, setSubmitCount] = React.useState(0);

  function handleAnniversaryClick() {
    setShowAnniversaryCalendar(true);
  }

  function handleAnniversaryCalendarClick() {
    setShowAnniversaryCalendar(!showAnniversaryCalendar);
  }

  function handleBirthdayClick() {
    setShowBirthdayCalendar(true);
  }

  function handleBirthdayCalendarClick() {
    setShowBirthdayCalendar(!showBirthdayCalendar);
  }

  function handleConfirmDialogClose() {
    setConfirmDialogOpen(false);
  }

  function handleConfirmDialogConfirm() {
    setConfirmDialogOpen(false);
    props.onClose();
  }

  function handlePasswordVisibilityClick() {
    setShowPassword(!showPassword);
  }

  function renderTabComponent(type, formikProps) {
    switch (type) {
      case TAB_TYPES.CONTACT:
        return <ProfileEditFormContact values={formikProps.values} />;
      case TAB_TYPES.PERSONAL:
        return (
          <ProfileEditFormPersonal
            onAnniversaryClick={handleAnniversaryClick}
            onAnniversaryCalendarClick={handleAnniversaryCalendarClick}
            onBirthdayClick={handleBirthdayClick}
            onBirthdayCalendarClick={handleBirthdayCalendarClick}
            showAnniversaryCalendar={showAnniversaryCalendar}
            showBirthdayCalendar={showBirthdayCalendar}
            values={formikProps.values}
          />
        );
      case TAB_TYPES.SECURITY:
        return (
          <ProfileEditFormSecurity
            minPasswordLength={PASSWORD_FIELDS.PASSWORD.min}
            maxPasswordLength={PASSWORD_FIELDS.PASSWORD.max}
            onPasswordVisibilityClick={handlePasswordVisibilityClick}
            setFieldError={formikProps.setFieldError}
            showPassword={showPassword}
            values={formikProps.values}
          />
        );
      default:
        return null;
    }
  }

  function renderForm(formikProps) {
    const formHasErrors = !!Object.keys(formikProps.errors).length;

    function handleCancel() {
      if (formikProps.dirty) {
        setConfirmDialogOpen(true);
      } else {
        props.onClose();
      }
    }

    function handlePasswordSubmit(values) {
      setPasswordFormOpen(false);
      formikProps.setFieldValue('currentPassword', values.currentPassword);
      formikProps.handleSubmit();
    }

    function handlePasswordCancel() {
      setPasswordFormOpen(false);
    }

    function handleSubmit(evt) {
      evt.preventDefault();
      setSubmitCount(submitCount + 1);

      if (!formHasErrors) {
        setPasswordFormOpen(true);
      }
    }

    return (
      <>
        <ArcResponsiveDialog open={props.open} onClose={handleCancel}>
          <DialogTitle>
            <ArcBox
              display="flex"
              flexDirection={['column', 'row']}
              alignItems={['flex-start', 'flex-end']}
            >
              <ArcBox mb={[1, 0]}>{'My Profile'}</ArcBox>
              <ArcBox display="flex" flex={100} />
              <ArcTabs value={currentTab} onChange={onTabChange}>
                {TABS.map(type => {
                  let hasError = false;
                  let hasTouched = false;

                  FIELDS_BY_TAB[type].forEach(field => {
                    if (formikProps.errors[field]) {
                      hasError = true;
                    }
                    if (formikProps.touched[field]) {
                      hasTouched = true;
                    }
                  });

                  const tabProps =
                    hasTouched && hasError && currentTab !== type
                      ? { color: 'danger.main' }
                      : {};

                  return (
                    <ArcTab
                      key={type}
                      id={`tab-${type}`}
                      aria-controls={`panel-${type}`}
                      value={type}
                      label={LABELS_BY_TAB_TYPE[type]}
                      {...tabProps}
                    />
                  );
                })}
              </ArcTabs>
            </ArcBox>
          </DialogTitle>
          <DialogContent style={dialogContentStyle}>
            <Form id="ProfileEditForm">
              {TABS.map(type => {
                const isSelected = currentTab === type;

                return (
                  <ArcBox
                    key={type}
                    role="tabpanel"
                    id={`panel-${type}`}
                    aria-labelledby={`tab-${type}`}
                    hidden={isSelected ? undefined : 'hidden'}
                  >
                    {isSelected && renderTabComponent(type, formikProps)}
                  </ArcBox>
                );
              })}
            </Form>
            <ProfileEditFormDisclaimer />
          </DialogContent>

          <DialogActions>
            <ArcButton label="Cancel" onClick={handleCancel} />
            <ArcResourceButton
              form="ProfileEditForm"
              type="submit"
              color="secondary"
              disabled={
                (submitCount > 0 && formHasErrors) || !props.status.idle
              }
              variant="contained"
              label="Submit"
              onClick={handleSubmit}
              status={props.status}
            />
          </DialogActions>
        </ArcResponsiveDialog>

        <ArcPasswordForm
          open={passwordFormOpen}
          onCancel={handlePasswordCancel}
          onSubmit={handlePasswordSubmit}
        />

        <ArcConfirmDialog
          title="Are you sure?"
          content="You will lose unsaved changes"
          open={confirmDialogOpen}
          onClose={handleConfirmDialogClose}
          onConfirm={handleConfirmDialogConfirm}
        />
      </>
    );
  }

  const initialValues = React.useMemo(
    () => ({ ...INITIAL_VALUES, ...props.initialValues }),
    [INITIAL_VALUES, props.initialValues],
  );

  return (
    <Formik
      onSubmit={props.onSubmit}
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      enableReinitialize
    >
      {renderForm}
    </Formik>
  );
}

ProfileEditForm.displayName = 'ProfileEditForm';

ProfileEditForm.Placeholder = ProfileEditFormPlaceholder;

ProfileEditForm.propTypes = {
  currentTab: PropTypes.oneOf(TABS),
  initialValues: PropTypes.shape({
    birthday: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onTabChange: PropTypes.func,
  open: PropTypes.bool,
  status: PropTypes.objectOf(PropTypes.bool),
};

ProfileEditForm.defaultProps = {
  currentTab: TABS[0],
  initialValues: {},
  onClose: global.noop,
  onSubmit: global.noop,
  onTabChange: global.noop,
  open: false,
  status: undefined,
};

export default ProfileEditForm;
