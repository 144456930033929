import React from 'react';
import PropTypes from 'prop-types';

import * as GAME_SCENE_SIZES from 'arcade-frontend-core/src/types/game-scene-sizes';

import { getStyleByGameSceneSize } from '../../helpers/utils/game-scene';
import { createWithStyles } from '../../styles';
import ArcView from '../../primitives/ArcView';

import positions from './positions-v2';

import FinishFlag from '../../assets/FinishFlag';
import Grass from '../../assets/Grass';
import RaceCircuit from '../../assets/RaceCircuit/RaceCircuitV2';
import Spectators from '../../assets/Spectators';
import RaceArrow from '../../assets/RaceArrow';
import ArcClouds from '../ArcClouds/ArcClouds';


import PositionedPlayersDemo from '../PositionedPlayers/PositionedPlayersDemo';

import ArcCircuitPlayers from './ArcCircuitPlayers';

const styles = {
  Container: () => ({
    root: {
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
  }),

  Track: () => ({
    root: {
      color: '#F00',
      paddingTop: 0,
    },
  }),

  TrackWrapper: theme => ({
    root: {
      position: 'relative',
      marginBottom: theme.spacing(1),
      width: 260,
      height: 244,
      margin: '14px auto',
      bottom: 15,
    },
  }),

  Field: () => ({
    root: {
      position: 'relative',
      width: '100%',
      backgroundColor: '#D0F9E7',
    },
  }),

  Flag: theme => ({
    root: {
      position: 'absolute',
      top: 0,
      left: '50%',
      marginLeft: -4,
      marginTop: theme.spacing(2),
      transform: 'translateY(-100%)',
      height: 48,
    },
  }),

  // Shadows: () => ({
  //   root: {
  //     zIndex: 1,
  //     position: 'absolute',
  //     bottom: 0,
  //     height: 150,
  //     left: '-10%',
  //     width: '120%',

  //     transform: 'skewX(-15deg) translateX(100px)',

  //     '& > .Cloud': {
  //       flexShrink: 0,
  //     },

  //     '& > .Cloud > path': {
  //       fill: 'rgba(0,0,0,0.06)',
  //     },
  //   },
  // }),

  Sky: () => ({
    root: {
      position: 'relative',
      backgroundColor: '#F0FBFE',
      height: 150,
      left: '-10%',
      width: '120%',

      '& > .Cloud': {
        flexShrink: 0,
      },
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);
const Field = createWithStyles(styles.Field)(ArcView);
const Flag = createWithStyles(styles.Flag)(ArcView);
const TrackWrapper = createWithStyles(styles.TrackWrapper)(ArcView);
// const Shadows = createWithStyles(styles.Shadows)(ArcView);
const Sky = createWithStyles(styles.Sky)(ArcView);


const ArcCircuit = ({
  scores,
  goal,
  isDemo,
  borderRadius,
  size,
  playerPositionOffsets,
}) => {
  const renderPlayerPositions = () => {
    if (isDemo) {
      return (
        <PositionedPlayersDemo
          slots={positions}
          size={size}
          offsetLeft={getStyleByGameSceneSize(size, [56, 25])}
          offsetTop={getStyleByGameSceneSize(size, [66, 25])}
        />
      );
    }

    if (scores) {
      return (
        <ArcCircuitPlayers
          slots={positions}
          scores={scores}
          goal={goal}
          size={size}
          offsetLeft={playerPositionOffsets.left}
          offsetTop={playerPositionOffsets.top}
        />
      );
    }

    return null;
  };

  return (
    <Container style={{ borderRadius }}>
      <Sky style={{ height: getStyleByGameSceneSize(size, [86, 150]) }} row>
        <ArcClouds />
      </Sky>
      <Field>
        <TrackWrapper style={{ height: getStyleByGameSceneSize(size, [200, 293]) }}>
          <Flag
            style={{
              position: 'absolute',
              top: -15,
              left: 96,
            }}
          >
            <FinishFlag
              width="56"
              height="56"
            />
          </Flag>
          <Spectators
            style={{
              position: 'absolute',
              top: -4,
              left: -6,
            }}
          />
          <RaceCircuit
            height={192}
            width={260}
            innerFieldFillColor="none"
            trackStrokeColor="none"
          />
          <RaceArrow
            style={{
              position: 'absolute',
              transform: 'rotate(76deg)',
              top: getStyleByGameSceneSize(size, [110, 115]),
              right: getStyleByGameSceneSize(size, [27, 20]),
            }}
          />
          <RaceArrow
            style={{
              position: 'absolute',
              transform: 'rotate(284deg)',
              top: getStyleByGameSceneSize(size, [110, 115]),
              left: getStyleByGameSceneSize(size, [28, 22]),
            }}
          />
          <Grass
            style={{
              position: 'absolute',
              top: 126,
              left: getStyleByGameSceneSize(size, [-6, -26]),
            }}
            width="16"
            height="16"
          />
          <Grass
            style={{
              position: 'absolute',
              top: getStyleByGameSceneSize(size, [210, 200]),
              left: getStyleByGameSceneSize(size, [-3, -42]),
            }}
            width="16"
            height="16"
          />
          <Grass
            style={{
              position: 'absolute',
              top: 25,
              right: 0,
            }}
            width="16"
            height="16"
          />
          <Grass
            style={{
              position: 'absolute',
              top: 72,
              right: -24,
            }}
            width="16"
            height="16"
          />

          {renderPlayerPositions()}

        </TrackWrapper>
      </Field>
    </Container>
  );
};

ArcCircuit.displayName = 'ArcCircuit';

ArcCircuit.propTypes = {
  borderRadius: PropTypes.number,
  goal: PropTypes.number,
  isDemo: PropTypes.bool,
  scores: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      catch_up: PropTypes.string,
      isSelf: PropTypes.bool,
    }),
  ),
  size: PropTypes.string,
  playerPositionOffsets: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
  }),
};

ArcCircuit.defaultProps = {
  borderRadius: 0,
  goal: 0,
  isDemo: false,
  scores: [],
  size: GAME_SCENE_SIZES.SMALL,
  playerPositionOffsets: {
    left: 0,
    top: 0,
  },
};

export default ArcCircuit;
