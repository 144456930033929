/* eslint-disable */
import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import Check from '@material-ui/icons/Check';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import ArcButton from '../../elements/ArcButton';
import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcStyles from '../../styles';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.palette.background.default,

    [theme.breakpoints.up('sm')]: {
      padding: 16,
    },
  },

  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 10,
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.up('sm')]: {
      border: `4px solid ${theme.palette.divider}`,
    },
  },

  stepperRoot: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    flexGrow: 0,
    minHeight: 48,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,

    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
  },

  contentRoot: {
    ...ArcStyles.rules.scroll,
    flexGrow: 10,
    flexShrink: 10,

    padding: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },

  actionRoot: {
    display: 'flex',
    flexShrink: 0,
    flexGrow: 0,

    borderTopColor: theme.palette.divider,
    borderTopStyle: 'solid',
    borderTopWidth: 1,

    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },

  button: {
    marginRight: theme.spacing(1),
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  spacer: {
    flexGrow: 100,
  },

  mobileStepper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  mobileStep1Invalid: {
    '& > div:nth-child(1)': {
      backgroundColor: theme.palette.danger.main,
    },
  },

  mobileStep2Invalid: {
    '& > div:nth-child(2)': {
      backgroundColor: theme.palette.danger.main,
    },
  },

  mobileStep3Invalid: {
    '& > div:nth-child(3)': {
      backgroundColor: theme.palette.danger.main,
    },
  },

  stepIconRoot: {
    '&$completed': {
      color: theme.palette.success.main,
    },
  },

  completed: {
  },

  stepLabelActive: {
    opacity: 1,
  },

  stepLabelRoot: {
    opacity: 0.5,
  },

  iconContainerConfirm: {
    display: 'none',
  },

  stepConnectorLine: {
    borderColor: theme.palette.divider,
  },

  stepRoot: {
    cursor: 'pointer',
    padding: theme.spacing(3),

    '&:first-child, &:last-child': {
      padding: theme.spacing(3),
    },

    '& span': {
      cursor: 'pointer',
      userSelect: 'none',
    },
  },
});

class ArcStepper extends React.Component {
  static propTypes = {
    // children: ArcPropTypes.arrayOf(ArcPropTypes.shape({
    //   children: ArcPropTypes.children.isRequired,
    //   isValid: ArcPropTypes.bool.isRequired,
    //   label: ArcPropTypes.string.isRequired,
    // })).isRequired,
    classes: ArcPropTypes.objectOf(ArcPropTypes.string).isRequired,
    renderSteps: ArcPropTypes.func.isRequired,

    children: ArcPropTypes.children,
    // isValid: ArcPropTypes.bool,
    onBack: ArcPropTypes.func,
    onCancel: ArcPropTypes.func,
    onNext: ArcPropTypes.func,
    onFinish: ArcPropTypes.func,
    onReset: ArcPropTypes.func,
    renderConfirmation: ArcPropTypes.func,
  };

  static defaultProps = {
    children: undefined,
    // isValid: true,
    onBack: ArcPropTypes.noop,
    onCancel: ArcPropTypes.noop,
    onNext: ArcPropTypes.noop,
    onFinish: ArcPropTypes.noop,
    onReset: ArcPropTypes.noop,
    renderConfirmation: undefined,
  };

  state = {
    activeStep: 0,
  };

  get isValid() {
    const stepsIsValid = this.steps.filter(step => step.isValid).length === this.steps.length;
    const confirmationIsValid = !this.props.renderConfirmation || this.props.renderConfirmation().isValid;

    return stepsIsValid && confirmationIsValid;
  }

  setActiveStep = activeStep => this.setState({ activeStep });

  createHandleClickStep(toStep) {
    const handleClickStep = () => {
      if (toStep === this.state.activeStep) {
        return;
      }

      if (toStep < this.state.activeStep) {
        this.props.onBack(toStep, this.state.activeStep);
      } else {
        this.props.onNext(toStep, this.state.activeStep);
      }

      this.setActiveStep(toStep);
    };

    return handleClickStep;
  }

  handleNext = () => {
    const { activeStep } = this.state;
    const toStep = activeStep + 1;

    this.setActiveStep(toStep);
    this.props.onNext(toStep, activeStep);
  };

  handleBack = () => {
    const { activeStep } = this.state;
    const toStep = activeStep - 1;

    this.setActiveStep(toStep);
    this.props.onBack(toStep, activeStep);
  };

  handleFinish = (e) => {
    this.props.onFinish(e);
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });

    this.props.onReset();
  };

  renderMobileActions(steps) {
    const { classes, renderConfirmation } = this.props;
    const { activeStep } = this.state;
    const maxSteps = steps.length;

    const children = this.props.renderSteps();
    const isLastStep = renderConfirmation ? activeStep === steps.length : activeStep === steps.length - 1;

    return (
      <MobileStepper
        activeStep={activeStep}
        className={cx([
          classes.mobileStepper,
        ])}
        classes={{
          dots: cx([
            !children[0] || (!children[0].isValid && classes.mobileStep1Invalid),
            !children[1] || (!children[1].isValid && classes.mobileStep2Invalid),
            !children[2] || (!children[2].isValid && classes.mobileStep3Invalid),
          ]),
        }}
        position="static"
        steps={maxSteps}
        backButton={
          <ArcButton size="small" onClick={this.handleBack} disabled={activeStep === 0}>
            {<KeyboardArrowLeft />}
            Back
          </ArcButton>
        }
        nextButton={
          <ArcButton
            size="small"
            color={isLastStep ? 'secondary' : 'primary'}
            onClick={isLastStep ? this.handleFinish : this.handleNext}
            disabled={isLastStep ? !this.isValid : false}
          >
            {isLastStep ? 'Finish' : 'Next'}
            {isLastStep ? (<Check />) : (<KeyboardArrowRight />)}
          </ArcButton>
        }
      />
    );
  }

  renderActions(steps) {
    const { classes, renderConfirmation } = this.props;
    const { activeStep } = this.state;

    const isLastStep = renderConfirmation ? activeStep === steps.length : activeStep === steps.length - 1;

    return (
      <React.Fragment>
        <ArcButton
          onClick={this.props.onCancel}
          className={classes.button}
          size="large"
        >
          Cancel
        </ArcButton>
        <div className={classes.spacer} />
        <ArcButton
          disabled={activeStep === 0}
          onClick={this.handleBack}
          className={classes.button}
          size="large"
        >
          Back
        </ArcButton>
        <ArcButton
          disabled={isLastStep ? !this.isValid : false}
          variant="contained"
          color={isLastStep ? 'secondary' : 'primary'}
          onClick={isLastStep ? this.handleFinish : this.handleNext}
          className={classes.button}
          size="large"
        >
          {isLastStep ? 'Finish' : 'Next'}
        </ArcButton>
      </React.Fragment>
    );
  }

  renderSteps(steps) {
    const { renderConfirmation } = this.props;
    const { activeStep } = this.state;

    if (activeStep === steps.length && renderConfirmation) {
      return renderConfirmation().children;
    }

    return steps[activeStep] ? steps[activeStep].children : null;
  }

  render() {
    const {
      children,
      renderSteps,
      classes,
    } = this.props;

    const { activeStep } = this.state;
    const steps = renderSteps();

    this.steps = steps;

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Hidden xsDown>
            <Stepper
              activeStep={activeStep}
              className={classes.stepperRoot}
              connector={(
                <StepConnector classes={{ line: classes.stepConnectorLine }} />
              )}
            >
              {steps.map((step, index) => (
                <Step
                  key={step.label}
                  classes={{ root: classes.stepRoot }}
                  onClick={this.createHandleClickStep(index)}
                >
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.stepIconRoot,
                        completed: classes.completed,
                      },
                    }}
                    error={activeStep !== index && !step.isValid}
                    classes={{
                      label: activeStep !== index && classes.stepLabelRoot,
                    }}
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}

              <Step
                classes={{ root: classes.stepRoot }}
                onClick={this.createHandleClickStep(steps.length)}
              >
                <StepLabel
                  classes={{
                    iconContainer: classes.iconContainerConfirm,
                    label: activeStep !== steps.length && classes.stepLabelRoot,
                  }}
                >
                  Confirm
                </StepLabel>
              </Step>
            </Stepper>
          </Hidden>
          <Hidden smUp>
            <div className={classes.stepperRoot}>
              <Typography variant="h6" >{steps[activeStep] ? steps[activeStep].label : 'Confirm'}</Typography>
            </div>
          </Hidden>

          <div className={classes.contentRoot}>
            {this.renderSteps(steps)}
            {children}
          </div>

          <div className={classes.actionRoot}>
            <Hidden xsDown>
              {this.renderActions(steps)}
            </Hidden>
            <Hidden smUp>
              {this.renderMobileActions(steps)}
            </Hidden>
          </div>
        </div>
      </div>
    );
  }
}


export default withStyles(styles)(ArcStepper);
