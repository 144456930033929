import React from 'react';
import { Path } from 'react-primitives-svg';
import Icon from '../Icon';

const Eye = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 15.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M23.376 11.672C22.213 10.352 17.562 5.5 12 5.5 6.438 5.5 1.787 10.352.624 11.672a.5.5 0 000 .656C1.787 13.648 6.438 18.5 12 18.5c5.562 0 10.213-4.852 11.376-6.172a.5.5 0 000-.656z"
    />
  </Icon>
);

Eye.displayName = 'Eye';

export default Eye;
