import { createReducer } from 'arcade-frontend-ui';

import * as typesRoutes from 'arcade-frontend-core/src/types/routes';

const setMetricDetailsType = (state, action) => {
  const { metricDetailsType } = action.payload;

  return metricDetailsType;
};

const handleRouteManageMetricsShow = setMetricDetailsType;

const initialState = null;

const handlers = {
  [typesRoutes.ROUTE_MANAGE_METRICS_SHOW]: handleRouteManageMetricsShow,
};

const currentMetricDetailsType = createReducer(initialState, handlers);

export default currentMetricDetailsType;
