import { makeApiActions } from 'arcade-frontend-ui';
import { makeDateFilterParams } from 'arcade-frontend-core/src/actions/helpers';

import normalizers from './normalizers';

const create = ({ kpiId, amount, date }) => ({
  method: 'POST',
  url: '/v3/sales',
  data: {
    kpi_id: kpiId,
    quantity: amount,
    created_at: date,
  },
});

const rankingsIndex = ({ metricId, ...rest }) => {
  return {
    method: 'GET',
    url: '/v3/user_targets/ranked',
    params: {
      user_target_id: metricId,
      ...makeDateFilterParams(rest),
    },
    rootKey: 'ranked_users',
    rootData: {
      metricId,
    },
    normalizer: normalizers.ranking,
  };
};

const activitiesIndex = ({ metricId, ...rest }) => ({
  method: 'GET',
  url: '/v3/sales/user_index',
  rootKey: 'sales',
  rootData: {
    metricId,
  },
  params: {
    kpi_id: metricId,
    ...makeDateFilterParams(rest),
  },
  normalizer: normalizers.activity,
});

const load = params => ({
  method: 'GET',
  url: '/v3/user_targets.json',
  params: { ...makeDateFilterParams(params) },
  rootKey: 'user_targets',
  normalizer: normalizers.load,
});

export const endpoints = {
  create,
  rankingsIndex,
  activitiesIndex,
  load,
};

const api = makeApiActions(endpoints);

export default api;
