import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  STATUS_TYPES,
  EMPTY_STATUS,
  INITIAL_STATUS,
} from 'arcade-frontend-core/src/hooks/useResourceAction';

import useTimeout from 'arcade-frontend-core/src/hooks/useTimeout';


import CircularProgress from '@material-ui/core/CircularProgress';


import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ArcView from '../../primitives/ArcView';


const ANIMATION_DURATION = 300;

function ArcResourceStatusIcon({
  color,
  idleIcon,
  status,
}) {
  const [hasAnimated, setHasAnimated] = React.useState(false);

  useTimeout(() => {
    setHasAnimated(true);
  }, ANIMATION_DURATION);

  return (
    <>
      <ArcView
        visibility={hasAnimated ? 'visible' : 'hidden'}
        position="absolute"
        fillToParent
        align="center"
        justify="center"
        className={cx([
          hasAnimated && status[STATUS_TYPES.pending] && 'animated animated--fast fadeIn',
          !status[STATUS_TYPES.pending] && 'animated animated--fast fadeOut',
        ])}
      >
        <CircularProgress
          disableShrink
          size="1em"
          thickness={2}
          color={color}
        />
      </ArcView>

      <ArcView
        visibility={hasAnimated ? 'visible' : 'hidden'}
        position="absolute"
        fillToParent
        align="center"
        justify="center"
        className={cx([
          hasAnimated && status[STATUS_TYPES.succeeded] && 'animated animated--fast fadeIn',
          !status[STATUS_TYPES.succeeded] && 'animated animated--fast fadeOut',
        ])}
      >
        <Check fontSize="inherit" />
      </ArcView>

      <ArcView
        visibility={hasAnimated ? 'visible' : 'hidden'}
        position="absolute"
        fillToParent
        align="center"
        justify="center"
        className={cx([
          hasAnimated && status[STATUS_TYPES.failed] && 'animated animated--fast fadeIn',
          !status[STATUS_TYPES.failed] && 'animated animated--fast fadeOut',
        ])}
      >
        <Close fontSize="inherit" />
      </ArcView>

      {!!idleIcon && (
        <ArcView
          visibility={hasAnimated ? 'visible' : 'hidden'}
          align="center"
          justify="center"
          className={cx([
            hasAnimated && status[STATUS_TYPES.idle] && 'animated animated--fast fadeIn',
            !status[STATUS_TYPES.idle] && 'animated animated--fast fadeOut',
          ])}
        >
          {idleIcon}
        </ArcView>
      )}
    </>
  );
}


ArcResourceStatusIcon.displayName = 'ArcResourceStatusIcon';

ArcResourceStatusIcon.STATUS_TYPES = STATUS_TYPES;

ArcResourceStatusIcon.EMPTY_STATUS = EMPTY_STATUS;

ArcResourceStatusIcon.INITIAL_STATUS = INITIAL_STATUS;

ArcResourceStatusIcon.propTypes = {
  color: PropTypes.string,
  idleIcon: PropTypes.node,
  status: PropTypes.shape({
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    succeeded: PropTypes.bool,
    failed: PropTypes.bool,
  }),
};

ArcResourceStatusIcon.defaultProps = {
  color: 'inherit',
  idleIcon: null,
  status: INITIAL_STATUS,
};


export default ArcResourceStatusIcon;
