import React from 'react';

import useSnackbar from 'arcade-frontend-core/src/hooks/useSnackbar';

import NotificationsPanelCard from '../NotificationsPanelCard';
import { NotificationsCardStackProps } from './types.d';

function NotificationsCardStack({
  notifications,
  onDismiss,
}: NotificationsCardStackProps) {
  const [alreadyEnqueued] = React.useState(() => new Set());

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function handleDismiss(id: string) {
    onDismiss(id);
    closeSnackbar(id);
  }

  React.useEffect(() => {
    if (window.arcPostMessage) {
      return;
    }

    const notification = notifications[0];

    if (!notification || alreadyEnqueued.has(notification.id)) {
      return;
    }

    alreadyEnqueued.add(notification.id);

    const card = (
      <NotificationsPanelCard
        title={notification.title}
        subtitle="1 day ago"
        content={notification.content}
        elevation={8}
        hasBorder
        hasDismiss
        onDismiss={() => handleDismiss(notification.id)}
        onPrimaryAction={() => handleDismiss(notification.id)}
        onSecondaryAction={() => handleDismiss(notification.id)}
        minWidth={200}
      />
    );

    enqueueSnackbar(card, {
      key: notification.id,
      variant: 'default',
      persist: true,
    });
  }, [notifications]);

  return null;
}

NotificationsCardStack.displayName = 'NotificationsCardStack';

export default NotificationsCardStack;
