import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  ArcPropTypes,
  ArcPeopleList,
  ArcView,
  forms,

  Typography,
} from 'arcade-frontend-ui';

// Sorted comes from here
import { getManageMetricsTeamsById } from '../reducers/manage/metrics/teamsById';
import { getManageGamesPeopleById } from '../reducers/manage/games/peopleById';

const model = 'peopleSelector';

const initialState = {
  selectorType: 'everyone',
  peopleIds: [],
  teamIds: [],
};

export const reducer = forms.createFormReducer(model, initialState);

export const getForm = state => state.newForms[model];

const selector = getForm;

export const selectorTypeItems = {
  all: {
    label: 'Everyone',
    value: 'everyone',
  },
  users: {
    label: 'Selected People',
    value: 'users',
  },
  all_teams: {
    label: 'All Teams',
    value: 'all_teams',
  },
  teams: {
    label: 'Selected Teams',
    value: 'teams',
  },
};

const fields = {
  selectorType: {
    type: 'radio',
    label: 'Who should we add?',
  },
};

const formProps = {
  fields,
  selector,
};

const styleTitle = {
  fontWeight: '300',
};

const styleSpacer = {
  marginBottom: 48,
};

class FormPeopleSelector extends React.PureComponent {
  static propTypes = {
    ...forms.propTypes,
    minSelect: ArcPropTypes.number,
    selectablePeople: ArcPropTypes.arrayOf(ArcPropTypes.string),
    selectableTeams: ArcPropTypes.arrayOf(ArcPropTypes.string),
    selectType: ArcPropTypes.string,
  };

  static defaultProps = {
    minSelect: 0,
    selectType: 'user',
    selectablePeople: [],
    selectableTeams: [],
  };

  constructor(props) {
    super(props);

    this.setup();
    this.hasPeopleOrTeams = !!props.selectablePeople.length || !!props.selectableTeams.length;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.teamsById !== this.props.teamsById
      || nextProps.peopleById !== this.props.peopleById) {
      this.setup(nextProps);
    }

    this.hasPeopleOrTeams = !!nextProps.selectablePeople.length || !!nextProps.selectableTeams.length;
  }

  get peopleListName() {
    if (this.props.selectableTeams.length === 1) {
      const id = this.props.selectableTeams[0];
      return `Individuals from ${this.props.teamsById[id].name}`;
    }

    return 'Individuals';
  }

  setup(props) {
    this.teamPeopleMap = {};
    this.peopleTeamMap = {};

    const { teamsById, selectableTeams, values } = props || this.props;

    selectableTeams.forEach((teamId) => {
      if (typeof teamId !== 'undefined') {
        const team = teamsById[teamId];
        const peopleIds = team.peopleIds || [];
        this.teamPeopleMap[team.id] = peopleIds;

        peopleIds.forEach((id) => {
          this.peopleTeamMap[id] = team.id;
        });
      }
    });

    const selectedPeopleByTeamIds = {};

    values.peopleIds.forEach((id) => {
      const teamId = this.peopleTeamMap[id];
      selectedPeopleByTeamIds[teamId] = selectedPeopleByTeamIds[teamId] || [];
      selectedPeopleByTeamIds[teamId].push(id);
    });

    const selectedTeamIds = [];

    Object.keys(selectedPeopleByTeamIds).forEach((key) => {
      const selectedPeople = selectedPeopleByTeamIds[key];
      const teamPeople = this.teamPeopleMap[key];

      if (!!selectedPeople && !!teamPeople && selectedPeople.length === teamPeople.length) {
        selectedTeamIds.push(key);
      }
    });

    this.props.setFieldValue('teamIds', selectedTeamIds);
  }

  handleChangePeople = (e, selected, id, checked) => {
    if (id === 'all') {
      const selectedTeamIds = checked ? this.props.selectableTeams : [];
      this.props.setFieldValue('teamIds', selectedTeamIds);
    }

    this.props.setFieldValue('peopleIds', selected);

    const selectedPeopleByTeamId = {};

    selected.forEach((personId) => {
      const teamId = this.peopleTeamMap[personId];
      selectedPeopleByTeamId[teamId] = selectedPeopleByTeamId[teamId] || [];
      selectedPeopleByTeamId[teamId].push(personId);
    });

    const selectedTeamIds = [];
    Object.keys(selectedPeopleByTeamId).forEach((teamId) => {
      if (!this.teamPeopleMap[teamId]) {
        return;
      }

      if (selectedPeopleByTeamId[teamId].length === this.teamPeopleMap[teamId].length) {
        selectedTeamIds.push(teamId);
      }
    });

    this.props.setFieldValue('teamIds', selectedTeamIds);
  };

  handleChangeTeams = (e, selected, id, checked) => {
    let { peopleIds } = this.props.values;
    const { teamIds } = this.props.values;
    let people = [];

    if (selected.length) {
      people = selected.map((teamId) => {
        const teamPeople = this.teamPeopleMap[teamId] || [];
        const selectedTeamPeople = peopleIds.filter(personId => teamPeople.includes(personId));
        const { length } = selectedTeamPeople;
        const isPartiallySelected = length && length < teamPeople.length;

        if (id === teamId) {
          return checked ? teamPeople : [];
        }

        return isPartiallySelected ? selectedTeamPeople : teamPeople;
      });

      if (id) {
        peopleIds = peopleIds.filter(personId => !this.teamPeopleMap[id] || !this.teamPeopleMap[id].includes(personId));
      }

      people = [].concat(peopleIds).concat(...people);
      people = people.unique();
    } else {
      let deselectedUsers = [];
      teamIds.forEach((teamId) => {
        deselectedUsers = deselectedUsers.concat(this.teamPeopleMap[teamId]);
      });

      people = peopleIds.filter(personId => !deselectedUsers.includes(personId));
    }

    this.props.setFieldValue('teamIds', selected);
    this.props.setFieldValue('peopleIds', people);
  };

  renderPeopleList() {
    const { selectorType } = this.props.values;

    if (['everyone', 'all_teams'].indexOf(selectorType) > -1 && this.hasPeopleOrTeams) {
      return null;
    }

    const teams = this.props.selectableTeams.map(id => this.props.teamsById[id]);
    const people = this.props.selectablePeople.map(id => this.props.peopleById[id]);


    const props = {
      people,
      teams,
      selectedPeople: this.props.values.peopleIds,
      selectedTeams: this.props.values.teamIds,
      isSelectable: true,
    };

    const peopleTitle = (
      <Typography
        style={styleTitle}
        variant="headline"
      >
        {people.length ? this.peopleListName : 'No individuals available'}
      </Typography>
    );

    if (!teams.length && !people.length) {
      return peopleTitle;
    }

    const selectingTeams = ['teams', 'all_teams'].indexOf(selectorType) > -1;

    const teamList = this.props.selectableTeams.length > 1 && (
      <ArcView style={styleSpacer}>
        <Typography
          style={styleTitle}
          variant="headline"
        >
          {teams.length ? 'Teams' : 'No teams available'}
        </Typography>
        {!!teams.length && (
          <ArcPeopleList
            onChange={this.handleChangeTeams}
            type="teams"
            {...props}
          />
        )}
      </ArcView>
    );

    return (
      <ArcView>
        {this.props.selectType === 'team' && teamList}
        <ArcView>
          {!selectingTeams && peopleTitle}
          {this.props.selectType === 'user' && !!people.length && !selectingTeams && (
            <ArcPeopleList
              onChange={this.handleChangePeople}
              type="people"
              {...props}
            />
          )}
        </ArcView>
      </ArcView>
    );
  }

  renderPlaceholder = () => {
    const { minSelect, values } = this.props;
    const { selectorType, peopleIds, teamIds } = values;

    const selectingTeams = ['teams', 'all_teams'].indexOf(selectorType) > -1;
    const { length } = selectingTeams ? teamIds : peopleIds;
    const pluralized = length === 1 ? 'participant' : 'participants';


    if ([selectorTypeItems.all.value, selectorTypeItems.all_teams.value].indexOf(selectorType) > -1) {
      return null;
    }


    if (minSelect && length < minSelect) {
      const minSelectPluralized = minSelect === 1 ? 'participant' : 'participants';
      return `Select at least ${minSelect} ${minSelectPluralized}`;
    }

    return length ? `${length} ${pluralized} selected` : `No ${pluralized} selected`;
  };

  render() {
    const canSelectTeams = this.props.selectableTeams.length > 1 && this.props.selectType === 'team';

    let items = [
      selectorTypeItems.all,
      selectorTypeItems.users,
    ];

    if (canSelectTeams) {
      items = [
        selectorTypeItems.all_teams,
        selectorTypeItems.teams,
      ];
    }

    return (
      <ArcView>
        {this.hasPeopleOrTeams && this.props.renderFields.selectorType({
          placeholder: this.renderPlaceholder(),
          items,
        })}
        {this.renderPeopleList()}
      </ArcView>
    );
  }
}


const getState = (state, props) => {
  const { selectablePeople } = props;
  const { selectableTeams } = props;

  return {
    // selectablePeople: sorted(state.manage.games.peopleById, 'name'),
    // selectableTeams: sorted(state.manage.metrics.teamsById, 'name'),
    selectablePeople,
    selectableTeams,
    peopleById: getManageGamesPeopleById(state),
    teamsById: getManageMetricsTeamsById(state),
    ...props,
  };
};

export default compose(
  connect(getState),
  forms.createForm(model, formProps),
)(FormPeopleSelector);
