import { Platform } from 'react-primitives';
import { createTheme } from '@material-ui/core/styles';
import font from './font';
import palette from './palette';

const fontFamily =
  Platform.OS === 'web'
    ? ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(',')
    : 'System';

const theme = createTheme({
  palette: {
    ...palette,
    action: {
      active: '#b4b4b4',
    },

    primary: palette.primary,
    secondary: palette.secondary,
    text: {
      // primary: '#666666',
      primary: '#23282C',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      light: 'rgba(255,255,255, 0.9)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    icon: {
      primary: '#B4B4B4',
    },
  },

  typography: {
    fontFamily,

    // https://material-ui.com/style/typography/#migration-to-typography-v2
    useNextVariants: true,
  },

  font,

  overrides: {
    MuiToggleButton: {
      root: {
        textTransform: 'initial',
        color: palette.grey.medium,

        '&$selected': {
          color: '#0580B8',
          backgroundColor: '#EBEBEB',
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        color: '#fff',
      },
      containedSecondary: {
        color: '#fff',
      },
    },

    MuiChip: {
      root: {
        backgroundColor: palette.divider,
        border: `1px solid ${palette.grey[300]}`,
      },
    },

    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${palette.divider}`,
      },
    },

    MuiTypography: {
      h1: {
        color: 'blue',
      },
      h2: {
        color: 'blue',
      },
      h5: {
        fontWeight: '400',
      },
    },
  },
});

if (global.window && process.env.NODE_ENV === 'development') {
  window.theme = theme;
}

export default theme;
