import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Help from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';

import ArcPropTypes from '../../helpers/arc/propTypes';
import { palette } from '../../styles';

const tooltipIconStyle = { color: palette.blue['500'] };

const ArcTooltip = props => (
  <Tooltip {...props}>
    <IconButton>
      <Help style={tooltipIconStyle} />
    </IconButton>
  </Tooltip>
);

ArcTooltip.propTypes = {
  title: ArcPropTypes.string.isRequired,
};


export default ArcTooltip;
