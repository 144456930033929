import React from 'react';
import PropTypes from 'prop-types';

import * as GAME_FORMATS from 'arcade-frontend-core/src/types/game-formats';
import * as GAME_SCENE_SIZES from 'arcade-frontend-core/src/types/game-scene-sizes';
import { formatByGameUnit } from 'arcade-frontend-core/src/helpers/numbers/numbers';
import { ArcText, date } from 'arcade-frontend-ui';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcCircuitV2 from 'arcade-frontend-ui/src/components/ArcCircuit/ArcCircuitV2';
import ArcMountainV2 from 'arcade-frontend-ui/src/components/ArcMountain/ArcMountainV2';
import ArcVineV2 from 'arcade-frontend-ui/src/components/ArcVine/ArcVineV2';
import ArcScoreboardBadge from 'arcade-frontend-ui/src/components/ArcScoreboardBadge';

import GamesGamePositionHelper from '../GamesGamePositionHelperText';
import GamesScoreLabel from '../GamesScoreLabel';
import GamesHowToWinText from '../GamesHowToWinText';
import GamesSceneEveryTime from '../GamesSceneEveryTime';
import GamesSceneOneTime from '../GamesSceneOneTime';

function GamesGameScene({
  borderRadius,
  isManager,
  participantCount,
  rankings,
  rewards,
  rules,
  type,
  score,
  pendingCount,
  width,
  size,
}) {
  const { metric, goal, unit } = rules;
  const currentUserRanking =
    rankings && rankings.find(ranking => ranking.isSelf);

  const renderSceneBounty = () => (
    <>
      <ArcMountainV2
        scores={rankings}
        goal={goal}
        borderRadius={borderRadius}
      />
      <ArcBox position="absolute" top={16} left={16}>
        <ArcBox display="flex" flexDirection="row" alignItems="center">
          <ArcScoreboardBadge
            numberWidth={16}
            decimalWidth={6}
            score={score}
            symbol={unit}
            digits={3}
          />

          <GamesScoreLabel
            pl={1}
            color="common.black"
            title={`${isManager ? 'Total' : 'Your'} Score`}
            metric={metric}
          />
        </ArcBox>

        <ArcBox display="flex" flexDirection="column">
          <ArcBox
            component={ArcText}
            mt={1}
            mb={8}
            color="#616264"
            fontSize={12}
            lineHeight="150%"
          >
            <ArcText isStrong>{'Goal'}</ArcText>
            <ArcText marginLeft="4">{formatByGameUnit(unit, goal)}</ArcText>
          </ArcBox>

          <ArcBox maxWidth={100}>
            {isManager ? (
              <GamesGamePositionHelper
                currentPlayerPosition={
                  currentUserRanking ? currentUserRanking.rank : undefined
                }
                numParticipants={participantCount}
                gameType={type}
              />
            ) : (
              <GamesHowToWinText
                gameType={type}
                metric={metric}
                rankings={rankings}
                unit={unit}
                textAlign="left"
              />
            )}
          </ArcBox>
        </ArcBox>
      </ArcBox>
    </>
  );

  const renderSceneTournament = () => (
    <>
      <ArcVineV2 scores={rankings} goal={goal} borderRadius={borderRadius} />
      <ArcBox position="absolute" top={16} left={16}>
        <ArcBox display="flex" flexDirection="row" alignItems="center" mb={2}>
          {isManager ? (
            <ArcScoreboardBadge
              numberWidth={16}
              decimalWidth={6}
              score={score}
              symbol={unit}
              digits={3}
            />
          ) : (
            <ArcScoreboardBadge
              numberWidth={16}
              decimalWidth={6}
              score={currentUserRanking ? currentUserRanking.score : 0}
              symbol={unit}
              digits={3}
            />
          )}

          <GamesScoreLabel
            pl={1}
            color="common.black"
            title={`${isManager ? 'Total' : 'Your'} Score`}
            metric={metric}
          />
        </ArcBox>

        <ArcBox display="flex" flexDirection="column">
          <ArcBox maxWidth={100}>
            {isManager ? (
              <ArcBox display="flex" flexDirection="column" mt={5}>
                <ArcBox
                  component={ArcText}
                  color="#00BA8C"
                  fontSize={24}
                  fontWeight={600}
                  lineHeight="150%"
                >
                  {formatByGameUnit(
                    unit,
                    currentUserRanking ? currentUserRanking.score : 0,
                  )}
                </ArcBox>
                <ArcBox
                  component={ArcText}
                  color="#616264"
                  fontSize={14}
                  fontWeight={500}
                  lineHeight="150%"
                >
                  {currentUserRanking
                    ? `${date.getOrdinal(currentUserRanking.rank)} Place`
                    : 'Not placed yet'}
                </ArcBox>
              </ArcBox>
            ) : (
              <>
                <GamesHowToWinText
                  gameType={type}
                  metric={metric}
                  rankings={rankings}
                  unit={unit}
                  textAlign="left"
                />
                <GamesGamePositionHelper
                  mt={1.5}
                  currentPlayerPosition={
                    currentUserRanking ? currentUserRanking.rank : undefined
                  }
                  numParticipants={participantCount}
                  gameType={type}
                />
              </>
            )}
          </ArcBox>
        </ArcBox>
      </ArcBox>
    </>
  );

  const renderSceneRace = () => (
    <>
      <ArcCircuitV2 scores={rankings} goal={goal} borderRadius={borderRadius} />
      <ArcBox
        position="absolute"
        top="50%"
        left="50%"
        mt={1}
        style={{ transform: 'translate3d(-50%, -50%, 0)' }}
      >
        <ArcBox display="flex" flexDirection="column" alignItems="center">
          {isManager ? (
            <ArcScoreboardBadge
              numberWidth={16}
              decimalWidth={6}
              score={score}
              symbol={unit}
              digits={3}
            />
          ) : (
            <ArcScoreboardBadge
              numberWidth={16}
              decimalWidth={6}
              score={currentUserRanking ? currentUserRanking.score : 0}
              symbol={unit}
              digits={3}
            />
          )}

          <GamesScoreLabel
            py={0.5}
            px={1}
            color="common.black"
            title={`${isManager ? 'Total' : 'Your'} Score`}
          />
          {!isManager && (
            <GamesHowToWinText
              gameType={type}
              metric={metric}
              rankings={rankings}
              textAlign="center"
              textSize={12}
            />
          )}
        </ArcBox>
      </ArcBox>
    </>
  );

  const renderSceneOneTime = () => (
    <GamesSceneOneTime
      currentUserRanking={currentUserRanking}
      rewards={rewards}
      pendingCount={pendingCount}
      metric={metric}
      size={GAME_SCENE_SIZES.LARGE}
    />
  );

  const renderSceneEveryTime = () => (
    <GamesSceneEveryTime
      metric={metric}
      score={score}
      unit={unit}
      goal={goal}
      pendingCount={pendingCount}
    />
  );

  const renderGameScene = () => {
    switch (type) {
      case GAME_FORMATS.BOUNTY:
        return renderSceneBounty();
      case GAME_FORMATS.TOURNAMENT:
        return renderSceneTournament();
      case GAME_FORMATS.RACE:
        return renderSceneRace();
      case GAME_FORMATS.RPA_ONE_TIME:
        return renderSceneOneTime();
      case GAME_FORMATS.RPA_EVERY_TIME:
        return renderSceneEveryTime();
      default:
        return null;
    }
  };

  return (
    <ArcBox
      overflow="hidden"
      position="relative"
      width={width}
      height={307}
      bgcolor="green.200"
      mx="auto"
      borderRadius={borderRadius}
      style={{ transform: 'translateZ(0)' }}
    >
      {renderGameScene()}
    </ArcBox>
  );
}

GamesGameScene.displayName = 'GamesGameScene';

GamesGameScene.propTypes = {
  borderRadius: PropTypes.number,
  isManager: PropTypes.bool,
  participantCount: PropTypes.number,
  rankings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      goal: PropTypes.number,
      isSelf: PropTypes.bool,
      rank: PropTypes.number,
      score: PropTypes.number,
    }),
  ),
  rewards: PropTypes.arrayOf(PropTypes.shape({})),
  rules: PropTypes.shape({
    metric: PropTypes.string,
    goal: PropTypes.number,
    unit: PropTypes.string,
  }),
  score: PropTypes.number,
  type: PropTypes.oneOf(Object.values(GAME_FORMATS.GAME_TYPES)),
  pendingCount: PropTypes.number,
  width: PropTypes.number,
  size: PropTypes.oneOf([GAME_SCENE_SIZES.SMALL, GAME_SCENE_SIZES.LARGE]),
};

GamesGameScene.defaultProps = {
  borderRadius: 8,
  isManager: false,
  participantCount: 0,
  rankings: [],
  rewards: [],
  rules: {
    metric: '',
    goal: 0,
  },
  type: GAME_FORMATS.RACE,
  pendingCount: 0,
  width: 328,
  score: 0,
  size: GAME_SCENE_SIZES.SMALL,
};

export default GamesGameScene;
