import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const GamePad = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.5 18l-1.071 1.785a.5.5 0 01-.858 0L10.5 18m7.5-7.5l1.785 1.071a.5.5 0 010 .858L18 13.5m-12 0l-1.785-1.073a.5.5 0 010-.858L6 10.5M10.5 6l1.071-1.785a.5.5 0 01.858 0L13.5 6"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M23.5 9.5a2 2 0 00-2-2h-5v-5a2 2 0 00-2-2h-5a2 2 0 00-2 2v5h-5a2 2 0 00-2 2v5a2 2 0 002 2h5v5a2 2 0 002 2h5a2 2 0 002-2v-5h5a2 2 0 002-2v-5z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GamePad;
