import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';

import { ArcButton, ArcView, ArcText } from 'arcade-frontend-ui';

const buttonStyle = {
  padding: 8,
  justifyContent: 'flex-start',
  textAlign: 'left',
  textTransform: 'initial',
};

const checkboxStyle = {
  marginLeft: 1,
  marginRight: 16,
};

const textStyle = {
  fontSize: 9,
};

const ArcAudienceListSelectAll = ({
  checked,
  name,
  onClick,
  partiallyChecked,
  ...props
}) => (
  <ArcView row align="center" marginTop="8">
    <ArcButton
      fullWidth
      style={buttonStyle}
      isDisabled={!onClick}
      onClick={e => onClick(e, checked)}
      {...props}
    >
      {!checked && !partiallyChecked && (
        <CheckBoxOutlineBlank
          style={checkboxStyle}
          color="action"
          fontSize="small"
        />
      )}

      {(checked || partiallyChecked) && (
        <CheckBox
          style={checkboxStyle}
          color={checked ? 'primary' : 'action'}
          fontSize="small"
        />
      )}
      <ArcView>
        <ArcText>{checked ? 'Deselect All' : 'Select All'}</ArcText>
        <ArcText style={textStyle}>{name}</ArcText>
      </ArcView>
    </ArcButton>
  </ArcView>
);

ArcAudienceListSelectAll.displayName = 'ArcAudienceListSelectAll';

ArcAudienceListSelectAll.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  partiallyChecked: PropTypes.bool,
};

ArcAudienceListSelectAll.defaultProps = {
  checked: false,
  name: '',
  onClick: global.noop,
  partiallyChecked: false,
};

export default ArcAudienceListSelectAll;
