import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { eventsByGameId } from 'arcade-frontend-features/src/reducers/manage/games/eventsByGameId';
import { propTypes as gamePropTypes } from 'arcade-frontend-features/src/resources/games';
import { apiManageGamesEventsIndexRequest } from 'arcade-frontend-features/src/actions/manage/games/actions';
import GameEvents from '../../components/GameEvents';


const ManageGamesEventsContainer = ({ game, allEvents, getGamesEvents }) => {
  useEffect(() => { getGamesEvents(game.id); }, [game.id]);

  const events = allEvents[game.id];

  if (!events) return null;

  return (<GameEvents events={events} />);
};

ManageGamesEventsContainer.propTypes = {
  game: gamePropTypes.game.isRequired,
  allEvents: PropTypes.objectOf(gamePropTypes.gameEvents).isRequired,
  getGamesEvents: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  allEvents: eventsByGameId(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getGamesEvents: apiManageGamesEventsIndexRequest,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ManageGamesEventsContainer);
