import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from 'arcade-frontend-core/src/actions';
import { getCurrentUserFeatures } from 'arcade-frontend-core/src/reducers/user';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import * as ROUTES from 'arcade-frontend-core/src/types/routes';
import getComponentFromDirective from 'arcade-frontend-core/src/helpers/getComponentFromDirective';

import createWithStyles from 'arcade-frontend-ui/src/styles/createWithStyles';

import NotificationsPortalProvider from 'arcade-frontend-notifications/src/providers/NotificationsPortalProvider';
import NotificationsPortalContainer from 'arcade-frontend-notifications/src/containers/NotificationsPortalContainer';
import ArcUploadManagerContainer from 'arcade-frontend-widgets/src/containers/ArcUploadManagerContainer';

import QuicklinkContainer from 'arcade-frontend-people/src/containers/QuicklinkContainer';
import TaggedActivitiesDialogContainer from 'arcade-frontend-newsfeed/src/containers/TaggedActivitiesDialogContainer';

import NotificationsPanelContainer from 'arcade-frontend-notifications/src/containers/NotificationsPanelContainer';
import ArcNotistackProvider from 'arcade-frontend-core/src/providers/ArcNotistackProvider';

import RoutesOpen from '../routes/RoutesOpen';
import RoutesSecured from '../routes/RoutesSecured';
import { CbLeftMenuContainer } from '../components/app/CbLeftMenuContainer';
import ArcAlertContainer from '../containers/app/ArcAlertContainer';
import ArcAppBar from '../containers/app/ArcAppBar';
import NotificationContainer from '../containers/app/NotificationContainer';
import SizzleContainer from '../containers/app/SizzleContainer';
import scenes from '../routes/scenes';
import { linkToRouteType } from '../routes/helpers';
import ArcTourStepperContainer from '../containers/app/ArcTourStepperContainer';

const isRTPOS = process.env.ARCADE_WHITELABEL_ID === 'rtpos';

const AngularQuicklinkDirective = getComponentFromDirective('quicklink', false);
const AngularViewsDirective = getComponentFromDirective('arcadeViews', false);
const AngularMessageNotificationsDirective = getComponentFromDirective(
  'messageNotifications',
  false,
);

const styleMain = theme => ({
  root: {
    height: `calc(100% - ${ArcAppBar.HEIGHT}px)`,
    flex: 1,
    transform: 'translate3d(0,0,0)',
    [theme.breakpoints.down(840)]: {
      'body.two-pane--view-open &': {
        height: '100%',
      },
    },
  },
});

const Main = createWithStyles(styleMain)('main');

class DevelopmentPlatform extends React.PureComponent {
  static displayName = 'DevelopmentPlatform';

  static propTypes = {
    appGetMuteRequest: PropTypes.func,
    appMenuRequest: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      type: PropTypes.string,
      payload: PropTypes.shape({}),
    }).isRequired,
    currentUser: PropTypes.shape({
      email: PropTypes.string,
      token: PropTypes.string,
      id: PropTypes.string,
    }),
    hasNotificationsPanel: PropTypes.bool,
  };

  static defaultProps = {
    appGetMuteRequest: global.noop,
    appMenuRequest: global.noop,
    currentUser: {},
    hasNotificationsPanel: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isViewingLeftMenu: false,
      isShowingAlert: false,
      counts: {
        chests: 0,
        messages: 0,
      },
      notificationsPanelOpen: false,
    };

    const { type } = props.location;
    this.isViewingMain = type && !!scenes[type];

    if (this.props.currentUser.id) {
      this.requestDataWithDelay();
    }

    if (window.ngRootScope) {
      this.rootScopeListeners = [
        window.ngRootScope.$on(
          'arcade:left-menu:counts',
          this.handleLeftMenuCounts,
        ),
        window.ngRootScope.$on(
          'arcade:notification:unread:count',
          this.handleMessagesCount,
        ),
      ];
    }
  }

  componentWillReceiveProps(nextProps) {
    const { type } = nextProps.location;
    if (type !== this.props.location.type) {
      this.isViewingMain = type && !!scenes[type];
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      if (
        this.props.currentUser.id &&
        this.props.currentUser.id !== prevProps.currentUser.id
      ) {
        this.requestDataWithDelay();
      }
    }
  }

  componentWillUnmount() {
    if (this.rootScopeListeners) {
      this.rootScopeListeners.forEach(deregister => deregister());
    }
  }

  get classnamesApp() {
    return classnames('App App--Development', {
      'is-viewing-main': this.isViewingMain,
    });
  }

  setNotificationsPanelOpen = notificationsPanelOpen =>
    this.setState({ notificationsPanelOpen });

  requestDataWithDelay = () => {
    if (this.requestDataTimeout) {
      window.clearTimeout(this.requestDataTimeout);
    }

    this.requestDataTimeout = window.setTimeout(() => {
      if (!this.props.currentUser?.id) {
        return;
      }

      this.props.appMenuRequest();
      this.props.appGetMuteRequest();
    }, 1000);
  };

  handleCloseLeftMenu = () => {
    this.setState({ isViewingLeftMenu: false });
  };

  handleMessagesCount = (_, messages) => {
    this.setState({
      counts: {
        ...this.state.counts,
        messages,
      },
    });
  };

  handleLeftMenuCounts = (_, counts) => {
    this.setState({
      counts: {
        chests: counts.chests,
        messages: counts.messages,
      },
    });
  };

  handleMenuClick = () => {
    if (this.isViewingMain) {
      linkToRouteType(scenes[this.props.location.type]);
    } else if (window.arcPostMessage) {
      window.arcPostMessage('native.navigate.DrawerToggle');
    } else {
      this.setState({ isViewingLeftMenu: true });
    }
  };

  handleNotificationClick = () => {
    if (this.props.hasNotificationsPanel) {
      if (window.arcPostMessage) {
        window.arcPostMessage('native.navigate.NotificationsPanelModal');
      } else {
        this.setNotificationsPanelOpen(true);
      }
    } else if (window?.ngRootScope) {
      window.ngRootScope.$emit('arcade:goto:home');
    }
  };

  handleNotificationsPanelClose = () => {
    this.setNotificationsPanelOpen(false);
  };

  render() {
    const { currentUser, location } = this.props;

    const { email, token } = currentUser;

    const isLoginRoute =
      location.type === ROUTES.ROUTE_LOGIN ||
      location.type === ROUTES.ROUTE_LOGIN_VIA_LINK;

    if (!currentUser.id || !email || !token || isLoginRoute) {
      return <RoutesOpen platform="development" />;
    }

    const isNative = !!window.arcPostMessage;

    const hasLeftMenu = !isRTPOS;

    return (
      <div className={this.classnamesApp}>
        {hasLeftMenu && !window.arcPostMessage && (
          <CbLeftMenuContainer
            chatCount={this.state.counts.messages}
            location={this.props.location}
            rewardsCount={this.state.counts.chests}
          />
        )}
        <ArcAppBar
          onMenuClick={this.handleMenuClick}
          onNotificationClick={this.handleNotificationClick}
        />
        <Main>
          <ArcNotistackProvider>
            <NotificationContainer />
            <RoutesSecured platform="development" />

            <AngularQuicklinkDirective />
            <SizzleContainer />
            <ArcAlertContainer />
            <ArcUploadManagerContainer />
            <QuicklinkContainer />
            <TaggedActivitiesDialogContainer />
            {isNative && (
              <>
                <AngularMessageNotificationsDirective />
                <ArcTourStepperContainer />
              </>
            )}

            <AngularViewsDirective />
            <NotificationsPanelContainer
              open={this.state.notificationsPanelOpen}
              onClose={this.handleNotificationsPanelClose}
            />
            <NotificationsPortalProvider>
              <NotificationsPortalContainer />
            </NotificationsPortalProvider>
          </ArcNotistackProvider>
        </Main>
      </div>
    );
  }
}

const getState = (state, ownProps) => ({
  hasNotificationsPanel: getCurrentUserFeatures(
    state,
    FEATURE_FLAGS.NOTIFICATIONS_PANEL,
  ),
  ...ownProps,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      appGetMuteRequest: actions.appGetMuteRequest,
      appMenuRequest: actions.appMenuRequest,
    },
    dispatch,
  );

export default connect(getState, getActions)(DevelopmentPlatform);
