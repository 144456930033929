import {
  ArcStyles,
} from 'arcade-frontend-ui';

export const styleInformationContainer = {
  maxWidth: 400,
  padding: '0 8px',
  marginTop: 16,

};
export const styleInformationText = {
  color: ArcStyles.theme.defaultTextColor,
  fontSize: 18,
  fontWeight: '300',
  marginLeft: 16,
};
export const styleInformationNumber = {
  width: 40,
  maxWidth: 40,
  textAlign: 'right',
  fontSize: 24,
  fontWeight: '500',
  margin: 0,
  color: ArcStyles.theme.defaultTextColor,
};
export const styleInformationCurrency = {
  ...styleInformationNumber,
  width: 100,
  maxWidth: 100,
};
export const styleInformationDetailsContainer = {
  width: 400,
  padding: '16px 16px 8px 16px',
  background: ArcStyles.theme.grey8,
  borderBottom: `solid 4px ${ArcStyles.theme.grey7}`,
  borderTop: `solid 4px ${ArcStyles.theme.grey7}`,
};
export const styleInformationRow = {
  marginBottom: 8,
};
export const styleInformationRewardContainer = {
  paddingTop: 16,
};
export const styleInformationRewardText = {
  margin: 0,
  paddingLeft: 16,
};
export const styleInformationRewardImageContainer = {
  padding: '0 0',
};
export const styleInformationRewardImage = {
  width: 88,
  height: 88,
};
