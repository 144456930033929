import React from 'react';
import PropTypes from 'prop-types';

import * as GAME_STATUSES from 'arcade-frontend-core/src/types/game-statuses';
import ArcResourceSpinner from 'arcade-frontend-ui/src/components/ArcResourceSpinner';

/**
 * FEATURE FLAG - Can delete these files when removing NEW_PAGINATION feature flag:
 * LegacyGamesEventsTableContainer.js,
 * LegacyGamesEventsTableContainer.test.js,
 * and LegacyGamesEventsTable.js
 */
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';

import GamesEventsTableContainer from '../../containers/GamesEventsTableContainer';
import LegacyGamesEventsTableContainer from '../../containers/GamesEventsTableContainer/LegacyGamesEventsTableContainer';
import GamesLeaderboardContainer from '../../containers/GamesLeaderboardContainer';
import { getGameEventsStatus } from '../../reducers/game-events';
import { getGameLeaderboardStatus } from '../../reducers/game-leaderboard';
import GamesGameCardTabPanel from './GamesGameCardTabPanel';
import GamesRules from '../GamesRules';

function GamesGameCardTabs({
  activeTab,
  onClose,
  onBack,
  gameId,
  type,
  isManager,
  rules,
  status,
  rewards,
  ...props
}) {
  const hasPagination = useFeatureFlag(FEATURE_FLAGS.NEW_PAGINATION);

  switch (activeTab) {
    case 'events':
      return (
        <GamesGameCardTabPanel
          title="Events"
          spinnerNode={
            <ArcResourceSpinner
              getStatus={getGameEventsStatus(gameId)}
              ml={1}
            />
          }
          onBack={onBack}
          onClose={onClose}
          {...props}
        >
          {hasPagination ? (
            <GamesEventsTableContainer
              gameId={gameId}
              type={type}
              isManager={isManager}
              status={status}
            />
          ) : (
            <LegacyGamesEventsTableContainer
              gameId={gameId}
              type={type}
              isManager={isManager}
              status={status}
            />
          )}
        </GamesGameCardTabPanel>
      );
    case 'leaderboard':
      return (
        <GamesGameCardTabPanel
          title="Leaderboard"
          spinnerNode={
            <ArcResourceSpinner
              getStatus={getGameLeaderboardStatus(gameId)}
              ml={1}
            />
          }
          onBack={onBack}
          onClose={onClose}
          {...props}
        >
          <GamesLeaderboardContainer gameId={gameId} />
        </GamesGameCardTabPanel>
      );
    case 'rules':
      return (
        <GamesGameCardTabPanel
          title="Rules"
          onClose={onClose}
          onBack={onBack}
          display="flex"
          flexDirection="column"
          {...props}
        >
          {
            <GamesRules
              bgcolor="#F2F2F2"
              height="100%"
              type={type}
              metric={rules.metric}
              goal={rules.goal}
              rewards={rewards}
            />
          }
        </GamesGameCardTabPanel>
      );
    default:
      return null;
  }
}

GamesGameCardTabs.displayName = 'GamesGameCardTabs';

GamesGameCardTabs.propTypes = {
  activeTab: PropTypes.oneOf([null, 'events', 'leaderboard', 'rules']),
  gameId: PropTypes.string,
  type: PropTypes.string,
  rules: PropTypes.shape({
    metric: PropTypes.string,
    goal: PropTypes.number,
  }),
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      receivingRank: PropTypes.number,
    }),
  ),
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  isManager: PropTypes.bool,
  status: PropTypes.oneOf(Object.values(GAME_STATUSES)),
};

GamesGameCardTabs.defaultProps = {
  activeTab: null,
  gameId: '',
  type: '',
  rules: {},
  rewards: [],
  onBack: global.noop,
  onClose: global.noop,
  isManager: false,
  status: GAME_STATUSES.ACTIVE,
};

export default GamesGameCardTabs;
