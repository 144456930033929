import React from 'react';
import { Path } from 'react-primitives-svg';
import Icon from '../Icon';

const Message = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 2C5.649 2 .5 6.253.5 11.5a8.737 8.737 0 003.4 6.741L1.5 24l6.372-3.641C9.207 20.784 10.599 21 12 21c6.351 0 11.5-4.253 11.5-9.5S18.351 2 12 2z"
    />
  </Icon>
);

Message.displayName = 'Message';

export default Message;
