import humps from 'humps';

import createResourceAction from 'arcade-frontend-core/src/helpers/createResourceAction';
import transformIdToString from 'arcade-frontend-core/src/helpers/transformIdToString';

export const apiGetActivityFilters = createResourceAction('read', {
  resourceType: 'activityFilters',
  requestKey: 'apiGetActivityFilters',

  api: () => ({
    method: 'GET',
    url: '/v5/newsfeed_filters',
    transformResponse: data => ({
      resources: [
        {
          activityTypes: data.activityTypes
            ? data.activityTypes.map(transformIdToString)
            : [],
          audiences: data.audiences
            ? data.audiences.map(transformIdToString)
            : [],
        },
      ],
    }),
  }),
});

export const apiPostActivityFilters = createResourceAction('update', {
  resourceType: 'activityFilters',
  requestKey: 'apiPostActivityFilters',

  api: payload => ({
    method: 'PUT',
    url: '/v5/newsfeed_filters',
    data: payload.resources
      ? humps.decamelizeKeys({ newsfeedFilter: payload.resources[0] })
      : {},
  }),
});
