import React from 'react';

import { Path } from 'react-primitives-svg';
import Icon from '../Icon';


const PresentOutline = props => (
  <Icon {...props}>
    <Path fill="none" fillRule="evenodd" clipRule="evenodd" d="M11.5417 22.0833C17.3637 22.0833 22.0833 17.3637 22.0833 11.5417C22.0833 5.71967 17.3637 1 11.5417 1C5.71967 1 1 5.71967 1 11.5417C1 17.3637 5.71967 22.0833 11.5417 22.0833Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <Path fill="none" d="M5.712 14.5808V8.28173L11.356 4.98979L17 8.28292V17.7505L14.178 16.2054L11.356 17.8312L5.712 14.5808ZM11.4296 8.28529L8.72625 9.85887V13.0048L11.4296 14.5784L14.1341 13.0048V9.85887L11.4296 8.28529Z" stroke="currentColor" />
  </Icon>
);

export default PresentOutline;
