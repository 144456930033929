import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../actions/audiences';

const initialState = {};

const handleAudienceLoadSuccess = (state, action) => {
  const { payload } = action;
  const { data } = payload;

  const nextState = { ...state };

  data.forEach(item => {
    nextState[item.id] = item;
  });

  return nextState;
};

const handlers = {
  [types.AUDIENCES_GET_AUDIENCES.SUCCESS]: handleAudienceLoadSuccess,
};

export default createReducer(initialState, handlers);

export const getById = state => state.audiences.byId;
