import React from 'react';
import IconClear from '@material-ui/icons/Clear';

import {
  ArcChestImage,
  ArcIconButton,
  ArcImage,
  ArcPropTypes,
  ArcText,
  ArcView,
  ArcButton,
  createWithStyles,
  date,
  theme as baseTheme,
} from 'arcade-frontend-ui';

import { IMAGE_BY_TYPE } from 'arcade-frontend-core/src/types/game-formats';

import pluralize from 'pluralize';

const styles = {
  HeaderSection: theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
      alignItems: 'center',
      padding: theme.spacing(2),
    },
  }),

  GameTypeImage: () => ({
    root: {
      width: 48,
      height: 48,
    },
  }),

  ArcadeText: () => ({
    root: {
      fontFamily: 'Arcade, monospace',
      wordSpacing: '-6px',
      textAlign: 'center',
      lineHeight: '140%',
    },

    heading: {
      color: '#00AB8B',
    },
  }),

  CloseButton: () => ({
    root: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 64,
      height: 64,
      fontSize: 40,

    },
  }),

  TokenImage: theme => ({
    root: {
      marginRight: theme.spacing(1),
      height: 18,
      width: 18,
    },
  }),

  RewardListItem: () => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  }),

  GameDate: theme => ({
    root: {
      marginBottom: theme.spacing(1),
    },
  }),
};

const HeaderSection = createWithStyles(styles.HeaderSection)(ArcView);
const GameTypeImage = createWithStyles(styles.GameTypeImage)(ArcImage);
const ArcadeText = createWithStyles(styles.ArcadeText)(ArcText);
const CloseButton = createWithStyles(styles.CloseButton)(ArcIconButton);
const TokenImage = createWithStyles(styles.TokenImage)(ArcImage);
const RewardListItem = createWithStyles(styles.RewardListItem)(ArcView);
const GameDate = createWithStyles(styles.GameDate)(ArcText);


class GameCardHeader extends React.PureComponent {
  static displayName = 'GameCardHeader';

  static propTypes = {
    gameType: ArcPropTypes.string,
    completedAt: ArcPropTypes.date,
    name: ArcPropTypes.string,
    onClose: ArcPropTypes.func,
    rewards: ArcPropTypes.arrayOf(ArcPropTypes.object),
    startsAt: ArcPropTypes.string,
    startsIn: ArcPropTypes.string,
    status: ArcPropTypes.string,
  };

  static defaultProps = {
    gameType: null,
    completedAt: null,
    name: null,
    onClose: null,
    rewards: [],
    startsAt: null,
    startsIn: null,
    status: null,
  };

  state = {
    showingAllPrizes: false,
  };

  toggleShowingAllPrizes = () => {
    this.setState({ showingAllPrizes: !this.state.showingAllPrizes });
  };

  renderPrize = (reward, ordinalRank) => {
    const { showingAllPrizes } = this.state;

    if (!reward.value) {
      return ordinalRank ? (
        <ArcView row align="center">
          <ArcadeText color="disabled" isSmall marginRight="8">
            {ordinalRank}
          </ArcadeText>
          <ArcadeText isSmall>
            {'No reward'}
          </ArcadeText>
        </ArcView>
      ) : (
        <ArcadeText color="disabled" isSmall>
          {'No reward'}
        </ArcadeText>
      );
    }

    switch (reward.type) {
      case 'chests':
        return (
          <ArcView
            align="center"
            row={showingAllPrizes}
          >
            {ordinalRank ? (
              <ArcadeText color="disabled" isSmall>
                {ordinalRank}
              </ArcadeText>
            ) : (
              <ArcadeText isSmall marginBottom="8">{'Win Chests'}</ArcadeText>
            )}
            <ArcView
              row
            >
              {Object.entries(reward.value).map(([chest, quantity]) => quantity > 0 && (
                <ArcChestImage
                  key={chest}
                  variant={chest.toLowerCase()}
                  label={`${quantity}x`}
                  size="32px"
                  marginLeft="4"
                  marginRight="4"
                />
              ))}
            </ArcView>
          </ArcView>
        );
      case 'tokens':
        return ordinalRank ? (
          <ArcView align="center" row>
            <ArcadeText color="disabled" isSmall marginRight="8">
              {ordinalRank}
            </ArcadeText>
            <ArcView row align="center">
              <TokenImage src={baseTheme.images.coin} />
              <ArcadeText isSmall>
                {`${reward.value} ${reward.type}`}
              </ArcadeText>
            </ArcView>
          </ArcView>
        ) : (
          <ArcView row align="center">
            <ArcadeText isSmall marginRight="8">
              {'Win'}
            </ArcadeText>
            <TokenImage src={baseTheme.images.coin} />
            <ArcadeText isSmall>
              {`${reward.value} ${reward.type}`}
            </ArcadeText>
          </ArcView>
        );
      case 'custom':
        return ordinalRank ? (
          <ArcView
            align="center"
            row
          >
            <ArcadeText color="disabled" isSmall marginRight="8">
              {ordinalRank}
            </ArcadeText>
            <ArcadeText isSmall>
              {reward.value}
            </ArcadeText>
          </ArcView>
        ) : (
          <ArcadeText isSmall>
            {`Win ${reward.value}`}
          </ArcadeText>
        );
      default:
        return null;
    }
  };

  renderRewards() {
    const { rewards } = this.props;
    const { length } = rewards;

    if (rewards && length > 0) {
      if (this.state.showingAllPrizes) {
        return (
          <ArcView>
            { rewards.map(reward => (
              <RewardListItem
                key={reward.receivingRank}
                marginBottom="16"
              >
                {this.renderPrize(reward, date.getOrdinal(reward.receivingRank + 1))}
              </RewardListItem>
            )) }

            <ArcButton onClick={this.toggleShowingAllPrizes}>
              {'hide extra prizes'}
            </ArcButton>
          </ArcView>
        );
      }
      return (
        <React.Fragment>
          { this.renderPrize(rewards[0]) }
          { length > 1 && (
            <ArcButton onClick={this.toggleShowingAllPrizes}>
              {`or ${length - 1} other ${pluralize('prize', length - 1)}`}
            </ArcButton>
          )}
        </React.Fragment>
      );
    }
    return <ArcadeText isSmall>{'No rewards configured'}</ArcadeText>;
  }

  renderCompletedGameInfo() {
    const {
      completedAt,
      startsAt,
    } = this.props;

    const startedAtText = startsAt
      ? `Started on ${date.formatDateTime(new Date(startsAt))}`
      : 'No Start';

    const endedAtText = completedAt
      ? `Ended on ${date.formatDateTime(completedAt)}`
      : 'No End';

    return (
      <ArcView align="center">
        <GameDate isSmall>
          {startedAtText}
        </GameDate>
        <GameDate isSmall>
          {endedAtText}
        </GameDate>
      </ArcView>
    );
  }

  render() {
    const {
      completedAt,
      gameType,
      onClose,
      name,
      status,
      startsIn,
    } = this.props;

    return (
      <HeaderSection component="header">
        <ArcView marginBottom="8">
          <GameTypeImage src={IMAGE_BY_TYPE[gameType]} />
        </ArcView>
        <ArcadeText
          data-testid="GameCardHeader-Name"
          heading
        >
          {name}
        </ArcadeText>
        { status === 'prelaunch'
          && (
            <ArcadeText isSmaller>
              {`Starting ${startsIn}`}
            </ArcadeText>
          )
        }
        { status !== 'completed' && this.renderRewards() }
        { (status === 'completed' || status === 'needs_verification')
          && (
            <ArcView>
              {completedAt && this.renderCompletedGameInfo()}
            </ArcView>
          )

        }
        {
          onClose
          && (
            <CloseButton
              onClick={onClose}
              disableRipple
            >
              <IconClear
                color="disabled"
                fontSize="inherit"
              />
            </CloseButton>
          )
        }
      </HeaderSection>
    );
  }
}

export default GameCardHeader;
