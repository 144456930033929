import React, { FC } from 'react';

import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';

const BillingMain: FC = () => (
  <ArcBox display="flex" flexDirection="column">
    <ArcBox
      component={ArcText}
      fontSize={[20, 24]}
      marginRight={1}
      lineHeight="150%"
      fontWeight="500"
    >
      {'Manage Your Subscription'}
    </ArcBox>
  </ArcBox>
);

export default BillingMain;
