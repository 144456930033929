import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

import {
  ArcView,
  ArcText,
  ArcScroll,
  ArcTextField,
  createWithStyles,
} from 'arcade-frontend-ui';

import ChatSearchResults from '../ChatSearchResults';
import { conversationSearchResultPropType } from '../../propTypes';

const styles = {
  ChatSearchWrapper: theme => ({
    root: {
      backgroundColor: theme.palette.grey.tint,
      height: '100%',
    },
  }),
  ResultsArea: theme => ({
    root: {
      flexGrow: 1,
      flexShrink: 1,
      overflow: 'hidden',
      position: 'relative',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  }),
  WhiteTextField: theme => ({
    root: {
      backgroundColor: theme.palette.common.white,
    },
  }),
};

const WhiteTextField = createWithStyles(styles.WhiteTextField)(ArcTextField);
const ChatSearchWrapper = createWithStyles(styles.ChatSearchWrapper)(ArcView);
const ResultsArea = createWithStyles(styles.ResultsArea)(ArcView);

class ChatSearch extends React.PureComponent {
  static propTypes = {
    initialValue: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    onResultSelected: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    hasError: PropTypes.bool,
    searchResults: PropTypes.arrayOf(conversationSearchResultPropType),
  };

  static defaultProps = {
    initialValue: '',
    isLoading: false,
    hasError: false,
    searchResults: [],
  };

  state = { searchText: this.props.initialValue, isDebouncing: false };

  startSearch = debounce(500, value => {
    this.setState({ isDebouncing: false });

    if (value.length >= 3) {
      this.props.onSearch(value);
    }
  });

  updateSearch = value => {
    this.setState({ searchText: value, isDebouncing: true }, () => {
      this.startSearch(value);
    });
  };

  updateSearchText = event => this.updateSearch(event.target.value);

  renderSearchResults = () => {
    const { isLoading, searchResults, onResultSelected, hasError } = this.props;
    const { isDebouncing } = this.state;

    const showError = hasError && !isLoading && !isDebouncing;

    return showError ? (
      <ArcView padding={16}>
        <ArcText>Error searching - please try again.</ArcText>
      </ArcView>
    ) : (
      <ArcScroll>
        <ChatSearchResults
          searchResults={searchResults}
          isUpdating={isLoading || isDebouncing}
          onResultSelected={onResultSelected}
        />
      </ArcScroll>
    );
  };

  render() {
    const { searchText } = this.state;
    return (
      <ChatSearchWrapper>
        <ArcView
          paddingTop={4}
          paddingRight={24}
          paddingBottom={24}
          paddingLeft={24}
        >
          <WhiteTextField
            variant="outlined"
            value={searchText}
            placeholder="Search for people and channel names"
            onChange={this.updateSearchText}
          />
        </ArcView>
        <ResultsArea>
          {searchText === '' ? (
            <ArcView align="center" justify="center" fullHeight>
              <ArcText color="grey-500" textAlign="center">
                {'Use the search bar to find conversations'}
              </ArcText>
            </ArcView>
          ) : (
            this.renderSearchResults()
          )}
        </ResultsArea>
      </ChatSearchWrapper>
    );
  }
}

export default ChatSearch;
