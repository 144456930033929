import React from 'react';
import PropTypes from 'prop-types';
import Clear from '@material-ui/icons/Clear';

import ArcView from '../../primitives/ArcView';
import ArcAttachedFile from '../ArcAttachedFile';
import ArcIconButton from '../../elements/ArcIconButton';
import { createWithStyles } from '../../styles';

const styles = {
  PreviewContainer: () => ({
    root: {},
  }),
  CloseContainer: () => ({
    root: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  }),
};

const PreviewContainer = createWithStyles(styles.PreviewContainer)(ArcView);
const CloseContainer = createWithStyles(styles.CloseContainer)(ArcView);

const ArcAttachedFileList = ({ files, onRemove }) => (
  <ArcView>
    {
      files.map((file, i) => (
        <PreviewContainer
          key={i.toString()}
          row
          positionRelative
        >
          <ArcAttachedFile isResponsive name={file.name} icon={file.icon} size={file.size} link={file.link} {...file} />
          {onRemove && (
            <CloseContainer>
              <ArcIconButton onClick={() => onRemove(file.name)}><Clear /></ArcIconButton>
            </CloseContainer>
          )}
        </PreviewContainer>
      ))
    }
  </ArcView>
);

ArcAttachedFileList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRemove: PropTypes.func,
};

ArcAttachedFileList.defaultProps = {
  onRemove: null,
};

export default ArcAttachedFileList;
