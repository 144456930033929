import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

export const sorted = tags => (
  tags.sort()
);

const handleHashTagIndexSuccess = (state, action) => (
  action.payload.data
);

const initialState = [];

const handlers = {
  [types.APP_HASH_TAGS_INDEX.SUCCESS]: handleHashTagIndexSuccess,
};

const hashTags = createReducer(initialState, handlers);

export default hashTags;

export const getAppHashTags = state => state.app.hashTags;

export const getAppHashTagsSorted = state => (sorted(getAppHashTags(state)));
