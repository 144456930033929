import React from 'react';
import { connect } from 'react-redux';

import { getAppPeopleById } from 'arcade-frontend-core/src/reducers/peopleById';

import {
  ArcPropTypes,
  ArcFormSummary,
  ArcGrid,
  ArcPeopleList,
  ArcView,
  Typography,
} from 'arcade-frontend-ui';


const styleMarginBottom = {
  marginBottom: 32,
};

class MetricsCreateConfirmationForm extends React.PureComponent {
  static propTypes = {
    setFieldTouched: ArcPropTypes.func.isRequired,
    values: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
    summaryFields: ArcPropTypes.arrayOf(ArcPropTypes.string).isRequired,
    teamsById: ArcPropTypes.shape({}).isRequired,
    peopleById: ArcPropTypes.shape({}).isRequired,

  };

  get hasSelectedRepeat() {
    return !!this.props.values.recurringTimePeriod && this.props.values.recurringTimePeriod !== 'none';
  }

  renderConfirmationPeople = () => {
    const { values } = this.props;

    if (!values) {
      return (
        <Typography variant="body1">
          {'No participants selected'}
        </Typography>
      );
    }

    const { assignType, peopleSelector, selectType } = values;

    const teamIds = peopleSelector.teamIds || [];
    const peopleIds = peopleSelector.peopleIds || [];

    const teams = teamIds.map(id => this.props.teamsById[id]);
    const people = peopleIds.map(id => this.props.peopleById[id]);

    const peopleListProps = {
      people,
      teams,
      showSelectAll: false,
    };

    if (selectType === 'all') {
      return assignType === 'teams' ? (
        <Typography variant="body1">Assigned to all teams</Typography>
      ) : (
        <Typography variant="body1">Assigned to everyone</Typography>
      );
    }

    if (assignType === 'teams' && !teams.length) {
      return (
        <Typography variant="caption">
          {'No teams selected'}
        </Typography>
      );
    }

    if (assignType === 'users' && !people.length) {
      return (
        <Typography variant="caption">
          {'No individuals selected'}
        </Typography>
      );
    }

    switch (assignType) {
      case 'teams':
        return (
          <ArcPeopleList
            type="teams"
            {...peopleListProps}
          />
        );
      case 'users':
      default:
        return (
          <ArcPeopleList
            type="people"
            {...peopleListProps}
          />
        );
    }
  };

  renderTargets() {
    if (this.props.values.targetType === 'none') {
      return (
        <Typography variant="body1">
          {'No targets set for this metric.'}
        </Typography>
      );
    }

    const fields = this.props.values.targetType === 'everyday'
      ? ['target']
      : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    return (
      <ArcFormSummary
        labelsByField={{
          target: 'Everyday',
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday',
        }}
        fields={fields}
        values={this.props.values}
      />
    );
  }

  render() {
    return (
      <ArcGrid
        className="animated fadeIn"
        component={ArcView}
        container
        position="relative"
        spacing={2}
        style={{ maxWidth: 600 }}
        wrap="nowrap"
      >
        <ArcGrid item xs={12}>
          <Typography variant="h5">
            {'Details'}
          </Typography>
        </ArcGrid>

        <ArcGrid item xs={12} style={styleMarginBottom}>
          <ArcFormSummary
            labelsByField={{
              unit: 'Unit Type',
              name: 'Name',
              verb: 'Verb',
              canAddSales: 'Allow manual entry?',
            }}
            fields={this.props.summaryFields}
            values={this.props.values}
          />
        </ArcGrid>

        <ArcGrid item xs={12}>
          <Typography variant="h5">
            {'People'}
          </Typography>
        </ArcGrid>

        <ArcGrid item xs={12} style={styleMarginBottom}>
          {this.renderConfirmationPeople()}
        </ArcGrid>

        <ArcGrid item xs={12}>
          <Typography variant="h5">
            {'Targets'}
          </Typography>
        </ArcGrid>

        <ArcGrid item xs={12} style={styleMarginBottom}>
          {this.renderTargets()}
        </ArcGrid>
      </ArcGrid>
    );
  }
}

const getState = state => ({
  peopleById: getAppPeopleById(state),
  teamsById: {},
});

export default connect(getState)(MetricsCreateConfirmationForm);
