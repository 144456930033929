import React from 'react';

const BountyMountain = props => (
  <svg
    width="273"
    height="255"
    viewBox="0 0 273 255"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="#DDD7F1"
        d="M244.042 0L273 44.499V255H0L187 0z"
      />
      <path
        fill="#FFF"
        d="M186.5 0H244l29 44.22c-15-8.147-22.5-4.887-22.5 9.78-26-21-41-22-53 2-8-22-22.167-26.333-42.5-13l31.5-43z"
      />
      <path d="M0 255L186 1h22" />
    </g>
  </svg>
);

export default BountyMountain;
