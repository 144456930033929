import { extractMentionTypes } from 'arcade-frontend-ui/src/helpers/utils/parsers';
import { isImage } from 'arcade-frontend-core/src/helpers/files';

export const buildActivityFormDataFromEditorFields = ({
  modelValue,
  displayValue,
  mentionIds,
  hashTags,
  files,
  videos,
  audiences,
  isPromotingPost,
}) => {
  const mentionTypes = extractMentionTypes(mentionIds);

  const images = files.filter(f => isImage(f.type));
  const video0 = videos[0];

  const postData = {
    content: modelValue,
    video_id: video0 ? video0.id : null,
    display_content: displayValue,
    tagged_users: mentionTypes.person,
    tagged_teams: mentionTypes.team,
    tagged_entities: mentionTypes.entity,
    hash_tags: hashTags,
    priority: isPromotingPost,
  };

  const formData = new FormData();
  Object.entries(postData).forEach(([key, value]) => {
    if (typeof value !== 'undefined') {
      // This should only be true for editing files
      formData.append(key, value);
    } else {
      // When we remove a document/video it sets the value to undefined in certain cases
      // The backend depends on receiving a null value
      formData.append(key, null);
    }
  });
  // audiences.forEach(audienceId => formData.append('audience_ids[]', audienceId));
  files
    .filter(f => !isImage(f.type))
    .forEach(f => {
      if (typeof f === 'object' && f.id) {
        formData.append('documents[]', f.id);
      } else {
        formData.append('documents[]', f);
      }
    });
  images.forEach(i => {
    if (typeof i === 'object' && i.id) {
      formData.append('images[]', i.id);
    } else {
      formData.append('images[]', i);
    }
  });

  return formData;
};

export const buildActivityJSonDataFromEditorFields = ({
  modelValue,
  displayValue,
  mentionIds,
  hashTags,
}) => {
  const mentionTypes = extractMentionTypes(mentionIds);

  return {
    activity: {
      content: modelValue,
      display_content: displayValue,
      tagged_users: mentionTypes.person,
      tagged_teams: mentionTypes.team,
      hash_tags: hashTags,
    },
  };
};

export const buildCommentJSonDataFromEditorFields = (
  activity,
  { modelValue, displayValue, mentionIds, hashTags },
) => {
  const mentionTypes = extractMentionTypes(mentionIds);

  return {
    activity_id: activity.id,
    content: modelValue,
    display_content: displayValue,
    tagged_users: mentionTypes.person,
    tagged_teams: mentionTypes.team,
    hash_tags: hashTags,
  };
};
