import { combineReducers } from 'redux';

import checklists from './checklists';
import games from './games';
import metrics from './metrics';
import people from './people';
import rewards from './rewards';
import sales from './sales';
import tokens from './tokens';

const manage = combineReducers({
  checklists,
  games,
  metrics,
  people,
  rewards,
  sales,
  tokens,
});

export default manage;
