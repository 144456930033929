import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/games';

const handleTemplateLoadSuccess = (state, action) => {
  if (!action.payload) return state;

  const { data } = action.payload;
  return data;
};

const initialState = [];

const handlers = {
  [types.MANAGE_GAMES_TEMPLATES_INDEX.SUCCESS]: handleTemplateLoadSuccess,
};

const templates = createReducer(initialState, handlers);

export default templates;

export const getManageGamesTemplates = state => state.manage.games.templates;
