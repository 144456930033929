import { ActionCreator } from 'redux';
import {
  AddChannelUsersActionType,
  AddFavouriteType,
  AddMessageActionType,
  ArchiveConversationActionType,
  ChatActionType,
  ClearUserRecordsType,
  CreateConversationActionType,
  DeleteConversationActionType,
  DeleteMessageActionType,
  DismissModalActionType,
  GetOrganisationUsersType,
  GoToAddUsersScreenType,
  GoToManageChannelConfigScreenActionType,
  HandleSelectedNewConverstionUsersType,
  InitChatType,
  JoinConversationActionType,
  LeaveConversationActionType,
  LoadConversationsListType,
  LoadCurrentConversationMessagesActionType,
  PushToConversationWindowSagaType,
  PushToMessageInsightsSagaType,
  RefreshCurrentConversationMessagesActionType,
  RemoveChannelUsersActionType,
  RemoveFavouriteType,
  RemoveMessageActionType,
  SendMessageType,
  SetLastReadIdActionType,
  SyncConversationsOnlyType,
  TypingActionType,
  UpdateConversationActionType,
  UpdateConversationListIsExpandStatus,
  ViewMembersType,
  ViewPublicChannelType,
  WatchConversationTypingActionType,
} from './types/chatActionTypes';
import {
  ChatReducerActionType,
  ResetCurrentConversaitonType,
  ResetLastReadDetailsActionType,
  UpdateSelectedMessageIdType,
  UpdateToggleKebabMenuType,
} from './types/reducerActionTypes';

export const CHAT_ACTION = {
  CLEAR_USER_RECORDS: 'chatAction/CLEAR_USER_RECORDS',
  ARCHIVE_CONVERSATION: 'chatAction/ARCHIVE_CONVERSATION',
  UPDATE_CONVERSATION_LIST_IS_EXPAND_STATUS:
    'chatAction/UPDATE_CONVERSATION_LIST_IS_EXPAND_STATUS',
  DELETE_MESSAGE: 'chatAction/DELETE_MESSAGE',
  DISMISS_MODAL: 'chatAction/DISMISS_MODAL',
  GET_HASH_TAG: 'chatAction/GET_HASH_TAG',
  GET_FAVOURITE: 'chatAction/GET_FAVOURITE',
  WATCH_NETWORK_INFO: 'chatAction/WATCH_NETWORK_INFO',
  WATCH_APP_STATE: 'chatAction/WATCH_APP_STATE',
  WATCH_CHAT_MESSAGES: 'chatAction/WATCH_CHAT_MESSAGES',
  WATCH_CONVERSATIONS: 'chatAction/WATCH_CONVERSATIONS',
  WATCH_MESSAGE_ACTIONS: 'chatAction/WATCH_MESSAGE_ACTIONS',
  WATCH_CONVERSATION_TYPING: 'chatAction/WATCH_CONVERSATION_TYPING',
  SYNC_CONVERSATIONS_LIST: 'chatAction/SYNC_CONVERSATIONS_LIST',
  SYNC_CONVERSATIONS_LIST_ONLY: 'chatAction/SYNC_CONVERSATIONS_LIST_ONLY',
  LOAD_CONVERSATIONS_LIST: 'chatAction/LOAD_CONVERSATIONS_LIST',
  SYNC_CONVERSATION_USERS: 'chatAction/SYNC_CONVERSATION_USERS',
  SYNC_BATCH_MESSAGES: 'chatAction/SYNC_BATCH_MESSAGES',
  LOAD_CONVERSATION_MESSAGES: 'chatAction/LOAD_CONVERSATION_MESSAGES',
  RESEND_MESSAGE: 'chatAction/RESEND_MESSAGE',
  SEND_MESSAGE: 'chatAction/SEND_MESSAGE',
  TYPING: 'chatAction/TYPING',
  INIT_CONVERSATION_SUBSCRIPTION: 'chatAction/INIT_CONVERSATION_SUBSCRIPTION',
  INIT_CHAT: 'chatAction/INIT_CHAT',
  PUSH_TO_CONVERSATION_WINDOW_SAGA:
    'chatAction/PUSH_TO_CONVERSATION_WINDOW_SAGA',
  PUSH_TO_MESSAGE_INSIGHTS_SAGA: 'chatAction/PUSH_TO_MESSAGE_INSIGHTS_SAGA',
  VIEW_MEMBERS: 'chatAction/VIEW_MEMBERS',
  VIEW_PUBLIC_CHANNEL: 'chatAction/VIEW_PUBLIC_CHANNEL',
  ADD_FAVOURITE: 'chatAction/ADD_FAVOURITE',
  REMOVE_FAVOURITE: 'chatkaction/REMOVE_FAVOURITE',
  LOAD_CONVERSATION_USER_PERMISSIONS:
    'chatAction/LOAD_CONVERSATION_USER_PERMISSIONS',
  GET_TAGGABLE_CONVERSATION_USERS: 'chatAction/GET_TAGGABLE_CONVERSATION_USERS',
  GET_ORGANISATION_USERS: 'chatAction/GET_ORGANISATION_USERS',
  ADD_MESSAGE_ACTION: 'chataAction/ADD_MESSAGE_ACTION',
  REMOVE_MESSAGE_ACTION: 'chataAction/REMOVE_MESSAGE_ACTION',
  SYNC_MESSAGE_ACTION: 'chatAction/SYNC_MESSAGE_ACTION',
  SYNC_CONVERSATION_MESSAGES_ACTION:
    'chatAction/SYNC_CONVERSATION_MESSAGES_ACTION',
  CREATE_NEW_CONVERSATiON: 'chatAction/CREATE_NEW_CONVERSATiON',
  JOIN_CONVERSATION: 'chatAction/JOIN_CONVERSATION',
  LEAVE_CONVERSATION: 'chatAction/LEAVE_CONVERSATION',
  UPDATE_CONVERSATION: 'chatAction/UPDATE_CONVERSATION',
  DELETE_CONVERSATION: 'chatAction/DELETE_CONVERSATION',
  SET_LAST_READ_ID: 'chatAction/SET_LAST_READ_ID',
  GO_TO_ADDUSERS_SCREEN: 'chatAction/GO_TO_ADDUSERS_SCREEN',
  ADD_CHANNEL_USERS: 'chatAction/ADD_CHANNEL_USERS',
  REMOVE_CHANNEL_USERS: 'chatAction/REMOVE_CHANNEL_USERS',
  GO_TO_MANAGE_CHANNEL_CONFIG_SCREEN:
    'chatAction/GO_TO_MANAGE_CHANNEL_CONFIG_SCREEN',
  HANDLE_SELECTED_NEW_CONVERSATION_USERS:
    'chatAction/HANDLE_SELECTED_NEW_CONVERSATION_USERS',
  REFRESH_CURRENT_CONVERSATION_MESSAGES:
    'chatAction/REFRESH_CURRENT_CONVERSATION_MESSAGES',
};

export const CHAT_REDUCER_ACTION = {
  UPDATE_CONVERSATION_USER_PERMISSIONS:
    'chatAction/UPDATE_CONVERSATION_USER_PERMISSIONS',
  UPDATE_IS_EXPAND_CONVERSTION_LIST:
    'chatAction/UPDATE_IS_EXPAND_CONVERSTION_LIST',
  UPDATE_SYNC_MESSAGES_STATUS: 'chatAction/UPDATE_SYNC_MESSAGES_STATUS',
  UPDATE_APP_STATE: 'chatAction/UPDATE_APP_STATE',
  RESET_CURRENT_CONVERSATION: 'chatAction/RESET_CURRENT_CONVERSATION',
  RESET_LAST_READ_DETAILS: 'chatAction/RESET_LAST_READ_DETAILS',
  TOOGLE_KEBAB_MENU_DETAILS: 'chatAction/TOOGLE_KEBAB_MENU_DETAILS',
  UPDATE_NETWORK_INFO: 'chatAction/UPDATE_NETWORK_INFO',
  UPDATE_NEW_MESSAGE: 'chatAction/UPDATE_NEW_MESSAGE',
  UPDATE_MESSAGE_ACTIONS: 'chatAction/UPDATE_MESSAGE_ACTIONS',
  UPDATE_SELECTED_MESSAGE_ID: 'chatAction/UPDATE_SELECTED_MESSAGE_ID',
  UPDATE_NEW_CHANNEL_USERS: 'chatAction/UPDATE_NEW_CHANNEL_USERS',
  UPDATE_TAGGABLE_USERS: 'chatAction/UPDATE_TAGGABLE_USERS',
  UPDATE_CURRENT_CONVERSATION_USERS:
    'chatAction/UPDATE_CURRENT_CONVERSATION_USERS',
  UPDATE_HASH_TAGS: 'chatAction/UPDATE_TAGGABLE_USERS',
  UPDATE_DELIVERED_MESSAGE_DETAILS:
    'chatAction/UPDATE_DELIVERED_MESSAGE_DETAILS',
  UPDATE_USER_PROFILE: 'chatAction/UPDATE_USER_PROFILE',
  UPDATE_FAVOURITE_CONVERSATION_LIST:
    'chatAction/UPDATE_FAVOURITE_CONVERSATION_LIST',
  UPDATE_CONVERSATION_LIST: 'chatAction/UPDATE_CONVERSATION_LIST',
  UPDATE_JOINABLE_CONVERSATIONS: 'chatAction/UPDATE_JOINABLE_CONVERSATIONS',
  UPDATE_IS_LOADING_CONVERSATION_MESSAGES:
    'chatAction/UPDATE_IS_LOADING_CONVERSATION_MESSAGES',
  UPDATE_CURRENT_CONVERSATION_MESSAGES_VIEWS:
    'chatAction/UPDATE_CURRENT_CONVERSATION_MESSAGES_VIEWS',
  UPDATE_CURRENT_CONVERSATION_MAX_MESSAGES_REACHED:
    'chatAction/UPDATE_CURRENT_CONVERSATION_MAX_MESSAGES_REACHED',
  UPDATE_IS_CONVERSATION_MESSAGES_EMPTY:
    'chatAction/UPDATE_IS_CONVERSATION_MESSAGES_EMPTY',
  UPDATE_CURRENT_CONVERSATION_ID: 'chatAction/UPDATE_CURRENT_CONVERSATION_ID',
  UPDATE_ORGANISATGION_USERS: 'chatAction/UPDATE_ORGANISATION_USERS',
  UPDATE_INVITABLE_USERS: 'chatAction/UPDATE_INVITABLE_USERS',
  UPDATE_LAST_READ_DETAILS: 'chatAction/UPDATE_LAST_READ_DETAILS',
  UPDATE_CURRENT_CONVERSATION_MESSAGES:
    'chatAction/UPDATE_CURRENT_CONVERSATION_MESSAGES',
  UPDATE_CURRENT_CONVERSATION_TYPING_USERS:
    'chatAction/UPDATE_CURRENT_CONVERSATION_TYPING_USERS',
  UPDATE_TAGGABLE_CONVERSATION_USERS:
    'chatAction/UPDATE_TAGGABLE_CONVERSATION_USERS',
};

export const toggleKebabMenu: ActionCreator<ChatReducerActionType> = (
  payload,
): UpdateToggleKebabMenuType => ({
  type: CHAT_REDUCER_ACTION.TOOGLE_KEBAB_MENU_DETAILS,
  payload,
});

export const updateSelectedMessageId: ActionCreator<ChatReducerActionType> = (
  payload,
): UpdateSelectedMessageIdType => ({
  type: CHAT_REDUCER_ACTION.UPDATE_SELECTED_MESSAGE_ID,
  payload,
});

export const initChat: ActionCreator<ChatActionType> = (
  payload,
): InitChatType => ({
  type: CHAT_ACTION.INIT_CHAT,
  payload,
});

export const clearUserRecords: ActionCreator<ClearUserRecordsType> = () => ({
  type: CHAT_ACTION.CLEAR_USER_RECORDS,
});

export const archiveConversation: ActionCreator<ArchiveConversationActionType> = () => ({
  type: CHAT_ACTION.ARCHIVE_CONVERSATION,
});

export const syncConversationsList: ActionCreator<ChatActionType> = (): SyncConversationsOnlyType => ({
  type: CHAT_ACTION.SYNC_CONVERSATIONS_LIST_ONLY,
});

export const loadConversationsList: ActionCreator<ChatActionType> = (): LoadConversationsListType => ({
  type: CHAT_ACTION.LOAD_CONVERSATIONS_LIST,
});

export const loadCurrentConversationMessages: ActionCreator<ChatActionType> = (): LoadCurrentConversationMessagesActionType => ({
  type: CHAT_ACTION.LOAD_CONVERSATION_MESSAGES,
});

export const updateConversationListIsExpandStatus: ActionCreator<ChatActionType> = (
  payload,
): UpdateConversationListIsExpandStatus => ({
  type: CHAT_ACTION.UPDATE_CONVERSATION_LIST_IS_EXPAND_STATUS,
  payload,
});

export const pushToConversationWindowSaga: ActionCreator<ChatActionType> = (
  payload,
): PushToConversationWindowSagaType => ({
  type: CHAT_ACTION.PUSH_TO_CONVERSATION_WINDOW_SAGA,
  payload,
});

export const pushToMessageInsightsSaga: ActionCreator<ChatActionType> = (
  payload,
): PushToMessageInsightsSagaType => ({
  type: CHAT_ACTION.PUSH_TO_MESSAGE_INSIGHTS_SAGA,
  payload,
});

export const viewMembers: ActionCreator<ChatActionType> = (): ViewMembersType => ({
  type: CHAT_ACTION.VIEW_MEMBERS,
});

export const viewPublicChannel: ActionCreator<ChatActionType> = (): ViewPublicChannelType => ({
  type: CHAT_ACTION.VIEW_PUBLIC_CHANNEL,
});

export const handleSelectedNewConversationUsers: ActionCreator<ChatActionType> = (
  payload,
): HandleSelectedNewConverstionUsersType => ({
  type: CHAT_ACTION.HANDLE_SELECTED_NEW_CONVERSATION_USERS,
  payload,
});

export const addMessageAction: ActionCreator<ChatActionType> = (
  payload,
): AddMessageActionType => ({
  type: CHAT_ACTION.ADD_MESSAGE_ACTION,
  payload,
});

export const removeMessageAction: ActionCreator<ChatActionType> = (
  payload,
): RemoveMessageActionType => ({
  type: CHAT_ACTION.REMOVE_MESSAGE_ACTION,
  payload,
});

export const joinConversation: ActionCreator<ChatActionType> = (
  payload,
): JoinConversationActionType => ({
  type: CHAT_ACTION.JOIN_CONVERSATION,
  payload,
});

export const createConversation: ActionCreator<ChatActionType> = (
  payload,
): CreateConversationActionType => ({
  type: CHAT_ACTION.CREATE_NEW_CONVERSATiON,
  payload,
});

export const updateConversation: ActionCreator<ChatActionType> = (
  payload,
): UpdateConversationActionType => ({
  type: CHAT_ACTION.UPDATE_CONVERSATION,
  payload,
});

export const deleteConversation: ActionCreator<ChatActionType> = (
  payload,
): DeleteConversationActionType => ({
  type: CHAT_ACTION.DELETE_CONVERSATION,
  payload,
});

export const sendMessage: ActionCreator<ChatActionType> = (
  payload,
): SendMessageType => ({
  type: CHAT_ACTION.SEND_MESSAGE,
  payload,
});

export const addChannelUsers: ActionCreator<ChatActionType> = (
  payload,
): AddChannelUsersActionType => ({
  type: CHAT_ACTION.ADD_CHANNEL_USERS,
  payload,
});

export const removeChannelUsers: ActionCreator<ChatActionType> = (
  payload,
): RemoveChannelUsersActionType => ({
  type: CHAT_ACTION.REMOVE_CHANNEL_USERS,
  payload,
});

export const typing: ActionCreator<ChatActionType> = (
  payload,
): TypingActionType => ({
  type: CHAT_ACTION.TYPING,
  payload,
});

export const deleteMesage: ActionCreator<ChatActionType> = (
  payload,
): DeleteMessageActionType => ({
  type: CHAT_ACTION.DELETE_MESSAGE,
  payload,
});

export const refreshCurrentConversationMessages: ActionCreator<ChatActionType> = (): RefreshCurrentConversationMessagesActionType => ({
  type: CHAT_ACTION.REFRESH_CURRENT_CONVERSATION_MESSAGES,
});

export const watchConversationTyping: ActionCreator<ChatActionType> = (): WatchConversationTypingActionType => ({
  type: CHAT_ACTION.WATCH_CONVERSATION_TYPING,
});

export const leaveConversation: ActionCreator<ChatActionType> = (): LeaveConversationActionType => ({
  type: CHAT_ACTION.LEAVE_CONVERSATION,
});

export const dismissModal: ActionCreator<ChatActionType> = (): DismissModalActionType => ({
  type: CHAT_ACTION.DISMISS_MODAL,
});

export const setLastReadId: ActionCreator<ChatActionType> = (): SetLastReadIdActionType => ({
  type: CHAT_ACTION.SET_LAST_READ_ID,
});

export const addFavourite: ActionCreator<ChatActionType> = (): AddFavouriteType => ({
  type: CHAT_ACTION.ADD_FAVOURITE,
});

export const getOrganisationUsers: ActionCreator<ChatActionType> = (): GetOrganisationUsersType => ({
  type: CHAT_ACTION.ADD_FAVOURITE,
});

export const removeFavourite: ActionCreator<ChatActionType> = (): RemoveFavouriteType => ({
  type: CHAT_ACTION.REMOVE_FAVOURITE,
});

export const goToAddUsersScreen: ActionCreator<ChatActionType> = (): GoToAddUsersScreenType => ({
  type: CHAT_ACTION.GO_TO_ADDUSERS_SCREEN,
});

export const goToManageChannelConfigScreen: ActionCreator<ChatActionType> = (): GoToManageChannelConfigScreenActionType => ({
  type: CHAT_ACTION.GO_TO_MANAGE_CHANNEL_CONFIG_SCREEN,
});

export const resetCurrentConversationMessages: ActionCreator<ChatReducerActionType> = (): ResetCurrentConversaitonType => ({
  type: CHAT_REDUCER_ACTION.RESET_CURRENT_CONVERSATION,
});

export const resetLastReadDetails: ActionCreator<ChatReducerActionType> = (): ResetLastReadDetailsActionType => ({
  type: CHAT_REDUCER_ACTION.RESET_LAST_READ_DETAILS,
});
