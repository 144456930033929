import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import Emitter from 'emmett';

import {
  ArcPropTypes,
  ArcButton,
  ArcButtonGroup,
  ArcParagraph,
  ArcPeopleSelector,
  ArcRadioButtonGroup,
  ArcGrid,
  ArcText,
  ArcView,
  ArcScreenBlocker,
  Tooltip,
} from 'arcade-frontend-ui';

import SpaceInvader from 'arcade-frontend-ui/src/assets/SpaceInvader';
import SpaceInvadersTeam from 'arcade-frontend-ui/src/assets/SpaceInvadersTeam';

import * as ASSIGN_TYPES from 'arcade-frontend-core/src/types/game-assignments';

import { getManageGamesValidMetrics } from '../../../reducers/manage/games/validMetrics';
import { getManageGamesMetricsById } from '../../../reducers/manage/games/metricsById';

import { sorted } from '../../../reducers/manage/metrics/teamsById';
import { getManageGamesEligiblePeople } from '../../../reducers/manage/games/eligiblePeople';
import { getManageGamesEligibleTeams } from '../../../reducers/manage/games/eligibleTeams';
import { getManageGamesEligibleTeamPeople } from '../../../reducers/manage/games/eligibleTeamPeople';
import { getManageGamesPeopleById } from '../../../reducers/manage/games/peopleById';
import { getManageGamesTeamsById } from '../../../reducers/manage/games/teamsById';
import { getIsFetchingPeople } from '../../../reducers/manage/games/isFetchingPeople';

const SELECT_TYPES = {
  ALL: 'all',
  SELECTED: 'selected',
};

const styleMarginBottom = {
  marginBottom: 32,
};

const styleMarginBottomSmall = {
  marginBottom: 8,
};

const styleImage = {
  marginBottom: 8,
};

const styleMutedImage = {
  ...styleImage,
  opacity: 0.5,
};

const styleButton = {
  width: 116,
  height: 116,
  marginRight: 16,
  marginBottom: 32,
};

const FIELDS = ['assignType', 'selectType', 'peopleSelector'];

class GamesCreateStepTwoForm extends React.PureComponent {
  static ASSIGN_TYPES = ASSIGN_TYPES;

  static SELECT_TYPES = SELECT_TYPES;

  static FIELDS = FIELDS;

  static propTypes = {
    eligiblePeople: ArcPropTypes.arrayOf(ArcPropTypes.object).isRequired,
    eligibleTeams: ArcPropTypes.arrayOf(ArcPropTypes.object).isRequired,
    eligibleTeamPeople: ArcPropTypes.objectOf(
      ArcPropTypes.arrayOf(ArcPropTypes.object),
    ).isRequired,
    metricsById: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
    setFieldTouched: ArcPropTypes.func.isRequired,
    setFieldValue: ArcPropTypes.func.isRequired,
    teamsById: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
    peopleById: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
    values: ArcPropTypes.objectOf(ArcPropTypes.any),
    isFetching: ArcPropTypes.bool,
  };

  static defaultProps = {
    values: {},
    isFetching: false,
  };

  constructor(props) {
    super(props);

    this.gameTypeEmitter = new Emitter();
  }

  state = {
    selectType: SELECT_TYPES.ALL,
  };

  componentWillUnmount() {
    FIELDS.forEach(field => {
      this.props.setFieldTouched(field, true, false);
    });
  }

  get metric() {
    return this.props.metricsById[this.props.values.metric] || null;
  }

  setSelectType = selectType => this.setState({ selectType });

  handleClickTypeTeam = () => {
    this.props.setFieldValue('assignType', ASSIGN_TYPES.TEAMS);

    this.gameTypeEmitter.emit('resetSearchTerm');
  };

  handleClickType = () => {
    this.props.setFieldValue('assignType', ASSIGN_TYPES.USERS);

    this.gameTypeEmitter.emit('resetSearchTerm');
  };

  render() {
    const { eligiblePeople, eligibleTeamPeople, eligibleTeams } = this.props;

    const extraInfo = (
      <ArcParagraph hasNoMargin>
        {this.metric
          ? 'Select at least two participants to play.'
          : "You'll need to select a metric first."}
      </ArcParagraph>
    );

    const assignTypeTeamDisabled = eligibleTeams && eligibleTeams.length === 0;
    const assignTypePeopleDisabled =
      eligiblePeople && eligiblePeople.length === 0;

    const assignTypeTeamsActive =
      !assignTypeTeamDisabled &&
      this.props.values.assignType === ASSIGN_TYPES.TEAMS;

    const assignTypeTeamButton = (
      <ArcButton
        aria-checked={assignTypeTeamsActive ? 'true' : 'false'}
        className={cx([assignTypeTeamsActive && 'isActive'])}
        disabled={assignTypeTeamDisabled}
        label="Teams"
        onClick={this.handleClickTypeTeam}
        style={styleButton}
      >
        <ArcView align="center">
          <SpaceInvadersTeam
            width="64px"
            height="64px"
            style={
              this.props.values.assignType !== ASSIGN_TYPES.TEAMS
                ? styleMutedImage
                : styleImage
            }
          />
          <ArcText>Teams</ArcText>
        </ArcView>
      </ArcButton>
    );

    const assignTypePeopleActive =
      (!assignTypePeopleDisabled || this.metric) &&
      this.props.values.assignType === ASSIGN_TYPES.USERS;

    const assignTypePeopleButton = (
      <ArcButton
        aria-checked={assignTypePeopleActive ? 'true' : 'false'}
        className={cx([assignTypePeopleActive && 'isActive'])}
        disabled={assignTypePeopleDisabled}
        label="Individuals"
        onClick={this.handleClickType}
        style={styleButton}
      >
        <ArcView align="center">
          <SpaceInvader
            width="64px"
            height="64px"
            style={
              this.props.values.assignType !== ASSIGN_TYPES.USERS
                ? styleMutedImage
                : styleImage
            }
          />
          <ArcText>Individuals</ArcText>
        </ArcView>
      </ArcButton>
    );

    const assignTypeButtons = (
      <ArcButtonGroup>
        {assignTypePeopleDisabled && this.metric ? (
          <Tooltip
            placement="top-start"
            title={`At least two people need to be assigned to ${this.metric.name} before it can be used for an Individuals game`}
          >
            <ArcView>{assignTypePeopleButton}</ArcView>
          </Tooltip>
        ) : (
          assignTypePeopleButton
        )}

        {assignTypeTeamDisabled && this.metric ? (
          <Tooltip
            placement="top-start"
            title={`At least two teams need to be assigned to ${this.metric.name} before it can be used for a Team game`}
          >
            <ArcView>{assignTypeTeamButton}</ArcView>
          </Tooltip>
        ) : (
          assignTypeTeamButton
        )}
      </ArcButtonGroup>
    );

    return (
      <ArcGrid
        component={ArcView}
        container
        position="relative"
        spacing={2}
        wrap="nowrap"
      >
        <ArcGrid item xs={12} style={{ display: 'none' }}>
          {extraInfo}
        </ArcGrid>

        <ArcGrid item xs={12}>
          <Typography style={styleMarginBottom} variant="h5">
            {'Is this an individual or a team game?'}
          </Typography>

          {!this.metric ? (
            <Tooltip
              placement="top-start"
              title="You'll need to select a metric first"
            >
              {assignTypeButtons}
            </Tooltip>
          ) : (
            assignTypeButtons
          )}
        </ArcGrid>

        <ArcGrid item xs={12}>
          <Typography style={styleMarginBottomSmall} variant="h5">
            {'Who is competing in this game?'}
          </Typography>
          <Typography style={styleMarginBottom} variant="subtitle2">
            {`Note that only ${
              this.props.values.assignType === ASSIGN_TYPES.TEAMS
                ? 'teams'
                : 'people'
            } who are assigned to ${this.metric?.name} will ${
              this.state?.selectType === SELECT_TYPES.ALL
                ? 'be added to the game'
                : 'appear in this list'
            }`}
          </Typography>
          <ArcRadioButtonGroup
            id="selectType"
            style={styleMarginBottom}
            onChange={value => {
              this.setSelectType(value);
              this.props.setFieldValue('selectType', value);

              this.props.setFieldValue('peopleSelector', {
                peopleIds: [],
                teamIds: [],
              });
            }}
            value={this.props.values.selectType}
            items={[
              {
                label:
                  this.props.values.assignType === ASSIGN_TYPES.TEAMS
                    ? 'All teams, including those that join in the future'
                    : 'Everyone, including people that join in the future',
                value: SELECT_TYPES.ALL,
              },
              {
                label:
                  this.props.values.assignType === ASSIGN_TYPES.TEAMS
                    ? 'Selected teams'
                    : 'Selected individuals',
                value: SELECT_TYPES.SELECTED,
              },
            ]}
          />
        </ArcGrid>
        {this.props.values.selectType === SELECT_TYPES.SELECTED && (
          <ArcPeopleSelector
            type={
              this.props.values.assignType === ASSIGN_TYPES.TEAMS
                ? 'teams'
                : 'people'
            }
            availablePeople={eligiblePeople}
            availableTeams={eligibleTeams}
            availableTeamPeople={eligibleTeamPeople}
            onChange={val => this.props.setFieldValue('peopleSelector', val)}
            peopleById={this.props.peopleById}
            selectedPeople={this.props.values.peopleSelector.peopleIds}
            selectedTeams={this.props.values.peopleSelector.teamIds}
            teamsById={this.props.teamsById}
            gameTypeEmitter={this.gameTypeEmitter}
            isFetching={this.props.isFetching}
          />
        )}

        {!this.metric && (
          <ArcScreenBlocker
            align="center"
            justify="center"
            textAlign="center"
            color="danger"
          >
            <Typography color="inherit" variant="h5">
              {'You need to select a metric in step 1'}
            </Typography>
          </ArcScreenBlocker>
        )}
      </ArcGrid>
    );
  }
}

const getState = (state, props) => ({
  eligiblePeople: getManageGamesEligiblePeople(state),
  eligibleTeams: getManageGamesEligibleTeams(state),
  eligibleTeamPeople: getManageGamesEligibleTeamPeople(state),
  metricsById: getManageGamesMetricsById(state),
  peopleById: getManageGamesPeopleById(state),
  teamsById: getManageGamesTeamsById(state),
  selectableMetrics: getManageGamesValidMetrics(state),
  selectablePeople: sorted(getManageGamesPeopleById(state), 'name'),
  selectableTeams: sorted(getManageGamesTeamsById(state), 'name'),
  isFetching: getIsFetchingPeople(state).eligiblePeople,
  ...props,
});

export default connect(getState)(GamesCreateStepTwoForm);
