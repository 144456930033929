import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIntegrationWidgetStatus } from 'arcade-frontend-core/src/reducers/integrationWidgetStatus';
import { getCurrentUser, getCurrentUserPermissions } from 'arcade-frontend-core/src/reducers/user';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import * as ROUTES from 'arcade-frontend-core/src/types/routes';

import { ArcListSelectable } from 'arcade-frontend-ui';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';

import CreditCard from 'arcade-frontend-ui/src/icons/CreditCard';
import GameControllerTeam from 'arcade-frontend-ui/src/icons/GameControllerTeam';
import GamePad from 'arcade-frontend-ui/src/icons/GamePad';
import History from 'arcade-frontend-ui/src/icons/History';
import ManageBudgets from 'arcade-frontend-ui/src/icons/ManageBudgets';
// import ManageIntegrations from 'arcade-frontend-ui/src/icons/ManageIntegrations';
import PresentOutline from 'arcade-frontend-ui/src/icons/PresentOutline';
import RunningMan from 'arcade-frontend-ui/src/icons/RunningMan';
import StatsLine from 'arcade-frontend-ui/src/icons/StatsLine';
import Tokens3 from 'arcade-frontend-ui/src/icons/Tokens3';
import User from 'arcade-frontend-ui/src/icons/User';

import { routes } from '../../../actions/manage';
import { routes as routesBilling } from '../../../actions/manage/billing';
import { routes as routesGames } from '../../../actions/manage/games';
import { routes as routesIntegrations } from '../../../actions/manage/integrations';
import { routes as routesMetrics } from '../../../actions/manage/metrics';
import { routes as routesRewards } from '../../../actions/manage/rewards';
import { routes as routesPeople } from '../../../actions/manage/people';
import { routes as salesRoutes } from '../../../actions/manage/sales';
import { routes as routesTokens } from '../../../actions/manage/tokens';

import { getLocationType } from '../../../reducers/location';


interface ManageNavContainerProps {
  onRoute?: () => void;
}

export default function ManageNavContainer({
  onRoute,
}: ManageNavContainerProps): React.ReactNode {
  const dispatch = useDispatch();
  const locationType = useSelector(getLocationType);
  const { features } = useSelector(getCurrentUser);
  const permissions = useSelector(getCurrentUserPermissions);
  const { integrationWidgetLoaded } = useSelector(getIntegrationWidgetStatus);

  const isRTPOS = process.env.ARCADE_WHITELABEL_ID === 'rtpos';

  const createHandleClick = route => () => {
    if (route) {
      dispatch(route());
    }
    if (onRoute) {
      onRoute();
    }
  };

  const availableManageRoutes = [
    !isRTPOS && permissions[PERMISSIONS.MANAGE_SALES] && {
      label: 'Activities',
      route: ROUTES.ROUTE_MANAGE_ACTIVITIES,
      onClick: createHandleClick(salesRoutes.routeManageActivities),
      leftIcon: RunningMan,
    },
    !isRTPOS && features[FEATURE_FLAGS.BILLING_V2] && permissions[PERMISSIONS.MANAGE_BILLING] && global.Platform.OS === 'web' && {
      label: 'Billing',
      route: ROUTES.ROUTE_MANAGE_BILLING2,
      onClick: createHandleClick(routesBilling.routeManageBilling),
      leftIcon: CreditCard,
    },
    !isRTPOS && permissions[PERMISSIONS.MANAGE_GAMES] && {
      label: 'Games',
      route: ROUTES.ROUTE_MANAGE_GAMES,
      onClick: createHandleClick(routesGames.routeManageGames),
      leftIcon: GamePad,
    },
    integrationWidgetLoaded && features[FEATURE_FLAGS.HOT_GLUE] && permissions[PERMISSIONS.MANAGE_DATA_CONNECTORS] && {
      label: 'Integrations',
      route: ROUTES.ROUTE_MANAGE_INTEGRATIONS,
      onClick: createHandleClick(routesIntegrations.routeManageIntegrations),
      leftIcon: History,
    },
    !isRTPOS && permissions[PERMISSIONS.MANAGE_METRICS] && {
      label: 'Metrics',
      route: ROUTES.ROUTE_MANAGE_METRICS,
      onClick: createHandleClick(routesMetrics.routeManageMetrics),
      leftIcon: StatsLine,
    },
    permissions[PERMISSIONS.MANAGE_USERS] && {
      label: 'People',
      route: ROUTES.ROUTE_MANAGE_PEOPLE,
      onClick: createHandleClick(routesPeople.routeManagePeople),
      leftIcon: User,
    },
    features[FEATURE_FLAGS.POST_HISTORY] && permissions[PERMISSIONS.VIEW_POST_HISTORY] && {
      label: 'Posts',
      route: ROUTES.ROUTE_MANAGE_POSTS,
      onClick: createHandleClick(routes.routeManagePosts),
      leftIcon: History,
    },
    permissions[PERMISSIONS.MANAGE_REWARDS] && {
      label: 'Rewards',
      route: ROUTES.ROUTE_MANAGE_REWARDS,
      onClick: createHandleClick(routesRewards.routeManageRewards),
      leftIcon: PresentOutline,
    },
    permissions[PERMISSIONS.MANAGE_TEAMS] && {
      label: 'Teams',
      route: ROUTES.ROUTE_MANAGE_TEAMS,
      onClick: createHandleClick(routes.routeManageTeams),
      leftIcon: GameControllerTeam,
    },
    permissions[PERMISSIONS.MANAGE_TOKENS] && {
      label: 'Tokens',
      route: ROUTES.ROUTE_MANAGE_TOKENS,
      onClick: createHandleClick(routesTokens.routeManageTokens),
      leftIcon: Tokens3,
    },
    features[FEATURE_FLAGS.TOKEN_BUDGETS] && permissions[PERMISSIONS.SET_MONTHLY_LIMIT] && {
      label: 'Token Budgets',
      route: ROUTES.ROUTE_MANAGE_TOKEN_BUDGETS,
      onClick: createHandleClick(routesTokens.routeManageTokenBudgets),
      leftIcon: ManageBudgets,
    },
    // !isRTPOS && permissions[PERMISSIONS.MANAGE_INTEGRATIONS] && {
    //   label: 'Integrations',
    //   route: ROUTES.ROUTE_MANAGE_INTEGRATION_HISTORY,
    //   onClick: createHandleClick(routes.routeManageIntegrationHistory),
    //   leftIcon: ManageIntegrations,
    // },
  ].filter(Boolean);

  return (
    <ArcViewBox component="nav" aria-label="manage arcade">
      <ArcListSelectable
        currentRoute={locationType}
        listItemRoutes={availableManageRoutes}
      />
    </ArcViewBox>
  );
}

ManageNavContainer.defaultProps = {

};
