import { getResources, getStatus, resourceReducer } from 'redux-resource';

const gameEvents = resourceReducer('gameEvents');

export default gameEvents;

export const getGameEvents = gameId => state =>
  getResources(state.gamesV2.gameEvents, gameId);

export const getGameEventsStatus = gameId => state =>
  getStatus(state, `gamesV2.gameEvents.requests.game-${gameId}.status`);

export const getGameEventsUpdateStatusById = (eventIds, type) => state => {
  const events = {};
  eventIds.forEach(eventId => {
    events[eventId] = getStatus(
      state,
      `gamesV2.gameEvents.requests.${type}${eventId}.status`,
    );
  });
  return events;
};

export const getGameEventsRevertStatus = state =>
  getStatus(state, 'gamesV2.gameEvents.requests.apiRevertGameEvent.status');
