import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import {
  GAME_TYPES,
  IMAGE_BY_TYPE,
  DISPLAYABLE_TITLE_BY_TYPE,
} from 'arcade-frontend-core/src/types/game-formats';
import ClockOutline from 'arcade-frontend-ui/src/icons/ClockOutline';
import { i18nFormatDateTime } from 'arcade-frontend-ui/src/helpers/utils/date';

import GamesRewardTag from '../GamesRewardTag';

function GamesUpcomingGameScene({
  expiresAt,
  rewards,
  startsAt,
  type,
  ...props
}) {
  return (
    <ArcBox
      display="flex"
      position="relative"
      color="#fff"
      style={{
        backgroundImage: `url("${IMAGE_BY_TYPE[type]}")`,
        backgroundPosition: 'center',
        backgroundSize: '50%',
        backgroundRepeat: 'no-repeat',
      }}
      {...props}
    >
      <ArcBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flex={1}
        bgcolor="rgba(97, 98, 100, 0.9)"
        textAlign="center"
      >
        <ArcBox mb={1}>
          <ClockOutline width="40px" height="40px" />
        </ArcBox>
        <ArcBox
          lineHeight={1.5}
          fontSize="24px"
          fontWeight="600"
          component={ArcText}
          textTransform="capitalize"
        >
          {`${DISPLAYABLE_TITLE_BY_TYPE[type]} Starts in ${moment(
            startsAt,
          ).toNow(true)}`}
        </ArcBox>
        {!!startsAt && !!expiresAt && (
          <ArcBox lineHeight={1.5}>
            {i18nFormatDateTime(startsAt)} &mdash;{' '}
            {i18nFormatDateTime(expiresAt)}
          </ArcBox>
        )}
        {!!rewards.length && (
          <ArcBox mt={2}>
            <GamesRewardTag rewards={rewards} rewardType={rewards[0].type} />
          </ArcBox>
        )}
      </ArcBox>
    </ArcBox>
  );
}

GamesUpcomingGameScene.propTypes = {
  expiresAt: PropTypes.string,
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ),
  startsAt: PropTypes.string,
  type: PropTypes.oneOf(GAME_TYPES),
};

GamesUpcomingGameScene.defaultProps = {
  expiresAt: '',
  rewards: [],
  startsAt: '',
  type: GAME_TYPES[0],
};

export default GamesUpcomingGameScene;
