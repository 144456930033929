import * as types from 'arcade-frontend-core/src/types/routes';

export const routeManageMetrics = () => ({
  type: types.ROUTE_MANAGE_METRICS,
  payload: {},
});

export const routeManageMetricsCreate = (step = 1) => ({
  type: types.ROUTE_MANAGE_METRICS_CREATE,
  payload: {
    step,
  },
});

export const routeManageMetricsShow = (id, metricDetailsType) => ({
  type: types.ROUTE_MANAGE_METRICS_SHOW,
  payload: {
    id,
    metricDetailsType,
  },
});


export const routeManageMetricsEdit = id => ({
  type: types.ROUTE_MANAGE_METRICS_EDIT,
  payload: {
    id,
  },
});
