import React from 'react';

export default props => (
  <svg
    width="512"
    height="512"
    {...props}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h512v512H0z" />
      <g transform="translate(34.816 34.816)">
        <circle fill="#F8F8F8" cx="221.184" cy="221.184" r="221.184" />
        <path
          d="M209.945 393.042l-25.582 46.053a219.38 219.38 0 0 1-40.25-10.758l.873-52.773c19.793 9.827 41.748 15.954 64.959 17.478zm22.488.025c23.199-1.47 45.149-7.539 64.948-17.304l.87 52.575a219.38 219.38 0 0 1-40.25 10.758l-25.568-46.029zm84.508-28.609a172.976 172.976 0 0 0 47.515-47.514l27.018 45.058a222.312 222.312 0 0 1-29.475 29.474l-45.058-27.018zm58.82-67.075c9.766-19.799 15.835-41.748 17.306-64.946l46.028 25.568a219.38 219.38 0 0 1-10.758 40.249l-52.575-.87zm17.281-87.438c-1.524-23.21-7.65-45.164-17.477-64.957l52.772-.874a219.38 219.38 0 0 1 10.758 40.249l-46.053 25.582zm-28.713-84.31a172.975 172.975 0 0 0-47.704-47.536L362 50.892a222.312 222.312 0 0 1 29.475 29.474l-27.145 45.269zm-66.954-58.633c-19.86-9.793-41.881-15.868-65.157-17.314l25.784-46.416a219.38 219.38 0 0 1 40.25 10.758l-.877 52.972zm-87.215-17.29c-23.288 1.5-45.315 7.634-65.167 17.489l-.88-53.17a219.38 219.38 0 0 1 40.25-10.758l25.797 46.44zm-84.208 28.515a172.975 172.975 0 0 0-47.726 47.727L50.892 80.368a222.311 222.311 0 0 1 29.475-29.475l45.585 27.334zM67.2 144.994c-9.855 19.853-15.988 41.88-17.488 65.168l-46.44-25.797a219.38 219.38 0 0 1 10.759-40.25l53.17.88zM49.688 232.22c1.447 23.276 7.522 45.298 17.316 65.157l-52.974.876a219.38 219.38 0 0 1-10.758-40.25l46.416-25.783zM78.1 316.625a172.975 172.975 0 0 0 47.537 47.705l-45.27 27.145A222.311 222.311 0 0 1 50.892 362L78.1 316.625z"
          fill="#F3F3F3"
        />
        <circle fill="#E7E7E7" cx="221.184" cy="221.184" r="172.032" />
      </g>
      <g fill="#FFF">
        <path d="M158.317 309.18V204.63L252 149.977l93.683 54.672v157.15l-46.842-25.635L252 363.146l-93.683-53.967zm94.914-104.492l-44.882 26.115v52.232l44.882 26.115 44.881-26.115v-52.232l-44.881-26.115z" />
        <path
          d="M252 149.976l93.683 53.292v106.586L252 363.146l-93.683-53.292V203.268L252 149.976zm1.23 54.712l-44.88 26.115v52.232l44.88 26.115 44.882-26.115v-52.232l-44.881-26.115z"
          opacity=".376"
        />
        <path d="M251.143 312.024l-40.35 23.478 40.193 23.205zM204.733 285.019l-40.35 23.478 40.193 23.205zM207.632 333.602l40.35-23.478-40.192-23.205zM161.222 306.597l40.35-23.478-40.192-23.205zM251.143 202.22l-40.35-23.478 40.193-23.205zM204.733 229.225l-40.35-23.478 40.193-23.205zM207.632 180.642l40.35 23.478-40.192 23.205zM161.222 207.647l40.35 23.478-40.192 23.205zM204.614 233.821l-40.35 23.478 40.192 23.205zM254.196 202.22l40.35-23.478-40.192-23.205zM300.607 229.225l40.35-23.478-40.193-23.205zM297.708 180.642l-40.35 23.478 40.192 23.205zM342.778 207.647l-40.35 23.478 40.192 23.205zM342.778 310.673l-40.35 23.479 40.192 23.205zM254.196 312.024l40.35 23.478-40.192 23.205zM300.607 285.019l40.35 23.478-40.193 23.205zM297.708 333.602l-40.35-23.478 40.192-23.205zM342.778 306.597l-40.35-23.478 40.192-23.205zM301.63 280.423l40.35-23.478-40.192-23.205z" />
      </g>
    </g>
  </svg>
);
