import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const GifOutline = props => (
  <Icon {...props}>
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M15 15h2.25M15 18v-6a1.5 1.5 0 011.5-1.5H18M12 18v-7.5M8.25 15H9v1.5a1.5 1.5 0 01-3 0V12a1.5 1.5 0 011.5-1.5H9M1.75.75h20.5a1 1 0 011 1v20.5a1 1 0 01-1 1H1.75a1 1 0 01-1-1V1.75a1 1 0 011-1z"
    />
  </Icon>
);

export default GifOutline;
