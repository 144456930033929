import React from 'react';
import PropTypes from 'prop-types';

const ArcCustomReward = ({ fill, ...props }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path d="M24.4986 8.18628L13.0673 21.5885L17.717 8.18628H24.4986Z" fill="#F9F9F9" stroke="#616264" strokeWidth="0.234375" />
      <path d="M7.70685 7.95202H0.996417L5.67699 2.1013L7.70685 7.95202Z" fill="#F2F2F2" stroke="#616264" strokeWidth="0.234375" />
      <path d="M17.7983 7.95154L19.8282 2.10081L24.5088 7.95154H17.7983Z" fill="#F2F2F2" stroke="#616264" strokeWidth="0.234375" />
      <path d="M8.11279 7.95154L12.7526 2.05139L17.3924 7.95154H8.11279Z" fill="#F2F2F2" stroke="#616264" strokeWidth="0.234375" />
      <path d="M7.91198 7.82773L5.88281 1.979H12.5114L7.91198 7.82773Z" fill="#D8D8D8" stroke="#616264" strokeWidth="0.234375" />
      <path d="M17.5932 7.82773L12.9938 1.979H19.6224L17.5932 7.82773Z" fill="#F9F9F9" stroke="#616264" strokeWidth="0.234375" />
      <path d="M17.4689 8.18628L12.7526 21.7805L8.03625 8.18628H17.4689Z" fill="#D8D8D8" stroke="#616264" strokeWidth="0.234375" />
      <path d="M7.78816 8.18628L12.4379 21.5885L1.00657 8.18628H7.78816Z" fill="#B4B4B4" stroke="#616264" strokeWidth="0.234375" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect x="0.752594" width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

ArcCustomReward.propTypes = {
  fill: PropTypes.string,
};

ArcCustomReward.defaultProps = {
  fill: 'none',
};


export default ArcCustomReward;
