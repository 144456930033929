import { bindActionCreators } from 'redux';

import * as actions from './actions';
import * as routes from './routes';
import * as types from './types';

export {
  actions,
  routes,
  types,
};

export const getGamesIndexActions = dispatch => bindActionCreators({
  apiGamesIndexRequest: actions.apiGamesIndexRequest,
  routeGamesShow: routes.routeGamesShow,
}, dispatch);

export const getGameDetailsActions = dispatch => bindActionCreators({
  routeGamesShow: routes.routeGamesShow,
  routeGames: routes.routeGames,
  apiGamesActivitiesCreateRequest: actions.apiGamesActivitiesCreateRequest,
  apiGamesRankingsIndexRequest: actions.apiGamesRankingsIndexRequest,
  apiGamesEventsIndexRequest: actions.apiGamesEventsIndexRequest,
}, dispatch);

export const getGameLeaderboardActions = dispatch => bindActionCreators({
  apiGamesRankingsIndexRequest: actions.apiGamesRankingsIndexRequest,
}, dispatch);
