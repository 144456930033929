import React from 'react';
import { ArcPropTypes, ArcCard, ArcCardContent } from 'arcade-frontend-ui';

import MetricCardNav from './MetricCardNav';
import MetricCardHeader from './MetricCardHeader';

class MetricCard extends React.PureComponent {
  static propTypes = {
    onArchive: ArcPropTypes.func,
    onClick: ArcPropTypes.func,
    onClickNav: ArcPropTypes.func,
    onClose: ArcPropTypes.func,
    onEdit: ArcPropTypes.func,
    onRestore: ArcPropTypes.func,

    children: ArcPropTypes.children,
    style: ArcPropTypes.style,

    hasArchive: ArcPropTypes.bool,
    hasAdd: ArcPropTypes.bool,
    hasEdit: ArcPropTypes.bool,
    hasDetails: ArcPropTypes.bool,
    hasRestore: ArcPropTypes.bool,
    hideNav: ArcPropTypes.bool,

    benchmarkValue: ArcPropTypes.number,
    percentOfBenchmark: ArcPropTypes.number,
    progress: ArcPropTypes.number,

    currentMetricDetailsType: ArcPropTypes.string,
    currentMetricId: ArcPropTypes.string,
    id: ArcPropTypes.string,
    kpiId: ArcPropTypes.string,
    name: ArcPropTypes.node,
    symbol: ArcPropTypes.string,
    verb: ArcPropTypes.node,
    canAddSales: ArcPropTypes.bool,
    isCalculated: ArcPropTypes.bool,
  };

  static defaultProps = {
    onArchive: null,
    onClick: undefined,
    onClickNav: ArcPropTypes.noop,
    onClose: null,
    onEdit: null,
    onRestore: null,

    children: null,
    style: null,

    hasArchive: false,
    hasAdd: false,
    hasEdit: false,
    hasDetails: false,
    hasRestore: false,
    hideNav: false,

    benchmarkValue: null,
    percentOfBenchmark: null,
    progress: null,

    currentMetricDetailsType: null,
    currentMetricId: null,
    id: null,
    kpiId: null,
    name: null,
    symbol: null,
    verb: null,
    canAddSales: true,
    isCalculated: false,
  };

  constructor(props) {
    super(props);

    if (props.onClick) {
      this.handleClick = e => props.onClick(e, this.props.id);
    } else {
      this.handleClick = undefined;
    }
  }

  renderContent() {
    return this.props.children ? (
      <ArcCardContent>{this.props.children}</ArcCardContent>
    ) : null;
  }

  render() {
    const {
      name,
      progress,
      symbol,
      id,
      currentMetricId,
      currentMetricDetailsType,
      onClickNav,
      onArchive,
      onRestore,
      hasDetails,
      hasArchive,
      hasEdit,
      hasAdd,
      hasRestore,
      onEdit,
      onClose,
      kpiId,
      percentOfBenchmark,
      style,
      benchmarkValue,
      verb,
      canAddSales,
      isCalculated,
    } = this.props;

    return (
      <ArcCard style={style} hasDetails={hasDetails}>
        <MetricCardHeader
          name={name}
          symbol={symbol}
          progress={progress}
          verb={verb}
          onClose={onClose}
          onCreate={hasDetails || !canAddSales ? undefined : this.handleClick}
          lineOneHasBorder={!this.props.hideNav}
          canAddSales={canAddSales}
          isCalculated={isCalculated}
        />
        {this.props.hideNav ? null : (
          <MetricCardNav
            id={id}
            kpiId={kpiId}
            onClick={onClickNav}
            currentMetricId={currentMetricId}
            currentMetricDetailsType={currentMetricDetailsType}
            percentOfBenchmark={percentOfBenchmark}
            benchmarkValue={benchmarkValue}
            hasDetails={hasDetails}
            hasAdd={hasAdd && canAddSales}
            hasEdit={hasEdit}
            onEdit={onEdit}
            hasArchive={hasArchive}
            onArchive={onArchive}
            hasRestore={hasRestore}
            onRestore={onRestore}
            isCalculated={isCalculated}
          />
        )}
        {this.renderContent()}
      </ArcCard>
    );
  }
}

export default MetricCard;
