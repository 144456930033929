import * as routeTypes from 'arcade-frontend-core/src/types/routes';
import games from './games';
import manage from './manage';
import newsfeed from './newsfeed';
import people from './people';
import profile from './profile';
import rewards from './rewards';

export default {
  [routeTypes.ROUTE_BASE]: '/',
  [routeTypes.NOT_FOUND]: '/arcade/404',
  [routeTypes.ROUTE_HOME]: '/arcade/home',
  [routeTypes.ROUTE_LOGIN]: '/login/:encodedObject?',
  [routeTypes.ROUTE_LOGIN_VIA_LINK]: '/authenticate/:encodedObject?',

  [routeTypes.ROUTE_CHAT]: '/arcade/messages',
  [routeTypes.ROUTE_CHAT_SHOW]: '/arcade/messages/:id',
  [routeTypes.ROUTE_CHAT_CREATE]: '/arcade/messages/create/:type',
  [routeTypes.ROUTE_CHAT_JOIN]: '/arcade/messages/join/:type',

  [routeTypes.ROUTE_ACADEMY]: '/arcade/training',
  [routeTypes.ROUTE_ACADEMY_NEW]: '/arcade/training/new',
  [routeTypes.ROUTE_ACADEMY_SHOW]: '/arcade/training/:id',
  [routeTypes.ROUTE_ACADEMY_EDIT]: '/arcade/training/:id/edit/:step',
  [routeTypes.ROUTE_ACADEMY_START]: '/arcade/training/:id/start/:quizId/:qId',

  [routeTypes.ROUTE_HUDDLE]: '/arcade/huddle',
  [routeTypes.ROUTE_INSIGHTS]: '/arcade/stats',
  [routeTypes.ROUTE_HUB]: '/arcade/hub',
  [routeTypes.ROUTE_LEAGUE]: '/arcade/league',

  [routeTypes.ROUTE_METRICS]: '/arcade/metrics',
  [routeTypes.ROUTE_METRICS_SHOW]: '/arcade/metrics/:id/:metricDetailsType',

  ...games,
  ...manage,
  ...newsfeed,
  ...people,
  ...profile,
  ...rewards,
};
