import React, { FC } from 'react'
import MenuItem from '@material-ui/core/MenuItem';

import ArcCheckbox from 'arcade-frontend-ui/src/elements/ArcCheckbox';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcView from 'arcade-frontend-ui/src/primitives/ArcView';
import GamesOutline from 'arcade-frontend-ui/src/icons/GamesOutline';
import PresentOutline from 'arcade-frontend-ui/src/icons/PresentOutline';

const ICONS_BY_ID = {
  DISABLED_GameLeaderboardActivity: (
    <GamesOutline color="action" fontSize="small" />
  ),
  DISABLED_RewardActivity: <PresentOutline color="action" fontSize="small" />,
}
export interface NewsfeedActivitiesFilterActivityTypeProps {
  item: any;
  selectedById: any;
  onClick: any;
}
const NewsfeedActivitiesFilterActivityType: FC<NewsfeedActivitiesFilterActivityTypeProps> = ({
  item,
  selectedById,
  onClick
}) => {
  return (
    <MenuItem onClick={() => onClick(item.id)}>
      <ArcCheckbox checked={selectedById[item.id]} />
      <ArcView row align="center">
        {!!ICONS_BY_ID[item.id] && (
          <ArcView marginRight="8">{ICONS_BY_ID[item.id]}</ArcView>
        )}

        <ArcText lineHeight="1em">{item.id}</ArcText>
      </ArcView>
    </MenuItem>
  )
}

export default NewsfeedActivitiesFilterActivityType
