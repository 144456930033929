import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';

import ArcBox from '../ArcBox';
import ArcBadge from '../ArcBadge';
import ArcText from '../../primitives/ArcText';

import TabContext from '../ArcTabs/TabContext';

const TAB_VARIANT = {
  DEFAULT: 'default',
  DARK: 'dark',
  BLUE: 'blue',
};

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(10),
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
    userSelect: 'none',

    '&:last-child': {
      marginRight: 0,
    },
  },

  pullRight: {
    '&:last-child': {
      marginLeft: 'auto',
      marginRight: 0,
    },
  },

  'variant-default': {
    color: theme.palette.grey.main,
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
  },

  'variant-dark': {
    color: theme.palette.grey.main,
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        backgroundColor: '#EBEBEB',
      },
    },
  },

  'variant-blue': {
    color: theme.palette.blue[500],
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        backgroundColor: theme.palette.blue[100],
      },
    },
  },

  'selected-default': {
    color: theme.palette.blue[500],
    backgroundColor: theme.palette.grey.tint,
  },

  'selected-dark': {
    color: theme.palette.blue[500],
    backgroundColor: '#EBEBEB',
  },

  'selected-blue': {
    backgroundColor: theme.palette.blue[100],
  },

  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        backgroundColor: theme.palette.grey.tint,
      },
    },
  },
}));

function ArcTab({
  children,
  badgeCount,
  condensed,
  disabled,
  label,
  value,
  variant,
  pullRight,
  ...props
}) {
  const classes = useStyles();

  const [activeTab, onSelect] = useContext(TabContext);
  const selected = value === activeTab;

  const className = cx([
    classes.root,
    disabled && classes.disabled,
    classes[`variant-${variant}`],
    selected && classes[`selected-${variant}`],
    pullRight && classes.pullRight,
  ]);

  return (
    <ArcBox
      aria-disabled={disabled.toString()}
      aria-selected={selected.toString()}
      className={className}
      color={selected ? 'blue.main' : 'grey.main'}
      id={value}
      onClick={disabled ? undefined : () => onSelect(value)}
      px={2}
      py={1}
      mr={condensed ? 0 : 1}
      role="tab"
      {...props}
    >
      <ArcText>{children || label}</ArcText>

      {badgeCount > 0 && (
        <ArcBox display="inline" position="relative">
          <ArcBox position="absolute" top={-14} left={2}>
            <ArcBadge color="danger" variant="round">
              {badgeCount}
            </ArcBadge>
          </ArcBox>
        </ArcBox>
      )}
    </ArcBox>
  );
}

ArcTab.propTypes = {
  badgeCount: PropTypes.number,
  children: PropTypes.node,
  condensed: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(TAB_VARIANT)),
  pullRight: PropTypes.bool,
};

ArcTab.defaultProps = {
  badgeCount: 0,
  children: null,
  condensed: false,
  disabled: false,
  label: null,
  variant: TAB_VARIANT.DEFAULT,
  pullRight: false,
};

export default ArcTab;
