import React from 'react';
import PropTypes from 'prop-types';

import PlayIcon from '@material-ui/icons/PlayCircleOutline';

import createWithStyles from '../../styles/createWithStyles';
import { openFileForPlatform } from '../../helpers/utils/open_link';
import ArcPaper from '../../elements/ArcPaper';
import ArcImage from '../../primitives/ArcImage';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';


import BrandLogoImage from '../BrandLogoImage';


const styles = {
  Wrapper: () => ({
    root: {
      display: 'flex',
      flexShrink: 1,
      marginTop: 8,
      marginBottom: 8,
      maxWidth: 600,
    },
  }),
  Content: theme => ({
    root: {
      flexShrink: 1,
      minWidth: 0,
      margin: theme.spacing(1),
      cursor: 'pointer',
    },
  }),
  FavIcon: theme => ({
    root: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
  }),
  TextRow: theme => ({
    root: {
      color: theme.palette.grey.medium,
      fontSize: '14px',
      lineHeight: '20px',
    },
  }),
  ImageWrapper: theme => ({
    root: {
      position: 'relative',
      backgroundColor: theme.palette.grey.medium,
      minHeight: 150,
      maxWidth: 400,
      minWidth: 300,
      alignSelf: 'flex-start',
      '& > img': {
        width: '100%',
      },
    },
  }),
  Play: () => ({
    root: {
      color: 'white',
      position: 'absolute',
      left: '50%',
      top: '50%',
      fontSize: 100,
      '& > svg': {
        transform: 'translate(-50%, -50%)',
      },
    },
  }),
};

const FavIcon = createWithStyles(styles.FavIcon)(BrandLogoImage);
const TextRow = createWithStyles(styles.TextRow)(ArcView);
const Wrapper = createWithStyles(styles.Wrapper)(ArcPaper);
const Content = createWithStyles(styles.Content)(ArcView);
const ImageWrapper = createWithStyles(styles.ImageWrapper)(ArcView);
const Play = createWithStyles(styles.Play)(ArcView);

const iconType = (url) => {
  if (url.indexOf('youtube') > -1) {
    return 'youtube';
  }

  if (url.indexOf('vimeo') > -1) {
    return 'vimeo';
  }

  return null;
};

const ArcLinkPreview = ({ preview, elevation }) => {
  const { title, description, image, url } = preview;

  return (
    <Wrapper elevation={elevation}>
      <Content onClick={() => openFileForPlatform(url)}>
        <ArcView row>
          <FavIcon type={iconType(url)} />
          <ArcView flexShrink={1} marginLeft={8} marginTop={4}><ArcText>{title}</ArcText></ArcView>
        </ArcView>
        <TextRow marginTop={4} marginBottom={image ? 16 : undefined}>{description}</TextRow>
        {image && (
          <ImageWrapper>
            <ArcImage src={image} />
            <Play><PlayIcon fontSize="inherit" /></Play>
          </ImageWrapper>
        )}
        <TextRow marginTop={image ? 16 : 8}><ArcText isSmall>{url}</ArcText></TextRow>
      </Content>
    </Wrapper>
  );
};

ArcLinkPreview.propTypes = {
  preview: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  elevation: PropTypes.number,
};

ArcLinkPreview.defaultProps = {
  elevation: 1,
};
export default ArcLinkPreview;
