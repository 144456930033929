/* eslint-disable no-console */

const isProduction = process.env.NODE_ENV === 'production';

const noop = () => {};

let log = () => {};
let warn = () => {};
let error = () => {};

if (typeof window !== 'undefined') {
  log = console.log.bind(window.console);
  warn = console.warn.bind(window.console);
  error = console.error.bind(window.console);
}

if (isProduction) {
  log = noop;
  warn = noop;
  error = noop;
}

function throwError(type, ...params) {
  const message = [type, ...params].join(' ');

  throw new Error(message);
}

const arcConsole = {
  log,
  warn,
  error,
  throwError,
};

export default arcConsole;
