import React from 'react';

import useDebounce from '../useDebounce';
import useWindowSize from '../useWindowSize';

// Consumers of this hook need to know when the height is changing to allow for
// transition animations and component remounts without jankyness.
// This delay is based off the time it takes for a fade in/out animation to finish.
const ANIMATION_MOUNT_DELAY = 300;

function useDynamicRowsPerPage(initialRowsPerPage, contentHeight, rowHeight) {
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

  useWindowSize();

  const debouncedRowHeight = useDebounce(rowHeight, ANIMATION_MOUNT_DELAY);
  const debouncedContentHeight = useDebounce(contentHeight, ANIMATION_MOUNT_DELAY);

  React.useEffect(() => {
    let nextRowsPerPage = debouncedContentHeight / debouncedRowHeight;
    nextRowsPerPage = parseInt(nextRowsPerPage, 10);

    setRowsPerPage(nextRowsPerPage || initialRowsPerPage);
  }, [debouncedContentHeight, debouncedRowHeight]);

  return rowsPerPage;
}

export default useDynamicRowsPerPage;
