import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import cx from 'classnames';
import Typography from '@material-ui/core/Typography';

import {
  getAppPeopleById,
  getSortedAppPeople,
} from 'arcade-frontend-core/src/reducers/peopleById';

import {
  ArcPropTypes,
  ArcPeopleSelector,
  ArcRadioButtonGroup,
  ArcGrid,
  ArcView,
} from 'arcade-frontend-ui';

// import SpaceInvader from 'arcade-frontend-ui/src/assets/SpaceInvader';
// import SpaceInvadersTeam from 'arcade-frontend-ui/src/assets/SpaceInvadersTeam';

import { actions as actionsApp } from 'arcade-frontend-core/src/actions';
import { getManageMetricsPeopleByTeam } from '../../../reducers/manage/metrics/peopleByTeam';
import { getManageMetricsTeamsById } from '../../../reducers/manage/metrics/teamsById';
import { getIsFetching } from '../../../reducers/manage/metrics/isFetching';
import { getManageMetricsTeams } from '../../../reducers/manage/metrics';

const ASSIGN_TYPES = {
  USERS: 'users',
  TEAMS: 'teams',
};

const SELECT_TYPES = {
  ALL: 'all',
  SELECTED: 'selected',
};

const styleMarginBottom = {
  marginBottom: 32,
};

// const styleImage = {
//   marginBottom: 8,
// };

// const styleMutedImage = {
//   ...styleImage,
//   opacity: 0.5,
// };

// const styleButton = {
//   width: 116,
//   height: 116,
//   marginRight: 16,
//   marginBottom: 32,
// };

const FIELDS = ['assignType', 'selectType', 'peopleSelector'];

class MetricsCreateStepTwoForm extends React.PureComponent {
  static ASSIGN_TYPES = ASSIGN_TYPES;

  static SELECT_TYPES = SELECT_TYPES;

  static FIELDS = FIELDS;

  static propTypes = {
    apiAppPeopleIndexRequest: ArcPropTypes.func.isRequired,
    availablePeople: ArcPropTypes.arrayOf(ArcPropTypes.object).isRequired,
    availableTeams: ArcPropTypes.arrayOf(ArcPropTypes.object).isRequired,
    availableTeamPeople: ArcPropTypes.objectOf(
      ArcPropTypes.arrayOf(ArcPropTypes.string),
    ).isRequired,
    setFieldTouched: ArcPropTypes.func.isRequired,
    setFieldValue: ArcPropTypes.func.isRequired,
    peopleById: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
    teamsById: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
    values: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
    isFetching: ArcPropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    if (!props.availablePeople.length) {
      props.apiAppPeopleIndexRequest();
    }
  }

  componentWillUnmount() {
    FIELDS.forEach(field => {
      this.props.setFieldTouched(field, true, false);
    });
  }

  setSelectType = selectType => this.setState({ selectType });

  render() {
    return (
      <ArcGrid
        className="animated fadeIn"
        component={ArcView}
        container
        position="relative"
        spacing={2}
        style={{ maxWidth: 600 }}
        wrap="nowrap"
      >
        <ArcGrid item xs={12}>
          <Typography variant="h5">
            {'Who is assigned to this metric?'}
          </Typography>
        </ArcGrid>

        <ArcGrid item xs={12}>
          <ArcRadioButtonGroup
            style={styleMarginBottom}
            onChange={value => {
              this.setSelectType(value);
              this.props.setFieldValue('selectType', value);

              if (value === SELECT_TYPES.ALL) {
                this.props.setFieldValue('peopleSelector', {
                  peopleIds: this.props.availablePeople,
                  teamIds: this.props.availablePeople,
                });
              } else {
                this.props.setFieldValue('peopleSelector', {
                  peopleIds: [],
                  teamIds: [],
                });
              }
            }}
            value={this.props.values.selectType}
            items={[
              {
                label:
                  this.props.values.assignType === ASSIGN_TYPES.TEAMS
                    ? 'All teams, including those that join in the future'
                    : 'Everyone, including people that join in the future',
                value: SELECT_TYPES.ALL,
              },
              {
                label:
                  this.props.values.assignType === ASSIGN_TYPES.TEAMS
                    ? 'Selected teams'
                    : 'Selected individuals',
                value: SELECT_TYPES.SELECTED,
              },
            ]}
          />
        </ArcGrid>
        {this.props.values.selectType === SELECT_TYPES.SELECTED && (
          <ArcPeopleSelector
            type={
              this.props.values.assignType === ASSIGN_TYPES.TEAMS
                ? 'teams'
                : 'people'
            }
            availablePeople={this.props.availablePeople}
            availableTeams={this.props.availableTeams}
            availableTeamPeople={this.props.availableTeamPeople}
            onChange={val => this.props.setFieldValue('peopleSelector', val)}
            peopleById={this.props.peopleById}
            selectedPeople={this.props.values.peopleSelector.peopleIds}
            selectedTeams={this.props.values.peopleSelector.teamIds}
            teamsById={this.props.teamsById}
            isFetching={this.props.isFetching}
          />
        )}
      </ArcGrid>
    );
  }
}

const getState = (state, props) => ({
  peopleById: getAppPeopleById(state),
  availablePeople: getSortedAppPeople(state),
  availableTeams: getManageMetricsTeams(state),
  availableTeamPeople: getManageMetricsPeopleByTeam(state),
  teamsById: getManageMetricsTeamsById(state),
  isFetching: getIsFetching(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      apiAppPeopleIndexRequest: actionsApp.apiAppPeopleIndexRequest,
    },
    dispatch,
  );

export default connect(getState, getActions)(MetricsCreateStepTwoForm);
