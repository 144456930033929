import React from 'react';
import cx from 'classnames';
import ArcButton from '../ArcButton';
import ArcView from '../../primitives/ArcView';
import ArcPropTypes from '../../helpers/arc/propTypes';

import { createWithStyles } from '../../styles';

const NAME = 'by name';
const TEAM = 'by team';

const TYPES = [NAME, TEAM];

const styles = {
  ButtonGroup: theme => ({
    root: {
      display: 'flex',
      flexDirection: 'row',

      '& > .ArcButton': {
        padding: 8,
        borderRadius: 0,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.divider,

        marginLeft: -1,
      },

      '& > .ArcButton:hover': {
        zIndex: 10,
        borderColor: theme.palette.secondary.main,
      },

      '& > .ArcButton.isActive': {
        zIndex: 10,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
      },

      '& > .ArcButton:first-child': {
        marginLeft: 0,
      },

      '& > .ArcButton:last-child': {
      },
    },

    hasError: {
      '& > .ArcButton:hover': {
        borderColor: theme.palette.danger.main,
      },

      '& > .ArcButton.isActive': {
        borderColor: theme.palette.danger.main,
        color: theme.palette.danger.main,
      },
    },

    marginBottom: {
      marginBottom: theme.spacing(2),
    },

    marginLeft: {
      marginLeft: theme.spacing(2),
    },
  }),
};

const ButtonGroup = createWithStyles(styles.ButtonGroup)(ArcView);

class ArcToggle extends React.PureComponent {
  static propTypes = {
    label: ArcPropTypes.string,
    onChange: ArcPropTypes.func,
  };

  static defaultProps = {
    label: NAME,
    onChange: ArcPropTypes.noop,
  }

  state = {
    active: NAME,
  }

  render() {
    return (
      <ButtonGroup>
        {
          TYPES.map(label => (
            <ArcButton
              key={label}
              className={cx([
                'ArcButton',
                this.props.label === label && 'isActive',
              ])}
              label={label}
              variant="outlined"
              onClick={e => this.props.onChange(e, label)}
            />
          ))
        }
      </ButtonGroup>
    );
  }
}

export default ArcToggle;
