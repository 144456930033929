import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import { createWithStyles } from '../../styles';

const styles = {
  ArcCardContent: () => ({
    root: {
      flexGrow: 1,
      flexShrink: 1,
      width: '100%',
      height: '100%',
      marginTop: 8,
      paddingTop: 16,
    },

    hasPadding: {
      padding: 8,
      paddingTop: 24,
      marginTop: 0,
    },
  }),
};

const ArcCardContent = createWithStyles(styles.ArcCardContent)(ArcView);

ArcCardContent.propTypes = {
  children: ArcPropTypes.children,
  style: ArcPropTypes.style,
  hasPadding: ArcPropTypes.bool,
};

ArcCardContent.defaultProps = {
  children: null,
  style: null,
  hasPadding: false,
};

export default ArcCardContent;
