import ArcStyles, { createWithStyles } from '../../styles';
import ArcParagraph from '../../typography/ArcParagraph';

const style = theme => ({
  root: {
    lineHeight: '24px',
    margin: '0 16px 8px 16px',
    fontWeight: '300',
    color: ArcStyles.theme.defaultTextColor,

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
});

const ArcViewContentParagraph = createWithStyles(style, { name: 'ArcViewContentParagraph' })(ArcParagraph);

export default ArcViewContentParagraph;
