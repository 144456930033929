import React, { FC } from 'react';

import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import usePermission from 'arcade-frontend-core/src/hooks/usePermission';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';

import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';

import BillingMain from '../../components/BillingMain';
import BillingNotManagerMessage from '../../components/BillingNotManagerMessage';
import BillingChargebeePortal from '../BillingChargebeePortal';

const STRINGS = {
  LAUNCH_PORTAL_LABEL: 'Launch Portal',
};

const BillingContainer: FC = () => {
  const hasFeatureBilling = useFeatureFlag(FEATURE_FLAGS.BILLING_V2);
  const canManageBilling = usePermission(PERMISSIONS.MANAGE_BILLING);
  const [portalOpen, setPortalOpen] = React.useState(false);

  if (!hasFeatureBilling) return null;
  if (!canManageBilling) return <BillingNotManagerMessage />;

  const handleOpenPortal = () => {
    setPortalOpen(true);
  };

  const handleClosePortal = () => {
    setPortalOpen(false);
  };

  return (
    <ArcViewBox mt={2} mx={[2, 3]}>
      <BillingMain />

      <ArcViewBox alignItems="flex-start">
        <ArcButton
          size="default"
          color="primary"
          variant="contained"
          onClick={handleOpenPortal}
        >
          {STRINGS.LAUNCH_PORTAL_LABEL}
        </ArcButton>
      </ArcViewBox>

      {portalOpen && <BillingChargebeePortal onClose={handleClosePortal} />}
    </ArcViewBox>
  );
};

export default BillingContainer;
