import { createIsFetchingReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const initialState = false;

const apiActionTypes = [
  types.REWARDS_SHOP_INDEX,
  types.REWARDS_TRANSACTIONS_INDEX,
];

const isFetching = createIsFetchingReducer(initialState, apiActionTypes);

export default isFetching;

export const getIsFetching = state => state.rewards.isFetching;
