import ArcStyles from '../../styles';
import percentToArcColor from '../../styles/colors/percentToArcColor';

const rules = {
  ArcProgress: {
    overflow: 'hidden',
    position: 'relative',
    height: 32,
    padding: 2,
    backgroundColor: 'rgba(0,0,0,0.11)',

    '& > div': {
      transitionProperty: 'width',
      transitionTimingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',
      '&:after': {
        content: ' ',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundImage: 'linear-gradient(-45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)',
        backgroundSize: '32px 32px',
        zIndex: 1,
      },
    },
  },
};

const toggle = {};

switch (ArcStyles.Platform.OS) {
  case 'web':
    toggle.web = props => ({
      '& > div': {
        width: `calc(${props.percent}% - 2px)`,
        minWidth: '4px',
        backgroundColor: percentToArcColor(props.percent),
        transitionDuration: `${props.duration}ms`,
      },
    });
    break;
  default:
}

const styles = props => ArcStyles.flatten([
  rules.ArcProgress,
  toggle.web && toggle.web(props),
  props.style,
]);

export { rules, styles };
