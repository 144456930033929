import createResourceAction from 'arcade-frontend-core/src/helpers/createResourceAction';
import { makePaginationParams } from 'arcade-frontend-core/src/actions/helpers';

const stringifyIds = data => {
  if (!data) return undefined;

  return data.map(record => ({ ...record, id: record.id.toString() }));
};

export const apiGetIntegrationRuns = createResourceAction('read', {
  resourceType: 'integrationRuns',
  requestKey: 'apiGetIntegrationRuns',
  list: 'index',

  api: payload => ({
    method: 'GET',
    url: '/manager/v4/integration_runs',
    params: {
      from: payload.dateRange.from,
      to: payload.dateRange.to,
      ...makePaginationParams(payload),
    },
    transformResponse: data => ({
      totalRuns: data.totalRuns,
      runs: stringifyIds(data.integrationRuns),
    }),
  }),
});

export const apiIntegrationMessages = createResourceAction('read', {
  resourceType: 'integrationRuns',
  requestKey: 'apiWithdrawIntegrationRun',

  api: payload => ({
    method: 'GET',
    url: `/manager/v4/integration_runs/${payload.resources[0].run.id}/${payload.resources[0].type}s`,

    transformResponse: data => ({
      integrationWarnings: stringifyIds(data.integrationWarnings),
      integrationErrors: stringifyIds(data.integrationErrors),
    }),
  }),
});

export const apiWithdrawIntegrationRun = createResourceAction('update', {
  resourceType: 'integrationRuns',
  requestKey: 'apiWithdrawIntegrationRun',

  api: payload => ({
    method: 'PUT',
    url: `/manager/v4/integration_runs/${payload.withdrawRunId}/revert`,
  }),
});

export const apiIntegrationHelp = createResourceAction('create', {
  resourceType: 'integrationRuns',
  requestKey: 'apiWithdrawIntegrationRun',

  api: payload => ({
    method: 'POST',
    data: { help_request: { run_id: payload.runId, text: payload.text } },
    url: '/manager/v4/integration_runs/help_request',
  }),
});
