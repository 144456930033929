import React from 'react';
import { useSelector } from 'react-redux';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { UPCOMING } from 'arcade-frontend-core/src/types/game-statuses';

import { resources } from '../../resources/games';
import {
  getUpcomingGamesByInterest,
  getUpcomingGamesStatus,
} from '../../reducers/games';
import GamesGameCardList from '../../components/GamesGameCardList';
import GamesUpcomingEmpty from '../../components/GamesUpcomingEmpty';
import GamesNoResultsBox from '../../components/GamesNoResultsBox';

function GamesUpcomingListContainer(props) {
  const apiGetUpcomingGamesIndex = useResourceAction(
    resources.apiGetUpcomingGamesIndex,
  );
  const upcomingGames = useSelector(getUpcomingGamesByInterest);
  const upcomingGamesStatus = useSelector(getUpcomingGamesStatus);

  React.useEffect(() => {
    apiGetUpcomingGamesIndex
      .requestData()
      .then(apiGetUpcomingGamesIndex.onStatusReset)
      .catch(global.noop);
  }, []);

  return (
    <GamesGameCardList
      status={UPCOMING}
      title="Upcoming"
      emptyNode={
        <GamesNoResultsBox>
          <GamesUpcomingEmpty />
        </GamesNoResultsBox>
      }
      games={upcomingGames}
      hasData={apiGetUpcomingGamesIndex.hasData}
      isPending={upcomingGamesStatus.pending}
      {...props}
    />
  );
}

GamesUpcomingListContainer.displayName = 'GamesUpcomingListContainer';

export default GamesUpcomingListContainer;
