import React from 'react';
import { Dimensions } from 'react-native';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useMutation } from 'arcade-frontend-core/src/helpers/apollo';
import { NOTIFICATIONS_PANEL } from 'arcade-frontend-core/src/types/feature-flags';
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';

import { actions, types } from 'arcade-frontend-core/src/actions';
import * as routeTypes from 'arcade-frontend-core/src/types/routes';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import { getIsMuted } from 'arcade-frontend-core/src/reducers/isMuted';
import { getMenu } from 'arcade-frontend-core/src/reducers/menu';
import { actions as widgetActions } from 'arcade-frontend-widgets/src/actions/uploadManager';
import {
  ArcLink,
  ArcListItem,
  ArcListItemIcon,
  ArcImage,
  ArcPlaceholder,
  ArcProgress,
  ArcStyles,
  ArcText,
  ArcTouchable,
  ArcView,
  Platform,
  // Resource,
  createWithStyles,
  palette,
} from 'arcade-frontend-ui';

import ArcSupportWidgetLinkContainer from '../ArcSupportWidgetLinkContainer';
import * as supportWidget from '../../../helpers/supportWidget';

import ArcTourStepperContainer from '../ArcTourStepperContainer';

import { featureRoutes } from './types';
import {
  getLocationPayload,
  getLocationType,
} from '../../../reducers/location';

import NOTIFICATIONS_MUTATION from './notification-mutation';
import SAMPLE_NOTIFICATIONS from './sample-notifications';

const BADGE_BORDER_WIDTH = 2;
const BADGE_SIZE = 48;

const PROFILE_IMAGE_WIDTH =
  Platform.OS === 'web' ? 182 : Dimensions.get('window').width * (5 / 9);
let debugCount = 0;
let timeout;

const LEFT_MENU_COLOR = '#21afdb';

const styles = {
  ArcBubble: () => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...Platform.select({
        web: {
          position: 'absolute',
          top: -12,
          right: -12,
        },
      }),
      minWidth: 24,
      height: 24,
      borderRadius: 24,
      paddingLeft: 4,
      paddingRight: 4,
      borderWidth: 1,
      borderColor: '#fff',
      borderStyle: 'solid',
    },
  }),
  ArcBubbleText: theme => ({
    root: {
      color: '#fff',
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: theme.font.getLineHeight(1.5),
      textAlign: 'center',
    },
  }),

  ArcLinkItem: () => ({
    root: {
      position: 'relative',
      paddingTop: 8,
      paddingRight: 16,
      paddingBottom: 8,
      paddingLeft: 16,
      color: LEFT_MENU_COLOR,

      ...Platform.select({
        web: {
          '&:hover': {
            backgroundColor: '#F0FBFE',
          },
        },
      }),
    },

    isSelected: {
      backgroundColor: '#F0FBFE',

      ...Platform.select({
        web: {
          '&:hover': {
            backgroundColor: '#dcf3f9',
          },
        },
      }),
    },
  }),

  ArcListContainer: theme => ({
    root: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  }),

  Bars: () => ({
    root: {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: 4,
      opacity: 0.87,
    },
  }),

  Icon: () => ({
    root: {
      marginHorizontal: 16,
      width: 24,
      alignItems: 'center',
    },
  }),

  IconInactive: () => ({
    root: {
      /*
       * Icons have 0.54 opacity according to guidelines
       * 100/87 * 54 ~= 62
       */
      opacity: 0.62,
    },
  }),

  ProfileBadge: theme => ({
    root: {
      overflow: 'hidden',
      zIndex: 10,
      position: 'absolute',
      right: -4,
      bottom: -24,
      width: BADGE_SIZE,
      height: BADGE_SIZE,
      borderRadius: BADGE_SIZE,
      backgroundColor: theme.palette.divider,
      borderColor: theme.palette.divider,
      borderStyle: 'solid',
      borderWidth: BADGE_BORDER_WIDTH,

      ...Platform.select({
        web: {
          '&:hover': {
            borderColor: theme.palette.default[200],
          },
        },
      }),
    },

    isSelected: {
      borderColor: LEFT_MENU_COLOR,

      ...Platform.select({
        web: {
          '&:hover': {
            borderColor: LEFT_MENU_COLOR,
          },
        },
      }),
    },
  }),

  ProfileImageContainer: () => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: 8,
    },
  }),

  ProfileImage: theme => ({
    root: {
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: palette.divider,
      borderStyle: 'solid',
      borderWidth: 4,
      width: PROFILE_IMAGE_WIDTH,
      height: PROFILE_IMAGE_WIDTH,
      padding: 8,

      ...Platform.select({
        web: {
          animationDelay: 200,

          '&:hover': {
            borderColor: theme.palette.default[200],
          },
        },
      }),
    },

    isSelected: {
      borderColor: LEFT_MENU_COLOR,

      ...Platform.select({
        web: {
          '&:hover': {
            borderColor: LEFT_MENU_COLOR,
          },
        },
      }),
    },
  }),

  ProfileLevel: theme => ({
    root: {
      width: 40,
      height: 40,
      borderStyle: 'solid',
      borderWidth: 4,
      borderColor: theme.palette.divider,
      padding: 8,
      ...Platform.select({
        web: {
          textAlign: 'center',
        },
      }),
    },
  }),

  ProfileMetaContainer: () => ({
    root: {
      width: '100%',
      padding: 8,
    },
  }),

  ProgressContainer: () => ({
    root: {
      flexGrow: 10,
    },
  }),

  ProgressText: theme => ({
    root: {
      color: theme.palette.text.disabled,
      fontSize: theme.font.getFontSize(0.75),
      lineHeight: theme.font.getLineHeight(1.125),
      marginLeft: theme.spacing(1),
    },

    marginBottom2: {
      marginBottom: 2,
    },
  }),

  ProgressPlaceholder: theme => ({
    root: {
      backgroundColor: theme.palette.divider,
      height: 12,
      marginTop: 3,
      marginBottom: 3,
      marginLeft: 4,
    },
  }),

  RoutePlaceholder: theme => ({
    root: {
      backgroundColor: theme.palette.divider,
      height: 48,
      margin: 8,
    },
  }),

  Divider: theme => ({
    root: {
      width: '100%',
      height: 1,
      backgroundColor: theme.palette.divider,
    },
  }),

  SelectedDivider: () => ({
    root: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: 4,
      height: '100%',
      backgroundColor: '#83E4FE',
    },

    height56: {
      height: 56,
    },
  }),
};

const barColors = [
  'blue',
  'aqua',
  'olive',
  'green',
  'orange',
  'yellow',
  'maroon',
  'red',
];

const barsByColor = {};

barColors.forEach(color => {
  const Bar = {
    root: {
      height: '12.5%',
      backgroundColor: palette[color].main,
    },
  };

  barsByColor[color] = createWithStyles(Bar)(ArcView);
});

const ArcBubble = createWithStyles(styles.ArcBubble)(ArcView);
const ArcBubbleText = createWithStyles(styles.ArcBubbleText)(ArcText);
const ArcLinkItem = createWithStyles(styles.ArcLinkItem)(ArcLink);
const ArcListContainer = createWithStyles(styles.ArcListContainer)(ArcView);

const ProfileBadge = createWithStyles(styles.ProfileBadge)(ArcView);
const ProfileImageContainer = createWithStyles(styles.ProfileImageContainer)(
  ArcView,
);
const ProfileImage = createWithStyles(styles.ProfileImage)(ArcView);
const ProfileLevel = createWithStyles(styles.ProfileLevel)(ArcView);
const ProfileMetaContainer = createWithStyles(styles.ProfileMetaContainer)(
  ArcView,
);
const ProgressContainer = createWithStyles(styles.ProgressContainer)(ArcView);
const ProgressText = createWithStyles(styles.ProgressText)(ArcText);

const RoutePlaceholder = createWithStyles(styles.RoutePlaceholder)(ArcView);
const ProgressPlaceholder = createWithStyles(styles.ProgressPlaceholder)(
  ArcView,
);

const Bars = createWithStyles(styles.Bars)(ArcView);
const Divider = createWithStyles(styles.Divider)(ArcView);
const SelectedDivider = createWithStyles(styles.SelectedDivider)(ArcView);

export const bars = (
  <Bars>
    {barColors.map(color => {
      const Bar = barsByColor[color];

      return <Bar key={color} />;
    })}
  </Bars>
);

const renderProgressText = text =>
  text ? (
    <ProgressText>{text.toUpperCase()}</ProgressText>
  ) : (
    <ProgressPlaceholder />
  );

const routePlaceholder = (
  <RoutePlaceholder>
    <ArcPlaceholder />
  </RoutePlaceholder>
);

const routePlaceholders = (
  <>
    {routePlaceholder}
    {routePlaceholder}
    {routePlaceholder}
    {routePlaceholder}
    {routePlaceholder}
    {routePlaceholder}
    {routePlaceholder}
    {routePlaceholder}
  </>
);

function titleCase(string) {
  const str = string.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i += 1) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}

function NotificationsTrigger({ onClick }) {
  const [count, setCount] = React.useState(0);
  const countRef = React.useRef(count);
  countRef.current = count;

  const [mutate] = useMutation(NOTIFICATIONS_MUTATION);
  const hasNotificationsPanel = useFeatureFlag(NOTIFICATIONS_PANEL);

  if (!hasNotificationsPanel) {
    return null;
  }

  function sendNotification(type) {
    const variables = { ...SAMPLE_NOTIFICATIONS[type].random() };
    variables.content = `${countRef.current} ${variables.content}`;

    setCount(countRef.current + 1);

    mutate({
      variables,
    });
  }

  function handleNotificationMutation() {
    sendNotification('typeOne');
    onClick();
  }

  function handleThreeNotificationMutation() {
    sendNotification('typeOne');
    setTimeout(() => sendNotification('typeOne'), 1000);
    setTimeout(() => sendNotification('typeOne'), 2000);
    onClick();
  }

  function handleTypeThreeNotificationMutation() {
    sendNotification('typeThree');
    onClick();
  }

  function handleThreeTypeThreeNotificationMutation() {
    sendNotification('typeThree');
    setTimeout(() => sendNotification('typeThree'), 1000);
    setTimeout(() => sendNotification('typeThree'), 2000);
    onClick();
  }

  return (
    <>
      <ArcLinkItem onPress={handleNotificationMutation}>
        {'Trigger Notification (T1)'}
      </ArcLinkItem>
      <ArcLinkItem onPress={handleTypeThreeNotificationMutation}>
        {'Trigger Notification (T3)'}
      </ArcLinkItem>
      <ArcLinkItem onPress={handleThreeNotificationMutation}>
        {'Trigger 3 Notifications (T1)'}
      </ArcLinkItem>
      <ArcLinkItem onPress={handleThreeTypeThreeNotificationMutation}>
        {'Trigger 3 Notifications (T3)'}
      </ArcLinkItem>
    </>
  );
}

NotificationsTrigger.displayName = 'NotificationsTrigger';

NotificationsTrigger.propTypes = {
  onClick: PropTypes.func,
};

NotificationsTrigger.defaultProps = {
  onClick: global.noop,
};

export class ArcLeftMenu extends React.PureComponent {
  static displayName = 'ArcLeftMenu';

  static propTypes = {
    appMenuRequest: PropTypes.func,
    appSetOnboardingTourOpen: PropTypes.func,
    arcPostMessage: PropTypes.func,
    badgeUrl: PropTypes.string,
    closeDrawer: PropTypes.func,
    currentUser: PropTypes.shape({
      endpoint: PropTypes.string,
      id: PropTypes.string,
      token: PropTypes.string,
      profileImage: PropTypes.string,
    }),
    dispatch: PropTypes.func,
    hasBars: PropTypes.bool,
    isInternetReachable: PropTypes.bool,
    isMuted: PropTypes.bool,
    menu: PropTypes.shape({
      badge: PropTypes.string,
      level: PropTypes.shape({
        currentLevel: PropTypes.number,
        percentageThroughLevel: PropTypes.number,
        experienceToNext: PropTypes.number,
        currentLevelStart: PropTypes.number,
        currentLevelEnd: PropTypes.number,
        teamName: PropTypes.string,
      }),
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          menuTitle: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
    // onClickItem: PropTypes.func, // TODO rename this to something checklist related
    onLogout: PropTypes.func,
    onMute: PropTypes.func,
    onPressRoute: PropTypes.func,
    onLongPressRoute: PropTypes.func,
    profileImageUrl: PropTypes.string,
    renderIcon: PropTypes.func,
    routesToRender: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    locationPayload: PropTypes.shape({
      id: PropTypes.string,
    }),
    locationType: PropTypes.string,
    chatCount: PropTypes.number,
    rewardsCount: PropTypes.number,
    uploadManagerOpened: PropTypes.func,
  };

  static defaultProps = {
    appMenuRequest: global.noop,
    appSetOnboardingTourOpen: global.noop,
    arcPostMessage: global.noop,
    badgeUrl: undefined,
    closeDrawer: global.noop,
    currentUser: {},
    dispatch: global.noop,
    hasBars: true,
    isInternetReachable: true,
    isMuted: false,
    menu: null,
    // onClickItem: global.noop,
    onLogout: global.noop,
    onMute: global.noop,
    onPressRoute: null,
    onLongPressRoute: global.noop,
    profileImageUrl: undefined,
    renderIcon: undefined,
    routesToRender: undefined,
    locationPayload: {},
    locationType: '',
    chatCount: 0,
    rewardsCount: 0,
    uploadManagerOpened: global.noop,
  };

  componentDidMount() {
    if (window.ngRootScope) {
      this.rootScopeListener = window.ngRootScope.$on(
        'arcade:leftmenu:reload',
        () => {
          this.props.appMenuRequest();
        },
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.chatCount !== prevProps.chatCount) {
      this.prevChatCount = this.props.chatCount;
    }
    if (this.props.rewardsCount !== prevProps.rewardsCount) {
      this.prevRewardsCount = this.props.rewardsCount;
    }
  }

  componentWillUnmount() {
    if (this.rootScopeListener) {
      this.rootScopeListener();
    }
  }

  get badgeUrl() {
    if (this.props.badgeUrl) {
      return this.props.badgeUrl;
    }

    return this.props.menu ? this.props.menu.badge : null;
  }

  get hasSelectedProfile() {
    return (
      this.props.locationType === routeTypes.ROUTE_PROFILE &&
      this.props.locationPayload.id === this.props.currentUser.id
    );
  }

  get profileImageUrl() {
    if (this.props.profileImageUrl) {
      return this.props.profileImageUrl;
    }

    return this.props.currentUser.profileImage
      ? this.props.currentUser.profileImage
      : null;
  }

  get routes() {
    return this.props.routesToRender || featureRoutes;
  }

  prevChatCount = this.props.chatCount;

  prevRewardsCount = this.props.rewardsCount;

  handlePressHelpAndSupport = () => {
    if (this.props.closeDrawer) {
      this.props.closeDrawer();
    }

    const action = actions.appSetOnboardingTourOpen(true);
    this.props.arcPostMessage(`rw.dispatch.${JSON.stringify(action)}`);

    this.props.appSetOnboardingTourOpen(true);
  };

  handleClickSupportWidget = () => {
    if (Platform.OS === 'web') {
      supportWidget.toggleSupportWidget();
    } else {
      if (this.props.closeDrawer) {
        this.props.closeDrawer();
      }

      const message = 'rw.supportWidget.open';
      this.props.arcPostMessage(message);
    }
  };

  handlePressMyMedia = () => {
    if (this.props.closeDrawer) {
      this.props.closeDrawer();
    }

    const action = widgetActions.uploadManagerOpened(null);
    const message = `rw.dispatch.${JSON.stringify(action)}`;
    this.props.arcPostMessage(message);

    this.props.uploadManagerOpened(null);
  };

  handlePressLogout = () => {
    this.props.onLogout();
    this.handlePressRoute(types.LOGOUT);
  };

  handlePressMute = () => {
    this.props.onMute(!this.props.isMuted);
  };

  handlePressProfile = () => {
    this.handlePressRoute(routeTypes.ROUTE_PROFILE, {
      id: this.props.currentUser.id,
    });
  };

  handlePressRoute = (type, payload = {}) => {
    if (this.props.onPressRoute) {
      this.props.onPressRoute(type, payload);
    }

    const routeAction = {
      type,
      payload,
    };

    this.props.dispatch(routeAction);
  };

  resetDebug = () => {
    debugCount = 0;
  };

  triggerDebug = () => {
    if (timeout) {
      clearTimeout(timeout);
    }

    debugCount += 1;

    if (debugCount === 5) {
      this.props.onPressRoute('DebuggerScreen');
    }

    timeout = setTimeout(this.resetDebug, 3000);
  };

  renderLeftIcon = type => this.renderLeftIconDefault(type);

  renderLeftIconChat = () =>
    this.renderLeftIconBubble(
      routeTypes.ROUTE_CHAT,
      this.props.chatCount,
      this.prevChatCount,
    );

  renderLeftIconRewards = () =>
    this.renderLeftIconBubble(
      routeTypes.ROUTE_REWARDS,
      this.props.rewardsCount,
      this.prevRewardsCount,
      'yellow',
    );

  renderLeftIconBubble = (routeType, count, prevCount, color = 'red') => {
    const bubble = (
      <ArcBubble
        align="center"
        justify="center"
        visibility={prevCount === 0 && count === 0 ? 'hidden' : 'visible'}
        className={cx([
          'animated',
          prevCount === 0 && count > 0 && 'bounceIn',
          count === 0 && 'bounceOut',
        ])}
        color={color}
      >
        <ArcBubbleText>
          {count > 99 ? 99 : count}
          {count > 99 && '+'}
        </ArcBubbleText>
      </ArcBubble>
    );

    const Icon = this.renderLeftIconDefault(routeType);

    const isSelected = this.props.locationType === routeType;

    const shouldRenderIcon = Platform.OS === 'web' ? true : count === 0;

    const shouldRenderBubble = Platform.OS === 'web' ? true : count > 0;

    return (
      <ArcView position="relative">
        {shouldRenderIcon && (
          <ArcListItemIcon isSelected={isSelected}>
            <Icon
              width="24px"
              height="24px"
              color={isSelected ? LEFT_MENU_COLOR : 'rgba(0,0,0,0.54)'}
            />
          </ArcListItemIcon>
        )}
        {shouldRenderBubble && bubble}
      </ArcView>
    );
  };

  renderLeftIconDefault = type => {
    const { renderIcon } = this.props;

    if (renderIcon) {
      // we override the renderIcon function from the native side
      return () =>
        renderIcon(this.routes[type], this.props.locationType === type);
    }

    return this.routes[type].icon;
  };

  renderProfileBadge() {
    if (!this.badgeUrl) {
      return null;
    }

    const normalizedBadgeSize = Platform.select({
      web: BADGE_SIZE,
      default: BADGE_SIZE - BADGE_BORDER_WIDTH * 2,
    });

    return (
      <ArcTouchable
        onPress={this.handlePressProfile}
        disabled={!this.props.isInternetReachable}
      >
        <ProfileBadge
          className="animated fadeIn"
          isSelected={this.hasSelectedProfile}
        >
          <ArcImage
            alt="badge"
            src={this.badgeUrl}
            width={normalizedBadgeSize}
            height={normalizedBadgeSize}
          />
        </ProfileBadge>
      </ArcTouchable>
    );
  }

  renderProfileImage() {
    if (Platform.OS === 'web') {
      return (
        <img
          alt="profile"
          src={this.profileImageUrl}
          width="160px"
          height="auto"
        />
      );
    }

    return (
      <ArcImage
        resizeMode="cover"
        src={this.profileImageUrl}
        width={PROFILE_IMAGE_WIDTH}
        height={PROFILE_IMAGE_WIDTH}
        style={{
          width: PROFILE_IMAGE_WIDTH,
          height: PROFILE_IMAGE_WIDTH,
          minWidth: 160,
          minHeight: 160,
        }}
      />
    );
  }

  renderLevel() {
    const { menu } = this.props;
    const { level = {} } = menu;

    const percent = level ? level.percentageThroughLevel : 0;
    const color = ArcStyles.colors.percentToArcColor(percent);
    const experienceRequired = level.currentLevelEnd - level.currentLevelStart;
    const experienceThroughLevel = experienceRequired - level.experienceToNext;

    return (
      <ProfileMetaContainer>
        <ArcTouchable
          onPress={this.handlePressProfile}
          disabled={!this.props.isInternetReachable}
        >
          <ArcView style={{ width: PROFILE_IMAGE_WIDTH }}>
            <ArcView row align="flex-end">
              <ProfileLevel
                align="center"
                justify="center"
                style={{ borderColor: color }}
              >
                <ArcText style={{ color }}>
                  {level ? level.currentLevel : '--'}
                </ArcText>
              </ProfileLevel>
              <ProgressContainer justify="flex-end">
                {renderProgressText(level ? level.teamName : '')}
                <ProgressText marginBottom="2">
                  {level.currentLevelStart ? experienceThroughLevel : '--'}
                  {'/'}
                  {level.currentLevelStart ? experienceRequired : '--'}
                  {' XP'}
                </ProgressText>
                <ArcProgress height={4} percent={percent} />
              </ProgressContainer>
            </ArcView>
          </ArcView>
        </ArcTouchable>
      </ProfileMetaContainer>
    );
  }

  renderMenuItem = ({ menuTitle }) => {
    const routeType = `route/ROUTE_${menuTitle.toUpperCase()}`;

    if (menuTitle === 'LOGOUT' || !this.routes[routeType]) {
      return null;
    }

    let renderLeftIcon;

    if (routeType === routeTypes.ROUTE_CHAT) {
      renderLeftIcon = this.renderLeftIconChat();
    }

    if (routeType === routeTypes.ROUTE_REWARDS) {
      renderLeftIcon = this.renderLeftIconRewards();
    }

    const title = titleCase(menuTitle);
    const menuLink = (
      <ArcListItem
        key={routeType}
        role="menuitem"
        data-testid={`ArcListItem--${routeType}`}
        onPress={() => this.handlePressRoute(routeType)}
        onLongPress={() => this.props.onLongPressRoute(routeType)}
        isSelected={this.props.locationType.indexOf(routeType) === 0}
        selectedDivider={<SelectedDivider height="56" />}
        leftIcon={this.renderLeftIcon(routeType)}
        renderLeftIcon={renderLeftIcon}
        position="relative"
        className="animated fadeIn"
      >
        {title}
      </ArcListItem>
    );

    if (menuTitle === 'PEOPLE') {
      return (
        <>
          {menuLink}
          <ArcSupportWidgetLinkContainer
            onClick={this.handleClickSupportWidget}
          />
        </>
      );
    }

    return menuLink;
  };

  render() {
    const { menu } = this.props;
    return (
      <ArcView paddingLeft="4" color="paper">
        <ArcView paddingLeft="8">
          <ProfileImageContainer>
            <ArcView
              position="relative"
              row
              align="flex-end"
              justify="flex-start"
            >
              <ProfileImage
                className="animated fadeIn"
                isSelected={this.hasSelectedProfile}
              >
                {!!this.profileImageUrl && (
                  <ArcTouchable
                    onPress={this.handlePressProfile}
                    disabled={!this.props.isInternetReachable}
                  >
                    {!!this.profileImageUrl && this.renderProfileImage()}
                  </ArcTouchable>
                )}
              </ProfileImage>

              {this.renderProfileBadge()}
            </ArcView>
          </ProfileImageContainer>

          {this.renderLevel()}
        </ArcView>

        <Divider />

        <ArcListContainer role="menu">
          {!menu.menuItems?.length && routePlaceholders}
          {!!menu &&
            !!menu.menuItems &&
            menu.menuItems.map(this.renderMenuItem)}
        </ArcListContainer>

        <Divider />

        <ArcView marginTop="16" marginBottom="16">
          <ArcView position="relative">
            <ArcLinkItem
              onPress={this.handlePressMyMedia}
              disabled={!this.props.isInternetReachable}
            >
              {'My Media'}
            </ArcLinkItem>

            <ArcView position="relative">
              <ArcLinkItem
                onPress={this.handlePressProfile}
                disabled={!this.props.isInternetReachable}
                isSelected={this.hasSelectedProfile}
              >
                {'View My Profile'}
              </ArcLinkItem>
              {this.hasSelectedProfile && <SelectedDivider />}
            </ArcView>
          </ArcView>
          <ArcLinkItem
            onPress={this.handlePressMute}
            disabled={!this.props.isInternetReachable}
          >
            {this.props.isMuted ? 'Unmute' : 'Mute'}
            {' Notifications'}
          </ArcLinkItem>

          {Platform.OS === 'web' ? (
            <ArcTourStepperContainer>
              {openPortal => (
                <ArcLinkItem
                  onPress={openPortal}
                  disabled={!this.props.isInternetReachable}
                >
                  {'Onboarding Tour'}
                </ArcLinkItem>
              )}
            </ArcTourStepperContainer>
          ) : (
            <ArcLinkItem
              onPress={this.handlePressHelpAndSupport}
              disabled={!this.props.isInternetReachable}
            >
              {'Onboarding Tour'}
            </ArcLinkItem>
          )}

          <ArcLinkItem target="_blank" href="https://help.arcade.co">
            {'Resources'}
          </ArcLinkItem>

          <ArcLinkItem
            target="_blank"
            href="https://www.iubenda.com/privacy-policy/19963067"
          >
            {'Privacy Policy'}
          </ArcLinkItem>

          <ArcLinkItem
            target="_blank"
            href="https://arcade.co/service-agreement"
          >
            {'Terms of Service'}
          </ArcLinkItem>

          <ArcLinkItem onPress={this.handlePressLogout}>{'Logout'}</ArcLinkItem>
        </ArcView>
        <ArcView>
          <ArcImage
            src="https://s3.amazonaws.com/arcade-assets-public/logo/logo_main.svg"
            width={200}
            height={74}
          />
        </ArcView>
        {this.props.hasBars && bars}
      </ArcView>
    );
  }
}

const getState = (state, ownProps) => ({
  currentUser: getCurrentUser(state),
  // endpoint: getCurrentUserEndpoint(state),
  isMuted: getIsMuted(state),
  menu: getMenu(state),

  locationType: getLocationType(state),
  locationPayload: getLocationPayload(state),

  ...ownProps,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      appLogout: actions.appLogout,
      appMenuRequest: actions.appMenuRequest,
      appSetOnboardingTourOpen: actions.appSetOnboardingTourOpen,
      webviewSetIsMuted: actions.webviewSetIsMuted,
      onMute: actions.appToggleMuteRequest,
      uploadManagerOpened: widgetActions.uploadManagerOpened,
      dispatch,
    },
    dispatch,
  );

export default connect(getState, getActions)(ArcLeftMenu);
