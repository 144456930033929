import React from 'react';
import IconGroup from '@material-ui/icons/Group';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcText from '../../primitives/ArcText';
import ArcStyles from '../../styles';
import { ArcPerson, ArcTeam, ArcEntity } from '../ArcPeopleList';
import ArcView from '../../primitives/ArcView';
import { taggableItem } from './taggableTypes';

const styleSuggestion = {
  padding: '8px 16px',
  minWidth: 200,
};

const styleSuggestionSelected = {
  ...styleSuggestion,
  '& .ArcPerson__name': {
    color: ArcStyles.theme.blue3,
  },
};

const styleEveryoneTag = {
  marginLeft: 4,
  height: 32,
};

const styleEveryoneText = {
  marginLeft: 12,
  fontSize: 16,
  lineHeight: '22px',
  color: '#8C8C8C',
};

const styleEveryoneCount = {
  marginLeft: 12,
  fontSize: 10,
  lineHeight: '10px',
  color: '#8C8C8C',
};

const renderPerson = (entry, totalUsers) => {
  if (entry.id === '0') {
    return (
      <ArcView row align="center" style={styleEveryoneTag}>
        <IconGroup />
        <ArcView>
          <ArcText style={styleEveryoneText}>
            {'Everyone'}
          </ArcText>
          <ArcText style={styleEveryoneCount}>
            {`${totalUsers} people`}
          </ArcText>
        </ArcView>
      </ArcView>
    );
  }
  return (
    <ArcPerson {...entry} />
  );
};

const renderSuggestion = (entry, totalUsers) => {
  switch (entry.type) {
    case 'team':
      return <ArcTeam {...entry} />;
    case 'entity':
      return <ArcEntity {...entry} />;
    default:
      return renderPerson(entry, totalUsers);
  }
};

const ArcSuggestionTag = (props) => {
  const {
    entry,
    isSelected,
    totalUsers,
    ...rest
  } = props;

  return (
    <ArcView
      style={isSelected ? styleSuggestionSelected : styleSuggestion}
      data-suggestion-type={entry.type}
      {...rest}
    >
      { renderSuggestion(entry, totalUsers) }
    </ArcView>
  );
};

ArcSuggestionTag.propTypes = {
  entry: taggableItem.isRequired,
  isSelected: ArcPropTypes.bool.isRequired,
  totalUsers: ArcPropTypes.number,
};

ArcSuggestionTag.defaultProps = {
  totalUsers: 0,
};

export default ArcSuggestionTag;
