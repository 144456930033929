import { createWithStyles } from '../../styles';
import ArcView from '../../primitives/ArcView';

const styles = {
  ArcButtonGroup: theme => ({
    root: {
      display: 'flex',
      flexDirection: 'row',

      '& > .ArcButton': {
        padding: 8,
        borderRadius: 0,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.divider,

        marginLeft: -1,
      },

      '& > .ArcButton:hover': {
        zIndex: 10,
        borderColor: theme.palette.secondary.main,
      },

      '& > .ArcButton.isActive': {
        zIndex: 10,
        // backgroundColor: '#fafffe',
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
      },

      '& > .ArcButton:first-child': {
        marginLeft: 0,
      },

      '& > .ArcButton:last-child': {
      },
    },

    marginBottom: {
      marginBottom: theme.spacing(2),
    },

    marginLeft: {
      marginLeft: theme.spacing(2),
    },

    fullWidth: {
      width: '100%',

      '& > .ArcButton': {
        flex: 10,
      },
    },
  }),
};

const ArcButtonGroup = createWithStyles(styles.ArcButtonGroup)(ArcView);

export default ArcButtonGroup;
