import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/styles';

import {
  ArcView,
  ArcText,
  ArcButton,
  ArcSelect,
  ArcSimpleDialog,
  date,
} from 'arcade-frontend-ui';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';

const responsiveInputStyles = makeStyles(() => ({
  formControl: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
  },
  inputBase: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
    flexDirection: 'column',
  },
  textArea: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto',
  },
}));

const IntegrationHistoryHelpDialog = ({
  open,
  runs,
  onClose,
  onConfirm,
  status,
}) => {
  const responsiveInputClasses = responsiveInputStyles();

  const [selectedRun, setSelectedRun] = useState('');
  const [currentText, setcurrentText] = useState('');
  const updateCurrentText = event => setcurrentText(event.target.value);

  const handleRunChange = event => setSelectedRun(event.target.value);

  const runItems = runs.map(({ id, sheetName, createdAt }) => ({
    value: id,
    label: `${sheetName} (${date.simpleLocaleDateTime({
      datetime: createdAt,
    })})`,
  }));
  const emptyItem = { value: '', label: 'Select Integration Run' };

  const handleConfirm = () =>
    onConfirm({ runId: selectedRun, text: currentText });

  return (
    <ArcSimpleDialog
      open={open}
      title="Request help from the support team"
      onClose={onClose}
    >
      <ArcView padding={16} scrollParent>
        <ArcText>
          To get help with your integrations, please describe what you need in
          the field below (and choose ther related integration run, if
          applicable.)
        </ArcText>
        <ArcView marginTop={8}>
          <ArcSelect
            placeholder="choose an integration run [optional]"
            items={[emptyItem, ...runItems]}
            value={selectedRun}
            onChange={handleRunChange}
            variant="outlined"
          />
        </ArcView>
        <ArcView marginTop={8} scrollParent>
          <FormControl classes={{ root: responsiveInputClasses.formControl }}>
            <OutlinedInput
              placeholder="Describe your issue"
              multiline
              rows={1}
              value={currentText}
              onChange={updateCurrentText}
              classes={{ root: responsiveInputClasses.inputBase }}
              inputProps={{ className: responsiveInputClasses.textArea }}
            />
          </FormControl>
        </ArcView>
      </ArcView>
      <DialogActions>
        <ArcButton onClick={onClose}>Cancel</ArcButton>
        <ArcResourceButton
          onClick={handleConfirm}
          color="blue"
          variant="outlined"
          label="Submit"
          status={status}
        />
      </DialogActions>
    </ArcSimpleDialog>
  );
};

IntegrationHistoryHelpDialog.propTypes = {
  open: PropTypes.bool,
  runs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      sheetName: PropTypes.string,
      createdAt: PropTypes.string,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  status: PropTypes.shape({
    idle: PropTypes.bool,
    pending: PropTypes.bool,
    succeeded: PropTypes.bool,
    failed: PropTypes.bool,
  }),
};

IntegrationHistoryHelpDialog.defaultProps = {
  open: false,
  status: {},
};

export default IntegrationHistoryHelpDialog;
