import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/sales';

const handleManageSaleMetricsLoadSuccess = (state, action) => {
  const { data } = action.payload;

  return [...data];
};

const initialState = [];

const handlers = {
  [types.MANAGE_SALE_METRICS_INDEX.SUCCESS]: handleManageSaleMetricsLoadSuccess,
};

const salesById = createReducer(initialState, handlers);

export default salesById;

export const getManageSaleMetrics = state => state.manage.sales.metrics;
