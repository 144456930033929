const ANIMATION_DELAY = 250;

let hasRemovedBootLogo = false;

export function removeBootLogo() {
  if (window?.document && !hasRemovedBootLogo) {
    const bootLogo = document.getElementById('bootLogo');
    if (bootLogo) {
      hasRemovedBootLogo = true;
      bootLogo.classList.add('animated', 'animated--fast', 'fadeOut');

      setTimeout(() => {
        document.body.removeChild(bootLogo);
        document.body.classList.remove('is-loading');
      }, ANIMATION_DELAY);
    }
  }
}

export default removeBootLogo;
