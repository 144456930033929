import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';


function ArcMultiSelectOption(props) {
  const { renderItem } = props.selectProps;

  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {renderItem ? renderItem(props.data) : props.children}
    </MenuItem>
  );
}

ArcMultiSelectOption.displayName = 'ArcMultiSelectOption';

ArcMultiSelectOption.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  data: PropTypes.shape({}),
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // key: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
  }),
  /**
   * Inner ref to DOM Node
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
  /**
   * Whether the option is focused.
   */
  isFocused: PropTypes.bool.isRequired,
  /**
   * Whether the option is selected.
   */
  isSelected: PropTypes.bool.isRequired,

  selectProps: PropTypes.shape({
    renderItem: PropTypes.func,
  }),
};

ArcMultiSelectOption.defaultProps = {
  children: null,
  data: {},
  innerProps: {},
  innerRef: undefined,
  selectProps: {},
};

export default ArcMultiSelectOption;
