import React from 'react';
import PropTypes from 'prop-types';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import { ArcText } from 'arcade-frontend-ui';

function GamesScoreLabel({
  title,
  metric,
  titleFontSize,
  metricFontSize,
  ...props
}) {
  return (
    <ArcBox display="flex" flexDirection="column" {...props}>
      <ArcBox component={ArcText} fontSize={titleFontSize} fontWeight="bold">
        {title}
      </ArcBox>
      {metric && (
        <ArcBox component={ArcText} fontSize={metricFontSize} mt={0.5}>
          {metric}
        </ArcBox>
      )}
    </ArcBox>
  );
}

GamesScoreLabel.displayName = 'GamesScoreLabel';

GamesScoreLabel.propTypes = {
  title: PropTypes.string,
  metric: PropTypes.string,
  titleFontSize: PropTypes.number,
  metricFontSize: PropTypes.number,
};

GamesScoreLabel.defaultProps = {
  title: 'Your Score',
  metric: null,
  titleFontSize: 14,
  metricFontSize: 12,
};

export default GamesScoreLabel;
