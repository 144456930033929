import React from 'react';
import PropTypes from 'prop-types';

import GamesGameCardSmall from './GamesGameCard';
import GamesGameCardLarge from './GamesGameCardLarge';

export default function GamesGameCard({ variant, ...props }) {
  switch (variant) {
    case 'large':
      return <GamesGameCardLarge {...props} />;
    case 'small':
    default:
      return <GamesGameCardSmall {...props} />;
  }
}

function GamesGameCardPlaceholder({ variant, ...props }) {
  switch (variant) {
    case 'large':
      return <GamesGameCardLarge.Placeholder {...props} />;
    case 'small':
    default:
      return <GamesGameCardSmall.Placeholder {...props} />;
  }
}

GamesGameCardPlaceholder.displayName = 'GamesGameCardPlaceholder';

GamesGameCardPlaceholder.propTypes = GamesGameCard.propTypes;

GamesGameCardPlaceholder.defaultProps = GamesGameCard.defaultProps;

GamesGameCard.displayName = 'GamesGameCard';

GamesGameCard.propTypes = {
  variant: PropTypes.oneOf(['small', 'large']),
};

GamesGameCard.defaultProps = {
  variant: 'small',
};

GamesGameCard.Placeholder = GamesGameCardPlaceholder;
