import React from 'react';

import { ArcImage, ArcView, ArcPropTypes } from 'arcade-frontend-ui';

const style = (size, url, clickable) => ({
  position: 'relative',
  width: size,
  height: size,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `url(${url})`,
  cursor: clickable ? 'pointer' : 'inherit',
  borderBottomRightRadius: '10px',
});

const styleBadge = (badgeSize, offset) => ({
  position: 'absolute',
  bottom: offset,
  right: offset,
  width: badgeSize,
  height: badgeSize,
  zIndex: 100,
});

const Portrait = ({ url, size, onClick, badge, badgeSize, offset }) => (
  <ArcView onClick={onClick} style={style(size, url, onClick !== global.noop)}>
    {badge && <ArcImage style={styleBadge(badgeSize, offset)} src={badge} />}
  </ArcView>
);

Portrait.propTypes = {
  url: ArcPropTypes.url.isRequired,
  size: ArcPropTypes.number,
  onClick: ArcPropTypes.func,
  badge: ArcPropTypes.url,
  badgeSize: ArcPropTypes.number,
  offset: ArcPropTypes.number,
};

Portrait.defaultProps = {
  size: 56,
  onClick: global.noop,
  badge: null,
  badgeSize: 32,
  offset: -12,
};

export default Portrait;
