import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FlatList } from 'react-native';
import Typography from '@material-ui/core/Typography';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcView from 'arcade-frontend-ui/src/primitives/ArcView';

import ArcAudienceMember from '../ArcAudienceMember';
import ArcAudienceListLetter from './ArcAudienceListLetter';
import ArcAudienceListPlaceholder from './ArcAudienceListPlaceholder';
import ArcAudienceListSelectAll from './ArcAudienceListSelectAll';
import ArcAudienceListUpOneLevel from './ArcAudienceListUpOneLevel';

const PLACEHOLDERS = (
  <ArcView>
    <ArcView marginTop="8" padding="16" className="shimmer" />
    <ArcView marginTop="8" padding="16" className="shimmer" />
    <ArcView marginTop="8" padding="16" className="shimmer" />
    <ArcView marginTop="8" padding="16" className="shimmer" />
    <ArcView marginTop="8" padding="16" className="shimmer" />
  </ArcView>
);

const ANIMATION_DURATION = 300;

const FADE_DIRECTIONS = {
  LEFT: 'left',
  NONE: 'none',
  RIGHT: 'right',
};

class ArcAudienceList extends React.PureComponent {
  static displayName = 'ArcAudienceList';

  static propTypes = {
    currentLevelName: PropTypes.string,
    currentUserId: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    disableSelf: PropTypes.bool,
    fadeDirection: PropTypes.oneOf(Object.values(FADE_DIRECTIONS)),
    fadeOut: PropTypes.bool,
    hasBack: PropTypes.bool,
    hasClick: PropTypes.bool,
    hasChange: PropTypes.bool,
    hasLetterHeaders: PropTypes.bool,
    hasPartiallySelectedAll: PropTypes.bool,
    hasRemove: PropTypes.bool,
    hasSelectedAll: PropTypes.bool,
    level: PropTypes.oneOf(['top', 'middle', 'bottom']),
    noDataPlaceholder: PropTypes.node,
    onBack: PropTypes.func,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    onSelectAll: PropTypes.func,
    partiallySelectedEntitiesByUuid: PropTypes.objectOf(PropTypes.bool),
    placeholderText: PropTypes.node,
    previousLevelName: PropTypes.string,
    preSelectingEntitiesByUuid: PropTypes.objectOf(PropTypes.bool),
    requestStatus: PropTypes.oneOf([
      'DEFAULT',
      'REQUEST',
      'SUCCESS',
      'FAILURE',
    ]),
    selectAllDisabled: PropTypes.bool,
    selectAllName: PropTypes.string,
    selectedEntitiesByUuid: PropTypes.objectOf(PropTypes.bool),
    shouldSort: PropTypes.bool,
    style: PropTypes.objectOf(PropTypes.any),
    upOneLevelDisabled: PropTypes.bool,
    upOneLevelTooltipText: PropTypes.string,
  };

  static defaultProps = {
    currentLevelName: '',
    currentUserId: '',
    data: [],
    disableSelf: false,
    fadeDirection: FADE_DIRECTIONS.NONE,
    fadeOut: false,
    hasBack: false,
    hasClick: false,
    hasChange: false,
    hasLetterHeaders: false,
    hasPartiallySelectedAll: false,
    hasRemove: false,
    hasSelectedAll: false,
    level: undefined,
    noDataPlaceholder: 'No teams or people available.',
    onBack: global.noop,
    onClick: global.noop,
    onChange: global.noop,
    onRemove: global.noop,
    onSelectAll: global.noop,
    partiallySelectedEntitiesByUuid: {},
    placeholderText: '',
    previousLevelName: '',
    preSelectingEntitiesByUuid: {},
    requestStatus: 'DEFAULT',
    selectAllDisabled: false,
    selectAllName: '',
    selectedEntitiesByUuid: {},
    shouldSort: true,
    style: null,
    upOneLevelDisabled: false,
    upOneLevelTooltipText: '',
  };

  static ANIMATION_DURATION = ANIMATION_DURATION;

  static FADE_DIRECTIONS = FADE_DIRECTIONS;

  state = {
    data: [],
    stickyHeaderIndices: [],
  };

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.updateData();
    }
  }

  get hasData() {
    return !!this.props.data.length;
  }

  get hasSelectedAll() {
    return this.getHasSelected(true);
  }

  get hasPartiallySelectedAll() {
    return this.getHasSelected();
  }

  getHasSelected = (checkAll = false) => {
    const {
      data,
      selectedEntitiesByUuid,
      preSelectingEntitiesByUuid,
    } = this.props;
    let hasSelected = !!checkAll;

    for (let idx = 0, dataLength = data.length; idx < dataLength; idx += 1) {
      const conditionForSelection = checkAll
        ? !selectedEntitiesByUuid[data[idx].uuid] &&
          !preSelectingEntitiesByUuid[data[idx].uuid]
        : selectedEntitiesByUuid[data[idx].uuid] ||
          preSelectingEntitiesByUuid[data[idx].uuid];

      if (conditionForSelection) {
        hasSelected = !hasSelected;
        break;
      }
    }

    return hasSelected;
  };

  getItemLayout = (data, index) => ({
    length: 40,
    offset: 40 * index,
    index,
  });

  setData = data => this.setState({ data });

  setStickyHeaderIndices = stickyHeaderIndices =>
    this.setState({ stickyHeaderIndices });

  updateData = () => {
    const nextData = this.props.hasRemove
      ? [
          {
            key: 'placeholder',
            listItemType: 'placeholder',
          },
        ]
      : [
          {
            key: 'placeholder',
            listItemType: 'placeholder',
          },
          {
            key: 'upOneLevel',
            listItemType: 'upOneLevel',
          },
          {
            key: 'selectAll',
            listItemType: 'selectAll',
          },
        ];

    const nextStickyHeaderIndices = [];

    const sortedData = this.props.shouldSort
      ? this.props.data.sort((a, b) => {
          const aName = a.letter || a.name;
          const bName = b.letter || b.name;
          if (aName === bName) {
            return 0;
          }

          return aName > bName ? 1 : -1;
        })
      : this.props.data;

    let previousLetter;

    sortedData.forEach(item => {
      const name = item.letter || item.name;

      if (!name) {
        return;
      }

      const nextLetter = name[0].toUpperCase();

      if (this.props.hasLetterHeaders && nextLetter !== previousLetter) {
        nextData.push({
          listItemType: 'letter',
          key: nextLetter,
          header: true,
          letter: nextLetter,
        });

        previousLetter = nextLetter;
      }

      nextData.push({
        listItemType: 'audienceMember',
        ...item,
        key: item.id.toString(),
      });
    });

    nextData.forEach((item, index) => {
      if (item.header) {
        nextStickyHeaderIndices.push(index);
      }
    });

    this.setData(nextData);
    this.setStickyHeaderIndices(nextStickyHeaderIndices);
  };

  keyExtractor = item => item.key;

  handleChange = item => this.props.onChange(item);

  handleClick = item => this.props.onClick(item);

  handleRemove = item => this.props.onRemove(item);

  renderAudienceMember = item => {
    const { fadeDirection, fadeOut } = this.props;

    const isDisabled =
      this.props.disableSelf &&
      item.type === 'person' &&
      item.id === this.props.currentUserId;

    return (
      <ArcAudienceMember
        {...item}
        role="listitem"
        checked={this.props.selectedEntitiesByUuid[item.uuid]}
        checkboxDisabled={isDisabled}
        entityDisabled={isDisabled}
        partiallyChecked={this.props.partiallySelectedEntitiesByUuid[item.uuid]}
        teamName={
          item.teamName && this.props.level !== 'bottom' ? item.teamName : null
        }
        hasChange={this.props.hasChange}
        hasClick={this.props.hasClick}
        hasRemove={this.props.hasRemove && !isDisabled}
        isNested={!!item.addedFromParentEntity}
        isPreSelected={this.props.preSelectingEntitiesByUuid[item.uuid]}
        onChange={this.handleChange}
        onClick={this.handleClick}
        onRemove={this.handleRemove}
        className={cx([
          'animated',
          fadeOut && fadeDirection === FADE_DIRECTIONS.LEFT && 'fadeOutLeft',
          fadeOut && fadeDirection === FADE_DIRECTIONS.NONE && 'fadeOut',
          fadeOut && fadeDirection === FADE_DIRECTIONS.RIGHT && 'fadeOutRight',

          !fadeOut && fadeDirection === FADE_DIRECTIONS.LEFT && 'fadeInLeft',
          !fadeOut && fadeDirection === FADE_DIRECTIONS.NONE && 'fadeIn',
          !fadeOut && fadeDirection === FADE_DIRECTIONS.RIGHT && 'fadeInRight',
        ])}
      />
    );
  };

  renderItem = ({ item }) => {
    switch (item.listItemType) {
      case 'audienceMember':
        return this.renderAudienceMember(item);
      case 'letter':
        return this.renderLetter(item);
      case 'placeholder':
        return this.renderPlaceholder(item);
      case 'selectAll':
        return this.renderSelectAll(item);
      case 'upOneLevel':
        return this.renderUpOneLevel(item);
      default:
        return null;
    }
  };

  renderLetter = item => (
    <ArcAudienceListLetter>{item.letter}</ArcAudienceListLetter>
  );

  renderUpOneLevel = () =>
    this.props.hasBack && (
      <ArcAudienceListUpOneLevel
        onClick={this.props.upOneLevelDisabled ? undefined : this.props.onBack}
        isDisabled={
          !this.props.previousLevelName || this.props.upOneLevelDisabled
        }
        tooltipText={this.props.upOneLevelTooltipText}
        name={this.props.previousLevelName}
      />
    );

  renderPlaceholder = () =>
    !!this.props.placeholderText && (
      <ArcAudienceListPlaceholder
        className="animated fadeIn"
        title={this.props.placeholderText}
      />
    );

  renderSelectAll = () => (
    <>
      <ArcAudienceListSelectAll
        disabled={this.props.selectAllDisabled || !this.hasData}
        onClick={this.props.onSelectAll}
        name={this.props.selectAllName || this.props.currentLevelName}
        checked={this.hasData ? this.hasSelectedAll : false}
        partiallyChecked={this.hasPartiallySelectedAll}
      />
      {!this.hasData && (
        <ArcView padding="8">
          <Typography variant="caption">
            {this.props.noDataPlaceholder}
          </Typography>
        </ArcView>
      )}
    </>
  );

  render() {
    if (!this.props.data.length && this.props.requestStatus === 'REQUEST') {
      return PLACEHOLDERS;
    }

    return (
      <ArcBox
        role="list"
        data-testid="ArcAudienceList"
        display="flex"
        flexShrink={100}
        flexGrow={100}
        overflow="auto"
      >
        <FlatList
          initialNumToRender={20}
          data={this.state.data}
          getItemLayout={this.getItemLayout}
          keyExtractor={this.keyExtractor}
          renderItem={this.renderItem}
          stickyHeaderIndices={this.stickyHeaderIndices}
          extraData={[
            this.props.partiallySelectedEntitiesByUuid,
            this.props.preSelectingEntitiesByUuid,
            this.props.placeholderText,
            this.props.selectedEntitiesByUuid,
            this.props.upOneLevelDisabled,
          ]}
          style={this.props.style}
        />
      </ArcBox>
    );
  }
}

export default ArcAudienceList;
