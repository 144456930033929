import React from 'react';

import { ArcView, createWithStyles, ArcPropTypes } from 'arcade-frontend-ui';

const styles = {
  DropMessage: () => ({
    root: {
      backgroundColor: 'rgba(255,255,255,0.5)',
      fontSize: 30,
      textAlign: 'center',
      verticalAlign: 'center',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  }),
};

const DropMessage = createWithStyles(styles.DropMessage)(ArcView);

const Target = ({ getRootProps, getInputProps, isDragActive, children }) => (
  <ArcView {...getRootProps({ refKey: 'internalRef' })}>
    {children}
    <input {...getInputProps()} />
    {isDragActive && <DropMessage>Drop your files here</DropMessage>}
  </ArcView>
);

Target.propTypes = {
  getRootProps: ArcPropTypes.func,
  getInputProps: ArcPropTypes.func,
  isDragActive: ArcPropTypes.bool,
  children: ArcPropTypes.node,
};

Target.defaultProps = {
  getRootProps: global.noop,
  getInputProps: global.noop,
  isDragActive: false,
  children: null,
};

export default Target;
