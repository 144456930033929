export interface NotificationActivity {
  id: number;
  activityId?: string;
  content: string;
  creator: string;
  date: Date;
  subject: string;
}

export interface Notification {
  id: string;
  title: string;
  activities: NotificationActivity[];
}

export const DIALOG_TYPES = {
  TYPE_ONE: 'TYPE_ONE',
  TYPE_TWO: 'TYPE_TWO',
  TYPE_THREE: 'TYPE_THREE',
};
