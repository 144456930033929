import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../actions/metrics/types';

const handleMetricRankingLoadSuccess = (state, action) => {
  const { data, metricId } = action.payload;

  const newState = { ...state };

  newState[metricId] = data;

  return newState;
};

const initialState = {};

const handlers = {
  [types.METRICS_RANKINGS_INDEX.SUCCESS]: handleMetricRankingLoadSuccess,
};

const rankingsById = createReducer(initialState, handlers);

export default rankingsById;
