import { put, call, takeLatest as takeLatestSaga } from '@redux-saga/core/effects';
import { types, actions } from '../actions';
import * as axios from '../helpers/axios';
import * as sagas from '../helpers/sagas';
import { api, normalizers } from '../resources';

const onSuccessLogin = (response) => {
  const user = {
    ...response.data,
    ...normalizers.fromLogin(response.data),
  };

  axios.setArcUserHeaders(user.token, user.email);

  return {
    type: 'APP_INIT',
    payload: {},
  };
};

const onSuccessChecklistRewardClaim = () => (
  actions.appRootScopeBroadcast({
    event: '$state.go',
    data: {
      location: 'arcade.prize.chests',
      params: {},
    },
  })
);

const onSuccessProfileUpdate = (response) => {
  if (window.ngRootScope) {
    window.ngRootScope.$broadcast('arcade:reload:profile');

    window.ngRootScope.$broadcast('arcade:profile:image:chosen',
      response.data.user.profile_image);
  }

  return actions.appMenuRequest();
};

const takeLatest = [
  {
    type: types.APP_LOGIN,
    call: api.login,
    onSuccess: onSuccessLogin,
  },
  {
    type: types.APP_LOGOUT,
    call: api.logout,
  },
  {
    type: types.APP_MENU,
    call: api.menu,
  },
  {
    type: types.APP_PERMISSIONS,
    call: api.permissions,
  },
  {
    type: types.APP_FEATURES,
    call: api.features,
  },
  {
    type: types.APP_UPDATE_FEATURES,
    call: api.updateFeatures,
  },
  {
    type: types.APP_PEOPLE_INDEX,
    call: api.people,
  },
  {
    type: types.APP_RESET_PASSWORD,
    call: api.resetPassword,
  },
  {
    type: types.APP_TAGGABLE_PEOPLE_INDEX,
    call: api.taggablePeople,
  },
  {
    type: types.APP_TAGGABLE_TEAMS_INDEX,
    call: api.taggableTeams,
  },
  {
    type: types.APP_TAGGABLE_ENTITIES_INDEX,
    call: api.taggableEntities,
  },
  {
    type: types.APP_HASH_TAGS_INDEX,
    call: api.hashTags,
  },
  {
    type: types.APP_INVITE_PERSON,
    call: api.invitePerson,
  },
  {
    type: types.APP_CHECKLIST_INDEX,
    call: api.checklist,
  },
  {
    type: types.APP_CHAT_REGISTRATION,
    call: api.registerChatSession,
  },
  {
    type: types.APP_CHECKLIST_CLAIM_REWARD,
    call: api.checklistClaimReward,
    onSuccess: onSuccessChecklistRewardClaim,
  },
  {
    type: types.APP_SAVE_WEBPUSH_CREDENTIALS,
    call: api.saveWebpushCredentials,
  },
  {
    type: types.APP_TOGGLE_MUTE,
    call: api.toggleMute,
  },
  {
    type: types.APP_GET_MUTE,
    call: api.getMute,
  },
  {
    type: types.APP_PROFILE_UPDATE,
    call: api.profileUpdate,
    onSuccess: onSuccessProfileUpdate,
  },
];

const core = takeLatest.map(sagas.makeTakeLatest);


const handleAppRootScopeBroadcast = function* (action) {
  const { payload } = action;

  const event = payload.event ? payload.event : payload;
  const { data } = payload;

  if (window.ngRootScope) {
    yield window.ngRootScope.$broadcast(event, data);
  } else {
    yield console.log(action, data);
  }
};

// https://github.com/GoogleChromeLabs/web-push-codelab/issues/46
const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

function* prepareWebpushCredentials() {
  const registration = yield call(() => navigator.serviceWorker.ready);

  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(process.env.WEBPUSH_VAPID_PUBLIC_KEY),
  };

  /*
    We supress the `reject` from the `subscribe` promise - it just means the
    user refused permission for the push subscription.
    https://developer.mozilla.org/en-US/docs/Web/API/PushManager/subscribe
  */
  const subscription = yield call(() => registration.pushManager.subscribe(subscribeOptions)
    .catch(() => null));

  if (subscription) {
    const { endpoint, keys: { p256dh, auth } } = subscription.toJSON();
    const body = { credentials: { endpoint, p256dh, auth } };
    yield put(actions.saveWebpushCredentials(body));
  }
}

function* handleToggleFeatureFlag(action) {
  const { payload } = action;
  yield put(actions.appUpdateFeaturesRequest(payload));
}

core.push(takeLatestSaga(types.APP_ROOT_SCOPE_BROADCAST, handleAppRootScopeBroadcast));

if ('serviceWorker' in navigator && 'PushManager' in window) {
  core.push(takeLatestSaga(types.APP_PREPARE_WEBPUSH_CREDENTIALS, prepareWebpushCredentials));
}

core.push(takeLatestSaga(types.APP_TOGGLE_FEATURE_FLAG, handleToggleFeatureFlag));

export default core;
