import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';
import { resources } from 'arcade-frontend-core/src/resources/tokens';
import { resources as userResources } from 'arcade-frontend-core/src/resources/users';
import { getAppPeopleById } from 'arcade-frontend-core/src/reducers/peopleById';
import { actions } from 'arcade-frontend-core/src/actions';

import ArcAwardTokenForm from '../../components/ArcAwardTokenForm';

function ArcAwardTokenFormContainer(props) {
  const dispatch = useDispatch();
  const appPeopleById = useSelector(getAppPeopleById);

  const apiGetEscrowableFunds = useResourceAction(
    userResources.apiGetEscrowableFunds,
  );
  const apiPostTokensAward = useResourceAction(resources.apiPostTokensAward);

  function setup() {
    if (!Object.keys(appPeopleById).length) {
      dispatch(actions.appTaggablePeopleIndexRequest());
    }

    apiGetEscrowableFunds
      .requestData()
      .then(apiGetEscrowableFunds.onStatusReset)
      .catch(global.noop);
  }

  React.useEffect(setup, []);

  function handleSubmit(values) {
    const userIds = values.people.map(person => {
      if (typeof person === 'number') {
        return person;
      }
      return person.id;
    });
    const data = {
      tokens: {
        amount: values.tokens,
        user_ids: userIds,
        is_public: values.isPublic,
        message: values.message,
        internal_reason: values.reason,
      },
    };

    return apiPostTokensAward
      .requestData({
        resources: [data],
      })
      .then(() => {
        // apiPostTokensAward.onStatusReset();
        setTimeout(() => {
          props.onCancel();
        }, 300);
      });
  }

  if (apiGetEscrowableFunds.isPending) {
    return (
      <ArcAwardTokenForm.Placeholder inDialog open onCancel={props.onCancel} />
    );
  }

  const escrowableFunds = apiGetEscrowableFunds.hasData
    ? apiGetEscrowableFunds.data.resources[0]
    : {};

  return (
    <ArcAwardTokenForm
      onSubmit={handleSubmit}
      peopleById={appPeopleById}
      escrowableFundsBalance={escrowableFunds.balance}
      escrowableFundsType={escrowableFunds.type}
      max={escrowableFunds.balance}
      error={apiPostTokensAward.error}
      isIdle={apiPostTokensAward.isIdle}
      isPending={apiPostTokensAward.isPending}
      hasError={apiPostTokensAward.hasError}
      onStatusReset={apiPostTokensAward.onStatusReset}
      onErrorReset={apiPostTokensAward.onErrorReset}
      status={apiPostTokensAward.status}
      {...props}
    />
  );
}

ArcAwardTokenFormContainer.displayName = 'ArcAwardTokenFormContainer';

ArcAwardTokenFormContainer.propTypes = {
  onCancel: PropTypes.func,
};

ArcAwardTokenFormContainer.defaultProps = {
  onCancel: global.noop,
};

export default ArcAwardTokenFormContainer;
