import React from 'react';
import PropTypes from 'prop-types';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';

import GamesUpcomingListContainer from '../../containers/GamesUpcomingListContainer';
import GamesNewFromTemplateBox from '../GamesNewFromTemplateBox';

const GamesUpcomingTabPanel = ({ onCardClick }) => (
  <ArcBox className="animated fadeIn">
    <GamesUpcomingListContainer onCardClick={onCardClick} />
    <GamesNewFromTemplateBox mb={4} />
  </ArcBox>
);

GamesUpcomingTabPanel.propTypes = {
  onCardClick: PropTypes.func,
};

GamesUpcomingTabPanel.defaultProps = {
  onCardClick: global.noop,
};

export default GamesUpcomingTabPanel;
