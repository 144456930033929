import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
  },
}));

function ArcMultiSelectEmpty(props) {
  const classes = useStyles();

  return (
    <Typography
      color="textSecondary"
      className={classes.root}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

ArcMultiSelectEmpty.displayName = 'ArcMultiSelectEmpty';

ArcMultiSelectEmpty.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props to be passed on to the wrapper.
   */
  innerProps: PropTypes.shape({}),
  selectProps: PropTypes.shape({}).isRequired,
};

ArcMultiSelectEmpty.defaultProps = {
  children: null,
  innerProps: {},
};

export default ArcMultiSelectEmpty;
