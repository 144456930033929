import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Close from '@material-ui/icons/Close';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcIconButton from 'arcade-frontend-ui/src/elements/ArcIconButton';
import LeftArrow from 'arcade-frontend-ui/src/icons/LeftArrow';

const useStyles = makeStyles(theme => ({
  backButton: {
    color: theme.palette.blue.main,
    padding: 0,
    '&:hover': {
      color: theme.palette.blue.dark,
      backgroundColor: 'none',
    },
  },
}));

function GamesGameCardTabPanel({
  children,
  onBack,
  onClose,
  spinnerNode,
  title,
  ...props
}) {
  const classes = useStyles();
  return (
    <ArcBox
      zIndex={1}
      display="flex"
      flexDirection="column"
      position="absolute"
      top={0}
      right={0}
      bottom={0}
      left={0}
      width="100%"
      height="100%"
      overflow="hidden"
      color="text.primary"
      bgcolor="background.paper"
      className="animated fadeIn"
      {...props}
    >
      <ArcBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        pt={2}
        pr={3}
        pb={3}
        pl={3}
      >
        {onBack !== global.noop && (
          <ArcIconButton
            aria-label="Back"
            onClick={onBack}
            className={classes.backButton}
          >
            <ArcBox component={LeftArrow} viewBox="0 0 24 18" fontSize={36} />
          </ArcIconButton>
        )}

        <ArcBox fontSize={28} lineHeight={1.5} fontWeight="600">
          {title}
        </ArcBox>
        {spinnerNode}
        <ArcBox flex={100} />
        <ArcIconButton aria-label="Close" onClick={onClose}>
          <Close />
        </ArcIconButton>
      </ArcBox>
      <ArcBox
        overflow="auto"
        display="flex"
        flex={100}
        borderTop={1}
        borderColor="divider"
        color="divider"
      >
        <ArcBox display="flex" flex={100} color="text.primary" width="100%">
          {children}
        </ArcBox>
      </ArcBox>
    </ArcBox>
  );
}

GamesGameCardTabPanel.displayName = 'GamesGameCardTabPanel';

GamesGameCardTabPanel.propTypes = {
  children: PropTypes.node,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  spinnerNode: PropTypes.node,
  title: PropTypes.node,
};

GamesGameCardTabPanel.defaultProps = {
  children: null,
  onBack: global.noop,
  onClose: global.noop,
  spinnerNode: null,
  title: null,
};

export default GamesGameCardTabPanel;
