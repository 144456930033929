import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import ArcTextField from '../../elements/ArcTextField';
import ArcView from '../../primitives/ArcView';

import ArcMultiSelectInputComponent from './ArcMultiSelectInputComponent';

const useStyles = makeStyles(() => ({
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
    minHeight: 48,
  },
}));

function ArcMultiSelectControl(props) {
  const classes = useStyles();

  const {
    children,
    innerProps,
    innerRef,
    selectProps,
  } = props;

  return (
    <ArcView
      paddingTop="8"
    >
      <ArcTextField
        variant="outlined"
        fullWidth
        InputProps={{
          inputComponent: ArcMultiSelectInputComponent,
          inputProps: {
            className: classes.input,
            ref: innerRef,
            children,
            ...innerProps,
          },
        }}
        {...selectProps.TextFieldProps}
      />
    </ArcView>
  );
}

ArcMultiSelectControl.displayName = 'ArcMultiSelectControl';

ArcMultiSelectControl.propTypes = {
  /**
   * Children to render.
   */
  children: PropTypes.node,
  /**
   * The mouse down event and the innerRef to pass down to the controller element.
   */
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.shape({
    TextFieldProps: PropTypes.object,
  }).isRequired,
};

ArcMultiSelectControl.defaultProps = {
  children: null,
};

export default ArcMultiSelectControl;
