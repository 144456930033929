import React from 'react';
import { ArcPropTypes } from 'arcade-frontend-ui';

import GamesViewV2 from 'arcade-frontend-games-v2/src/views/GamesView';
import * as types from 'arcade-frontend-core/src/types/routes';

const { PLATFORMS } = ArcPropTypes;

const base = {
  [types.ROUTE_GAMES]: GamesViewV2,
  [types.ROUTE_GAMES_SHOW]: GamesViewV2,
  [types.ROUTE_GAMES_CREATE]: GamesViewV2,
};

const benjo = base;
const development = base;
const launch = base;

export default {
  [PLATFORMS.BENJO]: benjo,
  [PLATFORMS.DEVELOPMENT]: development,
  [PLATFORMS.LAUNCH]: launch,
};
