import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const GamesOutline = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.913 11.5v3.826m1.913-1.913H6"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinejoin="round"
      d="M13.657 17.831a1.981 1.981 0 00-1.18-.39s-.581 0-1.179.39c-.597.388-2.176 1.125-3.401 1.147a5.927 5.927 0 01-3.44-1.026c-2.06-1.41-2.942-3.95-2.194-6.279.75-2.331 2.967-3.935 5.495-3.95a5.795 5.795 0 013.535 1.166l.004.003c.195.145.419.25.655.305L13 9.198a1.8 1.8 0 00.656-.306l.004-.003a5.78 5.78 0 013.525-1.165h.003a5.844 5.844 0 015.018 2.84 5.491 5.491 0 010 5.617C21.175 17.92 19.263 19 17.188 19h-.003a5.848 5.848 0 01-3.526-1.167l-.002-.002z"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      d="M15.238 12.572a2.102 2.102 0 011.948-1.252c1.168.005 2.09.925 2.09 2.03 0 .816-.505 1.56-1.294 1.877a2.151 2.151 0 01-2.298-.447 1.983 1.983 0 01-.446-2.207z"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.274 6.01l.08 3.028"
    />
  </Icon>
);

export default GamesOutline;
