import { createRequestingStatusReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const initialState = {
  APP_LOGIN: 'DEFAULT',
};

const actions = [
  types.APP_LOGIN,
  types.APP_RESET_PASSWORD,
];

const requestStatus = createRequestingStatusReducer(initialState, actions);

export default requestStatus;

export const getRequestStatus = state => state.app.requestStatus;
