import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import {
  ArcButton,
  ArcCheckbox,
  ArcText,
  ArcView,
  ArcLoaderButton,
  createWithStyles,
} from 'arcade-frontend-ui';
import track from 'arcade-frontend-core/src/helpers/track';
import {
  SlimEditor,
  // ArcAudienceSelectorContainer,
} from 'arcade-frontend-widgets';
import { videoObject } from 'arcade-frontend-widgets/src/propTypes';

import { buildActivityFormDataFromEditorFields } from '../utils';

const styles = {
  ControlsWrapper: theme => ({
    root: {
      position: 'absolute',
      width: '100%',
      top: '100%',
      overflow: 'hidden',
      transition: theme.transitions.create(['transform', 'opacity']),
      transform: 'scaleY(0)',
      transformOrigin: 'top center',
      opacity: 0,
    },
    isActive: {
      position: 'relative',
      top: 'initial',
      boxShadow: theme.shadows[1],
      transform: 'scaleY(1)',
      opacity: 1,
    },
  }),
  Controls: theme => ({
    root: {
      backgroundColor: theme.palette.grey[100],
      flexDirection: 'row',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    isActive: {},
  }),
  EditorWrapper: theme => ({
    root: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  }),
};

const Controls = createWithStyles(styles.Controls)(ArcView);
const ControlsWrapper = createWithStyles(styles.ControlsWrapper)(ArcView);
const EditorWrapper = createWithStyles(styles.EditorWrapper)(ArcView);

const activityData = PropTypes.shape({});

const shareButtonStyle = {
  borderRadius: 0,
  width: 56,
};

const STRINGS = {
  'NEWSFEED/ACTIVITY_EDITOR_PROMOTE_CHECKBOX_LABEL':
    'Mark this post as a priority',

  'NEWSFEED/ACTIVITY_EDITOR_PROMOTE_CHECKBOX_DESCRIPTION':
    'This will send a notification to everyone in the audience',

  'NEWSFEED/ACTIVITY_EDITOR_POSTING_TO_ANOTHER_NEWSFEED_TEXT_LINE_1':
    "The audience you selected doesn't include the one you are viewing",

  'NEWSFEED/ACTIVITY_EDITOR_POSTING_TO_ANOTHER_NEWSFEED_TEXT_LINE_2':
    "You can still share but it won't appear until you switch newsfeeds",

  'NEWSFEED/ACTIVITY_EDITOR_PROTECTED_POST_PERMISSION_ERROR':
    "You don't have permission to post to the selected audience.",

  'NEWSFEED/ACTIVITY_EDITOR_SHARED_SUMMARY_TEXT': 'See my',

  'NEWSFEED/ACTIVITY_EDITOR_MANAGE_POSTS_BUTTON_TEXT': 'Post History',
};

class ActivityEditor extends React.Component {
  static propTypes = {
    attachedFiles: PropTypes.arrayOf(videoObject),
    attachedVideos: PropTypes.arrayOf(videoObject),
    canPostToProtectedAudiences: PropTypes.bool,
    canUploadFiles: PropTypes.bool,
    canUploadImages: PropTypes.bool,
    canUploadVideos: PropTypes.bool,
    createActivity: PropTypes.func,
    currentlyEditing: activityData,
    isViewingProtectedAudience: PropTypes.bool,
    locationQuery: PropTypes.shape({
      audienceId: PropTypes.string,
    }),
    onCancelEdit: PropTypes.func,
    onEditActivity: PropTypes.func,
    onRemoveMedia: PropTypes.func,
    requestStatus: PropTypes.shape({
      NEWSFEED_CREATE_ACTIVITY: PropTypes.string,
      NEWSFEED_UPDATE_ACTIVITY: PropTypes.string,
    }),
    updateActivity: PropTypes.func,
    uploadManagerOpened: PropTypes.func,
    userTeamMembers: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    attachedFiles: [],
    attachedVideos: [],
    canPostToProtectedAudiences: false,
    canUploadFiles: false,
    canUploadImages: false,
    canUploadVideos: false,
    createActivity: global.noop,
    currentlyEditing: null,
    isViewingProtectedAudience: false,
    locationQuery: {},
    onEditActivity: global.noop,
    onCancelEdit: global.noop,
    onRemoveMedia: global.noop,
    requestStatus: {},
    updateActivity: global.noop,
    uploadManagerOpened: global.noop,
    userTeamMembers: [],
  };

  state = {
    attachedFiles: [],
    audienceIds: [],
    audienceUsers: {},
    value: '',
    hasChanged: false,
    hasProtectedAudiences: this.props.isViewingProtectedAudience,
    isPromotingPost: false,
    inputFocused: false,
  };

  componentDidMount() {
    if (window.ngRootScope) {
      this.cancelListener = window.ngRootScope.$on(
        'arcade:newsfeed:activity:edit',
        this.onEditActivity,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const didSubmit =
      prevProps.requestStatus[this.currentRequestStatus] === 'SUCCESS' &&
      this.props.requestStatus[this.currentRequestStatus] === 'DEFAULT';

    if (didSubmit) {
      this.clearActivity();
    }

    if (prevProps.currentlyEditing !== this.props.currentlyEditing) {
      this.updateActivity();
    }

    if (prevProps.attachedVideos !== this.props.attachedVideos) {
      this.setHasChanged(!!this.props.attachedVideos.length);
    }
  }

  componentWillUnmount() {
    if (this.cancelListener) {
      this.cancelListener();
    }
  }

  get currentRequestStatus() {
    if (this.props.currentlyEditing) {
      return 'NEWSFEED_UPDATE_ACTIVITY';
    }

    return 'NEWSFEED_CREATE_ACTIVITY';
  }

  get isDisabled() {
    return this.props.requestStatus[this.currentRequestStatus] !== 'DEFAULT';
  }

  get shareButtonDisabled() {
    if (this.props.currentlyEditing) {
      return false;
    }

    return (
      this.isRequesting ||
      !this.state.hasChanged ||
      (this.props.isViewingProtectedAudience &&
        !this.props.canPostToProtectedAudiences)
    );
  }

  get taggableUsers() {
    const { audienceUsers } = this.state;
    const users = [];
    Object.keys(audienceUsers).forEach(audience => {
      users.push(...audienceUsers[audience]);
    });
    return [...new Set(users)];
  }

  setHasChanged = hasChanged => this.setState({ hasChanged });

  setIsPromotingPost = isPromotingPost => {
    const promoting = isPromotingPost ? 'true' : 'false';
    track(`promoting activity set to ${promoting}`, {
      payload: {
        event: 'newsfeed:promoting-activity',
        properties: { promoting },
      },
    });
    this.setState({ isPromotingPost });
  };

  clearActivity = () => {
    this.props.onCancelEdit();

    if (this.inputRef.current) {
      this.inputRef.current.clear();
      this.inputRef.current.dirtyRef.blur();
    }

    this.props.attachedVideos.forEach(video => {
      this.props.onRemoveMedia(video.name);
    });

    this.setState({
      hasChanged: false,
      value: '',
      audienceIds: [],
      audienceUsers: {},
      attachedFiles: [],
    });
  };

  handleEditActivity = (event, activity) => {
    this.props.onEditActivity(activity);

    this.setState({ audienceIds: activity.audienceIds });
  };

  updateActivity = () => {
    const { currentlyEditing } = this.props;

    if (currentlyEditing) {
      const { images, files } = currentlyEditing;
      const attachedImages = images || [];

      const attachedFiles = files;

      const attachedContent = [...attachedImages, ...attachedFiles];

      this.setState({
        hasChanged: true,
        audienceIds: currentlyEditing.audienceId
          ? [currentlyEditing.audienceId]
          : [],
        attachedFiles: attachedContent,
      });
    } else {
      this.clearActivity();
    }
  };

  inputRef = React.createRef();

  handleAdd = () => {
    track('added media to activity', {
      payload: { event: 'newsfeed:activity-add-media' },
    });
    this.setHasChanged(true);
  };

  handleRemove = ({ files, videos }) => {
    track('removed media from activity', {
      payload: { event: 'newsfeed:activity-remove-media' },
    });
    this.setHasChanged(
      !!files.length || !!videos.length || !!this.state.value.length,
    );
  };

  handleRemoveMedia = name => {
    track('removed media from activity', {
      payload: { event: 'newsfeed:activity-remove-media' },
    });
    this.props.onRemoveMedia(name);

    this.setState({
      attachedFiles: this.state.attachedFiles.filter(
        file => file.name !== name,
      ),
    });
  };

  handleChange = userInputValues => {
    this.setState({
      hasChanged: userInputValues.hasChanged,
      value: userInputValues.modelValue,
    });
  };

  handleSubmit = editorFields => {
    const { createActivity, updateActivity, currentlyEditing } = this.props;
    const { audienceIds, isPromotingPost } = this.state;
    const { audienceId } = this.props.locationQuery;

    const fields = {
      ...editorFields,
      // audiences: audienceIds.length > 0 ? audienceIds : [audienceId],
      files: [...editorFields.files],
      isPromotingPost,
    };

    if (currentlyEditing) {
      track('updating activity', {
        payload: { event: 'newsfeed:activity-update' },
      });
      updateActivity({
        data: buildActivityFormDataFromEditorFields(fields),
        id: currentlyEditing.id,
      });
    } else {
      track('creating activity', {
        payload: { event: 'newsfeed:activity-create' },
      });
      createActivity(buildActivityFormDataFromEditorFields(fields));
    }
  };

  handleCancel = () => {
    track('cancel editing activity', {
      payload: {
        event: 'newsfeed:activity-edit-cancel',
        properties: {
          editingExisting:
            !!this.props.currentlyEditing && !!this.props.currentlyEditing.id,
        },
      },
    });
    this.clearActivity();
  };

  handleAudienceSelected = audience => {
    const { audienceIds, audienceUsers } = this.state;
    const index = audienceIds.indexOf(audience.id);
    let { hasProtectedAudiences } = this.state;

    if (index === -1) {
      audienceIds.push(audience.id);
      hasProtectedAudiences = hasProtectedAudiences || audience.protected;
      audienceUsers[audience.id] = audience.users;
    } else {
      audienceIds.splice(index, 1);

      if (!audienceIds.length) {
        hasProtectedAudiences = false;
      }

      delete audienceUsers[audience.id];
    }

    this.setState({
      audienceIds,
      audienceUsers,
      hasProtectedAudiences,
    });
  };

  handleChangeIsPromotingPost = () =>
    this.setIsPromotingPost(!this.state.isPromotingPost);

  handleInputFocused = inputFocused => this.setState({ inputFocused });

  renderControls = ({
    isActive,
    handleSubmit,
    handleCancel,
    handleFocus,
    controlProps,
  }) => {
    const { audienceIds, currentlyEditing } = controlProps;

    return (
      <ControlsWrapper isActive={isActive || !!currentlyEditing}>
        <Controls isActive={isActive || !!currentlyEditing}>
          {/* <ArcAudienceSelectorContainer
            selectedIds={audienceIds}
            onSelect={this.handleAudienceSelected}
            onFocus={handleFocus}
            isDisabled={
              this.isRequesting || this.props.isViewingProtectedAudience
            }
            isEditable={!currentlyEditing}
          /> */}
          <ArcView padding="8" paddingLeft="16">
            <ArcText colorGrey isSmaller lineHeight1em>
              {'Share to'}
            </ArcText>
            <ArcView marginTop="4">
              <ArcText colorGrey isSmall lineHeight1em>
                {'Everyone'}
              </ArcText>
            </ArcView>
          </ArcView>

          <ArcView spacer />

          <ArcView row>
            <ArcButton
              fullHeight
              size="small"
              isSpaced
              style={shareButtonStyle}
              onClick={handleCancel}
              disabled={this.isRequesting}
            >
              <ArcText isSmaller>{'Cancel'}</ArcText>
            </ArcButton>
            <ArcLoaderButton
              type="submit"
              color="primary"
              variant="flat"
              onClick={handleSubmit}
              disabled={this.shareButtonDisabled}
              fullHeight
              size="small"
              style={shareButtonStyle}
              loadingState={this.props.requestStatus[this.currentRequestStatus]}
              label={this.props.currentlyEditing ? 'Save' : 'Share'}
            />
          </ArcView>
        </Controls>
      </ControlsWrapper>
    );
  };

  render() {
    const {
      attachedVideos,
      canPostToProtectedAudiences,
      currentlyEditing,
      uploadManagerOpened,
      userTeamMembers,
      canUploadFiles,
      canUploadImages,
      canUploadVideos,
    } = this.props;

    const { audienceIds, attachedFiles } = this.state;

    const isPostingToAnotherNewsfeed = false;

    return (
      <EditorWrapper>
        <SlimEditor
          onAdd={this.handleAdd}
          onRemove={this.handleRemove}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
          onChange={this.handleChange}
          onInputFocused={this.handleInputFocused}
          attachmentSupport
          attachedFiles={[...this.props.attachedFiles, ...attachedFiles]}
          multipleFiles
          submitOnEnter={false}
          taggingRules={{
            prioritiseUsers: userTeamMembers,
            restrictToUsers:
              this.taggableUsers.length > 0 ? this.taggableUsers : [],
          }}
          uploadManagerOpened={uploadManagerOpened}
          attachedVideos={attachedVideos}
          onRemoveMedia={this.handleRemoveMedia}
          renderControls={this.renderControls}
          controlProps={{ audienceIds, currentlyEditing }}
          inputRef={this.inputRef}
          value={currentlyEditing ? currentlyEditing.content : ''}
          currentRequestStatus={
            this.props.requestStatus[this.currentRequestStatus]
          }
          canUploadFiles={canUploadFiles}
          canUploadImages={canUploadImages}
          canUploadVideos={canUploadVideos}
        />

        {this.props.isViewingProtectedAudience &&
          canPostToProtectedAudiences &&
          this.state.inputFocused && (
            <ArcView
              className="animated fadeIn"
              row
              align="center"
              paddingLeft="16"
              marginTop="16"
            >
              <ArcCheckbox
                checked={this.state.isPromotingPost}
                onChange={this.handleChangeIsPromotingPost}
                labelPlacement="end"
                label={this.renderLabel}
              />
            </ArcView>
          )}

        <ArcView display="block" textAlign="center">
          {isPostingToAnotherNewsfeed && (
            <ArcView marginBottom="16">
              <ArcView>
                <Typography variant="caption">
                  {
                    STRINGS[
                      'NEWSFEED/ACTIVITY_EDITOR_POSTING_TO_ANOTHER_NEWSFEED_TEXT_LINE_1'
                    ]
                  }
                </Typography>
              </ArcView>
              <ArcView>
                <Typography variant="caption">
                  {
                    STRINGS[
                      'NEWSFEED/ACTIVITY_EDITOR_POSTING_TO_ANOTHER_NEWSFEED_TEXT_LINE_2'
                    ]
                  }
                </Typography>
              </ArcView>
            </ArcView>
          )}

          {this.props.isViewingProtectedAudience &&
            !canPostToProtectedAudiences && (
              <ArcView marginBottom="16">
                <Typography variant="caption">
                  <ArcText color="danger">
                    {
                      STRINGS[
                        'NEWSFEED/ACTIVITY_EDITOR_PROTECTED_POST_PERMISSION_ERROR'
                      ]
                    }
                  </ArcText>
                </Typography>
              </ArcView>
            )}
        </ArcView>
      </EditorWrapper>
    );
  }
}

export default ActivityEditor;
