import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import useSWR from 'swr';

import {
  ArcConfirmDialog,
  ArcView,
  ArcText,
  ArcSelect,
  ArcMainView,
  ArcViewTitleBar,
  ArcResponsiveDialog,
  createWithStyles,
  ArcButton,
} from 'arcade-frontend-ui';
import ArcResourceSpinner from 'arcade-frontend-ui/src/components/ArcResourceSpinner';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import { getCurrentUserFeatures } from 'arcade-frontend-core/src/reducers/user';
import { apiManageGamesListRequest } from 'arcade-frontend-features/src/actions/manage/games/actions';
import {
  actions as manageGamesActions,
  routes as manageGamesRoutes,
} from 'arcade-frontend-features/src/actions/manage/games';
import { actions as actionsApp } from 'arcade-frontend-core/src/actions';
import { ManageGameCancel } from 'arcade-frontend-features/src/components/manage/games/ManageGameCancel';
import {
  getLocationPayload,
  getLocationType,
  getLocationQuery,
} from 'arcade-frontend-features/src/reducers/location';
import {
  gameFilters,
  getTotalLoot,
  getFilteredGameIds,
} from 'arcade-frontend-features/src/reducers/manage/games/filteredGameIds';
import {
  getManageGamesById,
  getManageGamesIsLoading,
} from 'arcade-frontend-features/src/reducers/manage/games/manageGamesById';
import { getManageGamesIsFetching } from 'arcade-frontend-features/src/reducers/manage/games/isFetching';
import { getExtraGameDataById } from 'arcade-frontend-features/src/reducers/manage/games/extraGameDataById';
import { propTypes as gamePropTypes } from 'arcade-frontend-features/src/resources/games';
import {
  ROUTE_MANAGE_GAMES_SHOW,
  ROUTE_MANAGE_GAMES_EDIT,
  ROUTE_MANAGE_GAMES_CLONE,
  ROUTE_MANAGE_GAMES_CREATE,
} from 'arcade-frontend-core/src/types/routes';
import { routes } from 'arcade-frontend-features/src/actions/manage';
import { ArcFormDateRangePickerContainer } from 'arcade-frontend-widgets/src/containers/ArcFormDateRangePickerContainer';
import { arcadeApiClient } from 'arcade-frontend-core/src/helpers/arcadeApiClient';
import { snakeCase } from 'arcade-frontend-core/src/helpers/snakeCase';

import GamesCloneFormContainer from 'arcade-frontend-games-v2/src/containers/GamesCloneFormContainer';
import GameCardHeader from '../components/GameCardHeader';
import ManageGamesOverview from '../components/ManageGamesOverview/ManageGamesOverview';
import ManageGamesGameList from '../components/ManageGamesGameList/ManageGamesGameList';
import ManageGameDetailsView from '../components/ManageGameDetailsView';
import ManageGamesFormContainer from '../containers/ManageGamesFormContainer';

const styles = {
  Header: theme => ({
    root: {
      backgroundColor: theme.palette.common.white,
      overflowX: 'auto',
      padding: theme.spacing(1),
    },
  }),
  TabSelector: () => ({
    root: {
      alignSelf: 'center',
    },
  }),
  DetailsWrapper: theme => ({
    root: {
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  }),
  ArcFormDateRangePickerContainer: theme => ({
    root: {
      paddingRight: theme.spacing(1),
    },
  }),
  TableContainer: theme => ({
    root: {
      borderWidth: 1,
      borderColor: theme.palette.grey[300],
      borderStyle: 'solid',
      backgroundColor: theme.palette.common.white,
      justifyContent: 'center',
    },
  }),
};

const Header = createWithStyles(styles.Header)(ArcView);
const TabSelector = createWithStyles(styles.TabSelector)(ArcView);
const DetailsWrapper = createWithStyles(styles.DetailsWrapper)(ArcView);
const DateRangeWrapper = createWithStyles(
  styles.ArcFormDateRangePickerContainer,
)(ArcView);
const TableContainer = createWithStyles(styles.TableContainer)(ArcView);

const paperProps = { style: { height: '100%', width: '100%', maxWidth: 800 } };

const menuItems = [
  {
    value: 'overview',
    label: 'Overview',
    title: 'Overview',
  },
  {
    value: 'active',
    label: 'Active',
    title: 'Active Games',
  },
  {
    value: 'upcoming',
    label: 'Upcoming',
    title: 'Upcoming',
  },
  {
    value: 'needs_verification',
    label: 'Needs Verification',
    title: 'Needs Verification',
  },
  {
    value: 'previous',
    label: 'Previous',
    title: 'Previous Games',
  },
];
const filterableTabs = ['previous'];

const categories = {};

menuItems.forEach(({ value, label }) => {
  categories[value] = label;
});

const fetcher = url => arcadeApiClient.get(url).then(({ data }) => data);

function ManageGamesView({
  filters,
  setType,
  goBack,
  gameIds,
  gamesById,
  extraGameDataById,
  getExtraGameData,
  totalLoot,
  hasEscrowFeature,
  isFetching,
  locationPayload,
  locationType,
  routeManageGames,
  routeManageGamesShow,
  routeManageGamesEdit,
  routeManageGamesClone,
  routeManageGamesCreate,
  isLoadingGames,
  onGameCancelAlert,
  onGameCancelAlertError,
}) {
  const canClone = useFeatureFlag(FEATURE_FLAGS.GAME_CLONING);

  const { data: overviewData, isValidating: isLoadingOverview } = useSWR(
    '/manager/v4/campaigns/overview',
    fetcher,
  );

  const isLoading = tab => {
    switch (tab) {
      case 'overview':
        return isLoadingOverview && !overviewData;
      default:
        return isLoadingGames;
    }
  };

  const stats = overviewData
    ? Object.entries(overviewData).map(([key, value]) => ({
        type: key,
        total: value,
      }))
    : [];

  const dispatch = useDispatch();

  const currentTab = useSelector(getLocationQuery).tab || filters.type;

  const [dateRange, setDateRange] = useState(() => {
    const initialDateRange = {
      type: 'time period',
    };

    if (filterableTabs.includes(currentTab)) {
      initialDateRange.timePeriod = 'last_30_days';
    }
    return initialDateRange;
  });

  useEffect(() => {
    setType(currentTab);
  }, [currentTab]);

  useEffect(() => {
    if (currentTab !== 'overview') {
      getExtraGameData({ listType: currentTab, ...dateRange });
    }
  }, [currentTab, dateRange]);

  const [cancelingGameId, setCancelingGameId] = useState(null);
  const [sendNotification, setSendNotification] = useState(false);

  const [cancelConfirmDialogOpen, setCancelConfirmDialogOpen] = useState(false);

  const [closeConfirmDialogOpen, setCloseConfirmDialogOpen] = useState(false);

  const tabLabel =
    menuItems.find(({ value }) => value === currentTab)?.title || '';

  const gotoGameView = () => {
    routeManageGames({}, { tab: currentTab });
  };

  const handleCancelConfirmDialogClose = () => {
    setCancelConfirmDialogOpen(false);
    setSendNotification(false);
    setCancelingGameId(null);
  };
  const cancelGame = async () => {
    try {
      await arcadeApiClient({
        method: 'PUT',
        url: `/manager/campaigns/${cancelingGameId}/cancel?notify=${sendNotification}`,
      });
      if (sendNotification) onGameCancelAlert();
      getExtraGameData({ listType: currentTab, ...dateRange });
      handleCancelConfirmDialogClose();
    } catch (err) {
      onGameCancelAlertError(err);
    }
  };
  const setDisplayGame = (id, mode) => routeManageGamesShow(id, mode);

  const setEditGame = id => routeManageGamesEdit(id, currentTab);

  const setCloneGame = id => routeManageGamesClone(id, currentTab);

  const startCreateGame = () => routeManageGamesCreate(null, currentTab);

  const handleDateRangeChange = ({ type, timePeriod, fromDate, toDate }) => {
    if (type === 'time period') {
      setDateRange({
        type,
        timePeriod,
        fromDate: undefined,
        toDate: undefined,
      });
    } else {
      setDateRange({
        type,
        timePeriod: undefined,
        fromDate,
        toDate,
      });
    }
  };

  const handleDefaultDateRange = tab => {
    if (!filterableTabs.includes(tab)) {
      setDateRange({
        type: 'time period',
        timePeriod: undefined,
        fromDate: undefined,
        toDate: undefined,
      });
    } else if (!filterableTabs.includes(currentTab)) {
      setDateRange({
        type: 'time period',
        timePeriod: 'last_30_days',
        fromDate: undefined,
        toDate: undefined,
      });
    }
  };

  const handleTabChange = ({ target: { value: tab } }) => {
    handleDefaultDateRange(tab);
    dispatch(manageGamesRoutes.routeManageGames({}, { tab }));
  };

  const handleTabClick = tab => {
    handleDefaultDateRange(tab);
    dispatch(manageGamesRoutes.routeManageGames({}, { tab: snakeCase(tab) }));
  };

  const handleCloseEditingDialog = () => setCloseConfirmDialogOpen(true);

  const handleCloseConfirmDialog = () => setCloseConfirmDialogOpen(false);

  const handleConfirmCloseConfirmDialog = () => {
    handleCloseConfirmDialog();
    gotoGameView();
  };

  let currentGameId;
  let displayingGameId;
  let displayingGameMode;

  if (locationType === ROUTE_MANAGE_GAMES_SHOW) {
    displayingGameId = locationPayload.id;
    displayingGameMode = locationPayload.gameDetailsType || 'leaderboard';
  } else if (locationType === ROUTE_MANAGE_GAMES_EDIT) {
    currentGameId = locationPayload.id;
  } else if (locationType === ROUTE_MANAGE_GAMES_CLONE) {
    currentGameId = locationPayload.id;
  }

  const showGameForm =
    !!currentGameId || locationType === ROUTE_MANAGE_GAMES_CREATE;

  const displayingGame = gamesById[displayingGameId];

  const actions = {
    onEditGame: id => {
      setEditGame(id);
    },
    onCloneGame: id => {
      setCloneGame(id);
    },
    onCancelGame: id => {
      setCancelingGameId(id);
      setCancelConfirmDialogOpen(true);
    },
    onShowLeaderboard: id => setDisplayGame(id, 'leaderboard'),
    onShowDetails: id => setDisplayGame(id, 'details'),
    onShowEvents: id => setDisplayGame(id, 'events'),
    onShowVerify: id => setDisplayGame(id, 'verify'),
  };

  const tabContents =
    currentTab === 'overview' ? (
      <ManageGamesOverview
        stats={stats}
        totalLoot={totalLoot}
        isLoading={isLoadingOverview}
        onItemClick={handleTabClick}
      />
    ) : (
      <ManageGamesGameList
        view={currentTab}
        gameIds={gameIds}
        extraGameDataById={extraGameDataById}
        gamesById={gamesById}
        hasEscrowFeature={hasEscrowFeature}
        isFetching={isFetching}
        {...actions}
      />
    );

  const getFormContainer = () => {
    switch (locationType) {
      case ROUTE_MANAGE_GAMES_CLONE:
        return canClone ? (
          <GamesCloneFormContainer
            onCancel={gotoGameView}
            gameId={currentGameId}
          />
        ) : null;
      default:
        return (
          <ManageGamesFormContainer
            onCancel={gotoGameView}
            currentGameId={currentGameId}
          />
        );
    }
  };

  return (
    <ArcView flex={1} fullHeight minWidth="0">
      <ArcResponsiveDialog
        open={showGameForm}
        PaperProps={paperProps}
        onClose={handleCloseEditingDialog}
      >
        {getFormContainer()}
      </ArcResponsiveDialog>

      <ArcConfirmDialog
        title="Close editor?"
        content="You will lose all unsaved information."
        open={closeConfirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleConfirmCloseConfirmDialog}
        cancelLabel="No"
        confirmLabel="Yes"
      />
      <ManageGameCancel
        isChecked={sendNotification}
        setIsChecked={() => setSendNotification(prev => !prev)}
        cancelConfirmDialogOpen={cancelConfirmDialogOpen}
        handleCancelConfirmDialogClose={handleCancelConfirmDialogClose}
        handleCancelConfirmDialogConfirm={cancelGame}
      />

      <ArcMainView
        isViewing
        hasScroll={false}
        hasNoPaddingLeftLaunch
        fullHeight
      >
        <ArcViewTitleBar onClickBack={goBack} title="Manage Games" />
        <Header row>
          <TabSelector row flexGrow={1}>
            <ArcView row align="center" paddingRight={16}>
              <ArcText isLarger>{tabLabel}</ArcText>
            </ArcView>

            <ArcSelect
              variant="outlined"
              onChange={handleTabChange}
              items={menuItems}
              value={currentTab}
              renderValue={() => 'Change View'}
              SelectDisplayProps={{
                style: {
                  padding: 12,
                  paddingRight: 32,
                  fontSize: 12,
                  borderWidth: 0,
                  height: 'auto',
                },
              }}
            />
          </TabSelector>

          {filterableTabs.includes(currentTab) && (
            <DateRangeWrapper>
              <ArcFormDateRangePickerContainer
                value={dateRange}
                onChange={handleDateRangeChange}
              />
            </DateRangeWrapper>
          )}

          <ArcButton
            label={'Create New Game'}
            variant={'outlined'}
            color={'primary'}
            onClick={startCreateGame}
          >
            <AddIcon /> New Game
          </ArcButton>
        </Header>

        <ArcView flexGrow={1} flexShrink={1} paddingTop={16} overflow="hidden">
          {isLoading(currentTab) ? (
            <ArcView fullWidth fullHeight padding={16}>
              <TableContainer fullHeight>
                <ArcResourceSpinner
                  status={{ pending: true }}
                  size={40}
                  align="center"
                />
              </TableContainer>
            </ArcView>
          ) : (
            tabContents
          )}
        </ArcView>
      </ArcMainView>

      {displayingGame && (
        <ArcResponsiveDialog
          open
          onClose={gotoGameView}
          PaperProps={paperProps}
        >
          <GameCardHeader
            {...displayingGame}
            gameType={displayingGame.type}
            onClose={gotoGameView}
          />
          <DetailsWrapper>
            <ManageGameDetailsView
              game={displayingGame}
              mode={displayingGameMode}
              finishedEditing={gotoGameView}
            />
          </DetailsWrapper>
        </ArcResponsiveDialog>
      )}
    </ArcView>
  );
}

ManageGamesView.propTypes = {
  setType: PropTypes.func.isRequired,
  getExtraGameData: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    type: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  gamesById: PropTypes.objectOf(gamePropTypes.game).isRequired,
  extraGameDataById: PropTypes.shape({}).isRequired,
  gameIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalLoot: PropTypes.number.isRequired,
  goBack: PropTypes.func.isRequired,
  hasEscrowFeature: PropTypes.bool,
  isFetching: PropTypes.bool,
  locationPayload: PropTypes.shape({
    id: PropTypes.string,
    gameDetailsType: PropTypes.string,
  }),
  locationType: PropTypes.string,
  routeManageGames: PropTypes.func,
  routeManageGamesShow: PropTypes.func,
  routeManageGamesEdit: PropTypes.func,
  routeManageGamesClone: PropTypes.func,
  routeManageGamesCreate: PropTypes.func,
  onGameCancelAlert: PropTypes.func,
  onGameCancelAlertError: PropTypes.func,
  isLoadingGames: PropTypes.bool,
};

ManageGamesView.defaultProps = {
  hasEscrowFeature: false,
  isFetching: false,
  isLoadingGames: false,
  locationPayload: {},
  locationType: '',
  routeManageGames: global.noop,
  routeManageGamesShow: global.noop,
  routeManageGamesEdit: global.noop,
  routeManageGamesClone: global.noop,
  routeManageGamesCreate: global.noop,
  onGameCancelAlert: global.noop,
  onGameCancelAlertError: global.noop,
};

const getState = (state, props) => ({
  filters: gameFilters(state),
  totalLoot: getTotalLoot(state),
  gamesById: getManageGamesById(state),
  extraGameDataById: getExtraGameDataById(state),
  gameIds: getFilteredGameIds(state),
  hasEscrowFeature: getCurrentUserFeatures(state, FEATURE_FLAGS.GAME_ESCROW),
  isFetching: getManageGamesIsFetching(state),
  locationType: getLocationType(state),
  locationPayload: getLocationPayload(state),
  isLoading: getManageGamesIsLoading(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      setType: manageGamesActions.setManageGamesTypeFilter,
      setPage: manageGamesActions.setManageGamesPageFilter,
      getExtraGameData: apiManageGamesListRequest,
      goBack: routes.routeManage,
      routeManageGames: manageGamesRoutes.routeManageGames,
      routeManageGamesShow: manageGamesRoutes.routeManageGamesShow,
      routeManageGamesEdit: manageGamesRoutes.routeManageGamesEdit,
      routeManageGamesClone: manageGamesRoutes.routeManageGamesClone,
      routeManageGamesCreate: manageGamesRoutes.routeManageGamesCreate,
      onGameCancelAlert: () =>
        actionsApp.setAlert({
          type: 'success',
          title: 'Game Cancelled!',
          content: 'All competitors will be notified',
        }),
      onGameCancelAlertError: errContent =>
        actionsApp.setAlert({
          type: 'error',
          title: 'Oops',
          content: errContent,
        }),
    },
    dispatch,
  );

export default connect(getState, getActions)(ManageGamesView);
