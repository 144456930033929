/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import ArcStyles from '../../styles';

export const LetterA = () => (
  <g id="A" fill={ArcStyles.theme.gray8}>
    <g id="six" transform="translate(22)">
      <path id="Path" d="M2.232 8.909c-.917 0-1.375-1.485-1.375-4.455C.857 1.484 1.315 0 2.232 0h31.45c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.455-1.46 4.455H2.232z"/>
    </g>
    <g id="five" transform="translate(11 11)">
      <path id="Path" d="M1.803 9.757C.887 9.757.43 8.272.43 5.303c0-2.97.458-4.454 1.374-4.454h20.022c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.803z"/>
      <path id="Path" d="M36.09 9.757c-.917 0-1.376-1.485-1.376-4.454 0-2.97.459-4.454 1.375-4.454h20.022c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H36.089z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 9.606c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513.697 80 2.182 80 5.151c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.454c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.546 80 2.03 80 5c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h77.164C79.513.394 80 1.88 80 4.85c0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.151c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.243 80 1.728 80 4.697c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.091 80 1.576 80 4.546 80 7.516 79.513 9 78.54 9H58.517z"/>
    </g>
  </g>
);
export const LetterB = () => (
  <g id="B" fill={ArcStyles.theme.gray8}>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0H67.11c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.455-1.46 4.455H1.375z"/>
    </g>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.757c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.849 80 2.333 80 5.303c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 9.606c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513.697 80 2.182 80 5.151c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546H67.11c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.303c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.394 80 1.88 80 4.85c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.151c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.243 80 1.728 80 4.697c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09H67.11c.974 0 1.46 1.485 1.46 4.455 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
    </g>
  </g>
);
export const LetterC = () => (
  <g id="C" fill={ArcStyles.theme.gray8}>
    <g id="six" transform="translate(22)">
      <path id="Path" d="M2.232 8.909c-.917 0-1.375-1.485-1.375-4.455C.857 1.484 1.315 0 2.232 0h42.879c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.455-1.46 4.455H2.232z"/>
    </g>
    <g id="five" transform="translate(11 11)">
      <path id="Path" d="M1.803 9.757C.887 9.757.43 8.272.43 5.303c0-2.97.458-4.454 1.374-4.454h20.022c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.803z"/>
      <path id="Path" d="M47.518 9.757c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C68.513.849 69 2.333 69 5.303c0 2.97-.487 4.454-1.46 4.454H47.517z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="one" transform="translate(11 59)">
      <path id="Path" d="M1.803 9.151C.887 9.151.43 7.667.43 4.697c0-2.97.458-4.454 1.374-4.454h20.022c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.803z"/>
      <path id="Path" d="M47.518 9.151c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C68.513.243 69 1.728 69 4.697c0 2.97-.487 4.454-1.46 4.454H47.517z"/>
    </g>
    <g id="zero" transform="translate(22 71)">
      <path id="Path" d="M2.232 9C1.315 9 .857 7.515.857 4.546c0-2.97.458-4.455 1.375-4.455h42.879c.974 0 1.46 1.485 1.46 4.455 0 2.97-.486 4.454-1.46 4.454H2.232z"/>
    </g>
  </g>
);
export const LetterD = () => (
  <g id="D" fill={ArcStyles.theme.gray8}>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0h54.307c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.455-1.46 4.455H1.375z"/>
    </g>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M47.09 9.757c-.917 0-1.376-1.485-1.376-4.454 0-2.97.459-4.454 1.375-4.454h20.022c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H47.089z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 9.606c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513.697 80 2.182 80 5.151c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.454c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.546 80 2.03 80 5c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.303c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.394 80 1.88 80 4.85c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M47.09 9.151c-.917 0-1.376-1.484-1.376-4.454 0-2.97.459-4.454 1.375-4.454h20.022c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H47.089z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h54.307c.974 0 1.46 1.485 1.46 4.455 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
    </g>
  </g>
);
export const LetterE = () => (
  <g id="E" fill={ArcStyles.theme.gray8}>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0h77.164C79.513 0 80 1.485 80 4.454c0 2.97-.487 4.455-1.46 4.455H1.374z"/>
    </g>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546H67.11c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h77.164C79.513.091 80 1.576 80 4.546 80 7.516 79.513 9 78.54 9H1.374z"/>
    </g>
  </g>
);
export const LetterF = () => (
  <g id="F" fill={ArcStyles.theme.gray8}>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0h77.164C79.513 0 80 1.485 80 4.454c0 2.97-.487 4.455-1.46 4.455H1.374z"/>
    </g>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546H67.11c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
  </g>
);
export const LetterG = () => (
  <g id="G" fill={ArcStyles.theme.gray8}>
    <g id="six" transform="translate(22)">
      <path id="Path" d="M2.232 8.909c-.917 0-1.375-1.485-1.375-4.455C.857 1.484 1.315 0 2.232 0h54.307C57.513 0 58 1.485 58 4.454c0 2.97-.487 4.455-1.46 4.455H2.231z"/>
    </g>
    <g id="five" transform="translate(11 11)">
      <path id="Path" d="M1.803 9.757C.887 9.757.43 8.272.43 5.303c0-2.97.458-4.454 1.374-4.454h20.022c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.803z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M47.09 9.454c-.917 0-1.376-1.484-1.376-4.454 0-2.97.459-4.454 1.375-4.454h31.45C79.513.546 80 2.03 80 5c0 2.97-.487 4.454-1.46 4.454H47.09z"/>
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.303c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.394 80 1.88 80 4.85c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="one" transform="translate(11 59)">
      <path id="Path" d="M1.803 9.151C.887 9.151.43 7.667.43 4.697c0-2.97.458-4.454 1.374-4.454h20.022c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.803z"/>
      <path id="Path" d="M47.518 9.151c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C68.513.243 69 1.728 69 4.697c0 2.97-.487 4.454-1.46 4.454H47.517z"/>
    </g>
    <g id="zero" transform="translate(22 71)">
      <path id="Path" d="M2.232 9C1.315 9 .857 7.515.857 4.546c0-2.97.458-4.455 1.375-4.455h54.307C57.513.091 58 1.576 58 4.546 58 7.516 57.513 9 56.54 9H2.231z"/>
    </g>
  </g>
);
export const LetterH = () => (
  <g id="H" fill={ArcStyles.theme.gray8}>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 8.909c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513 0 80 1.485 80 4.454c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.757c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.849 80 2.333 80 5.303c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 9.606c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513.697 80 2.182 80 5.151c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h77.164C79.513.546 80 2.03 80 5c0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.303c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.394 80 1.88 80 4.85c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.151c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.243 80 1.728 80 4.697c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.091 80 1.576 80 4.546 80 7.516 79.513 9 78.54 9H58.517z"/>
    </g>
  </g>
);
export const LetterI = () => (
  <g id="I" fill={ArcStyles.theme.gray8}>
    <g id="six">
      <path id="Path" d="M1.604 8.909C.534 8.909 0 7.424 0 4.454 0 1.484.535 0 1.604 0h76.692C79.432 0 80 1.485 80 4.454c0 2.97-.568 4.455-1.704 4.455H1.604z"/>
    </g>
    <g id="five" transform="translate(26 11)">
      <path id="Path" d="M2.27 9.757C1.202 9.757.668 8.272.668 5.303.667 2.333 1.2.849 2.27.849h23.358c1.136 0 1.704 1.484 1.704 4.454 0 2.97-.568 4.454-1.704 4.454H2.271z"/>
    </g>
    <g id="four" transform="translate(26 23)">
      <path id="Path" d="M2.27 9.606C1.202 9.606.668 8.12.668 5.15.667 2.181 1.2.697 2.27.697h23.358c1.136 0 1.704 1.485 1.704 4.454 0 2.97-.568 4.455-1.704 4.455H2.271z"/>
    </g>
    <g id="three" transform="translate(26 35)">
      <path id="Path" d="M2.27 9.454C1.202 9.454.668 7.97.668 5 .667 2.03 1.2.546 2.27.546h23.358c1.136 0 1.704 1.484 1.704 4.454 0 2.97-.568 4.454-1.704 4.454H2.271z"/>
    </g>
    <g id="two" transform="translate(26 47)">
      <path id="Path" d="M2.27 9.303C1.202 9.303.668 7.818.668 4.849.667 1.879 1.2.394 2.27.394h23.358c1.136 0 1.704 1.485 1.704 4.455 0 2.97-.568 4.454-1.704 4.454H2.271z"/>
    </g>
    <g id="one" transform="translate(26 59)">
      <path id="Path" d="M2.27 9.151C1.202 9.151.668 7.667.668 4.697.667 1.727 1.2.243 2.27.243h23.358c1.136 0 1.704 1.485 1.704 4.454 0 2.97-.568 4.454-1.704 4.454H2.271z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.604 9C.534 9 0 7.515 0 4.546 0 1.576.535.09 1.604.09h76.692C79.432.091 80 1.576 80 4.546 80 7.516 79.432 9 78.296 9H1.604z"/>
    </g>
  </g>
);
export const LetterJ = () => (
  <g id="J" fill={ArcStyles.theme.gray8}>
    <g id="six" transform="translate(57)">
      <path id="Path" d="M1.518 8.909C.6 8.909.143 7.424.143 4.454.143 1.484.6 0 1.518 0h20.021C22.513 0 23 1.485 23 4.454c0 2.97-.487 4.455-1.46 4.455H1.517z"/>
    </g>
    <g id="five" transform="translate(57 11)">
      <path id="Path" d="M1.518 9.757C.6 9.757.143 8.272.143 5.303.143 2.333.6.849 1.518.849h20.021C22.513.849 23 2.333 23 5.303c0 2.97-.487 4.454-1.46 4.454H1.517z"/>
    </g>
    <g id="four" transform="translate(57 23)">
      <path id="Path" d="M1.518 9.606C.6 9.606.143 8.12.143 5.15.143 2.181.6.697 1.518.697h20.021C22.513.697 23 2.182 23 5.151c0 2.97-.487 4.455-1.46 4.455H1.517z"/>
    </g>
    <g id="three" transform="translate(57 35)">
      <path id="Path" d="M1.518 9.454C.6 9.454.143 7.97.143 5 .143 2.03.6.546 1.518.546h20.021C22.513.546 23 2.03 23 5c0 2.97-.487 4.454-1.46 4.454H1.517z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.303c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.394 80 1.88 80 4.85c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.151c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.243 80 1.728 80 4.697c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="zero" transform="translate(11 71)">
      <path id="Path" d="M1.803 9C.887 9 .43 7.515.43 4.546.429 1.576.887.09 1.803.09h54.308c.974 0 1.46 1.485 1.46 4.455 0 2.97-.486 4.454-1.46 4.454H1.803z"/>
    </g>
  </g>
);
export const LetterK = () => (
  <g id="K" fill={ArcStyles.theme.gray8}>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 8.909c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513 0 80 1.485 80 4.454c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M47.09 9.757c-.917 0-1.376-1.485-1.376-4.454 0-2.97.459-4.454 1.375-4.454h20.022c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H47.089z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M35.66 9.606c-.916 0-1.374-1.485-1.374-4.455 0-2.97.458-4.454 1.375-4.454h20.021c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.455-1.46 4.455H35.661z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h42.878c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.373z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h54.307c.974 0 1.46 1.485 1.46 4.455 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M35.66 9.151c-.916 0-1.374-1.484-1.374-4.454 0-2.97.458-4.454 1.375-4.454h31.45c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H35.66z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M47.09 9c-.917 0-1.376-1.485-1.376-4.454 0-2.97.459-4.455 1.375-4.455h31.45C79.513.091 80 1.576 80 4.546 80 7.516 79.513 9 78.54 9H47.09z"/>
    </g>
  </g>
);
export const LetterL = () => (
  <g id="L" fill={ArcStyles.theme.gray8}>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
    </g>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h77.164C79.513.091 80 1.576 80 4.546 80 7.516 79.513 9 78.54 9H1.374z"/>
    </g>
  </g>
);
export const LetterM = () => (
  <g id="M" fill={ArcStyles.theme.gray8}>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 8.909c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513 0 80 1.485 80 4.454c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h31.45c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
      <path id="Path" d="M47.09 9.757c-.917 0-1.376-1.485-1.376-4.454 0-2.97.459-4.454 1.375-4.454h31.45C79.513.849 80 2.333 80 5.303c0 2.97-.487 4.454-1.46 4.454H47.09z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h77.164C79.513.697 80 2.182 80 5.151c0 2.97-.487 4.455-1.46 4.455H1.374z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h77.164C79.513.546 80 2.03 80 5c0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.303c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.394 80 1.88 80 4.85c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
      <path id="Path" d="M35.66 9.303c-.916 0-1.374-1.485-1.374-4.454 0-2.97.458-4.455 1.375-4.455h8.592c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H35.66z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.151c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.243 80 1.728 80 4.697c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.091 80 1.576 80 4.546 80 7.516 79.513 9 78.54 9H58.517z"/>
    </g>
  </g>
);
export const LetterN = () => (
  <g id="N" fill={ArcStyles.theme.gray8}>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h31.45c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
      <path id="Path" d="M58.518 9.757c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.849 80 2.333 80 5.303c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M35.66 9.303c-.916 0-1.374-1.485-1.374-4.454 0-2.97.458-4.455 1.375-4.455h42.878C79.513.394 80 1.88 80 4.85c0 2.97-.487 4.454-1.46 4.454H35.66z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M47.09 9.151c-.917 0-1.376-1.484-1.376-4.454 0-2.97.459-4.454 1.375-4.454h31.45C79.513.243 80 1.728 80 4.697c0 2.97-.487 4.454-1.46 4.454H47.09z"/>
    </g>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 8.909c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513 0 80 1.485 80 4.454c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.091 80 1.576 80 4.546 80 7.516 79.513 9 78.54 9H58.517z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h77.164C79.513.546 80 2.03 80 5c0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h42.878c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.373z"/>
      <path id="Path" d="M58.518 9.606c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513.697 80 2.182 80 5.151c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
  </g>
);
export const LetterO = () => (
  <g id="O" fill={ArcStyles.theme.gray8}>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.757c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.849 80 2.333 80 5.303c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="six" transform="translate(11)">
      <path id="Path" d="M1.803 8.909C.887 8.909.43 7.424.43 4.454.429 1.484.887 0 1.803 0h54.308c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.455-1.46 4.455H1.803z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 9.606c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513.697 80 2.182 80 5.151c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.303c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.394 80 1.88 80 4.85c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.151c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.243 80 1.728 80 4.697c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="zero" transform="translate(11 71)">
      <path id="Path" d="M1.803 9C.887 9 .43 7.515.43 4.546.429 1.576.887.09 1.803.09h54.308c.974 0 1.46 1.485 1.46 4.455 0 2.97-.486 4.454-1.46 4.454H1.803z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.454c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.546 80 2.03 80 5c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
  </g>
);
export const LetterP = () => (
  <g id="P" fill={ArcStyles.theme.gray8}>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.757c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.849 80 2.333 80 5.303c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0H67.11c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.455-1.46 4.455H1.375z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 9.606c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513.697 80 2.182 80 5.151c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394H67.11c.974 0 1.46 1.485 1.46 4.455 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.454c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.546 80 2.03 80 5c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
  </g>
);
export const LetterQ = () => (
  <g id="Q" fill={ArcStyles.theme.gray8}>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.757c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.849 80 2.333 80 5.303c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="six" transform="translate(11)">
      <path id="Path" d="M1.803 8.909C.887 8.909.43 7.424.43 4.454.429 1.484.887 0 1.803 0h54.308c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.455-1.46 4.455H1.803z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 9.606c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513.697 80 2.182 80 5.151c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M35.66 9.303c-.916 0-1.374-1.485-1.374-4.454 0-2.97.458-4.455 1.375-4.455h42.878C79.513.394 80 1.88 80 4.85c0 2.97-.487 4.454-1.46 4.454H35.66z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M47.09 9.151c-.917 0-1.376-1.484-1.376-4.454 0-2.97.459-4.454 1.375-4.454h20.022c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H47.089z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.454c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.546 80 2.03 80 5c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="zero" transform="translate(11 71)">
      <path id="Path" d="M1.803 9C.887 9 .43 7.515.43 4.546.429 1.576.887.09 1.803.09h42.88c.973 0 1.46 1.485 1.46 4.455 0 2.97-.487 4.454-1.46 4.454H1.802z"/>
      <path id="Path" d="M58.946 9c-.916 0-1.375-1.485-1.375-4.454 0-2.97.459-4.455 1.375-4.455h8.593C68.513.091 69 1.576 69 4.546 69 7.516 68.513 9 67.54 9h-8.594z"/>
    </g>
  </g>
);
export const LetterR = () => (
  <g id="R" fill={ArcStyles.theme.gray8}>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.757c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.849 80 2.333 80 5.303c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0H67.11c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.455-1.46 4.455H1.375z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 9.606c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513.697 80 2.182 80 5.151c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h54.307c.974 0 1.46 1.485 1.46 4.455 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M35.66 9.151c-.916 0-1.374-1.484-1.374-4.454 0-2.97.458-4.454 1.375-4.454h31.45c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H35.66z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M47.09 9.454c-.917 0-1.376-1.484-1.376-4.454 0-2.97.459-4.454 1.375-4.454h31.45C79.513.546 80 2.03 80 5c0 2.97-.487 4.454-1.46 4.454H47.09z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M47.09 9c-.917 0-1.376-1.485-1.376-4.454 0-2.97.459-4.455 1.375-4.455h31.45C79.513.091 80 1.576 80 4.546 80 7.516 79.513 9 78.54 9H47.09z"/>
    </g>
  </g>
);
export const LetterS = () => (
  <g id="S" fill={ArcStyles.theme.gray8}>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M47.09 9.757c-.917 0-1.376-1.485-1.376-4.454 0-2.97.459-4.454 1.375-4.454h20.022c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H47.089z"/>
    </g>
    <g id="six" transform="translate(11)">
      <path id="Path" d="M1.803 8.909C.887 8.909.43 7.424.43 4.454.429 1.484.887 0 1.803 0h42.88c.973 0 1.46 1.485 1.46 4.454 0 2.97-.487 4.455-1.46 4.455H1.802z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
    </g>
    <g id="two" transform="translate(57 47)">
      <path id="Path" d="M1.518 9.303C.6 9.303.143 7.818.143 4.849.143 1.879.6.394 1.518.394h20.021C22.513.394 23 1.88 23 4.85c0 2.97-.487 4.454-1.46 4.454H1.517z"/>
    </g>
    <g id="three" transform="translate(11 35)">
      <path id="Path" d="M1.803 9.454C.887 9.454.43 7.97.43 5 .429 2.03.887.546 1.803.546h54.308c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.803z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.151c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.243 80 1.728 80 4.697c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="zero" transform="translate(11 71)">
      <path id="Path" d="M1.803 9C.887 9 .43 7.515.43 4.546.429 1.576.887.09 1.803.09h54.308c.974 0 1.46 1.485 1.46 4.455 0 2.97-.486 4.454-1.46 4.454H1.803z"/>
    </g>
  </g>
);
export const LetterT = () => (
  <g id="T" fill={ArcStyles.theme.gray8}>
    <g id="five" transform="translate(26 11)">
      <path id="Path" d="M2.27 9.757C1.202 9.757.668 8.272.668 5.303.667 2.333 1.2.849 2.27.849h23.358c1.136 0 1.704 1.484 1.704 4.454 0 2.97-.568 4.454-1.704 4.454H2.271z"/>
    </g>
    <g id="six">
      <path id="Path" d="M1.604 8.909C.534 8.909 0 7.424 0 4.454 0 1.484.535 0 1.604 0h76.692C79.432 0 80 1.485 80 4.454c0 2.97-.568 4.455-1.704 4.455H1.604z"/>
    </g>
    <g id="four" transform="translate(26 23)">
      <path id="Path" d="M2.27 9.606C1.202 9.606.668 8.12.668 5.15.667 2.181 1.2.697 2.27.697h23.358c1.136 0 1.704 1.485 1.704 4.454 0 2.97-.568 4.455-1.704 4.455H2.271z"/>
    </g>
    <g id="one" transform="translate(26 59)">
      <path id="Path" d="M2.27 9.151C1.202 9.151.668 7.667.668 4.697.667 1.727 1.2.243 2.27.243h23.358c1.136 0 1.704 1.485 1.704 4.454 0 2.97-.568 4.454-1.704 4.454H2.271z"/>
    </g>
    <g id="zero" transform="translate(26 71)">
      <path id="Path" d="M2.27 9C1.202 9 .668 7.515.668 4.546.667 1.576 1.2.09 2.27.09h23.358c1.136 0 1.704 1.485 1.704 4.455 0 2.97-.568 4.454-1.704 4.454H2.271z"/>
    </g>
    <g id="three" transform="translate(26 35)">
      <path id="Path" d="M2.27 9.454C1.202 9.454.668 7.97.668 5 .667 2.03 1.2.546 2.27.546h23.358c1.136 0 1.704 1.484 1.704 4.454 0 2.97-.568 4.454-1.704 4.454H2.271z"/>
    </g>
    <g id="two" transform="translate(26 47)">
      <path id="Path" d="M2.27 9.303C1.202 9.303.668 7.818.668 4.849.667 1.879 1.2.394 2.27.394h23.358c1.136 0 1.704 1.485 1.704 4.455 0 2.97-.568 4.454-1.704 4.454H2.271z"/>
    </g>
  </g>
);
export const LetterU = () => (
  <g id="U" fill={ArcStyles.theme.gray8}>
    <g id="zero" transform="translate(11 71)">
      <path id="Path" d="M1.803 9C.887 9 .43 7.515.43 4.546.429 1.576.887.09 1.803.09h54.308c.974 0 1.46 1.485 1.46 4.455 0 2.97-.486 4.454-1.46 4.454H1.803z"/>
    </g>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 8.909c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513 0 80 1.485 80 4.454c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.757c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.849 80 2.333 80 5.303c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.303c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.394 80 1.88 80 4.85c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.151c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.243 80 1.728 80 4.697c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 9.606c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513.697 80 2.182 80 5.151c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.454c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.546 80 2.03 80 5c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
  </g>
);
export const LetterV = () => (
  <g id="V" fill={ArcStyles.theme.gray8}>
    <g id="zero" transform="translate(34 71)">
      <path id="Path" d="M1.66 9C.745 9 .287 7.515.287 4.546.286 1.576.744.09 1.66.09h8.592c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.66z"/>
    </g>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 8.909c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513 0 80 1.485 80 4.454c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.757c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.849 80 2.333 80 5.303c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="two" transform="translate(11 47)">
      <path id="Path" d="M1.803 9.303C.887 9.303.43 7.818.43 4.849c0-2.97.458-4.455 1.374-4.455h54.308c.974 0 1.46 1.485 1.46 4.455 0 2.97-.486 4.454-1.46 4.454H1.803z"/>
    </g>
    <g id="one" transform="translate(22 59)">
      <path id="Path" d="M2.232 9.151c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h31.45c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H2.232z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 9.606c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513.697 80 2.182 80 5.151c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h31.45c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
      <path id="Path" d="M47.09 9.454c-.917 0-1.376-1.484-1.376-4.454 0-2.97.459-4.454 1.375-4.454h31.45C79.513.546 80 2.03 80 5c0 2.97-.487 4.454-1.46 4.454H47.09z"/>
    </g>
  </g>
);
export const LetterW = () => (
  <g id="W" fill={ArcStyles.theme.gray8}>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 8.909c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513 0 80 1.485 80 4.454c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.757c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.849 80 2.333 80 5.303c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="two" transform="translate(0 47)">
      <path id="Path" d="M1.375 9.303C.458 9.303 0 7.818 0 4.849 0 1.879.458.394 1.375.394h77.164C79.513.394 80 1.88 80 4.85c0 2.97-.487 4.454-1.46 4.454H1.374z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.375 9.606C.458 9.606 0 8.12 0 5.15 0 2.181.458.697 1.375.697h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 9.606c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513.697 80 2.182 80 5.151c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="three" transform="translate(0 35)">
      <path id="Path" d="M35.66 9.454c-.916 0-1.374-1.484-1.374-4.454 0-2.97.458-4.454 1.375-4.454h8.592c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H35.66z"/>
      <path id="Path" d="M1.375 9.454C.458 9.454 0 7.97 0 5 0 2.03.458.546 1.375.546h20.021c.974 0 1.461 1.484 1.461 4.454 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9.454c-.917 0-1.375-1.484-1.375-4.454 0-2.97.458-4.454 1.375-4.454h20.021C79.513.546 80 2.03 80 5c0 2.97-.487 4.454-1.46 4.454H58.517z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h31.45c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
      <path id="Path" d="M47.09 9.151c-.917 0-1.376-1.484-1.376-4.454 0-2.97.459-4.454 1.375-4.454h31.45C79.513.243 80 1.728 80 4.697c0 2.97-.487 4.454-1.46 4.454H47.09z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.091 80 1.576 80 4.546 80 7.516 79.513 9 78.54 9H58.517z"/>
    </g>
  </g>
);
export const LetterX = () => (
  <g id="X" fill={ArcStyles.theme.gray8}>
    <g id="four" transform="translate(11 23)">
      <path id="Path" d="M1.803 9.606C.887 9.606.43 8.12.43 5.15c0-2.97.458-4.454 1.374-4.454h54.308c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.455-1.46 4.455H1.803z"/>
    </g>
    <g id="three" transform="translate(22 35)">
      <path id="Path" d="M2.232 9.454C1.315 9.454.857 7.97.857 5c0-2.97.458-4.454 1.375-4.454h31.45c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H2.232z"/>
    </g>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0h20.021c.974 0 1.461 1.485 1.461 4.454 0 2.97-.487 4.455-1.46 4.455H1.374z"/>
      <path id="Path" d="M58.518 8.909c-.917 0-1.375-1.485-1.375-4.455 0-2.97.458-4.454 1.375-4.454h20.021C79.513 0 80 1.485 80 4.454c0 2.97-.487 4.455-1.46 4.455H58.517z"/>
    </g>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.375 9.757C.458 9.757 0 8.272 0 5.303 0 2.333.458.849 1.375.849h31.45c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
      <path id="Path" d="M47.09 9.757c-.917 0-1.376-1.485-1.376-4.454 0-2.97.459-4.454 1.375-4.454h31.45C79.513.849 80 2.333 80 5.303c0 2.97-.487 4.454-1.46 4.454H47.09z"/>
    </g>
    <g id="two" transform="translate(11 47)">
      <path id="Path" d="M1.803 9.303C.887 9.303.43 7.818.43 4.849c0-2.97.458-4.455 1.374-4.455h54.308c.974 0 1.46 1.485 1.46 4.455 0 2.97-.486 4.454-1.46 4.454H1.803z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h31.45c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
      <path id="Path" d="M47.09 9.151c-.917 0-1.376-1.484-1.376-4.454 0-2.97.459-4.454 1.375-4.454h31.45C79.513.243 80 1.728 80 4.697c0 2.97-.487 4.454-1.46 4.454H47.09z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h20.021c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.374z"/>
      <path id="Path" d="M58.518 9c-.917 0-1.375-1.485-1.375-4.454 0-2.97.458-4.455 1.375-4.455h20.021C79.513.091 80 1.576 80 4.546 80 7.516 79.513 9 78.54 9H58.517z"/>
    </g>
  </g>
);
export const LetterY = () => (
  <g id="Y" fill={ArcStyles.theme.gray8}>
    <g id="three" transform="translate(13 35)">
      <path id="Path" d="M1.937 9.454C.868 9.454.333 7.97.333 5 .333 2.03.868.546 1.937.546h50.025C53.1.546 53.667 2.03 53.667 5c0 2.97-.568 4.454-1.705 4.454H1.937z"/>
    </g>
    <g id="six">
      <path id="Path" d="M1.604 8.909C.534 8.909 0 7.424 0 4.454 0 1.484.535 0 1.604 0h23.358c1.137 0 1.705 1.485 1.705 4.454 0 2.97-.568 4.455-1.705 4.455H1.604z"/>
      <path id="Path" d="M54.937 8.909c-1.069 0-1.604-1.485-1.604-4.455 0-2.97.535-4.454 1.604-4.454h23.359C79.432 0 80 1.485 80 4.454c0 2.97-.568 4.455-1.704 4.455H54.937z"/>
    </g>
    <g id="five" transform="translate(0 11)">
      <path id="Path" d="M1.604 9.757C.534 9.757 0 8.272 0 5.303 0 2.333.535.849 1.604.849h23.358c1.137 0 1.705 1.484 1.705 4.454 0 2.97-.568 4.454-1.705 4.454H1.604z"/>
      <path id="Path" d="M54.937 9.757c-1.069 0-1.604-1.485-1.604-4.454 0-2.97.535-4.454 1.604-4.454h23.359C79.432.849 80 2.333 80 5.303c0 2.97-.568 4.454-1.704 4.454H54.937z"/>
    </g>
    <g id="two" transform="translate(26 47)">
      <path id="Path" d="M2.27 9.303C1.202 9.303.668 7.818.668 4.849.667 1.879 1.2.394 2.27.394h23.358c1.136 0 1.704 1.485 1.704 4.455 0 2.97-.568 4.454-1.704 4.454H2.271z"/>
    </g>
    <g id="one" transform="translate(26 59)">
      <path id="Path" d="M2.27 9.151C1.202 9.151.668 7.667.668 4.697.667 1.727 1.2.243 2.27.243h23.358c1.136 0 1.704 1.485 1.704 4.454 0 2.97-.568 4.454-1.704 4.454H2.271z"/>
    </g>
    <g id="zero" transform="translate(26 71)">
      <path id="Path" d="M2.27 9C1.202 9 .668 7.515.668 4.546.667 1.576 1.2.09 2.27.09h23.358c1.136 0 1.704 1.485 1.704 4.455 0 2.97-.568 4.454-1.704 4.454H2.271z"/>
    </g>
    <g id="four" transform="translate(0 23)">
      <path id="Path" d="M1.604 9.606C.534 9.606 0 8.12 0 5.15 0 2.181.535.697 1.604.697h23.358c1.137 0 1.705 1.485 1.705 4.454 0 2.97-.568 4.455-1.705 4.455H1.604z"/>
      <path id="Path" d="M54.937 9.606c-1.069 0-1.604-1.485-1.604-4.455 0-2.97.535-4.454 1.604-4.454h23.359C79.432.697 80 2.182 80 5.151c0 2.97-.568 4.455-1.704 4.455H54.937z"/>
    </g>
  </g>
);
export const LetterZ = () => (
  <g id="Z" fill={ArcStyles.theme.gray8}>
    <g id="six">
      <path id="Path" d="M1.375 8.909C.458 8.909 0 7.424 0 4.454 0 1.484.458 0 1.375 0h77.164C79.513 0 80 1.485 80 4.454c0 2.97-.487 4.455-1.46 4.455H1.374z"/>
    </g>
    <g id="three" transform="translate(22 35)">
      <path id="Path" d="M2.232 9.454C1.315 9.454.857 7.97.857 5c0-2.97.458-4.454 1.375-4.454h31.45c.974 0 1.46 1.484 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H2.232z"/>
    </g>
    <g id="five" transform="translate(45 11)">
      <path id="Path" d="M2.09 9.757c-.917 0-1.376-1.485-1.376-4.454 0-2.97.459-4.454 1.375-4.454h31.45C34.513.849 35 2.333 35 5.303c0 2.97-.487 4.454-1.46 4.454H2.09z"/>
    </g>
    <g id="two" transform="translate(11 47)">
      <path id="Path" d="M1.803 9.303C.887 9.303.43 7.818.43 4.849c0-2.97.458-4.455 1.374-4.455h31.45c.974 0 1.461 1.485 1.461 4.455 0 2.97-.487 4.454-1.46 4.454H1.803z"/>
    </g>
    <g id="one" transform="translate(0 59)">
      <path id="Path" d="M1.375 9.151C.458 9.151 0 7.667 0 4.697 0 1.727.458.243 1.375.243h31.45c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.454-1.46 4.454H1.375z"/>
    </g>
    <g id="zero" transform="translate(0 71)">
      <path id="Path" d="M1.375 9C.458 9 0 7.515 0 4.546 0 1.576.458.09 1.375.09h77.164C79.513.091 80 1.576 80 4.546 80 7.516 79.513 9 78.54 9H1.374z"/>
    </g>
    <g id="four" transform="translate(34 23)">
      <path id="Path" d="M1.66 9.606C.745 9.606.287 8.12.287 5.15.286 2.181.744.697 1.66.697h31.45c.974 0 1.46 1.485 1.46 4.454 0 2.97-.486 4.455-1.46 4.455H1.66z"/>
    </g>
  </g>
);
