import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import byId, { getById } from './byId';
import ids, { getIds } from './ids';
import filter from './filter';
import levels from './levels';
import requestStatus from './requestStatus';

const audiences = combineReducers({
  byId,
  ids,
  filter,
  levels,
  requestStatus,
});

export default audiences;

export const getAudiences = createSelector(getById, getIds, (_byId, _ids) =>
  _ids.map(id => _byId[id]),
);
