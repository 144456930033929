import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';

import ArcToken from '../../assets/ArcToken';
import ArcPlaceholder from '../ArcPlaceholder';
import ArcLoaderButton from '../ArcLoaderButton';
import ArcButton from '../../elements/ArcButton';
import ArcDialog from '../../elements/ArcDialog';
import ArcSelect from '../../elements/ArcSelect';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';
import ArcTextV2 from '../ArcTextV2';
import ArcBoxV2 from '../ArcBoxV2';
import ArrowRight from '../../icons/ArrowRight';
import CheckCircle from '../../icons/CheckCircle';
import CautionCircle from '../../icons/CautionCircle';

const paperProps = {
  square: true,
  style: {
    border: '4px solid #f3f3f3',
    margin: 16,
    width: '100%',
    maxWidth: 260,
  },
};

const styleDialogContentText = {
  color: '#353535',
};

const styleMenuItem = {
  height: 'auto',
};

const placeholderValue = (
  <ArcView row align="center">
    <ArcToken />
    <ArcView marginLeft="8">
      <ArcText isSmall>
        <ArcPlaceholder>{'Loading...'}</ArcPlaceholder>
      </ArcText>
    </ArcView>
  </ArcView>
);

const TOKEN_AMOUNTS = [
  {
    value: 10000,
    label: '10,000',
  },
  {
    value: 25000,
    label: '25,000',
  },
  {
    value: 50000,
    label: '50,000',
  },
  {
    value: 100000,
    label: '100,000',
  },
  {
    value: 200000,
    label: '200,000',
  },
  {
    value: 250000,
    label: '250,000',
  },
  {
    value: 300000,
    label: '300,000',
  },
  {
    value: 500000,
    label: '500,000',
  },
];

const DEFAULT_RESPONSE_LABEL = 'Success';

const DEFAULT_ERROR_LABEL = 'Something went wrong';

const LABELS_BY_TOP_UP_RESPONSE = {
  top_up_received: 'Top up received',
};

const LABELS_BY_TOP_UP_ERROR = {
  unable_to_generate_offer: 'Top up failed',
};

class ArcTokenTopUpDialog extends React.PureComponent {
  static propTypes = {
    hasToppedUp: PropTypes.bool,
    isFetching: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onTopUpContinue: PropTypes.func,
    open: PropTypes.bool,
    requestStatus: PropTypes.string,
    tokenAmounts: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    topUpError: PropTypes.string,
    topUpResponse: PropTypes.string,
    topUpUrl: PropTypes.string,
  };

  static defaultProps = {
    hasToppedUp: false,
    isFetching: false,
    onClose: undefined,
    onSubmit: global.noop,
    onTopUpContinue: global.noop,
    open: false,
    requestStatus: undefined,
    tokenAmounts: TOKEN_AMOUNTS,
    topUpError: '',
    topUpResponse: '',
    topUpUrl: '',
  };

  state = {
    amount: 10000,
  };

  get isLoading() {
    return this.props.isFetching || this.props.requestStatus !== 'DEFAULT';
  }

  setAmount = amount => this.setState({ amount });

  handleChangeAmount = e => this.setAmount(e.target.value);

  handleSubmit = () => this.props.onSubmit(this.state);

  renderValue = value => {
    const amount = this.props.tokenAmounts.filter(
      acc => acc.value === value,
    )[0];

    if (amount) {
      return this.renderAmount(amount);
    }

    return placeholderValue;
  };

  renderAmount = amount => (
    <ArcView row align="center">
      <ArcToken />
      <ArcView marginLeft="8">
        <ArcText isSmall>{amount.label}</ArcText>
      </ArcView>
    </ArcView>
  );

  renderItem = amount => (
    <MenuItem key={amount.value} value={amount.value} style={styleMenuItem}>
      {this.renderAmount(amount)}
    </MenuItem>
  );

  renderItems = () => this.props.tokenAmounts.map(this.renderItem);

  renderResponse() {
    if (this.props.topUpUrl) {
      return (
        <ArcButton
          onClick={this.props.onTopUpContinue}
          variant="outlined"
          color="blue"
          fullWidth
        >
          <ArcTextV2 marginRight={2}>{'Continue to payment'}</ArcTextV2>
          <ArrowRight width="16px" height="16px" />
        </ArcButton>
      );
    }

    const { topUpResponse, topUpError } = this.props;

    const hasError = !!topUpError;

    const label = hasError
      ? LABELS_BY_TOP_UP_ERROR[topUpError] || DEFAULT_ERROR_LABEL
      : LABELS_BY_TOP_UP_RESPONSE[topUpResponse] || DEFAULT_RESPONSE_LABEL;

    const currentIcon = hasError ? (
      <CautionCircle color="danger.main" />
    ) : (
      <CheckCircle color="success.main" />
    );

    return (
      <ArcView align="center" fullWidth>
        <ArcBoxV2
          display="flex"
          justifyContent="center"
          width="100%"
          paddingY={2}
          marginBottom={2}
          borderRadius={4}
          bg={hasError ? 'danger.tint' : 'success.tint'}
        >
          {currentIcon}
          <ArcTextV2
            color={hasError ? 'danger.main' : 'success.main'}
            marginLeft={1}
          >
            {label}
          </ArcTextV2>
        </ArcBoxV2>
        <ArcButton
          onClick={this.props.onClose}
          fullWidth
          variant="outlined"
          color="primary"
        >
          {'Close'}
        </ArcButton>
      </ArcView>
    );
  }

  render() {
    return (
      <ArcDialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={this.props.open}
        PaperProps={paperProps}
        onClose={this.props.onClose}
      >
        <DialogTitle id="alert-dialog-title">
          <ArcText style={styleDialogContentText}>
            {'Request More Tokens'}
          </ArcText>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'How many tokens would you like to buy?'}
          </DialogContentText>

          <ArcView marginTop="16">
            <ArcSelect
              disabled={this.isLoading || !!this.props.topUpResponse}
              label="Amount"
              onChange={this.handleChangeAmount}
              renderValue={this.renderValue}
              value={this.state.amount}
              variant="outlined"
            >
              {this.renderItems()}
            </ArcSelect>
          </ArcView>
        </DialogContent>

        <DialogActions>
          {this.props.hasToppedUp ? (
            this.renderResponse()
          ) : (
            <>
              <ArcButton onClick={this.props.onClose}>{'Cancel'}</ArcButton>
              <ArcLoaderButton
                data-testid="token-top-up-submit-button"
                color="secondary"
                disabled={this.isLoading}
                variant="contained"
                onClick={this.handleSubmit}
                label="Continue"
                loadingState={this.props.requestStatus}
              />
            </>
          )}
        </DialogActions>
      </ArcDialog>
    );
  }
}

export default ArcTokenTopUpDialog;
