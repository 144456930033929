import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';
import * as GAME_FORMATS from 'arcade-frontend-core/src/types/game-formats';
import {
  getLocationType,
  getLocationQuery,
} from 'arcade-frontend-features/src/reducers/location';
import { ArcResponsiveDialog } from 'arcade-frontend-ui';
import GamesCreateForm from 'arcade-frontend-features/src/forms/games/GamesCreateForm';

const paperProps = { style: { height: '100%', width: '100%', maxWidth: 800 } };

function GamesNewContainer() {
  const dispatch = useDispatch();
  const locationType = useSelector(getLocationType);
  const locationQuery = useSelector(getLocationQuery);

  const getInitialValues = () => ({
    format: locationQuery.gameType || GAME_FORMATS.RACE,
  });

  const handleClose = () => {
    const routeAction = {
      type: ROUTE_TYPES.ROUTE_GAMES,
      payload: {},
      query: {
        ...locationQuery,
        gameType: undefined,
      },
    };

    dispatch(routeAction);
  };

  return (
    <ArcResponsiveDialog
      open={locationType === ROUTE_TYPES.ROUTE_GAMES_CREATE}
      onClose={handleClose}
      PaperProps={paperProps}
    >
      <GamesCreateForm
        onCancel={handleClose}
        initialValues={getInitialValues()}
        inDialog
      />
    </ArcResponsiveDialog>
  );
}

GamesNewContainer.displayName = 'GamesNewContainer';

export default GamesNewContainer;
