import { Platform } from 'react-primitives';
import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import { createWithStyles } from '../../styles';

const styles = {
  ArcCard: theme => ({
    root: {
      position: 'relative',
      backgroundColor: '#fff',
      flexGrow: 0,
      flexShrink: 0,
      width: '100%',
      boxShadow: '2px 2px 5px #B4B4B4',
      borderRadius: 0,
      minWidth: 280,

      [theme.breakpoints.up('sm')]: {
        flexBasis: 'calc(33% - 16px)',
        width: 'calc(33% - 16px)',
        margin: 8,
        // maxWidth: 350,

        ...Platform.select({
          web: {
            margin: '8px 0',
            flexBasis: 'auto',
          },
        }),
      },

      ...Platform.select({
        web: {
          margin: '8px 0',
          flexBasis: 'auto',
        },
      }),

      [theme.breakpoints.up('md')]: {
        flexBasis: 'calc(50% - 16px)',
        width: 'calc(50% - 16px)',

        ...Platform.select({
          web: {
            margin: 8,
          },
        }),
      },

      [theme.breakpoints.up('xl')]: {
        flexBasis: 'calc(25% - 16px)',
        width: 'calc(25% - 16px)',
      },
    },

    hasDetails: {
      height: '100%',
      width: '100%',
      flexBasis: '100%',
      padding: '16px 8px 8px 8px',
      margin: 0,

      [theme.breakpoints.up('md')]: {
        padding: 8,
      },
    },

    onClick: {
      cursor: 'pointer',

      [theme.breakpoints.up('md')]: {
        transition: 'all 250ms cubic-bezier(.02, .01, .47, 1)',

        '&:hover': {
          transform: 'translate(0, -8px)',
          boxShadow: '0 6px 12px rgba(0, 0, 0, .06)',
        },
      },
    },
  }),
};

const ArcCard = createWithStyles(styles.ArcCard)(ArcView);

ArcCard.propTypes = {
  hasDetails: ArcPropTypes.bool,
  children: ArcPropTypes.children,
  style: ArcPropTypes.style,
};

ArcCard.defaultProps = {
  hasDetails: false,
  children: null,
  style: null,
};

export default ArcCard;
