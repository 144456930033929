const { Buffer } = require('buffer');

export interface User {
  id: string;
  email: string;
  authenticationToken: string;
}

export default function getEncodedUser(user: User): string {
  try {
    if (window?.btoa) {
      return window.btoa(JSON.stringify(user));
    }

    return Buffer.from(JSON.stringify(user), 'utf-8').toString('base64');
  } catch (error) {
    console.error(error);
    return '';
  }
}
