import ArcStyles from '../../styles';

const rules = {
  ArcPeopleListItem: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 8,
    paddingRight: 8,
    borderBottomWidth: 1,
    borderBottomColor: ArcStyles.theme.grey7,
    borderBottomStyle: 'solid',
    color: ArcStyles.theme.grey2,
    flex: '2 0 auto',
  },

  hasNoBorderBottom: {
    borderBottom: 'none',
  },

  isSelectable: {
    cursor: 'pointer',
    userSelect: 'none',

    '&:hover > div': {
      color: ArcStyles.theme.blue3,
    },
  },
};

const styles = (props) => {
  let style = { ...rules.ArcPeopleListItem };

  if (props.isSelectable) {
    style = {
      ...style,
      ...rules.isSelectable,
    };
  }

  if (props.hasNoBorderBottom) {
    style = {
      ...style,
      ...rules.hasNoBorderBottom,
    };
  }

  style = {
    ...style,
    ...props.style,
  };

  return style;
};


export { rules, styles };
