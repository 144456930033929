import React from 'react';
import PropTypes from 'prop-types';

import { ArcView } from 'arcade-frontend-ui';
import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';

import { resources } from '../../resources/games';
import { getGame } from '../../reducers/games';
import GamesGameCard from '../../components/GamesGameCard';

function GamesCardContainer({ gameId }) {
  const apiGetGamesShow = useResourceAction(resources.apiGetGamesShow, {
    getInitialData: getGame(gameId),
  });

  function requestData() {
    apiGetGamesShow
      .requestData({
        resources: [gameId],
      })
      .catch(global.noop);
  }

  React.useEffect(() => {
    if (!gameId) {
      return;
    }

    requestData();
  }, [gameId]);

  const game =
    (apiGetGamesShow.hasResources && apiGetGamesShow.data.resources[0]) || {};

  const GameCardOrPlaceholder =
    apiGetGamesShow.hasResources && !!game && game.id === gameId
      ? GamesGameCard
      : GamesGameCard.Placeholder;

  return (
    <ArcView align="center">
      <GameCardOrPlaceholder
        id={game.id}
        campaignImage={game.campaignImage}
        expiresAt={game.expiresAt}
        startsAt={game.startsAt}
        status={game.status}
        name={game.name}
        type={game.type}
        participantCount={game.participantCount}
        rankings={game.rankings}
        rewards={game.rewards}
        rules={game.rules}
        score={game.currentUserScore}
        finalScore={game.finalScore}
        playerStatus={game.playerStatus}
        winners={game.winners}
        onClick={null}
        hideVerify
        hideMenu
        hideComments
      />
    </ArcView>
  );
}

GamesCardContainer.displayName = 'GamesCardContainerV2';

GamesCardContainer.propTypes = {
  gameId: PropTypes.string,
};

GamesCardContainer.defaultProps = {
  gameId: null,
};

export default GamesCardContainer;
