import React from 'react';

import { ArcPerson } from '../ArcPeopleList';
import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import ArcText from '../../primitives/ArcText';
import { createWithStyles } from '../../styles';

import ArcScoreboard from './ArcScoreboard';

const styleRank = {
  padding: '4px 16px',
  width: 50,
};

const styles = {
  ArcScoreboardListItemView: () => ({
    root: {
      width: 'auto',
      minWidth: 'auto',
      minHeight: 'auto',
      alignItems: 'center',

      backgroundColor: 'transparent',

      '@media (pointer: fine)': {
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
      },
    },

    isOdd: {
      backgroundColor: '#f8f8f8',
      '@media (pointer: fine)': {
        '&:hover': {
          backgroundColor: '#ebebeb',
        },
      },
    },

    isCurrentUser: {
      backgroundColor: '#cfecf6',

      '&$isOdd': {
        backgroundColor: '#cfecf6',
      },

      '@media (pointer: fine)': {
        '&:hover': {
          backgroundColor: '#b4e1f2',
        },
      },
    },
  }),

  RankText: theme => ({
    root: {
      color: theme.palette.grey[600],
      fontSize: 16,
    },

    sizeSmall: {
      fontSize: 12,
    },
  }),
};

const ArcScoreboardListItemView = createWithStyles(styles.ArcScoreboardListItemView)(ArcView);
const RankText = createWithStyles(styles.RankText)(ArcText);


class ArcScoreboardListItem extends React.PureComponent {
  static propTypes = {
    score: ArcPropTypes.number.isRequired,
    rank: ArcPropTypes.number.isRequired,
    imageUrl: ArcPropTypes.string,
    name: ArcPropTypes.string.isRequired,
    isCurrentUser: ArcPropTypes.bool,
    isOdd: ArcPropTypes.bool,
    symbol: ArcPropTypes.string,
    scoreboardColor: ArcPropTypes.string,
    size: ArcPropTypes.string,

  };

  static defaultProps = {

    isCurrentUser: false,
    isOdd: false,
    symbol: undefined,
    scoreboardColor: undefined,
    size: undefined,
    imageUrl: undefined,
  };

  render() {
    const {
      score,
      rank,
      name,
      imageUrl,
      isCurrentUser,
      isOdd,
      symbol,
      size,
    } = this.props;

    return (
      <ArcScoreboardListItemView
        display="table-row"
        className="ArcScoreboardListItem"
        row
        isCurrentUser={isCurrentUser}
        isOdd={isOdd}
      >
        <ArcView
          display="table-cell"
          style={styleRank}
          verticalAlign="middle"
          textAlign="right"
        >
          <RankText size={size}>
            {rank}
          </RankText>
        </ArcView>

        <ArcView
          display="table-cell"
          padding="4"
        >
          <ArcPerson
            name={name}
            imageUrl={imageUrl}
            size={size}
          />
        </ArcView>

        <ArcView
          display="table-cell"
          verticalAlign="middle"
        >
          <ArcScoreboard
            score={score}
            symbol={symbol}
            color={this.props.scoreboardColor}
            numberWidth={14}
            marginRight="8"
            style={{
              maxWidth: 90,
              marginLeft: 'auto',
              justifyContent: 'flex-end',
            }}
          />
        </ArcView>
      </ArcScoreboardListItemView>
    );
  }
}

export default ArcScoreboardListItem;
