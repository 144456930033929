import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ROUTE_NEWSFEED } from 'arcade-frontend-core/src/types/routes';
import { linkToRouteType } from 'arcade-frontend-features/src/routes/helpers';
import {
  getLocationQuery,
  getLocationPayload,
  getLocationType,
} from 'arcade-frontend-features/src/reducers/location';

import { selectors as lastActivitySelectors } from '../../reducers/fetched_last_activity';
import NewsfeedActivitiesContainer from '../../containers/NewsfeedActivitiesContainer';
import NewsfeedActivitiesDialogs from '../../containers/NewsfeedActivitiesDialogs';

class NewsfeedView extends React.Component {
  static propTypes = {
    scrollClassName: PropTypes.string,
    locationPayload: PropTypes.shape({}),
    locationQuery: PropTypes.shape({
      audienceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    locationType: PropTypes.string,
  };

  static defaultProps = {
    scrollClassName: '',
    locationPayload: {},
    locationQuery: {},
    locationType: '',
  };

  componentDidMount() {
    if (window.ngRootScope) {
      window.ngRootScope.$broadcast('arcade:react:newsfeed:ready');
    }
  }

  handleCommentReactionDialogOpen = commentId => {
    linkToRouteType(
      this.props.locationType,
      { ...this.props.locationPayload },
      {
        ...this.props.locationQuery,
        commentId,
        dialog: 'comment-reactions',
      },
    );
  };

  handleDialogClose = dialog => {
    let nextRoute = this.props.locationType;
    let nextPayload = this.props.locationPayload;

    if (dialog === 'activity' || dialog === 'summary') {
      nextRoute = ROUTE_NEWSFEED;
      nextPayload = {};
    }

    linkToRouteType(nextRoute, nextPayload, {
      audienceId: this.props.locationQuery.audienceId,
    });
  };

  handleDialogOpen = (activityId, currentDialog) => {
    linkToRouteType(
      this.props.locationType,
      { ...this.props.locationPayload },
      {
        ...this.props.locationQuery,
        activityId,
        dialog: currentDialog,
      },
    );
  };

  render() {
    return (
      <React.Fragment>
        <NewsfeedActivitiesContainer
          onCommentReactionDialogOpen={this.handleCommentReactionDialogOpen}
          onDialogOpen={this.handleDialogOpen}
          scrollClassName={this.props.scrollClassName}
        />
        <NewsfeedActivitiesDialogs
          onClose={this.handleDialogClose}
          onDialogOpen={this.handleDialogOpen}
          onCommentReactionDialogOpen={this.handleCommentReactionDialogOpen}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  getFetchedLastActivity: lastActivitySelectors.getFetchedLastActivity(state),
  locationPayload: getLocationPayload(state),
  locationQuery: getLocationQuery(state),
  locationType: getLocationType(state),
  ...props,
});

export default connect(mapStateToProps)(NewsfeedView);
