/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

import Clear from '@material-ui/icons/Clear';

import ArcView from '../../primitives/ArcView';
import ArcImage from '../../primitives/ArcImage';
import ArcText from '../../primitives/ArcText';
import ArcIconButton from '../ArcIconButton';

const styleSpacer = {
  flexGrow: 100,
};

const stylePreview = {
  width: 60,
  height: 60,
  marginRight: 16,
};

const styleText = {
  alignSelf: 'center',
  fontSize: '0.6em',
};

const styleLabel = {
  marginTop: 8,
  marginBottom: 8,
};

const styleButton = {
  width: '100%',
};

const DEFAULT_LABEL = 'imageSelect';

class ArcImageSelect extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.node,
    multiple: PropTypes.bool,
    placeholder: PropTypes.node,
    onChange: PropTypes.func,
    onImageSelected: PropTypes.func,
    render: PropTypes.func,
    showPreview: PropTypes.bool,
    value: PropTypes.string,
  };

  static defaultProps = {
    id: '',
    label: 'Image',
    multiple: false,
    placeholder: undefined,
    onChange: global.noop,
    onImageSelected: global.noop,
    render: undefined,
    showPreview: false,
    value: undefined,
  };

  state = {
    src: null,
    file: null,
  };

  componentDidMount() {
    if (this.props.value) {
      this.setSrc(this.props.value);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setSrc(nextProps.value);
    }
  }

  onImageChange = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onload = () => {
      this.props.onImageSelected({
        file,
        imagePreviewUrl: reader.result,
      });

      this.setState({ src: reader.result, file });
      this.props.onChange(reader.result);
    };

    reader.readAsDataURL(file);
  };

  get label() {
    return this.props.label || DEFAULT_LABEL;
  }

  setSrc = src => this.setState({ src });

  clearImage = () => {
    this.props.onChange('');
    this.setSrc(null);
  };

  renderClearButton() {
    return (
      <ArcIconButton
        onClick={this.clearImage}
        disableRipple
      >
        <Clear
          color="disabled"
          fontSize="inherit"
        />
      </ArcIconButton>
    );
  }

  renderImagePreview() {
    return (
      <ArcImage
        src={this.state.src || this.props.placeholder}
        style={stylePreview}
      />
    );
  }

  renderSelected() {
    return (
      <ArcView row>
        {this.props.showPreview && this.renderImagePreview()}
        {this.state.file && <ArcText style={styleText}>{this.state.file.name}</ArcText>}
        <ArcView style={styleSpacer} />
        {this.renderClearButton()}
      </ArcView>
    );
  }

  renderChildren() {
    const { render } = this.props;

    if (render) {
      return render(this.props, this.state);
    }

    return (
      <React.Fragment>
        {!!this.state.src && (
          <FormHelperText style={styleLabel}>
            {this.props.label}
          </FormHelperText>
        )}
        {this.state.src ? this.renderSelected() : (
          <Button component="span" style={styleButton}>
            {'Add Image'}
          </Button>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <ArcView>
        {this.state.src && !this.props.render ? (
          this.renderChildren()
        ) : (
          <label htmlFor={this.props.id || this.label}>
            {this.renderChildren()}
          </label>
        )}
        <input
          accept="image/jpeg, image/png"
          style={{ display: 'none' }}
          id={this.props.id || this.label}
          multiple={this.props.multiple}
          type="file"
          onChange={this.onImageChange}
        />
      </ArcView>
    );
  }
}

export default ArcImageSelect;
