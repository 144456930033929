import { makeApiActions } from 'arcade-frontend-ui';

import * as normalizers from './normalizers';

const getMyUploads = (payload = {}) => ({
  method: 'GET',
  url: '/v4/videos/mine',
  params: payload.requestAll
    ? {
        request_all: true,
      }
    : {},
  rootKey: 'videos',
  normalizer: normalizers.videoNormalizer,
});

const getSharedUploads = (payload = {}) => ({
  method: 'GET',
  url: '/v4/videos/shared',
  params: payload.requestAll
    ? {
        request_all: true,
      }
    : {},
  rootKey: 'videos',
  normalizer: normalizers.videoNormalizer,
});

const getMyFiles = (payload = {}) => ({
  method: 'GET',
  url: '/v4/documents/mine',
  params: payload.requestAll
    ? {
        request_all: true,
      }
    : {},
  rootKey: 'documents',
  normalizer: normalizers.fileNormalizer,
});

const getSharedFiles = (payload = {}) => ({
  method: 'GET',
  url: '/v4/documents/shared',
  params: payload.requestAll
    ? {
        request_all: true,
      }
    : {},
  rootKey: 'documents',
  normalizer: normalizers.fileNormalizer,
});

const searchUploads = term => ({
  method: 'GET',
  url: '/v4/media/search.json',
  params: { search_term: term },
  rootKey: 'media',
  normalizer: normalizers.videoNormalizer,
});

const createUpload = upload => ({
  method: 'POST',
  url: '/v4/videos.json',
  data: upload,
});

const deleteUpload = ({ id }) => ({
  method: 'DELETE',
  url: `/v4/videos/${id}.json`,
});

const updateUpload = ({ id, media }) => ({
  method: 'PUT',
  url: `/v4/videos/${id}.json`,
  data: { video: media },
});

const updateUploadFile = ({ id, media }) => ({
  method: 'PUT',
  url: `/v4/documents/${id}.json`,
  data: { document: media },
});

const getAudienceTypes = () => ({
  method: 'GET',
  url: '/v4/entity_levels',
  rootKey: 'entity_levels',
  normalizer: normalizers.entityLevelNormalizer,
});

const getAudiences = () => ({
  method: 'GET',
  url: '/v4/audiences',
  rootKey: 'audiences',
  normalizer: normalizers.audienceNormalizer,
});

const getCustomAudienceEntities = () => ({
  method: 'GET',
  url: '/manager/entity/descendants',
  normalizer: normalizers.getCustomAudienceEntities,
});

const createCustomAudience = audience => ({
  method: 'POST',
  url: '/v4/audiences',
  data: {
    audience: normalizers.createCustomAudience(audience),
  },
});

export default makeApiActions({
  getMyUploads,
  getSharedUploads,
  getMyFiles,
  getSharedFiles,
  searchUploads,
  createUpload,
  deleteUpload,
  updateUpload,
  updateUploadFile,
  getAudienceTypes,
  getAudiences,
  createCustomAudience,
  getCustomAudienceEntities,
});
