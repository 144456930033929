import React from 'react';
import Transition from 'react-transition-group/Transition';

import ArcPropTypes from '../../helpers/arc/propTypes';
import { LoaderBars } from '../../helpers/utils/loaders';
import ArcView from '../../primitives/ArcView';

const duration = 250;

const styleBase = {
  zIndex: 500,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',

  textAlign: 'center',

  transitionProperty: 'opacity',
  transitionTimingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',
  transitionDuration: 0,
  transitionDelay: 0,

  backgroundColor: 'rgba(255,255,255,0.87)',
};

const transition = {
  entering: {
    ...styleBase,
    opacity: '0',
  },
  entered: {
    ...styleBase,
    opacity: '1',
  },
  exiting: {
    ...styleBase,
    transitionDuration: `${duration}ms`,
    opacity: '0.1',
  },
  exited: {
    ...styleBase,
    opacity: '0',
  },
};

const ArcLoader = ({ in: inProp, style }) => (
  <Transition in={inProp} timeout={duration} unmountOnExit>
    {
      state => (
        <ArcView style={{ ...transition[state], ...style }}>
          <LoaderBars />
        </ArcView>
      )
    }
  </Transition>
);

ArcLoader.propTypes = {
  in: ArcPropTypes.bool.isRequired,
  style: ArcPropTypes.style,
};

ArcLoader.defaultProps = {
  style: undefined,
};

ArcLoader.displayName = 'ArcLoader';

export default ArcLoader;
