import { combineReducers } from 'redux';

import games from './games';
import completedGames from './completed-games';
import gameEvents from './game-events';
import gameLeaderboard from './game-leaderboard';

export default combineReducers({
  games,
  completedGames,
  gameEvents,
  gameLeaderboard,
});
