import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Platform } from 'react-native';
import ArcView from '../../primitives/ArcView';

const FormComponent = Platform.select({
  web: Form,
  default: ArcView,
});

// This is brittle and should be removed as soon as we invert the app from ng to react
const NG_MANAGE_TITLE_BAR_HEIGHT = 50;

const formStyle = Platform.select({
  web: {
    height: `calc(100% - ${NG_MANAGE_TITLE_BAR_HEIGHT}px)`,
  },
  default: {
    height: '100%',
  },
});

const inDialogFormStyle = {
  height: '100%',
};

function ArcForm({
  children,
  inDialog,
  ...props
}) {
  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      {...props}
    >
      {formikProps => (
        <FormComponent
          data-testid="ArcForm"
          style={inDialog ? inDialogFormStyle : formStyle}
        >
          {children(formikProps)}
        </FormComponent>
      )}
    </Formik>
  );
}

ArcForm.propTypes = {
  children: PropTypes.func,
  inDialog: PropTypes.bool,
};

ArcForm.defaultProps = {
  children: global.noop,
  inDialog: false,
};


export default ArcForm;
