import React from 'react';
import {
  ArcPropTypes,
  ArcView,
  createWithStyles,
} from 'arcade-frontend-ui';

import MetricCard from './MetricCard';

const styleMetricView = {
  padding: '16px 8px',
};

const styles = {
  CardList: theme => ({
    root: {
      flexDirection: 'column',

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
    },
  }),
};

const CardList = createWithStyles(styles.CardList)(ArcView);

class MetricCardList extends React.PureComponent {
  static propTypes = {
    onClick: ArcPropTypes.func.isRequired,
    onClickNav: ArcPropTypes.func.isRequired,
    metrics: ArcPropTypes.metrics.isRequired,
  };

  renderMetricCardsList() {
    const {
      metrics,
      onClick,
      onClickNav,
    } = this.props;

    return (
      <ArcView style={styleMetricView}>
        <CardList>
          {
            metrics.map(metric => (
              <MetricCard
                key={metric.id}
                id={metric.id}
                name={metric.title}
                onClick={onClick}
                onClickNav={onClickNav}
                onCreate={onClick}
                {...metric}
              />
            ))
          }
        </CardList>
      </ArcView>
    );
  }

  render() {
    return !!this.props.metrics.length && this.renderMetricCardsList();
  }
}

export default MetricCardList;
