import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';
import { activitySetEncodedKey } from './utils';

const initialState = {};

const handleSummaryActivityLoading = (state, action) => {
  const { payload } = action;
  const key = activitySetEncodedKey(payload);
  return { ...state, [key]: true };
};

const handleNewsfeedLoadIndexLoading = state => ({ ...state, main: 'REQUEST' });

const handleNewsfeedLoadIndexSuccess = state => ({ ...state, main: 'SUCCESS' });

const handleNewsfeedLoadIndexFailure = state => ({ ...state, main: 'ERROR' });

const handleSummaryActivitySuccess = (state, action) => {
  const { payload } = action.meta.requestAction;
  const key = activitySetEncodedKey(payload);
  return { ...state, [key]: false };
};

const handlers = {
  [types.NEWSFEED_INDEX.REQUEST]: handleNewsfeedLoadIndexLoading,
  [types.NEWSFEED_INDEX.SUCCESS]: handleNewsfeedLoadIndexSuccess,
  [types.NEWSFEED_INDEX.FAILURE]: handleNewsfeedLoadIndexFailure,
  [types.NEWSFEED_GET_SUMMARY_ACTIVITIES.REQUEST]: handleSummaryActivityLoading,
  [types.NEWSFEED_GET_SUMMARY_ACTIVITIES.SUCCESS]: handleSummaryActivitySuccess,
};

export default createReducer(initialState, handlers);

export const getActivitySetsLoading = state =>
  state.newsfeed.activitySetsLoading;

const loadingStateForSet = (state, set, value) => {
  const key = activitySetEncodedKey(set);

  return state.newsfeed.activitySetsLoading[key] === value;
};

export const selectors = { loadingStateForSet };
