import React from 'react';
// import ArcPropTypes from '../../helpers/arc/propTypes';

const InitialVine = ({ ...props }) => (
  <svg
    width="51"
    height="44"
    viewBox="0 0 51 44"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <g fillRule="nonzero" fill="none"><path d="M12 9h6v3H6V9H3V6h9v3zM0 3h3v3H0V3z" fill="#4CA600" /><path d="M15 3h3v3h3v3h-9V6H3V0h12v3z" fill="#62D500" /><g><path fill="#67B028" d="M21 0h3v44h-3z" /><path fill="#74C82B" d="M24 0h6v44h-6z" /></g>
    </g>
  </svg>
);

InitialVine.propTypes = {
};

InitialVine.defaultProps = {
};

export default InitialVine;
