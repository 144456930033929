import React from 'react';
import PropTypes from 'prop-types';
import IconClear from '@material-ui/icons/Clear';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import Portrait from 'arcade-frontend-games/src/components/ManageGamesGameList/Portrait';
import {
  ArcView,
  ArcText,
  ArcScroll,
  ArcIconButton,
  ArcEmoji,
  ArcResponsiveDialog,
  ArcDataTableCell,
  ArcDataTableRow,
  createWithStyles,
} from 'arcade-frontend-ui';

const paperProps = { style: { height: '100%' } };

const styles = {
  DialogHeader: theme => ({
    root: {
      backgroundColor: theme.palette.grey.tint,
    },
  }),
};

const DialogHeader = createWithStyles(styles.DialogHeader)(ArcView);

const ActivityReactionDialog = ({
  reactions,
  onClose,
  open,
  onQuicklinkUser,
}) => (
  <ArcResponsiveDialog open={open} onClose={onClose} PaperProps={paperProps}>
    <DialogHeader row align="center">
      <ArcView row flexGrow={1} paddingLeft={16}>
        <ArcText isLarger>Reactions</ArcText>
      </ArcView>
      <ArcIconButton onClick={onClose}>
        <IconClear />
      </ArcIconButton>
    </DialogHeader>
    <ArcScroll>
      <Table style={{ tableLayout: 'fixed' }}>
        <TableBody>
          {reactions.map(({ id, reaction, user }, i) => (
            <ArcDataTableRow
              key={id}
              shade={i % 2 === 1}
              onClick={() => onQuicklinkUser(user.id, 'person')}
            >
              <ArcDataTableCell style={{ width: 50 }}>
                <Portrait person={user} />
              </ArcDataTableCell>
              <TableCell>{user.name}</TableCell>
              <ArcDataTableCell style={{ width: 50 }}>
                <ArcText isLarger>
                  <ArcEmoji label={reaction} emoji={reaction} />
                </ArcText>
              </ArcDataTableCell>
            </ArcDataTableRow>
          ))}
        </TableBody>
      </Table>
    </ArcScroll>
  </ArcResponsiveDialog>
);

ActivityReactionDialog.propTypes = {
  onClose: PropTypes.func,
  onQuicklinkUser: PropTypes.func,
  open: PropTypes.bool,
  reactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
      }).isRequired,
      reaction: PropTypes.string.isRequired,
    }),
  ),
};

ActivityReactionDialog.defaultProps = {
  onClose: global.noop,
  onQuicklinkUser: global.noop,
  open: false,
  reactions: [],
};

export default ActivityReactionDialog;
