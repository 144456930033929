import { ArcPropTypes } from 'arcade-frontend-ui';

export const transaction = ArcPropTypes.shape({
  id: ArcPropTypes.string.isRequired,
  when: ArcPropTypes.date.isRequired,
  fromDisplayName: ArcPropTypes.string.isRequired,
  fromCompanyId: ArcPropTypes.string,
  fromTeamName: ArcPropTypes.string,
  toDisplayName: ArcPropTypes.string.isRequired,
  toCompanyId: ArcPropTypes.string,
  toTeamName: ArcPropTypes.string,
  event: ArcPropTypes.string.isRequired,
  amount: ArcPropTypes.number.isRequired,
  balance: ArcPropTypes.number.isRequired,
});

export const transactions = ArcPropTypes.arrayOf(transaction);
export const transactionsById = ArcPropTypes.objectOf(transactions);

ArcPropTypes.extendWith({
  transaction,
  transactions,
  transactionsById,
});
