import { ArcPropTypes } from 'arcade-frontend-ui';

export const gameReward = ArcPropTypes.shape({
  type: ArcPropTypes.string.isRequired,
  // value: ArcPropTypes.string,
  receiving_rank: ArcPropTypes.number.isRequired,
  receivingRank: ArcPropTypes.number.isRequired,
});

export const gameWinner = ArcPropTypes.shape({
  id: ArcPropTypes.number.isRequired,
  assignee_id: ArcPropTypes.number.isRequired,
  name: ArcPropTypes.string.isRequired,
  image: ArcPropTypes.string.isRequired,
  // goal: null
  is_self: ArcPropTypes.bool.isRequired,
  position: ArcPropTypes.number.isRequired,
  score: ArcPropTypes.number.isRequired,
});

export const userPosition = gameWinner;
export const relevantQuest = gameWinner;

export const gameCurrentUser = ArcPropTypes.oneOfType([userPosition, relevantQuest]);

export const game = ArcPropTypes.shape({
  id: ArcPropTypes.string.isRequired,
  name: ArcPropTypes.string.isRequired,
  expiresAt: ArcPropTypes.date.isRequired,
  timeRemaining: ArcPropTypes.number,
  goal: ArcPropTypes.number,
  rules: ArcPropTypes.oneOfType([
    ArcPropTypes.object,
    ArcPropTypes.string,
  ]),
  kpiGroup: ArcPropTypes.object,
  rewards: ArcPropTypes.arrayOf(gameReward),
});

export const gameRanking = ArcPropTypes.shape({
  id: ArcPropTypes.string.isRequired,
  name: ArcPropTypes.string.isRequired,
  score: ArcPropTypes.number.isRequired,
  imageUrl: ArcPropTypes.string,
  rank: ArcPropTypes.number,
});

export const gameEvent = ArcPropTypes.shape({
  id: ArcPropTypes.string.isRequired,
  name: ArcPropTypes.string.isRequired,
  score: ArcPropTypes.oneOfType([
    ArcPropTypes.shape({
      unit: ArcPropTypes.string.isRequired,
      quantity: ArcPropTypes.number.isRequired,
    }),
    ArcPropTypes.string,
    ArcPropTypes.number,
  ]).isRequired,
  imageUrl: ArcPropTypes.string.isRequired,
});


export const gameRankings = ArcPropTypes.arrayOf(gameRanking);
export const gameEvents = ArcPropTypes.arrayOf(gameEvent);
export const games = ArcPropTypes.arrayOf(game);
export const gamesById = ArcPropTypes.objectOf(game);
export const rankingsByGameId = ArcPropTypes.objectOf(gameRankings);
export const eventsByGameId = ArcPropTypes.objectOf(gameEvents);

ArcPropTypes.extendWith({
  gameRankings,
  gameWinner,
  gameEvents,
  game,
  games,
  gamesById,
  rankingsByGameId,
  eventsByGameId,
});
