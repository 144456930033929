import {
  compose,
  background,
  border,
  color,
  display,
  flexbox,
  grid,
  height,
  layout,
  minHeight,
  minWidth,
  position,
  shadow,
  space,
  typography,
  width,
} from 'styled-system';

const system = compose(
  background,
  border,
  color,
  display,
  flexbox,
  grid,
  height,
  layout,
  minHeight,
  minWidth,
  position,
  shadow,
  space,
  typography,
  width,
);

export default system;
