import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import byId, { getById } from './byId';
import list, { getList } from './list';

const teams = combineReducers({
  byId,
  list,
});

export default teams;

export const getTeams = createSelector(
  getById,
  getList,
  (_byId, _ids) => _ids.map(id => _byId[id]),
);
