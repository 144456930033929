import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/games';

const handleManageGamesEligibleTeamPeopleLoadSuccess = (state, action) => {
  const { data } = action.payload;

  return data.teamPeople;
};

const initialState = {};

const handlers = {
  [types.MANAGE_GAMES_ELIGIBLE_PEOPLE_INDEX.SUCCESS]:
    handleManageGamesEligibleTeamPeopleLoadSuccess,
};

const eligibleTeamPeople = createReducer(initialState, handlers);

export default eligibleTeamPeople;

export const getManageGamesEligibleTeamPeople = state =>
  state.manage.games.eligibleTeamPeople;
