import * as types from 'arcade-frontend-core/src/types/routes';

export const routeManage = () => ({
  type: types.ROUTE_MANAGE,
  payload: {},
});

export const routeManageIntegrationHistory = () => ({
  type: types.ROUTE_MANAGE_INTEGRATION_HISTORY,
  payload: {},
});

export const routeManagePosts = () => ({
  type: types.ROUTE_MANAGE_POSTS,
  payload: {},
});

export const routeManageTeams = () => ({
  type: types.ROUTE_MANAGE_TEAMS,
  payload: {},
});
