import React from 'react';
import PropTypes from 'prop-types';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import Close from 'arcade-frontend-ui/src/icons/Close';

const BUTTON_STYLE = {
  height: 44,
  width: 44,
};

export type Props = {
  onClose: (event: React.SyntheticEvent) => void;
};

export default function NotificationsPanelHeader({
  onClose,
}: Props): React.ReactNode {
  return (
    <ArcBox
      bgcolor="#0580B8"
      pl={3}
      display="flex"
      flexDirection="row"
      alignItems="center"
      width="100%"
      flexShrink={0}
    >
      <ArcText color="white" size="18px">
        {'Notifications'}
      </ArcText>
      <ArcBox flexGrow="100" />
      <ArcButton onClick={onClose} style={BUTTON_STYLE}>
        <Close width={24} height={24} color="#fff" />
      </ArcButton>
    </ArcBox>
  );
}

NotificationsPanelHeader.propTypes = {
  onClose: PropTypes.func,
};

NotificationsPanelHeader.defaultProps = {
  onClose: global.noop,
};
