import React from 'react';
import { Path } from 'react-primitives-svg';
import Icon from '../Icon';

const Check = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.625 3.374L8.25 20.624 3.375 15.75"
    />
  </Icon>
);

Check.displayName = 'Check';

export default Check;
