import * as types from './types';


export const apiGamesIndexRequest = () => ({
  type: types.GAMES_INDEX.REQUEST,
  payload: {},
  meta: {
    analytics: {
      payload: {
        event: 'Viewed assigned games',
      },
    },
  },
});

export const apiGameRequest = id => ({
  type: types.GAMES_GET.REQUEST,
  payload: { id },
  meta: {},
});

export const apiGamesRankingsIndexRequest = id => ({
  type: types.GAMES_RANKINGS_INDEX.REQUEST,
  payload: { id },
  meta: {
    analytics: {
      payload: {
        event: 'Viewed game rankings',
      },
    },
  },
});

export const apiGamesEventsIndexRequest = id => ({
  type: types.GAMES_EVENTS_INDEX.REQUEST,
  payload: { id },
  meta: {
    analytics: {
      payload: {
        event: 'Viewed game events',
      },
    },
  },
});

export const apiGamesActivitiesCreateRequest = (kpiId, amount, date) => ({
  type: types.GAMES_ACTIVITIES_CREATE.REQUEST,
  payload: {
    kpiId,
    amount,
    date,
  },
  meta: {
    analytics: {
      payload: {
        event: 'Logged a sale for a game',
      },
    },
  },
});
