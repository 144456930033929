import React from 'react';
import PropTypes from 'prop-types';

import {
  ActivityIndicator,
  Animated,
  Platform,
  View,
  StyleSheet,
} from 'react-native';

import logoPng from 'arcade-frontend-ui/src/assets/ArcadeLogo/arcade-logo.png';
import logoMaskPng from 'arcade-frontend-ui/src/assets/ArcadeLogo/arcade-logo-mask.png';

import MaskedView from './MaskedView';

const styles = StyleSheet.create({
  fullScreen: {
    flex: 1,
  },
  centeredFullScreen: {
    zIndex: 10,
    backgroundColor: 'transparent',
    position: 'relative',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageStyle: {
    height: 108,
    width: 108,
  },
  maskStyle: {
    height: 108,
    width: 108,
  },
  fullScreenWhiteLayer: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },

  topLayer: {
    zIndex: 10,
  },
});

let hasBlockedOnce = false;

class AnimatedSplash extends React.Component {
  static displayName = 'AnimatedSplash';

  static propTypes = {
    backgroundStyle: PropTypes.objectOf(PropTypes.any),
    children: PropTypes.node,
    isLoaded: PropTypes.bool,
  };

  static defaultProps = {
    backgroundStyle: {},
    children: null,
    isLoaded: false,
  };

  static defaultProps = {
    isLoaded: hasBlockedOnce,
  };

  state = {
    loadingProgress: new Animated.Value(hasBlockedOnce ? 1 : 0),
    animationDone: hasBlockedOnce,
  };

  componentDidMount() {
    if (this.props.isLoaded) {
      this.animate();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoaded !== this.props.isLoaded) {
      if (this.props.isLoaded) {
        this.animate();
      } else {
        this.reset();
      }
    }
  }

  setAnimationDone = (animationDone) =>
    this.setState({ animationDone }, () => {
      hasBlockedOnce = true;
    });

  animate = () => {
    Animated.timing(this.state.loadingProgress, {
      toValue: 100,
      duration: Platform.OS === 'android' ? 2000 : 1000,
      useNativeDriver: true,
    }).start(() => this.setAnimationDone(true));
  };

  reset = () => {
    this.state.loadingProgress.setValue(0);
    this.setAnimationDone(false);
  };

  render() {
    // if (hasBlockedOnce) {
    //   return this.props.children;
    // }

    const opacityClearToVisible = {
      opacity: this.state.loadingProgress.interpolate({
        inputRange: [0, 15, 30],
        outputRange: [0, 0, 1],
        extrapolate: 'clamp',
      }),
    };

    const imageScale = {
      transform: [
        {
          scale: this.state.loadingProgress.interpolate({
            inputRange: [0, 10, 100],
            outputRange: [1, 0.8, 70],
          }),
        },
      ],
    };

    const loaderOpacity = {
      position: 'absolute',
      opacity: this.state.loadingProgress.interpolate({
        inputRange: [0, 1],
        outputRange: [1, 0],
      }),
    };

    const appScale = {
      transform: [
        {
          scale: this.state.loadingProgress.interpolate({
            inputRange: [0, 7, 100],
            outputRange: [1.1, 1.03, 1],
          }),
        },
      ],
    };

    const fullScreenBackgroundLayer = this.state.animationDone ? null : (
      <View style={[StyleSheet.absoluteFill, this.props.backgroundStyle]} />
    );
    const fullScreenWhiteLayer = this.state.animationDone ? null : (
      <View style={[StyleSheet.absoluteFill, styles.fullScreenWhiteLayer]}>
        <Animated.Image
          style={[styles.imageStyle, imageScale]}
          source={require('arcade-frontend-ui/src/assets/ArcadeLogo/arcade-logo.png')}
          resizeMode="contain"
        />

        <Animated.View style={loaderOpacity}>
          <ActivityIndicator />
        </Animated.View>
      </View>
    );

    if (Platform.OS !== 'ios') {
      return (
        <View style={styles.fullScreen}>
          {fullScreenBackgroundLayer}
          {fullScreenWhiteLayer}
          <Animated.View style={[opacityClearToVisible, appScale, { flex: 1 }]}>
            {this.props.children}
          </Animated.View>
        </View>
      );
    }

    const maskElement = (
      <View style={styles.centeredFullScreen}>
        <Animated.Image
          style={[styles.maskStyle, imageScale]}
          source={require('arcade-frontend-ui/src/assets/ArcadeLogo/arcade-logo-mask.png')}
          resizeMode="contain"
        />
      </View>
    );

    return (
      <View style={styles.fullScreen}>
        {fullScreenBackgroundLayer}
        <MaskedView style={{ flex: 1 }} maskElement={maskElement}>
          {fullScreenWhiteLayer}
          <Animated.View style={[opacityClearToVisible, appScale, { flex: 1 }]}>
            {this.props.children}
          </Animated.View>
        </MaskedView>
      </View>
    );
  }
}

export default AnimatedSplash;
