import React, { FC } from 'react'
import WindowedSelect, { Props as SelectProps } from 'react-windowed-select'
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';
import ArcEntityAvatar from '../ArcEntityAvatar'

export type ArcWindowedSelectProps = SelectProps

export const ArcWindowedSelect: FC<ArcWindowedSelectProps> = ({
  ...props
}) => {
  const defaultOptionLabel = ({ label, imageUrl }) => {
    return (
      <ArcView align="center" row>
        <ArcEntityAvatar imageUrl={imageUrl} type="person" />
        <ArcText marginLeft8>{label}</ArcText>
      </ArcView>
    )
  }

  return (
    <WindowedSelect
      isMulti
      hideSelectedOptions={false}
      placeholder="Search"
      formatOptionLabel={defaultOptionLabel}
      {...props}
    />
  )
}
