import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ArcLoader, currency } from 'arcade-frontend-ui';

import { actions, routes } from '../../../actions/manage/metrics';

import {
  actions as actionsMetrics,
  routes as routesMetrics,
} from '../../../actions/metrics';

import MetricCardDetails from '../../../components/metrics/MetricCardDetails';

class ManageMetricsDetailsContainer extends React.PureComponent {
  static propTypes = {
    currentUser: PropTypes.shape({
      email: PropTypes.string,
      fullname: PropTypes.string,
    }).isRequired,
    currentMetric: PropTypes.shape({}),
    currentMetricId: PropTypes.string,
    currentMetricDetailsType: PropTypes.string,
    routeManageMetrics: PropTypes.func.isRequired,
    routeManageMetricsShow: PropTypes.func.isRequired,
    activitiesById: PropTypes.shape({}).isRequired,
    rankingsById: PropTypes.shape({}).isRequired,
    apiMetricsActivitiesIndexRequest: PropTypes.func.isRequired,
    apiMetricsActivitiesCreateRequest: PropTypes.func.isRequired,
    apiMetricsRankingsIndexRequest: PropTypes.func.isRequired,
    apiManageMetricsActivitiesIndexRequest: PropTypes.func.isRequired,
    apiManageMetricsRankingsIndexRequest: PropTypes.func.isRequired,
    manageMetricsById: PropTypes.shape({
      symbol: PropTypes.string,
    }),
    dateRange: PropTypes.shape({
      type: PropTypes.oneOf(['time period', 'date range']),
      timePeriod: PropTypes.string,
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
    }),
    people: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    currentMetric: null,
    currentMetricId: null,
    currentMetricDetailsType: null,
    manageMetricsById: null,
    dateRange: undefined,
    people: [],
  };

  static displayName = 'ManageMetricsDetailsContainer';

  constructor(props) {
    super(props);

    const { currentMetricId, currentMetricDetailsType, dateRange, people } =
      props;

    if (currentMetricId) {
      this.handleApiRequests(
        currentMetricId,
        currentMetricDetailsType,
        dateRange,
        people,
      );
    }
  }

  state = {
    ready: false,
  };

  componentWillReceiveProps(nextProps) {
    const {
      currentMetricId,
      currentMetricDetailsType,
      currentMetric,
      dateRange,
      people,
    } = nextProps;

    const isReady = currentMetricId && currentMetric;

    if (!this.state.ready && isReady) {
      this.setReady(isReady);
    }

    const isChangingId =
      currentMetricId && currentMetricId !== this.props.currentMetricId;
    const isChangingType =
      currentMetricDetailsType !== this.props.currentMetricDetailsType;
    const isChangingDateRange =
      dateRange.type !== this.props.dateRange.type ||
      dateRange.timePeriod !== this.props.dateRange.timePeriod ||
      dateRange.fromDate !== this.props.dateRange.fromDate ||
      dateRange.toDate !== this.props.dateRange.toDate;

    if (isChangingId || isChangingType || isChangingDateRange) {
      this.handleApiRequests(
        currentMetricId,
        currentMetricDetailsType,
        dateRange,
        people,
      );
    }
  }

  setReady = ready => setTimeout(() => this.setState({ ready }), 800);

  handleApiRequests(
    currentMetricId,
    currentMetricDetailsType,
    dateRange,
    people,
  ) {
    switch (currentMetricDetailsType) {
      case 'activity':
        return this.props.apiManageMetricsActivitiesIndexRequest({
          metricId: currentMetricId,
          people,
          ...dateRange,
        });
      case 'ranking':
        return this.props.apiManageMetricsRankingsIndexRequest({
          metricId: currentMetricId,
          people,
          ...dateRange,
        });
      default:
        return null;
    }
  }

  handleClickNav = (e, id, type) => this.props.routeManageMetricsShow(id, type);

  render() {
    const {
      routeManageMetrics,
      currentMetricId,
      currentMetricDetailsType,
      apiMetricsActivitiesIndexRequest,
      apiMetricsActivitiesCreateRequest,
      apiMetricsRankingsIndexRequest,
      currentUser,
      currentMetric,
      manageMetricsById,
    } = this.props;

    const { ready } = this.state;

    const activities = this.props.activitiesById[this.props.currentMetricId];
    let formattedActivities = activities;

    if (
      manageMetricsById[currentMetricId] &&
      formattedActivities &&
      manageMetricsById[currentMetricId].symbol === '$' &&
      activities
    ) {
      formattedActivities = activities.map(activity => ({
        ...activity,
        quantity: currency.toCurrencyFormat(activity.quantity),
      }));
    }

    const rankings = this.props.rankingsById[this.props.currentMetricId];

    if (!ready) return <ArcLoader in={!currentMetric} />;

    return (
      <MetricCardDetails
        handleClickMetricCard={() => {}}
        onClose={routeManageMetrics}
        onClickNav={this.handleClickNav}
        metricId={currentMetricId}
        currentMetricId={currentMetricId}
        currentMetricDetailsType={currentMetricDetailsType}
        activities={formattedActivities}
        rankings={rankings}
        apiMetricsActivitiesIndexRequest={apiMetricsActivitiesIndexRequest}
        apiMetricsActivitiesCreateRequest={apiMetricsActivitiesCreateRequest}
        apiMetricsRankingsIndexRequest={apiMetricsRankingsIndexRequest}
        currentUser={currentUser}
        {...currentMetric}
        hasAdd={false}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentUser: state.app.user.currentUser,
  manageMetricsById: state.manage.metrics.manageMetricsById,
  currentMetricId: state.manage.metrics.currentMetricId,
  rankingsByMetricId: state.metrics.rankingsByMetricId,
  currentMetricDetailsType: state.manage.metrics.currentMetricDetailsType,
  currentMetric:
    state.manage.metrics.manageActiveMetricsById[
      state.manage.metrics.currentMetricId
    ] ||
    state.manage.metrics.manageArchivedMetricsById[
      state.manage.metrics.currentMetricId
    ],
  rankingsById: state.manage.metrics.rankingsByMetricId,
  activitiesById: state.manage.metrics.activitiesByMetricId,
  ...props,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
      ...routes,
      ...actionsMetrics,
      ...routesMetrics,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageMetricsDetailsContainer);
