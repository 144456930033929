import React from 'react';

import {
  ArcPropTypes,
  ArcStyles,
  ArcParagraph,
  ArcView,
  ArcProgress,
  percentToArcColor,
} from 'arcade-frontend-ui';

import {
  styleProgressContainer,
  styleProgressIndicator,
  styleProgressIndicatorText,
} from './MetricBenchmarkProgress.style';

const accessibleTextColor = (percent) => {
  if (percent > 42 && percent < 53) {
    return ArcStyles.theme.defaultTextColor;
  }
  return ArcStyles.theme.white;
};

const MetricBenchmarkProgress = ({ percentOfBenchmark }) => (
  <ArcView
    row
    align="center"
    style={styleProgressContainer}
  >
    <ArcView
      justify="center"
      align="center"
      style={{
        ...styleProgressIndicator,
        backgroundColor: percentToArcColor(percentOfBenchmark),
      }}
    >
      <ArcParagraph
        style={{
          ...styleProgressIndicatorText,
          color: accessibleTextColor(percentOfBenchmark),
        }}
      >
        {percentOfBenchmark}
        <span>%</span>
      </ArcParagraph>

    </ArcView>
    <ArcView flexGrow={1}>
      <ArcProgress percent={percentOfBenchmark} style={{ height: 40 }} />
    </ArcView>
  </ArcView>
);

MetricBenchmarkProgress.propTypes = {
  percentOfBenchmark: ArcPropTypes.number.isRequired,
};

export default MetricBenchmarkProgress;
