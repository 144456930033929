const isProduction = process.env.NODE_ENV === 'production';

const track = (action, data = {}) => {
  const { payload = {} } = data;
  const { event, properties } = payload;

  if (process.env.NODE_ENV === 'development') {
    console.log('Analytics:', { action, event });
  }
};

export default track;
