import Rollbar from 'rollbar';

const RollbarClient = new Rollbar({
  accessToken: window.arcPostMessage
    ? process.env.ROLLBAR_POST_CLIENT_ITEM_WEBVIEW
    : process.env.ROLLBAR_POST_CLIENT_ITEM_LAUNCH,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV !== 'development',
  environment: process.env.NODE_ENV,
});

RollbarClient.setPerson = (id, username, email) => {
  RollbarClient.configure({
    payload: {
      client: {
        javascript: {
          code_version: process.env.GIT_CURRENT_SHA,
        },
      },
      person: {
        id,
        username,
        email,
      },
    },
  });
};

RollbarClient.clearPerson = () => {
  RollbarClient.configure({
    payload: {
      person: {
        id: null,
      },
    },
  });
};

window.RollbarClient = RollbarClient;

export default RollbarClient;
