import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const Home = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.5 12.5v9a1 1 0 001 1H9a.5.5 0 00.5-.5v-4a2.5 2.5 0 015 0v4a.5.5 0 00.5.5h4.5a1 1 0 001-1V13m-20-1L12 .5 23.5 12M16 1.5h3a.5.5 0 01.5.5v3"
    />
  </Icon>
);

export default Home;
