import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const Group = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14 23.5l.5-6H17V14a5 5 0 10-10 0v3.5h2.5l.5 6h4z"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.75"
      d="M21 12l.278-1.528H22.5V8.64a2.75 2.75 0 10-5.5 0v1.833h1.222L18.5 12M5.25 12l.278-1.528H6.75V8.64a2.75 2.75 0 10-5.5 0v1.833h1.222L2.75 12m17-7.028a1.986 1.986 0 100-3.972 1.986 1.986 0 000 3.972zm-15.754 0a1.986 1.986 0 100-3.972 1.986 1.986 0 000 3.972z"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 7.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
    />
  </Icon>
);

export default Group;
