import { createReducer } from 'arcade-frontend-ui';
import { createSelector } from 'reselect';

import * as types from '../../../actions/manage/rewards/types';

import { getManageRewardsById } from './byId';

const initialState = [];

const sortByName = (a, b) => {
  if (a.name === b.name) {
    return 0;
  }

  return a.name > b.name ? 1 : -1;
};

const handleManageRewardsCustomIndexSuccess = (state, action) => action.payload.data.sort(sortByName).map(reward => reward.id).unique();

const handlers = {
  [types.MANAGE_REWARDS_CUSTOM_INDEX.SUCCESS]: handleManageRewardsCustomIndexSuccess,
};

const custom = createReducer(initialState, handlers);

export const getManageRewardsCustom = state => state.manage.rewards.custom;

const getManageRewardsFilterProp = prop => () => prop;
const getManageRewardsFilterValue = value => () => value;

const filterCollectionByPropValue = (byId, ids, prop, value) => ids.map(id => byId[id]).filter(reward => reward[prop] === value);

const createFilterSelector = (prop, value) => (
  createSelector(
    [
      getManageRewardsById,
      getManageRewardsCustom,
      getManageRewardsFilterProp(prop),
      getManageRewardsFilterValue(value),
    ],
    filterCollectionByPropValue,
  )
);

export const getManageRewardsCustomActive = createFilterSelector('archived', false);
export const getManageRewardsCustomArchived = createFilterSelector('archived', true);

export default custom;
