import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';

import { resources } from '../../resources/game-leaderboard';
import { getGameLeaderboard } from '../../reducers/game-leaderboard';
import GamesLeaderboardTable from '../../components/GamesLeaderboardTable';

function GamesLeaderboardContainer({ gameId, ...props }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  const apiGetGameLeaderboard = useResourceAction(
    resources.apiGetGameLeaderboard,
    {
      treatIdleAsPending: true,
      getInitialData: getGameLeaderboard(gameId),
    },
  );

  function requestData() {
    return apiGetGameLeaderboard
      .requestData({
        requestKey: gameId,
        meta: { gameId },
      })
      .then(resp => {
        const action = apiGetGameLeaderboard.succeeded({
          requestKey: gameId,
          list: gameId,
          resources: resp.data.resources,
        });

        dispatch(action);
      })
      .catch(global.noop);
  }

  React.useEffect(() => {
    requestData();
  }, [gameId]);

  const [page, setPage] = React.useState(0);

  function handleChangePage(_, nextPage) {
    setPage(nextPage);
  }

  function handleRetry() {
    apiGetGameLeaderboard.onStatusReset();
    requestData();
  }

  if (!apiGetGameLeaderboard.hasData && apiGetGameLeaderboard.isPending) {
    return <GamesLeaderboardTable.Placeholder />;
  }

  const data = apiGetGameLeaderboard.hasData
    ? apiGetGameLeaderboard.data.resources
    : [];

  return (
    <GamesLeaderboardTable
      currentUserId={currentUser.id}
      data={data}
      hasFailed={apiGetGameLeaderboard.hasFailed}
      onChangePage={handleChangePage}
      onRetry={handleRetry}
      page={page}
      total={data.length}
      {...props}
    />
  );
}

GamesLeaderboardContainer.propTypes = {
  gameId: PropTypes.string.isRequired,
};

export default GamesLeaderboardContainer;
