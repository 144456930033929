import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ButtonBase from '@material-ui/core/ButtonBase';

import BlankProfileIcon from 'arcade-frontend-ui/src/icons/BlankProfileIcon';
import {
  ArcView,
  ArcText,
  ArcImage,
  createWithStyles,
} from 'arcade-frontend-ui';

const ACTIVITY_TYPES = {
  GameLeaderboardActivity: 'GameLeaderboardActivity',
  AnnouncementActivity: 'AnnouncementActivity',
  CompetitionActivity: 'CompetitionActivity',
  LeaderboardActivity: 'LeaderboardActivity',
  PerformanceActivity: 'PerformanceActivity',
  QuestActivity: 'QuestActivity',
  BadgeActivity: 'BadgeActivity',
  QuizActivity: 'QuizActivity',
  SaleActivity: 'SaleActivity',
  RecognitionActivity: 'RecognitionActivity',
  RewardActivity: 'RewardActivity',
  HuddleActivity: 'HuddleActivity',
  GameAnnouncementActivity: 'GameAnnouncementActivity',
  ExternalActivity: 'ExternalActivity',
};

const LABELS_BY_ACTIVITY_TYPE = {
  GameLeaderboardActivity: 'GameLeaderboardActivity',
  AnnouncementActivity: 'AnnouncementActivity',
  CompetitionActivity: 'CompetitionActivity',
  LeaderboardActivity: 'Moved Positions',
  PerformanceActivity: 'Achievements',
  QuestActivity: 'Finished Quests',
  BadgeActivity: 'Earned Badges',
  QuizActivity: 'QuizActivity',
  SaleActivity: 'Made Sales',
  RecognitionActivity: 'RecognitionActivity',
  RewardActivity: 'RewardActivity',
  HuddleActivity: 'HuddleActivity',
  GameAnnouncementActivity: 'GameAnnouncementActivity',
  ExternalActivity: 'ExternalActivity',
};

const countStyle = {
  display: 'block',
  lineHeight: '32px',
  height: '32px',
};

const extraPeopleStyle = {
  width: 32,
  height: 32,
  borderRadius: '50%',
  borderWidth: 2,
};

const portraitWrapperStyle = {
  width: 20,
};

const styles = {
  Portrait: theme => ({
    root: {
      width: 32,
      height: 32,
      maxWidth: 32,
      border: `2px solid ${theme.palette.grey[200]}`,
      borderRadius: 16,
      backgroundColor: 'white',
    },
  }),
};

const Portrait = createWithStyles(styles.Portrait)(ArcImage);

const PLACEHOLDER = (
  <ArcView padding="8">
    <ArcView className="shimmer" padding="4" marginBottom="8" />
    <ArcView
      className="shimmer"
      paddingTop="32"
      paddingLeft="32"
      paddingRight="32"
    />
  </ArcView>
);

const PORTRAIT_PLACEHOLDER = (
  <ArcView alignSelf="flex-start" padding="8" border="default" borderWidth="1">
    <BlankProfileIcon color="disabled" />
  </ArcView>
);

class NewsfeedSummaryItem extends React.Component {
  static propTypes = {
    count: PropTypes.number,
    newest: PropTypes.instanceOf(moment),
    oldest: PropTypes.instanceOf(moment),
    onClick: PropTypes.func,
    type: PropTypes.oneOf(Object.values(ACTIVITY_TYPES)),
    userImages: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    count: 0,
    isLoading: false,
    newest: null,
    oldest: null,
    onClick: global.noop,
    type: null,
    userImages: [],
  };

  static PLACEHOLDER = PLACEHOLDER;

  handleClick = () =>
    this.props.onClick({
      type: this.props.type,
      newest: this.props.newest,
      oldest: this.props.oldest,
    });

  renderPortrait = (src, i) => (
    <ArcView key={i.toString()} style={portraitWrapperStyle}>
      <Portrait src={src} />
    </ArcView>
  );

  render() {
    const { count, userImages, type } = this.props;

    const displayedUserImages = userImages.slice(0, 3);

    return (
      <ButtonBase onClick={this.handleClick}>
        <ArcView
          align="center"
          paddingTop="8"
          paddingRight="16"
          paddingBottom="8"
          paddingLeft="16"
        >
          <ArcText size="14px" textOverflow="ellipsis">
            {LABELS_BY_ACTIVITY_TYPE[type]}
          </ArcText>
          <ArcView
            row
            justify="center"
            paddingTop="8"
            paddingRight="16"
            paddingBottom="4"
          >
            {displayedUserImages.length
              ? displayedUserImages.map((src, i) => this.renderPortrait(src, i))
              : PORTRAIT_PLACEHOLDER}
            {count > 3 && (
              <ArcView style={portraitWrapperStyle}>
                <ArcView
                  align="center"
                  justify="center"
                  style={extraPeopleStyle}
                  border="default"
                  color="paper"
                >
                  <ArcText color="disabled" size="12px" style={countStyle}>
                    {'+'}
                    {count - 3}
                  </ArcText>
                </ArcView>
              </ArcView>
            )}
          </ArcView>
          <ArcText color="disabled" size="12px">
            {'Details'}
          </ArcText>
        </ArcView>
      </ButtonBase>
    );
  }
}

export default NewsfeedSummaryItem;
