import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Close from '@material-ui/icons/Close';

import {
  ArcButton,
  ArcForm,
  ArcView,
  ArcText,
  ArcFormField,
  ArcIconButton,
  ArcToken,
} from 'arcade-frontend-ui';
import ArcErrorDialog from 'arcade-frontend-ui/src/components/ArcErrorDialog';
import ArcResponsiveDialog from 'arcade-frontend-ui/src/components/ArcResponsiveDialog';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';
import ArcEntityChip from 'arcade-frontend-ui/src/components/ArcEntityChip';

import ArcClawbackTokensFormConfirmationDialog from './ArcClawbackTokensFormConfirmationDialog';

const INITIAL_VALUES = {
  isPublic: false,
  people: [],
  reason: '',
  tokens: '',
};

const MAX_NUM_CONFIRMATION_PEOPLE = 5;

const STRINGS = {
  'UI/CLAWBACK_TOKENS_FORM_TITLE': 'Clawback Tokens',
  'UI/CLAWBACK_TOKENS_FORM_DESCRIPTION_1':
    "Use this form to remove tokens from an employee's account.",
  'UI/CLAWBACK_TOKENS_FORM_DESCRIPTION_2': 'This is non-reversible.',
  'UI/CLAWBACK_TOKENS_FORM_MESSAGE_TEXT': 'I am awarding this for',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  reason: Yup.string().required(),
  tokens: Yup.number().required(),
});

class ArcClawbackTokensForm extends React.PureComponent {
  static displayName = 'ArcClawbackTokensForm';

  static propTypes = {
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    hasError: PropTypes.bool,
    hasFailed: PropTypes.bool,
    initialValues: PropTypes.shape({}),
    isPending: PropTypes.bool,
    onCancel: PropTypes.func,
    onErrorReset: PropTypes.func,
    onStatusReset: PropTypes.func,
    onSubmit: PropTypes.func,
    open: PropTypes.bool,
    peopleById: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string,
        imageUrl: PropTypes.string,
      }),
    ),
    status: PropTypes.objectOf(PropTypes.bool),
  };

  static defaultProps = {
    error: {},
    hasError: false,
    hasFailed: false,
    initialValues: INITIAL_VALUES,
    isPending: false,
    onCancel: global.noop,
    onErrorReset: global.noop,
    onStatusReset: global.noop,
    onSubmit: global.noop,
    open: false,
    peopleById: {},
    status: undefined,
  };

  static MAX_NUM_CONFIRMATION_PEOPLE = MAX_NUM_CONFIRMATION_PEOPLE;

  state = {
    isConfirmingSubmit: false,
  };

  setIsConfirmingSubmit = isConfirmingSubmit =>
    this.setState({ isConfirmingSubmit });

  hasSubmitted = false;

  handleConfirmingSubmitCancel = () => this.setIsConfirmingSubmit(false);

  renderForm = formikProps => {
    const { people, reason, tokens } = formikProps.values;

    const peopleHasError = !people || people.length === 0;

    const hasError = peopleHasError || !formikProps.isValid;
    const isTouched = formikProps.touched.reason && formikProps.touched.tokens;

    const submitDisabled = hasError && isTouched && this.hasSubmitted;

    let isPublicLabel = 'Notify Person';
    const hasPerson = !!people && !!people.length;

    if (hasPerson) {
      isPublicLabel =
        people.length === 1
          ? `Notify ${people[0].name}`
          : `Notify ${people.length} people`;
    }

    const tokensDisabled = this.props.isPending || !hasPerson;
    const numTokens = tokens ? parseInt(tokens, 10) : 0;
    const minTokens = 1;

    const maxTokens = hasPerson
      ? Math.min(...people.map(({ userBalance }) => userBalance))
      : 1;

    const addButtonDisabled = numTokens >= maxTokens;

    const addButton = (
      <ArcIconButton
        size="small"
        disabled={tokensDisabled || addButtonDisabled}
        onClick={() => {
          if (numTokens + 100 > maxTokens) {
            return;
          }

          formikProps.setFieldValue('tokens', (numTokens + 100).toString());
          formikProps.setFieldTouched('tokens', true);
        }}
      >
        <Add color="inherit" size="small" />
      </ArcIconButton>
    );

    const removeButtonDisabled = numTokens <= minTokens;

    const removeButton = (
      <ArcIconButton
        size="small"
        disabled={tokensDisabled || removeButtonDisabled}
        onClick={() => {
          if (numTokens - 100 < minTokens) {
            return;
          }

          formikProps.setFieldValue('tokens', (numTokens - 100).toString());
          formikProps.setFieldTouched('tokens', true);
        }}
      >
        <Remove color="inherit" size="small" />
      </ArcIconButton>
    );

    const removeButtonAdornment =
      removeButtonDisabled && minTokens > 0 ? (
        <Tooltip title={`Min amount is ${minTokens}`}>
          <ArcView>{removeButton}</ArcView>
        </Tooltip>
      ) : (
        removeButton
      );

    const addButtonAdornment = addButtonDisabled ? (
      <Tooltip title={`Max amount is ${maxTokens}`}>
        <ArcView>{addButton}</ArcView>
      </Tooltip>
    ) : (
      addButton
    );

    const startAdornment = (
      <InputAdornment position="start">
        <ArcToken />
      </InputAdornment>
    );

    const endAdornment = (
      <React.Fragment>
        {removeButtonAdornment}
        {addButtonAdornment}
      </React.Fragment>
    );

    return (
      <ArcView>
        <ArcView padding="16">
          <ArcView marginBottom="24">
            <ArcText color="disabled" fontSize="12px" marginBottom="8">
              {STRINGS['UI/CLAWBACK_TOKENS_FORM_DESCRIPTION_1']}
            </ArcText>
            <ArcText color="disabled" fontSize="12px">
              {STRINGS['UI/CLAWBACK_TOKENS_FORM_DESCRIPTION_2']}
            </ArcText>
          </ArcView>

          <ArcView marginBottom="24" row wrap="wrap">
            {people.map(person => (
              <ArcEntityChip key={person.id} {...person} />
            ))}
          </ArcView>

          <ArcFormField
            autoComplete="off"
            id="tokens"
            type="number"
            name="tokens"
            fullWidth
            label="Tokens"
            disabled={tokensDisabled}
            validations={{
              isRequired: true,
              minNumber: minTokens,
              maxNumber: maxTokens,
            }}
            InputProps={{
              startAdornment,
              endAdornment,
            }}
          />

          <ArcFormField
            type="text"
            name="reason"
            label="Reason"
            multiline
            validations={{
              isRequired: true,
            }}
          />

          <ArcFormField
            type="checkbox"
            name="isPublic"
            disabled={!hasPerson}
            label={isPublicLabel}
          />
        </ArcView>

        <ArcView row justify="flex-end" padding="4">
          <ArcView padding="4">
            <ArcButton onClick={this.props.onCancel} label="Cancel" />
          </ArcView>
          <ArcView padding="4">
            <ArcResourceButton
              disabled={submitDisabled}
              type="submit"
              onClick={evt => {
                evt.preventDefault();

                if (hasError) {
                  this.hasSubmitted = true;

                  const fields = {};

                  Object.keys(INITIAL_VALUES).forEach(key => {
                    fields[key] = true;
                  });

                  formikProps.setTouched(fields);
                } else {
                  this.setIsConfirmingSubmit(true);
                }
              }}
              label="Submit"
              color="primary"
              variant="contained"
              status={this.props.status}
            />
          </ArcView>
        </ArcView>
        <ArcClawbackTokensFormConfirmationDialog
          maxPeople={MAX_NUM_CONFIRMATION_PEOPLE}
          open={this.state.isConfirmingSubmit}
          onClose={this.handleConfirmingSubmitCancel}
          onConfirm={() => {
            this.setIsConfirmingSubmit(false);

            formikProps.handleSubmit();
          }}
          people={people}
          reason={reason}
          tokens={tokens && tokens.toString ? tokens.toString() : ''}
        />
      </ArcView>
    );
  };

  render() {
    return (
      <ArcResponsiveDialog onClose={this.props.onCancel} open={this.props.open}>
        <ArcView flexGrow="10" flexShrink="10" fullHeight>
          <ArcView
            position="relative"
            row
            padding="8"
            paddingLeft="16"
            align="flex-end"
            color="grey-tint"
            borderBottom="default"
            borderBottomWidth="1"
          >
            <Typography variant="h5">
              {STRINGS['UI/CLAWBACK_TOKENS_FORM_TITLE']}
            </Typography>

            <ArcView spacer />

            <ArcButton size="small" onClick={this.props.onCancel}>
              <Close color="action" fontSize="small" />
            </ArcButton>
          </ArcView>

          <ArcForm
            initialValues={{
              ...INITIAL_VALUES,
              ...this.props.initialValues,
            }}
            onSubmit={this.props.onSubmit}
            validateOnBlur
            validateOnChange
            validationSchema={VALIDATION_SCHEMA}
          >
            {this.renderForm}
          </ArcForm>
        </ArcView>

        <ArcErrorDialog
          open={this.props.hasFailed}
          onClose={this.props.onStatusReset}
          onConfirm={this.props.onStatusReset}
          onExited={this.props.onErrorReset}
          content={this.props.hasError ? this.props.error.message : undefined}
        />
      </ArcResponsiveDialog>
    );
  }
}

export default ArcClawbackTokensForm;
