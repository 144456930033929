import React, { FC } from 'react';
import {
  ArcView,
  ArcHeading,
  createWithStyles,
  ArcFormDateRangePickerProps,
} from 'arcade-frontend-ui';

import { ArcFormDateRangePickerContainer } from 'arcade-frontend-widgets/src/containers/ArcFormDateRangePickerContainer';

const styleSectionHeading = {
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: '300',
  color: '#666666',
  marginTop: '8px',
  marginBottom: '8px',
};

const styles = {
  Container: () => ({
    root: {
      paddingTop: 24,
      paddingRight: 16,
      paddingBottom: 8,
      paddingLeft: 16,
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);

interface Props {
  value: ArcFormDateRangePickerProps.value;
  onChange: ArcFormDateRangePickerProps.onChange;
}

const MetricViewHeader: FC<Props> = ({ value, onChange }: Props) => (
  <Container row justify="space-between" align="center">
    <ArcHeading style={styleSectionHeading}>My Goals</ArcHeading>
    <ArcFormDateRangePickerContainer value={value} onChange={onChange} />
  </Container>
);

export default MetricViewHeader;
