import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'throttle-debounce';
import { getMenu } from 'arcade-frontend-core/src/reducers/menu';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import { getIsMuted } from 'arcade-frontend-core/src/reducers/isMuted';

/* eslint-disable import/no-unresolved */
import { CbThemeProvider } from '@workplacearcade/ui/components/CbThemeProvider';
import { Box } from '@chakra-ui/core';
import { CbLeftMenu } from '@workplacearcade/ui/components/CbLeftMenu/CbLeftMenu';
import { Logo } from '@workplacearcade/ui/components/CbLeftMenu/Logo';
import { ICON_BY_NAME as IconByName } from '@workplacearcade/ui/components/Icons/Icon';
/* eslint-enable import/no-unresolved */

import * as routeTypes from 'arcade-frontend-core/src/types/routes';
import { Platform } from 'arcade-frontend-ui';
import { actions, types } from 'arcade-frontend-core/src/actions';
import { actions as widgetActions } from 'arcade-frontend-widgets/src/actions/uploadManager';
import ArcTourStepperContainer from 'arcade-frontend-features/src/containers/app/ArcTourStepperContainer';
import * as supportWidget from '../../../helpers/supportWidget';
import { ARCADE_ICON_BY_NAME } from './Icon';
import { Menu, CurrentUser, MainLink } from './types';

export interface CbLeftMenuContainerProps {
  chatCount: number;
  location: {
    type: string;
  };
  rewardsCount: number;
}

export const CbLeftMenuContainer: FC<CbLeftMenuContainerProps> = ({
  chatCount,
  location,
  rewardsCount,
}) => {
  const [mainLinks, setMainLinks] = useState<Array<MainLink>>([]);
  const dispatch = useDispatch();
  const menu: Menu = useSelector(getMenu);
  const currentUser: CurrentUser = useSelector(getCurrentUser);
  const isMuted = useSelector(getIsMuted);

  const breakpointLg = 1025;
  const [shouldDisplayOnMobile, setShouldDisplayOnMobile] = useState(
    window.innerWidth < breakpointLg,
  );

  const handleWindowResize = () => {
    if (window.innerWidth < breakpointLg) {
      setShouldDisplayOnMobile(true);
    } else {
      setShouldDisplayOnMobile(false);
    }
  };

  const onWindowResize = throttle(300, () => handleWindowResize());

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  useEffect(() => {
    const mainLinksArray: MainLink[] = [];

    if (menu.menuItems.length) {
      menu.menuItems.forEach(item => {
        const title = item.menuTitle.toLowerCase();

        if (title === 'logout') return null;

        const routeType = `route/ROUTE_${title.toUpperCase()}`;

        let count = 0;

        if (title === 'rewards') {
          count = rewardsCount;
        } else if (title === 'chat') {
          count = chatCount;
        }

        const link: MainLink = {
          label: title.charAt(0).toUpperCase() + title.toLowerCase().slice(1),
          notificationCount: count,
          isActive: location.type.indexOf(routeType) === 0,
          leftIcon: ARCADE_ICON_BY_NAME[title],
          onClick: () =>
            dispatch({
              type: routeType,
            }),
        };
        mainLinksArray.push(link);

        if (title === 'people') {
          const getHelpLink: MainLink = {
            label: 'Get Help',
            isActive: false,
            leftIcon: ARCADE_ICON_BY_NAME.get_help,
            onClick: () => {
              if (Platform.OS === 'web') {
                supportWidget.toggleSupportWidget();
              }
            },
          };

          return mainLinksArray.push(getHelpLink);
        }

        return mainLinksArray;
      });

      setMainLinks(mainLinksArray);
    }
  }, [menu, rewardsCount, chatCount, location]);

  const handleProfileClick = () => {
    dispatch({
      type: routeTypes.ROUTE_PROFILE,
      payload: { id: currentUser.id },
    });
  };

  const RightIcon: FC = () => (
    <IconByName.launch
      height="12px"
      width="12px"
      marginLeft="5px !important"
      marginTop="1px !important"
    />
  );

  const menuLinks = {
    mainLinks,
    subMenuTitle: 'ADMIN',
    subMenuLinks: [
      {
        label: 'My Media',
        onClick: () => {
          dispatch(widgetActions.uploadManagerOpened(null));
        },
      },
      {
        label: `${isMuted ? 'Unmute' : 'Mute'} Notifications`,
        isActive: false,
        onClick: () => {
          dispatch(actions.appToggleMuteRequest(!isMuted));
        },
      },
      {
        label: 'Onboarding Tour',
        onClick: () => {
          dispatch(actions.appSetOnboardingTourOpen(true));
        },
      },
      {
        label: 'Resources',
        href: 'https://help.arcade.co',
        isExternal: true,
        rightIcon: <RightIcon />,
      },
      {
        label: 'Privacy Policy',
        href: 'https://www.iubenda.com/privacy-policy/19963067',
        isExternal: true,
        rightIcon: <RightIcon />,
      },
      {
        label: 'Terms of Service',
        href: 'https://arcade.co/service-agreement',
        isExternal: true,
        rightIcon: <RightIcon />,
      },
      {
        label: 'Logout',
        onClick: () => {
          dispatch({
            type: types.LOGOUT,
            payload: currentUser.id,
          });
        },
      },
    ],
  };

  const userProfile = {
    name: currentUser.fullname,
    profileImage: currentUser.profileImage,
    onClick: handleProfileClick,
  };

  const handleLogoClick = () => {
    dispatch({
      type: routeTypes.ROUTE_NEWSFEED,
    })
  }

  const logoLink = (
    <a onClick={handleLogoClick}><Logo /></a>
  )

  return (
    <>
      <CbThemeProvider>
        <Box
          className="cb-left-menu"
          position="absolute"
          top={shouldDisplayOnMobile ? '2px' : '0'}
          left={shouldDisplayOnMobile ? '12px' : '0'}
          width="60px"
          height="60px"
          zIndex="10000"
        >
          <CbLeftMenu
            logo={logoLink}
            maxWidth={!shouldDisplayOnMobile ? '240px' : 'auto'}
            menuLinks={menuLinks}
            profile={userProfile}
            shouldDisplayOnMobile={shouldDisplayOnMobile}
          />
        </Box>
      </CbThemeProvider>
      {Platform.OS === 'web' && <ArcTourStepperContainer />}
    </>
  );
};
