import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import pluralize from 'pluralize';
import CommentIcon from '@material-ui/icons/Comment';

import track from 'arcade-frontend-core/src/helpers/track';
import BlankProfileIcon from 'arcade-frontend-ui/src/icons/BlankProfileIcon';
import {
  ArcDeleteDialog,
  ArcLink,
  ArcLoaderButton,
  ArcView,
  ArcText,
  ArcMarkdownContent,
  createWithStyles,
} from 'arcade-frontend-ui';

import Portrait from '../Portrait';
import NewsfeedCommentReactions from '../NewsfeedCommentReactions';
import NewsfeedCommentMenuContainer from '../../containers/NewsfeedCommentMenuContainer';

const styles = {
  CommentArea: theme => ({
    root: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  }),
  CollapsedControl: theme => ({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: theme.palette.blue.main,
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: 14,
    },
  }),
  CollapsedText: theme => ({
    root: {
      marginLeft: theme.spacing(2),
    },
  }),
};

const CommentArea = createWithStyles(styles.CommentArea)(ArcView);
const CollapsedControl = createWithStyles(styles.CollapsedControl)(ArcView);
const CollapsedText = createWithStyles(styles.CollapsedText)(ArcView);

class NewsfeedActivityComments extends React.PureComponent {
  static propTypes = {
    activityHistoryView: PropTypes.bool,
    activityId: PropTypes.string,
    currentUserId: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.shape({})),
    commentCount: PropTypes.number,
    meta: PropTypes.shape({
      deleted: PropTypes.bool,
    }),
    onDelete: PropTypes.func,
    onGetActivityComments: PropTypes.func,
    onReactionDialogOpen: PropTypes.func,
    onReactionSelect: PropTypes.func,
    onQuicklinkUser: PropTypes.func,
    requestStatus: PropTypes.shape({
      NEWSFEED_DELETE_COMMENT: PropTypes.string,
      NEWSFEED_GET_ACTIVITY_COMMENTS: PropTypes.string,
    }),
  };

  static defaultProps = {
    activityHistoryView: false,
    activityId: '',
    currentUserId: '',
    comments: [],
    commentCount: 0,
    meta: {},
    onDelete: global.noop,
    onGetActivityComments: global.noop,
    onReactionDialogOpen: global.noop,
    onReactionSelect: global.noop,
    onRetrieveAllComments: global.noop,
    onQuicklinkUser: global.noop,
    requestStatus: {},
  };

  static displayName = 'NewsfeedActivityComments';

  state = {
    isCollapsed: true,
    isRequestingComments: false,
    hasLoadedAllComments: false,
  };

  componentDidUpdate(prevProps) {
    const prevPropsCommentCount = prevProps.comments.length;
    const commentCount = this.props.comments.length;
    const { hasLoadedAllComments } = this.state;

    if (prevPropsCommentCount < commentCount && !hasLoadedAllComments) {
      this.resetCollapsibleCommentState();
    }
  }

  showComments = () => {
    track('clicked show comments', {
      payload: { event: 'newsfeed:comments-expanded' },
    });
    this.setState({ isRequestingComments: true });
    this.props.onGetActivityComments(this.props.activityId);
  };

  confirmCommentDeletion = ({ id, activityId }) => {
    track('clicked delete comment', {
      payload: { event: 'newsfeed:delete-comment-dialog' },
    });
    this.setState({ deletingId: id, activityId });
  };

  closeDialog = () => {
    this.setState({ deletingId: null, activityId: null });
  };

  handleDeleteComment = () => {
    track('delete comment confirmed', {
      payload: { event: 'newsfeed:delete-comment-confirmed' },
    });
    this.props.onDelete({
      activityId: this.props.activityId,
      commentId: this.state.deletingId,
    });

    this.closeDialog();
  };

  handleClickAuthor = userId => {
    track('comment author clicked', {
      payload: { event: 'newsfeed:clicked-comment-author' },
    });
    this.props.onQuicklinkUser(userId, 'person');
  };

  resetCollapsibleCommentState = () => {
    this.setState({
      isRequestingComments: false,
      isCollapsed: true,
      hasLoadedAllComments: true,
    });
  };

  renderAction(comment) {
    const { activityId } = this.props;

    const { id } = comment;

    return (
      <NewsfeedCommentMenuContainer
        currentUserId={this.props.currentUserId}
        commentId={id}
        activityId={activityId}
        onDelete={() =>
          this.confirmCommentDeletion({
            id: comment.id,
            activityId,
          })
        }
        onReactionSelect={this.props.onReactionSelect}
        userCreated={comment.userCreated}
        reactions={comment.reactions}
      />
    );
  }

  renderComment = comment => {
    const { meta } = this.props;

    const {
      id,
      createdAt,
      readableDate,
      userImage,
      userBadge,
      userId,
      userName,
      content,
    } = comment;

    if (meta.deleted) {
      return null;
    }

    const displayDate = readableDate
      ? `${readableDate} ago`
      : moment(createdAt).fromNow();

    return (
      <ArcView
        key={id}
        role="listitem"
        data-testid="NewsfeedActivityComments-Comment"
        row
        marginTop="8"
        align="flex-start"
      >
        {userImage ? (
          <Portrait
            url={userImage}
            size={40}
            onClick={() => this.handleClickAuthor(userId)}
            badge={userBadge}
            badgeSize={20}
            offset={-8}
          />
        ) : (
          <ArcView
            alignSelf="flex-start"
            padding="8"
            border="default"
            borderWidth="1"
          >
            <BlankProfileIcon color="disabled" />
          </ArcView>
        )}
        <ArcView marginLeft="12" flexGrow="1" flexShrink="1">
          <ArcView row align="flex-end">
            <ArcText
              onClick={() => this.handleClickAuthor(userId)}
              color="blue"
              cursor="pointer"
              size="14px"
              marginRight="4"
            >
              {userName}
            </ArcText>
            <ArcText color="grey600" size="12px" marginRight="8">
              {displayDate}
            </ArcText>
          </ArcView>
          <ArcMarkdownContent content={content} variant="light" />
          <ArcView flexGrow={1}>
            <NewsfeedCommentReactions
              currentUserId={this.props.currentUserId}
              onClick={() => this.props.onReactionDialogOpen(id)}
              reactions={comment.reactions}
            />
          </ArcView>
        </ArcView>

        {!this.props.activityHistoryView && this.renderAction(comment)}
      </ArcView>
    );
  };

  render() {
    const { comments, commentCount } = this.props;

    const {
      isRequestingComments,
      isCollapsed,
      hasLoadedAllComments,
    } = this.state;

    const uncollapsedCommentCount = comments && commentCount - 5;

    const visibleComments =
      comments && hasLoadedAllComments ? comments : comments.slice(-5);

    const hasMoreComments =
      uncollapsedCommentCount > 0 && isCollapsed && !hasLoadedAllComments;

    return (
      <React.Fragment>
        <CommentArea role="list" data-testid="NewsfeedActivityComments">
          {hasMoreComments && (
            <CollapsedControl
              data-testid="NewsfeedActivityComments-MoreButton"
              onClick={this.showComments}
            >
              <CommentIcon />
              <CollapsedText>
                <ArcLoaderButton
                  colour={'BLUE'}
                  component={ArcLink}
                  label={`View ${uncollapsedCommentCount} more ${pluralize(
                    'comment',
                    uncollapsedCommentCount,
                  )}`}
                  loadingState={isRequestingComments ? 'REQUEST' : 'DEFAULT'}
                />
              </CollapsedText>
            </CollapsedControl>
          )}

          {visibleComments.map(this.renderComment)}
        </CommentArea>

        <ArcDeleteDialog
          onDelete={this.handleDeleteComment}
          open={!!this.state.deletingId}
          onClose={this.closeDialog}
        />
      </React.Fragment>
    );
  }
}

export default NewsfeedActivityComments;
