import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCurrentUserPermission } from 'arcade-frontend-core/src/reducers/user';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';

import PersonDetailsDialog from '../components/PersonDetailsDialog';
import { getQuicklinkUser } from '../reducers/quicklinkUser';
import { actions } from '../actions';

class QuicklinkContainer extends React.PureComponent {
  static displayName = 'QuicklinkContainer';

  static propTypes = {
    canAwardChests: PropTypes.bool,
    canAwardTokens: PropTypes.bool,
    canClawbackTokens: PropTypes.bool,

    quicklinkUser: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      image: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      team: PropTypes.string,
      is_self: PropTypes.bool,
      level: PropTypes.number,
    }),

    showQuicklinksUser: PropTypes.func.isRequired,
    resetQuicklinksUser: PropTypes.func.isRequired,
  };

  static defaultProps = {
    canAwardChests: false,
    canAwardTokens: false,
    canClawbackTokens: false,
    quicklinkUser: null,
  };

  constructor(props) {
    super(props);
    if (window?.ngRootScope) {
      this.rootScopeListener = window?.ngRootScope.$on(
        'arcade:react:quicklinks',
        this.handleShowQuicklinksUser,
      );
    }

    window.quicklink = (id, type) => {
      if (type === 'person') {
        return this.props.showQuicklinksUser(id);
      }

      return window.quicklinkAngular(id, type);
    };
  }

  componentWillUnmount() {
    if (this.rootScopeListener) {
      this.rootScopeListener();
    }
  }

  handleShowQuicklinksUser = (event, userId) => {
    this.props.showQuicklinksUser(userId);
  };

  render() {
    if (!this.props.quicklinkUser) {
      return null;
    }

    return (
      <PersonDetailsDialog
        open
        onClose={this.props.resetQuicklinksUser}
        person={this.props.quicklinkUser}
        canAwardChests={this.props.canAwardChests}
        canAwardTokens={this.props.canAwardTokens}
        canClawbackTokens={this.props.canClawbackTokens}
      />
    );
  }
}

const getState = (state, props) => ({
  canAwardChests: getCurrentUserPermission(state, PERMISSIONS.AWARD_CHESTS),
  canAwardTokens: getCurrentUserPermission(
    state,
    PERMISSIONS.DISTRIBUTE_TOKENS,
  ),
  canClawbackTokens: getCurrentUserPermission(
    state,
    PERMISSIONS.CLAWBACK_TOKENS,
  ),
  quicklinkUser: getQuicklinkUser(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      showQuicklinksUser: actions.showQuicklinksUser,
      resetQuicklinksUser: actions.resetQuicklinksUser,
    },
    dispatch,
  );

export default connect(getState, getActions)(QuicklinkContainer);
