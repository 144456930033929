import React from 'react';
import { Path } from 'react-primitives-svg';
import Icon from '../Icon';

const RibbonAlt1 = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.961 14.763l3.128 5.417c.138.238.028.4-.243.35l-3.358-.568-1.164 3.2c-.094.258-.286.276-.426.04l-3-5.073M4.039 14.763L.911 20.18c-.138.238-.028.4.243.35l3.358-.568 1.165 3.2c.094.259.286.276.426.04l3-5.067"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20 8.632c0-1.14-.695-2.166-1.755-2.587a2.784 2.784 0 00-3.658-3.658 2.784 2.784 0 00-5.173 0 2.784 2.784 0 00-3.661 3.658 2.783 2.783 0 000 5.173 2.784 2.784 0 003.658 3.658 2.783 2.783 0 005.173 0 2.784 2.784 0 003.658-3.658A2.781 2.781 0 0020 8.632z"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16 6.132l-4.719 4.72a.75.75 0 01-1.061 0L8.5 9.132"
    />
  </Icon>
);

RibbonAlt1.displayName = 'RibbonAlt1';

export default RibbonAlt1;
