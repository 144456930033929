// import React from 'react';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const getStyles = (theme, color, prefix = '.dp') => ({
  [`${prefix}:before`]: {
    background: theme.palette[color].main,
  },

  [`
    ${prefix}-cal-month,
    ${prefix}-cal-year,
    ${prefix}-day,
    ${prefix}-month,
    ${prefix}-year
  `]: {
    borderRadius: theme.isRounded ? 2 : 0,
    color: theme.palette.text[color],
  },

  [`
    ${prefix}-close:hover,
    ${prefix}-close:focus,
    ${prefix}-clear:hover,
    ${prefix}-clear:focus,
    ${prefix}-today:hover,
    ${prefix}-today:focus,
    ${prefix}-next:hover,
    ${prefix}-next:focus,
    ${prefix}-prev:hover,
    ${prefix}-prev:focus,
    ${prefix}-cal-month:focus,
    ${prefix}-cal-month:hover,
    ${prefix}-cal-year:hover,
    ${prefix}-cal-year:focus
  `]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.text[color],
  },

  [`
    ${prefix}-day:not(.dp-day-disabled):not(.dp-selected):hover,
    ${prefix}-month:hover,
    ${prefix}-year:hover,
    ${prefix}-day:not(.dp-day-disabled):not(.dp-selected):focus,
    ${prefix}-month:focus,
    ${prefix}-year:focus
  `]: {
    background: theme.palette.action.hover,
    color: theme.palette[color].main,
    cursor: 'pointer',
  },


  [`
    ${prefix}-current:not(.dp-day-disabled):focus,
    ${prefix}-current:not(.dp-day-disabled):hover,
    ${prefix}-selected:hover,
    ${prefix}-selected:focus,
    ${prefix}-selected
  `]: {
    background: theme.palette[color].main,
    color: '#fff',
  },

  [`${prefix}-day-today`]: {
    color: theme.palette[color].main,
  },
});

const styles = theme => ({
  '@global': {
    '.dp-permanent': {
      width: '100%',
      maxWidth: 400,
    },
    '.dp-modal': {
      zIndex: theme.zIndex.modal,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      animationDuration: 500,
      animationFillMode: 'both',
      animationName: 'fadeIn',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },

    '.dp-modal .dp': {
      animation: 'none',
      animationDuration: 500,
      animationFillMode: 'both',
      animationName: 'fadeInUp',
      borderRadius: theme.isRounded ? 4 : 0,
      position: 'static',
      width: '100%',
      maxWidth: '100%',
      transform: 'none',
      userSelect: 'none',
    },

    [theme.breakpoints.up('sm')]: {
      '.dp-modal': {
        alignItems: 'center',
      },

      '.dp-modal .dp': {
        maxWidth: 600,
      },
    },

    '.dp-cal-header': {
      backgroundColor: theme.palette.common.white,
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: 0,
    },

    '.dp-cal-header button': {
      cursor: 'pointer',
    },

    '.dp-next, .dp-prev': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderRadius: theme.isRounded ? 2 : 0,
      top: 0,
      textTransform: 'uppercase',
      fontSize: 10,
      width: 64,
      height: '100%',
      padding: 8,
      paddingTop: 0,
    },

    '.dp-prev': {
      borderRight: `1px solid ${theme.palette.divider}`,
      left: 0,
    },

    '.dp-next': {
      borderLeft: `1px solid ${theme.palette.divider}`,
      right: 0,
    },

    '.dp-prev:before': {
      margin: '0 -8px 12px 0',
    },

    '.dp-next:before': {
      margin: '0 0 12px -8px',
    },

    '.dp-cal-month, .dp-cal-year': {
      paddingTop: 16,
      paddingBottom: 16,
    },

    '.dp-edge-day, .dp-edge-day.dp-day-disabled, .dp-edge-day.dp-day-disabled:hover': {
      visibility: 'hidden',
      opacity: 0,
    },
    '.dp-day.dp-current.dp-selected': {
      cursor: 'pointer',
    },

    [`
      .dp-day-disabled,
      .dp-day-disabled:hover,
      .dp-day-disabled:focused,
      .dp-day.dp-current.dp-day-disabled,
      .dp-day.dp-current.dp-day-disabled:focused,
      .dp-day.dp-current.dp-day-disabled:hover
    `]: {
      background: 'transparent',
      color: theme.palette.text.disabled,
    },

    '.dp-day-today.dp-selected': {
      color: theme.palette.common.white,
    },

    '.dp-day-today:after': {
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
    },

    [`
      .dp-day-disabled,
      .dp-day-disabled:focus,
      .dp-day-disabled:hover
    `]: {
      background: 'transparent',
      color: theme.palette.text.disabled,
      opacity: 0.8,
    },

    '.dp-cal-footer': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.common.white,
      borderTop: `1px solid ${theme.palette.divider}`,
    },

    '.dp-cal-footer button': {
      textTransform: 'uppercase',
      cursor: 'pointer',
      height: 36,
    },

    '.dp-clear': {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
    },

    '.dp-years': {
      height: 300,
    },

    '.dp-years .dp-selected': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },

    [`
      .dp-years .dp-current:not(.dp-disabled),
      .dp-months .dp-current:not(.dp-disabled),
      .dp-days .dp-current:not(.dp-disabled)
    `]: {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
    },

    ...getStyles(theme, 'secondary'),
    ...getStyles(theme, 'danger', '.dp-has-error .dp'),
  },
});

const TinyDatePickerJss = () => null;
export default withStyles(styles, { name: 'TinyDatePickerJss' })(TinyDatePickerJss);
