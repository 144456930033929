import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const initialState = {
  isViewing: false,
  type: undefined,
  title: undefined,
  content: undefined,
};

const handleSetIsViewing = (state, action) => {
  const { isViewing } = action.payload;

  if (!isViewing) {
    return {
      ...state,
      isViewing: false,
    };
  }

  return state;
};

const handleSetAlert = (state, action) => ({
  isViewing: true,
  type: action.payload.type,
  title: action.payload.title,
  content: action.payload.content,
});

const handlers = {
  [types.SET_ALERT]: handleSetAlert,
  [types.SET_IS_VIEWING_ALERT]: handleSetIsViewing,
};

const alert = createReducer(initialState, handlers);

export default alert;

export const getAlertState = state => ({
  open: state.app.alert.isViewing,
  type: state.app.alert.type,
  title: state.app.alert.title,
  content: state.app.alert.content,
});
