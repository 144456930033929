import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const Caret = props => (
  <Icon {...props}>
    <Path fill="currentColor" d="M7 10l5 5 5-5z" />
  </Icon>
);

export default Caret;
