import { actions } from 'arcade-frontend-core/src/actions';
import RollbarClient from 'arcade-frontend-ui/src/providers/RollbarProvider/client';
import resource from 'arcade-frontend-ui/src/helpers/utils/resource';
import * as supportWidget from './helpers/supportWidget';
import store from './store';

if (window && window.document) {
  const handleMessage = event => {
    const { data } = event;

    if (!data || typeof data !== 'string') {
      return null;
    }

    if (data === 'ng.cordova.resume') {
      RollbarClient.info(
        '[features/postMessageListener] calling appFeaturesRequest',
        {
          resourceEndpoint: resource.endpoint,
        },
      );
      return store.dispatch(actions.appFeaturesRequest());
    }

    let [destination, type, ...payload] = data.split('.'); // eslint-disable-line prefer-const
    payload = payload.join('.');

    if (destination !== 'rw') {
      return null;
    }

    switch (type) {
      case 'dispatch':
        return store.dispatch(JSON.parse(payload));
      case 'supportWidget':
        if (payload === 'open') {
          supportWidget.openSupportWidget();
        } else {
          supportWidget.closeSupportWidget();
        }

        return null;
      default:
        return null;
    }
  };

  window.addEventListener('message', handleMessage);
  document.addEventListener('message', handleMessage);
}
