import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const Expand = props => (
  <Icon {...props}>
    <Path
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.5 7.5h-5a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1v-4.97m-9 2.984l12-12m-6 0h6v6"
    />
  </Icon>
);

export default Expand;
