import React from 'react';
import PropTypes from 'prop-types';

import { ArcFormDateRangePickerContainer } from 'arcade-frontend-widgets/src/containers/ArcFormDateRangePickerContainer';

import ArcView from '../../primitives/ArcView';

import ArcTransactionLogFilterSelector from './ArcTransactionLogFilterSelector';
import ArcTransactionLogTile from './ArcTransactionLogTile';
import { PeopleFilterContainer } from '../../../../features/src/containers/manage/PeopleFilterContainer';

import createWithStyles from '../../styles/createWithStyles';
import ArcEntityChip from '../ArcEntityChip';
import ArcText from '../../primitives/ArcText';

const styles = {
  ArcTransactionLogFilterView: theme => ({
    root: {
      padding: theme.spacing(1),
      overflowX: 'auto',

      '& .ArcFormDateRangePickerContainer': {
        order: 1,
      },

      '& .ArcTransactionLogFilterSelector': {
        marginLeft: theme.spacing(1),
        order: 0,
      },

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row-reverse',

        '& .ArcFormDateRangePickerContainer': {
          order: 0,
        },

        '& .ArcTransactionLogFilterSelector': {
          order: 1,
        },
      },
    },
  }),
  UserFilterWrapper: () => ({
    root: {
      margin: '5px 10px',
    },
  }),
  UserFilterText: theme => ({
    root: {
      marginLeft: theme.spacing(1),
      fontSize: theme.font.getFontSize(0.65),
      color: theme.palette.common.black,
      textTransform: 'uppercase',
    },
  }),
  SelectWrapper: theme => ({
    root: {
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        width: 'auto',
      },
    },
  }),
  DisplayWrapper: theme => ({
    root: {
      display: 'flex',
      marginTop: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-start',
        marginTop: '0',
        marginLeft: theme.spacing(1),
      },
    },
  }),
};

const ArcTransactionLogFilterView = createWithStyles(
  styles.ArcTransactionLogFilterView,
)(ArcView);
const UserFilterWrapper = createWithStyles(styles.UserFilterWrapper)(ArcView);
const UserFilterText = createWithStyles(styles.UserFilterText)(ArcText);
const SelectWrapper = createWithStyles(styles.SelectWrapper)(ArcText);
const DisplayWrapper = createWithStyles(styles.DisplayWrapper)(ArcText);

const LABELS_BY_SUMMARY = {
  incoming: 'Deposited',
  outgoing: 'Distributed',
  change: 'Difference',
};

/** **Description**
 *
 * Renders date and account filter options for `<ArcTransactionLog />`
 *
 * **Requirements**
 *   - must be a child of `<ArcTransactionLog />`
 */
class ArcTransactionLogFilter extends React.PureComponent {
  static displayName = 'ArcTransactionLogFilter';

  static propTypes = {
    dateRange: PropTypes.shape({
      type: PropTypes.oneOf(['time period', 'date range']),
      timePeriod: PropTypes.string,
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
    }),
    onDateRangeChange: PropTypes.func,
    onChangeFilter: PropTypes.func,
    onDeleteUserFilter: PropTypes.func,
    onPeopleChange: PropTypes.func,
    transferTypes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
      }),
    ),
    filter: PropTypes.arrayOf(PropTypes.string),
    summary: PropTypes.shape({
      total: PropTypes.number,
      incoming: PropTypes.number,
      outgoing: PropTypes.number,
      change: PropTypes.number,
    }),
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      profileImage: PropTypes.string,
      monthlyLimit: PropTypes.number,
      remainingBalance: PropTypes.number,
    }),
  };

  static defaultProps = {
    dateRange: undefined,
    onDateRangeChange: global.noop,
    onChangeFilter: global.noop,
    onDeleteUserFilter: global.noop,
    onPeopleChange: global.noop,
    dateType: 'currentMonth',
    transferTypes: [],
    filter: [],
    summary: {},
    user: {},
  };

  summaryItem = key => {
    const label = LABELS_BY_SUMMARY[key];
    let value = this.props.summary[key];

    if (typeof value !== 'undefined' && value.toLocaleString) {
      value = value.toLocaleString();
    }

    return (
      <ArcView key={key} justify="flex-end" marginRight="16" marginTop="16">
        <ArcTransactionLogTile
          label={label}
          value={value}
          isFetching={!value}
        />
      </ArcView>
    );
  };

  render() {
    const {
      transferTypes,
      filter,
      user,
      dateRange,
      onDateRangeChange,
      onDeleteUserFilter,
      onPeopleChange,
      onChangeFilter,
    } = this.props;

    const hasInitialUserFilter = !!user && !!user.id;

    return (
      <ArcTransactionLogFilterView>
        <ArcView row align="center" justify="space-between">
          {hasInitialUserFilter && (
            <UserFilterWrapper>
              <UserFilterText>{'Filtered by user'}</UserFilterText>

              <ArcEntityChip
                onDelete={onDeleteUserFilter}
                name={user.name}
                imageUrl={user.profileImage}
              />
            </UserFilterWrapper>
          )}
          <SelectWrapper>
            {!hasInitialUserFilter && (
              <PeopleFilterContainer onSelect={onPeopleChange} />
            )}

            <DisplayWrapper>
              <ArcFormDateRangePickerContainer
                value={dateRange}
                onChange={onDateRangeChange}
              />

              <ArcView className="ArcTransactionLogFilterSelector">
                <ArcTransactionLogFilterSelector
                  transferTypes={transferTypes}
                  filter={filter}
                  onChange={onChangeFilter}
                />
              </ArcView>
            </DisplayWrapper>
          </SelectWrapper>
        </ArcView>
        <ArcView flexGrow="100" />
        <ArcView row flexGrow="1">
          {Object.keys(LABELS_BY_SUMMARY).map(key => this.summaryItem(key))}
        </ArcView>
      </ArcTransactionLogFilterView>
    );
  }
}

export default ArcTransactionLogFilter;
