import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/games';

const handleManageGamesEligiblePeopleIndexAction = (state, action) => {
  if (action.type === types.MANAGE_GAMES_ELIGIBLE_PEOPLE_INDEX.REQUEST) {
    return { ...state, eligiblePeople: true };
  }
  return { ...state, eligiblePeople: false };
};

const initialState = false;

const handlers = {
  [types.MANAGE_GAMES_ELIGIBLE_PEOPLE_INDEX.REQUEST]:
    handleManageGamesEligiblePeopleIndexAction,
  [types.MANAGE_GAMES_ELIGIBLE_PEOPLE_INDEX.SUCCESS]:
    handleManageGamesEligiblePeopleIndexAction,
  [types.MANAGE_GAMES_ELIGIBLE_PEOPLE_INDEX.FAILURE]:
    handleManageGamesEligiblePeopleIndexAction,
};

const isFetchingPeople = createReducer(initialState, handlers);

export default isFetchingPeople;

export const getIsFetchingPeople = state => state.manage.games.isFetchingPeople;
