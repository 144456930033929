import React from 'react';
import PropTypes from 'prop-types';

import ArcView from '../../primitives/ArcView';
import { createWithStyles } from '../../styles';

const styles = {
  NumberContainer: () => ({
    root: {
      flexShrink: 1,
      justifyContent: 'center',

      '& > svg polygon': {
        transition: 'fill-opacity 300ms cubic-bezier(0.42, 0, 0.65, 1.22)',
      },

      '& > svg': {
        width: '100%',
        // maxWidth: '40px',
      },
    },
  }),
};

const NumberContainer = createWithStyles(styles.NumberContainer)(ArcView);

const ArcScoreboardDollar = props => (
  <NumberContainer style={{ width: props.width }}>
    <svg
      viewBox="0 0 64 64"
    >
      <g
        fill="#D8D8D8"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
      >
        <path d="M18.1428571,27.4285714 L9,27.4285714 L9,18.2857143 L18.1428571,18.2857143 L18.1428571,27.4285714 Z M45.5714286,36.5714286 L54.7142857,36.5714286 L54.7142857,45.7142857 L45.5714286,45.7142857 L45.5714286,36.5714286 Z M36.4285714,36.5714286 L36.4285714,45.7142857 L45.5714286,45.7142857 L45.5714286,54.8571429 L36.4285714,54.8571429 L36.4285714,64 L27.2857143,64 L27.2857143,54.8571429 L9,54.8571429 L9,45.7142857 L27.2857143,45.7142857 L27.2857143,36.5714286 L18.1428571,36.5714286 L18.1428571,27.4285714 L27.2857143,27.4285714 L27.2857143,18.2857143 L18.1428571,18.2857143 L18.1428571,9.14285714 L27.2857143,9.14285714 L27.2857143,0 L36.4285714,0 L36.4285714,9.14285714 L54.7142857,9.14285714 L54.7142857,18.2857143 L36.4285714,18.2857143 L36.4285714,27.4285714 L45.5714286,27.4285714 L45.5714286,36.5714286 L36.4285714,36.5714286 Z" id="$" />
      </g>
    </svg>
  </NumberContainer>
);

ArcScoreboardDollar.propTypes = {
  width: PropTypes.number,
};

ArcScoreboardDollar.defaultProps = {
  width: undefined,
};

export default ArcScoreboardDollar;
