import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import ArcStyles, { createWithStyles } from '../../styles';

const styles = {
  ArcMainView: theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#f8f8f8',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transform: 'translateX(-100%)',

      transitionDuration: '.2s',
      transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
      transitionProperty: 'transform',

      // While the default min-width value is 0 (zero), for flex items it is auto.
      // This can make block elements take up much more space than desired,
      // even stretching their container beyond the screen edge on small screens.
      // https://makandracards.com/makandra/66994-css-flex-and-min-width
      minWidth: 0,
    },

    hasNav: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: ArcStyles.NAV_WIDTH,
      },
    },

    hasNoPaddingLeftLaunch: {
      paddingLeft: 0,
    },

    hasScroll: ArcStyles.rules.scroll,

    isViewing: {
      flexGrow: 1,
      flexShrink: 1,
      width: '100%',

      transform: 'translateX(0)',
    },
  }),
};

const ArcMainView = createWithStyles(styles.ArcMainView)(ArcView);

ArcMainView.propTypes = {
  isViewing: ArcPropTypes.bool,
};

ArcMainView.defaultProps = {
  isViewing: false,
};

export default ArcMainView;
