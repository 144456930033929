import React from 'react';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';

const styleImgContainer = {
  width: 24,
  height: 24,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 8,
};

const styleImg = row => ({
  width: 24,
  height: 24,
  backgroundSize: 'cover',
  backgroundPosition: '50% center',
  backgroundImage: (row && row.original) ? `url(${row.original.imageUrl})` : undefined,
});

const styleTeam = {
  fontSize: '0.75em',
  lineHeight: '1.2em',
  fontWeight: 400,
};

const ArcTableCellPerson = row => (
  <ArcView row align="center">
    <ArcView style={styleImgContainer}>
      <ArcView style={styleImg(row)} />
    </ArcView>
    <ArcView>
      <ArcView>{row.value || 'Person Name'}</ArcView>
      <ArcView>
        <ArcText className="show-small" style={styleTeam}>{row.original ? row.original.teamName : 'Team Name'}</ArcText>
      </ArcView>
    </ArcView>
  </ArcView>
);

export default ArcTableCellPerson;
