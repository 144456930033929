import { makeApiActions } from 'arcade-frontend-ui';
import { makeDateFilterParams } from 'arcade-frontend-core/src/actions/helpers';

import normalizers from './normalizers';

const index = params => ({
  method: 'GET',
  url: '/manager/kpi_groups',
  params: { ...makeDateFilterParams(params) },
  normalizer: normalizers.fromIndex,
  rootKey: 'kpi_groups',
});

const activeIndex = params => ({
  method: 'GET',
  url: '/manager/v4/kpi_groups/not_archived',
  params: { ...makeDateFilterParams(params), user: params.people },
  normalizer: normalizers.fromIndex,
  rootKey: 'kpi_groups',
});

const archivedIndex = params => ({
  method: 'GET',
  url: '/manager/v4/kpi_groups/archived',
  params: { ...makeDateFilterParams(params), user: params.people },
  normalizer: normalizers.fromIndex,
  rootKey: 'kpi_groups',
});

const show = ({ id }) => ({
  method: 'GET',
  url: `/manager/kpi_groups/${id}`,
  rootKey: 'kpi_group',
  normalizer: normalizers.fromShow,
});

const edit = show;

const create = ({ form }) => ({
  method: 'POST',
  url: '/manager/kpi_groups',
  data: normalizers.toUpdate(form),
  rootKey: 'kpi_group',
  normalizer: normalizers.fromCreate,
});

const update = ({ id, form }) => ({
  method: 'PUT',
  url: `/manager/kpi_groups/${id}`,
  data: normalizers.toUpdate(form),
});

const archive = ({ id }) => ({
  method: 'DELETE',
  url: `/manager/kpi_groups/${id}`,
});

const restore = ({ id }) => ({
  method: 'PUT',
  url: `/manager/kpi_groups/${id}/restore`,
});

const teamsIndex = () => ({
  method: 'GET',
  url: '/v2/teams',
  normalizer: normalizers.fromTeamsIndex,
  rootKey: 'teams',
});

const activitiesIndex = ({ metricId, people, ...rest }) => ({
  method: 'GET',
  url: '/manager/kpi_groups/sales_index',
  normalizer: normalizers.fromActivitiesIndex,
  params: {
    id: metricId,
    user: people,
    ...makeDateFilterParams(rest),
  },
  rootKey: 'kpi_groups',
  rootData: {
    metricId,
  },
});

const rankingsIndex = ({ metricId, people, ...rest }) => ({
  method: 'GET',
  url: '/manager/user_targets/ranked',
  normalizer: normalizers.fromRankingsIndex,
  params: {
    kpi_id: metricId,
    user: people,
    ...makeDateFilterParams(rest),
  },
  rootKey: 'ranked_users',
  rootData: {
    metricId,
  },
});

const endpoints = {
  index,
  activeIndex,
  archivedIndex,
  show,
  edit,
  create,
  update,
  archive,
  restore,

  teamsIndex,
  activitiesIndex,
  rankingsIndex,
};

const api = makeApiActions(endpoints);

export default api;
