import { ArcPropTypes } from 'arcade-frontend-ui';

export default {
  id: ArcPropTypes.string,
  isFetching: ArcPropTypes.bool,
  name: ArcPropTypes.string,
  imageUrl: ArcPropTypes.string,
  variants: ArcPropTypes.arrayOf(
    ArcPropTypes.shape({
      label: ArcPropTypes.oneOfType([ArcPropTypes.number, ArcPropTypes.string]),
      value: ArcPropTypes.string,
      cost: ArcPropTypes.number,
    }),
  ),
  rewardTokenCost: ArcPropTypes.number,
};
