import React from 'react';
import cx from 'classnames';

import {
  ArcPropTypes,
  ArcButton,
  ArcButtonGroup,
  ArcGrid,
  Typography,
  ArcFormField,
  ArcView,
  ArcText,
} from 'arcade-frontend-ui';

import MetricCard from '../../../components/metrics/MetricCard';

import ArcadeHashtag from '../../../assets/ArcadeHashtag';
import ArcadeDollar from '../../../assets/ArcadeDollar';
import ArcadePercentage from '../../../assets/ArcadePercentage';

const UNIT_TYPES = {
  DOLLAR: '$',
  NUMBER: '#',
  PERCENT: '%',
};

const ICONS_BY_UNIT_TYPE = {
  [UNIT_TYPES.DOLLAR]: ArcadeDollar,
  [UNIT_TYPES.NUMBER]: ArcadeHashtag,
  [UNIT_TYPES.PERCENT]: ArcadePercentage,
};

const UNITS = [UNIT_TYPES.NUMBER, UNIT_TYPES.DOLLAR, UNIT_TYPES.PERCENT];

const styleButton = {
  width: 72,
  height: 72,
  marginRight: 16,
  marginBottom: 32,
};

const styleMuted = {
  opacity: 0.5,
};

const FIELDS = [
  'name',
  'verb',
  'unit',
  'canAddSales',
  'includesIntegrationIdentifier',
  'integrationIdentifier',
  'average',
];

const placeholderName = (
  <ArcText style={styleMuted}>
    {'Name'}
  </ArcText>
);

const placeholderVerb = (
  <ArcText style={styleMuted}>
    {'Verb'}
  </ArcText>
);

class MetricsCreateStepOneForm extends React.PureComponent {
  static FIELDS = FIELDS;

  static propTypes = {
    setFieldTouched: ArcPropTypes.func.isRequired,
    setFieldValue: ArcPropTypes.func.isRequired,
    values: ArcPropTypes.objectOf(ArcPropTypes.any).isRequired,
  };

  componentWillUnmount() {
    FIELDS.forEach((field) => {
      this.props.setFieldTouched(field, true, false);
    });
  }

  renderUnit = (type) => {
    const Icon = ICONS_BY_UNIT_TYPE[type];

    return (
      <ArcButton
        key={type}
        className={cx([
          this.props.values.unit === type && 'isActive',
        ])}
        onClick={() => {
          this.props.setFieldValue('unit', type);
        }}
        style={styleButton}
      >
        <ArcView align="center">
          <Icon
            width="48px"
            height="48px"
            style={this.props.values.unit !== type ? styleMuted : undefined}
          />
        </ArcView>
      </ArcButton>
    );
  };

  render() {
    return (
      <ArcGrid
        className="animated fadeIn"
        component={ArcView}
        container
        position="relative"
        spacing={2}
        style={{ maxWidth: 600 }}
        wrap="nowrap"
      >
        <ArcGrid item xs={12}>
          <ArcFormField
            type="text"
            name="name"
            description="e.g. Revenue, Packages, any activity"
            fullWidth
            label="Name"
            validations={{
              isRequired: true,
              maxLength: 30,
            }}
            autoComplete="off"
          />
        </ArcGrid>

        <ArcGrid item xs={12}>
          <ArcFormField
            type="text"
            name="verb"
            description="e.g. Sold, Collected, Created"
            fullWidth
            label="Verb"
            validations={{
              isRequired: true,
              maxLength: 30,
            }}
            autoComplete="off"
          />
        </ArcGrid>

        <ArcGrid item xs={12}>
          <Typography variant="h5">
            What is the
            {' '}
            <i>unit</i>
            {' '}
for this metric?
          </Typography>
        </ArcGrid>

        <ArcGrid item xs={12}>
          <ArcButtonGroup wrap="wrap">
            {UNITS.map(this.renderUnit)}
          </ArcButtonGroup>
        </ArcGrid>

        <ArcGrid item xs={12}>
          <Typography variant="h5">
            Can people
            {' '}
            <i>manually</i>
            {' '}
add sales?
          </Typography>
        </ArcGrid>

        <ArcGrid item xs={12}>
          <ArcFormField
            data-testid="ArcFormField-AllowManualEntry"
            type="checkbox"
            name="canAddSales"
            label="Yes, allow manual entry"
          />
        </ArcGrid>

        <ArcGrid item xs={12}>
          <Typography variant="h5">
            Is this an average-style metric?
          </Typography>

          <Typography variant="body2">
            Selecting this means that we will calculate this metric as an average metric.
            The number we expect to receive (either via user input or integration) is
            the current average.
          </Typography>
        </ArcGrid>

        <ArcGrid item xs={12}>
          <ArcFormField
            type="checkbox"
            name="average"
            label="Yes, this metric is based on averages"
          />
        </ArcGrid>

        <ArcGrid item xs={12}>
          <Typography variant="h5">
            Does this metric have an integration identifier?
          </Typography>
        </ArcGrid>

        <ArcGrid item xs={12}>
          <ArcFormField
            type="checkbox"
            name="includesIntegrationIdentifier"
            label="Yes, I have an integration identifier"
          />
        </ArcGrid>

        { this.props.values.includesIntegrationIdentifier
        && (
        <ArcGrid item xs={12}>
          <ArcFormField
            type="text"
            name="integrationIdentifier"
            description="e.g. sales_made"
            fullWidth
            label="Integration Identifier"
            validations={{
              maxLength: 30,
            }}
            autoComplete="off"
          />
        </ArcGrid>
        )
        }

        <ArcGrid item xs={12}>
          <MetricCard
            name={this.props.values.name || placeholderName}
            verb={this.props.values.verb || placeholderVerb}
            symbol={this.props.values.unit}
            progress={100}
            hideNav
          />
        </ArcGrid>
      </ArcGrid>
    );
  }
}


export default MetricsCreateStepOneForm;
