import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
import ArcIconButton from 'arcade-frontend-ui/src/elements/ArcIconButton';
import Notifications from 'arcade-frontend-ui/src/icons/Notifications';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Menu from '@material-ui/icons/Menu';
import { ROUTE_HOME } from 'arcade-frontend-core/src/types/routes';

import { getLocation } from '../../reducers/location';
import titles from '../../routes/titles';

const HEIGHT = 44;

const isRTPOS = process.env.ARCADE_WHITELABEL_ID === 'rtpos';

const useStyles = makeStyles(theme => ({
  appBarRoot: {
    position: 'relative',
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    boxShadow: '-1px 1px 6px rgba(120,120,120,.18)',
    borderBottom: '1px solid #e0e0e0',
    height: HEIGHT,
    overflow: 'hidden',
  },

  appBarToolbar: {
    minHeight: HEIGHT,
  },

  appBarTitle: {
    fontSize: 20,
    lineHeight: `${HEIGHT}px`,
    flex: 1,
    textAlign: 'center',
    [theme.breakpoints.up(1025)]: {
      textAlign: 'left',
    },
  },

  menuButton: {
    marginLeft: -16,
    marginRight: 10,
    width: 64,
    height: HEIGHT,
    [theme.breakpoints.up('sm')]: {
      marginLeft: -24,
    },
  },

  menuIcon: {
    width: 24,
    height: 24,
  },

  notificationButton: {
    minWidth: 64,
    padding: 4,
  },
}));

export default function ArcAppBar({
  isViewingMain,
  onMenuClick,
  onNotificationClick,
}) {
  const location = useSelector(getLocation);
  const classes = useStyles();

  const [count, setCount] = React.useState(0);

  const handleNewNotificationsCount = (_, nextCount) => {
    if (nextCount !== count) {
      setCount(nextCount);
    }
  };

  useEffect(() => {
    let deregister;

    if (window.ngRootScope) {
      deregister = window.ngRootScope.$on(
        'arcade:new-notifications:count',
        handleNewNotificationsCount,
      );
    }
    return () => {
      if (deregister) {
        deregister();
      }
    };
  });

  const displayCount = count > 99 ? '99+' : count;

  const isViewingHome = location.type === ROUTE_HOME;

  const hasLeftMenu = !isRTPOS;

  const menuButton = !window.arcPostMessage ? (
    // Serves as a placeholder div to keep the Page Title centered on mobile
    <ArcViewBox className="DevelopmentPlatformBar__Menu" width="64px" />
  ) : (
    <ArcIconButton
      label="menu"
      onClick={onMenuClick}
      className={cx([
        'DevelopmentPlatformBar__Menu',
        isViewingMain && 'is-viewing-main',
        classes.menuButton,
      ])}
    >
      <Menu color="primary" className={cx('Menu', classes.menuIcon)} />
      <ArrowBack color="primary" className="ArrowBack" />
    </ArcIconButton>
  );

  return (
    <AppBar
      className="DevelopmentPlatformBar mdl-layout__header"
      position="static"
      classes={{
        root: classes.appBarRoot,
      }}
    >
      <Toolbar variant="dense" className={classes.appBarToolbar}>
        {hasLeftMenu ? (
          menuButton
        ) : (
          <ArcViewBox className="menu--back" position="relative" width="64px" />
        )}

        <Typography
          variant="h6"
          color="inherit"
          className={cx(classes.appBarTitle, 'mdl-layout-title')}
        >
          {titles[location.type] || `Title Missing (${location.type})`}
        </Typography>

        <ArcIconButton
          color="primary"
          className={cx([
            'header-button',
            isViewingHome && 'header-button--selected',
            classes.notificationButton,
          ])}
          onClick={onNotificationClick}
        >
          <Notifications width="24px" height="24px" />
          {count > 0 && (
            <div className="header-bubble-overlay bubble-unread--small mdl-color--red is-visible">
              {displayCount}
            </div>
          )}
        </ArcIconButton>
      </Toolbar>
    </AppBar>
  );
}

ArcAppBar.propTypes = {
  isViewingMain: PropTypes.bool,
  onMenuClick: PropTypes.func,
  onNotificationClick: PropTypes.func,
};

ArcAppBar.defaultProps = {
  isViewingMain: false,
  onMenuClick: global.noop,
  onNotificationClick: global.noop,
};

ArcAppBar.HEIGHT = HEIGHT;
