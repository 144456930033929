import React from 'react';

import { ArcMainView, ArcView } from 'arcade-frontend-ui';

import RewardsAwardFormContainer from '../../containers/RewardsAwardFormContainer';

const RewardAwardsView = () => (
  <ArcMainView isViewing fullHeight hasScroll={false}>
    <ArcView fullWidth fullHeight color="paper">
      <RewardsAwardFormContainer />
    </ArcView>
  </ArcMainView>
);

export default RewardAwardsView;
