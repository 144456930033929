import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const Cap = props => (
  <Icon {...props}>
    <Path
      stroke="currentColor"
      fill="transparent"
      d="M22.936 11.703h0l.007.007a.016.016 0 01.003.004.016.016 0 01-.003.019h0l-.008.007-3.344 3.356a.04.04 0 01-.048-.049l2.971-2.96.355-.353-.354-.354L12.075.928l-.354-.354-.354.354L.928 11.38l-.354.354.356.354 10.44 10.38.353.351.353-.352 4.8-4.8.352-.352-.35-.353L13.6 13.65l-.294-.298-.345.237c-.364.25-.796.383-1.238.38v0h-.003a2.248 2.248 0 112.248-2.238v.003c0 .438-.132.867-.38 1.228l-.237.345.298.294 3.706 3.658-.003.018v5.593a.04.04 0 01-.068 0V17.39l-.853.854-4.692 4.692h0l-.007.007a.016.016 0 01-.023 0h0l-.007-.007L.508 11.74s0 0 0 0a.028.028 0 010-.04s0 0 0 0L11.702.509s0 0 0 0c.01-.01.028-.01.039 0 0 0 0 0 0 0l11.195 11.195z"
    />
  </Icon>
);

export default Cap;
