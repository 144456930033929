import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/sales';

const handleManageSaleTeamsLoadSuccess = (state, action) => {
  const { data } = action.payload;

  return [...data];
};

const initialState = [];

const handlers = {
  [types.MANAGE_SALE_TEAMS_INDEX.SUCCESS]: handleManageSaleTeamsLoadSuccess,
};

const salesById = createReducer(initialState, handlers);

export default salesById;

export const getManageSaleTeams = state => state.manage.sales.teams;
