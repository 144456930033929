import React from 'react';
import PropTypes from 'prop-types';
import CalendarToday from '@material-ui/icons/CalendarToday';
import TextField from '@material-ui/core/TextField';

import { formatBirthday } from 'arcade-frontend-ui/src/helpers/utils/date';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcFormField from 'arcade-frontend-forms/src/components/ArcFormField';

const calendarButtonStyle = {
  width: 56,
};

const getTodayYearsAgo = years => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - years);

  return date;
};

function ProfileEditFormPersonal({ values, ...props }) {
  const birthdayMaxDate = getTodayYearsAgo(13);
  const anniversaryMaxDate = new Date();

  return (
    <ArcBox className="animated fadeIn">
      <ArcBox
        color="body1.color"
        fontSize="body1.fontSize"
        fontWeight="body1.fontWeight"
        lineHeight="body1.lineHeight"
        mb={1}
      >
        <ArcText>{'Your birthday'}</ArcText>
      </ArcBox>
      <ArcBox
        color="caption.color"
        fontSize="caption.fontSize"
        lineHeight="caption.lineHeight"
        mb={3}
      >
        <ArcText>{'Arcade will help celebrate you on your day.'}</ArcText>
      </ArcBox>

      <ArcBox mb={props.showBirthdayCalendar ? 2 : 4} align="flex-start">
        <ArcBox display="flex" flexDirection="row">
          <ArcBox mr={1} flex={100} onClick={props.onBirthdayClick}>
            <TextField
              id="birthday"
              name="birthday"
              disabled
              fullWidth
              value={
                values.birthday ? formatBirthday(values.birthday) : 'Not set'
              }
              label="Birthday"
              variant="outlined"
            />
          </ArcBox>

          <ArcButton
            variant="outlined"
            color="secondary"
            onClick={props.onBirthdayCalendarClick}
            label={props.showBirthdayCalendar ? 'Ok' : 'Set Birthday'}
            style={calendarButtonStyle}
          >
            {props.showBirthdayCalendar ? 'Ok' : <CalendarToday />}
          </ArcButton>
        </ArcBox>
      </ArcBox>

      {props.showBirthdayCalendar && (
        <ArcBox className="animated fadeIn" mb={4}>
          <ArcFormField
            type="datetime"
            name="birthday"
            label="Birthday"
            initialDate={values.birthday || birthdayMaxDate}
            maxDate={birthdayMaxDate}
            mode="permanent"
            hideTime
          />
        </ArcBox>
      )}

      <ArcBox
        color="body1.color"
        fontSize="body1.fontSize"
        fontWeight="body1.fontWeight"
        lineHeight="body1.lineHeight"
        mb={1}
      >
        <ArcText>{'Work anniversary'}</ArcText>
      </ArcBox>
      <ArcBox
        color="caption.color"
        fontSize="caption.fontSize"
        lineHeight="caption.lineHeight"
        mb={3}
      >
        <ArcText>{'Arcade will help celebrate your tenure.'}</ArcText>
      </ArcBox>

      <ArcBox mb={2} align="flex-start">
        <ArcBox display="flex" flexDirection="row">
          <ArcBox mr={1} flex={100} onClick={props.onAnniversaryClick}>
            <TextField
              id="anniversary"
              name="anniversary"
              disabled
              fullWidth
              value={
                values.anniversary
                  ? formatBirthday(values.anniversary)
                  : 'Not set'
              }
              label="Work Anniversary"
              variant="outlined"
            />
          </ArcBox>

          <ArcButton
            variant="outlined"
            color="secondary"
            onClick={props.onAnniversaryCalendarClick}
            label={props.showAnniversaryCalendar ? 'Ok' : 'Set Anniversary'}
            style={calendarButtonStyle}
          >
            {props.showAnniversaryCalendar ? 'Ok' : <CalendarToday />}
          </ArcButton>
        </ArcBox>
      </ArcBox>
      {props.showAnniversaryCalendar && (
        <ArcBox className="animated fadeIn">
          <ArcFormField
            name="anniversary"
            type="datetime"
            label="Work Anniversary"
            initialDate={values.anniversary || anniversaryMaxDate}
            maxDate={anniversaryMaxDate}
            mode="permanent"
            hideTime
          />
        </ArcBox>
      )}
    </ArcBox>
  );
}

ProfileEditFormPersonal.displayName = 'ProfileEditFormPersonal';

ProfileEditFormPersonal.propTypes = {
  values: PropTypes.shape({
    anniversary: PropTypes.string,
    birthday: PropTypes.string,
  }),
  onAnniversaryClick: PropTypes.func,
  onAnniversaryCalendarClick: PropTypes.func,
  onBirthdayClick: PropTypes.func,
  onBirthdayCalendarClick: PropTypes.func,
  open: PropTypes.bool,
  showAnniversaryCalendar: PropTypes.bool,
  showBirthdayCalendar: PropTypes.bool,
};

ProfileEditFormPersonal.defaultProps = {
  values: {},
  onAnniversaryClick: global.noop,
  onAnniversaryCalendarClick: global.noop,
  onBirthdayClick: global.noop,
  onBirthdayCalendarClick: global.noop,
  open: false,
  showAnniversaryCalendar: false,
  showBirthdayCalendar: false,
};

export default ProfileEditFormPersonal;
