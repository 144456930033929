import React from 'react';
import ArcView from '../../primitives/ArcView';
import ArcTableCellPerson from './ArcTableCellPerson';

export const ranking = [
  {
    Header: 'Rank',
    accessor: 'rank',
    width: 56,
    Cell: row => (
      <ArcView
        style={{
          fontSize: 20,
          fontWeight: 500,
        }}
      >
        {row.value}
      </ArcView>
    ),
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ArcTableCellPerson,
  },
  {
    Header: 'Score',
    accessor: 'score',
    width: 88,
  },
];

export const list = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: row => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: 40,
            height: 40,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 8,
          }}
        >
          <div
            style={{
              width: 40,
              height: 40,
              backgroundSize: 'cover',
              backgroundPosition: '50% center',
              backgroundImage: `url(${row.original.imageUrl})`,
            }}
          />
        </div>
        <div>{row.value}</div>
      </div>
    ),
  },
  {
    Header: 'Value',
    accessor: 'value',
    width: 88,
  },
];
