import React from 'react';

import { makeStyles } from '@material-ui/styles';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';

import LightBody from './LightBody';
import LightCone from './LightCone';


const styles = makeStyles({
  body: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    transform: 'rotate(90deg) translate(-200%, 0)', // translate(0, -200px)
  },
  lowerBody: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: '#6F59BE',
    transform: 'translate(30%, 0) rotate(-90deg) translate(-60%, 0)',
  },
  upperBody: {
    position: 'absolute',
    bottom: 0,
    color: '#A994EB',
    width: '100%',
  },
  animateRotation: {
    position: 'relative',
    width: '100%',
    animation: 'skylineLightSwing 2s ease-in-out both infinite alternate-reverse',
  },
  scale: {
    width: '100%',
  },
  lightBody: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    transform: 'rotate(180deg) scale(1.5)',
  },
  lightCone: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    transform: 'translate(-20%, 0) rotate(90deg) translate(-650%, 0) rotate(-90deg) scale(8)',
  },
});

function Light() {
  const classes = styles();

  return (
    <ArcBox className={classes.body}>
      <ArcBox className={classes.upperBody}>
        <ArcBox className={classes.animateRotation}>
          <LightCone className={classes.lightCone} />
          <LightBody className={classes.lightBody} />
          {/*
          <ArcBox className={classes.cone}>
            <LightCone className={classes.scale} />
          </ArcBox>
*/}
        </ArcBox>
      </ArcBox>
      <ArcBox className={classes.lowerBody}>
        <LightBody className={classes.scale} />
      </ArcBox>
    </ArcBox>
  );
}

Light.displayName = 'Light';

export default Light;
