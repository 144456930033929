/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import ArcView from '../../primitives/ArcView';
import ArcScoreboardComma from './ArcScoreboardComma';
import ArcScoreboardDollar from './ArcScoreboardDollar';
import ArcScoreboardNumber from './ArcScoreboardNumber';
import ArcScoreboardDecimal from './ArcScoreboardDecimal';
import { replaceSeparator } from '../../helpers/utils/currency';
import { createWithStyles } from '../../styles';
import { toFixed } from '../../../../core/src/helpers/numbers/numbers';

const DECIMAL_TO_NUMBER_RATIO = 12 / 22.5; // when the numbers are 22.5px wide the decimal is 12px

const styles = {
  Container: () => ({
    root: {
      maxWidth: '100%',
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);

class ArcScoreboard extends React.PureComponent {
  static displayName = 'ArcScoreboard';

  static propTypes = {
    color: PropTypes.string,
    decimalWidth: PropTypes.number,
    marginRight: PropTypes.string,
    numberOfDecimalPlaces: PropTypes.number,
    numberWidth: PropTypes.number,
    score: PropTypes.number,
    style: PropTypes.objectOf(PropTypes.any),
    symbol: PropTypes.oneOf(['#', '$']),
  };

  static defaultProps = {
    color: undefined,
    decimalWidth: 0,
    marginRight: undefined,
    numberOfDecimalPlaces: 3,
    numberWidth: 88,
    score: 0,
    style: undefined,
    symbol: '#',
  };

  constructor(props) {
    super(props);

    this.state = {
      scores: [],
      scoreMantissa: [],
    };

    this.hasDecimal = this.isDecimal || this.isDollar;
  }

  componentDidMount() {
    const scoreString = this.score.toString();

    if (scoreString.indexOf('.') === -1) {
      this.updateIntegerScore(Math.abs(this.score));
    } else {
      this.updateFloatScore(Math.abs(this.score));
    }
  }

  componentWillReceiveProps(nextProps) {
    const score = nextProps.score || 0;

    const nextScoreString = score.toString();
    const nextScoreNumber = score;

    if (nextScoreNumber !== this.score) {
      if (this.isNumber && nextScoreString.indexOf('.') === -1) {
        this.hasDecimal = false;
        this.updateIntegerScore(nextScoreNumber);
      } else {
        this.hasDecimal = true;
        this.updateFloatScore(nextScoreNumber);
      }
    }
  }

  get color() {
    if (this.props.score < 0) {
      return '#f00';
    }

    return this.props.color;
  }

  get decimalWidth() {
    if (this.props.decimalWidth) {
      return this.props.decimalWidth;
    }

    return this.props.numberWidth * DECIMAL_TO_NUMBER_RATIO;
  }

  get isDollar() {
    return this.props.symbol === '$';
  }

  get isNumber() {
    return this.props.symbol === '#';
  }

  get isDecimal() {
    return this.score.toString().indexOf('.') > -1;
  }

  get score() {
    return this.props.score || 0;
  }

  updateIntegerScore(score) {
    const scoreCharacteristic = replaceSeparator(score.toString()).split('');

    this.setState({
      scores: scoreCharacteristic,
    });

    if (this.state.scoreMantissa !== []) {
      this.setState({
        scoreMantissa: [],
      });
    }
  }

  updateFloatScore(score) {
    const roundedScore = toFixed(score, this.props.numberOfDecimalPlaces);

    const floatScore = this.isDollar
      ? score.toFixed(2).split('.')
      : roundedScore.toString().split('.');

    const scoreCharacteristic = replaceSeparator(floatScore[0]).split('');
    const scoreMantissa = floatScore[1] ? floatScore[1].split('') : [];

    if (scoreMantissa.length < 2 && this.isDollar) {
      while (scoreMantissa.length < 2) {
        scoreMantissa.push('0');
      }
    }

    this.setState({
      scores: scoreCharacteristic,
      scoreMantissa,
    });
  }

  renderScore = (score, index) =>
    score === ',' ? (
      <ArcScoreboardComma
        key={index}
        width={this.decimalWidth}
        color={this.color}
      />
    ) : (
      <ArcScoreboardNumber
        key={index}
        value={parseInt(score, 10)}
        width={this.props.numberWidth}
        color={this.color}
      />
    );

  render() {
    const { scores, scoreMantissa } = this.state;

    const { marginRight, numberWidth, style, symbol } = this.props;

    return (
      <Container row marginRight={marginRight} style={style}>
        {symbol === '$' && <ArcScoreboardDollar width={numberWidth} />}
        {scores.map(this.renderScore)}

        {this.hasDecimal && <ArcScoreboardDecimal width={this.decimalWidth} />}

        {scoreMantissa.map(this.renderScore)}
      </Container>
    );
  }
}

export default ArcScoreboard;
