import React from 'react';

import ArcScoreboard from '../ArcScoreboard';
import ArcButton from '../../elements/ArcButton';
import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import ArcParagraph from '../../typography/ArcParagraph';

import {
  styleConfirmationContainer,
  styleConfirmationInner,
  styleConfirmationMessage,
  styleFireworksContainer,
  styleScoreboardContainer,
  styleConfirmationButton,
} from './ArcFireworksScore.style';

const messages = [
  'Nice work!',
  'Way to go!',
  'Awesome work.',
];

const Fireworks = () => (
  <div className="Fireworks">
    <div className="before" />
    <div className="after" />
  </div>
);

class ArcFireworksScore extends React.PureComponent {
  static propTypes = {
    returnState: ArcPropTypes.string.isRequired,
    symbol: ArcPropTypes.string.isRequired,

    onClose: ArcPropTypes.func,
    prevScore: ArcPropTypes.number,
    score: ArcPropTypes.number,
  };

  static defaultProps = {
    onClose: undefined,
    prevScore: 0,
    score: 0,
  };

  state = {
    score: this.props.prevScore,
  };

  componentDidMount() {
    if (this.props.score !== this.state.score) {
      this.setScoreTimeout = setTimeout(() => this.setScore(this.props.score), 1000);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.setScoreTimeout);
  }

  setScore = score => this.setState({ score });

  renderMessage = () => messages[Math.floor(Math.random() * messages.length)];

  render() {
    const { score } = this.state;

    return (
      <ArcView
        row
        justify="center"
        style={styleConfirmationContainer}
      >
        <ArcView
          style={styleConfirmationInner}
        >
          <ArcParagraph
            align="center"
            style={styleConfirmationMessage}
          >
            {this.renderMessage()}
          </ArcParagraph>
          <ArcView
            row
            justify="center"
            style={styleScoreboardContainer}
          >
            <ArcScoreboard
              digits={3}
              score={score}
              background="transparent"
              symbol={this.props.symbol}
            />
          </ArcView>
          <ArcButton
            label={`Back to ${this.props.returnState}`}
            style={styleConfirmationButton}
            onClick={this.props.onClose}
          />
        </ArcView>
        <Fireworks
          style={styleFireworksContainer}
        />
      </ArcView>
    );
  }
}

export default ArcFireworksScore;
