import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/people';

const handleManagePeoplePermissionsIndexSuccess = (state, action) => {
  const { data } = action.payload;
  return data;
};

const initialState = {};

const handlers = {
  [types.MANAGE_PEOPLE_PERMISSIONS_INDEX.SUCCESS]:
    handleManagePeoplePermissionsIndexSuccess,
};

const peoplePermissions = createReducer(initialState, handlers);

export default peoplePermissions;

export const getPeoplePermissions = state => state.manage.people.peoplePermissions;
