import React from 'react';

const ArcadeDollar = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 19.138v-2.85h5.598v-2.863H7.793v-2.85h2.805V7.713H7.793V4.85h2.805V2h2.793v2.85H19v2.863h-5.609v2.862h2.805v2.85H13.39v2.862h2.805v2.851H13.39V22h-2.793v-2.862H5zm0-8.563V7.713h2.793v2.862H5zm11.196 5.712v-2.862H19v2.862h-2.804z" fill="currentColor" fillRule="evenodd" />
  </svg>
);

export default ArcadeDollar;
