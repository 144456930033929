import React from 'react';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import { createWithStyles } from '../../styles';

const styles = {
  NumberContainer: () => ({
    root: {
      alignSelf: 'flex-end',
      flexShrink: 1,

      '& > svg path': {
        transition: 'fill-opacity 300ms cubic-bezier(0.42, 0, 0.65, 1.22)',
      },

      '& > svg': {
        width: '100%',
        // maxWidth: '40px',
      },
    },
  }),
};

const NumberContainer = createWithStyles(styles.NumberContainer)(ArcView);

const ArcScoreboardComma = props => (
  <NumberContainer style={{ width: props.width }} className="ArcScoreboardComma">
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.873 21.063V24H6.937v-2.937h2.936zm-2.936-8.81h5.873v8.81H9.873v-2.936H6.937v-5.873z" fill="#FBBB38" fillRule="nonzero" />
    </svg>
  </NumberContainer>
);

export default ArcScoreboardComma;

ArcScoreboardComma.propTypes = {
  width: ArcPropTypes.number,
};

ArcScoreboardComma.defaultProps = {
  width: 40,
};
