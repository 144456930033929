import * as types from './types';

export const apiPeopleIndexRequest = (showLockedUsers = false) => ({
  type: types.PEOPLE_INDEX.REQUEST,
  payload: { showLockedUsers },
});

export const apiPeopleQuicklinksRequest = userId => ({
  type: types.PEOPLE_QUICKLINKS.REQUEST,
  payload: { userId },
});

export const showQuicklinksUser = userId => ({
  type: types.SHOW_QUICKLINKS_USER,
  payload: { userId },
});

export const resetQuicklinksUser = () => ({
  type: types.RESET_QUICKLINKS_USER,
  payload: {},
});

export const updatePeopleDisplayType = displayType => ({
  type: types.UPDATE_PEOPLE_DISPLAY_TYPE,
  payload: { displayType },
});
