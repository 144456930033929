/* eslint-disable no-unused-vars */

import React from 'react';
import PropTypes from 'prop-types';
import * as GAME_SCENE_SIZES from 'arcade-frontend-core/src/types/game-scene-sizes';
import { createWithStyles } from '../../styles';
import ArcView from '../../primitives/ArcView/ArcView';
import PlayerPortrait from '../PlayerPortrait';
import InitialVine from '../../assets/InitialVine';
import RepeatingVine from '../../assets/RepeatingVine';
import { distribute } from '../../helpers/utils/clustering';
import ArcClouds from '../ArcClouds/ArcClouds';
import ArcText from '../../primitives/ArcText';
import { date } from '../../elements';
import { getStyleByGameSceneSize } from '../../helpers/utils/game-scene';

const styles = {
  Container: () => ({
    root: {
      position: 'relative',
      width: '100%',
      height: 410,
      overflow: 'hidden',

    },
  }),

  VineWrapper: () => ({
    root: {
      position: 'absolute',
      bottom: 100,
    },

    slot0: {
      left: '10%',
      bottom: 120,
      opacity: 0.7,
    },

    slot1: {
      left: '30%',
    },

    slot2: {
      left: '50%',
    },

    slot3: {
      left: '70%',
    },
  }),

  Sky: () => ({
    root: {
      backgroundColor: '#F0FBFE',
      width: '100%',
      height: '100%',
    },
  }),

  Field: () => ({
    root: {
      backgroundColor: '#D0F9E7',
      height: 120,
      width: '100%',
      position: 'absolute',
      bottom: 0,
    },
  }),
  Leaves: () => ({
    root: {
      height: 24,
      width: 51,
      background: 'url(https://d17rkelr6jjme9.cloudfront.net/app/games/repeating-vine.svg)',
    },
  }),
  Stem: () => ({
    root: {
      height: 12,
      width: 51,
      background: 'url(https://d17rkelr6jjme9.cloudfront.net/app/games/initial-vine.svg) bottom center',
    },
  }),
  PlayerPosition: () => ({
    root: {
      position: 'absolute',
      left: 26,
      top: -18,
    },
  }),
  FirstPlace: () => ({
    root: {},
  }),
  NoScore: () => ({
    root: {
      position: 'absolute',
      top: '40%',
      left: '40%',
    },
  }),
  PlayerDemoCircle: () => ({
    root: {
      transform: 'translate(-50%, -50%)',
      width: 32,
      height: 32,
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      backgroundColor: '#A3F4D0',
      borderRadius: 32,
    },
  }),

};

const VineWrapper = createWithStyles(styles.VineWrapper)(ArcView);
const Container = createWithStyles(styles.Container)(ArcView);
const Sky = createWithStyles(styles.Sky)(ArcView);
const Field = createWithStyles(styles.Field)(ArcView);
const Leaves = createWithStyles(styles.Leaves)(ArcView);
const Stem = createWithStyles(styles.Stem)(ArcView);
const PlayerPosition = createWithStyles(styles.PlayerPosition)(ArcView);
const FirstPlace = createWithStyles(styles.FirstPlace)(ArcView);
const NoScore = createWithStyles(styles.NoScore)(ArcView);
const PlayerDemoCircle = createWithStyles(styles.PlayerDemoCircle)(ArcView);

const ArcVine = ({ scores, currentUser, isDemo, size, borderRadius }) => {
  const createDemoPlayers = () => {
    let score = 3;
    const demoPlayers = [];

    for (let index = 1; index <= 3; index += 1) {
      demoPlayers.push({
        score,
        l: {
          id: index.toString(),
          score,
          position: index,
        },
      });
      score -= 1;
    }
    return demoPlayers;
  };

  const rankings = !isDemo
    ? scores.map(s => ({ score: s.score, l: s }))
    : createDemoPlayers();

  const distribution = !isDemo
    ? distribute(rankings, i => i.id === currentUser.toString())
    : distribute(rankings);

  const { slot1, slot2, slot3, outlier, max } = distribution;
  const slots = [slot1, slot2, slot3];

  return (
    <Container style={{
      borderRadius,
      height: size === GAME_SCENE_SIZES.SMALL ? 318 : 410 }}
    >
      <Sky>
        <ArcClouds />
        <Field style={{ height: getStyleByGameSceneSize(size, [70, 120]) }} />
        {/* { max === 0 &&
          <NoScore>Be the first to score!</NoScore>
        } */}
        {/* { !!outlier &&
          <VineWrapper key="0" slot={0}>
            <FirstPlace>1st Place</FirstPlace>
            <Leaves style={{ height: 24 * 8 }} />
            <InitialVine />
          </VineWrapper>
      } */}

        { slots.map((slot, index) => !slot.missing && (
          <VineWrapper
            key={index.toString()}
            slot={index + 1}
            style={{ bottom: getStyleByGameSceneSize(size, [24, 100]) }}
          >
            <PlayerPosition>
              {!isDemo ? (
                <PlayerPortrait
                  isSelf={slot.item.isSelf}
                  image={slot.item.image}
                  title={slot.item.catchUp || 'You\'re doing great!'}
                />
              ) : (
                <PlayerDemoCircle>
                  <ArcText size12px>
                    {date.getOrdinal(slot.item.position)}!
                  </ArcText>
                </PlayerDemoCircle>
              )}
            </PlayerPosition>
            <Stem />
            <Leaves style={{ height: 24 * Math.round(6 * slot.fraction) }} />
            <InitialVine />
          </VineWrapper>
        ))}
      </Sky>
    </Container>
  );
};

// ArcVine.propTypes = displaysScoresType;

ArcVine.propTypes = {
  borderRadius: PropTypes.number,
  isDemo: PropTypes.bool,
  currentUser: PropTypes.objectOf(PropTypes.any),
  size: PropTypes.string,
  scores: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
    catchUp: PropTypes.string,
    isSelf: PropTypes.bool.isRequired,
  })),
};

ArcVine.defaultProps = {
  borderRadius: 8,
  isDemo: false,
  currentUser: {},
  size: GAME_SCENE_SIZES.SMALL,
  scores: [],
};

export default ArcVine;
