import transformIdToString from 'arcade-frontend-core/src/helpers/transformIdToString';
import { IMAGE_BY_TYPE } from 'arcade-frontend-core/src/types/game-formats';

export const normalizeGame = game => ({
  ...game,
  id: game.id ? game.id.toString() : '',
  campaignImage: IMAGE_BY_TYPE[game.type],
  participantCount: game.rankings
    ? game.rankings.length
    : game.participantCount || 0,
  rankings: game.rankings
    ? game.rankings.map(ranking => ({
        ...ranking,
        id: ranking && ranking.id ? ranking.id.toString() : '',
      }))
    : [],
  winners: game.winners
    ? game.winners.map(winner => ({
        ...winner,
        includedUsers:
          winner && winner.includedUsers.length > 0
            ? winner.includedUsers.map(userId => userId.toString())
            : [],
        teamId: winner && winner.teamId ? winner.teamId.toString() : '',
        userId: winner && winner.userId ? winner.userId.toString() : '',
      }))
    : [],
  leaderboardActivityId: game.leaderboardActivityId
    ? game.leaderboardActivityId.toString()
    : undefined,
});

export const normalizeGames = games => games.map(normalizeGame);

export const normalizeCompletedGames = games =>
  games.map(game => ({
    ...transformIdToString(game),
    participants: game.participants.map(transformIdToString),
  }));
