import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ArcCardActionButton from 'arcade-frontend-ui/src/components/ArcCardActionButton';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';

function GamesCardMenu({ onSelect, showVerify }) {
  const anchorEl = useRef();

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const handleSelect = action => toggleMenu() || onSelect(action);

  return (
    <ArcBox ref={anchorEl}>
      <ArcCardActionButton onClick={toggleMenu} />
      <Menu anchorEl={anchorEl.current} open={menuOpen} onClose={toggleMenu}>
        {showVerify && (
          <MenuItem onClick={() => handleSelect('verify')}>
            Verify game
          </MenuItem>
        )}
        <MenuItem onClick={() => handleSelect('leaderboard')}>
          View leaderboard
        </MenuItem>
        <MenuItem onClick={() => handleSelect('events')}>View events</MenuItem>
        <MenuItem onClick={() => handleSelect('rules')}>View rules</MenuItem>
      </Menu>
    </ArcBox>
  );
}

GamesCardMenu.propTypes = {
  onSelect: PropTypes.func,
  showVerify: PropTypes.bool,
};

GamesCardMenu.defaultProps = {
  onSelect: global.noop,
  showVerify: false,
};

export default GamesCardMenu;
