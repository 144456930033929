import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../actions/audiences';

const initialState = null;

const handleAudienceSetFilter = (state, action) => action.payload;

const handlers = {
  [types.AUDIENCES_SET_FILTER]: handleAudienceSetFilter,
};

export default createReducer(initialState, handlers);

export const getFilter = state => state.audiences.filter;
