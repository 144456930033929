import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';

import ArcPropTypes from '../../helpers/arc/propTypes';

class ArcNumberField extends PureComponent {
  static propTypes = {
    onChange: ArcPropTypes.func,
    onBlur: ArcPropTypes.func,
    onFocus: ArcPropTypes.func,
    value: ArcPropTypes.oneOfType([
      ArcPropTypes.string,
      ArcPropTypes.number,
    ]),
  };

  static defaultProps = {
    onChange: ArcPropTypes.noop,
    onBlur: ArcPropTypes.noop,
    onFocus: ArcPropTypes.noop,
    value: '',
  };

  selectAll = (event) => {
    event.target.select();
  };

  handleBlur = (e) => {
    this.props.onBlur(e);
  };

  handleFocus = (e) => {
    this.props.onFocus(e);
    this.selectAll(e);
  };

  render() {
    const {
      onBlur,
      onFocus,
      ...rest
    } = this.props;

    return (
      <TextField
        placeholder="Enter a number"
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        {...rest}
      />
    );
  }
}

export default ArcNumberField;
