import React from 'react';
import Check from '@material-ui/icons/Check';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import ChevronRight from '@material-ui/icons/ChevronRight';
import HighlightOff from '@material-ui/icons/HighlightOff';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import ArcCheckbox from '../../elements/ArcCheckbox';
import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import { createWithStyles } from '../../styles';

const styles = {
  Container: () => ({
    root: {
      textAlign: 'left',
      borderBottom: '1px solid #f3f3f3',
      backgroundColor: '#f8f8f8',
      fontSize: 14,
      lineHeight: '16px',
      paddingLeft: 16,

      '& label': {
        justifyContent: 'space-between',
        marginLeft: 0,
        marginRight: 0,
      },

      '& svg': {
        top: '0 !important',
      },
    },

    isChecked: {
      backgroundColor: '#ffffff',
    },
  }),
};

const Container = createWithStyles(styles.Container)(ArcView);

/** **Description**
  *
  * Renders a row for `<ArcChecklist />`
  *
  * **Requirements**
  *
  * This component is automatically rendered as part of `<ArcChecklist />`,
  * however it is available for isolated rendering
  *
*/

class ArcChecklistItem extends React.PureComponent {
  static displayName = 'ArcChecklistItem';

  static propTypes = {
    iconType: ArcPropTypes.oneOf([
      'checkbox',
      'info',
      'manage',
      'navigation',
    ]),

    onClose: ArcPropTypes.func,
    onClickCheckBox: ArcPropTypes.func,
    onClickInfo: ArcPropTypes.func,
    onClickNavigation: ArcPropTypes.func,
    onClickItem: ArcPropTypes.func,

    checked: ArcPropTypes.bool,
    disabled: ArcPropTypes.bool,

    value: ArcPropTypes.string,
    label: ArcPropTypes.string,
    labelInfo: ArcPropTypes.string,
  };

  static defaultProps = {
    iconType: 'checkbox',

    onClose: ArcPropTypes.noop,
    onClickCheckBox: ArcPropTypes.noop,
    onClickInfo: ArcPropTypes.noop,
    onClickNavigation: ArcPropTypes.noop,
    onClickItem: ArcPropTypes.noop,

    checked: false,
    disabled: false,

    value: undefined,
    label: undefined,
    labelInfo: undefined,
  };

  state = {
    isChecked: this.props.checked,
    label: this.props.label,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== this.props.checked) {
      this.setState({
        isChecked: nextProps.checked,
      });
    }

    if (nextProps.label !== this.props.label) {
      this.setState({
        label: nextProps.label,
      });
    }
  }

  get uncheckedIcon() {
    switch (this.props.iconType) {
      case 'info':
        if (this.state.label === this.props.label) {
          return (<InfoOutlined />);
        }
        return (<HighlightOff />);
      case 'manage':
        return '--';
      case 'navigation':
        return (<ChevronRight />);
      case 'checkbox':
      default:
        return (<CheckBoxOutlineBlank />);
    }
  }

  get checkedIcon() {
    switch (this.props.iconType) {
      case 'navigation':
      case 'manage':
        return (<Check color="primary" />);
      default:
        return (<CheckBox />);
    }
  }

  handleClickCheckBox = (e) => {
    const isChecked = !this.state.isChecked;

    this.props.onClickCheckBox(e, this.props.value, isChecked);
    this.setState({ isChecked });
  };

  handleClickInfo = (e) => {
    const {
      label,
      labelInfo,
    } = this.props;

    if (!this.state.isChecked) {
      if (this.state.label === label) {
        this.setState({ label: labelInfo });
      } else {
        this.setState({ label });
      }
    }

    this.props.onClickInfo(e, this.props.value);
  };

  handleClickNavigation = (e) => {
    this.props.onClickNavigation(e, this.props.value);
    this.props.onClickItem(e);
    this.props.onClose();
  };

  handleClick = (e) => {
    switch (this.props.iconType) {
      case 'info':
        return this.handleClickInfo(e);
      case 'manage':
        return null;
      case 'navigation':
        return this.handleClickNavigation(e);
      default:
      case 'checkbox':
        return this.handleClickCheckBox(e);
    }
  };

  render() {
    const {
      disabled,
    } = this.props;

    const {
      isChecked,
      label,
    } = this.state;

    return (
      <Container
        isChecked={isChecked}
        onClick={this.handleClick}
      >
        <ArcCheckbox
          checked={isChecked}
          disabled={disabled}
          label={label}
          labelPlacement="start"
          icon={this.uncheckedIcon}
          checkedIcon={this.checkedIcon}
        />
      </Container>
    );
  }
}

export default ArcChecklistItem;
