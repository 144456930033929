import { all } from '@redux-saga/core/effects';

import games from './games';
import metrics from './metrics';
import people from './people';
import rewards from './rewards';
import sales from './sales';
import tokens from './tokens';

const manage = function* () {
  yield all([
    ...games,
    ...metrics,
    ...people,
    ...rewards,
    ...sales,
    ...tokens,
  ]);
};

export default manage;
