import React from 'react';
import ArcPropTypes from '../../helpers/arc/propTypes';
import {
  createWithStyles,
} from '../../styles';

import ArcPlaceholder from '../ArcPlaceholder';
import { sortByProp } from '../../helpers/utils/array';
import ArcView from '../../primitives/ArcView';
import ArcText from '../../primitives/ArcText';

import ArcScoreboardListItem from './ArcScoreboardListItem';

const styles = {
  ArcScoreboardListView: () => ({
    root: {
      display: 'table',
      maxWidth: 600,
      width: '100%',
    },
  }),

  Rank: () => ({
    root: {
      padding: '16px',
      textAlign: 'right',
    },
  }),

  Name: () => ({
    root: {
      // padding: '16px 8px',
      // flexGrow: 2,
    },
  }),

  Score: () => ({
    root: {
      padding: '16px 8px',
      textAlign: 'right',
    },
  }),

  Header: () => ({
    root: {
      borderBottom: 'solid 4px #F3F3F3',
      color: '#8C8C8C',
    },
  }),

  Threshold: () => ({
    root: {
      position: 'relative',
      height: 2,
      background: '#FBBB38',
    },
  }),

  ThresholdText: () => ({
    root: {
      display: 'block',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: 12,
      lineHeight: '24px',
      padding: '0 16px',
      borderRadius: 24,
      background: '#ffffff',
      textTransform: 'uppercase',
      border: 'solid 2px #FBBB38',
      color: '#9c9c9c',
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
  }),
};

const ArcScoreboardListView = createWithStyles(styles.ArcScoreboardListView)(ArcView);
const Rank = createWithStyles(styles.Rank)(ArcView);
const Name = createWithStyles(styles.Name)(ArcView);
const Score = createWithStyles(styles.Score)(ArcView);
const Header = createWithStyles(styles.Header)(ArcView);
const Threshold = createWithStyles(styles.Threshold)(ArcView);
const ThresholdText = createWithStyles(styles.ThresholdText)(ArcView);

const stylePlaceholder = {
  marginBottom: 16,
  height: 24,
};

class ArcScoreboardList extends React.PureComponent {
  static propTypes = {
    rankings: ArcPropTypes.arrayOf(ArcPropTypes.shape({
      score: ArcPropTypes.number.isRequired,
      rank: ArcPropTypes.number.isRequired,
      id: ArcPropTypes.string.isRequired,
      imageUrl: ArcPropTypes.string,
      name: ArcPropTypes.string.isRequired,
    })),
    threshold: ArcPropTypes.number,
    id: ArcPropTypes.string,
    currentUserId: ArcPropTypes.string,
    unit: ArcPropTypes.string,
    hideHeader: ArcPropTypes.bool,
    isProgressView: ArcPropTypes.bool,
  };

  static defaultProps = {
    id: null,
    currentUserId: null,
    unit: '#',
    threshold: undefined,
    hideHeader: false,
    isProgressView: false,
    rankings: [],
  };

  constructor(props) {
    super(props);
    this.determineRankings(props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rankings !== this.props.rankings) {
      this.determineRankings(nextProps);
    }
  }

  get hasThreshold() {
    return typeof this.props.threshold !== typeof undefined;
  }

  get thresholdUnit() {
    if (this.props.unit === '#') {
      return '';
    }

    return this.props.unit;
  }

  get thresholdDisplay() {
    return `${this.thresholdUnit}${this.props.threshold}`;
  }

  get thresholdDifferenceDisplay() {
    return `${this.thresholdUnit}${Math.abs(this.currentUserScore - this.props.threshold)}`;
  }

  determineRankings(props) {
    const { currentUserId, rankings, threshold } = props;

    const sorted = rankings ? sortByProp(rankings, 'rank') : [];

    this.currentUserScore = 0;

    if (currentUserId && sorted.length) {
      const user = sorted.filter(ranking => ranking.id === currentUserId)[0];

      if (user) this.currentUserScore = user.score;
    }

    if (threshold) {
      this.rankingsAbove = sorted.filter(ranking => ranking.score >= threshold);
      this.rankingsBelow = sorted.filter(ranking => ranking.score < threshold);
    } else {
      this.rankingsAbove = sorted;
      this.rankingsBelow = [];
    }

    this.renderRankingsAbove = this.renderItem('above');
    this.renderRankingsBelow = this.renderItem('below');
  }

  renderItem = type => (item, index) => (
    <ArcScoreboardListItem
      key={`${this.props.id}:${item.id}`}
      symbol={this.props.unit}
      score={item.score}
      isOdd={index % 2 !== 0}
      isCurrentUser={item.id === this.props.currentUserId}
      scoreboardColor={type === 'below' ? '#B4B4B4' : undefined}
      {...item}
      isProgressView={this.props.isProgressView}
    />
  );

  renderThresholdContent() {
    if (this.currentUserScore >= this.props.threshold) {
      return (
        <ThresholdText>
          <ArcText isStrong>{this.thresholdDisplay}</ArcText> to win.
          You beat it by <ArcText isStrong>{this.thresholdDifferenceDisplay}!</ArcText>
        </ThresholdText>
      );
    }

    return (
      <ThresholdText>
        <ArcText isStrong>{this.thresholdDisplay}</ArcText> to win.
        You need <ArcText isStrong>{this.thresholdDifferenceDisplay}</ArcText> more.
      </ThresholdText>
    );
  }

  renderThreshold() {
    return (
      <Threshold>
        {this.renderThresholdContent()}
      </Threshold>
    );
  }

  renderItems() {
    if (!this.props.rankings || !this.props.rankings.length) {
      return (
        <React.Fragment>
          {[0, 1, 2, 3].map(num => (
            <ArcView key={num} style={stylePlaceholder}>
              <ArcPlaceholder />
            </ArcView>
          ))}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {this.rankingsAbove.map(this.renderRankingsAbove)}
        {this.hasThreshold && this.renderThreshold()}
        {this.rankingsBelow.map(this.renderRankingsBelow)}
      </React.Fragment>
    );
  }

  render() {
    return (
      <ArcScoreboardListView className="ArcScoreboardList">
        {!this.props.hideHeader && (
          <Header display="table-row">
            <Rank display="table-cell">#</Rank>
            <Name display="table-cell">Name</Name>
            <Score display="table-cell">Score {this.props.unit === '$' && `(${this.props.unit})`}</Score>
          </Header>
        )}

        {this.renderItems()}
      </ArcScoreboardListView>
    );
  }
}

export default ArcScoreboardList;
