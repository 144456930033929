import { createReducer } from 'arcade-frontend-ui';

import * as types from '../actions/types';

const handleGameRankingsLoadSuccess = (state, action) => {
  const { id, data } = action.payload;

  const newState = { ...state };

  newState[id] = data;

  return newState;
};

const initialState = {};

const handlers = {
  [types.GAMES_RANKINGS_INDEX.SUCCESS]: handleGameRankingsLoadSuccess,
};

export const getRankingsByGameId = state => state.games.rankingsByGameId;
const rankingsByGameId = createReducer(initialState, handlers);

export default rankingsByGameId;
