export const snakeCase = (raw: string): string => {
  const parts = raw.match(/([a-z]+)|([A-Z][a-z]+)/g);
  return (
    parts?.map(part => part.toLowerCase()).join('_') ||
    raw
      .toLowerCase()
      .split(/[\s-_]/)
      .join('_')
  );
};
