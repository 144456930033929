import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconSwapVert from '@material-ui/icons/SwapVert';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { createWithStyles } from '../../styles';

import ArcToken from '../../assets/ArcToken';
import ArcPlaceholder from '../ArcPlaceholder';
import ArcFormField from '../ArcFormField';
import ArcLoaderButton from '../ArcLoaderButton';
import ArcButton from '../../elements/ArcButton';
import ArcIconButton from '../../elements/ArcIconButton';
import ArcDialog from '../../elements/ArcDialog';
import ArcSelect from '../../elements/ArcSelect';
import ArcText from '../../primitives/ArcText';
import ArcView from '../../primitives/ArcView';

const paperProps = {
  square: true,
  style: {
    border: '4px solid #f3f3f3',
    margin: 16,
    width: '100%',
    maxWidth: 380,
  },
};

const amountInputProps = {
  autoComplete: 'off',
  startAdornment: (
    <InputAdornment position="start">
      <ArcToken />
    </InputAdornment>
  ),
};

const styleDialogContentText = {
  color: '#353535',
};

const styleMaxText = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
  fontSize: 12,
};

const styleMenuItem = {
  height: 'auto',
};

const styleTextFieldContainer = {
  width: 'calc(100% - 56px)',
};

const placeholderValue = (
  <ArcView>
    <ArcView marginBottom="8">
      <ArcPlaceholder>{'.'}</ArcPlaceholder>
    </ArcView>
    <ArcView row align="center">
      <ArcToken />
      <ArcView marginLeft="8">
        <ArcText isSmall>
          <ArcPlaceholder>{'Loading...'}</ArcPlaceholder>
        </ArcText>
      </ArcView>
    </ArcView>
  </ArcView>
);

const styles = {
  RightAngle: theme => ({
    root: {
      width: 24,
      height: 24,
      borderRight: `1px solid ${theme.palette.default.light}`,
    },

    borderTop: {
      borderTop: `1px solid ${theme.palette.default.light}`,
    },

    borderBottom: {
      borderBottom: `1px solid ${theme.palette.default.light}`,
    },
  }),
};

const RightAngle = createWithStyles(styles.RightAngle)(ArcView);

const PLACEHOLDER_ACCOUNT = {
  name: 'Loading',
  key: 'loading',
};

const PLACEHOLDER_ACCOUNTS = [PLACEHOLDER_ACCOUNT];

const FORM_INITIAL_VALUES = {
  amount: '',
};

class ArcTokenTransferDialog extends React.PureComponent {
  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      balance: PropTypes.number,
    })),
    fromAccountKey: PropTypes.string,
    isFetching: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    open: PropTypes.bool,
    requestStatus: PropTypes.string,
  };

  static defaultProps = {
    accounts: [],
    fromAccountKey: PLACEHOLDER_ACCOUNT.key,
    isFetching: false,
    onClose: undefined,
    onSubmit: global.noop,
    open: false,
    requestStatus: undefined,
  };

  constructor(props) {
    super(props);

    const {
      fromAccountKey,
      accounts,
    } = props;

    const fromAccount = accounts.filter(acc => acc.key === fromAccountKey)[0];
    const toAccount = accounts.filter(acc => acc.key !== fromAccountKey)[0];
    const maxAmount = fromAccount ? fromAccount.balance : '';

    this.state = {
      maxAmount,
      fromAccountKey: fromAccountKey || PLACEHOLDER_ACCOUNT.key,
      toAccountKey: toAccount ? toAccount.key : PLACEHOLDER_ACCOUNT.key,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fromAccountKey !== this.props.fromAccountKey) {
      const nextAccountKey = this.getNextAccountKey(nextProps.fromAccountKey);
      this.setToAccountKey(nextAccountKey);
      this.setFromAccountKey(nextProps.fromAccountKey);
    }
  }

  get accounts() {
    if (this.props.accounts.length) {
      return this.props.accounts;
    }

    return PLACEHOLDER_ACCOUNTS;
  }

  get isLoading() {
    return !this.props.accounts.length || this.props.isFetching || this.props.requestStatus !== 'DEFAULT';
  }

  getNextAccountKey = (value) => {
    const nextAccount = this.props.accounts.filter(acc => acc.key !== value)[0];
    const nextAccountKey = nextAccount ? nextAccount.key : '';

    return nextAccountKey;
  };

  setFromAccountKey = (fromAccountKey, cb) => {
    const account = this.props.accounts.filter(acc => acc.key === fromAccountKey)[0];
    let { maxAmount } = this.state;

    if (account) {
      maxAmount = account.balance;
    }

    this.setState({
      fromAccountKey,
      maxAmount,
    }, cb);
  };

  setMaxAmount = maxAmount => this.setState({ maxAmount });

  setToAccountKey = toAccountKey => this.setState({ toAccountKey });

  handleChangeFromAccount = (e) => {
    const { value } = e.target;
    this.setFromAccountKey(value);

    if (value === this.state.toAccountKey) {
      const nextAccountKey = this.getNextAccountKey(value);
      this.setToAccountKey(nextAccountKey);
    }
  };

  handleChangeToAccount = (e) => {
    const { value } = e.target;
    this.setToAccountKey(value);

    if (value === this.state.fromAccountKey) {
      const nextAccountKey = this.getNextAccountKey(value);
      this.setFromAccountKey(nextAccountKey);
    }
  };

  handleSubmit = (values, formikProps) => {
    formikProps.validateForm(values).then((errors) => {
      const isValid = !Object.keys(errors).length;

      if (isValid) {
        this.props.onSubmit({
          ...values,
          fromAccountKey: this.state.fromAccountKey,
          toAccountKey: this.state.toAccountKey,
        });

        setTimeout(formikProps.resetForm, 1200);
      }
    });
  };

  renderValue = (value) => {
    if (!this.props.accounts.length) {
      return placeholderValue;
    }

    const account = this.props.accounts.filter(acc => acc.key === value)[0];

    if (account) {
      return this.renderAccount(account);
    }

    return placeholderValue;
  };


  renderAccount = (account) => {
    if (!this.props.accounts.length) {
      return placeholderValue;
    }

    return (
      <ArcView>
        <ArcView marginBottom="4">{account.name}</ArcView>
        <ArcView row align="center">
          <ArcToken />
          <ArcView marginLeft="8">
            <ArcText isSmall>
              {account.balance.toLocaleString()}
            </ArcText>
          </ArcView>
        </ArcView>
      </ArcView>
    );
  };

  renderItem = account => (
    <MenuItem
      key={account.key}
      value={account.key}
      style={styleMenuItem}
      disabled={!account.transferable}
    >
      {this.renderAccount(account)}
    </MenuItem>
  );

  renderItems = () => this.accounts.map(this.renderItem);

  renderForm = (formikProps) => {
    const handleSetMax = () => formikProps.setFieldValue('amount', this.state.maxAmount);

    const handleSwap = () => {
      this.setToAccountKey(this.state.fromAccountKey);

      const hasSetMax = this.state.maxAmount === formikProps.values.amount;

      this.setFromAccountKey(this.state.toAccountKey, () => {
        if (hasSetMax) {
          formikProps.setFieldValue('amount', this.state.maxAmount);
        }
        formikProps.validateForm();
      });
    };

    const handleClose = () => {
      this.props.onClose();
      formikProps.setFieldValue('amount', '');
      formikProps.resetForm();
    };

    return (
      <ArcDialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={this.props.open}
        PaperProps={paperProps}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          <ArcText style={styleDialogContentText}>{'Transfer Tokens'}</ArcText>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'Move tokens between accounts.'}
          </DialogContentText>

          <ArcView
            align="center"
            row
            marginTop="24"
            marginBottom="16"
          >

            <ArcView
              flexGrow="100"
              marginRight="8"
            >
              <ArcView marginBottom="16">
                <ArcSelect
                  disabled={this.isLoading}
                  label="From"
                  onChange={this.handleChangeFromAccount}
                  renderValue={this.renderValue}
                  value={this.state.fromAccountKey}
                  variant="outlined"
                >
                  {this.renderItems()}
                </ArcSelect>
              </ArcView>

              <ArcView>
                <ArcSelect
                  disabled={this.isLoading}
                  label="To"
                  onChange={this.handleChangeToAccount}
                  renderValue={this.renderValue}
                  value={this.state.toAccountKey}
                  variant="outlined"
                >
                  {this.renderItems()}
                </ArcSelect>
              </ArcView>
            </ArcView>

            <ArcView>
              <RightAngle border="top" />
              <ArcIconButton
                disabled={this.isLoading}
                onClick={handleSwap}
              >
                <IconSwapVert />
              </ArcIconButton>
              <RightAngle border="bottom" />
            </ArcView>
          </ArcView>

          <ArcView
            align="center"
            row
          >
            <ArcView
              flexGrow="100"
              marginRight="8"
              style={styleTextFieldContainer}
            >
              <ArcFormField
                type="number"
                name="amount"
                label="Amount"
                InputProps={amountInputProps}
                disabled={this.isLoading}
                hasNoMarginBottom
                validations={{
                  isRequired: true,
                  isNumber: true,
                  minNumber: 1,
                  maxNumber: this.state.maxAmount,
                }}
              />
            </ArcView>
            <ArcIconButton
              disabled={this.isLoading}
              onClick={handleSetMax}
            >
              <ArcText style={styleMaxText}>
                {'MAX'}
              </ArcText>
            </ArcIconButton>
          </ArcView>
        </DialogContent>

        <DialogActions>
          <ArcButton onClick={handleClose}>
            {'Cancel'}
          </ArcButton>
          <ArcLoaderButton
            color="secondary"
            disabled={this.isLoading || !!Object.keys(formikProps.errors).length}
            variant="contained"
            onClick={formikProps.handleSubmit}
            label="Transfer"
            loadingState={this.props.requestStatus}
          />
        </DialogActions>
      </ArcDialog>
    );
  };

  render() {
    const TokenTransferSchema = Yup.object().shape({
      amount: Yup.number()
        .required()
        .positive()
        .integer()
        .max(this.state.maxAmount),
    });

    return (
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        validationSchema={TokenTransferSchema}
        onSubmit={this.handleSubmit}
      >
        {this.renderForm}
      </Formik>
    );
  }
}

export default ArcTokenTransferDialog;
