import React from 'react';

import PropTypes from 'prop-types';

import {
  ArcPropTypes,
  ArcStyles,
  ArcTable,
  ArcTableCellPerson,
  ArcScroll,
} from 'arcade-frontend-ui';

import ArcActivityCreateForm from 'arcade-frontend-widgets/src/containers/ArcActivityCreateForm';
import MetricCard from './MetricCard';
import MetricBenchmark from './MetricBenchmark';

const styleDate = {
  style: {
    color: ArcStyles.theme.arcSubheaderColor,
    textTransform: 'uppercase',
    fontSize: '0.8em',
  },
};

class MetricCardDetails extends React.Component {
  static propTypes = {
    apiMetricsActivitiesCreateRequest: ArcPropTypes.func.isRequired,

    onConfirmationClose: ArcPropTypes.func,

    activities: PropTypes.arrayOf(
      PropTypes.shape({
        formattedCreatedAt: PropTypes.string,
        person: PropTypes.string,
        quantity: PropTypes.string,
      }),
    ),

    rankings: PropTypes.arrayOf(
      PropTypes.shape({
        ranking: PropTypes.string,
        name: PropTypes.string,
        score: PropTypes.string,
      }),
    ),

    hasAdd: ArcPropTypes.bool,
    benchmarkValue: ArcPropTypes.number,
    percentOfBenchmark: ArcPropTypes.number,
    progress: ArcPropTypes.number,
    currentMetricDetailsType: ArcPropTypes.string,
    kpiId: ArcPropTypes.string,
    name: ArcPropTypes.string,
    symbol: ArcPropTypes.string,
    verb: ArcPropTypes.string,
  };

  static defaultProps = {
    onConfirmationClose: null,

    activities: [],
    rankings: [],

    hasAdd: true,
    benchmarkValue: null,
    percentOfBenchmark: null,
    progress: null,

    currentMetricDetailsType: null,
    kpiId: null,
    name: null,
    symbol: null,
    verb: null,
  };

  static displayName = 'MetricCardDetails';

  constructor(props) {
    super(props);

    this.columns = [
      {
        Header: 'Date',
        accessor: 'formattedCreatedAt',
        width: 80,
        sortMethod: (a, b) => new Date(a) - new Date(b),
        getProps: () => styleDate,
      },
      {
        Header: 'Name',
        accessor: 'person',
        Cell: ArcTableCellPerson,
      },
      {
        Header: 'Amount',
        accessor: 'quantity',
        sortable: false,
      },
    ];
  }

  handleSubmit = (e, values) => {
    this.props.apiMetricsActivitiesCreateRequest(
      this.props.kpiId,
      values.amount,
      values.date,
    );
  };

  renderContent = () => {
    switch (this.props.currentMetricDetailsType) {
      case 'activity':
        return (
          <ArcTable
            reactTableData={this.props.activities}
            reactTableColumns={this.columns}
            defaultPageSize={8}
            showPageJump={false}
            showPageSizeOptions={false}
            minRows={this.props.activities.length ? 0 : 5}
          />
        );
      case 'ranking':
        return (
          <ArcTable
            arcColumnType="ranking"
            reactTableData={this.props.rankings}
            defaultPageSize={8}
            showPageJump={false}
            showPageSizeOptions={false}
            minRows={this.props.rankings.length ? 0 : 5}
          />
        );
      case 'benchmark':
        return (
          <MetricBenchmark
            percentOfBenchmark={this.props.percentOfBenchmark}
            benchmarkValue={this.props.benchmarkValue}
            progress={this.props.progress}
            symbol={this.props.symbol}
            name={this.props.name}
            verb={this.props.verb}
          />
        );
      case 'create':
        return (
          <ArcActivityCreateForm
            name={this.props.name}
            symbol={this.props.symbol}
            kpiId={this.props.kpiId}
            onSubmit={this.handleSubmit}
            progress={this.props.progress}
            onConfirmationClose={this.props.onConfirmationClose}
            returnState="Metrics"
          />
        );
      default:
        return 'Please select a view type';
    }
  };

  render() {
    return (
      <MetricCard hasAdd={this.props.hasAdd} hasDetails {...this.props}>
        <ArcScroll>{this.renderContent()}</ArcScroll>
      </MetricCard>
    );
  }
}

export default MetricCardDetails;
