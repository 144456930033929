import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import byId, { getById } from './byId';
import list, { getList } from './list';

const entities = combineReducers({
  byId,
  list,
});

export default entities;

export const getEntities = createSelector(
  getById,
  getList,
  (_byId, _ids) => _ids.map(id => _byId[id]),
);
