import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import ManageMetricsEntitiesList from '../components/ManageMetricsEntitiesList';

import { getEntities } from '../reducers/entities';
import { actions } from '../actions';

class ManageMetricsContainer extends React.PureComponent {
  static propTypes = {
    apiManageMetricsEntityIndexRequest: PropTypes.func,
    currentUser: PropTypes.shape({
      id: PropTypes.string,
    }),
    entities: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        color: PropTypes.string,
        metrics: PropTypes.arrayOf(
          PropTypes.shape({
            quantity: PropTypes.number,
            title: PropTypes.string,
          }),
        ),
      }),
    ),
    dateRange: PropTypes.shape({
      type: PropTypes.oneOf(['time period', 'date range']),
      timePeriod: PropTypes.string,
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
    }),
  };

  static defaultProps = {
    apiManageMetricsEntityIndexRequest: global.noop,
    currentUser: {},
    entities: null,
    dateRange: undefined,
  };

  constructor(props) {
    super(props);
    this.requestAll();
  }

  componentDidUpdate(prevProps) {
    const { dateRange } = this.props;

    const isChangingUser = prevProps.currentUser !== this.props.currentUser;
    const isChangingDateRange =
      prevProps.dateRange.type !== dateRange.type ||
      prevProps.dateRange.timePeriod !== dateRange.timePeriod ||
      prevProps.dateRange.fromDate !== dateRange.fromDate ||
      prevProps.dateRange.toDate !== dateRange.toDate;

    if (isChangingUser || isChangingDateRange) {
      this.requestAll();
    }
  }

  requestAll() {
    if (!this.props.currentUser.id) {
      return;
    }

    const { dateRange } = this.props;

    if (dateRange) {
      this.props.apiManageMetricsEntityIndexRequest({ ...dateRange });
    }
  }

  render() {
    return <ManageMetricsEntitiesList entities={this.props.entities} />;
  }
}

const getState = state => ({
  currentUser: getCurrentUser(state),
  entities: getEntities(state),
});

const getActions = dispatch =>
  bindActionCreators(
    {
      apiManageMetricsEntityIndexRequest:
        actions.apiManageMetricsEntityIndexRequest,
    },
    dispatch,
  );

export default connect(getState, getActions)(ManageMetricsContainer);
