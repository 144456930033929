import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const handleSetUnreadRewards = (state, action) => action.payload;

const initialState = 0;

const handlers = {
  [types.SET_UNREAD_REWARDS]: handleSetUnreadRewards,
};

const unreadRewards = createReducer(initialState, handlers);

export default unreadRewards;

export const getUnreadRewards = state => state.app.unreadRewards;
