import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, Platform } from 'react-native';
import { useTheme } from '@material-ui/styles';

import ArcIconButton from 'arcade-frontend-ui/src/elements/ArcIconButton';
import ArcTextV2 from 'arcade-frontend-ui/src/components/ArcTextV2';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
import RefreshOutline from 'arcade-frontend-ui/src/icons/RefreshOutline';
import Inbox from 'arcade-frontend-ui/src/assets/Inbox';

import NotificationsPanelCardList from '../NotificationsPanelCardList';
import NotificationsPanelNoResult from '../NotificationsPanelNoResult';

const STRINGS = {
  NO_RESULT_SUBTITLE:
    'Game updates, pending verifications, and rewards will show up here.',
  NO_RESULT_TITLE: 'Inbox is empty',
};

const emptyNode = (
  <ArcViewBox m={3}>
    <NotificationsPanelNoResult
      title={STRINGS.NO_RESULT_TITLE}
      subtitle={STRINGS.NO_RESULT_SUBTITLE}
      icon={<Inbox />}
    />
  </ArcViewBox>
);

function NotificationsPanelMyInbox({
  notifications,
  onRefresh,
  statusGetNotifications,
  unreadCount,
}) {
  const theme = useTheme();
  return (
    <ArcViewBox width="100%">
      <ArcViewBox
        mt={Platform.OS === 'web' ? 0 : 3}
        mb={Platform.OS === 'web' ? 0 : 2}
        mx={2}
        pl={2}
        pr={0}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        height={50}
      >
        <ArcViewBox flexDirection="row" alignItems="center">
          <ArcTextV2 fontSize={20} lineHeight="30px" fontWeight={500}>
            {'Inbox'}
          </ArcTextV2>

          {unreadCount > 0 && (
            <ArcViewBox
              backgroundColor="#E99726"
              borderRadius={50}
              height={24}
              minWidth={24}
              alignItems="center"
              justifyContent="center"
              mx={1}
              mt={0.5}
            >
              <ArcTextV2
                color={theme.palette.common.white}
                fontSize={12}
                fontWeight="bold"
                px={2}
              >
                {unreadCount}
              </ArcTextV2>
            </ArcViewBox>
          )}

          <ArcViewBox ml={2}>
            <ActivityIndicator
              animating={statusGetNotifications.pending}
              size={16}
            />
          </ArcViewBox>
        </ArcViewBox>
        <ArcIconButton
          onClick={onRefresh}
          style={{ borderRadius: 50, padding: 8 }}
        >
          <RefreshOutline
            width={22}
            height={22}
            color={theme.palette.grey[700]}
          />
        </ArcIconButton>
      </ArcViewBox>

      <NotificationsPanelCardList
        isPending={statusGetNotifications.pending}
        emptyNode={emptyNode}
        notifications={notifications}
      />
    </ArcViewBox>
  );
}

NotificationsPanelMyInbox.displayName = 'NotificationsPanelMyInbox';

NotificationsPanelMyInbox.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  onRefresh: PropTypes.func,
  statusGetNotifications: PropTypes.objectOf(PropTypes.bool),
  unreadCount: PropTypes.number,
};

NotificationsPanelMyInbox.defaultProps = {
  notifications: [],
  onRefresh: global.noop,
  statusGetNotifications: {},
  unreadCount: 0,
};

export default NotificationsPanelMyInbox;
