import React from 'react';
import { Path } from 'react-primitives-svg';
import Icon from '../Icon';

const Tag = props => (
  <Icon {...props}>
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M23.03 14.545a.552.552 0 01-.249 1l-5.544 1.692-1.693 5.543a.551.551 0 01-1 .249L1.452 9.938a2.789 2.789 0 01-.708-1.707L.738 1.737a1 1 0 011-1h6.491a2.782 2.782 0 011.707.707L23.03 14.545z"
    />
    <Path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.737 7.737a2 2 0 100-4 2 2 0 000 4z"
    />
  </Icon>
);

Tag.displayName = 'Tag';

export default Tag;
