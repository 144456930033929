import React from 'react';
import PropTypes from 'prop-types';

import ArcView from '../../primitives/ArcView/ArcView';

const styleLetter = color => ({
  overflow: 'hidden',
  width: 32,
  height: 32,
  marginRight: 8,
  marginLeft: 8,
  backgroundColor: '#fff',
  borderRadius: 2,
  color,
  border: `2px solid ${color}`,
  fontWeight: 'bold',
  lineHeight: '30px',
  textAlign: 'center',
});

class ArcTeamLetter extends React.PureComponent {
  static propTypes = {
    color: PropTypes.string,
    id: PropTypes.string,
    letter: PropTypes.string,
    name: PropTypes.string.isRequired,
  };

  static defaultProps = {
    color: '',
    id: null,
    letter: '',
    peopleAmount: undefined,
  };

  render() {
    const {
      id,
      color,
      letter,
      name,
    } = this.props;

    const teamColor = color || window.teamDisplayTools.getTeamColor(id);
    const teamLetter = letter || window.teamDisplayTools.getTeamLetter(name);

    return (
      <ArcView style={styleLetter(teamColor)}>
        {teamLetter}
      </ArcView>
    );
  }
}

export default ArcTeamLetter;
