import React, { ComponentProps, FC, useState } from 'react';
import DayPicker, { RangeModifier } from 'react-day-picker';

import { GenericIndexedObject } from 'arcade-frontend-core/src/types/util';

import ArcSelect from '../../elements/ArcSelect';
import ArcDialog from '../../elements/ArcDialog';
import ArcButton from '../../elements/ArcButton';
import {
  applyGraylist,
  DateFilter,
  makeRangeText,
  disabledDays,
  dateAsParam,
} from './helpers';

export interface ArcFormDateRangePickerProps
  extends ComponentProps<typeof ArcSelect> {
  value?: {
    type: 'time period' | 'date range';
    timePeriod?: string;
    fromDate?: string;
    toDate?: string;
  };
  onChange?: (value: DateFilter['value']) => void;
  filterOptions?: GenericIndexedObject<string>[];
  optionWhitelist?: string[];
  optionBlacklist?: string[];
  futureIsDisabled?: boolean;
}

export const ArcFormDateRangePicker: FC<ArcFormDateRangePickerProps> = ({
  value = { type: 'time period', timePeriod: 'this_month' },
  onChange = global.noop,
  filterOptions = [],
  optionWhitelist = [],
  optionBlacklist = [],
  futureIsDisabled = true,
  ...selectProps
}) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState(
    {} as Partial<RangeModifier>,
  );
  const normalizedFilterOptions = filterOptions.map(
    ({ label, value: innerValue, selectedText }) =>
      ({
        label,
        value: {
          type: 'time period',
          timePeriod: innerValue,
        },
        selectedText,
      } as DateFilter),
  );
  const dateRangeOption: DateFilter = {
    label: 'Date Range',
    value: {
      type: 'date range',
      fromDate: value?.fromDate,
      toDate: value?.toDate,
    },
    selectedText: makeRangeText(value),
  };
  const selectOptions = applyGraylist<DateFilter>(normalizedFilterOptions, {
    whitelist: optionWhitelist,
    blacklist: optionBlacklist,
    prop: 'value',
  });
  selectOptions.push(dateRangeOption);

  const onItemClick = ({ type }) =>
    type === 'date range' && setPickerOpen(true);
  const findSelectValue = (selectedValue: DateFilter['value']): string =>
    selectOptions.find(
      ({ value: innerValue }) =>
        (innerValue.type === 'time period' &&
          innerValue.timePeriod &&
          innerValue.timePeriod === selectedValue.timePeriod) ||
        (innerValue.type === 'date range' &&
          innerValue.fromDate &&
          innerValue.toDate &&
          innerValue.fromDate === selectedValue.fromDate &&
          innerValue.toDate === selectedValue.toDate),
    )?.selectedText || '';
  const normalizeSelectedDays = () => {
    if (selectedDays.from && selectedDays.to) {
      return selectedDays as RangeModifier;
    }
    if (selectedDays.from) return selectedDays.from as Date;
    return [] as Date[];
  };
  const captureEvent = ({ target: { value: innerValue } }): void => {
    if (innerValue.type !== 'date range') onChange(innerValue);
  };
  const confirmDateRange = () => {
    if (selectedDays.from && selectedDays.to) {
      const fromDate = dateAsParam(selectedDays.from);
      const toDate = dateAsParam(selectedDays.to);
      onChange({ type: 'date range', fromDate, toDate });
      setPickerOpen(false);
    }
  };

  return (
    <>
      <ArcSelect
        renderValue={findSelectValue}
        onItemClick={onItemClick}
        onChange={captureEvent}
        items={selectOptions}
        value={value}
        variant="outlined"
        {...selectProps}
      />
      <ArcDialog open={pickerOpen} onClose={() => setPickerOpen(false)}>
        <DayPicker
          fixedWeeks
          disabledDays={(day: Date) => disabledDays(day, futureIsDisabled)}
          selectedDays={normalizeSelectedDays()}
          onDayClick={(day: Date) => {
            if (selectedDays.from && selectedDays.to) {
              setSelectedDays({
                from: day,
                to: undefined,
              });
            } else if (selectedDays.from) {
              const [from, to] = [selectedDays.from, day].sort(
                (a, b) => a.getTime() - b.getTime(),
              );
              setSelectedDays({ from, to });
            } else {
              setSelectedDays({ from: day });
            }
          }}
        />
        <ArcButton onClick={confirmDateRange}>{'Confirm'}</ArcButton>
      </ArcDialog>
    </>
  );
};

ArcFormDateRangePicker.defaultProps = {
  value: { type: 'time period', timePeriod: 'this_month' },
  onChange: global.noop,
  filterOptions: [],
  optionWhitelist: [],
  optionBlacklist: [],
};
