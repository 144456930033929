import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import ArcPropTypes from '../../helpers/arc/propTypes';

class ArcRadioButtonGroup extends React.PureComponent {
  static propTypes = {
    children: ArcPropTypes.children,
    disabled: ArcPropTypes.bool,
    error: ArcPropTypes.bool,
    fullWidth: ArcPropTypes.bool,
    hasLabel: ArcPropTypes.bool,
    items: ArcPropTypes.arrayOf(ArcPropTypes.shape({
      label: ArcPropTypes.string.isRequired,
      value: ArcPropTypes.string.isRequired,
    })),
    inputRef: ArcPropTypes.func,
    label: ArcPropTypes.children,
    onBlur: ArcPropTypes.func,
    onChange: ArcPropTypes.func,
    placeholder: ArcPropTypes.children,
    value: ArcPropTypes.oneOfType([
      ArcPropTypes.string,
      ArcPropTypes.number,
    ]),
  };

  static defaultProps = {
    children: undefined,
    disabled: false,
    error: false,
    fullWidth: false,
    hasLabel: true,
    items: [],
    inputRef: undefined,
    label: undefined,
    onBlur: ArcPropTypes.noop,
    onChange: ArcPropTypes.noop,
    placeholder: undefined,
    value: '',
  };

  handleBlur = e => this.props.onBlur(e.target.value);

  handleChange = e => this.props.onChange(e.target.value);

  renderItem = props => (
    <FormControlLabel
      key={props.value}
      disabled={this.props.disabled}
      aria-label={props.label ? props.label : undefined}
      aria-checked={props.value === this.props.value}
      {...props}
      control={<Radio disableRipple />}
    />
  );

  renderItems() {
    return this.props.items.map(this.renderItem);
  }

  render() {
    const {
      error,
      fullWidth,
      hasLabel,
      inputRef,
      items,
      label,
      placeholder,
      ...rest
    } = this.props;

    return (
      <FormControl fullWidth={fullWidth} error={error}>
        {hasLabel && label && (<FormLabel htmlFor={rest.id}>{label}</FormLabel>)}
        <RadioGroup
          {...rest}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          aria-label={label}
          name={rest.id}
        >
          {this.props.children || this.renderItems()}
        </RadioGroup>
        {placeholder && (<FormHelperText>{placeholder}</FormHelperText>)}
      </FormControl>
    );
  }
}

export default ArcRadioButtonGroup;
