const baseNewsfeedFilter = {
  dateFilter: {
    type: 'time period',
    timePeriod: 'all_dates',
  },
};
let newsfeedFilter = baseNewsfeedFilter;

export default {
  get: () => newsfeedFilter,
  clear: () => {
    newsfeedFilter = baseNewsfeedFilter;
  },
  set: update => {
    newsfeedFilter = {
      ...newsfeedFilter,
      ...update,
    };
  },
};
