import { createReducer } from 'arcade-frontend-ui';

import * as typesRoutes from 'arcade-frontend-core/src/types/routes';

const setMetricDetailsType = (state, action) => {
  const { metricDetailsType } = action.payload;

  return metricDetailsType;
};

const handleRouteMetric = () => null;
const handleRouteMetricShow = setMetricDetailsType;

const initialState = null;

const handlers = {
  [typesRoutes.ROUTE_METRICS]: handleRouteMetric,
  [typesRoutes.ROUTE_METRICS_SHOW]: handleRouteMetricShow,
};

const currentMetricDetailsType = createReducer(initialState, handlers);

export default currentMetricDetailsType;

export const getCurrentMetricDetailsType = state => state.metrics.currentMetricDetailsType;
