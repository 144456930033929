import React from 'react';

const SpaceInvader = props => (
  <svg
    width="180"
    height="143"
    viewBox="0 0 180 143"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs><linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="a"><stop stopColor="#19CCE9" offset="0%" /><stop stopColor="#25B4D1" offset="50.531%" /><stop stopColor="#1F6E9A" offset="100%" /></linearGradient></defs><g fillRule="nonzero" fill="none"><path fill="url(#a)" d="M162.07 78.044V48.513h-14.765V33.396l-16.172-.352 1.054-13.008 15.118-1.406V4.92l-17.93-2.462v15.82l-13.008 2.11v13.71l-51.68.352V18.63l-14.062.352-2.11-14.063H34.454l-.351 15.117h13.007l3.516 11.25-17.93 3.164v13.008l-17.693 2.813 2.225 29.882H3.867L2.11 123.044H4.57l14.063.703-.703-30.586 16.172-.351 2.109 30.937 11.602 2.11 1.757 13.71 30.938-2.46-1.406-14.063-30.235.703.703-15.469 82.266-.351-1.055 13.71-30.937 2.11.703 15.82h30.586l1.758-14.765h13.007l.352-31.64 15.82-1.055v32.695h15.47V78.396z" /><g fill="#FDFDFF"><path d="M51.577 48.396h15.002v13.36H51.577zM107.186 48.396h13.359v15.002h-13.36z" /></g><path d="M164.998 75.116V45.118h-14.996V30.116H135v-7.498h15.002V.118h-22.5v14.996H112.5v15.002h-45V15.114H52.498V.118h-22.5v22.5H45v7.498H29.998v15.002H15.002v29.998H0v52.498h22.5V97.616h7.498v29.998H45v15.002h37.502v-22.5H52.498v-7.498h75.004v7.498H97.498v22.5H135v-15.002h15.002V97.616h7.498v29.998H180V75.116h-15.002zM135 7.616h7.498v7.498H135V7.616zm-7.498 127.502h-22.5v-7.504h22.5v7.504zm-90-120.004V7.616H45v7.498h-7.498zm37.496 112.5v7.504h-22.5v-7.504h22.5zm97.504-7.498h-7.504V90.118h-22.5v29.998H135v-15.002H45v15.002h-7.498V90.118h-22.5v29.998H7.498V82.614H22.5V52.616h15.002V37.614h14.996V22.618h7.504v14.996h59.996V22.618h7.504v14.996h14.996v15.002H157.5v29.998h15.002v37.502z" fill="#0290C3" /></g>
  </svg>
);

export default SpaceInvader;
