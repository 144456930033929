import React from 'react';

function Land(props) {
  return (
    <svg viewBox="0 0 100 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M 0 20 C 50 10, 50 10, 100 20, L 100 200 L 0 200 Z" fill="currentColor" />
    </svg>
  );
}

Land.displayName = 'Land';

export default Land;
