import createResourceAction from 'arcade-frontend-core/src/helpers/createResourceAction';
import transformIdToString from 'arcade-frontend-core/src/helpers/transformIdToString';
import { makePaginationParams } from 'arcade-frontend-core/src/actions/helpers/params';

export const apiGetGameEvents = createResourceAction('read', {
  resourceType: 'gameEvents',
  requestKey: 'apiGetGameEvents',

  api: props => {
    const { gameId } = props.meta;

    return {
      method: 'GET',
      url: `/v5/games/${gameId}/events`,
      transformResponse: data => ({
        resources: data.games.map(transformIdToString),
        meta: {
          gameId,
        },
      }),
    };
  },
});

export const apiGetGameEventsPaginated = createResourceAction('read', {
  resourceType: 'gameEvents',
  requestKey: 'apiGetGameEventsPaginated',

  api: props => {
    const { gameId } = props.meta;

    return {
      method: 'GET',
      url: `/v5/games/${gameId}/events/paginated`,
      params: {
        ...makePaginationParams(props.meta),
      },
      transformResponse: data => ({
        resources: data.events?.games.map(transformIdToString),
        meta: {
          ...props.meta,
          totalEvents: data.totalEvents,
        },
      }),
    };
  },
});

export const apiApproveGameEvent = createResourceAction('update', {
  resourceType: 'gameEvents',
  requestKey: 'apiApproveGameEvent',

  api: props => {
    const { gameId, eventId } = props.meta;

    return {
      method: 'PUT',
      url: `/v5/games/${gameId}/pending_sales/${eventId}/approve`,
      transformResponse: data => ({
        resources: [data.message],
        meta: {
          gameId,
          eventId,
        },
      }),
    };
  },
});

export const apiRejectGameEvent = createResourceAction('update', {
  resourceType: 'gameEvents',
  requestKey: 'apiRejectGameEvent',

  api: props => {
    const { gameId, eventId } = props.meta;

    return {
      method: 'PUT',
      url: `/v5/games/${gameId}/pending_sales/${eventId}/reject`,
      transformResponse: data => ({
        resources: [data.message],
        meta: {
          gameId,
          eventId,
        },
      }),
    };
  },
});

export const apiRevertGameEvent = createResourceAction('update', {
  resourceType: 'gameEvents',
  requestKey: 'apiRevertGameEvent',

  api: props => {
    const { gameId, eventId } = props.meta;

    return {
      method: 'PUT',
      url: `/v5/games/${gameId}/pending_sales/${eventId}/undo`,
      transformResponse: data => ({
        resources: [data.message],
        meta: {
          gameId,
          eventId,
        },
      }),
    };
  },
});
