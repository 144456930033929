import React, { FC } from 'react'
import MenuItem from '@material-ui/core/MenuItem';

import ArcCheckbox from 'arcade-frontend-ui/src/elements/ArcCheckbox';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcView from 'arcade-frontend-ui/src/primitives/ArcView';
import GamesOutline from 'arcade-frontend-ui/src/icons/GamesOutline';
import PresentOutline from 'arcade-frontend-ui/src/icons/PresentOutline';

import getTeamColor from 'arcade-frontend-core/src/helpers/getTeamColor';
import getTeamLetter from 'arcade-frontend-core/src/helpers/getTeamLetter';
import ArcEntity from 'arcade-frontend-ui/src/components/ArcEntity';

const ICONS_BY_ID = {
  DISABLED_GameLeaderboardActivity: (
    <GamesOutline color="action" fontSize="small" />
  ),
  DISABLED_RewardActivity: <PresentOutline color="action" fontSize="small" />,
}
export interface NewsfeedActivitiesFilterItemProps {
  item: any;
  selectedById: any;
  onClick: any;
}
const NewsfeedActivitiesFilterItem: FC<NewsfeedActivitiesFilterItemProps> = ({
  item,
  selectedById,
  onClick
}) => {
  return (
    <MenuItem onClick={() => onClick(item.id)}>
      <ArcCheckbox checked={selectedById[item.id]} />
      {item.groupType ? (
        <ArcEntity
          color={getTeamColor(item.groupId)}
          letter={getTeamLetter(item.name)}
          type={item.groupType}
          name={item.name}
          padding="0"
        />
      ) : (
        <ArcView row align="center">
          {!!ICONS_BY_ID[item.id] && (
            <ArcView marginRight="8">{ICONS_BY_ID[item.id]}</ArcView>
          )}

          <ArcText lineHeight="1em">{item.name}</ArcText>
        </ArcView>
      )}
    </MenuItem>
  )
}

export default NewsfeedActivitiesFilterItem
