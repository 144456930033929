import { makeDateFilterParams } from 'arcade-frontend-core/src/actions/helpers';

import * as types from './types';

export const apiManageMetricsTeamsIndexRequest = () => ({
  type: types.MANAGE_METRICS_TEAMS_INDEX.REQUEST,
  payload: {},
});

export const apiManageMetricsCreateRequest = form => ({
  type: types.MANAGE_METRICS_CREATE.REQUEST,
  payload: {
    form,
  },
  meta: {
    analytics: {
      payload: {
        event: 'Created a metric',
      },
    },
  },
});

export const apiManageMetricsUpdateRequest = (id, form) => ({
  type: types.MANAGE_METRICS_UPDATE.REQUEST,
  payload: {
    id,
    form,
  },
});

export const apiManageMetricsEditRequest = id => ({
  type: types.MANAGE_METRICS_EDIT.REQUEST,
  payload: {
    id,
  },
  meta: {
    analytics: {
      payload: {
        event: 'Edited a metric',
      },
    },
  },
});

export const apiManageMetricsArchiveRequest = id => ({
  type: types.MANAGE_METRICS_ARCHIVE.REQUEST,
  payload: {
    id,
  },
  meta: {
    analytics: {
      payload: {
        event: 'Deleted a metric',
      },
    },
  },
});

export const apiManageMetricsRestoreRequest = id => ({
  type: types.MANAGE_METRICS_RESTORE.REQUEST,
  payload: {
    id,
  },
  meta: {
    analytics: {
      payload: {
        event: 'Restored a metric',
      },
    },
  },
});

export const apiManageMetricsIndexRequest = params => ({
  type: types.MANAGE_METRICS_INDEX.REQUEST,
  payload: { ...makeDateFilterParams(params) },
  meta: {
    analytics: {
      payload: {
        event: 'Viewed all created metrics',
      },
    },
  },
});

export const apiManageMetricsActiveIndexRequest = params => ({
  type: types.MANAGE_ACTIVE_METRICS_INDEX.REQUEST,
  payload: { ...makeDateFilterParams(params), people: params.people },
  meta: {
    analytics: {
      payload: {
        event: 'Viewed all active created metrics',
      },
    },
  },
});

export const apiManageMetricsArchivedIndexRequest = params => ({
  type: types.MANAGE_ARCHIVED_METRICS_INDEX.REQUEST,
  payload: { ...makeDateFilterParams(params), people: params.people },
  meta: {
    analytics: {
      payload: {
        event: 'Viewed all archived metrics',
      },
    },
  },
});

export const apiManageMetricsActivitiesIndexRequest = ({
  metricId,
  people,
  ...rest
}) => ({
  type: types.MANAGE_METRICS_ACTIVITIES_INDEX.REQUEST,
  payload: { metricId, people, ...makeDateFilterParams(rest) },
});

export const apiManageMetricsRankingsIndexRequest = ({
  metricId,
  people,
  ...rest
}) => ({
  type: types.MANAGE_METRICS_RANKINGS_INDEX.REQUEST,
  payload: { metricId, people, ...makeDateFilterParams(rest) },
});
