import React from 'react';
import PropTypes from 'prop-types';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const Close = ({ strokeWidth, ...props }) => (
  <Icon {...props}>
    <Path
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="M3 2.999l18 18m0-18l-18 18"
    />
  </Icon>
);

Close.propTypes = {
  strokeWidth: PropTypes.number,
};

Close.defaultProps = {
  strokeWidth: 1.5,
};

export default Close;
