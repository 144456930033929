import { makeApiActions } from 'arcade-frontend-ui';
import {
  makeParams,
  makeDateFilterParams,
  makePaginationParams,
} from 'arcade-frontend-core/src/actions/helpers';

import normalizers from './normalizers';

const shopIndex = () => ({
  method: 'GET',
  url: '/v3/rewards.json',
  normalizer: normalizers.shopIndex,
});

const shopPurchase = ({ rewardID, rewardDenomination, successCallback }) => ({
  method: 'POST',
  url: '/v3/reward_purchases',
  params: {
    reward_id: rewardID,
    selected_denomination: rewardDenomination ? rewardDenomination.label : null,
  },
  rootKey: 'rewards',
  onSuccess: successCallback,
});

const transactionsIndex = params => ({
  method: 'GET',
  url: '/manager/transactions',
  params: {
    ...makeParams(params, ['accountName', 'filter', 'user']),
    ...makeDateFilterParams(params),
    ...makePaginationParams(params),
  },
  normalizer: normalizers.transactionsIndex,
});

const transactionsIndexByUserId = params => ({
  method: 'GET',
  url: `/manager/transactions/for_user/${params.userId}`,
  params: { ...makeDateFilterParams(params), ...makePaginationParams(params) },
  normalizer: normalizers.transactionsIndex,
});

const accountsIndex = () => ({
  method: 'GET',
  url: '/manager/v4/accounts',
  rootKey: 'accounts',
  normalizer: normalizers.accountsIndex,
});

const endpoints = {
  shopIndex,
  shopPurchase,
  transactionsIndex,
  transactionsIndexByUserId,
  accountsIndex,
};

const api = makeApiActions(endpoints);

export default api;
