import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';

import { getLocation } from 'arcade-frontend-features/src/reducers/location';
import { isRPAType } from 'arcade-frontend-core/src/types/game-formats';
import * as ROUTE_TYPES from 'arcade-frontend-core/src/types/routes';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcTab from 'arcade-frontend-ui/src/components/ArcTab';
import ArcTabs from 'arcade-frontend-ui/src/components/ArcTabs';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import AddCircleOutlined from 'arcade-frontend-ui/src/assets/AddCircleOutlined';
import { ArcText, ArcIconButton } from 'arcade-frontend-ui';

function GamesGameCardActions({
  activeTab,
  gameId,
  onTabChange,
  showLeaderboard,
  showVerify,
  showAddMetric,
  type,
  pendingEventsCount,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isTiny = useMediaQuery('(max-width:330px)');
  const dispatch = useDispatch();

  const location = useSelector(getLocation);
  const isRPA = isRPAType(type);

  const handleClickAddScore = () => {
    const routeAction = {
      ...location,
      query: {
        ...location.query,
        form: 'add-sale',
        formGameId: gameId,
      },
    };

    dispatch(routeAction);
  };

  const handleClickVerifyRPAEvents = () => {
    const routeAction = {
      type: ROUTE_TYPES.ROUTE_GAMES_SHOW,
      payload: { id: gameId },
      query: {
        ...location.query,
        tab: 'events',
      },
    };

    dispatch(routeAction);
  };

  const handleClickVerify = () => {
    const routeAction = {
      ...location,
      query: {
        ...location.query,
        form: 'verify-game',
        formGameId: gameId,
      },
    };

    dispatch(routeAction);
  };

  const handleViewCardActionsMenu = event => setAnchorEl(event.currentTarget);

  const handleViewCardActionsMenuClose = () => setAnchorEl(null);

  const handleMenuClick = tab => {
    setAnchorEl(null);
    onTabChange(tab);
  };

  return (
    <ArcBox
      display="flex"
      flexDirection="row"
      pt={1}
      pb={2}
      px={isTiny ? 1 : 3}
      borderTop={1}
      borderColor="divider"
    >
      <ArcTabs
        value={activeTab}
        onChange={tab => onTabChange(tab)}
        alignItems="center"
        flexGrow={1}
      >
        {isTiny ? (
          <>
            {showAddMetric && (
              <ArcTab
                onClick={handleClickAddScore}
                variant="blue"
                value="score"
                condensed
              >
                <ArcBox display="flex" flexDirection="row" alignItems="center">
                  <AddCircleOutlined stroke="#0290C3" height={18} width={18} />
                  <ArcText marginLeft="4">{'Add Score'}</ArcText>
                </ArcBox>
              </ArcTab>
            )}
            <ArcTab
              variant="blue"
              value="events"
              label="Events"
              condensed
              badgeCount={pendingEventsCount}
            />

            <ArcBox ml="auto">
              <ArcIconButton
                aria-label="More Game Actions"
                aria-controls="GamesViewCardActionsMenu"
                aria-haspopup="true"
                onClick={handleViewCardActionsMenu}
              >
                <MoreVert color="disabled" fontSize="small" />
              </ArcIconButton>
            </ArcBox>
          </>
        ) : (
          <>
            {showLeaderboard && (
              <ArcTab variant="blue" value="leaderboard" label="Leaderboard" />
            )}

            <ArcTab
              variant="blue"
              value="events"
              label="Events"
              badgeCount={pendingEventsCount}
            />
            <ArcTab variant="blue" value="rules" label="Rules" />

            {showAddMetric && (
              <ArcTab
                onClick={handleClickAddScore}
                variant="blue"
                value="score"
                pullRight
                label="Add Score"
              >
                <ArcBox display="flex" flexDirection="row" alignItems="center">
                  <AddCircleOutlined
                    stroke="#0290C3"
                    height={18}
                    width={18}
                    strokeWidth={1.5}
                  />
                  {!showLeaderboard && (
                    <ArcText marginLeft="4">{'Add Score'}</ArcText>
                  )}
                </ArcBox>
              </ArcTab>
            )}
          </>
        )}

        {showVerify && (
          <ArcBox
            display="flex"
            flexDirection="row"
            flexGrow={1}
            justifyContent="flex-end"
          >
            <ArcButton
              onClick={isRPA ? handleClickVerifyRPAEvents : handleClickVerify}
              variant="contained"
              color="primary"
              label={isRPA ? 'Verify Events' : 'Verify'}
            />
          </ArcBox>
        )}
      </ArcTabs>
      <Menu
        id="GamesViewCardActionsMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleViewCardActionsMenuClose}
      >
        <MenuItem onClick={() => handleMenuClick('leaderboard')}>
          {'Leaderboard'}
        </MenuItem>
        <MenuItem onClick={() => handleMenuClick('rules')}>{'Rules'}</MenuItem>
      </Menu>
    </ArcBox>
  );
}

GamesGameCardActions.displayName = 'GamesGameCardActions';

GamesGameCardActions.propTypes = {
  activeTab: PropTypes.string,
  gameId: PropTypes.string,
  onTabChange: PropTypes.func,
  showAddMetric: PropTypes.bool,
  showLeaderboard: PropTypes.bool,
  showVerify: PropTypes.bool,
  type: PropTypes.string,
  pendingEventsCount: PropTypes.number,
};

GamesGameCardActions.defaultProps = {
  activeTab: '',
  gameId: '',
  onTabChange: global.noop,
  showAddMetric: false,
  showLeaderboard: true,
  showVerify: false,
  type: '',
  pendingEventsCount: 0,
};

export default GamesGameCardActions;
