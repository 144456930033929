import React from 'react';
import PropTypes from 'prop-types';

const RunningManOutlined = ({ height, width, stroke, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="runningMan">
      <g id="Group">
        <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M20.688 6.78998C20.0415 6.27241 19.0979 6.37671 18.58 7.02298L17.924 7.84198C17.5232 8.34603 16.8444 8.53629 16.24 8.31398L9.24 5.76998C7.45788 5.12795 5.46448 5.66714 4.249 7.11998L3.1 8.49998C2.7235 8.90671 2.60196 9.48791 2.78393 10.0114C2.9659 10.535 3.42172 10.9155 3.96933 11.001C4.51693 11.0865 5.06707 10.8631 5.4 10.42L6.553 9.03998C6.9589 8.55592 7.62376 8.37663 8.218 8.59098L9.893 9.19998L8.012 14.474C7.80795 15.0866 7.23473 15.4999 6.589 15.5H3.75C2.92157 15.5 2.25 16.1716 2.25 17C2.25 17.8284 2.92157 18.5 3.75 18.5H6.589C8.31179 18.5025 9.88431 17.5197 10.637 15.97L11.761 16.42C12.4561 16.6999 12.8396 17.4483 12.661 18.176L11.796 21.636C11.5951 22.4395 12.0835 23.2539 12.887 23.455C13.0064 23.4846 13.129 23.4997 13.252 23.5C13.9398 23.4994 14.5391 23.0312 14.706 22.364L15.57 18.9C16.1076 16.7178 14.959 14.4723 12.875 13.631L11.668 13.149L12.712 10.221L15.212 11.13C17.0256 11.7899 19.0581 11.2198 20.264 9.71298L20.92 8.89998C21.1693 8.58952 21.2849 8.19267 21.2414 7.79689C21.1979 7.40111 20.9988 7.03887 20.688 6.78998Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Line" d="M21.75 12L20.629 9.27802" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Line_2" d="M19.482 6.491L18.25 3.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_2" fillRule="evenodd" clipRule="evenodd" d="M13.75 5.5C15.1307 5.5 16.25 4.38071 16.25 3C16.25 1.61929 15.1307 0.5 13.75 0.5C12.3693 0.5 11.25 1.61929 11.25 3C11.25 4.38071 12.3693 5.5 13.75 5.5Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </g>
  </svg>

);

RunningManOutlined.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  stroke: PropTypes.string,
};

RunningManOutlined.defaultProps = {
  height: 40,
  width: 40,
  stroke: 'black',
};

export default RunningManOutlined;
