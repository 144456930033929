import { Platform } from 'react-primitives';

import { media } from './media';

export const baseSizePx = 16;

export const ptSizeInPx = (pt) => {
  switch (Platform.OS) {
    case 'web': return `${pt}em`;
    default: return pt * baseSizePx;
  }
};

export const body = {
  fontSize: ptSizeInPx(1),
  lineHeight: ptSizeInPx(1.25),

  [media('xs')]: {
    fontSize: ptSizeInPx(1),
    lineHeight: ptSizeInPx(1.25),
  },

  [media('sm')]: {
    fontSize: ptSizeInPx(1),
    lineHeight: ptSizeInPx(1.375),
  },

  [media('md')]: {
    fontSize: ptSizeInPx(1),
    lineHeight: ptSizeInPx(1.375),
  },

  [media('lg')]: {
    fontSize: ptSizeInPx(1),
    lineHeight: ptSizeInPx(1.375),
  },
};

export const h1 = {
  fontSize: ptSizeInPx(2),
  lineHeight: ptSizeInPx(1.25),
  color: 'blue',

  [media('xs')]: {
    fontSize: ptSizeInPx(2),
    lineHeight: ptSizeInPx(1.25),
  },

  [media('sm')]: {
    fontSize: ptSizeInPx(2.5),
    lineHeight: ptSizeInPx(1.125),
  },

  [media('md')]: {
    fontSize: ptSizeInPx(3),
    lineHeight: ptSizeInPx(1.05),
  },

  [media('lg')]: {
    fontSize: ptSizeInPx(3),
    lineHeight: ptSizeInPx(1.05),
  },
};

export const h2 = {
  fontSize: ptSizeInPx(1.625),
  lineHeight: ptSizeInPx(1.15384615),

  [media('xs')]: {
    fontSize: ptSizeInPx(1.625),
    lineHeight: ptSizeInPx(1.15384615),
  },

  [media('sm')]: {
    fontSize: ptSizeInPx(2),
    lineHeight: ptSizeInPx(1.25),
  },

  [media('md')]: {
    fontSize: ptSizeInPx(2.25),
    lineHeight: ptSizeInPx(1.25),
  },

  [media('lg')]: {
    fontSize: ptSizeInPx(2.25),
    lineHeight: ptSizeInPx(1.25),
  },
};

export const h3 = {
  fontSize: ptSizeInPx(1.375),
  lineHeight: ptSizeInPx(1.13636364),

  [media('xs')]: {
    fontSize: ptSizeInPx(1.375),
    lineHeight: ptSizeInPx(1.13636364),
  },

  [media('sm')]: {
    fontSize: ptSizeInPx(1.5),
    lineHeight: ptSizeInPx(1.25),
  },

  [media('md')]: {
    fontSize: ptSizeInPx(1.75),
    lineHeight: ptSizeInPx(1.25),
  },

  [media('lg')]: {
    fontSize: ptSizeInPx(1.75),
    lineHeight: ptSizeInPx(1.25),
  },
};

export const h4 = {
  fontSize: ptSizeInPx(1.125),
  lineHeight: ptSizeInPx(1.11111111),

  [media('xs')]: {
    fontSize: ptSizeInPx(1.125),
    lineHeight: ptSizeInPx(1.11111111),
  },

  [media('sm')]: {
    fontSize: ptSizeInPx(1.125),
    lineHeight: ptSizeInPx(1.22222222),
  },

  [media('md')]: {
    fontSize: ptSizeInPx(1.125),
    lineHeight: ptSizeInPx(1.22222222),
  },

  [media('lg')]: {
    fontSize: ptSizeInPx(1.125),
    lineHeight: ptSizeInPx(1.22222222),
  },
};
