import createResourceAction from 'arcade-frontend-core/src/helpers/createResourceAction';
import {
  makeDateFilterParams,
  makePaginationParams,
} from 'arcade-frontend-core/src/actions/helpers';

export const apiGetRewardPurchases = createResourceAction('read', {
  resourceType: 'rewardPurchases',
  requestKey: 'apiGetRewardPurchases',
  list: 'index',
  mergeListIds: false,

  api: payload => {
    const params = payload.resources[0];

    return {
      method: 'GET',
      url: '/v4/reward_purchases',
      params: {
        ...makeDateFilterParams(params),
        ...makePaginationParams(params),
      },
      transformResponse: data => ({
        resources: data.rewardPurchases.map(reward => ({
          ...reward,
          id: reward.id.toString(),
        })),
        meta: {
          index: {
            totalPurchases: data.totalPurchases,
          },
        },
      }),
    };
  },
});

export const apiCancelRewardPurchase = createResourceAction('update', {
  resourceType: 'rewardPurchases',
  requestKey: 'apiCancelRewardPurchase',

  api: payload => ({
    method: 'PUT',
    url: `/v4/reward_purchases/${payload.resources[0].id}/cancel`,
    transformResponse: data =>
      data.code === 'purchase_cancelled'
        ? {
            resources: [
              {
                id: payload.resources[0].id.toString(),
                code: data.code,
                status: 'cancelled',
              },
            ],
          }
        : {
            resources: [
              {
                id: payload.resources[0].id.toString(),
                code: data.code,
              },
            ],
          },
  }),
});
