import React from 'react';
import cx from 'classnames';
import hoist from 'hoist-non-react-statics';

import { withStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ArcPropTypes from '../../helpers/arc/propTypes';

import { NAMED_COLORS, COLORS } from './constants';

const VARIANTS = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text',
  FLAT: 'flat',
};

const BP_MOUSE_ONLY = '@media (hover: hover) and (pointer: fine)';

const createButtonStyle = (breakpoints, color) => ({
  '&$variant-contained': {
    backgroundColor: color.main,
    marginBottom: 4,
    boxShadow: `0px 4px 0px ${color.dark}`,

    [`
      &:focus,
      &$state-focus
    `]: {
      backgroundColor: color.light,
    },
  },

  '&$variant-contained&$size-small': {
    marginBottom: 2,
  },

  '&$variant-flat': {
    backgroundColor: color.main,

    [`
      &:focus,
      &$state-focus
    `]: {
      backgroundColor: color.light,
    },
  },

  '&$variant-flat:not($color-white)': {
    color: 'white',
  },

  '&$variant-outlined': {
    border: `1px solid ${color.main}`,
    color: color.main,

    [`
      &:focus,
      &$state-focus
    `]: {
      boxShadow: `0 0 0 2px ${color.main}`,
      borderColor: '#fff',
    },
  },

  '&$variant-text': {
    color: color.main,

    [`
      &:focus,
      &$state-focus
    `]: {
      boxShadow: `0 0 0 2px ${color.main}`,
    },
  },

  [`
    &$variant-contained:not($disabled):active,
    &$variant-contained:not($disabled)$state-active
  `]: {
    boxShadow: `0px 3px 0px ${color.dark}`,
    transform: 'translateY(1px)',
  },

  [`
    &$variant-contained:not($disabled):hover,
    &$variant-contained:not($disabled)$state-hover
  `]: {
    [BP_MOUSE_ONLY]: {
      boxShadow: `0px 5px 0px ${color.dark}`,
      transform: 'translateY(-1px)',
    },
  },

  [`
    &$variant-contained:not($disabled):hover:active,
    &$variant-contained:not($disabled)$state-active
  `]: {
    [BP_MOUSE_ONLY]: {
      boxShadow: `0px 3px 0px ${color.dark}`,
      transform: 'translateY(1px)',
    },
  },

  [`
    &$variant-outlined:not($disabled):active,
    &$variant-outlined:not($disabled)$state-active
  `]: {
    backgroundColor: fade(color.main, 0.3),
  },

  [`
    &$variant-outlined:not($disabled):hover,
    &$variant-text:not($disabled):hover,
    &$variant-outlined$state-hover:not($disabled),
    &$variant-text$state-hover:not($disabled)
  `]: {
    [BP_MOUSE_ONLY]: {
      backgroundColor: fade(color.main, 0.1),
    },
  },

  [`
    &$variant-outlined:not($disabled):hover:active,
    &$variant-text:not($disabled):hover:active,
    &$variant-outlined:not($disabled)$state-active,
    &$variant-text:not($disabled)$state-active
  `]: {
    [BP_MOUSE_ONLY]: {
      backgroundColor: fade(color.main, 0.3),
    },
  },
});

const styles = ({ breakpoints, palette, spacing, typography }) => ({
  root: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${spacing(1)}px ${spacing(1.5)}px`,
    fontFamily: typography.fontFamily,
    fontSize: '0.875rem',
    textAlign: 'center',

    backgroundColor: 'transparent',

    border: 0,
    borderSpacing: 0,
    borderRadius: 4,

    cursor: 'pointer',
    userSelect: 'none',
    textTransform: 'uppercase',
    letterSpacing: 0.25,

    '&:focus': {
      outline: 'none',
    },
  },

  'state-default': {},
  'state-hover': {},
  'state-active': {},
  'state-focus': {},

  'size-small': {
    fontSize: '0.75rem',
    minHeight: 28,
    padding: spacing(1),
  },

  'size-large': {
    fontSize: '1.2rem',
    padding: `${spacing(1)}px ${spacing(3)}px`,
  },

  'variant-contained': {
    color: palette.common.white,
  },

  'variant-outlined': {

  },

  'variant-text': {
  },

  'variant-flat': {

  },

  'color-default': createButtonStyle(breakpoints, palette.default),
  'color-primary': createButtonStyle(breakpoints, palette.primary),
  'color-secondary': createButtonStyle(breakpoints, palette.secondary),
  'color-danger': createButtonStyle(breakpoints, palette.danger),
  'color-success': createButtonStyle(breakpoints, palette.success),
  'color-info': createButtonStyle(breakpoints, palette.info),
  'color-warning': createButtonStyle(breakpoints, palette.warning),

  'color-blue': createButtonStyle(breakpoints, palette.blue),
  'color-green': createButtonStyle(breakpoints, palette.green),
  'color-grey': createButtonStyle(breakpoints, palette.grey),
  'color-purple': createButtonStyle(breakpoints, palette.purple),
  'color-red': createButtonStyle(breakpoints, palette.red),
  'color-yellow': createButtonStyle(breakpoints, palette.yellow),
  'color-white': createButtonStyle(breakpoints, { main: '#fff', dark: '#fff', light: '#fff' }),

  ...({
    'textColor-green': { color: palette.green.main },
  }),

  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },

  fullWidth: {
    width: '100%',
  },

  fullHeight: {
    height: '100%',
  },

  textOnly: {
    opacity: 1,
    cursor: 'default',

    '&$disabled': {
      opacity: 0.75,
    },
  },

  isSpaced: {
    marginRight: spacing(1),
  },

  hidden: {
    visibility: 'hidden',
  },

  'textTransform-initial': {
    textTransform: 'initial',
  },
});

class ArcButton extends React.PureComponent {
  static VARIANTS = VARIANTS;

  static propTypes = {
    children: ArcPropTypes.node,
    className: ArcPropTypes.string,
    classes: ArcPropTypes.objectOf(ArcPropTypes.string).isRequired,
    color: ArcPropTypes.oneOf([...COLORS, ...NAMED_COLORS, 'light']),
    component: ArcPropTypes.elementType,
    disabled: ArcPropTypes.bool,
    fullWidth: ArcPropTypes.bool,
    fullHeight: ArcPropTypes.bool,
    hidden: ArcPropTypes.bool,
    isDisabled: ArcPropTypes.bool,
    isSpaced: ArcPropTypes.bool,
    label: ArcPropTypes.node,
    onMouseDown: ArcPropTypes.func,
    size: ArcPropTypes.oneOf([
      'small',
      'default',
      'large',
    ]),
    state: ArcPropTypes.oneOf([
      'default',
      'hover',
      'active',
      'focus',
    ]),
    textOnly: ArcPropTypes.bool,
    textTransform: ArcPropTypes.oneOf([
      '',
      'initial',
    ]),
    type: ArcPropTypes.oneOf([
      'button',
      'submit',
    ]),
    variant: ArcPropTypes.oneOf(Object.values(VARIANTS)),
    textColor: ArcPropTypes.string,
  };

  static defaultProps = {
    children: null,
    className: '',
    color: 'default',
    component: 'button',
    disabled: false,
    fullHeight: false,
    fullWidth: false,
    hidden: false,
    isDisabled: false,
    isSpaced: false,
    label: '',
    onMouseDown: ArcPropTypes.noop,
    size: 'default',
    state: 'default',
    textOnly: false,
    textTransform: '',
    type: 'button',
    variant: 'text',
    textColor: null,
  };

  handleMouseDown = (e) => {
    e.preventDefault();
    this.props.onMouseDown(e);
  };

  render() {
    const {
      children,
      className,
      classes,
      color,
      component: Component,
      disabled,
      fullHeight,
      fullWidth,
      hidden,
      isDisabled,
      isSpaced,
      label,
      size,
      state,
      textOnly,
      textTransform,
      variant,
      textColor,
      ...props
    } = this.props;

    const classNames = cx([
      'ArcButton',
      classes.root,
      classes[`color-${color}`],
      classes[`variant-${variant}`],
      classes[`size-${size}`],
      classes[`state-${state}`],
      classes[`textTransform-${textTransform}`],
      textColor && classes[`textColor-${textColor}`],
      disabled && classes.disabled,
      isDisabled && classes.disabled,
      fullHeight && classes.fullHeight,
      fullWidth && classes.fullWidth,
      hidden && classes.hidden,
      isSpaced && classes.isSpaced,
      textOnly && classes.textOnly,
      textOnly && classes.disabled,
      className,
    ]);

    return (
      <Component
        aria-label={label || undefined}
        {...props}
        className={classNames}
        disabled={disabled}
        onMouseDown={this.handleMouseDown}
      >
        {children || label}
      </Component>
    );
  }
}

export default hoist(withStyles(styles, { withTheme: false })(ArcButton), ArcButton);
