import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const PhotoOutline = props => (
  <Icon {...props}>
    <Path
      fill="currentColor"
      d="M5.25 21A2.252 2.252 0 013 18.75V2.25A2.252 2.252 0 015.25 0h16.5A2.252 2.252 0 0124 2.25v16.5A2.252 2.252 0 0121.75 21H5.25zm0-19.5a.75.75 0 00-.75.75v16.5c0 .414.336.75.75.75h16.5a.75.75 0 00.75-.75V2.25a.75.75 0 00-.75-.75H5.25z"
    />
    <Path
      fill="currentColor"
      d="M2.25 24A2.252 2.252 0 010 21.75v-18a.75.75 0 011.5 0v18c0 .414.336.75.75.75h18a.75.75 0 010 1.5h-18zM8.625 8.249a1.127 1.127 0 01-.797-1.918 1.116 1.116 0 01.835-.329l.009.001A1.116 1.116 0 019.75 7.124c0 .619-.503 1.123-1.122 1.125h-.003z"
    />
    <Path
      fill="currentColor"
      d="M8.272 16.501a.748.748 0 01-.624-1.166l2.546-3.815a1.49 1.49 0 011.251-.668c.342 0 .664.114.933.328l.613.49 1.537-2.195a1.491 1.491 0 011.522-.614c.393.078.732.304.954.637l3.872 5.839a.75.75 0 01-.625 1.165H8.272v-.001zm10.581-1.5l-3.098-4.672-1.994 2.856a.75.75 0 01-1.083.156l-1.237-.989-1.768 2.649h9.18z"
    />
  </Icon>
);

export default PhotoOutline;
