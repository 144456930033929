import {
  makeDateFilterParams,
  makePaginationParams,
} from 'arcade-frontend-core/src/actions/helpers';

import * as types from './types';

export const apiRewardsShopIndexRequest = () => ({
  type: types.REWARDS_SHOP_INDEX.REQUEST,
  payload: {},
});

export const apiRewardsAccountsIndexRequest = () => ({
  type: types.REWARDS_ACCOUNTS_INDEX.REQUEST,
  payload: {},
});

export const apiRewardsShopPurchaseRequest = (
  rewardID,
  rewardDenomination,
) => ({
  type: types.REWARDS_SHOP_PURCHASE.REQUEST,
  payload: {
    rewardID,
    rewardDenomination,
  },
  meta: {
    analytics: {
      payload: {
        event: 'Purchased Giftcard',
      },
    },
  },
});

export const apiRewardsTransactionsIndexRequest = ({
  accountName,
  filter,
  people,
  ...rest
}) => ({
  type: types.REWARDS_TRANSACTIONS_INDEX.REQUEST,
  payload: {
    accountName,
    filter,
    user: people,
    ...makeDateFilterParams(rest),
    ...makePaginationParams(rest),
  },
  meta: {
    analytics: {
      payload: {
        event: `Viewed ${accountName} transactions`,
      },
    },
  },
});

export const apiRewardsTransactionsIndexByUserIdRequest = ({
  userId,
  ...rest
}) => ({
  type: types.REWARDS_TRANSACTIONS_INDEX_BY_USER_ID.REQUEST,
  payload: {
    userId,
    ...makeDateFilterParams(rest),
    ...makePaginationParams(rest),
  },
  meta: {
    analytics: {
      payload: {
        event: `Viewed transactions for user ${userId}`,
      },
    },
  },
});
