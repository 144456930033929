import { useSelector } from 'react-redux';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import { getCurrentUserPermission } from 'arcade-frontend-core/src/reducers/user';

const permissionNames = Object.values(PERMISSIONS);

export default function usePermission(permission) {
  const hasPermission = useSelector(state => getCurrentUserPermission(state, permission));

  if (permissionNames.includes(permission)) {
    return hasPermission;
  }

  console.error(`usePermission called with unrecognised permission ${permission}`);

  return false;
}
