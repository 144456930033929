import createResourceAction from '../../helpers/createResourceAction';

import getNormalizedEntities from '../../helpers/getNormalizedEntities';


export const apiGetChatEntities = createResourceAction('read', {
  resourceType: 'chatAudiences',
  requestKey: 'apiGetChatEntities',

  api: () => ({
    method: 'GET',
    url: '/v4/conversations/invitable_users',
    transformResponse: data => ({ resources: getNormalizedEntities(data) }),
  }),
});

export const apiPostChatEntities = createResourceAction('create', {
  resourceType: 'chatAudiences',
  requestKey: 'apiPostChatEntities',

  api: (payload) => {
    const {
      name,
      ...participantSet
    } = payload.resources[0];

    return {
      method: 'POST',
      url: '/v4/conversations',
      data: {
        conversation: {
          name,
        },
        participant_set: participantSet,
      },
    };
  },
});

export const apiPutChatEntities = createResourceAction('update', {
  resourceType: 'chatAudiences',
  requestKey: 'apiPutChatEntities',

  api: (payload) => {
    const {
      id,
      name,
      ...participantSet
    } = payload.resources[0];

    return {
      method: 'PUT',
      url: `/v4/conversations/${id}`,
      data: {
        conversation: {
          name,
        },
        participant_set: participantSet,
      },
    };
  },
});
