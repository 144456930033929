import React from 'react';

import { G, Path, Rect } from 'react-primitives-svg';

import Icon from '../Icon';

const StatsReport = props => (
  <Icon {...props}>
    <G
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <Path d="M3.5 20.5l2.2-3.083a.5.5 0 01.706-.11L7.6 18.2a.5.5 0 00.7-.1l2.7-3.6" />
      <Rect width="6" height="7" x="15" y="14" rx="0.5" />
      <Path d="M16.5 18.5h1m-1-2h2" />
      <Rect width="6" height="7" x="4" y="4" rx="0.5" />
      <Path d="M5.5 7.5h1m-1-2h2m9.5 5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z" />
      <Path d="M17.751 3.581L17 7l3.474-.425" />
      <Rect width="22" height="22" x="1" y="1" rx="1" />
    </G>
  </Icon>
);


export default StatsReport;
