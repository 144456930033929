import React from 'react';
// import ArcPropTypes from '../../helpers/arc/propTypes';

const RunningTrack = ({ ...props }) => (
  <svg
    width="296"
    height="334"
    viewBox="0 0 296 334"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="#D94A48"
        d="M69.232 41h158.997l67.775 290H0z"
      />
      <path
        d="M99 331l21.862-289.175M202 331L175.299 42.232"
        stroke="#FFF"
        strokeWidth="5"
        strokeLinecap="square"
      />
      <path
        fill="#8B572A"
        d="M65 10h3v31h-3z"
      />
      <path
        fill="#B87F4C"
        d="M67 10h3v31h-3z"
      />
      <path
        fill="#8B572A"
        d="M228 3h3v38h-3z"
      />
      <path
        fill="#B87F4C"
        d="M230 3h3v38h-3z"
      />
      <path
        fill="#FFCA13"
        d="M58 0h182v19H58z"
      />
      <text
        fontFamily="ArcadeNormal, Arcade Normal"
        fontSize="8"
        fill="#FFF"
      >
        <tspan
          x="126.508"
          y="12"
        >
            FINISH
        </tspan>
      </text>
    </g>
  </svg>
);

RunningTrack.propTypes = {
};

RunningTrack.defaultProps = {
};

export default RunningTrack;
