import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import useResourceAction from 'arcade-frontend-core/src/hooks/useResourceAction';

import PeopleTeamList from '../../components/PeopleTeamList/PeopleTeamList';
import { resources } from '../../resources/teams';
import {
  getUpdateTeamsStatus,
  getCreateTeamsStatus,
  getDeleteTeamStatus,
} from '../../reducers/teams';

function PeopleTeamListContainer({
  onDeleteTeam,
  onEditTeamDetails,
  searchTerm,
}) {
  const apiGetTeams = useResourceAction(resources.apiGetTeams);

  const statusCreateTeam = useSelector(getCreateTeamsStatus);
  const statusDeleteTeam = useSelector(getDeleteTeamStatus);
  const statusUpdateTeam = useSelector(getUpdateTeamsStatus);

  const getTeamsRequest = () => {
    apiGetTeams.requestData().catch(() => {
      apiGetTeams.onStatusReset();
    });
  };

  useEffect(() => {
    getTeamsRequest();
  }, []);

  useEffect(() => {
    getTeamsRequest();
  }, [
    statusUpdateTeam.succeeded,
    statusCreateTeam.succeeded,
    statusDeleteTeam.succeeded,
  ]);

  const filterTeams = teams => {
    if (searchTerm.length < 3) {
      return teams;
    }

    const rawSearchTerm = searchTerm.trim().toLowerCase();
    return teams.filter(team =>
      team.name.toLowerCase().includes(rawSearchTerm),
    );
  };

  const handleRetryGetTeams = () => {
    getTeamsRequest();
  };

  const teams = apiGetTeams.hasData ? apiGetTeams.data.resources : [];
  const filteredTeams = filterTeams(teams);

  if (apiGetTeams.isPending && !filteredTeams.length) {
    return <PeopleTeamList.Placeholder />;
  }

  if (!apiGetTeams.isPending && !filteredTeams.length) {
    return (
      <PeopleTeamList.EmptyState
        onRetryGetTeams={handleRetryGetTeams}
        searchTerm={searchTerm}
      />
    );
  }

  return (
    <PeopleTeamList
      onEditTeamDetails={onEditTeamDetails}
      onDeleteTeam={onDeleteTeam}
      teams={filteredTeams}
    />
  );
}

PeopleTeamListContainer.displayName = 'PeopleTeamListContainer';

PeopleTeamListContainer.propTypes = {
  onDeleteTeam: PropTypes.func,
  onEditTeamDetails: PropTypes.func,
  searchTerm: PropTypes.string,
};

PeopleTeamListContainer.defaultProps = {
  onDeleteTeam: global.noop,
  onEditTeamDetails: global.noop,
  searchTerm: '',
};

export default PeopleTeamListContainer;
