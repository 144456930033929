import { actionTypes } from 'redux-resource';

export const updateCommentsFromNewsfeedIndex = response => {
  const { data } = response;

  const comments = {};
  const activityLists = {};

  data.activities.forEach(activity => {
    const commentIds = activity.comments.map(comment => {
      comments[comment.id] = comment;
      return comment.id;
    });

    activityLists[activity.id] = commentIds;
  });

  if (data.pinned) {
    data.pinned.forEach(activity => {
      const commentIds = activity.comments.map(comment => {
        comments[comment.id] = comment;
        return comment.id;
      });

      activityLists[activity.id] = commentIds;
    });
  }

  return {
    type: actionTypes.UPDATE_RESOURCES,
    resources: {
      rrComments: comments,
    },
    lists: {
      rrComments: activityLists,
    },
  };
};

export const updateCommentsFromGetActivityComments = (response, _, action) => {
  const { data: comments } = response;
  const { payload: activityId } = action;

  return {
    type: actionTypes.UPDATE_RESOURCES,
    resources: {
      rrComments: comments,
    },
    lists: {
      rrComments: {
        [activityId]: comments.map(comment => comment.id),
      },
    },
  };
};
