import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { useTheme } from '@material-ui/styles';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcLink from 'arcade-frontend-ui/src/elements/ArcLink';

const errorDetailsLinkStyle = {
  cursor: 'pointer',
};

const listStyle = {
  listStyle: 'disc',
};

function ProfileEditFormDisclaimer() {
  const theme = useTheme();

  const [errorIn, setErrorIn] = React.useState(false);

  function handleClickError() {
    setErrorIn(!errorIn);
  }

  return (
    <>
      <ArcBox
        onClick={handleClickError}
        display="flex"
        alignItems="center"
        style={errorDetailsLinkStyle}
        py={0.25}
        fontSize={12}
      >
        <ChevronRight
          color="inherit"
          style={{
            transition: theme.transitions.create('transform'),
            transform: errorIn ? 'rotate(90deg)' : 'rotate(0)',
          }}
        />
        <ArcBox>{'Why do we collect this information?'}</ArcBox>
      </ArcBox>
      <Collapse in={errorIn}>
        <ArcBox pl={3} fontSize={12}>
          <ArcBox mt={2} color="text.secondary" lineHeight="1.5em">
            <ArcBox>
              {
                'Workplace Arcade collects this personal information as a way to:'
              }
            </ArcBox>
            <ArcBox component="ul" style={listStyle} my={1} ml={3}>
              <ArcBox component="li">{'Identify you on the platform'}</ArcBox>
              <ArcBox component="li">{'Allow you to login'}</ArcBox>
              <ArcBox component="li">
                {'Personalise any app related communication'}
              </ArcBox>
            </ArcBox>
            <ArcBox mb={1}>
              {
                'This information is stored securely on our servers and is not disclosed with any third parties. '
              }
            </ArcBox>
            <ArcBox>
              {'For more information view our '}
              <ArcLink
                size="inherit"
                href="https://www.iubenda.com/privacy-policy/19963067"
              >
                {'full privacy policy'}
              </ArcLink>
            </ArcBox>
          </ArcBox>
        </ArcBox>
      </Collapse>
    </>
  );
}

ProfileEditFormDisclaimer.displayName = 'ProfileEditFormDisclaimer';

export default ProfileEditFormDisclaimer;
