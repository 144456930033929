/* eslint-disable */
import moment from 'moment';
import { date } from 'arcade-frontend-ui';

import { DISPLAYABLE_TITLE_BY_TYPE } from 'arcade-frontend-core/src/types/game-formats';

class Game {
  constructor(props) {
    Object.keys(props).forEach((key) => {
      if (props[key] !== undefined) this[key] = props[key];
    });
  }
}

export const kpiGroup = props => ({
  ...props,
  id: props.id.toString(),
  title: props.title,
  canAddSales: props.allow_manual_add,
});

const displayableFormat = (format) => DISPLAYABLE_TITLE_BY_TYPE[format] || 'No Format Selected';

export const game = props => new Game({
  id: props.campaign_id.toString(),
  currentAmount: props.user_score,
  currentUser: props.user_position || props.relevant_quest,
  expiresAt: props.expires_at && new Date(props.expires_at.raw),
  completedAt: props.completed_at && new Date(props.completed_at.raw),
  imageUrl: props.campaign_image,
  kpiGroup: props.kpi_group ? kpiGroup(props.kpi_group) : {},
  metricId: props.kpi_id ? props.kpi_id.toString() : null,
  name: props.name,
  overtake: props.overtake_information && {
    ...props.overtake_information,
    scoreDifference: props.overtake_information.score_difference,
  },
  rankings: props.rankings && props.rankings.map(ranking => ({
    ...ranking,
    id: ranking.id.toString(),
  })),
  rules: props.rules,
  rewards: props.rewards.map(reward => ({
    ...reward,
    receivingRank: reward.receiving_rank,
    value: reward.type === 'tokens' ? parseInt(reward.value, 10) : reward.value,
  })),
  goal: props.goal,
  hasReachedGoal: props.has_reached_goal,
  participants: props.participants,
  startsAt: props.starts_at.raw,
  startsIn: moment(props.starts_at.formatted).fromNow(),
  status: props.status,
  timeRemaining: props.expires_at.formatted,
  type: displayableFormat(props.type),
  gameType: props.type,
  winners: props.winners,

  isManage: props.is_manage_view,

});

export const gameRanking = props => ({
  id: props.user_id.toString(),
  name: props.username,
  score: props.progress,
  rank: props.rank,
  imageUrl: props.user_image,
});

export const gameEvent = props => ({
  id: props.id.toString(),
  name: props.user_name,
  teamName: props.team_name,
  unit: props.unit,
  quantity: props.quantity,
  score: {
    unit: props.unit,
    quantity: props.quantity,
  },
  rank: null,
  imageUrl: props.user_image,
  createdAt: props.created_at,
  formattedCreatedAt: date.formatFullDateTime(props.created_at),
});
