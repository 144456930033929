import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getLocationQuery } from 'arcade-frontend-features/src/reducers/location';
import { getCurrentUserPermission } from 'arcade-frontend-core/src/reducers/user';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import { actions as widgetActions } from 'arcade-frontend-widgets/src/actions/uploadManager';

import ActivityEditor from '../../components/ActivityEditor';
import { actions } from '../../actions';
import { selectors } from '../../reducers/editing';
import { getRequestStatus } from '../../reducers/requestStatus';
import { getIsViewingProtectedAudience } from '../../reducers/isViewingProtectedAudience';

const getState = (state, props) => ({
  currentlyEditing: selectors.currentlyEditing(state),
  attachedFiles: selectors.attachedFiles(state),
  attachedVideos: selectors.attachedVideos(state),
  requestStatus: getRequestStatus(state),
  canViewHistory: getCurrentUserPermission(
    state,
    PERMISSIONS.VIEW_POST_HISTORY,
  ),
  canPostToProtectedAudiences: getCurrentUserPermission(
    state,
    PERMISSIONS.POST_TO_PROTECTED_AUDIENCES,
  ),
  canUploadFiles: getCurrentUserPermission(state, PERMISSIONS.UPLOAD_FILES),
  canUploadImages: getCurrentUserPermission(state, PERMISSIONS.UPLOAD_PHOTOS),
  canUploadVideos: getCurrentUserPermission(state, PERMISSIONS.UPLOAD_VIDEOS),
  isViewingProtectedAudience: getIsViewingProtectedAudience(state),
  locationQuery: getLocationQuery(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      createActivity: actions.createAndReloadActivity,
      updateActivity: actions.updateAndReloadActivity,
      uploadManagerOpened: widgetActions.uploadManagerOpened,
      onEditActivity: actions.editActivityFromAngular,
      onCancelEdit: actions.editActivityCancel,
      onRemoveMedia: actions.clearAttachedVideos,
    },
    dispatch,
  );

export default connect(getState, getActions)(ActivityEditor);
