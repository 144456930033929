import React from 'react';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import { createWithStyles } from '../../styles';

const styles = {
  Blocker: theme => ({
    root: {
      zIndex: 100,
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,0.9)',
    },

    colorDanger: {
      color: theme.palette.danger.main,
    },
  }),
};

const Blocker = createWithStyles(styles.Blocker)(ArcView);

const ArcScreenBlocker = props => (
  <Blocker {...props}>
    {props.children}
  </Blocker>
);

ArcScreenBlocker.propTypes = {
  children: ArcPropTypes.children.isRequired,
};

export default ArcScreenBlocker;
