import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../actions/customAudience';

const initialState = false;

const handlers = {
  [types.SET_OPEN_CUSTOM_AUDIENCE_DIALOG]: (state, action) => action.payload,
  [types.CUSTOM_AUDIENCE_CREATE.RESET]: () => false,
};

const open = createReducer(initialState, handlers);

export const getOpen = state => state.customAudience.open;

export default open;
