import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ArcAudienceSelector from '../../components/ArcAudienceSelector';
import { getAudiences } from '../../reducers/audiences';
import { getFilter } from '../../reducers/audiences/filter';
import { getLevels } from '../../reducers/audiences/levels';
import { getRequestStatus } from '../../reducers/audiences/requestStatus';
import { actions as customAudienceActions } from '../../actions/customAudience';
import { actions } from '../../actions/audiences';
import ArcCustomAudienceContainer from '../ArcCustomAudienceContainer';

class ArcAudienceSelectorContainer extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <ArcAudienceSelector {...this.props} />
        <ArcCustomAudienceContainer />
      </React.Fragment>
    );
  }
}

const getState = (state, props) => ({
  audiences: getAudiences(state),
  filter: getFilter(state),
  levels: getLevels(state),
  requestStatus: getRequestStatus(state),
  ...props,
});

const getActions = dispatch =>
  bindActionCreators(
    {
      onOpenAudienceTool: () =>
        customAudienceActions.setOpenCustomAudienceDialog(true),
      onFilterToggle: actions.audiencesToggleFilter,
      loadAudienceTypes: actions.audiencesLoadAudienceTypes,
    },
    dispatch,
  );

export default connect(getState, getActions)(ArcAudienceSelectorContainer);
