export const delayedPostMessages = [];

let postMessageFn = message => delayedPostMessages.push(message);

const arcPostMessage = message => postMessageFn(message);

export const setArcPostMessage = (fn) => {
  postMessageFn = fn;
};

export default arcPostMessage;
