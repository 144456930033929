import React, { useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';

import {
  formatDate,
  oneWeekAgo,
  // @ts-ignore
} from 'arcade-frontend-ui/src/helpers/utils/date';
// @ts-ignore
import ArcDateRange from 'arcade-frontend-ui/src/elements/ArcDateRange';
// @ts-ignore
import ArcSelect from 'arcade-frontend-ui/src/elements/ArcSelect';
// @ts-ignore
import ArcTextV2 from 'arcade-frontend-ui/src/components/ArcTextV2';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
// @ts-ignore
import Calendar from 'arcade-frontend-ui/src/icons/Calendar';

import {
  NotificationsPanelHistoryHeaderProps,
  DateRange,
  SelectedFilterItem,
  Filter,
} from './NotificationsPanelHistoryHeader.d';

const useStyles = makeStyles(() => ({
  select: {
    '&:hover': {
      backgroundColor: '#EBEBEB',
    },
  },
}));

const NotificationsPanelHistoryHeader: React.FC<NotificationsPanelHistoryHeaderProps> = ({
  isPending,
  onDateRangeChange,
}) => {
  const classes = useStyles();
  const [filter, setFilter] = useState<Filter>('most-recent');
  const [showDatepicker, setShowDatepicker] = useState(false);

  const [dateRange, setDateRange] = useState<DateRange>({
    from: oneWeekAgo(new Date()),
    to: new Date(),
  });

  const filterItems = [
    {
      value: 'most-recent',
      label: 'Most Recent',
    },
    {
      value: 'date-range',
      label: 'Date Range',
    },
  ];

  const handleDatepickerClose = () => {
    setShowDatepicker(false);
  };

  const getFilterText = () => {
    if (filter === 'most-recent') {
      return 'Most Recent';
    }

    if (dateRange.from && dateRange.to) {
      return `${formatDate(dateRange.from)} to ${formatDate(dateRange.to)}`;
    }
    return 'Date Range';
  };

  const handleFilterChange = (e: Event, selectedItem: SelectedFilterItem) => {
    if (selectedItem.key === 'date-range') {
      setFilter('date-range');
      setShowDatepicker(true);
    }

    if (selectedItem.key === 'most-recent') {
      setFilter('most-recent');
      onDateRangeChange(null);
    }
  };

  const handleDatepickerRangeChange = (selectedDateRange: DateRange) => {
    if (selectedDateRange.from && selectedDateRange.to) {
      setDateRange(selectedDateRange);
      onDateRangeChange(selectedDateRange);
    }
  };

  const renderFilters = () => (
    <ArcViewBox flexDirection="row" alignItems="center">
      <Calendar height={16} width={16} color="#B4B4B4" marginRight={1.5} />
      <ArcTextV2>{getFilterText()}</ArcTextV2>
    </ArcViewBox>
  );

  return (
    <ArcViewBox width="100%">
      <ArcViewBox
        mx={2}
        pl={2}
        pr={0}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        height={50}
      >
        <ArcViewBox flexDirection="row">
          <ArcViewBox mr={2}>
            <ArcTextV2 fontWeight={500} fontSize={20} lineHeight="30px">
              {'History'}
            </ArcTextV2>
          </ArcViewBox>
          <ActivityIndicator size={16} animating={isPending} />
        </ArcViewBox>
        <ArcSelect
          className={classes.select}
          variant="outlined"
          onChange={handleFilterChange}
          items={filterItems}
          value={filter}
          renderValue={renderFilters}
          SelectDisplayProps={{
            style: {
              padding: 12,
              paddingRight: 32,
              fontSize: 12,
              borderWidth: 0,
              height: 'auto',
            },
          }}
        />
        <Dialog open={showDatepicker} onClose={handleDatepickerClose}>
          <ArcViewBox>
            <ArcDateRange
              from={dateRange.from}
              to={dateRange.to}
              onOk={handleDatepickerClose}
              onCancel={handleDatepickerClose}
              onChange={handleDatepickerRangeChange}
            />
          </ArcViewBox>
        </Dialog>
      </ArcViewBox>
    </ArcViewBox>
  );
};

NotificationsPanelHistoryHeader.displayName = 'NotificationsPanelHistoryHeader';

NotificationsPanelHistoryHeader.defaultProps = {
  isPending: false,
  onDateRangeChange: global.noop,
};

export default NotificationsPanelHistoryHeader;
