import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import { getLocation } from 'arcade-frontend-features/src/reducers/location';
import useDynamicRowsPerPage from 'arcade-frontend-core/src/hooks/useDynamicRowsPerPage';
import ArcLink from 'arcade-frontend-ui/src/elements/ArcLink';
import ArcEntity from 'arcade-frontend-ui/src/components/ArcEntity';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import {
  BOUNTY,
  GAME_TYPES,
  isRPAType,
} from 'arcade-frontend-core/src/types/game-formats';
import * as GAME_STATUSES from 'arcade-frontend-core/src/types/game-statuses';
import { ArcText, ArcButton } from 'arcade-frontend-ui';

import GamesEventStatus from '../GamesEventStatus';

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.common.white,

    '& th': {
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: 1.5,
    },
  },

  body: {
    backgroundColor: theme.palette.common.white,

    '& td': {
      whiteSpace: 'nowrap',
    },
  },

  row: {
    '&:nth-child(even)': {
      backgroundColor: theme.palette.common.white,
    },

    '&:nth-child(odd)': {
      backgroundColor: theme.palette.grey[100],
    },
  },

  currentUserRow: {
    backgroundColor: theme.palette.blue.tint,
  },

  fullWidthScrollable: {
    width: '100%',
    overflowX: 'auto',
    scrollbar: 'floating',
  },

  paginationCaption: {
    fontSize: 12,
  },

  toolbar: {
    minHeight: 38,
  },

  statusCell: {
    width: 150,
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const GamesEventsTableHead = React.forwardRef(({ type }, ref) => {
  const classes = useStyles();

  return (
    <TableHead ref={ref}>
      <TableRow className={classes.header}>
        <TableCell align="right">{'Amount'}</TableCell>
        <TableCell>{'Name'}</TableCell>
        {isRPAType(type) && (
          <TableCell className={classes.statusCell}>{'Status'}</TableCell>
        )}
        <TableCell>{'Date'}</TableCell>
      </TableRow>
    </TableHead>
  );
});

const GamesEventsTablePagination = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <ArcBox bgcolor="background.paper">
      <TablePagination
        ref={ref}
        classes={{
          toolbar: classes.toolbar,
          caption: classes.paginationCaption,
        }}
        component="div"
        rowsPerPageOptions={[25, 50, 100]}
        {...props}
      />
    </ArcBox>
  );
});

GamesEventsTableHead.propTypes = {
  type: PropTypes.string,
};
GamesEventsTableHead.defaultProps = {
  type: BOUNTY,
};

function GamesEventsTable({
  gameId,
  currentUserId,
  data,
  type,
  isManager,
  hasFailed,
  total,
  page,
  onChangePage,
  onChangeRowsPerPage,
  onRetry,
  rowsPerPage,
  onEventAction,
  getUpdateStatus,
  status,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useSelector(getLocation);

  const isGameActiveOrPendingVerification =
    status === GAME_STATUSES.ACTIVE ||
    status === GAME_STATUSES.NEEDS_VERIFICATION;
  const shouldShowCompleteGameButton =
    isManager && isRPAType(type) && isGameActiveOrPendingVerification;
  const showRPAMenu = isManager && isRPAType(type);

  const getTotalScore = () =>
    data
      ? data
          .filter(
            event =>
              event.status === 'sale_created' || event.status === 'approved',
          )
          .reduce((sum, event) => sum + event.quantity, 0)
      : 0;

  const getPendingScore = () =>
    data
      ? data
          .filter(event => event.status === 'pending')
          .reduce((sum, event) => sum + event.quantity, 0)
      : 0;

  const totalScore = getTotalScore();
  const pendingScore = getPendingScore();

  const emptyOrErrorMessage = hasFailed
    ? 'Something went wrong'
    : 'No events found';

  const emptyOrErrorAction = (
    <ArcLink title="Try again" onClick={onRetry}>
      <ArcBox ml={0.5}>{'Try again'}</ArcBox>
    </ArcLink>
  );

  const emptyOrErrorRow = (
    <ArcBox
      display="flex"
      flexDirection="row"
      color="text.secondary"
      justifyContent="center"
    >
      {emptyOrErrorMessage} &mdash; {emptyOrErrorAction}
    </ArcBox>
  );

  const handleCompleteGame = () => {
    const routeAction = {
      ...location,
      query: {
        ...location.query,
        form: 'complete-game',
        formGameId: gameId,
      },
    };

    dispatch(routeAction);
  };

  return (
    <ArcBox display="flex" flexDirection="column" width="100%" flex={100}>
      <ArcBox flex={100} className={classes.fullWidthScrollable}>
        <Table>
          <GamesEventsTableHead type={type} />
          <TableBody className={classes.body}>
            {total
              ? data.map((item, idx) => (
                  <TableRow
                    key={item.id}
                    className={cx([
                      'animated animated--fast fadeIn',
                      currentUserId === item.userId
                        ? classes.currentUserRow
                        : classes.row,
                    ])}
                  >
                    <TableCell align="right">{item.quantity}</TableCell>
                    <TableCell className={showRPAMenu ? classes.pointer : ''}>
                      <ArcEntity
                        type={ArcEntity.TYPES.PERSON}
                        name={item.userName}
                        imageUrl={item.userImage}
                        padding="0"
                        teamName={item.teamName}
                      />
                    </TableCell>
                    {isRPAType(type) && (
                      <TableCell className={classes.statusCell}>
                        <GamesEventStatus
                          gameId={gameId}
                          gameStatus={status}
                          eventId={item.id}
                          status={item.status}
                          canVerify={isManager && item.canVerify}
                          updateStatus={getUpdateStatus(item.id)}
                          onVerifyClick={() => onEventAction('verify', item.id)}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      {item.createdAt
                        ? moment(item.createdAt).format('MMM D')
                        : '--'}
                    </TableCell>
                  </TableRow>
                ))
              : data.map((_, idx) => (
                  <TableRow
                    key={idx.toString()}
                    className={cx([
                      'animated animated--fast fadeIn',
                      classes.row,
                    ])}
                  >
                    <TableCell align="center" colSpan="1000">
                      <ArcBox minHeight={24}>
                        {idx === 0 && emptyOrErrorRow}
                      </ArcBox>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </ArcBox>

      {shouldShowCompleteGameButton && (
        <ArcBox
          px={2}
          py={2.1}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          borderTop="1px solid #f3f3f3"
          borderBottom="1px solid #f3f3f3"
        >
          {isManager && (
            <ArcButton
              variant="contained"
              color="primary"
              label="Complete Game"
              onClick={handleCompleteGame}
            />
          )}
          <ArcBox display="flex" flexDirection="column">
            <ArcBox display="flex" flexDirection="row">
              <ArcBox
                component={ArcText}
                fontWeight={300}
                fontSize={16}
                color="#616264"
                mr={1}
              >
                {'Total Score'}
              </ArcBox>

              <ArcBox component={ArcText} fontWeight={700} fontSize={16}>
                {totalScore}
              </ArcBox>
            </ArcBox>
            {pendingScore > 0 && (
              <ArcBox display="flex" flexDirection="row">
                <ArcBox
                  fontWeight={200}
                  fontSize={'caption.fontSize'}
                  color="rgba(0,0,0,0.54)"
                >
                  <ArcText>+ {pendingScore} pending</ArcText>
                </ArcBox>
              </ArcBox>
            )}
          </ArcBox>
        </ArcBox>
      )}

      <GamesEventsTablePagination
        count={total}
        labelDisplayedRows={total ? undefined : global.noop}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </ArcBox>
  );
}

GamesEventsTable.displayName = 'GamesEventsTable';

GamesEventsTable.propTypes = {
  gameId: PropTypes.string,
  currentUserId: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      teamName: PropTypes.string,
      userName: PropTypes.string,
      userImage: PropTypes.string,
      quantity: PropTypes.number,
    }),
  ),
  type: PropTypes.oneOf(GAME_TYPES),
  isManager: PropTypes.bool,
  hasFailed: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onRetry: PropTypes.func,
  onEventAction: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  total: PropTypes.number,
  getUpdateStatus: PropTypes.func,
};

GamesEventsTable.defaultProps = {
  gameId: null,
  currentUserId: '',
  data: [],
  isManager: false,
  type: BOUNTY,
  hasFailed: false,
  onChangePage: global.noop,
  onChangeRowsPerPage: global.noop,
  onRetry: global.noop,
  onEventAction: global.noop,
  page: 0,
  rowsPerPage: 5,
  total: 0,
  getUpdateStatus: global.noop,
};

function GamesEventsTablePlaceholder({
  gameId,
  currentUserId,
  data,
  hasFailed,
  total,
  isManager,
  type,
  page,
  onEventAction,
  onChangePage,
  onChangeRowsPerPage,
  onRetry,
  rowsPerPage,
  getUpdateStatus,
  ...props
}) {
  const classes = useStyles();

  const contentRef = React.useRef(null);
  const tableHeadRef = React.useRef(null);
  const firstTableRowRef = React.useRef(null);

  const ROW_HEIGHT = 59;

  let contentHeight = 0;

  if (contentRef.current && tableHeadRef.current) {
    contentHeight =
      contentRef.current.clientHeight - tableHeadRef.current.clientHeight;
  }

  const rowHeight = firstTableRowRef.current
    ? firstTableRowRef.current.clientHeight
    : ROW_HEIGHT;

  const dynamicRowsPerPage = useDynamicRowsPerPage(
    rowsPerPage,
    contentHeight,
    rowHeight,
  );

  return (
    <ArcBox display="flex" flexDirection="column" width="100%" {...props}>
      <ArcBox flex={100} ref={contentRef}>
        <Table>
          <GamesEventsTableHead ref={tableHeadRef} />
          <TableBody className={classes.body}>
            {Array.from(new Array(dynamicRowsPerPage)).map((_, idx) => (
              <TableRow
                key={idx.toString()}
                ref={idx === 0 ? firstTableRowRef : undefined}
                className={cx(['animated animated--fast fadeIn', classes.row])}
              >
                <TableCell colSpan="1000">
                  <Skeleton height={24} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ArcBox>
      <GamesEventsTablePagination
        backIconButtonProps={{
          disabled: true,
          size: 'small',
        }}
        count={0}
        labelDisplayedRows={() => <Skeleton width={40} />}
        nextIconButtonProps={{
          disabled: true,
          size: 'small',
        }}
        onChangePage={global.noop}
        onChangeRowsPerPage={global.noop}
        page={0}
        rowsPerPage={0}
      />
    </ArcBox>
  );
}

GamesEventsTablePlaceholder.displayName = 'GamesEventsTablePlaceholder';
GamesEventsTablePlaceholder.propTypes = GamesEventsTable.propTypes;
GamesEventsTablePlaceholder.defaultProps = GamesEventsTable.defaultProps;

GamesEventsTable.Placeholder = GamesEventsTablePlaceholder;

export default GamesEventsTable;
