import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const initialState = {
  isInternetReachable: true,
  // isInternetReachable: false,
};

const handleSetIsInternetReachable = (state, action) => action.payload;

const handlers = {
  [types.APP_SET_NETWORK_STATUS]: handleSetIsInternetReachable,
};

const network = createReducer(initialState, handlers);

export default network;

export const getNetwork = state => ({
  isInternetReachable: state.app.network.isInternetReachable,
});
