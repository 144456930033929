import getTransformedResponseFromResourceAction from 'arcade-frontend-core/src/helpers/getTransformedResponseFromResourceAction';

import * as resources from './users.resources';
import apiGetUsersWithDistributePermissionRaw from './raw/apiGetUsersWithDistributePermissionRaw.json';
import apiGetEligibleTokenBudgetUserRaw from './raw/apiGetEligibleTokenBudgetUserRaw.json';

export {
  apiGetUsersWithDistributePermissionRaw,
  apiGetEligibleTokenBudgetUserRaw,
};

export const apiGetUsersWithDistributePermission = getTransformedResponseFromResourceAction(
  resources.apiGetUsersWithDistributePermission,
  apiGetUsersWithDistributePermissionRaw,
);

export const apiGetEligibleTokenBudgetUser = getTransformedResponseFromResourceAction(
  resources.apiGetEligibleTokenBudgetUser,
  apiGetEligibleTokenBudgetUserRaw,
);
