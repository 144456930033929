import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../actions/uploadManager';

const initialState = null;

const handleUploadManagerOpened = (state, action) => action.payload.source;

const handleUploadManagerCancelled = () => null;

const handleUploadManagerFileSelected = () => null;

const handlers = {
  [types.UPLOAD_MANAGER_OPENED]: handleUploadManagerOpened,
  [types.UPLOAD_MANAGER_CANCELLED]: handleUploadManagerCancelled,
  [types.UPLOAD_MANAGER_FILE_SELECTED]: handleUploadManagerFileSelected,
};

export default createReducer(initialState, handlers);

export const getSource = state => state.uploadManager.source;
