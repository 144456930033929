import { makeApiActionTypes } from 'arcade-frontend-ui';

const AUDIENCES_GET_AUDIENCES = makeApiActionTypes('AUDIENCES_GET_AUDIENCES');
const AUDIENCES_GET_AUDIENCE_TYPES = makeApiActionTypes(
  'AUDIENCES_GET_AUDIENCE_TYPES',
);
const AUDIENCES_TOGGLE_FILTER = 'AUDIENCES_TOGGLE_FILTER';
const AUDIENCES_SET_FILTER = 'AUDIENCES_SET_FILTER';

// Actions
const audiencesLoadAudiences = filter => ({
  type: AUDIENCES_GET_AUDIENCES.REQUEST,
  payload: filter,
});

const audiencesLoadAudienceTypes = () => ({
  type: AUDIENCES_GET_AUDIENCE_TYPES.REQUEST,
  payload: {},
});

const audiencesToggleFilter = payload => ({
  type: AUDIENCES_TOGGLE_FILTER,
  payload,
});

const audiencesSetFilter = filter => ({
  type: AUDIENCES_SET_FILTER,
  payload: filter,
});

export const actions = {
  audiencesLoadAudiences,
  audiencesLoadAudienceTypes,
  audiencesToggleFilter,
  audiencesSetFilter,
};

export const types = {
  AUDIENCES_GET_AUDIENCES,
  AUDIENCES_GET_AUDIENCE_TYPES,
  AUDIENCES_TOGGLE_FILTER,
  AUDIENCES_SET_FILTER,
};
