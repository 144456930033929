import React, { useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { getResultsByGameId } from 'arcade-frontend-features/src/reducers/manage/games/gameResults';
import { propTypes as gamePropTypes } from 'arcade-frontend-features/src/resources/games';
import { gameVerificationResults } from 'arcade-frontend-features/src/resources/manage/games/propTypes';
import {
  apiManageGamesResultsIndexRequest, apiManageGamesVerifyRequest,
} from 'arcade-frontend-features/src/actions/manage/games/actions';

import GameVerification from '../../components/GameVerification';


const ManageGamesVerifyContainer = ({
  game,
  getGameResults,
  verifyGame,
  allResults,
  finishedEditing,
  inDialog,
}) => {
  useEffect(() => { getGameResults(game.id); }, [game.id]);

  if (!allResults) { return null; }
  const resultData = allResults[game.id];

  if (!resultData) { return null; }

  const { rankedResults, rewards } = resultData;

  const handleClickVerify = (id, scores) => { verifyGame(id, scores); finishedEditing(); };
  const handleClickClose = () => { finishedEditing(); };

  return (
    <GameVerification
      {...game}
      currentGameDetailsType="verify"
      onClickVerify={handleClickVerify}
      onClose={handleClickClose}
      results={rankedResults}
      rewards={rewards}
      inDialog={inDialog}
    />
  );
};

ManageGamesVerifyContainer.propTypes = {
  game: gamePropTypes.game.isRequired,
  allResults: PropTypes.shape({ rankedResults: PropTypes.objectOf(gameVerificationResults) }),
  getGameResults: PropTypes.func.isRequired,
  verifyGame: PropTypes.func.isRequired,
  finishedEditing: PropTypes.func.isRequired,
  inDialog: PropTypes.bool,
};
ManageGamesVerifyContainer.defaultProps = {
  allResults: null,
  inDialog: false,
};

const mapStateToProps = state => ({
  allResults: getResultsByGameId(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getGameResults: apiManageGamesResultsIndexRequest,
  verifyGame: apiManageGamesVerifyRequest,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ManageGamesVerifyContainer);
