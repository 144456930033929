import TableCell from '@material-ui/core/TableCell';
import { createWithStyles } from '../../styles';

const styles = {
  ArcDataTableCell: theme => ({
    root: {
      alignItems: 'flex-start',
      padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
      flexDirection: 'row',
      '&:first-child': {
        paddingLeft: theme.spacing(2),
      },
      '&:last-child': {
        paddingRight: theme.spacing(3),
      },
    },
    headerCell: {
      whiteSpace: 'nowrap',
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
    },
    strong: {
      fontWeight: '700',
    },
    slim: {
      padding: 2,
    },
  }),
};

const ArcDataTableCell = createWithStyles(styles.ArcDataTableCell)(TableCell);

export default ArcDataTableCell;
