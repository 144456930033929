import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import CalendarToday from '@material-ui/icons/CalendarToday';

import {
  ArcButton,
  ArcCheckbox,
  ArcFormField,
  ArcView,
  ArcText,
} from 'arcade-frontend-ui';

const subtitleStyle = {
  fontSize: '1rem',
  fontWeight: '400',
};

class ArcProfileFormStepWork extends React.PureComponent {
  static propTypes = {
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    touched: PropTypes.shape({
      anniversary: PropTypes.bool,
    }).isRequired,
    values: PropTypes.shape({
      anniversary: PropTypes.string,
    }).isRequired,
  };

  state = {
    openCalendar: false,
    isFindingOutLater: false,
  };

  get hasDate() {
    return !!this.props.values.anniversary;
  }

  get hasOpenCalendar() {
    return this.hasDate || this.state.openCalendar;
  }

  setIsFindingOutLater = isFindingOutLater =>
    this.setState({ isFindingOutLater });

  setOpenCalendar = openCalendar => this.setState({ openCalendar });

  maxDate = new Date();

  handleOpenCalendar = () => {
    this.setIsFindingOutLater(false);
    this.setOpenCalendar(true);
  };

  handleChangeLater = event => {
    const { checked } = event.target;

    this.setIsFindingOutLater(checked);

    if (this.hasOpenCalendar && checked) {
      this.props.setFieldValue('anniversary', '');
      this.props.setFieldTouched('anniversary', false);
      this.setOpenCalendar(false);
    }
  };

  render() {
    return (
      <ArcView>
        <ArcView color="blue" padding="24">
          <ArcView marginTop="16">
            <Typography variant="h4">
              <ArcText color="white">{'About You'}</ArcText>
            </Typography>
          </ArcView>
        </ArcView>
        <ArcView padding="24">
          <ArcView marginBottom="16">
            <ArcView marginBottom="16">
              <Typography variant="h5">
                {'Add your work anniversary'}
              </Typography>
              <Typography variant="caption" style={subtitleStyle}>
                {'Arcade will help celebrate your tenure.'}
              </Typography>
            </ArcView>

            {this.hasOpenCalendar ? (
              <ArcView className="animated fadeIn">
                <ArcFormField
                  name="anniversary"
                  type="datetime"
                  label="Work Anniversary"
                  validations={{
                    isRequired: true,
                  }}
                  initialDate={this.maxDate}
                  maxDate={this.maxDate}
                  mode="permanent"
                  hideTime
                />
              </ArcView>
            ) : (
              <ArcView marginBottom="16" align="flex-start">
                <ArcButton
                  variant="outlined"
                  color="secondary"
                  onClick={this.handleOpenCalendar}
                  label="Select Date"
                >
                  <CalendarToday />
                  <ArcView marginLeft="8">{'Select Date'}</ArcView>
                </ArcButton>
              </ArcView>
            )}

            <ArcCheckbox
              checked={this.state.isFindingOutLater}
              onChange={this.handleChangeLater}
              label="I'll find out later"
            />
          </ArcView>
        </ArcView>
      </ArcView>
    );
  }
}

export default ArcProfileFormStepWork;
