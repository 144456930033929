/* eslint-disable */
import React from 'react';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcList from './ArcList';
import ArcListItem from '../ArcListItem';

class ArcListSelectable extends React.PureComponent {
  static displayName = 'ArcListSelectable';

  static propTypes = {
    listItemRoutes: ArcPropTypes.arrayOf(ArcPropTypes.listItemRoute),
    initialSelectedItem: ArcPropTypes.number,
    style: ArcPropTypes.style,
  };

  static defaultProps = {
    listItemRoutes: [],
    initialSelectedItem: -1,
    style: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedItem: props.initialSelectedItem,
    };
  }

  isSelected(index) {
    return this.state.selectedItem === index;
  }

  isChildOfRoute(parent, route) {
    return parent && parent.indexOf(route) === 0;
  }

  handleClickListItem = (e, selectedItem, onClick) => {
    this.setState({ selectedItem });
    if (onClick) onClick(e);
  };

  renderListItems(listItemRoutes, currentRoute) {
    return listItemRoutes.map((props, index) => {
      const {
        route,
        onClick,
        ...rest
      } = props;

      return (
        <ArcListItem
          key={route || index}
          isSelected={currentRoute ?
            this.isChildOfRoute(currentRoute, route)
            :
            this.isSelected(index)
          }
          onClick={(e) => this.handleClickListItem(e, index, onClick)}
          {...rest}
        />
      );
    });
  }

  render() {
    const {
      initialSelectedItem,
      preList,
      listItemRoutes,
      postList,
      currentRoute,
      ...rest
    } = this.props;

    return (
      <ArcList {...rest} >
        {this.renderListItems(listItemRoutes, currentRoute)}
      </ArcList>
    );
  }
}

export default ArcListSelectable;
