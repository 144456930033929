import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcStyles, { createWithStyles } from '../../styles';

const styles = {
  ArcParagraph: () => ({
    root: {
      fontSize: ArcStyles.ptSizeInPx(1),
      marginTop: 0,
      marginBottom: ArcStyles.ptSizeInPx(1),
      color: ArcStyles.theme.defaultTextColor,
      lineHeight: '1.5em',
    },

    alignLeft: {
      textAlign: 'left',
    },

    alignCenter: {
      textAlign: 'center',
    },

    alignRight: {
      textAlign: 'right',
    },

    hasNoMargin: {
      margin: 0,
    },

    isLoading: {
      height: 24,
      width: '100%',
    },
  }),
};

const ArcParagraph = createWithStyles(styles.ArcParagraph)('p');

ArcParagraph.propTypes = {
  align: ArcPropTypes.oneOf([null, 'left', 'center', 'right']),
};

ArcParagraph.defaultProps = {
  align: undefined,
};

ArcParagraph.displayName = 'ArcParagraph';

export default ArcParagraph;
