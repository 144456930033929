import React from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import ArrowBack from '@material-ui/icons/ArrowBack';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

import {
  ArcButton,
  ArcLoaderButton,
  ArcView,
  ArcText,
  ArcTextField,
} from 'arcade-frontend-ui';

import ArcAudienceList from '../ArcAudienceList';

const buttonStyle = {
  fontSize: 10,
  height: 36,
};

const audienceListStyle = {
  paddingTop: 8,
  paddingBottom: 8,
};

const textfieldInputProps = {
  style: {
    padding: '6px 14px 6px 8px',
  },
};

const titleStyle = {
  marginLeft: 10,
};

class ArcAudienceConfirmation extends React.PureComponent {
  static displayName = 'ArcAudienceConfirmation';

  static propTypes = {
    currentUserId: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    disableSelf: PropTypes.bool,
    hasFieldName: PropTypes.bool,
    namePlaceholder: PropTypes.string,
    nameValue: PropTypes.string,
    placeholderText: PropTypes.node,
    requestStatus: PropTypes.string,
    title: PropTypes.string,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onNameChange: PropTypes.func,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    onSubmit: PropTypes.func,
    peopleOnly: PropTypes.bool,
    submitButtonColor: PropTypes.string,
    submitButtonLabel: PropTypes.string,
  };

  static defaultProps = {
    currentUserId: '',
    data: [],
    disableSelf: false,
    hasFieldName: false,
    namePlaceholder: '',
    nameValue: '',
    placeholderText: '',
    requestStatus: 'DEFAULT',
    title: '',
    onBack: global.noop,
    onCancel: global.noop,
    onNameChange: global.noop,
    onRemove: global.noop,
    onRemoveAll: global.noop,
    onSubmit: global.noop,
    peopleOnly: false,
    submitButtonColor: 'green',
    submitButtonLabel: 'Create',
  };

  get hasData() {
    return !!this.props.data.length;
  }

  render() {
    return (
      <ArcView
        data-testid="ArcAudienceConfirmation"
        flexGrow="1"
        flexShrink="1"
        fullHeight
      >
        <ArcView
          row
          align="center"
          borderBottom="default"
          borderBottomWidth="1"
          paddingBottom="8"
          paddingLeft="4"
        >
          <Hidden smUp>
            <ArcButton size="small" onClick={this.props.onBack}>
              <ArrowBack color="action" fontSize="small" />
            </ArcButton>

            <ArcView style={titleStyle}>{this.props.title}</ArcView>
          </Hidden>

          <Hidden xsDown>
            <ArcView>{this.props.title}</ArcView>
          </Hidden>

          <ArcView spacer />

          <ArcView
            marginLeft="8"
            visibility={this.hasData ? undefined : 'hidden'}
          >
            <ArcButton
              color="danger"
              size="small"
              style={buttonStyle}
              onClick={this.props.onRemoveAll}
            >
              <ArcView marginRight="8">
                <ArcText color="action">{'Remove All'}</ArcText>
              </ArcView>

              <RemoveCircleOutline color="action" fontSize="small" />
            </ArcButton>
          </ArcView>
        </ArcView>

        <ArcAudienceList
          currentUserId={this.props.currentUserId}
          data={this.props.data}
          disableSelf={this.props.disableSelf}
          hasRemove
          onRemove={this.props.onRemove}
          placeholderText={this.props.placeholderText}
          style={audienceListStyle}
          shouldSort={false}
        />

        {(this.hasData || this.props.peopleOnly) && (
          <ArcView
            row
            justify="flex-end"
            borderTop="default"
            borderTopWidth="1"
            paddingTop="8"
          >
            {this.props.hasFieldName ? (
              <ArcView flexGrow="100" flexShrink="1">
                <ArcTextField
                  data-testid="ArcAudienceConfirmation-NameField"
                  autoComplete="off"
                  disabled={this.props.requestStatus === 'REQUEST'}
                  id="audienceName"
                  variant="outlined"
                  placeholder={this.props.namePlaceholder}
                  inputProps={textfieldInputProps}
                  onChange={this.props.onNameChange}
                  value={this.props.nameValue}
                />
              </ArcView>
            ) : (
              <ArcButton onClick={this.props.onCancel} label="Cancel" />
            )}
            <ArcView flexGrow="0" flexShrink="100" marginLeft="8">
              <ArcLoaderButton
                data-testid="ArcAudienceConfirmation-SubmitButton"
                variant="contained"
                color={this.props.submitButtonColor}
                label={this.props.submitButtonLabel}
                size="small"
                fullWidth
                onClick={this.props.onSubmit}
                loadingState={this.props.requestStatus}
                type="submit"
              />
            </ArcView>
          </ArcView>
        )}
      </ArcView>
    );
  }
}

export default ArcAudienceConfirmation;
