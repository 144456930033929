import { makeApiActions } from 'arcade-frontend-ui';
import {
  makeDateFilterParams,
  makePaginationParams,
} from 'arcade-frontend-core/src/actions/helpers';

import {
  activityDataNormalizer,
  activityHistoryNormalizer,
  activityHistoryDetailsNormalizer,
  normalizeAcks,
  normalizeActivities,
  normalizeActivity,
  normalizeLikes,
  normalizeComments,
  normalizeViews,
  reactionNormalizer,
} from './normalizers';

const acknowledgePin = ({ id }) => ({
  method: 'PUT',
  url: `/v3/activities/${id}/activity_pins/acknowledge`,
});

const getActivity = ({ id }) => ({
  method: 'GET',
  url: `/v4/activities/${id}`,
  normalizer: normalizeActivity,
  rootKey: 'activity',
});

const createActivity = data => ({
  method: 'POST',
  url: '/v4/activities/file',
  data,
});

const updateActivity = ({ data, id }) => ({
  method: 'POST',
  url: `/v4/activities/file/${id}`,
  data,
});

const getActivityRespects = ({ id }) => ({
  method: 'GET',
  url: `/v3/activities/${id}/likes`,
  normalizer: normalizeLikes,
});

const getActivityViews = ({ id }) => ({
  method: 'GET',
  url: `/v3/activities/${id}/views`,
  normalizer: normalizeViews,
});

const getActivityAcks = ({ id }) => ({
  method: 'GET',
  url: `/v3/activities/${id}/activity_pins/acknowledged`,
  normalizer: normalizeAcks,
});

const getActivityComments = id => ({
  method: 'GET',
  url: `/v4/activities/${id}/comments`,
  normalizer: normalizeComments,
});

const deleteActivity = ({ id }) => ({
  method: 'DELETE',
  url: `/v1/activities/${id}`,
});

const activities = ({
  page = 1,
  numberOfComments = 5,
  audienceId,
  newsfeedFilter,
}) => {
  let dateFilter;
  if (newsfeedFilter?.dateFilter) {
    const { type, timePeriod, fromDate, toDate } = newsfeedFilter?.dateFilter;
    dateFilter =
      type === 'time period'
        ? {
            time_period: timePeriod,
          }
        : {
            from_date: fromDate,
            to_date: toDate,
          };
  }
  return {
    method: 'GET',
    url: '/v5/activities',
    params: {
      audience_id: audienceId,
      page,
      number_of_comments: numberOfComments,
      selected_activity_types: newsfeedFilter?.selectedActivityTypes,
      selected_audiences: newsfeedFilter?.selectedAudiences,
      ...dateFilter,
    },
    normalizer: activityDataNormalizer,
  };
};

const createComment = data => ({
  method: 'POST',
  url: '/v3/comments.json',
  data,
});

const deleteComment = ({ activityId, commentId }) => ({
  method: 'DELETE',
  url: `/v4/activities/${activityId}/comments/${commentId}`,
});

const toggleRespect = ({ id, value }) => ({
  method: 'PUT',
  url: `/v2/activities/${id}/like`,
  data: value,
});

const pinActivity = ({ id, expires }) => ({
  method: 'POST',
  url: `/v3/activities/${id}/activity_pins`,
  data: { expires },
});

const unpinActivity = ({ id }) => ({
  method: 'DELETE',
  url: `/v3/activities/${id}/activity_pins`,
});

const reportActivity = ({ id }) => ({
  method: 'PUT',
  url: `/v2/activities/${id}/report`,
});

const searchActivities = ({ type, newest, oldest }) => ({
  method: 'GET',
  url: '/v3/activities/system',
  params: {
    type,
    date_from: oldest.toISOString(),
    date_to: newest.toISOString(),
  },
  normalizer: normalizeActivities,
});

const activityHistory = params => ({
  method: 'GET',
  url: '/v4/activities/history',
  params: {
    ...makePaginationParams(params),
    ...makeDateFilterParams(params),
  },
  normalizer: activityHistoryNormalizer,
});

const activityHistoryDetails = id => ({
  method: 'GET',
  url: `/v4/activities/${id}/detailed`,
  rootKey: 'detailed_activity',
  normalizer: activityHistoryDetailsNormalizer,
});
const reactToActivity = ({ id, reaction }) => ({
  method: 'POST',
  url: `/v4/activities/${id}/reactions`,
  data: { reaction },
});

const getActivityReactions = ({ id }) => ({
  method: 'GET',
  url: `/v4/activities/${id}/reactions`,
  rootKey: 'activity_reactions',
  normalizer: reactionNormalizer,
});

const getCommentReactions = ({ id }) => ({
  method: 'GET',
  url: `/v4/comments/${id}/reactions`,
  rootKey: 'comment_reactions',
  normalizer: reactionNormalizer,
});

const deleteActivityReaction = ({ activityId, id }) => ({
  method: 'DELETE',
  url: `/v4/activities/${activityId}/reactions/${id}`,
});

const reactToComment = ({ id, reaction }) => ({
  method: 'POST',
  url: `/v4/comments/${id}/reactions`,
  data: { reaction },
});

const deleteCommentReaction = ({ commentId, id }) => ({
  method: 'DELETE',
  url: `/v4/comments/${commentId}/reactions/${id}`,
});

const promoteActivity = ({ id }) => ({
  method: 'PUT',
  url: `/v4/activities/${id}/share`,
});

const taggedActivities = ({ tag }) => ({
  method: 'GET',
  url: '/v4/activities/by_tag',
  params: { tag },
  normalizer: normalizeActivities,
});

export default makeApiActions({
  activities,
  pinActivity,
  unpinActivity,
  acknowledgePin,
  createActivity,
  updateActivity,
  deleteActivity,
  getActivity,
  createComment,
  deleteComment,
  toggleRespect,
  getActivityRespects,
  getActivityViews,
  getActivityAcks,
  getActivityComments,
  searchActivities,
  reportActivity,
  activityHistory,
  activityHistoryDetails,
  reactToActivity,
  deleteActivityReaction,
  reactToComment,
  deleteCommentReaction,
  getActivityReactions,
  getCommentReactions,
  promoteActivity,
  taggedActivities,
});
