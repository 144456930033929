import React from 'react';
import { Provider } from 'react-redux';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import theme from 'arcade-frontend-ui/src/styles/theme';

import store from '../store';


export const createProvidedContainer = (Container) => {
  // TODO - a better test for React-suitability: https://github.com/treyhuffine/is-react
  if (Container === undefined) {
    throw new Error("Can't wrap undefined in ArcProviders Container");
  }

  return class extends React.PureComponent {
    render() {
      return (
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Container {...this.props} />
          </ThemeProvider>
        </Provider>
      );
    }
  };
};
