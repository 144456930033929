import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ActivityIndicator } from 'react-native';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import IconRefresh from '@material-ui/icons/Refresh';

import Hidden from '@material-ui/core/Hidden';

import ArcTeam from 'arcade-frontend-ui/src/components/ArcPeopleList/ArcTeam';

import {
  ArcIconButton,
  ArcView,
  ArcScroll,
  createWithStyles,
  ArcDataTableCell,
  ArcEntity,
} from 'arcade-frontend-ui';

const NUM_OF_PLACEHOLDERS = 5;
const PLACEHOLDERS = [];

for (let idx = 0; idx < NUM_OF_PLACEHOLDERS; idx += 1) {
  PLACEHOLDERS.push(
    <TableRow key={idx}>
      <TableCell>
        <ArcView className="shimmer" padding="16" />
      </TableCell>
      <TableCell>
        <ArcView className="shimmer" padding="16" />
      </TableCell>
      <TableCell>
        <ArcView className="shimmer" padding="16" />
      </TableCell>
      <TableCell>
        <ArcView className="shimmer" padding="16" />
      </TableCell>
      <TableCell>
        <ArcView className="shimmer" padding="16" />
      </TableCell>
    </TableRow>,
  );
}

const styles = {
  SaleRow: theme => ({
    root: {
      cursor: 'pointer',
      height: theme.spacing(6),
    },

    alternate: {
      backgroundColor: theme.palette.grey[100],
    },
  }),
  SalesCell: theme => ({
    root: {
      padding: theme.spacing(1),
    },
  }),
  FloatingTableHeader: () => ({
    root: {
      zIndex: 10,
    },
  }),
};

const SaleRow = createWithStyles(styles.SaleRow)(TableRow);
const SalesCell = createWithStyles(styles.SalesCell)(TableCell);
const FloatingTableHeader = createWithStyles(styles.FloatingTableHeader)(
  ArcDataTableCell,
);

class SalesTable extends React.PureComponent {
  static propTypes = {
    isRequesting: PropTypes.bool,
    sales: PropTypes.arrayOf(
      PropTypes.shape({
        userName: PropTypes.string,
        teamName: PropTypes.string,
        profileImage: PropTypes.string,
        metricName: PropTypes.string,
        quantity: PropTypes.number,
        createdDate: PropTypes.string,
      }),
    ),
    onChangePage: PropTypes.func,
    onChangeRowsPerPage: PropTypes.func,
    onClickRow: PropTypes.func,
    onRefresh: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    totalSales: PropTypes.number,
  };

  static defaultProps = {
    isRequesting: false,
    sales: [],
    onChangePage: global.noop,
    onChangeRowsPerPage: global.noop,
    onClickRow: global.noop,
    onRefresh: global.noop,
    page: 0,
    rowsPerPage: 50,
    totalSales: 0,
  };

  constructor(props) {
    super(props);

    this.updateRows();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.sales !== nextProps.sales) {
      this.clickHandlers = {};
    }

    const pageChanged = this.props.page !== nextProps.page;
    const salesChanged = this.props.sales !== nextProps.sales;
    const rowsPerPageChanged = this.props.rowsPerPage !== nextProps.rowsPerPage;

    if (pageChanged || salesChanged || rowsPerPageChanged) {
      this.updateRows(nextProps);
    }
  }

  clickHandlers = {};

  createHandleClick = sale => {
    if (!this.clickHandlers[sale.id]) {
      this.clickHandlers[sale.id] = () => this.props.onClickRow(sale);
    }

    return this.clickHandlers[sale.id];
  };

  updateRows(props) {
    const nextProps = props || this.props;
    const { page, rowsPerPage, sales } = nextProps;

    this.emptyRows =
      rowsPerPage - Math.min(rowsPerPage, sales.length - page * rowsPerPage);
    this.rowsWithPlaceholders =
      this.emptyRows === rowsPerPage
        ? this.emptyRows - NUM_OF_PLACEHOLDERS
        : this.emptyRows;

    this.hasMultipleTeams = sales.map(sale => sale.teamId).unique().length > 1;
  }

  handleChangePage = (_e, page) => {
    this.props.onChangePage(page);
  };

  handleChangeRowsPerPage = ({ target: { value } }) => {
    const { page, totalSales, onChangePage, onChangeRowsPerPage } = this.props;
    const rowsPerPage = Number(value);

    onChangeRowsPerPage(rowsPerPage);
    if (rowsPerPage * page > totalSales) onChangePage(0);
  };

  renderTeam = sale => {
    if (!sale.teamId) {
      return 'No Team';
    }

    if (this.hasMultipleTeams) {
      return (
        <ArcTeam
          id={sale.teamId}
          name={sale.teamName}
          style={{
            color: 'inherit',
            fontSize: 'inherit',
          }}
        />
      );
    }

    return sale.teamName;
  };

  renderTableRow = (sale, index) => (
    <SaleRow
      hover
      key={sale.id}
      onClick={this.createHandleClick(sale)}
      alternate={index % 2 === 0}
      className="animated fadeIn"
    >
      <SalesCell scope="row">
        <ArcEntity
          type="person"
          name={sale.userName}
          teamName={sale.teamName}
          imageUrl={sale.userImage}
        />
      </SalesCell>
      <SalesCell>{sale.metricName}</SalesCell>
      <SalesCell align="right" padding="8px">
        {sale.quantity}
      </SalesCell>
      <SalesCell>
        {moment(sale.createdAt).format('YYYY-MM-DD hh:mma')}
      </SalesCell>
    </SaleRow>
  );

  render() {
    const {
      page,
      rowsPerPage,
      isRequesting,
      onRefresh,
      sales,
      totalSales,
    } = this.props;
    const isFirstPage = page === 0;
    const isLastPage = (page + 1) * rowsPerPage >= totalSales;

    return (
      <ArcView scrollParent>
        <ArcView scrollParent>
          <ArcScroll isHorizontal>
            <Table>
              <TableHead>
                <TableRow>
                  <FloatingTableHeader headerCell>User</FloatingTableHeader>
                  <FloatingTableHeader headerCell>Metric</FloatingTableHeader>
                  <FloatingTableHeader headerCell align="right" padding="none">
                    Quantity
                  </FloatingTableHeader>
                  <FloatingTableHeader headerCell>Date</FloatingTableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {sales.map((s, index) => this.renderTableRow(s, index))}

                {!sales.length && isRequesting && PLACEHOLDERS}
              </TableBody>
            </Table>
          </ArcScroll>
        </ArcView>
        <ArcView row align="center">
          <ArcView row>
            <ArcIconButton onClick={onRefresh}>
              <IconRefresh />
              <ArcView
                position="absolute"
                fillToParent
                align="center"
                justify="center"
                style={{ transform: 'scale(0.8)' }}
              >
                <ActivityIndicator animating={isRequesting} hidesWhenStopped />
              </ArcView>
            </ArcIconButton>
          </ArcView>
          <ArcView marginLeft="8" flexGrow={1}>
            <Hidden xsDown>
              <Typography variant="caption">
                {'Click an activity to edit'}
              </Typography>
            </Hidden>
          </ArcView>
          <TablePagination
            backIconButtonProps={{ disabled: isRequesting || isFirstPage }}
            nextIconButtonProps={{ disabled: isRequesting || isLastPage }}
            rowsPerPageOptions={[25, 50, 100]}
            colSpan={3}
            count={totalSales}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            onChangePage={this.handleChangePage}
          />
        </ArcView>
      </ArcView>
    );
  }
}

export default SalesTable;
