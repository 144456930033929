import React from 'react';
import PropTypes from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getCurrentUserPermission } from 'arcade-frontend-core/src/reducers/user';
import * as PERMISSIONS from 'arcade-frontend-core/src/types/permissions';
import withResourceAction from 'arcade-frontend-core/src/helpers/withResourceAction';

import { resources } from '../../resources/comments';
import { getDeleteStatus } from '../../reducers/rrComments';
import NewsfeedCommentMenu from '../../components/NewsfeedCommentMenu';

class NewsfeedCommentMenuContainer extends React.PureComponent {
  static displayName = 'NewsfeedCommentMenuContainer';

  static propTypes = {
    activityId: PropTypes.string,
    canDeleteComments: PropTypes.bool,
    currentUserId: PropTypes.string,
    commentId: PropTypes.string,
    reactions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        reaction: PropTypes.string.isRequired,
        userId: PropTypes.string.isRequired,
        userName: PropTypes.string,
        userImage: PropTypes.string,
      }),
    ),
    status: PropTypes.shape({
      idle: PropTypes.bool,
      pending: PropTypes.bool,
      failed: PropTypes.bool,
      succeeded: PropTypes.bool,
    }),
    onReactionSelect: PropTypes.func,
  };

  static defaultProps = {
    activityId: '',
    canDeleteComments: false,
    currentUserId: null,
    commentId: '',
    reactions: [],
    status: {},
    onReactionSelect: global.noop,
  };

  render() {
    const {
      activityId,
      canDeleteComments,
      commentId,
      currentUserId,
      reactions,
      status,
    } = this.props;

    return (
      <NewsfeedCommentMenu
        activityId={activityId}
        canDeleteComments={canDeleteComments}
        currentUserId={currentUserId}
        commentId={commentId}
        onDelete={() =>
          this.confirmCommentDeletion({
            commentId,
            activityId,
          })
        }
        onSelect={this.props.onReactionSelect}
        reactions={reactions}
        deleteStatus={status}
      />
    );
  }
}

const getState = (state, props) => ({
  status: getDeleteStatus(state),
  canDeleteComments:
    getCurrentUserPermission(state, PERMISSIONS.DELETE_COMMENTS) ||
    props.userCreated,
  ...props,
});

const getActions = dispatch => bindActionCreators({}, dispatch);

export default compose(
  connect(getState, getActions),
  withResourceAction(resources.apiDeleteComment),
)(NewsfeedCommentMenuContainer);
