import React from 'react';

import {
  ArcButton,
  ArcCheckbox,
  ArcFireworksScore,
  ArcParagraph,
  ArcSubheading,
  ArcView,
  forms,
} from 'arcade-frontend-ui';

const model = 'create';

const addTenMinutes = date => new Date(date.getTime() + 600000);
const now = new Date();

const initialState = {
  date: now,
  amount: '',
};

export const reducer = forms.createFormReducer(model, initialState);

const selector = state => state.newForms.activities[model];

const fields = {
  date: {
    type: 'date',
    label: 'Date',
    mode: 'permanent',
    validations: {
      isRequired: true,
    },
  },

  amount: {
    type: 'currency',
    label: 'Amount',
    validations: {
      isRequired: true,
    },
  },
};

const formProps = {
  fields,
  selector,
};

const formStyle = {
  marginLeft: 16,
};

const styleHeading = {
  marginTop: 0,
  marginBottom: 16,
};

const styleFormButtonContainer = {
  marginTop: 16,
  maxWidth: 200,
};

const styleFormElementsContainer = {
  maxWidth: 256,
  marginTop: 16,
};

class FormActivityCreate extends React.PureComponent {
  static propTypes = forms.propTypes;

  constructor(props) {
    super(props);

    this.state = {
      prevScore: props.progress,
    };
  }

  state = {
    isBackdated: false,
  };

  componentWillUnmount() {
    this.props.onReset();
  }

  get amount() {
    const { amount } = this.props.values;
    return amount ? parseFloat(amount, 10) : undefined;
  }

  setIsBackdated = isBackdated => this.setState({ isBackdated });

  handleCloseFireworksScore = () => {
    this.props.onReset();
    this.props.onConfirmationClose();
  };

  handleClickSubmit = () => {
    this.setState(
      {
        prevScore: this.props.progress,
        updatedScore: this.props.progress + this.amount,
      },
      this.props.onSubmit,
    );
  };

  handleChangeIsBackdated = () => {
    this.setIsBackdated(!this.state.isBackdated);
  };

  renderConfirmed() {
    const { prevScore, updatedScore } = this.state;

    return (
      <ArcFireworksScore
        score={updatedScore}
        onClose={this.handleCloseFireworksScore}
        returnState={this.props.returnState}
        symbol={this.props.symbol}
        prevScore={prevScore}
      />
    );
  }

  render() {
    const maxDate = addTenMinutes(new Date());

    if (this.props.hasSubmitted) {
      return this.renderConfirmed();
    }

    return (
      <ArcView style={formStyle}>
        <ArcSubheading style={styleHeading}>
          Add to {this.props.name}
        </ArcSubheading>

        {this.props.description && (
          <ArcParagraph>{this.props.description}</ArcParagraph>
        )}

        <ArcView justify="center" style={styleFormElementsContainer}>
          {this.props.renderFields.amount({
            type: this.props.symbol === '$' ? 'currency' : 'number',
            autoComplete: 'off',
          })}

          <ArcView marginBottom="16">
            <ArcCheckbox
              label="Backdate this activity"
              checked={this.state.isBackdated}
              onChange={this.handleChangeIsBackdated}
            />
          </ArcView>

          {this.state.isBackdated &&
            this.props.renderFields.date({
              maxDate,
              initialDate: new Date(),
            })}
        </ArcView>

        <ArcView style={styleFormButtonContainer}>
          <ArcButton
            variant="contained"
            color="secondary"
            label="Submit"
            onClick={this.handleClickSubmit}
          />
        </ArcView>
      </ArcView>
    );
  }
}

export default forms.createForm(model, formProps)(FormActivityCreate);
