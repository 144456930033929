import { createReducer } from 'arcade-frontend-ui';

import * as types from '../../../actions/manage/metrics/types';

const handleMetricRankingsLoadSuccess = (state, action) => {
  const { metricId, data } = action.payload;

  const newState = { ...state };

  newState[metricId] = data;

  return newState;
};

const initialState = {};

const handlers = {
  [types.MANAGE_METRICS_RANKINGS_INDEX.SUCCESS]: handleMetricRankingsLoadSuccess,
};

const rankingsByGameId = createReducer(initialState, handlers);

export default rankingsByGameId;
