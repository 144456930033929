import React from 'react';
import PropTypes from 'prop-types';

import { ArcView } from 'arcade-frontend-ui';

import GameLeaderboardActivityCurrent from './GameLeaderboardActivityCurrent';
import ChestActivity from './ChestActivity';
import RecognitionActivity from './RecognitionActivity';
import RewardActivity from './RewardActivity';
import WeeklySuperstarActivity from './WeeklySuperstarActivity';
import { summaryType, activityRecord } from '../../propTypes';

const types = {
  ChestActivity,
  GameLeaderboardActivity: GameLeaderboardActivityCurrent,
  RecognitionActivity,
  RewardActivity,
  WeeklySuperstarActivity,
};

const customActivities = [
  'ChestActivity',
  'GameLeaderboardActivity',
  'RecognitionActivity',
  'RewardActivity',
  'WeeklySuperstarActivity',
];

const mappers = {
  GameLeaderboardActivity: activity => ({
    gameId: activity.additionalInformation.id,
    component: ArcView,
  }),
};

const unsupported = type => () => <ArcView>{type} not yet supported</ArcView>;

const getActivityComponent = (activity, extras) => {
  const { type } = activity;
  const Component = types[type] || unsupported(type);
  const propsForType = (mappers[type] || global.noop)(activity);
  return <Component activity={activity} {...propsForType} {...extras} />;
};

getActivityComponent.propTypes = {
  type: summaryType.isRequired,
  activity: activityRecord.isRequired,
  extras: PropTypes.shape({}),
};

getActivityComponent.defaultProps = {
  extras: {},
};

export { customActivities, getActivityComponent };
