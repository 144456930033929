import { createReducer } from 'arcade-frontend-ui';

import * as routeTypes from 'arcade-frontend-core/src/types/routes';

const setMetricId = (state, action) => {
  if (!action.payload) return state;

  const { id } = action.payload;

  return id.toString();
};


const handleManageMetricsShow = setMetricId;
const handleManageMetricsEdit = setMetricId;

const initialState = null;
const reset = () => initialState;

const handlers = {
  [routeTypes.ROUTE_MANAGE_METRICS]: reset,
  [routeTypes.ROUTE_MANAGE_METRICS_SHOW]: handleManageMetricsShow,
  [routeTypes.ROUTE_MANAGE_METRICS_EDIT]: handleManageMetricsEdit,
};

const currentMetricId = createReducer(initialState, handlers);

export default currentMetricId;

export const getCurrentMetricId = state => state.manage.metrics.currentMetricId;
