import axios from 'axios';

import pkgJson from '../../../../../package.json';

export const request = props => axios.request(props);

export const setDefaultHeaders = (headers) => {
  Object.keys(headers).forEach((key) => {
    axios.defaults.headers.common[key] = headers[key];
  });
};

export const setArcUserHeaders = (token, email) => {
  const headers = {
    'X-User-Token': token,
    // 'X-User-Email': encodeURIComponent(email),
    'X-User-Email': email,
  };

  setDefaultHeaders(headers);
};

setDefaultHeaders({
  'X-ARCADE-VERSION': pkgJson.version,
  'X-ARCADE-PLATFORM': 'ARCADE_PLATFORM',
  'X-ARCADE-DEVICE': global.Platform.OS,
});
