import createResourceAction from 'arcade-frontend-core/src/helpers/createResourceAction';

export const apiGetAccounts = createResourceAction('read', {
  resourceType: 'accounts',
  requestKey: 'apiGetAccounts',

  api: () => ({
    method: 'GET',
    url: '/manager/v4/accounts',
    transformResponse: data => ({
      resources: data.accounts,
    }),
  }),
});

export const apiPostAccountTopUp = createResourceAction('update', {
  resourceType: 'accounts',
  requestKey: 'apiPostAccountTopUp',

  api: payload => ({
    method: 'POST',
    url: '/manager/v4/accounts/top_up',
    data: payload.resources[0],
    transformResponse: data => ({
      resources: [data],
    }),
  }),
});
