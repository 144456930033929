import React from 'react';

import ArcPlaceholder from 'arcade-frontend-ui/src/components/ArcPlaceholder';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';

export default () => (
  <ArcBox display="flex" flexDirection="column">
    <ArcPlaceholder padding={24} marginBottom={8} />
    <ArcPlaceholder padding={24} marginBottom={8} />
    <ArcPlaceholder padding={24} marginBottom={8} />
    <ArcPlaceholder padding={24} marginBottom={8} />
  </ArcBox>
);
