import { ArcPropTypes } from 'arcade-frontend-ui';

export const uploadObject = ArcPropTypes.shape({
  progress: ArcPropTypes.shape({ percentage: ArcPropTypes.number.isRequired }),
});

export const videoObject = ArcPropTypes.shape({
  id: ArcPropTypes.string,
  assets: ArcPropTypes.shape({
    icon: ArcPropTypes.string,
    link: ArcPropTypes.string,
    thumbnail: ArcPropTypes.arrayOf(
      ArcPropTypes.shape({
        url: ArcPropTypes.string,
      }),
    ),
    video_webm: ArcPropTypes.arrayOf(
      ArcPropTypes.shape({
        meta: ArcPropTypes.shape({
          duration: ArcPropTypes.number,
        }),
      }),
    ),
  }),
  media_type: ArcPropTypes.oneOf(['Video', 'Document']).isRequired,
  name: ArcPropTypes.string,
  size: ArcPropTypes.number,
  type: ArcPropTypes.string,
  user: ArcPropTypes.shape({
    id: ArcPropTypes.string,
    imageUrl: ArcPropTypes.string,
  }),
});

export const audienceObject = ArcPropTypes.shape({
  id: ArcPropTypes.string.isRequired,
  name: ArcPropTypes.string.isRequired,
  initial: ArcPropTypes.string.isRequired,
  color: ArcPropTypes.string.isRequired,
});

export const levelObject = ArcPropTypes.shape({
  id: ArcPropTypes.string.isRequired,
  description: ArcPropTypes.string.isRequired,
});
