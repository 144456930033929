import React from 'react';
import PropTypes from 'prop-types';

import * as GAME_PLAYER_STATUSES from 'arcade-frontend-core/src/types/game-player-statuses';
import ArcScoreboardBadge from 'arcade-frontend-ui/src/components/ArcScoreboardBadge';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';

function GamesFinalScore({ playerStatus, score, unit }) {
  const scoreHeader =
    playerStatus === GAME_PLAYER_STATUSES.PLAYER
      ? 'Your Score'
      : 'Total Game Score';

  return (
    <ArcBox textAlign="right">
      <ArcText isStrong>{scoreHeader}</ArcText>
      <ArcBox alignContent="flex-end" mt={0.5}>
        <ArcScoreboardBadge
          backgroundColor="#676f80"
          numberWidth={14}
          decimalWidth={6}
          score={score}
          symbol={unit}
          digits={3}
        />
      </ArcBox>
    </ArcBox>
  );
}

GamesFinalScore.displayName = 'GamesFinalScore';

GamesFinalScore.propTypes = {
  playerStatus: PropTypes.oneOf(GAME_PLAYER_STATUSES.ALL_PARTICIPANT_TYPES),
  unit: PropTypes.oneOf(['$', '#']),
  score: PropTypes.number,
};

GamesFinalScore.defaultProps = {
  playerStatus: null,
  score: null,
  unit: '#',
};

export default GamesFinalScore;
