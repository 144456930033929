import React from 'react';

import { Path, G } from 'react-primitives-svg';
import Icon from '../Icon';

const Lock = props => (
  <Icon {...props}>
   <G stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
    <Path clipRule="evenodd" d="M20.5 13.53a4 4 0 00-4-4h-9a4 4 0 00-4 4v6a4 4 0 004 4h9a4 4 0 004-4v-6z" />
    <Path clipRule="evenodd" d="M12 18.03a2 2 0 100-4 2 2 0 000 4z" />
    <Path d="M6.5 9.656V6.03a5.5 5.5 0 1111 0v3.626" />
  </G>
  </Icon>
);

export default Lock;
