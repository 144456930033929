import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const Camera = props => (
  <Icon {...props}>
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M2.913 6.87l.027-.957a.972.972 0 01.93-.956h.903a1.02 1.02 0 011.01.956v.957m-2.87 0h5.74a.957.957 0 00.764-.39C10.661 4.797 11.11 4 12 4h4.783c1.052 0 1.608 1.207 2.127 2.318a.957.957 0 00.866.552h1.257c1.489 0 1.967.649 1.967 1.648v9.936c0 .998-.478 1.807-1.967 1.807H2.967C1.478 20.26 1 19.45 1 18.454V8.518c0-.999.478-1.648 1.913-1.648zm11.478 10.203a3.986 3.986 0 100-7.972 3.986 3.986 0 000 7.972zm-10.043-5.42a1.435 1.435 0 100-2.87 1.435 1.435 0 000 2.87z"
    />
  </Icon>
);

export default Camera;
