import { makeApiActions } from 'arcade-frontend-ui';
import * as normalizers from './normalizers';

const index = () => ({
  method: 'GET',
  url: '/v4/games',
  rootKey: 'games',
  normalizer: normalizers.game,
});

const endpoints = {
  index,

  game: ({ id }) => ({
    method: 'GET',
    url: `/v4/games/${id}`,
    rootKey: 'game',
    rootData: { id },
    normalizer: normalizers.game,
  }),

  rankings: ({ id }) => ({
    method: 'GET',
    url: `/v3/games/${id}/leaderboard`,
    rootKey: 'games',
    rootData: { id },
    normalizer: normalizers.gameRanking,
  }),

  events: ({ id }) => ({
    method: 'GET',
    url: `/v3/games/${id}/events`,
    rootKey: 'games',
    rootData: { id },
    normalizer: normalizers.gameEvent,
  }),

  createActivity: ({ kpiId, amount, date }) => ({
    method: 'POST',
    url: '/v3/sales',
    data: {
      kpi_id: kpiId,
      quantity: amount,
      date,
    },
  }),
};

const api = makeApiActions(endpoints);

export default api;
