import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import {
  ArcIconButton,
  ArcResponsiveDialog,
  ArcText,
  ArcView,
} from 'arcade-frontend-ui';

import NewsfeedActivityCard from '../NewsfeedActivityCard';

const PAPER_PROPS = {
  style: {
    width: '100%',
    height: '100%',
  },
};

const NewsfeedSummaryDialog = ({
  onClose,
  open,
  title,
  summaries,
  onRespect,
  onCommentSave,
  onCommentDelete,
  savingCommentId,
  commentParticipants,
  onReactionSelect,
  reactionsByActivityId,
  reactionsIsRequestingByActivityId,
  currentUser,
  onQuicklinkUser,
  isLoading,
  emptyMessage,
}) => {
  const [currentTitle, setCurrentTitle] = useState(title);

  useEffect(() => {
    if (title) setCurrentTitle(title);
  }, [title]);

  return (
    <ArcResponsiveDialog onClose={onClose} open={open} PaperProps={PAPER_PROPS}>
      <DialogTitle>
        <ArcView row align="center">
          <ArcText>{currentTitle}</ArcText>

          <ArcView spacer />

          <ArcIconButton onClick={onClose} size="small">
            <CloseIcon />
          </ArcIconButton>
        </ArcView>
      </DialogTitle>

      {isLoading ? (
        <ArcView>
          {NewsfeedActivityCard.PLACEHOLDER}
          {NewsfeedActivityCard.PLACEHOLDER}
        </ArcView>
      ) : (
        <React.Fragment>
          {summaries.length > 0 &&
            summaries.map(
              summary =>
                summary && (
                  <NewsfeedActivityCard
                    key={summary.id}
                    onRespect={onRespect}
                    onCommentSave={onCommentSave}
                    onCommentDelete={onCommentDelete}
                    participants={commentParticipants}
                    isSavingComment={savingCommentId === summary.id}
                    onReactionSelect={onReactionSelect}
                    reactions={reactionsByActivityId[summary.id]}
                    reactionsIsRequesting={
                      reactionsIsRequestingByActivityId[summary.id]
                    }
                    currentUserId={currentUser.id}
                    onQuicklinkUser={onQuicklinkUser}
                    {...summary}
                  />
                ),
            )}

          {!summaries.length && (
            <ArcView marginLeft={24}>
              <ArcText isLarge>{emptyMessage}</ArcText>
            </ArcView>
          )}
        </React.Fragment>
      )}
    </ArcResponsiveDialog>
  );
};

NewsfeedSummaryDialog.propTypes = {
  summaries: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onRespect: PropTypes.func,
  onCommentDelete: PropTypes.func,
  onCommentSave: PropTypes.func,
  onQuicklinkUser: PropTypes.func,
  savingCommentId: PropTypes.string,
  commentParticipants: PropTypes.arrayOf(PropTypes.string),
  onReactionSelect: PropTypes.func,
  reactionsByActivityId: PropTypes.shape({}),
  reactionsIsRequestingByActivityId: PropTypes.shape({}),
  currentUser: PropTypes.shape({ id: PropTypes.string }),
  isLoading: PropTypes.bool,
  emptyMessage: PropTypes.string,
};

NewsfeedSummaryDialog.defaultProps = {
  summaries: [],
  savingCommentId: null,
  title: '',
  onClose: global.noop,
  onRespect: global.noop,
  onCommentDelete: global.noop,
  onCommentSave: global.noop,
  onQuicklinkUser: global.noop,
  commentParticipants: [],
  open: false,
  onReactionSelect: global.noop,
  reactionsByActivityId: {},
  reactionsIsRequestingByActivityId: {},
  currentUser: {},
  isLoading: false,
  emptyMessage: 'No results found.',
};

export default NewsfeedSummaryDialog;
