import React from 'react';
import PropTypes from 'prop-types';
import Uppy from '@uppy/core';
import ReduxStore from '@uppy/store-redux';
import Transloadit from '@uppy/transloadit';
import Dropzone from 'react-dropzone';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/styles';

import store from 'arcade-frontend-features/src/store';

import ArcFileList from '../ArcFileList';
import DropTarget from '../DropTarget';
import { videoObject, uploadObject } from '../../propTypes';

const MAX_FILE_SIZE_MB = 20;
const PREVENT_DEFAULT = evt => evt.preventDefault();

const style = theme => ({
  root: {
    borderRadius: 0,
    width: '100%',
    height: '100%',
    margin: 0,
    maxWidth: 'none',
    maxHeight: 'none',

    [theme.breakpoints.up('sm')]: {
      maxWidth: 600,

      margin: theme.spacing(4),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      height: `calc(100% - ${theme.spacing(4)}px)`,
    },

    backgroundColor: theme.palette.background.paper,
    paddingBottom: 'env(safe-area-inset-bottom)',
  },
});

const DialogPaper = withStyles(style)(Paper);

const searchObject = PropTypes.shape({});

class ArcUploadManager extends React.Component {
  static displayName = 'ArcUploadManager';

  static propTypes = {
    open: PropTypes.bool,
    onSelect: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    onUpload: PropTypes.func,
    source: PropTypes.string,
    getMyUploads: PropTypes.func,
    getSharedUploads: PropTypes.func,
    getMyFiles: PropTypes.func,
    getSharedFiles: PropTypes.func,
    createUpload: PropTypes.func,
    uploadStatus: PropTypes.string,
    requestStatus: PropTypes.objectOf(PropTypes.string),
    resetSearch: PropTypes.func,
    searchUploads: PropTypes.func,
    updateUpload: PropTypes.func,
    updateUploadFile: PropTypes.func,
    filesById: PropTypes.objectOf(videoObject).isRequired,
    filesMine: PropTypes.arrayOf(videoObject).isRequired,
    filesShared: PropTypes.arrayOf(videoObject).isRequired,
    uploads: PropTypes.objectOf(uploadObject).isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    updating: PropTypes.bool,
    searchResults: PropTypes.arrayOf(videoObject).isRequired,
    searchResultsById: PropTypes.objectOf(videoObject).isRequired,
    videosById: PropTypes.objectOf(videoObject).isRequired,
    videosMine: PropTypes.arrayOf(videoObject).isRequired,
    videosShared: PropTypes.arrayOf(videoObject).isRequired,
    videosHasMore: PropTypes.bool,
    sharedVideosHasMore: PropTypes.bool,
    filesHasMore: PropTypes.bool,
    sharedFilesHasMore: PropTypes.bool,
    videosIsLoading: PropTypes.bool,
    filesIsLoading: PropTypes.bool,
  };

  static defaultProps = {
    open: false,
    onSelect: global.noop,
    onDelete: global.noop,
    onCancel: global.noop,
    onUpload: global.noop,
    getMyUploads: global.noop,
    getSharedUploads: global.noop,
    getMyFiles: global.noop,
    getSharedFiles: global.noop,
    createUpload: global.noop,
    uploadStatus: undefined,
    requestStatus: undefined,
    resetSearch: global.noop,
    searchUploads: global.noop,
    updateUpload: global.noop,
    updateUploadFile: global.noop,
    updating: false,
    source: null,
    videosHasMore: false,
    sharedVideosHasMore: false,
    filesHasMore: false,
    sharedFilesHasMore: false,
    videosIsLoading: false,
    filesIsLoading: false,
  };

  constructor(props) {
    super(props);
    this.setup();
  }

  state = {
    selectedFileId: null,
  };

  componentWillUnmount() {
    this.destroy();
  }

  setup() {
    const id = 'ArcUploadManager';
    const reduxStore = ReduxStore({ store, id });

    this.uppy = Uppy({
      restrictions: {
        maxFileSize: 1024 * 1024 * 1024 * MAX_FILE_SIZE_MB,
        allowedFileTypes: ['video/*'],
      },
      autoProceed: true,
      store: reduxStore,
    });

    this.uppy.use(Transloadit, {
      params: {
        auth: {
          key: process.env.TRANSLOADIT_AUTH_KEY,
        },
        template_id: process.env.TRANSLOADIT_TEMPLATE_ID,
      },
      waitForEncoding: true,
    });

    this.uppy.on('upload', this.props.onUpload);
    this.uppy.on('complete', this.handleCompletedUpload);
  }

  destroy() {
    this.uppy.close();
  }

  handleCompletedUpload = response => {
    const { successful, transloadit } = response;
    const [details] = successful;
    const [uploads] = transloadit;

    const { name, size, type } = details;
    const { results } = uploads;
    const video = { title: name, name, size, type, assets: results };
    this.props.createUpload({ video });
  };

  handleRefresh = () => {
    this.props.getMyUploads();
    this.props.getSharedUploads();
    this.props.getMyFiles();
    this.props.getSharedFiles();
  };

  handleLoadMore = tab => {
    switch (tab) {
      case 'mine':
        return this.props.getMyUploads({
          requestAll: this.props.videosHasMore,
        });
      case 'shared':
        return this.props.getSharedUploads({
          requestAll: this.props.sharedVideosHasMore,
        });
      case 'my_files':
        return this.props.getMyFiles({
          requestAll: this.props.filesHasMore,
        });
      case 'shared_files':
        return this.props.getSharedFiles({
          requestAll: this.props.sharedFilesHasMore,
        });
      case 'search':
      default:
        return null;
    }
  };

  handleTabChange = tab => {
    if (!this.props.user || !this.props.user.id) {
      return null;
    }

    switch (tab) {
      case 'mine':
        return this.props.getMyUploads();
      case 'shared':
        return this.props.getSharedUploads();
      case 'my_files':
        return this.props.getMyFiles();
      case 'shared_files':
        return this.props.getSharedFiles();
      case 'search':
      default:
        return null;
    }
  };

  addFiles = files => {
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      const { name, type, size } = file;
      this.addFile({ name, type, size, data: file });
    }
  };

  addFile = fileData => {
    try {
      this.uppy.addFile(fileData);
    } catch (exp) {
      // this.props.reportError(message);
    }
  };

  renderDialog = targetProps => {
    const {
      open,
      onSelect,
      onDelete,
      onCancel,
      source,
      uploads,
      user,
      updating,
      searchUploads,
      updateUpload,
      updateUploadFile,
      resetSearch,
    } = this.props;

    return (
      <DropTarget {...targetProps}>
        <Dialog open={open} onClose={onCancel} PaperComponent={DialogPaper}>
          <ArcFileList
            uploads={uploads}
            filesById={this.props.filesById}
            filesMine={this.props.filesMine}
            filesShared={this.props.filesShared}
            updating={updating}
            user={user}
            source={source}
            onAdd={targetProps.open}
            onCancel={onCancel}
            onRefresh={this.handleRefresh}
            onSearchChange={searchUploads}
            onSearchReset={resetSearch}
            onSelect={selectedFile => onSelect({ selectedFile, source })}
            onTabChange={this.handleTabChange}
            onDelete={onDelete}
            onUpdate={updateUpload}
            onUpdateFile={updateUploadFile}
            onLoadMore={this.handleLoadMore}
            requestStatus={this.props.requestStatus}
            searchResults={this.props.searchResults}
            searchResultsById={this.props.searchResultsById}
            uploadStatus={this.props.uploadStatus}
            videosById={this.props.videosById}
            videosMine={this.props.videosMine}
            videosShared={this.props.videosShared}
            videosHasMore={this.props.videosHasMore}
            sharedVideosHasMore={this.props.sharedVideosHasMore}
            filesHasMore={this.props.filesHasMore}
            sharedFilesHasMore={this.props.sharedFilesHasMore}
            videosIsLoading={this.props.videosIsLoading}
            filesIsLoading={this.props.filesIsLoading}
          />
        </Dialog>
      </DropTarget>
    );
  };

  render() {
    return (
      <Dropzone
        onDrop={this.addFiles}
        onClick={PREVENT_DEFAULT}
        accept="video/*"
        multiple={false}
      >
        {this.renderDialog}
      </Dropzone>
    );
  }
}

export default ArcUploadManager;
