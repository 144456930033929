import React from 'react';

import {
  ArcPropTypes,
  // ArcStyles,
  ArcIconButton,
  ArcView,
} from 'arcade-frontend-ui';

const styleNavItemIcon = {
  padding: '16px 8px',
};

const styleNavButton = {
  width: 24,
  height: 24,
  padding: 0,
  margin: '0 4px',
};

const getColor = isActive => (isActive ? 'primary' : 'disabled');

const MetricCardNavItemIcon = ({
  icon: Icon, onClick, isActive, color,
}) => (
  <ArcView style={styleNavItemIcon}>
    <ArcIconButton
      style={styleNavButton}
      onClick={onClick}
    >
      <Icon color={typeof color !== 'undefined' ? color : getColor(isActive)} />
    </ArcIconButton>
  </ArcView>
);

MetricCardNavItemIcon.propTypes = {
  icon: ArcPropTypes.shape({}).isRequired,
  onClick: ArcPropTypes.func,
  isActive: ArcPropTypes.bool,
  color: ArcPropTypes.string,
};

MetricCardNavItemIcon.defaultProps = {
  onClick: ArcPropTypes.noop,
  isActive: false,
  color: undefined,
};

export default MetricCardNavItemIcon;
