/* eslint-disable no-param-reassign */
import produce from 'immer'; // https://immerjs.github.io/immer/docs/introduction

import { CHAT_REDUCER_ACTION } from './actions';
import { ChatStateType } from './types';
import {
  ChatReducerActionType,
  UpdateConversationsListType,
  UpdateCurrentConversationIdType,
  UpdateNewChannelUsersType,
  UpdateJoinableConversationsType,
  UpdateNewMessageType,
  UpdateToggleKebabMenuType,
  UpdateConversationUsersType,
  UpdateInvitableUsersType,
  UpdateMessageActionsType,
  UpdateCurrentConversationTypingUsersType,
  UpdateCurrentConversationMessagesType,
  UpdateCurrentConversationMaxMessageReachedType,
  UpdateCurrentConversationMessageViewsType,
  UpdateIsLoadingConversationMessagesActionsType,
  UpdateSelectedMessageIdType,
  UpdateTaggableConversationUsersActionsType,
  UpdateNetworkInfoType,
  UpdateHashTagsActionsType,
  UpdateLastReadDetailsType,
  UpdateAppStateActionType,
  UpdateFavouriteConversationsListType,
  UpdateCurrentConversationUsersType,
  UpdateIsConversationMessagesEmptyType,
  UpdateSyncMessageStatus,
  UpdateConversationListExpandStatusType,
  UpdateConversationUserPermissionsActionsType,
} from './types/reducerActionTypes';

const INIT_STATE: ChatStateType = {
  favouriteConversationList: [],
  favouriteConversationsById: {},
  conversationsById: {},
  conversationList: [],
  newMessage: {},
  networkInfo: {
    isInternetReachable: null,
    date: null,
  },
  appState: '',
  newChannelUsers: {},
  organisationUsers: [],
  invitableUsers: [],
  conversationTypingUsers: [],
  isConversationListExpanded: {
    favourite: true,
    directMessage: true,
    channel: true,
    groupConversation: true,
  },
  currentConversation: {
    lastReadDetails: {
      index: null,
      messageId: null,
      unreadCount: 0,
    },
    conversationUsers: [],
    selectedMessageId: '',
    taggableUsers: [],
    hashTags: [],
    syncComplete: false,
    maxMessagesReached: false,
    isConversationMessagesEmpty: false,
    messageActions: [],
    messages: [],
    messageViews: [],
    userPermissions: {},
    conversationId: '0',
    loading: false,
  },
  joinableConversations: [],
  toggleKebabMenuDetails: {
    toggle: false,
    parent: '',
    id: '0',
    others: {},
  },
};

export default (state = INIT_STATE, action: ChatReducerActionType) => {
  switch (action.type) {
    case CHAT_REDUCER_ACTION.UPDATE_CONVERSATION_LIST: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateConversationsListType;
        draftState.conversationsById = {};
        if (tempAction?.payload?.length) {
          tempAction.payload.forEach((conversation) => {
            draftState.conversationsById[conversation.id] = conversation;
          });
        }

        draftState.conversationList = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_FAVOURITE_CONVERSATION_LIST: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateFavouriteConversationsListType;
        draftState.favouriteConversationsById = {};
        if (tempAction?.payload?.length) {
          tempAction.payload.forEach((conversation) => {
            draftState.favouriteConversationsById[conversation.id] =
              conversation;
          });
        }
        draftState.favouriteConversationList = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.RESET_CURRENT_CONVERSATION: {
      return produce(state, (draftState) => {
        const currentConversation = {
          selectedMessageId: '',
          taggableUsers: [],
          hashTags: [],
          maxMessagesReached: false,
          syncComplete: false,
          isConversationMessagesEmpty: false,
          messageActions: [],
          conversationUsers: [],
          messages: [],
          messageViews: [],
          conversationId: '0',
          lastReadDetails: {
            index: null,
            messageId: null,
            unreadCount: 0,
          },
          userPermissions: {},
          loading: false,
        };
        draftState.currentConversation = currentConversation;
      });
    }
    case CHAT_REDUCER_ACTION.RESET_LAST_READ_DETAILS: {
      return produce(state, (draftState) => {
        const lastReadDetails = {
          index: null,
          messageId: null,
          unreadCount: 0,
        };
        draftState.currentConversation.lastReadDetails = lastReadDetails;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_APP_STATE: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateAppStateActionType;
        draftState.appState = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_TAGGABLE_CONVERSATION_USERS: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateTaggableConversationUsersActionsType;
        draftState.currentConversation.taggableUsers = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_HASH_TAGS: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateHashTagsActionsType;
        draftState.currentConversation.hashTags = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_CONVERSATION_USER_PERMISSIONS: {
      return produce(state, (draftState) => {
        const tempAction =
          action as UpdateConversationUserPermissionsActionsType;
        draftState.currentConversation.userPermissions = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_MESSAGE_ACTIONS: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateMessageActionsType;
        draftState.currentConversation.messageActions = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_LAST_READ_DETAILS: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateLastReadDetailsType;
        draftState.currentConversation.lastReadDetails = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_SYNC_MESSAGES_STATUS: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateSyncMessageStatus;
        draftState.currentConversation.syncComplete = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_SELECTED_MESSAGE_ID: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateSelectedMessageIdType;
        draftState.currentConversation.selectedMessageId = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_CURRENT_CONVERSATION_USERS: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateCurrentConversationUsersType;
        draftState.currentConversation.conversationUsers = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_IS_LOADING_CONVERSATION_MESSAGES: {
      return produce(state, (draftState) => {
        const tempAction =
          action as UpdateIsLoadingConversationMessagesActionsType;
        draftState.currentConversation.loading = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_CURRENT_CONVERSATION_MESSAGES: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateCurrentConversationMessagesType;
        draftState.currentConversation.messages = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_CURRENT_CONVERSATION_MESSAGES_VIEWS: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateCurrentConversationMessageViewsType;
        draftState.currentConversation.messageViews = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_CURRENT_CONVERSATION_ID: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateCurrentConversationIdType;
        draftState.currentConversation.conversationId = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_CURRENT_CONVERSATION_MAX_MESSAGES_REACHED: {
      return produce(state, (draftState) => {
        const tempAction =
          action as UpdateCurrentConversationMaxMessageReachedType;
        draftState.currentConversation.maxMessagesReached = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_IS_CONVERSATION_MESSAGES_EMPTY: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateIsConversationMessagesEmptyType;
        draftState.currentConversation.isConversationMessagesEmpty =
          tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_NEW_MESSAGE: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateNewMessageType;
        draftState.currentConversation.messages = [
          tempAction.payload,
          ...draftState.currentConversation.messages,
        ];
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_NEW_CHANNEL_USERS: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateNewChannelUsersType;
        draftState.newChannelUsers = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_JOINABLE_CONVERSATIONS: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateJoinableConversationsType;
        draftState.joinableConversations = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_ORGANISATGION_USERS: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateConversationUsersType;
        draftState.organisationUsers = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_INVITABLE_USERS: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateInvitableUsersType;
        draftState.invitableUsers = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_CURRENT_CONVERSATION_TYPING_USERS: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateCurrentConversationTypingUsersType;
        draftState.conversationTypingUsers = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_IS_EXPAND_CONVERSTION_LIST: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateConversationListExpandStatusType;
        draftState.isConversationListExpanded = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.UPDATE_NETWORK_INFO: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateNetworkInfoType;
        draftState.networkInfo = tempAction.payload;
      });
    }
    case CHAT_REDUCER_ACTION.TOOGLE_KEBAB_MENU_DETAILS: {
      return produce(state, (draftState) => {
        const tempAction = action as UpdateToggleKebabMenuType;
        const { parent, id = '0', others = {} } = tempAction.payload;
        draftState.toggleKebabMenuDetails = {
          toggle: !state.toggleKebabMenuDetails.toggle,
          parent,
          id,
          others,
        };
      });
    }
    default:
      return state;
  }
};
