import React from 'react';
import PropTypes from 'prop-types';

import ArcadeLogo from 'arcade-frontend-ui/src/assets/ArcadeLogo';
import AlertDiamond from 'arcade-frontend-ui/src/icons/AlertDiamond';
import RefreshOutline from 'arcade-frontend-ui/src/icons/RefreshOutline';
import ArcButton from 'arcade-frontend-ui/src/components/ArcButton';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
import ArcTextV2 from 'arcade-frontend-ui/src/components/ArcTextV2';

function WebLoadingLogo({ errorMessage, hasError, visible }) {
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    let timeout;

    if (visible) {
      setIsMounted(true);
    } else {
      timeout = setTimeout(() => {
        setIsMounted(false);
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [visible]);

  function handleClick() {
    if (window?.location) {
      window.location.reload();
    }
  }

  if (!isMounted) {
    return null;
  }

  return (
    <ArcViewBox
      zIndex={1000}
      position="fixed"
      width="100%"
      height="100%"
      top={0}
      right={0}
      bottom={0}
      left={0}
      bgcolor="background.paper"
      alignItems="center"
      justifyContent="center"
    >
      {hasError && (
        <ArcViewBox position="absolute" className="animated fadeIn">
          <AlertDiamond color="#C4C4C4" width="96px" height="96px" />
        </ArcViewBox>
      )}
      <ArcViewBox
        position="absolute"
        className={(!visible || hasError) && 'animated fadeOut'}
      >
        <ArcadeLogo animated width={72} />
      </ArcViewBox>
      {hasError && (
        <ArcViewBox mt={232} position="absolute" alignItems="center">
          <ArcTextV2 mb={4}>{errorMessage}</ArcTextV2>
          <ArcButton
            variant="outlined"
            color="blue"
            label="Try Again"
            onClick={handleClick}
          >
            <RefreshOutline />
            <ArcTextV2 ml={2}>{'Try Again'}</ArcTextV2>
          </ArcButton>
        </ArcViewBox>
      )}
    </ArcViewBox>
  );
}

WebLoadingLogo.displayName = 'WebLoadingLogo';

WebLoadingLogo.propTypes = {
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  visible: PropTypes.bool,
};

WebLoadingLogo.defaultProps = {
  errorMessage: 'Something went wrong while starting Arcade.',
  hasError: false,
  visible: false,
};

export default WebLoadingLogo;
