import createAction from '../createAction';

export default (type, options) => {
  const types = {
    REQUEST: `api/${type}/REQUEST`,
    SUCCESS: `api/${type}/SUCCESS`,
    FAILURE: `api/${type}/FAILURE`,
    RESET: `api/${type}/RESET`,
  };

  return {
    types,
    request: createAction(types.REQUEST),
    success: createAction(types.SUCCESS),
    failure: createAction(types.FAILURE),
    ...options,
  };
};
