// ////////////////////////////////////////////////////////////////////////////
// Shortcuts
const shortcuts = {
  ANGRY: ['>:(', '>:-('],
  ANGUISHED: ['D:'],
  BROKEN_HEART: ['</3'],
  CONFUSED: [':/', ':-/', ':\\', ':-\\'],
  DISAPPOINTED: [':(', '):', ':-('],
  HEART: ['<3'],
  KISS: [':*', ':-*'],
  MONKEY_FACE: [':o)'],
  NEUTRAL_FACE: [':|'],
  SATISFIED: [':>', ':->'],
  SIMPLE_SMILE: [':)', '(:', ':-)'],
  SMILE: [':D', ':-D'],
  SMILEY: ['=)', '=-)'],
  STUCK_OUT_TONGUE: [':p', ':-p', ':P', ':-P', ':b', ':-b'],
  STUCK_OUT_TONGUE_WINKING_EYE: [';p', ';-p', ';P', ';-P', ';b', ';-b'],
  SUNGLASSES: ['8)'],
  THUMBSDOWN: [':-1', ':-1:'],
  THUMBSUP: [':+1', ':+1:'],
  WINK: [';)', ';-)'],
};

// ////////////////////////////////////////////////////////////////////////////
// Names

const names = {
  ANGRY: ':angry:',
  ANGUISHED: ':anguished:',
  BROKEN_HEART: ':broken_heart',
  CONFUSED: ':confused:',
  DISAPPOINTED: ':disappointed:',
  HEART: ':heart',
  KISS: ':kiss:',
  MONKEY_FACE: ':monkey_face:',
  NEUTRAL_FACE: ':neutral_face:',
  SATISFIED: ':satisfied:',
  // Github seems to be missing a simple smile, closest one is :blush:
  // SIMPLE_SMILE: ':simple_smile:',
  SIMPLE_SMILE: ':blush:',
  SMILE: ':smile:',
  SMILEY: ':smiley:',
  STUCK_OUT_TONGUE: ':stuck_out_tongue:',
  STUCK_OUT_TONGUE_WINKING_EYE: ':stuck_out_tongue_winking_eye:',
  SUNGLASSES: ':sunglasses:',
  THUMBSDOWN: ':thumbsdown:',
  THUMBSUP: ':thumbsup:',
  WINK: ':wink:',
};

const shortcutMap = {};

Object.keys(names).forEach((key) => {
  shortcuts[key].forEach((shortcut) => {
    shortcutMap[shortcut] = names[key];
  });
});

export default shortcutMap;
