import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import ArcImage from '../../primitives/ArcImage';
import ArcView from '../../primitives/ArcView';
import ArcText from '../../primitives/ArcText';
import { formatDateTime } from '../../helpers/utils/date';

const numberWithCommas = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const styleAccount = {
  minWidth: 120,
};

const styleImage = {
  width: 24,
  height: 24,
  marginRight: 8,
};

const styleLink = {
  cursor: 'pointer',
};

const styleTableCellGrow = {
  width: '100%',
  minWidth: 180,
};

const stylePlaceholderName = {
  minWidth: 120,
};


const stylePlaceholderDescription = {
  maxWidth: 220,
};

const stylePlaceholderAmount = {
  minWidth: 96,
};

const PLACEHOLDER = (
  <TableRow>
    <TableCell>
      <ArcView padding="16" className="shimmer" style={stylePlaceholderName} />
    </TableCell>
    <TableCell>
      <ArcView padding="16" className="shimmer" style={stylePlaceholderName} />
    </TableCell>
    <TableCell>
      <ArcView padding="16" className="shimmer" style={stylePlaceholderName} />
    </TableCell>
    <TableCell>
      <ArcView padding="16" className="shimmer" style={stylePlaceholderDescription} />
    </TableCell>
    <TableCell>
      <ArcView padding="16" className="shimmer" style={stylePlaceholderAmount} />
    </TableCell>
    <TableCell>
      <ArcView padding="16" className="shimmer" style={stylePlaceholderAmount} />
    </TableCell>
  </TableRow>
);

const renderQuickLink = (id, name, imageUrl) => {
  const handleClick = () => {
    if (id && window && window.quicklink) {
      window.quicklink(id);
    }
  };

  return (
    <ArcView
      row
      align="center"
      whiteSpace="nowrap"
      onClick={handleClick}
      style={styleLink}
    >
      <ArcImage
        src={imageUrl}
        style={styleImage}
      />
      {name}
    </ArcView>
  );
};

/** **Description**
  *
  * Renders a row for `<ArcTransactionLog />`
  *
  * **Requirements**
  *
  * This component is automatically rendered as part of `<ArcTransactionLog />`,
  * however it can manually be rendered if
  *
  *   - it has an ancestor of `<ArcTransactionLog />` (doesn't have to be direct)
  *   - it has a previous sibling of `<ArcTransactionLogHeader />`
*/
class ArcTransactionLogItem extends React.PureComponent {
  static displayName = 'ArcTransactionLogItem';

  static propTypes = {
    accountName: PropTypes.string,
    amount: PropTypes.number,
    balance: PropTypes.number,
    event: PropTypes.string,
    fromAccountCode: PropTypes.string,
    fromDisplayName: PropTypes.string,
    fromImage: PropTypes.string,
    fromPersonId: PropTypes.string,
    isFetching: PropTypes.bool,
    isPositiveChange: PropTypes.bool,
    toAccountCode: PropTypes.string,
    toDisplayName: PropTypes.string,
    toImage: PropTypes.string,
    toPersonId: PropTypes.string,
    when: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  };

  static defaultProps = {
    accountName: '',
    amount: 0,
    balance: 0,
    event: '',
    fromAccountCode: '',
    fromDisplayName: '',
    fromImage: '',
    fromPersonId: null,
    isFetching: false,
    isPositiveChange: false,
    toAccountCode: '',
    toDisplayName: '',
    toImage: '',
    toPersonId: null,
    when: '',
  };

  renderAmount() {
    const formatted = numberWithCommas(this.props.amount);
    const value = this.props.isPositiveChange ? formatted : `(${formatted})`;

    return (
      <TableCell align="right">
        <ArcText color={this.props.isPositiveChange ? 'success' : undefined}>
          {value}
        </ArcText>
      </TableCell>
    );
  }

  renderAccount(id, name, imageUrl, code) {
    let account = (
      <ArcView row align="center" whiteSpace="nowrap">
        {this.props.accountName !== code && (
          <ArcImage
            src={imageUrl}
            style={styleImage}
            width="24px"
            height="24px"
          />
        )}
        {name}
      </ArcView>
    );

    if (id) {
      account = renderQuickLink(id, name, imageUrl);
    }

    return (
      <TableCell style={styleAccount}>
        {account}
      </TableCell>
    );
  }

  render() {
    const {
      fromAccountCode,
      fromDisplayName,
      fromImage,
      toAccountCode,
      toDisplayName,
      toImage,
      fromPersonId,
      toPersonId,
    } = this.props;

    if (this.props.isFetching) {
      return PLACEHOLDER;
    }

    return (
      <TableRow>
        <TableCell>
          <ArcView whiteSpace="nowrap">
            {formatDateTime(this.props.when)}
          </ArcView>
        </TableCell>

        {this.renderAccount(fromPersonId, fromDisplayName, fromImage, fromAccountCode)}
        {this.renderAccount(toPersonId, toDisplayName, toImage, toAccountCode)}

        <TableCell style={styleTableCellGrow}>
          {this.props.event}
        </TableCell>

        {this.renderAmount()}

        <TableCell align="right">
          {numberWithCommas(this.props.balance)}
        </TableCell>
      </TableRow>
    );
  }
}

export default ArcTransactionLogItem;
