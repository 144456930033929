import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { safeDateTime } from '../../helpers/formatSafeDate';
import formatNumberToPrecision from '../../helpers/formatNumberToPrecision';

export const ManageGamesSeeDetails = gameData => {
  const { game } = gameData;

  const data = [
    ['Total Score', game.totalScore],
    ['Started', safeDateTime(game.startsAt)],
    ['Ended', safeDateTime(game.expiresAt)],
    ['Tokens Awarded', game.loot],
    ['Creator', game.created_by],
    [
      'Game Goal',
      game.gameGoal ? formatNumberToPrecision(game.gameGoal) : 'N/A',
    ],
  ];

  const renderColumnHeadings = heading => (
    <TableCell style={{ whiteSpace: 'nowrap' }} key={heading[0]}>
      {heading[0]}
    </TableCell>
  );

  const renderData = metric => (
    <TableCell style={{ whiteSpace: 'nowrap' }} key={metric[1]}>
      {metric[1]}
    </TableCell>
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>{data.map(renderColumnHeadings)}</TableRow>
        </TableHead>
        <TableBody>
          <TableRow alignItem>{data.map(renderData)}</TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
