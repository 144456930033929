import io from 'socket.io-client';
import React from 'react';
import { AppRegistry } from 'react-native';

import 'arcade-frontend-ui/src/globals';
import 'arcade-frontend-ui/src/css';
import 'arcade-frontend-ui/src/helpers/extensions/array';
import 'arcade-frontend-features/src/directives';
import * as rtpos from 'arcade-frontend-features/src/rtpos-tools';
import store from 'arcade-frontend-features/src/store';
import { actions, routes } from 'arcade-frontend-core/src/actions';
import { actions as uploadManagerActions } from 'arcade-frontend-widgets/src/actions/uploadManager';
import ArcProviders from 'arcade-frontend-features/src/ArcProviders';
import { AUS_ENDPOINTS } from 'arcade-frontend-core/src/helpers/getEndpointsByEmail';

import WebAppContainer from './WebAppContainer';

if (global.window) {
  window.io = io;
  window.authenticateViaToken = rtpos.setRtposUser;

  window.ArcadeChat = {
    sendChatNotification: data =>
      store.dispatch(actions.appChatNotification(data)),
  };

  window.WorkplaceArcade = window.WorkplaceArcade || {};

  window.WorkplaceArcade.Widgets = {
    showMediaCenter: () =>
      store.dispatch(uploadManagerActions.uploadManagerOpened(null)),
  };

  window.WorkplaceArcade.Routes = {
    newsfeedShow: activityId =>
      store.dispatch(routes.routeNewsfeedShow({ id: activityId })),
  };
}

function getEndpoints() {
  if (window.localStorage) {
    return {
      endpoint: window.localStorage.getItem('endpoint'),
      chatEndpoint: window.localStorage.getItem('chat_endpoint'),
    };
  }

  return AUS_ENDPOINTS;
}

function ArcadeWebApp(props) {
  return (
    <ArcProviders>
      <WebAppContainer {...getEndpoints()} {...props} />
    </ArcProviders>
  );
}

ArcadeWebApp.displayName = 'ArcadeWebApp';

AppRegistry.registerComponent('arcade', () => ArcadeWebApp);

AppRegistry.runApplication('arcade', {
  rootTag: document.getElementById('root'),
});
