import React from 'react';

import ArcPropTypes from '../../helpers/arc/propTypes';
import ArcView from '../../primitives/ArcView';
import { createWithStyles } from '../../styles';

const styles = {
  NumberContainer: () => ({
    root: {
      flexShrink: 1,

      '& > svg polygon': {
        transition: 'fill-opacity 300ms cubic-bezier(0.42, 0, 0.65, 1.22)',
      },

      '& > svg': {
        width: '100%',
        maxWidth: '88px',
      },
    },
  }),
};

const NumberContainer = createWithStyles(styles.NumberContainer)(ArcView);

const polyExlusionMap = [
  [
    'middle',
  ], // 0
  [
    'top',
    'top-left',
    'bottom-left',
    'middle',
    'bottom',
  ], // 1
  [
    'top-left',
    'bottom-right',
  ], // 2
  [
    'top-left',
    'bottom-left',
  ], // 3
  [
    'top',
    'bottom-left',
    'bottom',
  ], // 4
  [
    'top-right',
    'bottom-left',
  ], // 5
  [
    'top-right',
  ], // 6
  [
    'top-left',
    'bottom-left',
    'middle',
    'bottom',
  ], // 7
  [

  ], // 8
  [
    'bottom-left',
    'bottom',
  ], // 9
];

class ArcScoreboardNumber extends React.PureComponent {
  static propTypes = {
    value: ArcPropTypes.number,
    color: ArcPropTypes.string,
    width: ArcPropTypes.number.isRequired,

  };

  static defaultProps = {
    value: 0,
    color: undefined,
  };

  determineEnabled(key) {
    let fillOpacity = '1.0';
    polyExlusionMap[this.props.value].forEach((value) => {
      if (key === value) {
        fillOpacity = '0.1';
      }
    });
    return fillOpacity;
  }

  render() {
    return (
      <NumberContainer
        className="ArcScoreboardNumber"
        style={{ width: this.props.width }}
      >
        <svg
          viewBox="0 0 88 160"
        >
          <g>
            <g transform="translate(4.000000, 6.000000)" fill={this.props.color ? this.props.color : '#FBBB38'}>
              <polygon
                fillOpacity={this.determineEnabled('middle')}
                points="8.64864865 74.2342342 15.8558559 66.3063063 64.8648649 66.3063063 72.0720721 74.2342342 64.8701043 81.4414414 15.2829327 81.4414414"
              />
              <polygon
                fillOpacity={this.determineEnabled('top-left')}
                points="6.12612613 70.6306306 14.4115991 61.2612613 14.4115991 18.018018 0.717905405 2.88288288 0.717905405 64.8648649"
              />
              <polygon
                fillOpacity={this.determineEnabled('bottom-left')}
                transform="translate(7.564752, 112.432432) scale(1, -1) translate(-7.564752, -112.432432) "
                points="6.12612613 146.306306 14.4115991 136.936937 14.4115991 93.6936937 0.717905405 78.5585586 0.717905405 140.540541"
              />
              <polygon
                fillOpacity={this.determineEnabled('bottom')}
                transform="translate(40.718961, 141.621622) scale(1, -1) translate(-40.718961, -141.621622) "
                points="3.6036036 134.054054 17.2972973 149.189189 64.1441441 149.189189 77.8343187 134.054054"
              />
              <polygon
                fillOpacity={this.determineEnabled('bottom-right')}
                transform="translate(73.153153, 111.711712) scale(-1, -1) translate(-73.153153, -111.711712) "
                points="70.990991 145.585586 80 135.495495 80 92.9366617 66.3063063 77.8378378 66.3063063 139.099099"
              />
              <polygon
                fillOpacity={this.determineEnabled('top-right')}
                transform="translate(73.153153, 37.477477) scale(-1, 1) translate(-73.153153, -37.477477) "
                points="70.990991 71.3513514 80 61.2612613 80 18.7024274 66.3063063 3.6036036 66.3063063 64.8648649"
              />
              <polygon
                fillOpacity={this.determineEnabled('top')}
                points="3.6036036 0 17.2972973 15.1351351 64.1441441 15.1351351 77.8343187 0"
              />
            </g>
          </g>
        </svg>
      </NumberContainer>
    );
  }
}

export default ArcScoreboardNumber;
