export const BILLING_V2 = 'billing-v2';
export const CAN_GEOFENCE = 'can_geofence';
export const ENTITY_TAGGING = 'entity_tagging';
export const GAME_AUTO_VERIFY = 'game_auto_verify';
export const GAME_ESCROW = 'game_escrow';
export const INSTANT_REWARDS = 'instant_rewards';
export const LINK_UNFURLING = 'link_unfurling';
export const NEW_MANAGE_GAMES = 'new_manage_games';
export const NEW_METRICS = 'new_metrics';
export const NEWSFEED_FILTERING = 'newsfeed_filtering';
export const NEWSFEED_DATE_FILTERING = 'newsfeed_date_filtering';
export const NOTIFICATIONS_PANEL = 'notifications_panel';
export const PEOPLE_V2 = 'people-v2';
export const POST_HISTORY = 'post_history';
export const REACT_AWARD_SECTION = 'react_award_section';
export const REWARDS_V2 = 'rewards-v2';
export const TEAMS_V2 = 'teams-v2';
export const TOKEN_BUDGETS = 'token_budgets';
export const TOKEN_BALANCE = 'token_balance';
export const GAME_CLONING = 'game_cloning';
export const CHAT_V2 = 'chat_v2';
export const SET_TOKEN_COUNT = 'set_token_count';
export const USER_COUNT_LIMIT = 'user_count_limit';
export const METRIC_FILTER = 'metric_filter';
export const ALL_DATES_DEFAULT = 'all_dates_default';
export const PEOPLE_FILTER = 'people_filter';
export const GAME_CENTER_CREATE = 'game_center_create';
export const CHONKY_NAV = 'chonky_nav';
export const CHONKY_LOGIN = 'chonky_login';
export const NEW_PAGINATION = 'new_pagination';
export const MANAGE_TOKENS_V2 = 'manage_tokens_v2';
export const HOT_GLUE = 'hot_glue';
export const DEBUG_SCREEN = 'debug_screen'
