import { react2angular } from 'react2angular';

import ManagePeopleContainer from '../containers/manage/people/ManagePeopleContainer';

import { createProvidedContainer } from './helpers';

const ProvidedManagePeopleContainer = createProvidedContainer(ManagePeopleContainer);

export default react2angular(ProvidedManagePeopleContainer, [
  'profileEditable',
]);
