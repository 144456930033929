import { combineReducers } from 'redux';

import { getForm } from '../../forms/metrics/dateRange';

import activitiesById from './activitiesById';

import currentMetricDetailsType from './currentMetricDetailsType';

import currentMetricId, { getCurrentMetricId } from './currentMetricId';

import isFetching, { getIsFetching } from './isFetching';

import metricsById, {
  getMetrics,
  getMetricsById,
  getSortedMetrics,
} from './metricsById';

import rankingsById from './rankingsById';

const metrics = combineReducers({
  currentMetricDetailsType,
  currentMetricId,
  activitiesById,
  metricsById,
  isFetching,
  rankingsById,
});

export default metrics;

export const getMetricsViewState = state => ({
  currentMetricId: getCurrentMetricId(state),
  isFetching: getIsFetching(state),
  metrics: getMetrics(state),
  metricsById: getMetricsById(state),
});

export const getMetricsIndexState = state => ({
  currentMetricId: getCurrentMetricId(state),
  metricsById: getMetricsById(state),
  metrics: getMetrics(state),

  sortedMetrics: getSortedMetrics(state),

  fromDate: getForm(state).values.dateRange.from,
  toDate: getForm(state).values.dateRange.to,
  timePeriod: getForm(state).values.timePeriod,
});
