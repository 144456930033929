import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';

import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';
import { INITIAL_STATUS } from 'arcade-frontend-core/src/hooks/useResourceAction';

const styles = makeStyles(theme => ({
  barAnchor: {
    height: 0,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  barWindow: {
    position: 'absolute',
    height: 100,
    bottom: 0,
  },
  bar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.green.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    transform: 'translate(0, 0)',
  },
  barMessage: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  slideOverUp: {
    animation: 'slideOverUp 300ms both',
  },
  slideOverDown: {
    animation: 'slideOverDown 300ms both',
  },
}));

function GamesEventUndoBar({ open, message, onUndo, onClose, updateStatus }) {
  const classes = styles();

  const [startAnimating, setStartAnimating] = useState(false);

  useEffect(() => {
    if (open) setStartAnimating(true);
  }, [open]);

  const boxClasses = [
    classes.bar,
    startAnimating && (open ? classes.slideOverUp : classes.slideOverDown),
  ];

  return (
    <ArcBox className={classes.barAnchor}>
      <ArcBox className={cx(boxClasses)} px={2} py={1}>
        <ArcBox className={classes.barMessage}>{message}</ArcBox>
        <ArcButton label="Hide" onClick={onClose} color="white" />
        <ArcResourceButton
          variant="flat"
          label="Undo"
          onClick={onUndo}
          status={updateStatus}
          color="white"
          textColor="green"
        />
      </ArcBox>
    </ArcBox>
  );
}

GamesEventUndoBar.displayName = 'GamesEventUndoBar';

GamesEventUndoBar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onUndo: PropTypes.func,
  onClose: PropTypes.func,
  updateStatus: PropTypes.shape({}),
};

GamesEventUndoBar.defaultProps = {
  open: false,
  message: '',
  onUndo: global.noop,
  onClose: global.noop,
  updateStatus: INITIAL_STATUS,
};

export default GamesEventUndoBar;
