import { makeApiActionTypes } from 'arcade-frontend-ui';

export const MANAGE_GAMES_INDEX = makeApiActionTypes('MANAGE_GAMES_INDEX');
export const MANAGE_GAMES_CREATE = makeApiActionTypes('MANAGE_GAMES_CREATE');
export const MANAGE_GAMES_SHOW = makeApiActionTypes('MANAGE_GAMES_SHOW');
export const MANAGE_GAMES_UPDATE = makeApiActionTypes('MANAGE_GAMES_UPDATE');
export const MANAGE_GAMES_EDIT = makeApiActionTypes('MANAGE_GAMES_EDIT');
export const MANAGE_GAMES_CLONE = makeApiActionTypes('MANAGE_GAMES_CLONE');
export const MANAGE_GAMES_VERIFY = makeApiActionTypes('MANAGE_GAMES_VERIFY');
export const MANAGE_GAMES_REJECT = makeApiActionTypes('MANAGE_GAMES_REJECT');

export const MANAGE_GAMES_TEMPLATES_INDEX = makeApiActionTypes(
  'MANAGE_GAMES_TEMPLATES_INDEX',
);
export const MANAGE_GAMES_TEAMS_INDEX = makeApiActionTypes(
  'MANAGE_GAMES_TEAMS_INDEX',
);
export const MANAGE_GAMES_ELIGIBLE_PEOPLE_INDEX = makeApiActionTypes(
  'MANAGE_GAMES_ELIGIBLE_PEOPLE_INDEX',
);
export const MANAGE_GAMES_EVENTS_INDEX = makeApiActionTypes(
  'MANAGE_GAMES_EVENTS_INDEX',
);
export const MANAGE_GAMES_RANKINGS_INDEX = makeApiActionTypes(
  'MANAGE_GAMES_RANKINGS_INDEX',
);
export const MANAGE_GAMES_RESULTS_INDEX = makeApiActionTypes(
  'MANAGE_GAMES_RESULTS_INDEX',
);

export const MANAGE_GAMES_TEMPLATE_CREATE = makeApiActionTypes(
  'MANAGE_GAMES_TEMPLATE_CREATE',
);
export const MANAGE_GAMES_VALID_METRICS_INDEX = makeApiActionTypes(
  'MANAGE_GAMES_VALID_METRICS_INDEX',
);
export const MANAGE_GAMES_CANCEL = makeApiActionTypes('MANAGE_GAMES_CANCEL');

export const MANAGE_GAMES_SHOW_EDIT_DIALOG = 'MANAGE_GAMES_SHOW_EDIT_DIALOG';
export const MANAGE_GAMES_HIDE_EDIT_DIALOG = 'MANAGE_GAMES_HIDE_EDIT_DIALOG';

export const MANAGE_GAMES_SET_TYPE_FILTER = 'MANAGE_GAMES_SET_TYPE_FILTER';
export const MANAGE_GAMES_SET_DATE_FILTER = 'MANAGE_GAMES_SET_DATE_FILTER';
export const MANAGE_GAMES_SET_PAGE_FILTER = 'MANAGE_GAMES_SET_PAGE_FILTER';

export const MANAGE_GAMES_LIST = makeApiActionTypes('MANAGE_GAMES_LIST');

export const MANAGE_GAMES_GET_ESCROWABLE_FUNDS = makeApiActionTypes(
  'MANAGE_GAMES_GET_ESCROWABLE_FUNDS',
);

export const MANAGE_GAMES_SET_VERIFIED_GAME = 'MANAGE_GAMES_SET_VERIFIED_GAME';
