import React from 'react';

import { G, Path } from 'react-primitives-svg';

import Icon from '../Icon';

const Notifications = props => (
  <Icon {...props}>
    <G
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#NotificationsClip0)"
    >
      <Path d="M14.45 21.5a2.5 2.5 0 01-4.9 0" />
      <Path
        d="M4 11.5a8 8 0 1116 0V18a1.5 1.5 0 001.5 1.5h-19A1.5 1.5 0 004 18v-6.5z"
        clipRule="evenodd"
      />
      <Path d="M14.5 6.969A4.5 4.5 0 0117 11M12 .501v3" />
    </G>
    <defs>
      <clipPath id="NotificationsClip0">
        <Path fill="transparent" d="M0 0h24v24H0V0z" />
      </clipPath>
    </defs>
  </Icon>
);

export default Notifications;
