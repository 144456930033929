import React from 'react';
import useSWR from 'swr';
import { arcadeApiClient } from 'arcade-frontend-core/src/helpers/arcadeApiClient';
import { Connections } from 'hotglue-elements';
import 'hotglue-elements/dist/index.css';

const fetcher = () => url =>
  arcadeApiClient.get(url).then(res => res.data?.identifier);

const ManageIntegrationsContainer = () => {
  const { data: identifier, error } = useSWR(
    '/manager/v4/hotglue/identifier',
    fetcher(),
  );

  const isLoading = !identifier && !error;

  return !isLoading && <Connections tenant={identifier} />;
};

export default ManageIntegrationsContainer;
