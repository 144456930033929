import axios from 'axios';
import humps from 'humps';

function getAxiosConfig(resourceAction = {}, payload = {}, baseConfig = {}) {
  let dynamicConfig = {};

  if (baseConfig.baseURL === undefined) {
    delete baseConfig.baseURL; // eslint-disable-line no-param-reassign
  }

  if (baseConfig.headers && !Object.keys(baseConfig.headers).length) {
    delete baseConfig.headers; // eslint-disable-line no-param-reassign
  }

  if (resourceAction.api) {
    // This looks a bit wonky but it was done to avoid resource test failures
    // where the expectation is checking for only 1 param
    if (Object.keys(baseConfig).length) {
      dynamicConfig = resourceAction.api(payload, baseConfig);
    } else {
      dynamicConfig = resourceAction.api(payload);
    }
  }

  const axiosConfig = {
    responseType: 'json',
    ...baseConfig,
    ...dynamicConfig,

    headers: {
      'Content-Type': 'application/json',
      ...baseConfig.headers,
      ...dynamicConfig.headers,
    },
  };

  const configs = {
    baseConfig,
    dynamicConfig,
  };

  const transformResponse = baseConfig.transformResponse || [
    humps.camelizeKeys,
  ];

  Object.values(configs).forEach(config => {
    if (config.transformResponse) {
      const iterableTransformResponse =
        typeof config.transformResponse === 'function'
          ? [config.transformResponse]
          : config.transformResponse;

      transformResponse.push(...iterableTransformResponse);
    }
  });

  axiosConfig.transformResponse =
    axios.defaults.transformResponse?.concat(transformResponse) ||
    transformResponse;

  return axiosConfig;
}

export default getAxiosConfig;
