import { react2angular } from 'react2angular';
import ArcLeftMenu from '../containers/app/ArcLeftMenu';

import { createProvidedContainer } from './helpers';


const ProvidedArcLeftMenu = createProvidedContainer(ArcLeftMenu);

export default react2angular(ProvidedArcLeftMenu, [
  'badgeUrl',
  'chatCount',
  'hasBars',
  'onMute',
  'onClickItem',
  'onPressRoute',
  'onPressSupportGuides',
  'onPressSupportRequest',
  'profileImageUrl',
  'rewardsCount',
]);
