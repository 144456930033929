import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  ArcPropTypes,
  ArcStyles,
  ArcMainView,
  ArcScroll,
  ArcView,
  ArcViewTitleBar,
} from 'arcade-frontend-ui';

import { ArcTransactionLogDateSelector } from 'arcade-frontend-ui/src/components/ArcTransactionLog';

import { routes } from '../../../actions/manage';
import * as fromFormsEngagement from '../../../reducers/forms/engagement';

import EngagementTiles from './EngagementTiles';
import EntitySelector from './EntitySelector';

const stylePositionRel = {
  position: 'relative',
  backgroundColor: '#fff',
};

const stylePositionAbs = {
  position: 'absolute',
  height: '100%',
  width: '100%',
};

const styleContainer = {
  padding: 16,
};

const styleHeaderRow = {
  alignItems: 'center',

  [ArcStyles.media('xs')]: {
    flexDirection: 'row',
  },
};

const dateNamesMap = {
  today: 'today',
  yesterday: 'yesterday',
  selectedDay: 'on the selected day',
  pastWeek: 'in the past 7 days',
  currentWeek: 'in the current week',
  currentMonth: 'in the current month',
  dateRange: 'in the selected date range',
};

class ManageEngagementContainer extends React.PureComponent {
  static propTypes = {
    routeManage: ArcPropTypes.func.isRequired,
    dateRange: ArcPropTypes.shape({
      fromDate: ArcPropTypes.date,
      toDate: ArcPropTypes.date,
      type: ArcPropTypes.string,
    }),
  };

  static defaultProps = {
    dateRange: {},
  };

  state = {
    dateRangeReadable: 'in the current month',
    viewingType: 'organisation',
  };

  handleChangeDate = date => {
    this.setState({
      dateRangeReadable: dateNamesMap[date.dateType].toLowerCase(),
    });
  };

  handleChangeViewingType = viewingType => this.setState({ viewingType });

  render() {
    return (
      <ArcView style={stylePositionAbs}>
        <ArcMainView
          isViewing
          hasScroll={false}
          hasNoPaddingLeftLaunch
          style={stylePositionRel}
        >
          <ArcViewTitleBar
            onClickBack={this.props.routeManage}
            title="Manage Engagement"
          />
          <ArcScroll>
            <ArcView style={styleContainer}>
              <ArcView row justify="space-between" style={styleHeaderRow}>
                <ArcView>
                  <EntitySelector
                    viewingType={this.state.viewingType}
                    onChange={this.handleChangeViewingType}
                  />
                </ArcView>
                <ArcTransactionLogDateSelector
                  onChange={this.handleChangeDate}
                />
              </ArcView>

              <EngagementTiles
                dateType={this.props.dateRange.type}
                dateRangeReadable={this.state.dateRangeReadable}
                viewingType={this.state.viewingType}
              />
            </ArcView>
          </ArcScroll>
        </ArcMainView>
      </ArcView>
    );
  }
}

const getState = state => ({
  dateRange: fromFormsEngagement.getDateRange(state),
});

const getActions = dispatch =>
  bindActionCreators(
    {
      routeManage: routes.routeManage,
    },
    dispatch,
  );

export default connect(getState, getActions)(ManageEngagementContainer);
