import ArcStyles from '../../styles';

const fontStyle = {
  // fontFamily: ArcStyles.theme.fontFamilyDefault,
  fontSize: 14,
  lineHeight: '16px',
  fontWeight: '400',
};

const underline = {
  content: ' ',
  position: 'absolute',
  backgroundColor: ArcStyles.theme.blue6,
  height: 4,
  left: 0,
  bottom: 0,
  right: 0,
};

export const ArcUserInputView = theme => ({
  root: {
    ...fontStyle,

    width: '100%',

    '& .ArcUserInput': {
      width: '100%',
    },

    // Input / TextArea
    '& .ArcUserInput__control': {
      width: '100%',
      padding: 16,
      wordBreak: 'break-word',
    },

    '& .ArcUserInput__input': {
      ...fontStyle,
      zIndex: 1,
      overflow: 'auto',
      width: '100%',
      border: 'none',
      outline: 0,
      padding: 16,
      left: 0,
      transition: 'background-color 0.1s linear',
    },

    // Highlight mentions
    '& .ArcUserInput__highlighter': {
      userSelect: 'none',
    },

    '& .ArcUserInput__highlighter > strong': {
      position: 'relative',
    },

    '& .ArcUserInput__highlighter > .ArcUserInput__highlighter__caret > strong': {
      position: 'relative',
    },

    '& .ArcUserInput__highlighter > strong::after': underline,
    '& .ArcUserInput__highlighter > .ArcUserInput__highlighter__caret > strong::after': underline,


    // Suggestion List
    '& .ArcUserInput__suggestions__item--focused': {
      background: '#eeeeee',
    },

    '& .ArcUserInput__suggestions': {
      zIndex: '100 !important',
      overflow: 'auto',
      boxShadow: theme.shadows[8],
      maxHeight: 144,
      marginLeft: 16,

      [theme.breakpoints.up('xs')]: {
        maxHeight: 384,
      },
    },
  },

  hasSaveButton: {
    width: 'calc(100% - 75px)',
  },
});
