const ARCADE_IMAGE_URL =
  'https://s3.amazonaws.com/arcade-assets-public/logo/80x80.png';

const getFromImage = props => props.awarded_by.image || ARCADE_IMAGE_URL;
const getToImage = props => props.awarded_to.image || ARCADE_IMAGE_URL;

const normalizeVariant = variant => ({
  ...variant,
  cost: parseInt(variant.value, 10),
  value: variant.id,
});

const normalizeProduct = product => ({
  ...product,
  id: product.id.toString(),
  imageUrl: product.reward_image,
  variantLabel: product.variant_label,
  variants: product.variants.map(normalizeVariant),
});

const shopIndex = props => ({
  disclaimer: props.disclaimer,
  rewards: props.rewards.map(normalizeProduct),
});

const transaction = props => ({
  id: props.id.toString(),
  type: props.code,
  when: new Date(props.created_at),
  fromAccountCode: props.awarded_by.account_code,
  fromDisplayName: props.awarded_by.name || 'Arcade',
  fromImage: getFromImage(props),
  fromPersonId: props.awarded_by.id ? props.awarded_by.id.toString() : '',
  fromCompanyId: props.awarded_by.company_identifier,
  fromTeamName: props.awarded_by.team_name,
  toAccountCode: props.awarded_to.account_code,
  toDisplayName: props.awarded_to.name || '',
  toCompanyId: props.awarded_to.company_identifier,
  toTeamName: props.awarded_to.team_name,
  toImage: getToImage(props),
  toPersonId: props.awarded_to.id ? props.awarded_to.id.toString() : '',
  event: props.description,
  amount: Math.abs(props.awarded_amount),
  balance: props.post_award_balance,
  isPositiveChange: props.awarded_amount > 0,
});

const transactionsIndex = result => {
  const { transactions, summary } = result;
  return {
    transactions: transactions.map(transaction),
    summary,
  };
};

const accountsIndex = account => ({
  ...account,
  transferTypes: account.transfer_types,
});

const normalizers = {
  shopIndex,
  transactionsIndex,
  accountsIndex,
};

export default normalizers;
