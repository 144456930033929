
export const styleInvisible = {
  zIndex: -9999,
  position: 'absolute',
  display: 'block',
  opacity: 0,
  transform: 'scale(0)',
  width: 0,
  height: 0,
};

export const styles = {
  ButtonGroup: theme => ({
    root: {
      display: 'flex',
      flexDirection: 'row',

      '& > .ArcButton': {
        padding: 8,
        borderRadius: 0,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.divider,

        marginLeft: -1,
      },

      '& > .ArcButton:hover': {
        zIndex: 10,
        borderColor: theme.palette.secondary.main,
      },

      '& > .ArcButton.isActive': {
        zIndex: 10,
        // backgroundColor: '#fafffe',
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
      },

      '& > .ArcButton:first-child': {
        marginLeft: 0,
      },

      '& > .ArcButton:last-child': {
      },
    },

    hasError: {
      '& > .ArcButton:hover': {
        borderColor: theme.palette.danger.main,
      },

      '& > .ArcButton.isActive': {
        borderColor: theme.palette.danger.main,
        color: theme.palette.danger.main,
      },
    },

    marginBottom: {
      marginBottom: theme.spacing(2),
    },

    marginLeft: {
      marginLeft: theme.spacing(2),
    },
  }),

  Container: () => ({
    root: {
      // maxWidth: 352,
      maxWidth: 446,
    },
  }),

  SelectGroup: theme => ({
    root: {
      display: 'flex',
      flexDirection: 'row',

      // borderTop: `1px solid ${theme.palette.divider}`,
      // borderBottom: `1px solid ${theme.palette.divider}`,

      '& .ArcSelect': {
        overflow: 'hidden',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.divider,
        borderBottomWidth: 0,
      },

      '& .ArcSelect select': {
        paddingTop: 11,
        paddingRight: 40,
        paddingBottom: 11,
        paddingLeft: 8,
      },

      '& .ArcSelect:before': {
        borderBottomColor: theme.palette.divider,
      },

      '& .ArcSelect:after': {
        borderBottomColor: theme.palette.secondary.main,
      },
    },

    hasError: {
      '& .ArcSelect': {
        borderColor: theme.palette.danger.main,
      },

      '& .ArcSelect:hover:before': {
        borderBottom: `2px solid ${theme.palette.danger.main} !important`,
      },

      '& .ArcSelect:before': {
        borderBottomColor: theme.palette.danger.main,
      },

      '& .ArcSelect:after': {
        borderBottomColor: theme.palette.danger.main,
      },
    },

  }),

  TimeSeperator: theme => ({
    root: {
      justifyContent: 'center',
      padding: theme.spacing(1),
    },
  }),
  PermanentModeWrapper: theme => ({
    root: {
      marginBottom: theme.spacing(1),
    },
  }),
};
