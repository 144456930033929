export const ANNOUNCE_MESSAGE = 'announce_message';
export const AWARD_CHESTS = 'award_chests';
export const CLAWBACK_TOKENS = 'clawback_tokens';
export const DELETE_COMMENTS = 'delete_comments';
export const DELETE_POSTS = 'delete_posts';
export const DISTRIBUTE_TOKENS = 'distribute_tokens';
export const INVITE_USER = 'invite_user';
export const MANAGE_BILLING = 'manage_billing';
export const MANAGE_DATA_CONNECTORS = 'manage_data_connectors';
export const MANAGE_GAMES = 'manage_games';
export const MANAGE_INTEGRATIONS = 'manage_integrations';
export const MANAGE_METRICS = 'manage_metrics';
export const MANAGE_PERMISSIONS = 'manage_permissions';
export const MANAGE_REWARDS = 'manage_rewards';
export const MANAGE_SALES = 'manage_sales';
export const MANAGE_TEAMS = 'manage_teams';
export const MANAGE_TOKENS = 'manage_tokens';
export const MANAGE_USERS = 'manage_users';
export const PIN_NEWSFEED_POSTS = 'pin_newsfeed_posts';
export const POST_TO_NEWSFEED = 'post_to_newsfeed';
export const POST_TO_PROTECTED_AUDIENCES = 'post_to_protected_audiences';
export const PROMOTE_NEWSFEED_POSTS = 'promote_newsfeed_posts';
export const REQUEST_TOPUP = 'request_topup';
export const SET_MONTHLY_LIMIT = 'set_monthly_limit';
export const SET_RECOGNITION_STAR_COUNT = 'set_recognition_star_count';
export const TRANSFER_TOKENS = 'transfer_tokens';
export const UPLOAD_FILES = 'upload_files';
export const UPLOAD_PHOTOS = 'upload_photos';
export const UPLOAD_VIDEOS = 'upload_videos';
export const VIEW_POST_HISTORY = 'view_post_history';
export const MESSAGE_TAG_EVERYONE = 'message_tag_everyone';
