import React from 'react';
import PropTypes from 'prop-types';

const AddCircleOutlined = ({ height, width, stroke, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 6V18" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 12H6" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

AddCircleOutlined.displayName = 'AddCircleOutlined';

AddCircleOutlined.propTypes = {
  stroke: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

AddCircleOutlined.defaultProps = {
  stroke: 'black',
  height: 24,
  width: 24,
};

export default AddCircleOutlined;
