import {
  put,
  take,
  select,
  takeEvery as RSTakeEvery,
} from '@redux-saga/core/effects';
import { actionTypes } from 'redux-resource';

import { actions as coreActions } from 'arcade-frontend-core/src/actions';
import sagas from 'arcade-frontend-core/src/helpers/sagas';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import newsfeedFilter from 'arcade-frontend-core/src/helpers/newsfeedFilter';

import { actions, types } from '../actions';
import api from '../resources';
import { getActivityComments } from '../reducers/rrComments';
import { activityReactionsById } from '../reducers/activity_reactions';

const getLocationQuery = state => state.location.query || {};

const takeLatest = [
  {
    type: types.NEWSFEED_INDEX,
    call: api.activities,
    onSuccess: actions.updateCommentsFromNewsfeedIndex,
  },
];

const takeEvery = [
  {
    type: types.ACTIVITY_HISTORY_INDEX,
    call: api.activityHistory,
  },
  {
    type: types.ACTIVITY_HISTORY_DETAILS,
    call: api.activityHistoryDetails,
  },
  {
    type: types.ACTIVITY_HISTORY_DELETE,
    call: api.deleteActivity,
  },
  {
    type: types.NEWSFEED_ACTIVITY,
    call: api.getActivity,
  },
  {
    type: types.NEWSFEED_ACTIVITY_RELOAD_COMMENTS,
    call: api.getActivity,
  },
  {
    type: types.NEWSFEED_UPDATE_ACTIVITY,
    call: api.updateActivity,
  },
  {
    type: types.NEWSFEED_CREATE_ACTIVITY,
    call: api.createActivity,
  },
  {
    type: types.NEWSFEED_ACK_PIN,
    call: api.acknowledgePin,
  },
  {
    type: types.NEWSFEED_DELETE_ACTIVITY,
    call: api.deleteActivity,
  },
  {
    type: types.NEWSFEED_CREATE_COMMENT,
    call: api.createComment,
  },
  {
    type: types.NEWSFEED_DELETE_COMMENT,
    call: api.deleteComment,
  },
  {
    type: types.NEWSFEED_TOGGLE_ACTIVITY_RESPECT,
    call: api.toggleRespect,
  },
  {
    type: types.NEWSFEED_GET_ACTIVITY_RESPECTS,
    call: api.getActivityRespects,
  },
  {
    type: types.NEWSFEED_GET_ACTIVITY_VIEWS,
    call: api.getActivityViews,
  },
  {
    type: types.NEWSFEED_GET_ACTIVITY_ACKS,
    call: api.getActivityAcks,
  },
  {
    type: types.NEWSFEED_GET_ACTIVITY_COMMENTS,
    call: api.getActivityComments,
    onSuccess: actions.updateCommentsFromGetActivityComments,
  },
  {
    type: types.NEWSFEED_PIN_ACTIVITY,
    call: api.pinActivity,
  },
  {
    type: types.NEWSFEED_UNPIN_ACTIVITY,
    call: api.unpinActivity,
  },
  {
    type: types.NEWSFEED_REPORT_ACTIVITY,
    call: api.reportActivity,
  },
  {
    type: types.NEWSFEED_GET_SUMMARY_ACTIVITIES,
    call: api.searchActivities,
  },
  {
    type: types.NEWSFEED_REACT_TO_ACTIVITY,
    call: api.reactToActivity,
  },
  {
    type: types.NEWSFEED_REACT_TO_COMMENT,
    call: api.reactToComment,
  },
  {
    type: types.NEWSFEED_DELETE_REACT_TO_ACTIVITY,
    call: api.deleteActivityReaction,
  },
  {
    type: types.NEWSFEED_DELETE_REACT_TO_COMMENT,
    call: api.deleteCommentReaction,
  },
  {
    type: types.NEWSFEED_GET_ACTIVITY_REACTIONS,
    call: api.getActivityReactions,
  },
  {
    type: types.NEWSFEED_GET_COMMENT_REACTIONS,
    call: api.getCommentReactions,
  },
  {
    type: types.NEWSFEED_PROMOTE_ACTIVITY,
    call: api.promoteActivity,
  },
  {
    type: types.NEWSFEED_TAGGED_ACTIVITIES,
    call: api.taggedActivities,
  },
];

const completedApiActionTypes = type => [type.SUCCESS, type.FAILURE];

const sendNotification = message =>
  coreActions.appChatNotification({
    type: 'short_notification',
    content: { message },
  });

function* saveCommentAndUpdateActivity(data) {
  const { payload } = data;
  yield put(actions.createComment(payload));
  const result = yield take(
    completedApiActionTypes(types.NEWSFEED_CREATE_COMMENT),
  );
  if (result.type === types.NEWSFEED_CREATE_COMMENT.SUCCESS) {
    yield put(sendNotification('Comment saved'));

    yield put(actions.reloadComments({ id: payload.comment.activity_id }));
    const reloadResult = yield take(
      completedApiActionTypes(types.NEWSFEED_ACTIVITY_RELOAD_COMMENTS),
    );

    if (reloadResult.type === types.NEWSFEED_ACTIVITY_RELOAD_COMMENTS.SUCCESS) {
      const { comments } = reloadResult.payload.data;

      yield put({
        type: actionTypes.READ_RESOURCES_SUCCEEDED,
        resourceType: 'rrComments',
        resources: comments,
        list: payload.comment.activity_id,
      });
    }
  }
}

function* acknowledgePinAndUpdateActivity(data) {
  const { payload } = data;
  yield put(actions.activityAcknowledgePin(payload));
  const result = yield take(completedApiActionTypes(types.NEWSFEED_ACK_PIN));
  if (result.type === types.NEWSFEED_ACK_PIN.SUCCESS) {
    yield put(actions.loadActivity({ id: payload.id }));
  }
}

function* deleteCommentAndUpdateActivity(data) {
  const { payload } = data;
  const { activityId } = payload;
  yield put(actions.deleteComment(payload));
  const result = yield take(
    completedApiActionTypes(types.NEWSFEED_DELETE_COMMENT),
  );
  if (result.type === types.NEWSFEED_DELETE_COMMENT.SUCCESS) {
    yield put(actions.loadActivity({ id: activityId }));
    yield put(sendNotification('Comment deleted'));
  }
}

function* toggleRespectAndUpdateActivity(data) {
  const { payload } = data;
  yield put(actions.toggleActivityRespect(payload));
  const result = yield take(
    completedApiActionTypes(types.NEWSFEED_TOGGLE_ACTIVITY_RESPECT),
  );
  if (result.type === types.NEWSFEED_TOGGLE_ACTIVITY_RESPECT.SUCCESS) {
    yield put(actions.loadActivity({ id: payload.id }));
  }
}

function* togglePinnedAndUpdateActivity(data) {
  const { payload } = data;
  if (payload.pin) {
    yield put(actions.pinActivity(payload));
  } else {
    yield put(actions.unpinActivity(payload));
  }
  const completeActions = [
    ...completedApiActionTypes(types.NEWSFEED_PIN_ACTIVITY),
    ...completedApiActionTypes(types.NEWSFEED_UNPIN_ACTIVITY),
  ];
  yield take(completeActions);
  yield put(actions.loadActivity({ id: payload.id }));
  const message = payload.pin ? 'Activity pinned' : 'Activity unpinned';
  yield put(sendNotification(message));
}

function* clearAndReloadActivities() {
  const locationQuery = yield select(getLocationQuery);
  yield put(
    actions.activityIndexRequest({
      audienceId: locationQuery.audienceId,
      newsfeedFilter: newsfeedFilter.get(),
    }),
  );
}

function* createAndReloadActivity(action) {
  yield put(actions.createActivity(action.payload));
  const response = yield take(
    completedApiActionTypes(types.NEWSFEED_CREATE_ACTIVITY),
  );
  const { payload, type } = response;
  if (type === types.NEWSFEED_CREATE_ACTIVITY.FAILURE) {
    yield put(sendNotification('Error creating activity'));
  } else {
    const { data } = payload;
    const locationQuery = yield select(getLocationQuery);

    yield put(
      actions.activityIndexRequest({
        page: 1,
        audienceId: locationQuery.audienceId,
        newsfeedFilter: newsfeedFilter.get(),
      }),
    );

    yield put(sendNotification('Activity created'));
    const angularAction = {
      event: 'arc:activityEditor:activityCreated',
      data: data.payload,
    };
    yield put(coreActions.appRootScopeBroadcast(angularAction));
  }
}

function* updateAndReloadActivity(data) {
  yield put(actions.updateActivity(data.payload));
  const response = yield take(
    completedApiActionTypes(types.NEWSFEED_UPDATE_ACTIVITY),
  );
  const { type } = response;
  if (type === types.NEWSFEED_UPDATE_ACTIVITY.FAILURE) {
    yield put(sendNotification('Error updating activity'));
  } else {
    const locationQuery = yield select(getLocationQuery);
    yield put(
      actions.activityIndexRequest({
        audienceId: locationQuery.audienceId,
        newsfeedFilter: newsfeedFilter.get(),
      }),
    );

    const angularAction = {
      event: 'arc:activityEditor:activityUpdated',
      data: data.payload,
    };
    yield put(coreActions.appRootScopeBroadcast(angularAction));
    yield put(sendNotification('Activity updated'));
  }
}

function* deleteAndReloadActivities(data) {
  yield put(actions.deleteActivity(data.payload));
  yield take(completedApiActionTypes(types.NEWSFEED_DELETE_ACTIVITY));
  const locationQuery = yield select(getLocationQuery);
  yield put(
    actions.activityIndexRequest({
      audienceId: locationQuery.audienceId,
      newsfeedFilter: newsfeedFilter.get(),
    }),
  );
  yield put(sendNotification('Activity deleted'));
}

function* editActivityFromAngular(action) {
  const { payload } = action;
  yield put(actions.loadActivity(payload));
  yield take(completedApiActionTypes(types.NEWSFEED_ACTIVITY));

  // Manually load activity
  // const data = activityNormaliser(payload);
  // yield put({ type: types.NEWSFEED_ACTIVITY.SUCCESS, payload: { data } });

  // select activity for editing
  yield put(actions.editActivity(payload));
}

function* saveCommentReaction(action) {
  const { payload } = action;
  const { id, reaction, activityId } = payload;

  const user = yield select(getCurrentUser);

  const allCommentsForActivity = yield select(getActivityComments, {
    activityId,
  });

  const comment = allCommentsForActivity.find(c => c.id === id);

  if (!comment) return;

  const { reactions } = comment;

  const existingReaction =
    reactions &&
    reactions.find(r => r.userId === user.id && r.reaction === reaction);

  if (existingReaction) {
    yield put(
      actions.deleteReactToComment({
        commentId: id,
        id: existingReaction.id,
        activityId,
      }),
    );
  } else {
    yield put(
      actions.reactToComment({
        id,
        reaction,
        activityId,
        currentUserId: user.id,
      }),
    );
  }
  const completeActions = [
    ...completedApiActionTypes(types.NEWSFEED_REACT_TO_COMMENT),
    ...completedApiActionTypes(types.NEWSFEED_DELETE_REACT_TO_COMMENT),
  ];
  yield take(completeActions);
  yield put(actions.getActivityComments(activityId));
}

function* saveActivityReaction(action) {
  const { payload } = action;
  const { id, reaction } = payload;

  const user = yield select(getCurrentUser);
  const allReactions = yield select(activityReactionsById);
  const reactions = allReactions[id];

  const existingReaction =
    reactions &&
    Object.values(reactions).find(
      r => r.userId === user.id && r.reaction === reaction,
    );
  if (existingReaction) {
    yield put(
      actions.deleteReactToActivity({
        activityId: id,
        id: existingReaction.id,
      }),
    );
  } else {
    yield put(actions.reactToActivity({ id, reaction }));
  }
  const completeActions = [
    ...completedApiActionTypes(types.NEWSFEED_REACT_TO_ACTIVITY),
    ...completedApiActionTypes(types.NEWSFEED_DELETE_REACT_TO_ACTIVITY),
  ];
  yield take(completeActions);
  yield put(actions.getActivityReactions(id));
}

function* promoteAndReloadActivity(action) {
  const { payload } = action;
  yield put(actions.promoteActivity(payload));
  const { type } = yield take(
    completedApiActionTypes(types.NEWSFEED_PROMOTE_ACTIVITY),
  );
  if (type === types.NEWSFEED_PROMOTE_ACTIVITY.FAILURE) {
    yield put(sendNotification('Error promoting activity'));
  } else {
    yield put(actions.loadActivity(payload));
    yield put(sendNotification('Activity promoted'));
  }
}

function* loadTaggedActivities(action) {
  const { payload } = action;
  yield put(actions.taggedActivities({ tag: payload }));
}

export default [
  RSTakeEvery(types.NEWSFEED_OPEN_TAGGED_ACTIVITIES, loadTaggedActivities),
  RSTakeEvery(
    types.NEWSFEED_PROMOTE_AND_RELOAD_ACTIVITY,
    promoteAndReloadActivity,
  ),
  RSTakeEvery(types.NEWSFEED_SAVE_COMMENT_REACTION, saveCommentReaction),
  RSTakeEvery(types.NEWSFEED_SAVE_ACTIVITY_REACTION, saveActivityReaction),
  RSTakeEvery(
    types.NEWSFEED_EDIT_ACTIVITY_FROM_ANGULAR,
    editActivityFromAngular,
  ),
  RSTakeEvery(
    types.NEWSFEED_CREATE_AND_RELOAD_ACTIVITY,
    createAndReloadActivity,
  ),
  RSTakeEvery(
    types.NEWSFEED_UPDATE_AND_RELOAD_ACTIVITY,
    updateAndReloadActivity,
  ),
  RSTakeEvery(
    types.NEWSFEED_DELETE_AND_RELOAD_ACTIVITIES,
    deleteAndReloadActivities,
  ),
  RSTakeEvery(
    types.NEWSFEED_CLEAR_AND_RELOAD_ACTIVITIES,
    clearAndReloadActivities,
  ),
  RSTakeEvery(
    types.NEWSFEED_SAVE_COMMENT_AND_UPDATE_ACTIVITY,
    saveCommentAndUpdateActivity,
  ),
  RSTakeEvery(
    types.NEWSFEED_DELETE_COMMENT_AND_UPDATE_ACTIVITY,
    deleteCommentAndUpdateActivity,
  ),
  RSTakeEvery(
    types.NEWSFEED_ACKNOWLEDGE_PIN_AND_UPDATE_ACTIVITY,
    acknowledgePinAndUpdateActivity,
  ),
  RSTakeEvery(
    types.NEWSFEED_TOGGLE_ACTIVITY_PIN_AND_UPDATE_ACTIVITY,
    togglePinnedAndUpdateActivity,
  ),
  RSTakeEvery(
    types.NEWSFEED_TOGGLE_RESPECT_AND_UPDATE_ACTIVITY,
    toggleRespectAndUpdateActivity,
  ),
  ...takeLatest.map(sagas.makeTakeLatest),
  ...takeEvery.map(sagas.makeTakeEvery),
];
