import { createReducer } from 'arcade-frontend-ui';

import { types } from '../actions';

const handleWebviewSetUnreadMessages = (state, action) => action.payload;

const initialState = 0;

const handlers = {
  [types.WEBVIEW_SET_UNREAD_MESSAGES]: handleWebviewSetUnreadMessages,
};

const unreadMessages = createReducer(initialState, handlers);

export default unreadMessages;

export const getUnreadMessages = state => state.app.unreadMessages;
