import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';

import { emphasize, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0.5, 0.25),
  },
  isFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },

  labelNode: {
    padding: 0,
  },
}));

function ArcMultiSelectValue({
  children,
  data,
  ...props
}) {
  const classes = useStyles();

  const { renderValue, isDisabled } = props.selectProps;

  return (
    <Chip
      tabIndex={-1}
      label={renderValue ? renderValue(data) : children}
      classes={{
        label: cx([
          (typeof children !== 'string' || !!renderValue) && classes.labelNode,
        ]),
      }}
      className={cx([
        classes.root,
        props.isFocused && classes.isFocused,
      ])}
      onDelete={isDisabled ? undefined : props.removeProps.onClick}
      deleteIcon={isDisabled ? undefined : (<CancelIcon {...props.removeProps} />)}
    />
  );
}

ArcMultiSelectValue.displayName = 'ArcMultiSelectValue';

ArcMultiSelectValue.propTypes = {
  children: PropTypes.node,
  data: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  isFocused: PropTypes.bool,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
  }),
  selectProps: PropTypes.shape({
    isDisabled: PropTypes.bool,
    renderValue: PropTypes.func,
  }),
};

ArcMultiSelectValue.defaultProps = {
  children: null,
  data: {},
  isFocused: false,
  removeProps: {},
  selectProps: {},
};

export default ArcMultiSelectValue;
