import React, { FC, useState } from 'react';
import { ArcCheckbox, ArcConfirmDialog, ArcView, ArcText } from 'arcade-frontend-ui';
import ArcViewBox from 'arcade-frontend-ui/src/components/ArcViewBox';
import IconCheck from '@material-ui/icons/Check';
import { styled } from '@material-ui/core';

const StyledIconCheck = styled(IconCheck)({
  verticalAlign: 'middle',
  marginRight: '3px',
  width: '17px',
  height: '17px',
  padding: 0
})

interface ManageGameCancelProps {
  cancelConfirmDialogOpen: boolean;
  isChecked: boolean;
  setIsChecked: () => void;
  handleCancelConfirmDialogClose: () => void;
  handleCancelConfirmDialogConfirm: () => void;
}
export const ManageGameCancel: FC<ManageGameCancelProps> = ({
  cancelConfirmDialogOpen,
  isChecked,
  setIsChecked,
  handleCancelConfirmDialogClose,
  handleCancelConfirmDialogConfirm,
}) => {

  return (
    <ArcConfirmDialog
      title="Are you sure you want to cancel this game?"
      textContent={false}
      content={
        <ArcView align="center" justify="flex-end">
          <ArcViewBox paddingX="45px">
            <ArcViewBox marginBottom="5px">
              <ArcText textAlignLeft>
                <StyledIconCheck />
                  {'A cancelled game cannot be uncancelled or restarted.'}
              </ArcText>
            </ArcViewBox>
            <ArcViewBox marginBottom="5px">
              <ArcText textAlignLeft>
                <StyledIconCheck />
                {
                  'Cancellation does not impact players who may have already earned prizes.'
                }
              </ArcText>
            </ArcViewBox>
            <ArcViewBox marginBottom="5px">
              <ArcText textAlignLeft>
                <StyledIconCheck />
                {
                  'Cancellation only affects the selected game. It does not cancel games set to repeat.'
                }
              </ArcText>
            </ArcViewBox>
            <ArcViewBox marginBottom="5px">
              <ArcText textAlignLeft>
                <StyledIconCheck />
                {
                  'We highly recommend notifying players so they are not confused.'
                }
              </ArcText>
            </ArcViewBox>
          </ArcViewBox>
          <ArcViewBox width="100%" alignItems="flex-end">
            <ArcCheckbox
              style={{ height: 32 }}
              label={'Notify all players immediately'}
              checked={isChecked}
              onChange={setIsChecked}
            />
          </ArcViewBox>
        </ArcView>
      }
      open={cancelConfirmDialogOpen}
      onClose={handleCancelConfirmDialogClose}
      onConfirm={handleCancelConfirmDialogConfirm}
      cancelLabel="Go Back"
      confirmLabel="Cancel Game"
      confirmButtonColor="danger"
    />
  );
};
