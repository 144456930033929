import { react2angular } from 'react2angular';

import NotificationsPanelContainer from 'arcade-frontend-notifications/src/containers/NotificationsPanelContainer';

import { createProvidedContainer } from './helpers';

const ProvidedNotificationsPanelContainer = createProvidedContainer(
  NotificationsPanelContainer,
);

export default react2angular(ProvidedNotificationsPanelContainer, [
  'onClose',
  'open',
]);
