import React from 'react';

import PropTypes from 'prop-types';

import ArcBox from '../ArcBox';

import TabContext from './TabContext';

const ArcTabs = ({
  children,
  value,
  onChange,
  ...props
}) => (
  <TabContext.Provider value={[value, onChange]}>
    <ArcBox
      role="tablist"
      display="flex"
      flexDirection="row"
      {...props}
    >
      { children }
    </ArcBox>
  </TabContext.Provider>
);

ArcTabs.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

ArcTabs.defaultProps = {
  children: null,
  onChange: global.noop,
  value: null,
};

export default ArcTabs;
