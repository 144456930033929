import React from 'react';
import PropTypes from 'prop-types';

import { ArcView, ArcText } from 'arcade-frontend-ui';
import ArcChestImage from 'arcade-frontend-ui/src/components/ArcChestImage';
import ArcConfirmDialog from 'arcade-frontend-ui/src/components/ArcConfirmDialog';
import ArcEntity from 'arcade-frontend-ui/src/components/ArcEntity';
import ArcStackedEntities from 'arcade-frontend-ui/src/components/ArcStackedEntities';

function ArcAwardChestFormConfirmationDialog({
  chests,
  maxPeople,
  open,
  onClose,
  onConfirm,
  people,
  ...props
}) {
  const length = people ? people.length : 0;

  const shouldCollapsePeople = length > maxPeople;
  const shouldRenderPeople = length > 0;

  return (
    <ArcConfirmDialog
      {...props}
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      textContent={false}
      content={
        <ArcView>
          <ArcView marginBottom="24" align="center">
            <ArcView marginBottom="24">{'You will be awarding'}</ArcView>
            <ArcView>
              {Object.entries(chests).map(
                ([rarity, quantity]) =>
                  !!quantity && (
                    <ArcView key={rarity} row align="center" marginBottom="8">
                      <ArcChestImage
                        label=""
                        size="24px"
                        variant={rarity.toLowerCase()}
                      />
                      <ArcText marginLeft="4" color="disabled" size="12px">
                        x{quantity}
                      </ArcText>
                      <ArcText marginLeft="4" size="12px">
                        {rarity}
                      </ArcText>
                    </ArcView>
                  ),
              )}
            </ArcView>
          </ArcView>

          <ArcView>
            <ArcView marginBottom="24">
              {shouldCollapsePeople
                ? `To ${length} ${length === 1 ? 'person' : 'people'}`
                : 'To'}
            </ArcView>

            {!length && (
              <ArcText color="disabled" size="12px">
                {'No one selected'}
              </ArcText>
            )}

            {shouldRenderPeople && (
              <ArcView fullWidth wrap="wrap" justify="center">
                {shouldCollapsePeople ? (
                  <ArcStackedEntities type="person" entities={people} />
                ) : (
                  people.map(person => (
                    <ArcView key={person?.id}>
                      <ArcEntity
                        type="person"
                        imageUrl={person?.imageUrl}
                        name={person?.name}
                        teamName={person?.teamName}
                      />
                    </ArcView>
                  ))
                )}
              </ArcView>
            )}
          </ArcView>
        </ArcView>
      }
    />
  );
}

ArcAwardChestFormConfirmationDialog.displayName =
  'ArcAwardChestFormConfirmationDialog';

ArcAwardChestFormConfirmationDialog.propTypes = {
  chests: PropTypes.objectOf(PropTypes.number),
  maxPeople: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      imageUrl: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

ArcAwardChestFormConfirmationDialog.defaultProps = {
  chests: {},
  maxPeople: 5,
  open: false,
  onClose: global.noop,
  onConfirm: global.noop,
  people: [],
};

export default ArcAwardChestFormConfirmationDialog;
