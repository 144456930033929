
import React, { FC, useEffect, useState } from 'react'
import { ArcSelect } from 'arcade-frontend-ui';
import useFeatureFlag from 'arcade-frontend-core/src/hooks/useFeatureFlag';
import * as FEATURE_FLAGS from 'arcade-frontend-core/src/types/feature-flags';
import { useArcSWR } from '../../../../../core/src/hooks/useArcSWR';

export interface Option {
  value: number;
  label: string;
}
export interface MetricFilterContainerProps {
  onChange: (value: string) => void;
  value?: string;
}
const MetricFilterContainer: FC<MetricFilterContainerProps> = ({
  onChange,
  value,
}) => {
  const { data } = useArcSWR('/manager/v4/sales/metrics.json')
  const [metricOptions, setMetricOptions] = useState([] as Option[])
  const hasMetricFilter = useFeatureFlag(FEATURE_FLAGS.METRIC_FILTER)
  useEffect(() => {
    const networkMetricOptions = data?.metrics ? data?.metrics?.map(m => ({ value: m.id, label: m.name })) : []
    setMetricOptions([
      { value: -1, label: 'All Metrics'},
      ...networkMetricOptions
    ])
  }, [data?.metrics])

  const handleMetricChange = e => {
    onChange(e.target.value)
  }
  return (
    hasMetricFilter && <ArcSelect
      items={metricOptions}
      onChange={handleMetricChange}
      value={value || -1}
      variant="outlined"
      style={{
        minWidth: "130px",
        marginLeft: "8px"
      }}
    />
  )
}

export default MetricFilterContainer
