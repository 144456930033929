import React from 'react';
import PropTypes from 'prop-types';

const ArcBounty = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 120 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path d="M58.1122 43.7939L47.2884 73.4341L44.9573 79.9283H33.301L37.4639 68.272L46.2894 43.7939H58.1122Z" fill="#FFD15C" />
      <path d="M86.9198 79.9283H75.2635L72.9324 73.4341L62.1086 43.7939H73.9314L82.7569 68.272L86.9198 79.9283Z" fill="#FFD15C" />
      <path d="M46.2894 43.7939H58.1121L47.4549 73.4341C43.958 72.2685 40.6277 70.4368 37.4639 68.272L46.2894 43.7939Z" fill="#F8B64C" />
      <path d="M82.7568 68.272C79.7595 70.4368 76.4291 72.102 72.7657 73.4341L62.1086 43.7939H73.9314L82.7568 68.272Z" fill="#F8B64C" />
      <path d="M60.1105 73.2679C80.3429 73.2679 96.7444 56.8663 96.7444 36.6339C96.7444 16.4016 80.3429 0 60.1105 0C39.8781 0 23.4766 16.4016 23.4766 36.6339C23.4766 56.8663 39.8781 73.2679 60.1105 73.2679Z" fill="#FF7058" />
      <path d="M60.1105 65.4416C76.0204 65.4416 88.918 52.5441 88.918 36.6342C88.918 20.7242 76.0204 7.82666 60.1105 7.82666C44.2005 7.82666 31.303 20.7242 31.303 36.6342C31.303 52.5441 44.2005 65.4416 60.1105 65.4416Z" fill="white" />
      <path d="M60.1106 57.7818C71.7902 57.7818 81.2583 48.3136 81.2583 36.6341C81.2583 24.9545 71.7902 15.4863 60.1106 15.4863C48.431 15.4863 38.9629 24.9545 38.9629 36.6341C38.9629 48.3136 48.431 57.7818 60.1106 57.7818Z" fill="#FF7058" />
      <path d="M60.1105 50.1219C67.5597 50.1219 73.5985 44.0832 73.5985 36.634C73.5985 29.1848 67.5597 23.146 60.1105 23.146C52.6613 23.146 46.6226 29.1848 46.6226 36.634C46.6226 44.0832 52.6613 50.1219 60.1105 50.1219Z" fill="white" />
      <path d="M60.1105 42.4618C63.3293 42.4618 65.9386 39.8525 65.9386 36.6337C65.9386 33.415 63.3293 30.8057 60.1105 30.8057C56.8918 30.8057 54.2825 33.415 54.2825 36.6337C54.2825 39.8525 56.8918 42.4618 60.1105 42.4618Z" fill="#FF7058" />
      <path d="M87.7524 13.6545L61.1095 37.4664H60.943L59.2778 35.8013V35.6347L85.9207 11.6562H86.0872L87.5859 13.3214C87.7524 13.488 87.7524 13.488 87.7524 13.6545Z" fill="#40596B" />
      <path d="M84.089 15.3195L74.431 23.9783L73.0989 16.9846L82.7569 8.32568L84.089 15.3195Z" fill="#334A5E" />
      <path d="M81.2582 25.9764L90.9162 17.484L84.0889 15.3193L74.4309 23.9781L81.2582 25.9764Z" fill="#334A5E" />
      <path d="M59.9999 73.3333C80.2504 73.3333 96.6666 56.9171 96.6666 36.6667C96.6666 16.4162 80.2504 0 59.9999 0C39.7495 0 23.3333 16.4162 23.3333 36.6667C23.3333 56.9171 39.7495 73.3333 59.9999 73.3333Z" fill="#00A3D6" />
      <path d="M60.1657 64.374C45.0179 64.374 32.6243 51.9803 32.6243 36.8325C32.6243 21.6847 45.0179 9.29102 60.1657 9.29102C75.3136 9.29102 87.7072 21.6847 87.7072 36.8325C87.7072 51.9803 75.3136 64.374 60.1657 64.374Z" fill="#F2F2F2" />
      <path d="M59.8341 54.7512C49.7964 54.7512 41.5837 46.5386 41.5837 36.5009C41.5837 26.4632 49.7964 18.2505 59.8341 18.2505C69.8718 18.2505 78.0845 26.4632 78.0845 36.5009C78.0845 46.5386 69.8718 54.7512 59.8341 54.7512Z" fill="#FF7058" />
      <path d="M60 45.7919C54.9811 45.7919 50.8748 41.6855 50.8748 36.6667C50.8748 31.6478 54.9811 27.5415 60 27.5415C65.0188 27.5415 69.1251 31.6478 69.1251 36.6667C69.1251 41.6855 65.0188 45.7919 60 45.7919Z" fill="#CDD6E0" />
      <path d="M60 45.7919C54.9811 45.7919 50.8748 41.6855 50.8748 36.6667C50.8748 31.6478 54.9811 27.5415 60 27.5415C65.0188 27.5415 69.1251 31.6478 69.1251 36.6667C69.1251 41.6855 65.0188 45.7919 60 45.7919Z" fill="#FFD15C" />
      <path opacity="0.2" d="M96.3346 41.1752C95.722 45.7868 94.3436 50.091 92.1995 54.0876L90.2085 53.319L87.605 47.7852L60.0375 37.7935C58.8123 37.3324 58.1997 35.9489 58.6591 34.7192C59.1186 33.4895 60.497 32.8746 61.7221 33.3357L89.2896 43.3274L94.8031 40.7142L96.3346 41.1752Z" fill="#334A5E" />
      <path d="M85.0085 14.8224L62.5545 37.1447C61.638 38.0558 60.1104 38.0558 59.194 37.1447C58.2775 36.2336 58.2775 34.715 59.194 33.8039L81.8008 11.6335C82.7172 10.7224 84.2448 10.7224 85.1613 11.6335C85.925 12.5446 85.925 13.9113 85.0085 14.8224Z" fill="#40596B" />
      <path d="M87.3756 9.43715L80.0754 16.5913V7.15396L87.3756 0V9.43715Z" fill="#FF7058" />
      <path d="M89.5127 16.5897L96.6667 9.28955H87.2295L80.0754 16.5897H89.5127Z" fill="#F1543F" />
      <path d="M104.603 43.3423C104.085 45.1193 105.587 49.4308 110.359 50.333C112.082 45.8419 108.038 43.1536 104.603 43.3423Z" fill="#03C8A2" />
      <path d="M103.104 50.333C101.583 52.7861 103.183 57.4751 106.595 58.9997C109.293 55.5255 107.182 51.3899 103.104 50.333Z" fill="#03C8A2" />
      <path d="M111.758 50.8602C114.398 52.2177 120.003 50.8233 120 44.4319C116.137 43.7247 112.335 46.9083 111.758 50.8602Z" fill="#03C8A2" />
      <path d="M110.11 32.396C109.797 29.8306 106.2 26.3549 101.539 29.7318C102.661 32.4759 106.693 33.8854 110.11 32.396Z" fill="#03C8A2" />
      <path d="M113.134 41.2946C118.346 41.8411 120.228 36.4563 118.959 32.6665C114.704 33.1196 112.706 37.8539 113.134 41.2946Z" fill="#03C8A2" />
      <path d="M103.846 36.4057C104.275 40.4454 109.079 42.0232 111.412 41.6001C111.712 37.2274 107.97 35.0355 103.846 36.4057Z" fill="#03C8A2" />
      <path d="M114.954 21.6665C110.5 23.5782 110.596 29.456 111.83 31.6665C117.144 30.4283 117.24 25.0866 114.954 21.6665Z" fill="#03C8A2" />
      <path d="M100.523 65.9998C104.25 63.5727 103.168 58.8398 99.7101 56.6665C96.6736 59.855 99.0566 64.7692 100.523 65.9998Z" fill="#03C8A2" />
      <path d="M101.539 66.9977C103.606 70.5819 108.993 70.8039 111.758 66.1385C108.816 63.1831 104.123 64.1307 101.539 66.9977Z" fill="#03C8A2" />
      <path d="M94.6213 61.333C91.7537 62.8673 91.3325 67.5434 92.8121 70.333C96.2647 69.3569 97.5885 64.8103 94.6213 61.333Z" fill="#03C8A2" />
      <path d="M107.802 59.9528C110.339 62.3162 115.443 62.0279 117.363 56.0442C113.245 53.7174 108.671 57.6498 107.802 59.9528Z" fill="#03C8A2" />
      <path d="M93.2969 72.0613C94.3726 75.6006 98.5028 77.6594 103.517 74.2795C102.491 72.1057 98.3226 69.5005 93.2969 72.0613Z" fill="#03C8A2" />
      <path d="M84.0659 74.1813C84.0659 78.3787 88.0443 81.4111 93.2967 79.3233C93.049 77.3742 89.9211 73.0671 84.0659 74.1813Z" fill="#03C8A2" />
      <path d="M88.3595 64.6665C85.7899 64.958 83.0546 68.5956 83.8893 72.6665C88.0073 72.6665 90.1857 68.8369 88.3595 64.6665Z" fill="#03C8A2" />
      <path d="M15.3974 43.3423C15.9149 45.1193 14.4132 49.4308 9.64106 50.333C7.91842 45.8419 11.9616 43.1536 15.3974 43.3423Z" fill="#03C8A2" />
      <path d="M16.8961 50.333C18.4167 52.7861 16.8171 57.4751 13.4046 58.9997C10.7073 55.5255 12.8182 51.3899 16.8961 50.333Z" fill="#03C8A2" />
      <path d="M8.2417 50.8602C5.60221 52.2177 -0.00270557 50.8233 -5.81741e-05 44.4319C3.86301 43.7247 7.66549 46.9083 8.2417 50.8602Z" fill="#03C8A2" />
      <path d="M9.89 32.396C10.2031 29.8306 13.7996 26.3549 18.4614 29.7318C17.3392 32.4759 13.3074 33.8854 9.89 32.396Z" fill="#03C8A2" />
      <path d="M6.8665 41.2946C1.65358 41.8411 -0.227635 36.4563 1.0412 32.6665C5.29586 33.1196 7.29389 37.8539 6.8665 41.2946Z" fill="#03C8A2" />
      <path d="M16.1538 36.4057C15.7246 40.4454 10.9211 42.0232 8.58832 41.6001C8.28799 37.2274 12.0305 35.0355 16.1538 36.4057Z" fill="#03C8A2" />
      <path d="M5.04604 21.6665C9.49962 23.5782 9.40361 29.456 8.17025 31.6665C2.85581 30.4283 2.7598 25.0866 5.04604 21.6665Z" fill="#03C8A2" />
      <path d="M19.4773 65.9998C15.7496 63.5727 16.8324 58.8398 20.2899 56.6665C23.3264 59.855 20.9434 64.7692 19.4773 65.9998Z" fill="#03C8A2" />
      <path d="M18.4614 66.9977C16.3938 70.5819 11.0071 70.8039 8.24164 66.1385C11.1836 63.1831 15.8765 64.1307 18.4614 66.9977Z" fill="#03C8A2" />
      <path d="M25.3787 61.333C28.2463 62.8673 28.6675 67.5434 27.1879 70.333C23.7353 69.3569 22.4115 64.8103 25.3787 61.333Z" fill="#03C8A2" />
      <path d="M12.1978 59.9528C9.6613 62.3162 4.55701 62.0279 2.63731 56.0442C6.75537 53.7174 11.329 57.6498 12.1978 59.9528Z" fill="#03C8A2" />
      <path d="M26.7031 72.0613C25.6274 75.6006 21.4972 77.6594 16.4833 74.2795C17.5086 72.1057 21.6774 69.5005 26.7031 72.0613Z" fill="#03C8A2" />
      <path d="M35.9341 74.1813C35.9341 78.3787 31.9557 81.4111 26.7033 79.3233C26.951 77.3742 30.079 73.0671 35.9341 74.1813Z" fill="#03C8A2" />
      <path d="M31.6405 64.6665C34.2101 64.958 36.9454 68.5956 36.1107 72.6665C31.9926 72.6665 29.8143 68.8369 31.6405 64.6665Z" fill="#03C8A2" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0H120V80H0V0Z" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default ArcBounty;

ArcBounty.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

ArcBounty.defaultProps = {
  height: 360,
  width: 240,
};
