import React from 'react';

import { Path } from 'react-primitives-svg';
import Icon from '../Icon';


const NoInternet = props => (
  <Icon {...props}>
    <Path
      fill="currentColor"
      d="M7.975 6.127c1.092-.43 2.298-.711 3.596-.711.908 0 1.863.14 2.863.466l.808-1.4a10.86 10.86 0 00-3.671-.649c-1.552 0-2.979.342-4.236.846-1.888.758-3.402 1.877-4.454 2.806-1.052.93-1.641 1.673-1.663 1.7a.791.791 0 001.241.981c.032-.04 1.013-1.255 2.64-2.435a13.188 13.188 0 012.876-1.604zm-1.053 4.58a9.648 9.648 0 012.09-1.11 7.229 7.229 0 012.548-.48c.313 0 .636.025.965.073l.84-1.455a8.302 8.302 0 00-1.805-.2c-1.215 0-2.33.267-3.311.66-1.472.591-2.647 1.46-3.463 2.18-.816.722-1.273 1.299-1.295 1.326a.791.791 0 001.24.982h.001l.031-.039a11.43 11.43 0 012.16-1.937zm-.988 4.581a.791.791 0 001.11-.128l.001-.002a7.443 7.443 0 011.316-1.211 6.558 6.558 0 011.432-.799 5.27 5.27 0 01.542-.177l1.01-1.748a6.413 6.413 0 00-2.166.467c-1.049.421-1.877 1.035-2.454 1.543-.576.51-.9.917-.921.944a.791.791 0 00.13 1.111zm15.857-6.146c-1.207-1.322-2.407-2.35-3.59-3.14L21.163.87l-1.46-.843-13.337 23.1 1.46.843 2.71-4.694a2.242 2.242 0 002.236-3.873l1.13-1.957c.668.371 1.385.932 2.135 1.753a.79.79 0 101.168-1.067c-.838-.918-1.68-1.59-2.512-2.056l.965-1.672c.861.549 1.762 1.302 2.693 2.32a.79.79 0 101.168-1.068c-1.03-1.127-2.056-1.988-3.069-2.625l.957-1.658c1.038.704 2.113 1.627 3.215 2.834a.791.791 0 001.169-1.066z"
    />
  </Icon>
);

export default NoInternet;
