import { combineReducers } from 'redux';

import isRequesting from './isRequesting';
import metrics from './metrics';
import requestStatus from './requestStatus';
import salesById from './salesById';
import teams from './teams';
import totalSales from './totalSales';
import users from './users';

export default combineReducers({
  isRequesting,
  metrics,
  requestStatus,
  salesById,
  teams,
  totalSales,
  users,
});
