import React from 'react';

import { G, Path } from 'react-primitives-svg';
import Icon from '../Icon';

const Calendar = props => (
  <Icon {...props}>
    <G
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    >
      <Path d="M22.5 2.501h-21a1 1 0 00-1 1v19a1 1 0 001 1h21a1 1 0 001-1v-19a1 1 0 00-1-1zM.5 7.501h23M18.5.501v5M5.5.501v5" />
    </G>
  </Icon>
);

export default Calendar;
