import { createReducer } from 'arcade-frontend-ui';
import { createSelector } from 'reselect';

import * as types from '../../../actions/manage/metrics/types';

const handleManageMetricsIndexSuccess = (state, action) => {
  if (!action.payload) return state;

  const { data } = action.payload;

  const nextState = { };

  data.forEach((metric) => {
    nextState[metric.id] = metric;
  });

  return nextState;
};

const handleManageMetricsArchiveSuccess = (state, action) => {
  const { id } = action.payload.data;

  const nextState = { ...state };

  delete nextState[id];

  return nextState;
};

const handleManageMetricsEditSuccess = (state, action) => {
  const { id } = action.payload.data;

  if (id) {
    return {
      ...state,
      [id]: {
        ...action.payload.data,
      },
    };
  }

  return state;
};

const initialState = {};

const handlers = {
  [types.MANAGE_METRICS_INDEX.SUCCESS]: handleManageMetricsIndexSuccess,
  [types.MANAGE_METRICS_EDIT.SUCCESS]: handleManageMetricsEditSuccess,
  [types.MANAGE_METRICS_ARCHIVE.SUCCESS]: handleManageMetricsArchiveSuccess,
};

const activeHandlers = {
  [types.MANAGE_ACTIVE_METRICS_INDEX.SUCCESS]: handleManageMetricsIndexSuccess,
};

const archivedHandlers = {
  [types.MANAGE_ARCHIVED_METRICS_INDEX.SUCCESS]: handleManageMetricsIndexSuccess,
};

const manageMetricsById = createReducer(initialState, handlers);
export const manageActiveMetricsById = createReducer(initialState, activeHandlers);
export const manageArchivedMetricsById = createReducer(initialState, archivedHandlers);

export default manageMetricsById;

export const getManageMetrics = state => state.manage.metrics.manageMetricsById;
export const getManageMetricsById = state => state.manage.metrics.manageMetricsById;

export const getManageActiveMetricsById = state => state.manage.metrics.manageActiveMetricsById;
export const getManageArchivedMetricsById = state => state.manage.metrics.manageArchivedMetricsById;

const getSortDesc = desc => () => desc;
const getSortProp = prop => () => prop;

const sortCollectionByProp = (collection, prop, desc) => (
  Object.keys(collection).sort((a, b) => {
    if (collection[a][prop] < collection[b][prop]) {
      return desc ? 1 : -1;
    } if (collection[a][prop] > collection[b][prop]) {
      return desc ? -1 : 1;
    }

    return 0;
  }).map(id => collection[id])
);

const createSortSelector = (collection, prop, desc) => (
  createSelector(
    [
      collection,
      getSortProp(prop),
      getSortDesc(desc),
    ],
    sortCollectionByProp,
  )
);

const createValueSelector = collection => (
  createSelector(collection, hash => Object.values(hash))
);

const manageMetricsActive = createValueSelector(getManageActiveMetricsById);
const manageMetricsArchived = createValueSelector(getManageArchivedMetricsById);

export const getManageMetricsSorted = createSelector([getManageMetrics, getSortProp], sortCollectionByProp);

export const getManageMetricsActive = createSortSelector(manageMetricsActive, 'title');
export const getManageMetricsArchived = createSortSelector(manageMetricsArchived, 'title');
