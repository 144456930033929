import getAxiosConfig from '../getAxiosConfig';

function getTransformedResponseFromResourceAction(resourceAction, sampleData, payload) {
  const axiosConfig = getAxiosConfig(resourceAction, payload);

  let data = sampleData;

  axiosConfig.transformResponse.forEach((transformFn) => {
    data = transformFn(data);
  });

  return data;
}


export default getTransformedResponseFromResourceAction;
