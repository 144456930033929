import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/tokens';

const initialState = false;

const handlers = {
  [types.MANAGE_TOKENS_SET_HAS_TOPPED_UP]: (state, action) => action.payload,
};

const hasToppedUp = createReducer(initialState, handlers);

export default hasToppedUp;

export const getHasToppedUp = state => state.manage.tokens.hasToppedUp;
