import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@material-ui/lab/Skeleton';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcTab from 'arcade-frontend-ui/src/components/ArcTab';
import ArcTabs from 'arcade-frontend-ui/src/components/ArcTabs';
import ArcResponsiveDialog from 'arcade-frontend-ui/src/components/ArcResponsiveDialog';
import ArcButton from 'arcade-frontend-ui/src/elements/ArcButton';
import ArcResourceButton from 'arcade-frontend-ui/src/components/ArcResourceButton';

import ProfileEditFormDisclaimer from './ProfileEditFormDisclaimer';
import { TABS, LABELS_BY_TAB_TYPE } from './ProfileEditForm.types';

const dialogContentStyle = {
  height: 478,
};

function ProfileEditFormPlaceholder({ onClose, ...props }) {
  return (
    <ArcResponsiveDialog onClose={onClose} {...props}>
      <DialogTitle>
        <ArcBox
          display="flex"
          flexDirection={['column', 'row']}
          alignItems={['flex-start', 'flex-end']}
        >
          <ArcBox mb={[1, 0]}>{'My Profile'}</ArcBox>
          <ArcBox display="flex" flex={100} />
          <ArcTabs value={TABS[0]}>
            {TABS.map(type => (
              <ArcTab
                key={type}
                id={`tab-${type}`}
                aria-controls={`panel-${type}`}
                value={type}
                label={LABELS_BY_TAB_TYPE[type]}
                disabled
              />
            ))}
          </ArcTabs>
        </ArcBox>
      </DialogTitle>
      <DialogContent style={dialogContentStyle}>
        <ArcBox display="flex" flexDirection={['column', 'row']} mb={2}>
          <ArcBox display="flex" flex="1" mr={[0, 1]}>
            <Skeleton variant="rect" height={56} width="100%" />
          </ArcBox>

          <ArcBox display="flex" flex="1" ml={[0, 1]}>
            <Skeleton variant="rect" height={56} width="100%" />
          </ArcBox>
        </ArcBox>
        {[0, 1].map(id => (
          <ArcBox key={id} mb={2}>
            <Skeleton variant="rect" height={56} />
          </ArcBox>
        ))}
        <ProfileEditFormDisclaimer />
      </DialogContent>
      <DialogActions>
        <ArcButton label="Cancel" onClick={onClose} />
        <ArcResourceButton
          color="secondary"
          disabled
          variant="contained"
          label="Submit"
        />
      </DialogActions>
    </ArcResponsiveDialog>
  );
}
ProfileEditFormPlaceholder.displayName = 'ProfileEditFormPlaceholder';

ProfileEditFormPlaceholder.propTypes = {
  onClose: PropTypes.func,
};

ProfileEditFormPlaceholder.defaultProps = {
  onClose: global.noop,
};

export default ProfileEditFormPlaceholder;
