import { createReducer } from 'arcade-frontend-ui';
import { types as typesGames } from 'arcade-frontend-games/src/actions';
import { types } from '../../../actions/manage/games';

const setTrue = () => true;
const setFalse = () => false;

const createIsFetchingReducer = (initialState, apiActionTypes) => {
  const handlers = {};

  apiActionTypes.forEach(type => {
    handlers[type.REQUEST] = setTrue;
    handlers[type.SUCCESS] = setFalse;
    handlers[type.FAILURE] = setFalse;
  });

  return createReducer(initialState, handlers);
};

const initialState = false;

const apiActionTypes = [
  typesGames.GAMES_INDEX,
  types.MANAGE_GAMES_INDEX,
  types.MANAGE_GAMES_SHOW,
  types.MANAGE_GAMES_LIST,
  // types.MANAGE_GAMES_CREATE,
  // types.MANAGE_GAMES_UPDATE,
];

const isFetching = createIsFetchingReducer(initialState, apiActionTypes);

export default isFetching;

export const getManageGamesIsFetching = state => state.manage.games.isFetching;
