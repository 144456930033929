import * as routeTypes from 'arcade-frontend-core/src/types/routes';

import Cap from 'arcade-frontend-ui/src/icons/Cap';
import PresentOutline from 'arcade-frontend-ui/src/icons/PresentOutline';
import Chat from 'arcade-frontend-ui/src/icons/Chat';
import GamesOutline from 'arcade-frontend-ui/src/icons/GamesOutline';
import FlagAlt from 'arcade-frontend-ui/src/icons/FlagAlt';
import Users from 'arcade-frontend-ui/src/icons/Users';
import Settings from 'arcade-frontend-ui/src/icons/Settings';
import StatsLine from 'arcade-frontend-ui/src/icons/StatsLine';
import Group from 'arcade-frontend-ui/src/icons/Group';
import Home from 'arcade-frontend-ui/src/icons/Home';

const featureRoutesConfig = [
  {
    type: routeTypes.ROUTE_NEWSFEED,
    title: 'Newsfeed',
    icon: Home,
  },
  {
    type: routeTypes.ROUTE_CHAT,
    title: 'Chat',
    icon: Chat,
  },
  {
    type: routeTypes.ROUTE_HUB,
    title: 'Hub',
    icon: StatsLine,
  },
  {
    type: routeTypes.ROUTE_METRICS,
    title: 'Metrics',
    icon: FlagAlt,
  },
  {
    type: routeTypes.ROUTE_LEAGUE,
    title: 'League',
    icon: GamesOutline,
  },
  {
    type: routeTypes.ROUTE_GAMES,
    title: 'Games',
    icon: GamesOutline,
  },
  {
    type: routeTypes.ROUTE_HUDDLE,
    title: 'Huddle',
    icon: Group,
  },
  {
    type: routeTypes.ROUTE_REWARDS,
    title: 'Rewards',
    icon: PresentOutline,
  },
  {
    type: routeTypes.ROUTE_ACADEMY,
    title: 'Academy',
    icon: Cap,
  },
  {
    type: routeTypes.ROUTE_PEOPLE,
    title: 'People',
    icon: Users,
  },
  {
    type: routeTypes.ROUTE_MANAGE,
    title: 'Manage',
    icon: Settings,
  },
  {
    type: routeTypes.ROUTE_INSIGHTS,
    title: 'Insights',
    icon: StatsLine,
  },
];


const routes = {};
const types = [];

featureRoutesConfig.forEach((route) => {
  routes[route.type] = route;
  types.push(route.type);
});

export const featureRoutes = routes;
export const featureRouteTypes = types;
