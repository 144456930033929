import React from 'react';
import ArcPropTypes from '../../helpers/arc/propTypes';

const ArcImage = React.forwardRef(({ alt, ...props }, ref) => (
  <img
    alt={alt}
    ref={ref}
    {...props}
  />
));

ArcImage.propTypes = {
  src: ArcPropTypes.string,
  alt: ArcPropTypes.string,
};

ArcImage.defaultProps = {
  src: undefined,
  alt: 'image',
};

export default ArcImage;
