import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import VpnKey from '@material-ui/icons/VpnKey';

import ArcBox from 'arcade-frontend-ui/src/components/ArcBox';
import ArcIconButton from 'arcade-frontend-ui/src/elements/ArcIconButton';
import ArcText from 'arcade-frontend-ui/src/primitives/ArcText';
import ArcFormField from 'arcade-frontend-forms/src/components/ArcFormField';

const PREVENT_DEFAULT = e => e && e.preventDefault && e.preventDefault();

function ProfileEditFormSecurity(props) {
  return (
    <ArcBox className="animated fadeIn">
      <ArcBox
        color="body1.color"
        fontSize="body1.fontSize"
        fontWeight="body1.fontWeight"
        lineHeight="body1.lineHeight"
        mb={1}
      >
        <ArcText>{'Change your password'}</ArcText>
      </ArcBox>
      <ArcBox
        color="caption.color"
        fontSize="caption.fontSize"
        lineHeight="caption.lineHeight"
        mb={3}
      >
        <ArcText>{'It must be at least 6 characters'}</ArcText>
      </ArcBox>

      <ArcFormField
        autoComplete="new-password"
        type={props.showPassword ? 'text' : 'password'}
        name="newPassword"
        label="New Password"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <VpnKey color="action" fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <ArcIconButton
                aria-label="toggle password visibility"
                onClick={props.onPasswordVisibilityClick}
                onMouseDown={PREVENT_DEFAULT}
              >
                <ArcText isSmaller>
                  {props.showPassword ? 'HIDE' : 'SHOW'}
                </ArcText>
              </ArcIconButton>
            </InputAdornment>
          ),
        }}
      />

      <ArcFormField
        autoComplete="new-password"
        type={props.showPassword ? 'text' : 'password'}
        name="passwordConfirmation"
        label="New Password Confirmation"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <VpnKey color="action" fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
    </ArcBox>
  );
}

ProfileEditFormSecurity.displayName = 'ProfileEditFormSecurity';

ProfileEditFormSecurity.propTypes = {
  values: PropTypes.shape({
    birthday: PropTypes.string,
  }),
  onPasswordVisibilityClick: PropTypes.func,
  showPassword: PropTypes.bool,
};

ProfileEditFormSecurity.defaultProps = {
  values: {},
  onPasswordVisibilityClick: global.noop,
  showPassword: false,
};

export default ProfileEditFormSecurity;
