import track from '../../helpers/analytics';


export default () => next => (action) => {
  if (action && action.meta && action.meta.analytics) {
    track(action.type, action.meta.analytics);
  }

  return next(action);
};
