import { combineReducers } from 'redux';

import currentGameId from './currentGameId';
import currentGameDetailsType from './currentGameDetailsType';
import eventsByGameId from './eventsByGameId';
import eligiblePeople from './eligiblePeople';
import eligibleTeams from './eligibleTeams';
import eligibleTeamPeople from './eligibleTeamPeople';
import resultsByGameId from './gameResults';
import gameCreateType from './gameCreateType';
import isCreating from './isCreating';
import isFetching from './isFetching';
import metricsById from './metricsById';
import manageGamesById from './manageGamesById';
import manageGamesErrors from './manageGamesErrors';
import peopleById from './peopleById';
import rankingsByGameId from './rankingsByGameId';
import requestStatus from './requestStatus';
import teamsById from './teamsById';
import templates from './templates';
import validMetrics from './validMetrics';
import isFetchingPeople from './isFetchingPeople';
import isFetchingResults from './isFetchingResults';
import filteredGameIds from './filteredGameIds';
import extraGameDataById from './extraGameDataById';
import escrowAvailable from './escrowAvailable';
import verifiedGameIds from './verifiedGameIds';

const games = combineReducers({
  escrowAvailable,
  extraGameDataById,
  currentGameId,
  currentGameDetailsType,
  eligiblePeople,
  eligibleTeams,
  eligibleTeamPeople,
  eventsByGameId,
  gameCreateType,
  isCreating,
  isFetching,
  manageGamesById,
  manageGamesErrors,
  metricsById,
  peopleById,
  rankingsByGameId,
  resultsByGameId,
  requestStatus,
  teamsById,
  templates,
  validMetrics,
  isFetchingPeople,
  isFetchingResults,
  filteredGameIds,
  verifiedGameIds,
});

export default games;
