import { createReducer } from 'arcade-frontend-ui';

import { types } from '../../../actions/manage/sales';

const sorted = (people, prop) => people.sort((a, b) => {
  if (a[prop] < b[prop]) {
    return -1;
  } if (a[prop] > b[prop]) {
    return 1;
  }

  return 0;
});

const handleManageSaleUsersLoadSuccess = (state, action) => {
  const { data } = action.payload;

  return [...sorted(data, 'name')];
};

const initialState = [];

const handlers = {
  [types.MANAGE_SALE_USERS_INDEX.SUCCESS]: handleManageSaleUsersLoadSuccess,
};

const salesById = createReducer(initialState, handlers);

export default salesById;

export const getManageSaleUsers = state => state.manage.sales.users;
