import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import VpnKey from '@material-ui/icons/VpnKey';

import {
  ArcFormField,
  ArcIconButton,
  ArcView,
  ArcText,
} from 'arcade-frontend-ui';

const subtitleStyle = {
  fontSize: '1rem',
  fontWeight: '400',
};

const MIN_PASSWORD_LENGTH = 6;
const MAX_PASSWORD_LENGTH = 256;

const PREVENT_DEFAULT = e => e && e.preventDefault && e.preventDefault();

class ArcProfileFormStepPassword extends React.PureComponent {
  static propTypes = {
    errors: PropTypes.shape({
      password: PropTypes.string,
      password_confirmation: PropTypes.string,
    }).isRequired,
    values: PropTypes.shape({
      password: PropTypes.string,
      password_confirmation: PropTypes.string,
    }).isRequired,
    touched: PropTypes.shape({
      password: PropTypes.bool,
      password_confirmation: PropTypes.bool,
    }).isRequired,
  };

  state = {
    showPassword: false,
    passwordsAreEqual: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.values !== this.props.values) {
      const passwordChanged =
        prevProps.values.password !== this.props.values.password;
      const passwordConfirmationChanged =
        prevProps.values.password_confirmation !==
        this.props.values.password_confirmation;

      if (passwordChanged || passwordConfirmationChanged) {
        this.setPasswordsAreEqual(
          this.props.values.password ===
            this.props.values.password_confirmation,
        );
      }
    }
  }

  get showError() {
    return (
      !this.state.passwordsAreEqual &&
      this.props.touched.password &&
      this.props.touched.password_confirmation &&
      this.props.values.password &&
      this.props.values.password_confirmation &&
      !this.props.errors.password &&
      !this.props.errors.password_confirmation
    );
  }

  setPasswordsAreEqual = passwordsAreEqual =>
    this.setState({ passwordsAreEqual });

  setShowPassword = showPassword => this.setState({ showPassword });

  handleTogglePasswordVisibility = () =>
    this.setShowPassword(!this.state.showPassword);

  render() {
    return (
      <ArcView>
        <ArcView color="blue" padding="24">
          <ArcView marginTop="16">
            <Typography variant="h4">
              <ArcText color="white">{'Password'}</ArcText>
            </Typography>
          </ArcView>
        </ArcView>
        <ArcView padding="24">
          <ArcView marginBottom="16">
            <ArcView marginBottom="16">
              <Typography variant="h5">{'Set your password'}</Typography>
              <Typography variant="caption" style={subtitleStyle}>
                {'Must be at least 6 characters'}
              </Typography>
            </ArcView>

            <ArcFormField
              autoComplete="off"
              name="password"
              type={this.state.showPassword ? 'password' : 'text'}
              label="Password"
              validations={{
                isRequired: true,
                minLength: MIN_PASSWORD_LENGTH,
                maxLength: MAX_PASSWORD_LENGTH,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey color="action" fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <ArcIconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleTogglePasswordVisibility}
                      onMouseDown={PREVENT_DEFAULT}
                      size="small"
                    >
                      <ArcText isSmaller>
                        {this.state.showPassword ? 'SHOW' : 'HIDE'}
                      </ArcText>
                    </ArcIconButton>
                  </InputAdornment>
                ),
              }}
            />

            <ArcFormField
              autoComplete="off"
              name="password_confirmation"
              type={this.state.showPassword ? 'password' : 'text'}
              label="Password Confirmation"
              validations={{
                isRequired: true,
                minLength: MIN_PASSWORD_LENGTH,
                maxLength: MAX_PASSWORD_LENGTH,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey color="action" fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />

            {this.showError && (
              <ArcView>
                <ArcText color="danger">
                  {'Password and confirmation must be the same.'}
                </ArcText>
              </ArcView>
            )}
          </ArcView>
        </ArcView>
      </ArcView>
    );
  }
}

export default ArcProfileFormStepPassword;
