import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';


const AddCircleAlt = props => (
  <Icon {...props}>
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11z"
      clipRule="evenodd"
    />
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 6v12M18 12H6"
    />
  </Icon>
);

export default AddCircleAlt;
