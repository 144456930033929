import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Portal } from 'react-portal';

import { actions } from 'arcade-frontend-core/src/actions';
import { getCurrentUser } from 'arcade-frontend-core/src/reducers/user';
import { getOnboardingTourOpen } from 'arcade-frontend-core/src/reducers/onboardingTourOpen';

import ArcTourStepper from 'arcade-frontend-widgets/src/components/ArcTourStepper';

export class ArcTourStepperContainer extends React.PureComponent {
  static propTypes = {
    appProfileUpdate: PropTypes.func.isRequired,
    appSetOnboardingTourOpen: PropTypes.func.isRequired,
    children: PropTypes.func,
    isOpen: PropTypes.bool,
    onFinish: PropTypes.func,
    portalNode: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  };

  static defaultProps = {
    children: null,
    isOpen: false,
    onFinish: global.noop,
    portalNode: typeof document === 'undefined' ? null : document.body,
  };

  closePortal = () => this.props.appSetOnboardingTourOpen(false);

  openPortal = () => this.props.appSetOnboardingTourOpen(true);

  handleFinish = () => {
    this.closePortal();
    this.props.onFinish();
  };

  handleProfileFormSubmit = values => {
    const formValues = { ...values };

    if (formValues.remote_image_url) {
      delete formValues.avatar;
    }

    delete formValues.password_confirmation;

    this.props.appProfileUpdate(formValues);

    if (window.arcPostMessage) {
      const { firstname, lastname, avatar } = formValues;
      const payload = JSON.stringify({
        firstname,
        lastname,
        profileImage:
          avatar || formValues.remote_image_url.replace('.svg', '.png'),
      });

      window.arcPostMessage(`native.updateCurrentUser.${payload}`);
    }
  };

  renderArcTourStepper = () => (
    <ArcTourStepper
      {...this.props}
      onProfileFormSubmit={this.handleProfileFormSubmit}
      onFinish={this.handleFinish}
    />
  );

  renderChildren() {
    if (this.props.children) {
      return this.props.children(this.openPortal);
    }

    return null;
  }

  render() {
    if (!this.props.isOpen) {
      return this.renderChildren();
    }

    return (
      <>
        {this.renderChildren()}
        <Portal node={this.props.portalNode}>
          {this.renderArcTourStepper()}
        </Portal>
      </>
    );
  }
}

const getState = state => {
  const currentUser = getCurrentUser(state);

  let isFirstSignIn = false;

  if (currentUser && typeof currentUser.firstSignIn !== 'undefined') {
    isFirstSignIn = currentUser.firstSignIn;
  }

  return {
    canDismiss: !isFirstSignIn,
    firstName: currentUser.firstname,
    lastName: currentUser.lastname,
    isFirstSignIn,
    isOpen: isFirstSignIn || getOnboardingTourOpen(state),
  };
};

const getActions = dispatch =>
  bindActionCreators(
    {
      appProfileUpdate: actions.appProfileUpdate,
      appSetOnboardingTourOpen: actions.appSetOnboardingTourOpen,
      onFinish: () => {
        if (global.canUseDOM) {
          const user = JSON.parse(window.localStorage.getItem('user') || {});
          user.firstSignIn = false;

          window.localStorage.setItem('user', JSON.stringify(user));
        }

        return actions.appSetFirstSignIn(false);
      },
    },
    dispatch,
  );

export default connect(getState, getActions)(ArcTourStepperContainer);
