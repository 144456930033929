import { date } from 'arcade-frontend-ui';

const ranking = props => ({
  personId: props.user_id.toString(),
  rank: props.rank,
  name: props.fullname,
  score: props.sum,
  imageUrl: props.profile_image,
});

const formatPercent = percent => parseInt(Math.round(percent * 100) / 100, 10);

const activity = props => ({
  id: props.id ? props.id.toString() : null,
  name: props.kpi_title,
  kpiId: props.kpi_id ? props.kpi_id.toString() : null,
  createdAt: props.created_at,
  formattedCreatedAt: date.formatFullDateTime(props.created_at),
  quantity: props.quantity,
  person: props.user_name,
  imageUrl: props.profile_image,
});

const load = props => ({
  id: props.id ? props.id.toString() : null,
  kpiId: props.kpi_id ? props.kpi_id.toString() : null,
  name: props.title,
  progress: props.progress,
  symbol: props.symbol,
  percentOfBenchmark: formatPercent(props.percent_of_benchmark),
  benchmarkValue: props.benchmark_value,
  verb: props.verb,
  canAddSales: props.can_add_sales,
  isCalculated: props.is_calculated,
});

const normalizers = {
  ranking,
  activity,
  load,
};

export default normalizers;
