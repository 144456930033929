import createResourceAction from 'arcade-frontend-core/src/helpers/createResourceAction';

import normalizers from '../normalizers';

export const apiGetTeams = createResourceAction('read', {
  resourceType: 'teams',
  requestKey: 'apiGetTeams',

  api: () => ({
    method: 'GET',
    url: '/v4/teams/',
    transformResponse: data => ({
      resources: normalizers.normalizeTeams(data.teams),
    }),
  }),
});

export const apiPostCreateTeam = createResourceAction('create', {
  resourceType: 'teams',
  requestKey: 'apiPostCreateTeam',

  api: payload => {
    const { team } = payload.resources[0];
    return {
      method: 'POST',
      url: 'manager/v4/teams',
      data: {
        team: {
          name: team.name,
          location: team.location,
          company_identifier: team.companyIdentifier,
        },
      },
      transformResponse: data => ({
        resources: normalizers.normalizeTeams([data.team]),
      }),
    };
  },
});

export const apiPutUpdateTeam = createResourceAction('update', {
  resourceType: 'teams',
  requestKey: 'apiPutUpdateTeam',

  api: payload => {
    const { team } = payload.resources[0];
    return {
      method: 'PUT',
      url: `manager/v4/teams/${team.id}`,
      data: {
        team: {
          name: team.name,
          location: team.location,
          company_identifier: team.companyIdentifier,
        },
      },
      transformResponse: data => ({
        resources: normalizers.normalizeTeams([data.team]),
      }),
    };
  },
});

export const apiDeleteTeam = createResourceAction('delete', {
  resourceType: 'teams',
  requestKey: 'apiDeleteTeam',

  api: payload => {
    const { id } = payload.resources[0];

    return {
      method: 'DELETE',
      url: `/manager/v4/teams/${id}`,
      transformResponse: data => ({ resources: [data] }),
    };
  },
});
