import React from 'react';

import { Path } from 'react-primitives-svg';

import Icon from '../Icon';

const ArrowRight = props => (
  <Icon {...props}>
    <Path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.495 12.333H4.505m7 7.334l7.762-6.833a.667.667 0 000-1L11.505 5"
    />
  </Icon>
);

export default ArrowRight;
